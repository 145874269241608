import React from "react";
import EnterpriseSelector from "./EnterpriseSelector";
import FarmLevelApplications from "./FarmLevelApplications";

const FarmLevel = (props) => {
    const { form, viewModel, enterprisesOnFarm, values } = props;
    return (
        <React.Fragment>
            <EnterpriseSelector className="u-mt-md" form={form} enterprisesFieldName="enterpriseIds" availableEnterprises={enterprisesOnFarm} />
            <FarmLevelApplications className="u-mt-lg" form={form} viewModel={viewModel} values={values} />
        </React.Fragment>
    );
};

export default FarmLevel;
