import { Link } from "react-router-dom";
import { GridCell } from "components/Grid";
import RenewSubscriptionButton from "containers/Payments/RenewSubscriptionButton";
import Spinner from "components/Spinner/Spinner";
import Alert from "components/Alert";

export default function WidgetContainer({ className, title, children, linkTitle, linkUrl, pill, expired = false, farm, leadBase = null, height = "auto", hideLink, icon, id, loading, error }) {
    return (
        <GridCell className={`${className} FarmDashboard-cell`}>
            <div className="Widget" style={{ height }}>
                <div className="Widget-header">
                    <div className="u-flexSplit">
                        {!linkUrl && (
                            <div className={`Widget-title-nolink ${icon}`}>
                                <span>{title}</span>
                            </div>
                        )}
                        {linkUrl && (
                            <Link to={linkUrl} id={"title_" + id}>
                                <div className={`Widget-title ${icon}`}>
                                    <span>{title}</span>
                                </div>
                            </Link>
                        )}
                        {pill && <span className={`u-pill ${pill.level} u-ml-sm`}>{pill.text}</span>}
                    </div>
                    <div className="u-ml-auto">
                        {farm && expired && <RenewSubscriptionButton farm={farm} />}
                        {!expired && linkTitle && linkUrl && (
                            <div className="h5 u-link" style={{ fontSize: "1rem" }}>
                                {!hideLink && (
                                    <Link to={linkUrl} id={id} className="IconText">
                                        {linkTitle}
                                        <i className="icon icon-arrow-right" />
                                    </Link>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="Widget-content">
                    {loading && (
                        <div className="Tile-body-message u-mt-lg u-mb-lg waiting">
                            <Spinner dark />
                        </div>
                    )}
                    {!loading && error && <Alert type="error" text={error} />}
                    {!loading && children}
                </div>
                {leadBase && (
                    <div className="Widget-footer">
                        <hr />
                        <div className="u-flex u-flexJustifyCenter">
                            <div>{leadBase}</div>
                        </div>
                    </div>
                )}
            </div>
        </GridCell>
    )
}
