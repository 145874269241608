import React from "react";
import { connect } from "react-redux";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import { deleteUserInvitation } from "../MyOrg/_actions";
import ActionLink from "components/ActionLink";
import { modal } from "containers/App/_actions";
import * as utils from "common/utils";
import { useConfirm } from "common/hooks";

const InvitationList = ({ online, invitations, deleteUserInvitation, title, showStudentFields, modal }) => {
    const confirm = useConfirm();

    const resendInvitation = (invitation) => (e) => {
        const viewModel = {
            invitation,
        };
        modal("InviteStudentModal", null, viewModel);
    };

    const deleteInvitation = (invitation) => (e) => {
        confirm(`Are you sure you want to delete the invitation to "${invitation.email}"?`, () => deleteUserInvitation(invitation));
    };

    return (
        <Tile title={title} secondary collapsible>
            <TileBody>
                <div className="Table">
                    <table>
                        <thead>
                            <tr>
                                <th>Email</th>
                                {showStudentFields && <th>Group</th>}
                                {showStudentFields && <th>Expiry</th>}
                                <th className="th--shrink">Status</th>
                                {online && <th className="th--shrink"></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {invitations
                                .filter((i) => i.status !== "Accepted")
                                .map((invitation, i) => {
                                    return (
                                        <tr key={i} name={`${invitation.email}_INV`} id={`${invitation.id}_INV`}>
                                            <td>{invitation.email}</td>
                                            {showStudentFields && <td>{invitation.group}</td>}
                                            {showStudentFields && <td>{utils.dateOnly(invitation.expires)}</td>}
                                            <td>{invitation.status}</td>
                                            {online && (
                                                <td>
                                                    <div className="u-flex">
                                                        <ActionLink id={`resend-${invitation.id}`} className="IconLink--edit" onClick={resendInvitation(invitation)}>
                                                            Resend
                                                        </ActionLink>
                                                        <ActionLink id={`delete-${invitation.id}`} className="IconLink--cross-circle u-ml-sm" onClick={deleteInvitation(invitation)}>
                                                            Delete
                                                        </ActionLink>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </TileBody>
        </Tile>
    );
};

export default connect(null, { deleteUserInvitation, modal })(InvitationList);
