import Alert from "components/Alert";
import { useFieldHelp } from "components/FormFields/useFieldHelp";

export default function InputPack({ input, label, type = "text", placeholder, id, readOnly, isHidden, requiredLabel, required, noLabel, val, value, onChange, noBorder, onBlur, disabled, meta: { touched, error, nrf }, uom, validateOnRender, autoFocus, dataWidth, hideErrors, maxLength, className, tip, info, warning, enableLabel }) {
    const [fieldHelpLink, fieldHelp] = useFieldHelp(tip);

    if (isHidden) return null;

    if (placeholder === 0) placeholder = "0";

    const _onChange = (e) => {
        input.onChange(e);

        if (onChange) onChange(e);
    };

    return nrf ? (
        <div className={`Field ${val && val.touched && val.error ? "has-error" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            <label htmlFor={id} className={`Field-label ${disabled && !enableLabel ? "is-disabled" : ""}`}>
                {label}
                {requiredLabel === true && <sup className="required">*</sup>}
                {label === undefined && "\u00A0"}
                {fieldHelpLink}
            </label>
            <div className="Input_wrapper">
                <input type={type} id={id} name={id} placeholder={placeholder || label} disabled={disabled} className={`Input Input--block ${disabled ? "is-disabled" : ""}`} readOnly={readOnly} autoFocus={autoFocus ? true : false} onChange={(e) => onChange(e)} onBlur={(e) => (onBlur ? onBlur(e) : undefined)} value={value || ""} />
                {uom && <small className="Input-helper">{uom}</small>}
            </div>
            <small className="Field-error" id={`${id}-error`}>
                {val && val.message}
            </small>
            {fieldHelp}
        </div>
    ) : (
        <div className={`Field ${(validateOnRender || touched) && error ? "has-error" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            {noLabel !== true && (
                <label htmlFor={id || input.name} className={`Field-label ${disabled ? "is-disabled" : ""}`}>
                    {label}
                    {(requiredLabel === true || required) && <sup className="required">*</sup>}
                    {label === undefined && "\u00A0"}
                    {fieldHelpLink}
                </label>
            )}
            <div className="Input_wrapper">
                <input {...input} value={value || input.value || ""} type={type || input.type} placeholder={placeholder || label} className={`Input Input--block${disabled ? " is-disabled" : ""} ${noBorder ? " u-nobox-shadow" : ""} `} onChange={_onChange} id={id || input.name} readOnly={readOnly} autoFocus={autoFocus ? true : false} disabled={disabled} maxLength={maxLength} />
                {uom && <small className="Input-helper">{uom}</small>}
            </div>
            {hideErrors !== true && (validateOnRender || touched) && error && (
                <small className="Field-error" id={`${id || input.name}-error`}>
                    {error}
                </small>
            )}
            {info && <Alert type="info" text={info} className="u-mb-0" />}
            {warning && <Alert type="warning" text={warning} />}
            {fieldHelp}
        </div>
    )
}
