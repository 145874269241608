import { AnalysisResultsWidget } from "components/ResultsWidgets";

export default function FarmSummary({ analysis }) {
    return (
        <div className="u-flexSplit u-flexWrap">
            <div className="h2 u-pt-0">Farm details</div>
            <div className="u-pt-md u-pb-md">
                <AnalysisResultsWidget analysis={analysis} showNCE />
            </div>
        </div>
    )
}
