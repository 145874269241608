import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import TileSubPanel from "components/TileSubPanel";
import ActionLink from "components/ActionLink";
import { useConfirm, useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function SoilTestsList({ farm, analysis }) {
    const refData = useRefData();
    const confirm = useConfirm();
    const [expandedSoilTestIds, setExpandedSoilTestIds] = useState([]);
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const _deleteSoilTest = (soilTest) => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete this soil test?`, async () => {
            const updatedAnalysis = {
                ...analysis,
                soilTests: analysis.soilTests.filter((t) => t.id !== soilTest.id),
                blocks: analysis.blocks.map((block) => {
                    return {
                        ...block,
                        soilTestId: block.soilTestId === soilTest.id ? null : block.soilTestId,
                    };
                }),
            }
            await updateAnalysisAsync(updatedAnalysis);
        });
    }

    const toggleSoilTestBlocks = (soilTestId) => {
        if (!expandedSoilTestIds.some((id) => id === soilTestId)) setExpandedSoilTestIds([...expandedSoilTestIds, soilTestId]);
        else setExpandedSoilTestIds(expandedSoilTestIds.filter((id) => id !== soilTestId));
    }

    const hasSoilTests = analysis.soilTests.length > 0;
    if (!hasSoilTests) {
        return null;
    }

    return (
        <>
            <TileSubPanel title="Soil tests" green stickyHeaderName="analysis.soil.soilTests.collapsed">
                <div className="FarmTable_wrapper">
                    <div className="FarmTable-supplementary">
                        <div className="Table u-mt-md">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="th--shrink">Blocks</th>
                                        <th data-width="md-10 xl-10">Reference</th>
                                        <th data-width="md-7.5 xl-7.5" className="u-textCenter">
                                            ASC
                                        </th>
                                        <th data-width="md-5 xl-5" className="u-textCenter">
                                            TBK reserve K
                                        </th>
                                        <th data-width="md-5 xl-5" className="u-textCenter">
                                            Olsen P
                                        </th>
                                        <th data-width="md-5 xl-5" className="u-textCenter">
                                            QT K
                                        </th>
                                        <th data-width="md-5 xl-5" className="u-textCenter">
                                            QT Ca
                                        </th>
                                        <th data-width="md-5 xl-5" className="u-textCenter">
                                            Qt Mg
                                        </th>
                                        <th data-width="md-5 xl-5" className="u-textCenter">
                                            Qt Na
                                        </th>
                                        {analysis.soilTests.length > 0 && <th className="th--shrink">&nbsp;</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {analysis.soilTests.map((soilTest) => {
                                        const showBlocks = expandedSoilTestIds.some((id) => id === soilTest.id);
                                        return (
                                            <Fragment key={soilTest.id}>
                                                <tr>
                                                    <td className="u-textCenter">
                                                        <ActionLink onClick={() => toggleSoilTestBlocks(soilTest.id)}>
                                                            <i className={`icon icon-arrow-${showBlocks ? "up" : "down"}`}></i>
                                                        </ActionLink>
                                                    </td>
                                                    <td className="td--alignTop">
                                                        <span className="u-flex u-flexAlignItemsCenter">
                                                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/soil/soilTests/${soilTest.id}`} className="u-flex u-flexAlignItemsCenter" id={`edit-soiltest-${soilTest.id}`}>
                                                                <span>
                                                                    <b>{soilTest.name}</b>
                                                                </span>
                                                            </Link>
                                                        </span>
                                                    </td>
                                                    <td className="u-textCenter td--alignTop">
                                                        <span id={`soiltest-p-${soilTest.id}`}>{!soilTest.asc || soilTest.asc === 0 ? "Using soil defaults" : soilTest.asc}</span>
                                                    </td>
                                                    <td className="u-textCenter td--alignTop">
                                                        <span id={`soiltest-tBKReserveK-${soilTest.id}`}>{!soilTest.kReserveStatusOption || soilTest.kReserveStatusOption === "Usedefault" ? (soilTest.tbkReserveK && soilTest.tbkReserveK > 0 ? soilTest.tbkReserveK : "Using soil defaults") : utils.valueToText(refData.kReserveStatuses, soilTest.kReserveStatusOption)}</span>
                                                    </td>
                                                    <td className="u-textCenter td--alignTop">
                                                        <span id={`soiltest-p-${soilTest.id}`}>{!soilTest.nutrients["P"] || soilTest.nutrients["P"] === 0 ? "-" : soilTest.nutrients["P"].toFixed(0)}</span>
                                                    </td>
                                                    <td className="u-textCenter td--alignTop">
                                                        <span id={`soiltest-p-${soilTest.id}`}>{!soilTest.nutrients["K"] || soilTest.nutrients["K"] === 0 ? "-" : soilTest.nutrients["K"].toFixed(0)}</span>
                                                    </td>
                                                    <td className="u-textCenter td--alignTop">
                                                        <span id={`soiltest-p-${soilTest.id}`}>{!soilTest.nutrients["Ca"] || soilTest.nutrients["Ca"] === 0 ? "-" : soilTest.nutrients["Ca"].toFixed(0)}</span>
                                                    </td>
                                                    <td className="u-textCenter td--alignTop">
                                                        <span id={`soiltest-p-${soilTest.id}`}>{!soilTest.nutrients["Mg"] || soilTest.nutrients["Mg"] === 0 ? "-" : soilTest.nutrients["Mg"].toFixed(0)}</span>
                                                    </td>
                                                    <td className="u-textCenter td--alignTop">
                                                        <span id={`soiltest-p-${soilTest.id}`}>{!soilTest.nutrients["Na"] || soilTest.nutrients["Na"] === 0 ? "-" : soilTest.nutrients["Na"].toFixed(0)}</span>
                                                    </td>
                                                    <td className="u-textCenter td--alignTop">{!soilTest.defaultTest && <ActionLink id={`delete-soiltest-${soilTest.id}`} onClick={_deleteSoilTest(soilTest)} className="IconLink--trash" title="Delete"></ActionLink>}</td>
                                                </tr>
                                                {showBlocks && (
                                                    <tr className="tr-nested">
                                                        <td colSpan="10">
                                                            <ul className="disc">
                                                                {analysis.blocks
                                                                    .filter((b) => b.soilTestId && b.soilTestId === soilTest.id)
                                                                    .map((b) => (
                                                                        <li key={b.name}>{`${b.name}`}</li>
                                                                    ))}
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </TileSubPanel>
        </>
    )
}
