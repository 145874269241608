import TileSubPanel from "components/TileSubPanel";
import StructureListCard from "./StructureListCard";

export default function StructureList({ farm, analysis }) {
    const structuresWithDistributedSupplements = (analysis.structures || []).filter((s) => {
        return (analysis.feedSupplements || []).some((fs) => (fs.destinations || []).some((d) => d.structureId === s.id));
    });

    return (
        <div>
            {structuresWithDistributedSupplements && structuresWithDistributedSupplements.length > 0 && (
                <TileSubPanel title="Structures" green stickyHeaderName="budget.supplements.structures.collapsed">
                    {structuresWithDistributedSupplements.map((structure) => (
                        <StructureListCard key={structure.id} farm={farm} analysis={analysis} structure={structure} />
                    ))}
                </TileSubPanel>
            )}
        </div>
    )
}
