import { Panel } from "components/Panel";
import Planning from "containers/BudgetHome/Reporting/PlanningReport";

export default function Compare({ farm, analysis }) {
    return (
        <Panel title="Review, plan & compare" green referrer={`/app/farm/${farm.id}`}>
            <div className="u-pl-sm u-pr-sm">
                <Planning farm={farm} analysis={analysis} />
            </div>
        </Panel>
    )
}
