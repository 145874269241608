import { Bar } from "react-chartjs-2";
import { useRefData } from "common/hooks";
import * as domain from "common/domain";
import * as utils from "common/utils";
import { structuresViewModel } from "common/viewModels";
import Alert from "components/Alert";
import Watermark from "components/Watermark";
import { SummaryRow } from "components/PlanningReport";

export default function StructuresEffluentSummary({ farm, before, after }) {
    const refData = useRefData();

    const getEffluentResults = (budget = {}) => {
        const { currentResults = {} } = budget;
        const { effluentResults } = currentResults;
        return effluentResults;
    };

    const effluentResults = getEffluentResults(after.details);
    const baseEffluentResults = before && getEffluentResults(before.details);

    const { effluentSystem = {} } = after.details;
    const baseEffluentSystem = before ? before.details.effluentSystem || {} : {};

    const targetEffluentRate = effluentSystem.targetEffluentRate ? effluentSystem.targetEffluentRate : 150;
    const baseTargetEffluentRate = before && baseEffluentSystem.targetEffluentRate ? baseEffluentSystem.targetEffluentRate : 150;

    const structureDetails = getStructureDetails(after.details, refData, farm);
    const baseStructureDetails = before && getStructureDetails(before.details, refData, farm);

    const structureTypes = [];
    structureDetails
        .filter((s) => s.structure.type !== "MilkingShed")
        .forEach((s) => {
            if (!structureTypes.find((e) => e.structureType === s.structure.type && e.enterpriseType === s.enterprise.enterpriseType.value)) {
                structureTypes.push({ structureType: s.structure.type, enterpriseType: s.enterprise.enterpriseType.value });
            }
        });
    baseStructureDetails &&
        baseStructureDetails
            .filter((s) => s.structure.type !== "MilkingShed")
            .forEach((s) => {
                if (!structureTypes.find((e) => e.structureType === s.structure.type && e.enterpriseType === s.enterprise.enterpriseType.value)) {
                    structureTypes.push({ structureType: s.structure.type, enterpriseType: s.enterprise.enterpriseType.value });
                }
            });

    const effluentKeys = effluentResults && effluentResults.averageEffluentStructure && Object.keys(effluentResults.averageEffluentStructure);
    const baseEffluentKeys = before && baseEffluentResults && baseEffluentResults.averageEffluentStructure && Object.keys(baseEffluentResults.averageEffluentStructure);
    const allEffluentKeys = [...(effluentKeys || []), ...(baseEffluentKeys || [])];
    const uniqueEffluentKeys = [...new Set(allEffluentKeys)];

    let noValues = true;
    structureTypes.forEach((st, i) => {
        const actualStructureCntr = structureDetails.find((e) => e.structure.type === st.structureType && e.enterprise.enterpriseType.value === st.enterpriseType);
        const baseStructureCntr = baseStructureDetails && baseStructureDetails.find((e) => e.structure.type === st.structureType && e.enterprise.enterpriseType.value === st.enterpriseType);
        const baseValue = before ? (baseStructureCntr && baseStructureCntr.enterprise && baseStructureCntr.enterprise.rsu ? utils.round(baseStructureCntr.enterprise.rsu.total, 1) : 0) : undefined;
        const actualValue = actualStructureCntr && actualStructureCntr.enterprise && actualStructureCntr.enterprise.rsu ? utils.round(actualStructureCntr.enterprise.rsu.total, 1) : 0;
        noValues = noValues && !baseValue && !actualValue;
    });
    const hasEffluentResults = effluentResults || baseEffluentResults;
    const hasEntStructures = !noValues;

    if (!hasEntStructures && !hasEffluentResults) {
        return (
            <div className="u-pt-lg">
                <Alert type="info" text="The is no stucture/effluent information for this analysis" />
            </div>
        );
    }

    const isComparing = !!before && !!after;

    return (
        <>
            {hasEntStructures && (
                <>
                    <div className="ReportSection">
                        <h1>Structures</h1>
                        <div className="Table Table-Compressed">
                            <Watermark />
                            <table>
                                <thead>
                                    <tr>
                                        <th data-width="25"></th>
                                        <th></th>
                                        {isComparing && (
                                            <th className="numric u-before" data-width="12.5">
                                                {utils.truncateText(before.displayName, 35)}
                                            </th>
                                        )}
                                        <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                            {utils.truncateText(after.displayName, 35)}
                                        </th>
                                        {isComparing && (
                                            <th className="numeric" data-width="16.7">
                                                Change
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {structureTypes.map((st, i) => {
                                        const actualStructureCntr = structureDetails.find((e) => e.structure.type === st.structureType && e.enterprise.enterpriseType.value === st.enterpriseType);
                                        const baseStructureCntr = baseStructureDetails && baseStructureDetails.find((e) => e.structure.type === st.structureType && e.enterprise.enterpriseType.value === st.enterpriseType);
                                        const name = (actualStructureCntr && actualStructureCntr.name) || (baseStructureCntr && baseStructureCntr.name);
                                        const category = `${name} - ${utils.valueToText(refData.enterpriseTypes, st.enterpriseType)}`;

                                        const beforeValue = baseStructureCntr?.enterprise?.rsu?.total;
                                        const afterValue = actualStructureCntr?.enterprise?.rsu?.total;

                                        return <SummaryRow key={i} beforeValue={beforeValue} afterValue={afterValue} isComparing={isComparing} category={category} label="RSU on structure" unit="RSU" dp={1} prefix={category} />;
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <StructureMonthlySummary before={before} after={after} refData={refData} />
                </>
            )}
            {(effluentResults || baseEffluentResults) && (
                <>
                    <div className="ReportSection u-page-break-avoid">
                        <h1>Effluent</h1>
                        <div className="Table Table-Compressed">
                            <Watermark />
                            <table>
                                <thead>
                                    <tr>
                                        <th data-width="25"></th>
                                        <th></th>
                                        {isComparing && (
                                            <th className="numeric u-before" data-width="12.5">
                                                {utils.truncateText(before.displayName, 35)}
                                            </th>
                                        )}
                                        <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                            {utils.truncateText(after.displayName, 35)}
                                        </th>
                                        {isComparing && (
                                            <th className="numeric" data-width="16.7">
                                                Change
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <SummaryRow beforeValue={baseEffluentResults?.areaReceivingLiquid} afterValue={effluentResults?.areaReceivingLiquid} isComparing={isComparing} category="Area receiving liquid" label="Total area" prefix="AreaReceivingLiquid" unit="ha" dp={1} />
                                    <SummaryRow beforeValue={baseEffluentResults?.pastoralAreaReceivingLiquid} afterValue={effluentResults?.pastoralAreaReceivingLiquid} isComparing={isComparing} category=" " label="Pastoral area receiving liquid" prefix="AreaReceivingLiquid" unit="ha" dp={1} />
                                    <SummaryRow beforeValue={baseEffluentResults?.pastoralBlockPercentage} afterValue={effluentResults?.pastoralBlockPercentage} isComparing={isComparing} category=" " label="% of farm pastoral area" prefix="AreaReceivingLiquid" unit="%" />
                                    <SummaryRow beforeValue={baseEffluentResults?.averageEffluentApplied} afterValue={effluentResults?.averageEffluentApplied} isComparing={isComparing} category=" " label="Average liquid effluent" prefix="AreaReceivingLiquid" unit="N/ha/yr" />
                                    <SummaryRow beforeValue={baseEffluentResults?.averageFertiliserN} afterValue={effluentResults?.averageFertiliserN} isComparing={isComparing} category=" " label="Average fertiliser" prefix="AreaReceivingLiquid" unit="N/ha/yr" />
                                    <SummaryRow beforeValue={baseEffluentResults?.averageOtherN} afterValue={effluentResults?.averageOtherN} isComparing={isComparing} category=" " label="Average other" prefix="AreaReceivingLiquid" unit="N/ha/yr" />
                                    <SummaryRow beforeValue={baseEffluentResults?.averageEffluentDairy} afterValue={effluentResults?.averageEffluentDairy} isComparing={isComparing} category="Source of N in effluent blocks" label="Effluent from farm dairy" prefix="SourceOfNInEffluentBlocks" unit="%" />
                                    {uniqueEffluentKeys.map((k, i) => {
                                        const effluentResult = effluentResults && effluentResults.averageEffluentStructure && effluentResults.averageEffluentStructure[k];
                                        const baseEffluentResult = baseEffluentResults && baseEffluentResults.averageEffluentStructure && baseEffluentResults.averageEffluentStructure[k];

                                        const name = utils.valueToText(refData.structureType, k) || k;

                                        return <SummaryRow key={i} beforeValue={baseEffluentResult} afterValue={effluentResult} isComparing={isComparing} category=" " label={"Effluent from " + name} prefix="SourceOfNInEffluentBlocks" unit="%" />;
                                    })}
                                    <SummaryRow beforeValue={baseEffluentResults?.solidFatePercentage} afterValue={effluentResults?.solidFatePercentage} isComparing={isComparing} category=" " label="Solids" prefix="SourceOfNInEffluentBlocks" unit="%" />
                                    <SummaryRow beforeValue={baseEffluentResults?.exportedFatePercentage} afterValue={effluentResults?.exportedFatePercentage} isComparing={isComparing} category=" " label="Exported" prefix="SourceOfNInEffluentBlocks" unit="%" />

                                    {targetEffluentRate === baseTargetEffluentRate ? (
                                        <>
                                            <SummaryRow showZero beforeValue={baseEffluentResults?.achievementLiquid150kgN} afterValue={effluentResults?.achievementLiquid150kgN} isComparing={isComparing} category="Area of farm to apply all effluent to achieve rates of" label={`${targetEffluentRate} kg N/ha/yr - Liquid`} prefix="Rates" unit="ha" />
                                            <SummaryRow showZero beforeValue={baseEffluentResults?.achievementSolid150kgN} afterValue={effluentResults?.achievementSolid150kgN} isComparing={isComparing} category=" " label={`${targetEffluentRate} kg N/ha/yr - Solid`} prefix="Rates" unit="ha" />
                                            <SummaryRow showZero beforeValue={baseEffluentResults?.achievement150kgN} afterValue={effluentResults?.achievement150kgN} isComparing={isComparing} category=" " label={`${targetEffluentRate} kg N/ha/yr - Total`} prefix="Rates" unit="ha" />
                                        </>
                                    ) : (
                                        <>
                                            <SummaryRow showZero beforeValue={baseTargetEffluentRate} afterValue={targetEffluentRate} isComparing={isComparing} category=" " label="Target application rate as effluent" prefix="Rates" unit="kg N/ha/yr" />
                                            <SummaryRow showZero beforeValue={0} afterValue={effluentResults?.achievementLiquid150kgN} isComparing={isComparing} category="Area of farm to apply all effluent to achieve rates of" label={`${targetEffluentRate} kg N/ha/yr - Liquid`} prefix="Rates" unit="ha" />
                                            <SummaryRow showZero beforeValue={0} afterValue={effluentResults?.achievementSolid150kgN} isComparing={isComparing} category=" " label={`${targetEffluentRate} kg N/ha/yr - Solid`} prefix="Rates" unit="ha" />
                                            <SummaryRow showZero beforeValue={0} afterValue={effluentResults?.achievement150kgN} isComparing={isComparing} category=" " label={`${targetEffluentRate} kg N/ha/yr - Total`} prefix="Rates" unit="ha" />
                                            {before && baseEffluentResults && (
                                                <>
                                                    <SummaryRow showZero beforeValue={baseEffluentResults?.achievementLiquid150kgN} afterValue={0} isComparing={isComparing} category=" " label={`${baseTargetEffluentRate} kg N/ha/yr - Liquid`} prefix="Rates" unit="ha" />
                                                    <SummaryRow showZero beforeValue={baseEffluentResults?.achievementSolid150kgN} afterValue={0} isComparing={isComparing} category=" " label={`${baseTargetEffluentRate} kg N/ha/yr - Solid`} prefix="Rates" unit="ha" />
                                                    <SummaryRow showZero beforeValue={baseEffluentResults?.achievement150kgN} afterValue={0} isComparing={isComparing} category=" " label={`${baseTargetEffluentRate} kg N/ha/yr - Total`} prefix="Rates" unit="ha" />
                                                </>
                                            )}
                                        </>
                                    )}
                                    <SummaryRow beforeValue={baseEffluentResults?.achievementMaintenanceK} afterValue={effluentResults?.achievementMaintenanceK} isComparing={isComparing} category=" " label="Maintenance K" prefix="Rates" unit="ha" />
                                    <SummaryRow beforeValue={baseEffluentResults?.achievement100kgK} afterValue={effluentResults?.achievement100kgK} isComparing={isComparing} category=" " label="100 kg K/ha/yr" prefix="Rates" unit="ha" />
                                    {((effluentResults && effluentResults.maintenanceKWarning && effluentResults.maintenanceKWarning.length > 0) || (before && baseEffluentResults && baseEffluentResults.maintenanceKWarning && baseEffluentResults.maintenanceKWarning.length > 0)) && <SummaryRow beforeValue={baseEffluentResults?.achievement100kgK} afterValue={effluentResults?.achievement100kgK} isComparing={isComparing} category=" " label="Maintenance K Warning" prefix="Rates" unit="ha" />}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <SolidApplicationAreaMonthlySummary before={before} after={after} />
                </>
            )}
        </>
    )
}

function StructureMonthlySummary({ before, after }) {
    const refData = useRefData();
    const structureDetails = getStructureDetails(after.details, refData);
    const baseStructureDetails = before && getStructureDetails(before.details, refData);

    const barOptions = {
        scales: {
            y: {
                display: true,
                title: { display: true, text: "RSU" },
            },
        },
    };

    const structureTypes = [];
    structureDetails.forEach((s) => {
        if (!structureTypes.find((e) => e.structureType === s.structure.type && e.enterpriseType === s.enterprise.enterpriseType.value)) {
            structureTypes.push({ structureType: s.structure.type, enterpriseType: s.enterprise.enterpriseType.value });
        }
    });
    baseStructureDetails &&
        baseStructureDetails.forEach((s) => {
            if (!structureTypes.find((e) => e.structureType === s.structure.type && e.enterpriseType === s.enterprise.enterpriseType.value)) {
                structureTypes.push({ structureType: s.structure.type, enterpriseType: s.enterprise.enterpriseType.value });
            }
        });

    if (structureTypes.length === 0) {
        return null;
    }

    return (
        <div className="ReportSection">
            <h1>Structure RSU by month</h1>
            {structureTypes
                .filter((s) => s.structureType !== "MilkingShed")
                .map((st, i) => {
                    const actualStructureCntr = structureDetails.find((e) => e.structure.type === st.structureType && e.enterprise.enterpriseType.value === st.enterpriseType);
                    const baseStructureCntr = baseStructureDetails && baseStructureDetails.find((e) => e.structure.type === st.structureType && e.enterprise.enterpriseType.value === st.enterpriseType);
                    const name = (actualStructureCntr && actualStructureCntr.name) || (baseStructureCntr && baseStructureCntr.name);
                    const datasets = [];

                    const getDataset = (cntr, label, before) => {
                        const getData = () => {
                            const { enterprise = {} } = cntr;
                            return domain.farmYear.map((m) => {
                                let rsu = 0;
                                if (enterprise.rsu) {
                                    const { months = [] } = enterprise.rsu;
                                    const month = months.find((r) => r.month === m);
                                    if (month) rsu = month.rsu;
                                }
                                return utils.round(rsu, 1);
                            });
                        };
                        return {
                            label,
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: before ? "#E3EEF8" : "#c6f3c6",
                            borderColor: before ? "#509BC2" : "#52af6e",
                            borderWidth: 1,
                            data: getData(),
                        };
                    };

                    if (baseStructureCntr) {
                        datasets.push(getDataset(baseStructureCntr, before.displayName, true));
                    }
                    if (actualStructureCntr) {
                        datasets.push(getDataset(actualStructureCntr, after.displayName, false));
                    }

                    const monthlyAreaData = {
                        datasets,
                        labels: domain.farmYear,
                    };

                    if (!st.enterpriseType) return null;

                    return (
                        <div key={i} className="ReportSection u-page-break-avoid">
                            <h2>
                                {name} - {utils.valueToText(refData.enterpriseTypes, st.enterpriseType)}
                            </h2>
                            <Bar data={monthlyAreaData} height={75} options={barOptions} />
                        </div>
                    )
                })}
        </div>
    )
}

function SolidApplicationAreaMonthlySummary({ before, after }) {
    const solidOptions = {
        scales: {
            y: {
                display: true,
                title: { display: true, text: "Area(ha)" },
            },
        },
        maintainAspectRatio: false,
        responsive: true,
    };

    const getSolidsDataset = (budget, label, before) => {
        const getData = () => {
            const { effluentSystem, structures, blocks } = budget;
            const effluentSystems = [...[effluentSystem], ...structures.filter((s) => s.effluentSystem).map((s) => s.effluentSystem)];
            return domain.farmYear.map((m) => {
                const area = effluentSystems
                    .filter((e) => e)
                    .reduce((total, effluentSystem) => {
                        let sysTotal = total;
                        const { solidManagement } = effluentSystem;
                        if (solidManagement) {
                            const { pondApplications = [], solidApplications = [] } = solidManagement;
                            const applications = [...pondApplications, ...solidApplications];
                            applications.forEach((app) => {
                                if (app.months.find((appMonth) => appMonth.month === m)) {
                                    sysTotal += app.blockIds.reduce((blockTotals, blockId) => {
                                        let bTotal = blockTotals;
                                        const block = blocks.find((b) => b.id === blockId);
                                        bTotal += block.type === "FodderCrop" ? block.rotationArea : block.areaInHectares;
                                        return bTotal;
                                    }, 0);
                                }
                            });
                        }
                        return sysTotal;
                    }, 0);
                return utils.round(area, 1);
            });
        };
        return {
            label,
            fill: false,
            lineTension: 0.1,
            backgroundColor: before ? "#E3EEF8" : "#c6f3c6",
            borderColor: before ? "#509BC2" : "#52af6e",
            borderWidth: 1,
            data: getData(),
        };
    };

    const solidDatasets = [];

    if (before) {
        solidDatasets.push(getSolidsDataset(before.details, before.displayName, true));
    }
    solidDatasets.push(getSolidsDataset(after.details, after.displayName, false));

    const monthlySolidsData = {
        datasets: solidDatasets,
        labels: domain.farmYear,
    };

    let soildsHasData = false;
    monthlySolidsData.datasets.forEach((ds) => {
        const dsHasData = ds.data.reduce((dsResult, x) => dsResult || x > 0, false);
        soildsHasData = soildsHasData || dsHasData;
    });

    if (!soildsHasData) return null;

    return (
        <div className="ReportSection u-page-break-avoid">
            <h1>Effluent solids by month</h1>
            <div className="ReportSection u-page-break-avoid">
                <h2>Solids application area by month</h2>
                <div className="printable-chart-container">
                    <Bar data={monthlySolidsData} className="printable-chart" options={solidOptions} />
                </div>
            </div>
        </div>
    )
}

const getStructureDetails = (analysis, refData) => {
    const { structures = [] } = analysis;
    return structuresViewModel(analysis, structures, refData);
}
