import { Component } from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import Calendar from "components/Calendar";
import * as _utils from "./_utils";
import ActionLink from "components/ActionLink";
import { useRefData } from "common/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function StructureList({ farm, analysis, structure }) {
    const refData = useRefData();

    return (
        <StructureListCardClassComponent farm={farm} analysis={analysis} structure={structure} refData={refData} />
    )
}

class StructureListCardClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
        };
    }

    _toggleMe = (e) => {
        e.preventDefault();
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        const { farm, analysis, structure, refData } = this.props;
        const { isOpen } = this.state;

        const cardData = _getCardData(analysis, structure, refData);
        const calendarData = _getCalenderData(farm, analysis, structure, cardData);
        const hasHarvestedSupplements = calendarData && calendarData.some((cal) => cal.slots.length);
        const canBeToggled = hasHarvestedSupplements;

        const tableHeading = (
            <ul className="DataWidthTable FarmTable">
                <CardTitle analysis={analysis} structure={structure} refData={refData} />
                {hasHarvestedSupplements && <CardSummaryIcons cardData={cardData} />}
                {canBeToggled && (
                    <li className="FarmTable-slideToggle u-ml-auto" data-width="md-shrink xl-shrink">
                        <span className={`RoundButton ${isOpen ? "RoundButton--up" : "RoundButton--down"}`}></span>
                    </li>
                )}
            </ul>
        );

        return (
            <div className="FarmTable_wrapper">
                {!canBeToggled && tableHeading}
                {canBeToggled && <ActionLink onClick={this._toggleMe}>{tableHeading}</ActionLink>}
                {hasHarvestedSupplements && (
                    <div className={`FarmTable-supplementary ${isOpen ? "is-open" : "is-closed"}`}>
                        <Calendar data={calendarData} />
                    </div>
                )}
            </div>
        );
    }
}

function CardTitle({analysis, structure, refData}) {
    let enterpriseType = null;
    const structureType = utils.valueToText(refData.structureType, structure.type);

    const enterprise = analysis.enterprises && analysis.enterprises.find((enterprise) => enterprise.id === structure.enterpriseId);
    if (enterprise) {
        enterpriseType = utils.valueToText(refData.enterpriseTypes, enterprise.type);
    }
    const icon = utils.getStructureIcon(structure.type);

    return (
        <li className="FarmTable-title" data-width="md-100 xl-40">
            <div className="FarmTitle">
                <img className="FarmTitle-icon" src={icon} alt="Structure icon" />
                <div className="FarmTitle-heading">
                    <span className="FarmTitle-name" title={structureType}>
                        {structureType}
                    </span>
                    {enterpriseType && <div className="FarmTitle-sub">{enterpriseType}</div>}
                </div>
            </div>
        </li>
    )
}

function CardSummaryIcons({ cardData }) {
    const distinctByCategory =
        cardData &&
        cardData.reduce((results, supplement) => {
            if (supplement.months.length && !results.some((result) => result.category === supplement.category)) results.push(supplement);

            return results;
        }, []);

    return (
        <li className="FarmTable-summaryIcons" data-width="md-shrink xl-shrink">
            {distinctByCategory && distinctByCategory.map((data) => <img className="u-p-10" key={data.id} src={data.icon} width="45" height="45" alt={data.iconTitle} title={data.iconTitle} />)}
        </li>
    )
}

const _getCardData = (analysis, structure, refData) => {
    const data = [];

    const title = utils.getStructureTitle(analysis, structure, refData);

    analysis.feedSupplements.forEach((supplement) => {
        const destinations = (supplement.destinations || []).filter((d) => d.structureId === structure.id);
        if (destinations && destinations.length > 0) {
            const months = structure.animals.reduce((months, animal) => {
                animal.months.forEach((month) => {
                    if (!months.includes(month)) {
                        months.push(month);
                    }
                });
                return months;
            }, []);

            destinations.forEach((destination) => {
                data.push({
                    ...supplement,
                    structureId: structure.id,
                    title: title,
                    icon: utils.getSupplementIcon(supplement),
                    iconTitle: utils.valueToText(refData.supplementCategories, supplement.category),
                    months: months,
                });
            });
        }
    });

    return data;
}

const _getCalenderData = (farm, analysis, structure, cardData) => {
    const filtered = cardData && cardData.filter((s) => s.months.length);

    const calendarData =
        filtered &&
        filtered.map((data) => {
            const amountTypeMeta = _utils.getAmountTypeMeta(data.amountType);

            const amountTotalByStructure = data.destinations.filter((d) => d.structureId === structure.id).reduce((sum, destination) => (sum += parseInt(destination.amount, 10)), 0);

            const url = `/app/farm/${farm.id}/analysis/${analysis.id}/supplements/${data.id}/distribution`;

            const subTitle = (
                <Link to={url} className="FarmTable-value-link">
                    <span>{`${data.title} (${amountTotalByStructure} ${amountTypeMeta.uom})`}</span>
                    <span className="icon icon-edit"></span>
                </Link>
            );

            return {
                id: data.id,
                title: "Distributed",
                subTitle: subTitle,
                url: url,
                slots: data.months.map((month) => {
                    return {
                        month: month,
                        icon: data.icon,
                    };
                }),
            };
        });

    return calendarData;
}
