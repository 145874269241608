import { useParams } from "react-router-dom";
import * as domain from "common/domain";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import RiparianStripDetails from "./RiparianStripDetails";

export default function RiparianStripEdit({ farm, analysis }) {
    const { blockId } = useParams();
    const block = analysis.blocks.find((b) => b.id === blockId && b.type !== domain.BlockType.FodderCrop);
    const riparianStrip = block?.riparianStrip || domain.factoryRiparianStrip();

    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.WETLANDS} />
            <TileBody>
                <RiparianStripDetails farm={farm} analysis={analysis} block={block} riparianStrip={riparianStrip} isNew={false} />
            </TileBody>
        </Tile>
    )
}
