import FieldGroup from "components/FieldGroup";
import { Field } from "react-final-form";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack2";
import Alert from "components/Alert";
import * as FormUtils from "common/FormUtils";
import * as validations from "common/validations";
import createDecorator from "final-form-calculate";
import * as utils from "common/utils";
import { useRefData } from "common/hooks";

const Nutrients = (props) => {
    const refData = useRefData();

    const { type, nutrients, readonly, unitReadOnly, prefix = "fertiliser.", organicType, solubleUnits = [], noMarginTop, hideUnits } = props;
    let { nutrientNType = [], nutrientPType = [], nutrientMgType = [], supplementGeneralTypes = [], irrigatorNutrientUnits = [], effluentNutrientUnits = [] } = refData;
    effluentNutrientUnits = effluentNutrientUnits.filter((u) => u.value === "Percent");
    const calcDecimalPlaces = readonly ? 3 : ["Effluent", "Organic"].includes(type) ? (["Otherorganicmaterial", "CompostMulches"].includes(organicType) ? 1 : 3) : 1;
    const calcUom = ["Irrigation", "Soluble"].includes(type) ? undefined : "%";
    const fieldName = type === "UserDefinedCrop" ? prefix : `${prefix}nutrients`;
    const organicTip = ["Organic"].includes(type) && ["Otherorganicmaterial", "CompostMulches"].includes(organicType) && "Enter the percentage of nutrients in the fertiliser. This is multiplied by the dry matter content percentage to determine the nutrients applied.";

    return (
        <>
            <div className="Grid">
                <div className="Grid-cell">
                    <Field name={`${prefix}nutrientSum`}>
                        {({ meta }) => (
                            <>
                                <div className={`Field ${noMarginTop ? "u-mt-0" : ""}`}>
                                    {!props.noLabel && (
                                        <span className="Field-label">
                                            Nutrient data<sup className="required">*</sup>
                                        </span>
                                    )}
                                    {organicTip && <Alert type="info" text={organicTip} />}
                                    <div className="u-flex">
                                        <Field name={`${fieldName}.N`} uom={calcUom} label="N" component={InputPack} format={FormUtils.formatters.formatDecimal(calcDecimalPlaces)} formatOnBlur disabled={readonly} className={`${noMarginTop ? "u-mt-0" : ""}`} />
                                        <Field name={`${fieldName}.P`} uom={calcUom} label="P" component={InputPack} format={FormUtils.formatters.formatDecimal(calcDecimalPlaces)} formatOnBlur disabled={readonly} className={`u-ml-sm ${noMarginTop ? "u-mt-0" : ""}`} />
                                        <Field name={`${fieldName}.K`} uom={calcUom} label="K" component={InputPack} format={FormUtils.formatters.formatDecimal(calcDecimalPlaces)} formatOnBlur disabled={readonly} className={`u-ml-sm ${noMarginTop ? "u-mt-0" : ""}`} />
                                        <Field name={`${fieldName}.S`} uom={calcUom} label="S" component={InputPack} format={FormUtils.formatters.formatDecimal(calcDecimalPlaces)} formatOnBlur disabled={readonly} className={`u-ml-sm ${noMarginTop ? "u-mt-0" : ""}`} />
                                        <Field name={`${fieldName}.Ca`} uom={calcUom} label="Ca" component={InputPack} format={FormUtils.formatters.formatDecimal(calcDecimalPlaces)} formatOnBlur disabled={readonly} className={`u-ml-sm ${noMarginTop ? "u-mt-0" : ""}`} />
                                        <Field name={`${fieldName}.Mg`} uom={calcUom} label="Mg" component={InputPack} format={FormUtils.formatters.formatDecimal(calcDecimalPlaces)} formatOnBlur disabled={readonly} className={`u-ml-sm ${noMarginTop ? "u-mt-0" : ""}`} />
                                        <Field name={`${fieldName}.Na`} uom={calcUom} label="Na" component={InputPack} format={FormUtils.formatters.formatDecimal(calcDecimalPlaces)} formatOnBlur disabled={readonly} className={`u-ml-sm ${noMarginTop ? "u-mt-0" : ""}`} />
                                        {type === "Supplements" && <Field name={`${prefix}clValue`} uom={calcUom} label="Cl" component={InputPack} format={FormUtils.formatters.formatDecimal(calcDecimalPlaces)} formatOnBlur disabled={readonly} className={`u-ml-sm ${noMarginTop ? "u-mt-0" : ""}`} />}
                                        {type === "Irrigation" && <Field name={`${prefix}irrigationUnit`} label="Unit" component={SelectPack} required={true && !readonly} placeholder={readonly ? "None" : "Please select"} options={irrigatorNutrientUnits} dataWidth="20" className={`u-ml-sm ${noMarginTop ? "u-mt-0" : ""}`} disabled={readonly || unitReadOnly} />}
                                        {type === "OrganicApplication" && <Field name={`${prefix}unit`} label="Unit" placeholder={readonly ? "None" : "Please select"} component={SelectPack} required={true && !readonly} options={effluentNutrientUnits} dataWidth="25" disabled={readonly || unitReadOnly} className={`u-ml-sm ${noMarginTop ? "u-mt-0" : ""}`} />}
                                        {type === "Soluble" && !hideUnits && <Field name={`${prefix}unit`} label="Unit" component={SelectPack} required={true && !readonly} options={solubleUnits} placeholder={readonly ? "None" : "Please select"} dataWidth="20" disabled={readonly || unitReadOnly} className={`u-ml-sm ${noMarginTop ? "u-mt-0" : ""}`} />}
                                    </div>
                                </div>
                                {meta.touched && meta.error && (
                                    <div className="u-mt-lg Field has-error">
                                        <div>
                                            <small className="Field-error" id="nutrients_error">
                                                {meta.error}
                                            </small>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </Field>
                </div>
            </div>
            {["Fertiliser", "Product"].includes(type) && (
                <div className="Grid">
                    <div className="Grid-cell">
                        <FieldGroup>
                            <Field name={`${prefix}nType`} label="N type" component={SelectPack} required={nutrients.N && !readonly} options={!nutrients.N || readonly ? nutrientNType : nutrientNType.filter((n) => n.value !== "None")} placeholder={!nutrients.N || readonly ? "None" : "Please select"} dataWidth="25" disabled={!nutrients.N || readonly} />
                            <Field name={`${prefix}pType`} label="P type" component={SelectPack} required={nutrients.P && !readonly} options={!nutrients.P || readonly ? nutrientPType : nutrientPType.filter((n) => n.value !== "None")} placeholder={!nutrients.P || readonly ? "None" : "Please select"} dataWidth="25" disabled={!nutrients.P || readonly} />
                            <Field name={`${prefix}percentElementalS`} label="% of S as elemental S" component={InputPack} requiredLabel={nutrients.S && !readonly} uom="%" dataWidth="25" placeholder="None" disabled={!nutrients.S || readonly} />
                            <Field name={`${prefix}mgType`} label="Mg type" component={SelectPack} required={nutrients.Mg && !readonly} options={!nutrients.Mg || readonly ? nutrientMgType : nutrientMgType.filter((n) => n.value !== "None")} placeholder={!nutrients.Mg || readonly ? "None" : "Please select"} dataWidth="25" disabled={!nutrients.Mg || readonly} />
                        </FieldGroup>
                    </div>
                </div>
            )}
            {type === "Supplements" && (
                <div className="Grid">
                    <div className="Grid-cell">
                        <FieldGroup>
                            <Field name={`${prefix}supplementType`} label="Type" component={SelectPack} required={!readonly} options={supplementGeneralTypes} dataWidth="25" placeholder={readonly ? "None" : "Please select"} disabled={readonly} />
                            <Field name={`${prefix}dm`} label="DM (%)" component={InputPack} requiredLabel={!readonly} format={FormUtils.formatters.formatInt} formatOnBlur dataWidth="25" disabled={readonly} />
                            <Field name={`${prefix}me`} label="ME (MJ/kg DM)" component={InputPack} requiredLabel={false} format={FormUtils.formatters.formatDecimal(1)} formatOnBlur dataWidth="25" disabled={readonly} />
                            <Field name={`${prefix}digestibility`} label="Digestibility (%)" component={InputPack} requiredLabel={false} format={FormUtils.formatters.formatInt} formatOnBlur dataWidth="25" disabled={readonly} />
                        </FieldGroup>
                    </div>
                </div>
            )}
        </>
    );
};

export default Nutrients;

const augmentNutrients = (fertiliser) => {
    if (!fertiliser.me && fertiliser.digestibility > 0) {
        fertiliser.me = utils.round(fertiliser.digestibility * 0.14, 1);
        fertiliser.me = fertiliser.me > 15 ? 15 : fertiliser.me;
        fertiliser.me = fertiliser.me < 4 ? 4 : fertiliser.me;
    } else if (!fertiliser.digestibility && fertiliser.me > 0) {
        fertiliser.digestibility = utils.round(fertiliser.me / 0.14, 0);
        fertiliser.digestibility = fertiliser.digestibility < 50 ? 50 : fertiliser.digestibility;
        fertiliser.digestibility = fertiliser.digestibility > 95 ? 95 : fertiliser.digestibility;
    }
};

const validateNutrients = (parent, type) => {
    const nutrients = parent.nutrients || {};
    const errors = { nutrients: {} };
    let unit = "Percent";
    switch (type) {
        case "Soluble":
            unit = parent.unit;
            errors.unit = validations.required(unit);
            break;
        case "Irrigation":
            unit = parent.irrigationUnit;
            errors.nutrients.N = validations.rangeShort(0, 200)(nutrients.N);
            errors.nutrients.P = validations.rangeShort(0, 100)(nutrients.P);
            errors.nutrients.K = validations.rangeShort(0, 200)(nutrients.K);
            errors.nutrients.S = validations.rangeShort(0, 100)(nutrients.S);
            errors.nutrients.Ca = validations.rangeShort(0, 100)(nutrients.Ca);
            errors.nutrients.Mg = validations.rangeShort(0, 100)(nutrients.Mg);
            errors.nutrients.Na = validations.rangeShort(0, 100)(nutrients.Na);
            errors.irrigationUnit = validations.required(unit);
            break;
        case "Organic":
            unit = parent.nutrientUnit;
            errors.nutrientUnit = validations.required(unit);
            break;
        case "Effluent":
            unit = parent.effluentUnit;
            errors.effluentUnit = validations.required(unit);
            break;
        case "Product":
        case "Fertiliser":
            errors.nType = nutrients.N ? (validations.required(parent.nType) || parent.nType === "None" ? "Required" : undefined) : undefined;
            errors.pType = nutrients.P ? (validations.required(parent.pType) || parent.pType === "None" ? "Required" : undefined) : undefined;
            if (nutrients.S && parent.percentElementalS !== 0) {
                errors.percentElementalS = validations.required(parent.percentElementalS);
                errors.percentElementalS = errors.percentElementalS || validations.range(0, 100)(parseInt(parent.percentElementalS, 10));
            }
            errors.mgType = nutrients.Mg ? (validations.required(parent.mgType) || parent.mgType === "None" ? "Required" : undefined) : undefined;
            break;
        case "Supplements": {
            errors.supplementType = validations.required(parent.supplementType);
            errors.dm = validations.required(parent.dm);
            const meOrDigest = parent.me || parent.digestibility ? undefined : "ME or digestibility required";
            errors.me = meOrDigest;
            errors.digestibility = meOrDigest;
            errors.dm = errors.dm || validations.range(5, 100)(parseInt(parent.dm, 10));
            errors.me = errors.me || validations.range(4, 40)(parseFloat(parent.me));
            errors.digestibility = errors.digestibility || validations.range(50, 95)(parseInt(parent.digestibility, 10));
            break;
        }
        default:
            break;
    }

    const keys = Object.keys(nutrients) || [];

    errors.nutrientSum = (keys.length === 0 || keys.every((k) => !nutrients[k])) && !parent.clValue ? "At least one nutrient value should be greater than 0" : undefined;

    if (!errors.nutrientSum && (type === "Effluent" || (type === "Organic" && parent.organicType === "Dairyfactory" && !["Lacticacidwhey", "Sulphuricacidwhey", "Cheesewhey", "None"].includes(parent.dairyFactoryEffluent)))) {
        const nutrientSum = keys.reduce((total, key) => (total += parseFloat(nutrients[key] ? nutrients[key] : 0)), 0);
        errors.nutrientSum = validations.range(0.001, 20)(nutrientSum) && `The sum of the nutrients must not exceed 20${unit === "Percent" ? "%" : ""}`;
    }

    if (!errors.nutrientSum && type === "Organic" && ["CompostMulches", "Otherorganicmaterial"].includes(parent.organicType)) {
        const nutrientSum = keys.reduce((total, key) => (total += parseFloat(nutrients[key] ? nutrients[key] : 0)), 0);
        errors.nutrientSum = validations.range(0.1, 90)(nutrientSum) && "The sum of the nutrients must not exceed 90%";
    }

    if (!errors.nutrientSum && unit === "Percent") {
        let nutrientSum = keys.reduce((total, key) => (total += parseFloat(nutrients[key] ? nutrients[key] : 0)), 0);
        nutrientSum += parent.clValue || 0;
        errors.nutrientSum = validations.range(0.001, 100)(nutrientSum) && "The sum of the nutrients must not exceed 100%";
    }

    if (!errors.nutrientSum && type === "Lime") {
        errors.nutrientSum = nutrients.Ca || nutrients.Mg ? undefined : "Calcium or magnesium are required";
    }

    return errors;
};

const nutrientDecorators = (parentName = "fertiliser") =>
    createDecorator(
        {
            field: `${parentName}.nutrients.N`,
            updates: (fieldValue, fieldName, allValues) => {
                const vm = Object.assign({}, allValues);
                const { fertiliser = {} } = vm;
                if (!fieldValue) {
                    delete fertiliser.nType;
                    return vm;
                }
                return {};
            },
        },
        {
            field: `${parentName}.nutrients.P`,
            updates: (fieldValue, fieldName, allValues) => {
                const vm = Object.assign({}, allValues);
                const { fertiliser = {} } = vm;
                if (!fieldValue) {
                    delete fertiliser.pType;
                    return vm;
                }
                return {};
            },
        },
        {
            field: `${parentName}.nutrients.S`,
            updates: (fieldValue, fieldName, allValues) => {
                const vm = Object.assign({}, allValues);
                const { fertiliser = {} } = vm;
                if (!fieldValue) {
                    delete fertiliser.percentElementalS;
                    return vm;
                }
                return {};
            },
        },
        {
            field: `${parentName}.nutrients.Mg`,
            updates: (fieldValue, fieldName, allValues) => {
                const vm = Object.assign({}, allValues);
                const { fertiliser = {} } = vm;
                if (!fieldValue) {
                    delete fertiliser.mgType;
                    return vm;
                }
                return {};
            },
        },
        {
            field: `${parentName}.percentElementalS`,
            updates: (fieldValue, fieldName, allValues) => {
                const vm = Object.assign({}, allValues);
                const parent = vm[parentName];
                //This is a bit of a hack as we should be able to do this inline on the control. The issue is when the decorator above
                //wipes the value out it remains in the control. Solution maybe to change these all to onChange events
                parent.percentElementalS = FormUtils.formatters.formatInt(fieldValue);
                return vm;
            },
        }
    );

export { validateNutrients, nutrientDecorators, augmentNutrients };
