import React from "react";
import { Field } from "react-final-form";
import ModalBodyAlert from "components/Modal/ModalBodyAlert";
import { Grid, GridCell } from "components/Grid";
import InputPack from "components/InputPack";
import Alert from "components/Alert";
import SelectPack from "components/SelectPack2";
import CheckboxPack from "components/CheckboxPack";
import { ZendeskLink } from "components/Help";

export const VIS_HELP = (
    <div>
        <b>Private - </b> Visibility is restricted to the organisation who created the farm group.
        <br />
        <b>Owner - </b> Farm owners of the published farms will see the farm group and selected reports. Other member's farms will be nonidentifiable to the farm owner.
        <br />
        <b>Farm access - </b> Any organisation with access to the farm will see the farm group and selected reports. Other member's farms will be nonidentifiable.
    </div>
);

const alertText = (
    <div>
        <span>
            A farm group is a way for a group of farms to work together and share information. To view a guide on farm groups,{" "}
            <b>
                {" "}
                <ZendeskLink title="click here" url="https://support.overseer.org.nz/hc/en-us/articles/900000809586" />{" "}
            </b>{" "}
        </span>
    </div>
);

const FarmGroupDetails = ({ dirtySinceLastSubmit, submitError, values, refData }) => {
    const { farmGroupVisibility = [] } = refData;
    return (
        <>
            <ModalBodyAlert>
                <Alert type="info" text={alertText} />
                {!dirtySinceLastSubmit && submitError && <Alert type="error" text={submitError} />}
            </ModalBodyAlert>
            <Grid title="Group details">
                <GridCell>
                    <Field name="id" type="hidden" component="input" />
                    <Field name="name" label="Name" required component={InputPack} dataWidth="50" />
                    <Field name="description" label="Description" component={InputPack} />
                    <Field name="farmGroupVisibility" label="Visibility" required component={SelectPack} options={farmGroupVisibility} placeholder="Select the visibility" info={VIS_HELP} />
                    {values.farmGroupVisibility !== "Private" && (
                        <>
                            <Field name="showAggregatedReport" label="Show aggregated report" type="checkbox" component={CheckboxPack} />
                            <Field name="showBenchmarkReport" label="Show benchmark report" type="checkbox" component={CheckboxPack} />
                            <Field name="showMembers" label="Show member list" type="checkbox" component={CheckboxPack} />
                        </>
                    )}
                </GridCell>
            </Grid>
        </>
    );
};
export default FarmGroupDetails;
