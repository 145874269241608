import { useState } from "react";
import { Switch, Route } from "react-router-dom";
import moment from "moment";
import { Panel, PanelBody, PanelTabs } from "components/Panel";
import Overview from "containers/Reporting/Overview";
import DistributionGraphs from "containers/Reporting/DistributionGraphs";
import HistoryGraphs from "../HistoryGraphs";
import AggregatedReporting from "containers/Reporting/AggregatedReporting/AggregatedReporting";
import ReportingHeatmap from "containers/Reporting/ReportingHeatmap";
import { useCouncilReportingData } from "./_hooks";
import * as utils from "common/utils";
import { useLocation } from "react-router-dom";
import { useCouncilHistory } from "./_hooks";
import Alert from "components/Alert";
import { useAuthContext, useRefData } from "common/hooks";

export default function CouncilReporting() {
    const refData = useRefData();
    const authContext = useAuthContext();
    const { isSystemAdmin, accountType, council: accountCouncil } = authContext;
    const { councils = [] } = refData;
    const initialCouncil = accountCouncil;
    const [criteria, setCriteria] = useState({ year: moment().year() + 1, rollup: "true", council: initialCouncil });
    const { loading: councilReportingDataLoading, data: councilReportingData } = useCouncilReportingData(criteria);
    const { loading: historyLoading, error: historyError, data: historyData } = useCouncilHistory(criteria);

    const location = useLocation();

    if (!isSystemAdmin && accountType !== "Council") {
        return (
            <div className="Tile-body-message">
                <i className="icon icon--md icon-alert u-textError" />
                <h3 className="u-textError">Access denied</h3>
            </div>
        );
    }

    const error = councilReportingData && councilReportingData.error;
    const hasBenchmarkData = councilReportingData && councilReportingData.benchmarkData && councilReportingData.benchmarkData.length > 0;
    const locatedFarms = councilReportingData && councilReportingData.farms ? councilReportingData.farms.filter((f) => f.longitude && f.latitude) : [];

    const handleEnterpriseChange = (e) => {
        setCriteria({ ...criteria, farmType: e.target.value });
    };

    const handleYearChange = (e) => {
        const year = Number(e.target.value);
        setCriteria({ ...criteria, year });
    };

    const handleIncludeOutliersChange = (e) => {
        setCriteria({ ...criteria, includeOutliers: e.target.checked ? "true" : "false" });
    };

    const handleCouncilChange = (e) => {
        setCriteria({ ...criteria, council: e.target.value });
    };

    const handleRollupChange = (e) => {
        setCriteria({ ...criteria, rollup: e.target.checked ? "true" : "false" });
    };

    const handleDownloadsChange = (e) => {
        setCriteria({ ...criteria, includeFarms: e.target.checked ? "true" : "false" });
    };

    const enterpriseSelector = (
        <div className="u-flex flex-center">
            <div className="Panel-action-label">Enterprise:</div>
            <div className="Select Select--slim">
                <select id="enterprise" onChange={handleEnterpriseChange} value={criteria.enterprise}>
                    <option value="">All enterprises</option>
                    <option value="Dairy">Dairy</option>
                    <option value="NonDairyAnimals">Beef, sheep, deer & other animals</option>
                    <option value="CropAndHorticulture">Crop & horticulture</option>
                    <option value="Mixed">Mixed</option>
                </select>
            </div>
        </div>
    );

    const councilSelector = isSystemAdmin && (
        <div className="u-flex flex-center">
            <div className="Panel-action-label">Region:</div>
            <div className="Select Select--slim">
                <select id="council" onChange={(e) => handleCouncilChange(e)} value={criteria.council}>
                    <option value="">All regions</option>
                    <optgroup label="North Island">
                        {utils
                            .sortCouncils(councils)
                            .filter((c) => utils.councilOrder.find((o) => o.value === c.value)?.isNorth === true)
                            .map((c) => (
                                <option value={c.value}>{c.text}</option>
                            ))}
                    </optgroup>
                    <optgroup label="South Island">
                        {utils
                            .sortCouncils(councils)
                            .filter((c) => !utils.councilOrder.find((o) => o.value === c.value) || utils.councilOrder.find((o) => o.value === c.value)?.isNorth === false)
                            .map((c) => (
                                <option value={c.value}>{c.text}</option>
                            ))}
                    </optgroup>
                </select>
            </div>
        </div>
    );

    const yearSelector = (
        <div className="u-flex flex-center">
            <div className="Panel-action-label">Year ending:</div>
            <div className="Select Select--slim">
                <select id="year" onChange={handleYearChange} value={criteria.year}>
                    {(refData.budgetYears || [])
                        .sort()
                        .reverse()
                        .map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                </select>
            </div>
        </div>
    );

    const includeOutliersCheckbox = (
        <div className="u-flex flex-center">
            <label className="Checkbox Checkbox--slim u-ml-sm" htmlFor="includeOutliers">
                <input id="includeOutliers" className="Checkbox-input" type="checkbox" onChange={handleIncludeOutliersChange} checked={criteria.includeOutliers === "true"} />
                <span className="Checkbox-label">Show outliers</span>
            </label>
        </div>
    );

    const includeDownloadCheckbox = (
        <div className="u-flex flex-center">
            <label className="Checkbox Checkbox--slim u-ml-sm" htmlFor="downloads">
                <input id="downloads" className="Checkbox-input" type="checkbox" onChange={handleDownloadsChange} />
                <span className="Checkbox-label">Enable downloads</span>
            </label>
        </div>
    );

    const rollupCheckbox = (
        <div className="u-flex flex-center u-ml-sm">
            <label className="Checkbox Checkbox--slim u-ml-sm" htmlFor="rollup">
                <input id="rollup" className="Checkbox-input" type="checkbox" onChange={handleRollupChange} checked={criteria.rollup === "true"} />
                <span className="Checkbox-label">Or most recent</span>
            </label>
        </div>
    );

    const baseUrl = "/app/council";
    const tabs = {
        aggregated: { title: "Aggregated", path: `${baseUrl}`, exact: true, show: true },
        distribution: { title: "Distribution", path: `${baseUrl}/distribution`, exact: true },
        history: { title: "Trends", path: `${baseUrl}/history`, exact: true },
        map: { title: "Map view", path: `${baseUrl}/map`, exact: true, show: isSystemAdmin },
    };

    const actions = (
        <>
            {enterpriseSelector}
            {councilSelector}
            {!location.pathname.includes(tabs.history.path) && yearSelector}
            {rollupCheckbox}
            {isSystemAdmin && includeDownloadCheckbox}
        </>
    );

    const aggregatedData = councilReportingData && councilReportingData.aggregatedData;

    const farmLookup =
        councilReportingData && councilReportingData.farms
            ? councilReportingData.farms.reduce((d, f) => {
                  d[f.id] = f;
                  return d;
              }, {})
            : undefined;

    const noDataMessage = !error && !hasBenchmarkData && <div className="Tile-body-message">{councilReportingData && councilReportingData.farmCount && councilReportingData.farmCount < 20 ? <p className="lead">There are not enough farms to give valid results.</p> : <p className="lead">There is no data currently available. Please try again in 5 minutes. If problem persists please contact support.</p>}</div>;

    return (
        <Panel>
            <PanelTabs waiting={councilReportingDataLoading} iconClassName={hasBenchmarkData ? "IconLink--stats-bars" : ""} loading={!hasBenchmarkData} tabs={tabs} actions={actions} />
            <PanelBody loading={councilReportingDataLoading && !councilReportingData} waiting={councilReportingDataLoading}>
                <Alert type="info" className="u-mb-md" text={`If "Or most recent" is chosen, missing analyses for the specified year will be filled with the most recent results from up to five years earlier.`} />
                {!error && (
                    <>
                        {tabs && (
                            <Switch>
                                <Route
                                    exact
                                    path={tabs.aggregated.path}
                                    render={() => (
                                        <>
                                            {noDataMessage ? (
                                                noDataMessage
                                            ) : (
                                                <>
                                                    {aggregatedData && <Overview aggregatedData={aggregatedData} farmsWithResultsCount={councilReportingData.farmsWithResultsCount} waiting={councilReportingDataLoading} includeLatestYearEnds={true} />}

                                                    <Panel title="Aggregated reporting" className="u-mt-md" loading={councilReportingDataLoading} skyBlue>
                                                        <PanelBody grey>
                                                            {aggregatedData && <AggregatedReporting aggregatedData={aggregatedData} farmLookup={farmLookup} farmsWithResultsCount={councilReportingData.farmsWithResultsCount} refData={refData} />}
                                                            {!(<p className="lead">There is no data available.</p>)}
                                                        </PanelBody>
                                                    </Panel>
                                                </>
                                            )}
                                        </>
                                    )}
                                />
                                <Route
                                    exact
                                    path={tabs.distribution.path}
                                    render={() => (
                                        <>
                                            {noDataMessage ? (
                                                noDataMessage
                                            ) : (
                                                <Panel title="Distributions" className="u-mt-md" loading={councilReportingDataLoading} skyBlue actions={includeOutliersCheckbox}>
                                                    <PanelBody grey>
                                                        <DistributionGraphs benchmarkData={councilReportingData.benchmarkData} farmLookup={farmLookup} hideTables={true} />
                                                    </PanelBody>
                                                </Panel>
                                            )}
                                        </>
                                    )}
                                />
                                <Route
                                    exact
                                    path={tabs.history.path}
                                    render={() => (
                                        <>
                                            {!historyData || Object.keys(historyData).length === 0 ? (
                                                noDataMessage
                                            ) : (
                                                <Panel title="History" className="u-mt-md" loading={councilReportingDataLoading} skyBlue>
                                                    <PanelBody grey>
                                                        <HistoryGraphs loading={historyLoading} error={historyError} data={historyData} />
                                                    </PanelBody>
                                                </Panel>
                                            )}
                                        </>
                                    )}
                                />
                                {isSystemAdmin && (
                                    <Route
                                        exact
                                        path={tabs.map.path}
                                        render={() => (
                                            <>
                                                <Panel title="Heat map" className="u-mt-md" loading={councilReportingDataLoading} skyBlue>
                                                    <PanelBody grey>
                                                        <ReportingHeatmap locations={locatedFarms} />
                                                    </PanelBody>
                                                </Panel>
                                            </>
                                        )}
                                    />
                                )}
                            </Switch>
                        )}
                    </>
                )}
                {error && (
                    <div className="Tile-body-message">
                        <i className="icon icon--md icon-alert u-textError" />
                        <h3 className="u-textError">{councilReportingData.error}</h3>
                    </div>
                )}
            </PanelBody>
        </Panel>
    );
}
