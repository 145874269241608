import { Fragment } from "react";
import moment from "moment";
import * as icons from "common/icons";
import * as utils from "common/utils";
import ActionLink from "components/ActionLink";
import TileBodyMessage from "components/TileBodyMessage";
import Alert from "components/Alert";
import AnalysesPanelActions from "./AnalysesPanelActions";
import AnalysesByTypePanel from "./AnalysesByTypePanel";
import { useCreateAnalysisModal, useUploadAnalysisModal } from "./Modals/CreateAnalysisModal";
import { RequestFarmAccessActionLink } from "containers/Farm/RequestFarmAccess";
import { useAuthContext, useConfirm, useNavigate, useOnline, useShowQuickTips } from "common/hooks";
import { useDeleteFarmAsync } from "containers/hooks";

export default function AnalysesPanel({ farm }) {
    const online = useOnline();
    const authContext = useAuthContext();
    const showQuickTips = useShowQuickTips();
    const deleteFarmAsync = useDeleteFarmAsync();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const [createAnalysisModal, openCreateAnalysisModal] = useCreateAnalysisModal(farm);
    const [uploadAnalysisModal, openUploadAnalysisModal] = useUploadAnalysisModal(farm);

    const _createAnalysis = () => {
        openCreateAnalysisModal();
    }

    const _uploadAnalysis = () => {
        openUploadAnalysisModal();
    }

    const _deleteFarm = () => {
        confirm("Are you sure you want to delete this farm?", async () => {
            await deleteFarmAsync(farm.id);
            navigate("/");
        });
    };

    const _getAnalysesByType = (type) => {
        let analyses = (farm?.budgets?.filter((b) => b.type === type && b.class === "Budget")) || [];
        analyses.sort((a, b) => (!b.name || !a.name ? -1 : b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1));
        return analyses;
    };

    const canUpdateFarm = utils.canUpdateFarm(farm);
    const canManageAnalyses = utils.canManageAnalyses(farm);
    const canCreateWorkingCopy = utils.canCreateWorkingCopy(farm);

    const yearEnds = _getAnalysesByType("YearEnd").sort((a, b) => b.year - a.year);
    const privateYearEnds = _getAnalysesByType("PrivateYearEnd");
    const allPredictives = _getAnalysesByType("Predictive");
    const predictives = allPredictives.filter((b) => !b.year);
    const plans = allPredictives.filter((b) => b.year);

    const scenarios = _getAnalysesByType("Scenario");

    const hasAnalyses = yearEnds.length > 0 || predictives.length > 0 || plans.length > 0 || scenarios.length > 0 || privateYearEnds.length > 0;
    const subscriptionMessage = `There is no subscription for this farm. Analyses can be created or changed but no nutrient results will be generated. ${canUpdateFarm ? "Select 'Pay for subscription' to pay for a subscription." : ""}`;
    const canBeDeleted = online && utils.canManageAccess(farm) && moment(farm.expirationDate).isBefore(moment()) && farm.budgets && farm.budgets.length === 0;
    const { accessRequest = {}, ownershipStatus } = farm;
    const { requestedBy = {} } = accessRequest;

    const analysisHelpOptions =
        "<p>The following functions are available for each analysis:<p><ul>" +
        "<li><b>Audit log:</b> View an audit log of changes to this analysis.</li>" +
        "<li><b>Comments:</b> View/create comments that will be stored against this analysis. Useful to explain choices made when setting up the analysis.</li>" +
        "<li><b>Publish:</b> Publish a copy of this analysis to your organisation or another organisation. Mainly used to publish to a council for regulation.</li>" +
        "<li><b>Delete:</b> Delete an analysis if it has not been published yet.</li>" +
        "<li><b>Copy:</b> Create a new analysis as a copy of this one.</li>" +
        "<li><b>Replace:</b> Year ends can be replaced with another analysis. Useful if it has been published and so cannot be deleted.</li>" +
        "<li><b>Simplify:</b> Combines similar blocks with different soils and irrigation. This is particularly useful when have an old legacy analysis and want less blocks to manage.</li>" +
        "<li><b>Compare:</b> View the farm impact report, so can compare the differences between this analysis and another on this farm.</li>" +
        "<li><b>Share:</b> Predictive and scenario analyses start as private to the creators organisation. They can be shared with other organisations that have farm access.</li>";

    return (
        <>
            {showQuickTips && <Alert type="help" html={"<p>The following is a list of analyses for this farm. Clicking on an analysis name will open the analysis for editing/viewing. Synced means that the information has been saved at Overseer (if the analysis is changed while you are not connected to the internet it may become unsynced). When a new model version is released the analysis results will be upgraded and the previous results are shown under the analysis name.</p>" + analysisHelpOptions} />}
            {(farm.expired || !farm.expirationDate) && <Alert type={"Error"} text={subscriptionMessage} />}
            <>
                {(canManageAnalyses || canCreateWorkingCopy) && <AnalysesPanelActions farm={farm} createAnalysis={_createAnalysis} uploadAnalysis={_uploadAnalysis} />}
                {!canUpdateFarm && canManageAnalyses && farm.requestAccessRequired && (
                    <Alert
                        text={
                            <div>
                                <b>Warning:</b> You do not have full access to this farm. To view shared analyses you will need write access. Click the button below to request write access:
                                <br />
                                <RequestFarmAccessActionLink farm={farm} className="Button Button--secondary u-mt-md" />
                            </div>
                        }
                        type="Warning"
                    />
                )}
                {!canUpdateFarm && canManageAnalyses && !farm.requestAccessRequired && (
                    <Alert
                        text={
                            <div>
                                <b>Access</b>
                                <br />
                                <Fragment>
                                    You do not have write access to this farm and a pending invite is still waiting for the farm owner to respond. You can still create and publish analyses but you cannot share your analyses or view shared analyses.
                                    <br />
                                    Access for this farm was requested by <b>{requestedBy.userId === authContext.userId ? "yourself" : requestedBy.userFullName}</b> on <b>{utils.dateOnly(accessRequest.requestedOn)}</b>. Clicking the button below will resend the invitation to the {ownershipStatus === "Pending" ? "pending" : ""} farm owner.
                                    <br />
                                    <RequestFarmAccessActionLink farm={farm} className="Button Button--secondary u-mt-md">
                                        Resend access request
                                    </RequestFarmAccessActionLink>
                                </Fragment>
                            </div>
                        }
                        type="Info"
                    />
                )}
                {hasAnalyses && (
                    <Fragment>
                        {yearEnds.length > 0 && <AnalysesByTypePanel title={privateYearEnds.length > 0 ? "Shared year end analyses" : "Year end analyses"} farm={farm} analyses={yearEnds} />}
                        {privateYearEnds.length > 0 && (
                            <div className="u-mt-lg">
                                {!farm.requestAccessRequired && !canUpdateFarm && (
                                    <Alert
                                        text={
                                            <div>
                                                <b>Private Year end analyses:</b> Until your pending access invitation is accepted, year end analyses will be private to you.
                                            </div>
                                        }
                                        type="Info"
                                    />
                                )}
                                {canUpdateFarm && (
                                    <Alert
                                        text={
                                            <div>
                                                <b>Private Year end analyses:</b> To promote these analyses to a shared year end, use the the copy command.
                                            </div>
                                        }
                                        type="Info"
                                    />
                                )}
                                <AnalysesByTypePanel title="Private year end analyses" farm={farm} analyses={privateYearEnds} />
                            </div>
                        )}
                        {plans.length > 0 && <AnalysesByTypePanel title="Plans" farm={farm} analyses={plans} />}
                        {predictives.length > 0 && <AnalysesByTypePanel title="Predictive analyses" farm={farm} analyses={predictives} showVisibility />}
                        {scenarios.length > 0 && <AnalysesByTypePanel title="Scenarios" farm={farm} analyses={scenarios} showVisibility />}
                    </Fragment>
                )}
                {!hasAnalyses && canUpdateFarm && (
                    <TileBodyMessage>
                        <img className="u-p-5" src={icons.add} alt="Plus" />
                        <p className="lead">There are no analyses for this farm</p>
                        <p>Create a new analysis/scenario or upload an existing one to get started!</p>
                        <ActionLink id="budget-list-create" className="IconLink--arrow-plus Button Button--lg u-mt-lg" onClick={_createAnalysis}>
                            Create new analysis
                        </ActionLink>
                        {online && (
                            <ActionLink id="budget-list-upload" className="IconLink--upload Button Button--lg u-mt-lg" onClick={_uploadAnalysis}>
                                Upload analysis
                            </ActionLink>
                        )}
                        {canBeDeleted && (
                            <ActionLink id="delete-farm" className="IconLink--trash Button Button--tertiary Button--lg u-mt-lg" onClick={_deleteFarm}>
                                Delete farm
                            </ActionLink>
                        )}
                    </TileBodyMessage>
                )}
            </>
            {createAnalysisModal}
            {uploadAnalysisModal}
        </>
    )
}
