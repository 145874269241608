import React from "react";
import { compose, withAppContext } from "containers/HOC";
import { Form, Field } from "react-final-form";
import { valueToText } from "common/utils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SelectField from "components/FormFields/SelectField";
import AnalysisComparisonById from "../AnalysisComparisonById";
import { getAnalysisOptions } from "../_utils";

const CompareAnalysesModal = ({ farm, fromId, close, refData }) => {
    return (
        <Form initialValues={{ fromId }} onSubmit={() => {}}>
            {({ values }) => {
                const fromOptions = getAnalysisOptions(farm, [values.toId]);
                const fromOption = fromOptions.find((o) => o.value === values.fromId);
                const fromIdLabel = !fromOption ? "Compare" : fromOption.type === "Publication" ? "Compre publication" : `Compare ${valueToText(refData.budgetTypes, fromOption.type)} analysis`;

                const toOptions = getAnalysisOptions(farm, [values.fromId]);
                const toOption = toOptions.find((o) => o.value === values.toId);
                const toIdLabel = !toOption ? "To" : toOption.type === "Publication" ? "To publication" : `To ${valueToText(refData.budgetTypes, toOption.type)} analysis`;

                return (
                    <form>
                        <Modal title="Compare analyses" close={close} wide>
                            <ModalBody>
                                <Grid>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="fromId" label={fromIdLabel} placeholder="Select an analysis" options={fromOptions} required component={SelectField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="toId" label={toIdLabel} placeholder="Select an analysis" options={toOptions} required component={SelectField} />
                                    </GridCell>
                                </Grid>
                                {values.toId && <AnalysisComparisonById farm={farm} fromId={values.fromId} toId={values.toId} />}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft></ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="cancel" onClick={close} secondary>
                                        Close
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
};
export default compose(withAppContext)(CompareAnalysesModal);
