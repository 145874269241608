import React from "react";
import { useDebounce } from "common/effects";
import { compose, withAppContext, withActions, withState } from "containers/HOC";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import * as utils from "common/utils";
import TargetFarmList from "./TargetFarmList";
import { searchFarms, transferSubscriptions } from "containers/Admin/_actions";

const TransferSubscriptionsModal = ({ farm, farms, close, searchFarms, transferSubscriptions, refData }) => {
    const [searchText, setSearchText] = React.useState("");
    const [searchResults, setSearchResults] = React.useState(farms);

    const debouncedSearchText = useDebounce(searchText, 500);

    React.useEffect(() => {
        if (debouncedSearchText) {
            searchFarms(debouncedSearchText)
                .then((results) => {
                    if (results) {
                        setSearchResults(results);
                    }
                })
                .catch((error) => console.log(error));
        }
    }, [debouncedSearchText, searchFarms]);

    const validate = (viewModel) => {
        const validation = {};
        validation.destinationFarmId = FormUtils.validators.required(viewModel.destinationFarmId);
        return validation;
    };

    const submit = async (viewModel) => {
        const submitResults = await transferSubscriptions(viewModel.id, viewModel.destinationFarmId)
            .then(close)
            .catch((error) => ({ [FORM_ERROR]: error }));
        return submitResults;
    };

    const searchTextInfo = "The account that paid for the source farm must have write, admin or owner rights on the destination farm. An 'Unexpected error' will be displayed if access is missing.";

    return (
        <Form initialValues={farm} validate={validate} onSubmit={submit}>
            {({ form, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const error = !dirtySinceLastSubmit && submitError;
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Move farm subscription" close={close} submitting={submitting} fluid>
                            <ModalBody error={error}>
                                <Panel title="Source farm" skyBlue className="u-mt-lg" notCollapsible>
                                    <PanelBody>
                                        <Grid>
                                            <GridCell>
                                                <Field name="id" type="hidden" component="input" />
                                                <ul className="disc">
                                                    <li>This farm's subscription will be transfered to the target farm. The target farm will still exist but will be in an expired state.</li>
                                                </ul>
                                                <div className="Table u-mt-md">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Address</th>
                                                                <th>Region</th>
                                                                <th>Subscription</th>
                                                                <th>Created</th>
                                                                <th>Modified</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Link to={`/app/farm/${farm.id}`} target="_blank">
                                                                        {farm.name}
                                                                    </Link>
                                                                </td>
                                                                <td>{farm.address}</td>
                                                                <td>{farm.region}</td>
                                                                <td className={farm.expired ? "u-textError" : ""}>{farm.expired ? "Expired" : utils.dateLong(farm.expirationDate, "-")}</td>
                                                                <td>{utils.dateLong(farm.createdOn, "-")}</td>
                                                                <td>{utils.dateLong(farm.modifiedOn, "-")}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </GridCell>
                                        </Grid>
                                    </PanelBody>
                                </Panel>
                                <Panel title="Destination farm" green notCollapsible>
                                    <PanelBody>
                                        <Grid>
                                            <GridCell>
                                                <Field name="searchText" placeholder="Enter search text" info={searchTextInfo} onChange={setSearchText} className="u-mt-md" autoFocus component={TextField} />
                                            </GridCell>
                                            <GridCell className="u-mt-sm">
                                                <TargetFarmList form={form} farms={searchResults} refData={refData} />
                                            </GridCell>
                                        </Grid>
                                    </PanelBody>
                                </Panel>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary disabled={submitting}>
                                        Transfer
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
};
export default compose(
    withAppContext,
    withActions({ searchFarms, transferSubscriptions }),
    withState((state, ownProps) => {
        return {
            farms: (state.admin.farms || []).filter((f) => f.id !== ownProps.farm.id).map((f) => ({ ...f, ...{ disabled: !f.expired } })),
        };
    })
)(TransferSubscriptionsModal);
