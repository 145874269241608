import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import * as FormUtils from "common/FormUtils";
import InputPack from "components/InputPack";

export default function OutdoorPigBlockAreaAllocationFieldArray({ form, outdoorPigs, areaAllocationIndex }) {
    const totalPercentage = getTotalPercentage(outdoorPigs.areaAllocations[areaAllocationIndex].allocations, outdoorPigs);
    const isTotalPercentageInvalid = totalPercentage !== 100;
    const fieldName = `areaAllocations[${areaAllocationIndex}].allocations`;
    const fieldState = form.getFieldState(fieldName);
    const errorMessages = getErrorMessages(fieldState);

    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const showGrowersFinishersFields = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    return (
        <>
            <div className="Table">
                <table>
                    <thead>
                        <tr>
                            <th data-width="30"></th>
                            <th className="th--shrink">% of block</th>
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray name={fieldName} validate={validateAllocations}>
                            {({ fields }) => {
                                return fields.map((field, index) => {
                                    const allocation = fields.value[index];

                                    if (allocation.stockClassGroup && allocation.stockClassGroup === "Growers" && !showGrowersFinishersFields) return null;

                                    if (allocation.stockClassGroup && allocation.stockClassGroup !== "Growers" && outdoorPigs.growOutUnitOnly) return null;

                                    return (
                                        <tr key={allocation.label}>
                                            <td className="u-textBold">{allocation.label}</td>
                                            <td>
                                                <Field name={`${field}.percentage`} onChange={onPercentageChange(form, fieldName)} noLabel hideErrors maxLength="5" placeholder="0" uom="%" type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                            </td>
                                        </tr>
                                    );
                                });
                            }}
                        </FieldArray>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className="u-textRight">Total</th>
                            <th className={`u-textRight ${isTotalPercentageInvalid ? "u-textError" : ""}`}>{totalPercentage} %</th>
                        </tr>
                    </tfoot>
                </table>
                {errorMessages && errorMessages.length > 0 && (
                    <div className="Field-error u-block u-mt-0">
                        {errorMessages.map((msg) => (
                            <p key={msg}>{msg}</p>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

const getTotalPercentage = (allocations, outdoorPigs) => {
    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const includeGrowers = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    const totalPercentage = allocations.reduce((sum, allocation) => {
        if (allocation.stockClassGroup && allocation.stockClassGroup === "Growers" && !includeGrowers) return sum;

        if (allocation.stockClassGroup && allocation.stockClassGroup !== "Growers" && outdoorPigs.growOutUnitOnly) return sum;

        sum += Number(allocation.percentage || 0);
        return sum;
    }, 0);

    return totalPercentage;
}

const validateAllocations = (allocations, outdoorPigs) => {
    const errors = [];

    allocations &&
        allocations.forEach((allocation) => {
            const error = {};

            const isLanesAndOtherAreas = allocation.stockClassGroup === undefined;
            if (isLanesAndOtherAreas) {
                error.percentage = FormUtils.validators.range(1, 60)(allocation.percentage);
            } else {
                error.percentage = FormUtils.validators.max(100)(allocation.percentage);
            }

            errors.push(error);
        });

    const totalPercentage = getTotalPercentage(allocations, outdoorPigs);
    if (totalPercentage !== 100) errors.push({ percentage: "Must equal 100%" });

    return errors;
}

const getErrorMessages = (fieldState) => {
    if (!fieldState || !fieldState.touched) return [];

    const errors = fieldState ? fieldState.error || [] : [];

    const errorMessages = errors.reduce((messages, error, index) => {
        if (error.percentage) {
            const message = error.percentage.toLowerCase();
            if (index === fieldState.value.length) {
                messages.push(`Total ${message}`);
            } else {
                const label = fieldState.value[index].label;
                messages.push(`${label} - ${message}`);
            }
        }
        return messages;
    }, []);
    return errorMessages;
}

const onPercentageChange = (form, fieldName) => (e) => {
    form.blur(fieldName);
}

