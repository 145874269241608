import React from "react";
import ActionLink from "components/ActionLink";
import Page from "components/Page";
import PageBody from "components/PageBody";
import Reporting from "containers/Reporting/Reporting";
import { useAccountReportData } from "containers/hooks";
import { useOnline } from "common/hooks";
import { useQueryString } from "common/effects";
import { useFarmTagsModal } from "containers/FarmTags/FarmTagsModal";

const PublicationReporting = () => {
    const queryString = useQueryString();
    const year = queryString.get("year");
    const data = useAccountReportData(year);
    const { farms } = data;
    const online = useOnline();
    const [farmTagsModal, openFarmTagsModal] = useFarmTagsModal(farms);

    return (
        <>
            <Page>
                <section className="PageHead">
                    <div className="Container">
                        <div className="FarmTitle">
                            <div className="FarmTitle-heading FarmTitle-heading--lg">
                                <span id="page-head-title" className="FarmTitle-name">
                                    Publication Reporting
                                </span>
                            </div>
                        </div>
                        {online && farms?.length > 0 && (
                            <div className="PageHead-actions u-print-none">
                                <ActionLink id="tag-farms-link" className="IconLink--tag u-link" onClick={openFarmTagsModal}>
                                    Tag farms
                                </ActionLink>
                            </div>
                        )}
                    </div>
                </section>
                <PageBody>
                    <Reporting {...data} />
                </PageBody>
            </Page>
            {farmTagsModal}
        </>
    );
};

export default PublicationReporting;
