import { Component } from "react";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Button } from "components/Button";
import InputPack from "components/InputPack";
import NumericInputPack from "components/NumericInputPack";
import SelectPack from "components/SelectPack";
import RadioGroupPack from "components/RadioGroupPack";
import * as validations from "common/validations";
import { Link } from "react-router-dom";
import * as domain from "common/domain";
import * as utils from "common/utils";
import Alert from "components/Alert";
import SavePrompt from "components/SavePrompt";
import ActionLink from "components/ActionLink";
import { useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function RiparianStripDetails({ farm, analysis, block, riparianStrip }) {
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const refData = useRefData();

    return (
        <RiparianStripDetailsClassComponent farm={farm} analysis={analysis} block={block} riparianStrip={riparianStrip} refData={refData} updateAnalysisAsync={updateAnalysisAsync} />
    )
}

class RiparianStripDetailsClassComponent extends Component {
    constructor(props) {
        super(props);
        const { block, riparianStrip } = props;

        const isPastureBlock = block.type === domain.BlockType.ProductivePasture;
        let showRunoffSourceBlocks = false;

        if (riparianStrip) {
            riparianStrip.surfaceFlowEstimationMethod = riparianStrip.valleyWidth > 0 ? SURFACE_FLOW_ESTIMATION_OPTIONS[1].value : SURFACE_FLOW_ESTIMATION_OPTIONS[0].value;

            if (riparianStrip.conditionByPass === true) riparianStrip.runoffEstimationMethod = RUNOFF_ESTIMATION_OPTIONS[3].value;
            else if (riparianStrip.runoffChannels && riparianStrip.runoffChannels !== "Undefined") riparianStrip.runoffEstimationMethod = RUNOFF_ESTIMATION_OPTIONS[1].value;
            else if (riparianStrip.dryChannels && riparianStrip.dryChannels !== "Notused") riparianStrip.runoffEstimationMethod = RUNOFF_ESTIMATION_OPTIONS[2].value;
            else riparianStrip.runoffEstimationMethod = RUNOFF_ESTIMATION_OPTIONS[0].value;

            showRunoffSourceBlocks = riparianStrip.runoffPercentages && riparianStrip.runoffPercentages.length > 0;
            if (showRunoffSourceBlocks) {
                /*eslint-disable no-unused-vars*/
                for (const rp of riparianStrip.runoffPercentages) {
                    rp.area = utils.truncateDecimal(calcBlockArea(rp, riparianStrip), 1);
                }
            }
        }

        this.state = {
            block: block,
            validation: riparianStrip.catchmentArea ? this.validate({}, block, riparianStrip) : {},
            dirty: false,
            submitSucceeded: false,
            isPastureBlock,
            riparianStrip,
            showRunoffSourceBlocks,
        };
    }

    toggleRunoffSourceBlocks() {
        let riparianStrip = this.state.riparianStrip;
        if (!riparianStrip.runoffPercentages || riparianStrip.runoffPercentages.length === 0) {
            riparianStrip.runoffPercentages = [{ blockId: undefined, percentage: undefined }];
        }
        this.setState({ showRunoffSourceBlocks: !this.state.showRunoffSourceBlocks, riparianStrip });
    }

    removeBlock(index) {
        const riparianStrip = this.state.riparianStrip;
        riparianStrip.runoffPercentages.splice(index, 1);
        this.setState({ riparianStrip });
    }

    addBlock() {
        const riparianStrip = this.state.riparianStrip;
        riparianStrip.runoffPercentages.push({});
        this.setState({ riparianStrip });
    }

    onChange(e, source) {
        const block = this.state.block;
        const riparianStrip = this.state.riparianStrip;
        switch (source.type) {
            case "riparianStrip":
                riparianStrip[source.key] = e.target.value;
                break;
            case "surfaceFlowEstimation":
                riparianStrip[source.key] = e.target.value;
                break;
            case "runoffEstimation":
                riparianStrip[source.key] = e.target.value;
                riparianStrip.conditionByPass = e.target.value === RUNOFF_ESTIMATION_OPTIONS[3].value;
                break;
            case "runoffPercentage":
                riparianStrip.runoffPercentages[source.index][source.key] = e.target.value;
                if (!isNaN(riparianStrip.runoffPercentages[source.index][source.key])) {
                    riparianStrip.runoffPercentages[source.index].area = utils.truncateDecimal(calcBlockArea(riparianStrip.runoffPercentages[source.index], riparianStrip), 1);
                }
                break;
            default:
                break;
        }
        this.isValid(block, riparianStrip, source);
        this.setState({ block, dirty: true });
    }

    validate(currentValidation, block, riparianStrip, source = undefined) {
        let validation = {};
        let message = undefined;
        let touched = false;
        const isPastureBlock = block.type === domain.BlockType.ProductivePasture;

        message = validations.required(riparianStrip.catchmentArea);
        message = message || validations.isNumeric(riparianStrip.catchmentArea);
        message = message || validations.range(0.1, isPastureBlock ? block.areaInHectares : 32000)(riparianStrip.catchmentArea);
        touched = isTouched(currentValidation, source, "catchmentArea");
        validation.catchmentArea = { touched: touched, error: message !== undefined, message: message };

        message = validations.required(riparianStrip.stripLength);
        message = message || validations.isNumeric(riparianStrip.stripLength);
        message = message || validations.range(1, 32000)(riparianStrip.stripLength);
        touched = isTouched(currentValidation, source, "stripLength");
        validation.stripLength = { touched: touched, error: message !== undefined, message: message };

        message = validations.required(riparianStrip.pathLength);
        message = message || validations.isNumeric(riparianStrip.pathLength);
        message = message || validations.range(1, 32000)(riparianStrip.pathLength);
        touched = isTouched(currentValidation, source, "pathLength");
        validation.pathLength = { touched: touched, error: message !== undefined, message: message };

        message = validations.required(riparianStrip.stripAge);
        message = message || validations.isNumeric(riparianStrip.stripAge);
        message = message || validations.range(1, 32000)(riparianStrip.stripAge);
        touched = isTouched(currentValidation, source, "stripAge");
        validation.stripAge = { touched: touched, error: message !== undefined, message: message };

        message = validations.valueNotEmpty(riparianStrip.entryCondition);
        touched = isTouched(currentValidation, source, "entryCondition");
        validation.entryCondition = { touched: touched, error: message !== undefined, message: message };

        message = validations.valueNotEmpty(riparianStrip.condition);
        touched = isTouched(currentValidation, source, "condition");
        validation.condition = { touched: touched, error: message !== undefined, message: message };

        if (riparianStrip.surfaceFlowEstimationMethod === SURFACE_FLOW_ESTIMATION_OPTIONS[0].value) {
            message = validations.required(riparianStrip.surfaceDrainagePercentage);
            message = message || validations.isNumeric(riparianStrip.surfaceDrainagePercentage);
            message = message || validations.range(1, 100)(riparianStrip.surfaceDrainagePercentage);
            touched = isTouched(currentValidation, source, "surfaceDrainagePercentage");
            validation.surfaceDrainagePercentage = { touched: touched, error: message !== undefined, message: message };
        } else {
            message = validations.required(riparianStrip.valleyWidth);
            message = message || validations.isNumeric(riparianStrip.valleyWidth);
            message = message || validations.range(1, 32000)(riparianStrip.valleyWidth);
            touched = isTouched(currentValidation, source, "valleyWidth");
            validation.valleyWidth = { touched: touched, error: message !== undefined, message: message };
        }

        switch (riparianStrip.runoffEstimationMethod) {
            case RUNOFF_ESTIMATION_OPTIONS[0].value:
                message = validations.required(riparianStrip.runoffPassPercentage);
                message = message || validations.isNumeric(riparianStrip.runoffPassPercentage);
                message = message || validations.range(1, 100)(riparianStrip.runoffPassPercentage);
                touched = isTouched(currentValidation, source, "runoffPassPercentage");
                validation.runoffPassPercentage = { touched: touched, error: message !== undefined, message: message };
                break;
            case RUNOFF_ESTIMATION_OPTIONS[1].value:
                message = validations.valueNotEmpty(riparianStrip.runoffChannels);
                touched = isTouched(currentValidation, source, "runoffChannels");
                validation.runoffChannels = { touched: touched, error: message !== undefined, message: message };
                break;
            case RUNOFF_ESTIMATION_OPTIONS[2].value:
                message = validations.valueNotEmpty(riparianStrip.dryChannels);
                touched = isTouched(currentValidation, source, "dryChannels");
                validation.dryChannels = { touched: touched, error: message !== undefined, message: message };
                break;
            default:
                break;
        }

        message = validations.required(riparianStrip.pondWaterUpslopePercentage);
        message = message || validations.isNumeric(riparianStrip.pondWaterUpslopePercentage);
        message = message || validations.range(1, 100)(riparianStrip.pondWaterUpslopePercentage);
        touched = isTouched(currentValidation, source, "pondWaterUpslopePercentage");
        validation.pondWaterUpslopePercentage = { touched: touched, error: message !== undefined, message: message };

        if (riparianStrip.runoffPercentages) {
            let catchmentTotalPercentage = riparianStrip.runoffPercentages.reduce((a, b) => a + parseInt(b.percentage, 10), 0);
            if (catchmentTotalPercentage < 20) {
                validation.distribution = { touched: true, error: true, message: "At least 20% of the runoff must come from within the farm" };
            } else if (catchmentTotalPercentage > 100) {
                validation.distribution = { touched: true, error: true, message: "Sum of runoff percentages must be less than or equal to 100" };
            } else if (catchmentTotalPercentage === 100) {
                riparianStrip.outsideFarmSourceAmount = 0;
            }
            for (const cp of riparianStrip.runoffPercentages) {
                let key = `${cp.blockId}.percentage`;
                message = validations.required(cp.percentage);
                message = message || validations.isNumeric(cp.percentage);
                message = message || validations.range(1, 100)(cp.percentage);
                touched = isTouched(currentValidation, source, key);
                validation[key] = { touched: touched, error: message !== undefined, message: message };

                key = `${cp.blockId}.blockId`;
                message = validations.valueNotEmpty(cp.blockId);
                touched = isTouched(currentValidation, source, key);
                validation[key] = { touched: touched, error: message !== undefined, message: message };
            }
        }

        return validation;
    }

    isValid(block, riparianStrip, source = undefined) {
        const validation = this.validate(this.state.validation, block, riparianStrip, source);
        this.setState({ validation: validation });
        for (let key of Object.keys(validation)) {
            if (validation[key].error) {
                return false;
            }
        }
        return true;
    }

    async save() {
        const block = this.state.block;
        const riparianStrip = this.state.riparianStrip;
        if (!this.state.showRunoffSourceBlocks) {
            delete riparianStrip.runoffPercentages;
        }
        if (this.isValid(block, riparianStrip)) {
            this.setState({ submitting: true, submitSucceeded: false });

            if (riparianStrip.surfaceFlowEstimationMethod === SURFACE_FLOW_ESTIMATION_OPTIONS[0].value) delete riparianStrip.valleyWidth;
            else delete riparianStrip.surfaceDrainagePercentage;

            switch (riparianStrip.runoffEstimationMethod) {
                case RUNOFF_ESTIMATION_OPTIONS[0].value:
                    delete riparianStrip.runoffChannels;
                    delete riparianStrip.dryChannels;
                    break;
                case RUNOFF_ESTIMATION_OPTIONS[1].value:
                    delete riparianStrip.runoffPassPercentage;
                    delete riparianStrip.dryChannels;
                    break;
                case RUNOFF_ESTIMATION_OPTIONS[2].value:
                    delete riparianStrip.runoffPassPercentage;
                    delete riparianStrip.runoffChannels;
                    break;
                default:
                    delete riparianStrip.runoffPassPercentage;
                    delete riparianStrip.dryChannels;
                    delete riparianStrip.runoffChannels;
                    break;
            }
            block.riparianStrip = riparianStrip;

            const analysis = this.props.analysis;
            analysis.blocks = analysis.blocks.map((b) => (b.id === block.id ? block : b));

            await this.props.updateAnalysisAsync(analysis);

            this.setState({ submitting: false, submitSucceeded: true });
        }
    }

    render() {
        const _referrer = `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/drainage`;
        const distVal = this.state.validation.distribution || {};
        const entryConditions = this.props.refData.riparianEntryConditions;
        const conditions = (this.props.refData.riparianConditions || []).filter((x) => (this.state.isPastureBlock ? ["Densehealthygrass", "Rankfragileorclumpygrass"].includes(x.value) : true));
        const runoffChannels = this.props.refData.riparianRunoffs;
        const dryChannels = (this.props.refData.riparianDryChannels || []).filter((x) => x.value !== "Notused");

        const usedBlocks = !this.state.riparianStrip ? [] : this.state.riparianStrip.runoffPercentages ? this.state.riparianStrip.runoffPercentages.map((b) => b.blockId) : [];
        const blocks = this.props.analysis.blocks.filter((b) => b.id !== this.state.block.id && ![domain.BlockType.NonProductiveRiparian, domain.BlockType.NonProductiveWetland, domain.BlockType.FodderCrop].includes(b.type));
        let outsideFarmSourceAmount = 100;
        const totalPercentage = this.state.riparianStrip && this.state.riparianStrip.runoffPercentages ? this.state.riparianStrip.runoffPercentages.reduce((a, b) => a + parseInt(b.percentage, 10), 0) : 0;
        if (this.state.riparianStrip && this.state.riparianStrip.runoffPercentages) {
            for (const cp of this.state.riparianStrip.runoffPercentages) {
                if (!isNaN(cp.percentage)) {
                    outsideFarmSourceAmount -= parseFloat(cp.percentage);
                }
            }
            outsideFarmSourceAmount = outsideFarmSourceAmount < 0 ? 0 : utils.truncateDecimal(outsideFarmSourceAmount, 1);
        }
        const pageTitle = this.state.isPastureBlock ? "Grass filter strip" : "Riparian model inputs";

        return (
            <div>
                <SavePrompt blockIf={this.state.dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={_referrer} />
                <Tile title={pageTitle} waiting={this.state.submitting} referrer={_referrer}>
                    {this.props.showQuickTips && !this.state.isPastureBlock && <Alert className="u-mb-0" type="help" text="A riparian strip/block is defined as a fenced area through which runoff water must pass before reaching a watercourse or water body. The fenced-off region is the area defined as the riparian block. Not all water captured by the catchment is intercepted by the riparian strip/block and water movement across a catchment is complex. The riparian model within Overseer asks some detailed questions to try and provide a best estimate of likely efficacy of the riparian area, e.g. dimensions and amount of the catchment runoff that is intercepted. It is always of benefit to make some on-farm observations before trying to set up the riparian block." />}
                    {this.state.isPastureBlock && <Alert className="u-mb-0" type="info" text="A grass filter strip is an area fenced off containing dense grass such that runoff water passes through it before reaching a water body such as a stream." />}
                    {this.props.showQuickTips && this.state.isPastureBlock && <Alert className="u-mb-0" type="help" text="Defining the effectiveness of a grass filter strip requires observation of how the strip operates during a runoff event. After making these observations, complete the fields provided on this page. Note that a grass filter strip near a stream could be a source of P. In this case P removal by the grass filter strip may be over-estimated due to no recycling of accumulated P and P bypassing the filter strip as re-emergent saturated flow." />}

                    <TileBody>
                        <div className="Grid Grid--withGutter">
                            <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                <h3>Dimensions</h3>
                                <NumericInputPack id="catchmentArea" name="catchmentArea" type="text" label="Catchment area supplying strip" tip="Enter the dimensions for the catchment area supplying the riparian strip, expressed in hectares." value={this.state.riparianStrip.catchmentArea} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "catchmentArea" })} meta={{ nrf: true }} uom="ha" decimalPlaces={1} val={this.state.validation.catchmentArea} requiredLabel={true} placeholder="0" />
                                <NumericInputPack id="stripLength" name="stripLength" type="text" label="Length of strip" value={this.state.riparianStrip.stripLength} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "stripLength" })} meta={{ nrf: true }} uom="m" decimalPlaces={0} val={this.state.validation.stripLength} requiredLabel={true} placeholder="0" />
                                <NumericInputPack id="pathLength" name="pathLength" type="text" label="Width of strip" tip={`Enter the width of the riparian strip, i.e. the "down-the-length" slope, expressed in metres.`} value={this.state.riparianStrip.pathLength} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "pathLength" })} meta={{ nrf: true }} uom="m" decimalPlaces={0} val={this.state.validation.pathLength} requiredLabel={true} placeholder="0" />
                            </div>
                        </div>

                        <div className="Grid Grid--withGutter u-mt-lg u-mb-lg">
                            <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                <h3>Strip condition</h3>
                                <NumericInputPack id="stripAge" name="stripAge" type="text" label="Age of strip" value={this.state.riparianStrip.stripAge} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "stripAge" })} meta={{ nrf: true }} uom="years" decimalPlaces={0} val={this.state.validation.stripAge} requiredLabel={true} placeholder="0" />
                                <SelectPack id="entryCondition" name="entryCondition" value={this.state.riparianStrip.entryCondition} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "entryCondition" })} meta={{ nrf: true }} val={this.state.validation.entryCondition} label="Entry condition" requiredLabel={true}>
                                    <option value="" disabled={true}>
                                        Select an entry condition
                                    </option>
                                    {entryConditions &&
                                        entryConditions.map((item) => (
                                            <option value={item.value} key={item.value}>
                                                {item.text}
                                            </option>
                                        ))}
                                </SelectPack>
                                <SelectPack id="condition" name="condition" value={this.state.riparianStrip.condition} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "condition" })} meta={{ nrf: true }} val={this.state.validation.condition} label="Riparian strip type and condition" requiredLabel={true}>
                                    <option value="" disabled={true}>
                                        Select a condition
                                    </option>
                                    {conditions &&
                                        conditions.map((item) => (
                                            <option value={item.value} key={item.value}>
                                                {item.text}
                                            </option>
                                        ))}
                                </SelectPack>
                            </div>
                        </div>

                        <Tile title="Hydraulic performance" tertiary>
                            {this.props.showQuickTips && <Alert type="help" text="Describes estimates of % surface flow that drains through the riparian block, the % runoff that is intercepted and the amount of ponding." />}
                            <TileBody>
                                <div className="Grid Grid--withGutter">
                                    <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                        <h3>Estimate proportion of surface flow that drains through the strip</h3>
                                        <RadioGroupPack id="surfaceFlowEstimationMethod" name="surfaceFlowEstimationMethod" value={this.state.riparianStrip.surfaceFlowEstimationMethod} onChange={(e) => this.onChange(e, { type: "surfaceFlowEstimation", key: "surfaceFlowEstimationMethod" })} meta={{ nrf: true }} inline={false} options={SURFACE_FLOW_ESTIMATION_OPTIONS} />
                                        <div className="Field-group">
                                            {this.state.riparianStrip.surfaceFlowEstimationMethod === SURFACE_FLOW_ESTIMATION_OPTIONS[0].value && <NumericInputPack id="surfaceDrainagePercentage" name="surfaceDrainagePercentage" type="text" label="Percentage of surface flow that drains through the strip" info="Enter 50% if fenced on one side only" value={this.state.riparianStrip.surfaceDrainagePercentage} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "surfaceDrainagePercentage" })} meta={{ nrf: true }} uom="%" decimalPlaces={0} val={this.state.validation.surfaceDrainagePercentage} requiredLabel={true} placeholder="0" />}
                                            {this.state.riparianStrip.surfaceFlowEstimationMethod === SURFACE_FLOW_ESTIMATION_OPTIONS[1].value && <NumericInputPack id="valleyWidth" name="valleyWidth" type="text" label="Width of valley" value={this.state.riparianStrip.valleyWidth} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "valleyWidth" })} meta={{ nrf: true }} uom="m" decimalPlaces={0} val={this.state.validation.valleyWidth} requiredLabel={true} placeholder="0" />}
                                        </div>
                                    </div>
                                </div>
                                <div className="Grid Grid--withGutter u-mt-md">
                                    <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                        <h3>Estimate proportion of this runoff that interacts with the strip</h3>
                                        <RadioGroupPack id="runoffEstimationMethod" name="runoffEstimationMethod" value={this.state.riparianStrip.runoffEstimationMethod} onChange={(e) => this.onChange(e, { type: "runoffEstimation", key: "runoffEstimationMethod" })} meta={{ nrf: true }} inline={false} options={RUNOFF_ESTIMATION_OPTIONS} />
                                        <div className="Field-group">
                                            {this.state.riparianStrip.runoffEstimationMethod === RUNOFF_ESTIMATION_OPTIONS[0].value && <NumericInputPack id="runoffPassPercentage" name="runoffPassPercentage" type="text" label="Percentage of runoff that is intercepted by the strip or runoff not in channels" info="1 = highly channelised, 100 = all runoff intercepted by vegetation" value={this.state.riparianStrip.runoffPassPercentage} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "runoffPassPercentage" })} meta={{ nrf: true }} uom="%" decimalPlaces={0} val={this.state.validation.runoffPassPercentage} requiredLabel={true} placeholder="0" />}
                                            {this.state.riparianStrip.runoffEstimationMethod === RUNOFF_ESTIMATION_OPTIONS[1].value && (
                                                <SelectPack id="runoffChannels" name="runoffChannels" value={this.state.riparianStrip.runoffChannels} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "runoffChannels" })} meta={{ nrf: true }} val={this.state.validation.runoffChannels} label="During a runoff event, where shallow channels form in paddocks, how much runoff is carried?" requiredLabel={true}>
                                                    <option value="" disabled={true}>
                                                        Select an option
                                                    </option>
                                                    {runoffChannels &&
                                                        runoffChannels.map((item) => (
                                                            <option value={item.value} key={item.value}>
                                                                {item.text}
                                                            </option>
                                                        ))}
                                                </SelectPack>
                                            )}
                                            {this.state.riparianStrip.runoffEstimationMethod === RUNOFF_ESTIMATION_OPTIONS[2].value && (
                                                <SelectPack id="dryChannels" name="dryChannels" value={this.state.riparianStrip.dryChannels} onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "dryChannels" })} meta={{ nrf: true }} val={this.state.validation.dryChannels} label="During dry weather, are channels draining the strip clearly visible?" requiredLabel={true}>
                                                    <option value="" disabled={true}>
                                                        Select an option
                                                    </option>
                                                    {dryChannels &&
                                                        dryChannels.map((item) => (
                                                            <option value={item.value} key={item.value}>
                                                                {item.text}
                                                            </option>
                                                        ))}
                                                </SelectPack>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="Grid Grid--withGutter u-mt-md">
                                    <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                        <h3>Estimate length of strip that ponds water upslope</h3>
                                        <div className="Field-group">
                                            <NumericInputPack
                                                id="pondWaterUpslopePercentage"
                                                name="pondWaterUpslopePercentage"
                                                type="text"
                                                label="Percentage of length of strip ponding water upslope"
                                                tip="Length that ponds - To estimate the percentage of length that ponds water upslope from the filter strip during a runoff event, observe water flow into the upstream edge of the grass filter strip, or into a riparian strip. If water is observed to pond, then estimate the percentage of the filter strip length where water ponds, and enter this value.  If no ponding is observed, observe where water enters the filter strip, estimate the percentage of the filter strip length where water is entering, and enter this value."
                                                value={this.state.riparianStrip.pondWaterUpslopePercentage}
                                                onChange={(e) => this.onChange(e, { type: "riparianStrip", key: "pondWaterUpslopePercentage" })}
                                                meta={{ nrf: true }}
                                                uom="%"
                                                decimalPlaces={0}
                                                val={this.state.validation.pondWaterUpslopePercentage}
                                                requiredLabel={true}
                                                placeholder="0"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TileBody>
                        </Tile>

                        {!this.state.isPastureBlock && (
                            <div className="Grid Grid--withGutter">
                                <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                    <div className="Field">
                                        <label htmlFor="specifyRunoffSourceBlocks" className="Checkbox">
                                            <input id="specifyRunoffSourceBlocks" type="checkbox" className="Checkbox-input" onChange={(e) => this.toggleRunoffSourceBlocks()} checked={this.state.showRunoffSourceBlocks} />
                                            <span className="Checkbox-label">Specify runoff sources across the blocks on this farm</span>
                                        </label>
                                    </div>
                                </div>
                                {this.state.showRunoffSourceBlocks && (
                                    <div className="Grid-cell u-mt-md">
                                        <Tile
                                            title="Runoff sources"
                                            indent
                                            actions={
                                                usedBlocks.length < blocks.length && (
                                                    <span id="riparianStrip-runoff-source-addblock" className="IconLink--arrow-plus u-link" onClick={(e) => this.addBlock()}>
                                                        Add another block
                                                    </span>
                                                )
                                            }
                                            tertiary
                                        >
                                            <Alert className="u-mb-0" type="info" text="For the runoff that flows into the riparian strip, enter the percentage that comes from each block. Any remaining area will be attributed to an off farm source." />
                                            <TileBody>
                                                {this.state.riparianStrip.runoffPercentages.map((rp) => {
                                                    let index = this.state.riparianStrip.runoffPercentages.indexOf(rp);
                                                    return <RunoffPercentageCard key={index} index={index} runoffPercentage={rp} blocks={blocks} usedBlocks={usedBlocks} showRemove={this.state.riparianStrip.runoffPercentages.length > 1} removeBlock={(i) => this.removeBlock(i)} validation={this.state.validation} onChange={(e, source) => this.onChange(e, source)} />;
                                                })}
                                                <div className="Field-group">
                                                    <InputPack type="text" dataWidth="70" label="Off farm source" value="From outside farm" meta={{ nrf: true }} disabled={true} />
                                                    <InputPack id="outsideFarmSourceAmount" name="outsideFarmSourceAmount" type="text" dataWidth="25" uom="%" value={isNaN(outsideFarmSourceAmount) ? 0 : outsideFarmSourceAmount} meta={{ nrf: true }} disabled={true} />
                                                    <div className="Field" data-width="5"></div>
                                                </div>
                                                <div className="Field-group">
                                                    <div className="Field" data-width="70">
                                                        <label className="Field-label">Totals</label>
                                                    </div>
                                                    <div className="Field" data-width="25">
                                                        <div id="offfarm-percent">
                                                            <span>
                                                                <b>{isNaN(totalPercentage) || totalPercentage < 100 ? 100 : totalPercentage}</b> %
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="Field" data-width="5"></div>
                                                </div>
                                                <div className={"Field" + (distVal.touched && distVal.error ? " has-error" : "")}>
                                                    <small className="Field-error" id="distribution-error">
                                                        {distVal.message}
                                                    </small>
                                                </div>
                                            </TileBody>
                                        </Tile>
                                    </div>
                                )}
                            </div>
                        )}
                    </TileBody>
                    <TileFooter>
                        <div className="ButtonBar ButtonBar--fixed">
                            <div className="ButtonBar-left">
                                <Link to={_referrer} className="Button Button--secondary">
                                    Cancel
                                </Link>
                            </div>
                            <div className="ButtonBar-right">
                                <Button id="fertiliser-details-submit" submit primary waiting={this.state.submitting} onClick={(e) => this.save()}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </TileFooter>
                </Tile>
            </div>
        );
    }
}

const SURFACE_FLOW_ESTIMATION_OPTIONS = [
    { value: "SurfaceFlow", text: "Enter estimated % of surface flow" },
    { value: "ValleyWidth", text: "Estimate by entering width of valley" },
];

const RUNOFF_ESTIMATION_OPTIONS = [
    { value: "Strip", text: "Enter estimated % of runoff that is intercepted by the strip" },
    { value: "Channels", text: "Estimate from observations during runoff" },
    { value: "DryWeather", text: "Estimate from state of riparian strip in dry conditions" },
    { value: "StripCondition", text: "Base it on the condition of strip (as described above)" },
];

const isTouched = (validation, source, id) => (validation && validation[id] && validation[id].touched) || source === undefined || source.key === id || source.id === id;

const calcBlockArea = (cp, riparianStrip) => (isNaN(cp.percentage) || isNaN(riparianStrip.catchmentArea) ? 0 : riparianStrip.catchmentArea * (cp.percentage / 100));

const RunoffPercentageCard = ({ index, runoffPercentage, blocks, usedBlocks, showRemove, removeBlock, validation, onChange }) => {
    const blockVal = validation[`${runoffPercentage.blockId}.blockId`] || {};
    return (
        <div className="Field-group" key={index}>
            <div className={"Field" + (blockVal.touched && blockVal.error ? " has-error" : "")} data-width="70">
                <label className="Select Select--block">
                    <span className="Field-label">
                        {index === 0 && "Block"}
                        {index === 0 && <sup className="required">*</sup>}
                    </span>
                    <select className="is-unpopulated" name={`${runoffPercentage.blockId}.blockId`} id={`${runoffPercentage.blockId}.blockId`} onChange={(e) => onChange(e, { type: "runoffPercentage", key: "blockId", index: index, id: `${runoffPercentage.blockId}.blockId` })} value={runoffPercentage.blockId || ""}>
                        <option value="" disabled={true}>
                            Select a block
                        </option>
                        {blocks
                            .filter((b) => !usedBlocks.includes(b.id) || b.id === runoffPercentage.blockId)
                            .map((w) => (
                                <option value={w.id} key={w.id}>
                                    {w.name}
                                </option>
                            ))}
                    </select>
                </label>
                <small className="Field-error" id={`${runoffPercentage.blockId}.blockId-error`}>
                    {blockVal.message}
                </small>
            </div>
            <NumericInputPack id={`${runoffPercentage.blockId}.percentage`} name={`${index}.percentage`} meta={{ nrf: true }} type="text" value={runoffPercentage.percentage} onChange={(e) => onChange(e, { type: "runoffPercentage", key: "percentage", index: index })} label={index === 0 && "Runoff Percentage"} requiredLabel={index === 0} val={validation[`${runoffPercentage.blockId}.percentage`]} placeholder="% in this block" decimalPlaces={0} uom="%" dataWidth="25" />

            <div className="Field" data-width="5">
                {showRemove && (
                    <div className="u-pt-md">
                        <ActionLink className="IconLink--cross-circle" onClick={() => removeBlock(index)}>
                            <span></span>
                        </ActionLink>
                    </div>
                )}
            </div>
        </div>
    )
}
