import React from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import { useModal } from "common/effects";
import NotFound from "components/NotFound";
import { Panel, PanelBody } from "components/Panel";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";
import FarmGroupInvitationResponseModal from "containers/FarmGroups/FarmGroupInvitationResponseModal";
import { leaveFarmGroup, deleteFarmGroupInvite } from "containers/FarmGroups/_actions";
import Button from "components/Button/Button";
import { useConfirm } from "common/hooks";

const Membership = ({ authContext, farmGroup, reloadFarmGroup, leaveFarmGroup, inviteMembers, deleteFarmGroupInvite }) => {
    const { openModal, getModal } = useModal();
    const [state, setState] = React.useState({ farmGroup });
    const confirm = useConfirm();

    React.useEffect(() => {
        if (farmGroup) {
            setState({ farmGroup });
        }
    }, [farmGroup]);

    if (!state.farmGroup) {
        return <NotFound />;
    }

    const isSystemAdmin = authContext.isSystemAdmin;
    const isFarmGroupAdmin = state.farmGroup.role === "Admin";
    const { showMembers, invitedFarms = [], memberFarms = [] } = state.farmGroup;
    const showMemberFarms = showMembers || isFarmGroupAdmin || isSystemAdmin;
    const showInvitedFarms = invitedFarms.length > 0 && showMemberFarms;

    const respondToInvitation = (farm) => () => {
        const { id, name, description, createdOn } = state.farmGroup;
        const farmGroup = {
            ...farm,
            id,
            name,
            description,
            createdOn,
        };
        openModal("FarmGroupInvitationResponseModal", { farmGroup }, reloadFarmGroup);
    };
    const respondToInvitationModal = getModal("FarmGroupInvitationResponseModal");

    const confirmDeleteInvite = (farm) => () => {
        confirm(`Are you sure you want to delete this invitation?`, () =>
            deleteFarmGroupInvite(state.farmGroup.id, farm.farmId)
                .then(() => reloadFarmGroup())
                .catch()
        );
    };

    const leaveGroup = (farm) => () => {
        confirm(`You are about to remove '${farm.farmName}' farm from the '${state.farmGroup.name}' farm group. Are you sure?`, () =>
            leaveFarmGroup(state.farmGroup.id, farm.farmId)
                .then(() => reloadFarmGroup())
                .catch()
        );
    };

    return (
        <>
            {!showMemberFarms && <Alert type="warning" text="Membership details are hidden from group members" />}
            {showInvitedFarms && (
                <Panel title={`Invited farms (${invitedFarms.length})`} green loading={!farmGroup}>
                    <PanelBody grey>
                        <Panel>
                            <PanelBody>
                                <SortableTable data={invitedFarms} defaultSortCriteria={{ fieldName: "invitedOn", fieldType: "date", desc: true }}>
                                    {({ data, sortCriteria, handleSort }) => {
                                        const showActions = data.some((farm) => farm.isOwner) || isFarmGroupAdmin;
                                        return (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <SortableTableHeader label="Name" fieldName="farmName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                        <SortableTableHeader label="Address" fieldName="farmAddress" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                        <SortableTableHeader label="Invited" fieldName="invitedOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                        {showActions && <th className="th--shrink"></th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((farm) => {
                                                        return (
                                                            <tr key={farm.farmId} className="hover">
                                                                <td>{farm.farmName}</td>
                                                                <td>{farm.farmAddress}</td>
                                                                <td>{utils.dateOnly(farm.invitedOn)}</td>
                                                                {showActions && (
                                                                    <td>
                                                                        <div className="u-flex">
                                                                            {farm.isOwner && (
                                                                                <ActionLink id={`respond-${farm.farmId}`} onClick={respondToInvitation(farm)} className="IconLink--edit u-link">
                                                                                    Respond
                                                                                </ActionLink>
                                                                            )}
                                                                            {isFarmGroupAdmin && (
                                                                                <ActionLink id={`delete-${farm.farmId}`} onClick={confirmDeleteInvite(farm)} className="IconLink--trash u-link">
                                                                                    Delete
                                                                                </ActionLink>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        );
                                    }}
                                </SortableTable>
                            </PanelBody>
                        </Panel>
                    </PanelBody>
                </Panel>
            )}
            {showMemberFarms && (
                <Panel title={`Member farms (${memberFarms.length})`} skyBlue loading={!farmGroup}>
                    <PanelBody grey>
                        <Panel>
                            <PanelBody>
                                <SortableTable data={memberFarms} defaultSortCriteria={{ fieldName: "invitedOn", fieldType: "date", desc: true }}>
                                    {({ data, sortCriteria, handleSort }) => {
                                        const showActions = data.some((farm) => farm.isOwner) || isFarmGroupAdmin;
                                        return (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <SortableTableHeader label="Name" fieldName="farmName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                        <SortableTableHeader label="Address" fieldName="farmAddress" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                        <SortableTableHeader label="Member since" fieldName="memberSince" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                        {showActions && <th className="th--shrink"></th>}
                                                    </tr>
                                                </thead>
                                                {data.length === 0 && (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="4">
                                                                <div className="Tile-body">
                                                                    <div className="Tile-body-message">
                                                                        <h3 className="u-mt-md">This farm group does not have any members</h3>
                                                                        {isFarmGroupAdmin && (
                                                                            <Button id="invite-members-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={() => inviteMembers(reloadFarmGroup)}>
                                                                                Invite new members
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                                {data.length > 0 && (
                                                    <tbody>
                                                        {data.map((farm) => {
                                                            return (
                                                                <tr key={farm.farmId} className="hover">
                                                                    <td>{farm.isOwner ? <Link to={`/app/farm/${farm.farmId}`}>{farm.farmName}</Link> : farm.farmName}</td>
                                                                    <td>{farm.farmAddress}</td>
                                                                    <td>{utils.dateOnly(farm.memberSince)}</td>
                                                                    {showActions && (
                                                                        <td>
                                                                            <div className="u-flex">{(farm.isOwner || isFarmGroupAdmin) && <ActionLink id={`leave-${farm.farmId}`} onClick={leaveGroup(farm)} className="IconLink--trash u-link" title="Leave group"></ActionLink>}</div>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                )}
                                            </table>
                                        );
                                    }}
                                </SortableTable>
                            </PanelBody>
                        </Panel>
                    </PanelBody>
                </Panel>
            )}
            {respondToInvitationModal && <FarmGroupInvitationResponseModal {...respondToInvitationModal.modalProps} />}
        </>
    );
};

export default compose(withAppContext, withActions({ deleteFarmGroupInvite, leaveFarmGroup }))(Membership);
