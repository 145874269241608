import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import TabMessages, { MESSAGE_CODE } from "containers/BudgetHome/TabMessages";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import BlockList from "./BlockList";
import TotalCropsList from "./TotalCropsList";
import { CropsGreenBox } from "containers/BudgetHome/Crops/CropsGreenBox";
import { Link } from "react-router-dom";
import { ZendeskLink } from "components/Help";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";
import { useConfirm, useNavigate } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function CropList({ farm, analysis }) {
    const navigate = useNavigate();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const useNewCropsGreenBox = true;

    const addPasture = () => {
        const pastureBlock = analysis.blocks.find((b) => b.type === "ProductivePasture" && b.pasture && !b.pasture.pastureCategory);
        navigate(`/app/farm/${farm.id}/analysis/${analysis.id}/crops/pasture/${pastureBlock.id}`);
    }

    const addCrop = () => {
        const cropBlock = analysis.blocks.find((b) => b.type === "ProductiveCrop" && (b.crops || []).length === 0);
        navigate(`/app/farm/${farm.id}/analysis/${analysis.id}/crops/crop/${cropBlock.id}`);
    }

    const addFruit = () => {
        const fruitBlock = analysis.blocks.find((b) => b.type === "ProductiveFruit" && !b.fruit);
        navigate(`/app/farm/${farm.id}/analysis/${analysis.id}/crops/fruit/${fruitBlock.id}`);
    }

    const deleteFodderCropRotation = (id) => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete this fodder crop rotation?`, async () => {
            utils.deleteOrphanBlockRef(analysis, id);
            analysis.blocks = analysis.blocks.filter((b) => b.id !== id);
            await updateAnalysisAsync(analysis);
            navigate(`/app/farm/${farm.id}/analysis/${analysis.id}/crops`);
        });
    }

    return (
        <>
            <Panel>
                <PageTabs farm={farm} analysis={analysis} tab={TAB.CROPS} />
                <PanelBody>
                    <TabMessages farm={farm} analysis={analysis} tab={TAB.CROPS} actions={{ [MESSAGE_CODE.PASTURE_REQUIRED]: addPasture, [MESSAGE_CODE.CROPS_REQUIRED]: addCrop, [MESSAGE_CODE.FRUIT_REQUIRED]: addFruit }} />
                    <div className="ActionsBar ActionsBar--super u-print-none">
                        <div className="ActionsBar-left">
                            <ul className="ActionsBar-links">
                                <li>
                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/crops/rotation/new`} className="IconLink--arrow-plus" id="create-effluent-link">
                                        Add fodder crop rotation
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="ActionsBar-right">
                            <ul className="ActionsBar-links">
                                {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                                    <li>
                                        <ScenarioToolLink farm={farm} analysis={analysis} />
                                    </li>
                                )}
                                <li>
                                    <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000961726" className="IconLink--new-tab u-font-green" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    {useNewCropsGreenBox
                        ? <CropsGreenBox analysis={analysis} />
                        : <TotalCropsList analysis={analysis} />
                    }
                    <BlockList farm={farm} analysis={analysis} onDelete={deleteFodderCropRotation} />
                </PanelBody>
            </Panel>
        </>
    )
}
