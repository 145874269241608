import { useState } from "react";
import { Redirect } from "react-router";
import * as utils from "common/utils";
import { useEmailTracking } from "common/effects";
import { useAuthContext, useIsOverseerSci, useIsPaidVersionOfOverseer } from "common/hooks";
import { Grid, GridCell } from "components/Grid";
import Page from "components/Page";
import PageBody from "components/PageBody";
import FarmHomePageHead from "./FarmHomePageHead";
import Analyses from "./Widgets/Analyses";
import Access from "./Widgets/Access";
import Activity from "./Widgets/Activity";
import Subscriptions from "./Widgets/Subscriptions";
import Trends from "./Widgets/Trends";
import LatestAnalysis from "./Widgets/LatestAnalysis";
import Benchmarks from "./Widgets/Benchmarks";
import FarmGroups from "./Widgets/FarmGroups";
import FertiliserRecommendations from "./Widgets/FertiliserRecommendations";

export default function FarmDashboard({ farm }) {
    const isPaidVersionOfOverseer = useIsPaidVersionOfOverseer();
    const isOverseerSci = useIsOverseerSci();
    const { canCreateFertiliserRecommendations } = useAuthContext()

    const latestAnalysisId = getLatestAnalysisId(farm);
    const [selectedAnalysisId, setSelectedAnalysisId] = useState(latestAnalysisId);

    const isGuest = farm?.role === "Guest";
    useEmailTracking("Farm dashboard opened", farm?.name);

    const canUpdateFarm = utils.canUpdateFarm(farm);

    if (!isOverseerSci && canUpdateFarm && (!farm?.latitude || !farm?.longitude)) {
        return <Redirect to={`/app/farm/${farm?.id}/settings`} />
    }

    if (!farm?.budgets || farm?.budgets?.filter((b) => b.role !== "Header").length === 0) {
        return <Redirect to={`/app/farm/${farm?.id}/analysis`} />
    }

    const breadcrumb = [{ name: farm?.displayName }];

    return (
        <Page>
            <FarmHomePageHead breadcrumb={breadcrumb} />
            <PageBody>
                <Grid>
                    <GridCell className="u-xl-width2of5 u-md-width1of1">
                        <Grid>
                            <Analyses farm={farm} className="u-width1of1" />
                            {canCreateFertiliserRecommendations && <FertiliserRecommendations farm={farm} className="u-width1of1" />}
                            {canUpdateFarm && isPaidVersionOfOverseer && <Subscriptions farm={farm} className="u-width1of1" />}
                            {!isGuest && <Trends farm={farm} className="u-width1of1" />}
                            {canUpdateFarm && isPaidVersionOfOverseer && (
                                <>
                                    <Access farm={farm} className="u-width1of1" />
                                    <FarmGroups farm={farm} className="u-width1of1" />
                                </>
                            )}
                        </Grid>
                    </GridCell>
                    <GridCell className="u-xl-width3of5 u-md-width1of1 u-pl-0 u-pr-0">
                        <LatestAnalysis farm={farm} selectedAnalysisId={selectedAnalysisId} onSelectedAnalysisChange={setSelectedAnalysisId} className="u-width1of1" />
                        {canUpdateFarm && (
                            <>
                                {isPaidVersionOfOverseer && <Benchmarks farm={farm} selectedAnalysisId={selectedAnalysisId} onSelectedAnalysisChange={setSelectedAnalysisId} className="u-width1of1" />}
                                <Activity farm={farm} className="u-width1of1" />
                            </>
                        )}
                    </GridCell>
                </Grid>
            </PageBody>
        </Page>
    )
}

function getLatestAnalysisId(farm) {
    const yearEndBudgets = (farm?.budgets && farm?.budgets?.filter((b) => b.class === "Budget" && (b.type === "YearEnd" || b.type === "PrivateYearEnd") && b.role === "Write").sort((a, b) => b.year - a.year)) || [];
    const yearEndPublications = (farm?.budgets && farm?.budgets?.filter((b) => b.class === "Budget" && (b.type === "YearEnd" || b.type === "PrivateYearEnd") && b.role === "Write").sort((a, b) => b.year - a.year)) || [];

    let latestAnalysis = yearEndBudgets?.length > 0 ? yearEndBudgets[0] : yearEndPublications?.length > 0 && yearEndPublications;
    if (!latestAnalysis && farm?.budgets?.length > 0) {
        latestAnalysis = farm.budgets.find((b) => b.role === "Write");
    }

    return latestAnalysis?.id;
}
