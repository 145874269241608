const memoize = (fn) => {
    const cache = {};
    return (...args) => {
        const stringifiedArgs = JSON.stringify(args);
        const result = (cache[stringifiedArgs] = cache[stringifiedArgs] || fn(...args));
        return result;
    };
};

// NOTE TO DEVS. THIS IS EXTREMELY IMPORTANT!
// redux-form (since v7) will re- register any field that uses a curried function as an inline validate argument UNLESS IT IS MEMOIZED!
// this can cause infinite loop scenarios SO YOU MUST use the memoize function above to wrap any curried function you add below

export const required = (value) => (value && (String(value).trim().length > 0 || value === 0) && value !== "Undefined" ? undefined : "Required");

export const requiredExt = (value) => (value !== undefined && String(value).trim().length > 0 && value !== "Undefined" ? undefined : "Required");

export const valueNotEmpty = (value) => (value && String(value).length > 0 && String(value).trim().length > 0 ? undefined : "Required");

export const maxLength = memoize((max) => (value) => value && String(value).length > max ? `Must be ${max} character${max > 1 ? "s" : ""} or less` : undefined);

export const minLength = memoize((min) => (value) => value && String(value).length < min ? `Must be ${min} character${min > 1 ? "s" : ""} or more` : undefined);

export const minValue = memoize((min) => (value) => (value || value === 0) && value < min ? `Must be ${min} or more` : undefined);

export const maxValue = memoize((max) => (value) => (value || value === 0) && value > max ? `Must be ${max} or less` : undefined);

export const range = memoize((min, max) => (value) => (value || value === 0) && (value < min || value > max) ? `Must be between ${min} and ${max}` : undefined);

export const rangeShort = (min, max) => (value) => (value || value === 0) && (value < min || value > max) ? `${min} - ${max}` : undefined;

export const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? "Invalid email address" : undefined);

export const emailExt = (value) => (email(value) ? `Invalid email address: ${value}` : undefined);

export const alphanumeric = (value) => (/^[A-Za-z0-9\s()\-=_./\\]*$/.test(value) ? undefined : "Invalid identifier. Can only contain alphanumeric characters, spaces and ()-=_./\\");

export const requiredInt = (value) => (!value || value <= 0 ? `Required` : undefined);

export const isNumeric = (value) => (isNaN(value) ? "Must be numeric" : undefined);

export const nameRegex = (value) => (/^[a-zA-Z0-9\s@&'()+:,."\-/]+$/.test(value) ? undefined : "Invalid. Can only contain alphanumeric characters, spaces and @&'()+:,.-\"/");
