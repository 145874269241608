import Button from "components/Button/Button";
import { useIsPaidVersionOfOverseer } from "common/hooks";
import * as utils from "common/utils";
import { usePaymentModal } from "containers/Payments/PaymentModal";

export default function RenewSubscriptionButton({ farm, onPaid }) {
    const isPaidVersionOfOverseer = useIsPaidVersionOfOverseer();
    const [paymentModal, openPaymentModal] = usePaymentModal();

    const pay = () => {
        openPaymentModal([farm], onPaid)
    }

    const canPay = isPaidVersionOfOverseer && (farm.expired || !farm.expirationDate) && utils.canMakePayment(farm);


    return (
        <>
            {canPay
                ? (
                    <Button id={`renew-sub-${farm.id}`} className="Button Button--sm Button--alert u-print-none" onClick={pay}>
                        <i className="icon icon--before icon-dollar" />
                        <span>Pay for subscription</span>
                    </Button>
                )
                : undefined
            }
            {paymentModal}
        </>
    )
}
