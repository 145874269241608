import React, { Component } from "react";
import { Field } from "react-final-form";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack2";
import * as domain from "common/domain";
import * as utils from "common/utils";
import FieldGroup from "components/FieldGroup";
import CheckboxPack from "components/CheckboxPack";
import TileSubPanel from "components/TileSubPanel";
import * as FormUtils from "common/FormUtils";
import ActionLink from "components/ActionLink";

class AnalysisBlock extends Component {
    render() {
        const { startMonth, analysis, modelledResults, blockIndex, block, refData, deleteBlock, allowDelete, addSoil, editSoil, deleteSoil, errors, keyOrder = {}, showFormErrors } = this.props;
        const { soils = [], farmSoilBlocks = [] } = analysis;
        const farmSoilBlock = farmSoilBlocks.find((fsb) => fsb.blockId === block.id);
        const soil = farmSoilBlock ? soils.find((s) => s.id === farmSoilBlock.soilId) : undefined;
        const { rainDailyPattern = [], pastureTypes = [] } = refData;
        const { formState = {} } = block;
        const { annualClimate, annualNUptake } = formState;

        const blockRef = `analysis.blocks[${blockIndex}]`;
        const otherResults = modelledResults ? modelledResults[block.id] : block.otherResults;

        const orderedValues = (prefix, decPlaces = 0) =>
            domain.calendarYear.map((c, i) => {
                const startIndx = domain.calendarYear.indexOf(startMonth) + i;
                const indx = startIndx > 11 ? startIndx - 12 : startIndx;
                return (
                    <td key={i}>
                        <Field name={`${prefix}[${indx}].value`} component={InputPack} format={FormUtils.formatters.formatDecimal(decPlaces)} formatOnBlur />
                    </td>
                );
            });

        const orderedResults = (results) =>
            domain.calendarYear.map((c, i) => {
                const startIndx = domain.calendarYear.indexOf(startMonth) + i;
                const indx = startIndx > 11 ? startIndx - 12 : startIndx;
                return (
                    <td className="u-textRight" key={i}>
                        {results[domain.calendarYear[indx]] ? results[domain.calendarYear[indx]].toFixed(2) : "-"}
                    </td>
                );
            });

        const calendarYearOptions = domain.calendarYear.map((c) => ({ value: c, text: c }));
        const orderedMonths = domain.calendarYear.map((c, i) => {
            const startIndx = domain.calendarYear.indexOf(startMonth) + i;
            const indx = startIndx > 11 ? startIndx - 12 : startIndx;
            return <th key={i}>{domain.calendarYear[indx].substring(0, 3)}</th>;
        });

        const orderedMonthsLeft = domain.calendarYear.map((c, i) => {
            const startIndx = domain.calendarYear.indexOf(startMonth) + i;
            const indx = startIndx > 11 ? startIndx - 12 : startIndx;
            return (
                <th className="u-textRight" key={i}>
                    {domain.calendarYear[indx].substring(0, 3)}
                </th>
            );
        });

        const formatSoil = (soil) => {
            if (!soil) return null;
            const soilProfile = [];
            const topSoil = [];
            const lowerProfile = [];
            const description = soil.sibling && soil.sibling.description && soil.sibling.description.length > 0 ? soil.sibling.description : undefined;

            if (soil.profileDrainageClass && soil.profileDrainageClass !== "Usedefault") {
                soilProfile.push(`Drainage class: ${utils.valueToText(refData.drainageClasses, soil.profileDrainageClass)}`);
            }
            if (soil.isStony) {
                topSoil.push(`Stony: Yes`);
            }
            if (soil.topSoilTexture && soil.topSoilTexture !== "Undefined") {
                topSoil.push(`Topsoil texture: ${utils.valueToText(refData.soilTextures, soil.topSoilTexture)}`);
            }
            if (soil.bottomRootingDepth) {
                lowerProfile.push(`Maximum rooting depth: ${soil.bottomRootingDepth}cm`);
            }
            if (soil.impededLayerDepth) {
                lowerProfile.push(`Impeded layer depth: ${soil.impededLayerDepth}cm`);
            }
            if (soil.parentMaterial && soil.parentMaterial !== "Undefined") {
                lowerProfile.push(`Non-standard layer: ${utils.valueToText(refData.parentMaterials, soil.parentMaterial)}`);
            }
            if (soil.nonStandardLayerDepth) {
                lowerProfile.push(`Non-standard layer depth: ${soil.nonStandardLayerDepth}cm`);
            }

            return (
                <div className="Table u-mt-xl">
                    <table>
                        <thead>
                            <tr>
                                <th>Reference</th>
                                {soil.soilGroup ? <th>Soil Group</th> : null}
                                {soil.soilOrder && soil.soilOrder !== "Undefined" ? <th>Soil Order</th> : null}
                                {soilProfile.length > 0 ? <th>Soil Profile</th> : null}
                                {topSoil.length > 0 ? <th>Topsoil (0-10 cm)</th> : null}
                                {lowerProfile.length > 0 ? <th>Lower profile</th> : null}
                                {!description || description.length === 0 ? null : <th>Description</th>}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{soil.sibling ? soil.sibling.smapReference : "-"}</td>
                                {soil.soilGroup ? <td>{utils.valueToText(refData.soilGroups, soil.soilGroup)}</td> : null}
                                {soil.soilOrder && soil.soilOrder !== "Undefined" ? <td>{soil.soilOrder}</td> : null}
                                {soilProfile.length > 0 ? (
                                    <td>
                                        {soilProfile.map((d, i) => (
                                            <div key={i}>{d}</div>
                                        ))}
                                    </td>
                                ) : null}
                                {topSoil.length > 0 ? (
                                    <td>
                                        {topSoil.map((d, i) => (
                                            <div key={i}>{d}</div>
                                        ))}
                                    </td>
                                ) : null}
                                {lowerProfile.length > 0 ? (
                                    <td>
                                        {lowerProfile.map((d, i) => (
                                            <div key={i}>{d}</div>
                                        ))}
                                    </td>
                                ) : null}
                                {!description || description.length === 0 ? null : <td>{description}</td>}
                                <td className="td--shrink u-textCenter">
                                    <div className="u-flex">
                                        <ActionLink onClick={() => editSoil(soil)} className="IconLink--edit">
                                            <span>edit</span>
                                        </ActionLink>
                                        <ActionLink onClick={() => deleteSoil(block.id)} className="IconLink--cross-circle u-ml-sm">
                                            <span>delete</span>
                                        </ActionLink>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        };

        const rainFallError = showFormErrors && errors && errors.analysis.blocks.length > blockIndex && errors.analysis.blocks[blockIndex] && errors.analysis.blocks[blockIndex].rainFallError && errors.analysis.blocks[blockIndex].rainFallError;

        const petError = showFormErrors && errors && errors.analysis.blocks.length > blockIndex && errors.analysis.blocks[blockIndex] && errors.analysis.blocks[blockIndex].petError && errors.analysis.blocks[blockIndex].petError;

        const { enterpriseUrineReportKeys = [], OtherValueKeys = [] } = keyOrder;

        return (
            <React.Fragment>
                <div className="Grid u-pb-xl u-ml-sm">
                    <div className="Grid-cell u-lg-width1of1 u-xl-width1of2">
                        <Field name={`${blockRef}.name`} label="Block name" component={InputPack} type="text" placeholder="Block Name" requiredLabel={true} />
                    </div>
                    {allowDelete && (
                        <div className="Grid-cell u-lg-width1of1 u-xl-width1of2">
                            <div className="u-mt-lg u-mr-sm u-textRight">
                                <ActionLink className="Button Button--secondary" onClick={() => deleteBlock(blockIndex)}>
                                    Delete block
                                </ActionLink>
                            </div>
                        </div>
                    )}
                </div>

                <TileSubPanel title="Lysimeter">
                    <div className="FarmTable_wrapper">
                        <div className="FarmTable-supplementary">
                            <div className="Grid">
                                <div className="Grid-cell">
                                    <FieldGroup>
                                        <Field name={`${blockRef}.scienceInputs.startMonth`} label="experiment starts in" component={SelectPack} required={true} options={calendarYearOptions} dataWidth="25" />
                                        <Field name={`${blockRef}.scienceInputs.urineNAdded`} label="Urine N added" component={InputPack} required={false} dataWidth="25" format={FormUtils.formatters.formatDecimal(0)} formatOnBlur />
                                        <Field name={`${blockRef}.scienceInputs.kSaturationMM`} label="Ksat" component={InputPack} required={false} dataWidth="25" uom="mm/day" format={FormUtils.formatters.formatDecimal(0)} formatOnBlur />
                                        <Field name={`${blockRef}.scienceInputs.lysimeterDepthCM`} label="Lysimeter depth" component={InputPack} required={false} dataWidth="25" uom="cm" format={FormUtils.formatters.formatDecimal(0)} formatOnBlur />
                                    </FieldGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </TileSubPanel>

                <TileSubPanel title="Climate">
                    <div className="FarmTable_wrapper">
                        <div className="FarmTable-supplementary">
                            <div className="Grid u-mb-lg">
                                <div className="Grid-cell">
                                    <FieldGroup>
                                        <Field name={`${blockRef}.climate.rainfallRangeSeasonalityPattern`} label="Daily rainfall pattern" component={SelectPack} required={true} options={rainDailyPattern} dataWidth="25" />
                                    </FieldGroup>
                                    <FieldGroup>
                                        <Field name={`${blockRef}.formState.annualClimate`} label="Enter annual climate data (will reset monthly)" component={CheckboxPack} />
                                    </FieldGroup>
                                    {annualClimate && (
                                        <FieldGroup>
                                            <Field name={`${blockRef}.climate.averageRain`} label="Average rainfall" component={InputPack} requiredLabel={true} disabled={!annualClimate} dataWidth="25" uom="mm/yr" format={FormUtils.formatters.formatDecimal(0)} formatOnBlur />
                                            <Field name={`${blockRef}.climate.averageTemp`} label="Mean annual temp" component={InputPack} disabled={!annualClimate} dataWidth="25" uom="c" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur />
                                            <Field name={`${blockRef}.climate.annualPetInMM`} label="PET" component={InputPack} disabled={!annualClimate} dataWidth="25" uom="mm/yr" format={FormUtils.formatters.formatDecimal(0)} formatOnBlur />
                                        </FieldGroup>
                                    )}
                                </div>
                            </div>
                            {!annualClimate && (
                                <div className="Grid">
                                    <div className="Grid-cell u-mt-lg">
                                        <div className="Table">
                                            <table className="u-tbl-fix">
                                                <thead>
                                                    <tr>
                                                        <th colSpan="2"></th>
                                                        {orderedMonths}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="2">Rainfall (mm)</td>
                                                        {orderedValues(`${blockRef}.climate.regionalData.rainFall`, 1)}
                                                    </tr>
                                                    {rainFallError && (
                                                        <tr className="Alert--error">
                                                            <td colSpan="14" className="u-textCenter">
                                                                <span className="u-textError">{rainFallError}</span>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td colSpan="2">Temperature</td>
                                                        {orderedValues(`${blockRef}.climate.regionalData.temperature`, 1)}
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2">PET</td>
                                                        {orderedValues(`${blockRef}.climate.regionalData.pet`, 1)}
                                                    </tr>
                                                    {petError && (
                                                        <tr className="Alert--error">
                                                            <td colSpan="14" className="u-textCenter">
                                                                <span className="u-textError">{petError}</span>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </TileSubPanel>

                <TileSubPanel title="Soil &amp; Drainage">
                    <div className="FarmTable_wrapper">
                        <div className="FarmTable-supplementary">
                            <div className="Grid">
                                <div className="Grid-cell">
                                    <Field name="analysis.farmSoilBlocks">
                                        {({ meta }) => (
                                            <React.Fragment>
                                                {soil && formatSoil(soil)}
                                                {!soil && (
                                                    <div className="u-mt-lg Field has-error">
                                                        <ActionLink className="Button Button--secondary" onClick={() => addSoil(block.id)}>
                                                            Add soil
                                                        </ActionLink>
                                                        {meta.touched && meta.error && (
                                                            <div>
                                                                <small className="Field-error">{meta.error}</small>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Field>
                                </div>

                                <div className="Grid-cell u-mt-lg">
                                    <div className="Table">
                                        <table className="u-tbl-fix">
                                            <thead>
                                                <tr>
                                                    <th colSpan="2"></th>
                                                    {orderedMonths}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2">Drainage - mm</td>
                                                    {orderedValues(`${blockRef}.scienceInputs.drainageMM`)}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TileSubPanel>

                <TileSubPanel title="N uptake">
                    <div className="FarmTable_wrapper">
                        <div className="FarmTable-supplementary">
                            <div className="Grid">
                                {block.type === "ProductivePasture" && (
                                    <div className="Grid-cell">
                                        <Field name={`${blockRef}.pasture.pastureCategory`} label="Pasture type" placeholder="Select a pasture type" component={SelectPack} required={true} options={pastureTypes} dataWidth="25" />
                                    </div>
                                )}
                                <div className="Grid-cell">
                                    <Field name={`${blockRef}.formState.annualNUptake`} label="Set base N Uptake (will reset monthly)" component={CheckboxPack} />
                                </div>
                                {annualNUptake && (
                                    <div className="Grid-cell">
                                        <Field name={`${blockRef}.scienceInputs.baseNUptakeKG`} label="N uptake" placeholder="Defaults to 40" component={InputPack} required={false} dataWidth="25" format={FormUtils.formatters.formatDecimal(0)} formatOnBlur />
                                    </div>
                                )}
                                <div className="Grid-cell u-mt-lg">
                                    {!annualNUptake && (
                                        <div className="Table">
                                            <table className="u-tbl-fix">
                                                <thead>
                                                    <tr>
                                                        <th colSpan="2"></th>
                                                        {orderedMonths}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="2">N uptake - kg</td>
                                                        {orderedValues(`${blockRef}.scienceInputs.nUptakeKG`)}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </TileSubPanel>

                <TileSubPanel title="N added">
                    <div className="FarmTable_wrapper">
                        <div className="FarmTable-supplementary">
                            <div className="Grid">
                                <div className="Grid-cell u-mt-lg">
                                    <div className="Table">
                                        <table className="u-tbl-fix">
                                            <thead>
                                                <tr>
                                                    <th colSpan="2"></th>
                                                    {orderedMonths}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2">N fertiliser - kg</td>
                                                    {orderedValues(`${blockRef}.scienceInputs.nFertiliserKG`)}
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">N effluent - kg</td>
                                                    {orderedValues(`${blockRef}.scienceInputs.nEffluentKG`)}
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">Irrigation - mm</td>
                                                    {orderedValues(`${blockRef}.scienceInputs.irrigationMM`)}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TileSubPanel>

                {otherResults && otherResults.length > 0 && (
                    <TileSubPanel title="Results">
                        <div className="FarmTable_wrapper">
                            <div className="FarmTable-supplementary">
                                <div className="Grid">
                                    <div className="Grid-cell u-mt-lg">
                                        {otherResults.map(
                                            (r, i) =>
                                                r.enterpriseUrineReports && (
                                                    <React.Fragment key={i}>
                                                        <h3>{(r.runType === "Measured" ? "Using entered data (v" : "Using modelled data (v") + r.version + ")"} </h3>
                                                        {r.messages && r.messages.length > 0 && (
                                                            <div className="Table">
                                                                <table className="u-tbl-fix">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Error code</th>
                                                                            <th>Message</th>
                                                                            <th>Data</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {r.messages.map((m) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{m.code}</td>
                                                                                    <td>{m.text}</td>
                                                                                    <td>{m.data}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}
                                                        <div className="Table">
                                                            <table className="u-tbl-fix">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="2"></th>
                                                                        {orderedMonthsLeft}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(r.enterpriseUrineReports)
                                                                        .sort((a, b) => a - b)
                                                                        .map((e) =>
                                                                            Object.keys(r.enterpriseUrineReports[e])
                                                                                .sort((a, b) => (enterpriseUrineReportKeys.length > 0 ? enterpriseUrineReportKeys.indexOf(a) - enterpriseUrineReportKeys.indexOf(b) : a - b))
                                                                                .map((k, j) => (
                                                                                    <tr key={j}>
                                                                                        <th colSpan="2">{r.enterpriseUrineReports[e][k].label}</th>
                                                                                        {orderedResults(r.enterpriseUrineReports[e][k].results)}
                                                                                    </tr>
                                                                                ))
                                                                        )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TileSubPanel>
                )}

                {otherResults && otherResults.length > 0 && (
                    <TileSubPanel title="Other Results">
                        <div className="FarmTable_wrapper">
                            <div className="FarmTable-supplementary">
                                <div className="Grid">
                                    <div className="Grid-cell u-mt-lg">
                                        <div className="Table">
                                            <table className="u-tbl-fix">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        {otherResults.map((r, i) => (
                                                            <th className="u-textRight" key={i}>
                                                                {r.runType}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.keys(otherResults[0].otherValues)
                                                        .sort((a, b) => (OtherValueKeys.length > 0 ? OtherValueKeys.indexOf(a) - OtherValueKeys.indexOf(b) : a - b))
                                                        .map((k, j) => (
                                                            <tr key={"other-value" + j}>
                                                                <th>{otherResults[0].otherValues[k].name}</th>
                                                                {otherResults.map((r, i) => (
                                                                    <td className="u-textRight" key={"other-value-number-" + i}>
                                                                        {r.otherValues[k] ? r.otherValues[k].number.toFixed(2) : "-"}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TileSubPanel>
                )}
            </React.Fragment>
        );
    }
}

export default AnalysisBlock;
