import React from "react";
import { connect } from "react-redux";
import { Field, change } from "redux-form";
import * as normalizers from "common/normalizers";
import * as validations from "common/validations";
import * as utils from "common/utils";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack2";
import MonthDayInputPack from "components/MonthDayInputPack";
import ActionLink from "components/ActionLink";

class LivestockEvent extends React.Component {
    constructor(props) {
        super(props);
        const state = this._initState(props);
        this.state = { ...state };
    }

    componentWillReceiveProps(props) {
        const state = this._initState(props);
        this.setState({ ...state });
        if (!props.showAgeAndWeightFields) {
            props.change("modal-form", `events[${this.props.index}].weightType`, undefined);
            props.change("modal-form", `events[${this.props.index}].weightKg`, undefined);
            props.change("modal-form", `events[${this.props.index}].ageInMonths`, undefined);
        }
    }

    _initState = (props) => {
        const { fields, index } = props;
        const event = fields.get(index);
        const minDate = this._getMinDate(index, event.eventDate);
        const minDateIndex = this._getMinDateIndex(index, event.eventDate);
        const showWeightType = this._showWeightTypeField(event.eventType);
        return {
            showWeight: this._showWeightFieldForEventType(event.eventType, minDateIndex) && (this._showWeightFieldForWeightType(event.weightType) || !showWeightType),
            showWeightType: showWeightType,
            showAge: this._showAgeField(event.eventType, minDateIndex),
            disableEventDate: event.eventType === "Starting",
            weightType: event.weightType,
            eventType: event.eventType,
            minDate,
            minDateIndex,
        };
    };

    _eventTypeChanged = (e, newValue, ev) => {
        const showWeightType = this._showWeightTypeField(newValue);
        const showWeight = this._showWeightFieldForEventType(newValue, this.state.minDateIndex) && (this._showWeightFieldForWeightType(this.state.weightType) || !showWeightType);
        const showAge = this._showAgeField(newValue, this.state.minDateIndex);
        this.setState({
            eventType: newValue,
            showWeightType,
            showWeight,
            showAge,
        });
        this._toggleStartingDate(newValue);
        this._calculateStockCounts(newValue, newValue === "Starting" ? "01 July" : null, -1);
        if ((this.state.currentDate === "01 July" || this.state.currentDate === "1 July") && ["Sale", "SaleToWorks"].includes(newValue)) {
            this.props.change("modal-form", `events[${this.props.index}].eventDate`, "02 July");
        }
    };

    _getMinDateIndex = (index, eventDate) => {
        const minDate = this._getMinDate(index, eventDate);
        const events = this.props.fields.getAll();
        const minDateIndex = events.findIndex((event) => {
            const date = utils.parseDate(event.eventDate, "DD MMMM");
            return ["Starting", "Weaning", "Purchase"].includes(event.eventType) && date.isSame(minDate);
        });
        return minDateIndex > -1 ? minDateIndex : index;
    };

    _getMinDate = (index, eventDate) => {
        const newDate = utils.parseDate(eventDate, "DD MMMM");
        const events = this.props.fields.getAll();
        const minDate = events.reduce((resultDate, event, i) => {
            if (["Starting", "Weaning", "Purchase"].includes(event.eventType)) {
                let eventDate = utils.parseDate(event.eventDate, "DD MMMM");
                if (i === index) eventDate = newDate;
                if (eventDate.isBefore(resultDate)) {
                    resultDate = eventDate;
                }
            }
            return resultDate;
        }, newDate);
        return minDate;
    };

    _eventDateChanged = (index) => (e, newValue) => {
        const event = this.props.fields.get(index);
        const minDate = this._getMinDate(index, newValue);
        const minDateIndex = this._getMinDateIndex(index, newValue);
        const showWeight = this._showWeightFieldForEventType(event.eventType, minDateIndex) && (this._showWeightFieldForWeightType(this.state.weightType) || !this.state.showWeightType);
        const showAge = this._showAgeField(event.eventType, minDateIndex);
        this.setState({ showWeight, showAge, minDate, minDateIndex, currentDate: newValue });
        this._calculateStockCounts(null, newValue, -1);
    };

    _numberAnimalsChanged = (e, newValue) => {
        this._calculateStockCounts(null, null, newValue || 0);
    };

    _weightTypeChanged = (e, newValue) => {
        const showWeight = this._showWeightFieldForEventType(this.state.eventType, this.state.minDateIndex) && (this._showWeightFieldForWeightType(newValue) || !this.state.showWeightType);
        this.setState({ weightType: newValue, showWeight });
    };

    _calculateStockCounts = (eventType, eventDate, numberAnimals) => {
        const { fields, index, calculateStockCounts } = this.props;
        const events = fields.getAll().map((event, i) => {
            if (index !== i) return event;

            return {
                ...event,
                eventType: eventType ? eventType : event.eventType || "Starting",
                eventDate: eventDate ? eventDate : event.eventDate || "01 July",
                numberAnimals: numberAnimals >= 0 ? numberAnimals : event.numberAnimals || 0,
            };
        });
        calculateStockCounts(events);
    };

    _stockClassRequiresSaleWeight = (eventType) => {
        if (!["Sale", "SaleToWorks"].includes(eventType)) return false;

        switch (this.props.enterpriseType) {
            case "DairyReplacements":
                return ["CalvesWeanedMixedSex"].includes(this.props.stockClass);
            case "Sheep":
                return ["Lambs", "EwesAndHoggets", "Wethers", "Rams"].includes(this.props.stockClass);
            case "Beef":
                return ["Weaners", "HeifersAndCows", "Steers", "Bulls", "DairyReplacements"].includes(this.props.stockClass);
            case "DairyGrazing":
                return ["DairyReplacements"].includes(this.props.stockClass);
            case "Deer":
                return ["Weaners", "Hinds", "Stags"].includes(this.props.stockClass);
            default:
                return false;
        }
    };

    _noAgeOrWeightOnStarting = () => this.props.enterpriseType === "DairyReplacements" && this.props.stockClass === "CalvesWeanedMixedSex" && this.props.index === 0;

    _showWeightFieldForEventType = (eventType, minDateIndex = 0) => {
        return (this._stockClassRequiresSaleWeight(eventType) || ["Starting", "Weaning"].includes(eventType) || (eventType === "Purchase" && this.props.index === minDateIndex)) && !this._noAgeOrWeightOnStarting();
    };

    _showWeightFieldForWeightType = (weightType) => {
        return ["Carcass", "Live"].includes(weightType);
    };

    _showWeightTypeField = (eventType) => {
        return this._stockClassRequiresSaleWeight(eventType);
    };

    _showAgeField = (eventType, minDateIndex = 0) => {
        const eventTypes = ["Starting"];
        return (eventTypes.includes(eventType) || (eventType === "Purchase" && this.props.index === minDateIndex)) && !this._noAgeOrWeightOnStarting();
    };

    _toggleStartingDate = (eventType) => {
        const disabled = eventType === "Starting";
        this.setState({ disableEventDate: disabled });
        if (disabled) {
            this.props.change("modal-form", "events[0].eventDate", "01 July");
        }
    };

    _remove = (index) => (e) => {
        this.props.remove(e, index);
        const { fields, calculateStockCounts } = this.props;
        const allFields = fields.getAll();
        const events = allFields.filter((f) => allFields.indexOf(f) !== index);
        calculateStockCounts(events);
    };

    render() {
        const { event, index, refData, showAgeAndWeightFields, primaryEventTypes, secondaryEventTypes, fields } = this.props;
        const ev = fields.get(index);
        const secondaryOptions = Number(ev.day) === 1 && ev.month === "July" ? secondaryEventTypes.filter((et) => !["Starting", "Weaning", "SaleToWorks", "Sale"].includes(et.value)) : secondaryEventTypes.filter((et) => !["Starting", "Weaning"].includes(et.value));
        const maxMonths = this.props.enterpriseType === "DairyReplacements" && this.props.stockClass === "HeiferReplacements" ? 36 : 60;
        const ageOptions = [{ value: 1, text: "1 month" }];
        for (var i = 2; i <= maxMonths; i++) {
            ageOptions.push({ value: i, text: `${i} months` });
        }
        const minDate = ["Sale", "SaleToWorks"].includes(ev.eventType) ? "20130702" : undefined;

        return (
            <tr key={index} className="hover">
                <td>
                    {index === 0 && <Field name={`${event}.eventType`} noLabel={true} placeholder="Select type" options={primaryEventTypes} onChange={(e, newValue) => this._eventTypeChanged(e, newValue, ev)} required={true} validate={[validations.required]} component={SelectPack} />}
                    {index > 0 && <Field name={`${event}.eventType`} noLabel={true} placeholder="Select type" options={secondaryOptions} onChange={(e, newValue) => this._eventTypeChanged(e, newValue, ev)} required={true} validate={[validations.required]} component={SelectPack} />}
                </td>
                <td>
                    <Field name={`${event}.eventDate`} placeholder="Select date" onChange={this._eventDateChanged(index)} disabled={this.state.disableEventDate} validate={[validations.required]} placement="top" minDate={minDate} component={MonthDayInputPack} />
                </td>
                <td>
                    <Field name={`${event}.numberAnimals`} noLabel={true} placeholder="0" onChange={this._numberAnimalsChanged} type="text" normalize={normalizers.integer} requiredLabel={true} component={InputPack} validate={[validations.required, validations.range(1, 30000)]} />
                </td>
                <td>
                    {showAgeAndWeightFields && this.state.showWeightType && <Field name={`${event}.weightType`} noLabel={true} options={refData.weightTypes} required={true} validate={[validations.required]} component={SelectPack} onChange={this._weightTypeChanged} />}
                    {(!showAgeAndWeightFields || !this.state.showWeightType) && <span>-</span>}
                </td>
                <td>
                    {showAgeAndWeightFields && this.state.showWeight && <Field name={`${event}.weightKg`} uom="kg" noLabel={true} placeholder="0" type="text" normalize={normalizers.integer} component={InputPack} validate={[validations.maxValue(10000)]} />}
                    {(!showAgeAndWeightFields || !this.state.showWeight) && <span>-</span>}
                </td>

                <td>
                    {showAgeAndWeightFields && this.state.showAge && <Field name={`${event}.ageInMonths`} uom="months" noLabel={true} placeholder="Select age" placeholderEnabled={true} options={ageOptions} required={true} component={SelectPack} />}
                    {(!showAgeAndWeightFields || !this.state.showAge) && <span>-</span>}
                </td>
                <td>
                    {
                        <ActionLink id={`remove-event-${index}`} className="IconLink--trash" onClick={this._remove(index)}>
                            <span></span>
                        </ActionLink>
                    }
                </td>
            </tr>
        );
    }
}

export default connect(null, { change })(LivestockEvent);
