import { Component } from "react";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import arrayMutators from "final-form-arrays";
import * as FormUtils from "common/FormUtils";
import SavePrompt from "components/SavePrompt";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import Alert from "components/Alert";
import { Grid, GridCell } from "components/Grid";
import InputPack from "components/InputPack";
import { getOutdoorPigHutsViewModel, requiresOutdoorPigEffluentSystem } from "common/viewModels";
import OutdoorPigHutsStrawDisposals from "./OutdoorPigHutsStrawDisposals";
import * as structureUtils from "./_utils";
import { useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function OutdoorPigHuts({ farm, analysis }) {
    const refData = useRefData();
    const viewModel = getOutdoorPigHutsViewModel(analysis);
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    return <OutdoorPigHutsClassComponent farm={farm} analysis={analysis} viewModel={viewModel} updateAnalysisAsync={updateAnalysisAsync} refData={refData} />
}

class OutdoorPigHutsClassComponent extends Component {
    constructor(props) {
        super(props);
        const { viewModel } = props;
        this.state = {
            viewModel,
        };
    }

    validate = (viewModel) => {
        const error = {};

        if (viewModel.strawPerSow <= 0) error.strawPerSow = FormUtils.validators.range(1, 9999)(viewModel.strawPerSow);

        return error;
    };

    submit = async (viewModel) => {
        // Map view model back to dto.
        const structure = {
            ...viewModel,
        };

        structure.strawDisposals.forEach((straw) => {
            const showCompostDisposal = ["Spreadonselectedblocks", "Otherexported"].includes(straw.strawDisposal);
            if (!showCompostDisposal) {
                delete straw.compostDisposal;
            }

            const showTimeInStorage = showCompostDisposal && ["Coveredfromrain", "Opentorain"].includes(straw.compostDisposal);
            if (!showTimeInStorage) {
                delete straw.timeInStorage;
            }
        });

        const updatedAnalysis = structureUtils.getUpdatedAnalysisFromSavingStructure(this.props.analysis, structure);
        await this.props.updateAnalysisAsync(updatedAnalysis);
    };

    render() {
        const { farm, analysis } = this.props;
        const baseRedirectUrl = `/app/farm/${farm.id}/analysis/${analysis.id}/structures`;

        return (
            <Form
                initialValues={this.state.viewModel}
                validate={this.validate}
                onSubmit={this.submit}
                mutators={{
                    setProperty: (args, state, utils) => {
                        utils.changeValue(state, args[0], () => args[1]);
                    },
                    ...arrayMutators,
                }}
            >
                {({ form, values, handleSubmit, dirty, submitSucceeded }) => {
                    const disableSave = false;
                    const redirectOnSaveTo = values.manageEffluent ? `${baseRedirectUrl}/outdoorPigEffluent` : baseRedirectUrl;

                    const enableManageEffluentButton = requiresOutdoorPigEffluentSystem(values);

                    return (
                        <form onSubmit={handleSubmit}>
                            {true && <SavePrompt blockIf={dirty && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={redirectOnSaveTo} />}
                            <Panel title="Outdoor pig huts" referrer={baseRedirectUrl}>
                                <Alert className="u-mb-sm" type="info" text="Describes the management of straw bedding from huts." />
                                <PanelBody>
                                    <Grid title="Straw on huts">
                                        <GridCell className="u-width1of4">
                                            <Field name="strawPerSow" label="Straw per sow in huts" placeholder={values.strawPerSowDefault} uom="kg/sow/yr" type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                        </GridCell>
                                    </Grid>
                                    <OutdoorPigHutsStrawDisposals form={form} structure={values} />
                                </PanelBody>
                                <PanelFooter>
                                    <div className="ButtonBar ButtonBar--fixed">
                                        <div className="ButtonBar-left">
                                            <Link to={baseRedirectUrl} id="cancel-button" className="Button Button--secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="ButtonBar-right">
                                            <button type="submit" onClick={(e) => form.change("manageEffluent", true)} disabled={!enableManageEffluentButton} id="manage-button" className="Button Button--tertiary">
                                                Save and manage effluent
                                            </button>
                                            <button type="submit" onClick={(e) => form.change("manageEffluent", false)} id="save-button" className="Button Button--primary" disabled={disableSave}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </PanelFooter>
                            </Panel>
                        </form>
                    );
                }}
            </Form>
        )
    }
}
