import React from "react";

const Loader = ({ message }) => {
    return (
        <div id="loader-overlay" className="Overlay">
            <div className="Loader">
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </div>
            <h3 className="u-textWhite u-mb-0">{message}</h3>
        </div>
    );
};
export default Loader;
