import { useState } from "react";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import { ForMoreInfoZendeskLink } from "components/Help";
import { useAnalysisResults } from "containers/BudgetHome";
import StockNumbers from "./StockNumbers";
import RsuFromPastureByBlock from "./RsuFromPastureByBlock";
import Diet from "./Diet";

export default function PastureAndDiet({ analysis }) {
    const { data: analysisResults } = useAnalysisResults(analysis);
    const [enterpriseId, setEnterpriseId] = useState(analysis?.enterprises?.[0]?.id);

    if (!analysisResults?.animals) {
        return null;
    }

    return (
        <Panel title="Block pasture &amp; diet (ME source)" info={PAGE.INFO} skyBlue className="u-page-break">
            <PanelBody>
                <ul className="SubMenu">
                    {analysis?.enterprises.map((enterprise) => (
                        <li key={enterprise.id} className={`SubMenu-item ${enterprise.id === enterpriseId ? "is-active" : ""}`}>
                            <ActionLink onClick={() => setEnterpriseId(enterprise.id)}>
                                <span>{enterprise.name}</span>
                            </ActionLink>
                        </li>
                    ))}
                </ul>
                <StockNumbers analysis={analysis} enterpriseId={enterpriseId} />
                <RsuFromPastureByBlock analysis={analysis} enterpriseId={enterpriseId} />
                <Diet analysis={analysis} enterpriseId={enterpriseId} />
            </PanelBody>
        </Panel>
    )
}

const PAGE = {
    INFO: <>
        Shows the monthly stock numbers, the estimated monthly RSU from pasture for each block, and the estimated monthly breakdown of diet for each animal enterprise.
        <ForMoreInfoZendeskLink url="https://support.overseer.org.nz/hc/en-us/articles/900001981206-Animal-Report-Description-of-Block-Pasture-Diet-ME-Source-Report" />
    </>
}
