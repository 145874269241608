import { Fragment } from "react";
import { useFarmTrendCharts } from "containers/Farm/Trends";
import { Bar } from "react-chartjs-2";
import WidgetContainer from "components/WidgetContainer";
import { Grid } from "components/Grid";

export default function FarmTrends({ farm }) {
    const { loading, error, charts } = useFarmTrendCharts(farm, 10);
    const chartIndexes = [0, 1, 3, 4];

    if (loading || error || !charts || charts.length === 0) return null;

    return (
        <div className="ReportSection">
            <h1>Farm trends</h1>
            <div className="u-pb-md">The farm value represents the farms Year End analyses results for each year. The median value represents the current mid-point of the data from Year End analyses in the OverseerFM database.</div>

            <Grid className="u-textCenter">
                {chartIndexes.map((i) => {
                    const c = charts && charts.length > i && charts[i];
                    if (!c || !c.data) return null;

                    const { data, options } = c;
                    options.maintainAspectRatio = true;
                    options.responsive = true;
                    const { title } = data;
                    const { datasets = [], labels = [] } = data;
                    const medianDataset = datasets.find((d) => d.label === "Median");
                    const farmDataset = datasets.find((d) => d.label !== "Median");

                    return (
                        <Fragment key={i}>
                            <WidgetContainer id="trends" title={title} loading={loading} error={error} className="u-width1of4 Farm-trends">
                                <Bar data={data} options={options} />
                                {medianDataset && medianDataset.data && medianDataset.data.length >= labels.length && farmDataset && farmDataset.data && farmDataset.data.length >= labels.length && (
                                    <div className="Table u-mt-sm Table-Compressed">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Year</th>
                                                    <th className="u-textRight">Farm</th>
                                                    <th className="u-textRight">Median</th>
                                                </tr>
                                            </thead>
                                            {labels.map((label, i) => {
                                                const diff = farmDataset.data[i] - medianDataset.data[i] || 0;
                                                const increased = diff > 0;
                                                const decreased = diff < 0;

                                                const changeTextClassName = decreased ? "u-textSuccess u-textBold" : "u-textError u-textBold";
                                                const changeArrowClassName = increased ? "icon-arrow-up1 IconLink--compressed" : decreased ? "icon-arrow-down1 IconLink--compressed" : "";

                                                return (
                                                    <tr>
                                                        <td>{label}</td>
                                                        <td align="right">
                                                            <div className="u-flex u-flexJustifyEnd">
                                                                <span>{farmDataset.data[i]?.toLocaleString()}</span>
                                                                {false && <span className={`${changeArrowClassName} ${changeTextClassName}`}></span>}
                                                            </div>
                                                        </td>
                                                        <td align="right" className="u-me-sm">
                                                            {medianDataset.data[i]?.toLocaleString()}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </div>
                                )}
                            </WidgetContainer>
                        </Fragment>
                    );
                })}
            </Grid>
        </div>
    )
}
