import { useModal } from "common/hooks";
import ActionLink from "components/ActionLink";
import { UserModal } from "containers/Admin/Users";

export default function AddUserActionLink({ orgId, children="Add user", className = "IconLink--arrow-plus" }) {
    const [userModal, openAddUserModal] = useAddUserModal(orgId);
    return (<>
        <ActionLink id="add-user" className={className} onClick={openAddUserModal}>
            {children}
        </ActionLink>
        {userModal}
    </>);
}

const useAddUserModal = (orgId) => {
    const [modal, openModal] = useModal(UserModal);

    const openAddUserModal = () => {
        const modalProps = {
            user: {
                accountId: orgId
            }
        };
        openModal(modalProps);
    };

    return [modal, openAddUserModal];
}
