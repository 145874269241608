import React from "react";
import { Link } from "react-router-dom";
import { withActions } from "containers/HOC";
import * as utils from "common/utils";
import { PanelBody } from "components/Panel";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { getAccessGivenStats, getAccessRequestedStats } from "../_actions";
import { Grid, GridCell } from "components/Grid";
import moment from "moment";
import SelectPack from "components/SelectPack";

const AccessReport = ({ getAccessGivenStats, getAccessRequestedStats }) => {
    const [accessRequestedStats, setAccessRequestedStats] = React.useState();
    const [accessGivenStats, setAccessGivenStats] = React.useState();
    const [statGroup, setStatGroup] = React.useState();
    const [role, setRole] = React.useState("All");

    React.useEffect(() => {
        getAccessRequestedStats()
            .then((response) => {
                setAccessRequestedStats({
                    ...response,
                });
            })
            .catch((error) => setAccessRequestedStats({ error }));
    }, [getAccessRequestedStats]);

    React.useEffect(() => {
        getAccessGivenStats()
            .then((response) => {
                setAccessGivenStats({
                    ...response,
                });
            })
            .catch((error) => setAccessGivenStats({ error }));
    }, [getAccessGivenStats]);

    const RequestSummary = ({ title, statsCount, groupColour, type }) => {
        if (!statsCount) return null;
        const all = statsCount.find((s) => s.period === "Total") || {};
        const threeMonths = statsCount.find((s) => s.period === "ThreeMonths") || {};
        const twoMonths = statsCount.find((s) => s.period === "TwoMonths") || {};
        const oneMonth = statsCount.find((s) => s.period === "OneMonth") || {};
        const sevenDays = statsCount.find((s) => s.period === "SevenDays") || {};

        const setGroup = (heroGroup, groupTitle) => {
            const items = type === "REQ" ? accessRequestedStats.items : accessGivenStats.items;
            switch (heroGroup.period) {
                case "ThreeMonths":
                    setStatGroup({ items: items.filter((i) => moment(i.date) > moment.utc().subtract(3, "months")), title, groupTitle, type });
                    break;
                case "TwoMonths":
                    setStatGroup({ items: items.filter((i) => moment(i.date) > moment.utc().subtract(2, "months")), title, groupTitle, type });
                    break;
                case "OneMonth":
                    setStatGroup({ items: items.filter((i) => moment(i.date) > moment.utc().subtract(1, "months")), title, groupTitle, type });
                    break;
                case "SevenDays":
                    setStatGroup({ items: items.filter((i) => moment(i.date) > moment.utc().subtract(7, "days")), title, groupTitle, type });
                    break;
                default:
                    setStatGroup({ items, title, groupTitle, type });
                    break;
            }
        };

        const HeroItem = ({ heroGroup, groupTitle }) => (
            <div className="HeroValue-item u-link">
                <div className="u-flex" onClick={() => setGroup(heroGroup, groupTitle)}>
                    <div>
                        <h5>{groupTitle}</h5>
                        <div className="HeroValue-item-number-large">
                            {heroGroup.total > 0 ? (
                                <span className="a" id={`${type}_${groupTitle.replace(" ", "")}`}>
                                    {utils.round((heroGroup.actioned / heroGroup.total) * 100, 0)}
                                    <small>%</small>
                                </span>
                            ) : (
                                "-"
                            )}
                        </div>
                    </div>
                    {heroGroup.total > 0 && (
                        <div className="u-pl-lg u-pt-lg">
                            <div className="h6">Total {Number(heroGroup.total).toLocaleString()}</div>
                            <div className="h6">Actioned {Number(heroGroup.actioned).toLocaleString()}</div>
                            <div className="h6">Outstanding {Number(heroGroup.outstanding).toLocaleString()}</div>
                        </div>
                    )}
                </div>
            </div>
        );
        return (
            <div className={`HeroPanel HeroPanel--${groupColour} u-print-border`}>
                <div className="HeroPanel-heading">
                    {title} <small> - % actioned</small>
                </div>
                <Grid>
                    <GridCell>
                        <div className="HeroValue u-flexWrap">
                            <HeroItem heroGroup={sevenDays} groupTitle="7 Days" />
                            <HeroItem heroGroup={oneMonth} groupTitle="1 Month" />
                            <HeroItem heroGroup={twoMonths} groupTitle="2 Months" />
                            <HeroItem heroGroup={threeMonths} groupTitle="3 Months" />
                            <HeroItem heroGroup={all} groupTitle="Total" />
                        </div>
                    </GridCell>
                </Grid>
            </div>
        );
    };

    const gridData = statGroup && role ? statGroup.items.filter((x) => x.role === role || role === "All") : [];

    return (
        <div>
            <PanelBody loading={!accessGivenStats}>{accessGivenStats && <RequestSummary title="Access given" type="GVN" statsCount={accessGivenStats.statsCount} groupColour="nloss" />}</PanelBody>
            <PanelBody loading={!accessRequestedStats}>{accessRequestedStats && <RequestSummary title="Access requested" type="REQ" statsCount={accessRequestedStats.statsCount} groupColour="ghg" />}</PanelBody>
            {statGroup && (
                <div>
                    <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter u-mt-sm">
                        <div className="h2">
                            {statGroup.title} - {statGroup.groupTitle} - Outstanding ({gridData.length})
                        </div>
                        <div className="u-mb-sm">
                            <SelectPack meta={{ nrf: true }} onChange={(e) => setRole(e.target.value)} value={role} id="role" label="Filter by role">
                                <option value="All">All</option>
                                <option value="Write">Write</option>
                                <option value="Admin">Admin</option>
                                <option value="Owner">Owner</option>
                            </SelectPack>
                        </div>
                    </div>
                    <SortableTable data={gridData} defaultSortCriteria={{ fieldName: "farmName", fieldType: "string" }} pageSize={100}>
                        {({ data, sortCriteria, handleSort }) => {
                            return (
                                <table>
                                    <thead>
                                        <tr>
                                            <SortableTableHeader label="Date" fieldName="date" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                            <SortableTableHeader label="Status" fieldName="failed" sortCriteria={sortCriteria} handleSort={handleSort} bool />
                                            <SortableTableHeader label="Farm" fieldName="farmName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                            <SortableTableHeader label="Role" fieldName="role" sortCriteria={sortCriteria} handleSort={handleSort} />
                                            <SortableTableHeader label="Sender - Account" fieldName="senderAccount" sortCriteria={sortCriteria} handleSort={handleSort} />
                                            <SortableTableHeader label="Sender - User" fieldName="senderUser" sortCriteria={sortCriteria} handleSort={handleSort} />
                                            <SortableTableHeader label="Details" fieldName="details" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((req, i) => {
                                            const { emails = [] } = req;
                                            const isUnknown = req.emailStatus === "Unknown";
                                            return (
                                                <tr key={i} id={req.id} className={req.failed && !isUnknown ? "u-font-red u-font-600" : ""}>
                                                    <td>{utils.dateOnly(req.date, "-")}</td>
                                                    <td valign="top">
                                                        {(req.emailStatus || "").split(",").map((s) => (
                                                            <div key={s} className={s.includes("failed") && !s.includes(": 0") && !isUnknown ? "u-font-red" : ""}>
                                                                {s}
                                                            </div>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        <Link to={`/app/farm/${req.farmId}`}>{req.farmName}</Link>
                                                    </td>
                                                    <td>{req.role}</td>
                                                    <td>{req.senderAccount}</td>
                                                    <td>{req.senderUser}</td>
                                                    {statGroup.type === "REQ" ? (
                                                        <td>
                                                            {emails.map((e) => {
                                                                return (
                                                                    <div key={e.toEmail} className={e.failed && !isUnknown ? "u-font-red u-font-600" : ""}>
                                                                        {e.toEmail} - {e.status || "Unknown"}
                                                                    </div>
                                                                );
                                                            })}
                                                        </td>
                                                    ) : (
                                                        <td>{req.details}</td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            );
                        }}
                    </SortableTable>
                </div>
            )}
        </div>
    );
};

export default withActions({ getAccessGivenStats, getAccessRequestedStats })(AccessReport);
