import React from "react";
import * as utils from "common/utils";
import { compose, withAppContext, withState } from "containers/HOC";
import { withRouter } from "react-router-dom";
import { Form, Field } from "react-final-form";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack2";
import * as FormUtils from "common/FormUtils";
import * as validations from "common/validations";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import Modal from "components/new/Modal";
import ModalBody from "components/new/ModalBody";
import ModalFooter from "components/new/ModalFooter";
import Nutrients from "components/Nutrients";
import { Line } from "react-chartjs-2";

const ThermalTimes = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120];
const Yields = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class UserDefinedCropModal extends React.Component {
    _closeOnEscape = (e) => {
        if (e.keyCode === 27) {
            this.props.cancel();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this._closeOnEscape);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._closeOnEscape);
    }

    onSubmit(values) {
        this.props.submit(values.crop);
    }

    _validate = (values) => {
        const errors = { crop: { productionBiomassParameter: {}, coverParameter: {}, cropHarvestIndexParameter: {}, cropNutrientContentsParameter: { product: {}, residue: {} } } };
        const nameExists = this.props.crops.find((c) => c.name && values.crop.name && c.id !== values.crop.id && c.name.trim().toUpperCase() === values.crop.name.trim().toUpperCase());

        errors.crop.name = validations.required(values.crop.name);
        errors.crop.name = errors.crop.name || validations.maxLength(50)(values.crop.name);
        errors.crop.name = errors.crop.name || (nameExists ? "Name already in use" : undefined);

        errors.crop.typicalYield = validations.maxValue(100)(values.crop.typicalYield);
        errors.crop.dmContent = validations.maxValue(1)(values.crop.dmContent);
        errors.crop.meContent = validations.maxValue(20)(values.crop.meContent);
        errors.crop.maxRootDepth = validations.maxValue(1.5)(values.crop.maxRootDepth);
        errors.crop.nFixed = validations.maxValue(500)(values.crop.nFixed);

        if (values.crop.productionBiomassParameter) {
            errors.crop.productionBiomassParameter.xo = validations.maxValue(100)(values.crop.productionBiomassParameter.xo);
            errors.crop.productionBiomassParameter.b = validations.maxValue(30)(values.crop.productionBiomassParameter.b);
            errors.crop.productionBiomassParameter.pRoot = validations.maxValue(1)(values.crop.productionBiomassParameter.pRoot);
        }

        if (values.crop.coverParameter) {
            errors.crop.coverParameter.xo = validations.maxValue(100)(values.crop.coverParameter.xo);
            errors.crop.coverParameter.b = validations.maxValue(100)(values.crop.coverParameter.b);
            errors.crop.coverParameter.aCover = validations.maxValue(100)(values.crop.coverParameter.aCover);
            errors.crop.coverParameter.senescenceThermalTime = validations.maxValue(10000)(values.crop.coverParameter.senescenceThermalTime);
            errors.crop.coverParameter.matureThermalTime = validations.maxValue(10000)(values.crop.coverParameter.matureThermalTime);
        }

        if (values.crop.cropHarvestIndexParameter) {
            errors.crop.cropHarvestIndexParameter.a = validations.maxValue(1)(values.crop.cropHarvestIndexParameter.a);
            errors.crop.cropHarvestIndexParameter.b = validations.maxValue(1)(values.crop.cropHarvestIndexParameter.b);
        }

        if (values.crop.cropNutrientContentsParameter) {
            errors.crop.cropNutrientContentsParameter.rootsN = validations.maxValue(5)(values.crop.cropNutrientContentsParameter.rootsN);

            if (values.crop.cropNutrientContentsParameter.product) {
                Object.keys(values.crop.cropNutrientContentsParameter.product).forEach((k) => {
                    errors.crop.cropNutrientContentsParameter.product[k] = validations.maxValue(5)(values.crop.cropNutrientContentsParameter.product[k]);
                });
            }
            if (values.crop.cropNutrientContentsParameter.residue) {
                Object.keys(values.crop.cropNutrientContentsParameter.residue).forEach((k) => {
                    errors.crop.cropNutrientContentsParameter.residue[k] = validations.maxValue(5)(values.crop.cropNutrientContentsParameter.residue[k]);
                });
            }
        }
        return errors;
    };

    render() {
        const { viewModel = {}, online, isReadOnly, refData } = this.props;

        const cropCategories = refData.cropCategories.filter((c) => c.value !== "Undefined");

        const Cell = ({ size = 4, children }) => (
            <div className={"Grid-cell u-lg-width1of" + size}>
                <div className="u-mr-sm">{children}</div>
            </div>
        );

        const CoverGraph = ({ bio, cover }) => {
            const nData = {
                labels: ThermalTimes,
                datasets: [
                    {
                        label: "Biomass",
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: "rgba(75,192,192,0.4)",
                        borderColor: utils.graphColours[0],
                        data: ThermalTimes.map((t, i) => {
                            return 1 / (1 + Math.exp(-((t - bio.xo) / bio.b)));
                        }),
                    },
                    {
                        label: "Cover",
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: "rgba(75,192,192,0.4)",
                        borderColor: utils.graphColours[1],
                        data: ThermalTimes.map((t, i) => {
                            return t > cover.matureThermalTime ? 0 : t > cover.senescenceThermalTime ? cover.aCover * (1 - (t - cover.senescenceThermalTime) / (cover.matureThermalTime - cover.senescenceThermalTime)) : (cover.aCover * 1) / (1 + Math.exp(-((t - cover.xo) / cover.b)));
                        }),
                    },
                ],
            };

            const options = {
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: "Thermal time (degrees C/month)",
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: "Proportion",
                        },
                    },
                },
            };

            return <Line data={nData} legend={{ display: true }} height={75} options={options} />;
        };

        const HarvestGraph = ({ harvest }) => {
            const nData = {
                labels: Yields,
                datasets: [
                    {
                        label: "Yield (T/ha)",
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: "rgba(75,192,192,0.4)",
                        borderColor: utils.graphColours[0],
                        data: Yields.map((y, i) => {
                            return harvest.a + harvest.b * y;
                        }),
                    },
                ],
            };

            const options = {
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: "Yield (T/ha)",
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: "Harvest index",
                        },
                    },
                },
            };

            return <Line data={nData} legend={{ display: true }} height={75} options={options} />;
        };

        return (
            <Form onSubmit={(values) => this.onSubmit(values)} initialValues={viewModel} validate={this._validate}>
                {({ handleSubmit, initialValues, submitting, values, errors }) => {
                    const cropCategoryName = values.crop.category;
                    const cropCategory = cropCategories.find((p) => p.value === cropCategoryName) || { children: [] };

                    return (
                        <Modal title={viewModel.crop.name ? viewModel.crop.name : "Crop Details"} submitLabel="Save" initialValues={initialValues} wide={true}>
                            {({ closeModal }) => (
                                <form onSubmit={handleSubmit}>
                                    <ModalBody>
                                        {!online && (
                                            <div className="Modal-subhead">
                                                <Alert type="error" text="Overseer is currently offline. Please try again later." />
                                            </div>
                                        )}
                                        <div className="Modal-body">
                                            <h3>Crop details</h3>
                                            <div className="Grid">
                                                <input type="hidden" id="crop.id" value={values.crop.id} />
                                                <Cell>
                                                    <Field name={`crop.name`} label="Name" component={InputPack} requiredLabel={true} disabled={isReadOnly} />
                                                </Cell>
                                                <Cell>
                                                    <Field name="crop.category" label="Category" component={SelectPack} required={true} options={cropCategories} placeholder="Select a crop category" />
                                                </Cell>
                                                <Cell>
                                                    <Field name="crop.baseCropType" label="Base crop" component={SelectPack} required={true} options={cropCategory.children} placeholder="Select a base crop" disabled={!cropCategoryName} />
                                                </Cell>
                                            </div>
                                            <div className="Grid">
                                                <Cell>
                                                    <Field name="crop.typicalYield" type="text" label="Typical yield" component={InputPack} placeholder="Typical yield of crop" uom="T/ha" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                </Cell>
                                                <Cell>
                                                    <Field name="crop.dmContent" type="text" label="DM content" component={InputPack} placeholder="DM content of crop" uom="kg/kg" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                </Cell>
                                                <Cell>
                                                    <Field name="crop.meContent" type="text" label="ME content" component={InputPack} placeholder="ME content of crop" uom="MJ ME/kg DM" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                </Cell>
                                                <Cell>
                                                    <Field name="crop.maxRootDepth" type="text" label="Max rooting depth" component={InputPack} placeholder="Maximum rooting depth of crop" uom="m" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                </Cell>
                                                <Cell>
                                                    <Field name="crop.nFixed" type="text" label="N fixed" component={InputPack} placeholder="Total amount of N fixed" uom="kg N/ha" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                </Cell>
                                            </div>
                                            <h3>Production</h3>
                                            <div className="Grid">
                                                <Cell>
                                                    <Field name="crop.productionBiomassParameter.xo" type="text" label="XO" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                </Cell>
                                                <Cell>
                                                    <Field name="crop.productionBiomassParameter.b" type="text" label="B" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                </Cell>
                                                <Cell>
                                                    <Field name="crop.productionBiomassParameter.pRoot" type="text" label="Root yield proportion" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                </Cell>
                                            </div>
                                            <h3>Cover</h3>
                                            <div className="Grid">
                                                <div className="Grid-cell u-lg-width1of2">
                                                    <div className="Grid">
                                                        <Cell size="2">
                                                            <Field name="crop.coverParameter.xo" type="text" label="XO" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                        </Cell>
                                                        <Cell size="2">
                                                            <Field name="crop.coverParameter.b" type="text" label="B" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                        </Cell>
                                                    </div>
                                                    <div className="Grid">
                                                        <Cell size="2">
                                                            <Field name="crop.coverParameter.aCover" type="text" label="Max proportion of area" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                        </Cell>
                                                        <Cell size="2">
                                                            <Field name="crop.coverParameter.senescenceThermalTime" type="text" label="Senescence thermal time" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                        </Cell>
                                                    </div>
                                                    <div className="Grid">
                                                        <Cell size="2">
                                                            <Field name="crop.coverParameter.matureThermalTime" type="text" label="Mature thermal time" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                        </Cell>
                                                    </div>
                                                </div>
                                                <div className="Grid-cell u-lg-width1of2">{values.crop.productionBiomassParameter && values.crop.coverParameter && <CoverGraph bio={values.crop.productionBiomassParameter} cover={values.crop.coverParameter} />}</div>
                                            </div>
                                            <h3>Harvest</h3>
                                            <div className="Grid">
                                                <div className="Grid-cell u-lg-width1of2">
                                                    <Cell size="2">
                                                        <Field name="crop.cropHarvestIndexParameter.a" type="text" label="Regression coefficient" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                    </Cell>
                                                    <Cell size="2">
                                                        <Field name="crop.cropHarvestIndexParameter.b" type="text" label="Slope" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                    </Cell>
                                                </div>
                                                <div className="Grid-cell u-lg-width1of2">{values.crop.cropHarvestIndexParameter && <HarvestGraph harvest={values.crop.cropHarvestIndexParameter} />}</div>
                                            </div>
                                            <h3>Nutrients</h3>
                                            <div className="Grid">
                                                <Cell>
                                                    <Field name="crop.cropNutrientContentsParameter.rootsN" type="text" label="Roots N" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                </Cell>
                                                <h4>Product</h4>
                                                <Nutrients type="UserDefinedCrop" noMarginTop={true} errors={errors} refData={refData} prefix="crop.cropNutrientContentsParameter.product" noLabel hideUnits={true} />
                                                <h4>Residue</h4>
                                                <Nutrients type="UserDefinedCrop" noMarginTop={true} errors={errors} refData={refData} prefix="crop.cropNutrientContentsParameter.residue" noLabel hideUnits={true} />
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="ButtonBar">
                                            <div className="ButtonBar-left">
                                                <ActionLink id="cancel-button" onClick={() => this.props.cancel()} className="Button Button--secondary" type="button" name="cancel-button">
                                                    Cancel
                                                </ActionLink>
                                            </div>
                                            {!isReadOnly && (
                                                <div className="ButtonBar-right">
                                                    <button id="confirm-button" className="Button Button--primary" type="submit" disabled={submitting || !online} name="confirm-button">
                                                        Save
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </ModalFooter>
                                </form>
                            )}
                        </Modal>
                    );
                }}
            </Form>
        );
    }
}
export default compose(
    withRouter,
    withAppContext,
    withState((state, ownProps) => {
        const { refData = {} } = state.app;
        const viewModel = {
            crop: utils.clone(ownProps.crop),
        };
        return {
            ...ownProps,
            online: state.app.online,
            refData: refData,
            viewModel: viewModel,
        };
    })
)(UserDefinedCropModal);
