import React from "react";
import { Switch, Route } from "react-router-dom";
import { Panel, PanelBody } from "components/Panel";
import UpgradeRuns from "./UpgradeRuns";
import AnalysisDiagnostics from "./AnalysisDiagnostics";
import ReportCache from "./ReportCache";

const Index = () => {
    const baseUrl = "/app/admin/devTeamOnly";
    const tabs = {
        upgrade: { title: "Upgrade runs", path: `${baseUrl}`, exact: true },
        diagnostics: { title: "Diagnostics", path: `${baseUrl}/diagnostics`, exact: true },
        caches: { title: "Report cache", path: `${baseUrl}/cache`, exact: true },
    };
    return (
        <Panel tabs={tabs}>
            <PanelBody>
                <Switch>
                    <Route exact path={tabs.upgrade.path} render={() => <UpgradeRuns />} />
                    <Route exact path={tabs.diagnostics.path} render={() => <AnalysisDiagnostics />} />
                    <Route exact path={tabs.caches.path} render={() => <ReportCache />} />
                </Switch>
            </PanelBody>
        </Panel>
    );
};

export default Index;
