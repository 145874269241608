import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { FORM_ERROR } from "final-form";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as FormUtils from "common/FormUtils";
import { useRefData } from "common/hooks";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import SavePrompt from "components/SavePrompt";
import BlockSelector from "components/new/BlockSelector";
import { SelectField, WholeNumberField } from "components/FormFields";
import ActionLink from "components/ActionLink";
import Button from "components/Button/Button";
import * as climateUtils from "./_utils";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function SnowfallDetails({ farm, analysis, snowfall }) {
    const refData = useRefData();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const validate = (values) => {
        const validation = {};

        if (!values.blockIds || values.blockIds.length === 0) {
            validation.blockIds = "Required";
        }

        return validation;
    }

    const submitAsync = async (values) => {
        try {
            if (!analysis.snowfalls) {
                analysis.snowfalls = [];
            }

            if (!values.id) {
                values.id = uuidv4();
                analysis.snowfalls.push(values);
            } else {
                analysis.snowfalls = analysis.snowfalls.map((s) => (s.id === values.id ? values : s));
            }

            await updateAnalysisAsync(analysis);
        } catch (ex) {
            return {
                [FORM_ERROR]: ex.message,
            };
        }
    }

    const referrer = `/app/farm/${farm.id}/analysis/${analysis.id}/climate`;
    const snowfallIndex = (analysis.snowfalls || []).findIndex((s) => s.id === snowfall.id) + 1;
    const availableSnowfallBlocks = climateUtils.getAvailableSnowfallBlocks(analysis, snowfall);

    const infoText = "Select the blocks that have similar snowfall and add each month that it fell on those blocks. If there is a difference in snowfall for some blocks you will need to enter those separately.";
    const helpText = "It is assumed that all snow melts in the month that it falls and that snow/ice cover does not affect runoff from subsequent rainfall events";

    return (
        <Form initialValues={snowfall} mutators={{ ...arrayMutators }} validate={validate} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, dirty, submitting, submitError, submitSucceeded }) => {
                const hasSnowfallMonths = values.snowfallMonths && values.snowfallMonths.length > 0;

                const selectedMonths = (values.snowfallMonths || []).map((sm) => sm.month);
                const addMonthAction = selectedMonths.length < 12 && (
                    <span id="add-snowfall-link" className="IconLink--arrow-plus u-link" onClick={addSnowfallMonth(form, refData.months, selectedMonths)}>
                        Add another month
                    </span>
                );

                return (
                    <form onSubmit={handleSubmit}>
                        <SavePrompt blockIf={dirty && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={referrer} />
                        <Panel title={!snowfall.id ? "Add snowfall" : `Edit Snowfall ${snowfallIndex}`} info={infoText} help={helpText} error={submitError} waiting={submitting} referrer={referrer}>
                            <PanelBody waiting={submitting}>
                                <Grid>
                                    <GridCell>
                                        <Field name="blockIds" availableBlocks={availableSnowfallBlocks} component={BlockSelector} />
                                    </GridCell>
                                    <GridCell>
                                        <Panel title="Snowfall months" midBlue className="u-mt-lg" actions={addMonthAction}>
                                            <PanelBody>
                                                <div className="Table u-mt-md">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th data-width="md-40 xl-40">Month</th>
                                                                <th data-width="md-20 xl-20">Depth</th>
                                                                <th data-width="md-20 xl-20">Type</th>
                                                                {hasSnowfallMonths && <th className="th--shrink">&nbsp;</th>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!hasSnowfallMonths && (
                                                                <tr>
                                                                    <td colSpan="3">
                                                                        <div className="Panel-body">
                                                                            <div className="Panel-body-message">
                                                                                <p className="h4 u-mt-0">You have not defined any snowfall months</p>
                                                                                <Button id="add-snowfall-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={addSnowfallMonth(form, refData.months, selectedMonths)}>
                                                                                    Add snowfall month
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {hasSnowfallMonths && (
                                                                <FieldArray name="snowfallMonths" validate={validateSnowfallMonths}>
                                                                    {({ fields }) => {
                                                                        return fields.map((field, index) => {
                                                                            const snowfallMonth = fields.value[index];
                                                                            const options = refData.months.filter((m) => m.value === snowfallMonth.month || !selectedMonths.includes(m.value));

                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <Field name={`${field}.month`} options={options} required component={SelectField} />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Field name={`${field}.amount`} placeholder="0" uom="cm" required component={WholeNumberField} />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Field name={`${field}.coverage`} placeholder="Select a snow type" options={refData.snowfallTypes} required component={SelectField} />
                                                                                    </td>
                                                                                    <td>{fields.length > 1 && <ActionLink id={`remove-snowfall-${index}`} className="IconLink--trash" onClick={removeSnowfallMonth(form, values, index)}></ActionLink>}</td>
                                                                                </tr>
                                                                            );
                                                                        });
                                                                    }}
                                                                </FieldArray>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </PanelBody>
                                        </Panel>
                                    </GridCell>
                                </Grid>
                            </PanelBody>
                            <PanelFooter>
                                <div className="ButtonBar ButtonBar--fixed">
                                    <div className="ButtonBar-left">
                                        <Link to={referrer} className="Button Button--secondary" id="cancel-button">
                                            Cancel
                                        </Link>
                                    </div>
                                    <div className="ButtonBar-right">
                                        <Button id="submit-button" submit primary waiting={submitting} disabled={submitting}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </PanelFooter>
                        </Panel>
                    </form>
                );
            }}
        </Form>
    )
}

const addSnowfallMonth = (form, months, selectedMonths) => (e) => {
    const firstUnselectedMonth = months.find((month) => !selectedMonths.includes(month.value));
    if (firstUnselectedMonth) form.mutators.push("snowfallMonths", { month: firstUnselectedMonth.value });
}

const removeSnowfallMonth = (form, values, index) => (e) => {
    const results = values.snowfallMonths.reduce((acc, cur, i) => {
        if (i !== index) acc.push(cur);
        return acc;
    }, []);
    form.change("snowfallMonths", results);
}

const validateSnowfallMonths = (snowfallMonths) => {
    const errors = [];

    snowfallMonths &&
        snowfallMonths.forEach((snowfallMonth) => {
            const error = {};

            error.month = FormUtils.validators.required(snowfallMonth.month);

            error.amount = FormUtils.validators.required(snowfallMonth.amount);
            error.amount = error.amount || FormUtils.validators.range(1, 10000)(snowfallMonth.amount);

            error.coverage = FormUtils.validators.required(snowfallMonth.coverage);

            errors.push(error);
        });

    return errors;
}
