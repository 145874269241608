import { Panel, PanelBody } from "components/Panel";
import * as utils from "common/utils";
import ActionLink from "components/ActionLink";
import { useForestModal } from "./ForestModal";
import Button from "components/Button/Button";
import { useRefData } from "common/hooks";

export default function Forests({ form, forests = [] }) {
    const refData = useRefData();

    const deleteForest = (forestId) => () => {
        const updatedForests = forests.reduce((acc, cur) => {
            if (cur.id !== forestId) {
                acc.push(cur);
            }
            return acc;
        }, []);
        form.change("forests", updatedForests);
    };

    const onSave = (forest) => {
        if (forest.isNew) {
            form.change("forests", [...forests, { ...forest, isNew: false }]);
        } else {
            form.change(
                "forests",
                forests.map((f) => {
                    if (f.id === forest.id) {
                        return forest;
                    }
                    return f;
                })
            );
        }
    };
    const [openForestModal, forestModal] = useForestModal(forests, onSave);

    return (
        <>
            <Panel
                title={`Forests (${forests.length})`}
                className="u-mt-lg"
                info="Adding forest details will enable indicative estimation of carbon stocks based on look-up tables for post 1989 forest land by MPI. You will find this report under the GHG reporting section."
                actions={
                    <ActionLink id="add-forest-link" className="IconLink--arrow-plus u-textWhite" onClick={() => openForestModal()}>
                        Add forest
                    </ActionLink>
                }
                skyBlue
            >
                <PanelBody className="u-p-0">
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="40">Name/Type</th>
                                    <th data-width="20" className="u-textRight">
                                        Age
                                    </th>
                                    <th data-width="20" className="u-textRight">
                                        Harvested
                                    </th>
                                    <th data-width="20" className="u-textRight">
                                        %
                                    </th>
                                    <th className="th--shrink"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {forests.length === 0 && (
                                    <tr>
                                        <td colSpan="5">
                                            <div className="Tile-body-message">
                                                <Button id="add-forest-button" onClick={() => openForestModal()} className="IconLink--arrow-plus Button Button--secondary">
                                                    Add forest
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                {forests.length > 0 &&
                                    forests.map((forest) => {
                                        return (
                                            <tr key={forest.id}>
                                                <td onClick={() => openForestModal(forest)}>
                                                    <div className="u-flexSplit u-link a">
                                                        <span>{forest.name || utils.valueToText(refData.forestType, forest.forestType)}</span>
                                                        <span className="icon icon-edit" />
                                                    </div>
                                                </td>
                                                <td className="u-textRight">{forest.forestAge}</td>
                                                <td className="u-textRight">{forest.isHarvested ? "Yes" : "No"}</td>
                                                <td className="u-textRight">{forest.percentage}</td>
                                                <td>
                                                    <ActionLink id={`remove-forest-${forest.id}`} className="IconLink--trash" onClick={deleteForest(forest.id)} title="Delete" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </PanelBody>
            </Panel>
            {forestModal}
        </>
    );
}
