import { useQuery } from "@tanstack/react-query";
import { currencyFormatter } from "common/utils";
import { httpClient } from "../../common/httpClient";

export default function OrgBalance({ orgId }) {
    const { balance } = useOrgBalanceQuery(orgId);
    const { currentBalance = 0, creditLimit = 0 } = balance;
    const availableCredit = currentBalance + creditLimit;

    return (
        <div className="u-mt-md u-mb-md">
            {creditLimit > 0 && (
                <span className="u-pr-lg">
                    <b>Postpaid spending limit:</b> {currencyFormatter.format(creditLimit)}
                </span>
            )}
            {creditLimit > 0 && currentBalance !== availableCredit && (
                <span className="u-pr-lg">
                    <b>Postpaid purchases:</b> {currencyFormatter.format(currentBalance)}
                </span>
            )}
            <span>
                <b>Available balance:</b> {currencyFormatter.format(availableCredit)}
            </span>
        </div>
    );
}

function useOrgBalanceQuery(orgId) {
    const query = useQuery({
        queryKey: ["orgs", orgId, "balance"],
        queryFn: () => httpClient.get(`accounts/${orgId}/balance`),
        retry: false
    });

    return {
        isLoading: query.isLoading,
        balance: query.data || {}
    };
}
