import arrayMutators from "final-form-arrays";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import * as FormUtils from "common/FormUtils";
import { useModal } from "common/hooks";
import * as utils from "common/utils";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import { WholeNumberField } from "components/FormFields";
import BlockSelector from "components/new/BlockSelector";

function OutdoorPigGreenCoverApplicationModal({ viewModel, onSubmit, close }) {
    const validate = (formValues) => {
        const validation = {};

        if (!formValues.blockIds || formValues.blockIds.length === 0) {
            validation.blockIds = "Required";
        }

        return validation;
    }

    const submitAsync = async (formValues) => {
        delete formValues.availableBlocks;
        if (onSubmit) {
            await onSubmit(formValues);
        }
        close();
    }

    const validateSeasons = (seasons) => {
        const validations = [];

        seasons &&
            seasons.forEach((season) => {
                const validation = {};
                validation.percentage = FormUtils.validators.max(100)(season.percentage);
                validations.push(validation);
            });

        return validations;
    }

    return (
        <Form initialValues={viewModel} mutators={{ ...arrayMutators }} validate={validate} onSubmit={submitAsync}>
            {({ handleSubmit, submitting }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Outdoor pigs seasonal green cover" close={close} fluid>
                            <ModalBody>
                                <Grid>
                                    <GridCell>
                                        <Field name="blockIds" availableBlocks={viewModel.availableBlocks} component={BlockSelector} />
                                    </GridCell>
                                    <GridCell>
                                        <h3>Seasonal green cover</h3>
                                        <p className="u-mt-md">Enter the percentage of green cover for each season for each animal class."</p>
                                        <div className="FarmTable-supplementary u-mt-md">
                                            <div className="Table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th data-width="15"></th>
                                                            {SEASON_NAMES.map((seasonName) => (
                                                                <th key={seasonName} className="th--shrink">
                                                                    {seasonName}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name="applications">
                                                            {({ fields }) => {
                                                                return fields.map((applicationField, applicationIndex) => {
                                                                    const application = fields.value[applicationIndex];
                                                                    const stockClassGroupDisplayText = utils.getOutdoorPigStockClassGroupDisplayText(application.stockClassGroup);

                                                                    return (
                                                                        <tr key={application.stockClassGroup}>
                                                                            <td className="u-textBold">{stockClassGroupDisplayText}</td>
                                                                            <FieldArray name={`${applicationField}.seasons`} validate={validateSeasons}>
                                                                                {({ fields }) => {
                                                                                    return fields.map((seasonField, seasonIndex) => {
                                                                                        const season = fields.value[seasonIndex];
                                                                                        return (
                                                                                            <td key={season.season}>
                                                                                                <Field name={`${seasonField}.percentage`} uom="%" placeholder="0" component={WholeNumberField} />
                                                                                            </td>
                                                                                        );
                                                                                    });
                                                                                }}
                                                                            </FieldArray>
                                                                        </tr>
                                                                    );
                                                                });
                                                            }}
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="done-button" submit waiting={submitting}>
                                        Done
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useOutdoorPigGreenCoverApplicationModal() {
    const [modal, openModal] = useModal(OutdoorPigGreenCoverApplicationModal);

    const open = (viewModel, onSubmit) => {
        const modalProps = {
            viewModel,
            onSubmit
        };
        openModal(modalProps);
    };

    return [modal, open];
}

const SEASON_NAMES = ["Spring", "Summer", "Autumn", "Winter"];
