import React from "react";
import moment from "moment";
import numeral from "numeral";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { Grid, GridCell } from "components/Grid";
import CheckboxPack from "components/CheckboxPack";

const PAYMENT_METHOD_OPTIONS = [
    { value: "Invoice", text: "Invoice" },
    { value: "CreditCard", text: "Credit card" },
    { value: "POLi", text: "POLi" },
    { value: "BankDeposit", text: "Bank deposit" },
];

const PAYMENT_STATUS_OPTIONS = [
    { value: "Paid", text: "Paid" },
    { value: "Pending", text: "Pending" },
    { value: "Declined", text: "Declined" },
    { value: "Aborted", text: "Aborted" },
];

class PaymentList extends React.Component {
    state = {
        status: PAYMENT_STATUS_OPTIONS[0].value,
        yearMonth: moment().format("YYYY-MM"),
        showOrgFilter: this.props.accountId === undefined,
        showPaystationDetails: this.props.authContext.isSystemAdmin,
        showFarmPaymentsOnly: true,
    };

    componentDidMount() {
        this.props.getPaymentsByMonthYear(this.state.yearMonth, this.props.accountId);
    }

    filterPayments = (payments) => {
        const filteredPayments = (payments || []).filter((payment) => {
            if (this.state.farmId && !payment.farmIds.includes(this.state.farmId)) {
                return false;
            }

            if (this.state.accountId && this.state.accountId !== payment.accountId) {
                return false;
            }

            if (this.state.paymentMethod && this.state.paymentMethod !== payment.paymentMethod) {
                return false;
            }

            if (this.state.status && this.state.status !== payment.status) {
                return false;
            }

            const paymentYearMonth = moment(payment.transactionTime).format("YYYY-MM");
            if (this.state.yearMonthh && this.state.yearMonthh !== paymentYearMonth) {
                return false;
            }

            if (this.state.paymentMethod !== "BankDeposit" && this.state.showFarmPaymentsOnly && payment.paymentMethod === "BankDeposit") {
                return false;
            }

            return true;
        });
        return filteredPayments.map((payment) => ({ ...payment, farmNames: payment.farms.map((f) => f.name).join(", ") }));
    };

    getFarmOptions = (payments) => {
        const filteredPayments = this.filterPayments(payments);
        const options = (filteredPayments || []).reduce((results, payment) => {
            payment.farms.forEach((farm) => {
                if (!results.some((result) => result.value === farm.id)) results.push({ value: farm.id, text: farm.name || "" });
            });
            return results;
        }, []);
        const sortedOptions = options.sort((a, b) => {
            const optionA = a.text.toUpperCase();
            const optionB = b.text.toUpperCase();
            if (optionA < optionB) return -1;
            if (optionA > optionB) return 1;
            return 0;
        });
        if (this.state.accountName && this.state.farmId && !sortedOptions.some((o) => o.value === this.state.farmId)) {
            sortedOptions.unshift({ value: this.state.farmId, text: this.state.farmName });
        }
        return sortedOptions;
    };

    getOrgOptions = (payments) => {
        const filteredPayments = this.filterPayments(payments);
        const options = (filteredPayments || []).reduce((results, payment) => {
            if (!results.some((result) => result.value === payment.accountId)) results.push({ value: payment.accountId, text: payment.accountName || "" });
            return results;
        }, []);
        const sortedOptions = options.sort((a, b) => {
            const optionA = a.text.toUpperCase();
            const optionB = b.text.toUpperCase();
            if (optionA < optionB) return -1;
            if (optionA > optionB) return 1;
            return 0;
        });
        if (this.state.accountName && !sortedOptions.some((o) => o.value === this.state.accountId)) {
            sortedOptions.unshift({ value: this.state.accountId, text: this.state.accountName });
        }
        return sortedOptions;
    };

    getYearMonthOptions = () => {
        const options = [];

        let start = moment("2018-01-01");
        const now = moment();
        while (start.isBefore(now)) {
            options.unshift({ value: start.format("YYYY-MM"), text: start.format("MMMM YYYY") });
            start.add(1, "months");
        }

        return options;
    };

    onYearMonthChange = (e) => {
        const yearMonth = e.target.value;
        this.setState({ yearMonth });
        this.props.getPaymentsByMonthYear(yearMonth, this.props.accountId);
    };

    onPaymentMethodChange = (e) => {
        this.setState({ paymentMethod: e.target.value, showFarmPaymentsOnly: e.target.value === "BankDeposit" ? false : this.state.showFarmPaymentsOnly });
    };

    onStatusChange = (e) => {
        this.setState({ status: e.target.value });
    };

    onOrgChange = (e) => {
        const accountId = e.target.value;
        const payment = this.props.payments.find((s) => s.accountId === accountId);
        this.setState({ accountName: payment ? payment.accountName : "", accountId });
    };

    onFarmChange = (e) => {
        const farmId = e.target.value;
        const farmName = farmId ? e.target.options[e.target.selectedIndex].text : "";
        this.setState({ farmId, farmName });
    };

    onLast50Change = (e) => {
        this.setState({ showLast50Payments: !this.state.showLast50Payments }, () => {
            if (this.state.showLast50Payments) {
                const { recentPaymentCount = 50 } = this.props;
                this.props.getRecentPayments(recentPaymentCount, this.props.accountId);
            } else {
                this.props.getPaymentsByMonthYear(this.state.yearMonth, this.props.accountId);
            }
        });
    };

    render() {
        const { accountId, payments = [], paymentsLoading, authContext, recentPaymentCount = 50 } = this.props;
        const isAuthorised = authContext.isSystemAdmin || authContext.accountId === accountId;
        const farmOptions = this.getFarmOptions(payments);
        const orgOptions = this.getOrgOptions(payments);
        const yearMonthOptions = this.getYearMonthOptions();
        const filteredPayments = this.filterPayments(payments);
        const hasFilteredPayments = filteredPayments.length > 0;
        const startOfDay = moment().startOf("day");
        const todaysPayments = (filteredPayments || []).filter((p) => moment(p.transactionTime).isAfter(startOfDay));

        return (
            <Panel>
                <PanelBody>
                    {!isAuthorised && (
                        <div className="Tile-body-message">
                            <p className="lead">Access denied</p>
                        </div>
                    )}
                    {isAuthorised && (
                        <>
                            <Grid>
                                <GridCell className="u-width1of5">
                                    <div className="Field u-mt-sm">
                                        <label htmlFor="payment-method-filter" className="Select Select--block">
                                            <span className="Field-label">Payment method</span>
                                            <select id="payment-method-filter" value={this.state.paymentMethod} onChange={this.onPaymentMethodChange}>
                                                <option value="">All payment methods</option>
                                                {PAYMENT_METHOD_OPTIONS.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.text}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                </GridCell>
                                <GridCell className="u-width1of5">
                                    <div className="Field u-mt-sm">
                                        <label htmlFor="status-filter" className="Select Select--block">
                                            <span className="Field-label">Status</span>
                                            <select id="status-filter" value={this.state.status} onChange={this.onStatusChange}>
                                                <option value="">All statuses</option>
                                                {PAYMENT_STATUS_OPTIONS.filter((option) => this.state.showOrgFilter || option.value !== "Aborted").map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.text}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                </GridCell>
                                {this.state.showOrgFilter && (
                                    <GridCell className="u-width1of5">
                                        <div className="Field u-mt-sm">
                                            <label htmlFor="org-filter" className="Select Select--block">
                                                <span className="Field-label">Org</span>
                                                <select id="org-filter" value={this.state.accountId} onChange={this.onOrgChange}>
                                                    <option value="">All orgs</option>
                                                    {orgOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.text}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                        </div>
                                    </GridCell>
                                )}
                                <GridCell className="u-width1of5">
                                    <div className="Field u-mt-sm">
                                        <label htmlFor="farm-filter" className="Select Select--block">
                                            <span className="Field-label">Farm</span>
                                            <select id="farm-filter" value={this.state.farmId} onChange={this.onFarmChange}>
                                                <option value="">All farms</option>
                                                {farmOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.text}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                </GridCell>
                                {!this.state.showLast50Payments && (
                                    <GridCell className="u-width1of5">
                                        <div className="Field u-mt-sm">
                                            <label htmlFor="month-filter" className="Select Select--block">
                                                <span className="Field-label">Month</span>
                                                <select id="month-filter" value={this.state.yearMonthh} onChange={this.onYearMonthChange}>
                                                    {yearMonthOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.text}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                        </div>
                                    </GridCell>
                                )}
                            </Grid>
                            <Grid className="u-mb-lg">
                                <GridCell className="u-width1of5">
                                    <CheckboxPack id="farm-payments" value={this.state.showFarmPaymentsOnly} onChange={(e) => this.setState({ showFarmPaymentsOnly: !this.state.showFarmPaymentsOnly })} label="Farm payments only" disabled={this.state.paymentMethod === "BankDeposit"} meta={{ nrf: true }} />
                                </GridCell>
                                <GridCell className="u-width1of5">
                                    <CheckboxPack id="last-50" value={this.state.showLast50Payments} onChange={this.onLast50Change} label={`Last ${recentPaymentCount} payments`} meta={{ nrf: true }} />
                                </GridCell>
                            </Grid>
                        </>
                    )}
                    {isAuthorised && (
                        <Panel>
                            <PanelBody loading={paymentsLoading}>
                                {isAuthorised && !hasFilteredPayments && (
                                    <div className="Tile-body-message">
                                        <p className="lead">No data</p>
                                    </div>
                                )}
                                {isAuthorised && hasFilteredPayments && (
                                    <React.Fragment>
                                        <p className="u-mt-0 u-mb-md">
                                            <span className="h3">{filteredPayments.length}</span> transaction(s) found
                                        </p>
                                        <p className="u-mt-0 u-mb-md">
                                            <span className="h3">{todaysPayments.length}</span> transaction(s) today
                                        </p>
                                        <SortableTable data={filteredPayments} defaultSortCriteria={{ fieldName: "transactionTime", fieldType: "date", desc: true }}>
                                            {({ data, sortCriteria, handleSort }) => {
                                                const CHARGEABLE_STATUSES = ["Pending", "Paid"];
                                                return (
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <SortableTableHeader label="Date" fieldName="transactionTime" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                                <SortableTableHeader label="Payment method" fieldName="paymentMethod" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                <SortableTableHeader label="Sub type" fieldName="creditCardSubTxnType" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                {authContext.isSystemAdmin && <SortableTableHeader label="Trxn type" fieldName="transactionType" sortCriteria={sortCriteria} handleSort={handleSort} />}
                                                                <SortableTableHeader label="Status" fieldName="status" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                <SortableTableHeader label="Invoice" fieldName="invoiceNumber" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                                {this.state.showPaystationDetails && <SortableTableHeader label="Paystation id" fieldName="paystationId" sortCriteria={sortCriteria} handleSort={handleSort} />}
                                                                {this.state.showPaystationDetails && <SortableTableHeader label="Paystation result" fieldName="paystationResultMessage" sortCriteria={sortCriteria} handleSort={handleSort} number />}
                                                                {this.state.showOrgFilter && <SortableTableHeader label="Org" fieldName="accountName" sortCriteria={sortCriteria} handleSort={handleSort} />}
                                                                <SortableTableHeader label="User" fieldName="userFullName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                <SortableTableHeader label="Farms" fieldName="farmNames" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                <SortableTableHeader label="Reference" fieldName="reference" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                <SortableTableHeader label="Amount" fieldName="amountExclGst" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                                <SortableTableHeader label="GST" fieldName="gst" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                                <SortableTableHeader label="Total" fieldName="amountInclGst" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((payment) => {
                                                                const paymentMethodOption = PAYMENT_METHOD_OPTIONS.find((option) => option.value === payment.paymentMethod);
                                                                const paymentStatusOption = PAYMENT_STATUS_OPTIONS.find((option) => option.value === payment.status);
                                                                const subType = {
                                                                    CardOnly: "",
                                                                    SaveToken: "Save card",
                                                                    UserSavedCard: "Used saved card",
                                                                    AutoSavedCard: "Auto renewal",
                                                                };
                                                                return (
                                                                    <tr key={payment.id} className="hover">
                                                                        <td valign="top">{utils.dateLong(payment.transactionTime, "-")}</td>
                                                                        <td valign="top">{paymentMethodOption ? paymentMethodOption.text : payment.paymentMethod}</td>
                                                                        <td valign="top">{subType[payment.creditCardSubTxnType]}</td>
                                                                        {authContext.isSystemAdmin && <td valign="top">{payment.transactionType}</td>}
                                                                        <td valign="top">{paymentStatusOption ? paymentStatusOption.text : payment.status}</td>
                                                                        <td valign="top">
                                                                            {payment.invoiceNumber && (
                                                                                <Link to={`/invoice/${payment.id}`} target="_blank">
                                                                                    {payment.invoiceNumber}
                                                                                </Link>
                                                                            )}
                                                                            {!payment.invoiceNumber && "-"}
                                                                        </td>
                                                                        {this.state.showPaystationDetails && (
                                                                            <td valign="top">
                                                                                <>
                                                                                    {payment.paystationId && authContext.isSystemAdmin ? (
                                                                                        <a rel="noreferrer" href={`https://admin.paystation.co.nz/transaction_details.php?txid=${payment.paystationId}`} target="_blank">
                                                                                            {payment.paystationId}
                                                                                        </a>
                                                                                    ) : (
                                                                                        <div>{payment.paystationId ? payment.paystationId : "-"}</div>
                                                                                    )}
                                                                                </>
                                                                            </td>
                                                                        )}
                                                                        {this.state.showPaystationDetails && <td valign="top">{payment.paystationResultMessage ? payment.paystationResultMessage : "-"}</td>}
                                                                        {this.state.showOrgFilter && <td valign="top">{payment.accountName}</td>}
                                                                        <td valign="top">{payment.userFullName}</td>
                                                                        <td valign="top" data-width="20">
                                                                            {payment.farms.length > 0 &&
                                                                                payment.farms.map((farm, index) => {
                                                                                    const isLastInTheList = index === payment.farms.length - 1;
                                                                                    return (
                                                                                        <span key={farm.id}>
                                                                                            <Link to={`/app/farm/${farm.id}`}>
                                                                                                {farm.name}
                                                                                                {isLastInTheList ? "" : ", "}
                                                                                            </Link>
                                                                                        </span>
                                                                                    );
                                                                                })}
                                                                            {payment.farms.length === 0 && <>-</>}
                                                                        </td>
                                                                        <td valign="top">{payment.reference}</td>
                                                                        <td className="u-textRight" valign="top">
                                                                            {CHARGEABLE_STATUSES.includes(payment.status) ? numeral(payment.amountExclGst).format("$0,0.00") : "-"}
                                                                        </td>
                                                                        <td className="u-textRight" valign="top">
                                                                            {CHARGEABLE_STATUSES.includes(payment.status) ? numeral(payment.gst).format("$0,0.00") : "-"}
                                                                        </td>
                                                                        <td className="u-textRight" valign="top">
                                                                            {CHARGEABLE_STATUSES.includes(payment.status) ? numeral(payment.amountInclGst).format("$0,0.00") : "-"}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                );
                                            }}
                                        </SortableTable>
                                    </React.Fragment>
                                )}
                            </PanelBody>
                        </Panel>
                    )}
                </PanelBody>
            </Panel>
        );
    }
}

export default PaymentList;
