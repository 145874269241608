import React from "react";
import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import Climate from "./Climate";
import SnowfallCreate from "./SnowfallCreate";
import SnowfallEdit from "./SnowfallEdit";

export default function ClimateTab({ farm, analysis }) {
    return (
        <Switch>
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/climate" render={() => <Climate farm={farm} analysis={analysis} />} restoreScrollY />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/climate/snowfall/new" render={() => <SnowfallCreate farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/climate/snowfall/:snowfallId" render={() => <SnowfallEdit farm={farm} analysis={analysis} />} />
        </Switch>
    )
}
