import SelectPack from "components/SelectPack";
import NumericInputPack from "components/NumericInputPack";
import * as utils from "common/utils";
import * as validations from "common/validations";
import CheckboxPack from "components/CheckboxPack";
import { useRefData } from "common/hooks";

export default function Bunker({ bunker, validation, onChange, activeControls, isFirst }) {
    const refData = useRefData();
    const { winPadBunkerLining = [], cleaningMethod = [] } = refData;

    if (!activeControls[`bunker_bunkerHeading`]) return null;

    return (
        <div className={`Grid Grid--withGutter ${isFirst ? "" : "u-mt-lg"}`}>
            <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                <h3>Bunker management</h3>
                <div className="Field-group">
                    <SelectPack meta={{ nrf: true }} isHidden={!activeControls[`bunker_bunkerLining`]} onChange={(e) => onChange(e, { type: "bunker", key: "bunkerLining" })} value={bunker.bunkerLining} val={validation[`bunker_bunkerLining`]} id={`bunker_bunkerLining`} label="Bunker lining material" requiredLabel={true}>
                        <option value="" disabled={true}>
                            Select a lining material
                        </option>
                        {utils.mapRefDataItems(winPadBunkerLining)}
                    </SelectPack>
                    <SelectPack meta={{ nrf: true }} isHidden={!activeControls[`bunker_cleaningMethod`]} onChange={(e) => onChange(e, { type: "bunker", key: "cleaningMethod" })} value={bunker.cleaningMethod} val={validation[`bunker_cleaningMethod`]} id={`bunker_cleaningMethod`} label="Bunker cleaning method" requiredLabel={true}>
                        <option value="" disabled={true}>
                            Select a cleaning method
                        </option>
                        {utils.mapRefDataItems(cleaningMethod.filter((c) => c.value !== "ScrapingAdded"))}
                    </SelectPack>
                </div>
                <div>
                    <NumericInputPack id={`bunker_timeBunker`} isHidden={!activeControls[`bunker_timeBunker`]} label="Time between adding animals and cleaning bunker" placeholder="Enter number of months" uom="months" requiredLabel={true} val={validation[`bunker_timeBunker`]} onChange={(e) => onChange(e, { type: "bunker", key: "timeBunker" })} decimalPlaces={0} value={bunker.timeBunker} />
                </div>
                <div>
                    <CheckboxPack meta={{ nrf: true }} isHidden={!activeControls[`bunker_liquidDrained`]} id={`liquidDrained`} label="Liquids drained away (added to liquid effluent)" value={bunker.liquidDrained} onChange={(e) => onChange(e, { type: "bunker", key: "liquidDrained" })} />
                </div>
            </div>
        </div>
    )
}

export function validateBunker(activeControls, validation, bunker, currentValidation, source, setVal) {
    let message = undefined;
    let key = undefined;
    let objkey = undefined;

    objkey = "bunkerLining";
    key = `bunker_${objkey}`;
    if (activeControls[key]) {
        message = validations.required(bunker[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }

    objkey = "cleaningMethod";
    key = `bunker_${objkey}`;
    if (activeControls[key]) {
        message = validations.required(bunker[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }

    objkey = "timeBunker";
    key = `bunker_${objkey}`;
    if (activeControls[key]) {
        message = validations.requiredExt(bunker[objkey]);
        message = message || validations.isNumeric(bunker[objkey]);
        message = message || validations.range(0, 400)(bunker[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }
}
