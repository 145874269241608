import React from "react";
import { compose, withAppContext } from "containers/HOC";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import * as FormUtils from "common/FormUtils";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import SelectPack from "components/SelectPack2";
import InputPack from "components/InputPack";

const addDisposal = (form) => (e) => {
    form.mutators.push("strawDisposals", {});
};

const removeDisposal = (form, structure, index) => (e) => {
    const results = structure.strawDisposals.reduce((acc, cur, i) => {
        if (i !== index) acc.push(cur);
        return acc;
    }, []);
    form.change("strawDisposals", results);
    form.mutators.setProperty("strawDisposals[0].proportion", 100);
};

const onProportionChange = (form, fields, index) => (e) => {
    const newValue = FormUtils.formatters.formatInt(e.target.value) || 0;
    let proportion = 100 - newValue;
    if (proportion < 0 || proportion > 100) proportion = 0;

    if (fields.length > 0) {
        if (index === 0) {
            form.mutators.setProperty("strawDisposals[1].proportion", proportion);
        } else {
            form.mutators.setProperty("strawDisposals[0].proportion", proportion);
        }
    }
};

const showCompostDisposal = (disposal) => ["Spreadonselectedblocks", "Otherexported"].includes(disposal.strawDisposal);

const showTimeInStorage = (disposal) => showCompostDisposal(disposal) && ["Coveredfromrain", "Opentorain"].includes(disposal.compostDisposal);

const validate = (strawDisposals) => {
    const errors = [];

    strawDisposals &&
        strawDisposals.forEach((disposal, index) => {
            const error = {};

            if (index === 1) {
                error.proportion = FormUtils.validators.required(disposal.proportion);
                error.proportion = error.proportion || FormUtils.validators.range(1, 99)(disposal.proportion);
            }

            error.strawDisposal = FormUtils.validators.required(disposal.strawDisposal);

            if (showCompostDisposal(disposal)) {
                error.compostDisposal = FormUtils.validators.required(disposal.compostDisposal);
            }

            if (showTimeInStorage(disposal)) {
                error.timeInStorage = FormUtils.validators.required(disposal.timeInStorage);
                error.timeInStorage = error.timeInStorage || FormUtils.validators.range(1, 9999)(disposal.timeInStorage);
            }

            errors.push(error);
        });

    return errors;
};

export const OutdoorPigHutsStrawDisposals = ({ form, structure, refData }) => {
    const actions =
        structure.strawDisposals.length === 1 ? (
            <ActionLink id="add-disposal" className="IconLink--arrow-plus u-link u-textWhite" onClick={addDisposal(form)}>
                Add straw disposal option
            </ActionLink>
        ) : undefined;

    return (
        <Panel title="Straw disposal" actions={actions} midBlue className="u-mt-lg">
            <PanelBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th className="th--shrink">Proportion</th>
                                <th>Straw management</th>
                                <th>Composting method</th>
                                <th>Time in storage</th>
                                <th className="th--shrink"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <FieldArray name="strawDisposals" validate={validate}>
                                {({ fields }) => {
                                    return fields.map((field, index) => {
                                        const disposal = fields.value[index];

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Field name={`${field}.proportion`} onChange={onProportionChange(form, fields, index)} disabled={index === 0} noLabel placeholder="0" uom="%" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                                </td>
                                                <td>
                                                    <Field name={`${field}.strawDisposal`} noLabel placeholder="Select a straw management system" options={refData.outdoorPigStrawDisposalMethods} required component={SelectPack} />
                                                </td>
                                                <td>{showCompostDisposal(disposal) && <Field name={`${field}.compostDisposal`} noLabel placeholder="Select a composting method" options={refData.outdoorPigStrawCompostingMethods} required component={SelectPack} />}</td>
                                                <td>{showTimeInStorage(disposal) && <Field name={`${field}.timeInStorage`} noLabel placeholder="0" uom="months" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />}</td>
                                                <td className="u-textCenter">{index === 1 && <ActionLink id="remove-disposal" className="IconLink--cross-circle" onClick={removeDisposal(form, structure, index)}></ActionLink>}</td>
                                            </tr>
                                        );
                                    });
                                }}
                            </FieldArray>
                        </tbody>
                    </table>
                </div>
            </PanelBody>
        </Panel>
    );
};

export default compose(withAppContext)(OutdoorPigHutsStrawDisposals);
