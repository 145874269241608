import { Component, Fragment } from "react";
import { Menu, Item, Separator, MenuProvider } from "react-contexify";
import { v4 as uuidv4 } from "uuid";
import * as icons from "common/icons";
import { useConfirm, useModalInline, useOpenReduxModal, useRefData, useShowQuickTips } from "common/hooks";
import * as utils from "common/utils";
import Alert from "components/Alert";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import * as cropUtils from "./_utils";
import Cropsown from "./Events/Cropsown";
import Defoliation from "./Events/Defoliation";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function Crops({ analysis, block, isRotation, growthCurve, validation, submitEvent, showCopyCrops, copyCrops }) {
    const refData = useRefData();
    const openReduxModal = useOpenReduxModal();
    const showQuickTips = useShowQuickTips();
    const { modalInlineOpen, modalInlineClose } = useModalInline();
    const confirm = useConfirm();

    return (
        <>
            <CropsClassComponent analysis={analysis} block={block} isRotation={isRotation} growthCurve={growthCurve} validation={validation} submitEvent={submitEvent} showCopyCrops={showCopyCrops} copyCrops={copyCrops} refData={refData} confirm={confirm} openReduxModal={openReduxModal} modalInlineOpen={modalInlineOpen} modalInlineClose={modalInlineClose} showQuickTips={showQuickTips} />
        </>
    )
}

class CropsClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillUnmount() {
        this.props.modalInlineClose();
    }

    addEvent(evtType, data) {
        switch (evtType) {
            case "crop-add":
                this.addCrop(data);
                break;
            case "crop-edit":
                this.editCrop(data);
                break;
            case "crop-del":
                this.setState({ activeModal: undefined });
                this.props.confirm(`Are you sure you want to delete this crop?`, () => this.props.submitEvent(evtType, data.cropId));
                break;
            case "crop-defol-add":
                this.addDefoliation(data);
                break;
            case "crop-defol-edit":
                this.editDefoliation(data);
                break;
            default:
                this.props.submitEvent(evtType, data);
                break;
        }
    }

    addDefoliation(data) {
        const { block = {} } = this.props;
        const { crops = [] } = block;
        const crop = crops.find((a) => a.id === data.cropId);
        const isReportingYear = cropUtils.indexToReportingYear(data.index, data.rotationYear);
        const monthIndex = cropUtils.getMonthIndex(data.index, data.rotationYear);
        const defoliation = { type: "Defoliation", month: data.rotationYear[data.index], reportingYear: isReportingYear, numberMonthsDefoliating: 1, monthIndex };
        const isLastMonth = data.rotationYear.length === data.index + 1 && isReportingYear === true;
        if (isLastMonth) {
            defoliation.maxAdditionalDefoliations = crop.thermalTimeEndIndex - data.index;
            defoliation.additionalDefoliations = 0;
        }
        this.setState({ activeModal: "defoliation", defoliation, crop, isLastMonth });
        this.props.modalInlineOpen();
    }

    editDefoliation(data) {
        const { block = {} } = this.props;
        const { crops = [] } = block;
        const { croppingEvents = [] } = block;
        const isReportingYear = cropUtils.indexToReportingYear(data.index, data.rotationYear);
        const month = data.rotationYear[data.index];
        const crop = crops.find((a) => a.id === data.cropId);
        const evt = crop ? cropUtils.getCropEvent(crop, month, ["Defoliation"], data.rotationYear, data.index) : croppingEvents.find((e) => e.type === "Defoliation" && e.month === month && e.reportingYear === isReportingYear);
        const defoliation = utils.clone(evt);
        defoliation.monthIndex = cropUtils.getMonthIndex(data.index, data.rotationYear);
        const isLastMonth = data.rotationYear.length === data.index + 1 && isReportingYear === true;
        if (isLastMonth) {
            defoliation.maxAdditionalDefoliations = crop.thermalTimeEndIndex - data.index;
        }
        this.setState({ activeModal: "defoliation", defoliation, crop, isLastMonth });
        this.props.modalInlineOpen();
    }

    saveDefoliation() {
        const { crop, defoliation } = this.state;
        const { block } = this.props;
        delete defoliation.incomplete;
        if (crop) {
            //Defoliation is on the crop
            const { events = [] } = crop;
            crop.events = events.filter((e) => !(e.month === defoliation.month && e.reportingYear === defoliation.reportingYear));
            crop.events.push(defoliation);
            if (defoliation.finalHarvest) {
                const rotationYear = cropUtils.rotationYear(block);
                cropUtils.clearTrailingEvents(defoliation, crop, rotationYear);

                if (defoliation.defoliationFate === "ToStorage") {
                    crop.events = crop.events.filter((e) => e.type === "Cropsown" || e === defoliation);
                }
            }
            this.props.submitEvent("crop-save", crop);
        } else {
            //Defoliation is on prior land use as gain crops
            const { croppingEvents = [] } = block;
            block.croppingEvents = croppingEvents.filter((e) => !(e.month === defoliation.month && e.reportingYear === defoliation.reportingYear));
            block.croppingEvents.push(defoliation);
        }
        this.props.modalInlineClose();
    }

    addCrop(data) {
        const crop = { id: uuidv4(), events: [] };
        const { block = {} } = this.props;
        const { croppingEvents = [] } = block;
        const isReportingYear = cropUtils.indexToReportingYear(data.index, data.rotationYear);
        crop.events.push({ type: "Cropsown", month: data.rotationYear[data.index], reportingYear: isReportingYear });
        this.setState({ activeModal: "cropsown", crop: crop, isEdit: false, isAutoRotationEvent: false, croppingEvents: utils.clone(croppingEvents) });
        this.props.modalInlineOpen();
    }

    editCrop(data) {
        const { block = {} } = this.props;
        const { crops = [], croppingEvents = [] } = block;
        const crop = crops.find((a) => a.id === data.cropId);
        this.setState({ activeModal: "cropsown", isEdit: true, crop: utils.clone(crop), isAutoRotationEvent: data.isAutoRotationEvent, croppingEvents: utils.clone(croppingEvents) });
        this.props.modalInlineOpen();
    }

    saveCrop() {
        this.props.submitEvent("crop-save-withevents", { crop: this.state.crop, croppingEvents: this.state.croppingEvents });
        this.props.modalInlineClose();
    }

    copyCrops() {
        const { block, analysis } = this.props;
        const viewModel = { block: utils.clone(block), analysis };
        const swapCrops = (cropData = []) => {
            if (cropData.length !== 3) return;
            this.props.copyCrops(cropData);
        };
        this.props.openReduxModal("CopyCropsModal", swapCrops, viewModel);
    }

    closeModal() {
        this.props.modalInlineClose();
        this.setState({ activeModal: undefined });
    }

    render() {
        const categoryFilter = this.props.isRotation ? ["Fodder", "Forages", "Pasture"] : undefined;
        const { block = {}, refData, validation = {}, analysis, showQuickTips } = this.props;
        const { cropsVal = {} } = validation;
        const rotationYear = cropUtils.rotationYear(block);
        let { crops = [] } = block;
        crops = cropUtils.sortCrops(crops, rotationYear);
        let { growthCurve = [] } = this.props;
        if (!Array.isArray(growthCurve)) growthCurve = [];

        const curveCells = cropUtils.mapGrowthCurve(growthCurve, crops, block, rotationYear, analysis);
        const labelCells = cropUtils.mapLabels(block, growthCurve, refData);
        const animalCells = cropUtils.mapAnimals(block, analysis, refData);
        const { cropBlock = {} } = block;

        const cropBlockMatches = analysis.blocks.filter((b) => b.type === block.type && b.id !== block.id && b.monthResown && b.monthResown !== "Undefined" && b.monthResown === block.monthResown);

        let incompleteDefols = false;
        for (const c of crops) {
            const { events = [] } = c;
            if (events.find((e) => e.incomplete)) {
                incompleteDefols = true;
                break;
            }
        }

        const cropHelp = this.props.isRotation ? "Fodder crop rotations are entered for the reporting year only. Add a new event by selecting the + for the month that the event occurred. Adding a crop will create a sowing event. The land is re-sown back into pasture on the final month of the rotation." : "Crop rotations are entered for the reporting year and the previous year. Add a new event by selecting the + for the month that the event occurred. Adding a crop will create a sowing event. When adding a crop a finish month and event for the sown crop is required. The finish month can be 12 month after the reporting year (post reporting year).";

        return (
            <div className="u-mt-xl" style={{ minHeight: "300px" }}>
                {this.state.crop && this.state.activeModal === "cropsown" && <Cropsown analysis={this.props.analysis} block={block} isRotation={this.props.isRotation} crop={this.state.crop} croppingEvents={this.state.croppingEvents} setCrop={(c, e) => this.setState({ crop: c, croppingEvents: e })} categoryFilter={categoryFilter} save={() => this.saveCrop()} cancel={() => this.closeModal()} isEdit={this.state.isEdit} isAutoRotationEvent={this.state.isAutoRotationEvent || false} />}
                {this.state.defoliation && this.state.activeModal === "defoliation" && <Defoliation analysis={this.props.analysis} block={block} isRotation={this.props.isRotation} crop={this.state.crop} defoliation={this.state.defoliation} setDefoliation={(d) => this.setState({ defoliation: d })} rotationYear={rotationYear} isLastMonth={this.state.isLastMonth} save={() => this.saveDefoliation()} cancel={() => this.closeModal()} />}
                <Tile
                    title={block.name ? "Crops on " + block.name : "Crops"}
                    tertiary
                    actions={
                        this.props.showCopyCrops &&
                        cropBlockMatches.length > 0 && (
                            <span className="IconLink--arrow-plus u-link" id="structure_add_animals" onClick={() => this.copyCrops()}>
                                Copy crops from another block
                            </span>
                        )
                    }
                >
                    <Alert type="info" className={incompleteDefols ? "u-mb-0" : ""} text={cropHelp} />
                    {incompleteDefols && <Alert type="error" text="One or more crops have incomplete defoliation information. Please click on the defoliation event and complete the missing details. Note: If the final event of a crop is “Final defoliation” and the final month is in the post-reporting year you will have the ability to delete the defoliation event that is on the final month." />}
                    {showQuickTips && (
                        <Alert
                            type="help"
                            text={
                                <div>
                                    <b>Events</b>
                                    <div className="u-pb-sm">The following events can be added to the crop rotation by selecting the + for the month that the event occurred</div>
                                    <ul>
                                        <li className="u-pb-sm">
                                            <b>Add crop:</b> Add the details of the crop that is sown on the month that you have selected. It is assumed the crop is sown at the start of the month.
                                        </li>

                                        <li className="u-pb-sm">
                                            <b>Add defoliation:</b> Add the details of whether the crop was grazed in place or removed (cut and carry). You can add one or many defoliation events between the sown and final month of the crop. When a crops <i>Final event</i> is <i>Final defoliation</i>, a defoliation event will be created on the crops final month and the defoliation details will be required to be entered.
                                        </li>
                                        <li>
                                            <b>Cultivation:</b> Cultivation events can only be entered on months that are not within a crops sown and finish month. Cultivation can be entered as a crops final event within the add crop screen and any existing crop growth is stopped at the end of the previous month.
                                        </li>
                                    </ul>
                                    <br />
                                    <b>Ending a crop which has regrown after the last defoliation</b>
                                    <br />
                                    Seed and pasture crops will regrow after harvest and defoliation and may be sprayed out or pulled before the next crop is sown. This can be accounted for by selecting <i>End crop</i> for the final event within the crop sown screen. The block is left fallow and regrowth is retained. This is not an option for forage and fodder crops, e.g. kale and forage oats, because the final event must be either a cultivation or a final defoliation.
                                    <br />
                                    <br />
                                    <b>Pasture renewal</b>
                                    <br />
                                    Pasture renewal as a part of the crop rotation can be included by ending an existing crop with a <i>End crop{this.props.isRotation ? "" : " or Ongoing"}</i> final event and then sowing a pasture crop immediately after the existing pasture crop.
                                    <br />
                                    {!this.props.isRotation && (
                                        <>
                                            <br />
                                            <b>Default harvest times for seed crops</b>
                                            <br />
                                            Ryegrass seed crops are by default harvested in December, clover seed is harvested in January.
                                        </>
                                    )}
                                </div>
                            }
                        />
                    )}
                    <TileBody>
                        {block.monthResown && block.monthResown !== "Undefined" && (
                            <div className="Table">
                                <table className="Calendar u-tbl-fix">
                                    <thead>
                                        {rotationYear.length > 12 && (
                                            <tr className="Calendar-reportingyears">
                                                <td colSpan="12" className="Year1 Solid-border">
                                                    <span className="h5">Year 1</span>
                                                </td>
                                                <td colSpan="12">
                                                    <span className="h5">Reporting Year</span>
                                                </td>
                                            </tr>
                                        )}
                                        <tr className="Calendar-months">
                                            {rotationYear.map((m, i) => (
                                                <th key={i} className="Calendar-month">
                                                    {m.substring(0, 3)}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {crops.length > 0 && rotationYear.length === growthCurve.length && <tr>{cropUtils.growthCurveCells(curveCells)}</tr>}
                                        {(cropBlock.preCrop || crops.length > 0) && (
                                            <tr className="Calendar-slots Calendar-slots--crop">
                                                {rotationYear.map((m, i) => {
                                                    return cropUtils.renderCropIconMonth(rotationYear, crops, i, block, growthCurve, analysis);
                                                })}
                                            </tr>
                                        )}
                                        {animalCells.some((l) => l.iconText) && (
                                            <tr className="Calendar-slots Calendar-slots--crop">
                                                {animalCells.map((cell, i) => {
                                                    return (
                                                        <td key={i} className={"Calendar-slot"}>
                                                            {cell.icon && <img className="Calendar-icon u-p-xxs" src={cell.icon} alt="crop icon" style={{ opacity: cell.preCrop ? "0.2" : "1" }} />}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        )}
                                        {labelCells.some((l) => l.iconText) && (
                                            <tr className="Calendar-slots Calendar-slots--crop">
                                                {labelCells.map((cell, i) => {
                                                    return (
                                                        <td key={i} colSpan={cell.colSpan} className={"Calendar-slot"}>
                                                            {cell.iconText && (
                                                                <span className="h6" style={{ opacity: cell.preCrop ? "0.6" : "1" }}>
                                                                    {cell.iconText}
                                                                </span>
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        )}
                                        <tr className="Calendar-slots Calendar-slots--crop">{rotationYear.map((m, i) => renderEventMonth(curveCells, rotationYear, crops, i, block, analysis, refData, this.props.isRotation, (evtType, data) => this.addEvent(evtType, data)))}</tr>
                                    </tbody>
                                </table>
                                {cropsVal.touched && cropsVal.error && (
                                    <div className="Field has-error u-mt-sm">
                                        <small className="Field-error" id="crops_error">
                                            {cropsVal.message}
                                        </small>
                                    </div>
                                )}
                            </div>
                        )}
                    </TileBody>
                </Tile>
            </div>
        );
    }
}

export function validate(block, validation, currentValidation, source) {
    const { cropBlock = {} } = block;
    const priorLandUseSeedCrop = ["Firstyearofseedcrop", "Secondyearofseedcrop"].includes(cropBlock.preCrop);
    const message = !priorLandUseSeedCrop && (!block.crops || block.crops.length === 0) ? "Please add at least one crop" : undefined;
    validation.cropsVal = utils.setVal("cropsVal", currentValidation, source, message);
}

const renderEventMonth = (curveCells, rotationYear, crops, i, block, analysis, refData, isRotation, addEvent) => {
    const { croppingEvents = [] } = block;
    const month = rotationYear[i];
    const isReportingYear = cropUtils.indexToReportingYear(i, rotationYear);
    const [crop, cropEvt] = cropUtils.getFirstCropEvent(crops, i, ["Cropsown", "Endcrop", "Enduptake", "Cropharvest", "Defoliation"], rotationYear, true);

    const isIconType = cropEvt && ["Cropsown", "Enduptake", "Defoliation"].includes(cropEvt.type);
    const croppingEvt = croppingEvents.find((e) => e.month === rotationYear[i] && e.reportingYear === isReportingYear);
    const idPrefix = `${isReportingYear ? "RY" : "Y1"}_${month}_`;
    const { cropManagement = [], defoliationFate = [] } = refData;
    const isFodderCrop = rotationYear.length === 12;
    const thermalTimeIndex = isFodderCrop ? i + 12 : i;
    const isFinishMonth = crops.find((c) => c.thermalTimeEndIndex === thermalTimeIndex + 1);
    const showCultivate = croppingEvt && !isFinishMonth;
    const actionMenu = renderActionMenu(curveCells, rotationYear, crops, i, block, analysis, refData, addEvent, isRotation);
    const hideAction = actionMenu[1].length === 0;
    const currentCrop = crops.find((c) => cropUtils.isCropMonth(rotationYear, c, i));
    const isDefolToStorage = currentCrop && currentCrop.events && currentCrop.events.find((e) => e.type === "Defoliation" && e.finalHarvest && e.defoliationFate === "ToStorage");

    return (
        <td style={{ height: "50px", backgroundColor: cropEvt && cropEvt.incomplete ? "#F8EDEC" : "" }} className="Calendar-slot" key={`crop_${i}`}>
            <MenuProvider event="onClick" id={`menu_${i}`}>
                {isIconType && (
                    <div style={{ cursor: "pointer" }}>
                        <img className="Calendar-icon u-p-xxs" src={cropUtils.getEventIcon(cropEvt, crop, analysis)} alt="crop icon" style={cropEvt.isAdditionalDefoliationEvent ? { opacity: ".3" } : null} />
                        {!cropEvt.isAdditionalDefoliationEvent && (
                            <span className="Calendar-icon-text" id={crop.id}>
                                {["ToStorage", "Exported"].includes(cropEvt.defoliationFate) ? utils.valueToText(defoliationFate, cropEvt.defoliationFate) : utils.valueToText(cropManagement, cropEvt.type)}
                            </span>
                        )}
                    </div>
                )}
                {showCultivate && (
                    <div style={{ cursor: "pointer" }}>
                        <div>
                            <img className="Calendar-icon u-p-xxs" src={croppingEvt.type === "Cultivation" ? icons.cultivate1 : cropUtils.getDefoliationIcon(analysis, croppingEvt)} alt="cultivate" />
                            <span className="Calendar-icon-text">{croppingEvt.type === "Cultivation" ? "Cultivate" : ["ToStorage", "Exported"].includes(cropEvt.defoliationFate) ? utils.valueToText(defoliationFate, cropEvt.defoliationFate) : "Defoliation"}</span>
                        </div>
                        <div style={{ fontSize: 24, color: "#5DA2C4", position: "absolute", top: 0, right: 8, marginTop: -15 }} id={`${idPrefix}_event-edit`}>
                            ...
                        </div>
                    </div>
                )}

                {isIconType ? (
                    (cropEvt && cropEvt.isAdditionalDefoliationEvent) || hideAction ? null : (
                        <div style={{ fontSize: 24, color: "#5DA2C4", position: "absolute", top: 0, right: 8, marginTop: -15, cursor: "pointer" }} id={`${idPrefix}_event-edit`}>
                            ...
                        </div>
                    )
                ) : (
                    <>
                        {!showCultivate && !hideAction && !isDefolToStorage && (
                            <div style={{ fontSize: 30, color: "#5DA2C4", position: "relative", top: 0, margin: "0 auto", cursor: "pointer" }} id={`${idPrefix}_event-add`}>
                                +
                            </div>
                        )}
                    </>
                )}
            </MenuProvider>
            {!hideAction && actionMenu[0]}
        </td>
    );
}

const renderActionMenu = (curveCells, rotationYear, crops, indx, block, analysis, refData, addEvent, isRotation) => {
    const isReportingYear = cropUtils.indexToReportingYear(indx, rotationYear);
    const month = rotationYear[indx];
    const cultivateEvent = cropUtils.croppingEvent(rotationYear, block, indx, "Cultivation");
    const priorLandDefol = cropUtils.croppingEvent(rotationYear, block, indx, "Defoliation");
    const [endCrop, endCropEvent] = cropUtils.getFirstCropEvent(crops, indx, ["Endcrop"], rotationYear);
    const [cropHarvest, cropHarvestEvent] = cropUtils.getFirstCropEvent(crops, indx, ["Cropharvest"], rotationYear);
    const [endUptakeCrop, endUptakeEvent] = cropUtils.getFirstCropEvent(crops, indx, ["Enduptake"], rotationYear);
    const [sownCrop, sownCropEvent] = cropUtils.getFirstCropEvent(crops, indx, ["Cropsown"], rotationYear);
    const [defolCrop, defolCropEvent] = cropUtils.getFirstCropEvent(crops, indx, ["Defoliation"], rotationYear, true);
    const anyEvent = cropUtils.monthHasEvent(isReportingYear, indx, crops, block, rotationYear);
    const crop = crops.find((c) => cropUtils.isCropMonth(rotationYear, c, indx));
    const hasTrailingEndUptake = crop && crop.events && crop.events.filter((e) => e.type === "Enduptake").some((e) => cropUtils.rotationIndex(e, rotationYear) > indx);
    //const hasPrecedingDefol = crop && crop.events && crop.events.filter((e) => e.type === "Defoliation").some((e) => cropUtils.rotationIndex(e, rotationYear) < indx);
    const { category } = crop || {};
    const isFodderCrop = rotationYear.length === 12;
    const thermalTimeIndex = isFodderCrop ? indx + 12 : indx;
    const firstCropSownIndx = cropUtils.getFirstCropSownIndex(crops, rotationYear);
    const isBeforeFirstCrop = !firstCropSownIndx || indx < firstCropSownIndx;
    const isAfterOrEqualToCropFinish = crop && crop.thermalTimeEndIndex - 1 <= thermalTimeIndex;
    const isFinishMonth = crops.find((c) => c.thermalTimeEndIndex === thermalTimeIndex + 1);
    const cultivationIsFinishEvent = cultivateEvent && isFinishMonth;
    const endCropIsFinishEvent = endCropEvent && isFinishMonth;

    const idPrefix = `${isReportingYear ? "RY" : "Y1"}_${month}`;
    const MenuItem = ({ icon, text, evt, crop, isAutoRotationEvent }) => (
        <Item id={`mi_${idPrefix}_${evt}`} onClick={() => addEvent(evt, { rotationYear: rotationYear, index: indx, cropId: crop ? crop.id : undefined, isAutoRotationEvent: isAutoRotationEvent })}>
            <div className="u-flex">
                <img src={icon} style={{ height: 24, marginBottom: "-7px" }} className="u-p-5" alt={text} />
                <span style={{ paddingLeft: 8 }} id={`${idPrefix}_${evt}`}>
                    {text}
                </span>
            </div>
        </Item>
    );

    //const cropEndCurveMonth = crop && curveCells && curveCells.reduce((t,c)=>  c.cropId === crop.id ? t.e +=   ,{f:false, e: 0})
    const { cropBlock = {} } = block;
    const isGrainCrop = cropBlock.preCrop === "Graincrop" && isBeforeFirstCrop;
    const isAutoRotationEvent = indx === rotationYear.length - 1 && isRotation && crop && crop.cropId === "Pasture";
    const cropType = cropUtils.findCropType(crop, refData);
    const refDataAllowDefoliation = !cropType || cropType.allowedDefoliation;
    const refDataAllowHarvest = !cropType || cropType.allowedHarvest;
    const allowCropAdd = !sownCropEvent && !priorLandDefol && ((!crop && !cultivateEvent) || endCropIsFinishEvent || cropHarvestEvent);
    const allowCropEdit = sownCrop;
    const allowCropDelete = sownCrop && sownCropEvent && !isAutoRotationEvent;
    const allowCropEnd = crop && !anyEvent && (refDataAllowHarvest || ["Seed", "GreenManure"].includes(category) || crop.cropId === "Pasture") && false;
    const allowCropHarvest = crop && !anyEvent && refDataAllowHarvest && false;
    const allowCropEndDel = endCropEvent && endCrop && false;
    const allowCropHarvestDel = cropHarvestEvent && cropHarvest && false;

    //const isWithin2MonthsOfCurveHarvest = curveCells && curveCells.fn;
    //const allowEndUptake = crop && !anyEvent && !hasPrecedingDefol && !(category === "Seed" || (category === "Forages" && cropId === "AnnualRyegrass") || crop.cropId === "Pasture");
    const allowEndUptake = false;
    const allowEndUptakeDel = endUptakeCrop && endUptakeEvent;
    const allowDefol = (crop || isGrainCrop) && !anyEvent && refDataAllowDefoliation && !hasTrailingEndUptake && !priorLandDefol;
    const isFinalDefolEvent = defolCrop && (defolCrop.finalHarvest || (defolCrop.month === rotationYear[11].month && defolCrop.reportingYear));
    const allowDefolDel = (defolCrop && defolCropEvent && !defolCropEvent.isAdditionalDefoliationEvent && !defolCropEvent.finalHarvest && !isFinalDefolEvent) || priorLandDefol;
    const allowDefolEdit = (defolCrop && defolCropEvent && !defolCropEvent.isAdditionalDefoliationEvent) || priorLandDefol;
    const allowCultivateAdd = !anyEvent && (!crop || isAfterOrEqualToCropFinish);
    const allowCultivateDel = cultivateEvent && !cultivationIsFinishEvent;

    const menuItems = [];
    allowCultivateAdd && menuItems.push(<MenuItem icon={icons.smallCultivate1} text="Cultivate" evt="cultivation-add" crop={crop} />);
    allowCultivateDel && menuItems.push(<MenuItem icon={icons.deleteIcon} text="Delete cultivate" evt="cultivation-del" crop={crop} />);
    allowCropEdit && menuItems.push(<MenuItem icon={icons.editIcon} text="Edit sown" evt="crop-edit" crop={sownCrop} isAutoRotationEvent={isAutoRotationEvent} />);
    allowCropAdd && menuItems.push(<MenuItem icon={icons.sowing1} text="Add crop" evt="crop-add" crop={crop} />);
    allowCropDelete && menuItems.push(<MenuItem icon={icons.deleteIcon} text="Delete crop" evt="crop-del" crop={sownCrop} />);
    allowCropEnd && menuItems.push(<MenuItem icon={icons.sowing1} text="End crop" evt="crop-end" crop={crop} />);
    allowCropEndDel && menuItems.push(<MenuItem icon={icons.deleteIcon} text="Delete end crop" evt="crop-end-del" crop={crop} />);
    allowCropHarvest && menuItems.push(<MenuItem icon={icons.smallHarvest2} text="Add harvest" evt="crop-harvest" crop={crop} />);
    allowCropHarvestDel && menuItems.push(<MenuItem icon={icons.deleteIcon} text="Delete harvest" evt="crop-harvest-del" crop={crop} />);
    allowEndUptake && menuItems.push(<MenuItem icon={icons.pasture} text="End uptake" evt="crop-enduptake" crop={crop} />);
    allowEndUptakeDel && menuItems.push(<MenuItem icon={icons.deleteIcon} text="Delete end uptake" evt="crop-enduptake-del" crop={crop} />);
    allowDefol && menuItems.push(<MenuItem icon={cropUtils.getAnimalIcon(undefined, crop, analysis)} text="Add defoliation" evt="crop-defol-add" crop={crop} />);
    allowDefolDel && menuItems.push(<MenuItem icon={icons.deleteIcon} text="Delete defoliation" evt="crop-defol-del" crop={crop} />);
    allowDefolEdit && menuItems.push(<MenuItem icon={icons.editIcon} text="Edit defoliation" evt="crop-defol-edit" crop={crop} />);

    return [
        <Menu id={`menu_${indx}`}>
            {menuItems.map((m, i) => (
                <Fragment key={i}>
                    {m} {i < menuItems.length - 1 && <Separator />}
                </Fragment>
            ))}
        </Menu>,
        menuItems,
    ];
}
