import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router-dom";

const PATHS = ["/app/farm/:farmId/analysis/:budgetId/blocks", "/app/farm/:farmId/analysis/:budgetId/climate", "/app/farm/:farmId/analysis/:budgetId/soil", "/app/farm/:farmId/analysis/:budgetId/drainage", "/app/farm/:farmId/analysis/:budgetId/crops", "/app/farm/:farmId/analysis/:budgetId/animals", "/app/farm/:farmId/analysis/:budgetId/structures", "/app/farm/:farmId/analysis/:budgetId/supplements", "/app/farm/:farmId/analysis/:budgetId/fertiliser", "/app/farm/:farmId/analysis/:budgetId/irrigation"];

let prevPathname = "";
let scrollY = 0;

const routeHistoryMiddleware = () => (next) => (action) => {
    if (action.type === LOCATION_CHANGE) {
        const newAction = {
            ...action,
            payload: {
                ...action.payload,
                prevPathname,
            },
        };

        const comingFromBlocksParent = matchPath(prevPathname, { path: "/app/farm/:farmId/analysis/:budgetId", exact: true });
        const goingToBlocksParent = matchPath(action.payload.location.pathname, { path: "/app/farm/:farmId/analysis/:budgetId", exact: true });
        const comingFromBlocksChild = matchPath(prevPathname, { path: "/app/farm/:farmId/analysis/:budgetId/blocks/:blockId", exact: true });
        const goingToBlocksChild = matchPath(action.payload.location.pathname, { path: "/app/farm/:farmId/analysis/:budgetId/blocks/:blockId", exact: true });

        PATHS.forEach((path) => {
            const prevRouteMatch = matchPath(prevPathname, { path });
            const nextRouteMatch = matchPath(action.payload.location.pathname, { path });

            const comingFromParent = comingFromBlocksParent || (prevRouteMatch ? prevRouteMatch.isExact : false);
            const goingToParent = goingToBlocksParent || (nextRouteMatch ? nextRouteMatch.isExact : false);

            const comingFromChild = comingFromBlocksChild || (prevRouteMatch ? !prevRouteMatch.isExact : false);
            const goingToChild = goingToBlocksChild || (nextRouteMatch ? !nextRouteMatch.isExact : false);

            if (comingFromParent && goingToChild) {
                // Record the scroll Y position.
                scrollY = window.scrollY;
            } else if (goingToParent) {
                newAction.payload.location.scrollY = comingFromChild ? scrollY : 0;
                scrollY = comingFromChild ? scrollY : 0;
            }
        });

        prevPathname = action.payload.location.pathname;
        return next(newAction);
    }
    return next(action);
};
export default routeHistoryMiddleware;
