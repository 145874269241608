import Tile from "components/Tile";
import TileBody from "components/TileBody";
import React from "react";
import * as utils from "common/utils";
import EmptyCustom from "./EmptyCustom";
import ActionLink from "components/ActionLink";

const UserDefinedCrops = ({ remove, edit, crops = [], refData, online, showDelete }) => {
    return (
        <div className="Table u-mt-md">
            <table>
                <thead>
                    <tr>
                        <th data-width="md-5 xl-5">Name</th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            Yield
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            Base crop
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            DM content
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            ME content
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            Max root depth
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            N fixed
                        </th>
                        {online && showDelete && <th className="th--shrink"></th>}
                    </tr>
                </thead>
                <tbody>
                    {crops.map((crop, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <ActionLink className="u-link" onClick={() => edit(crop)}>
                                        {crop.name}
                                    </ActionLink>
                                </td>
                                <td className="u-textCenter">{crop.typicalYield}</td>
                                <td className="u-textCenter">{crop.baseCropType}</td>
                                <td className="u-textCenter">{crop.dmContent}</td>
                                <td className="u-textCenter">{crop.meContent}</td>
                                <td className="u-textCenter">{crop.maxRootDepth}</td>
                                <td className="u-textCenter">{crop.nFixed}</td>
                                {online && showDelete && (
                                    <td>
                                        <ActionLink id={`remove-${[crop.id]}`} className="IconLink--cross-circle" onClick={() => remove(crop)}>
                                            <span></span>
                                        </ActionLink>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const CustomItem = ({ remove, edit, products = [], showCl, type, refData, online, showDelete }) => {
    let unit = ["Irrigation"].includes(type) ? "" : "%";
    const { irrigatorNutrientUnits = [] } = refData;

    return (
        <div className="Table u-mt-md">
            <table>
                <thead>
                    <tr>
                        <th data-width="md-5 xl-5">Name</th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            N{unit}
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            P{unit}
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            K{unit}
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            S{unit}
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            Ca{unit}
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            Mg{unit}
                        </th>
                        <th data-width="md-5 xl-5" className="u-textCenter">
                            Na{unit}
                        </th>
                        {showCl && (
                            <th data-width="md-5 xl-5" className="u-textCenter">
                                Cl{unit}
                            </th>
                        )}
                        {online && showDelete && <th className="th--shrink"></th>}
                    </tr>
                </thead>
                <tbody>
                    {products.map((product, index) => {
                        const { nutrients = {} } = product;
                        switch (type) {
                            case "Irrigation":
                                unit = utils.valueToText(irrigatorNutrientUnits, product.irrigationUnit);
                                break;
                            default:
                                unit = "";
                                break;
                        }
                        return (
                            <tr key={index}>
                                <td>
                                    <ActionLink className="u-link" onClick={() => edit(product)}>
                                        {product.name}
                                    </ActionLink>
                                </td>
                                <td className="u-textCenter">{nutrients.N ? `${nutrients.N}${unit}` : "-"}</td>
                                <td className="u-textCenter">{nutrients.P ? `${nutrients.P}${unit}` : "-"}</td>
                                <td className="u-textCenter">{nutrients.K ? `${nutrients.K}${unit}` : "-"}</td>
                                <td className="u-textCenter">{nutrients.S ? `${nutrients.S}${unit}` : "-"}</td>
                                <td className="u-textCenter">{nutrients.Ca ? `${nutrients.Ca}${unit}` : "-"}</td>
                                <td className="u-textCenter">{nutrients.Mg ? `${nutrients.Mg}${unit}` : "-"}</td>
                                <td className="u-textCenter">{nutrients.Na ? `${nutrients.Na}${unit}` : "-"}</td>
                                {showCl && <td className="u-textCenter">{product.clValue ? `${product.clValue}${unit}` : "-"}</td>}
                                {online && showDelete && (
                                    <td>
                                        <ActionLink id={`remove-${[product.id]}`} className="IconLink--cross-circle" onClick={() => remove(product)}>
                                            <span></span>
                                        </ActionLink>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const CustomList = ({ compositions = [], crops = [], edit, remove, editCrop, removeCrop, refData, online, title, emptyLabel, showDelete }) => {
    return (
        <Tile title={title} tertiary>
            <TileBody>
                {compositions.some((p) => p.type === "Fertiliser") && (
                    <React.Fragment>
                        <div className="h2 u-mt-md">Fertiliser</div>
                        <CustomItem products={compositions.filter((p) => p.type === "Fertiliser")} edit={edit} remove={remove} type="Fertiliser" refData={refData} online={online} showDelete={showDelete} />
                    </React.Fragment>
                )}
                {compositions.some((p) => p.type === "Effluent") && (
                    <React.Fragment>
                        <div className="h2 u-mt-md">Factory effluent</div>
                        <CustomItem products={compositions.filter((p) => p.type === "Effluent")} edit={edit} remove={remove} type="Effluent" refData={refData} online={online} showDelete={showDelete} />
                    </React.Fragment>
                )}
                {compositions.some((p) => p.type === "Irrigation") && (
                    <React.Fragment>
                        <div className="h2 u-mt-md">Irrigation</div>
                        <CustomItem products={compositions.filter((p) => p.type === "Irrigation")} edit={edit} remove={remove} type="Irrigation" refData={refData} online={online} showDelete={showDelete} />
                    </React.Fragment>
                )}
                {compositions.some((p) => p.type === "Lime") && (
                    <React.Fragment>
                        <div className="h2 u-mt-md">Lime</div>
                        <CustomItem products={compositions.filter((p) => p.type === "Lime")} edit={edit} remove={remove} type="Lime" refData={refData} online={online} showDelete={showDelete} />
                    </React.Fragment>
                )}
                {compositions.some((p) => p.type === "Supplements") && (
                    <React.Fragment>
                        <div className="h2 u-mt-md">Supplements</div>
                        <CustomItem products={compositions.filter((p) => p.type === "Supplements")} edit={edit} remove={remove} type="Supplements" refData={refData} online={online} showCl={true} showDelete={showDelete} />
                    </React.Fragment>
                )}
                {compositions.length === 0 && <EmptyCustom label={emptyLabel} />}
                {crops && crops.length > 0 && (
                    <React.Fragment>
                        <div className="h2 u-mt-md">User defined crops</div>
                        <UserDefinedCrops crops={crops} edit={editCrop} remove={removeCrop} refData={refData} online={online} showDelete={showDelete} />
                    </React.Fragment>
                )}
            </TileBody>
        </Tile>
    );
};

export default CustomList;
