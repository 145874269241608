export const GHG_SECTION_NAMES = {
    FUEL: "fuel",
    FERTILISER: "fertiliser",
    TRANSPORT: "transport",
    ELECTRICITY: "electricity",
};

export const GHG_SECTIONS = [
    { name: GHG_SECTION_NAMES.FUEL, title: "Fuel footprint", actionText: "Override fuel footprint" },
    { name: GHG_SECTION_NAMES.FERTILISER, title: "Fertiliser application footprint", actionText: "Override fertiliser application footprint" },
    { name: GHG_SECTION_NAMES.TRANSPORT, title: "Transportation footprint", actionText: "Override transportation footprint" },
    { name: GHG_SECTION_NAMES.ELECTRICITY, title: "Electricity footprint", actionText: "Override electricity footprint" },
];
