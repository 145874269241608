import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { fetchRefData } from "containers/App/_actions";
import { getCompositions } from "containers/Custom/_actions";

export function useFetchRefDataOnAuth() {
    const dispatch = useDispatch();
    const lastLoginTime = useSelector((state) => state.auth.lastLoginTime);

    useEffect(() => {
        dispatch(fetchRefData());
    }, [dispatch, lastLoginTime])
}

export function useFetchCustomCompositionsOnAuth() {
    const dispatch = useDispatch();
    const lastLoginTime = useSelector((state) => state.auth.lastLoginTime);

    useEffect(() => {
        dispatch(getCompositions());
    }, [dispatch, lastLoginTime])
}

export function useClearQueryCacheOnLogout() {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (queryClient && !isAuthenticated) {
            queryClient.clear();
        }
    }, [isAuthenticated, queryClient]);
}
