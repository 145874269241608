import Alert from "components/Alert";
import FeatureTracker, { FEATURES } from "components/FeatureTracker/FeatureTracker";
import Emissions from "./Emissions";
import ProductionFootprint from "./ProductionFootprint";
import CarbonStock from "./CarbonStock";
import { useAnalysisResults } from "containers/BudgetHome";

export default function Ghg({ farm, analysis }) {
    const { isLoading, data: analysisResults } = useAnalysisResults(analysis);
    const hasGhgResults = isLoading || analysisResults?.ghg !== undefined;

    return (
        <>
            <FeatureTracker feature={FEATURES.GHG_REPORT} farm={farm} />
            {hasGhgResults
                ? <>
                    <Alert type="info" text={<span>You can print this page by selecting print under the browser menu. Landscape is recommended for this report.</span>} />
                    <Emissions analysis={analysis} />
                    <ProductionFootprint latestResults={analysis.currentResults} />
                    <CarbonStock farm={farm} analysis={analysis} />
                </>
                : <Alert type="error" text="There are no GHG results for this analysis" />
            }
        </>
    )
}
