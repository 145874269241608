import { useState } from "react";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import * as utils from "common/utils";
import { getAnalysisOptions } from "containers/FarmHome/_utils";
import { GridCell } from "components/Grid";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";
import SelectField from "components/FormFields/SelectField";
import Spinner from "components/Spinner/Spinner";
import { useBenchmarkData, useBenchmarkChartData } from "containers/Reporting/_hooks";
import { useAnalysis } from "containers/hooks";
import { useOnline } from "common/hooks";

export default function Benchmarks({ farm, selectedAnalysisId, onSelectedAnalysisChange, className }) {
    const { isLoading: analysisIsLoading, data: selectedAnalysis } = useAnalysis(farm.id, selectedAnalysisId);
    const { loading: benchmarkDataLoading, data: benchmarkData } = useBenchmarkData(selectedAnalysis);
    const { loading: benchmarkChartDataLoading, data: benchmarkChartData } = useBenchmarkChartData(benchmarkData);
    const [pageNumber, setPageNumber] = useState(0);
    const online = useOnline();

    const analysisToCompareHasResults = selectedAnalysis?.currentResults?.nitrogenOverview;
    const benchmarkChart = benchmarkChartData && benchmarkChartData.length > 0 && benchmarkChartData[pageNumber];

    const numPages = benchmarkChartData && benchmarkChartData.length - 1;
    const leadBase = benchmarkChart && analysisToCompareHasResults && (
        <div className="u-mt-md">
            <ActionLink onClick={() => setPageNumber(pageNumber > 0 ? pageNumber - 1 : numPages)} className="RoundButton RoundButton--left u-mr-sm">
                <span></span>
            </ActionLink>
            <ActionLink onClick={() => setPageNumber(pageNumber < numPages ? pageNumber + 1 : 0)} className="RoundButton RoundButton--right">
                <span></span>
            </ActionLink>
        </div>
    );

    const linkUrl = `/app/farm/${farm?.id}/benchmarks`;
    const analysisOptions = getAnalysisOptions(farm, [], true);

    const loading = online && (analysisIsLoading || benchmarkDataLoading || benchmarkChartDataLoading);

    return (
        <GridCell className={`${className} FarmDashboard-cell`}>
            <div className="Widget">
                <div className="Widget-header">
                    <div className="Widget-header-select">
                        <SelectField
                            label={
                                <Link to={linkUrl}>
                                    <span className="Widget-title IconLink--stats-bars">
                                        <span>Benchmarks</span>
                                    </span>
                                </Link>
                            }
                            input={{ name: "benchmarkAnalysis", value: selectedAnalysisId, onChange: onSelectedAnalysisChange }}
                            options={analysisOptions}
                        />
                    </div>
                    <div className="h5 u-link u-ml-auto" style={{ fontSize: "1rem" }}>
                        <Link to={linkUrl} className="IconText">
                            View all
                            <i className="icon icon-arrow-right" />
                        </Link>
                    </div>
                </div>
                <div className="Widget-content">
                    {loading && (
                        <div className="Tile-body-message u-mt-lg u-mb-lg waiting">
                            <Spinner dark />
                        </div>
                    )}
                    {!loading && benchmarkChart && analysisToCompareHasResults && (
                        <div>
                            <div className="Widget-content-title u-mb-md">{benchmarkChart.data.title}</div>
                            {benchmarkChart.farmValue && benchmarkChart.outlier && (
                                <Alert
                                    type="error"
                                    text={
                                        <>
                                            The farm value{" "}
                                            <b>
                                                {utils.round(benchmarkChart.farmValue, 1).toLocaleString()} {benchmarkChart.units}
                                            </b>{" "}
                                            is outside of the distribution range. This may indicate a problem with the analysis or you have selected an option that is not compatible with this farm.
                                        </>
                                    }
                                />
                            )}
                            <Bar data={benchmarkChart.data} height={100} options={benchmarkChart.options} />
                        </div>
                    )}
                    {!loading && benchmarkChart && !analysisToCompareHasResults && (
                        <div>
                            <div className="Widget-content-title">The selected analysis does not contain results</div>
                            <p>For benchmarks to display choose an analysis with valid results</p>
                        </div>
                    )}
                    {!loading && !benchmarkChart && <Alert type="warning" text="Benchmark data is not yet available. Please try again soon." />}
                </div>
                {leadBase && (
                    <div className="Widget-footer">
                        <hr />
                        <div className="u-flex u-flexJustifyCenter">
                            <div>{leadBase}</div>
                        </div>
                    </div>
                )}
            </div>
        </GridCell>
    )
}
