// redux-form normalizers
import * as utils from "./utils";

export const numeric = (value) => {
    if (!value || value === undefined) return null;

    const isNumeric = utils.isNumeric(value, -1);
    return isNumeric ? value : undefined;
};

export const integer = (value) => {
    if (!value || value === undefined) return null;

    const isNumeric = utils.isNumeric(value, -1);
    if (isNumeric) {
        value = utils.truncateDecimal(value, 0);
    }
    return isNumeric ? value : undefined;
};

export const trim = (value) => {
    return value && typeof value === "string" ? value.trim() : value;
};
