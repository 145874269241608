import Alert from "components/Alert";
import { ZendeskLink } from "components/Help";
import FeatureTracker, { FEATURES } from "components/FeatureTracker/FeatureTracker";
import { OverviewPanel } from "./OverviewPanel";
import { BlockList } from "./BlockList";

export default function BlocksSummary({ farm, analysis }) {
    return (
        <>
            <FeatureTracker feature={FEATURES.BLOCK_SUMMARY} farm={farm} />
            <Alert className="u-print-none" type="info" text="You can print this page by selecting print under the browser menu. For PDF, change the destination to PDF. Portrait is recommended for this report." moreInfo={<ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000978743" className="IconLink--new-tab u-font-green" />} />
            <OverviewPanel analysis={analysis} />
            <BlockList farm={farm} analysis={analysis} />
        </>
    )
}
