import React from "react";
import { compose, withActions, withAppContext } from "containers/HOC";
import { Panel, PanelBody } from "components/Panel";
import { getFarmPaymentsAsync } from "./_actions";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import * as utils from "common/utils";
import { Link } from "react-router-dom";
import numeral from "numeral";

const FarmPayments = ({ farmId, getFarmPaymentsAsync, authContext }) => {
    const [farmPayments, setFarmPayments] = React.useState();

    React.useEffect(() => {
        const fetchData = async (farmId) => {
            try {
                const response = await getFarmPaymentsAsync(farmId);
                setFarmPayments(response);
            } catch {
                setFarmPayments([]);
            }
        };

        if (!farmPayments) {
            fetchData(farmId);
        }
    }, [farmId, farmPayments, getFarmPaymentsAsync]);

    const isAuthorised = authContext.isSystemAdmin;
    const hasFarmPayments = farmPayments && farmPayments.length > 0;

    return (
        <Panel>
            <PanelBody loading={!farmPayments}>
                {!isAuthorised && (
                    <div className="Tile-body-message">
                        <p className="lead">Access denied</p>
                    </div>
                )}
                {isAuthorised && !hasFarmPayments && (
                    <div className="Tile-body-message">
                        <p className="lead">No Payments</p>
                    </div>
                )}
                {isAuthorised && hasFarmPayments && (
                    <div>
                        <React.Fragment>
                            <SortableTable data={farmPayments} defaultSortCriteria={{ fieldName: "transactionTime", fieldType: "date", desc: true }} pageSize={50}>
                                {({ data, sortCriteria, handleSort }) => {
                                    const CHARGEABLE_STATUSES = ["Pending", "Paid"];
                                    return (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <SortableTableHeader label="Date" fieldName="transactionTime" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Payment method" fieldName="paymentMethod" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Sub yype" fieldName="creditCardSubTxnType" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Trnx yype" fieldName="transactionType" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Status" fieldName="status" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Invoice" fieldName="invoiceNumber" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Paystation ID" fieldName="paystationId" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Paystation result" fieldName="paystationResultMessage" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Org" fieldName="accountName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="User" fieldName="userFullName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Farms" fieldName="farmNames" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Reference" fieldName="reference" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Amount" fieldName="amountExclGst" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="GST" fieldName="gst" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Total" fieldName="amountInclGst" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((payment) => {
                                                    return (
                                                        <tr key={payment.id}>
                                                            <td>{utils.dateLong(payment.transactionTime, "-")}</td>
                                                            <td>{payment.paymentMethod}</td>
                                                            <td>{payment.creditCardSubTxnType}</td>
                                                            <td>{payment.transactionType}</td>
                                                            <td>{payment.status}</td>
                                                            <td>{payment.invoiceNumber}</td>
                                                            <td>
                                                                {payment.paystationId && authContext.isSystemAdmin ? (
                                                                    <a rel="noreferrer" href={`https://admin.paystation.co.nz/transaction_details.php?txid=${payment.paystationId}`} target="_blank">
                                                                        {payment.paystationId}
                                                                    </a>
                                                                ) : (
                                                                    <div>{payment.paystationId ? payment.paystationId : "-"}</div>
                                                                )}
                                                            </td>
                                                            <td>{payment.paystationResultMessage}</td>
                                                            <td>{payment.accountName}</td>
                                                            <td>{payment.userFullName}</td>
                                                            <td valign="top" data-width="20">
                                                                {payment.farms.length > 0 &&
                                                                    payment.farms.map((farm, index) => {
                                                                        const isLastInTheList = index === payment.farms.length - 1;
                                                                        return (
                                                                            <span key={farm.id}>
                                                                                <Link to={`/app/farm/${farm.id}`}>
                                                                                    {farm.name}
                                                                                    {isLastInTheList ? "" : ", "}
                                                                                </Link>
                                                                            </span>
                                                                        );
                                                                    })}
                                                                {payment.farms.length === 0 && <>-</>}
                                                            </td>
                                                            <td>{payment.reference}</td>
                                                            <td className="u-textRight" valign="top">
                                                                {CHARGEABLE_STATUSES.includes(payment.status) ? numeral(payment.amountExclGst).format("$0,0.00") : "-"}
                                                            </td>
                                                            <td className="u-textRight" valign="top">
                                                                {CHARGEABLE_STATUSES.includes(payment.status) ? numeral(payment.gst).format("$0,0.00") : "-"}
                                                            </td>
                                                            <td className="u-textRight" valign="top">
                                                                {CHARGEABLE_STATUSES.includes(payment.status) ? numeral(payment.amountInclGst).format("$0,0.00") : "-"}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    );
                                }}
                            </SortableTable>
                        </React.Fragment>
                    </div>
                )}
            </PanelBody>
        </Panel>
    );
};

export default compose(withAppContext, withActions({ getFarmPaymentsAsync }))(FarmPayments);
