import { Component } from "react";
import isReachable from "../common/isReachable";
import { setOnlineStatus, setVersion, setInvalidVersionCount, fetchRefData, setOfflineCount } from "containers/App/_actions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    const { app = {} } = state;
    return {
        online: app.online,
        version: app.version,
        invalidVersionCount: app.invalidVersionCount,
        offlineCount: app.offlineCount,
    };
};

class OfflinePoller extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forceOffline: false,
        };
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.checkOffline();
        }, 3000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    toggleOffline = (e) => {
        e.preventDefault();
        const offline = !e.target.checked;
        this.setState({ forceOffline: offline });
    };

    checkOffline() {
        isReachable()
            .then(([online, pragma = ""]) => {
                const isOnline = online && !this.state.forceOffline;
                const versions = typeof pragma === "string" || pragma instanceof String ? pragma.split(",") : [];
                if (versions.length === 2) {
                    const uiVer = versions[0].replace("ui-ver=", "").split("+")[0];
                    const apiVer = versions[1].replace("api-ver=", "").split("+")[0];
                    if (uiVer !== this.props.version && isOnline) {
                        this.props.setVersion(uiVer);
                    }
                    if (process.env.REACT_APP_DISABLE_REFRESH !== "true") {
                        const { invalidVersionCount = 0, refDataVersion = 0 } = this.props;
                        if (uiVer !== process.env.REACT_APP_VERSION.split("+")[0]) {
                            this.props.setInvalidVersionCount(invalidVersionCount + 1);
                        } else if (invalidVersionCount !== 0) {
                            this.props.setInvalidVersionCount(0);
                        }

                        if (refDataVersion && apiVer !== refDataVersion.split("+")[0]) {
                            this.props.fetchRefData();
                        }
                    }
                }
                if (isOnline && !this.props.online) {
                    this.props.setOnlineStatus(true);
                    this.props.setOfflineCount(0);
                } else if (!isOnline && this.props.online) {
                    if (this.props.offlineCount < 4) {
                        this.props.setOfflineCount(this.props.offlineCount + 1);
                    } else {
                        this.props.setOnlineStatus(false);
                    }
                }
            })
            .catch((err) => {
                if (this.props.online) {
                    this.props.setOnlineStatus(false);
                }
            });

        this.timer = setTimeout(() => {
            this.checkOffline();
        }, 3000);
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, { setOnlineStatus, setVersion, setInvalidVersionCount, fetchRefData, setOfflineCount })(OfflinePoller);
