import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { Switch } from "react-router-dom";
import { useIsOverseerSci, useReferrer } from "common/hooks";
import { Panel, PanelBody } from "components/Panel";
import Tabs from "components/new/Tabs";
import TabsItem from "components/new/TabsItem";
import ScrollTopRoute from "components/ScrollTopRoute";
import { orgUtils } from "containers/Orgs";
import { AddUserActionLink } from "containers/Admin/Users";
import {
    EditOrgActionLink,
    DeleteOrgActionLink,
    OrgUsers,
    OrgUserInvitations,
    OrgFarms,
    OrgPayments,
    OrgDatasets,
    OrgDataset
} from "containers/Admin/Orgs";

export default function Org({ orgId }) {
    const { isLoading, error, org } = useOrg(orgId);
    const isOverseerSci = useIsOverseerSci();
    const referrer = useReferrer();

    return (
        <Panel title={org?.name || "Loading..."} loading={isLoading} error={error} actions={<OrgActions org={org} />} referrer={referrer}>
            <PanelBody loading={isLoading}>
                <Tabs>
                    <TabsItem id="tab_users" title={org ? `Users (${org.userCount})` : "Users"} to={`/app/admin/orgs/${orgId}`} exact />
                    <TabsItem id="tab_invites" title={org ? `Pending User Invitations (${org.userInvitationCount})` : "Pending User Invitations"} to={`/app/admin/orgs/${orgId}/invitations`} exact />
                    <TabsItem id="tab_farms" title={org ? `Farms (${org.farmCount})` : "Farms"} to={`/app/admin/orgs/${orgId}/farms`} exact />
                    {isOverseerSci ? <TabsItem id="tab_datasets" title="Datasets" to={`/app/admin/orgs/${orgId}/datasets`} /> : <TabsItem id="tab_payment" title="Payment Transactions" to={`/app/admin/orgs/${orgId}/payments`} exact />}
                </Tabs>
                <Switch>
                    <ScrollTopRoute exact path="/app/admin/orgs/:accountId" render={() => <OrgUsers org={org} />} />
                    <ScrollTopRoute exact path="/app/admin/orgs/:accountId/invitations" render={() => <OrgUserInvitations org={org} />} />
                    <ScrollTopRoute exact path="/app/admin/orgs/:accountId/farms" render={() => <OrgFarms org={org} />} />
                    <ScrollTopRoute exact path="/app/admin/orgs/:accountId/payments" render={() => <OrgPayments org={org} />} />
                    <ScrollTopRoute exact path="/app/admin/orgs/:accountId/datasets" render={() => <OrgDatasets org={org} />} />
                    <ScrollTopRoute exact path="/app/admin/orgs/:accountId/datasets/:datasetId" component={OrgDataset} />
                </Switch>
            </PanelBody>
        </Panel>
    );
}

function OrgActions({ org }) {
    if (!org) {
        return null;
    }

    const canDeleteOrg = getCanDeleteOrg(org);
    return (<>
        {canDeleteOrg && <DeleteOrgActionLink org={org} className="IconLink--trash u-ml-sm u-textWhite" />}
        <EditOrgActionLink org={org} className="IconLink--edit u-ml-sm u-textWhite" />
        <AddUserActionLink orgId={org?.id} className="IconLink--arrow-plus u-ml-sm u-textWhite" />
    </>);
}

function getCanDeleteOrg(org) {
    if (org.farmCount > 0)
        return false;

    if (orgUtils.isCouncil(org))
        return false;

    return true;
}

function useOrg(orgId) {
    const query = useQuery({
        queryKey: ["orgs", orgId],
        queryFn: async () => httpClient.get(`admin/accounts/${orgId}`),
        retry: false
    });

    return {
        isLoading: query.isLoading,
        error: query.error?.message,
        org: query.data
    };
}
