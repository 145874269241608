import { HashLink as Link } from "react-router-hash-link";
import * as domain from "common/domain";
import { useFormState, useRefData } from "common/hooks";
import BlockSummary from "./Cards/BlockSummary";
import BlockCrops from "./Cards/BlockCrops";
import NameValueSplit from "./Tables/NameValueSplit";
import BlockMonths from "./Tables/BlockMonths";
import { climateViewModel, cropNamePairs, climateNamePairs, blockCropsViewModel, pastureNamePairs, supplementNamePairs, drainageNamePairs, getBlocksRotatedThru, getFodderRotations } from "common/viewModels";
import Watermark from "components/Watermark";
import * as cropUtils from "../../Crops/_utils";
import { BlockNutrientBudget } from "components/NutrientBudget";
import { BlockSoilIrrigation, BlockNitrogenPoolsGraphs } from "containers/BudgetHome/Overview/BlockDetails";

export default function BlockDetails({ farm, analysis, block, monthlyBlockModel }) {
    const refData = useRefData();
    const formState = useFormState();

    const vm = climateViewModel(farm, analysis, block, refData);
    const cropDetails = cropNamePairs(block, refData);
    const climateDetails = climateNamePairs(vm);
    const pastureResults = pastureNamePairs(block, analysis, refData);
    const supplementResults = supplementNamePairs(block, analysis);
    const drainageResults = drainageNamePairs(block, analysis, refData);
    const monthlyModel = monthlyBlockModel.blocks.find((b) => b.id === block.id);
    const fodderRotations = getFodderRotations(block, analysis);
    const blocksRotatedThru = getBlocksRotatedThru(block, analysis);

    const isCropBlock = [domain.BlockType.ProductivePasture, domain.BlockType.ProductiveFruit, domain.BlockType.ProductiveCrop, domain.BlockType.FodderCrop].includes(block.type);
    const blockCropsVm = isCropBlock && blockCropsViewModel(block, refData);

    const showClimate = [domain.BlockType.ProductivePasture, domain.BlockType.ProductiveFruit, domain.BlockType.ProductiveCrop, domain.BlockType.NonProductiveHouse, domain.BlockType.NonProductiveTreesAndScrub].includes(block.type);
    const showCropMonths = [domain.BlockType.ProductiveCrop, domain.BlockType.FodderCrop].includes(block.type);
    const rotationYear = cropUtils.rotationYear(block, true);

    return (
        <div className="u-pt-lg">
            <div className="u-pos-rel u-overflow-hidden">
                <Watermark />
                <BlockSummary analysis={analysis} block={block} climateDetails={formState.print_showBlockClimate && showClimate && climateDetails} />
                {fodderRotations && (
                    <div className="u-page-break-avoid u-mt-lg u-pos-rel u-overflow-hidden">
                        <div className="h5">Fodder crop rotations</div>
                        {fodderRotations.map((rotation, i) => (
                            <Link key={rotation.id} to={`#${rotation.id}`}>
                                {rotation.name}
                                {i !== fodderRotations.length - 1 ? ", " : ""}
                            </Link>
                        ))}
                    </div>
                )}
                {blocksRotatedThru && (
                    <div className="u-page-break-avoid u-mt-lg u-pos-rel u-overflow-hidden">
                        <div className="h5">Rotates through</div>
                        {blocksRotatedThru.map((block, i) => (
                            <Link key={block.id} to={`#${block.id}`}>
                                {block.name}
                                {i !== blocksRotatedThru.length - 1 ? ", " : ""}
                            </Link>
                        ))}
                    </div>
                )}
                {formState.print_showBlockPastureResults && pastureResults && (
                    <div className="u-page-break-avoid u-mt-lg u-pos-rel u-overflow-hidden">
                        <div className="h5">Pasture</div>
                        <NameValueSplit nameValues={pastureResults} splitBy={2} />
                    </div>
                )}
                {formState.print_showBlockDrainage && drainageResults && drainageResults.length > 0 && (
                    <div className="u-page-break-avoid u-mt-lg u-pos-rel u-overflow-hidden">
                        <div className="h5">Artificial drainage</div>
                        <NameValueSplit nameValues={drainageResults} splitBy={1} />
                    </div>
                )}
                {formState.print_showBlockSupplementResults && supplementResults && supplementResults.length > 0 && (
                    <div className="u-page-break-avoid u-mt-lg u-pos-rel u-overflow-hidden">
                        <div className="h5">Supplements</div>
                        <NameValueSplit nameValues={supplementResults} splitBy={2} />
                    </div>
                )}
                {formState.print_showBlockCrops && isCropBlock && (
                    <div className="u-page-break-avoid u-mt-lg u-pos-rel u-overflow-hidden">
                        <div className="h5">Crop management</div>
                        <NameValueSplit nameValues={cropDetails} splitBy={2} />
                    </div>
                )}
            </div>

            {formState.print_showBlockCrops && isCropBlock && (
                <>
                    <BlockCrops crops={blockCropsVm} formState={formState} block={block} analysis={analysis} />
                    {(formState.print_showBlockMonths || showCropMonths) && monthlyModel?.categories?.length > 0 && (
                        <div className="u-pt-xl u-pb-lg">
                            <div className="Table u-tbl-lg Table-Compressed Calendar-Compressed">
                                <Watermark />
                                <table className="Calendar u-tbl-fix">
                                    {cropUtils.cropTableHeader(analysis, rotationYear, block, refData, true, 1, 0)}
                                    <tbody><BlockMonths block={block} thisBlockModel={monthlyModel} rotationYear={rotationYear} /></tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </>
            )}

            {!formState.print_showBlockCrops && isCropBlock && formState.print_showBlockMonths && monthlyModel && monthlyModel.categories.length > 0 && (
                <div className="u-pt-xl u-pb-lg">
                    <div className="Table u-tbl-lg Table-Compressed Calendar-Compressed">
                        <Watermark />
                        <table className="Calendar u-tbl-fix">
                            <tr>
                                <th></th>
                                {rotationYear.map((month, index) => (
                                    <th className={`Calendar-month ${rotationYear.length > 12 && index < 12 ? "Year1" : ""}`} key={index}>
                                        {month.substring(0, 3)}
                                    </th>
                                ))}
                            </tr>
                            <tbody>
                                <BlockMonths block={block} thisBlockModel={monthlyModel} rotationYear={rotationYear} />
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {formState.print_showNPoolsGraphs && block.isProductive && (
                <div className="u-page-break-avoid u-mt-lg">
                    <BlockNitrogenPoolsGraphs analysis={analysis} block={block} />
                </div>
            )}

            {formState.print_showSoilIrrigationResults && block.isProductive && (
                <div className="u-page-break-avoid u-mt-lg">
                    <div className="h5">Soil/Irrigation - results</div>
                    <BlockSoilIrrigation farm={farm} analysis={analysis} block={block} />
                </div>
            )}

            {formState.print_showBlockNutrientBudget && !farm.expired && (
                <div className="u-page-break-avoid u-mt-lg">
                    <div className="h5">Nutrient budget</div>
                    <BlockNutrientBudget id={block.id} analysis={analysis} block={block} />
                </div>
            )}
            <hr />
        </div>
    )
}
