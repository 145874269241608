import * as utils from "common/utils";
import * as FormUtils from "common/FormUtils";
import { diff } from "deep-object-diff";
import * as fertUtils from "containers/BudgetHome/Fertiliser/_utils";

class SimplifyAnalysisLogic {
    static getMergedSoils = (farm, budget, selectedBlocks, soilGroups) => {
        const mergedAreaInHectares = utils.round(
            selectedBlocks.reduce((area, block) => (area += block.areaInHectares), 0),
            1
        );
        const mergedFarmSoilBlocks = mergeFarmSoilBlocks(selectedBlocks, budget, mergedAreaInHectares);
        const soils = budget.useFarmSoils ? farm.soils : budget.soils;
        const mergedSoils = mergedFarmSoilBlocks.map((ob) => {
            const soil = soils.find((s) => s.id === ob.soilId);
            return {
                id: soil.id,
                title: utils.getSoilTitle(soil, soilGroups),
                percentage: ob.percentageOfBlock,
            };
        });
        return mergedSoils;
    };

    static mergeBlock = (budget, masterBlockId, oldBlockIds, newBlockName) => {
        const masterBlock = (budget.blocks || []).find((block) => block.id === masterBlockId);
        const oldBlocks = (budget.blocks || []).filter((b) => oldBlockIds.includes(b.id));
        const masterAndOldBlocks = oldBlocks.concat(masterBlock);

        const mergedAreaInHectares = utils.round(
            masterAndOldBlocks.reduce((area, block) => (area += block.areaInHectares), 0),
            1
        );
        const mergedDistanceFromCoast = mergeDistanceFromCoast(masterAndOldBlocks);
        const mergedClimate = mergeClimate(masterAndOldBlocks, mergedAreaInHectares);
        const mergedFarmSoilBlocks = mergeFarmSoilBlocks(masterAndOldBlocks, budget, mergedAreaInHectares);
        const mergedFruit = mergeFruit(masterBlock, masterAndOldBlocks);

        const newBlock = {
            ...masterBlock,
            name: newBlockName || `${masterBlock.name.substring(0, 40)} (Merged)`,
            areaInHectares: mergedAreaInHectares,
            distanceFromCoastInKilometres: mergedDistanceFromCoast,
            climate: mergedClimate,
            farmSoilBlocks: mergedFarmSoilBlocks.map((fsb) => ({ ...fsb, blockId: masterBlock.id })),
            fruit: mergedFruit,
            currentResults: undefined,
        };
        delete newBlock.currentResults;

        return newBlock;
    };

    static mergeBudget = (budget, newBlock, oldBlockIds) => {
        const mergedBlocks = mergeBlocks(budget, newBlock, oldBlockIds);

        const mergedFeatures = mergeFeatures(budget, newBlock, oldBlockIds);

        const mergedIrrigators = mergeIrrigators(budget, newBlock, oldBlockIds);

        const mergedFertiliser = mergeFertiliser(budget, oldBlockIds);

        const mergedFeedSupplements = mergeFeedSupplements(budget, newBlock, oldBlockIds);

        const mergedDrainageSystems = mergeDrainageSystems(budget, mergedBlocks);

        const mergedWetlands = mergeUnfencedWetlands(budget, newBlock, oldBlockIds);

        const mergedSnowfalls = mergeSnowfall(budget, oldBlockIds);

        const mergedSoilTests = mergeSoilTests(budget, mergedBlocks);

        const mergedStructures = mergeStructures(budget, oldBlockIds);

        const mergedDairyEffluent = mergeDairyEffluent(budget, oldBlockIds);

        // Merge farm soil blocks from each block.
        const simplifiedFarmSoilBlocks = mergedBlocks.filter((b) => b.farmSoilBlocks && b.farmSoilBlocks.length > 0).reduce((results, b) => [...results, ...b.farmSoilBlocks], []);
        const simplifiedFarmSoilBlockIds = simplifiedFarmSoilBlocks.map((fsb) => fsb.id);
        const mergedFarmSoilBlocks = (budget.farmSoilBlocks || []).filter((fsb) => !oldBlockIds.includes(fsb.blockId) && fsb.blockId !== newBlock.id && !simplifiedFarmSoilBlockIds.includes(fsb.id)).concat(simplifiedFarmSoilBlocks);

        const mergedBudget = {
            ...budget,
            blocks: mergedBlocks,
            features: mergedFeatures,
            irrigators: mergedIrrigators,
            fertiliser: mergedFertiliser,
            feedSupplements: mergedFeedSupplements,
            drainageSystems: mergedDrainageSystems,
            wetlands: mergedWetlands,
            snowfalls: mergedSnowfalls,
            soilTests: mergedSoilTests,
            structures: mergedStructures,
            effluentSystem: mergedDairyEffluent,
            farmSoilBlocks: mergedFarmSoilBlocks,
            version: undefined,
            datasetId: undefined,
            currentResults: undefined,
        };
        delete mergedBudget.version;
        delete mergedBudget.datasetId;
        delete mergedBudget.currentResults;

        return mergedBudget;
    };
}
export default SimplifyAnalysisLogic;

const mergeDistanceFromCoast = (masterAndOldBlocks) => {
    const totalDistanceFromCoast = masterAndOldBlocks.reduce((sum, block) => (sum += block.distanceFromCoastInKilometres), 0);
    return FormUtils.formatters.formatInt(totalDistanceFromCoast / masterAndOldBlocks.length);
};

const mergeClimate = (masterAndOldBlocks, mergedAreaInHectares) => {
    const climate = {
        annualPetInMM: 0,
        averageRain: 0,
        averageTemp: 0,
    };
    masterAndOldBlocks
        .filter((block) => block.areaInHectares !== undefined && block.climate !== undefined)
        .forEach((block) => {
            const percentageArea = block.areaInHectares / mergedAreaInHectares;
            const annualPetInMM = block.climate.annualPetInMM * percentageArea;
            const averageRain = block.climate.averageRain * percentageArea;
            const averageTemp = block.climate.averageTemp * percentageArea;

            climate.annualPetInMM = climate.annualPetInMM + annualPetInMM;
            climate.averageRain = climate.averageRain + averageRain;
            climate.averageTemp = climate.averageTemp + averageTemp;
        });

    climate.annualPetInMM = FormUtils.formatters.formatInt(climate.annualPetInMM);
    climate.averageRain = FormUtils.formatters.formatInt(climate.averageRain);
    climate.averageTemp = FormUtils.formatters.formatDecimal(1)(climate.averageTemp);

    return climate;
};

const mergeFarmSoilBlocks = (masterAndOldBlocks, budget, mergedAreaInHectares) => {
    let mergedFarmSoilBlocks = [];
    const { farmSoilBlocks = [] } = budget;

    // First up - get all the overseer blocks as a percentage of the new total block area
    masterAndOldBlocks
        .filter((block) => block.requiresSoil)
        .forEach((block) => {
            const blockSoils = farmSoilBlocks.filter((fsb) => fsb && fsb.blockId === block.id);

            const percentageArea = (block.areaInHectares / mergedAreaInHectares) * 100;
            (blockSoils || []).forEach((fsb) => {
                const percentageOfBlock = FormUtils.formatters.formatInt((fsb.percentageOfBlock / 100) * percentageArea);
                if (percentageOfBlock > 0) {
                    const mapped = mergedFarmSoilBlocks.find((ob) => ob.soilId === fsb.soilId);
                    if (mapped) {
                        mapped.percentageOfBlock = FormUtils.formatters.formatInt(mapped.percentageOfBlock + percentageOfBlock);
                    } else {
                        mergedFarmSoilBlocks.push({ ...fsb, percentageOfBlock });
                    }
                }
            });
        });

    // Now - redistribute the top 3 to 100%
    if (mergedFarmSoilBlocks.length > 3) {
        // Get the top 3
        mergedFarmSoilBlocks = mergedFarmSoilBlocks.sort((a, b) => (a.percentageOfBlock > b.percentageOfBlock ? -1 : 1)).slice(0, 3);
        // Redistribute to 100%
        const totalPercentageOfBlock = mergedFarmSoilBlocks.reduce((sum, ob) => (sum += ob.percentageOfBlock), 0);
        if (totalPercentageOfBlock !== 100) {
            mergedFarmSoilBlocks = mergedFarmSoilBlocks.map((ob) => {
                const percentageOfBlock = FormUtils.formatters.formatInt((ob.percentageOfBlock / totalPercentageOfBlock) * 100);
                return {
                    ...ob,
                    percentageOfBlock,
                };
            });
        }
    }

    // Redistribute any under 10% to those over 10%
    let totalOver10Percent = FormUtils.formatters.formatInt(mergedFarmSoilBlocks.filter((ob) => ob.percentageOfBlock > 10).reduce((sum, ob) => (sum += ob.percentageOfBlock), 0));
    if (totalOver10Percent < 100) {
        const toAdd = FormUtils.formatters.formatInt(mergedFarmSoilBlocks.filter((ob) => ob.percentageOfBlock < 5).reduce((sum, ob) => (sum += ob.percentageOfBlock), 0)) || 0;
        const toRemove = FormUtils.formatters.formatInt(mergedFarmSoilBlocks.filter((ob) => ob.percentageOfBlock >= 5 && ob.percentageOfBlock < 10).reduce((sum, ob) => (sum += 10 - ob.percentageOfBlock), 0)) || 0;
        const totalAdjustment = toAdd - toRemove;

        mergedFarmSoilBlocks = mergedFarmSoilBlocks
            .filter((ob) => ob.percentageOfBlock >= 5)
            .map((ob) => {
                let percentageOfBlock = ob.percentageOfBlock;
                if (percentageOfBlock < 10) {
                    percentageOfBlock += 10 - percentageOfBlock;
                } else if (totalAdjustment !== 0) {
                    percentageOfBlock += (percentageOfBlock / totalOver10Percent) * totalAdjustment;
                }
                ob.percentageOfBlock = FormUtils.formatters.formatInt(percentageOfBlock);
                return ob;
            });
    }

    // Make sure total is 100.
    let total = 0;
    mergedFarmSoilBlocks.forEach((ob, i) => {
        if (i === mergedFarmSoilBlocks.length - 1) {
            ob.percentageOfBlock = 100 - total;
        } else {
            total += ob.percentageOfBlock;
        }
    });

    return mergedFarmSoilBlocks;
};

const mergeFruit = (masterBlock, masterAndOldBlocks) => {
    if (!masterBlock.fruit) return masterBlock.fruit;

    const fruit = {
        ...masterBlock.fruit,
    };

    const allBlocksBeingMergedAreTheSameFruit = masterAndOldBlocks.every((b) => b.fruit && b.fruit.cropType === masterBlock.fruit.cropType);
    if (allBlocksBeingMergedAreTheSameFruit) {
        fruit.productYield = masterAndOldBlocks.filter((block) => block.type === "ProductiveFruit").reduce((sum, block) => (sum += block.fruit.productYield), 0);

        fruit.ageOfTrees_yrs = masterAndOldBlocks.filter((block) => block.type === "ProductiveFruit").reduce((max, block) => (block.fruit.ageOfTrees_yrs > max ? block.fruit.ageOfTrees_yrs : max), 0);

        const sumRejected = masterAndOldBlocks.filter((block) => block.type === "ProductiveFruit").reduce((sum, block) => (sum += block.fruit.productYield * (block.fruit.rejectPercentage / 100)), 0);
        fruit.rejectPercentage = utils.round((sumRejected / fruit.productYield) * 100, 0);
    }

    return fruit;
};

const mergeBlocks = (budget, newBlock, oldBlockIds) => {
    const mergedBlocks = (budget.blocks || [])
        .filter((block) => !oldBlockIds.includes(block.id))
        .map((block) => (block.id === newBlock.id ? newBlock : block))
        .reduce((blocks, block) => {
            if (block.type === "FodderCrop") {
                const mergedFodderCropBlock = mergeFodderCropBlock(block, newBlock, oldBlockIds);
                blocks.push(mergedFodderCropBlock);
            } else if (block.type === "NonProductiveWetland") {
                const mergedWetlandBlock = mergeWetlandBlock(block, newBlock, oldBlockIds);
                blocks.push(mergedWetlandBlock);
            } else if (block.type === "NonProductiveRiparian") {
                const mergedRiparianBlock = mergeRiparianBlock(block, newBlock, oldBlockIds);
                blocks.push(mergedRiparianBlock);
            } else {
                blocks.push(block);
            }
            return blocks;
        }, []);

    return mergedBlocks;
};

const mergeFodderCropBlock = (fodderCropBlock, newBlock, oldBlockIds) => {
    const blockIds = fodderCropBlock.blockIds.filter((id) => !oldBlockIds.includes(id));
    if (!blockIds.includes(newBlock.id)) {
        blockIds.push(newBlock.id);
    }
    const mergedFodderCropBlock = {
        ...fodderCropBlock,
        blockIds,
    };
    return mergedFodderCropBlock;
};

const mergeFeatures = (budget, newBlock, oldBlockIds) => {
    const mergedFeatures = (budget.features || []).map((f) => {
        if (f.properties.blockId && oldBlockIds.includes(f.properties.blockId)) {
            return {
                ...f,
                properties: {
                    ...f.properties,
                    blockId: newBlock.id,
                    name: newBlock.name,
                },
            };
        }
        return f;
    });
    return mergedFeatures;
};

const mergeSnowfall = (budget, oldBlockIds) => {
    const mergedSnowfall = (budget.snowfalls || []).reduce((snowfall, snow) => {
        const blockIds = snow.blockIds.filter((blockId) => !oldBlockIds.includes(blockId));
        if (blockIds.length > 0) {
            snowfall.push({ ...snow, blockIds });
        }
        return snowfall;
    }, []);
    return mergedSnowfall;
};

const mergeSoilTests = (budget, mergedBlocks) => {
    const mergedSoilTests = (budget.soilTests || []).reduce((soilTests, soilTest) => {
        const soilTestInUse = mergedBlocks.some((b) => b.soilTestId && b.soilTestId === soilTest.id);
        if (soilTestInUse) {
            soilTests.push(soilTest);
        }
        return soilTests;
    }, []);
    return mergedSoilTests;
};

const mergeDrainageSystems = (budget, mergedBlocks) => {
    const remainingDrainageIds = mergedBlocks.filter((block) => block.drainageDetailsId !== undefined).map((block) => block.drainageDetailsId);
    const mergedDrainageSystems = (budget.drainageSystems || []).reduce((systems, sys) => {
        const drainages = sys.drainage.filter((drainage) => remainingDrainageIds.includes(drainage.id));
        if (drainages.length > 0) {
            systems.push({
                ...sys,
                drainage: drainages,
            });
        }
        return systems;
    }, []);
    return mergedDrainageSystems;
};

const mergeDrainagePercentages = (drainagePercentages, newBlock, oldBlockIds) => {
    const mergedPercentage = (drainagePercentages || []).filter((cp) => cp.blockId === newBlock.id || oldBlockIds.includes(cp.blockId)).reduce((sum, cp) => (sum += cp.percentage), 0);

    if (mergedPercentage === 0) return drainagePercentages;

    const mergedDrainagePercentages = (drainagePercentages || [])
        .filter((cp) => !oldBlockIds.includes(cp.blockId))
        .map((cp) => {
            if (cp.blockId === newBlock.id) return { ...cp, percentage: mergedPercentage };
            return cp;
        });

    if (!mergedDrainagePercentages.some((cp) => cp.blockId === newBlock.id)) {
        mergedDrainagePercentages.push({ blockId: newBlock.id, percentage: mergedPercentage });
    }

    return mergedDrainagePercentages;
};

const mergeWetlandBlock = (wetlandBlock, newBlock, oldBlockIds) => {
    if (!wetlandBlock.wetland) return wetlandBlock;

    const mergedCatchmentPercentages = mergeDrainagePercentages(wetlandBlock.wetland.catchmentPercentages, newBlock, oldBlockIds);
    const mergedWetlandBlock = {
        ...wetlandBlock,
        wetland: {
            ...wetlandBlock.wetland,
            catchmentPercentages: mergedCatchmentPercentages,
        },
    };
    return mergedWetlandBlock;
};

const mergeRiparianBlock = (riparianBlock, newBlock, oldBlockIds) => {
    if (!riparianBlock.riparianStrip) return riparianBlock;

    const mergedRunoffPercentages = mergeDrainagePercentages(riparianBlock.riparianStrip.runoffPercentages, newBlock, oldBlockIds);
    const mergedRiparianBlock = {
        ...riparianBlock,
        riparianStrip: {
            ...riparianBlock.riparianStrip,
            runoffPercentages: mergedRunoffPercentages,
        },
    };
    return mergedRiparianBlock;
};

const mergeUnfencedWetlands = (budget, newBlock, oldBlockIds) => {
    const mergedWetlands = (budget.wetlands || []).map((wetland) => {
        const mergedCatchmentPercentages = mergeDrainagePercentages(wetland.catchmentPercentages, newBlock, oldBlockIds);
        return {
            ...wetland,
            catchmentPercentages: mergedCatchmentPercentages,
        };
    });
    return mergedWetlands;
};

const mergeIrrigators = (budget, newBlock, oldBlockIds) => {
    const mergedIrrigators = (budget.irrigators || []).reduce((irrs, irr) => {
        const mergedNonDrawnArea = (irr.nonDrawnArea || []).reduce((results, nonDrawn) => {
            const block = (budget.blocks || []).find((b) => b.id === nonDrawn.blockId);
            if (!block) return results;

            if (newBlock.id === nonDrawn.blockId || oldBlockIds.includes(nonDrawn.blockId)) {
                const blockArea = block.areaInHectares || block.rotationArea;
                const irrigatedAreaOfOriginalBlock = blockArea * (nonDrawn.percentageOfNonDrawnArea / 100);
                const percentageOfMergedBlock = (irrigatedAreaOfOriginalBlock / newBlock.areaInHectares) * 100;
                const existingResult = results.find((r) => r.blockId === newBlock.id);
                if (existingResult) {
                    existingResult.percentageOfNonDrawnArea += percentageOfMergedBlock;
                } else {
                    results.push({
                        blockId: newBlock.id,
                        percentageOfNonDrawnArea: percentageOfMergedBlock,
                    });
                }
            } else {
                results.push(nonDrawn);
            }

            return results;
        }, []);
        mergedNonDrawnArea.forEach((nonDrawn) => (nonDrawn.percentageOfNonDrawnArea = utils.round(nonDrawn.percentageOfNonDrawnArea, 1)));

        const mergedApplications = irr.applications.reduce((apps, app) => {
            if (app.blockIds.includes(newBlock.id) || app.blockIds.some((id) => oldBlockIds.includes(id))) {
                const alreadyMapped = apps.some((existingApp) => {
                    const diffObj = diff(existingApp, app);
                    delete diffObj.id;
                    delete diffObj.blockIds;
                    const diffLength = Object.keys(diffObj).length;

                    const noDifferences = diffLength === 0;

                    // We can't have more than one app for the same month so if there is only one thing other than the months
                    // that is different then we will have to drop this app and it won't be included in the merged apps.
                    const onlyOneDifferenceButSameMonths = diffLength === 1 && !diffObj.months;

                    const appIsTheSame = noDifferences || onlyOneDifferenceButSameMonths;
                    const itIsLinkedToTheNewBlock = existingApp.blockIds.includes(newBlock.id);

                    return appIsTheSame && itIsLinkedToTheNewBlock;
                });

                if (!alreadyMapped) {
                    const blockIds = app.blockIds.filter((id) => !oldBlockIds.includes(id));
                    if (!blockIds.includes(newBlock.id)) {
                        blockIds.push(newBlock.id);
                    }
                    apps.push({ ...app, blockIds });
                }
            } else {
                apps.push(app);
            }
            return apps;
        }, []);

        irrs.push({
            ...irr,
            nonDrawnArea: mergedNonDrawnArea.length > 0 ? mergedNonDrawnArea : irr.nonDrawnArea,
            applications: mergedApplications.length > 0 ? mergedApplications : irr.applications,
        });

        return irrs;
    }, []);
    return mergedIrrigators;
};

const mergeFertiliser = (budget, oldBlockIds) => {
    const mergedFertiliser = (budget.fertiliser || []).reduce((ferts, fert) => {
        const applications = fertUtils.groupApplications(fert.applications);
        const fertApps = applications.reduce((apps, app) => {
            const blockIds = app.blockIds.filter((id) => !oldBlockIds.includes(id));
            if (blockIds.length > 0) {
                apps.push({ ...app, blockIds });
            }
            return apps;
        }, []);

        if (fertApps.length > 0) {
            ferts.push({ ...fert, applications: fertUtils.ungroupApplications(fertApps) });
        }
        return ferts;
    }, []);
    return mergedFertiliser;
};

const mergeFeedSupplementApplications = (applications = [], newBlock, oldBlockIds) => {
    const mergedApps = applications.reduce((apps, app) => {
        if (app.blockId === newBlock.id || oldBlockIds.includes(app.blockId)) {
            let mergedApp = apps.find((a) => a.blockId === newBlock.id);
            if (!mergedApp) {
                mergedApp = { blockId: newBlock.id, months: {} };
                apps.push(mergedApp);
            }
            if (app.months) {
                Object.keys(app.months).forEach((month) => {
                    if (!mergedApp.months[month]) {
                        mergedApp.months[month] = 0;
                    }
                    mergedApp.months[month] += app.months[month];
                });
            }
        } else {
            apps.push(app);
        }
        return apps;
    }, []);
    return mergedApps;
};

const mergeFeedSupplements = (budget, newBlock, oldBlockIds) => {
    const mergedFeedSupplements = (budget.feedSupplements || []).reduce((supps, supp) => {
        const isCuts = supp.amountType === "Cuts";
        const { sources: supSources = [], destinations: supDestinations = [] } = supp;

        const destinations = supDestinations.reduce((dests, dest) => {
            if (dest.type === "SpecifiedBlocks") {
                let mergedBlockIsAlreadyADestination = dest.applications.some((app) => app.blockId === newBlock.id);

                const mergedApplications = mergeFeedSupplementApplications(dest.applications, newBlock, oldBlockIds);
                const destApps = mergedApplications.reduce((apps, app) => {
                    if (oldBlockIds.includes(app.blockId)) {
                        if (!isCuts && !mergedBlockIsAlreadyADestination) {
                            apps.push({ ...app, blockId: newBlock.id });
                            mergedBlockIsAlreadyADestination = true;
                        }
                    } else {
                        apps.push(app);
                    }
                    return apps;
                }, []);

                if (destApps.length > 0) {
                    dests.push({
                        ...dest,
                        applications: destApps,
                    });
                }
            } else {
                dests.push(dest);
            }
            return dests;
        }, []);

        const sources = supSources.reduce((srcs, src) => {
            const sourceIsTheNewBlock = newBlock.id === src.blockId;
            const sourceIsAnOldBlock = oldBlockIds.includes(src.blockId);

            if (sourceIsTheNewBlock) {
                if (!isCuts) {
                    srcs.push(src);
                }
            } else if (sourceIsAnOldBlock) {
                if (!isCuts) {
                    srcs.push({ ...src, blockId: newBlock.id });
                }
            } else {
                srcs.push(src);
            }
            return srcs;
        }, []);

        supps.push({ ...supp, destinations, sources });

        return supps;
    }, []);
    return mergedFeedSupplements;
};

const mergeEffluentSystem = (effluentSystem, oldBlockIds) => {
    if (Object.keys(effluentSystem || {}).length === 0) {
        return effluentSystem;
    }

    const mergedEffluentSystem = {};

    const existingLiquidApps = ((effluentSystem || {}).liquidManagement || {}).applications || [];
    const mergedLiquidApps = existingLiquidApps.reduce((apps, app) => {
        const blockIds = (app.blockIds || []).filter((blockId) => !oldBlockIds.includes(blockId));
        if (blockIds.length > 0) {
            apps.push({ ...app, blockIds });
        }
        return apps;
    }, []);
    if (existingLiquidApps.length > 0) {
        mergedEffluentSystem.liquidManagement = {
            ...effluentSystem.liquidManagement,
        };

        delete mergedEffluentSystem.liquidManagement.applications;
        if (mergedLiquidApps.length > 0) {
            mergedEffluentSystem.liquidManagement.applications = mergedLiquidApps;
        }
    }

    const existingSolidApps = ((effluentSystem || {}).solidManagement || {}).solidApplications || [];
    const mergedSolidApps = existingSolidApps.reduce((apps, app) => {
        const blockIds = (app.blockIds || []).filter((blockId) => !oldBlockIds.includes(blockId));
        if (blockIds.length > 0) {
            apps.push({ ...app, blockIds });
        }
        return apps;
    }, []);

    const existingPondApps = ((effluentSystem || {}).solidManagement || {}).pondApplications || [];
    const mergedPondApps = existingPondApps.reduce((apps, app) => {
        const blockIds = (app.blockIds || []).filter((blockId) => !oldBlockIds.includes(blockId));
        if (blockIds.length > 0) {
            apps.push({ ...app, blockIds });
        }
        return apps;
    }, []);

    if (existingSolidApps.length > 0 || existingPondApps.length > 0) {
        mergedEffluentSystem.solidManagement = {
            ...effluentSystem.solidManagement,
        };

        delete mergedEffluentSystem.solidManagement.solidApplications;
        if (mergedSolidApps.length > 0) {
            mergedEffluentSystem.solidManagement.solidApplications = mergedSolidApps;
        }

        delete mergedEffluentSystem.solidManagement.pondApplications;
        if (mergedPondApps.length > 0) {
            mergedEffluentSystem.solidManagement.pondApplications = mergedPondApps;
        }
    }

    return {
        ...effluentSystem,
        ...mergedEffluentSystem,
    };
};

const mergeStructures = (budget, oldBlockIds) => {
    if ((budget.structures || []).length === 0) return budget.structures;

    const mergedStructures = budget.structures.reduce((structures, structure) => {
        if (!structure.effluentSystem) {
            structures.push(structure);
        } else {
            const mergedEffluentSystem = mergeEffluentSystem(structure.effluentSystem, oldBlockIds);
            structures.push({
                ...structure,
                effluentSystem: mergedEffluentSystem,
            });
        }
        return structures;
    }, []);
    return mergedStructures;
};

const mergeDairyEffluent = (budget, oldBlockIds) => {
    if (Object.keys(budget.effluentSystem || {}).length === 0) {
        return budget.effluentSystem;
    }

    const mergedEffluentSystem = mergeEffluentSystem(budget.effluentSystem, oldBlockIds);
    return mergedEffluentSystem;
};
