import ReactGA from "react-ga4";
import * as api from "api";

export const getSmapSoilsAsync = {
    send: (features, existingSoilFeatures) => (dispatch) => {
        (features || []).forEach((f) => {
            delete f.properties.errorWindow;
            delete f.scrollRef;
        });

        (existingSoilFeatures || []).forEach((f) => {
            delete f.properties.errorWindow;
            delete f.scrollRef;
        });

        return new Promise((resolve, reject) => {
            ReactGA.event({
                category: "Soils",
                action: "Get Smap Soils",
            });
            dispatch(
                api.post({
                    path: `soil/smap`,
                    timeout: 300000,
                    content: { features, existingSoilFeatures },
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("Error loading S-Map soils");
                        return [];
                    },
                    cancelActionType: "abort_getSmapSoilsAsync",
                })
            );
        });
    },
    abort: () => (dispatch) => dispatch({ type: "abort_getSmapSoilsAsync" }),
};

export const getFslSoilsByGeoJsonAsync = {
    send: (features) => (dispatch) => {
        (features || []).forEach((f) => {
            delete f.properties.scrollRef;
        });

        return new Promise((resolve, reject) => {
            dispatch(
                api.post({
                    path: `soil/fsl/block`,
                    timeout: 300000,
                    content: { features },
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("Error loading FSL soils");
                        return [];
                    },
                    cancelActionType: "abort_getBlockFslSoilsAsync",
                })
            );
        });
    },
    abort: () => (dispatch) => dispatch({ type: "abort_getBlockFslSoilsAsync" }),
};

export const getSoilMap = {
    send: (features, drawnArea) => (dispatch) => {
        (features || []).forEach((f) => {
            delete f.properties.scrollRef;
        });

        return new Promise((resolve, reject) => {
            dispatch(
                api.post({
                    path: `soil/map`,
                    content: { features, area: drawnArea },
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("Error loading soil map");
                        return [];
                    },
                    cancelActionType: "abort_getSoilMap",
                })
            );
        });
    },
    abort: () => (dispatch) => dispatch({ type: "abort_getSoilMap" }),
};

export const getRisk = {
    send: (riskProfile) => (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                api.post({
                    path: `soil/risk`,
                    content: riskProfile,
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("Error loading soil map");
                        return [];
                    },
                    cancelActionType: "abort_getRisk",
                })
            );
        });
    },
    abort: () => (dispatch) => dispatch({ type: "abort_getSoilMap" }),
};

export const getClimateByGeoJsonAsync = {
    send: (features) => (dispatch) => {
        (features || []).forEach((f) => {
            delete f.properties.scrollRef;
        });

        return new Promise((resolve, reject) => {
            dispatch(
                api.post({
                    path: `climate`,
                    content: { features },
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("Error loading climate");
                        return [];
                    },
                    cancelActionType: "abort_getClimateByGeoJsonAsync",
                })
            );
        });
    },
    abort: () => (dispatch) => dispatch({ type: "abort_getClimateByGeoJsonAsync" }),
};

export const getSmapSoilByRefAsync = {
    send: (smapReference) => (dispatch) => {
        return new Promise((resolve, reject) => {
            ReactGA.event({
                category: "Soils",
                action: "Get Soil by SMAP ref",
            });
            dispatch({ type: "abort_getSmapSoilByRefAsync" });
            dispatch(
                api.get({
                    path: `soil/smap/${smapReference}`,
                    timeout: 300000,
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("Error loading S-Map soil");
                        return [];
                    },
                    cancelActionType: "abort_getSmapSoilByRefAsync",
                })
            );
        });
    },
    abort: () => (dispatch) => dispatch({ type: "abort_getSmapSoilByRefAsync" }),
};
