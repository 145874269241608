import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import { HiddenField, SelectField, TextField } from "components/FormFields";
import { useRefData, useModal } from "common/hooks";
import { httpClient } from "common/httpClient";

export default function GrantFarmAccessModal({ accessInvitation, close }) {
    const refData = useRefData();
    const grantFarmAccessAsync = useGrantFarmAccessAsync();
    const isAResend = accessInvitation.inviteeEmail ? true : false;
    const roles = accessInvitation.canInviteOwner || isAResend ? refData.farmRoles.filter((r) => !["Publication", "PublicationAgent", "Guest"].includes(r.value)) : (refData.farmRoles || []).filter((r) => !["Owner", "Publication", "PublicationAgent", "Guest"].includes(r.value));

    const validate = (formValues) => {
        const validation = {};

        validation.inviteeEmail = FormUtils.validators.required(formValues.inviteeEmail);
        validation.inviteeEmail = validation.inviteeEmail || FormUtils.validators.email(formValues.inviteeEmail);
        validation.inviteeEmail = validation.inviteeEmail || FormUtils.validators.maxLength(255)(formValues.inviteeEmail);

        validation.role = FormUtils.validators.required(formValues.role);

        return validation;
    }

    const submitAsync = async (formValues) => {
        try {
            await grantFarmAccessAsync(formValues);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }

    return (
        <Form initialValues={accessInvitation} validate={validate} onSubmit={submitAsync}>
            {({ handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Manage farm access" submitting={submitting} close={close}>
                            <ModalBody info="Farm access is granted at the organisational level. Enter the email address of the user you are granting access to this farm. All users of their organisation will be granted this access." error={submitError}>
                                <Grid>
                                    <GridCell>
                                        <Field name="id" component={HiddenField} />
                                        <Field name="inviteeEmail" label="Email" placeholder="Email address" disabled={isAResend} required component={TextField} />
                                    </GridCell>
                                    <GridCell>
                                        <Field name="role" label="Access level" placeholder="Select the access level" options={roles} info="An organisation granted access may publish analyses and associated data from this farm to themselves or other organisations without further permission. Organisations granted admin or owner permission can provide other organisations with access to this farm." disabled={isAResend} required component={SelectField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit primary waiting={submitting}>
                                        Send
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useGrantFarmAccessModal() {
    const [modal, openModal] = useModal(GrantFarmAccessModal);

    const openGrantFarmAccessModal = (accessInvitation) => {
        const modalProps = {
            accessInvitation
        };
        openModal(modalProps);
    };

    return [modal, openGrantFarmAccessModal];
}

function useGrantFarmAccessAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (accessInvitation) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.put(`farms/${accessInvitation.farmId}/access`, accessInvitation, timeout);
            } catch (error) {
                if (error.status === 400) {
                    throw new Error("Error sending invite. Please ensure the email address is valid.");
                } else if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        }
    });

    return (accessInvitation) => mutation.mutateAsync(accessInvitation);
}
