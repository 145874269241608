import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as icons from "common/icons";
import Alert from "components/Alert";
import TileSubPanel from "components/TileSubPanel";
import Calendar from "components/Calendar";
import { irrigatorViewModel } from "common/viewModels";
import ActionLink from "components/ActionLink";
import { useConfirm, useOnline, useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function IrrigatorList({ farm, analysis, removeLinks }) {
    const online = useOnline();
    const refData = useRefData();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const _deleteIrrigator = (irrigator) => (e) => {
        utils.stopPropagation(e);
        const irrigatorType = utils.valueToText(refData.irrigatorTypes, irrigator.type);
        confirm(`Are you sure you want to delete the ${irrigatorType} irrigator?`, async () => {
            analysis.irrigators = analysis.irrigators.filter((i) => i.id !== irrigator.id);
            if (analysis.features) {
                analysis.features = analysis.features.filter((f) => !f.properties.irrigatorId || f.properties.irrigatorId !== irrigator.id);
            }
            await updateAnalysisAsync(analysis);
        });
    };

    const calendarData = _getCalenderData(online, farm, analysis, refData, _deleteIrrigator, removeLinks);

    return (
        <>
            <TileSubPanel title="Irrigation systems" green stickyHeaderName="analysis.irrigation.irrigationSystems.collapsed">
                <Alert type="info" text="The following is a list of irrigation systems for the farm. For pasture blocks that have fodder crops, the area irrigated has been adjusted to remove the fodder block area. The area removed is based on dividing the total fodder area by the total pastoral area for the farm." />
                <div className="FarmTable_wrapper">
                    <div className="FarmTable-supplementary u-pt-md">
                        {calendarData.length === 0 && (
                            <div className="Tile-body">
                                <div className="Tile-body-message">
                                    <img className="u-p-5" src={icons.add} alt="Plus" />
                                    <p className="lead">You do not have any irrigation systems yet</p>
                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/irrigation/new`} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-enterprise-button">
                                        Add irrigation system
                                    </Link>
                                </div>
                            </div>
                        )}
                        {calendarData.length > 0 && <Calendar data={calendarData} column="Area covered" />}
                    </div>
                </div>
            </TileSubPanel>
        </>
    )
}

const _getCalenderData = (online, farm, analysis, refData, deleteIrrigator, removeLinks) => {
    const calendarData = [];

    const irrigators = irrigatorViewModel(analysis, refData);
    irrigators.forEach((irrigator) => {
        const url = `/app/farm/${farm.id}/analysis/${analysis.id}/irrigation/${irrigator.id}`;
        const subTitle = removeLinks ? (
            <span>{irrigator.name}</span>
        ) : (
            <Link to={url} className="FarmTable-value-link" id={`edit-${irrigator.type}`}>
                <span>{irrigator.name}</span>
                <span className="icon icon-edit"></span>
            </Link>
        );

        var alertIcon = online && (analysis.messages || []).find((m) => m.category === "Irrigation" && m.entityType === "Irrigator" && m.entityId === irrigator.id && m.severity === "Error") ? "error" : undefined;
        if (!alertIcon) alertIcon = online && (analysis.messages || []).find((m) => m.category === "Irrigation" && m.entityType === "Irrigator" && m.entityId === irrigator.id && m.severity === "Warning" && !analysis.suppressedMessageCodes.includes(m.code)) ? "warning" : undefined;

        calendarData.push(
            removeLinks
                ? {
                    id: irrigator.id,
                    title: irrigator.irrigatorType,
                    subTitle: subTitle,
                    alertIcon: alertIcon,
                    firstColumn: irrigator.area + " ha",
                    slots: irrigator.slots,
                }
                : {
                    id: irrigator.id,
                    title: irrigator.irrigatorType,
                    subTitle: subTitle,
                    url: url,
                    alertIcon: alertIcon,
                    firstColumn: irrigator.area + " ha",
                    slots: irrigator.slots,
                    actions: (
                        <ActionLink id={`delete-irrigator-${irrigator.id}`} onClick={deleteIrrigator(irrigator)} className="IconLink--trash" title="Delete">
                            <span></span>
                        </ActionLink>
                    ),
                }
        );
    });

    return calendarData;
}