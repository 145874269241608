import { Component } from "react";
import * as utils from "common/utils";
import { useRefData, useShowQuickTips, useToggleQuickTips } from "common/hooks";
import * as validations from "common/validations";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import CheckboxPack from "components/CheckboxPack";
import SelectPack from "components/SelectPack";
import * as cropUtils from "../_utils";
import AnimalGrazing from "../AnimalGrazing";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function Defoliation({ analysis, block, isRotation, crop, defoliation, setDefoliation, rotationYear, isLastMonth, save, cancel }) {
    const refData = useRefData();
    const showQuickTips = useShowQuickTips();
    const toggleQuickTips = useToggleQuickTips();

    return (
        <DefoliationClassComponent analysis={analysis} block={block} isRotation={isRotation} crop={crop} defoliation={defoliation} setDefoliation={setDefoliation} rotationYear={rotationYear} isLastMonth={isLastMonth} save={save} cancel={cancel} refData={refData} showQuickTips={showQuickTips} toggleQuickTips={toggleQuickTips} />
    )
}

class DefoliationClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    _closeOnEscape = (e) => {
        if (e.keyCode === 27) {
            this.props.cancel();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this._closeOnEscape);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._closeOnEscape);
    }

    save() {
        const defoliation = this.props.defoliation;
        const currentValidation = this.state.validation;
        const activeControls = this.getActiveControls(defoliation);
        if (this.isValid(defoliation, activeControls, currentValidation)) {
            this.props.save();
        }
    }

    isValid(defoliation, activeControls, currentValidation, source = undefined) {
        let validation = {};
        let message = undefined;
        const { animalGrazing = {} } = defoliation;

        const required = (parent, keys) => {
            /* eslint-disable no-unused-vars */
            for (const key of keys.filter((k) => activeControls[k])) {
                const objKey = key.split("_").pop();
                validation[key] = utils.setVal(key, currentValidation, source, validations.requiredExt(parent[objKey]));
            }
        };

        required(defoliation, ["defoliation_defoliationFate", "defoliation_defoliationMethod"]);
        required(animalGrazing, ["animalGrazing_animalSource", "animalGrazing_animalType"]);

        if (activeControls.animalGrazing_consumption && !source) {
            const { percentageGrazed = [] } = animalGrazing;
            message = percentageGrazed.length === 0 ? "Please select at least one enterprise" : undefined;
            message = message || (percentageGrazed.length > 0 && percentageGrazed.reduce((a, b) => (a += b.percentage), 0) !== 100 ? "Percentages need to sum to 100" : undefined);
            validation.animalConsumption = utils.setVal("animalConsumption", currentValidation, source, message);
        }

        if (activeControls.animalGrazing_hoursCropGrazed) {
            message = validations.range(0, 20)(animalGrazing.hoursCropGrazed);
            validation.animalGrazing_hoursCropGrazed = utils.setVal("hoursCropGrazed", currentValidation, source, message);
        }

        this.setState({ validation: validation });
        /* eslint-disable no-unused-vars */
        for (let key of Object.keys(validation)) {
            if (validation[key].error) {
                return false;
            }
        }
        return true;
    }

    getActiveControls(defoliation) {
        const { rotationYear, block } = this.props;
        const defoliationIndx = cropUtils.rotationIndex(defoliation, rotationYear);
        const { crops = [] } = block;
        const firstCropEventIndx = cropUtils.getFirstCropSownIndex(crops, rotationYear);
        const activeControls = { defoliation_defoliationMethod: true, defoliation_finalHarvest: false };
        const { animalGrazing = {} } = defoliation;
        const onFarmFate = !["Exported", "ToStorage", undefined].includes(defoliation.defoliationFate);
        activeControls.defoliation_defoliationFate = defoliation.defoliationMethod === "CutandCarry";
        activeControls.animalGrazing_animalSource = defoliation.defoliationMethod === "Grazedinsitu";
        activeControls.animalGrazing_animalType = animalGrazing.animalSource === "Standalonegeneralisedanimaltype";
        activeControls.animalGrazing_consumption = animalGrazing.animalSource === "Integratedwithpastoralblocks" && !["Exported", "ToStorage"].includes(defoliation.defoliationFate);
        activeControls.animalGrazing_hoursCropGrazed = animalGrazing.animalSource === "Integratedwithpastoralblocks" && !["Exported", "ToStorage"].includes(defoliation.defoliationFate);
        activeControls.defoliation_storedPriorFeeding = defoliation.defoliationMethod === "CutandCarry" && onFarmFate;
        activeControls.defoliation_additionalDefoliations = !defoliation.finalHarvest && this.props.isLastMonth && defoliation.defoliationFate !== "ToStorage";
        activeControls.defoliation_numberMonthsDefoliating = !this.props.isLastMonth && firstCropEventIndx <= defoliationIndx && defoliation.defoliationFate !== "ToStorage";
        return activeControls;
    }

    getMaxDefoliationMonths() {
        const { crop, rotationYear, block, defoliation } = this.props;
        const isFodderCrop = rotationYear.length === 12;
        const defoliationIndx = cropUtils.rotationIndex(defoliation, rotationYear);
        const adjIndx = isFodderCrop ? defoliationIndx + 12 : defoliationIndx;

        if (!crop) return 1;

        const cropRangeEnd = crop.thermalTimeEndIndex - 1;
        let maxRange = cropRangeEnd - adjIndx;

        const dfCroppingEventMonth = (i) => {
            return cropUtils.croppingEvent(rotationYear, block, i);
        };

        const dfCropEventMonth = (i) => {
            const month = rotationYear[i];
            return cropUtils.getCropEvent(crop, month, ["Cropsown", "Endcrop", "Enduptake", "Cropharvest", "Defoliation"], rotationYear, i);
        };

        for (let i = adjIndx + 1; i <= cropRangeEnd; i++) {
            if (dfCroppingEventMonth(i) || dfCropEventMonth(i)) {
                maxRange = i - adjIndx;
                i = cropRangeEnd + 1;
            }
        }
        if (maxRange > 15) return 15;
        //if (!defoliation.finalHarvest && adjIndx + maxRange === 24) return maxRange + 10 > 15 ? 15 : maxRange + 10;

        return maxRange;
    }

    onChange(e, source) {
        const defoliation = this.props.defoliation;
        const currentValidation = this.state.validation;

        switch (source.type) {
            case "animalGrazing":
                defoliation.animalGrazing = source.animalGrazing;
                break;
            default:
                defoliation[source.key] = utils.ctrlVal(e);
                if (source.key === "defoliationFate") {
                    const { analysis } = this.props;
                    const { structures = [] } = analysis;
                    const enterprises = filteredEnterprises(analysis, defoliation, structures);
                    if (!["Exported", "ToStorage"].includes(defoliation.defoliationFate)) {
                        if (enterprises.length === 1) {
                            defoliation.animalGrazing = cropUtils.singleEnterpriseGrazing(enterprises[0].id);
                        }
                    } else if (["Exported", "ToStorage"].includes(defoliation.defoliationFate)) {
                        delete defoliation.animalGrazing;
                    }
                    if (defoliation.defoliationFate !== "ToStorage") {
                        delete defoliation.stackContained;
                    }
                } else if (source.key === "defoliationMethod") {
                    delete defoliation.animalGrazing;
                    delete defoliation.defoliationFate;
                    delete defoliation.stackContained;
                } else if (source.key === "finalHarvest" && defoliation.finalHarvest) {
                    const maxMonths = this.getMaxDefoliationMonths();
                    if (defoliation.numberMonthsDefoliating > maxMonths) defoliation.numberMonthsDefoliating = maxMonths;
                }
                break;
        }

        const activeControls = this.getActiveControls(defoliation);
        this.setDefaults(activeControls, defoliation);
        this.isValid(defoliation, activeControls, currentValidation, source);
        this.props.setDefoliation(defoliation);
    }

    setDefaults(activeControls, defoliation) {
        if (!activeControls.defoliation_storedPriorFeeding) {
            delete defoliation.storedPriorFeeding;
        }
        if (!activeControls.defoliation_additionalDefoliations) {
            delete defoliation.additionalDefoliations;
        }
        if (!activeControls.defoliation_numberMonthsDefoliating) {
            delete defoliation.numberMonthsDefoliating;
        }
    }

    render() {
        const { cancel, defoliation, refData, analysis, crop } = this.props;
        const { animalGrazing = {} } = defoliation;
        const { validation = {} } = this.state;
        const { defoliationFate = [], defoliationMethod = [] } = refData;
        const activeControls = this.getActiveControls(defoliation);
        const { structures = [] } = analysis;
        const enterprises = filteredEnterprises(analysis, defoliation, structures);

        let filteredFate = defoliationFate.filter((f) => !["Multiple", "StoredThenFedOut"].includes(f.value));
        if (!structures.some((s) => s.type === "MilkingShed")) {
            filteredFate = filteredFate.filter((e) => e.value !== "Inshed");
        }
        if (!structures.some((s) => s.type === "FeedingPad")) {
            filteredFate = filteredFate.filter((e) => e.value !== "Feedpad");
        }
        if (!structures.some((s) => ["CoveredWinteringPad", "UncoveredWinteringPad"].includes(s.type))) {
            filteredFate = filteredFate.filter((e) => e.value !== "Winteringpadbarn");
        }

        // To storage only allowed for maize silage on finish month of the crop
        if (crop.cropId !== "Forages_Maize" || defoliation.monthIndex !== crop.thermalTimeEndIndex) {
            filteredFate = filteredFate.filter((e) => e.value !== "ToStorage");
        }

        const numberMonthsDefoliating = [];
        const maxDefolMonths = this.getMaxDefoliationMonths();
        for (let index = 1; index <= maxDefolMonths; index++) {
            numberMonthsDefoliating.push({ value: index, text: index });
        }

        return (
            <div className="Modal_wrapper">
                <div className="Modal Modal--skinny">
                    <div className="Modal-head">
                        <span className="Modal-head--left">{`Defoliation - ${defoliation.month} ${defoliation.reportingYear ? "Reporting year" : "Year 1"}`}</span>
                        <span className="Modal-head--right">
                            <ActionLink onClick={this.props.toggleQuickTips} className="Modal-close">
                                <i className="icon icon-question" title={`${this.props.showQuickTips ? "Hide" : "Show"} quick tips`} />
                            </ActionLink>
                            <ActionLink onClick={cancel} id="modal_close" className="Modal-close">
                                <i className="icon icon-cross" title="Close" />
                            </ActionLink>
                        </span>
                    </div>
                    <div className="Modal-subhead">
                        <Alert type="info" text="Select whether the defoliation was grazed in place or removed (cut and carry)." />
                    </div>
                    <div className="Modal-body">
                        <div className="Grid">
                            <div className="Grid-cell">
                                <CheckboxPack meta={{ nrf: true }} id="finalHarvest" isHidden={!activeControls.defoliation_finalHarvest} label="Final harvest" disabled={true} value={defoliation.finalHarvest} tip="The final harvest check box can be ticked to indicate this is last defoliation event of the crop. The final harvest is the time at which regrowth ceases. Remaining tops and roots become residues the following month. If more than one month is represented as defoliating the final harvest will be represented as the last defoliation event." onChange={(e) => this.onChange(e, { type: "defoliation", key: "finalHarvest" })} />
                                <div className="Field-group">
                                    <SelectPack id="defoliation_defoliationMethod" label="Method of defoliation" dataWidth="50" meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "defoliation", key: "defoliationMethod" })} value={defoliation.defoliationMethod} val={validation.defoliation_defoliationMethod} requiredLabel={true}>
                                        <option value="" disabled={true}>
                                            Select method
                                        </option>
                                        {utils.mapRefDataItems(defoliationMethod)}
                                    </SelectPack>
                                    <SelectPack id="defoliation_defoliationFate" label="Destination of crop" dataWidth="50" meta={{ nrf: true }} isHidden={!activeControls.defoliation_defoliationFate} onChange={(e) => this.onChange(e, { type: "defoliation", key: "defoliationFate" })} value={defoliation.defoliationFate} val={validation.defoliation_defoliationFate} requiredLabel={true}>
                                        <option value="" disabled={true}>
                                            Select destination
                                        </option>
                                        {utils.mapRefDataItems(filteredFate)}
                                    </SelectPack>
                                    
                                    {!defoliation.finalHarvest && defoliation.maxAdditionalDefoliations && (
                                        <SelectPack id="defoliation_additionalDefoliations" label="Additional defoliation months" dataWidth="50" meta={{ nrf: true }} isHidden={!activeControls.defoliation_additionalDefoliations} onChange={(e) => this.onChange(e, { type: "defoliation", key: "additionalDefoliations" })} value={defoliation.additionalDefoliations} tip="For the last month of the grid, the number of defoliations not accounted for in the reporting year can be entered. This splits the crop yield between harvest (defolaitions in the reporting year) and standing yield (additional defoliations)." requiredLabel={true}>
                                            {[...Array(defoliation.maxAdditionalDefoliations)].map((m, i) => (
                                                <option value={i} key={i}>
                                                    {i === 0 ? "None" : i}
                                                </option>
                                            ))}
                                        </SelectPack>
                                    )}

                                    {!defoliation.finalHarvest && (
                                        <SelectPack id="defoliation_numberMonthsDefoliating" label="Number of months defoliating" dataWidth="50" meta={{ nrf: true }} isHidden={!activeControls.defoliation_numberMonthsDefoliating} onChange={(e) => this.onChange(e, { type: "defoliation", key: "numberMonthsDefoliating" })} value={defoliation.numberMonthsDefoliating} tip="Select the number of months this crop is defoliated. Defoliation can occur up to final month of the crop." requiredLabel={true}>
                                            {utils.mapRefDataItems(numberMonthsDefoliating)}
                                        </SelectPack>
                                    )}
                                </div>
                                {defoliation.defoliationFate === "ToStorage" && <>
                                    <CheckboxPack meta={{ nrf: true }} id="stackContained" label="Effluent from storage is contained" value={defoliation.stackContained} onChange={(e) => this.onChange(e, { type: "defoliation", key: "stackContained" })} />
                                </>}
                                {defoliation.defoliationMethod && <AnimalGrazing onChange={(e, source) => this.onChange(e, source)} animalGrazing={animalGrazing} validation={validation} source="defoliation" enterprises={enterprises} defoliation={defoliation} displayEqualRatio={false} isRequired={true} isRotation={this.props.isRotation} />}
                                <CheckboxPack meta={{ nrf: true }} id="storedPriorFeeding" isHidden={!activeControls.defoliation_storedPriorFeeding} label="Crop stored prior to feeding out" value={defoliation.storedPriorFeeding} tip="Whether all or some of this crop is stored and fed out in subsequent months. If not checked it is assumed to be fed out during the month of defoliation. If checked and fed to animals on a structure or to animals on pastoral blocks, usage is spread evenly over the months animals are on the structure or grazing pastoral blocks." onChange={(e) => this.onChange(e, { type: "defoliation", key: "storedPriorFeeding" })} />
                            </div>
                        </div>
                    </div>
                    <div className="Modal-footer">
                        <div className="ButtonBar">
                            <div className="ButtonBar-left">
                                <button type="button" onClick={cancel} className="Button Button--secondary" id="modal-cancel">
                                    Cancel
                                </button>
                            </div>
                            <div className="ButtonBar-right">
                                <button type="button" onClick={() => this.save()} className="Button" id="modal-save">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const filteredEnterprises = (analysis, defoliation, structures) => {
    let { enterprises = [] } = analysis;
    if (defoliation.defoliationMethod === "CutandCarry" && ["Inshed", "Feedpad", "Winteringpadbarn"].includes(defoliation.defoliationFate)) {
        //need to filter the enterprises based on what type of animal is on the structure
        const filterEnterprises = (types) =>
            enterprises.filter((e) =>
                structures
                    .filter((s) => types.includes(s.type))
                    .map((e) => e.enterpriseId)
                    .includes(e.id)
            );
        if (defoliation.defoliationFate === "Inshed") {
            enterprises = filterEnterprises(["MilkingShed"]);
        }
        if (defoliation.defoliationFate === "Feedpad") {
            enterprises = filterEnterprises(["FeedingPad"]);
        }
        if (defoliation.defoliationFate === "Winteringpadbarn") {
            enterprises = filterEnterprises(["CoveredWinteringPad", "UncoveredWinteringPad"]);
        }
    }
    return enterprises.filter((e) => e.type !== "OutdoorPigs");
}
