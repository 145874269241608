import { Link } from "react-router-dom";
import { ZendeskLink } from "components/Help";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";

export default function SupplementsActionsBar({ farm, analysis }) {
    return (
        <div className="ActionsBar ActionsBar--super u-print-none">
            <div className="ActionsBar-left">
                <ul className="ActionsBar-links">
                    <li>
                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/harvested`} className="IconLink--arrow-plus" id="add-harvested-supplement-link">
                            Add harvested supplement
                        </Link>
                    </li>
                    <li>
                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/purchased`} className="IconLink--arrow-plus" id="add-purchased-supplement-link">
                            Add purchased supplement
                        </Link>
                    </li>
                    <li>
                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/fromStorage`} className="IconLink--arrow-plus" id="add-fromStorage-supplement-link">
                            Add supplement from storage
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="ActionsBar-right">
                <ul className="ActionsBar-links">
                    {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                        <li>
                            <ScenarioToolLink farm={farm} analysis={analysis} />
                        </li>
                    )}
                    <li>
                        <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000903203" className="IconLink--new-tab u-font-green" />
                    </li>
                </ul>
            </div>
        </div>
    )
}
