import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

export default function FeatureTracker({ feature, action = "Open", farm }) {
    useFeatureTracker(feature, action, farm);
    return null;
}

export function useFeatureTracker(feature, onLoadAction, farm) {
    const [sessionId] = useState(uuidv4());

    const [featureTracker] = useState({
        track: async (action) => {
            fetch(`${process.env.REACT_APP_API_URL}/analytics/featureUsage`, {
                method: "post",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ sessionId, feature, action, location: window.location.hash, farmId: farm?.id, farmName: farm?.name }),
            }).catch((ex) => console.log(ex));
        },
    });

    useEffect(() => {
        if (onLoadAction) {
            featureTracker.track(onLoadAction);
        }
    }, [featureTracker, onLoadAction]);

    return featureTracker;
}

export const FEATURES = {
    SCENARIO_TOOL: "Scenario tool",
    EMAIL_SUMMARY_TO_OWNER: "Email farm summary to owner",
    EMAIL_GHG_TO_OWNER: "Email GHG summary to owner",
    FARM_TIMELINE: "Farm timeline",
    FARM_SUMMARY_REPORT: "Farm summary report",
    FARM_DETAILS: "Farm details report",
    BLOCK_SUMMARY: "Block summary report",
    BLOCK_DETAILS: "Block details report",
    COMPARE_BLOCKS: "Compare blocks report",
    ANIMAL_REPORT: "Animal report",
    GHG_REPORT: "GHG report",
    FEASIBILITY: "Feasibility report",
    FARM_IMPACT_REPORT: "Farm impact report",
    FARM_TRENDS: "Farm trend graphs",
    FARM_BENCHMARKS: "Farm benchmark graphs",
    SMAP_UPDATES: "Smap updates",
    PUBLICATION_REPORTING: "Publication reporting",
    FARM_GROUP_REPORTING: "Farm group reporting",
    IMPORT_MAP: "Import map",
    BLOCK_SOILS: "Block soils",
    ANALYSIS_LINKS_LATEST: "Analysis link - farm home latest link",
    ANALYSIS_LINKS_LIST: "Analysis link - analysis list link",
    ANALYSIS_LINKS_LOG_HOME: "Analysis link - farm home logs link",
    ANALYSIS_LINKS_LOG_PAGE: "Analysis link - activity logs link",
};
