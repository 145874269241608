import React from "react";
import { compose, withAppContext } from "containers/HOC";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";;

const BenchmarksSelectedBlock = ({ selectedBlock, availableBlockTypes, farmId, refData }) => {
    const farmName = selectedBlock ? (selectedBlock.farmId === farmId || selectedBlock.isMyFarm ? selectedBlock.farmName : "Anonymous farm") : "";
    const blockName = selectedBlock ? (selectedBlock.farmId === farmId || selectedBlock.isMyFarm ? selectedBlock.name : `Anonymous ${utils.valueToText(availableBlockTypes, selectedBlock.type)} block`) : "";
    const blockType = selectedBlock ? (selectedBlock.cutAndCarry ? "Cut and carry" : utils.valueToText(availableBlockTypes, selectedBlock.type)) : "";
    return (
        <Panel title="Selected block" openIfChanges={selectedBlock ? `${selectedBlock.analysisId}.${selectedBlock.id}` : undefined} inError={selectedBlock} skyBlue={!selectedBlock}>
            <PanelBody>
                <div id="selected-block">
                    {!selectedBlock && <div>Click on a bar within a chart to select a block</div>}
                    {selectedBlock && (
                        <>
                            <div className="Table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan="2" className="u-bg-midblue u-textWhite" title={blockName}>
                                                <span style={{ display: "inline-block", maxWidth: "255px", overflow: "hidden", textOverflow: "ellipsis" }}>{blockName}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr valign="top">
                                            <td>Farm name</td>
                                            <td>{farmName}</td>
                                        </tr>
                                        <tr valign="top">
                                            <td>Type</td>
                                            <td>{blockType}</td>
                                        </tr>
                                        {selectedBlock.averageRain && (
                                            <>
                                                <tr valign="top">
                                                    <td>Avg temp</td>
                                                    <td>{utils.round(selectedBlock.averageTemp, 2)} &deg;C</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>Avg rain</td>
                                                    <td>{utils.round(selectedBlock.averageRain, 2)} mm/yr</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td>Avg PET</td>
                                                    <td>{utils.round(selectedBlock.annualPET, 2)} mm/yr</td>
                                                </tr>
                                            </>
                                        )}
                                        <tr valign="top">
                                            <td>Area</td>
                                            <td>{utils.round(selectedBlock.area, 2)} ha</td>
                                        </tr>
                                        {selectedBlock.isProductive && (
                                            <tr valign="top">
                                                <td>Irrigated area</td>
                                                <td>{utils.round(selectedBlock.irrigatedArea, 2)} ha</td>
                                            </tr>
                                        )}
                                        {
                                            // Oddly, we have to use a ternary operator here or React has a wobbly.
                                            selectedBlock.pasture && selectedBlock.pasture.length > 0 ? (
                                                <tr valign="top">
                                                    <td>Pasture type</td>
                                                    <td>{utils.valueToText(refData.pastureTypes || [], selectedBlock.pasture)}</td>
                                                </tr>
                                            ) : null
                                        }
                                        {
                                            // Oddly, we have to use a ternary operator here or React has a wobbly.
                                            selectedBlock.enterpriseRSU && selectedBlock.enterpriseRSU.length > 0 ? (
                                                <>
                                                    <tr valign="top">
                                                        <th colSpan="2">Animal enterprise - RSU</th>
                                                    </tr>
                                                    {selectedBlock.enterpriseRSU.map((enterprise) => (
                                                        <React.Fragment key={enterprise.enterpriseType}>
                                                            <tr valign="top">
                                                                <td rowSpan="2">{utils.valueToText(refData.enterpriseTypes, enterprise.enterpriseType)}</td>
                                                                <td>{utils.round(enterprise.totalRSU, 0) + " rsu"}</td>
                                                            </tr>
                                                            <tr valign="top">
                                                                <td style={{borderLeftWidth: '1px'}}>{utils.round(enterprise.rsuPerHectare, 2) + " rsu/ha"}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            ) : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="Table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan="2" className="u-bg-nloss u-textWhite">
                                                Nitrogen
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr valign="top">
                                            <td>Total N loss</td>
                                            <td>{utils.round(selectedBlock.nLossTotal, 1).toLocaleString()} kg</td>
                                        </tr>
                                        <tr valign="top">
                                            <td>N loss per ha</td>
                                            <td>{utils.round(selectedBlock.nLossPerHa, 1).toLocaleString()} kg/ha</td>
                                        </tr>
                                        <tr valign="top">
                                            <td>N applied from fertiliser</td>
                                            <td>{(selectedBlock.nAppliedFromFertiliser || 0).toLocaleString()} kg/ha</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="Table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan="2" className="u-bg-ploss u-textWhite">
                                                Phosphorus
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr valign="top">
                                            <td>Total P loss</td>
                                            <td>{utils.round(selectedBlock.pLossTotal, 1).toLocaleString()} kg</td>
                                        </tr>
                                        <tr valign="top">
                                            <td>P loss per ha</td>
                                            <td>{utils.round(selectedBlock.pLossPerHa, 1).toLocaleString()} kg/ha</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </PanelBody>
        </Panel>
    );
};

export default compose(withAppContext)(BenchmarksSelectedBlock);
