import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";
import * as utils from "common/utils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import FarmFields, { ValidateFarmFields } from "./FarmFields";
import Alert from "components/Alert";
import { useIsOverseerEd, useModal, useNavigate, useRefData } from "common/hooks";
import { httpClient } from "common/httpClient";
import { useCreateFarmAsync } from "containers/hooks";

export default function CreateFarmModal({ close }) {
    const refData = useRefData();
    const isOverseerEd = useIsOverseerEd();
    const navigate = useNavigate();
    const createFarmAsync = useCreateFarmAsync();

    const [farm] = useState({
        id: uuidv4(),
        isNew: true,
        identifiers: [],
        isOwner: isOverseerEd ? "Yes" : undefined,
    });
    const [matchingFarms, setMatchingFarms] = useState([]);
    const [decorators] = useState([createDecorator()]);
    const modalTopRef = useRef();
    const hasMatchingFarms = matchingFarms.length > 0;

    const selectMatchingFarm = (matchingFarmId) => () => {
        setMatchingFarms((prevState) => prevState.map((f) => ({ ...f, selected: f.id === matchingFarmId && !f.selected })));
    };

    const submit = async (values) => {
        if (values.isOwner === "Yes") {
            delete values.ownerName;
            delete values.ownerEmail;
        }

        const hasMatchingFarms = matchingFarms.length > 0;
        const isNotAMatch = hasMatchingFarms && !values.match;
        if (isOverseerEd || (hasMatchingFarms && isNotAMatch)) {
            const submitResult = await createFarmAsync(values)
                .then(() => {
                    close();
                    navigate(`/app/farm/${values.id}`)
                })
                .catch((ex) => ({ [FORM_ERROR]: ex.message }));
            return submitResult;
        }

        if (!hasMatchingFarms) {
            const submitResult = await findMatchingFarmsAsync(values)
                .then(async (response) => {
                    if (response.length === 0) {
                        return await createFarmAsync(values)
                            .then(() => {
                                close();
                                navigate(`/app/farm/${values.id}`)
                            })
                            .catch((ex) => ({ [FORM_ERROR]: ex.message }));
                    } else {
                        setMatchingFarms(response);
                        modalTopRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
                    }
                })
                .catch((error) => ({ [FORM_ERROR]: error }));
            return submitResult;
        }
    };

    return (
        <Form initialValues={farm} validate={ValidateFarmFields} mutators={arrayMutators} decorators={decorators} onSubmit={submit}>
            {({ form, values, handleSubmit, submitting, submitError, dirtySinceLastSubmit, errors, submitFailed }) => {
                const error = !dirtySinceLastSubmit && submitError;
                const matchingFarmsInfo = hasMatchingFarms ? (
                    <span>
                        There are <b>{matchingFarms.length}</b> farms with similar details to the farm you are trying to create. Are any of these farms the one you are trying to create?
                    </span>
                ) : undefined;
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={hasMatchingFarms ? "Possible duplicate?" : "Create new farm"} close={close} submitting={submitting} full green>
                            <ModalBody error={error} info={!hasMatchingFarms ? "Enter the farm name and physical address. If the system can associate a nearest town for the address this will be automatically selected. If you wish to use region instead, select 'Use region' for nearest town and then select a region." : ""}>
                                <div ref={modalTopRef}></div>
                                {hasMatchingFarms && <Alert type="warning" text={matchingFarmsInfo} />}
                                {!hasMatchingFarms && <FarmFields form={form} values={values} farm={values} errors={errors} submitFailed={submitFailed} />}
                                {hasMatchingFarms && (
                                    <div className="flexWidget matchingFarms u-mt-md">
                                        <div className="flexWidget__header">
                                            <h3>Are any of these farms the one you are trying to create?</h3>
                                        </div>
                                        <div className="flexWidget__body">
                                            <div className="Table u-mt-md">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Farm</th>
                                                            <th>Region</th>
                                                            <th>Description</th>
                                                            <th>Ownership</th>
                                                            <th className="th--shrink">Match?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {matchingFarms.map((matchingFarm) => {
                                                            const region = matchingFarm.region ? utils.valueToText(refData.regions, matchingFarm.region) : "-";

                                                            return (
                                                                <tr key={matchingFarm.id} className={matchingFarm.selected ? "hover is-selected" : "hover"} onClick={selectMatchingFarm(matchingFarm.id)}>
                                                                    <td>
                                                                        <div className="u-textBold">{matchingFarm.name}</div>
                                                                        <div>{matchingFarm.address}</div>
                                                                    </td>
                                                                    <td>{region}</td>
                                                                    <td>{matchingFarm.description}</td>
                                                                    <td>{matchingFarm.ownershipStatus}</td>
                                                                    <td className="u-textCenter">
                                                                        <label className="Radio">
                                                                            <input className="Radio-input" type="radio" name="matchingFarm" value={matchingFarm.selected} onChange={selectMatchingFarm(matchingFarm.id)} />
                                                                            <span className="Radio-label"></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                {!hasMatchingFarms && (
                                    <ModalFooterRight>
                                        <Button id="submit" submit primary disabled={submitting}>
                                            Create
                                        </Button>
                                    </ModalFooterRight>
                                )}
                                {hasMatchingFarms && (
                                    <ModalFooterRight>
                                        <Button id="no" submit tertiary disabled={submitting}>
                                            Create new farm
                                        </Button>
                                        <Button id="yes" primary onClick={() => close(() => navigate(`/app/farm/${matchingFarms.find((f) => f.selected).id}`))} disabled={!matchingFarms.some((f) => f.selected)}>
                                            Go to existing farm
                                        </Button>
                                    </ModalFooterRight>
                                )}
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
};

export function useCreateFarmModal() {
    const [modal, openModal] = useModal(CreateFarmModal);

    const openCreateFarmModal = () => {
        openModal();
    };

    return [modal, openCreateFarmModal];
}

function findMatchingFarmsAsync(farm) {
    return httpClient.get(`search/existingfarms?name=${encodeURIComponent(farm.name)}&address=${encodeURIComponent(farm.address)}&ownerName=${encodeURIComponent(farm.ownerName)}&ownerEmail=${encodeURIComponent(farm.ownerEmail)}&identifiers=${encodeURIComponent(farm.identifiers)}&supplierNumber=${encodeURIComponent(farm.supplierNumber)}`);
}
