import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import ActionLink from "components/ActionLink";
import { useModal } from "common/effects";
import CreateFarmLink from "containers/Farm/CreateFarmLink";
import CreateFarmGroupModal from "containers/FarmGroups/CreateFarmGroupModal";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import { useAuthContext, useOnline } from "common/hooks";

export default function DashboardPageHead({ breadcrumb }) {
    const online = useOnline();
    const authContext = useAuthContext();
    const { openModal, getModal } = useModal();

    const createFarmGroup = () => {
        const viewModel = {
            id: uuidv4(),
            showMembers: true,
            showAggregatedReport: true,
            showBenchmarkReport: true,
        };
        openModal("CreateFarmGroup", { viewModel });
    };
    const createFarmGroupModal = getModal("CreateFarmGroup");

    return (
        <>
            <section className="PageHead">
                <div className="Container">
                    <div className="FarmTitle">
                        <div className="FarmTitle-heading">
                            <span id="page-head-title" className="FarmTitle-name">
                                {"Dashboard (" + authContext.accountName + ")"}
                            </span>
                        </div>
                    </div>
                    {online && (
                        <div className="PageHead-actions">
                            {process.env.REACT_APP_DISABLE_FARM_GROUPS !== "true" && (
                                <ActionLink id="myfarms-header-create-group" className="IconLink--arrow-plus" onClick={createFarmGroup}>
                                    Create farm group
                                </ActionLink>
                            )}
                            <Link className="IconLink--search" to="/app/search/farms" id="myfarms-header-search">
                                Find existing farm
                            </Link>
                            <CreateFarmLink id="myfarms-header-create" />
                        </div>
                    )}
                </div>
            </section>
            {breadcrumb && breadcrumb.length > 0 && <Breadcrumb breadcrumb={breadcrumb} />}

            {createFarmGroupModal && <CreateFarmGroupModal {...createFarmGroupModal.modalProps} />}
        </>
    );
}
