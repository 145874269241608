import { Field } from "react-final-form";
import Alert from "components/Alert";
import { useShowQuickTips } from "common/hooks";

export default function RadioGroupField({ input, options, label, onChange, inline, info, warning, tip, disabled, required, meta }) {
    const showQuickTips = useShowQuickTips();

    const handleOnChange = (value) => {
        if (input.onChange) input.onChange(value);

        if (onChange) onChange(value);
    };

    const error = meta && meta.touched && meta.error;

    return (
        <div className={`Field ${error ? "has-error" : ""}`}>
            {label && (
                <label className={`Field-label ${disabled ? "is-disabled" : ""}`}>
                    {label}
                    {required && <sup className="required">*</sup>}
                </label>
            )}
            <div className={`Radio_group ${inline ? "Radio_group--inline" : ""}`}>
                {options.map((option) => {
                    return (
                        <Field key={option.value} {...input} type="radio" value={option.value} onChange={handleOnChange} disabled={disabled || option.disabled} component={RadioField}>
                            {option.text}
                        </Field>
                    );
                })}
            </div>
            {error && (
                <div id={`${input.name}-error`} className="Field-error u-block u-mt-sm">
                    {error}
                </div>
            )}
            {warning && <Alert type="warning" text={warning} className="u-mb-0" />}
            {info && <Alert type="info" text={info} className="u-mb-0" />}
            {showQuickTips && tip && <Alert type="help" text={tip} className="u-mb-0" />}
        </div>
    );
}

function RadioField({ input, children, disabled, onChange }) {
    const handleOnChange = (e) => {
        const value = e.target.value;

        if (input.onChange) input.onChange(value);

        if (onChange) onChange(value);
    };

    const id = `${input.name}_${input.value}`;
    return (
        <label className="Radio" htmlFor={id}>
            <input type="radio" {...input} id={id} data-test-id={id} onChange={handleOnChange} className="Radio-input" disabled={disabled} />
            <span className={disabled ? "Radio-label u-textMidGrey" : "Radio-label"}>{children}</span>
        </label>
    );
}
