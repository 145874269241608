import { Bar } from "react-chartjs-2";
import * as utils from "common/utils";
import { useAnalysisResults } from "containers/BudgetHome";

export default function AnimalsRsuByMonthCharts({ before, after }) {
    const { rsuByMonth, rsuByEnterprise } = useRsuByMonth(before, after);

    return (
        <>
            <div className="ReportSection">
                <h1>Enterprise RSU by month</h1>
                <div className="ReportSection u-page-break-avoid">
                    <h2>{rsuByMonth.title}</h2>
                    <div className="printable-chart-container">
                        <Bar data={rsuByMonth.chartData} options={rsuByMonth.chartOptions} className="printable-chart" />
                    </div>
                </div>
                {rsuByEnterprise.map((enterprise) => {
                    return (
                        <div key={enterprise.title} className="ReportSection u-page-break-avoid">
                            <h2>{enterprise.title}</h2>
                            <div className="printable-chart-container">
                                <Bar data={enterprise.chartData} options={enterprise.chartOptions} className="printable-chart" />
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

function useRsuByMonth(before, after) {
    const { data: beforeResults } = useAnalysisResults(before?.details);
    const { data: afterResults } = useAnalysisResults(after?.details);

    let maxBefore = 0;
    let beforeData;
    if (before) {
        beforeData = {
            displayName: before.displayName,
            data: beforeResults?.animals?.diet?.byMonth?.map(x => ({ month: x.month, value: x.revisedStockUnits.value }))
        }
        maxBefore = beforeData?.data ? Math.max(...beforeData.data.map(x => x.value)) : 0;
    }
    const afterData = {
        displayName: after?.displayName,
        data: afterResults?.animals?.diet?.byMonth?.map(x => ({ month: x.month, value: x.revisedStockUnits.value }))
    }
    const maxAfter = afterData?.data ? Math.max(...afterData.data.map(x => x.value)) : 0;
    const maxYForScaleConsistency = Math.max(maxBefore, maxAfter); // Get the max Y-axis value to ensure consistency between the scale of the RSU charts
    const rsuByMonth = getChartData("All enterprises", "RSU", beforeData, afterData, maxYForScaleConsistency);

    // Cater for the distinct union of enterprises when comparing
    const beforeEnterpriseNames = beforeResults?.animals?.diet?.byEnterprise?.map((e) => e.name) || [];
    const afterEnterpriseNames = afterResults?.animals?.diet?.byEnterprise?.map((e) => e.name) || [];
    const distinctEnterpriseNames = [...new Set([...beforeEnterpriseNames, ...afterEnterpriseNames])];

    const rsuByEnterprise = distinctEnterpriseNames.map((enterpriseName) => {
        const beforeEnterprise = beforeResults?.animals?.diet?.byEnterprise?.find((e) => e.name === enterpriseName);
        const afterEnterprise = afterResults?.animals?.diet?.byEnterprise?.find((e) => e.name === enterpriseName);

        let beforeData;
        if (before) {
            beforeData = {
                displayName: before?.displayName,
                data: beforeEnterprise?.byMonth.map(x => ({ month: x.month, value: x.revisedStockUnits.value }))
            }
        }
        const afterData = {
            displayName: after?.displayName,
            data: afterEnterprise?.byMonth.map(x => ({ month: x.month, value: x.revisedStockUnits.value }))
        }

        const chartData = getChartData(enterpriseName, "RSU", beforeData, afterData, maxYForScaleConsistency); // Again, pass the max value to ensure consistency between the scale of the RSU charts
        return chartData;
    })

    return {
        rsuByMonth,
        rsuByEnterprise
    }
}

function getChartData(title, uom, before, after, maxY) {
    const datasets = [];

    if (before) {
        const beforeDataset = {
            label: before.displayName,
            fill: false,
            lineTension: 0.1,
            backgroundColor: "#e3eef8",
            borderColor: "#509bc2",
            borderWidth: 1,
            data: before.data?.map(x => x.value) || after?.data?.map(x => 0), // Fill with zeros if "before" does not have equivalent data to "after""
        };
        datasets.push(beforeDataset);
    }

    if (after) {
        const afterDataset = {
            label: after.displayName,
            fill: false,
            lineTension: 0.1,
            backgroundColor: "#c6f3c6",
            borderColor: "#52af6e",
            borderWidth: 1,
            data: after.data?.map(x => x.value) || before?.data?.map(x => 0), // Fill with zeros if "after" does not have equivalent data to "before"
        };
        datasets.push(afterDataset);
    }

    const chartOptions = getChartOptions(title, uom, maxY);
    const months = before?.data?.map(x => x.month) || after?.data?.map(x => x.month);

    const result = {
        title: chartOptions.scales.y.title.text,
        chartData: {
            labels: months,
            datasets
        },
        chartOptions,
    }
    return result;
}

function getChartOptions(title, uom, maxY) {
    const titleText = uom ? `${title} (${uom})` : title;
    return {
        scales: {
            y: {
                display: true,
                title: {
                    text: titleText,
                    display: true
                },
                max: maxY,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        if (uom) {
                            return `${utils.numeric.format(tooltipItem.raw)} ${uom}`;
                        } else {
                            return utils.numeric.format(tooltipItem.raw);
                        }
                    },
                },
            },
        },
        maintainAspectRatio: false,
        responsive: true,
    }
}
