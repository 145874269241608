import React from "react";

const RadioGroup = (props) => {
    const { id, name, value, label, options, required, onChange, inline = true, meta } = props;

    const hasError = meta && meta.touched && meta.error;

    return (
        <div className={`Field${hasError ? " has-error" : ""}`}>
            <label className="Field-label">
                {label}
                {required && <sup className="required">*</sup>}
            </label>
            <div className={`Radio_group${inline === true ? " Radio_group--inline" : ""}`}>
                {options.map((option) => {
                    const inputId = `${id || name}.${option.value}`;
                    return (
                        <label key={option.value} className="Radio" htmlFor={inputId}>
                            <input className="Radio-input" type="radio" id={inputId} value={option.value} checked={value === option.value} onChange={onChange} />
                            <span className="Radio-label">{option.text}</span>
                        </label>
                    );
                })}
            </div>
            {hasError && (
                <small id={`${id || name}-error`} className="Field-error">
                    {meta.error}
                </small>
            )}
        </div>
    );
};

export default RadioGroup;
