import ActionLink from "components/ActionLink";
import { getModifiedSoilPropertiesData } from "./_utils";

export default function SoilPropertiesSummary({ soil, editMode, toggleEditMode, isModified, refData }) {
    const data = getModifiedSoilPropertiesData(soil, refData);
    const isSmapSoil = soil.sibling && soil.sibling.smapReference;

    return (
        <ul className="DataWidthTable FarmTable">
            {(isModified || editMode) && (
                <>
                    {data.kLeaching && (
                        <li data-width="xl-10">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">{data.kLeaching.title}</span>
                                    <span className="FarmTable-value">
                                        {data.kLeaching.items.map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                    {data.wiltingPoint && (
                        <li data-width="xl-12.5">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">{data.wiltingPoint.title}</span>
                                    <span className="FarmTable-value">
                                        {data.wiltingPoint.items.map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                    {data.fieldCapacity && (
                        <li data-width="xl-12.5">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">{data.fieldCapacity.title}</span>
                                    <span className="FarmTable-value">
                                        {data.fieldCapacity.items.map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                    {data.saturation && (
                        <li data-width="xl-12.5">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">{data.saturation.title}</span>
                                    <span className="FarmTable-value">
                                        {data.saturation.items.map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                    {data.topSoil && (
                        <li data-width="xl-12.5">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">{data.topSoil.title}</span>
                                    <span className="FarmTable-value">
                                        {data.topSoil.items.map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                    {data.subSoil && (
                        <li data-width="xl-12.5">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">{data.subSoil.title}</span>
                                    <span className="FarmTable-value">
                                        {data.subSoil.items.map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                    {data.organicMatter && (
                        <li data-width="xl-15">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">{data.organicMatter.title}</span>
                                    <span className="FarmTable-value">
                                        {data.organicMatter.items.map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                </>
            )}
            {!isModified && (
                <li data-width="xl-20">
                    <h4>{isSmapSoil ? "Using S-Map defaults" : "Using system defaults"}</h4>
                </li>
            )}
            {!editMode && !isModified && (
                <li data-width="xl-12.5">
                    <ActionLink className="IconLink--edit u-mt-sm" onClick={toggleEditMode(true)}>
                        {isSmapSoil ? "Override S-Map defaults" : "Override system defaults"}
                    </ActionLink>
                </li>
            )}
            {editMode && !isModified && (
                <li data-width="xl-12.5">
                    <ActionLink className="IconLink--cross-circle u-mt-sm" onClick={toggleEditMode(false)}>
                        {isSmapSoil ? "Use S-Map defaults" : "Use system defaults"}
                    </ActionLink>
                </li>
            )}
            {isModified && (
                <li data-width="xl-12.5">
                    <ActionLink className="IconLink--refresh u-mt-md" onClick={toggleEditMode(false)}>
                        {isSmapSoil ? "Restore S-Map defaults" : "Restore system defaults"}
                    </ActionLink>
                </li>
            )}
        </ul>
    );
}
