import React from "react";
import { Bar } from "react-chartjs-2";
import * as domain from "common/domain";
import { getChartOptions } from "./_utils";

export default function MonthlySummary({ scenarios = [], categoryName, rowName, disableSameY }) {
    const { model, maxY } = getFarmLevelModel(scenarios, categoryName, rowName);
    const hasData = model.some((m) => m.total > 0);
    if (!hasData) return null;

    return model
        .filter((m) => m.total > 0)
        .map((m) => {
            const data = getChartData(m.data, m.rotationYear, scenarios);
            const options = getChartOptions(m.label, m.uom);
            if (!disableSameY && options) {
                options.scales.y.max = maxY;
            }
            return (
                data && (
                    <div key={`${m.id}-${m.data.length}`} className="ReportSection u-page-break-avoid">
                        <h2>{m.label}</h2>
                        <div className="printable-chart-container">
                            <Bar data={data} className="printable-chart" options={options} />
                        </div>
                    </div>
                )
            );
        });
}

export const getFarmLevelModel = (scenarios, categoryName, rowName) => {
    const isFert = categoryName === "Fertiliser";
    const allRowNames = isFert
        ? [{ name: rowName, label: rowName }]
        : scenarios.reduce((results, scenario) => {
              const category = scenario.summary.categories.find((c) => c.name === categoryName);
              if (category) {
                  const rows = category.rows.reduce((names, row) => {
                      if (!results.some((a) => a.name === row.name)) names.push({ name: row.name, label: row.labels.join(", ") });
                      return names;
                  }, []);
                  return results.concat(rows);
              }
              return results;
          }, []);

    let maxY = 0;
    const model = allRowNames.map((rowName) => {
        let total = 0;
        let uom = "";
        const data = scenarios.map((scenario) => {
            const category = (scenario.summary.categories || []).find((c) => c.name === categoryName) || {};
            const row = (category.rows && category.rows.find((r) => r.name === rowName.name)) || { months: [] };
            if (uom === "" && row.uom) uom = row.uom;
            return domain.farmYear.map((m) => {
                const month = row.months.find((rm) => rm.month === m && rm.reportingYear);
                if (month) {
                    maxY = maxY < month.value ? month.value : maxY;
                    total += month.value;
                    return month.value;
                } else {
                    return 0;
                }
            });
        });

        return {
            id: rowName.label,
            rowType: rowName.name,
            label: `${rowName.label} (${uom})`,
            rotationYear: domain.farmYear,
            data,
            total,
            uom,
        };
    });
    return { model, maxY };
}

const getChartData = (data, rotationYear, scenarios) => {
    const chartData = data.map((m, i) => {
        return {
            label: scenarios[i].displayName,
            fill: false,
            lineTension: 0.1,
            backgroundColor: data.length > 1 && i === 0 ? "#E3EEF8" : "#c6f3c6",
            borderColor: data.length > 1 && i === 0 ? "#509BC2" : "#52af6e",
            borderWidth: 1,
            data: m,
        };
    });

    return {
        datasets: chartData,
        labels: rotationYear,
    };
}
