import * as domain from "common/domain";
import { v4 as uuidv4 } from "uuid";

export const createEmptyBlock = (name) => {
    const emptyMonthValueSet = () => domain.calendarYear.map((c) => ({ month: c }));
    return {
        id: uuidv4(),
        type: "ProductivePasture",
        isProductive: true,
        name: name,
        areaInHectares: 10,
        climate: { regionalData: { rainFall: emptyMonthValueSet(), temperature: emptyMonthValueSet(), pet: emptyMonthValueSet() } },
        pasture: { topography: "Flat", pastureCultivated: false },
        scienceInputs: {
            nUptakeKG: emptyMonthValueSet(),
            nFertiliserKG: emptyMonthValueSet(),
            nEffluentKG: emptyMonthValueSet(),
            irrigationMM: emptyMonthValueSet(),
            drainageMM: emptyMonthValueSet(),
            startMonth: "January",
        },
    };
};

export const hasValue = (array) => array.some((v) => !isNaN(v.value) && v.value > 0);

export const analysisViewModel = (analysis) => {
    const { blocks = [] } = analysis;
    blocks.forEach((b) => {
        const { scienceInputs = {}, formState = {} } = b;
        const { nUptakeKG = [] } = scienceInputs;
        formState.annualClimate = isAnnualClimate(b);
        formState.annualNUptake = !hasValue(nUptakeKG);
        b.formState = formState;
    });

    padScienceArrays(analysis);
    return analysis;
};

export const padScienceArrays = (analysis) => {
    const padArray = (arr) => {
        domain.calendarYear.forEach((m, i) => {
            const monthVal = arr.find((a) => a.month === m);
            if (monthVal) {
                monthVal.indx = i;
            } else {
                arr.splice(i, 0, { month: m, indx: i, value: 0 });
            }
        });
    };
    const { blocks = [] } = analysis;
    /* eslint-disable no-unused-vars */
    for (const block of blocks) {
        const { climate = {}, scienceInputs = {} } = block;
        const { regionalData = {} } = climate;
        const { rainFall = [], temperature = [], pet = [] } = regionalData;
        const { nUptakeKG = [], nFertiliserKG = [], nEffluentKG = [], irrigationMM = [], drainageMM = [] } = scienceInputs;
        const allArrays = [rainFall, temperature, pet, nUptakeKG, nFertiliserKG, nEffluentKG, irrigationMM, drainageMM];
        allArrays.forEach((arr) => padArray(arr));
        scienceInputs.nUptakeKG = nUptakeKG.sort((a, b) => a.indx - b.indx);
        scienceInputs.nFertiliserKG = nFertiliserKG.sort((a, b) => a.indx - b.indx);
        scienceInputs.nEffluentKG = nEffluentKG.sort((a, b) => a.indx - b.indx);
        scienceInputs.irrigationMM = irrigationMM.sort((a, b) => a.indx - b.indx);
        scienceInputs.drainageMM = drainageMM.sort((a, b) => a.indx - b.indx);
        regionalData.rainFall = rainFall.sort((a, b) => a.indx - b.indx);
        regionalData.temperature = temperature.sort((a, b) => a.indx - b.indx);
        regionalData.pet = pet.sort((a, b) => a.indx - b.indx);
        block.scienceInputs = scienceInputs;
        climate.regionalData = regionalData;
    }
};

export const isAnnualClimate = (block) => {
    const { climate = {} } = block;
    const { regionalData = {} } = climate;
    const { rainFall = [], temperature = [], pet = [] } = regionalData;
    return ![rainFall, temperature, pet].some((t) => hasValue(t));
};
