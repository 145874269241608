import { useState, useCallback } from "react";
import { useLocation } from "react-router";
import { useCurrentWidth } from "common/effects";
import * as utils from "common/utils";
import { Link, matchPath } from "react-router-dom";
import ActionLink from "components/ActionLink";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import RenewSubscriptionButton from "containers/Payments/RenewSubscriptionButton";
import moment from "moment";
import { usePaymentModal } from "containers/Payments/PaymentModal";
import { usePaymentAdviceModal } from "containers/Payments/PaymentAdviceModal";
import { useCreateAnalysisModal } from "./Modals/CreateAnalysisModal";
import { useCreateFertiliserRecommendationModal } from "./Modals/CreateFertiliserRecommendationModal";
import FarmMenu from "components/FarmMenu";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import { RequestFarmAccessActionLink } from "containers/Farm/RequestFarmAccess";
import { useFarm, useToggleMyFarmAsync } from "containers/hooks";
import { useAuthContext, useRefData } from "common/hooks";

export default function FarmHomePageHead({ breadcrumb }) {
    const { data: farm, error } = useFarm();
    const refData = useRefData();
    const authContext = useAuthContext();
    const location = useLocation();
    const displayPayment = location?.pathname?.endsWith("dp") || false;
    const toggleMyFarmAsync = useToggleMyFarmAsync();
    const [createAnalysisModal, openCreateAnalysisModal] = useCreateAnalysisModal(farm);
    const [createFertiliserRecommendationModal, openCreateFertiliserRecommendationModal] = useCreateFertiliserRecommendationModal(farm);
    const [paymentModal, openPaymentModal] = usePaymentModal();
    const [paymentAdviceModal, openPaymentAdviceModal] = usePaymentAdviceModal(farm);

    const farmAccessDenied = error?.status === 403;

    const [hasOpenedPaymentModal, setHasOpenedPaymentModal] = useState(false);
    const [farmMenuOpen, setFarmMenuOpen] = useState(false);
    const [left, setLeft] = useState(0);
    const width = useCurrentWidth();

    const payForSubscription = () => {
        openPaymentModal([farm]);
    }

    const hamRef = useCallback(
        (element) => {
            if (element !== null && width > 0) {
                setLeft(element.getBoundingClientRect().left);
            }
        },
        [width]
    )

    const updateFarmAccess = utils.canUpdateFarm(farm);
    if (displayPayment && !hasOpenedPaymentModal) {
        setHasOpenedPaymentModal(true);
        payForSubscription();
    }

    const hasFarmWriteAccess = utils.canManageAnalyses(farm);
    const daysTillExpired = farm.expired ? 0 : farm.expirationDate ? moment.utc(farm.expirationDate).diff(moment.utc(), "days") : 0;
    const viewTimeline = !displayPayment && (utils.canUpdateFarm(farm) || utils.canCreateWorkingCopy(farm)) && farm?.budgets?.some(b => b.type === "YearEnd");

    const { roles = [], publications = [] } = farm;
    const iAmWrite = roles.includes("Write");
    const cannotSendPaymentRequest = farm.ownershipStatus !== "Owned" && iAmWrite;
    const farmUrl = `/app/farm/${farm.id}`;
    const hasPublications = publications.length > 0;
    const canGrantAgentAccess = utils.canGrantPublicationAccess(farm);
    const farmHasLocation = refData.deploymentType === "Science" || (farm.latitude !== 0 && farm.longitude !== 0);
    const isEducation = refData.deploymentType === "Education";

    return (
        <>
            {farmMenuOpen && farmHasLocation && <FarmMenu farm={farm} onClick={() => setFarmMenuOpen(false)} left={left} onMouseOut={() => setFarmMenuOpen(false)} displayPayment={displayPayment} deploymentType={refData.deploymentType} />}
            <section className="PageHead">
                <div className="Container" ref={hamRef}>
                    <div className="FarmTitle">
                        {farmHasLocation && <ActionLink onClick={() => setFarmMenuOpen(!farmMenuOpen)} className="RoundButton RoundButton--hamburger FarmHome-hamburger"></ActionLink>}
                        <div className="FarmTitle-heading FarmTitle-heading--lg">
                            <Link to={farmUrl}>
                                <span id="page-head-title" className="FarmTitle-name" title={farm.name}>
                                    {farm.displayName}
                                </span>
                            </Link>

                            <div id="page-head-sub-title" className="FarmTitle-sub" title={farm.address}>
                                {farm.address}
                            </div>
                        </div>
                    </div>
                    <div className="PageHead-actions">
                        {refData.deploymentType === "Commercial" && (
                            <>
                                {farm.expired || !farm.expirationDate || daysTillExpired < 0 ? (
                                    <>
                                        {!cannotSendPaymentRequest && hasFarmWriteAccess && (
                                            <ActionLink id="farm-home-send-payment-advice" className="IconLink--dollar UnpaidSubscription" onClick={() => openPaymentAdviceModal()}>
                                                Send payment request
                                            </ActionLink>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {!farm.expired && daysTillExpired >= 0 && daysTillExpired < 182 && (
                                            <ActionLink id="farm-home-make-payment" className="IconLink--dollar ExtendSubscription" onClick={payForSubscription}>
                                                Extend subscription
                                            </ActionLink>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        {hasFarmWriteAccess && (
                            <ActionLink id="farm-home-create-analysis" className="IconLink--arrow-plus u-link" onClick={() => openCreateAnalysisModal()}>
                                Create analysis
                            </ActionLink>
                        )}

                        {hasFarmWriteAccess && authContext.canCreateFertiliserRecommendations && (
                            <ActionLink id="farm-home-create-fert-rec" className="IconLink--arrow-plus u-link" onClick={() => openCreateFertiliserRecommendationModal()}>
                                Create fertiliser recommendation
                            </ActionLink>
                        )}

                        {viewTimeline && !farm.expired && (
                            //!matchPath(location.pathname, { path: `/app/farm/${farm.id}/timeline`, exact: true }) &&
                            <Link className="IconLink--clock u-link" to={`/app/farm/${farm.id}/timeline`} id="farm-home-timeline-link">
                                Farm timeline
                            </Link>
                        )}

                        {updateFarmAccess && (
                            <Link className="IconLink--settings u-link" to={`/app/farm/${farm.id}/settings`} id="farm-home-edit-link">
                                Farm settings
                            </Link>
                        )}

                        {hasPublications && (authContext.isSystemAdmin || canGrantAgentAccess) && !matchPath(location.pathname, { path: `/app/farm/${farm.id}/publicationAccess`, exact: true }) && (
                            <Link className="IconLink--share u-link" to={`/app/farm/${farm.id}/publicationAccess`} id="share-publication-link">
                                Manage publication access
                            </Link>
                        )}

                        {!updateFarmAccess && !farm.accessRequest && !isEducation && (
                            <RequestFarmAccessActionLink farm={farm} className="IconLink--arrow-plus u-link">
                                Request farm access
                            </RequestFarmAccessActionLink>
                        )}
                        {!farmAccessDenied && (
                            <label id="farm-home-toggle-my-farm" title={farm.isMyFarm ? "Remove from my farms" : "Add to my farms"} className="flex-center u-link">
                                <Toggle defaultChecked={farm.isMyFarm} onChange={async () => await toggleMyFarmAsync(farm.id, !farm.isMyFarm)} className="My-farm-toggle" />
                                <div className="u-pl-sm h5 a">{farm.isMyFarm ? "In my farms" : "Add to my farms"}</div>
                            </label>
                        )}
                        <RenewSubscriptionButton farm={farm} />
                    </div>
                </div>
            </section>
            {breadcrumb && breadcrumb.length > 0 && <Breadcrumb breadcrumb={breadcrumb} />}
            {paymentModal}
            {paymentAdviceModal}
            {createAnalysisModal}
            {createFertiliserRecommendationModal}
        </>
    )
}
