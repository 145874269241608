import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useIsOverseerEd } from "common/hooks";
import { httpClient } from "common/httpClient";
import * as FormUtils from "common/FormUtils";
import { dateLong } from "common/utils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import { CheckboxField, HiddenField, TextField } from "components/FormFields";
import { useRefreshOrgSearchResultsIfAny } from "containers/Admin/Search";

export default function UserModal({ user, close }) {
    const saveUserAsync = useSaveUserAsync();
    const isOverseerEd = useIsOverseerEd();

    const initialValues = {
        ...user,
        lastLoginReadOnly: dateLong(user.lastLogin, "-"),
        createdOnReadOnly: dateLong(user.createdOn, "-"),
    }

    const onResetPasswordChange = (form) => {
        form.change("password", null);
    }

    const onIsAdminChange = (isAdmin, form) => {
        if (isAdmin) {
            form.change("isApiUser", false);
        }
    }

    const onIsApiUserChange = (isApiUser, form) => {
        if (isApiUser) {
            form.change("isAdmin", false);
        }
    }

    const validateAsync = async (formValues) => {
        const validation = {};

        validation.email = FormUtils.validators.required(formValues.email);
        validation.email = validation.email || FormUtils.validators.email(formValues.email);

        validation.fullName = FormUtils.validators.required(formValues.fullName);
        validation.fullName = validation.fullName || FormUtils.validators.maxLength(50)(formValues.fullName);
        validation.fullName = validation.fullName || FormUtils.validators.nameRegex(formValues.fullName);

        const isAddingNewUser = !formValues.id;
        const isResettingExistingUsersPassword = formValues.resetPassword;
        if (isAddingNewUser || isResettingExistingUsersPassword) {
            validation.password = FormUtils.validators.required(formValues.password);
            validation.password = validation.password || FormUtils.validators.range(5, 30)(formValues.password.length);
        }

        return validation;
    }

    const submitAsync = async (formValues) => {
        try {
            await saveUserAsync(formValues);
            close();
        } catch (ex) {
            return {
                [FORM_ERROR]: ex.message,
            };
        }
    }

    return (
        <Form initialValues={initialValues} validate={validateAsync} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting, submitError }) => {
                const isEditingExistingUser = values.id;
                const title = isEditingExistingUser ? "Edit user" : "Add user";
                const isAddingNewUser = !isEditingExistingUser;
                const isResettingExistingUsersPassword = values.resetPassword;
                const showPassword = isAddingNewUser || isResettingExistingUsersPassword;
                const userRequiresActivation = isEditingExistingUser && !values.isActivated;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={title} close={close} submitting={submitting} skinny fluid allowOffline>
                            <ModalBody error={submitError}>
                                <Grid>
                                    <GridCell>
                                        <Field name="accountId" component={HiddenField} />
                                        {isEditingExistingUser && <Field name="id" label="ID" readOnly component={TextField} />}
                                        <Field name="email" label="Email" required component={TextField} />
                                        <Field name="fullName" label="Full name" required component={TextField} />
                                        {isEditingExistingUser && <Field name="resetPassword" label="Reset password" onChange={() => onResetPasswordChange(form)} component={CheckboxField} />}
                                        {showPassword && <Field name="password" label="Password" required component={TextField} />}
                                        {isEditingExistingUser && (
                                            <>
                                                <Field name="isAdmin" label="Is org admin" onChange={(isAdmin) => onIsAdminChange(isAdmin, form)} component={CheckboxField} />
                                                <Field name="isApiUser" label="Is API user" onChange={(isApiUser) => onIsApiUserChange(isApiUser, form)} component={CheckboxField} />
                                                <Field name="isSuspended" label="Is suspended" component={CheckboxField} />
                                                <Field name="canPublishToMPIWholeFarmDataProject" label="Can publish to MPI Whole Farm Data Project" component={CheckboxField} />
                                                <Field name="canCreateFertiliserRecommendations" label="Can create fertiliser recommendations" component={CheckboxField} />
                                            </>
                                        )}
                                        {isOverseerEd && <Field name="isStudent" label="Is student" component={CheckboxField} />}
                                        {userRequiresActivation && <Field name="isActivated" label="Is activated" component={CheckboxField} />}
                                        {isEditingExistingUser && (
                                            <>
                                                <Field name="loginAttempts" label="Login attempts" info="If this number is >= 5 then the user will be locked out. Set it back to 0 to get them going again" component={TextField} />
                                                <Field name="lastLoginReadOnly" label="Last login" readOnly component={TextField} />
                                                <Field name="createdOnReadOnly" label="Created on" readOnly component={TextField} />
                                            </>
                                        )}
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    )
}

const useSaveUserAsync = () => {
    const queryClient = useQueryClient();
    const refreshOrgSearchResultsIfAny = useRefreshOrgSearchResultsIfAny();

    const mutation = useMutation({
        mutationFn: async (user) => {
            const isNew = !user.id;

            try {
                if (isNew) {
                    await httpClient.post(`admin/accounts/${user.accountId}/users`, user);
                } else {
                    await httpClient.put(`admin/accounts/${user.accountId}/users/${user.id}`, user);
                }
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 409) {
                    throw new Error("The user already exists.");
                } else if (error.status === 400) {
                    throw new Error("Error sending invite. Please ensure the email address is valid.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        // These callbacks ares called BEFORE their mutateAsync versions, even if the component is unmounted
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["orgs"] }),
        onSettled: () => refreshOrgSearchResultsIfAny(),
    });

    return (user) => mutation.mutateAsync(user);
}
