import { Link } from "react-router-dom";
import { GHG_SECTION_NAMES, GHG_SECTIONS } from "./_constants";
import { ZendeskLink } from "components/Help";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";

export default function GHGActionsBar({ farm, analysis }) {
    const ghgSections = [];
    if (!analysis.ghg || !analysis.ghg.fuel) ghgSections.push(GHG_SECTIONS.find((s) => s.name === GHG_SECTION_NAMES.FUEL));
    if (!analysis.ghg || !analysis.ghg.fertiliser) ghgSections.push(GHG_SECTIONS.find((s) => s.name === GHG_SECTION_NAMES.FERTILISER));
    if (!analysis.ghg || !analysis.ghg.transport) ghgSections.push(GHG_SECTIONS.find((s) => s.name === GHG_SECTION_NAMES.TRANSPORT));
    if (!analysis.ghg || !analysis.ghg.electricity) ghgSections.push(GHG_SECTIONS.find((s) => s.name === GHG_SECTION_NAMES.ELECTRICITY));

    return (
        <div className="ActionsBar ActionsBar--super u-print-none">
            <div className="ActionsBar-left">
                <ul className="ActionsBar-links">
                    {ghgSections.map((section) => {
                        return (
                            <li key={section.name}>
                                <Link id={`ghg-${section.name}-action`} to={`/app/farm/${farm.id}/analysis/${analysis.id}/ghg/${section.name}`} className="IconLink--arrow-plus">
                                    {section.actionText}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="ActionsBar-right">
                <ul className="ActionsBar-links">
                    {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                        <li>
                            <ScenarioToolLink farm={farm} analysis={analysis} />
                        </li>
                    )}
                    <li>
                        <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000978543" className="IconLink--new-tab u-font-green" />
                    </li>
                </ul>
            </div>
        </div>
    )
}
