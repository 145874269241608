import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import SnowfallDetails from "./SnowfallDetails";

export default function SnowfallCreate({ farm, analysis }) {
    const snowfall = {
        blockIds: [],
        snowfallMonths: [{ month: "January", amount: null, coverage: null }],
    };

    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.CLIMATE} />
            <TileBody>
                <SnowfallDetails farm={farm} analysis={analysis} snowfall={snowfall} />
            </TileBody>
        </Tile>
    )
}
