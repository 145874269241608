import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { Panel, PanelBody } from "components/Panel";
import * as utils from "common/utils";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import ActionLink from "components/ActionLink";
import { useManageNotificationModal } from "./ManageNotificationModal";
import { getAllNotifications, deleteNotification } from "./_actions";
import { useConfirm } from "common/hooks";

export default function Notifications() {
    const confirm = useConfirm();
    const dispatch = useDispatch();
    const [notifications, refreshNotifications] = useNotifications();
    const [manageNotificationModal, openManageNotificationModal] = useManageNotificationModal();

    const _addNotification = () => {
        const newNotification = {
            id: uuidv4(),
            isNew: true,
            type: "info",
            startsAt: moment.utc().format("YYYY MM DD HH:mm"),
            expiresAt: moment().add(5, "days").utc().format("YYYY MM DD HH:mm"),
            content: '<b>OverseerFM notification - Month YYYY</b>\n<p></p>\n<ul class=\'disc\'>\n<li>A list item without a bold heading</li>\n<li><b>A sub heading</b> - A list item with a bold heading</li>\n</ul>\n<p>A sentence with a link to click <a href="https://support.overseer.org.nz/hc/en-us/articles/4412310769561" target="_blank">here</a>.</p>',
        };
        openManageNotificationModal(newNotification, refreshNotifications);
    };

    const _editNotification = (notification) => {
        const existingNotification = { ...notification, type: notification.type || "info" };
        openManageNotificationModal(existingNotification, refreshNotifications);
    };

    const _deleteNotification = (id) => {
        confirm("Are you sure you want to delete this notification?", async () => {
            await dispatch(deleteNotification(id))
                .then(refreshNotifications)
                .catch((ex) => console.log(ex));
        });
    };

    return (
        <>
            <Panel
                title="Dashboard notifications"
                green
                actions={
                    <ActionLink id="create-notification" className="IconLink--arrow-plus u-textWhite" onClick={_addNotification}>
                        Create new notification
                    </ActionLink>
                }
            >
                <PanelBody>
                    {notifications && notifications.length === 0 && (
                        <div className="Tile-body-message">
                            <p className="lead">No data</p>
                        </div>
                    )}
                    {notifications && notifications.length > 0 && (
                        <SortableTable data={notifications} defaultSortCriteria={{ fieldName: "createdAt", fieldType: "date", desc: true }} pageSize={10}>
                            {({ data, sortCriteria, handleSort }) => {
                                return (
                                    <table>
                                        <thead>
                                            <tr>
                                                <SortableTableHeader dataWidth="10" label="Date" fieldName="createdAt" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Type" fieldName="type" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Start" fieldName="startsAt" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                <SortableTableHeader label="Expires" fieldName="expiresAt" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Content" fieldName="content" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <th>Active</th>
                                                <th className="th--shrink">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((notification) => {
                                                const now = moment();
                                                const isActive = now.isBefore(notification.expiresAt) ? "YES" : "NO";
                                                return (
                                                    <tr key={notification.id}>
                                                        <td valign="top"> {utils.dateLong(notification.createdAt)}</td>
                                                        <td valign="top"> {notification.type ? utils.enumToSentence(notification.type) : "Information"}</td>
                                                        <td valign="top"> {utils.dateLong(notification.startsAt)}</td>
                                                        <td valign="top"> {utils.dateLong(notification.expiresAt)}</td>
                                                        <td valign="top">
                                                            {" "}
                                                            <div dangerouslySetInnerHTML={{ __html: notification.content }} />{" "}
                                                        </td>
                                                        <td valign="top">{isActive}</td>
                                                        <td valign="top">
                                                            <div className="u-flexSplit">
                                                                <ActionLink id={`edit-notification-${notification.id}`} onClick={() => _editNotification(notification)} className="icon icon-edit" title="Edit notification" />
                                                                <ActionLink id={`delete-notification-${notification.id}`} onClick={() => _deleteNotification(notification.id)} className="icon icon-trash" title="Delete notification" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                );
                            }}
                        </SortableTable>
                    )}
                </PanelBody>
            </Panel>
            {manageNotificationModal}
        </>
    );
}

function useNotifications() {
    const dispatch = useDispatch();
    const [notifications, setNotifications] = useState();
    const [forceRefresh, setForceRefresh] = useState(false);

    const refreshNotifications = useCallback(() => setForceRefresh((prevState) => !prevState), []);

    useEffect(() => {
        dispatch(getAllNotifications())
            .then((response) => setNotifications(response))
            .catch((ex) => console.log(ex));
    }, [forceRefresh, dispatch]);

    return [notifications, refreshNotifications];
}
