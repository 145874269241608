import { Link } from "react-router-dom";
import * as domain from "common/domain";
import { useConfirm } from "common/hooks";
import ActionLink from "components/ActionLink";
import { ZendeskLink } from "components/Help";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function SoilTestsActionsBar({ farm, analysis }) {
    const blocks = analysis.blocks.filter((b) => b.type !== domain.BlockType.FodderCrop);
    const _availableBlocks = blocks.filter((block) => !block.soilTestId || !analysis.soilTests.some((t) => !t.defaultTest && t.id === block.soilTestId));
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync();

    const resetSoils = () => {
        confirm('Are you sure you want to reset all soils to the default values?', async () => {
            const updatedAnalysis = {
                ...analysis,
                blocks: analysis.blocks.map((block) => {
                    if (block.isDrawn && block.requiresSoil) {
                        return {
                            ...block,
                            autoGeneratedSoils: true,
                            regenerateSoils: true,
                        };
                    }
                    return block;
                }),
            };
            await updateAnalysisAsync(updatedAnalysis);
        });
    }

    return (
        <div className="ActionsBar ActionsBar--super u-print-none">
            <div className="ActionsBar-left">
                <ul className="ActionsBar-links">
                    <li>
                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/soil/soilTests/new`} className={"IconLink--arrow-plus" + (_availableBlocks.length === 0 ? " is-disabled" : "")} id="add-soil-test-link">
                            Add soil tests
                        </Link>
                    </li>
                    <li>
                        <ActionLink className="IconLink--refresh" onClick={() => resetSoils()}>Reset soils to S-Map defaults</ActionLink>
                    </li>
                    {false && !analysis.useFarmSoils && (
                        <li>
                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/soil/blocks`} className="IconLink--settings" id="add-soil-test-link">
                                Manage analysis soils
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
            <div className="ActionsBar-right">
                <ul className="ActionsBar-links">
                    {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                        <li>
                            <ScenarioToolLink farm={farm} analysis={analysis} />
                        </li>
                    )}
                    <li>
                        <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000960826" className="IconLink--new-tab u-font-green" />
                    </li>
                </ul>
            </div>
        </div>
    );
}
