import * as utils from "common/utils";
import * as api from "api";

const actionTypes = {
    BLOCK_GROWTH_CURVE_FETCH: "BLOCK_GROWTH_CURVE_FETCH",
    BLOCK_GROWTH_CURVE_FETCH_CANCEL: "BLOCK_GROWTH_CURVE_FETCH_CANCEL",
    BLOCK_GROWTH_CURVE_FETCH_SUCCESS: "BLOCK_GROWTH_CURVE_FETCH_SUCCESS",
    BLOCK_GROWTH_CURVE_FETCH_FAILURE: "BLOCK_GROWTH_CURVE_FETCH_FAILURE",
    BLOCK_GROWTH_CURVE_CLEAR: "BLOCK_GROWTH_CURVE_CLEAR",

    BUDGET_GROWTH_CURVE_FETCH: "BUDGET_GROWTH_CURVE_FETCH",
    BUDGET_GROWTH_CURVE_FETCH_CANCEL: "BUDGET_GROWTH_CURVE_FETCH_CANCEL",
    BUDGET_GROWTH_CURVE_FETCH_SUCCESS: "BUDGET_GROWTH_CURVE_FETCH_SUCCESS",
    BUDGET_GROWTH_CURVE_FETCH_FAILURE: "BUDGET_GROWTH_CURVE_FETCH_FAILURE",
    BUDGET_GROWTH_CURVE_CLEAR: "BUDGET_GROWTH_CURVE_CLEAR",
};

export default actionTypes;

export function getBlockGrowthCurve(farmId, budgetId, block) {
    var _block = utils.clone(block);
    _block.currentResults = undefined;

    return api.post({
        path: `farms/${farmId}/budgets/${budgetId}/blocks/${_block.id}/growthcurve`,
        content: _block,
        onBeforeSend: () => {
            return { type: actionTypes.BLOCK_GROWTH_CURVE_FETCH };
        },
        onSuccess: (response) => {
            return { type: actionTypes.BLOCK_GROWTH_CURVE_FETCH_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            error.handled = true;
            return { type: actionTypes.BLOCK_GROWTH_CURVE_FETCH_FAILURE };
        },
    });
}

export function clearBlockGrowthCurves() {
    return (dispatch) => {
        dispatch({ type: actionTypes.BLOCK_GROWTH_CURVE_CLEAR });
    };
}

export function getBudgetGrowthCurve(farmId, budgetId, blocks) {
    var _blocks = utils.clone(blocks);
    /* eslint-disable no-unused-vars */
    for (const block of _blocks) {
        block.points = undefined;
        block.currentResults = undefined;
    }

    return api.post({
        path: `farms/${farmId}/budgets/${budgetId}/growthcurves`,
        content: _blocks,
        onBeforeSend: () => {
            return { type: actionTypes.BUDGET_GROWTH_CURVE_FETCH };
        },
        onSuccess: (response) => {
            return { type: actionTypes.BUDGET_GROWTH_CURVE_FETCH_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            error.handled = true;
            return { type: actionTypes.BUDGET_GROWTH_CURVE_FETCH_FAILURE };
        },
    });
}
