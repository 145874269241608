import ActionLink from "components/ActionLink";
import { usePublicationDetailsModal } from "./PublicationDetailsModal";

export default function PublishAnalysisActionLink({ farmId, analysisId }) {
    const [publicationDetailsModal, openPublicationDetailsModal] = usePublicationDetailsModal({ farmId, budgetId: analysisId });
    return (
        <>
            <ActionLink id={`publish-analysis-${analysisId}`} className="IconLink--bookmarks" onClick={openPublicationDetailsModal}>
                Publish
            </ActionLink>
            {publicationDetailsModal}
        </>
    )
}
