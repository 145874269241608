import { useModal } from "common/hooks";
import ActionLink from "components/ActionLink";
import { MoveUserModal } from "containers/Admin/Users";

export default function MoveUserActionLink({ user, children = null, className = "IconLink--users" }) {
    const [moveUserModal, openMoveUserModal] = useMoveUserModal(user);
    return (<>
        <ActionLink id={`move-user-${user.id}`} title="Move user" className={className} onClick={openMoveUserModal}>
            {children}
        </ActionLink>
        {moveUserModal}
    </>);
}

const useMoveUserModal = (user) => {
    const [modal, openModal] = useModal(MoveUserModal);

    const openMoveUserModal = () => {
        const modalProps = {
            user
        };
        openModal(modalProps);
    };

    return [modal, openMoveUserModal];
}
