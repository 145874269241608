import { Doughnut } from "react-chartjs-2";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import { irrigatorViewModel } from "common/viewModels";
import Alert from "components/Alert";
import { Grid, GridCell } from "components/Grid";
import Watermark from "components/Watermark";
import { SummaryRow } from "components/PlanningReport";
import { getGroupLabel } from "./_utils";

export default function IrrigationSummary({ after, before, vm, baseVm }) {
    const refData = useRefData();
    vm = vm || irrigatorViewModel(after.details, refData);
    baseVm = baseVm || (before && irrigatorViewModel(before.details, refData));

    if (vm.length === 0 && (!baseVm || baseVm.length === 0)) return null;

    const CalcValues = ({ srcKey, render, group }) => {
        switch (srcKey) {
            case "group": {
                const actualApplications = vm.reduce((t, i) => (t += i.applications.reduce((mt, m) => (mt += m.months.filter((m) => m.reportingYear).length), 0)), 0);
                const baseApplications = baseVm && baseVm.reduce((t, i) => (t += i.applications.reduce((mt, m) => (mt += m.months.filter((m) => m.reportingYear).length), 0)), 0);
                const actualGroupApplications = vm.reduce((t, i) => (t += i.applications.filter((a) => a.group === group).reduce((mt, m) => (mt += m.months.filter((m) => m.reportingYear).length), 0)), 0);
                const baseGroupApplications = baseVm && baseVm.reduce((t, i) => (t += i.applications.filter((a) => a.group === group).reduce((mt, m) => (mt += m.months.filter((m) => m.reportingYear).length), 0)), 0);
                const actualGroupValue = (actualGroupApplications / (actualApplications > 0 ? actualApplications : 1)) * 100;
                const baseGroupValue = (baseGroupApplications / (baseApplications > 0 ? baseApplications : 1)) * 100;

                if (baseGroupValue === 0 && actualGroupValue === 0) return null;

                return render(baseGroupValue === 0 ? undefined : baseGroupValue, actualGroupValue === 0 ? undefined : actualGroupValue);
            }
            default: {
                const actualValue = vm.filter((s) => s.irrigatorType === srcKey || srcKey === "area").reduce((t, i) => (t += i.area), 0);
                const baseValue = baseVm && baseVm.filter((s) => s.irrigatorType === srcKey || srcKey === "area").reduce((t, i) => (t += i.area), 0);

                if (baseValue === 0 && actualValue === 0) return null;

                return render(baseValue === 0 ? undefined : baseValue, actualValue === 0 ? undefined : actualValue);
            }
        }
    };

    const irrigatorTypes = [];

    vm.forEach((i) => {
        if (!irrigatorTypes.includes(i.irrigatorType)) {
            irrigatorTypes.push(i.irrigatorType);
        }
    });

    baseVm &&
        baseVm.forEach((i) => {
            if (!irrigatorTypes.includes(i.irrigatorType)) {
                irrigatorTypes.push(i.irrigatorType);
            }
        });

    const groups = [1, 2, 3, 4, 5];

    const assessmentData = (group, dataset) => {
        const irrVm = irrigatorViewModel(dataset.details, refData);
        //const total = irrVm.reduce((t,i)=> t+= i.area,0)
        const data = getTotalData(irrVm, group);
        //return total > 0 ? utils.round(data / total * 100,2) : null
        return utils.round(data, 2);
    };

    const afterDs = groups.map((n) => assessmentData(n, after));
    const afterLabels = groups.map((n) => getGroupLabel(n)).filter((l, i) => afterDs[i] > 0);
    const beforeDs = before && groups.map((n) => assessmentData(n, before));
    const beforeLabels = before && groups.map((n) => getGroupLabel(n)).filter((l, i) => beforeDs[i] > 0);

    const beforeData = before && {
        labels: beforeLabels,
        datasets: [
            {
                data: beforeDs.filter((n) => n > 0),
                backgroundColor: groups.map((g) => utils.graphColours[g]),
                hoverBackgroundColor: groups.map((g) => utils.graphColours[g]),
            },
        ],
    };

    const afterData = {
        labels: afterLabels,
        datasets: [
            {
                data: afterDs.filter((n) => n > 0),
                backgroundColor: groups.map((g) => utils.graphColours[g]),
                hoverBackgroundColor: groups.map((g) => utils.graphColours[g]),
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
            },
        },
        responsive: false,
        maintainAspectRatio: false,
    };

    const isComparing = !!before && !!after;

    return (
        <>
            <div className="ReportSection">
                <h1>Irrigation</h1>
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                {isComparing && (
                                    <th className="numeric u-before" data-width="12.5">
                                        {utils.truncateText(before.displayName, 35)}
                                    </th>
                                )}
                                <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                    {utils.truncateText(after.displayName, 35)}
                                </th>
                                {isComparing && (
                                    <th className="numeric" data-width="16.7">
                                        Change
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <CalcValues srcKey="area" render={(baseValue, actualValue) => <SummaryRow beforeValue={baseValue} afterValue={actualValue} isComparing={isComparing} label="Total irrigated area" unit="ha" dp={1} />} />
                            {irrigatorTypes.map((t, i) => (
                                <CalcValues srcKey={t} key={i} render={(baseValue, actualValue) => <SummaryRow beforeValue={baseValue} afterValue={actualValue} isComparing={isComparing} label={t} unit="ha" dp={1} />} />
                            ))}
                        </tbody>
                    </table>
                </div>
                {false && <Alert type="info" text={<div>The following table groups the application area by common soil moisture assessment types.</div>} />}
                {false && (
                    <Grid>
                        {before && (
                            <GridCell className="u-lg-width1of2 u-md-width1of1">
                                <div className="h4 u-textCenter">{before.displayName}</div>
                                <div className="u-flex u-flexJustifyCenter">
                                    <Doughnut data={beforeData} options={options} height={400} width={400} />
                                </div>
                            </GridCell>
                        )}
                        <GridCell className="u-lg-width1of2 u-md-width1of1">
                            <div className="h4 u-textCenter">{after.displayName}</div>
                            <div className="u-flex u-flexJustifyCenter">
                                <Doughnut data={afterData} options={options} height={400} width={400} />
                            </div>
                        </GridCell>
                    </Grid>
                )}
            </div>
            <div className="ReportSection">
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th>Soil moisture assessment type usage</th>

                                {isComparing && (
                                    <th className="u-before numeric" data-width="12.5">
                                        {utils.truncateText(before.displayName, 35)}
                                    </th>
                                )}
                                <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                    {utils.truncateText(after.displayName, 35)}
                                </th>
                                {isComparing && (
                                    <th className="numeric" data-width="16.7">
                                        Change
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <CalcValues srcKey="group" group={1} render={(baseValue, actualValue) => <SummaryRow beforeValue={baseValue} afterValue={actualValue} isComparing={isComparing} label={getGroupLabel(1)} unit="%" />} />
                            <CalcValues srcKey="group" group={2} render={(baseValue, actualValue) => <SummaryRow beforeValue={baseValue} afterValue={actualValue} isComparing={isComparing} label={getGroupLabel(2)} unit="%" />} />
                            <CalcValues srcKey="group" group={3} render={(baseValue, actualValue) => <SummaryRow beforeValue={baseValue} afterValue={actualValue} isComparing={isComparing} label={getGroupLabel(3)} unit="%" />} />
                            <CalcValues srcKey="group" group={4} render={(baseValue, actualValue) => <SummaryRow beforeValue={baseValue} afterValue={actualValue} isComparing={isComparing} label={getGroupLabel(4)} unit="%" />} />
                            <CalcValues srcKey="group" group={5} render={(baseValue, actualValue) => <SummaryRow beforeValue={baseValue} afterValue={actualValue} isComparing={isComparing} label={getGroupLabel(5)} unit="%" />} />
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

const getTotalData = (viewModel, group) => {
    let monthTotal = 0;
    for (const irrSys of viewModel) {
        monthTotal += irrSys.applications.filter((a) => a.group === group && a.months.find((m) => m.reportingYear)).length;
    }
    return monthTotal;
}
