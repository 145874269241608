import SelectPack from "components/SelectPack2";
import RadioGroupPack from "components/RadioGroupPack";
import { Field } from "react-final-form";
import InputPack from "components/InputPack";
import { ExistingWarning, GlobalCopy } from "./Dialogs";
import { useRefData } from "common/hooks";

export default function Lime({ viewModel, customFertiliser = [], customCompositions = [] }) {
    const options = [
        { value: "MANUFACTURER", text: "Select a product" },
        { value: "CUSTOM", text: "Custom fertiliser" },
    ];
    return (
        <>
            {!viewModel.preventCustomSwitch && (
                <div className="Grid-cell">
                    <Field name="productSource" label="Select the source of your lime" options={options} inline={true} component={RadioGroupPack} />
                </div>
            )}
            {viewModel.productSource === "MANUFACTURER" && <ManufacturedLime viewModel={viewModel} />}
            {viewModel.productSource === "CUSTOM" && <CustomLime customFertiliser={customFertiliser} viewModel={viewModel} customCompositions={customCompositions} />}
            <ExistingWarning viewModel={viewModel} />
        </>
    )
}

const ManufacturedLime = ({ viewModel }) => {
    const refData = useRefData();

    const { analysis } = viewModel;
    const usedProductIds = viewModel.isNew ? analysis.fertiliser.filter((f) => f.manufacturerId === 0).map((f) => f.productId) : [];
    const { lime = [] } = refData;
    const prodOptions = lime.filter((p) => !usedProductIds.includes(p.id)).map((f) => ({ value: f.id, text: f.name }));
    prodOptions.sort((a, b) => (b.text < a.text ? 1 : -1));

    return (
        <div className="Grid-cell">
            <Field name="fertiliser.productId" label="Product" component={SelectPack} required={true} options={prodOptions} placeholder="Select a product" />
        </div>
    )
}

const CustomLime = ({ customFertiliser, customCompositions, viewModel }) => {
    const localProducts = viewModel.isNew ? [] : customFertiliser.map((c) => ({ value: c.id, text: c.productName, groupIndex: 1, groupLabel: "Use existing custom lime" }));
    localProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    const myProducts = customCompositions.filter((c) => c.userId).map((c) => ({ value: c.id, text: c.name, groupIndex: 2, groupLabel: "Copy my lime product" }));
    const orgProducts = customCompositions.filter((c) => !c.userId).map((c) => ({ value: c.id, text: c.name, groupIndex: 3, groupLabel: "Copy organisation lime product" }));
    myProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    orgProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    const products = [...[{ value: "NEW", text: "New custom lime", groupLabel: "Add new custom lime", groupIndex: 0 }], ...localProducts, ...myProducts, ...orgProducts];

    return (
        <div className="Grid-cell">
            {(localProducts.length > 0 || myProducts.length > 0 || orgProducts.length > 0) && <Field name="fertiliser.id" label="Custom products" component={SelectPack} required={true} options={products} />}
            <GlobalCopy viewModel={viewModel} />
            <Field name={`fertiliser.productName`} label="Name" component={InputPack} requiredLabel={true} disabled={viewModel.existing} />
        </div>
    )
}