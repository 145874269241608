import { Component } from "react";
import { useLocation } from "react-router-dom";
import SavePrompt from "components/SavePrompt";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Link } from "react-router-dom";
import SelectPack from "components/SelectPack";
import * as utils from "common/utils";
import * as validations from "common/validations";
import Bunker, { validateBunker } from "./Bunker";
import FeedingApron, { validateFeedingApron } from "./FeedingApron";
import BeddingPad, { validateBeddingPad } from "./BeddingPad";
import { v4 as uuidv4 } from "uuid";
import AnimalsOnStructure, { validateAnimals } from "./AnimalsOnStructure";
import { Button } from "components/Button";
import { deselectMonths, structureEffluent, hideAnimalsTimeOnPad } from "./_utils";
import "url-search-params-polyfill";
import Alert from "components/Alert";
import * as domain from "common/domain";
import * as structureUtils from "./_utils";
import { useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function StructureDetails({ farm, analysis }) {
    const refData = useRefData();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const location = useLocation();

    return <StructureDetailsClassComponent farm={farm} analysis={analysis} updateAnalysisAsync={updateAnalysisAsync} location={location} refData={refData} />
}

class StructureDetailsClassComponent extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        let type = params.get("type");
        const id = params.get("id");
        const structures = props.analysis.structures || [];
        let structure = structures.find((s) => s.type === type && s.id === id);
        let isNew = false;
        let enterpriseDisabled = false;
        const { enterprises = [] } = props.analysis;
        if (!structure) {
            isNew = true;
            structure = { id: uuidv4(), type: type, units: "HoursPerDay" };
            if (["StandoffPad", "FeedingPad"].includes(type)) {
                const enterprise = enterprises.find((e) => e.type === "Dairy");
                if (enterprise) {
                    structure.enterpriseId = enterprise.id;
                    enterpriseDisabled = true;
                }
            }
            if (structure.type === "MilkingShed") {
                const { enterprises = [] } = props.analysis;
                const dairyEnterprises = enterprises.filter((e) => ["Dairy", "DairyGoat"].includes(e.type));
                if (dairyEnterprises.length === 1) {
                    structure.enterpriseId = dairyEnterprises[0].id;
                    enterpriseDisabled = true;
                }
            }
        }
        structure = utils.clone(structure);
        if (!structure.animals) structure.animals = [];
        if (structure.type !== "MilkingShed" && structure.animals.length <= 0) {
            structure.animals.push({ id: uuidv4() });
        }
        if (structure.enterpriseId) {
            const enterprise = props.analysis.enterprises.find((e) => e.id === structure.enterpriseId);
            if (!enterprise) {
                structure.enterpriseId = undefined;
            }
        }

        this.state = {
            referrer: `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/structures`,
            structure: structure,
            validation: {},
            isNew: isNew,
            enterpriseDisabled: enterpriseDisabled,
            dirty: false,
            submitting: false,
            submitSucceeded: false,
        };
    }

    getActiveControls(structure) {
        const activeControls = {};
        this.populateStructure(structure);
        const { bunker } = structure;

        if (structure.type === "FeedingPad") {
            activeControls.cleaningMethod = true;
        } else if (structure.type === "StandoffPad") {
            activeControls.pad_header = "Standoff pad construction";
            activeControls.pad_padSurface = true;
            activeControls.pad_lined = true;
            activeControls.pad_scraped = true;
        } else if (structure.type === "WinteringPad") {
            activeControls.covered_uncovered = true;
        } else if (structure.type === "UncoveredWinteringPad") {
            activeControls.covered_uncovered = true;

            //general
            activeControls.isFarmGrazedOutPrior = true;
            activeControls.grazingHours = true;

            //Bedding pad
            activeControls.pad_header = "Bedding pad";
            activeControls.pad_padSurface = true;
            activeControls.pad_lined = true;
            activeControls.pad_scraped = true;

            //Concrete apron
            activeControls.feedingApron_apronHeading = true;
            activeControls.feedingApron_cleaningMethod = true;
            activeControls.feedingApron_timeApron = true;
        } else if (structure.type === "CoveredWinteringPad") {
            activeControls.covered_uncovered = true;

            //general
            activeControls.isFarmGrazedOutPrior = true;
            activeControls.grazingHours = true;

            //Bunker management
            const isUnlined = bunker.bunkerLining === "Noliningmaterial";
            activeControls.bunker_bunkerHeading = true;
            activeControls.bunker_cleaningMethod = isUnlined;
            activeControls.bunker_bunkerLining = true;
            activeControls.bunker_timeBunker = ["Carbonrichsawdustbarkwoodchips", "Soil"].includes(bunker.bunkerLining);
            activeControls.bunker_liquidDrained = ["Carbonrichsawdustbarkwoodchips", "Soil"].includes(bunker.bunkerLining);

            //Concrete apron
            activeControls.feedingApron_apronHeading = true;
            activeControls.feedingApron_cleaningMethod = true;
            activeControls.feedingApron_timeApron = true;
        }

        return activeControls;
    }

    setDefaults(structure, activeControls) {
        this.populateStructure(structure);
        const { bunker, feedingApron, pad } = structure;
        const clearValues = (obj, keys) => {
            /*eslint-disable no-unused-vars*/
            for (const key of keys.filter((k) => !activeControls[k])) {
                const objKey = key.split("_").pop();
                obj[objKey] = undefined;
            }
        };

        clearValues(bunker, ["bunker_bunkerLining", "bunker_cleaningMethod", "bunker_liquidDrained", "bunker_timeBunker"]);

        if (feedingApron) {
            clearValues(feedingApron, ["feedingApron_timeApron", "feedingApron_cleaningMethod"]);
        }

        clearValues(structure, ["surfaceSpecified", "cleaningMethod"]);

        clearValues(pad, ["pad_header", "pad_lined", "pad_scraped", "pad_padSurface"]);
    }

    onChange(e, source) {
        let structure = this.state.structure;
        this.populateStructure(structure);
        switch (source.type) {
            case "winteringPadType": {
                const structureType = utils.ctrlVal(e);
                structure = {
                    ...this.state.structure,
                    type: structureType,
                    pad: undefined,
                    bunker: undefined,
                    effluentSystem: undefined,
                };
                break;
            }
            case "pad":
                structure.pad[source.key] = utils.ctrlVal(e);
                break;
            case "bunker":
                structure.bunker[source.key] = utils.ctrlVal(e);
                break;
            case "feedingApron":
                structure.feedingApron[source.key] = utils.ctrlVal(e);
                break;
            case "units":
                structure.units = utils.ctrlVal(e) ? "DaysPerMonth" : "HoursPerDay";
                break;
            case "animals": {
                const application = structure.animals.find((a) => a.id === source.applicationId);
                switch (source.key) {
                    case "month": {
                        const { months = [] } = application;
                        application.months = e.target.checked ? [...[source.month], ...months] : months.filter((m) => m !== source.month);
                        deselectMonths(application, structure.animals);
                        break;
                    }
                    default:
                        application[source.key] = utils.ctrlVal(e);
                        break;
                }
                break;
            }
            default:
                structure[source.key] = utils.ctrlVal(e);
                break;
        }
        const activeControls = this.getActiveControls(structure);
        this.setDefaults(structure, activeControls);
        this.isValid(structure, activeControls, source);
        this.setState({ structure, dirty: true });
    }

    isValid(structure, activeControls, source = undefined) {
        const currentValidation = this.state.validation;
        this.populateStructure(structure);
        const { bunker, feedingApron, pad } = structure;
        let validation = {};
        const hideTime = hideAnimalsTimeOnPad(structure);

        const required = (parent, keys) => {
            /*eslint-disable no-unused-vars*/
            for (const key of keys.filter((k) => activeControls[k])) {
                const objKey = key.split("_").pop();
                validation[key] = setVal(key, currentValidation, source, validations.required(parent[objKey]));
            }
        };

        if (activeControls.covered_uncovered) {
            if (!["CoveredWinteringPad", "UncoveredWinteringPad"].includes(structure.type)) {
                validation.type = setVal("type", currentValidation, source, "Required");
            }
        }

        required(structure, ["cleaningMethod"]);
        validateBunker(activeControls, validation, bunker, currentValidation, source, setVal);
        if (feedingApron) validateFeedingApron(activeControls, validation, feedingApron, currentValidation, source, setVal);

        validateBeddingPad(activeControls, validation, pad, currentValidation, source, setVal);
        validateAnimals(validation, structure, currentValidation, source, setVal, hideTime);

        this.setState({ validation: validation });
        /*eslint-disable no-unused-vars*/
        for (let key of Object.keys(validation)) {
            if (validation[key].error) {
                return false;
            }
        }
        return true;
    }

    async save(navigateToEffluent = false) {
        const structure = this.state.structure;
        const activeControls = this.getActiveControls(structure);
        if (this.isValid(structure, activeControls)) {
            this.setState({ submitting: true, submitSucceeded: false });

            if (!structure.effluentSystem) structure.effluentSystem = { effluentDisposal: "UseDairySystem" };
            if (!structure.grazingHours && structure.animals && ["CoveredWinteringPad", "UncoveredWinteringPad"].includes(structure.type)) {
                for (const application of structure.animals) {
                    application.time = undefined;
                }
            }

            const [structureSolids, structureLiquids] = structureEffluent(structure);
            const { effluentSystem = {} } = structure;
            const showPond = ["PondDischarge", "HoldingPond", "HoldingPondSeparated"].includes(effluentSystem.effluentDisposal);
            if (!structureSolids && !showPond) {
                structure.effluentSystem.solidManagement = undefined;
            }
            if (!structureLiquids) {
                structure.effluentSystem.liquidManagement = undefined;
                delete structure.effluentSystem.effluentDisposal;
            }
            if (hideAnimalsTimeOnPad(structure)) {
                structure.units = undefined;
            }

            const effluentUrl = `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/structures/effluent` + (structure.type === "MilkingShed" ? `` : `?id=${structure.id}`);

            const updatedAnalysis = structureUtils.getUpdatedAnalysisFromSavingStructure(this.props.analysis, structure);
            await this.props.updateAnalysisAsync(updatedAnalysis);

            const referrer = navigateToEffluent ? effluentUrl : `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/structures`;
            this.setState({ submitting: false, submitSucceeded: true, referrer });
            return true;
        }
        return false;
    }

    populateStructure(structure) {
        const setDefault = (obj, key, defaultValue = {}) => {
            if (!obj[key] || obj[key] === null) obj[key] = defaultValue;
        };
        setDefault(structure, "animals", []);
        setDefault(structure, "bunker");
        setDefault(structure, "pad");
        setDefault(structure, "effluentSystem");
    }

    switchApron() {
        const { structure } = this.state;
        const activeControls = this.getActiveControls(structure);

        this.populateStructure(structure);
        if (structure.feedingApron) {
            structure.feedingApron = undefined;
        } else {
            structure.feedingApron = {};
        }

        this.isValid(structure, activeControls);
        this.setState({ structure: structure });
    }

    animalsAddApplication() {
        const { structure } = this.state;
        this.populateStructure(structure);
        structure.animals.push({ id: uuidv4() });
        this.setState({ structure: structure });
    }

    deleteAnimalsApp(id) {
        const { structure } = this.state;
        this.populateStructure(structure);
        structure.animals = structure.animals.filter((a) => a.id !== id);
        this.setState({ structure: structure });
    }

    render() {
        const animalsTab = `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/animals`;
        const { structure, validation = {} } = this.state;
        const activeControls = this.getActiveControls(structure);
        const { cleaningMethod = [], structureType = [] } = this.props.refData;
        this.populateStructure(structure);
        const { animals, bunker, feedingApron, pad } = structure;
        const structureName = utils.valueToText(structureType, structure.type) || "Winter pad";
        const [structureSolids, structureLiquids] = structureEffluent(structure);
        const hasEffluentSystem = structure.type === "MilkingShed" || structureLiquids || structureSolids;

        const structureTip = domain.StructureTypeTips[structure.type];
        const effluentManagementTip = domain.FeedpadCleaningTips[structure.cleaningMethod];

        return (
            <>
                <input type="hidden" value={structure.id} id="structureid" />
                <SavePrompt blockIf={this.state.dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={this.state.referrer} />
                <Tile title={structure.type === "MilkingShed" ? "Milking shed feeding" : structureName} waiting={this.state.submitting} referrer={this.state.referrer}>
                    {structureTip && <Alert className="u-mb-0" type="info" html={structureTip.text} />}
                    <TileBody>
                        {/* Wintering pad */}
                        {activeControls.covered_uncovered && (
                            <div className="Grid Grid--withGutter u-mb-lg">
                                <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                    <h3>Wintering pad</h3>
                                    <SelectPack meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "winteringPadType" })} value={structure.type} val={validation.type} id="winteringPadType" label="Pad type" requiredLabel={true}>
                                        <option value="WinteringPad" disabled={true}>
                                            Select a wintering pad type
                                        </option>
                                        <option value="CoveredWinteringPad">Covered</option>
                                        <option value="UncoveredWinteringPad">Uncovered</option>
                                    </SelectPack>
                                    {false && <Alert className="u-mb-0" type="info" text="Information on the type of wintering pad" />}
                                </div>
                            </div>
                        )}
                        {/* Feed Pad */}
                        {activeControls.cleaningMethod && (
                            <div className="Grid Grid--withGutter">
                                <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                    <h3>Effluent management</h3>
                                    <SelectPack meta={{ nrf: true }} isHidden={!activeControls.cleaningMethod} onChange={(e) => this.onChange(e, { type: "effluentSystem", key: "cleaningMethod" })} value={structure.cleaningMethod} val={validation.cleaningMethod} id="cleaningMethod" label="Manure removal method" requiredLabel={true}>
                                        <option value="" disabled={true}>
                                            Select a removal method
                                        </option>
                                        {utils.mapRefDataItems(cleaningMethod.filter((c) => c.value !== "ScrapingStored"))}
                                    </SelectPack>
                                    {effluentManagementTip && <Alert className="u-mb-0" type="info" text={effluentManagementTip.text} />}
                                </div>
                            </div>
                        )}

                        <BeddingPad onChange={(e, evt) => this.onChange(e, evt)} pad={pad} validation={validation} activeControls={activeControls} isFirst={!activeControls.cleaningMethod} type="pad" />

                        <Bunker onChange={(e, evt) => this.onChange(e, evt)} bunker={bunker} validation={validation} activeControls={activeControls} isFirst={!activeControls.cleaningMethod} type="bunker" />

                        <FeedingApron onChange={(e, evt) => this.onChange(e, evt)} feedingApron={feedingApron} switchApron={() => this.switchApron()} validation={validation} activeControls={activeControls} type="feedingApron" />

                        <AnimalsOnStructure applications={animals} onChange={(e, o) => this.onChange(e, o)} addApplication={() => this.animalsAddApplication()} deleteApplication={(id) => this.deleteAnimalsApp(id)} name={structureName} analysis={this.props.analysis} activeControls={activeControls} structure={structure} animalsTab={animalsTab} isFirst={structure.type === "MilkingShed"} enterpriseDisabled={this.state.enterpriseDisabled} validation={validation} />
                    </TileBody>
                    <TileFooter>
                        <div className="ButtonBar ButtonBar--fixed">
                            <div className="ButtonBar-left">
                                <Link to={this.state.referrer} className="Button Button--secondary" id="structure-cancel">
                                    Cancel
                                </Link>
                            </div>
                            <div className="ButtonBar-right">
                                <Button id="structure-submit-effluent" tertiary onClick={() => this.save(true)} disabled={!hasEffluentSystem || this.state.submitting}>
                                    Save and manage effluent
                                </Button>
                                <Button submit id="structure-submit" primary waiting={this.state.submitting} onClick={() => this.save()}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </TileFooter>
                </Tile>
            </>
        )
    }
}

const isTouched = (validation, source, id) => (validation && validation[id] && validation[id].touched) || source === undefined || source.key === id || source.id === id;

const setVal = (ctrlName, currentValidation, source, message) => {
    return { touched: isTouched(currentValidation, source, ctrlName), error: message !== undefined, message: message };
}
