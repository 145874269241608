import { Field } from "react-final-form";
import RadioGroupField from "./RadioGroupField";

export default function YesNoRadioGroupField({ input, ...rest }) {
    return (
        <Field
            {...input}
            {...rest}
            options={[
                { value: "Yes", text: "Yes" },
                { value: "No", text: "No" },
            ]}
            component={RadioGroupField}
        />
    );
}
