import numeral from "numeral";
import { Grid, GridCell } from "components/Grid";

export default function DistributionRemaining({ destination, showAutumnAmount, amounts, uom }) {

    let remaining = numeral(destination.amount || 0)
        .add(amounts.totalRemaining)
        .value();
    if (showAutumnAmount)
        remaining = numeral(destination.amount || 0)
            .add(amounts.springSummerRemaining)
            .value();

    const autumRemaining = numeral(destination.amountAutumn || 0)
        .add(amounts.autumnWinterRemaining)
        .value();

    return (
        <div className="u-flex u-flexJustifyCenter">
            <div className="u-mt-md u-ml-auto" style={_boxCss}>
                <div className="u-textSuccess">
                    <small>
                        <b>Available for distribution</b>
                    </small>
                </div>
                {showAutumnAmount && (
                    <Grid className="u-mt-sm">
                        <GridCell className={`u-md-width1of2 u-textCenter ${remaining === 0 ? "u-textSuccess" : ""}`}>
                            <h1 className="u-mt-0">{remaining}</h1>
                            <p>Spring/Summer {uom}</p>
                        </GridCell>
                        <GridCell className={`u-md-width1of2 u-textCenter ${autumRemaining === 0 ? "u-textSuccess" : ""}`}>
                            <h1 className="u-mt-0">{autumRemaining}</h1>
                            <p>Autumn/Winter {uom}</p>
                        </GridCell>
                    </Grid>
                )}
                {!showAutumnAmount && (
                    <Grid>
                        <GridCell className={`u-textCenter ${remaining === 0 ? "u-textSuccess" : ""}`}>
                            <h1 className="u-mt-md">{remaining}</h1>
                        </GridCell>
                        <GridCell className="u-textCenter">
                            <h2 className="u-mt-0">{uom}</h2>
                        </GridCell>
                    </Grid>
                )}
            </div>
        </div>
    )
}

const _boxCss = { textAlign: "center", padding: "10px", height: "150px", width: "280px", border: "solid 2px #bec8c2", borderRadius: "5px" };
