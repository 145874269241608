import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import Alert from "components/Alert";
import SelectPack from "components/SelectPack2";
import InputPack from "components/InputPack";
import * as _utils from "./_utils";
import * as utils from "common/utils";
import Button from "components/Button/Button";
import { useRefData } from "common/hooks";

export default function FeedSupplementBlocks({ form, values, blocks }) {
    const refData = useRefData();

    const _addSource = () => {
        const newSource = {};

        if (values.amountType === "Cuts") {
            const firstBlock = _getFirstBlock(values, blocks);
            if (firstBlock) {
                newSource.blockId = firstBlock.id;
            }
            newSource.amount = 1;
        }

        form.mutators.push("sources", newSource);
    };

    const _removeSource = (index) => {
        const results = values.sources.reduce((acc, cur, i) => {
            if (i !== index) acc.push(cur);
            return acc;
        }, []);
        form.change("sources", results);
    };

    const _hasBlocks = (values.sources || []).length > 0;

    const _showAmount = values.amountType !== "Cuts";

    const _validate = (fields, supplement) => {
        const errorArray = [];

        const primaryBlockId = fields.length > 0 && fields[0].blockId;
        const primaryPastureType = ((blocks.find((b) => b.id === primaryBlockId) || {}).pasture || {}).pastureCategory;

        fields &&
            fields.forEach((field, index) => {
                const errors = {};

                errors.blockId = FormUtils.validators.required(field.blockId);
                if (index > 0) {
                    const secondaryPastureType = ((blocks.find((b) => b.id === field.blockId) || {}).pasture || {}).pastureCategory;
                    if (secondaryPastureType !== primaryPastureType) errors.blockId = errors.blockId || "Required";
                }

                errors.month = FormUtils.validators.required(field.month);

                if (supplement.amountType !== "Cuts") {
                    errors.amount = FormUtils.validators.required(field.amount);
                    errors.amount = errors.amount || FormUtils.validators.range(values.amountType === "Weight" ? 0.1 : 1, 10000)(field.amount);
                }

                errorArray.push(errors);
            });

        return errorArray;
    };

    const _amountMeta = _utils.getAmountTypeMeta(values.amountType);

    const _addHarvestEventAction = _hasBlocks && values.sources[0].blockId && (_showAmount || values.sources.length < 8) && (
        <span id="add-harvest-event-link" className="IconLink--arrow-plus u-link" onClick={_addSource}>
            Add harvest event
        </span>
    );

    return (
        <Tile title="Harvest events" actions={_addHarvestEventAction} padTop tertiary>
            {_showAmount && <Alert type="info" text="Add all supplements of this type for the year. The month of harvest does not influence the results, so you can total the supplements for each block or break them out if wish to see each event." />}
            {!_showAmount && <Alert type="info" text="Add each cut for the year. Cuts in spring/summer months are generally expected to produce less dry matter supplement than cuts in autumn/winter." />}
            <TileBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th data-width="md-30 xl-30">Block</th>
                                <th data-width="md-10 xl-10">Area</th>
                                <th data-width="md-20 xl-20">Month</th>
                                {_showAmount && (
                                    <>
                                        <th data-width="md-15 xl-15">{_amountMeta.label}</th>
                                        <th data-width="md-15 xl-15">{_amountMeta.label + "/ha"}</th>
                                    </>
                                )}
                                {_hasBlocks && <th className="th--shrink">&nbsp;</th>}
                            </tr>
                        </thead>
                        {!_hasBlocks && (
                            <tbody>
                                <tr>
                                    <td colSpan="5">
                                        <div className="Tile-body">
                                            <div className="Tile-body-message">
                                                <h3 className="u-mt-md">This feed supplement does not have any source harvest events</h3>
                                                <Button id="add-harvest-event-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={_addSource}>
                                                    Add harvest event
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {_hasBlocks && (
                            <tbody>
                                <FieldArray name="sources" validate={_validate}>
                                    {({ fields }) => {
                                        return fields.map((field, index) => {
                                            const _blockOptions = _getBlockOptions(values, index, blocks, refData);
                                            const block = values.sources[index] && values.sources[index].blockId && blocks.find((b) => b.id === values.sources[index].blockId);
                                            const perHa = values.sources[index] && values.sources[index].amount && block && utils.round(values.sources[index].amount / block.areaInHectares, 2);
                                            return (
                                                <tr key={field}>
                                                    <td>
                                                        <Field name={`${field}.blockId`} noLabel placeholder={(index === 0 || values.amountType !== "Cuts") && "Select a block"} options={_blockOptions} required component={SelectPack} />
                                                    </td>
                                                    <td>{block && (block.areaInHectares || block.rotationArea) + " ha"}</td>
                                                    <td>
                                                        <Field name={`${field}.month`} noLabel placeholder="Select month" options={refData.months} required component={SelectPack} />
                                                    </td>
                                                    {_showAmount && (
                                                        <>
                                                            <td>
                                                                <Field name={`${field}.amount`} noLabel placeholder="0" uom={_amountMeta.uom} requiredLabel={true} type="text" component={InputPack} format={_amountMeta.uom === "tonnes" ? FormUtils.formatters.formatDecimal(1) : FormUtils.formatters.formatInt} formatOnBlur />
                                                            </td>
                                                            <td>{perHa && perHa + " " + _amountMeta.uom + "/ha"}</td>
                                                        </>
                                                    )}

                                                    <td>{index > 0 && <span id={`remove-block-${index}`} className="a u-link IconLink--cross-circle" onClick={() => _removeSource(index)}></span>}</td>
                                                </tr>
                                            );
                                        });
                                    }}
                                </FieldArray>
                            </tbody>
                        )}
                    </table>
                </div>
            </TileBody>
        </Tile>
    );
};

const _getBlockOptions = (feedSupplement, index, blocks, refData) => {
    let pastureTypes = refData.pastureTypes || [];

    const firstBlock = _getFirstBlock(feedSupplement, blocks);
    if (firstBlock && index > 0) {
        if (feedSupplement.amountType === "Cuts") {
            // Cuts can only be for one block.
            return [{ value: firstBlock.id, text: firstBlock.name }];
        } else {
            const { pasture = {} } = firstBlock;
            pastureTypes = pastureTypes.filter((option) => option.value === pasture.pastureCategory);
        }
    }

    const options = [];
    pastureTypes.forEach((pt, i) => {
        blocks
            .filter((b) => b.pasture && b.pasture.pastureCategory === pt.value)
            .forEach((b) => {
                options.push({
                    value: b.id,
                    text: b.name,
                    groupIndex: i,
                    groupLabel: pt.text,
                });
            });
    });

    if (pastureTypes.length === 0 || pastureTypes.length > 1) {
        blocks.forEach((block) => {
            const { cropBlock = {}, crops = [] } = block;
            if (cropBlock.supplementsRemoved || crops.some((c) => _utils.cropHasSupplements(c))) {
                options.push({
                    value: block.id,
                    text: block.name,
                    groupIndex: 100,
                    groupLabel: "Crop Blocks",
                });
            }
        });
    }

    return options;
}

const _getFirstBlock = (feedSupplement, blocks) => {
    const firstSource = (feedSupplement.sources || [])[0];
    return blocks.find((b) => b.id === (firstSource || {}).blockId);
}
