import { Fragment } from "react";
import * as utils from "common/utils";
import { LoadingPanel } from "components/LoadingPanel";
import Watermark from "components/Watermark";
import { SummaryRow } from "components/PlanningReport";
import { useAnalysisResults } from "containers/BudgetHome";

export default function FeedSummaryByEnterprise({ before, after }) {
    const { isLoading: beforeIsLoading, data: beforeResults } = useAnalysisResults(before?.details);
    const { isLoading: afterIsLoading, data: afterResults } = useAnalysisResults(after?.details);

    const isLoading = afterIsLoading || (before && beforeIsLoading);
    const isComparing = !!before && !!after;

    // Cater for the distinct union of enterprises when comparing
    const beforeEnterpriseNames = beforeResults?.animals?.diet?.byEnterprise?.map((e) => e.name) || [];
    const afterEnterpriseNames = afterResults?.animals?.diet?.byEnterprise?.map((e) => e.name) || [];
    const distinctEnterpriseNames = [...new Set([...beforeEnterpriseNames, ...afterEnterpriseNames])];

    return (
        <div className="ReportSection">
            <h1>Enteprise RSU</h1>
            <LoadingPanel isLoading={isLoading}>
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="25"></th>
                                <th></th>
                                {isComparing && (
                                    <th className="numeric u-before" data-width="12.5">
                                        {utils.truncateText(before.displayName, 35)}
                                    </th>
                                )}
                                <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                    {utils.truncateText(after.displayName, 35)}
                                </th>
                                {isComparing && (
                                    <th className="numeric" data-width="16.7">
                                        Change
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {distinctEnterpriseNames?.map((enterpriseName) => {
                                const beforeEnterprise = beforeResults?.animals?.diet?.byEnterprise?.find((e) => e.name === enterpriseName);
                                const afterEnterprise = afterResults?.animals?.diet?.byEnterprise?.find((e) => e.name === enterpriseName);
                                const showBeforeSources = !afterEnterprise && beforeEnterprise;
                                return (
                                    <Fragment key={enterpriseName}>
                                        <SummaryRow beforeValue={beforeEnterprise?.totalRevisedStockUnits?.value} afterValue={afterEnterprise?.totalRevisedStockUnits?.value} isComparing={isComparing} category={beforeEnterprise?.name || afterEnterprise?.name} label="Total" unit={afterEnterprise?.totalRevisedStockUnits?.unit?.notation} />
                                        {afterEnterprise?.bySource?.map((afterSource) => {
                                            const beforeSource = beforeEnterprise?.bySource?.find((b) => b.name === afterSource.name);
                                            return <SummaryRow key={afterSource.name} beforeValue={beforeSource?.totalRevisedStockUnits?.value} afterValue={afterSource?.totalRevisedStockUnits?.value} isComparing={isComparing} category=" " label={afterSource.name} prefix="RSU" unit={afterSource?.totalRevisedStockUnits?.unit?.notation} />;
                                        })}
                                        {showBeforeSources && beforeEnterprise?.bySource?.map((beforeSource) => {
                                            // Catering for distinct union of sources when comparing
                                            return <SummaryRow key={beforeSource.name} beforeValue={beforeSource?.totalRevisedStockUnits?.value} afterValue={0} isComparing={isComparing} category=" " label={beforeSource.name} prefix="RSU" unit={beforeSource?.totalRevisedStockUnits?.unit?.notation} />;
                                        })}
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </LoadingPanel>
        </div>
    )
}
