import { diff } from "deep-object-diff";
import { v4 as uuidv4 } from "uuid";
import * as fertUtils from "containers/BudgetHome/Fertiliser/_utils";
import SimplifyAnalysisLogic from "./SimplifyAnalysisLogic";

const isMergeable = (block) => ["ProductiveFruit", "ProductiveCrop", "ProductivePasture"].includes(block.type);

const hasCrops = (block) => block.crops && block.crops.length > 0;

const hasPasture = (block) => block.pasture && Object.keys(block.pasture).length > 0;

const hasAnimals = (block) => block.animals && block.animals.length > 0;

const hasFruit = (block) => block.fruit && Object.keys(block.fruit).length > 0;

const isCropBlock = (block) => hasCrops(block);

const isGrazingBlock = (block) => hasPasture(block) && hasAnimals(block);

const isCutAndCarryBlock = (block) => hasPasture(block) && !hasAnimals(block);

const isFruitBlock = (block) => hasFruit(block);

const hasComparableApps = (applications, masterBlock, otherBlock) => applications.every((app) => app.blockIds.includes(masterBlock.id) === app.blockIds.includes(otherBlock.id));

const hasComparableFert = (fertilisers, masterBlock, otherBlock) => fertilisers.every((fert) => hasComparableApps(fert.applications, masterBlock, otherBlock));

const hasComparableIrrigation = (irrigationSystems, masterBlock, otherBlock) => irrigationSystems.every((irrigation) => hasComparableApps(irrigation.applications, masterBlock, otherBlock));

const hasComparableDairyEffluent = (dairyEffluent, masterBlock, otherBlock) => {
    const isComparable = hasComparableApps(dairyEffluent.liquidApplications, masterBlock, otherBlock) && hasComparableApps(dairyEffluent.solidApplications, masterBlock, otherBlock) && hasComparableApps(dairyEffluent.pondApplications, masterBlock, otherBlock);
    return isComparable;
};

const hasComparableStructureEffluent = (structures, masterBlock, otherBlock) => {
    const isComparable = structures.every((structure) => hasComparableApps(structure.liquidApplications, masterBlock, otherBlock) && hasComparableApps(structure.solidApplications, masterBlock, otherBlock) && hasComparableApps(structure.pondApplications, masterBlock, otherBlock));
    return isComparable;
};

const hasComparableCrops = (masterBlock, otherBlock) => {
    const blockDiff = diff(masterBlock, otherBlock);

    if (blockDiff.crops === undefined) return true;

    //if (masterBlock.name.startsWith('Grain, seed and green manure block') && otherBlock.name.startsWith('Grain, seed and green manure block')) {
    //    console.log(blockDiff);
    //}

    const cropDiffs = Object.keys(blockDiff.crops).reduce((diffs, cropIndex) => {
        const cropDiff = blockDiff.crops[cropIndex];
        if (cropDiff) {
            const numberOfDiffs = Object.keys(cropDiff).length;
            const thereAreDiffs = numberOfDiffs > 1;
            if (thereAreDiffs) {
                const diffIsOnlySoilTestId = numberOfDiffs === 2 && cropDiff.soilTest && Object.keys(cropDiff.soilTest).length === 1;
                if (!diffIsOnlySoilTestId) diffs.push(cropDiff);
            }
        }
        return diffs;
    }, []);
    if (cropDiffs.length === 0 && blockDiff.cropBlock === undefined) return true;

    return false;
};

const hasComparablePasture = (masterBlock, otherBlock) => {
    const blockDiff = diff(masterBlock, otherBlock);
    return blockDiff.pasture === undefined;
};

const hasComparableAnimals = (masterBlock, otherBlock) => {
    const blockDiff = diff(masterBlock, otherBlock);

    if (blockDiff.animals === undefined) return true;

    const animalDiffs = Object.values(blockDiff.animals);
    const animalsAreDifferent = animalDiffs.some((diff) => diff === undefined);
    if (animalsAreDifferent) return false;

    const monthsAreNotTheOnlyDiff = animalDiffs.some((diff) => Object.keys(diff).length > 1 || !diff.months);
    if (monthsAreNotTheOnlyDiff) return false;

    // This can happen if the months are in a different order in the array.
    const monthsAreActuallyTheSame = masterBlock.animals.reduce((result, master) => {
        const other = otherBlock.animals.find((o) => o.enterpriseId === master.enterpriseId);
        if (master.months.some((m) => !other.months.includes(m)) || other.months.some((m) => !master.months.includes(m))) result = false;
        return result;
    }, true);
    return monthsAreActuallyTheSame;
};

const hasComparableFruit = (masterBlock, otherBlock) => {
    const blockDiff = diff(masterBlock, otherBlock);

    if (blockDiff.fruit === undefined) return true;

    const mergeableFields = ["productYield", "rejectPercentage", "ageOfTrees_yrs"];
    const isComparable = Object.keys(blockDiff.fruit).every((field) => mergeableFields.includes(field));
    if (isComparable) return true;

    return false;
};

const isComparableBlockType = (masterBlock, otherBlock) => {
    if (otherBlock.type !== masterBlock.type) return false;

    //if (masterBlock.name.startsWith('Grain, seed and green manure block') && otherBlock.name.startsWith('Grain, seed and green manure block')) {
    //    console.log(isCropBlock(masterBlock));
    //    console.log(isCropBlock(otherBlock));
    //    console.log(hasComparableCrops(masterBlock, otherBlock));
    //}

    if (isGrazingBlock(masterBlock) && isGrazingBlock(otherBlock) && hasComparablePasture(masterBlock, otherBlock) && hasComparableAnimals(masterBlock, otherBlock)) return true;

    if (isCutAndCarryBlock(masterBlock) && isCutAndCarryBlock(otherBlock) && hasComparablePasture(masterBlock, otherBlock)) return true;

    if (isCropBlock(masterBlock) && isCropBlock(otherBlock) && hasComparableCrops(masterBlock, otherBlock)) return true;

    if (isFruitBlock(masterBlock) && isFruitBlock(otherBlock) && hasComparableFruit(masterBlock, otherBlock)) return true;

    return false;
};

const mergeAnalysis = (analysis, comparable) => {
    const masterBlockId = comparable.blockIds[0];
    const oldBlockIds = comparable.blockIds.filter((id) => id !== masterBlockId);

    const mergedBlock = SimplifyAnalysisLogic.mergeBlock(analysis, masterBlockId, oldBlockIds, comparable.newBlockName);

    const mergedAnalysis = SimplifyAnalysisLogic.mergeBudget(analysis, mergedBlock, oldBlockIds);
    return mergedAnalysis;
};

export function getComparableBlockSets(budget, dispatch) {
    const fertilisers = budget.fertiliser.map((fert) => {
        const applications = fertUtils.groupApplications(fert.applications, true);
        return {
            ...fert,
            applications,
        };
    });

    const irrigationSystems = budget.irrigators.map((irr) => {
        const applications = fertUtils.groupApplications(irr.applications, false);
        return {
            ...irr,
            applications,
        };
    });

    const dairyEffluent = {};
    const dariyLiquids = (budget.effluentSystem && budget.effluentSystem.liquidManagement && budget.effluentSystem.liquidManagement.applications) || [];
    dairyEffluent.liquidApplications = fertUtils.groupApplications(dariyLiquids, false);
    const dariySoilds = (budget.effluentSystem && budget.effluentSystem.solidManagement && budget.effluentSystem.solidManagement.solidApplications) || [];
    dairyEffluent.solidApplications = fertUtils.groupApplications(dariySoilds, false);
    const dariyPonds = (budget.effluentSystem && budget.effluentSystem.solidManagement && budget.effluentSystem.solidManagement.pondApplications) || [];
    dairyEffluent.pondApplications = fertUtils.groupApplications(dariyPonds, false);

    const structureEffluent = budget.structures.map((structure) => {
        const effluent = {};
        const structureLiquids = (structure.effluentSystem && structure.effluentSystem.liquidManagement && structure.effluentSystem.liquidManagement.applications) || [];
        effluent.liquidApplications = fertUtils.groupApplications(structureLiquids, false);
        const structureSolids = (structure.effluentSystem && structure.effluentSystem.solidManagement && structure.effluentSystem.solidManagement.solidApplications) || [];
        effluent.solidApplications = fertUtils.groupApplications(structureSolids, false);
        const structurePonds = (structure.effluentSystem && structure.effluentSystem.solidManagement && structure.effluentSystem.solidManagement.pondApplications) || [];
        effluent.pondApplications = fertUtils.groupApplications(structurePonds, false);
        return effluent;
    });

    const mergeableBlocks = budget.blocks.filter((block) => isMergeable(block));

    const comparableBlocks = mergeableBlocks.reduce((results, currentBlock) => {
        const otherBlocks = mergeableBlocks.filter((nextBlock) => nextBlock.id !== currentBlock.id);

        const comparableBlockIds = otherBlocks.reduce((blockIds, nextBlock) => {
            //if (currentBlock.name.startsWith('Grain, seed and green manure block') && nextBlock.name.startsWith('Grain, seed and green manure block')) {
            //    const isComparable = isComparableBlockType(currentBlock, nextBlock);
            //    console.log('isComparable: ', isComparable);

            //    const fert = hasComparableFert(fertilisers, currentBlock, nextBlock);
            //    console.log('fert: ', fert);

            //    const irr = hasComparableIrrigation(irrigationSystems, currentBlock, nextBlock);
            //    console.log('irr: ', irr);

            //    const eff = hasComparableDairyEffluent(dairyEffluent, currentBlock, nextBlock);
            //    console.log('eff: ', eff);

            //    const str = hasComparableStructureEffluent(structureEffluent, currentBlock, nextBlock);
            //    console.log('str: ', str);
            //}
            if (isComparableBlockType(currentBlock, nextBlock) && hasComparableFert(fertilisers, currentBlock, nextBlock) && hasComparableIrrigation(irrigationSystems, currentBlock, nextBlock) && hasComparableDairyEffluent(dairyEffluent, currentBlock, nextBlock) && hasComparableStructureEffluent(structureEffluent, currentBlock, nextBlock)) {
                blockIds.push(nextBlock.id);
            }
            return blockIds;
        }, []);

        if (comparableBlockIds.length > 0) {
            if (!results.some((comparable) => comparable.blockIds.includes(currentBlock.id))) {
                results.push({
                    key: currentBlock.id,
                    blockIds: [currentBlock.id, ...comparableBlockIds],
                });
            }
        }

        dispatch({ type: "incrementFindProgress" });

        return results;
    }, []);

    return comparableBlocks;
}

export function simplifyAnalysis(analysis, comparableBlocks, dispatch) {
    let simplifiedAnalysis = { ...analysis };
    simplifiedAnalysis.id = uuidv4();
    simplifiedAnalysis.type = "Scenario";
    simplifiedAnalysis.name = `${simplifiedAnalysis.name.substring(0, 37)} (Simplified)`;

    comparableBlocks.forEach((comparable) => {
        simplifiedAnalysis = mergeAnalysis(simplifiedAnalysis, comparable);
        dispatch({ type: "incrementSimplifyProgress" });
    });

    return simplifiedAnalysis;
}
