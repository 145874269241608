import React from "react";
import { FieldArray } from "react-final-form-arrays";
import * as domain from "common/domain";

const toggleMonth = (form, monthsFieldName, selectedMonths, month) => (e) => {
    const index = selectedMonths.findIndex((m) => m === month);

    if (index >= 0) {
        form.mutators.remove(monthsFieldName, index);
    } else {
        if (selectedMonths.length === 3) form.mutators.remove(monthsFieldName, 0);

        form.mutators.push(monthsFieldName, month);
    }

    form.blur(monthsFieldName);
};

const validateMonths = (monthsFieldName) => (months) => {
    const errors = [];

    if (!months || months.length === 0) errors.push({ [monthsFieldName]: "Required" });

    return errors;
};

const MonthsSelector = (props) => {
    const { form, monthsFieldName } = props;

    return (
        <FieldArray name={monthsFieldName} validate={validateMonths(monthsFieldName)}>
            {({ fields }) => {
                const selectedMonths = fields.value || [];
                return domain.farmYear.map((month, i) => {
                    const key = `${monthsFieldName}_${month}`;
                    const isSelected = selectedMonths.includes(month);
                    const isSelectable = isSelected || selectedMonths.length < 3;
                    return (
                        <td key={key} className="u-textCenter">
                            {isSelectable && (
                                <label htmlFor={key}>
                                    <input id={key} className="Checkbox-input" type="checkbox" checked={isSelected} onChange={toggleMonth(form, monthsFieldName, selectedMonths, month)} />
                                    <span className="Checkbox-label"></span>
                                </label>
                            )}
                            {!isSelectable && <span>-</span>}
                        </td>
                    );
                });
            }}
        </FieldArray>
    );
};

export default MonthsSelector;
