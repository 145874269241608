import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as utils from "common/utils";
import * as FormUtils from "common/FormUtils";
import { usePortalModal } from "common/effects";
import Alert from "components/Alert";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import CheckboxField from "components/FormFields/CheckboxField";
import { useNavigate } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function EditFarmAreaModal({ analysis, close }) {
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const navigate = useNavigate();

    const setDefaultTotalArea = (form, values) => () => {
        form.batch(() => {
            form.change("totalFarmArea", values.totalBlockAreaInHectares);
            form.change("nonProductiveAreaInHectares", 0);
        });
    };

    const validate = (formValues) => {
        const validation = {};
        const { totalBlockAreaInHectares, totalFarmArea, overrideFarmArea } = formValues;

        if (overrideFarmArea && (totalFarmArea || 0) < totalBlockAreaInHectares) validation.totalFarmArea = `Must be greater than or equal to the total area declared as blocks (${totalBlockAreaInHectares} ha)`;
        else if (overrideFarmArea && totalFarmArea > 250000) validation.totalFarmArea = "Total area must be less than 250,000 Ha";

        return validation;
    };

    const submit = async (formValues) => {
        try {
            await updateAnalysisAsync(formValues);
            close();
            if (!analysis.hasCompletedBlocks) {
                navigate(`/app/farm/${analysis.farmId}/analysis/${analysis.id}/soil`);
            }
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    };

    return (
        <Form initialValues={{ ...analysis, overrideFarmArea: analysis.totalFarmArea > 0, hasCompletedBlocks: true }} validate={validate} onSubmit={submit}>
            {({ form, values, errors, handleSubmit, invalid, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Edit farm area" close={close} submitting={submitting} allowOffline fluid skinny>
                            <ModalBody info="Enter the total area for the farm. Include all non productive areas that are not allocated to blocks (e.g. lanes, races and yards)" error={submitError}>
                                <Grid>
                                    <GridCell className="u-width1of2">
                                        <Field name="totalBlockAreaInHectares" label="Total area declared as blocks" uom="ha" readOnly component={TextField} />
                                    </GridCell>
                                    {values.overrideFarmArea && (
                                        <GridCell className="u-width1of2">
                                            <Field name="nonProductiveAreaInHectares" label="Area not declared as blocks" uom="ha" readOnly component={TextField} />
                                        </GridCell>
                                    )}
                                    <GridCell>
                                        <Field name="overrideFarmArea" label="Override default farm area" onChange={(checked) => !checked && form.change("totalFarmArea", 0)} type="checkbox" component={CheckboxField} />
                                        {values.overrideFarmArea && (
                                            <>
                                                <Field name="totalFarmArea" label="Total farm area" placeholder="Enter the total farm area" required uom="ha" hideError onChange={(value) => form.change("nonProductiveAreaInHectares", utils.round(value - values.totalBlockAreaInHectares, 1))} autoFocus format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                                {false && errors.totalFarmArea && (
                                                    <div className="In-error u-pt-0">
                                                        <div id="use-drawn-area" onClick={setDefaultTotalArea(form, values)} className="a u-link IconLink--arrow-plus">
                                                            Use total area declared as blocks ({values.totalBlockAreaInHectares} ha)
                                                        </div>
                                                    </div>
                                                )}
                                                {errors.totalFarmArea && <Alert type="error" text={<span className="u-textError">{errors.totalFarmArea}</span>} actionMessage="Use total area declared as blocks" onAction={setDefaultTotalArea(form, values)} actionIcon="IconLink--arrow-plus" actionAlignRight className="u-mt-xs u-mb-0" />}
                                            </>
                                        )}
                                    </GridCell>
                                </Grid>
                                {false && analysis.isDrawn && !analysis.hasCompletedBlocks && (
                                    <>
                                        <h3 className="u-mb-md">Use farm soils map or manually assign soils?</h3>
                                        <Grid>
                                            <GridCell>
                                                <Field name="useFarmSoils" label="Use farm soils map" vertical type="checkbox" component={CheckboxField} />
                                            </GridCell>
                                        </Grid>
                                    </>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting} disabled={invalid || submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useEditFarmAreaModal(analysis) {
    const { openModal, getModal } = usePortalModal();

    const openEditFarmAreaModal = () => {
        const modalProps = {
            analysis,
        };
        openModal("EditFarmAreaModal", modalProps);
    };

    const editFarmAreaModal = getModal("EditFarmAreaModal", EditFarmAreaModal);

    return [openEditFarmAreaModal, editFarmAreaModal];
}
