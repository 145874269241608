import React from "react";
import Page from "components/Page";
import PageBody from "components/PageBody";
import CouncilReporting from "./CouncilReporting";

function CouncilIndex() {
    return (
        <Page>
            <PageBody>
                <CouncilReporting />
            </PageBody>
        </Page>
    );
}

export default CouncilIndex;
