import Alert from "components/Alert";
import { useFieldHelp } from "components/FormFields/useFieldHelp";

export default function SelectPack(props) {
    const { tip, info, warning } = props;
    const [fieldHelpLink, fieldHelp] = useFieldHelp(tip);
    if (props.isHidden) return null;
    return props.meta.nrf ? (
        <div className={`Field ${props.val && props.val.touched && props.val.error ? "has-error" : ""} ${props.className ? props.className : ""}`} data-width={props.dataWidth}>
            <label className="Select Select--block">
                <span className={`Field-label ${props.disabled && "is-disabled"}`}>
                    {props.label}
                    {props.requiredLabel === true && <sup className="required">*</sup>}
                    {fieldHelpLink}
                </span>
                <select className={props.value ? "" : "is-unpopulated"} name={props.id} id={props.id} onChange={(e) => props.onChange(e)} value={props.value || ""} disabled={props.disabled}>
                    {props.children}
                </select>
            </label>
            <small className="Field-error" id={`${props.id}-error`}>
                {props.val && props.val.message}
            </small>
            {info && <Alert type="info" text={info} />}
            {warning && <Alert type="warning" text={warning} />}
            {fieldHelp}
        </div>
    ) : (
        <div className={`Field ${props.meta.touched && props.meta.error ? "has-error" : ""} ${props.className ? props.className : ""}`} data-width={props.dataWidth}>
            <label htmlFor={props.id || props.input.name} className="Select Select--block">
                {props.noLabel !== true && (
                    <span className={`Field-label ${props.disabled && "is-disabled"}`}>
                        {props.label}
                        {props.requiredLabel === true && <sup className="required">*</sup>}
                        {fieldHelpLink}
                    </span>
                )}
                <select {...props.input} id={props.id || props.input.name} disabled={props.disabled} autoFocus={props.autoFocus ? true : false}>
                    {props.children}
                </select>
            </label>
            {props.meta.touched && props.meta.error && (
                <small className="Field-error" id={`${props.id || props.input.name}-error`}>
                    {props.meta.error}
                </small>
            )}
            {info && <Alert type="info" text={info} />}
            {warning && <Alert type="warning" text={warning} />}
            {fieldHelp}
        </div>
    )
}
