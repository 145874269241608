import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FEATURES } from "components/FeatureTracker/FeatureTracker";
import ActionLink from "components/ActionLink";
import { getFeatureUsage } from "./_actions";
import { useFeatureUsageModal } from "./FeatureUsageModal";

export default function FeatureUsageWidget() {
    const [minutes, setMinutes] = useState(MINUTES[3].value);
    const trackedFeatureNames = Object.keys(FEATURES).map((key) => FEATURES[key]);
    const [featureUsage, refresh] = useFeatureUsage(minutes);
    const [featureUsageModal, openFeatureUsageModal] = useFeatureUsageModal();

    const features = featureUsage.reduce((results, usage) => {
        let feature = results.find((r) => r.name === usage.feature);
        if (!feature) {
            feature = {
                name: usage.feature,
                sessions: [],
                actions: [],
                orgs: [],
                users: [],
                farms: [],
            };
            results.push(feature);
        }

        let session = feature.sessions.find((s) => s.id === usage.sessionId);
        if (!session) {
            session = {
                id: usage.sessionId,
                timestamp: usage.timestamp,
                userId: usage.userId,
                userName: usage.userName,
                accountId: usage.accountId,
                accountName: usage.accountName,
                actions: [],
            };
            feature.sessions.push(session);
        }
        session.actions.push(usage.action);

        let action = feature.actions.find((a) => a.name === usage.action);
        if (!action) {
            action = {
                name: usage.action,
                count: 0,
            };
            feature.actions.push(action);
        }
        action.count = action.count + 1;

        let org = feature.orgs.find((o) => o.id === usage.accountId);
        if (!org) {
            org = {
                id: usage.accountId,
                name: usage.accountName,
            };
            feature.orgs.push(org);
        }

        let user = feature.users.find((u) => u.id === usage.userId);
        if (!user) {
            user = {
                id: usage.userId,
                name: usage.userName,
                orgName: usage.accountName,
                orgId: usage.accountId,
            };
            feature.users.push(user);
        }

        let farm = feature.farms.find((u) => u.id === usage.farmId);
        if (!farm && usage.farmId) {
            farm = {
                id: usage.farmId,
                name: usage.farmName,
                orgs: [],
            };
            feature.farms.push(farm);
        }
        if (farm && !farm.orgs.includes(usage.accountId)) {
            farm.orgs.push(usage.accountId);
        }

        return results;
    }, []);

    const _openFeatureUsageModal = (feature) => () => {
        if (!feature) return;

        const since = MINUTES.find((min) => min.value === minutes);
        openFeatureUsageModal(feature, since.text);
    };

    return (
        <>
            <div className="featureUsage">
                <div className="flexWidget">
                    <div className="flexWidget__header">
                        <h3>Feature usage</h3>
                        <div className="u-flexSplit">
                            <select id="feature-usage-minutes" value={minutes} onChange={(e) => setMinutes(Number(e.target.value))} className="u-border-none">
                                {MINUTES.map((opt) => (
                                    <option key={opt.value} value={opt.value}>
                                        {opt.text}
                                    </option>
                                ))}
                            </select>
                            <ActionLink className="IconLink--refresh u-ml-md u-mr-xs" onClick={refresh}>
                                Refresh
                            </ActionLink>
                        </div>
                    </div>
                    <div className="flexWidget__body">
                        <div className="Table u-mt-md">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Feature</th>
                                        <th>Sessions</th>
                                        <th>Organisations</th>
                                        <th>Users</th>
                                        <th>Farms</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trackedFeatureNames.map((featureName) => {
                                        const feature = features.find((f) => f.name.replace(" ", "").toLowerCase() === featureName.replace(" ", "").toLowerCase());
                                        return (
                                            <tr key={featureName} onClick={feature ? _openFeatureUsageModal(feature) : undefined} className={`hover ${feature ? "u-link" : ""}`}>
                                                <td>{featureName}</td>
                                                <td className="u-textCenter">{feature ? feature.sessions.length : 0} </td>
                                                <td className="u-textCenter">{feature ? feature.orgs.length : 0}</td>
                                                <td className="u-textCenter">{feature ? feature.users.length : 0}</td>
                                                <td className="u-textCenter">{feature ? feature.farms.length : 0}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {false &&
                            trackedFeatureNames.map((featureName) => {
                                const feature = features.find((f) => f.name.replace(" ", "").toLowerCase() === featureName.replace(" ", "").toLowerCase());
                                return (
                                    <div key={featureName}>
                                        <h3>{featureName}</h3>
                                        <div className="HeroPanel u-flex u-flexWrap u-mt-md">
                                            <div className="HeroValue-item">
                                                <h5>Sessions</h5>
                                                <div className="HeroValue-item-number-large">{feature ? feature.sessions.length : 0}</div>
                                            </div>
                                        </div>
                                        <div className="HeroPanel u-flex u-flexWrap u-mt-md">
                                            <div className="HeroValue-item">
                                                <h5>Orgs</h5>
                                                <div className="HeroValue-item-number-large">{feature ? feature.orgs.length : 0}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
            {featureUsageModal}
        </>
    );
}

function useFeatureUsage(minutes) {
    const dispatch = useDispatch();
    const [refreshToggle, setRefreshToggle] = useState(false);
    const [featureUsage, setFeatureUsage] = useState([]);

    useEffect(() => {
        const search = async () => {
            const usage = await dispatch(getFeatureUsage(minutes));
            setFeatureUsage(usage);
        };
        search();
    }, [minutes, refreshToggle, dispatch]);

    return [featureUsage, () => setRefreshToggle((prevState) => !prevState)];
}

const MINUTES = [
    { value: 30, text: "Last 30 mins" },
    { value: 60, text: "Last hour" },
    { value: 1440, text: "Last 24 hours" },
    { value: 10080, text: "Last 7 days" },
    { value: 43200, text: "Last 30 days" },
    { value: 129600, text: "Last 90 days" },
];
