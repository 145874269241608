import { Line } from "react-chartjs-2";
import * as utils from "common/utils";
import { useBlockResults } from "containers/BudgetHome";

export default function BlockNitrogenPoolsGraphs({ analysis, block }) {
    const { data: blockResults } = useBlockResults(analysis, block);

    if (!blockResults) {
        return null;
    }

    const nPoolsChartData = mapPoolsDataToChartData(blockResults.nitrogen?.pools);
    const changeInNPoolsChartData = mapPoolsDataToChartData(blockResults.nitrogen?.changeInPools);

    return (
        <>
            {nPoolsChartData && <>
                <h3>Nitrogen pools</h3>
                <Line data={nPoolsChartData.data} legend={{ display: true }} height={75} options={nPoolsChartData.options} />
            </>}
            {changeInNPoolsChartData && <>
                <h3>Change in nitrogen pools</h3>
                <Line data={changeInNPoolsChartData.data} legend={{ display: true }} height={75} options={changeInNPoolsChartData.options} />
            </>}
        </>
    );
}

function mapPoolsDataToChartData(poolsData) {
    if (!poolsData) return null;

    let uom = "";

    const chartData = poolsData.reduce((results, pool, i) => {
        const months = [];
        if (pool.previousYear) {
            const previousYearMonths = pool.previousYear.map(m => ({ index: m.index, month: m.month, amount: m.amount }));
            months.push(...previousYearMonths);
        }
        if (pool.reportingYear) {
            const reportingYearMonths = pool.reportingYear.map(m => ({ index: m.index, month: m.month, amount: m.amount }));
            months.push(...reportingYearMonths);
        }
        months.sort((a, b) => a.index - b.index);

        results.datasets.push({
            label: pool.name,
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: utils.graphColours[i],
            data: months.map(m => m.amount.value)
        });
        if (results.labels.length === 0) {
            results.labels.push(...months.map(m => m.month));
        }
        if (uom === "") {
            uom = months[0].amount.unit.notation;
        }
        return results;
    }, { labels: [], datasets: [] });

    const options = {
        scales: {
            y: {
                title: {
                    display: true,
                    text: uom,
                },
            },
        },
    };

    return { data: chartData, options };
}
