import * as geoJsonUtils from "common/geoJsonUtils";
import * as utils from "common/utils";
export const basedOnId = `irrigator_activity_basedon`;
export const manDefaultId = `irrigator_activity_managementsys_def`;
export const manShift1Id = `irrigator_activity_managementsys_1`;
export const manShift2Id = `irrigator_activity_managementsys_2`;
export const soilMoistureUsageId = `irrigator_activity_soilMoistureUsage`;
export const minDepthId = `irrigator_activity_mindepth`;
export const maxDepthId = `irrigator_activity_maxdepth`;
export const depthId = `irrigator_activity_depth`;
export const retPerId = `irrigator_activity_returnperiod`;
export const minRetPerId = `irrigator_activity_minreturnperiod`;
export const unitsId = `irrigator_activity_units`;
export const trigPtId = `irrigator_activity_triggerpoint`;
export const targetId = `irrigator_activity_target`;
export const outwashId = `irrigator_activity_outwash`;
export const blksId = `irrigator_activity_blocks`;
export const appDepthId = `irrigator_activity_appdepth`;
export const avgFrostTempId = `irrigator_activity_frost_avgt`;
export const avgFrostDurId = `irrigator_activity_frost_avgd`;
export const frostDaysId = `irrigator_activity_frost_days`;
export const monthGroupId = (applicationId) => `irrigator_activity_months_${applicationId}`;
export const nutrients = ["N", "P", "K", "S", "Ca", "Mg", "Na"];
export const IrrigatorTypes = { CentrePivot: "CentrePivot", Flood: "Flood", Solid: "Solid", Drip: "Drip", Spray: "Spray", Travelling: "Travelling", BorderDyke: "BorderDyke" };
export const Schedule = { Fixed: "None", Probes: "Probes", SoilWaterBudget: "Manual", Visual: "Visual", Tapes: "SoilMoistureTapes", Frost: "Frost", Depth: "Depth", BorderDyke: "BorderDyke", Flood: "Flood" };
export const SoilMoistureUsage = { TriggerPoint: "TriggerPoint", TargetDepth: "TargetDepth", TriggerPointTargetDepth: "TriggerPointTargetDepth" };
export const SystemDefinitions = { Default: "Default", UserDefined: "UserDefined", DefaultSingleShift: "DefaultSingleShift", DefaultDoubleShift: "DefaultDoubleShift" };

export const IrrigationApplicationTips = {
    TriggerPoint: { key: SoilMoistureUsage.TriggerPoint, text: "Application depth and trigger point is set using defaults for this irrigator and PAW of the soil. Return period is calculated using the modelled soil moisture (based on climate and soil properties) and trigger point. Default depth and trigger point can be overridden below." },
    TargetDepth: { key: SoilMoistureUsage.TargetDepth, text: "Return period is set using the default for this irrigator and PAW of the soil. Application depth is calculated using the modelled soil moisture (based on climate and soil properties) and a default target of 95% of PAW. Default return period and target can be overridden below." },
    TriggerPointTargetDepth: { key: SoilMoistureUsage.TriggerPointTargetDepth, text: "Return period and application depth is calculated based on the modelled soil moisture (based on climate and soil properties). Uses default trigger point for this irrigator and soil PAW. Uses default arget default is 95% of PAW. These defaults can be overridden below." },
};

export const Ctrl = {
    BasedOn: "BasedOn",
    ManagementSysDef: "ManagementSysDef",
    SoilMoistureUsage: "SoilMoistureUsage",
    MinimumDepth: "MinimumDepth",
    MaximumDepth: "MaximumDepth",
    Depth: "Depth",
    ReturnPeriod: "ReturnPeriod",
    MinReturnPeriod: "MinReturnPeriod",
    Units: "Units",
    TriggerPoint: "TriggerPoint",
    Target: "Target",
    Outwash: "Outwash",
};
export const UserDefinedConfig = [
    {
        IrrigatorTypes: [],
        Schedules: [Schedule.Fixed, Schedule.BorderDyke, Schedule.Flood, Schedule.Visual],
        SoilMoistureUsageControls: [
            {
                SoilMoistureUsage: undefined,
                Controls: [Ctrl.BasedOn, Ctrl.Depth, Ctrl.ReturnPeriod],
            },
        ],
    },
    {
        IrrigatorTypes: [],
        Schedules: [Schedule.SoilWaterBudget, Schedule.Probes, Schedule.Tapes],
        SoilMoistureUsageControls: [
            {
                SoilMoistureUsage: SoilMoistureUsage.TriggerPoint,
                Controls: [Ctrl.BasedOn, Ctrl.SoilMoistureUsage, Ctrl.Depth, Ctrl.MinReturnPeriod, Ctrl.Units, Ctrl.TriggerPoint],
            },
            {
                SoilMoistureUsage: SoilMoistureUsage.TargetDepth,
                Controls: [Ctrl.BasedOn, Ctrl.SoilMoistureUsage, Ctrl.MinimumDepth, Ctrl.MaximumDepth, Ctrl.ReturnPeriod, Ctrl.Units, Ctrl.Target],
            },
            {
                SoilMoistureUsage: SoilMoistureUsage.TriggerPointTargetDepth,
                Controls: [Ctrl.BasedOn, Ctrl.SoilMoistureUsage, Ctrl.Units, Ctrl.TriggerPoint, Ctrl.Target],
            },
        ],
    },
];

export const evalScheduleConfig = (application, irrigatorType) => UserDefinedConfig.find((c) => c.Schedules.includes(application.schedule) || c.IrrigatorTypes.includes(irrigatorType)) || { SoilMoistureUsageControls: [] };
export const evalSoilMoistureUsageConfig = (application, irrigatorType) => evalScheduleConfig(application, irrigatorType).SoilMoistureUsageControls.find((s) => s.SoilMoistureUsage === (application.soilMoistureUsage === null ? undefined : application.soilMoistureUsage)) || { Controls: [] };
export const evalMultiManagementSysDef = (irrigator) => [IrrigatorTypes.Travelling, IrrigatorTypes.Spray].includes(irrigator.type);
export const existingTouched = (validation, id) => validation && validation[id] && validation[id].touched;
export const isTouched = (validation, source, id) => existingTouched(validation, id) || source === undefined || source.id === id;
export const removeMonth = (application, month, reportingYear) => {
    const newMonths = application.months.filter((m) => !(m.reportingYear === reportingYear && m.month === month));
    if (newMonths.length !== application.months.length) {
        application.months = newMonths;
        return true;
    }
    return false;
};
export const otherAppsWithSameBlocks = (irrigator, application) => {
    const result = [];
    /* eslint-disable no-unused-vars */
    for (const otherApplication of irrigator.applications.filter((a) => a.id !== application.id)) {
        for (const block of application.blockIds) {
            if (otherApplication.blockIds.includes(block)) {
                result.push(otherApplication);
                break;
            }
        }
    }
    return result;
};

export const drawnAreas = (analysis) => {
    try {
        const irrigatedBlockAreas = [];
        const fodderDetails = utils.getFodderBlockAreas(analysis.blocks);

        const { irrigators = [], blocks = [] } = analysis;

        // Drawn ones first
        irrigators.forEach((irrigator) => {
            getBlocksIntersectedByDrawnIrrigators(analysis, irrigator.id).forEach((ib) => {
                irrigatedBlockAreas.push({ irrigatorId: irrigator.id, blockId: ib.id, irrigatedArea: fodderDetails.pastureBlockIds.has(ib.id) ? ib.irrigatedArea - ib.irrigatedArea * fodderDetails.ratio : ib.irrigatedArea, drawn: true });
            });
        });

        // Non-drawn ones second
        irrigators
            .filter((irrigator) => irrigator.nonDrawnArea && irrigator.nonDrawnArea.length > 0)
            .forEach((irrigator) => {
                irrigator.nonDrawnArea.forEach((nonDrawn) => {
                    //const block = blocks.find(b => b.type !== 'FodderCrop' && b.id === nonDrawn.blockId)
                    const block = blocks.find((b) => b.id === nonDrawn.blockId);
                    if (block) {
                        const blockArea = block.type === "FodderCrop" ? block.rotationArea : fodderDetails.pastureBlockIds.has(block.id) ? block.areaInHectares - (block.areaInHectares * fodderDetails.fodderArea) / fodderDetails.pastoralArea : block.areaInHectares;
                        const blockAreaAlreadyIrrigatedByDrawnSystems = irrigatedBlockAreas.filter((ib) => ib.blockId === nonDrawn.blockId && ib.drawn).reduce((sum, ib) => (sum += ib.irrigatedArea), 0);
                        const blockAreaAlreadyIrrigatedByNonDrawnSystems = irrigatedBlockAreas.filter((ib) => ib.blockId === nonDrawn.blockId && !ib.drawn).reduce((sum, ib) => (sum += ib.irrigatedArea), 0);
                        const blockAreaAlreadyIrrigated = blockAreaAlreadyIrrigatedByDrawnSystems + blockAreaAlreadyIrrigatedByNonDrawnSystems;
                        const remainingBlockArea = blockAreaAlreadyIrrigatedByDrawnSystems > 0 ? blockArea - blockAreaAlreadyIrrigated : blockArea;
                        const irrigatedArea = utils.round(remainingBlockArea * (nonDrawn.percentageOfNonDrawnArea / 100), 1);
                        irrigatedBlockAreas.push({ irrigatorId: irrigator.id, blockId: block.id, irrigatedArea });
                    }
                });
            });

        // Lines 129 and 147-159 are a proposed solution for PRD-3006. Note: it doesn't use the ratio of pasture blocks with fodder rotations it simply excludes
        // fodder area if a block it rotates through is already irrigated

        // Special case for fodder crops - only include their rotation area if the blocks they rotate through are not already irrigated
        //irrigators
        //    .filter(irrigator => irrigator.nonDrawnArea && irrigator.nonDrawnArea.length > 0)
        //    .forEach(irrigator => {
        //        irrigator.nonDrawnArea.forEach(nonDrawn => {
        //            const fodderBlock = blocks.find(b => b.type === 'FodderCrop' && b.id === nonDrawn.blockId)
        //            if (fodderBlock) {
        //                const noneOfTheBlocksTheFodderCropRotatesThroughAreIrrigated = !irrigatedBlockAreas.some(ib => fodderBlock.blockIds.includes(ib.blockId));
        //                if (noneOfTheBlocksTheFodderCropRotatesThroughAreIrrigated) {
        //                    irrigatedBlockAreas.push({ irrigatorId: irrigator.id, blockId: fodderBlock.id, irrigatedArea: fodderBlock.rotationArea })
        //                }
        //            }
        //        })
        //    })

        // Total by irrigator.
        return irrigators.map((irrigator) => {
            const totalIrrigatedArea = irrigatedBlockAreas.filter((ib) => ib.irrigatorId === irrigator.id).reduce((total, ib) => (total += ib.irrigatedArea), 0);
            return {
                irrigatorId: irrigator.id,
                area: utils.round(totalIrrigatedArea, 1),
            };
        });
    } catch (ex) {
        console.log("Exception while getting area of irrigator");
        return [];
    }
};

export function getBlocksIntersectedByDrawnIrrigators(analysis, irrigatorId) {
    const irrigatorFeatures = (analysis.features || []).filter((f) => f.properties.irrigatorId === irrigatorId);
    if (irrigatorFeatures.length === 0) return [];

    const _isDrawnBlock = (block) => (analysis.features || []).some((f) => f.properties.blockId === block.id);

    var intersectedBlocks = (analysis.blocks || [])
        .filter((block) => block.isProductive && block.type !== "ProductiveOutdoorPigs" && _isDrawnBlock(block))
        .reduce((blocks, block) => {
            const blockFeatures = (analysis.features || []).filter((f) => f.properties.blockId === block.id);
            const unionedBlockFeature = geoJsonUtils.union(blockFeatures);
            const unionedIrrigatorFeature = geoJsonUtils.union(irrigatorFeatures);
            var intersection = geoJsonUtils.intersection(unionedIrrigatorFeature, unionedBlockFeature);
            if (intersection && intersection.area > 0) {
                const drawnBlockArea = geoJsonUtils.area(unionedBlockFeature);
                const intersectionRatio = intersection.area / drawnBlockArea;
                const irrigatedArea = utils.round(block.areaInHectares * intersectionRatio, 1);
                const irrigatedPercentage = utils.round((irrigatedArea / block.areaInHectares) * 100, 1);
                blocks.push({
                    id: block.id,
                    name: block.name,
                    type: block.type,
                    area: block.areaInHectares,
                    irrigatedArea,
                    irrigatedPercentage,
                });
            }
            return blocks;
        }, []);
    return intersectedBlocks;
}

export const getBlocksWithTooManyIrrigators = (irrigator, analysis, intersectedBlocks) => {
    const blocksWithTooManyIrrigators = intersectedBlocks.reduce((results, block) => {
        const appCount = (analysis.irrigators || [])
            .filter((irr) => irr.id !== irrigator.id)
            .reduce((count, irr) => {
                if (irr.applications.some((app) => app.blockIds.includes(block.id))) {
                    count++;
                }
                return count;
            }, 0);

        if (appCount >= 2) {
            results.push(block);
        }

        return results;
    }, []);
    return blocksWithTooManyIrrigators;
};

export const getIrrigatedBlocksWithTooMuchDrawnArea = (intersectedBlocks, analysis) => {
    return intersectedBlocks.filter((block) => {
        if (block.irrigatedPercentage > 95) {
            // Cannot be over 95% if block also has non-drawn irrigation system on it
            const blockAlsoHasNonDrawnIrrigator = (analysis.irrigators || []).some((irr) => (irr.nonDrawnArea || []).some((nda) => nda.blockId === block.id));
            return blockAlsoHasNonDrawnIrrigator;
        }
        return false;
    });
};

export const getIrrigatedBlocksWithInsignificantDrawnArea = (intersectedBlocks) => {
    return intersectedBlocks.filter((block) => block.irrigatedArea === 0 || block.irrigatedPercentage < 5);
};
