import { Link } from "react-router-dom";
import { FieldArray } from "react-final-form-arrays";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import Card from "components/new/Card";
import CardHeader from "components/new/CardHeader";
import CardBody from "components/new/CardBody";
import CardActions from "components/new/CardActions";
import CardActionsRight from "components/new/CardActionsRight";
import Button from "components/Button/Button";
import { useRefData } from "common/hooks";
import { useOutdoorPigGreenCoverApplicationModal } from "./OutdoorPigGreenCoverApplicationModal";

export default function OutdoorPigGreenCoverApplications({ farm, analysis, form, outdoorPigs, outdoorPigBlocks }) {
    const refData = useRefData();
    const [outdoorPigGreenCoverApplicationModal, openOutdoorPigGreenCoverApplicationModal] = useOutdoorPigGreenCoverApplicationModal();

    const applications = outdoorPigs.greenCoverApplications || [];
    const selectedBlockIds = applications.reduce((results, application) => results.concat(application.blockIds), []);
    const unselectedBlocks = outdoorPigBlocks.filter((b) => !selectedBlockIds.includes(b.id));
    const allBlocksAlreadyHaveApplications = unselectedBlocks.length === 0;
    const addApplicationLink = !allBlocksAlreadyHaveApplications && (
        <span id="add-application-link" className="u-link IconLink--arrow-plus" onClick={addApplication(form, outdoorPigs, unselectedBlocks, refData, openOutdoorPigGreenCoverApplicationModal)}>
            Add green cover
        </span>
    );

    const blockIcon = utils.getBlockIcon("OutdoorPigs");
    const width = outdoorPigs.greenCoverApplications.length > 1 ? "u-width1of2" : `u-width1of1`;

    const hasOutdoorPigBlocks = outdoorPigBlocks.length > 0;
    const hasGreenCoverApplications = applications.length > 0;

    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const showGrowersFinishersFields = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    return (
        <>
            <Panel title="Green cover" actions={addApplicationLink} midBlue>
                <Alert type="info" text="Specifies the percentage area of outdoor pig blocks that are covered by pasture." />
                <PanelBody>
                    {!hasOutdoorPigBlocks && (
                        <div className="Table u-mt-md">
                            <div className="Tile-body">
                                <div className="Tile-body-message">
                                    <h3>One or more outdoor pigs blocks are required</h3>
                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/blocks/new`} id="create-outdoor-pig-block" className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                        Create outdoor pigs block
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                    {hasOutdoorPigBlocks && !hasGreenCoverApplications && (
                        <div className="Table u-mt-md">
                            <div className="Tile-body">
                                <div className="Tile-body-message">
                                    <Button id="add-application-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={addApplication(form, outdoorPigs, unselectedBlocks, refData, openOutdoorPigGreenCoverApplicationModal)}>
                                        Add green cover
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {hasOutdoorPigBlocks && hasGreenCoverApplications && (
                        <Grid>
                            <FieldArray name="greenCoverApplications">
                                {({ fields }) => {
                                    return fields.map((greenCoverApplicationField, greenCoverApplicationIndex) => {
                                        const greenCover = fields.value[greenCoverApplicationIndex];
                                        const selectedBlocks = greenCover.blockIds.map((id) => {
                                            return outdoorPigBlocks.find((b) => b.id === id);
                                        });
                                        const title = selectedBlocks.map((block, i) => (
                                            <span key={block.id}>
                                                {i > 0 ? "| " : ""} {block.name}{" "}
                                            </span>
                                        ));

                                        return (
                                            <GridCell key={greenCover.blockIds} className={width}>
                                                <Card>
                                                    <CardHeader id={`${greenCoverApplicationIndex}-card`} dataWidth="md-100 xl-100" titleIcon={blockIcon} title={title}></CardHeader>
                                                    <CardBody className="u-ml-sm">
                                                        <div className="Table">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th data-width="25"></th>
                                                                        {SEASON_NAMES.map((seasonName) => (
                                                                            <th key={seasonName} className="u-textCenter">
                                                                                {seasonName}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <FieldArray name={`${greenCoverApplicationField}.applications`}>
                                                                        {({ fields }) => {
                                                                            return fields.map((applicationField, applicationIndex) => {
                                                                                const application = fields.value[applicationIndex];
                                                                                const stockClassGroupDisplayText = utils.getOutdoorPigStockClassGroupDisplayText(application.stockClassGroup);

                                                                                if (application.stockClassGroup === "Growers" && !showGrowersFinishersFields) return null;

                                                                                if (application.stockClassGroup !== "Growers" && outdoorPigs.growOutUnitOnly) return null;

                                                                                return (
                                                                                    <tr key={application.stockClassGroup}>
                                                                                        <td className="u-textBold">{stockClassGroupDisplayText}</td>
                                                                                        <FieldArray name={`${applicationField}.seasons`}>
                                                                                            {({ fields }) => {
                                                                                                return fields.map((seasonField, seasonIndex) => {
                                                                                                    const season = fields.value[seasonIndex];
                                                                                                    return (
                                                                                                        <td key={season.season} className="u-textCenter">
                                                                                                            {season.percentage} %
                                                                                                        </td>
                                                                                                    );
                                                                                                });
                                                                                            }}
                                                                                        </FieldArray>
                                                                                    </tr>
                                                                                );
                                                                            });
                                                                        }}
                                                                    </FieldArray>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </CardBody>
                                                    <CardActions>
                                                        <CardActionsRight>
                                                            <ul className="ActionsBar-links">
                                                                <li>
                                                                    <ActionLink id={`delete-cover-${greenCoverApplicationIndex}`} onClick={removeApplication(form, outdoorPigs, greenCoverApplicationIndex)} className="IconLink--cross-circle">
                                                                        Delete green cover
                                                                    </ActionLink>
                                                                </li>
                                                                <li>
                                                                    <ActionLink id={`edit-cover-${greenCoverApplicationIndex}`} onClick={editApplication(form, outdoorPigs, greenCover, greenCoverApplicationIndex, selectedBlocks, unselectedBlocks, refData, openOutdoorPigGreenCoverApplicationModal)} className="IconLink--edit">
                                                                        Edit green cover
                                                                    </ActionLink>
                                                                </li>
                                                            </ul>
                                                        </CardActionsRight>
                                                    </CardActions>
                                                </Card>
                                            </GridCell>
                                        );
                                    });
                                }}
                            </FieldArray>
                        </Grid>
                    )}
                </PanelBody>
            </Panel>
            {outdoorPigGreenCoverApplicationModal}
        </>
    )
}

const SEASON_NAMES = ["Spring", "Summer", "Autumn", "Winter"];

const addApplication = (form, outdoorPigs, unselectedBlocks, refData, openOutdoorPigGreenCoverApplicationModal) => () => {
    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const showGrowersFinishersFields = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    const viewModel = {
        blockIds: [],
        applications: [],
        availableBlocks: unselectedBlocks,
    };

    const outdoorPigStockClassGroups = refData.outdoorPigStockClassGroups || [];
    outdoorPigStockClassGroups.forEach((option) => {
        const stockClassGroup = option.value;
        if (stockClassGroup === "Growers" && !showGrowersFinishersFields) return;

        if (stockClassGroup !== "Growers" && outdoorPigs.growOutUnitOnly) return;

        const seasons = SEASON_NAMES.map((season) => ({ season, percentage: 0 }));
        viewModel.applications.push({ stockClassGroup, seasons });
    });

    openOutdoorPigGreenCoverApplicationModal(viewModel, applicationAdded(form));
}

const applicationAdded = (form) => (application) => {
    form.mutators.push("greenCoverApplications", application);
}

const removeApplication = (form, outdoorPigs, index) => (e) => {
    const results = outdoorPigs.greenCoverApplications.reduce((acc, cur, i) => {
        if (i !== index) acc.push(cur);
        return acc;
    }, []);
    form.change("greenCoverApplications", results);
}

const editApplication = (form, outdoorPigs, application, applicationIndex, selectedBlocks, unselectedBlocks, refData, openOutdoorPigGreenCoverApplicationModal) => (e) => {
    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const showGrowersFinishersFields = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    const viewModel = {
        ...application,
        applications: [],
        availableBlocks: selectedBlocks.concat(unselectedBlocks),
    };

    const outdoorPigStockClassGroups = refData.outdoorPigStockClassGroups || [];
    outdoorPigStockClassGroups.forEach((option) => {
        const stockClassGroup = option.value;
        if (stockClassGroup === "Growers" && !showGrowersFinishersFields) return;

        if (stockClassGroup !== "Growers" && outdoorPigs.growOutUnitOnly) return;

        const existingApplication = application.applications.find((a) => a.stockClassGroup === stockClassGroup);
        if (existingApplication) {
            viewModel.applications.push(existingApplication);
        } else {
            const seasons = SEASON_NAMES.map((season) => ({ season, percentage: 0 }));
            viewModel.applications.push({ stockClassGroup, seasons });
        }
    });

    openOutdoorPigGreenCoverApplicationModal(viewModel, applicationEdited(form, applicationIndex));
}

const applicationEdited = (form, applicationIndex) => (application) => {
    form.mutators.update("greenCoverApplications", applicationIndex, application);
}