import GHGActionsBar from "./GHGActionsBar";
import GHGList from "./GHGList";

export default function GHG({ farm, analysis }) {

    return (
        <>
            <GHGActionsBar farm={farm} analysis={analysis} />
            <GHGList farm={farm} analysis={analysis} />
        </>
    )
}
