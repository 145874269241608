import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";

export function useUnitPriceExclGst() {
    const query = useQuery({
        queryKey: ["payment-details", "unit-price-excl-gst"],
        queryFn: async () => httpClient.get(`payments/unitPrice`),
        retry: false,
        refetchOnWindowFocus: false
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error
    };
}

export function useMySaveCards() {
    const query = useQuery({
        queryKey: ["payment-details", "saved-cards"],
        queryFn: async () => httpClient.get(`payments/savedCards`),
        retry: false,
        refetchOnWindowFocus: false
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error
    };
}

export function useMyOrgBalance() {
    const query = useQuery({
        queryKey: ["payment-details", "org-balance"],
        queryFn: async () => httpClient.get(`payments/balance`),
        retry: false,
        refetchOnWindowFocus: false
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error
    };
}

export async function getPaymentStateAsync(paymentId) {
    try {
        return httpClient.get(`payments/${paymentId}/state`);
    } catch (ex) {
        if (ex.status === 400 && ex?.errors?.length > 0) {
            throw new Error(ex.errors[0].errorMessage);
        } else {
            throw new Error(ex.message);
        }
    }
}

export async function payByInvoiceAsync(farmIds, reference) {
    try {
        const content = { farmIds, reference };
        return httpClient.post(`payments/invoice`, content);
    } catch (ex) {
        if (ex.status === 400 && ex?.errors?.length > 0) {
            throw new Error(ex.errors[0].errorMessage);
        } else {
            throw new Error(ex.message);
        }
    }
}

export async function payByPaystationCardAsync(farmIds, reference, paymentMethod, saveCard) {
    try {
        const content = { farmIds, reference, saveCard, paymentMethod };
        return httpClient.post(`payments/payByPaystationCard`, content);
    } catch (ex) {
        if (ex.status === 400 && ex?.errors?.length > 0) {
            throw new Error(ex.errors[0].errorMessage);
        } else {
            throw new Error(ex.message);
        }
    }
}

export async function payByPaystationTokenAsync(farmIds, reference, paymentTokenId) {
    try {
        const content = { farmIds, reference, paymentTokenId };
        return httpClient.post(`payments/payByPaystationToken`, content);
    } catch (ex) {
        if (ex.status === 400 && ex?.errors?.length > 0) {
            throw new Error(ex.errors[0].errorMessage);
        } else {
            throw new Error(ex.message);
        }
    }
}

export async function setRecurringFarmSubscriptionsAsync(farmIds, paymentTokenId) {
    try {
        const content = { farmIds, paymentTokenId };
        return httpClient.post(`payments/recurring`, content);
    } catch (ex) {
        if (ex.status === 400 && ex?.errors?.length > 0) {
            throw new Error(ex.errors[0].errorMessage);
        } else {
            throw new Error(ex.message);
        }
    }
}

export async function sendPaymentAdvice(paymentAdvice) {
    try {
        return httpClient.post(`payments/paymentAdvice`, paymentAdvice);
    } catch (ex) {
        if (ex.status === 400 && ex?.errors?.length > 0) {
            throw new Error(ex.errors[0].errorMessage);
        } else {
            throw new Error(ex.message);
        }
    }
}
