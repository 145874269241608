import React from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

const TagSelector = ({ input, availableTags, onTagAdded, meta }) => {
    const options = availableTags.map((tag) => ({ value: tag, label: tag }));
    const values = options.filter((o) => (input.value || []).includes(o.value));
    const error = meta && meta.touched && meta.error;

    const handleChange = (values, action) => {
        const mappedValues = values ? values.map((v) => v.value) : [];
        if (input.onChange) {
            input.onChange(mappedValues, action);
        }
        const newTag = mappedValues.find((tag) => !availableTags.includes(tag));
        if (newTag && onTagAdded) {
            onTagAdded(newTag);
        }
    };

    return (
        <div className={`Field ${error ? "has-error" : ""}`}>
            <label htmlFor={input.name} className="Select Select--block">
                <CreatableSelect {...input} id={input.name} value={values} onChange={handleChange} options={options} isMulti={true} components={{ ClearIndicator, DropdownIndicator }} closeMenuOnSelect={false} classNamePrefix="ovr_multiSelect" menuShouldScrollIntoView={false} styles={colourStyles} placeholder="Select tags or enter new tag" noOptionsMessage={(props) => "Enter new tag"} />
            </label>
            {error && (
                <small className="Field-error u-mt-xs" id={`${input.name}-error`}>
                    {error}
                </small>
            )}
        </div>
    );
};

const colourStyles = {
    multiValue: (styles) => {
        return {
            ...styles,
            background: "repeating-linear-gradient(-60deg, #4591b9, #4591b9 5px, #549bc0 5px, #549bc0 10px) !important",
            color: "#fff !important",
            padding: "5px 10px !important",
            marginRight: "3px !important",
            marginBottom: "3px !important",
        };
    },
};

const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <i className="icon icon-cross-circle" title="Clear all" />
        </components.ClearIndicator>
    );
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="icon icon-arrow-filled" />
        </components.DropdownIndicator>
    );
};

export default TagSelector;
