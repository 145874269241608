import { ForMoreInfoZendeskLink } from "components/Help";
import Alert from "components/Alert";
import WidgetContainer from "components/WidgetContainer";
import { useFarmGroupsByFarm } from "containers/hooks";
import * as utils from "common/utils";

export default function FarmGroups({ farm, className, height }) {
    const { isLoading, data: farmGroups, error } = useFarmGroupsByFarm(farm?.id);
    const canRespondToFarmGroupInvite = utils.canManageAccess(farm);
    const canViewFarmGroups = utils.canUpdateFarm(farm);

    const warning = farmGroups && (farmGroups.length === 0 || farmGroups.filter((g) => !g.isMember).length > 0);
    const invitePendingWarning = warning && canRespondToFarmGroupInvite && "This farm has not responded to a farm group invite. Click the groups link to see the details of the invitation.";
    const pill = warning ? { level: "warning", text: "Not a member" } : { level: "info", text: "Active" };

    return (
        <WidgetContainer id="groups" title="Farm groups" icon="IconLink--users" className={className} loading={isLoading} error={error} linkTitle={farmGroups && farmGroups.length > 0 && "View all"} linkUrl={farmGroups && farmGroups.length > 0 && `/app/farm/${farm.id}/groups`} height={height} pill={pill}>
            {canViewFarmGroups && farmGroups && farmGroups.length > 0 && (
                <>
                    {invitePendingWarning && <Alert type={"warning"} text={invitePendingWarning} />}
                    <div className="HeroValue u-mt-sm">
                        <div className="HeroValue-item">
                            <h5>Groups</h5>
                            <p className="HeroValue-item-number">{farmGroups.filter((g) => g.isMember).length}</p>
                        </div>
                        <div className="HeroValue-item">
                            <h5>Invites</h5>
                            <p className="HeroValue-item-number">{farmGroups.filter((g) => !g.isMember).length}</p>
                        </div>
                    </div>
                </>
            )}
            {canViewFarmGroups && farmGroups && farmGroups.length === 0 && (
                <div>
                    <div className="Widget-content-title">This farm is not a member of a farm group</div>
                    <p>
                        Farm groups provide a way for farmers, advisors and organisations to group farms enabling aggregated reporting and benchmarking. This provides a mechanism for working collectively to understand and lower nutrient losses into the environment.
                        <ForMoreInfoZendeskLink url="https://support.overseer.org.nz/hc/en-us/articles/900000809586" />
                    </p>
                </div>
            )}
            {!canViewFarmGroups && (
                <div>
                    <div className="Widget-content-title">You do not have access to farm groups for this farm</div>
                    <p>You will need to get access from the owner or an administrator of the farm to see groups.</p>
                </div>
            )}
        </WidgetContainer>
    )
}
