import React from "react";

const CardActionStatus = ({ className, status, statusClassName }) => {
    return (
        <span className="ActionsBar-status">
            <span className={`IconText ${statusClassName}`}>
                <i className={className} /> {status}
            </span>
        </span>
    );
};
export default CardActionStatus;
