import React, { useEffect, useState } from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import { useQueryString } from "common/effects";
import PublicationList from "containers/Dashboard/PublicationList";
import { Panel, PanelBody } from "components/Panel";
import { getPublicationsByYear, getPublicationsByFarmGroup } from "../_actions";
import useDetectPrint from "react-detect-print";

const Publications = ({ refData, publications = [] }) => {
    const queryString = useQueryString();
    const [status, setStatus] = useState(queryString.get("status") || "All");
    const { publicationStatuses = [] } = refData;
    const [myFarmPublications, setMyFarmPublications] = useState([]);
    const printing = useDetectPrint();

    useEffect(() => {
        if (publications.length > 0) {
            setMyFarmPublications(publications);
        }
    }, [publications]);

    const handleToggleMyFarm = (farmId, isMyFarm) => {
        const publication = myFarmPublications.find((p) => p.farmId === farmId);
        if (publication) {
            const modPublication = { ...publication, isMyFarm };
            const modList = [...myFarmPublications.filter((p) => p.farmId !== farmId), ...[modPublication]];
            setMyFarmPublications(modList);
        }
    };

    const statusSelector = (
        <div className="u-flex flex-center">
            <div className="Panel-action-label">Status:</div>
            <div className="Select Select--slim">
                <select id="status" onChange={(e) => setStatus(e.target.value)} value={status}>
                    <option value="All">All</option>
                    {publicationStatuses.map((p) => (
                        <option key={p.value} value={p.value}>
                            {p.text}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );

    const filteredPublications = status === "All" ? myFarmPublications : myFarmPublications.filter((p) => p.status === status);
    const recordCount = filteredPublications.length;

    return (
        <Panel title={`Publications (${recordCount})`} className="skyblue" actions={recordCount > 0 ? statusSelector : null}>
            <PanelBody grey={!printing}>
                {recordCount === 0 ? (
                    <div className="HeroPanel">
                        <div className="Tile-body-message">
                            <h3 className="u-mt-md">There are no results for this period</h3>
                        </div>
                    </div>
                ) : (
                    <PublicationList myPublications={filteredPublications} onToggleMyFarm={handleToggleMyFarm} pageSize={10} />
                )}
            </PanelBody>
        </Panel>
    );
};

export default compose(withAppContext, withActions({ getPublicationsByYear, getPublicationsByFarmGroup }))(Publications);
