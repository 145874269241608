import { useEffect, useState } from "react";
import * as geoJsonUtils from "common/geoJsonUtils";
import { useGoogleApi } from "components/Map/hooks";

export default function MapZoomButton({ mapData, mapId, padding, where, when }) {
    const google = useGoogleApi();
    const [rendered, setRendered] = useState(false);
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (google && mapData && mapId && !rendered) {
            // Add 'Zoom to farm' control (if it doesn't already exist)
            const map = mapData.getMap();
            const position = google.maps.ControlPosition.RIGHT_BOTTOM;
            const controls = map.controls[position].getArray();
            const id = `map-zoom-to-farm-${mapId}`;
            if (!controls.some((ctl) => ctl.id === id)) {
                const div = document.createElement("div");
                div.id = id;
                div.className = "mapButton mapButton--right";
                div.title = "Zoom to farm";
                div.innerHTML = '<i class="icon icon-target"></i>';
                div.addEventListener("click", () => geoJsonUtils.zoomTo(mapData));
                map.controls[position].push(div);
            }

            setRendered(true);
        }
    }, [google, mapData, mapId, rendered]);

    // Default zoom on load and optionally if "when" changes.
    useEffect(() => {
        if (mapData && (when || !runOnce)) {
            geoJsonUtils.zoomTo(mapData, where, padding);
            setRunOnce(true);
        }
    }, [mapData, where, when, padding, runOnce]);

    return null;
}
