import { v4 as uuidv4 } from "uuid";
import * as domain from "common/domain";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import CatchmentDetails from "./CatchmentDetails";

export default function CatchmentCreate({ farm, analysis }) {
    const wetland = domain.factoryWetland();
    wetland.id = uuidv4();

    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.WETLANDS} />
            <TileBody>
                <CatchmentDetails farm={farm} analysis={analysis} wetland={wetland} isNew isUnfenced />
            </TileBody>
        </Tile>
    )
}
