import * as api from "api";
import appActionTypes from "containers/App/_actions";

const actionTypes = {
    CUSTOM_COMPOSITIONS_FETCH_CANCEL: "CUSTOM_COMPOSITIONS_FETCH_CANCEL",
    CUSTOM_COMPOSITIONS_FETCH_SUCCESS: "CUSTOM_COMPOSITIONS_FETCH_SUCCESS",
    CUSTOM_COMPOSITIONS_FETCH_FAILURE: "CUSTOM_COMPOSITIONS_FETCH_FAILURE",
    CUSTOM_COMPOSITIONS_SUBMIT_SUCCESS: "CUSTOM_COMPOSITIONS_SUBMIT_SUCCESS",
    CUSTOM_COMPOSITIONS_DELETE_SUCCESS: "CUSTOM_COMPOSITIONS_DELETE_SUCCESS",
    USERDEFINEDCROPS_SUBMIT_SUCCESS: "USERDEFINEDCROPS_SUBMIT_SUCCESS",
};

export default actionTypes;

export function getCompositions() {
    return api.get({
        path: `account/custom`,
        onBeforeSend: () => {
            return { type: actionTypes.CUSTOM_COMPOSITIONS_FETCH_CANCEL };
        },
        onSuccess: (response) => {
            return { type: actionTypes.CUSTOM_COMPOSITIONS_FETCH_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            error.handled = true;
            return { type: actionTypes.CUSTOM_COMPOSITIONS_FETCH_FAILURE };
        },
    });
}

export function removeComposition(composition) {
    return api.del({
        path: `account/custom/${composition.id}`,
        onSuccess: () => {
            return { type: actionTypes.CUSTOM_COMPOSITIONS_DELETE_SUCCESS, payload: composition };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." });
            };
        },
    });
}

export function submitComposition(composition) {
    return api.post({
        path: `account/custom`,
        content: composition,
        onSuccess: () => {
            return { type: actionTypes.CUSTOM_COMPOSITIONS_SUBMIT_SUCCESS, payload: composition };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                if (error.status === 426) {
                    dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "Custom item modified by another user. Please refresh your browser and try again." });
                } else {
                    dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." });
                }
            };
        },
    });
}

export function updateComposition(composition) {
    return api.put({
        path: `account/custom/${composition.id}`,
        content: composition,
        onSuccess: () => {
            return { type: actionTypes.CUSTOM_COMPOSITIONS_SUBMIT_SUCCESS, payload: composition };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                if (error.status === 426) {
                    dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "Custom item modified by another user. Please refresh your browser and try again." });
                } else {
                    dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." });
                }
            };
        },
    });
}

export function updateUserDefinedCrops(crops) {
    return api.post({
        path: `science/userdefinedcrops`,
        content: { userDefinedCrops: crops },
        onSuccess: () => {
            return { type: actionTypes.USERDEFINEDCROPS_SUBMIT_SUCCESS, payload: crops };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                if (error.status === 426) {
                    dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "Custom crops modified by another user. Please refresh your browser and try again." });
                } else {
                    dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." });
                }
            };
        },
    });
}
