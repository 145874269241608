import { useState } from "react";
import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import * as domain from "common/domain";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import SelectField from "components/FormFields/SelectField";
import SoilProfileSummary from "./SoilProfileSummary";
import { getIsTextureGroupRequired, hasModifiedSoilProfile } from "./_utils";
import { useRefData } from "../../common/hooks";

export default function SoilProfile({ soil, form }) {
    const refData = useRefData();  

    const [editMode, setEditMode] = useState(false);
    const isModified = hasModifiedSoilProfile(soil);
    const isSmapSoil = soil.sibling && soil.sibling.smapReference;
    const isTextureGroupRequired = getIsTextureGroupRequired(soil);
    const isLevel1 = soil.sibling && (soil.sibling.level === 1 || (soil.sibling.level === 0 && soil.sibling.wiltingPoint.top === 0));
    const isLevel2 = soil.sibling && (soil.sibling.level === 2 || (soil.sibling.level === 0 && soil.sibling.wiltingPoint.top > 0));
    const nonStdLayerTip = soil && domain.SoilNonStandLayerTips[soil.parentMaterial];
    const textureGroupTip = soil && domain.SoilTextureTips[soil.textureGroup];

    const toggleEditMode = (toggle) => () => {
        setEditMode(toggle);
        resetSoilProfile(form);
    };

    const panelActions = (
        <div className="u-flexSplit">
            {!editMode && !isTextureGroupRequired && !isModified && (
                <ActionLink id="override-defaults-soil-profile" className="IconLink--edit u-textWhite" onClick={toggleEditMode(true)}>
                    {isSmapSoil ? "Override S-Map defaults" : "Override system defaults"}
                </ActionLink>
            )}
            {editMode && !isTextureGroupRequired && !isModified && (
                <ActionLink id="use-defaults-soil-profile" className="IconLink--cross-circle u-textWhite" onClick={toggleEditMode(false)}>
                    {isSmapSoil ? "Use S-Map defaults" : "Use system defaults"}
                </ActionLink>
            )}
            {isModified && (
                <ActionLink id="restore-defaults-soil-profile" className="IconLink--refresh u-textWhite" onClick={toggleEditMode(false)}>
                    {isSmapSoil ? "Restore S-Map defaults" : "Restore system defaults"}
                </ActionLink>
            )}
        </div>
    );

    const isGroupOrder = !soil.sibling;
    let panelInfo = isGroupOrder && "Soil descriptors (depth to impeded layer, maximum rooting depth and non-standard layers) maybe required when entering soil using group and order.";
    if (isTextureGroupRequired || editMode) {
        panelInfo = "Properties describing the profile are divided between those of topsoil (0-10 cm layer) and those describing the lower profile. This information modifies the soil's water holding capacity and also P runoff risk.";
    }

    return (
        <Panel title="Soil profile" actions={panelActions} info={panelInfo} skyBlue className="u-mt-lg">
            <PanelBody>
                <SoilProfileSummary soil={soil} editMode={editMode} toggleEditMode={toggleEditMode} isModified={isModified} isTextureGroupRequired={isTextureGroupRequired} />
                {(isModified || editMode || isTextureGroupRequired) && (
                    <div className="flexWidget u-mt-md">
                        <div className="flexWidget__body">
                            <Grid>
                                <GridCell className="u-width1of4">
                                    <h3 className="u-text-md">Profile</h3>
                                    <Field name="profileDrainageClass" label="Drainage class" placeholder={soil.sibling && soil.sibling.profileDrainageClass ? `S-Map default: ${utils.valueToText(refData.drainageClasses, soil.sibling.profileDrainageClass)}` : "Use default"} options={refData.drainageClasses.filter((d) => d.value !== "Usedefault")} component={SelectField} />
                                </GridCell>
                                <GridCell className="u-width1of4">
                                    <h3 className="u-text-md">Topsoil (0-10 cm)</h3>
                                    <Field name="topSoilTexture" label="Topsoil texture" placeholder={soil.sibling && soil.sibling.topsoilTexture ? `S-Map default: ${utils.valueToText(refData.soilTextures, soil.sibling.topsoilTexture)}` : "Unknown"} tip="Top soil texture can have a large effect on runoff, particularly for heavier textured soils. The presence of organic matter or cracking can also result in higher infiltration. If this occurs then select a texture class with less clay or silt." options={refData.soilTextures} component={SelectField} />
                                </GridCell>
                                <GridCell className="u-width1of4">
                                    <h3 className="u-text-md">&nbsp;</h3>
                                    {!isLevel2 && (
                                        <Field
                                            name="isStony"
                                            label="Is stony"
                                            placeholder={isLevel1 ? `S-Map default: ${soil.sibling.isStony ? "Yes" : "No"}` : "Not defined"}
                                            options={[
                                                { value: false, text: "No" },
                                                { value: true, text: "Yes" },
                                            ]}
                                            component={SelectField}
                                        />
                                    )}
                                </GridCell>
                            </Grid>
                            <Grid title="Lower profile">
                                <GridCell className="u-width1of4">
                                    <Field name="bottomRootingDepth" label="Max rooting depth" uom="cm" placeholder={soil.sibling && soil.sibling.bottomRootingDepth ? `S-Map default: ${soil.sibling.bottomRootingDepth}` : "No value implies no barrier"} tip="Maximum depth to a layer that may impede root extension. However, water (drainage) can move through this barrier. Entering zero implies there is no barrier." format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                </GridCell>
                                <GridCell className="u-width1of4">
                                    <Field name="impededLayerDepth" label="Impeded layer depth" uom="cm" placeholder={soil.sibling && soil.sibling.impededLayerDepth ? `S-Map default: ${soil.sibling.impededLayerDepth}` : "No value implies no barrier"} tip="Depth at which water (drainage) is impeded. Entering zero implies there is no barrier." format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                </GridCell>
                                {!isLevel2 && (
                                    <>
                                        <GridCell className="u-width1of4">
                                            <Field
                                                name="parentMaterial"
                                                label="Non-standard layer"
                                                placeholder={soil.sibling && soil.sibling.lowerProfileNonStandardLayer ? `S-Map default: ${utils.valueToText(refData.parentMaterials, soil.sibling.lowerProfileNonStandardLayer)}` : "Unknown"}
                                                tip={
                                                    <div>
                                                        This is used to modify the water holding capacities of the soil.{" "}
                                                        {nonStdLayerTip && (
                                                            <p>
                                                                <b>{nonStdLayerTip.bold}</b> - {nonStdLayerTip.text}
                                                            </p>
                                                        )}
                                                    </div>
                                                }
                                                options={refData.parentMaterials}
                                                component={SelectField}
                                            />
                                        </GridCell>
                                        {soil && soil.parentMaterial && soil.parentMaterial !== "Undefined" && (
                                            <GridCell className="u-width1of4">
                                                <Field name="nonStandardLayerDepth" label="Non-standard layer depth" required placeholder="0" uom="cm" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                            </GridCell>
                                        )}
                                    </>
                                )}
                                {isTextureGroupRequired && (
                                    <GridCell className="u-width1of4">
                                        <Field
                                            name="textureGroup"
                                            label="Texture group"
                                            required
                                            placeholder="Select a texture group"
                                            tip={
                                                <div>
                                                    Refers to the fine material (including between the stones) down to 60 cm for pasture, 150 cm for cropping or until a non-standard layer, if present.{" "}
                                                    {textureGroupTip && (
                                                        <p>
                                                            <b>{textureGroupTip.bold}</b> - {textureGroupTip.text}
                                                        </p>
                                                    )}
                                                </div>
                                            }
                                            options={refData.textureGroups}
                                            component={SelectField}
                                        />
                                    </GridCell>
                                )}
                            </Grid>
                        </div>
                    </div>
                )}
            </PanelBody>
        </Panel>
    );
}

export const validateSoilProfileFields = (soil) => {
    const validation = {};
    const isTextureGroupRequired = getIsTextureGroupRequired(soil);
    if (isTextureGroupRequired) {
        validation.textureGroup = FormUtils.validators.required(soil.textureGroup);
    }

    const isModified = hasModifiedSoilProfile(soil);
    if (isModified) {
        if (soil.bottomRootingDepth) {
            validation.bottomRootingDepth = FormUtils.validators.range(1, 999)(soil.bottomRootingDepth);
        }
        if (soil.impededLayerDepth) {
            validation.impededLayerDepth = FormUtils.validators.range(1, 999)(soil.impededLayerDepth);
        }
        if (soil.parentMaterial && soil.parentMaterial !== "Undefined") {
            validation.nonStandardLayerDepth = FormUtils.validators.required(soil.nonStandardLayerDepth);
            validation.nonStandardLayerDepth = validation.nonStandardLayerDepth || FormUtils.validators.range(1, 999)(soil.nonStandardLayerDepth);
        }
    }

    return validation;
};

export const resetSoilProfile = (form) => {
    form.change("profileDrainageClass", undefined);
    form.change("topSoilTexture", undefined);
    form.change("isStony", undefined);
    form.change("bottomRootingDepth", undefined);
    form.change("impededLayerDepth", undefined);
    form.change("parentMaterial", undefined);
    form.change("nonStandardLayerDepth", undefined);
};
