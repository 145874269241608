import { createRef, useEffect, useReducer, useRef, useState } from "react";
import { FORM_ERROR } from "final-form";
import { FieldArray } from "react-final-form-arrays";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import * as utils from "common/utils";
import * as _utils from "containers/Budget/_utils";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import Button from "components/Button/Button";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import SelectField from "components/FormFields/SelectField";
import TextField from "components/FormFields/TextField";
import BlockSelector from "components/BlockSelector/BlockSelector";
import SimplifyAnalysisLogic from "./SimplifyAnalysisLogic";
import { ZendeskLink } from "components/Help";
import { useModal, useRefData } from "common/hooks";
import { useAnalysis, useCreateAnalysisAsync } from "containers/hooks";
import { getComparableBlockSets, simplifyAnalysis } from "./SimplifyAnalysisWorker";

export default function SimplifyAnalysisModal({ farm, analysisId, close }) {
    const { data: analysis } = useAnalysis(farm.id, analysisId);
    const refData = useRefData();
    const createAnalysisAsync = useCreateAnalysisAsync();

    const copyDetailsRef = useRef();
    const [tab, setTab] = useState(0);
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        let isMounted = true;

        function dispatchIfMounted(action) {
            if (isMounted) {
                dispatch(action);
            }
        }

        async function fetchData(analysis) {
            try {
                dispatchIfMounted({ type: "setPreMergeAnalysis", analysis });

                const comparableBlockSets = await getComparableBlockSets(analysis, dispatchIfMounted);
                dispatchIfMounted({ type: "setComparableBlockSets", comparableBlockSets });

                const simplifiedAnalysis = await simplifyAnalysis(analysis, comparableBlockSets, dispatchIfMounted);
                dispatchIfMounted({ type: "setPostMergeAnalysis", analysis: simplifiedAnalysis });
            } catch (e) {
                console.log(e);
            }
        }

        if (analysis) {
            fetchData(analysis);
        }

        return function cleanup() {
            isMounted = false;
        };
    }, [analysis]);

    const validate = (formValues) => {
        const validation = {};

        if (formValues.copyAs === "YearEnd") {
            validation.year = FormUtils.validators.required(formValues.year);
        } else {
            validation.name = FormUtils.validators.required(formValues.name);
            validation.name = validation.name || FormUtils.validators.maxLength(50)(formValues.name);
        }

        return validation;
    };

    const validateComparableBlockSets = (comparableBlockSets) => {
        const validations = [];

        (comparableBlockSets || []).forEach((set) => {
            const validation = {};

            validation.blockIds = FormUtils.validators.required(set.blockIds);
            validation.newBlockName = FormUtils.validators.required(set.newBlockName);
            validation.newBlockName = validation.newBlockName || FormUtils.validators.maxLength(50)(set.newBlockName);

            if (Object.keys(validation).length > 0) validations.push(validation);
        });

        return validations;
    };

    const submitAsync = async (formValues) => {
        const { comparableBlockSets = [] } = formValues;

        const simplifiedAnalysis = simplifyAnalysis(state.analysis.preMerge, comparableBlockSets, dispatch);
        simplifiedAnalysis.type = formValues.copyAs;
        if (formValues.copyAs === "YearEnd") {
            simplifiedAnalysis.year = formValues.year;
        } else {
            simplifiedAnalysis.name = formValues.name;
        }

        await createAnalysisAsync(simplifiedAnalysis)
            .then(close)
            .catch((ex) => ({ [FORM_ERROR]: ex.message }));
    };

    const copyAsOptions = (refData.budgetTypes || []).filter((bt) => bt.value !== "Account");
    const yearOptions = _utils.getYearOptions(farm, refData);
    const farmSoilBlocks = analysis?.farmSoilBlocks || [];

    const info = (
        <div>
            <span>
                Where an analysis has blocks with comparable land use and management practices, those blocks can be merged to simplify the analysis of the farm. On merging, a copy of the analysis is created, so please select the type of analysis and enter a name or select a year. To view a guide on simplifying analyses, <ZendeskLink title="click here" url="https://support.overseer.org.nz/hc/en-us/articles/900000809606" rel="noopener noreferrer" target="_blank" />
            </span>
        </div>
    );

    const viewModel = {
        comparableBlockSets: state.comparableBlockSets,
        copyAs: analysis?.type,
        name: analysis ? `${analysis.name?.substring(0, 37)} (Simplified)` : "",
    };

    return (
        <Form initialValues={viewModel} mutators={arrayMutators} validate={validate} onSubmit={submitAsync}>
            {({ values, handleSubmit, submitting, submitError, dirtySinceLastSubmit, errors }) => {
                const finding = state.findProgress < 100 && !submitting;
                const alreadySimplified = !finding && state.originalBlockCount && state.comparableBlocksCount === 0;
                const canBeSimplified = !finding && state.originalBlockCount && state.comparableBlocksCount > 0;
                const simplifying = canBeSimplified && state.simplifyProgress < 100 && !submitting;
                const disableSubmit = finding || simplifying || alreadySimplified || tab === 1 || submitting;

                const showYear = values.copyAs && values.copyAs === "YearEnd";
                const showName = values.copyAs && values.copyAs !== "YearEnd";

                const newBlocksCount = values.comparableBlockSets.filter((set) => set.blockIds.length > 0).length;
                const selectedBlocksCount = values.comparableBlockSets.reduce((sum, set) => (sum += set.blockIds.length), 0);
                const newTotalBlocksCount = finding ? 0 : state.originalBlockCount - selectedBlocksCount + newBlocksCount;

                const scrollToErrors = () => {
                    let errorRef = undefined;
                    if (errors.year || errors.name) {
                        errorRef = copyDetailsRef;
                    } else if (state.comparableBlockSetRefs) {
                        const index = errors.comparableBlockSets && errors.comparableBlockSets.findIndex((set) => set.blockIds !== undefined || set.newBlockName !== undefined);
                        if (index >= 0) {
                            errorRef = state.comparableBlockSetRefs[index];
                        }
                    }
                    if (errorRef) {
                        errorRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
                    }
                };

                const error = !dirtySinceLastSubmit && submitError;
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`Simplify analysis - ${state.analysis.preMerge ? state.analysis.preMerge.name : analysis?.name}`} close={close} submitting={submitting} wide>
                            <ModalBody info={info} error={error}>
                                {(finding || simplifying) && (
                                    <div>
                                        <div className="Tile-body-message u-mt-lg u-mb-lg">
                                            <div className="Loading">
                                                <div className="Loader">
                                                    <i></i>
                                                    <i></i>
                                                    <i></i>
                                                    <i></i>
                                                    <i></i>
                                                    <i></i>
                                                </div>
                                            </div>
                                            <p className="lead">Step 1: finding comparable blocks</p>
                                            <p className="u-mt-sm">Comparable blocks are blocks with the same land use and management practices</p>
                                            <h2 className="u-textSuccess u-mt-sm">{state.findProgress} %</h2>
                                            {simplifying && (
                                                <>
                                                    <p className="lead">Step 2: pre-processing comparable blocks</p>
                                                    <h2 className="u-textSuccess u-mt-sm">{state.simplifyProgress} %</h2>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {alreadySimplified && (
                                    <div>
                                        <div className="Tile-body-message u-mt-lg u-mb-lg">
                                            <h2 className="u-textSuccess">Congratulations!</h2>
                                            <p className="lead">This analysis is already simplified</p>
                                            <p>This analysis does not have any blocks with comparable land use and management practices</p>
                                        </div>
                                    </div>
                                )}
                                {canBeSimplified && !simplifying && (
                                    <>
                                        <div ref={copyDetailsRef}>
                                            <Grid>
                                                <GridCell className="u-width1of4">
                                                    <Field name="copyAs" label="Copy as" placeholder="Select an analysis type" required options={copyAsOptions} component={SelectField} />
                                                </GridCell>
                                                {showYear && (
                                                    <GridCell className="u-width1of3">
                                                        <Field name="year" label="Year" placeholder="Select a year" options={yearOptions} required component={SelectField} />
                                                    </GridCell>
                                                )}
                                                {showName && (
                                                    <GridCell className="u-width1of3">
                                                        <Field name="name" label="Name" placeholder="Enter a name" required component={TextField} />
                                                    </GridCell>
                                                )}
                                            </Grid>
                                        </div>
                                        <Panel title="Blocks" className="u-mt-lg" skyBlue>
                                            <PanelBody>
                                                <div className="u-flex u-flexJustifyBetween">
                                                    <h2>
                                                        Simplified block count: <span className="u-textSuccess">{newTotalBlocksCount}</span>
                                                    </h2>
                                                    <h2>Original block count: {state.originalBlockCount}</h2>
                                                </div>

                                                <ul className="SubMenu u-mt-md u-mb-md">
                                                    <li className={`SubMenu-item ${tab === 0 ? "is-active" : ""}`}>
                                                        <ActionLink className="h5" onClick={() => setTab(0)}>{`Comparable blocks (${state.comparableBlocksCount} of ${state.originalBlockCount})`}</ActionLink>
                                                    </li>
                                                    <li className={`SubMenu-item ${tab === 1 ? "is-active" : ""}`}>
                                                        <ActionLink className="h5" onClick={() => setTab(1)}>{`Non-comparable blocks (${state.nonComparableBlocks.length} of ${state.originalBlockCount})`}</ActionLink>
                                                    </li>
                                                </ul>
                                                {tab === 0 && (
                                                    <>
                                                        <Alert type="info" text="The following sets of blocks have the same land use and management practices so each set can be merged into a single block. Enter a name for the resulting block and check the list of blocks to be merged. You can add and remove blocks from the list. If the blocks have more than three soils the most dominant will be used. Select the Non-comparable blocks tab (above) to see blocks that do not match. When satisfied, click on create a copy button below to create the new analysis." />
                                                        <div className="Table u-mt-md">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th data-width="25">New block name</th>
                                                                        <th className="th--shrink">New block area</th>
                                                                        <th data-width="15">
                                                                            New block soils <sup>1</sup>
                                                                        </th>
                                                                        <th>Blocks to be merged</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <FieldArray name="comparableBlockSets" validate={validateComparableBlockSets}>
                                                                        {({ fields }) => {
                                                                            return fields.map((field, index) => {
                                                                                const key = fields.value[index].key;
                                                                                const comparableBlockSet = state.comparableBlockSets.find((comparable) => comparable.key === key);
                                                                                const availableBlocks = (state.analysis.preMerge.blocks || []).filter((block) => (comparableBlockSet.blockIds || []).includes(block.id));
                                                                                const selectedBlockIds = fields.value[index].blockIds;
                                                                                const selectedBlocks = availableBlocks.filter((b) => selectedBlockIds.includes(b.id));
                                                                                const newBlockArea = selectedBlocks.reduce((area, block) => (area += block.areaInHectares), 0);

                                                                                const selectedSoilIds = selectedBlocks.reduce((soilIds, block) => {
                                                                                    const blockSoils = farmSoilBlocks.filter((fsb) => fsb.blockId === block.id);
                                                                                    const ids = blockSoils.filter((ob) => !soilIds.includes(ob.soilId)).map((ob) => ob.soilId);
                                                                                    soilIds = soilIds.concat(ids);
                                                                                    return soilIds;
                                                                                }, []);
                                                                                const selectedSoils = state.analysis.preMerge.soils.filter((soil) => selectedSoilIds.includes(soil.id)).map((soil) => ({ id: soil.id, title: utils.getSoilTitle(soil, refData.soilGroups) }));

                                                                                const mergedSoils = SimplifyAnalysisLogic.getMergedSoils(farm, state.analysis.preMerge, selectedBlocks, refData.soilGroups);

                                                                                const mergedSoilIds = mergedSoils.map((s) => s.id);
                                                                                const excludedSoils = selectedSoils.filter((s) => !mergedSoilIds.includes(s.id));

                                                                                return (
                                                                                    <tr key={key} ref={state.comparableBlockSetRefs[index]}>
                                                                                        <td valign="top" className="u-pt-md">
                                                                                            <Field name={`${field}.newBlockName`} component={TextField} />
                                                                                        </td>
                                                                                        <td valign="top">
                                                                                            <p>{utils.round(newBlockArea, 1)}ha</p>
                                                                                        </td>
                                                                                        <td valign="top">
                                                                                            {mergedSoils.length > 0 && (
                                                                                                <>
                                                                                                    <h4 className="u-mt-sm">Merged soils:</h4>
                                                                                                    <ul className="disc">
                                                                                                        {mergedSoils.map((soil) => (
                                                                                                            <li key={soil.id}>
                                                                                                                {soil.title} ({soil.percentage}%)
                                                                                                            </li>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                </>
                                                                                            )}
                                                                                            {excludedSoils.length > 0 && (
                                                                                                <>
                                                                                                    <h4 className="u-mt-lg">Excluded soils:</h4>
                                                                                                    <ul className="disc">
                                                                                                        {excludedSoils.map((soil) => (
                                                                                                            <li key={soil.id}>{soil.title}</li>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                </>
                                                                                            )}
                                                                                        </td>
                                                                                        <td valign="top">
                                                                                            <Field name={`${field}.blockIds`} availableBlocks={availableBlocks} showBlockArea component={BlockSelector} />
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            });
                                                                        }}
                                                                    </FieldArray>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="u-ml-sm">
                                                            <sub style={{ lineHeight: "1rem" }}>1 - A block can have a maximum of 3 soils. The 3 largest soils by area will used for the new block. If there are more than 3 soils then those soils will be excluded.</sub>
                                                        </div>
                                                    </>
                                                )}
                                                {tab === 1 && (
                                                    <>
                                                        {state.nonComparableBlocks.length === 0 ? (
                                                            <Alert type="warning" text="There are no non-comparable blocks" />
                                                        ) : (
                                                            <>
                                                                <Alert type="warning" text="The following blocks do not have any other blocks with the same land use and management practices. You will have to exit this screen and correct the blocks manually if you believe this list is incorrect." />
                                                                <div className="Table u-mt-md">
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Block</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {state.nonComparableBlocks.map((block) => {
                                                                                return (
                                                                                    <tr key={block.id}>
                                                                                        <td valign="top">{block.name}</td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </PanelBody>
                                        </Panel>
                                    </>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="simplify-button" submit primary onClick={scrollToErrors} disabled={disableSubmit}>
                                        Create simplified copy
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useSimplifyAnalysisModal(farm, analysisId) {
    const [modal, openModal] = useModal(SimplifyAnalysisModal);

    const openSimplifyAnalysisModal = () => {
        const modalProps = {
            farm,
            analysisId,
        };
        openModal(modalProps);
    };

    return [modal, openSimplifyAnalysisModal];
}

const initialState = {
    findProgressCounter: 0,
    findProgress: 0,
    simplifyProgressCounter: 0,
    simplifyProgress: 0,
    analysis: {},
    comparableBlockSets: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case "setPreMergeAnalysis": {
            const { analysis } = action;
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    preMerge: analysis,
                },
            };
        }
        case "setPostMergeAnalysis": {
            const { analysis } = action;
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    postMerge: analysis,
                },
            };
        }
        case "setComparableBlockSets": {
            const { comparableBlockSets } = action;
            const comparableBlocksCount = comparableBlockSets.reduce((sum, set) => (sum += set.blockIds.length), 0);
            const nonComparableBlocks = state.analysis.preMerge.blocks.filter((block) => !comparableBlockSets.some((set) => set.key === block.id || set.blockIds.includes(block.id)));

            //const keys = comparableBlockSets.map(set => set.key);
            const comparableBlockSetRefs = comparableBlockSets.reduce((acc, set, index) => {
                acc[index] = createRef();
                return acc;
            }, []);

            return {
                ...state,
                originalBlockCount: state.analysis.preMerge.blocks.length,
                findProgress: 100,
                comparableBlockSets,
                comparableBlocksCount,
                nonComparableBlocks,
                comparableBlockSetRefs,
            };
        }
        case "incrementFindProgress": {
            const findProgressCounter = state.findProgressCounter + 1;
            const findProgress = utils.round((findProgressCounter / state.analysis.preMerge.blocks.length) * 100, 0);
            return {
                ...state,
                findProgressCounter,
                findProgress: findProgress > 99 ? 99 : findProgress,
            };
        }
        case "incrementSimplifyProgress": {
            const simplifyProgressCounter = state.simplifyProgressCounter + 1;
            const simplifyProgress = utils.round((simplifyProgressCounter / state.comparableBlockSets.length) * 100, 0);
            return {
                ...state,
                simplifyProgressCounter,
                simplifyProgress,
            };
        }
        default: {
            return state;
        }
    }
};
