import { reducer as reduxFormReducer } from "redux-form";
import { matchPath } from "react-router-dom";
import authReducer from "containers/Auth/_reducer";
import farmSearchReducer from "containers/FarmSearch/_reducer";
import overviewReducer from "containers/BudgetHome/Overview/_reducer";
import userManagementReducer from "containers/AccountDetails/_reducer";
import datasetReducer from "containers/Datasets/_reducer";
import cropsReducer from "containers/BudgetHome/Crops/_reducer";
import authActions from "containers/Auth/_actions";
import actionTypes from "./_actions";
import customCompositionsReducer from "containers/Custom/_reducer";
import adminReducer from "containers/Admin/_reducer";
import myOrgReducer from "containers/MyOrg/_reducer";
import { connectRouter, LOCATION_CHANGE } from "connected-react-router";

const INITIAL_STATE = {
    alert: {
        active: false,
        type: null,
        message: null,
    },
    modal: {
        modalType: null,
        onConfirm: null,
        props: null,
    },
    signalRConn: false,
    showQuickTips: false,
    outcome: null,
    online: true,
    refData: {},
    isSaving: false,
    loaders: [],
    isLoading: false,
    isModalLoading: false,
    formState: {},
};

function rootReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "APP_SIGNALR_CONN_CREATED": {
            return { ...state, signalRConn: action.payload };
        }
        case "EMAIL_ID": {
            return {
                ...state,
                emailId: action.payload,
                emailTime: Date.now(),
            };
        }
        case "EMAIL_ID_CLEAR": {
            return {
                ...state,
                emailId: false,
                emailTime: false,
            };
        }
        case actionTypes.APP_SAVING: {
            return {
                ...state,
                isSaving: action.payload,
            };
        }
        case actionTypes.APP_VERSION_INVALID_COUNT: {
            return {
                ...state,
                invalidVersionCount: action.payload,
            };
        }
        case actionTypes.APP_OFFLINE_COUNT: {
            return {
                ...state,
                offlineCount: action.payload,
            };
        }
        case actionTypes.APP_VERSION:
            return {
                ...state,
                version: action.payload,
            };
        case actionTypes.APP_LOADING_BEGIN: {
            if (!state.online) return state;

            let loaders = [];
            if (!state.loaders.some((l) => l === action.payload)) {
                loaders.push(action.payload);
            }

            const modalIsOpen = state.modal.modalType !== null;
            const newState = {
                ...state,
                loaders: state.loaders.concat(loaders),
            };
            newState.isLoading = !modalIsOpen && newState.loaders.length > 0;
            newState.isModalLoading = modalIsOpen && newState.loaders.length > 0;
            return newState;
        }

        case actionTypes.APP_LOADING_END: {
            const modalIsOpen = state.modal.modalType !== null;
            const newState = {
                ...state,
                loaders: state.loaders.filter((l) => l !== action.payload),
            };
            newState.isLoading = !modalIsOpen && newState.loaders.length > 0;
            newState.isModalLoading = modalIsOpen && newState.loaders.length > 0;
            return newState;
        }

        case LOCATION_CHANGE: {
            const formState = { ...state.formState };
            const isFarmHome = matchPath(action.payload.location.pathname, { path: "/app/farm/:farmId", exact: true });
            if (isFarmHome) {
                // Reset any budget form state keys
                Object.keys(formState).forEach((key) => {
                    if (key.startsWith("budget.")) formState[key] = null;
                });
            }

            return {
                ...state,
                formState,
                loaders: [],
                isLoading: false,
                isSaving: false,
            };
        }

        case actionTypes.APP_TOGGLE_HELP:
            return {
                ...state,
                showQuickTips: !state.showQuickTips,
            };
        case actionTypes.APP_HELP_OFF:
            return {
                ...state,
                showQuickTips: false,
            };

        case actionTypes.APP_OFFLINE:
            return {
                ...state,
                online: false,
            };
        case actionTypes.APP_ONLINE:
            return {
                ...state,
                online: true,
            };
        case actionTypes.APP_REF_DATA_FETCH_SUCCESS:
            return {
                ...state,
                refData: action.payload,
            };
        case actionTypes.APP_MODAL_INLINE_OPEN:
            return {
                ...state,
                modal: {
                    modalType: "inline",
                    onConfirm: null,
                    props: null,
                },
                loaders: [],
                isLoading: false,
                isSaving: false,
            };
        case actionTypes.APP_MODAL_INLINE_CLOSE:
            return {
                ...state,
                modal: {
                    modalType: null,
                    onConfirm: null,
                    props: null,
                },
                isSaving: false,
            };
        case actionTypes.APP_CLOSE_CONFIRM:
            return {
                ...state,
                modalConfirm: {
                    open: false,
                    message: null,
                    onConfirm: null,
                },
                isSaving: false,
            };
        case actionTypes.APP_OPEN_MODAL:
            return {
                ...state,
                modalError: null,
                modal: {
                    ...action.payload,
                },
                loaders: [],
                isLoading: false,
                isSaving: false,
            };
        case actionTypes.APP_CLOSE_MODAL:
            return {
                ...state,
                modalError: null,
                modal: {
                    modalType: null,
                    onConfirm: null,
                    props: null,
                },
                isSaving: false,
            };
        case actionTypes.APP_MODAL_ERROR:
            return {
                ...state,
                modalError: action.payload,
            };
        case actionTypes.APP_MODAL_ERROR_REPEATABLE:
            return {
                ...state,
                modalError: action.payload,
                isSaving: false,
            };
        case actionTypes.APP_MODAL_CLEAR_ERROR:
            return {
                ...state,
                modalError: null,
            };
        case actionTypes.APP_SUBMISSION_OUTCOME:
            return {
                ...state,
                outcome: action.payload,
                isSaving: false,
            };
        case actionTypes.APP_SUBMISSION_CANCEL:
            return {
                ...state,
                outcome: null,
            };
        case actionTypes.APP_FORM_STATE:
            return {
                ...state,
                formState: {
                    ...state.formState,
                    [action.payload.name]: action.payload.value,
                },
            };
        case authActions.AUTH_LOGOUT:
            return {
                ...INITIAL_STATE,
                emailId: state.emailId,
                emailTime: state.emailTime,
            };
        default:
            return state;
    }
}

const createRootReducer = (history) => ({
    router: connectRouter(history),
    app: rootReducer,
    auth: authReducer,
    searchResults: cropsReducer,
    farmSearch: farmSearchReducer,
    reportSupplements: overviewReducer,
    dataset: datasetReducer,
    form: reduxFormReducer,
    userManagement: userManagementReducer,
    customNutrients: customCompositionsReducer,
    admin: adminReducer,
    myOrg: myOrgReducer,
});

export default createRootReducer;
