import * as utils from "common/utils";

export default function SmapSiblingSummary({ soil, refData }) {
    const { sibling = {} } = soil;
    const { drainageClasses = [], soilGroups = [] } = refData;

    return (
        <>
            <h2 className="u-mt-md">{sibling.smapReference}</h2>
            <h3 className="h5 u-text-md u-mt-xs u-mb-0">
                S-Map defaults <sup>*</sup>
            </h3>
            <ul className="DataWidthTable FarmTable">
                {soil.soilOrder && (
                    <li data-width="xl-25">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Soil order</span>
                                <span className="FarmTable-value">{soil.soilOrder}</span>
                            </div>
                        </div>
                    </li>
                )}
                {soil.soilGroup && (
                    <li data-width="xl-25">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Soil group</span>
                                <span className="FarmTable-value">{utils.valueToText(soilGroups, soil.soilGroup)}</span>
                            </div>
                        </div>
                    </li>
                )}
                {sibling.description && (
                    <li data-width="xl-50">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Description</span>
                                <span className="FarmTable-value">{sibling.description}</span>
                            </div>
                        </div>
                    </li>
                )}
            </ul>
            <ul className="DataWidthTable FarmTable">
                {sibling.profileDrainageClass && (
                    <li data-width="xl-25">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Drainage class</span>
                                <span className="FarmTable-value">{utils.valueToText(drainageClasses, sibling.profileDrainageClass)}</span>
                            </div>
                        </div>
                    </li>
                )}
                {sibling.level === 2 && (
                    <>
                        {sibling.wiltingPoint && (
                            <li data-width="xl-25">
                                <div className="FarmTable-keyValuePair">
                                    <div className="FarmTable-keyValue">
                                        <span className="FarmTable-key">Wilting point</span>
                                        <span className="FarmTable-value">
                                            <div>Top: {sibling.wiltingPoint.top}mm/10 cm</div>
                                            <div>Middle: {sibling.wiltingPoint.middle}mm/10 cm</div>
                                            <div>Bottom: {sibling.wiltingPoint.bottom}mm/10 cm</div>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        )}
                        {sibling.fieldCapacity && (
                            <li data-width="xl-25">
                                <div className="FarmTable-keyValuePair">
                                    <div className="FarmTable-keyValue">
                                        <span className="FarmTable-key">Field capacity</span>
                                        <span className="FarmTable-value">
                                            <div>Top: {sibling.fieldCapacity.top}mm/10 cm</div>
                                            <div>Middle: {sibling.fieldCapacity.middle}mm/10 cm</div>
                                            <div>Bottom: {sibling.fieldCapacity.bottom}mm/10 cm</div>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        )}
                        {sibling.saturation && (
                            <li data-width="xl-25">
                                <div className="FarmTable-keyValuePair">
                                    <div className="FarmTable-keyValue">
                                        <span className="FarmTable-key">Saturation</span>
                                        <span className="FarmTable-value">
                                            <div>Top: {sibling.saturation.top}mm/10 cm</div>
                                            <div>Middle: {sibling.saturation.middle}mm/10 cm</div>
                                            <div>Bottom: {sibling.saturation.bottom}mm/10 cm</div>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        )}
                    </>
                )}
            </ul>
            <div className="small u-mt-sm">
                <sup>*</sup> S-Map soil data reproduced with the permission of Manaaki Whenua - Landcare Research
            </div>
        </>
    );
}
