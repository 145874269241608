import { Link } from "react-router-dom";
import ActionLink from "components/ActionLink";
import * as _utils from "./_utils";
import { ZendeskLink } from "components/Help";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";
import { useRefData } from "common/hooks";
import { useCreateEnterpriseModal } from "containers/BudgetHome/Animals/Enterprise/CreateEnterpriseModal";

export default function AnimalsActionBar({ farm, analysis }) {
    const refData = useRefData();
    const [createEnterpriseModal, openCreateEnterpriseModal] = useCreateEnterpriseModal(farm, analysis);

    const options = _utils.getEnterpriseTypeOptions(analysis, refData);
    const hasDairy = options.some((o) => o.value === "Dairy" && o.disabled);
    const canDistributeAnimals = (analysis.enterprises || []).length > 0 && analysis.blocks.some((block) => _utils.canDistributeAnimals(block));
    const canAddEnterprise = options.filter((o) => !o.disabled).length > 0;

    return (
        <>
            <div className="ActionsBar ActionsBar--super u-print-none">
                <div className="ActionsBar-left">
                    <ul className="ActionsBar-links">
                        {canDistributeAnimals && (
                            <li>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/animals/distribution`} className={`IconLink--settings${canDistributeAnimals === false ? " is-disabled" : ""}`} id="edit-distribution-link">
                                    Animal distribution
                                </Link>
                            </li>
                        )}
                        {options
                            .filter((o) => o.disabled && (o.value !== "DairyReplacements" || hasDairy))
                            .map((option) => {
                                return (
                                    <li key={option.value}>
                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/animals/enterprises/${option.value.toLowerCase()}`} className="IconLink--settings" id={`manage-${option.value}`}>
                                            {option.text.replace("- ", "")}
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                {canAddEnterprise && (
                    <div className="ActionsBar-right">
                        <ul className="ActionsBar-links">
                            <li>
                                <ActionLink onClick={openCreateEnterpriseModal} className="IconLink--arrow-plus" id="add-enterprise-link">
                                    Add enterprise
                                </ActionLink>
                            </li>
                            {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                                <li>
                                    <ScenarioToolLink farm={farm} analysis={analysis} />
                                </li>
                            )}
                            <li>
                                <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000978243" className="IconLink--new-tab u-font-green" />
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            {createEnterpriseModal}
        </>
    )
}
