import { Fragment } from "react";
import Watermark from "components/Watermark";
import { effluentSystemViewModel, structuresViewModel } from "common/viewModels";
import * as domain from "common/domain";
import * as utils from "common/utils";
import { useRefData } from "common/hooks";

export default function FarmStuctures({ analysis }) {
    const refData = useRefData();
    const { structures = [], effluentSystem } = analysis;
    const structuresVm = structuresViewModel(analysis, structures, refData);

    const renderEffluentSystem = (vm, i) => {
        let label = `${vm.name} - ${vm.managementSystem}`;
        if (vm.enterprise && vm.enterprise.enterpriseType.value === "OutdoorPigs") label = `${vm.name}`;
        else if (vm.managementSystem !== "Exported") label = `${vm.name} - ${vm.managementSystem} | Solids: ${vm.solidsManagement} | Pond: ${vm.pondSolidsManagement} | Liquid: ${vm.liquidManagement}`;

        const pigIcon = utils.getAnimalIcon("OutdoorPigs");

        return (
            <Fragment key={i}>
                <tr>
                    <th colSpan={13} className="td--keyValuePair u-textCapitalise u-textBold">
                        <span className="u-flex u-flexAlignItemsCenter">
                            {vm.icon && <img src={vm.icon} className="u-mr-xs u-p-5" width="35" height="35" alt="" />}
                            <span className="FarmTable-value">
                                <span>{label}</span>
                            </span>
                        </span>
                    </th>
                </tr>
                {vm.enterprise && !["OutdoorPigHuts", "OutdoorPigFarrowingVillages"].includes(vm.structure.type) && (
                    <>
                        <tr>
                            {vm.structure.type !== "OutdoorPigBarns" && <td className="u-textBold u-textLower">{`% of ${vm.enterprise.enterpriseType.text} animals`}</td>}
                            {vm.structure.type === "OutdoorPigBarns" && <td className="u-textBold">{`${vm.enterprise.enterpriseType.text}`}</td>}
                            {domain.farmYear.map((month, i) => {
                                const monthActivity = vm.enterprise.animals.find((a) => a.months && a.months.includes(month));

                                if (vm.structure.type === "OutdoorPigBarns") {
                                    const total = Object.keys(vm.structure.pigBarnPlacements).reduce((monthlyTotal, key) => {
                                        const placement = vm.structure.pigBarnPlacements[key].find((p) => p.month === month);
                                        if (placement) monthlyTotal += placement.amount;
                                        return monthlyTotal;
                                    }, 0);

                                    if (total === 0)
                                        return (
                                            <td key={month} className="u-textCenter">
                                                <span>-</span>
                                            </td>
                                        );
                                    else
                                        return (
                                            <td key={month} className="u-textCenter">
                                                <img className="Calendar-icon u-p-xxs" src={pigIcon} width={35} height={35} alt="Outdoor pigs" />
                                            </td>
                                        );
                                } else {
                                    return monthActivity ? (
                                        <td key={month} className="u-textCenter">
                                            <span>{`${monthActivity.percentageOfAnimals}`}</span>
                                        </td>
                                    ) : (
                                        <td key={month} className="u-textCenter">
                                            <span>-</span>
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                        {!["MilkingShed", "OutdoorPigBarns"].includes(vm.structure.type) && (
                            <tr>
                                <td className="u-textBold">Hours on structure per day</td>

                                {domain.farmYear.map((month, i) => {
                                    const monthActivity = vm.enterprise.animals.find((a) => a.months && a.months.includes(month));

                                    return monthActivity ? (
                                        <td key={month} className="u-textCenter">
                                            <span>{`${monthActivity.time}`}</span>
                                        </td>
                                    ) : (
                                        <td key={month} className="u-textCenter">
                                            <span>-</span>
                                        </td>
                                    );
                                })}
                            </tr>
                        )}
                    </>
                )}
            </Fragment>
        );
    };

    return (
        <>
            {!effluentSystem && structures.length === 0 ? (
                "No structures exist."
            ) : (
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="30">Name</th>
                                {domain.farmYear.map((month, index) => (
                                    <th key={month} className={`Calendar-month`}>
                                        {month.substring(0, 3)}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {structuresVm.map((vm, i) => renderEffluentSystem(vm, i))}
                            {effluentSystem && renderEffluentSystem(effluentSystemViewModel(effluentSystem, refData, "Dairy effluent system"))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}
