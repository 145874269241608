import TileSubPanel from "components/TileSubPanel";
import BlockListCard from "./BlockListCard";
import * as domain from "common/domain";
import { getBlockList } from "common/utils";

export default function BlockList({ farm, analysis }) {
    const blockList = getBlockList(analysis.blocks);
    const fodderCropBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.FodderCrop);
    const cropBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductiveCrop);
    const fruitBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductiveFruit);
    const pastureBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductivePasture);
    const outdoorPigBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductiveOutdoorPigs);
    const otherBlocks = blockList.nonProductiveBlocks;

    return (
        <div>
            {pastureBlocks.length > 0 && (
                <TileSubPanel title="Pasture blocks" stickyHeaderName="analysis.animals.pastureBlocks.collapsed">
                    {pastureBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
            {cropBlocks.length > 0 && (
                <TileSubPanel title="Crop blocks" stickyHeaderName="analysis.animals.cropBlocks.collapsed">
                    {cropBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
            {fruitBlocks.length > 0 && (
                <TileSubPanel title="Fruit blocks" stickyHeaderName="analysis.animals.fruitBlocks.collapsed">
                    {fruitBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
            {fodderCropBlocks.length > 0 && (
                <TileSubPanel title="Fodder crop rotations" stickyHeaderName="analysis.animals.fodderCropRotations.collapsed">
                    {fodderCropBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
            {outdoorPigBlocks.length > 0 && (
                <TileSubPanel title="Outdoor pigs" stickyHeaderName="analysis.animals.outdoorPigs.collapsed">
                    {outdoorPigBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
            {otherBlocks.length > 0 && (
                <TileSubPanel title="Other blocks" stickyHeaderName="analysis.animals.otherBlocks.collapsed">
                    {otherBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
        </div>
    )
}
