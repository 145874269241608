import React from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import { Link, Switch } from "react-router-dom";
import { Panel, PanelBody } from "components/Panel";
import ScrollTopRoute from "components/ScrollTopRoute";
import Tabs from "components/new/Tabs";
import TabsItem from "components/new/TabsItem";
import FarmContacts from "./FarmContacts";
import FarmPayments from "./FarmPayments";
import { getFarmSummaryAsync } from "./_actions";

const FarmSummary = ({ farmId, getFarmSummaryAsync }) => {
    const [farmSummary, setFarmSummary] = React.useState();

    React.useEffect(() => {
        const fetchData = async (farmId) => {
            const response = await getFarmSummaryAsync(farmId);
            setFarmSummary(response);
        };

        if (!farmSummary) {
            fetchData(farmId);
        }
    }, [farmId, farmSummary, getFarmSummaryAsync]);

    const tabs = {
        contacts: { title: "Contacts", path: `/app/admin/farms/${farmId}`, exact: true },
        payments: { title: "Payment transactions", path: `/app/admin/farms/${farmId}/payments`, exact: true },
    };

    return (
        <Panel title="Farm summary" loading={!farmSummary} referrer="/app/admin/search">
            <PanelBody>
                {farmSummary && (
                    <div className="u-mb-md">
                        <div>
                            <Link id={`farm_summary_${farmSummary.farmId}`} to={`/app/farm/${farmSummary.farmId}`} title="Go to farm home" className="u-textBold u-text-xl">
                                {farmSummary.farmName}
                            </Link>
                        </div>
                        <div className="u-text-md">{farmSummary.farmAddress}</div>
                    </div>
                )}
                <Tabs>
                    {Object.keys(tabs).map((key) => (
                        <TabsItem key={key} id={key} title={tabs[key].title} to={tabs[key].path} exact={tabs[key].exact} />
                    ))}
                </Tabs>
                <Switch>
                    <ScrollTopRoute exact path={tabs.contacts.path} render={(_) => <FarmContacts farm={farmSummary} />} />
                    <ScrollTopRoute exact path={tabs.payments.path} render={(_) => <FarmPayments farmId={farmId} />} />
                </Switch>
            </PanelBody>
        </Panel>
    );
};

export default compose(withAppContext, withActions({ getFarmSummaryAsync }))(FarmSummary);
