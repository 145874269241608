import "./Analytics.css";
import UserSessionsWidget from "./UserSessionsWidget";
import FeatureUsageWidget from "./FeatureUsageWidget";

export default function Analytics() {
    return (
        <div className="dashboard u-mt-sm">
            <UserSessionsWidget />
            <FeatureUsageWidget />
        </div>
    );
}
