import Spinner from "components/Spinner/Spinner";

export default function Button({ id, onClick, submit, primary, secondary, secondaryGrey, tertiary, disabled, waiting, children, iconBefore, iconAfter, className, name }) {
    const type = submit ? "submit" : "button";

    let classNames = "Button " + (className ? className : "");
    if (primary) classNames += " Button--primary";
    else if (secondary) classNames += " Button--secondary";
    else if (secondaryGrey) classNames += " Button--secondary-grey";
    else if (tertiary) classNames += " Button--tertiary";

    const dark = secondary && waiting;

    return (
        <button id={id} type={type} className={classNames} onClick={onClick} disabled={disabled || waiting} name={name}>
            {waiting && <Spinner small dark={dark} />}
            {iconBefore && <i className={`icon icon--before ${iconBefore}`} />}
            {children}
            {iconAfter && <i className={`icon icon--after ${iconAfter}`} />}
        </button>
    );
}
