import * as api from "api";

// Get all Analysis GeoJsons for a farm
export const getFarmAnalysesWithGeoJsonAsync = {
    send: (farmId) => (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                api.get({
                    path: `farms/${farmId}/analyses/geojson`,
                    timeout: 120000,
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("Error loading map data");
                        return [];
                    },
                    cancelActionType: "abort_getFarmAnalysesWithGeoJsonAsync",
                })
            );
        });
    },
    abort: () => (dispatch) => dispatch({ type: "abort_getFarmAnalysesWithGeoJsonAsync" }),
}
