import React from "react";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import Page from "components/Page";
import PageBody from "components/PageBody";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import Loader from "components/Loader";
import TileActionsBar from "components/TileActionsBar";
import StudentListExpired from "./StudentListExpired";
import InvitationList from "./InvitationList";
import { getStudents, getAccount } from "./_actions";
import moment from "moment";
import TileSubPanel from "components/TileSubPanel";
import * as icons from "common/icons";
import StudentListRegistered from "./StudentListRegistered";
import { modal } from "containers/App/_actions";
import ActionLink from "components/ActionLink";

class StudentManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubsOpen: true,
            isUsersOpen: true,
            isInvitesOpen: true,
        };
    }

    _launchInviteModal =
        (registeredStudents = [], invitations = [], invitation) =>
        (e) => {
            e.preventDefault();
            if (!invitation) {
                invitation = { id: uuidv4() };
            }
            invitation.accountId = this.props.accountId;
            invitation.accountName = this.props.accountName;
            invitation.expires = moment.utc().add(90, "days").startOf("day");
            invitation.target = "Organisation";
            const viewModel = { invitation, students: this.props.students, invitations };
            this.props.modal("InviteStudentModal", null, viewModel);
        };

    componentDidMount() {
        this.props.getStudents();
        //For invites
        this.props.getAccount();
    }

    render() {
        const { online, accountName, isLoading, students = [], invitations = [], userId } = this.props;
        const registeredStudents = students.filter((s) => moment(s.expires).isAfter(moment().valueOf()));
        const expiredStudents = students.filter((s) => moment(s.expires).isBefore(moment().valueOf()));
        const noStudentsOrInvites = students.length === 0 && (invitations === null || invitations.length === 0);
        return (
            <Page>
                <section className="PageHead">
                    <div className="Container">
                        <div className="FarmTitle">
                            <div className="FarmTitle-heading">
                                <span className="FarmTitle-name">{accountName}</span>
                            </div>
                        </div>
                    </div>
                </section>
                <PageBody>
                    {isLoading && <Loader message="Loading..." />}
                    <Tile title="Student list" referrer="/">
                        <TileBody>
                            {!noStudentsOrInvites && online && (
                                <TileActionsBar>
                                    <ul className="ActionsBar-links">
                                        <li>
                                            <ActionLink id="add-user" className="IconLink--arrow-plus u-link" onClick={this._launchInviteModal(registeredStudents, invitations)}>
                                                Invite students
                                            </ActionLink>
                                        </li>
                                    </ul>
                                </TileActionsBar>
                            )}
                            {registeredStudents.length > 0 && <StudentListRegistered online={online} accounts={registeredStudents} userId={userId} title="Registered students" showDelete={true} />}
                            {invitations.length > 0 && <InvitationList online={online} invitations={invitations} registeredStudents={registeredStudents} resend={(invitation) => this._launchInviteModal(undefined, undefined, invitation)} title="Student invitations" showStudentFields={true} />}
                            {expiredStudents.length > 0 && <StudentListExpired online={online} accounts={expiredStudents} userId={userId} title="Expired students" showDelete={false} />}
                            {noStudentsOrInvites && (
                                <TileSubPanel title="Students" green>
                                    <div className="FarmTable_wrapper">
                                        <div className="FarmTable-supplementary">
                                            <div className="Tile-body">
                                                <div className="Tile-body-message">
                                                    <img src={icons.add} alt="Plus" />
                                                    <p className="lead">No students have been invited</p>
                                                    <p>Click the button below to get started</p>
                                                    <ActionLink className="IconLink--arrow-plus Button Button--lg u-mt-lg" onClick={this._launchInviteModal(registeredStudents, invitations)}>
                                                        Invite students
                                                    </ActionLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TileSubPanel>
                            )}
                        </TileBody>
                    </Tile>
                </PageBody>
            </Page>
        );
    }
}

const _mapStateToProps = (state, ownProps) => {
    return {
        online: state.app.online,
        isLoading: state.app.isLoading,
        accountName: state.auth.accountName,
        accountId: state.auth.accountId,
        userId: state.auth.userId,
        users: state.userManagement.users,
        students: state.userManagement.students,
        subscriptions: state.userManagement.subscriptions,
        invitations: state.userManagement.studentInvitations ? state.userManagement.studentInvitations.filter((i) => i.status !== "Accepted") : [],
    };
};

export default connect(_mapStateToProps, { getStudents, getAccount, modal })(StudentManagement);
