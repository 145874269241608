import { useParams } from "react-router-dom";
import { httpClient } from "common/httpClient";
import { useQueryString } from "common/effects";
import { useEffect, useState } from "react";
import Spinner from "components/Spinner/Spinner";
import { Panel, PanelBody } from "components/Panel";
import * as icons from "common/icons";

export default function FarmAccessRequest() {
    const { accessRequestId } = useParams();
    const queryString = useQueryString();
    const [action] = useState(queryString.get("action"));
    const [userId] = useState(queryString.get("userId"));
    const [accountId] = useState(queryString.get("accountId"));
    const [farmId] = useState(queryString.get("farmId"));
    const [accessKey] = useState(queryString.get("accessKey"));
    const [status, setStatus] = useState();

    useEffect(() => {
        const processRequest = async () => {
            try {
                switch (action) {
                    case "accept":
                        await httpClient.post(`access/farms/${farmId}/requests/${accessRequestId}/accept`, { farmId, accessRequestId, userId, accountId, accessKey });
                        setStatus({ message: "If you have accepted this request by mistake, please log in to OverseerFM to manage your farm access.", icon: icons.success, heading: "Accepted access request" });
                        break;
                    case "decline":
                        await httpClient.post(`access/farms/${farmId}/requests/${accessRequestId}/decline`, { farmId, accessRequestId, userId, accountId, accessKey });
                        setStatus({ message: "If you have declined this request by mistake, please log in to OverseerFM to manage your farm access.", icon: icons.success, heading: "Declined access request" });
                        break;
                    default:
                        setStatus({ message: "Please log in to OverseerFM and process the farm access request from the dashboard.", icon: icons.error, heading: "Unexpected error occurred" });
                        break;
                }
            } catch (error) {
                switch (error?.status) {
                    case 401:
                        setStatus({ message: "Please log in to OverseerFM to review access.", icon: icons.error, heading: "Unauthorised error occurred" });
                        break;
                    case 404:
                        setStatus({ message: "Please log in to OverseerFM to review access.", icon: icons.error, heading: "Request not found" });
                        break;
                    default:
                        setStatus({ message: "Please log in to OverseerFM to review access.", icon: icons.info, heading: "Request already actioned" });
                        break;
                }
                console.log("Request Error: ", error);
            }
        };
        if (action && userId && accountId && farmId && accessKey) {
            processRequest();
        }
    }, [accessKey, accessRequestId, accountId, action, farmId, userId]);

    return (
        <Panel title="Farm access" notCollapsible={true}>
            <PanelBody>
                {status ? (
                    <StatusMessage status={status} />
                ) : (
                    <div className="Tile-body-message">
                        <Spinner dark />
                        <p className="lead">Processing ...</p>
                    </div>
                )}
            </PanelBody>
        </Panel>
    );
}

const StatusMessage = ({ status }) => {
    return (
        <div className="Tile-body-message">
            <img src={status.icon} alt="Plus" />
            <div className="h2 u-mt-md">{status.heading}</div>
            <p className="u-mt-sm">{status.message}</p>
        </div>
    );
};
