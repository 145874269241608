import * as api from "api";

export const getUserSessions = (minutes) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                path: `admin/userSessions/${minutes}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getFeatureUsage = (minutes) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                path: `admin/featureUsage/${minutes}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};
