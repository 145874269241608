import React from "react";
import { connect } from "react-redux";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import { useConfirm } from "common/hooks";
import { removeUser, toggleAdminForUser } from "./_actions";
import ActionLink from "components/ActionLink";

const UserList = ({ online, users, userId, removeUser, toggleAdminForUser }) => {
    const confirm = useConfirm();

    const _confirmRemove = (id) => {
        removeUser(id);
    };

    const _removeUser = (user) => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to remove ${user.fullName} from your organisation?`, () => _confirmRemove(user.id));
    };

    return (
        <Tile title="Users in this organisation" secondary collapsible>
            <TileBody>
                <div className="Table">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th className="th--shrink">Is Admin?</th>
                                {online && <th className="th--shrink"></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => {
                                return (
                                    <tr key={user.id} className={userId === user.id ? "is-selected" : ""}>
                                        <td>{user.fullName}</td>
                                        <td>{user.email}</td>
                                        <td className="u-textCenter">
                                            {userId !== user.id && (
                                                <label className="Checkbox" htmlFor={"users-isadmin-select-" + user.id}>
                                                    <input className="Checkbox-input" type="checkbox" id={"users-isadmin-select-" + user.id} checked={user.isAdmin} onChange={(e) => toggleAdminForUser(user.id)} />
                                                    <span className="Checkbox-label"></span>
                                                </label>
                                            )}
                                            {userId === user.id && "Y"}
                                        </td>
                                        {online && (
                                            <td className="u-textCenter">
                                                {userId !== user.id && (
                                                    <ActionLink id={`remove-usere-${user.id}`} className="IconLink--cross-circle" onClick={_removeUser(user)} title="Remove">
                                                        <span></span>
                                                    </ActionLink>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </TileBody>
        </Tile>
    );
};

export default connect(null, { removeUser, toggleAdminForUser })(UserList);
