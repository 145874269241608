import { useModal } from "common/hooks";
import ActionLink from "components/ActionLink";
import { OrgModal } from 'containers/Admin/Orgs';

export default function CreateOrgActionLink({ children = "Create new organisation", className = "IconLink--arrow-plus" }) {
    const [orgModal, openCreateOrgModal] = useCreateOrgModal();
    return (<>
        <ActionLink id="create-org" className={className} onClick={openCreateOrgModal}>
            {children}
        </ActionLink>
        {orgModal}
    </>);
}

const useCreateOrgModal = () => {
    const [modal, openModal] = useModal(OrgModal);

    const openCreateOrgModal = () => {
        const modalProps = {
            org: {
                user: {}
            }
        };
        openModal(modalProps);
    };

    return [modal, openCreateOrgModal];
}
