import React from "react";
import { compose, withActions, withState, withAppContext } from "containers/HOC";
import PaymentList from "components/PaymentList";
import { getPaymentsByMonthYear, getRecentPayments } from "../_actions";
import ActionLink from "components/ActionLink";
import { v4 as uuidv4 } from "uuid";
import { OrgBalance } from "containers/Orgs";
import { useAddBankDepositModal } from "containers/Admin/Payments/AddBankDepositModal";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
function Payments(props) {
    const [addBankDepositModal, openAddBankDepositModal] = useAddBankDepositModal();

    return (
        <>
            <PaymentsClassComponent {...props} openAddBankDepositModal={openAddBankDepositModal} />
            {addBankDepositModal}
        </>
    )
}

class PaymentsClassComponent extends React.Component {
    state = {};

    _getPayments(yearMonth, accountId) {
        this.setState({ yearMonth });
        this.props.getPaymentsByMonthYear(yearMonth, accountId);
    }

    _getRecentPayments(lastCount, accountId) {
        this.props.getRecentPayments(lastCount, accountId);
    }

    render() {
        const { org, authContext, payments, paymentsLoading } = this.props;
        const { yearMonth } = this.state;

        const createPayment = () => {
            const viewModel = {
                accountId: org.id,
                yearMonth,
                organisationName: org.name,
                paymentTransaction: {
                    id: uuidv4(),
                    accountId: org.id,
                },
            };
            this.props.openAddBankDepositModal(viewModel);
        };

        return (<>
            <div className="ActionsBar ActionsBar--super u-print-none">
                <div className="ActionsBar-left">
                    <ul className="ActionsBar-links">
                        <li>
                            <ActionLink id="admin-create-payment" className="IconLink--arrow-plus" onClick={createPayment}>
                                Add bank deposit for this org
                            </ActionLink>
                        </li>
                    </ul>
                </div>
            </div>
            <OrgBalance orgId={org.id} />
            <PaymentList recentPaymentCount={200} getPaymentsByMonthYear={(a, b) => this._getPayments(a, b)} getRecentPayments={(a, b) => this._getRecentPayments(a, b)} authContext={authContext} accountId={org.id} payments={payments} paymentsLoading={paymentsLoading} />
        </>);
    }
}

export default compose(
    withAppContext,
    withActions({ getPaymentsByMonthYear, getRecentPayments }),
    withState((state) => {
        return {
            payments: state.admin.payments,
            paymentsLoading: state.admin.paymentsLoading,
        };
    })
)(Payments);
