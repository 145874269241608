import TileSubPanel from "components/TileSubPanel";
import * as utils from "common/utils";
import { cropViewModel } from "common/viewModels";
import { useRefData } from "common/hooks";
import { HelpTooltip, helpText } from "components/Help";

export default function CropsGreenBox({ analysis }) {
    const refData = useRefData();
    const crops = cropViewModel(analysis, refData);

    return (
        Object.keys(crops).length > 0 && (
            <TileSubPanel title="Crops/Pasture - Reporting year only" green stickyHeaderName="analysis.crops.cropsPasture.collapsed">
                {
                    <div className="FarmTable_wrapper">
                        <div className="FarmTable-supplementary">
                            <div className="Table u-mt-md">
                                <table>
                                    <thead>
                                        <tr>
                                            <th data-width="md-20 xl-20">Crop/Pasture</th>
                                            <th data-width="md-10 xl-10" className="numeric">Hectares (sown)</th>
                                            <th data-width="md-10 xl-10" className="numeric">Yield</th>
                                            <th data-width="md-10 xl-10" className="numeric">
                                                Pasture grown (t DM/yr)
                                                <HelpTooltip tip={helpText.pastureGrowth} />
                                            </th>
                                            <th data-width="md-10 xl-10" className="numeric">
                                                Pasture intake (t DM/yr)
                                                <HelpTooltip tip={helpText.pastureIntake} />
                                            </th>
                                            <th data-width="md-10 xl-10" className="numeric">Supplements (t DM/yr)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(crops).map((key) => {
                                            const crop = crops[key];
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        <span className="u-flex u-flexAlignItemsCenter">
                                                            <img className="u-mr-xs u-p-5" src={crop.icon} width="35" height="35" alt={`${key} icon`} />
                                                            <span id={`crops-name-${key}`}>{crop.name}</span>
                                                        </span>
                                                    </td>
                                                    <td id={`crops-yield-${key}`} className="numeric">
                                                        {utils.numeric.format(crop.area, 1)}
                                                    </td>
                                                    <td id={`crops-yield-${key}`} className="numeric">
                                                        {utils.numeric.format(crop.yield)}
                                                        {crop.yield && crop.units ? " " + crop.units.replace("/ha", "") : ""}
                                                    </td>
                                                    <td id={`crops-yield-${key}`} className="numeric">
                                                        {utils.numeric.format(crop.grown)}
                                                    </td>
                                                    <td id={`crops-yield-${key}`} className="numeric">
                                                        {utils.numeric.format(crop.intake)}
                                                    </td>
                                                    <td id={`crops-yield-${key}`} className="numeric">
                                                        {utils.numeric.format(crop.supplements)}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </TileSubPanel>
        )
    )
}
