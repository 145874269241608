import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import ActionLink from "components/ActionLink";
import { ManagePublicationActionLink, AddPublicationCommentActionLink, ViewPublicationHistoryActionLink, isPublicationLocked } from "containers/Publications";
import { useNavigate } from "common/hooks";
import { useCreateWorkingCopyAsync } from "containers/hooks";

export default function PublicationActionsBar({ farm, publication }) {
    const createWorkingCopyAsync = useCreateWorkingCopyAsync();
    const navigate = useNavigate();

    const isLocked = isPublicationLocked(publication);

    const _createWorkingCopy = async () => {
        const workingCopyId = uuidv4();
        await createWorkingCopyAsync(publication, workingCopyId);
        navigate(`/app/farm/${farm.id}/analysis/${workingCopyId}`);
    }

    const workingCopy = (farm.budgets || []).find((b) => b.id === publication.workingCopyId);
    const canCreateWorkingCopy = !workingCopy && utils.canCreateWorkingCopy(farm) && !publication.farmGroupId;

    return (
        <div className="ActionsBar ActionsBar--super u-print-none">
            <div className="ActionsBar-left">
                <ul className="ActionsBar-links">
                    {workingCopy && (
                        <li>
                            <Link to={`/app/farm/${workingCopy.farmId}/analysis/${workingCopy.id}`} className="IconLink--edit" id="edit-working-copy">
                                Working copy
                            </Link>
                        </li>
                    )}
                    {canCreateWorkingCopy && (
                        <li>
                            <ActionLink onClick={_createWorkingCopy} className="IconLink--edit" id="create-working-copy">
                                Working copy
                            </ActionLink>
                        </li>
                    )}
                    <li>
                        <ViewPublicationHistoryActionLink publication={publication} />
                    </li>

                    {!isLocked && (
                        <li>
                            <ManagePublicationActionLink publication={publication} />
                        </li>
                    )}
                    <li>
                        <AddPublicationCommentActionLink publication={publication} label="Add publication comment" />
                    </li>
                </ul>
            </div>
        </div>
    )
}
