import { useDispatch } from "react-redux";
import * as utils from "common/utils";
import { useModal } from "common/effects";
import { useAuthContext, useConfirm, useNavigate, useRefData } from "common/hooks";
import Card from "components/new/Card";
import CardHeader from "components/new/CardHeader";
import CardHeaderColumn from "components/new/CardHeaderColumn";
import CardHeaderField from "components/new/CardHeaderField";
import CardBody from "components/new/CardBody";
import CardActions from "components/new/CardActions";
import CardActionStatus from "components/new/CardActionStatus";
import CardActionsLeft from "components/new/CardActionsLeft";
import CardActionsRight from "components/new/CardActionsRight";
import ActionLink from "components/ActionLink";
import { Panel, PanelBody } from "components/Panel";
import { AnalysisResultsWidget } from "components/ResultsWidgets";
import PublicationCard from "containers/Publications/PublicationCard";
import { getStudents, getAccount } from "../AccountDetails/_actions";
import { useRenameAnalysisModal } from "./Modals/RenameAnalysisModal";
import { useAnalysisAuditLogModal } from "./Modals/AnalysisAuditLogModal";
import { useCopyAnalysisModal } from "./Modals/CreateAnalysisModal";
import { useReplaceAnalysisModal } from "./Modals/ReplaceAnalysisModal";
import { useSimplifyAnalysisModal } from "./Modals/SimplifyAnalysisModal";
import { useShareAnalysisModal } from "./Modals/ShareAnalysisModal";
import CompareAnalysesModal from "./Modals/CompareAnalysesModal";
import { useAnalysisCommentsModal } from "./Modals/AnalysisCommentsModal";
import { isPublishingToMPIWholeFarmDataProject } from "containers/Publications";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";
import { Menu, Item, MenuProvider, Separator } from "react-contexify";
import { usePublicationDetailsModal } from "containers/Publications/PublicationDetailsModal";
import { useDeleteAnalysisAsync, useDownloadAnalysis } from "containers/hooks";
import { useAddToDatasetModal } from "../Datasets/AddToDatasetModal";

export default function AnalysisCard({ farm, analysis, parentTitle = "", showVisibility = false }) {
    const dispatch = useDispatch();
    const _getStudents = () => dispatch(getStudents());
    const _getAccount = () => dispatch(getAccount());

    let { isPlaceholder } = analysis;
    const [analysisCommentsModal, openAnalysisCommentsModal] = useAnalysisCommentsModal(farm.id, analysis.id);
    const [publicationDetailsModal, openPublicationDetailsModal] = usePublicationDetailsModal({ farmId: farm.id, budgetId: analysis.id });
    const [renameAnalysisModal, openRenameAnalysisModal] = useRenameAnalysisModal(farm, analysis.id);
    const [analysisAuditLogModal, openAnalysisAuditLogModal] = useAnalysisAuditLogModal(farm, analysis);
    const [replaceAnalysisModal, openReplaceAnalysisModal] = useReplaceAnalysisModal(farm, analysis.id);
    const [copyAnalysisModal, openCopyAnalysisModal] = useCopyAnalysisModal(farm, analysis.id);
    const [simplifyAnalysisModal, openSimplifyAnalysisModal] = useSimplifyAnalysisModal(farm, analysis.id);
    const [addToDatasetModal, openAddToDatasetModal] = useAddToDatasetModal(farm.id, analysis.id);
    const [shareAnalysisModal, openShareAnalysisModal] = useShareAnalysisModal(analysis);
    const authContext = useAuthContext();
    const refData = useRefData();
    const navigate = useNavigate();
    const featureTracker = useFeatureTracker(FEATURES.FARM_IMPACT_REPORT, undefined, farm);
    const linkFeatureTracker = useFeatureTracker(FEATURES.ANALYSIS_LINKS_LIST, undefined, farm);
    const deleteAnalysisAsync = useDeleteAnalysisAsync();
    const downloadAnalysis = useDownloadAnalysis();
    const confirm = useConfirm();

    const { getModal } = useModal();

    const _addToDataset = () => {
        openAddToDatasetModal()
    };

    const _viewAnalysis = () => {
        linkFeatureTracker.track("View analysis");
        navigate(`/app/farm/${farm.id}/analysis/${analysis.id}`);
    }

    const _viewComments = () => {
        openAnalysisCommentsModal();
    }

    const _viewAuditLog = () => {
        openAnalysisAuditLogModal();
    }

    const _renameAnalysis = () => {
        openRenameAnalysisModal();
    }

    const _shareAnalysis = () => {
        _getStudents();
        _getAccount();
        openShareAnalysisModal();
    }

    const _compareAnalyses = () => {
        featureTracker.track("Compare from analysis list");
        navigate(`/app/farm/${farm.id}/analysis/${analysis.id}/compare`);
    }

    const _simplifyAnalysis = () => {
        openSimplifyAnalysisModal();
    }

    const _publishAnalysis = () => {
        openPublicationDetailsModal();
    }

    const _copyAnalysis = () => {
        openCopyAnalysisModal(analysis.id);
    }

    const _replaceAnalysis = () => {
        openReplaceAnalysisModal();
    }

    const _deleteAnalysis = () => {
        confirm(`Are you sure you want to delete the "${analysis.name}" analysis?`, async () => {
            await deleteAnalysisAsync(analysis);
        });
    }

    const MenuItem = ({ id, className, text, evt }) => (
        <Item onClick={(e) => evt(e)}>
            <div className="u-flex" id={id}>
                <i className={className} />
                <span>{text}</span>
            </div>
        </Item>
    )

    const publications = (farm.publications || []).filter((p) => p.budgetId === analysis.id && (!isPublishingToMPIWholeFarmDataProject(p.accountId) || authContext.canPublishToMPIWholeFarmDataProject)).sort((a, b) => b.version - a.version);
    const hasBodyContent = refData.deploymentType !== "Science" && publications.length > 0;

    const guestReadOnly = analysis.role === "Header";
    isPlaceholder = isPlaceholder || guestReadOnly;
    const hasFarmUpdateAccess = utils.canUpdateFarm(farm);
    const hasManageAnalysisAccess = utils.canManageAnalyses(farm);
    const hasAnalysisWriteAccess = analysis.role === "Write";
    const canView = isPlaceholder ? false : true;
    const isPrivateYearEnd = analysis.type === "PrivateYearEnd";
    const isFertiliserRecommendation = analysis.type === "FertiliserRecommendation";
    const educationProvider = authContext.accountType === "EducationProvider";
    const canShare = hasFarmUpdateAccess && hasAnalysisWriteAccess && !farm.studentGroup && (analysis.visibility !== "Farm" || educationProvider) && authContext.accountType !== "Student" && !isPrivateYearEnd && !isFertiliserRecommendation;
    const canAddDatasets = refData.deploymentType === "Science";
    const canDownload = authContext.isSystemAdmin;
    const canPublish = refData.deploymentType !== "Science" && analysis.hasCompletedBlocks && (!farm.expired || (hasFarmUpdateAccess && authContext.canPublishWithoutSubscription)) && ((hasAnalysisWriteAccess && analysis.type === "PrivateYearEnd") || (hasFarmUpdateAccess && ["YearEnd", "Predictive"].includes(analysis.type)));
    const canViewComments = hasAnalysisWriteAccess;
    const canViewAuditLog = hasAnalysisWriteAccess;
    const canDelete = hasAnalysisWriteAccess && publications.length === 0;
    const canCopy = hasAnalysisWriteAccess && hasManageAnalysisAccess;
    const canReplace = hasFarmUpdateAccess && analysis.type === "YearEnd" && farm?.budgets?.length > 1 && analysis?.hasCompletedBlocks && !isFertiliserRecommendation;
    const canCompare = analysis.hasCompletedBlocks && !isFertiliserRecommendation;
    const canRename = hasAnalysisWriteAccess && ["Predictive", "Scenario", "Account"].includes(analysis.type);
    const canSimplify = process.env.REACT_APP_DISABLE_MERGE_BLOCKS !== "true" && analysis.nLossPerHa && !isFertiliserRecommendation;
    const warningMessages = (analysis.messages || []).filter((m) => m.severity !== "Error" && !analysis.suppressedMessageCodes.includes(m.code)).map((m) => m.code);
    const warnings = Array.from(new Set(warningMessages));
    const errorMessages = (analysis.messages || []).filter((m) => m.severity === "Error").map((m) => m.code);
    const errors = Array.from(new Set(errorMessages));
    const defaultToOpen = isPlaceholder || publications.some((p) => p.status === "Returned");
    const showPreviousResults = !analysis.previousNLossPerHa || analysis.previousNLossPerHa === 0 ? false : analysis.nLossPerHa === analysis.previousNLossPerHa && analysis.pLossPerHa === analysis.previousPLossPerHa && analysis.ghgkPerHa > 0 && analysis.ghgkPerHa === analysis.previousGHGKPerHa ? false : true;
    const previousMessage = !analysis.previousNLossPerHa || analysis.previousNLossPerHa === 0 ? `v${analysis.previousModelVersion}: No results` : analysis.nLossPerHa === analysis.previousNLossPerHa && analysis.pLossPerHa === analysis.previousPLossPerHa && analysis.ghgkPerHa > 0 && analysis.ghgkPerHa === analysis.previousGHGKPerHa ? `v${analysis.previousModelVersion}: No change` : `v${analysis.previousModelVersion}: `;
    const compareAnalysesModal = getModal("CompareAnalysesModal");
    const sharedWith = getSharedWith(analysis);

    const menuShown = () => {
        const menu = document.querySelector(".react-contexify");
        const container = document.querySelector(".actions-link");

        if (menu && container) {
            menu.style.top = `${menu.getBoundingClientRect().top + 13}px`;
            menu.style.left = `${container.getBoundingClientRect().left}px`;
        }
    }

    return (
        <>
            <Card collapsible open={defaultToOpen}>
                {({ collapsed, toggle }) => {
                    return (
                        <>
                            <CardHeader id={analysis.id} name={parentTitle || analysis.id} dataWidth={`md-100 xl-17.5 xxl-25`} title={analysis.name} subTitle={analysis.year} markerIcon={hasBodyContent ? <i className="icon icon-bookmarks" title="Published" /> : undefined} collapsed={collapsed} onTitleClick={canView && _viewAnalysis}>
                                {guestReadOnly && (
                                    <>
                                        {hasBodyContent && (
                                            <CardHeaderColumn className="u-pr-xxl">
                                                <CardHeaderField
                                                    label={`Publications (${publications.length})`}
                                                    value={
                                                        hasBodyContent ? (
                                                            <ActionLink id={`view-analysis-publications-${analysis.id}`} className={collapsed ? "IconLink--arrow-down" : "IconLink--arrow-up"} onClick={toggle()}>
                                                                {collapsed ? "Show" : "Hide"}
                                                            </ActionLink>
                                                        ) : (
                                                            "-"
                                                        )
                                                    }
                                                />
                                            </CardHeaderColumn>
                                        )}
                                        <CardHeaderColumn>
                                            <CardHeaderField label="Access" value="You do not have access to view this analysis. You will need to request access or wait until your request is granted." />
                                        </CardHeaderColumn>
                                    </>
                                )}
                                {!guestReadOnly && (
                                    <>
                                        {(canPublish || hasBodyContent) && (
                                            <CardHeaderColumn className="u-pr-xl">
                                                <CardHeaderField
                                                    label={`Publications (${publications.length})`}
                                                    value={
                                                        hasBodyContent ? (
                                                            <ActionLink id={`view-analysis-publications-${analysis.id}`} className={collapsed ? "IconLink--arrow-down" : "IconLink--arrow-up"} onClick={toggle()}>
                                                                {collapsed ? "Show" : "Hide"}
                                                            </ActionLink>
                                                        ) : (
                                                            "-"
                                                        )
                                                    }
                                                />
                                            </CardHeaderColumn>
                                        )}
                                        <CardHeaderColumn className="u-pr-xl" onTitleClick={canView && _viewAnalysis}>
                                            <CardHeaderField label="Created" value={analysis.created ? utils.dateOnly(analysis.created) : "-"} />
                                        </CardHeaderColumn>
                                        <CardHeaderColumn className="u-pr-xl">
                                            <CardHeaderField label="Modified" value={analysis.lastUpdated ? utils.dateOnly(analysis.lastUpdated) : "-"} />
                                        </CardHeaderColumn>
                                        {showVisibility && (
                                            <CardHeaderColumn className="u-pr-xl">
                                                <CardHeaderField label="Shared with" value={sharedWith} />
                                            </CardHeaderColumn>
                                        )}
                                        {canAddDatasets && analysis.datasetName && (
                                            <CardHeaderColumn className="u-pr-xl">
                                                <CardHeaderField label="Dataset" value={analysis.datasetName} />
                                            </CardHeaderColumn>
                                        )}
                                        {!isPlaceholder && (
                                            <CardHeaderColumn nutrientsLost>
                                                <AnalysisResultsWidget analysis={analysis} perHaOnly />
                                                <MenuProvider event="onClick" id={`menu_${analysis.id}`}>
                                                    <div className="u-flex u-flexJustifyCenter u-link u-font-blue u-textBold actions-link">
                                                        <i className="icon icon--sm icon-dots-horizontal-triple" id={`menu_${analysis.id}`} />
                                                        <span>Actions</span>
                                                    </div>
                                                </MenuProvider>
                                                <Menu id={`menu_${analysis.id}`} onShown={(e) => menuShown()}>
                                                    {canRename && <MenuItem id={`rename-analysis-${analysis.id}`} className="IconLink--settings" text="Rename" evt={_renameAnalysis} />}
                                                    {canViewAuditLog && <MenuItem id={`view-analysis-auditlog-${analysis.id}`} className="IconLink--clock" text="Audit log" evt={_viewAuditLog} />}
                                                    {canViewComments && <MenuItem id={`view-analysis-comments-${analysis.id}`} className="IconLink--chat" text={`Comments (${(analysis.comments || []).length})`} evt={_viewComments} />}
                                                    {canAddDatasets && <MenuItem id={`set-dataset-${analysis.id}`} className="IconLink--tick-circle" text={`${analysis.datasetId ? "Update dataset" : "Add to dataset"}`} evt={_addToDataset} />}
                                                    {canShare && <MenuItem id={`share-analysis-${analysis.id}`} className="IconLink--share" text="Share" evt={_shareAnalysis} />}
                                                    {canPublish && <MenuItem id={`publish-analysis-${analysis.id}`} className="IconLink--bookmarks" text="Publish" evt={_publishAnalysis} />}
                                                    {canCopy && <MenuItem id={`copy-analysis-${analysis.id}`} className="IconLink--copy" text="Copy" evt={_copyAnalysis} />}
                                                    {canReplace && <MenuItem id={`replace-analysis-${analysis.id}`} className="IconLink--replace" text="Replace" evt={_replaceAnalysis} />}
                                                    {canSimplify && <MenuItem id={`simplify-analyses-${analysis.id}`} className="IconLink--group" text="Simplify" evt={_simplifyAnalysis} />}
                                                    {canCompare && <MenuItem id={`compare-analyses-${analysis.id}`} className="IconLink--compare" text="Compare" evt={_compareAnalyses} />}
                                                    {canDownload && <MenuItem id={`download-analysis-${analysis.id}`} className="IconLink--shield u-link" text="Download" evt={() => downloadAnalysis(farm.id, analysis.id)} />}
                                                    {canDelete && (
                                                        <>
                                                            <Separator />
                                                            <MenuItem id={`delete-analysis-${analysis.id}`} className="IconLink--trash" text="Delete" evt={_deleteAnalysis} />
                                                        </>
                                                    )}
                                                </Menu>
                                            </CardHeaderColumn>
                                        )}
                                    </>
                                )}
                            </CardHeader>
                            <CardActions>
                                <CardActionsLeft>
                                    {!isPlaceholder && (
                                        <>
                                            {warnings.length > 0 && <CardActionStatus className="icon icon-dot u-textWarning" status={`${warnings.length} Attention`} />}
                                            {errors.length > 0 && <CardActionStatus className="icon icon-dot u-textError" status={`${errors.length} Error(s)`} />}
                                            <CardActionStatus status={previousMessage} />
                                            {showPreviousResults && (
                                                <>
                                                    {analysis.previousNLossPerHa ? <CardActionStatus className="icon icon-square N-color" status={`N/ha: ${analysis.previousNLossPerHa}`} /> : ""}
                                                    {analysis.previousPLossPerHa ? <CardActionStatus className="icon icon-square P-color" status={`P/ha: ${analysis.previousPLossPerHa}`} /> : ""}
                                                    {analysis.previousGHGKPerHa ? <CardActionStatus className="icon icon-square GHG-color" status={`GHG/ha: ${analysis.previousGHGKPerHa}`} /> : ""}
                                                </>
                                            )}
                                        </>
                                    )}
                                </CardActionsLeft>
                                <CardActionsRight>
                                    {!isPlaceholder && (
                                        <>
                                            
                                        </>
                                    )}
                                </CardActionsRight>
                            </CardActions>
                            {hasBodyContent && (
                                <CardBody collapsed={collapsed} className="u-ml-sm">
                                    {publications.length > 0 &&
                                        publications
                                            .filter((p) => p.workingCopyId)
                                            .map((publication) => {
                                                const workingCopy = farm.budgets.find((b) => b.id === publication.workingCopyId);
                                                return workingCopy ? (
                                                    <Panel key={workingCopy.id} title={`Working copy - ${publication.publishedTo}`} small midBlue className="u-mt-md">
                                                        <PanelBody grey>
                                                            <AnalysisCard key={workingCopy.id} farm={farm} analysis={workingCopy} parentTitle={parentTitle} showVisibility={showVisibility} />
                                                        </PanelBody>
                                                    </Panel>
                                                ) : undefined;
                                            })}
                                    {publications.length > 0 && (
                                        <Panel title={`Publications (${publications.length})`} small skyBlue className="u-mt-md">
                                            <PanelBody grey>
                                                {publications.map((publication) => (
                                                    <PublicationCard key={publication.id} farm={farm} analysis={analysis} publication={publication} />
                                                ))}
                                            </PanelBody>
                                        </Panel>
                                    )}
                                </CardBody>
                            )}
                        </>
                    );
                }}
            </Card>
            {renameAnalysisModal}
            {analysisAuditLogModal}
            {copyAnalysisModal}
            {replaceAnalysisModal}
            {simplifyAnalysisModal}
            {compareAnalysesModal && <CompareAnalysesModal {...compareAnalysesModal.modalProps} />}
            {analysisCommentsModal}
            {publicationDetailsModal}
            {addToDatasetModal}
            {shareAnalysisModal}
        </>
    )
}

function getSharedWith(analysis) {
    if (analysis?.visibility === "Organisation") {
        return "My organisation";
    } else if (analysis?.visibility === "Owner") {
        return "My organisation and farm owners";
    } else if (analysis?.visibility === "Farm") {
        return "All users with farm access";
    }

    return "-";
}
