import { Link } from "react-router-dom";
import * as utils from "common/utils";
import SoilDetails from "components/SoilDetails";
import { categoriseMessage } from "containers/BudgetHome/TabMessages";
import { useOnline, useRefData } from "common/hooks";

export default function SoilListCard({ farm, analysis, block }) {
    const online = useOnline();
    const refData = useRefData();

    const _blockIcon = utils.getBlockIcon(block.type);
    const _blockType = utils.getBlockTypeText(refData, block);
    const { farmSoilBlocks = [] } = analysis;
    const _hasSoil = block.isProductive && farmSoilBlocks.find((fsb) => fsb.blockId === block.id);
    const _soilTest = block.soilTestId && analysis.soilTests && analysis.soilTests.find((t) => t.id === block.soilTestId);
    const { messages = [] } = analysis;
    const errorIcon = online && messages.find((m) => categoriseMessage(m, "Soil") && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error") ? "error" : undefined;
    const alertIcon = errorIcon || (online && messages.find((m) => categoriseMessage(m, "Soil") && m.entityType === "Block" && m.entityId === block.id && m.severity === "Warning" && !analysis.suppressedMessageCodes.includes(m.code)) ? "warning" : undefined);
    const coverageStyle = block.percentageSoilCoverage < 50 ? " has-error" : block.percentageSoilCoverage < 75 ? " has-warning" : "";

    return (
        <div className="FarmTable_wrapper">
            <ul className="DataWidthTable FarmTable">
                <li className="FarmTable-title" data-width="md-100 xl-30">
                    <div className="FarmTitle">
                        {alertIcon && (
                            <span className={`Todo Todo--${alertIcon} ${errorIcon ? "error-anchor" : ""}`}>
                                <i className="icon icon-alert" />
                            </span>
                        )}
                        <img className="FarmTitle-icon" src={_blockIcon} alt="Block Type" />
                        <div className="FarmTitle-heading">
                            {block.requiresSoil && (
                                <Link id={block.id} to={`/app/farm/${farm.id}/analysis/${analysis.id}/soil/blocks/${block.id}`} className="FarmTitle-name" title={block.name}>
                                    {block.name}
                                </Link>
                            )}
                            {!block.requiresSoil && (
                                <span className="FarmTitle-name" title={block.name}>
                                    {block.name}
                                </span>
                            )}
                            <div className="FarmTitle-sub">{_blockType}</div>
                        </div>
                    </div>
                </li>
                {block.requiresSoil && _hasSoil && (
                    <li data-width="md-25 xl-17.5">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Soils</span>
                                <span className="FarmTable-value" id={`${block.id}_auto`}>
                                    {block.autoGeneratedSoils && <span className="IconLink--alert u-textWarning">Using auto assigned soils</span>}
                                    {!block.autoGeneratedSoils && <span className="">Using user defined soils</span>}
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {_soilTest && (
                    <li data-width="md-25 xl-17.5">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Soil test</span>
                                <span className="FarmTable-value" id={`${block.id}_SoilTest`}>
                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/soil/soilTests/${_soilTest.id}`} className="u-flex u-flexAlignItemsCenter" id={`edit-soiltest-${_soilTest.id}`}>
                                        <span>
                                            <b>{_soilTest.defaultTest ? "Default soil test" : _soilTest.name}</b>
                                        </span>
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {_soilTest && (
                    <li data-width="md-25 xl-17.5">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Soil test ASC</span>
                                <span className="FarmTable-value" id={`${block.id}_SoilTest_ASC`}>
                                    {_soilTest.asc ? `${_soilTest.asc}` : "Using soil defaults"}
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {_soilTest && (
                    <li data-width="md-25 xl-17.5">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Soil test TBK reserve K</span>
                                <span className="FarmTable-value" id={`${block.id}_SoilTest_ASC`}>
                                    {_soilTest.tbkReserveK ? `${_soilTest.tbkReserveK}` : (_soilTest.kReserveStatusOption ? `${_soilTest.kReserveStatusOption}` : "Using soil defaults")}
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {false && analysis.useFarmSoils && block.requiresSoil && (
                    <li data-width="md-25 xl-15">
                        <div className="FarmTable-keyValuePair">
                            <div className={"FarmTable-keyValue" + coverageStyle}>
                                <span className="FarmTable-key">Soil map coverage</span>
                                <span className="FarmTable-value" id={`${block.id}_coverage`}>
                                    <span>
                                        <b>{utils.round(block.percentageSoilCoverage, 0)}%</b>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {!block.isProductive && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <span>Soil data is not required for this block</span>
                    </li>
                )}
                {!_hasSoil && block.isProductive && !analysis.useFarmSoils && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <span>
                            There is no soil defined for this block.{" "}
                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/soil/blocks/${block.id}`} id={`edit-soils-${block.id}`}>
                                Select this link to assign soils
                            </Link>
                        </span>
                    </li>
                )}
                {!_hasSoil && block.isProductive && analysis.useFarmSoils && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        {block.isDrawn && (
                            <span>
                                This block has no soils because it is not covered by any farm soils.{" "}
                                <Link to={{ pathname: `/app/farm/${farm.id}/soils`, state: { analysisId: analysis.id } }} id={`edit-farm-soils-${block.id}`}>
                                    Select this link to view farm soils map
                                </Link>
                            </span>
                        )}
                        {!block.isDrawn && (
                            <span>
                                This block has no soils because it is not drawn.{" "}
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}`} id={`edit-farm-soils-${block.id}`}>
                                    Select this link to view the block map
                                </Link>
                            </span>
                        )}
                    </li>
                )}
                {_hasSoil && block.percentageSoilCoverage < 75 && block.isDrawn && block.isProductive && analysis.useFarmSoils && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <span>
                            Edit the{" "}
                            <Link to={{ pathname: `/app/farm/${farm.id}/soils`, state: { analysisId: analysis.id, blockId: block.id } }} id={`edit-farm-soils-${block.id}`}>
                                farm soils map
                            </Link>{" "}
                            to increase the soil map coverage for this block
                        </span>
                    </li>
                )}
            </ul>
            {_hasSoil && <SoilDetails farm={farm} analysis={analysis} block={block} />}
        </div>
    );
}
