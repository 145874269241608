import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import Watermark from "components/Watermark";
import { HelpTooltip, ForMoreInfoZendeskLink } from "components/Help";
import { useAnalysisResults } from "containers/BudgetHome";

export default function StockingRate({ analysis }) {
    const { data: analysisResults } = useAnalysisResults(analysis);
    if (!analysisResults.animals) {
        return null;
    }

    const stockingRates = analysisResults?.animals?.stockingRates;
    const pastureFed = analysisResults?.animals?.pastureFed;

    return (
        <Panel title="Stocking rate for all enterprises" info={PAGE.INFO} skyBlue>
            <PanelBody>
                <div className="Table">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="20">Enterprise</th>
                                <th data-width="15" className="numeric">
                                    By grazed area (RSU/ha)
                                    <HelpTooltip tip="The estimated stocking rate per hectare of the area grazed by all animal enterprises on pastoral blocks" />
                                </th>
                                <th data-width="15" className="numeric">
                                    By total farm area (RSU/ha)
                                    <HelpTooltip tip="The estimated stocking rate per hectare of the total farm area" />
                                </th>
                                <th data-width="12.5" className="numeric">
                                    Diet from pasture (%)
                                    <HelpTooltip tip="The estimated percentage of diet from pasture" />
                                </th>
                                <th data-width="12.5" className="numeric">
                                    Time on pasture (%)
                                    <HelpTooltip tip="The estimated percentage of time spent on blocks" />
                                </th>
                                <th data-width="10" className="numeric">
                                    Total (RSU)
                                    <HelpTooltip tip="Using RSU for estimating stocking rates allows for meaningful comparisons of the carrying capacities of different animal enterprises based on feed intake" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {stockingRates?.byEnterprise?.map(enterprise => {
                                const enterpriseKey = `stocking_rate_${enterprise.name}`;
                                const pastureFedForEnterprise = pastureFed?.byEnterprise?.find(e => e.id === enterprise.id);
                                return (
                                    <tr key={enterprise.id}>
                                        <td>{enterprise.name}</td>
                                        <td id={`${enterpriseKey}_byGrazedArea`} className="numeric">
                                            {utils.numeric.format(enterprise.perTotalGrazingArea.value, 2)}
                                        </td>
                                        <td id={`${enterpriseKey}_byFarmArea`} className="numeric total">
                                            {utils.numeric.format(enterprise.perTotalFarmArea.value, 2)}
                                        </td>
                                        <td id={`${enterpriseKey}_fromPastureEaten`} className="numeric">
                                            {utils.numeric.format(pastureFedForEnterprise.percentageOfDietFromPasture.value, 1)}%
                                        </td>
                                        <td id={`${enterpriseKey}_fromTimeOnPasture`} className="numeric">
                                            {utils.numeric.format(pastureFedForEnterprise.percentageOfTimeOnPasture.value, 1)}%
                                        </td>
                                        <td id={`${enterpriseKey}_total`} className="numeric total">
                                            {utils.numeric.format(enterprise.total.value)}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            {stockingRates &&
                                <tr className="totals">
                                    <th>Totals</th>
                                    <th id="totals_byGrazedArea" className="numeric total">
                                        {utils.numeric.format(stockingRates.perTotalGrazingArea.value, 2)}
                                    </th>
                                    <th id="totals_fromTimeOnPasture" className="numeric total">
                                        {utils.numeric.format(stockingRates.perTotalFarmArea.value, 2)}
                                    </th>
                                    <th id="totals_fromPastureEaten" className="numeric total">
                                        {utils.numeric.format(pastureFed?.totalPercentageOfDietFromPasture.value, 1)}%
                                    </th>
                                    <th id="totals_fromTimeOnPasture" className="numeric total">
                                        {utils.numeric.format(pastureFed?.totalPercentageOfTimeOnPasture.value, 1)}%
                                    </th>
                                    <th id="totals_total" className="numeric total">
                                        {utils.numeric.format(stockingRates.total.value)}
                                    </th>
                                </tr>
                            }
                        </tfoot>
                    </table>
                </div>
            </PanelBody>
        </Panel>
    )
}

const PAGE = {
    INFO: <>
        Shows the stocking rates for the farm, expressed in revised stock units (RSU).
        RSU is a measure of animal feed intake, where 1 RSU equals 6,000 MJ ME.
        Using RSU for estimating stocking rates allows for meaningful comparisons of the carrying capacities of different animal enterprises based on feed intake.
        <ForMoreInfoZendeskLink url="https://support.overseer.org.nz/hc/en-us/articles/900001917046-Animal-Report-Description-of-Stocking-Rate-for-All-Enterprises-Report" />
    </>,
}
