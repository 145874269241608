import React from "react";
import { getFailedPayments } from "../_actions";
import { useDispatch } from "react-redux";
import { Panel, PanelBody } from "components/Panel";
import Alert from "components/Alert";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { Link } from "react-router-dom";
import * as utils from "common/utils";

export default function FailedPayments() {
    const dispatch = useDispatch();

    const [failedPayments, setFailedPayments] = React.useState();

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(getFailedPayments());
                setFailedPayments(response);
            } catch {
                setFailedPayments([]);
            }
        };

        if (!failedPayments) {
            fetchData();
        }
    }, [dispatch, failedPayments]);

    const paymentMethodText = (paymentMethod) => {
        switch (paymentMethod) {
            case "CreditCard":
                return "Credit Card";
            default:
                return paymentMethod;
        }
    };

    return (
        <Panel>
            <Alert type="info" text="This list shows recent failed payment transactions that have not subsequently processed a successful payment. They have tried to pay but cannot and have not. Select the farm link to go to the farms full list of payment transactions." />
            <PanelBody loading={!failedPayments}>
                <div>
                    <React.Fragment>
                        <SortableTable data={failedPayments} defaultSortCriteria={{ fieldName: "transactionTime", fieldType: "date", desc: true }} pageSize={50}>
                            {({ data, sortCriteria, handleSort }) => {
                                const subType = {
                                    CardOnly: "",
                                    SaveToken: "Save card",
                                    UserSavedCard: "Used saved card",
                                    AutoSavedCard: "Auto renewal",
                                };
                                return (
                                    <table>
                                        <thead>
                                            <tr>
                                                <SortableTableHeader label="Last failure date" fieldName="transactionTime" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Sub expiry" fieldName="subscriptionExpiryDate" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Payment method" fieldName="paymentMethod" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Trxn type" fieldName="creditCardSubTxnType" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Farm name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Farm address" fieldName="address" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Account" fieldName="accountName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="User" fieldName="tokenUser" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Email" fieldName="tokenUserEmail" sortCriteria={sortCriteria} handleSort={handleSort} />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((renewal) => {
                                                return (
                                                    <tr key={renewal.id}>
                                                        <td>{utils.dateLong(renewal.transactionTime, "-")}</td>
                                                        <td>{utils.dateOnly(renewal.expiresAt, "-")}</td>
                                                        <td>{paymentMethodText(renewal.paymentMethod)}</td>
                                                        <td>{subType[renewal.creditCardSubTxnType]}</td>
                                                        <td>
                                                            <Link target="_blank" to={`/app/admin/farms/${renewal.id}/payments`}>
                                                                {renewal.name}
                                                            </Link>
                                                        </td>
                                                        <td>{renewal.address}</td>
                                                        <td>{renewal.accountName}</td>
                                                        <td>{renewal.userFullName}</td>
                                                        <td>{renewal.userEmail}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                );
                            }}
                        </SortableTable>
                    </React.Fragment>
                </div>
            </PanelBody>
        </Panel>
    );
}
