import React from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import InputPack from "components/InputPack";
import * as domain from "common/domain";
import { toggleQuickTips } from "containers/App/_actions";
import SelectPack from "components/SelectPack";
import CheckboxPack from "components/CheckboxPack";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";

class DrainageModal extends React.Component {
    _closeOnEscape = (e) => {
        if (e.keyCode === 27) {
            this.props.cancel();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this._closeOnEscape);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._closeOnEscape);
    }

    render() {
        const { refData, drainageMethod, analysis, drainage, cancel, save, onChange, validation, specifyPlacement, specifyWetland, availableBlocks, toggleQuickTips, showQuickTips } = this.props;

        const wetlandConditions = refData.wetlandConditionType;
        const drainageWetlandConditions = (wetlandConditions && wetlandConditions.filter((w) => [domain.WetlandCondition.Artificialtype1.key, domain.WetlandCondition.Artificialtype2.key, domain.WetlandCondition.Artificialtype3.key].includes(w.value))) || [];
        const selectedBlock = analysis.blocks.find((b) => b.drainageDetailsId === drainage.id);
        const blockArea = selectedBlock ? selectedBlock.areaInHectares : 0;
        const blockId = selectedBlock ? selectedBlock.id : "";
        const wetlandCondition = specifyWetland && drainage.artificialWetlandCondition ? domain.WetlandCondition[drainage.artificialWetlandCondition] : undefined;

        return (
            <div className="Modal_wrapper">
                <input type="hidden" id="drainageId" value={drainage.id} />
                <div className="Modal Modal--skinny">
                    <div className="Modal-head">
                        <span className="Modal-head--left">Drainage</span>
                        <span className="Modal-head--right">
                            <ActionLink onClick={toggleQuickTips} className="Modal-close">
                                <i className="icon icon-question" title={`${showQuickTips ? "Hide" : "Show"} quick tips`} />
                            </ActionLink>
                            <ActionLink onClick={cancel} className="Modal-close" id="irrigation_modal_close">
                                <i className="icon icon-cross" title="Close" />
                            </ActionLink>
                        </span>
                    </div>
                    <div className="Modal-body">
                        <div className="Grid">
                            <div className="Grid-cell">
                                <input type="hidden" id="drainageId" value={drainage.id} />

                                <SelectPack name="drainage_select_blocks" meta={{ nrf: true }} onChange={(e) => onChange(e, { type: "block", key: "drainageDetailsId" })} val={validation.blocks} id="drainage_select_blocks" label="Select block" value={blockId} requiredLabel={true}>
                                    <option value="" disabled={true}>
                                        Select a block
                                    </option>
                                    {availableBlocks.map((block) => (
                                        <option value={block.id} key={block.id}>
                                            {block.name}
                                        </option>
                                    ))}
                                </SelectPack>

                                <InputPack id="propDrained" name="propDrained" type="text" label="Percentage drained" onChange={(e) => onChange(e, { type: "drainage", key: "propDrained" })} onBlur={(e) => onChange(e, { type: "drainage", key: "propDrained", round: 0 })} meta={{ nrf: true }} value={drainage.propDrained} val={validation.propDrained} requiredLabel={true} uom="%" placeholder="Percentage of block drained" />

                                {drainageMethod.toLowerCase() === "other" && <CheckboxPack name="specifyPlacement" meta={{ nrf: true }} onChange={(e) => onChange(e, { type: "state", key: "placement" })} value={specifyPlacement} id="specifyPlacement" label="Specify drainage placements" requiredLabel={true}></CheckboxPack>}

                                {specifyPlacement && (
                                    <div className="Field-group">
                                        <SelectPack name="depthToDrain" meta={{ nrf: true }} onChange={(e) => onChange(e, { type: "drainage", key: "depthToDrain" })} value={drainage.depthToDrain} val={validation.depthToDrain} dataWidth="50" id="depthToDrain" label="Depth to drains" requiredLabel={true}>
                                            <option value="" disabled={true}>
                                                Select the depth to drains
                                            </option>
                                            {refData.depthLayers.map((w) => (
                                                <option value={w.value} key={w.value}>
                                                    {w.text}
                                                </option>
                                            ))}
                                        </SelectPack>
                                        <InputPack id="drainSpace" name="drainSpace" dataWidth="50" type="text" label="Spacing between drains" onChange={(e) => onChange(e, { type: "drainage", key: "drainSpace" })} onBlur={(e) => onChange(e, { type: "drainage", key: "drainSpace", round: 0 })} uom="m" meta={{ nrf: true }} value={drainage.drainSpace} val={validation.drainSpace} requiredLabel={true} placeholder="Specify the spacing between drains in meters" />
                                    </div>
                                )}

                                <CheckboxPack name="specifyWetland" meta={{ nrf: true }} onChange={(e) => onChange(e, { type: "state", key: "wetland" })} value={specifyWetland} id="specifyWetland" label="All of the drainage from the block is captured by an artificial wetland" tip="One method to reduce N losses from mole/tile drains is to add an artificial wetland to the outlets. This model assumes that all drainage from a mole/tile drained block is captured." requiredLabel={true}></CheckboxPack>

                                {specifyWetland && (
                                    <SelectPack name="artificialWetlandCondition" dataWidth="50" meta={{ nrf: true }} onChange={(e) => onChange(e, { type: "drainage", key: "artificialWetlandCondition" })} value={drainage.artificialWetlandCondition} val={validation.artificialWetlandCondition} id="artificialWetlandCondition" label="Wetland condition" requiredLabel={true}>
                                        <option value="" disabled={true}>
                                            Select a wetland condition
                                        </option>
                                        {drainageWetlandConditions.map((w) => (
                                            <option value={w.value} key={w.value}>
                                                {w.text}
                                            </option>
                                        ))}
                                    </SelectPack>
                                )}
                                {wetlandCondition && (
                                    <Alert
                                        type="info"
                                        text={
                                            <div>
                                                <b>{wetlandCondition.bold}</b> <span>- {wetlandCondition.text}</span>
                                            </div>
                                        }
                                    />
                                )}

                                {specifyWetland && (
                                    <div className="Field-group">
                                        <InputPack id="artificialWetlandPercentage" name="artificialWetlandPercentage" dataWidth="50" type="text" label="Wetland percentage" onChange={(e) => onChange(e, { type: "drainage", key: "artificialWetlandPercentage" })} uom="%" meta={{ nrf: true }} value={drainage.artificialWetlandPercentage} val={validation.artificialWetlandPercentage} requiredLabel={true} placeholder="Wetland area as a % of the block" />
                                        <InputPack id="artificialWetlandArea" name="artificialWetlandArea" dataWidth="50" type="text" label="Wetland area" onChange={(e) => onChange(e, { type: "drainage", key: "artificialWetlandArea" })} uom="ha" meta={{ nrf: true }} value={drainage.artificialWetlandArea} val={validation.artificialWetlandArea} requiredLabel={true} placeholder="Wetland area in hectares" />
                                        <InputPack id="blockArea" name="blockArea" dataWidth="50" type="text" label="Block area" uom="ha" disabled={true} meta={{ nrf: true }} value={blockArea} requiredLabel={true} placeholder="" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="Modal-footer">
                        <div className="ButtonBar">
                            <div className="ButtonBar-left">
                                <button type="button" onClick={() => cancel()} className="Button Button--secondary" id="drainage-details-cancel">
                                    Cancel
                                </button>
                            </div>
                            <div className="ButtonBar-right">
                                <button type="button" onClick={() => save()} className="Button" id="drainage-details-save">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(withAppContext, withActions({ toggleQuickTips }))(DrainageModal);
