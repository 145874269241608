import React, { useState, useEffect, useCallback } from "react";
import { compose, withAppContext } from "containers/HOC";
import { useGoogleMap } from "components/Map/hooks";
import { v4 as uuidv4 } from "uuid";
import * as geoJsonUtils from "common/geoJsonUtils";
import { getSoilMap, getRisk } from "containers/Soils/_actions";
import { useSelector, useDispatch } from "react-redux";
import Page from "components/Page";
import PageBody from "components/PageBody";
import * as utils from "common/utils";
import Spinner from "components/Spinner/Spinner";
import "rc-slider/assets/index.css";
import Button from "components/Button/Button";
import * as icons from "common/icons";
import PageHead from "components/PageHead";
import { useAuthContext } from "common/hooks";
import { Redirect } from "react-router-dom";

const INIT_MAP = { features: [], type: "FeatureCollection" };
const DAIRY_RSU = 20;
const BEEF_RSU = 10;
const SHEEP_RSU = 6;

const DAIRY_N_ADDED = 163;
const BEEF_N_ADDED = 65;
const SHEEP_N_ADDED = 40;

const DAIRY_P_ADDED = 3;
const BEEF_P_ADDED = 3;
const SHEEP_P_ADDED = 3;

const DAIRY_RSU_TO_SR = 9.1;
const BEEF_RSU_TO_SR = 6;
const SHEEP_RSU_TO_SR = 1;

const Index = () => {
    const [geoJson, setGeoJson] = useState(INIT_MAP);
    const { google, map, setMapRef } = useGoogleMap();
    const [mapData, setMapData] = useState();
    const [loaded, setLoaded] = useState(false);
    const [dairyRsu, setDairyRsu] = useState(DAIRY_RSU);
    const [beefRsu, setBeefRsu] = useState(BEEF_RSU);
    const [sheepRsu, setSheepRsu] = useState(SHEEP_RSU);
    const [dairyNAdded, setDairyNAdded] = useState(DAIRY_N_ADDED);
    const [beefNAdded, setBeefNAdded] = useState(BEEF_N_ADDED);
    const [sheepNAdded, setSheepNAdded] = useState(SHEEP_N_ADDED);
    const [dairyPAdded, setDairyPAdded] = useState(DAIRY_P_ADDED);
    const [beefPAdded, setBeefPAdded] = useState(BEEF_P_ADDED);
    const [sheepPAdded, setSheepPAdded] = useState(SHEEP_P_ADDED);
    const [runRisk, setRunRisk] = useState(true);
    useOnPolygonAdd(mapData, setGeoJson);
    useOnPolygonEdit(mapData, setGeoJson);
    const drawnArea = geoJson?.properties?.drawnArea;
    const isSystemAdmin = useAuthContext()?.isSystemAdmin;
    const [soilMap, mapLoading] = useSoilMap(geoJson, drawnArea);
    const [risk, riskError, riskLoading] = useRisk(soilMap, dairyRsu, beefRsu, sheepRsu, dairyNAdded, beefNAdded, sheepNAdded, dairyPAdded, beefPAdded, sheepPAdded, setRunRisk, runRisk);
    const climate = soilMap && soilMap.length > 0 && soilMap[0].climate;
    const longitude = climate && climate.longitude;
    const [toggleAdjustments, setToggleAdjustments] = useState(false);

    useEffect(() => {
        if (longitude) setRunRisk(true);
    }, [longitude]);

    const deleteLand = useCallback(
        (mapData) => {
            mapData?.forEach((feature) => {
                mapData.remove(feature);
            });
            mapData?.setDrawingMode(null);
            setGeoJson(mapData);
        },
        [setGeoJson]
    );

    useEffect(() => {
        if (mapData && !loaded) {
            setLoaded(true);
            mapData?.forEach((feature) => {
                mapData.remove(feature);
            });
        }
    }, [mapData, loaded]);

    useEffect(() => {
        if (map && google && !mapData) {
            const mapData = initMapData(map, geoJson, google);
            setMapData(mapData);
        }
    }, [map, google, geoJson, mapData]);

    const reset = () => {
        deleteLand(mapData);
        setDairyNAdded(DAIRY_N_ADDED);
        setBeefNAdded(BEEF_N_ADDED);
        setSheepNAdded(SHEEP_N_ADDED);
        setDairyPAdded(DAIRY_P_ADDED);
        setBeefPAdded(BEEF_P_ADDED);
        setSheepPAdded(SHEEP_P_ADDED);
        setDairyRsu(DAIRY_RSU);
        setBeefRsu(BEEF_RSU);
        setSheepRsu(SHEEP_RSU);
    };

    const getAnimalIcon = (enterpriseType) => {
        if (!enterpriseType) return icons.unknown;

        const type = enterpriseType.value || enterpriseType;
        if (!type || type === Object(type)) return icons.unknown;

        switch (type.toLowerCase()) {
            case "sheep":
                return icons.sheepOutline;
            case "beef":
                return icons.beefOutline;
            case "dairygrazing":
                return icons.dairyOutline;
            case "deer":
                return icons.deer;
            case "dairy":
            case "dairyreplacements":
                return icons.dairyOutline;
            case "dairygoat":
                return icons.dairyGoat;
            case "outdoorpigs":
                return icons.pig;
            case "other":
                return icons.other;
            default:
                return icons.unknown;
        }
    };

    const EnterpriseEstimate = ({ enterprise, avgNAdded, avgPAdded, avgRsu }) => {
        const nWeighted = climate && risk && soilMap && soilMap.reduce((acc, sm) => (!risk[sm.soilOrder] || !risk[sm.soilOrder][enterprise] ? acc : acc + risk[sm.soilOrder][enterprise].n_loss * sm.percentageOfBlock), 0) / soilMap.reduce((acc, sm) => acc + sm.percentageOfBlock, 0);
        const pWeighted = climate && risk && soilMap && soilMap.reduce((acc, sm) => (!risk[sm.soilOrder] || !risk[sm.soilOrder][enterprise] ? acc : acc + risk[sm.soilOrder][enterprise].p_loss * sm.percentageOfBlock), 0) / soilMap.reduce((acc, sm) => acc + sm.percentageOfBlock, 0);
        const ghgWeighted = climate && risk && soilMap && soilMap.reduce((acc, sm) => (!risk[sm.soilOrder] || !risk[sm.soilOrder][enterprise] ? acc : acc + risk[sm.soilOrder][enterprise].ghg * sm.percentageOfBlock), 0) / soilMap.reduce((acc, sm) => acc + sm.percentageOfBlock, 0);

        return (
            <div className="u-ml-sm u-flex u-flexJustifyBetween">
                <div className="h4 u-font-grey u-flex u-flexAlignItemsCenter">
                    <div className="u-mr-sm">
                        <img src={getAnimalIcon(enterprise)} height="30" alt={enterprise} />
                    </div>
                    <div className="u-text-lg u-mr-sm">{utils.enumToSentence(enterprise)}</div>
                    <div>{toggleAdjustments === enterprise ? <i className="icon icon-arrow-up u-link" onClick={() => setToggleAdjustments(false)}></i> : <i className="icon icon-arrow-down u-link" onClick={() => setToggleAdjustments(enterprise)}></i>}</div>
                </div>
                {nWeighted ? (
                    <div className="u-flex">
                        <div className="NutrientPanelMedium ColourPanelModern--nloss  u-mr-md">
                            <div className="u-pl-md">
                                <h5>Nitrogen</h5>
                                <div>
                                    <div className="HeroValue-item-number">
                                        {utils.round(nWeighted, 0)} <small>kg/ha</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="NutrientPanelMedium ColourPanelModern--ploss u-mr-md">
                            <div className="u-pl-md">
                                <h5>Phosphorus</h5>
                                <div>
                                    <div className="HeroValue-item-number">
                                        {utils.round(pWeighted, 1)} <small>kg/ha</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="NutrientPanelMedium ColourPanelModern--ghg">
                            <div className="u-pl-md">
                                <h5>GHG</h5>
                                <div>
                                    <div className="HeroValue-item-number">
                                        {utils.round(ghgWeighted, 0)} <small>kg/ha</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="u-pt-lg">
                        <Spinner dark small />
                    </div>
                )}
            </div>
        );
    };

    if (!isSystemAdmin) {
        return <Redirect to="/" />;
    }

    return (
        <Page>
            <PageHead>
                <div className="FarmTitle">
                    <div className="FarmTitle-heading">
                        <span id="page-head-title" className="FarmTitle-name">
                            <h2>Land risk estimation</h2>
                        </span>
                    </div>
                </div>
                <div className="PageHead-actions">
                    <b>Overseer AI Model - Beta 1.0.0.0</b>
                </div>
            </PageHead>
            <PageBody>
                <div className="u-flex u-flexWrap Tile u-mt-md u-pt-md u-pr-md u-pb-md u-pl-md">
                    <div className="u-flex-grow RiskTool-container">
                        <div ref={setMapRef} className="RiskTool-map" />
                    </div>

                    <div className="RiskTool-content">
                        {climate ? (
                            <div>
                                <div>
                                    <div>
                                        <div className="h2 u-font-600">Land risk</div>
                                        <div className="u-font-grey u-font-sm">Weighted average based on soil results below</div>
                                    </div>

                                    <div className="u-mb-md">
                                        <div className={`u-mt-sm u-mr-md u-pl-sm ${toggleAdjustments === "dairy" ? "ColourPanelModern--selected" : ""}`}>
                                            <EnterpriseEstimate enterprise="dairy" avgNAdded={DAIRY_N_ADDED} avgPAdded={DAIRY_P_ADDED} avgRsu={DAIRY_RSU} />
                                            {toggleAdjustments === "dairy" && (
                                                <EnterpriseAdjustment
                                                    rsuValues={{
                                                        veryLow: 5,
                                                        low: 10,
                                                        typical: DAIRY_RSU,
                                                        high: 35,
                                                        veryHigh: 40,
                                                    }}
                                                    nAddedValues={{
                                                        veryLow: 0,
                                                        low: 50,
                                                        typical: DAIRY_N_ADDED,
                                                        high: 200,
                                                        veryHigh: 400,
                                                    }}
                                                    pAddedValues={{
                                                        veryLow: 0,
                                                        low: 1,
                                                        typical: DAIRY_P_ADDED,
                                                        high: 5,
                                                        veryHigh: 10,
                                                    }}
                                                    onRsuChange={setDairyRsu}
                                                    onNAddedChange={setDairyNAdded}
                                                    onPAddedChange={setDairyPAdded}
                                                    rsu={dairyRsu}
                                                    nadded={dairyNAdded}
                                                    padded={dairyPAdded}
                                                    stockRateFactor={DAIRY_RSU_TO_SR}
                                                />
                                            )}
                                        </div>
                                        <div className={`u-mt-sm u-mr-md u-pl-sm ${toggleAdjustments === "sheep" ? "ColourPanelModern--selected" : ""}`}>
                                            <EnterpriseEstimate enterprise="sheep" avgNAdded={SHEEP_N_ADDED} avgPAdded={SHEEP_P_ADDED} avgRsu={SHEEP_RSU} />
                                            {toggleAdjustments === "sheep" && (
                                                <EnterpriseAdjustment
                                                    rsuValues={{
                                                        veryLow: 1,
                                                        low: 3,
                                                        typical: SHEEP_RSU,
                                                        high: 10,
                                                        veryHigh: 15,
                                                    }}
                                                    nAddedValues={{
                                                        veryLow: 0,
                                                        low: 5,
                                                        typical: SHEEP_N_ADDED,
                                                        high: 150,
                                                        veryHigh: 300,
                                                    }}
                                                    pAddedValues={{
                                                        veryLow: 0,
                                                        low: 1,
                                                        typical: SHEEP_P_ADDED,
                                                        high: 5,
                                                        veryHigh: 10,
                                                    }}
                                                    onRsuChange={setSheepRsu}
                                                    onNAddedChange={setSheepNAdded}
                                                    onPAddedChange={setSheepPAdded}
                                                    rsu={sheepRsu}
                                                    nadded={sheepNAdded}
                                                    padded={sheepPAdded}
                                                    stockRateFactor={SHEEP_RSU_TO_SR}
                                                />
                                            )}
                                        </div>
                                        <div className={`u-mt-sm u-mr-md u-pl-sm ${toggleAdjustments === "beef" ? "ColourPanelModern--selected" : ""}`}>
                                            <EnterpriseEstimate enterprise="beef" avgNAdded={BEEF_N_ADDED} avgPAdded={BEEF_P_ADDED} avgRsu={BEEF_RSU} />
                                            {toggleAdjustments === "beef" && (
                                                <EnterpriseAdjustment
                                                    rsuValues={{
                                                        veryLow: 1,
                                                        low: 3,
                                                        typical: BEEF_RSU,
                                                        high: 20,
                                                        veryHigh: 35,
                                                    }}
                                                    nAddedValues={{
                                                        veryLow: 0,
                                                        low: 5,
                                                        typical: BEEF_N_ADDED,
                                                        high: 150,
                                                        veryHigh: 300,
                                                    }}
                                                    pAddedValues={{
                                                        veryLow: 0,
                                                        low: 1,
                                                        typical: BEEF_P_ADDED,
                                                        high: 5,
                                                        veryHigh: 10,
                                                    }}
                                                    onRsuChange={setBeefRsu}
                                                    onNAddedChange={setBeefNAdded}
                                                    onPAddedChange={setBeefPAdded}
                                                    rsu={beefRsu}
                                                    nadded={beefNAdded}
                                                    padded={beefPAdded}
                                                    stockRateFactor={BEEF_RSU_TO_SR}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <>
                                    <div className="u-pt-md">
                                        <div className="h2 u-font-600">Location details</div>

                                        <div className="u-mt-md u-pl-md u-pr-md u-pb-md">
                                            <div className="u-flex">
                                                <div className="u-pr-xl u-mr-xl">
                                                    <div className="h4">Area</div>
                                                    <div className="u-font-grey u-font-sm">{utils.round(drawnArea, 1)}</div>
                                                    <div className="h4 u-mt-lg">Temperature</div>
                                                    <div className="u-font-grey u-font-sm">{utils.round(climate.averageTemp, 1)}</div>
                                                </div>
                                                <div className="u-pr-xl u-mr-xl">
                                                    <div className="h4">Latitude</div>
                                                    <div className="u-font-grey u-font-sm">{utils.round(climate.latitude, 6)}</div>
                                                    <div className="h4 u-mt-lg">Rainfall</div>
                                                    <div className="u-font-grey u-font-sm">{utils.round(climate.averageRain, 0)}</div>
                                                </div>
                                                <div className="u-pl-lg">
                                                    <div className="h4">Longitude</div>
                                                    <div className="u-font-grey u-font-sm">{utils.round(climate.longitude, 6)}</div>
                                                    <div className="h4 u-mt-lg">PET</div>
                                                    <div className="u-font-grey u-font-sm">{utils.round(climate.annualPetInMM, 0)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="u-pt-md">
                                        <div className="h2 u-font-600">Soils</div>
                                        {soilMap
                                            .filter((p) => p.percentageOfBlock > 1)
                                            .map((sm) => {
                                                const error = riskError && riskError[sm.soilOrder];
                                                const loading = riskLoading && riskLoading.includes(sm.soilOrder);
                                                const riskValues = risk && risk[sm.soilOrder];
                                                return (
                                                    <div className="u-flex u-mt-md u-pr-md" key={sm.soilOrder}>
                                                        <div style={{ width: "220px" }}>
                                                            <div>
                                                                {sm.soilOrder} ({sm.source})
                                                            </div>
                                                            <div className="h2">{utils.round(sm.percentageOfBlock, 0)}%</div>
                                                        </div>
                                                        <div className="u-flex u-flex-grow">
                                                            {error && <div className="u-ml-md u-font-red">{error.message}</div>}
                                                            {loading && (
                                                                <div className="u-pt-lg">
                                                                    <Spinner dark small />
                                                                </div>
                                                            )}
                                                            {riskValues &&
                                                                Object.keys(riskValues).map((k) => (
                                                                    <div className="u-pl-md u-ml-md u-flex-grow">
                                                                        <div className="h4 u-font-grey u-flex u-flexAlignItemsCenter u-pl-lg">
                                                                            <div className="u-mr-sm">
                                                                                <img src={getAnimalIcon(k)} height="24" alt={k} />
                                                                            </div>
                                                                            <div className="u-text-md u-mr-sm">{utils.enumToSentence(k)}</div>
                                                                        </div>

                                                                        <div className="NutrientPanel ColourPanelModern--nloss u-flex u-flexAlignItemsEnd u-flexJustifyBetween u-mb-sm">
                                                                            <div className="u-font-600">Nitrogen</div>
                                                                            <div className="u-flex u-flexAlignItemsEnd">
                                                                                <div className="u-font-lg  u-font-600">{utils.round(riskValues[k].n_loss, 0)}</div>
                                                                                <div className="u-font-grey u-font-sm u-ml-sm">kg/ha</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="NutrientPanel ColourPanelModern--ploss u-flex u-flexAlignItemsEnd u-flexJustifyBetween u-mb-sm">
                                                                            <div className="u-font-600">Phosphorus</div>
                                                                            <div className="u-flex u-flexAlignItemsEnd">
                                                                                <div className="u-font-lg  u-font-600">{utils.round(riskValues[k].p_loss, 0)}</div>
                                                                                <div className="u-font-grey u-font-sm u-ml-sm">kg/ha</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="NutrientPanel ColourPanelModern--ghg u-flex u-flexAlignItemsEnd u-flexJustifyBetween u-mb-sm">
                                                                            <div className="u-font-600">GHG</div>
                                                                            <div className="u-flex u-flexAlignItemsEnd">
                                                                                <div className="u-font-lg u-font-600">{utils.round(riskValues[k].ghg, 0)}</div>
                                                                                <div className="u-font-grey u-font-sm u-ml-sm">kg/ha</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>

                                    <div className="u-flex">
                                        <Button onClick={() => reset(mapData)} className="u-mt-md u-mr-md" secondary>
                                            Reset
                                        </Button>
                                    </div>
                                </>
                            </div>
                        ) : (
                            <>
                                {mapLoading ? (
                                    <div className="u-textCenter u-flex u-mt-lg u-flexCenter">
                                        <Spinner dark small />

                                        <div className="h4 u-ml-md">Loading climate and soil....</div>
                                    </div>
                                ) : (
                                    <div className="Panel">
                                        <div className="Tile-body ">
                                            <div className="Tile-body-message">
                                                <img src={icons.add} alt="Plus" />
                                                <h2 className="u-mt-sm">Draw your area</h2>
                                                <h4 className="u-mt-sm">you can edit this shape later</h4>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </PageBody>
        </Page>
    );
};

export default compose(withAppContext)(Index);

function initMapData(map, geoJson, google) {
    if (google && map && geoJson) {
        const data = new google.maps.Data({
            map,
            controlPosition: google.maps.ControlPosition.TOP_CENTER,
            controls: ["Polygon"],
            drawingMode: "Polygon",
            featureFactory: (geometry) => {
                return new google.maps.Data.Feature({
                    id: uuidv4(),
                    properties: {
                        layer: "Block",
                        selected: true,
                        visible: true,
                    },
                    geometry,
                });
            },
        });
        data.addGeoJson(geoJson);
        data.setStyle((feature) => {
            const hovered = feature.getProperty("hovered");
            const selected = feature.getProperty("selected");
            const visible = feature.getProperty("visible");
            const error = feature.getProperty("error");
            const layerColor = geoJsonUtils.getMapLayerColor("Block");
            let zIndex = 0;

            return {
                strokeColor: error ? "red" : layerColor,
                strokeWeight: hovered || error ? 4 : 3,
                fillColor: layerColor,
                fillOpacity: 0.3,
                editable: selected,
                zIndex,
                visible,
            };
        });
        return data;
    }
}

function useOnPolygonAdd(mapData, setGeoJson) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            mapData.addListener("addfeature", ({ feature }) => {
                feature.toGeoJson((geoJsonFeature) => {
                    const drawnArea = geoJsonUtils.area(geoJsonFeature);
                    feature.setProperty("drawnArea", drawnArea);
                    setGeoJson((prevGeoJson) => {
                        const newGeoJson = { ...prevGeoJson };
                        newGeoJson.properties = { ...prevGeoJson.properties, drawnArea };
                        if (!newGeoJson.features) newGeoJson.features = [];
                        newGeoJson.features.push(geoJsonFeature);
                        return newGeoJson;
                    });
                });
            });
            setRunOnce(true);
        }
    }, [mapData, runOnce, setGeoJson]);
}

function useOnPolygonEdit(mapData, setGeoJson) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            mapData.addListener("setgeometry", ({ feature }) => {
                feature.toGeoJson((geoJsonFeature) => {
                    const drawnArea = geoJsonUtils.area(geoJsonFeature);
                    feature.setProperty("drawnArea", drawnArea);
                    setGeoJson((prevGeoJson) => {
                        const newGeoJson = { ...prevGeoJson };
                        newGeoJson.properties = { ...prevGeoJson.properties, drawnArea };
                        newGeoJson.features.push(geoJsonFeature);
                        return newGeoJson;
                    });
                });
            });
            setRunOnce(true);
        }
    }, [mapData, runOnce, setGeoJson]);
}

function useRisk(soilMap, dairyRsu, beefRsu, sheepRsu, dairyNAdded, beefNAdded, sheepNAdded, dairyPAdded, beefPAdded, sheepPAdded, setRunRisk, runRisk) {
    const [risk, setRisk] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchRiskData = async (soil, enterprise, rsu, nadded, padded) => {
            await dispatch(getRisk.send({ ...soil, enterprise, nadded, padded, rsu }))
                .then((response) => {
                    setRisk((prevRisk) => {
                        const update = prevRisk && prevRisk[soil.soilOrder] ? { ...prevRisk[soil.soilOrder], [enterprise]: response } : { [enterprise]: response };
                        return { ...prevRisk, [soil.soilOrder]: update };
                    });
                    setLoading((prevLoading) => prevLoading.filter((sl) => sl !== soil.soilOrder));
                })
                .catch((error) => {
                    setError({ ...error, [soil.soilOrder]: error });
                });
        };

        const asyncFetchRiskData = async () => {
            for (const soil of soilMap) {
                for (const enterprise of ["dairy", "sheep", "beef"]) {
                    const rsu = enterprise === "dairy" ? dairyRsu : enterprise === "beef" ? beefRsu : sheepRsu;
                    const nadded = enterprise === "dairy" ? dairyNAdded : enterprise === "beef" ? beefNAdded : sheepNAdded;
                    const padded = enterprise === "dairy" ? dairyPAdded : enterprise === "beef" ? beefPAdded : sheepPAdded;
                    await fetchRiskData(soil, enterprise, rsu, nadded, padded);
                }
            }
        };

        if (soilMap && soilMap.length > 0 && runRisk) {
            setRisk();
            setLoading(soilMap.map((sm) => sm.soilOrder));
            asyncFetchRiskData();
        }
    }, [dispatch, soilMap, dairyRsu, beefRsu, sheepRsu, dairyNAdded, beefNAdded, sheepNAdded, runRisk, setRunRisk, dairyPAdded, beefPAdded, sheepPAdded]);

    return [risk, error, loading];
}

function useSoilMap(geoJson, drawnArea) {
    const online = useSelector((state) => state.app.online);
    const dispatch = useDispatch();
    const [soilMap, setSoilMap] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        const fetchFslData = async () => {
            let { features = [] } = geoJson;
            setLoading(true);
            await dispatch(getSoilMap.send(features, drawnArea))
                .then((response) => {
                    setSoilMap(response);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        if (online && geoJson && geoJson.features?.length > 0 && drawnArea > 0) {
            fetchFslData();
        } else {
            setSoilMap();
        }
    }, [geoJson, online, dispatch, drawnArea]);

    return [soilMap, loading];
}

function EnterpriseAdjustment({ rsu, nadded, padded, onRsuChange, onNAddedChange, onPAddedChange, rsuValues, nAddedValues, pAddedValues, stockRateFactor = 1 }) {
    return (
        <div className="u-pb-lg u-mb-lg u-pl-md u-pr-md">
            <div className="h4 u-mt-md">Stocking rate</div>
            <div className="u-ml-sm u-font-lightgrey u-font-md">
                <b>{utils.round(rsu / stockRateFactor, 2)}</b> animals/ha <span className="u-font-sm">({rsu} RSU)</span>
            </div>
            <div className="u-flex u-flexJustifyBetween u-pt-sm">
                <Button onClick={() => onRsuChange(rsuValues.veryLow)} secondaryGrey={rsu !== rsuValues.veryLow} className="u-mr-md Button-fill">
                    Very low
                </Button>
                <Button onClick={() => onRsuChange(rsuValues.low)} secondaryGrey={rsu !== rsuValues.low} className="u-mr-md Button-fill">
                    Low
                </Button>
                <Button onClick={() => onRsuChange(rsuValues.typical)} secondaryGrey={rsu !== rsuValues.typical} className="u-mr-md Button-fill">
                    Typical
                </Button>
                <Button onClick={() => onRsuChange(rsuValues.high)} secondaryGrey={rsu !== rsuValues.high} className="u-mr-md Button-fill">
                    High
                </Button>
                <Button onClick={() => onRsuChange(rsuValues.veryHigh)} secondaryGrey={rsu !== rsuValues.veryHigh} className="Button-fill">
                    Very high
                </Button>
            </div>

            <div className="h4 u-mt-md">N added</div>
            <div className="u-ml-sm u-font-lightgrey u-font-sm">
                <b>{nadded}</b>/ha
            </div>
            <div className="u-flex u-flexJustifyBetween u-pt-sm">
                <Button onClick={() => onNAddedChange(nAddedValues.veryLow)} secondaryGrey={nadded !== nAddedValues.veryLow} className="u-mr-md Button-fill">
                    Very low
                </Button>
                <Button onClick={() => onNAddedChange(nAddedValues.low)} secondaryGrey={nadded !== nAddedValues.low} className="u-mr-md Button-fill">
                    Low
                </Button>
                <Button onClick={() => onNAddedChange(nAddedValues.typical)} secondaryGrey={nadded !== nAddedValues.typical} className="u-mr-md Button-fill">
                    Typical
                </Button>
                <Button onClick={() => onNAddedChange(nAddedValues.high)} secondaryGrey={nadded !== nAddedValues.high} className="u-mr-md Button-fill">
                    High
                </Button>
                <Button onClick={() => onNAddedChange(nAddedValues.veryHigh)} secondaryGrey={nadded !== nAddedValues.veryHigh} className="Button-fill">
                    Very high
                </Button>
            </div>
            <div className="h4 u-mt-md">P added</div>
            <div className="u-ml-sm u-font-lightgrey u-font-sm">
                <b>{padded}</b>/ha
            </div>
            <div className="u-flex u-flexJustifyBetween u-pt-sm">
                <Button onClick={() => onPAddedChange(pAddedValues.veryLow)} secondaryGrey={padded !== pAddedValues.veryLow} className="u-mr-md Button-fill">
                    Very low
                </Button>
                <Button onClick={() => onPAddedChange(pAddedValues.low)} secondaryGrey={padded !== pAddedValues.low} className="u-mr-md Button-fill">
                    Low
                </Button>
                <Button onClick={() => onPAddedChange(pAddedValues.typical)} secondaryGrey={padded !== pAddedValues.typical} className="u-mr-md Button-fill">
                    Typical
                </Button>
                <Button onClick={() => onPAddedChange(pAddedValues.high)} secondaryGrey={padded !== pAddedValues.high} className="u-mr-md Button-fill">
                    High
                </Button>
                <Button onClick={() => onPAddedChange(pAddedValues.veryHigh)} secondaryGrey={padded !== pAddedValues.veryHigh} className="Button-fill">
                    Very high
                </Button>
            </div>
        </div>
    );
}
