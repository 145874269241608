import React from "react";

const ProgressBar = ({ progress, leftLabel, middleLabel, rightLabel, endLabel, colour, showZero, noPadLeft }) => {
    const cssMainStyle = colour ? { backgroundColor: colour, width: progress > 100 ? "100" : progress + "%" } : { width: progress > 100 ? "100" : progress + "%" };
    const cssTrailColour = colour ? { backgroundColor: "#ccc" } : null;
    const showProgress = progress > 0 || showZero;

    return (
        <div className="u-flex">
            {showProgress && (
                <div className={`ProgressBar-wrapper ${noPadLeft ? "u-pl-0" : ""} u-flex-growBig u-pt-sm`}>
                    <div className="ProgressBar-label u-flex u-flexJustifyBetween">
                        <div>{leftLabel}</div>
                        <div>{middleLabel}</div>
                        <div>{rightLabel}</div>
                    </div>
                    <div className="ProgressBar-trail" style={cssTrailColour}>
                        <div className="ProgressBar-value" style={cssMainStyle}></div>
                    </div>
                </div>
            )}
            {endLabel && showProgress && <div className="ProgressBar-endlabel">{endLabel}</div>}
        </div>
    );
};

export default ProgressBar;
