import { TAB } from "containers/BudgetHome/PageTabs";
import TabMessages from "containers/BudgetHome/TabMessages";
import SupplementsActionsBar from "./SupplementsActionsBar";
import { SupplementsGreenBox } from "./SupplementsGreenBox";
import SupplementList from "./SupplementList";
import BlockList from "./BlockList";
import StructureList from "./StructureList";

export default function Supplements({ farm, analysis }) {
    const useNewGreenBox = true;
    return (
        <>
            <TabMessages farm={farm} analysis={analysis} tab={TAB.SUPPLEMENTS} />
            <SupplementsActionsBar farm={farm} analysis={analysis} />
            {useNewGreenBox
                ? <SupplementsGreenBox farm={farm} analysis={analysis} />
                : <SupplementList farm={farm} analysis={analysis} />
            }
            <StructureList farm={farm} analysis={analysis} />
            <BlockList farm={farm} analysis={analysis} />
        </>
    )
}
