import "components/FarmMap/FarmMap.css";
import { useEffect, useState } from "react";
import * as geoJsonUtils from "common/geoJsonUtils";
import Spinner from "components/Spinner/Spinner";
import { useGoogleApi, useGoogleMap } from "components/Map/hooks";
import MapLegend from "components/Map/MapLegend";
import MapZoomButton from "components/Map/MapZoomButton";
import MapBlockLabels from "components/Map/MapBlockLabels";
import { useOnline } from "common/hooks";

export default function FarmMap({ farm, analysis, showUnassignedLand }) {
    const online = useOnline();
    const { map, mapId, setMapRef } = useGoogleMap({ center: { lat: farm.latitude, lng: farm.longitude }, zoom: 12 });
    const geoJson = useAnalysisGeoJson(analysis, showUnassignedLand);

    const mapData = useMapData(map, geoJson);
    useMapStyle(mapData);
    useOnPolygonHover(mapData);

    const mapError = !online && "Overseer is currently offline. Mapping is unavailable.";
    const mapLoading = online && !geoJson && !mapError;
    const showMap = !mapLoading && !mapError;

    return (
        <div className="farm_map">
            {mapLoading && (
                <div className="Tile-body-message">
                    <Spinner dark />
                    <p className="lead u-text-lg u-mt-md">Loading map...</p>
                </div>
            )}
            {!mapLoading && mapError && (
                <div className="Tile-body-message u-textError">
                    <i className="icon icon--md icon-alert" />
                    <p className="lead">{mapError}</p>
                </div>
            )}
            <div className={`map ${showMap ? "" : "u-hidden"}`} ref={setMapRef} />
            <MapBlockLabels mapData={mapData} mapId={mapId} geoJson={geoJson} blocks={analysis.blocks} />
            <MapLegend
                mapData={mapData}
                mapId={mapId}
                layersToShow={[
                    { id: "UnassignedLand", title: "Unassigned land", sortOrder: 0 },
                    { id: "Irrigator", title: "Drawn Irrigators", sortOrder: 0 },
                ]}
            />
            <MapZoomButton mapData={mapData} mapId={mapId} when={geoJson} />
        </div>
    );
}

function useAnalysisGeoJson(analysis, showUnassignedLand) {
    const [geoJson, setGeoJson] = useState();

    // Set geoJson whenever analysis features change.
    useEffect(() => {
        const features = (analysis.features || []).reduce((results, feature) => {
            // Flatten and hide irrigators and unassigned land by default.
            const hiddenLayers = ["Irrigator"];
            if (!showUnassignedLand) hiddenLayers.push("UnassignedLand");
            const flattenedFeatures = geoJsonUtils.flatten(feature, hiddenLayers);
            return [...results, ...flattenedFeatures];
        }, []);
        setGeoJson({ type: "FeatureCollection", features });
    }, [analysis.features, showUnassignedLand]);

    return geoJson;
}

function useMapData(map, geoJson) {
    const google = useGoogleApi();
    const [mapData, setMapData] = useState();

    // Set map data.
    useEffect(() => {
        if (google && map && geoJson && !mapData) {
            const data = new google.maps.Data({ map });
            data.addGeoJson(geoJson);
            setMapData(data);
        }
    }, [google, map, geoJson, mapData]);

    // Refresh map if geoJson changes.
    useEffect(() => {
        if (mapData && geoJson) {
            mapData.forEach((feature) => mapData.remove(feature));
            mapData.addGeoJson(geoJson);
        }
    }, [mapData, geoJson]);

    return mapData;
}

function useMapStyle(mapData) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            mapData.setStyle((feature) => {
                const hovered = feature.getProperty("hovered");
                const visible = feature.getProperty("visible");
                const layer = feature.getProperty("layer");
                const layerColor = geoJsonUtils.getMapLayerColor(layer);

                let zIndex = 0;
                if (hovered) {
                    zIndex = 2;
                } else if (layer === "Block") {
                    zIndex = 1;
                }

                return {
                    strokeColor: layerColor,
                    strokeWeight: hovered ? 4 : 3,
                    fillColor: hovered || layer === "Irrigator" ? layerColor : "white",
                    fillOpacity: 0.6,
                    zIndex,
                    visible,
                };
            });
            setRunOnce(true);
        }
    }, [mapData, runOnce]);
}

function useOnPolygonHover(mapData) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            // Add 'mouseover' event for hover styling.
            mapData.addListener("mouseover", ({ feature }) => {
                if (feature.getProperty("blockId")) {
                    feature.setProperty("hovered", true);
                }
            });

            // Add 'mouseout' event for un-hover styling.
            mapData.addListener("mouseout", ({ feature }) => {
                if (feature.getProperty("blockId")) {
                    feature.setProperty("hovered", false);
                }
            });

            setRunOnce(true);
        }
    }, [mapData, runOnce]);
}
