import SelectPack from "components/SelectPack";
import NumericInputPack from "components/NumericInputPack";
import * as utils from "common/utils";
import * as validations from "common/validations";
import SolidApplications from "./SolidApplications";
import { useRefData } from "common/hooks";

export default function SolidEffluent({ solidManagement, validate, validation, onChange, type, activeControls, isMandatory, cancelApplication, saveApplication, deleteApplication, modalInlineClose, modalInlineOpen, analysis, confirm, isFirst }) {
    const refData = useRefData();

    const { solidsManagement = [], effluentStorageMethod = [] } = refData;
    const filteredSolidsManagement = solidsManagement.filter((s) => !(isMandatory && s.value === "NotSeparated"));

    if (!activeControls[`${type}_solidsHeader`]) return null;

    return (
        <div className={`Grid Grid--withGutter ${isFirst ? "" : "u-mt-lg"}`}>
            <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                <h3>Solids separated from effluent before transfer to pond</h3>
                <SelectPack meta={{ nrf: true }} isHidden={!activeControls[`${type}_disposalMethod`]} onChange={(e) => onChange(e, { type: type, key: "disposalMethod" })} value={solidManagement.disposalMethod} val={validation[`${type}_disposalMethod`]} id={`${type}_disposalMethod`} label="Separated solid effluent management" requiredLabel={true}>
                    <option value="" disabled={true}>
                        Select how separated solid effluent is managed
                    </option>
                    {utils.mapRefDataItems(filteredSolidsManagement)}
                </SelectPack>
                <div className="Field-group">
                    <SelectPack meta={{ nrf: true }} isHidden={!activeControls[`${type}_effluentStorageMethod`]} onChange={(e) => onChange(e, { type: type, key: "effluentStorageMethod" })} value={solidManagement.effluentStorageMethod} val={validation[`${type}_effluentStorageMethod`]} id={`${type}_effluentStorageMethod`} label="Storage method" dataWidth="50" requiredLabel={true}>
                        <option value="" disabled={true}>
                            Select a storage method
                        </option>
                        {utils.mapRefDataItems(effluentStorageMethod)}
                    </SelectPack>
                    <NumericInputPack name="timeInStorage" label="Time in storage" isHidden={!activeControls[`${type}_timeInStorage`]} requiredLabel={true} val={validation[`${type}_timeInStorage`]} dataWidth="50" uom="months" id={`${type}_timeInStorage`} onChange={(e) => onChange(e, { type: type, key: "timeInStorage" })} decimalPlaces={0} value={solidManagement.timeInStorage} />
                </div>
            </div>
            {activeControls[`${type}_solidApplications`] && (
                <div className="Grid-cell">
                    <SolidApplications validation={validation} cancelApplication={cancelApplication} saveApplication={(application, dirty) => saveApplication(application, dirty)} deleteApplication={(id) => deleteApplication(id)} modalInlineClose={modalInlineClose} modalInlineOpen={modalInlineOpen} confirm={confirm} type={type} prefix="solid" title="Application of separated solid effluent on blocks" blocks={analysis.blocks.filter((b) => b.type !== "ProductiveOutdoorPigs" && (b.type.startsWith("Productive") || b.type === "FodderCrop"))} applications={solidManagement.solidApplications || []} validate={validate} />
                </div>
            )}
        </div>
    )
}

export function validateSolidEffluent(activeControls, validation, solidManagement, currentValidation, source, setVal, type) {
    let message = undefined;
    let objkey = undefined;
    let key = undefined;

    objkey = "disposalMethod";
    key = `${type}_${objkey}`;
    if (activeControls[key]) {
        message = validations.required(solidManagement[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }

    objkey = "effluentStorageMethod";
    key = `${type}_${objkey}`;
    if (activeControls[key]) {
        message = validations.required(solidManagement[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }

    objkey = "timeInStorage";
    key = `${type}_${objkey}`;
    if (activeControls[key]) {
        message = validations.required(solidManagement[objkey]);
        message = message || validations.isNumeric(solidManagement[objkey]);
        message = message || validations.range(1, 300)(solidManagement[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }

    objkey = "solidApplications";
    key = `${type}_${objkey}`;
    if (activeControls[key]) {
        const { solidApplications = [] } = solidManagement;
        /*eslint-disable no-unused-vars*/
        for (const application of solidApplications) {
            if (!application.months || !application.months.length > 0) {
                key = `monthGroupVal_${application.id}`;
                validation[key] = setVal(key, currentValidation, source, "Required");
            }
        }

        if (solidApplications.length === 0) {
            key = `${type}_applications`;
            validation[key] = setVal(key, currentValidation, source, "Required");
        }
    }
}

export function validateSolidApplication(validation, application, currentValidation, source, setVal, type, monthsReq) {
    let error = undefined;
    const val = {};

    let key = `${type}_blockIds`;
    val.blockIds = validations.required(application.blockIds);
    validation[key] = setVal(key, currentValidation, source, val.blockIds);
    error = val.blockIds;

    key = `monthGroupVal_${application.id}`;
    val.months = !monthsReq || (application.months || []).length > 0 ? undefined : "Required";
    validation[key] = setVal(key, currentValidation, source, val.months);
    error = error || val.months;

    return error === undefined;
}
