import Spinner from "components/Spinner/Spinner";

export default function TileBody({ children, className, waiting, grey }) {
    let cssClasses = "Tile-body";
    if (grey) cssClasses += " u-bg-grey";
    if (waiting) cssClasses += " waiting";
    if (className) cssClasses += ` ${className}`;

    return (
        <div className={cssClasses}>
            <div className="Tile-loader">
                <Spinner />
                <p className="lead u-textWhite">Loading...</p>
            </div>
            {children}
        </div>
    )
}
