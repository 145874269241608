import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as cropUtils from "../Crops/_utils";
import { useOnline, useRefData } from "common/hooks";

export default function IrrigationCard({ farm, analysis, block, intersections }) {
    const online = useOnline();
    const refData = useRefData();

    const _blockIcon = utils.getBlockIcon(block.type);
    const _blockType = utils.getBlockTypeText(refData, block);
    const blockIrrigators = [];
    const intersectingIrrigators = [];
    const rotationYear = cropUtils.rotationYear(block, true);
    const { messages = [] } = analysis;
    const hasErrors = online && messages.some((m) => m.category === "Irrigation" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error");
    const hasWarnings = online && messages.some((m) => m.category === "Irrigation" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Warning");

    /*eslint-disable no-unused-vars*/
    for (const irrigator of analysis.irrigators) {
        const { applications = [] } = irrigator;
        var found = false;
        /*eslint-disable no-unused-vars*/
        for (const application of applications) {
            if (application.blockIds.includes(block.id)) {
                blockIrrigators.push(irrigator);
                found = true;
                break;
            }
        }

        const isIrrigatorDrawn = (analysis.features || []).some((f) => f.properties.irrigatorId === irrigator.id);
        if (!found && isIrrigatorDrawn) {
            const irrigatorIntersection = intersections.find((i) => i.id === irrigator.id);
            if (irrigatorIntersection && irrigatorIntersection.blockIntersections.some((ib) => ib.id === block.id)) {
                intersectingIrrigators.push(irrigator.name);
            }
        }
    }

    const message = intersectingIrrigators.length > 0 ? "Irrigated area for the following irrigators intersects this block but no application exists - " + intersectingIrrigators.join() + ". Edit the irrigator to add this block." : undefined;

    if (blockIrrigators.length === 0) {
        return (
            <div className="FarmTable_wrapper">
                <ul className="DataWidthTable FarmTable">
                    <li className="FarmTable-title" data-width="md-100 xl-30">
                        <div className="FarmTitle">
                            <img className="FarmTitle-icon" src={_blockIcon} alt="Block Type" />
                            <div className="FarmTitle-heading">
                                <span className="FarmTitle-name" title={block.name}>
                                    {block.name}
                                </span>
                                <div className="FarmTitle-sub">{_blockType}</div>
                            </div>
                        </div>
                    </li>
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        {block.isProductive === true && block.type !== "ProductiveOutdoorPigs" ? (
                            message ? (
                                <span className="IconText">
                                    <i className="icon icon-alert u-textWarning"></i> {message}
                                </span>
                            ) : (
                                <span>No irrigation has been defined for this block. Select an existing irrigator or add a new irrigator if required.</span>
                            )
                        ) : (
                            <span>Irrigation is not required for this block</span>
                        )}
                    </li>
                </ul>
            </div>
        );
    }

    const hasAtLeastOneZeroAppliedMonth = block.currentResults && block.currentResults.irrigationResults && blockIrrigators.some((irr) => block.currentResults.irrigationResults[irr.id] && block.currentResults.irrigationResults[irr.id].months.some((m) => m.applied === 0 && m.reportingYear));

    return (
        <div className="FarmTable_wrapper">
            <ul className="DataWidthTable FarmTable">
                <li className="FarmTable-title">
                    {hasWarnings && !hasErrors && (
                        <span className={`Todo Todo--warning`}>
                            <i className="icon icon-alert" />
                        </span>
                    )}
                    {hasErrors && (
                        <span className={`Todo Todo--error error-anchor`}>
                            <i className="icon icon-alert" />
                        </span>
                    )}
                    <div className="FarmTitle">
                        <img className="FarmTitle-icon" src={_blockIcon} alt="Block Type" />
                        <div className="FarmTitle-heading">
                            <span className="FarmTitle-name" title={block.name}>
                                {block.name}
                            </span>
                            <div className="FarmTitle-sub">{_blockType}</div>
                        </div>
                    </div>
                </li>
                {block.isProductive === true && message && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <span className="IconText">
                            <i className="icon icon-dot u-textWarning"></i> {message}
                        </span>
                    </li>
                )}
            </ul>
            <div className="FarmTable-supplementary">
                <div className="FarmTable-supplementary">
                    <div className="Table u-tbl-lg">
                        <table className="Calendar u-tbl-fix">
                            {cropUtils.cropTableHeader(analysis, rotationYear, block, refData, false, 3, 0)}
                            <tbody>
                                {blockIrrigators.map((item, i) => {
                                    const irrigatorResults = block.currentResults && block.currentResults.irrigationResults ? block.currentResults.irrigationResults[item.id] : undefined;
                                    const url = `/app/farm/${farm.id}/analysis/${analysis.id}/irrigation/${item.id}`;
                                    return (
                                        <tr key={i} name={`row_${i}`} id={item.id}>
                                            <td className="td--keyValuePair" colSpan="3">
                                                <span className="FarmTable-key" style={{ display: "contents" }}>
                                                    {utils.valueToText(refData.irrigatorTypes, item.type)}
                                                </span>
                                                <span className="FarmTable-value">
                                                    <Link to={url} className="FarmTable-value-link">
                                                        <span className="td-value-text">{item.name + (irrigatorResults ? " (" + utils.round(irrigatorResults.blockPercentage, 1) + "%)" : "")}</span>
                                                        <span className="icon icon-edit" />
                                                    </Link>
                                                </span>
                                            </td>
                                            {rotationYear.map((month, index) => {
                                                const reportingYear = cropUtils.indexToReportingYear(index, rotationYear);
                                                return renderMonth(item, month, block, reportingYear, index, irrigatorResults, rotationYear.length > 12);
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {hasAtLeastOneZeroAppliedMonth && (
                        <sub>
                            1 -{" "}
                            <a href="https://support.overseer.org.nz/hc/en-us/articles/16309194178329" target="_blank" rel="noreferrer">
                                Click here to see why irrigation applied is 0mm for some months
                            </a>
                        </sub>
                    )}
                </div>
            </div>
        </div>
    );
}

const renderMonth = (irrigator, month, block, reportingYear, index, irrigatorResults, extendedReportingYear) => {
    const icon = utils.getIrrigatorIcon(irrigator.type);
    const { applications = [] } = irrigator;
    let hasApplication = false;
    let blockResults = {};

    /*eslint-disable no-unused-vars*/
    for (const application of applications) {
        const { months = [], blockIds = [] } = application;
        if (months.find((m) => m.month === month && (!extendedReportingYear || (!m.reportingYear && index < 12) || (m.reportingYear && index >= 12))) && blockIds.includes(block.id)) {
            if (reportingYear) blockResults = irrigatorResults ? irrigatorResults.months.find((r) => r.month === month && r.reportingYear) : undefined;
            else blockResults = irrigatorResults ? irrigatorResults.months.find((r) => ((!r.reportingYear && index < 12) || (r.reportingYear && index >= 12)) && r.month === month) : undefined;
            hasApplication = true;
            break;
        }
    }

    const applied = blockResults && blockResults.applied ? blockResults.applied + " mm" : "0 mm";
    return hasApplication ? (
        <td key={index} id={`${block.id}_${irrigator.id}_${month}`} className="Calendar-slot">
            <img className="Calendar-icon u-p-xxs" src={icon} alt="irrigator icon" />
            {(reportingYear || applied !== "0 mm") && (
                <span className="Calendar-icon-text">
                    {applied}
                    {(!blockResults || !blockResults.applied) && <sup>1</sup>}
                </span>
            )}
        </td>
    ) : (
        <td key={index} id={`${block.id}_${month}`} className="Calendar-slot"></td>
    );
};
