import { useState, useEffect } from "react";
import * as utils from "common/utils";
import * as icons from "common/icons";
import { Irrigation } from "components/PlanningReport/Irrigation";
import { StructuresEffluent } from "components/PlanningReport/StructuresEffluent";
import { Ghg }  from "components/PlanningReport/Ghg";
import { Animals } from "components/PlanningReport/Animals";
import { Feed } from "components/PlanningReport/Feed";
import Header from "components/PlanningReport/Header";
import { Fertiliser } from "components/PlanningReport/Fertiliser";
import { Crops } from "components/PlanningReport/Crops";
import { compose, withState, withActions, withAppContext } from "containers/HOC";
import { clearPlanningReport } from "./_actions";
import SectionTabs from "components/PlanningReport/SectionTabs";
import { ClimateSoils } from "components/PlanningReport/ClimateSoils";
import { Nutrients } from "components/PlanningReport/Nutrients";
import { Actions } from "components/PlanningReport/Actions";
import { Comments } from "components/PlanningReport/Comments";
import { getAnalysisViewModel } from "common/viewModels";
import { useModal } from "common/effects";
import AnalysisComparisonModal from "containers/Summary/Modals/AnalysisComparisonModal";
import Summary from "components/PlanningReport/Summary";
import { Blocks } from "components/PlanningReport/Blocks";
import { Drainage } from "components/PlanningReport/Drainage";
import FileSummary from "components/PlanningReport/FileSummary";
import { Panel, PanelBody } from "components/Panel";
import FarmMap from "components/FarmMap/FarmMap";
import { clearBaseAnalysis, setBaseAnalysis } from "containers/BudgetHome/Overview/_actions";
import { setFormState } from "containers/App/_actions";
import PrintDisclaimer from "components/PrintDisclaimer/PrintDisclaimer";
import { getFarmTimeline, ComparisonTable } from "components/AnalysisComparisons/ComparisonUtils";
import { getForestCarbonAsync } from "containers/BudgetHome/Overview/_actions";
import FeatureTracker, { FEATURES, useFeatureTracker } from "components/FeatureTracker/FeatureTracker";
import { FarmTrends } from "components/PlanningReport/FarmTrends";
import { httpClient } from "common/httpClient";
import { useRefData } from "common/hooks";

const PlanningReport = ({ farm, analysis, base, getForestCarbonAsync, planningData = {}, clearPlanningReport, scenarioMode, publication, setBaseAnalysis, setFormState, clearBaseAnalysis, authContext, getFarmTimeline, baseAnalysisMap }) => {
    const refData = useRefData();
    const [sections, setSections] = useState({ summary: true });
    const [printPreview, setPreview] = useState(false);
    const [timelineData, setTimelineData] = useState();
    const [waiting, setWaiting] = useState(false);
    const [budgetModel] = useState(getAnalysisViewModel(farm, analysis, refData));
    const [baseModel, setBaseModel] = useState(base && getAnalysisViewModel(farm, base, refData));
    if (publication) analysis.publication = getPubicationDetails(publication);
    const { openModal, getModal } = useModal();
    const analysisComparisonModal = getModal("AnalysisComparisonModal");
    const baseMap = baseAnalysisMap && baseAnalysisMap.find((b) => b.analysisId === analysis.id);
    const farmId = farm && farm.id;
    const featureTracker = useFeatureTracker(FEATURES.FARM_IMPACT_REPORT, undefined, farm);

    var after = budgetModel;
    var before = baseModel;
    if (baseModel && baseModel.details.year > budgetModel.details.year) {
        after = baseModel;
        before = budgetModel;
    }

    if (before) before.displayName = "Before";
    after.displayName = before ? "After" : after.name;

    //disableStandardPrintHeader
    const [beforeSeq, setBeforeSeq] = useState();
    const [afterSeq, setAfterSeq] = useState();
    const afterBudgetId = after.details.id;
    const beforeBudgetId = before && before.details.id;

    useEffect(() => {
        getForestCarbonAsync(farmId, afterBudgetId)
            .then((seq) => setAfterSeq(seq))
            .catch((error) => console.log(error));
    }, [farmId, afterBudgetId, getForestCarbonAsync]);

    useEffect(() => {
        if (beforeBudgetId) {
            getForestCarbonAsync(farmId, beforeBudgetId)
                .then((seq) => setBeforeSeq(seq))
                .catch((error) => console.log(error));
        }
    }, [farmId, beforeBudgetId, getForestCarbonAsync]);

    const changeBase = () => {
        const viewModel = {
            farm,
            excludedAnalysisIds: [analysis.id],
            onCompare: async (analysisId) => {
                const scenario = await getAnalysisForComparison(farm, analysisId, refData);
                setBaseModel(scenario);
                setBaseAnalysis(analysis.id, analysisId);
            },
        };
        featureTracker.track("Compare base");

        openModal("AnalysisComparisonModal", viewModel);
    };

    useEffect(() => {
        if (!scenarioMode && farmId && baseMap && baseMap.baseId) {
            const includedIds = [baseMap.baseId, baseMap.analysisId];
            let isMounted = true;
            setWaiting(true);
            getFarmTimeline(farmId, [], includedIds)
                .then((response) => isMounted && setTimelineData(response.comparisons[0]))
                .catch((error) => isMounted && setTimelineData({ error }))
                .finally(() => isMounted && setWaiting(false));
            return () => (isMounted = false);
        } else setTimelineData(null);
    }, [farmId, baseMap, getFarmTimeline, scenarioMode]);

    useEffect(() => {
        setFormState("disableStandardPrintHeader", true);
        return () => setFormState("disableStandardPrintHeader", false);
    }, [setFormState]);

    useEffect(() => {
        const setBaseAnalysis = async (id) => {
            const scenario = await getAnalysisForComparison(farm, id, refData);
            if (scenario) {
                setBaseModel(scenario);
            }
        };

        if (!base && baseMap) {
            const allIds = (farm.budgets || []).map((b) => b.id);
            if (allIds.includes(baseMap.baseId)) setBaseAnalysis(baseMap.baseId);
        }
    }, [base, baseAnalysisMap, analysis.id, farm, refData, baseMap]);

    useEffect(() => {
        const { scenarios = [] } = planningData;
        const beforeScenario = scenarios.length > 1 && scenarios.find((s) => s.selected === 2);
        if (beforeScenario) {
            setBaseModel(beforeScenario);
        } else if (analysis.baseBudgetId) {
            const scenario = getAnalysisForComparison(farm, analysis.baseBudgetId, refData);
            setBaseModel(scenario);
        }
    }, [planningData, analysis.baseBudgetId, farm, refData]);

    useEffect(() => {
        return () => {
            clearPlanningReport();
        };
    }, [clearPlanningReport]);

    const togglePreview = () => {
        setPreview(!printPreview);
    };

    const removeBase = () => {
        clearBaseAnalysis(analysis.id);
        setBaseModel(undefined);
    };

    const loading = (base && !timelineData && !scenarioMode) || waiting;

    return (
        <>
            {!scenarioMode && <Header before={before} after={after} changeBase={changeBase} togglePreview={togglePreview} printPreview={printPreview} removeBase={removeBase} />}

            {printPreview ? (
                <>
                    {<FeatureTracker feature={before ? FEATURES.FARM_IMPACT_REPORT : FEATURES.FARM_SUMMARY_REPORT} action="Preview" farm={farm} />}
                    <div className="ReportCover ReportPage">
                        <div className="u-flex u-flexJustifyEnd u-flexAlignItemsCenter">
                            <img className="u-p-5" src={icons.logoPrint()} alt="logo" style={{ width: "200px", height: "50px" }} />
                        </div>
                        <div className="u-flex u-flexColumn u-flexCenter">
                            <div className="ReportCoverPage-title">Overseer</div>
                            <div className="ReportCoverPage-title">{before ? "Farm Impact Report" : "Farm Summary Report"}</div>
                            <div className="u-flex u-flexCenter u-mt-md">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="u-textBold">Printed date:</td>
                                            <td className="u-textRight">{utils.dateLong(new Date())}</td>
                                        </tr>
                                        <tr>
                                            <td className="u-textBold">Printed by:</td>
                                            <td className="u-textRight">{authContext.accountName}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="u-flex u-flexColumn u-flexCenter">
                            <div className="FarmTitle--xl u-flex u-flexAlignItemsCenter u-mb-lg">
                                <img className="FarmTitle-icon" src={icons.farm} alt="Farm icon" />
                                <div>
                                    <div className="FarmTitle-name">{farm.name}</div>
                                    <div className="FarmTitle-address">{farm.address}</div>
                                </div>
                            </div>
                            <div className="">
                                <FileSummary before={before} after={after} print />
                            </div>
                        </div>
                        <PrintDisclaimer />
                    </div>
                    <div className="ReportPage">
                        <div className="ReportSection">
                            <h1>Farm map</h1>
                            <div style={{ display: "flex", height: "430px" }}>
                                <FarmMap farm={farm} analysis={after.details} />
                            </div>
                        </div>
                        <FarmTrends farm={farm} />
                    </div>
                    {timelineData && timelineData.changeCategories && timelineData.changeCategories.length > 0 && <ComparisonTable comparison={timelineData} />}
                    <Actions before={before} after={after} />
                    <Comments before={before} after={after} />
                    <Ghg before={before} after={after} beforeSeq={beforeSeq} afterSeq={afterSeq} />
                    <Nutrients before={before} after={after} />
                    <ClimateSoils farm={farm} before={before} after={after} />
                    <Blocks before={before} after={after} />
                    <Drainage before={before} after={after} />
                    <Crops before={before} after={after} />
                    <Animals before={before} after={after} />
                    <StructuresEffluent farm={farm} before={before} after={after} />
                    <Feed before={before} after={after} />
                    <Fertiliser before={before} after={after} />
                    <Irrigation before={before} after={after} />
                </>
            ) : (
                <>
                    {!scenarioMode && (
                        <Panel title="Farm map" stickyHeaderName="planningreport.farmmap" midBlue>
                            <PanelBody>
                                <div style={{ display: "flex", height: "900px" }}>
                                    <FarmMap farm={farm} analysis={after.details} />
                                </div>
                            </PanelBody>
                        </Panel>
                    )}
                    {<FeatureTracker feature={before ? FEATURES.FARM_IMPACT_REPORT : FEATURES.FARM_SUMMARY_REPORT} action="Open" farm={farm} />}
                    <Panel title={before ? "Farm system changes" : "Farm system"} className={!scenarioMode ? "midblue u-mt-md" : "midblue"} notCollapsible>
                        <PanelBody className="u-pt-sm" loading={loading} waiting={loading}>
                            <SectionTabs sections={sections} setSections={setSections} />
                            {sections.summary && <Summary farm={farm} before={before} after={after} showFileComparison={!scenarioMode} comparison={timelineData} />}
                            {sections.nutrients && <Nutrients before={before} after={after} showFileComparison={!scenarioMode} />}
                            {sections.ghg && <Ghg before={before} after={after} showFileComparison={!scenarioMode} beforeSeq={beforeSeq} afterSeq={afterSeq} />}
                            {sections.physical && <ClimateSoils farm={farm} before={before} after={after} showFileComparison={!scenarioMode} />}
                            {sections.blocks && <Blocks before={before} after={after} showFileComparison={!scenarioMode} />}
                            {sections.wetlands && <Drainage before={before} after={after} showFileComparison={!scenarioMode} />}
                            {sections.crops && <Crops before={before} after={after} showFileComparison={!scenarioMode} />}
                            {sections.animals && <Animals before={before} after={after} showFileComparison={!scenarioMode} />}
                            {sections.structures && <StructuresEffluent farm={farm} before={before} after={after} showFileComparison={!scenarioMode} />}
                            {sections.feed && <Feed before={before} after={after} showFileComparison={!scenarioMode} />}
                            {sections.fertiliser && <Fertiliser before={before} after={after} showFileComparison={!scenarioMode} />}
                            {sections.irrigation && <Irrigation before={before} after={after} showFileComparison={!scenarioMode} />}
                            {analysisComparisonModal && <AnalysisComparisonModal {...analysisComparisonModal.modalProps} />}
                        </PanelBody>
                    </Panel>
                </>
            )}
        </>
    );
};

export default compose(
    withAppContext,
    withState((state, ownProps) => {
        const { reportSupplements = { baseAnalysisMap: [] }, searchResults = {} } = state;
        return {
            ...ownProps,
            planningData: reportSupplements.planningData,
            climateSoilSearchResults: searchResults.climateSoilSearchResults,
            baseAnalysisMap: reportSupplements.baseAnalysisMap,
        };
    }),
    withActions({
        clearPlanningReport,
        getFarmTimeline,
        clearBaseAnalysis,
        setFormState,
        getForestCarbonAsync,
        setBaseAnalysis,
    })
)(PlanningReport);

async function getAnalysis(analysisId) {
    try {
        return await httpClient.get(`v1/analysis-details/${analysisId}`);
    } catch (error) {
        if (error.status === 401 || error.status === 403) {
            throw new Error("You are not authorised to make this change.");
        } else {
            throw new Error(error.message);
        }
    }
}

function getPubicationDetails(publication) {
    return {
        publishedTo: publication.publishedTo,
        publishedBy: publication.publishedBy,
        reference: publication.reference,
        status: publication.status,
        version: publication.version,
        publishedOn: publication.publishedOn,
    };
}

async function getAnalysisForComparison(farm, analysisId, refData) {
    const analysis = await getAnalysis(analysisId);
    return getAnalysisViewModel(farm, analysis, refData);
}
