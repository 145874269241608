import { Component } from "react";
import numeral from "numeral";
import { withActions } from "containers/HOC";
import { Field, FieldArray, change, touch, arrayRemoveAll, arrayPush } from "redux-form";
import { useRefData } from "common/hooks";
import * as validations from "common/validations";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack2";
import * as _utils from "containers/BudgetHome/Animals/_utils";
import DairyGoatLeftOversEnterpriseList from "./DairyGoatLeftOversEnterpriseList";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
function DairyGoatLeftOversDetails({ enterprise, analysis, ...rest }) {
    const refData = useRefData();

    return <DairyGoatLeftOversDetailsClassComponent enterprise={enterprise} analysis={analysis} refData={refData} {...rest} />
}

const FORM_NAME = "enterprise-details-form";
const ENTERPRISE_PERCENTAGE_FATES = ["FedOnPastoralBlocks", "FedOnWinteringPads"];

class DairyGoatLeftOversDetailsClassComponent extends Component {
    constructor(props) {
        super(props);
        const { name, isPastureBlockLeftOvers, isWinteringPadLeftOvers, enterprise } = props;

        let dairyGoatLeftOversDetails = { [name]: {} };
        if (enterprise.dairyGoatLeftOvers && enterprise.dairyGoatLeftOvers[name]) {
            dairyGoatLeftOversDetails = enterprise.dairyGoatLeftOvers[name];
        }

        this.state = {
            fieldNamePrefix: `dairyGoatLeftOvers.${name}`,
            showFate: isWinteringPadLeftOvers,
            showEnterprisePercentages: isPastureBlockLeftOvers || ENTERPRISE_PERCENTAGE_FATES.includes(dairyGoatLeftOversDetails.fate),
        };
    }

    _formatRate = (e, newValue) => {
        if (!newValue) return;

        e.preventDefault();
        this.props.change(FORM_NAME, `${this.state.fieldNamePrefix}.rate`, numeral(newValue).format("0"));
        this.props.touch(FORM_NAME, `${this.state.fieldNamePrefix}.rate`);
    };

    _onFateChanged = (e, newValue) => {
        this.setState({ showEnterprisePercentages: ENTERPRISE_PERCENTAGE_FATES.includes(newValue) });
        this.props.arrayRemoveAll(FORM_NAME, `${this.state.fieldNamePrefix}.enterprisePercentages`);

        if (newValue === ENTERPRISE_PERCENTAGE_FATES[0]) {
            const nonDairyGoatEnterprisesOnPastureBlocks = _utils.getNonDairyGoatEnterpriesesOnPastureBlocks(this.props.analysis.enterprises, this.props.analysis.blocks);
            nonDairyGoatEnterprisesOnPastureBlocks.forEach((e) => this.props.arrayPush(FORM_NAME, `${this.state.fieldNamePrefix}.enterprisePercentages`, { enterpriseId: e.id, percentage: nonDairyGoatEnterprisesOnPastureBlocks.length === 1 ? 100 : 0 }));
        } else if (newValue === ENTERPRISE_PERCENTAGE_FATES[1]) {
            const nonDairyGoatWinteringPads = _utils.getNonDairyGoatWinterpingPads(this.props.analysis);
            nonDairyGoatWinteringPads.forEach((s) => this.props.arrayPush(FORM_NAME, `${this.state.fieldNamePrefix}.enterprisePercentages`, { enterpriseId: s.enterpriseId, percentage: nonDairyGoatWinteringPads.length === 1 ? 100 : 0 }));
        }
    };

    _validateEnterprisePercentages = (enterprisePercentages) => {
        if (!enterprisePercentages) return undefined;

        const total = enterprisePercentages.reduce((sum, enterprisePercentage, index) => {
            const percentage = numeral(numeral(enterprisePercentage.percentage).format("0")).value();
            return (sum += percentage);
        }, 0);

        if (total !== 100) return "Total must equal 100%";

        return undefined;
    };

    render() {
        const { analysis, refData } = this.props;

        const _fateOptions = (refData.dairyGoatLeftOverFates || []).filter((o) => {
            if (o.value === ENTERPRISE_PERCENTAGE_FATES[0] && !_utils.hasNonDairyGoatEnterprises(analysis)) {
                return false;
            } else if (o.value === ENTERPRISE_PERCENTAGE_FATES[1] && !_utils.hasNonDairyGoatWinteringPads(analysis)) {
                return false;
            }
            return true;
        });

        return (
            <Tile className="Tile--indent">
                <TileBody>
                    <Field name={`${this.state.fieldNamePrefix}.rate`} type="text" component={InputPack} label="Percentage of feed supplements left over" requiredLabel={true} placeholder="17" uom="%" dataWidth="33" validate={[validations.required, validations.range(1, 100)]} onBlur={this._formatRate} />

                    {this.state.showFate && <Field name={`${this.state.fieldNamePrefix}.fate`} label="Fate of left-overs" component={SelectPack} options={_fateOptions} required={true} placeholder="Select a fate" dataWidth="33" validate={[validations.required]} onChange={this._onFateChanged} />}

                    {this.state.showEnterprisePercentages && <span className="Field-label u-mt-lg">Percentage of left-overs eaten by other enterprises</span>}
                    {this.state.showEnterprisePercentages && <FieldArray name={`${this.state.fieldNamePrefix}.enterprisePercentages`} component={DairyGoatLeftOversEnterpriseList} analysis={analysis} validate={this._validateEnterprisePercentages} rerenderOnEveryChange />}
                </TileBody>
            </Tile>
        );
    }
}

export default withActions({ change, touch, arrayRemoveAll, arrayPush })(DairyGoatLeftOversDetails);
