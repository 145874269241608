import React from "react";
import { connect } from "react-redux";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import { removeStudent, toggleAdminForUser, getStudents } from "./_actions";
import * as utils from "common/utils";
import { Link } from "react-router-dom";
import { modal as launchModal } from "containers/App/_actions";
import { updateMyOrg } from "../MyOrg/_actions";
import ActionLink from "components/ActionLink";
import { useConfirm } from "common/hooks";

const StudentList = ({ online, accounts, userId, title, removeStudent, showDelete, launchModal, updateMyOrg, group }) => {
    const confirm = useConfirm();

    const removeStudentActions = (id) => {
        removeStudent(id);
    };

    const _removeAccount = (account, user) => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to remove ${user.fullName}?`, () => removeStudentActions(account.id));
    };

    const _edit = (id) => (e) => {
        e.preventDefault();
        const account = accounts.find((a) => a.id === id);
        launchModal("EditStudentModal", updateMyOrg, account);
    };

    const studentGroups = accounts.map((g) => g.group);
    const allGroups = [...new Set(studentGroups)].filter((g) => g !== undefined).sort((a, b) => (a < b ? -1 : 1));

    return (
        <Tile title={title} tertiary collapsible>
            <TileBody>
                {allGroups.map((g, i) => (
                    <div key={i}>
                        {allGroups.length > 1 && <div className={`h2 ${allGroups.indexOf(g) > 0 ? "u-mt-lg" : ""}`}>{g}</div>}
                        <div className="Table u-mt-sm">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Group</th>
                                        <th>Expiry</th>
                                        <th>Farms with Publications</th>
                                        {online && showDelete && <th className="th--shrink"></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {accounts
                                        .filter((a) => a.group === g)
                                        .map((account) => {
                                            const { publicationSummary = [] } = account;
                                            const uniqueFarmIds = [...new Set(publicationSummary.map((p) => p.farmId))];
                                            const { users = [] } = account;
                                            const user = users.length > 0 ? users[0] : {};
                                            return (
                                                <tr key={user.id} className={userId === user.id ? "is-selected" : ""} name={`${user.email}_REG`} id={`${account.id}_REG`}>
                                                    <td>{user.fullName}</td>
                                                    <td>{user.email}</td>
                                                    <td>
                                                        <ActionLink className="u-link" id={`update-${account.id}_group`} onClick={_edit(account.id)}>
                                                            {account.group}
                                                        </ActionLink>
                                                    </td>
                                                    <td>
                                                        <ActionLink className="u-link" id={`update-${account.id}_date`} onClick={_edit(account.id)}>
                                                            {utils.dateOnly(account.expires)}
                                                        </ActionLink>
                                                    </td>

                                                    {uniqueFarmIds.length > 0 ? (
                                                        <td>
                                                            <ol style={{ paddingLeft: "12px" }}>
                                                                {uniqueFarmIds.map((id) => (
                                                                    <li key={id}>
                                                                        <Link to={`/app/farm/${id}`}>{publicationSummary.find((p) => p.farmId === id).farmName}</Link>
                                                                    </li>
                                                                ))}
                                                            </ol>
                                                        </td>
                                                    ) : (
                                                        <td>-</td>
                                                    )}
                                                    {online && showDelete && (
                                                        <td className="u-textCenter">
                                                            <ActionLink id={`remove-usere-${user.id}`} className="IconLink--cross-circle" onClick={_removeAccount(account, user)} title="Remove">
                                                                <span></span>
                                                            </ActionLink>
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </TileBody>
        </Tile>
    );
};

export default connect(null, { removeStudent, toggleAdminForUser, getStudents, launchModal, updateMyOrg })(StudentList);
