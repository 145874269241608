import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { Panel, PanelBody } from "components/Panel";
import NoData from "components/NoData/NoData";
import { OrgUserInvitationList } from "containers/Admin/Orgs";

export default function OrgUserInvitations({ org }) {
    const { isLoading, error, userInvitations } = useOrgUserInvitations(org?.id);
    const hasData = userInvitations?.length > 0;

    return (
        <Panel error={error}>
            <PanelBody loading={isLoading}>
                {hasData
                    ? <OrgUserInvitationList org={org} userInvitations={userInvitations} />
                    : <NoData />
                }
            </PanelBody>
        </Panel>
    );
}

function useOrgUserInvitations(orgId) {
    const query = useQuery({
        queryKey: ["orgs", orgId, "users"],
        queryFn: async () => httpClient.get(`admin/accounts/${orgId}/users`),
        retry: false
    });

    return {
        isLoading: query.isLoading,
        error: query.error?.message,
        userInvitations: query.data?.userInvitations,
    };
}
