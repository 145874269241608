import { Fragment } from "react";
import * as utils from "common/utils";
import Watermark from "components/Watermark";

export default function BlockSoilIrrigation({ farm, analysis, block }) {
    const results = block.currentResults || {};

    const PLossCategory = (category) => {
        if (category === "NotApplicable") return "N/A";
        else return category;
    };

    const { comments = [] } = results;
    const localComments = utils.clone(comments);
    const { farmSoilBlocks = [] } = analysis;
    const blockSoils = farmSoilBlocks.filter((fsb) => fsb.blockId === block.id);
    const soils = analysis.useFarmSoils ? farm.soils : analysis.soils;

    return (
        <>
            {(!results || !results.nitrogenOverview || !results.phosphorusOverview) && "There are no nutrient results for this analysis. Please correct any errors shown for this analysis and try again."}
            {results && (results.nitrogenOverview || results.phosphorusOverview) && (
                <>
                    <div className="Table Table-Compressed u-mt-sm">
                        <Watermark />
                        <table>
                            <tbody>
                                <tr key="results-heading-1">
                                    <th colSpan="3"></th>
                                    <th colSpan="5" className="u-textCenter Colour-td--nloss Colour-td--start">
                                        Nitrogen
                                    </th>
                                    <th colSpan="5" className="u-textCenter Colour-td--ploss Colour-td--start">
                                        Phosphorus
                                    </th>
                                </tr>
                                <tr key="results-heading-2">
                                    <th data-width="10">Soil</th>
                                    <th data-width="10">Irrigator</th>
                                    <th data-width="5">Area</th>
                                    <th data-width="5" className="Colour-td--nloss Colour-td--middle">
                                        Total lost
                                    </th>
                                    <th data-width="5">Lost</th>
                                    <th data-width="5">
                                        Drainage <sup>1</sup>
                                    </th>
                                    <th data-width="5">Surplus</th>
                                    <th data-width="5">
                                        Added <sup>2</sup>
                                    </th>
                                    <th data-width="5" className="Colour-td--ploss Colour-td--middle">
                                        Total lost
                                    </th>
                                    <th data-width="5">Lost</th>
                                    <th data-width="5" className="u-white-space-normal">
                                        Soil P loss risk
                                    </th>
                                    <th data-width="5" className="u-white-space-normal">
                                        Fert P loss risk
                                    </th>
                                    <th data-width="5" className="u-white-space-normal">
                                        Eff P loss risk
                                    </th>
                                </tr>

                                {results.soilIrrigation &&
                                    results.soilIrrigation.map((r, j) => {
                                        const isLastRow = j === results.soilIrrigation.length - 1;
                                        const soil = soils.find((s) => s.id === r.soilId);
                                        const soilName = soil ? `${soil.subTitle || soil.title}${soil.name ? " - " + soil.name : ""}` : "Missing soil";
                                        const irrigator = r.irrigatorId && analysis.irrigators.find((i) => i.id === r.irrigatorId);
                                        const { nitrogen, phosphorus } = r;
                                        return (
                                            <tr key={"block-result-" + j}>
                                                <td className="u-textBold">{soilName}</td>
                                                <td className="u-textBold">{`${irrigator ? irrigator.name : "-"}`}</td>
                                                <td className="u-textBold">{`${r.area + " ha"} (${utils.round(r.percentageOfBlock, 1) + "%"})`}</td>
                                                <td className={`Colour-td--nloss ${isLastRow ? "Colour-td--end" : "Colour-td--middle"}`}>{nitrogen.loss.toLocaleString() + " kg"}</td>
                                                <td>{nitrogen.lossPerHa + " kg/ha"}</td>
                                                <td>{nitrogen.drainage + " ppm"}</td>
                                                <td>{nitrogen.surplus + " kg/ha"}</td>
                                                <td>{nitrogen.added + " kg/ha"}</td>
                                                <td className={`Colour-td--ploss ${isLastRow ? "Colour-td--end" : "Colour-td--middle"}`}>{phosphorus.loss.toLocaleString() + " kg"}</td>
                                                <td>{phosphorus.lossPerHa + " kg/ha"}</td>
                                                <td>{PLossCategory(phosphorus.soil)}</td>
                                                <td>{PLossCategory(phosphorus.fertiliser)}</td>
                                                <td>{PLossCategory(phosphorus.effluent)}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <sub>1 - N concentration due to leaching in drainage water at the bottom of the root zone.</sub>
                    </div>
                    <div>
                        <sub>2 - N added as fertiliser, effluent and organic only</sub>
                    </div>
                </>
            )}
            {results.soilIrrigation && results.soilIrrigation.length > 0 && (
                <>
                    <div className="h5 u-mt-lg">Soil/Irrigation - other values</div>
                    <div className="Table Table-Compressed u-mt-sm">
                        <Watermark />
                        <table>
                            <tbody>
                                <tr key="other-results-heading-1">
                                    <th colSpan="3"></th>
                                    <th colSpan="3" className="u-textCenter Colour-td--sky Colour-td--start"></th>
                                    <th colSpan="4" className="u-textCenter Colour-td--sky Colour-td--start">
                                        0cm to 60cm
                                    </th>
                                    <th colSpan="4" className="u-textCenter Colour-td--sky Colour-td--start">
                                        0cm to 150cm
                                    </th>
                                </tr>
                                <tr key="other-results-heading-2">
                                    <th data-width="10">Soil</th>
                                    <th data-width="10">Irrigator</th>
                                    <th data-width="5">Area</th>
                                    <th data-width="5" className="Colour-td--sky Colour-td--middle">
                                        Drainage
                                    </th>
                                    <th data-width="5">Runoff</th>
                                    <th data-width="5">AET</th>
                                    <th data-width="5" className="Colour-td--sky Colour-td--middle u-white-space-normal">
                                        Field capacity
                                    </th>
                                    <th data-width="5" className="u-white-space-normal">
                                        Wilting point
                                    </th>
                                    <th data-width="5">Saturation</th>
                                    <th data-width="5">PAW</th>
                                    <th data-width="5" className="Colour-td--sky Colour-td--middle u-white-space-normal">
                                        Field capacity
                                    </th>
                                    <th data-width="5" className="u-white-space-normal">
                                        Wilting point
                                    </th>
                                    <th data-width="5">Saturation</th>
                                    <th data-width="5">PAW</th>
                                </tr>
                                {results.soilIrrigation &&
                                    results.soilIrrigation.map((r, j) => {
                                        const isLastRow = j === results.soilIrrigation.length - 1;
                                        const soil = soils.find((s) => s.id === r.soilId);
                                        const soilName = soil ? `${soil.subTitle || soil.title}${soil.name ? " - " + soil.name : ""}` : "Missing soil";
                                        const irrigator = r.irrigatorId && analysis.irrigators.find((i) => i.id === r.irrigatorId);
                                        const { otherValues = {} } = r;
                                        return (
                                            <tr key={"other-block-result-" + j}>
                                                <td className="u-textBold">{soilName}</td>
                                                <td className="u-textBold">{`${irrigator ? irrigator.name : "-"}`}</td>
                                                <td className="u-textBold">{`${r.area + " ha"} (${utils.round(r.percentageOfBlock, 1) + "%"})`}</td>
                                                <td className={`Colour-td--sky ${isLastRow ? "Colour-td--end" : "Colour-td--middle"}`}>{otherValues.annualDrainage + " mm"}</td>
                                                <td>{otherValues.totalRunoff + " mm"}</td>
                                                <td>{otherValues.totalAnnualAET + " mm"}</td>
                                                <td className={`Colour-td--sky ${isLastRow ? "Colour-td--end" : "Colour-td--middle"}`}>{otherValues.fieldCapacity60 ? otherValues.fieldCapacity60 + " mm" : "-"}</td>
                                                <td>{otherValues.wiltingPoint60 ? otherValues.wiltingPoint60 + " mm" : "-"}</td>
                                                <td>{otherValues.saturation60 ? otherValues.saturation60 + " mm" : "-"}</td>
                                                <td>{otherValues.paW60 ? otherValues.paW60 + " mm" : "-"}</td>
                                                <td className={`Colour-td--sky ${isLastRow ? "Colour-td--end" : "Colour-td--middle"}`}>{otherValues.fieldCapacity150 ? otherValues.fieldCapacity150 + " mm" : "-"}</td>
                                                <td>{otherValues.wiltingPoint150 ? otherValues.wiltingPoint150 + " mm" : "-"}</td>
                                                <td>{otherValues.saturation150 ? otherValues.saturation150 + " mm" : "-"}</td>
                                                <td>{otherValues.paW150 ? otherValues.paW150 + " mm" : "-"}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            {localComments.length > 0 && (
                <div className="u-mt-lg">
                    <div className="h5">Model notes</div>
                    {localComments.reverse().map((bc, i) => {
                        const irrigator = bc.irrigatorId && analysis.irrigators.find((i) => i.id === bc.irrigatorId);
                        const farmSoilBlock = bc.farmSoilBlockId && blockSoils && blockSoils.find((bs) => bs.id === bc.farmSoilBlockId);
                        const soil = farmSoilBlock && soils.find((s) => s.id === farmSoilBlock.soilId);
                        const soilName = soil ? `${soil.subTitle || soil.title}${soil.name ? " - " + soil.name : ""}` : "Missing soil";
                        return (
                            <Fragment key={"comment-" + i}>
                                {bc.comments.map((c, x) => {
                                    const { soilIrrigation = [] } = results;
                                    const r = farmSoilBlock ? soilIrrigation.find((n) => n.farmSoilBlockId === farmSoilBlock.id && n.irrigatorId === bc.irrigatorId) : undefined;
                                    const title = x > 0 ? undefined : !soil && !irrigator ? "Overview" : `${soilName}/${irrigator ? irrigator.name : "No irrigation"} - ${r ? r.area + " ha" : ""} (${r ? utils.round(r.percentageOfBlock, 1) + "%" : ""})`;
                                    const { subComments = [] } = c;
                                    return (
                                        <Fragment key={x}>
                                            {title && <div className="h4 u-mt-md">{title}</div>}
                                            <p>{c.message}</p>
                                            {subComments.length > 0 && (
                                                <ol style={{ listStyleType: "disc" }}>
                                                    {subComments.map((sc, y) => (
                                                        <li key={"subcomment-" + x + "-" + y}>{sc.message}</li>
                                                    ))}
                                                </ol>
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </Fragment>
                        );
                    })}
                </div>
            )}
        </>
    )
}
