import { farmYear } from "common/domain";
import { irrigatorViewModel } from "common/viewModels";
import Watermark from "components/Watermark";
import { useRefData } from "common/hooks";

export default function IrrigatorList({ analysis }) {
    const refData = useRefData();
    const vm = irrigatorViewModel(analysis, refData);

    return (
        <>
            {vm.length === 0 && "There is no irrigation information for this analysis"}
            {vm.length > 0 && (
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="md-15 xl-15">Name</th>
                                <th className="u-textCenter" data-width="md-5 xl-5">
                                    Area covered
                                </th>
                                {farmYear.map((calendarMonth, i) => (
                                    <th data-width="md-5 xl-5" key={i} className="u-textCenter">
                                        {calendarMonth.substring(0, 3)}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {vm.map((irrigator) => {
                                return (
                                    <tr key={irrigator.id}>
                                        <td className="td--keyValuePair">
                                            <span className="u-flex u-flexAlignItemsCenter">
                                                <span id={`calendar.title.${irrigator.id}`} className="td-key">
                                                    {irrigator.irrigatorType}
                                                </span>
                                            </span>
                                            <span id={`calendar.subTitle.${irrigator.id}`} className="td-value-text">
                                                {irrigator.name}
                                            </span>
                                        </td>
                                        <td className="Calendar-slot">{irrigator.area + " ha"}</td>
                                        {irrigator.slots.length > 0 &&
                                            farmYear.map((calendarMonth, i) => {
                                                const slot = irrigator.slots.find((slot) => slot.monthIndx === i) || irrigator.slots.find((slot) => slot.month === calendarMonth);
                                                if (slot) {
                                                    return (
                                                        <td key={i} className="Calendar-slot">
                                                            {slot.icon && <img id={`calendar.${calendarMonth}.icon.${irrigator.id}`} src={slot.icon} width={45} height={45} className="Calendar-icon u-p-xxs" alt="Calendar icon" />}
                                                            {slot.iconText && (
                                                                <span id={`calendar.${calendarMonth}.iconText.${irrigator.id}`} className="Calendar-icon-text">
                                                                    {slot.iconText}
                                                                </span>
                                                            )}
                                                            {slot.text && <span id={`calendar.${calendarMonth}.text.${irrigator.id}`}>{slot.text}</span>}
                                                        </td>
                                                    );
                                                } else {
                                                    return <td key={i} className="Calendar-slot"></td>;
                                                }
                                            })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}
