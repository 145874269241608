import { Bar } from "react-chartjs-2";
import * as utils from "common/utils";
import Alert from "components/Alert";

export default function GhgEmissions({ aggregatedData }) {
    if (!aggregatedData || !aggregatedData.results || aggregatedData.totalArea === 0) return null;

    const bgColours = ["#9D211B", "#FFAD29", "#1E499F"];

    const data = {
        labels: ["Methane", "Nitrous oxide", "Carbon dioxide"],
        datasets: [
            {
                backgroundColor: bgColours,
                borderWidth: 1,
                hoverBackgroundColor: bgColours,
                data: [utils.round((aggregatedData.results.ghgMethaneKg || 0) / 1000, 1), utils.round((aggregatedData.results.ghgNitrousOxideKg || 0) / 1000, 1), utils.round((aggregatedData.results.ghgCarbonDioxideKg || 0) / 1000, 1)],
            },
        ],
    };

    const options = {
        indexAxis: "y",
        responsive: true,
        scales: {
            x: {
                grid: {
                    drawOnChartArea: true,
                },
                ticks: {
                    callback: function (label, index, labels) {
                        return Number(label).toLocaleString();
                    },
                },
            },
            y: {
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div className="HeroPanel HeroPanel--ghg u-print-border">
            <Alert type="info" html="Shows the aggregated totals for all farm GHG emissions. The figures are in tonnes of CO2e per year broken down by methane, nitrous oxide and carbon dioxide." className="u-print-show" />
            <div className="HeroPanel-heading">GHG emissions</div>
            <div className="u-flex u-flexWrap">
                <div style={{ width: "570px" }}>
                    <Bar data={data} options={options} />
                </div>
                <div className="u-flex-growBig">
                    <Alert type="info" html="Shows the aggregated totals for all farm GHG emissions. The figures are in tonnes of CO2e per year broken down by methane, nitrous oxide and carbon dioxide." className="u-print-none" />
                    <div className="HeroValue u-pt-lg">
                        <div className="HeroValue-item">
                            <h5>Total GHG emissions</h5>
                            <div className="HeroValue-item-number-large" id="totalGhg">
                                {utils.numeric.format(aggregatedData.results.ghgKg / 1000)} <small>t CO2e/yr</small>
                            </div>
                        </div>
                    </div>
                    <div className="HeroValue u-pt-lg">
                        <div className="HeroValue-item" data-width="md-25">
                            <h5>Per ha</h5>
                            <div className="HeroValue-item-number">
                                {utils.numeric.format(aggregatedData.results.ghgKg / aggregatedData.totalArea)} <small>kg CO2e/ha/yr</small>
                            </div>
                        </div>
                        <div className="HeroValue-item" data-width="md-25">
                            <h5>Methane GHG emissions</h5>
                            <div className="HeroValue-item-number" id="totalMethaneGhg">
                                {utils.numeric.format(aggregatedData.results.ghgMethaneKg / 1000 || 0)} <small>t CO2e/yr</small>
                            </div>
                        </div>
                        <div className="HeroValue-item" data-width="md-25">
                            <h5>Nitrous oxide</h5>
                            <div className="HeroValue-item-number" id="totalN2OGhg">
                                {utils.numeric.format(aggregatedData.results.ghgNitrousOxideKg / 1000 || 0)} <small>t CO2e/yr</small>
                            </div>
                        </div>
                        <div className="HeroValue-item" data-width="md-25">
                            <h5>Carbon dioxide</h5>
                            <div className="HeroValue-item-number" id="totalCO2Ghg">
                                {utils.numeric.format(aggregatedData.results.ghgCarbonDioxideKg / 1000 || 0)} <small>t CO2e/yr</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
