import "./BlockMap.css";
import { useEffect, useState } from "react";
import * as geoJsonUtils from "common/geoJsonUtils";
import Spinner from "components/Spinner/Spinner";
import { useGoogleApi, useGoogleMap } from "components/Map/hooks";
import MapLegend from "components/Map/MapLegend";
import MapZoomButton from "components/Map/MapZoomButton";
import MapBlockLabels from "components/Map/MapBlockLabels";
import { useOnline } from "common/hooks";

export default function BlockMap({ farm, analysis, blockId, onClick }) {
    const online = useOnline();
    const { map, mapId, setMapRef } = useGoogleMap({ center: { lat: farm.latitude, lng: farm.longitude }, zoom: 12 });
    const geoJson = useAnalysisGeoJson(analysis);

    const mapData = useMapData(map, geoJson);
    useMapStyle(mapData, blockId);
    useOnBlockPolygonHover(mapData);
    useOnBlockPolygonClick(mapData, onClick);

    const mapError = !online && "Overseer is currently offline. Mapping is unavailable.";
    const mapLoading = online && !geoJson && !mapError;
    const showMap = !mapLoading && !mapError;

    return (
        <div className="block_map">
            {mapLoading && (
                <div className="Tile-body-message">
                    <Spinner dark />
                    <p className="lead u-text-lg u-mt-md">Loading map...</p>
                </div>
            )}
            {!mapLoading && mapError && (
                <div className="Tile-body-message u-textError">
                    <i className="icon icon--md icon-alert" />
                    <p className="lead">{mapError}</p>
                </div>
            )}
            <div className={`map ${showMap ? "" : "u-hidden"}`} ref={setMapRef} />
            <MapBlockLabels mapData={mapData} mapId={mapId} geoJson={geoJson} blocks={analysis.blocks} />
            <MapLegend mapData={mapData} mapId={mapId} layersToShow={[{ id: "Irrigator", title: "Drawn Irrigators", sortOrder: 0 }]} />
            <MapZoomButton mapData={mapData} mapId={mapId} where={(f) => f.properties.blockId === blockId} when={blockId} padding={100} />
        </div>
    );
}

function useAnalysisGeoJson(analysis) {
    const [geoJson, setGeoJson] = useState();

    useEffect(() => {
        if (!geoJson) {
            const features = (analysis.features || []).reduce((results, feature) => {
                // Flatten and hide irrigators and unassigned land by default.
                const flattenedFeatures = geoJsonUtils.flatten(feature, ["Irrigator", "UnassignedLand"]);
                return [...results, ...flattenedFeatures];
            }, []);
            setGeoJson({ type: "FeatureCollection", features });
        }
    }, [analysis.features, geoJson]);

    return geoJson;
}

function useMapData(map, geoJson) {
    const google = useGoogleApi();
    const [mapData, setMapData] = useState();

    useEffect(() => {
        if (google && map && geoJson && !mapData) {
            const data = new google.maps.Data({ map });
            data.addGeoJson(geoJson);
            setMapData(data);
        }
    }, [google, map, geoJson, mapData]);

    return mapData;
}

function useMapStyle(mapData, blockId) {
    useEffect(() => {
        if (mapData && blockId) {
            mapData.setStyle((feature) => {
                const hovered = feature.getProperty("hovered");
                const visible = feature.getProperty("visible");
                const layer = feature.getProperty("layer");
                const layerColor = geoJsonUtils.getMapLayerColor(layer);

                const isCurrentBlock = feature.getProperty("blockId") === blockId;

                let zIndex = 0;
                if (hovered) {
                    zIndex = 3;
                } else if (isCurrentBlock) {
                    zIndex = 2;
                } else if (layer === "Block") {
                    zIndex = 1;
                }

                return {
                    strokeColor: layerColor,
                    strokeWeight: hovered ? 4 : 3,
                    fillColor: isCurrentBlock || layer === "Irrigator" ? layerColor : "white",
                    fillOpacity: 0.6,
                    zIndex,
                    visible,
                };
            });
        }
    }, [mapData, blockId]);
}

function useOnBlockPolygonHover(mapData) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            // Add 'mouseover' event for hover styling.
            mapData.addListener("mouseover", ({ feature }) => {
                if (feature.getProperty("blockId")) {
                    feature.setProperty("hovered", true);
                }
            });

            // Add 'mouseout' event for un-hover styling.
            mapData.addListener("mouseout", ({ feature }) => {
                if (feature.getProperty("blockId")) {
                    feature.setProperty("hovered", false);
                }
            });

            setRunOnce(true);
        }
    }, [mapData, runOnce]);
}

function useOnBlockPolygonClick(mapData, onClick) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && onClick && !runOnce) {
            mapData.addListener("click", ({ feature }) => {
                if (feature.getProperty("blockId")) {
                    onClick(feature.getProperty("blockId"));
                }
            });
            setRunOnce(true);
        }
    }, [mapData, onClick, runOnce]);
}
