import React from "react";

const ReportingError = ({ msg, online }) => (
    <div className="HeroPanel">
        <div className="Tile-body-message">
            <i className="icon icon--md icon-alert u-textError" />
            <h3 className="u-textError">{!online ? "Overseer is currently offline. Please try again later." : msg}</h3>
        </div>
    </div>
);

export default ReportingError;
