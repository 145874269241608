import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as cropUtils from "../Crops/_utils";
import * as fu from "./_utils";
import numeral from "numeral";
import { useOnline, useRefData } from "common/hooks";

export default function BlockListCard({ farm, analysis, block }) {
    const online = useOnline();
    const refData = useRefData();

    const _blockIcon = utils.getBlockIcon(block.type);
    const _blockType = utils.getBlockTypeText(refData, block);

    const blockApplications = [];
    const { fertiliser = [] } = analysis;
    analysis.fertiliser = fertiliser;
    const calcBlockSize = (block) => (block.type === "FodderCrop" ? block.rotationArea : block.areaInHectares);
    const blockSize = calcBlockSize(block);
    const { messages = [] } = analysis;
    const hasErrors = online && messages.some((m) => m.category === "Fertiliser" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error");
    const hasWarnings = online && messages.some((m) => m.category === "Fertiliser" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Warning");
    const rotationYear = cropUtils.rotationYear(block, true);

    /* eslint-disable no-unused-vars */
    for (const fertiliser of analysis.fertiliser) {
        /* eslint-disable no-unused-vars */
        for (const application of fertiliser.applications) {
            const totalArea = analysis.blocks.filter((b) => application.blockIds.includes(b.id)).reduce((a, b) => a + calcBlockSize(b), 0);
            if (application.blockIds.includes(block.id)) {
                blockApplications.push({ fertiliser: fertiliser, application: application, totalArea: totalArea });
            }
        }
    }

    const dcdApplicationMonths = (analysis.nitrateInhibitors || []).reduce((months, nitrateInhibitor) => {
        const applicationToThisBlock = nitrateInhibitor.applications.find((a) => (a.blockIds || []).includes(block.id));
        if (applicationToThisBlock) {
            months = applicationToThisBlock.months.map((m) => m.month);
        }
        return months;
    }, []);

    const renderDCDApplicationMonths = (farm, analysis, block, rotationYear, dcdApplicationMonths) => {
        return (
            <tr className="Calendar-slots Calendar-slots--fertiliser">
                <td className="td--keyValuePair" colSpan="3">
                    <span className="td-key">Additive</span>
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/dcd`} className="td-value" id={`${block.id}_dcd`}>
                        <span className="td-value-text">DCD</span>
                        <span className="icon icon-edit" />
                    </Link>
                </td>
                {rotationYear.map((month, index) => {
                    const monthHasApplication = dcdApplicationMonths.includes(month);
                    if (monthHasApplication) {
                        return (
                            <td key={index} id={`${block.id}_dcd_${month}`} className="Calendar-slot">
                                <img className="Calendar-icon u-p-xxs" width="35" height="35" src={utils.getFertiliserIcon("Soluble")} alt="DCD" />
                                <span className="Calendar-icon-text"></span>
                            </td>
                        );
                    } else {
                        return <td key={index} id={`${block.id}_${month}`} className="Calendar-slot"></td>;
                    }
                })}
            </tr>
        );
    };

    if (blockApplications.length === 0) {
        return (
            <div className="FarmTable_wrapper">
                <ul className="DataWidthTable FarmTable">
                    <li className="FarmTable-title" data-width="md-50 xl-30">
                        {hasWarnings && !hasErrors && (
                            <span className={`Todo Todo--warning`}>
                                <i className="icon icon-alert" />
                            </span>
                        )}
                        {hasErrors && (
                            <span className={`Todo Todo--error error-anchor`}>
                                <i className="icon icon-alert" />
                            </span>
                        )}
                        <div className="FarmTitle">
                            <img className="FarmTitle-icon" src={_blockIcon} alt="Block Type" />
                            <div className="FarmTitle-heading">
                                <span className="FarmTitle-name" title={block.name}>
                                    {block.name}
                                </span>
                                <div className="FarmTitle-sub">{_blockType}</div>
                            </div>
                        </div>
                    </li>
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        {block.isProductive && block.type !== "ProductiveOutdoorPigs" && <span>Fertiliser has not been added to this block</span>}
                        {(!block.isProductive || block.type === "ProductiveOutdoorPigs") && <span>Fertiliser data is not required for this block</span>}
                    </li>
                </ul>
                {dcdApplicationMonths.length > 0 && (
                    <div className="FarmTable-supplementary">
                        <div className="Table u-tbl-lg">
                            <table className="Calendar u-tbl-fix">
                                {cropUtils.cropTableHeader(analysis, rotationYear, block, refData, false, 3, 0)}
                                <tbody>{renderDCDApplicationMonths(farm, analysis, block, rotationYear, dcdApplicationMonths)}</tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    const formatAmount = (fertiliser, month, blockSize, totalArea, slash) => {
        if (!month || month.amount === 0) return "";

        const blockAmount = month.unit !== "kgha" ? parseFloat((blockSize / totalArea) * month.amount) : month.amount;

        const unit = fertiliser.dairyFactoryEffluent && fertiliser.dairyFactoryEffluent === "Userdefined" ? "lt/ha" : month.unit === "kgha" ? "kg/ha" : month.unit;
        return `${+blockAmount.toFixed(2)} ${unit}`;
    };

    const renderMonth = (blockApplications, month, blockSize, block, reportingYear, index) => {
        let amount = "";
        const application = blockApplications.application;
        const blockMonth = application.months.find((m) => m.reportingYear === reportingYear && m.month === month);
        if (blockMonth) {
            amount = formatAmount(blockApplications.fertiliser, blockMonth, blockSize, blockMonth.totalArea, true);
        }

        return blockMonth ? (
            <td key={index} id={`${block.id}_${blockApplications.fertiliser.id}_${month}`} className="Calendar-slot">
                <img className="Calendar-icon u-p-xxs" src={utils.getFertiliserIcon(blockApplications.fertiliser.material)} alt="fertiliser icon" />
                <span className="Calendar-icon-text">{amount}</span>
            </td>
        ) : (
            <td key={index} id={`${block.id}_${month}`} className="Calendar-slot"></td>
        );
    };

    const fertApplications = blockApplications.filter((a) => a.fertiliser.isLastPreviousLime !== true);
    const prevLimeApplications = blockApplications.filter((a) => a.fertiliser.isLastPreviousLime === true && a.application.blockIds.includes(block.id));
    const fodderBlockAreas = utils.getFodderBlockAreas(analysis.blocks);

    const maintenanceNutrients = block.currentResults && block.currentResults.maintenanceNutrientResults && block.currentResults.maintenanceNutrientResults.fertiliserNutrients;
    const totalNutrients = block.currentResults && block.currentResults.nutrientBudget && block.currentResults.nutrientBudget.nutrientCategories && block.currentResults.nutrientBudget.nutrientCategories.Fertiliser && block.currentResults.nutrientBudget.nutrientCategories.Fertiliser.total && block.currentResults.nutrientBudget.nutrientCategories.Fertiliser.total.nutrients;

    const productIds = fertApplications.reduce((x, y) => (x.includes(y.fertiliser.id) ? x : [...x, y.fertiliser.id]), []);
    const products = productIds.map((id) => {
        const combinedApplications = {
            months: [],
        };
        const productApplications = fertApplications.filter((f) => f.fertiliser.id === id);
        /* eslint-disable no-unused-vars */
        for (const fertApp of productApplications) {
            const { application = {} } = fertApp;
            const { months = [] } = application;
            /* eslint-disable no-unused-vars */
            for (const month of months) {
                const monthApplication = combinedApplications.months.find((c) => c.month === month.month && c.reportingYear === month.reportingYear);
                if (monthApplication) {
                    //two applications for the same product on the same month
                    if (monthApplication.unit === application.unit) {
                        //same unit so just sum the amounts
                        monthApplication.amount += application.amount;
                    } else {
                        //differnt units so need to convert to a common unit > tonnes
                        monthApplication.blockIds = [];
                        monthApplication.blockIds.push(block.id);
                        const applicationAmount = fu.calcTonnesPerMonth(application.amount, application.unit, fu.getApplicationBlockArea(application.blockIds, analysis.blocks, fodderBlockAreas));
                        const summaryAmount = fu.calcTonnesPerMonth(monthApplication.amount, monthApplication.unit, fu.getApplicationBlockArea(monthApplication.blockIds, analysis.blocks, fodderBlockAreas));
                        monthApplication.amount = applicationAmount + summaryAmount;
                        monthApplication.unit = "tonnes";
                        month.totalArea = fertApp.totalArea;
                    }
                } else {
                    month.unit = application.unit;
                    month.amount = application.amount;
                    month.totalArea = fertApp.totalArea;
                    combinedApplications.months.push(utils.clone(month));
                }
            }
        }
        return {
            id: id,
            fertiliser: productApplications[0].fertiliser,
            application: combinedApplications,
        };
    });

    return (
        <div className="FarmTable_wrapper">
            <ul className="DataWidthTable FarmTable">
                <li className="FarmTable-title" data-width="md-100 xl-30">
                    {hasWarnings && !hasErrors && (
                        <span className={`Todo Todo--warning`}>
                            <i className="icon icon-alert" />
                        </span>
                    )}
                    {hasErrors && (
                        <span className={`Todo Todo--error error-anchor`}>
                            <i className="icon icon-alert" />
                        </span>
                    )}
                    <div className="FarmTitle">
                        <img className="FarmTitle-icon" src={_blockIcon} alt="Block Type" />
                        <div className="FarmTitle-heading">
                            <span className="FarmTitle-name" title={block.name}>
                                {block.name}
                            </span>
                            <div className="FarmTitle-sub">{_blockType}</div>
                        </div>
                    </div>
                </li>

                {prevLimeApplications.length > 0 && (
                    <li data-width="md-30 xl-12.5">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Previous lime</span>
                                <span className="FarmTable-value">
                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/${prevLimeApplications[0].fertiliser.id}?type=${prevLimeApplications[0].fertiliser.material}`} id={`${block.id}_${prevLimeApplications[0].fertiliser.id}_fert`} className="FarmTable-value-link">
                                        <span className="FarmTable-value-link-text">{prevLimeApplications[0].fertiliser.productName}</span>
                                        <span className="icon icon-edit" />
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {prevLimeApplications.length > 0 && (
                    <li data-width="md-12.5 xl-7.5">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Amount</span>
                                <span className="FarmTable-value">{formatAmount(prevLimeApplications[0].fertiliser, prevLimeApplications[0].application, blockSize, prevLimeApplications[0].totalArea, false)}</span>
                            </div>
                        </div>
                    </li>
                )}
                {totalNutrients && Object.keys(totalNutrients).length > 0 && (
                    <li data-width="md-30 xl-27.5">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Reporting Year - Total nutrients (kg/ha/yr)</span>
                                <span className="FarmTable-value">
                                    {totalNutrients.N !== undefined && (
                                        <span>
                                            <b>N:</b> {numeral(totalNutrients.N).format("0.0")}
                                        </span>
                                    )}
                                    {totalNutrients.P !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>P:</b> {numeral(totalNutrients.P).format("0.0")}
                                        </span>
                                    )}
                                    {totalNutrients.K !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>K:</b> {numeral(totalNutrients.K).format("0.0")}
                                        </span>
                                    )}
                                    {totalNutrients.S !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>S:</b> {numeral(totalNutrients.S).format("0.0")}
                                        </span>
                                    )}
                                    {totalNutrients.Ca !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>Ca:</b> {numeral(totalNutrients.Ca).format("0.0")}
                                        </span>
                                    )}
                                    {totalNutrients.Mg !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>Mg:</b> {numeral(totalNutrients.Mg).format("0.0")}
                                        </span>
                                    )}
                                    {totalNutrients.Na !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>Na:</b> {numeral(totalNutrients.Na).format("0.0")}
                                        </span>
                                    )}
                                    {totalNutrients.H !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>H+:</b> {numeral(totalNutrients.H).format("0.0")} <sup>1</sup>
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {maintenanceNutrients && Object.keys(maintenanceNutrients).length > 0 && (
                    <li data-width="md-27.5 xl-22.5">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Maintenance nutrients (kg/ha/yr)</span>
                                <span className="FarmTable-value">
                                    {maintenanceNutrients.P !== undefined && (
                                        <span>
                                            <b>P:</b> {maintenanceNutrients.P}
                                        </span>
                                    )}
                                    {maintenanceNutrients.K !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>K:</b> {maintenanceNutrients.K}
                                        </span>
                                    )}
                                    {maintenanceNutrients.S !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>S:</b> {maintenanceNutrients.S}
                                        </span>
                                    )}
                                    {maintenanceNutrients.Ca !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>Ca:</b> {maintenanceNutrients.Ca}
                                        </span>
                                    )}
                                    {maintenanceNutrients.Mg !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>Mg:</b> {maintenanceNutrients.Mg}
                                        </span>
                                    )}
                                    {maintenanceNutrients.Na !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>Na:</b> {maintenanceNutrients.Na}
                                        </span>
                                    )}
                                    {maintenanceNutrients.H !== undefined && (
                                        <span className="u-ml-sm">
                                            <b>Lime:</b> {maintenanceNutrients.H} <sup>2</sup>
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                    </li>
                )}
            </ul>
            {products.length > 0 && (
                <div className="FarmTable-supplementary">
                    <div className="Table u-tbl-lg">
                        <table className="Calendar u-tbl-fix">
                            {cropUtils.cropTableHeader(analysis, rotationYear, block, refData, false, 3, 0)}
                            <tbody>
                                {products.map((item, i) => (
                                    <tr className="Calendar-slots Calendar-slots--fertiliser" key={i} name={`row_${i}`} id={item.fertiliser.id}>
                                        <td className="td--keyValuePair" colSpan="3">
                                            <span className="td-key">{item.fertiliser.manufacturer}</span>
                                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/${item.fertiliser.id}?type=${item.fertiliser.material}`} className="td-value" id={`${block.id}_${item.fertiliser.id}_fert`}>
                                                <span className="td-value-text">{item.fertiliser.productName}</span>
                                                <span className="icon icon-edit" />
                                            </Link>
                                        </td>
                                        {rotationYear.map((month, index) => {
                                            const reportingYear = cropUtils.indexToReportingYear(index, rotationYear);
                                            return renderMonth(item, month, blockSize, block, reportingYear, index);
                                        })}
                                    </tr>
                                ))}
                                {dcdApplicationMonths.length > 0 && renderDCDApplicationMonths(farm, analysis, block, rotationYear, dcdApplicationMonths)}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {prevLimeApplications.length > 0 && (
                <div className="ActionsBar">
                    <div className="ActionsBar-right">
                        <ul className="ActionsBar-links">
                            <li>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/${prevLimeApplications[0].fertiliser.id}?type=${prevLimeApplications[0].fertiliser.material}`} className="IconLink--edit" id={`edit_lime_${block.id}`}>
                                    Edit previous lime
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    )
};
