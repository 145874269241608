import * as api from "api";

const actionTypes = {
    CARBON_FETCH_CANCEL: "CARBON_FETCH_CANCEL",
    CARBON_FETCH_SUCCESS: "CARBON_FETCH_SUCCESS",
    CARBON_FETCH_FAILURE: "CARBON_FETCH_FAILURE",
    PLANNING_DATA_SET: "PLANNING_DATA_SET",
    PLANNING_DATA_CLEAR: "PLANNING_DATA_CLEAR",
    BASE_ANALYSIS_SET: "BASE_ANALYSIS_SET",
    BASE_ANALYSIS_CLEAR: "BASE_ANALYSIS_CLEAR",
};

export default actionTypes;

export const clearBaseAnalysis = (id) => ({ type: actionTypes.BASE_ANALYSIS_CLEAR, payload: id });
export const setBaseAnalysis = (analysisId, baseId) => ({ type: actionTypes.BASE_ANALYSIS_SET, payload: { analysisId, baseId } });

export function getForestCarbon(farmId, id) {
    return (dispatch) => {
        dispatch({ type: actionTypes.CARBON_FETCH_CANCEL });
        dispatch(
            api.get({
                path: `farms/${farmId}/analysis/${id}/forestcarbon`,
                onSuccess: (response) => {
                    return { type: actionTypes.CARBON_FETCH_SUCCESS, payload: response };
                },
                onFailure: (error) => {
                    error.handled = true;
                    return { type: actionTypes.CARBON_FETCH_FAILURE };
                },
                cancelActionType: actionTypes.CARBON_FETCH_CANCELœ,
            })
        );
    };
}

export const getForestCarbonAsync = (farmId, budgetId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                path: `farms/${farmId}/analysis/${budgetId}/forestcarbon`,
                silentMode: true,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};
