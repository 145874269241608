import { Component } from "react";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import arrayMutators from "final-form-arrays";
import * as FormUtils from "common/FormUtils";
import SavePrompt from "components/SavePrompt";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import Alert from "components/Alert";
import { Grid, GridCell } from "components/Grid";
import SelectPack from "components/SelectPack2";
import InputPack from "components/InputPack";
import RadioGroupPack from "components/RadioGroupPack";
import { getOutdoorPigBarnsViewModel, requiresOutdoorPigEffluentSystem, OUTDOOR_PIG_SOLID_DISPOSAL_OPTIONS } from "common/viewModels";
import OutdoorPigBarnPlacements from "./OutdoorPigBarnPlacements";
import * as structureUtils from "./_utils";
import { useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function OutdoorPigBarns({ farm, analysis }) {
    const refData = useRefData();
    const viewModel = getOutdoorPigBarnsViewModel(analysis, refData);
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    return <OutdoorPigBarnsClassComponent farm={farm} analysis={analysis} viewModel={viewModel} updateAnalysisAsync={updateAnalysisAsync} refData={refData} />
}

class OutdoorPigBarnsClassComponent extends Component {
    constructor(props) {
        super(props);
        const { viewModel } = props;
        this.state = {
            viewModel,
        };
    }

    showEffluentDisposal = (viewModel) => Number(viewModel.beddingOption) === BARN_BEDDING_OPTIONS[0].value || !viewModel.beddingOption;

    showSludgeDisposal = (viewModel) => this.showEffluentDisposal(viewModel) && OUTDOOR_PIG_SOLID_DISPOSAL_OPTIONS.includes(viewModel.effluentDisposal);

    showStrawDisposal = (viewModel) => Number(viewModel.beddingOption) === BARN_BEDDING_OPTIONS[1].value;

    showCompostDisposal = (viewModel) => this.showStrawDisposal(viewModel) && viewModel.strawDisposal === "SpreadOnBlocks";

    showTimeInStorage = (viewModel) => this.showCompostDisposal(viewModel) && ["Coveredfromrain", "Opentorain"].includes(viewModel.compostDisposal);

    validate = (viewModel) => {
        const error = {};

        if (this.showEffluentDisposal(viewModel)) error.effluentDisposal = FormUtils.validators.required(viewModel.effluentDisposal);

        if (this.showSludgeDisposal(viewModel)) error.sludgeDisposal = FormUtils.validators.required(viewModel.sludgeDisposal);

        if (this.showStrawDisposal(viewModel)) error.strawDisposal = FormUtils.validators.required(viewModel.strawDisposal);

        if (this.showCompostDisposal(viewModel)) error.compostDisposal = FormUtils.validators.required(viewModel.compostDisposal);

        if (this.showTimeInStorage(viewModel)) {
            error.timeInStorage = FormUtils.validators.required(viewModel.timeInStorage);
            error.timeInStorage = error.timeInStorage || FormUtils.validators.range(1, 9999)(viewModel.timeInStorage);
        }

        return error;
    };

    submit = async (viewModel) => {
        // Map view model back to dto.
        const outdoorPigs = viewModel.outdoorPigs || {};
        const numbers = outdoorPigs.numbers || {};
        const growersFinishers = numbers.growersFinishers || {};
        const hasWeanedNumber = growersFinishers.weanedNumber > 0;
        const hasBroughtIn = growersFinishers.broughtIn > 0;
        const includeGrowers = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

        const structure = {
            ...viewModel,
            pigBarnPlacements: {},
        };

        viewModel.placements
            .filter((placement) => (placement.stockClassGroup === "Growers" && includeGrowers) || (placement.stockClassGroup !== "Growers" && !outdoorPigs.growOutUnitOnly))
            .forEach((placement) => {
                structure.pigBarnPlacements[placement.stockClassGroup] = placement.months;
            });

        if (!this.showEffluentDisposal(viewModel)) delete structure.effluentDisposal;

        if (!this.showSludgeDisposal(viewModel)) delete structure.sludgeDisposal;

        if (!this.showStrawDisposal(viewModel)) delete structure.strawDisposal;

        if (!this.showCompostDisposal(viewModel)) delete structure.compostDisposal;

        if (!this.showTimeInStorage(viewModel)) delete structure.timeInStorage;

        const updatedAnalysis = structureUtils.getUpdatedAnalysisFromSavingStructure(this.props.analysis, structure);
        await this.props.updateAnalysisAsync(updatedAnalysis);
    };

    render() {
        const { farm, analysis, refData } = this.props;
        const baseRedirectUrl = `/app/farm/${farm.id}/analysis/${analysis.id}/structures`;

        return (
            <Form
                initialValues={this.state.viewModel}
                validate={this.validate}
                onSubmit={this.submit}
                mutators={{
                    setProperty: (args, state, utils) => {
                        utils.changeValue(state, args[0], () => args[1]);
                    },
                    ...arrayMutators,
                }}
            >
                {({ form, values, handleSubmit, dirty, submitSucceeded }) => {
                    const disableSave = false;
                    const redirectOnSaveTo = values.manageEffluent ? `${baseRedirectUrl}/outdoorPigEffluent` : baseRedirectUrl;

                    const showEffluentManagement = this.showEffluentDisposal(values);
                    const showBeddingManagement = this.showStrawDisposal(values);
                    const enableManageEffluentButton = requiresOutdoorPigEffluentSystem(values) && ((showEffluentManagement && values.effluentDisposal && values.effluentDisposal !== "Allexported") || (showBeddingManagement && values.strawDisposal && values.strawDisposal !== "Exported"));

                    return (
                        <form onSubmit={handleSubmit}>
                            {true && <SavePrompt blockIf={dirty && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={redirectOnSaveTo} />}
                            <Panel title="Outdoor pig barns/sheds" referrer={baseRedirectUrl}>
                                <Alert className="u-mb-sm" type="info" text="For each pig class, record the percentage of animals in that class that are housed off-paddock in a barn or shed. To account for movements at different times during the month, the best approach is to first calculate numbers on a housed days basis (i.e. number of pigs * days housed / days in month) before calculating the percentage of pigs housed. For sows, it is the percentage of pig days sows are not in the village." />
                                <PanelBody>
                                    <OutdoorPigBarnPlacements form={form} outdoorPigs={this.state.viewModel.outdoorPigs} />
                                    <Grid title="Effluent from barns/sheds">
                                        <GridCell className="u-mt-md">
                                            <Field name="beddingOption" options={BARN_BEDDING_OPTIONS} value={values.beddingOption ? Number(values.beddingOption) : BARN_BEDDING_OPTIONS[0].value} inline type="radio" component={RadioGroupPack} />
                                        </GridCell>
                                        {showEffluentManagement && (
                                            <GridCell className="u-width1of4">
                                                <Field name="effluentDisposal" label="Effluent management" placeholder="Select an effluent management system" options={refData.winPadEffTreat} required component={SelectPack} />
                                            </GridCell>
                                        )}
                                        {this.showSludgeDisposal(values) && (
                                            <GridCell className="u-width1of4">
                                                <Field name="sludgeDisposal" label="Pond solids management" placeholder="Select a pond solids management system" options={refData.solidsManagement} required component={SelectPack} />
                                            </GridCell>
                                        )}

                                        {showBeddingManagement && (
                                            <GridCell className="u-width1of4">
                                                <Field name="strawDisposal" label="Bedding management" placeholder="Select a bedding management system" options={refData.solidsManagement} required component={SelectPack} />
                                            </GridCell>
                                        )}
                                        {this.showCompostDisposal(values) && (
                                            <GridCell className="u-width1of4">
                                                <Field name="compostDisposal" label="Composting method" placeholder="Select a composting method" options={refData.outdoorPigStrawCompostingMethods} required component={SelectPack} />
                                            </GridCell>
                                        )}
                                        {this.showTimeInStorage(values) && (
                                            <GridCell className="u-width1of4">
                                                <Field name="timeInStorage" label="Time in storage" placeholder="0" uom="months" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                            </GridCell>
                                        )}
                                    </Grid>
                                </PanelBody>
                                <PanelFooter>
                                    <div className="ButtonBar ButtonBar--fixed">
                                        <div className="ButtonBar-left">
                                            <Link to={baseRedirectUrl} id="cancel-button" className="Button Button--secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="ButtonBar-right">
                                            <button type="submit" onClick={(e) => form.change("manageEffluent", true)} disabled={!enableManageEffluentButton} id="manage-button" className="Button Button--tertiary">
                                                Save and manage effluent
                                            </button>
                                            <button type="submit" onClick={(e) => form.change("manageEffluent", false)} id="save-button" className="Button Button--primary" disabled={disableSave}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </PanelFooter>
                            </Panel>
                        </form>
                    );
                }}
            </Form>
        )
    }
}

const BARN_BEDDING_OPTIONS = [
    { value: 0, text: "Concreate pad (no bedding)" },
    { value: 1, text: "Straw bedding material" },
];
