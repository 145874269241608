import React, { useEffect } from "react";
import { compose, withAppContext } from "containers/HOC";
import BlockSelector from "components/new/BlockSelector";
import ActionLink from "components/ActionLink";

const SolidModal = ({ cancel, save, blocks, selectedBlocks, application, validation, type, prefix, onChange, showQuickTips, toggleQuickTips }) => {
    useEffect(() => {
        function closeOnEscape(e) {
            if (e.keyCode === 27) {
                e.preventDefault();
                cancel();
            }
        }
        document.addEventListener("keydown", closeOnEscape);
        return () => document.removeEventListener("keydown", closeOnEscape);
    });

    const blockIdsVal = validation[`${type}_blockIds`] || {};

    return (
        <div className="Modal_wrapper">
            <input type="hidden" value={application.id} id="applicationId" />
            <div className="Modal Modal--skinny">
                <div className="Modal-head">
                    {prefix === "solid" && <span className="Modal-head--left">Separated solid effluent application</span>}
                    {prefix === "pond" && <span className="Modal-head--left">Pond sludge effluent application</span>}
                    <span className="Modal-head--right">
                        <ActionLink onClick={toggleQuickTips} className="Modal-close">
                            <i className="icon icon-question" title={`${showQuickTips ? "Hide" : "Show"} quick tips`} />
                        </ActionLink>
                        <ActionLink onClick={cancel} id="modal_close" className="Modal-close">
                            <i className="icon icon-cross" title="Close" />
                        </ActionLink>
                    </span>
                </div>
                <div className="Modal-body" style={{ minHeight: "300px" }}>
                    <div className="Grid">
                        <div className="Grid-cell">
                            <BlockSelector
                                input={{
                                    value: application.blockIds || [],
                                    onChange: (blockIds) => onChange({ target: { value: blockIds } }, { id: `${type}_blockIds`, key: "blockIds", type }),
                                }}
                                availableBlocks={blocks}
                                meta={{
                                    touched: blockIdsVal.touched,
                                    error: blockIdsVal.message,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="Modal-footer">
                    <div className="ButtonBar">
                        <div className="ButtonBar-left">
                            <button type="button" onClick={cancel} className="Button Button--secondary" id="modal-cancel">
                                Cancel
                            </button>
                        </div>
                        <div className="ButtonBar-right">
                            <button type="button" onClick={() => save()} className="Button" id="modal-save" disabled={selectedBlocks.length < 1}>
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default compose(withAppContext)(SolidModal);
