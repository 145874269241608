import { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalBodyAlert from "components/Modal/ModalBodyAlert";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SearchInputPack from "components/SearchInputPack";
import TextareaPack from "components/TextareaPack";
import Alert from "components/Alert";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { useModal } from "common/hooks";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { useDebounce } from "common/effects";

export default function InviteFarmGroupMembersModal({ farmGroup, close }) {
    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const inviteFarmGroupMembersAsync = useInviteFarmGroupMembersAsync();
    const debouncedSearchText = useDebounce(searchText, 500);

    useEffect(() => {
        if (debouncedSearchText) {
            findFarms(debouncedSearchText)
                .then((results) => setSearchResults(results))
                .catch((error) => console.log(error));
        }
    }, [debouncedSearchText]);

    const submitAsync = async (values) => {
        try {
            await inviteFarmGroupMembersAsync(values.id, values.farmIds, values.comments);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }

    return (
        <Form initialValues={farmGroup} onSubmit={submitAsync}>
            {({ form, handleSubmit, pristine, submitting, submitError, dirtySinceLastSubmit, values }) => {
                const selectedFarmIds = values.farmIds || [];
                const disableSubmit = pristine || submitting || selectedFarmIds.length === 0;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`Invite new members to join '${farmGroup.name}'`} close={close} submitting={submitting}>
                            <ModalBody>
                                <ModalBodyAlert>{!dirtySinceLastSubmit && submitError && <Alert type="error" text={submitError} />}</ModalBodyAlert>
                                <Grid title="Find farms to invite">
                                    <GridCell className="u-lg-width2of3">
                                        <div className="Field u-mt-md">
                                            <label className="Field-label">Farm search</label>
                                            <Field name="search" placeholder="Search by farm name or address" onChange={(e) => setSearchText(e.target.value)} onClear={() => form.change("search", "")} component={SearchInputPack} />
                                        </div>
                                    </GridCell>
                                </Grid>
                                <Grid className="u-mt-md">
                                    <GridCell>
                                        <span className="h3">{searchResults?.length}</span> farm(s) found
                                    </GridCell>
                                </Grid>
                                {searchResults?.length > 0 && (
                                    <Grid className="u-mt-md">
                                        <GridCell>
                                            <SortableTable data={searchResults} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }}>
                                                {({ data, sortCriteria, handleSort }) => {
                                                    return (
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="th--shrink"></th>
                                                                    <SortableTableHeader label="Name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                    <SortableTableHeader label="Address" fieldName="address" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                    <SortableTableHeader label="Ownership status" fieldName="ownershipStatus" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((farm) => {
                                                                    const isAlreadyAMember = (farmGroup.memberFarms || []).some((m) => m.farmId === farm.id);
                                                                    const isAlreadyInvited = (farmGroup.invitedFarms || []).some((i) => i.farmId === farm.id);
                                                                    const canBeSelected = !isAlreadyAMember && !isAlreadyInvited;
                                                                    return (
                                                                        <tr key={farm.id} className={canBeSelected ? "hover" : ""}>
                                                                            <td className="u-textCenter">
                                                                                {isAlreadyAMember && <span>Member</span>}
                                                                                {isAlreadyInvited && <span>Invited</span>}
                                                                                {canBeSelected && (
                                                                                    <label className="Checkbox">
                                                                                        <Field name="farmIds" id={farm.id} data-testing-name={farm.name} value={farm.id} className="Checkbox-input" type="checkbox" component="input" />
                                                                                        <span className="Checkbox-label"></span>
                                                                                    </label>
                                                                                )}
                                                                            </td>
                                                                            <td>{farm.name}</td>
                                                                            <td>{farm.address}</td>
                                                                            <td>{farm.ownershipStatus}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    );
                                                }}
                                            </SortableTable>
                                        </GridCell>
                                        <GridCell>
                                            <Field name="comments" label="Invitation comments" placeholder="Enter any comments to include on the invitation email to the invited farms" type="text" component={TextareaPack} />
                                        </GridCell>
                                    </Grid>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="btnFarmGroupInvite_cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="btnFarmGroupInvite_send" submit primary disabled={disableSubmit}>
                                        Send
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    )
}

export function useInviteFarmGroupMembersModal(farmGroup) {
    const [modal, openModal] = useModal(InviteFarmGroupMembersModal);

    const open = () => {
        const modalProps = {
            farmGroup,
        };
        openModal(modalProps);
    };

    return [modal, open];
}

async function findFarms(searchText) {
    const urlEncodedSearchText = encodeURIComponent(searchText);
    return httpClient.get(`search/farms?searchText=${urlEncodedSearchText}&filterBy=AllFarms`);
}

function useInviteFarmGroupMembersAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ farmGroupId, farmIds, comments }) => {
            try {
                await httpClient.post(`farmGroups/${farmGroupId}/members`, { farmIds, comments });
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["reporting"] });
        },
    });

    return (farmGroupId, farmIds, comments) => mutation.mutateAsync({ farmGroupId, farmIds, comments });
}
