import React, { useState } from "react";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import Alert from "components/Alert";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import * as validations from "common/validations";
import InputPack from "components/InputPack";
import * as domain from "common/domain";
import { v4 as uuidv4 } from "uuid";
import AnalysisBlock from "./AnalysisBlock";
import TileActionsBar from "components/TileActionsBar";
import SectionHeading from "components/SectionHeading";
import setFieldTouched from "final-form-set-field-touched";
import arrayMutators from "final-form-arrays";
import * as datasetUtils from "./_utils";
import ActionLink from "components/ActionLink";
import { useSoilModal } from "containers/Soils/SoilModal";
import { useNavigate, useRefData } from "common/hooks";
import { useSelector } from "react-redux";
import { useExecuteAnalysis } from "./_hooks";

export default function AnalysisDetails({ datasetId, farmViewModel, onSave }) {
    const refData = useRefData();
    const navigate = useNavigate();
    const [soilModal, openSoilModal] = useSoilModal();
    const sessionId = useState(uuidv4());
    const _referrer = `/app/datasets/${datasetId}`;
    const executeAnalysis = useExecuteAnalysis();
    const modelledResults = useSelector((state) => state.dataset.modelledResults);

    const validate = (values) => {
        const { blockIndex = 0 } = values;
        const errors = { analysis: { blocks: [] }, farm: {} };

        //Valiate farm
        errors.analysis.name = validations.required(values.analysis.name);
        errors.analysis.name = errors.analysis.name || validations.maxLength(50)(values.analysis.name);

        //Validate blocks
        values.analysis.blocks.forEach((b, i) => {
            const { scienceInputs = {}, climate = {} } = b;
            const blockErrors = { scienceInputs: {}, climate: {} };
            errors.analysis.blocks.push(blockErrors);
            const { formState = {} } = b;

            if (blockIndex === i) {
                blockErrors.name = validations.required(b.name);
                blockErrors.name = blockErrors.name || validations.maxLength(50)(b.name);
                blockErrors.scienceInputs.urineNAdded = validations.range(0, 1000000000)(scienceInputs.urineNAdded);
                blockErrors.scienceInputs.kSaturationMM = validations.range(1, 1000)(scienceInputs.kSaturationMM);
                blockErrors.scienceInputs.lysimeterDepthCM = validations.range(0, 150)(scienceInputs.lysimeterDepthCM);

                if (formState.annualClimate) {
                    blockErrors.climate.averageRain = validations.required(climate.averageRain);
                } else {
                    const { regionalData = {} } = climate;
                    const { rainFall = [], pet = [] } = regionalData;
                    const rainfallSum = rainFall.reduce((total, rain) => (total += rain.value ? parseFloat(rain.value) : 0), 0);
                    const petSum = pet.reduce((total, pet) => (total += pet.value ? parseFloat(pet.value) : 0), 0);
                    blockErrors.rainFallError = validations.range(300, 6000)(rainfallSum) ? "Sum of rainfall must be between 300 and 6000 mm" : undefined;
                    if (pet.some((p) => p.value)) {
                        const petSumError = petSum > 200 ? undefined : "Sum of pet values must be greater than 200";
                        const petAllError = pet.every((p) => p.value) ? undefined : "If a pet value is entered all values must be entered";
                        if (petSumError || petAllError) {
                            blockErrors.petError = `${petSumError || ""}${petSumError ? ". " : ""}${petAllError || ""}`;
                        }
                    }
                }
                if (!(values.analysis.farmSoilBlocks || []).some((fsb) => fsb.blockId === b.id)) {
                    errors.analysis.farmSoilBlocks = "Please add a soil";
                }

                blockErrors.pasture = {};
                const { pasture = {} } = b;
                if (b.type === "ProductivePasture") blockErrors.pasture.pastureCategory = validations.required(pasture.pastureCategory);

                const valueArrays = ["drainageMM", "irrigationMM", "nEffluentKG", "nFertiliserKG", "nUptakeKG"];
                valueArrays.forEach((arrName) => {
                    blockErrors.scienceInputs[arrName] = [];
                    if (scienceInputs[arrName]) {
                        scienceInputs[arrName].forEach((monthVal) => {
                            const message = monthVal && monthVal.value ? validations.rangeShort(0, 1000000000)(monthVal.value) : undefined;
                            blockErrors.scienceInputs[arrName].push({ value: message });
                        });
                    }
                });
            }
        });
        return errors;
    };

    const submitAsync = async (values) => {
        const { farm, analysis } = values;

        if (!farm.name) {
            farm.name = analysis.name;
        }

        analysis.soils = analysis.soils || [];

        const filterArrayOfZeros = (array) => (array ? array.filter((p) => p && p.value && p.value > 0 && p.month && domain.calendarYear.includes(p.month)) : undefined);

        analysis.blocks
            .filter((b) => b.isProductive && !b.type === "FodderCrop")
            .forEach((b) => {
                const { regionalData = {} } = b.climate;
                const { scienceInputs = {}, formState = {} } = b;
                b.scienceInputs = scienceInputs;
                b.scienceInputs = scienceInputs;

                analysis.soils = analysis.soils.filter((s) => s.sessionId !== sessionId || (s.sessionId && analysis.farmSoilBlocks.some((fsb) => fsb.soilId === s.id)));
                analysis.soils.forEach((s) => {
                    delete s.sessionId;
                });

                if (formState.annualClimate) {
                    regionalData.pet = [];
                    regionalData.temperature = [];
                    regionalData.rainFall = [];
                } else {
                    b.climate.annualPetInMM = undefined;
                    b.climate.averageTemp = undefined;
                    b.climate.averageRain = undefined;
                    //may need to set Annual Avg Rain, Mean Temp, PET
                }

                if (formState.annualNUptake) {
                    scienceInputs.nUptakeKG = [];
                } else {
                    delete scienceInputs.baseNUptakeKG;
                }

                if (values.saveType === "save") {
                    scienceInputs.nUptakeKG = filterArrayOfZeros(scienceInputs.nUptakeKG);
                    scienceInputs.nFertiliserKG = filterArrayOfZeros(scienceInputs.nFertiliserKG);
                    scienceInputs.nEffluentKG = filterArrayOfZeros(scienceInputs.nEffluentKG);
                    scienceInputs.irrigationMM = filterArrayOfZeros(scienceInputs.irrigationMM);
                    scienceInputs.drainageMM = filterArrayOfZeros(scienceInputs.drainageMM);
                    regionalData.rainFall = filterArrayOfZeros(regionalData.rainFall);
                    regionalData.temperature = filterArrayOfZeros(regionalData.temperature);
                    regionalData.pet = filterArrayOfZeros(regionalData.pet);
                }
            });

        analysis.datasetId = datasetId;
        if (values.saveType === "execute") {
            executeAnalysis(farm, values.analysis);
        } else {
            await onSave(farm, analysis);
            navigate(_referrer);
        }
    };

    const { towns = [] } = refData;

    return (
        <>
            <Form
                initialValues={farmViewModel}
                validate={validate}
                onSubmit={submitAsync}
                keepDirtyOnReinitialize={true}
                mutators={{
                    setProperty: (args, state, utils) => {
                        utils.changeValue(state, args[0], () => args[1]);
                    },
                    setFieldTouched,
                    ...arrayMutators,
                }}
                render={({ handleSubmit, submitting, values, form, valid, errors }) => {
                    let { blockIndex = 0 } = values;

                    if (blockIndex < 0 || blockIndex > values.analysis.blocks.length - 1) {
                        blockIndex = 0;
                    }
                    const block = values.analysis.blocks[blockIndex];
                    const { formState = {} } = block;

                    const setBlockChangeError = (message) => {
                        form.mutators.setFieldTouched(`analysis.name`, true);
                        form.mutators.setFieldTouched(`farm.nearestTown`, true);
                        form.mutators.setFieldTouched(`farm.region`, true);

                        if (formState.annualNUptake) {
                            form.mutators.setFieldTouched(`analysis.blocks[${blockIndex}].scienceInputs.baseNUptakeKG`, true);
                        }

                        form.mutators.setFieldTouched(`analysis.blocks[${blockIndex}].pasture.pastureCategory`, true);
                        form.mutators.setFieldTouched(`analysis.farmSoilBlocks`, true);
                        form.mutators.setFieldTouched(`analysis.blocks[${blockIndex}].climate.averageRain`, true);
                        form.mutators.setProperty(`analysis.blocks[${blockIndex}].formState.blockError`, message);
                    };

                    const addBlock = () => {
                        if (valid) {
                            const blocks = values.analysis.blocks;
                            const blockCount = blocks.length;
                            const maxBlockInt = blocks.reduce((max, block) => {
                                const { name = "" } = block;
                                const potentialNumber = name.replace(/^\D+/g, "");
                                return !isNaN(potentialNumber) && parseInt(potentialNumber, 10) > max ? parseInt(potentialNumber, 10) : max;
                            }, blockCount);
                            const newBlock = datasetUtils.createEmptyBlock(`Block ${maxBlockInt + 1}`);
                            form.mutators.push(`analysis.blocks`, newBlock);
                            form.mutators.setProperty("blockIndex", blockCount);
                        } else {
                            setBlockChangeError("Please fix the errors before adding a new block");
                        }
                    };

                    const setBlock = (indx) => {
                        if (valid) {
                            form.mutators.setProperty("blockIndex", indx);
                        } else {
                            setBlockChangeError("Please fix the errors before changing blocks");
                        }
                    };

                    const deleteBlock = (blockIndex) => {
                        const results = values.analysis.blocks.reduce((acc, cur, i) => {
                            if (i !== blockIndex) acc.push(cur);
                            return acc;
                        }, []);
                        form.change("analysis.blocks", results);
                        form.mutators.setProperty("blockIndex", 0);
                    };

                    const deleteSoil = (blockId) => {
                        form.change(
                            `analysis.farmSoilBlocks`,
                            values.analysis.farmSoilBlocks.filter((fsb) => fsb.blockId !== blockId)
                        );
                    };

                    const addSoil = (blockId) => {
                        const soilModalOptions = {
                            saveOnSubmit: true,
                            onSubmit: async (addedSoil) => {
                                form.change("analysis.soils", [...values.analysis.soils, addedSoil]);

                                const newFarmSoilBlock = {
                                    id: uuidv4(),
                                    percentageOfBlock: 100,
                                    soilId: addedSoil.id,
                                    blockId,
                                };
                                const updatedFarmSoilBlocks = [...values.analysis.farmSoilBlocks.filter((fsb) => fsb.blockId !== blockId), newFarmSoilBlock];
                                form.change("analysis.farmSoilBlocks", updatedFarmSoilBlocks);
                            },
                        };
                        openSoilModal({ id: uuidv4(), isNew: true }, soilModalOptions);
                    };

                    const editSoil = (soil) => {
                        const soilModalOptions = {
                            saveOnSubmit: true,
                            onSubmit: async (editedSoil) => {
                                form.change(
                                    "analysis.soils",
                                    values.analysis.soils.map((s) => (s.id === editedSoil.id ? editedSoil : s))
                                );
                            },
                        };
                        openSoilModal(soil, soilModalOptions);
                    };

                    if (values.farm.nearestTown) {
                        var town = towns.find((t) => t.value === values.farm.nearestTown);
                        values.farm.address = town ? town.text : "";
                    }
                    const { scienceInputs = {} } = values.analysis.blocks[blockIndex];
                    let startMonth = scienceInputs.startMonth || domain.calendarYear[0];
                    if (!domain.calendarYear.includes(startMonth)) startMonth = domain.calendarYear[0];
                    const blockName = block.name || `Block ${blockIndex}`;

                    return (
                        <form onSubmit={handleSubmit}>
                            <Tile title="Lysimeter settings" referrer={_referrer}>
                                <TileBody className="u-pt-0">
                                    <div className="Grid Grid--withGutter">
                                        <div className="Grid-cell u-lg-width1of1 u-xl-width1of2">
                                            <Field name="analysis.name" label="Analysis name" component={InputPack} type="text" placeholder="Analysis name" requiredLabel={true} />
                                        </div>
                                    </div>
                                    <SectionHeading title="Blocks">
                                        <div className="u-flex">
                                            <ActionLink className="IconLink--arrow-plus" id="block_add" onClick={() => addBlock()}>
                                                <span>Add block</span>
                                            </ActionLink>
                                        </div>
                                    </SectionHeading>
                                    <TileActionsBar>
                                        <ul className="SubMenu u-flex-wrap">
                                            {values.analysis.blocks.map((b, i) => (
                                                <li key={i} className={`SubMenu-item ${blockIndex === i ? "is-active" : ""}`}>
                                                    <ActionLink onClick={() => setBlock(i)}>{b.name || `Block ${i + 1}`}</ActionLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </TileActionsBar>
                                    {formState.blockError && !valid && <Alert type="error" text={formState.blockError} />}

                                    <div style={{ paddingLeft: "10px", paddingRight: "4px" }}>
                                        <AnalysisBlock refData={refData} block={block} editSoil={editSoil} addSoil={addSoil} deleteSoil={deleteSoil} analysis={values.analysis} startMonth={startMonth} deleteBlock={deleteBlock} allowDelete={values.analysis.blocks.length > 1} initLysimeterDepth={scienceInputs.lysimeterDepthCM} showFormErrors={block.blockError || values.saveType} blockName={blockName} errors={errors} blockIndex={blockIndex} modelledResults={modelledResults} />
                                    </div>
                                    {formState.blockError && !valid && <Alert type="error" text={formState.blockError} />}
                                </TileBody>
                                <TileFooter>
                                    <div className="ButtonBar">
                                        <div className="ButtonBar-left">
                                            <Link to={_referrer} className="Button Button--secondary" id="analysis-details-cancel">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="ButtonBar-right">
                                            <button
                                                type="submit"
                                                className="Button Button--tertiary"
                                                disabled={submitting}
                                                id="analysis-details-execute"
                                                onClick={() => {
                                                    form.change("saveType", "execute");
                                                }}
                                            >
                                                Execute model
                                            </button>
                                            <button
                                                type="submit"
                                                className="Button Button--primary"
                                                disabled={submitting}
                                                id="analysis-details-submit"
                                                onClick={() => {
                                                    form.change("saveType", "save");
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </TileFooter>
                                {/* 
                                    <pre>{JSON.stringify(values, 0, 2)}</pre>
                                    */}
                            </Tile>
                        </form>
                    );
                }}
            />
            {soilModal}
        </>
    );
}
