import * as utils from "common/utils";
import ActionLink from "components/ActionLink";
import { useRestoreAnalysisModal } from "./Modals/RestoreAnalysisModal";
import { useUpdateSmapSoilsModal } from "./Modals/UpdateSmapSoilsModal";
import { useOnline } from "common/hooks";

export default function AnalysesPanelActions({ farm, createAnalysis, uploadAnalysis }) {
    const online = useOnline();
    const [restoreAnalysisModal, openRestoreAnalysisModal] = useRestoreAnalysisModal(farm);
    const [updateSmapSoilsModal, openUpdateSmapSoilsModal] = useUpdateSmapSoilsModal(farm);

    const hasFarmWriteAccess = utils.canManageAnalyses(farm);
    const hasEditableBudgets = farm.budgets?.some((b) => utils.canEditBudget(b)) || false;
    const hasPublications = farm?.publications?.length > 0 || false;
    const showCheckSoils = online && (hasEditableBudgets || hasPublications);

    return (
        <>
            <div className="ActionsBar ActionsBar--super u-print-none">
                <div className="ActionsBar-left">
                    <ul className="ActionsBar-links">
                        {online && hasFarmWriteAccess && (
                            <li>
                                <ActionLink id="farm-home-create-budget" className="IconLink--arrow-plus" onClick={createAnalysis}>
                                    Create new analysis
                                </ActionLink>
                            </li>
                        )}
                        {online && hasFarmWriteAccess && (
                            <li>
                                <ActionLink id="farm-home-upload-budget" className="IconLink--upload" onClick={uploadAnalysis}>
                                    Upload analysis
                                </ActionLink>
                            </li>
                        )}
                        {online && hasFarmWriteAccess && (
                            <li>
                                <ActionLink id="restore-budgets" className="IconLink--history" onClick={openRestoreAnalysisModal}>
                                    Restore deleted year end analysis
                                </ActionLink>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="ActionsBar-right">
                    {showCheckSoils && (
                        <ul className="ActionsBar-links">
                            <li>
                                <ActionLink id="check-soils" className="IconLink--sync" onClick={openUpdateSmapSoilsModal}>
                                    Check for S-Map soil updates
                                </ActionLink>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
            {restoreAnalysisModal}
            {updateSmapSoilsModal}
        </>
    )
}
