import { useRef, useState, useEffect } from "react";
import Alert from "components/Alert";
import { useShowQuickTips } from "common/hooks";

export default function AddressAutocompleteField({ google, input, label, placeholder, uom, className, onChange, info, warning, tip, autoFocus, readOnly, disabled, required, meta }) {
    const showQuickTips = useShowQuickTips();
    const ref = useRef();
    const [autocomplete, setAutocomplete] = useState();

    useEffect(() => {
        if (google && onChange && ref && ref.current && !autocomplete) {
            const autocompleteOptions = {
                types: ["geocode"],
                componentRestrictions: { country: "nz" },
            };
            const autocomplete = new google.maps.places.Autocomplete(ref.current, autocompleteOptions);
            autocomplete.addListener("place_changed", () => {
                onChange(autocomplete.getPlace());
            });
            setAutocomplete(autocomplete);
        }
    }, [google, onChange, ref, autocomplete]);

    const error = meta && meta.touched && meta.error;

    const preventEnterKeyPress = (e) => {
        if (e.defaultPrevented) return;

        if (e.which === 13 /* Enter */) {
            e.preventDefault();
        }
    };

    return (
        <div className={`Field ${error ? "has-error" : ""} ${className ? className : ""}`}>
            {label && (
                <label htmlFor={input.name} className={`Field-label ${disabled && !readOnly ? "is-disabled" : ""}`}>
                    {label}
                    {required && <sup className="required">*</sup>}
                </label>
            )}
            <div className="Input_wrapper">
                <input {...input} id={input.name} value={input.value || ""} type="type" placeholder={placeholder || label} className="Input Input--block" autoFocus={autoFocus} readOnly={readOnly} disabled={disabled} ref={ref} onKeyPress={preventEnterKeyPress} />
                {uom && <small className="Input-helper">{uom}</small>}
            </div>
            {error && (
                <div id={`${input.name}-error`} className="Field-error u-block u-mt-sm">
                    {error}
                </div>
            )}
            {warning && <Alert type="warning" text={warning} className="u-mb-0" />}
            {info && <Alert type="info" text={info} className="u-mb-0" />}
            {showQuickTips && tip && <Alert type="help" text={tip} className="u-mb-0" />}
        </div>
    );
}
