import React from "react";
import { withActions } from "containers/HOC";
import { logout } from "containers/Auth/_actions";
import moment from "moment";
import ActionLink from "./ActionLink";

const FatalModal = (props) => {
    const { modal, closeModal, logout } = props;

    const _confirm = (e) => {
        e.preventDefault();
        if (modal.onConfirm) modal.onConfirm();
        closeModal();
    };

    const _logout = () => {
        logout();
    };

    let message = (
        <div>
            <h2>There was a problem completing your request.</h2>
            <h3>Return to the dashboard to review any pending saves</h3>
        </div>
    );

    return (
        <div className="Modal_wrapper" style={{ zIndex: 50 }}>
            <div className="Modal Modal--notification Alert--error">
                <div className="Modal-body">
                    <i className="icon icon--md icon-alert" />
                    {!modal.props.logOutOnly && <h2>Error!</h2>}
                    {modal.props.message || message}
                    {modal.props.requestId && <p>Error ID - {modal.props.requestId}</p>}
                    {!modal.props.logOutOnly && <h3 className="u-textWarning">WARNING! if you log out from here you will lose any unsaved changes</h3>}
                </div>
                <div className="Modal-footer">
                    <ActionLink id="logout" onClick={_logout} className="Button Button--secondary" type="button" name="confirm-button">
                        Log out
                    </ActionLink>
                    {!modal.props.logOutOnly && (
                        <ActionLink id="confirm-button" onClick={_confirm} className="Button" type="button" name="confirm-button">
                            Return to Dashboard
                        </ActionLink>
                    )}
                </div>
                <div className="Error-date">
                    <small>{moment().format()}</small>
                </div>
            </div>
        </div>
    );
};

export default withActions({ logout })(FatalModal);
