import { Fragment } from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as icons from "common/icons";
import * as domain from "common/domain";
import { useConfirm } from "common/hooks";
import { useAnalysisSummary } from "containers/BudgetHome";
import TileSubPanel from "components/TileSubPanel";
import ActionLink from "components/ActionLink";
import Button from "components/Button/Button";
import { EnterpriseNameWithIcon } from "components/EnterpriseNameWithIcon";
import { useUpdateAnalysisAsync } from "containers/hooks";
import * as animalUtils from "../_utils";
import { useCreateEnterpriseModal } from "containers/BudgetHome/Animals/Enterprise/CreateEnterpriseModal";

export default function EnterprisesGreenBox({ farm, analysis }) {
    const { isFetching, isLoading, animalSummaries } = useAnimalSummaries(analysis);
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const [createEnterpriseModal, openCreateEnterpriseModal] = useCreateEnterpriseModal(farm, analysis);

    const hasAnimalEnterprises = animalSummaries?.length > 0;

    const deleteEnterprise = (enterpriseId) => {
        confirm(`Are you sure you want to delete this enterprise?`, async () => {
            const updatedAnalysis = animalUtils.getUpdatedAnalysisFromDeletingEnterprise(analysis, enterpriseId);
            await updateAnalysisAsync(updatedAnalysis);
        });
    }

    return (
        <>
            <TileSubPanel title="Enterprises" waiting={isFetching} loading={isLoading} green stickyHeaderName="analysis.animals.greenBox.collapsed">
                <div className="FarmTable_wrapper">
                    <div className="FarmTable-supplementary u-pt-md">
                        {hasAnimalEnterprises
                            ? <>
                                <div className="Table">
                                    <table className="Calendar">
                                        <thead>
                                            <tr className="Calendar-months">
                                                <th className="Calender-title">Name</th>
                                                {domain.farmYear.map((month) => (
                                                    <th key={month} className="numeric">
                                                        {utils.month.format(month)}
                                                    </th>
                                                ))}
                                                <th className="th--shrink"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {animalSummaries?.map((enterpriseSummary) => {
                                                const enterprise = analysis.enterprises.find((e) => e.id === enterpriseSummary.id);
                                                const enterpriseKey = `stock_numbers_${enterprise.name}`;
                                                const hasMonthlyStockCounts = enterpriseSummary?.stockNumbersByMonth?.length > 0;
                                                const hasError = analysis.messages?.some((m) => m.entityType === "Enterprise" && m.entityId === enterprise.id && m.severity === "Error");
                                                const hasWarning = !hasError && analysis.messages?.some((m) => m.entityType === "Enterprise" && m.entityId === enterprise.id && m.severity === "Warning");
                                                return (
                                                    <Fragment key={enterpriseKey}>
                                                        <tr key={enterpriseKey} className="hover">
                                                            <td>
                                                                <Link id={`edit-${enterprise.type}`} to={`/app/farm/${farm.id}/analysis/${analysis.id}/animals/enterprises/${enterprise.type.toLowerCase()}`} className="u-flexSplit">
                                                                    <EnterpriseNameWithIcon enterprise={enterprise} hasError={hasError} hasWarning={hasWarning} />
                                                                </Link>
                                                            </td>
                                                            {hasMonthlyStockCounts
                                                                ? <>
                                                                    {enterpriseSummary.stockNumbersByMonth.map((m) => {
                                                                        const monthKey = `${enterpriseKey}_${m.month}`;
                                                                        return (
                                                                            <td key={monthKey} id={monthKey} className="numeric">
                                                                                {utils.numeric.format(m.count)}
                                                                            </td>
                                                                        )
                                                                    })}
                                                                </>
                                                                : <>
                                                                    <td colSpan="12">
                                                                        <span className="inline-key u-ml-sm">{enterpriseSummary.numberingMethod}: </span>
                                                                        {enterpriseSummary.totalRevisedStockUnits?.value > 0 && <span className="inline-value">{enterpriseSummary.totalRevisedStockUnits?.value}</span>}
                                                                        {enterpriseSummary.count > 0 && <span className="inline-value">{enterpriseSummary.count}</span>}
                                                                    </td>
                                                                </>
                                                            }
                                                            <td>
                                                                <ActionLink className="IconLink--trash" id={`delete-enterprise-${enterprise.id}`} onClick={() => deleteEnterprise(enterprise.id)} title="Delete" disabled={isFetching}></ActionLink>
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            : <>
                                <div className="Tile-body">
                                    <div className="Tile-body-message">
                                        <img src={icons.add} className="u-p-5" alt="Plus" />
                                        <p className="lead">This analysis does not have any animal enterprises</p>
                                        <Button onClick={() => openCreateEnterpriseModal()} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-enterprise">
                                            Add enterprise
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </TileSubPanel>
            {createEnterpriseModal}
        </>
    )
}

function useAnimalSummaries(analysis) {
    const { isFetching, isLoading, data } = useAnalysisSummary(analysis);

    return {
        isFetching,
        isLoading,
        animalSummaries: data?.animals
    }
}
