import actionTypes from "./_actions";
import authActionTypes from "containers/Auth/_actions";
import moment from "moment";

const INITIAL_STATE = { students: [] };

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.ACCOUNT_GET_INVOICE_SUCCESS:
            return {
                ...state,
                invoiceDetails: action.payload,
            };
        case actionTypes.ACCOUNT_CLEAR_INVOICE:
            return {
                ...state,
                invoiceDetails: undefined,
            };
        case actionTypes.ACCOUNT_GET_SUCCESS: {
            const { userInvitations = [] } = action.payload;
            return {
                ...state,
                users: action.payload.users,
                invitations: userInvitations.filter((u) => u.target !== "Organisation"),
                studentInvitations: userInvitations.filter((u) => u.target === "Organisation"),
                subscriptions: action.payload.subscriptions,
                creditLimit: action.payload.creditLimit,
                scienceSettings: action.payload.scienceSettings,
            };
        }
        case actionTypes.ACCOUNT_GET_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                paymentHistory: action.payload,
            };
        case actionTypes.ACCOUNT_GET_STUDENTS_SUCCESS:
            return {
                ...state,
                students: action.payload,
            };
        case actionTypes.ACCOUNT_REMOVE_STUDENT_SUCCESS: {
            const students = [...state.students];
            const student = students.find((s) => s.id === action.payload);
            if (student) student.expires = moment().valueOf();
            return {
                ...state,
                students,
            };
        }
        case actionTypes.ACCOUNT_UPDATE_STUDENT_SUCCESS: {
            const students = [...state.students];
            const student = students.find((s) => s.id === action.payload.id);
            if (student) {
                student.expires = action.payload.expires;
                student.group = action.payload.group;
            }
            return {
                ...state,
                students,
            };
        }
        case authActionTypes.AUTH_CLEAR_STORAGE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
export default reducer;
