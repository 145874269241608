import { Bar } from "react-chartjs-2";
import * as utils from "common/utils";
import { useAnalysisResults } from "containers/BudgetHome";

export default function NutrientBudgetMovementGraph({ before, after, nutrient }) {
    const { isLoading: afterLoading, data: afterResults } = useAnalysisResults(after?.details);
    const { data: beforeResults } = useAnalysisResults(before?.details);

    if (afterLoading || !afterResults) return null;

    const afterNutrientBudget = afterResults?.nutrientBudget;
    const beforeNutrientBudget = beforeResults?.nutrientBudget;

    if (!afterNutrientBudget) return null;
    const barData = mapPoolsDataToChartData(afterNutrientBudget, beforeNutrientBudget, nutrient, after?.displayName, before?.displayName);

    return (
        <div className="ReportSection u-page-break-avoid">
            <h2>{nutrient.text} movements</h2>

            <div className="printable-chart-container">
                <Bar data={barData} options={barOptions} className="printable-chart" />
            </div>

            <div className="u-flex u-flexJustifyCenter">
                <div className="u-pr-lg u-flex">
                    <div style={{ backgroundColor: utils.graphColoursMuted[1], width: "30px", height: "20px", marginRight: "6px" }}></div>
                    <span>Nutrients added</span>
                </div>
                <div className="u-pr-lg u-flex">
                    <div style={{ backgroundColor: utils.graphColoursMuted[2], width: "30px", height: "20px", marginRight: "6px" }}></div>
                    <span>Nutrients removed</span>
                </div>
                <div className="u-flex">
                    <div style={{ backgroundColor: utils.graphColoursMuted[4], width: "30px", height: "20px", marginRight: "6px" }}></div>
                    <span>Change in pools</span>
                </div>
            </div>
        </div>
    );
}

function mapPoolsDataToChartData(afterNutrientBudget, beforeNutrientBudget, nutrient, afterLabel, beforeLabel) {
    const allLabels = [...afterNutrientBudget.added.map((x) => x.name), ...afterNutrientBudget.removed.map((x) => x.name), ...afterNutrientBudget.changeInPools.map((x) => x.name)];
    const colours = [...afterNutrientBudget.added.map((n) => utils.graphColoursMuted[1]), ...afterNutrientBudget.removed.map((n) => utils.graphColoursMuted[2]), ...afterNutrientBudget.changeInPools.map((n) => utils.graphColoursMuted[4])];

    const barData = {
        labels: allLabels,
        datasets: [
            {
                label: afterLabel,
                data: [...afterNutrientBudget.added.map((x) => x.nutrients[nutrient.value]?.value), ...afterNutrientBudget.removed.map((x) => x.nutrients[nutrient.value]?.value * -1), ...afterNutrientBudget.changeInPools.map((x) => x.nutrients[nutrient.value]?.value)],
                backgroundColor: colours,
                borderWidth: 1,
            },
        ],
    };

    if (beforeNutrientBudget) {
        barData.datasets.unshift({
            label: beforeLabel,
            data: [...beforeNutrientBudget.added.map((x) => x.nutrients[nutrient.value]?.value), ...beforeNutrientBudget.removed.map((x) => x.nutrients[nutrient.value]?.value * -1), ...beforeNutrientBudget.changeInPools.map((x) => x.nutrients[nutrient.value]?.value)],
            backgroundColor: "#E3EEF8",
            borderColor: colours,
            borderWidth: 1,
        });
    }

    return barData;
}

const barOptions = {
    scales: {
        x: {
            display: true,
        },
        y: {
            display: true,
            title: { display: true, text: "kg/ha/yr" },
            grid: {
                color: function (context) {
                    return context.tick.value === 0 ? "#aaa" : "#e1e1e1";
                },
            },
        },
    },
    maintainAspectRatio: false,
    responsive: true,
};
