import { Fragment } from "react";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import { farmSoilsViewModel } from "common/viewModels";
import Watermark from "components/Watermark";
import { SummaryRow } from "components/PlanningReport";

export default function FarmSoils({ farm, before, after }) {
    const refData = useRefData();
    const [soilData = []] = farmSoilsViewModel(farm, after.details, refData);
    const [baseSoilData = []] = before ? farmSoilsViewModel(farm, before.details, refData) : [];

    const uniqueSoils = soilData.map((sd) => ({ order: sd.order, group: sd.group, smapReference: sd.smapReference, name: sd.name, category: `${sd.group}/${sd.order}  ${sd.smapReference || sd.name || ""}` }));
    baseSoilData.forEach((sd) => {
        if (!uniqueSoils.find((us) => us.order === sd.order && us.group === sd.group && us.smapReference === sd.smapReference && us.name === sd.name)) {
            uniqueSoils.push({ order: sd.order, group: sd.group, smapReference: sd.smapReference, name: sd.name, category: `${sd.group}/${sd.order}  ${sd.smapReference || sd.name || ""}` });
        }
    });

    const isComparing = !!before && !!after;

    return (
        <div className="ReportSection">
            <h1>Farm soils</h1>
            <div className="Table Table-Compressed">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th data-width="25"></th>
                            <th></th>
                            {isComparing && (
                                <th className="numeric u-before" data-width="12.5">
                                    {utils.truncateText(before.displayName, 35)}
                                </th>
                            )}
                            <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                {utils.truncateText(after.displayName, 35)}
                            </th>
                            {isComparing && (
                                <th className="numeric" data-width="16.7">
                                    Change
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {uniqueSoils
                            .sort((a, b) => (a.category > b.category ? 1 : -1))
                            .map((us, i) => {
                                const actualSoil = soilData.find((sd) => sd.group === us.group && sd.order === us.order && sd.smapReference === us.smapReference && sd.name === us.name);
                                const baseSoil = baseSoilData.find((sd) => sd.group === us.group && sd.order === us.order && sd.smapReference === us.smapReference && sd.name === us.name);
                                const category = us.category;

                                const beforeSoilModified = baseSoil ? (baseSoil.modified === "Yes" ? "Yes" : "No") : "-";
                                const afterSoilModified = actualSoil ? (actualSoil.modified === "Yes" ? "Yes" : "No") : "-";

                                return (
                                    <Fragment key={i}>
                                        <SummaryRow beforeValue={baseSoil?.totalArea} afterValue={actualSoil?.totalArea} isComparing={isComparing} category={category} prefix={category} label="Area" unit="ha" dp={1} />
                                        <SummaryRow beforeValue={beforeSoilModified} afterValue={afterSoilModified} isComparing={isComparing} category=" " label="Properties modified" prefix={category} dispalyValuesAsText />
                                    </Fragment>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div className="u-ml-sm">
                <sub style={{ lineHeight: "1rem" }}>1 - Olsen P is calculated using soil test results, proportioned by the area of the farm that this soil covers.</sub>
            </div>
        </div>
    )
}
