
export default function HelpTooltip({ tip, place = "top" }) {
    const hasTip = tip?.length > 0;
    if (!hasTip) return null;

    return (
        <>
            <span data-tooltip-id="help-tooltip" data-tooltip-content={tip} data-tooltip-place={place} className="help-tootip-link u-print-none">i</span>
        </>
    )
}
