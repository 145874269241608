import * as utils from "common/utils";
import { LoadingPanel } from "components/LoadingPanel";
import Watermark from "components/Watermark";
import { SummaryRowForV2API } from "components/PlanningReport";
import { useAnalysisResults } from "containers/BudgetHome";

export default function NutrientSummary({ before, after }) {
    const { isLoading: beforeLoading, data: beforeResults } = useAnalysisResults(before?.details);
    const { isLoading: afterLoading, data: afterResults } = useAnalysisResults(after?.details);
    if (!beforeResults?.nitrogen && !afterResults?.nitrogen) {
        return null;
    }

    const isLoading = afterLoading || (before && beforeLoading);
    const isComparing = !!before && !!after;

    return (
        <div className="ReportSection">
            <h1>Nutrients</h1>
            <LoadingPanel isLoading={isLoading}>
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="25"></th>
                                <th></th>
                                {isComparing && (
                                    <th className="numeric u-before" data-width="12.5">
                                        {utils.truncateText(before.displayName, 35)}
                                    </th>
                                )}
                                <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                    {utils.truncateText(after.displayName, 35)}
                                </th>
                                {isComparing && (
                                    <th className="numeric" data-width="16.7">
                                        Change
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <SummaryRowForV2API before={beforeResults?.nitrogen?.totalLoss} after={afterResults?.nitrogen?.totalLoss} isComparing={isComparing} category="Nitrogen" label="Total loss" prefix="Nitrogen" rowClass="Table-tr--nloss Table-tr--start" />
                            <SummaryRowForV2API before={beforeResults?.nitrogen?.lossPerHectare} after={afterResults?.nitrogen?.lossPerHectare} isComparing={isComparing} category=" " label="Average loss" prefix="Nitrogen" rowClass="Table-tr--nloss Table-tr--middle" />
                            <SummaryRowForV2API before={beforeResults?.nitrogen?.conversionEfficiency} after={afterResults?.nitrogen?.conversionEfficiency} isComparing={isComparing} category=" " label="NCE" prefix="Nitrogen" rowClass="Table-tr--nloss Table-tr--middle" />
                            <SummaryRowForV2API before={beforeResults?.nitrogen?.surplus} after={afterResults?.nitrogen?.surplus} isComparing={isComparing} category=" " label="N Surplus" prefix="Nitrogen" rowClass="Table-tr--nloss Table-tr--end" />
                            <SummaryRowForV2API before={beforeResults?.phosphorus?.totalLoss} after={afterResults?.phosphorus?.totalLoss} isComparing={isComparing} category="Phosphorus" label="Total loss" prefix="Phosphorus" rowClass="Table-tr--ploss Table-tr--start" />
                            <SummaryRowForV2API before={beforeResults?.phosphorus?.lossPerHectare} after={afterResults?.phosphorus?.lossPerHectare} isComparing={isComparing} category=" " label="Average loss" prefix="Phosphorus" dp={1} rowClass="Table-tr--ploss Table-tr--end" />
                            <SummaryRowForV2API before={beforeResults?.phosphorus?.surplus} after={afterResults?.phosphorus?.surplus} isComparing={isComparing} category=" " label="P Surplus" prefix="Phosphorus" rowClass="Table-tr--ploss Table-tr--end" />
                        </tbody>
                    </table>
                </div>
            </LoadingPanel>
        </div>
    )
}
