import ActionLink from "components/ActionLink";
import { useImportMapModal } from "./ImportMapModal";
import { usePreviewImportedMapModal } from "./PreviewImportedMapModal";

export default function ImportMapLink({ farm, analysis, className }) {
    const [importMapModal, openImportMapModal] = useImportMapModal(analysis);
    const [previewImportedMapModal, openPreviewImportedMapModal] = usePreviewImportedMapModal(farm, analysis);

    const importMap = () => {
        const onSubmit = (importedGeoJson, source) => openPreviewImportedMapModal(importedGeoJson, source);
        openImportMapModal(onSubmit);
    };

    return (
        <>
            <ActionLink id="import-map" className={className} onClick={importMap}>
                Import a map of this farm
            </ActionLink>
            {importMapModal}
            {previewImportedMapModal}
        </>
    )
}
