import React from "react";
import { Field } from "redux-form";
import * as validations from "common/validations";
import Modal from "components/ModalOld";
import InputPack from "components/InputPack";

class MyAccountModal extends React.Component {
    _submit = (formValues) => {
        this.props.modal.onConfirm(formValues);
    };

    render() {
        const { initialValues } = this.props;

        return (
            <Modal title="My account" submitLabel="Save" initialValues={initialValues} onSubmit={this._submit}>
                <div className="Grid">
                    <div className="Grid-cell u-lg-width2of3">
                        <Field name="accountName" type="text" label="Account name" placeholder="This may be the organisation/farm name or your own name." requiredLabel={true} component={InputPack} validate={[validations.required, validations.maxLength(50), validations.minLength(1)]} />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default MyAccountModal;
