import React from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as domain from "common/domain";
import ActionLink from "components/ActionLink";
import { useRefData } from "common/hooks";

const RiparianCard = ({ farm, analysis, block, promptBeforeDelete }) => {
    const refData = useRefData();

    const _blockIcon = utils.getBlockIcon(block.type);
    const _blockType = utils.getBlockTypeText(refData, block);

    const riparianStrip = block.riparianStrip;
    const _isPopulatedRiparianStrip = block.type === domain.BlockType.NonProductiveRiparian && riparianStrip && riparianStrip.catchmentArea > 0;

    const editLink = `/app/farm/${farm.id}/analysis/${analysis.id}/drainage/riparian/${block.id}`;

    return (
        <div className="FarmTable_wrapper" id={block.id}>
            <ul className="DataWidthTable FarmTable">
                <li className="FarmTable-title" data-width="md-100 xl-30">
                    <div className="FarmTitle">
                        <img className="FarmTitle-icon" src={_blockIcon} alt="Riparian" />
                        <div className="FarmTitle-heading">
                            <Link to={editLink} className="FarmTitle-link">
                                <span className="FarmTitle-name" id={`${block.id}_name`} title={block.name}>
                                    {block.name}
                                </span>
                            </Link>
                            <div className="FarmTitle-sub">{_blockType}</div>
                        </div>
                    </div>
                </li>
                {_isPopulatedRiparianStrip && (
                    <li data-width="md-20 xl-20">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Riparian strip catchment area</span>
                                <span className="FarmTable-value" id={`${block.id}_catchment-area`}>
                                    {riparianStrip.catchmentArea} ha
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {_isPopulatedRiparianStrip && (
                    <li data-width="md-20 xl-20">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Riparian strip length</span>
                                <span className="FarmTable-value" id={`${block.id}_strip-length`}>
                                    {riparianStrip.stripLength} m
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {_isPopulatedRiparianStrip && (
                    <li data-width="md-20 xl-20">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Riparian strip width</span>
                                <span className="FarmTable-value" id={`${block.id}_strip-width`}>
                                    {riparianStrip.pathLength} m
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {!_isPopulatedRiparianStrip && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        {block.type === domain.BlockType.NonProductiveRiparian && (
                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/drainage/riparian/${block.id}`} className="IconLink--arrow-plus" type="button" id={`${block.id}_add-riparian-strip-details`}>
                                <span>Specify riparian model inputs</span>
                            </Link>
                        )}
                    </li>
                )}
            </ul>
            {_isPopulatedRiparianStrip && (
                <div className="ActionsBar">
                    <div className="ActionsBar-right">
                        <ul className="ActionsBar-links">
                            <li>
                                <ActionLink onClick={(e) => promptBeforeDelete(e, block)} className="IconLink--cross-circle" id={`${block.id}_delete-riparian-strip`}>
                                    Clear P model input
                                </ActionLink>
                            </li>
                            <li>
                                <Link to={editLink} className="IconLink--edit" id={`${block.id}_edit-riparian-strip`}>
                                    Edit P model input
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RiparianCard;
