import * as api from "api";
import ReactGA from "react-ga4";

const actionTypes = {
    FARM_SEARCH: "FARM_SEARCH",
    FARM_SEARCH_SUCCESS: "FARM_SEARCH_SUCCESS",
    FARM_SEARCH_FAILURE: "FARM_SEARCH_FAILURE",
    FARM_SEARCH_CANCEL: "FARM_SEARCH_CANCEL",
    FARM_SEARCH_CLEAR: "FARM_SEARCH_CLEAR",
    FARM_SEARCH_TOGGLE_MY_FARM: "FARM_SEARCH_TOGGLE_MY_FARM",
};
export default actionTypes;

export function farmSearch(searchText, filterBy) {
    const encodedSearchText = encodeURIComponent(searchText);

    ReactGA.event({
        category: "Farm",
        action: "Search",
    });
    if (filterBy === "Publications") {
        return api.get({
            path: `publications/search?searchText=${encodedSearchText}`,
            onBeforeSend: () => {
                return { type: actionTypes.FARM_SEARCH };
            },
            onSuccess: (response) => {
                return { type: actionTypes.FARM_SEARCH_SUCCESS, payload: { response, filterBy, searchText } };
            },
            onFailure: (error) => {
                error.handled = true;
                return { type: actionTypes.FARM_SEARCH_FAILURE };
            },
        });
    } else {
        return api.get({
            path: `search/farms?searchText=${encodedSearchText}&filterBy=${filterBy}`,
            onBeforeSend: () => {
                return { type: actionTypes.FARM_SEARCH };
            },
            onSuccess: (response) => {
                return { type: actionTypes.FARM_SEARCH_SUCCESS, payload: { response, filterBy, searchText } };
            },
            onFailure: (error) => {
                error.handled = true;
                return { type: actionTypes.FARM_SEARCH_FAILURE };
            },
        });
    }
}

export function farmSearchClear() {
    return (dispatch) => {
        dispatch({ type: actionTypes.FARM_SEARCH_CANCEL });
        dispatch({ type: actionTypes.FARM_SEARCH_CLEAR });
    };
}
