import { FileSummary } from "components/PlanningReport";
import PhysicalCharacteristics from "./PhysicalCharacteristics";
import FarmSoils from "./FarmSoils";

export default function ClimateSoils({ farm, before, after, showFileComparison }) {
    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <PhysicalCharacteristics before={before} after={after} />
            <FarmSoils farm={farm} before={before} after={after} />
        </div>
    )
}
