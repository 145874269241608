import numeral from "numeral";
import { dateLong } from "common/utils";

const memoize = (fn) => {
    const cache = {};
    return (...args) => {
        const stringifiedArgs = JSON.stringify(args);
        const result = (cache[stringifiedArgs] = cache[stringifiedArgs] || fn(...args));
        return result;
    };
};

export const formatters = {
    formatInt: (value) => {
        if (!value) return undefined;

        return numeral(numeral(value).format("0")).value();
    },
    formatDecimal: memoize((decimalPlaces) => (value) => {
        if (!value) return undefined;

        let formatString = "0";
        const dp = numeral(decimalPlaces).value();
        if (dp && dp > 0) {
            formatString += ".";
            formatString = formatString.padEnd(dp + 2, "0");
        }
        return numeral(numeral(value).format(formatString)).value();
    }),
    formatDate: (value) => {
        if (!value) return undefined;

        return dateLong(value, undefined);
    },
};

const hasValue = (value) => {
    return value && String(value).trim().length > 0 && !["Undefined", "0"].includes(value);
};

const hasValueAllowZero = (value) => {
    return value !== undefined && String(value).trim().length > 0 && !["Undefined"].includes(value);
};

export const validators = {
    requiredAllowZero: (value, error = "Required") => (hasValueAllowZero(value) ? undefined : error),
    required: (value, error = "Required") => (hasValue(value) ? undefined : error),
    requiredIf: (value, otherValue, error = "Required") => {
        const hasFirstValue = hasValue(value);
        const hasOtherValue = hasValue(otherValue);
        return hasFirstValue || hasOtherValue ? undefined : error;
    },
    range: memoize((min, max) => (value) => (value || value === 0) && (value < min || value > max) ? `Must be between ${min} and ${max}` : undefined),
    minLength: memoize((min) => (value) => value && String(value).length < min ? `Must be ${min} character${min > 1 ? "s" : ""} or more` : undefined),
    maxLength: memoize((max) => (value) => value && String(value).length > max ? `Must be ${max} character${max > 1 ? "s" : ""} or less` : undefined),
    max: memoize(
        (max) =>
            (value, error = `Must be ${max} or less`) =>
                (value || value === 0) && value > max ? error : undefined
    ),
    email: (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? "Invalid email address" : undefined),
    nameRegex: (value) => (/^[a-zA-Z0-9\s@&'()+:,."\-/]+$/.test(value) ? undefined : "Invalid. Can only contain alphanumeric characters, spaces and @&'()+:,.-\"/"),
    alphaNumeric: (value) => (/^[A-Za-z0-9\s()\-=_./\\]*$/.test(value) ? undefined : "Invalid identifier. Can only contain alphanumeric characters, spaces and ()-=_./\\"),
};
