import { farmSoilsViewModel } from "common/viewModels";
import Watermark from "components/Watermark";
import { useRefData } from "common/hooks";

export default function FarmSoils({ farm, analysis }) {
    const refData = useRefData();
    const [soilData = [], blocksWithNoSoils] = farmSoilsViewModel(farm, analysis, refData);

    return (
        <>
            {soilData.length === 0 && "There are no soils defined for this analysis."}
            {soilData.length > 0 && (
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="10">S-Map ref/name</th>
                                <th data-width="10">Group/Order</th>
                                <th data-width="10">Drainage class</th>
                                <th data-width="10">Modified</th>
                                <th data-width="5" className="u-textRight">
                                    Total area (ha)
                                </th>
                                <th data-width="10" className="u-textRight">
                                    % of prod. blocks
                                </th>
                                <th data-width="10" className="u-textCenter">
                                    Blocks
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {soilData
                                .sort((a, b) => ((a.smapReference || a.name) < (b.smapReference || b.name) ? -1 : 1))
                                .map((d, i) => {
                                    return (
                                        <tr key={i} className="Table-tr">
                                            <td>{d.smapReference || d.name}</td>
                                            <td>
                                                {d.group}/{d.order}
                                            </td>
                                            <td>{d.drainageClass}</td>
                                            <td>{d.modified}</td>
                                            <td className="u-textRight">{d.totalArea}</td>
                                            <td className="u-textRight">{d.percentageOfProductive}</td>
                                            <td className="u-textCenter">{d.blockCount}</td>
                                        </tr>
                                    );
                                })}

                            {blocksWithNoSoils && (
                                <tr className="Table-tr">
                                    <td colSpan="4">
                                        <b>Blocks require soil data</b> - {blocksWithNoSoils.blocks}
                                    </td>
                                    <td className="u-textRight">{blocksWithNoSoils.totalArea}</td>
                                    <td className="u-textRight">{blocksWithNoSoils.percentageOfProductive}</td>
                                    <td className="u-textCenter">-</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}
