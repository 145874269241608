import React from "react";
import { v4 as uuidv4 } from "uuid";
import { compose, withAppContext, withState, withActions } from "containers/HOC";
import NotFound from "components/NotFound";
import Page from "components/Page";
import PageBody from "components/PageBody";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import Loader from "components/Loader";
import { modalType } from "components/ModalConductor";
import TileActionsBar from "components/TileActionsBar";
import UserList from "./UserList";
import SubscriptionList from "./SubscriptionList";
import InvitationList from "./InvitationList";
import { modal as launchModal } from "containers/App/_actions";
import { getAccount, inviteUser, getPaymentHistory } from "./_actions";
import { updateMyOrg } from "../MyOrg/_actions";
import PaymentHistory from "./PaymentHistory";
import ActionLink from "components/ActionLink";

class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    _sendInvite = (invitation) => {
        this.props.inviteUser(invitation);
    };

    _launchInviteModal = (invitation) => (e) => {
        e.preventDefault();
        if (!invitation) invitation = { id: uuidv4() };
        invitation.existingUsers = this.props.users;
        invitation.accountName = this.props.auth.accountName;

        this.props.launchModal(modalType.INVITE_USER, this._sendInvite, invitation);
    };

    _updateMyAccount = (myAccount) => {
        this.props.updateMyOrg(myAccount);
    };

    _launchMyAccount = () => (e) => {
        e.preventDefault();
        this.setState({ isUserNavOpen: false });
        const { auth } = this.props;
        const myAccount = {
            name: auth.accountName,
            expires: auth.accountExpires,
            id: auth.accountId,
        };
        this.props.launchModal(modalType.MY_ACCOUNT, this._updateMyAccount, myAccount);
    };

    componentDidMount() {
        this.props.getAccount();
        this.props.getPaymentHistory();
    }

    render() {
        const { online, accountName, isLoading, users, invitations = [], subscriptions, userId, paymentHistory, creditLimit } = this.props;
        return (
            <Page>
                <section className="PageHead">
                    <div className="Container">
                        <div className="FarmTitle">
                            <div className="FarmTitle-heading">
                                <span className="FarmTitle-name">{accountName}</span>
                            </div>
                        </div>
                    </div>
                </section>
                <PageBody>
                    {isLoading && <Loader message="Loading..." />}
                    <Tile title={accountName + " details"} referrer="/">
                        <TileBody>
                            {this.props.authContext.isAdmin && (
                                <TileActionsBar>
                                    <ul className="ActionsBar-links">
                                        <li>
                                            {online && users && users.length > 0 && this.props.accountType !== "Student" && (
                                                <ActionLink id="add-user" className="IconLink--arrow-plus" onClick={this._launchInviteModal()}>
                                                    Add staff member to organisation
                                                </ActionLink>
                                            )}
                                        </li>
                                        <li>
                                            {online && (
                                                <ActionLink id="manage-account" className="IconLink--settings" onClick={this._launchMyAccount()}>
                                                    Organisation settings
                                                </ActionLink>
                                            )}
                                        </li>
                                    </ul>
                                </TileActionsBar>
                            )}
                            {users && users.length > 0 && <UserList online={online} users={users} userId={userId} />}
                            {invitations.length > 0 && <InvitationList online={online} invitations={invitations} resend={this._launchInviteModal} title="User invitations" />}
                            {subscriptions && <SubscriptionList online={online} subscriptions={subscriptions} creditLimit={creditLimit} />}
                            {!isLoading && paymentHistory && paymentHistory.length > 0 && <PaymentHistory paymentHistory={paymentHistory} />}
                            {!isLoading && (!users || users.length === 0) && (!paymentHistory || paymentHistory.length === 0) && <NotFound />}
                        </TileBody>
                    </Tile>
                </PageBody>
            </Page>
        );
    }
}

export default compose(
    withAppContext,
    withState((state) => {
        return {
            online: state.app.online,
            isLoading: state.app.isLoading,
            auth: state.auth,
            accountName: state.auth.accountName,
            userId: state.auth.userId,
            users: state.userManagement.users,
            subscriptions: state.userManagement.subscriptions,
            creditLimit: state.userManagement.creditLimit,
            invitations: state.userManagement.invitations ? state.userManagement.invitations.filter((i) => i.status !== "Accepted" && i.target !== "Organisation") : [],
            paymentHistory: state.userManagement.paymentHistory,
        };
    }),
    withActions({ getAccount, inviteUser, updateMyOrg, launchModal, getPaymentHistory })
)(Details);
