import React from "react";
import Loader from "components/Loader";
import NotFound from "components/NotFound";
import Page from "components/Page";
import PageBody from "components/PageBody";
import FarmPageHead from "./FarmPageHead";
import Reporting from "containers/Reporting/Reporting";
import { useFarm, useFarmGroupReportData } from "containers/hooks";
import { useParams } from "react-router-dom";

function FarmHomeGroup(props) {
    const { data: farm } = useFarm();
    const { farmGroupId } = useParams();
    const reportData = useFarmGroupReportData(farmGroupId, true);
    const { farmGroup } = reportData;
    const loading = !farm || !farmGroup;
    const hasFarmGroup = farmGroup && farmGroup.id;
    const farmGroupNotFound = farmGroup && !farmGroup.id;
    const name = farmGroup && farmGroup.name;
    const breadcrumb = [{ name: farm?.name, to: `/app/farm/${farm?.id}` }];

    if (hasFarmGroup) {
        breadcrumb.push({ name: "Farm groups", to: `/app/farm/${farm?.id}/groups` });
        breadcrumb.push({ name: farmGroup.name });
    }

    return (
        <Page>
            <FarmPageHead title={name} breadcrumb={breadcrumb} />
            <PageBody>
                {loading && <Loader />}
                {farmGroupNotFound && <NotFound />}
                {hasFarmGroup && <Reporting {...props} {...reportData} />}
            </PageBody>
        </Page>
    );
}

export default FarmHomeGroup;
