import React from "react";
import { compose, withAppContext, withState, withActions } from "containers/HOC";
import { Link } from "react-router-dom";
import { setFormState } from "containers/App/_actions";
import Spinner from "components/Spinner/Spinner";
import Alert from "components/Alert";
import PanelTabs from "./PanelTabs";

class Panel extends React.Component {
    state = {
        openIfChanges: this.props.openIfChanges,
        collapsed: this.props.open === undefined ? false : !this.props.open,
    };

    toggle = () => {
        const { setFormState, stickyHeaderName, formState, referrer, actions, notCollapsible } = this.props;
        const clickable = !referrer && !actions && !notCollapsible;
        if (!clickable) return;
        const collapsed = stickyHeaderName ? !formState[stickyHeaderName] : !this.state.collapsed;
        stickyHeaderName ? setFormState(stickyHeaderName, collapsed) : this.setState({ collapsed });
        this.props.onChange && this.props.onChange(collapsed);
    };

    static getDerivedStateFromProps(props, state) {
        if (state.openIfChanges !== props.openIfChanges) {
            return {
                ...state,
                collapsed: false,
                openIfChanges: props.openIfChanges,
            };
        }
        return state;
    }

    render() {
        const { id, title, style, referrer, actions, small, medium, midBlue, skyBlue, green, orange, nloss, ploss, ghg, inError, className, children, notCollapsible, img, iconClassName, stickyHeaderName, formState, loading, waiting, isInvalid, info, moreInfo, warning, error, help, showQuickTips, tabs } = this.props;
        const collapsed = isInvalid ? false : stickyHeaderName ? formState[stickyHeaderName] : this.state.collapsed;

        let classNames = "Tile u-pos-rel u-print-noborder";
        if (collapsed) classNames += " u-print-none";

        if (loading) classNames += " Tile-loading";

        if (waiting) classNames += " waiting";

        if (midBlue) classNames += " midblue";
        else if (skyBlue) classNames += " skyblue";
        else if (green) classNames += " green";
        else if (orange) classNames += " orange";
        else if (nloss) classNames += " nloss";
        else if (ploss) classNames += " ploss";
        else if (ghg) classNames += " ghg";
        else if (inError) classNames += " error";
        else classNames += " darkblue";

        if (className) classNames += ` ${className}`;

        const clickable = !referrer && !actions && !notCollapsible;

        return (
            <div id={id} className={classNames} style={style}>
                {title && (
                    <div className={`Tile-head u-print-none ${clickable ? "u-link" : ""} ${small ? "u-pt-xs u-pb-xs" : ""} ${medium ? "u-pt-md u-pb-md" : ""} ${collapsed ? "Tile-head--closed" : ""}`} onClick={this.toggle}>
                        {img && <img id={`tile-head.titleIcon`} src={img} className="u-mr-xs u-p-5" width="35" height="35" alt="" />}
                        {iconClassName && <span className={iconClassName}></span>}
                        <span className="u-mr-sm u-textNoWrap">{`${title}${collapsed ? "..." : ""}`}</span>
                        {(loading || waiting) && <Spinner />}
                        <span className="Tile-head-close u-flexSplit">
                            {actions && actions}
                            {referrer && <Link to={referrer} title="Close" className="IconLink--cross u-textWhite u-ml-lg"></Link>}
                            {clickable && <i className={`icon ${collapsed ? "icon-arrow-down" : "icon-arrow-up"}`}></i>}
                        </span>
                    </div>
                )}
                {!title && tabs && <PanelTabs tabs={tabs} />}
                {!collapsed && info && <Alert className="u-mt-0 u-mb-0" type="info" text={info} moreInfo={moreInfo} />}
                {!collapsed && error && <Alert className="u-mt-0 u-mb-0" type="error" text={error} moreInfo={moreInfo} />}
                {!collapsed && warning && <Alert className="u-mt-0 u-mb-0" type="warning" text={warning} moreInfo={moreInfo} />}
                {!collapsed && help && showQuickTips && <Alert className="u-mt-0 u-mb-0" type="help" text={help} />}
                {!collapsed && children}
            </div>
        );
    }
}

export default compose(
    withAppContext,
    withState((state) => {
        return {
            formState: state.app.formState,
        };
    }),
    withActions({ setFormState })
)(Panel);
