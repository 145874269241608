import Alert from "components/Alert";
import { useFieldHelp } from "./useFieldHelp";

export default function TextareaField({ input, label, placeholder, rows, className, onChange, info, warning, tip, maxLength, autoFocus, readOnly, disabled, required, meta }) {
    const [fieldHelpLink, fieldHelp] = useFieldHelp(tip);

    const handleOnChange = (e) => {
        input.onChange(e.target.value);

        if (onChange) onChange(e.target.value);
    };

    const error = meta && meta.touched && meta.error;

    return (
        <div className={`Field ${error ? "has-error" : ""} ${className ? className : ""}`}>
            {label && (
                <label htmlFor={input.name} className={`Field-label ${disabled && !readOnly ? "is-disabled" : ""}`}>
                    {label}
                    {required && <sup className="required">*</sup>}
                    {fieldHelpLink}
                </label>
            )}
            <div className="Textarea">
                <textarea {...input} id={input.name} data-test-id={input.name} type="text" rows={rows} className={rows ? "u-min-height-0" : ""} placeholder={placeholder || label} autoFocus={autoFocus} readOnly={readOnly} disabled={disabled} maxLength={maxLength} onChange={handleOnChange} />
            </div>
            {error && (
                <div id={`${input.name}-error`} data-test-id={`${input.name}-error`} className="Field-error u-block u-mt-sm">
                    {error}
                </div>
            )}
            {warning && <Alert type="warning" text={warning} className="u-mb-0" />}
            {info && <Alert type="info" text={info} className="u-mb-0" />}
            {fieldHelp}
        </div>
    );
}
