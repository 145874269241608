import React from "react";
import { connect } from "react-redux";
import Tile from "components/Tile";
import TileBody from "components/TileBody";

const SubscriptionList = ({ online, subscriptions, creditLimit }) => {
    return (
        <Tile title="Farm account subscriptions created by month" secondary collapsible>
            <TileBody>
                {creditLimit && creditLimit > 0 && (
                    <div>
                        <span>{"Credit limit: " + creditLimit}</span>
                    </div>
                )}
                <div className="Table">
                    <table>
                        <thead>
                            <tr>
                                <th>Month</th>
                                <th>Number subscriptions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(subscriptions.months).map((k) => (
                                <tr key={k}>
                                    <td>{k}</td>
                                    <td>{subscriptions.months[k]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </TileBody>
        </Tile>
    );
};

export default connect(null, null)(SubscriptionList);
