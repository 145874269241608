import numeral from "numeral";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { calendarYear } from "common/domain";
import * as FormUtils from "common/FormUtils";
import * as utils from "common/utils";
import InputPack from "components/InputPack";
import CheckboxPack from "components/CheckboxPack";
import { useRefData } from "common/hooks";

export default function OutdoorPigFeedAmountsFieldArray({ form, outdoorPigs, fieldName, title }) {
    const refData = useRefData();

    const fieldState = form.getFieldState(fieldName);
    const errorMessages = getErrorMessages(fieldState, refData);
    const feedAmountTotal = getFeedAmountTotal(outdoorPigs, fieldState);

    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const showGrowersFinishersFields = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;
    const feedingSystem = outdoorPigs.feedingSystem || {};

    return (
        <>
            <h3>{title}</h3>
            <div className="Table u-mt-md">
                <table>
                    <thead>
                        <tr>
                            <th data-width="10"></th>
                            <th className="th--shrink">
                                Override
                                <br />
                                defaults
                            </th>
                            {calendarYear.map((m) => (
                                <th key={m} className="u-textCenter" style={{ minWidth: "65px" }}>
                                    {m.substring(0, 3)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray name={fieldName}>
                            {({ fields }) => {
                                return fields.map((field, index) => {
                                    const feedAmount = fields.value[index];
                                    const stockClassDisplayText = utils.valueToText(refData.outdoorPigStockClasses, feedAmount.stockClass);

                                    if (["Weaners", "Growers", "Finishers"].includes(feedAmount.stockClass) && !showGrowersFinishersFields) return null;

                                    if (feedAmount.stockClass === "GiltDevelopers" && outdoorPigs.growOutUnitOnly) return null;

                                    if (feedAmount.stockClass === "Creep" && !feedingSystem.creepFeedSupplied) return null;

                                    return (
                                        <tr key={index}>
                                            <td className="u-textBold">{stockClassDisplayText}</td>
                                            <td className="u-textCenter">
                                                <Field name={`${field}.override`} onChange={toggleDefaults(feedAmount.stockClass, field, form, refData)} type="checkbox" component={CheckboxPack} />
                                            </td>
                                            <FieldArray name={`${field}.months`} validate={validateMonthlyFeedAmounts(feedAmount.stockClass)}>
                                                {({ fields }) => {
                                                    return calendarYear.map((calendarMonth) => {
                                                        const monthIndex = fields.value.findIndex((f) => f.month === calendarMonth);
                                                        const month = fields.value[monthIndex];
                                                        return (
                                                            <td key={month.month} className="u-textCenter">
                                                                <Field name={`${field}.months[${monthIndex}].amount`} noLabel maxLength="5" disabled={!feedAmount.override} hideErrors placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                                            </td>
                                                        );
                                                    });
                                                }}
                                            </FieldArray>
                                        </tr>
                                    );
                                });
                            }}
                        </FieldArray>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan="14" className="u-textRight">
                                {feedAmountTotal} <span className="u-textLower"> kg/day</span>
                            </th>
                        </tr>
                    </tfoot>
                </table>
                {errorMessages && errorMessages.length > 0 && (
                    <div className="Field-error u-block u-mt-0">
                        {errorMessages.map((msg) => (
                            <p key={msg}>{msg}</p>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

const toggleDefaults = (stockClass, field, form, refData) => (e) => {
    const selected = e.target.checked;
    if (!selected) {
        const defaultFeedAmount = (refData.outdoorPigDefaults || {}).feedAmountDefaults[stockClass];
        const months = calendarYear.map((m) => ({ month: m, amount: defaultFeedAmount }));
        form.mutators.setProperty(`${field}.months`, months);
    }
}

const getErrorMessages = (fieldState, refData) => {
    const errorMessages =
        fieldState &&
        (fieldState.error || []).reduce((errors, error, index) => {
            if (error && error.months.some((m) => m.amount)) {
                const monthIndex = error.months.findIndex((m) => m.amount);
                const message = error.months[monthIndex].amount.toLowerCase();

                const stockClass = fieldState.value[index].stockClass;
                const stockClassDisplayText = utils.valueToText(refData.outdoorPigStockClasses, stockClass);
                errors.push(`${stockClassDisplayText} - ${message}`);
            }
            return errors;
        }, []);
    return errorMessages;
}

const getFeedAmountTotal = (outdoorPigs, fieldState) => {
    const feedAmountTotal =
        fieldState &&
        (fieldState.value || []).reduce((total, feedAmount) => {
            const exclude = feedAmount.stockClass === "GiltDevelopers" && outdoorPigs.growOutUnitOnly;
            const yearTotal = feedAmount.months.reduce((total, month) => (total += numeral(month.amount).value()), 0);
            const yearAvg = exclude ? 0 : yearTotal / 12;
            return (total += yearAvg);
        }, 0);
    return numeral(feedAmountTotal).format("0.00");
}

const validateMonthlyFeedAmounts = (stockClass) => (months) => {
    const errors = [];

    months &&
        months.forEach((month) => {
            const error = {};

            const maxAmount = stockClass === "SowsLactating" ? 12 : 5;
            error.amount = FormUtils.validators.required(month.amount);
            error.amount = error.amount || FormUtils.validators.range(0.01, maxAmount)(month.amount);

            errors.push(error);
        });

    return errors;
}
