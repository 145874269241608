import ReactGA from "react-ga4";
import * as api from "api";
import appActionTypes from "containers/App/_actions";

export const createFarmGroup = (farmGroup) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Farm Group",
            action: "Create",
        });
        return dispatch(
            api.post({
                path: `farmGroups`,
                content: farmGroup,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured.Please try again or call support if the problem persists.");
                    return [];
                },
            })
        );
    });
};

export const findFarms = (searchText) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Farm Group",
            action: "Farm Search",
        });
        const encodedSearchText = encodeURIComponent(searchText);
        return dispatch(
            api.get({
                path: `search/farms?searchText=${encodedSearchText}&filterBy=AllFarms`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getFarmGroup = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Farm Group",
            action: "Get",
        });
        return dispatch(
            api.get({
                path: `farmGroups/${id}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const acceptFarmGroupInvitation = (farmGroupId, farmId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Farm Group",
            action: "Accept Invitation",
        });
        return dispatch(
            api.put({
                path: `farmGroups/${farmGroupId}/invitations/${farmId}/accept`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const declineFarmGroupInvitation = (farmGroupId, farmId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Farm Group",
            action: "Decline Invitation",
        });
        return dispatch(
            api.put({
                path: `farmGroups/${farmGroupId}/invitations/${farmId}/decline`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const deleteFarmGroupInvite = (farmGroupId, farmId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Farm Group",
            action: "Delete Invitation",
        });
        return dispatch(
            api.del({
                path: `farmGroups/${farmGroupId}/invitations/${farmId}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const deleteFarmGroup = (farmGroupId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Farm Group",
            action: "Delete",
        });
        return dispatch(
            api.del({
                path: `farmGroups/${farmGroupId}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const leaveFarmGroup = (farmGroupId, farmId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Farm Group",
            action: "Leave",
        });
        return dispatch(
            api.del({
                path: `farmGroups/${farmGroupId}/members/${farmId}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    switch (error.status) {
                        case 403:
                            dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: `You are not authorised to remove this farm from this group.` });
                            break;
                        default:
                            dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." });
                            break;
                    }
                },
            })
        );
    });
};
