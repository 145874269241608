import { Component, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import numeral from "numeral";
import { Field, FieldArray, formValueSelector as reduxFormValueSelector, change as reduxFormChange } from "redux-form";
import * as normalizers from "common/normalizers";
import * as validations from "common/validations";
import * as utils from "common/utils";
import Alert from "components/Alert";
import Modal from "components/ModalOld";
import FieldGroup from "components/FieldGroup";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack2";
import CheckboxPack from "components/CheckboxPack";
import { Panel, PanelBody } from "components/Panel";
import MonthDayInputPack from "components/MonthDayInputPack";
import * as animalUtils from "../_utils";
import LivestockEventsFieldArray from "./LivestockEventsFieldArray";
import { Menu, Item, Separator, MenuProvider } from "react-contexify";
import * as domain from "common/domain";
import * as icons from "common/icons";
import * as _utils from "../_utils";
import MobWeights from "components/MobWeights";
import { diff } from "deep-object-diff";
import ActionLink from "components/ActionLink";
import { useOnline, useRefData, useShowQuickTips } from "common/hooks";
import { httpClient } from "common/httpClient";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function LivestockModal({ initialValues, modal }) {
    const online = useOnline();
    const refData = useRefData();
    const showQuickTips = useShowQuickTips();
    const mob = useMob();
    const change = useReduxFormChange();

    return <LivestockModalClassComponent initialValues={initialValues} mob={mob} modal={modal} change={change} online={online} refData={refData} showQuickTips={showQuickTips} />
}

class LivestockModalClassComponent extends Component {
    constructor(props) {
        super(props);
        const { initialValues, refData } = props;
        const { livestock, livestocks } = initialValues;
        const { events = [], calvingDetails = {} } = livestock;
        const stockClass = animalUtils.getStockClass(livestock);
        const stockCounts = animalUtils.getStockCountsForMob(livestock);

        const orderedEvents = events.sort((a, b) => {
            const aDate = utils.parseDate(a.eventDate, "DD MMMM");
            const bDate = utils.parseDate(b.eventDate, "DD MMMM");
            aDate.set("year", 2000);
            bDate.set("year", 2000);
            const aDateMonth = aDate.month() + 1;
            const bDateMonth = bDate.month() + 1;

            if (aDateMonth < 7) aDate.add(1, "year");
            if (bDateMonth < 7) bDate.add(1, "year");
            const sameDay = aDate.isSame(bDate, "day");

            if (sameDay) {
                if (["Starting", "Weaning"].includes(b.eventType)) return 1;
                if (b.eventType === a.eventType && b.weightType === "Live") return 1;
            }
            return bDate.isBefore(aDate) ? 1 : -1;
        });

        const breedType = (enterpriseType) => {
            switch (enterpriseType) {
                case "Sheep":
                    return "sheep";
                case "DairyGrazing":
                case "Beef":
                    return "beef";
                case "Deer":
                    return "deer";
                case "DairyGoat":
                    return "dairyGoat";
                default:
                    return "dairy";
            }
        };

        const breedOptions = animalUtils.dairyBreeds(breedType(livestock.enterpriseType), livestocks, refData);

        this.state = {
            title: livestock.id ? "Edit livestock" : "Add livestock",
            [`show${livestock.enterpriseType}Fields`]: true,
            stockCounts: stockCounts,
            stockCountsInvalid: stockCounts.some((c) => c.stockCount < 0),
            eventCountsInvalid: events.length === 0,
            canBeWeaned: animalUtils.canStockClassBeWeaned(livestock.enterpriseType, stockClass),
            showBreedingReplacementFields: animalUtils.isBreedingReplacementStockClass(livestock.enterpriseType, stockClass),
            showSexFields: animalUtils.sexRequired(livestock.enterpriseType, stockClass),
            showAgeAndWeightFields: animalUtils.ageOrWeightRequired(livestock.enterpriseType, stockClass),
            midCalvingDate: calvingDetails ? calvingDetails.midCalvingDate : undefined,
            dryDate: calvingDetails ? calvingDetails.dryDate : undefined,
            stockClass,
            endweight: livestock.endWeight_kg,
            breed: animalUtils.getBreed(livestock),
            breedOptions,
            deaths: livestock.deaths || {},
            events: orderedEvents,
            editMaxWeight: livestock.maxWeight_kg > 0,
            editEndWeight: livestock.endWeight_kg > 0,
            startMonth: "July",
        };
    }

    _getMobWeights = async (mob) => {
        const { initialValues } = this.props;
        const { analysis, farm, enterprise } = initialValues;
        const mobClone = utils.clone(mob);
        const { events = [] } = mobClone;
        if (events.length === 0) return;
        if (events.find((e) => ["Starting", "Purchase"].includes(e.eventType) && !e.numberAnimals)) return;

        events.forEach((e) => {
            const eventDate = utils.parseDate(e.eventDate, "DD MMMM");
            e.day = eventDate.format("DD");
            e.month = eventDate.format("MMMM");
        });

        const mobEnterprise = {
            ...enterprise,
            mobs: [mobClone],
        };

        const mobWeights = await getMobWeights(farm, analysis, mobEnterprise);
        this.setState({ mobWeights });
    };

    componentDidMount() {
        const { initialValues } = this.props;
        const { livestock } = initialValues;
        this.setState({ mob: livestock });
    }

    componentDidUpdate() {
        const { mob: propsMob = {} } = this.props;
        const { mob: stateMob = {}, showAgeAndWeightFields } = this.state;
        const diffObj = diff(propsMob, stateMob);
        delete diffObj.name;
        const diffLength = Object.keys(diffObj).length;
        const { events = [] } = propsMob;
        const hasValidStartEvent = showAgeAndWeightFields && events.some((e) => ["Starting", "Purchase", "Weaning"].includes(e.eventType) && (e.weightKg || e.ageInMonths));
        if (diffLength > 0 && hasValidStartEvent) {
            const { initialValues } = this.props;
            const { livestock } = initialValues;
            this.setState({ mob: propsMob }, () => this._getMobWeights({ ...livestock, ...propsMob }));
        }
    }

    _getLastEventByType = (events, eventTypes) => {
        const lastEvent = events
            .filter((e) => eventTypes.includes(e.eventType))
            .reduce((result, event) => {
                if (!result) result = event;
                const maxDate = utils.parseDate(result.eventDate, "DD MMMM");
                const eventDate = utils.parseDate(event.eventDate, "DD MMMM");
                if (eventDate.isAfter(maxDate)) {
                    result = event;
                }
                return result;
            }, null);
        return lastEvent;
    };

    _getFirstEventByType = (events, eventTypes) => {
        const firstEvent = events
            .filter((e) => eventTypes.includes(e.eventType))
            .reduce((result, event) => {
                if (!result) result = event;
                const minDate = utils.parseDate(result.eventDate, "DD MMMM");
                const eventDate = utils.parseDate(event.eventDate, "DD MMMM");
                if (eventDate.isBefore(minDate)) {
                    result = event;
                }
                if (eventDate.isSame(minDate)) {
                    if (event.weightKg || event.ageInMonths) result = event;
                }
                return result;
            }, null);
        return firstEvent;
    };

    _validateEventSequence = (events) => {
        if (events.length === 0) return;

        const startingEvent = events.find((e) => e.eventType === "Starting");

        const weaningEvent = events.find((e) => e.eventType === "Weaning");
        const weaningDate = weaningEvent ? utils.parseDate(weaningEvent.eventDate, "DD MMMM") : moment();

        const firstPurchaseEvent = this._getFirstEventByType(events, ["Purchase"]);
        const firstPurchaseDate = firstPurchaseEvent ? utils.parseDate(firstPurchaseEvent.eventDate, "DD MMMM") : moment();

        const firstSaleEvent = this._getFirstEventByType(events, ["Sale", "SaleToWorks"]);
        const firstSaleDate = firstSaleEvent ? utils.parseDate(firstSaleEvent.eventDate, "DD MMMM") : moment();

        if (startingEvent) return;

        if (weaningEvent) {
            if (firstSaleDate.isBefore(weaningDate)) {
                return {
                    error: "Must be on/after weaning event",
                    eventDate: firstSaleDate,
                };
            }

            if (firstPurchaseDate.isBefore(weaningDate)) {
                return {
                    error: "Must be on/after weaning event",
                    eventDate: firstPurchaseDate,
                };
            }
        } else if (firstSaleDate.isBefore(firstPurchaseDate)) {
            return {
                error: "Must be on/after earliest purchase event",
                eventDate: firstSaleDate,
            };
        }

        return;
    };

    // Use redux form validate function to validate age or weight provided for bring-on events
    _validate = (values) => {
        const errors = {};
        const eventErrors = errors.events || [];
        const { events = [] } = values;

        if (events.length === 0) {
            errors.eventList = "Required";
        }

        const stockClass = animalUtils.getStockClass(values);
        const showAgeAndWeight = animalUtils.ageOrWeightRequired(values.enterpriseType, stockClass);
        if (showAgeAndWeight === false) return errors;

        const eventSequenceValidationResult = this._validateEventSequence(events);

        events.forEach((event, index) => {
            if (eventSequenceValidationResult && eventSequenceValidationResult.error) {
                const eventDate = utils.parseDate(event.eventDate, "DD MMMM");
                if (eventDate.isSame(eventSequenceValidationResult.eventDate)) {
                    while (eventErrors.length < index + 1) {
                        eventErrors.push({});
                    }
                    eventErrors[index].eventDate = eventSequenceValidationResult.error;
                }
            }
            if (!event.weightKg && !event.ageInMonths) {
                while (eventErrors.length < index + 1) {
                    eventErrors.push({});
                }
                eventErrors[index].weightKg = "Required";
                if (!["Weaning", "Sale", "SaleToWorks"].includes(event.eventType)) {
                    eventErrors[index].ageInMonths = "Required";
                }
            }
            if (values.endWeight_kg) {
                const maxCarcassWeight = numeral(values.endWeight_kg).value() * 0.7;
                if (event.weightType === "Carcass" && event.weightKg) {
                    while (eventErrors.length < index + 1) {
                        eventErrors.push({});
                    }
                    const carcassWeight = numeral(event.weightKg).value();
                    if (carcassWeight > maxCarcassWeight) eventErrors[index].weightKg = `Must be between 1 and ${Math.floor(maxCarcassWeight)}`;
                }
            }
        });

        return {
            ...errors,
            events: eventErrors,
        };
    };

    render() {
        const { initialValues, modal, refData, mob } = this.props;
        const { livestocks, livestock, addEvent } = initialValues;

        const lastMobWeights = this.state.mobWeights && this.state.mobWeights.length > 0 && this.state.mobWeights[this.state.mobWeights.length - 1];

        const monthsWithStock = this.state.stockCounts && this.state.stockCounts.filter((m) => m.stockCount > 0);
        const lastMonthWithAnimalsIndex = monthsWithStock && monthsWithStock.length > 0 && monthsWithStock[monthsWithStock.length - 1].month;
        const lastMonthWithAnimals = lastMonthWithAnimalsIndex === 6 ? domain.calendarYear[lastMonthWithAnimalsIndex - 1] : domain.calendarYear[lastMonthWithAnimalsIndex];

        const isDairyReplacements = livestock.enterpriseType === "DairyReplacements";

        const _calculateStockCounts = (events) => {
            const stockCounts = animalUtils.getStockCounts(events, this.state.deaths);
            this.setState({
                stockCounts: stockCounts,
                stockCountsInvalid: stockCounts.some((c) => c.stockCount < 0),
                events,
            });
        };

        const _onStockClassChanged = (e, stockClass) => {
            this.props.change("modal-form", "sex", null);
            this.props.change("modal-form", "mated", null);
            this.props.change("modal-form", "dairyBreed", null);

            this.setState({
                canBeWeaned: animalUtils.canStockClassBeWeaned(livestock.enterpriseType, stockClass),
                showBreedingReplacementFields: animalUtils.isBreedingReplacementStockClass(livestock.enterpriseType, stockClass),
                showSexFields: animalUtils.sexRequired(livestock.enterpriseType, stockClass),
                showAgeAndWeightFields: animalUtils.ageOrWeightRequired(livestock.enterpriseType, stockClass),
                stockClass,
                breed: null
            });
        };

        const _onBreedChanged = (e, stockBreed) => {
            this.setState({
                breed: stockBreed,
            });
        };

        const _onDeathChange = (e, value, month) => {
            const deaths = this.state.deaths;
            const { mob: propsMob = {} } = this.props;
            if (value) deaths[month] = value;
            else delete deaths[month];

            this.setState({ deaths: deaths });

            _calculateStockCounts(this.state.events);
            this.setState({ mob: propsMob }, () => this._getMobWeights({ ...livestock, ...propsMob }));
        };

        const _lactationDatesChanged = (field, e, n) => {
            const [isValid, days] = field === "dryDate" ? _utils.validateLactationDates(this.state.midCalvingDate, n) : _utils.validateLactationDates(n, this.state.dryDate);
            this.setState({ lactationDatesInvalid: !isValid, lactationDays: days, [field]: n });
        };

        const _endWeightChanged = (e, n) => {
            this.setState({ endweight: n });
        };

        const _maxWeightForEnterprise = () => {
            switch (livestock.enterpriseType) {
                case "DairyReplacements" || "Dairy":
                    return 2000;
                case "Sheep":
                    return 250;
                case "DairyGrazing":
                case "Beef":
                    return 2000;
                case "Deer":
                    return 500;
                default:
                    return 250;
            }
        };

        const _onSubmit = (livestock) => {
            const { events = [] } = livestock;
            if (events.length === 0) {
                this.setState({ eventsTouched: true, eventCountsInvalid: true });
                return;
            }
            events.filter((evt) => ["Sale", "SaleToWorks"].includes(evt.eventType) && !["Carcass", "Live"].includes(evt.weightType)).forEach((evt) => delete evt.weightKg);
            const [isValid, days] = _utils.validateLactationDates(this.state.midCalvingDate, this.state.dryDate);
            if (!isValid) {
                this.setState({ lactationDatesTouched: true, lactationDatesInvalid: true, lactationDays: days });
                return;
            }
            if (this.state.stockCountsInvalid) return;

            if (!this.state.showAgeAndWeightFields) {
                events.forEach((e) => {
                    delete e.ageInMonths;
                    delete e.weightKg;
                });
            }

            if (livestock.deaths) {
                Object.keys(livestock.deaths).forEach((k) => {
                    if (!livestock.deaths[k]) delete livestock.deaths[k];
                });
            }

            if (!this.state.showSexFields) {
                delete livestock.sex;
            }

            const showEndWeight = this.state.showAgeAndWeightFields && lastMonthWithAnimals && (lastMonthWithAnimals === "June" || !lastSaleEvent || lastSaleEvent.weightType === "Default");
            if (!showEndWeight) {
                delete livestock.endWeight_kg;
            }

            livestock.stockCounts = this.state.stockCounts;
            modal.onConfirm(livestock);
        };

        const addLivestockEvent = (event, month) => {
            const isSaleInJuly = event && month && month === "July" && ["Sale", "SaleToWorks"].includes(event.value);
            const eventDate = month ? `${isSaleInJuly ? "02" : "01"} ${month}` : this.state.events[this.state.events.length - 1].eventDate;

            const newEvent = { eventType: event && event.value, eventDate: eventDate, weightType: "Default" };

            let insertAt = -1;
            if (["Starting", "Weaning", "Purchase"].includes(newEvent.eventType) && this.state.events.length > 0) {
                const firstEvent = this.state.events[0];
                const firstEventMoment = utils.parseDate(firstEvent.eventDate, "DD MMMM");
                const newEventMoment = utils.parseDate(newEvent.eventDate, "DD MMMM");

                if (newEventMoment.isBefore(firstEventMoment) && !["Starting", "Weaning"].includes(firstEvent.eventType)) {
                    insertAt = 0;
                } else if (newEvent.eventType === "Weaning") {
                    delete newEvent.eventType;
                }
            }

            const events = this.state.events.map((e) => e);
            if (insertAt >= 0) events.splice(insertAt, 0, newEvent);
            else events.push(newEvent);
            this.setState({ eventCountsInvalid: false, events });
            addEvent(newEvent, insertAt);
        };

        const MenuItem = ({ icon, month, event }) => (
            <Item id={`${month}_${event.value}`} onClick={() => addLivestockEvent(event, month)}>
                <div className="u-flex">
                    <img src={icon} className="u-p-5" style={{ height: 24, marginBottom: "-7px" }} alt={event.text} />
                    <span style={{ paddingLeft: 8 }} id={`${month}_${event.value}`}>
                        {event.text}
                    </span>
                </div>
            </Item>
        );

        const hasStock = this.state.events.length > 0;
        const startingEvent = this.state.events.find((e) => ["Starting", "Weaning"].includes(e.eventType));
        let startingEventMonthIndex = -1;
        if (startingEvent) {
            const startingEventMonth = utils.parseDate(startingEvent.eventDate, "DD MMMM").month();
            if (startingEventMonth < 6) startingEventMonthIndex = startingEventMonth + 6;
            else startingEventMonthIndex = startingEventMonth - 6;
        }

        const firstPurchaseEvent = this._getFirstEventByType(this.state.events, ["Purchase"]);
        let firstPurchaseEventMonthIndex = -1;
        if (firstPurchaseEvent) {
            const firstPurchaseEventMonth = utils.parseDate(firstPurchaseEvent.eventDate, "DD MMMM").month();
            if (firstPurchaseEventMonth < 6) firstPurchaseEventMonthIndex = firstPurchaseEventMonth + 6;
            else firstPurchaseEventMonthIndex = firstPurchaseEventMonth - 6;
        }

        const lastSaleEvent = this._getLastEventByType(mob.events ? mob.events : this.state.events, ["Sale", "SaleToWorks"]);

        const primaryEventTypes = refData.animalEventTypes.filter((option) => (this.state.canBeWeaned && option.value === "Weaning") || option.value === "Starting" || option.value === "Purchase");
        const secondaryEventTypes = refData.animalEventTypes.filter((option) => option.value !== "Weaning" || this.state.canBeWeaned);
        const menuEvents = hasStock ? secondaryEventTypes : primaryEventTypes;
        const milkingHerd = this.state.showDairyFields && this.state.stockClass === "MilkingHerd";
        const dairyBreed = milkingHerd && this.state.breed ? refData.dairyBreeds.find((b) => b.value === this.state.breed) : undefined;
        const weightTip = milkingHerd ? domain.AnimalWeightTips.find((t) => t.key === "Average") : domain.AnimalWeightTips.find((t) => t.key === "Mature");
        const endWeightTip = domain.AnimalWeightTips.find((t) => t.key === "End");
        const maxWeightLabel = milkingHerd ? "Average Weight" : YELLOW_BREED.includes(this.state.stockClass) ? "Average mob weight" : "Mature weight";

        const milkingHerdMobCount = (livestocks || []).reduce((count, mob) => {
            if (mob.id !== livestock.id && mob.dairyStockClass === "MilkingHerd") count++;
            return count;
        }, 0);

        const dairyStockClasses = (refData.dairyStockClasses || []).map((option) => {
            const disabled = !milkingHerd && option.value === "MilkingHerd" && milkingHerdMobCount >= 3;
            return {
                ...option,
                disabled,
                groupIndex: disabled ? 1 : 0,
                groupLabel: disabled ? "Farm already has 3 milking herds" : "Available",
            };
        });

        let maxWeightPlaceholder = milkingHerd ? "Enter average weight of herd" : "Enter weight of animals when fully grown";
        if (dairyBreed && dairyBreed.weight) {
            maxWeightPlaceholder = utils.round(dairyBreed.weight, 1);
        } else if (!this.state.editMaxWeight && this.state.mobWeights && this.state.mobWeights.length > 0) {
            maxWeightPlaceholder = utils.round(this.state.mobWeights[0].matureWeight, 1);
        }

        const toggleEndWeight = () => {
            if (this.state.editEndWeight) {
                this.props.change("modal-form", "endWeight_kg", null);
            }
            this.setState({ editEndWeight: !this.state.editEndWeight });
        };

        const toggleMaxWeight = () => {
            if (this.state.editMaxWeight) {
                this.props.change("modal-form", "maxWeight_kg", null);
            }
            this.setState({ editMaxWeight: !this.state.editMaxWeight });
        };

        const endWeightDefault = lastMobWeights && !this.state.editEndWeight ? utils.round(lastMobWeights.startWeight + Object.keys(lastMobWeights.changeInLiveWeight).reduce((t, m) => (t += lastMobWeights.changeInLiveWeight[m]), 0), 1) : `Weight of animals in ${lastMonthWithAnimals}`;

        const startWeight = this.state.mobWeights && this.state.mobWeights.length > 0 ? Number(this.state.mobWeights[0].startWeight) : 0;

        const endWeightWarning = this.state.editEndWeight && this.props.mob.endWeight_kg > 0 && Number(this.props.mob.endWeight_kg) < startWeight ? "End animal weight is less than start weight" : null;

        const calcEndWeight = lastMobWeights && !this.state.editEndWeight ? lastMobWeights : 0;

        const endWeight = this.state.editEndWeight && this.props.mob.endWeight_kg > 0 ? this.props.mob.endWeight_kg : calcEndWeight;

        const calcMaxWeight = this.state.editMaxWeight ? Number(this.props.mob.maxWeight_kg) : this.state.mobWeights && this.state.mobWeights.length > 0 && this.state.mobWeights[0].matureWeight > 0 ? this.state.mobWeights[0].matureWeight : 0;

        const maxWeightWarning = calcMaxWeight < startWeight ? `${maxWeightLabel} is less than the start weight` : calcMaxWeight < endWeight ? `${maxWeightLabel} is less than the animal weight in ${lastMonthWithAnimals}` : null;

        const liveWeightWarning = mob.events && mob.events.find((e) => e.weightKg > 0 && e.weightType === "Live" && e.weightKg <= startWeight) ? "There are sales at a lower weight than the starting weight for the mob." : undefined;

        const showWeaning = ![...YELLOW_BREED, ...ORANGE_BREED].includes(this.state.stockClass);

        const allStockClasses = [...refData.sheepStockClasses, ...refData.beefStockClasses, ...refData.dairyGrazingStockClasses, ...refData.deerStockClasses, ...dairyStockClasses, ...refData.dairyReplacementStockClasses, ...refData.dairyGoatStockClasses];

        const matureWeightMessage = maxWeightLabel.includes("Mature") ? "default mature" : "average mob";

        const showEndWeight = this.state.showAgeAndWeightFields && lastMonthWithAnimals && (lastMonthWithAnimals === "June" || !lastSaleEvent || lastSaleEvent.weightType === "Default");

        const eventMsg = `Events add or remove animals from the farm.`;
        const infoNoWeights = `It is assumed ${utils.valueToText(allStockClasses, this.state.stockClass)} animals have already reached a mature weight. The ${matureWeightMessage} weight for the breed and gender is displayed below and can be overridden if required.`;
        const infoWeights = "All animals should have a similar weight/age to existing animals when added to the mob. They can be sold at different weights.";
        const salesInfo1 = "If animals are growing at different rates, enter the live/carcass weight for each event. The animals in each sale have their own growth curve as shown in the graph below.";
        const salesInfo2 = "If animals mostly grow at the same rate, select default weight for sales events. This will place those animals on the same growth curve as the last animals in the mob.";
        const endWeightInfo = `The end weight applies to the last animals in this mob (${lastMonthWithAnimals}). If the graph does not match your animal's weight gain, override the default end weight with your measured weight.`;

        const eventMessage = eventMsg + '<ul className="disc">' + (this.state.showAgeAndWeightFields ? "<li>" + infoWeights + "</li>" + (REQUIRES_SALE_WEIGHT.includes(this.state.stockClass) ? "<li>" + salesInfo1 + "</li><li>" + salesInfo2 + "</li>" : "") : "<li>" + infoNoWeights + "</li>") + (showEndWeight ? "<li>" + endWeightInfo + "</li></ul>" : "</ul>");

        let dairyBreedOptions = this.state.breedOptions;
        if (this.state.showDairyReplacementsFields || (this.state.showDairyFields && this.state.stockClass === "MilkingHerd")) {
            dairyBreedOptions = dairyBreedOptions.filter(opt => opt.weight > 0);
        }

        return (
            <Modal title={this.state.title} submitLabel="Done" initialValues={livestock} onSubmit={_onSubmit} validate={this._validate}>
                <Alert type="info" text="Describe the livestock and enter the events that brought animals on or removed animals from the farm. This will populate the animal numbers on the farm during the year. It is not necessary to enter every event, but rather provide an estimate of total animals on the farm during the year." />
                <div className="Grid">
                    <div className="Grid-cell u-lg-width3of3">
                        <FieldGroup>
                            <Field name="name" label="Livestock name" placeholder="Enter a name for this group of animals" type="text" dataWidth="50" requiredLabel={true} validate={[validations.required, validations.maxLength(50)]} component={InputPack} />
                            {this.state.showSheepFields && <Field name="sheepStockClass" id="stockClass" label="Stock class" onChange={_onStockClassChanged} placeholder="Select a stock class" options={refData.sheepStockClasses} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showBeefFields && <Field name="beefStockClass" id="stockClass" label="Stock class" onChange={_onStockClassChanged} placeholder="Select a stock class" options={refData.beefStockClasses} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showDairyGrazingFields && <Field name="beefStockClass" id="stockClass" label="Stock class" onChange={_onStockClassChanged} placeholder="Select a stock class" options={refData.dairyGrazingStockClasses} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showDeerFields && <Field name="deerStockClass" id="stockClass" label="Stock class" onChange={_onStockClassChanged} placeholder="Select a stock class" options={refData.deerStockClasses} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showDairyFields && <Field name="dairyStockClass" id="stockClass" label="Stock class" onChange={_onStockClassChanged} placeholder="Select a stock class" options={dairyStockClasses} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showDairyReplacementsFields && <Field name="dairyReplacementStockClass" id="stockClass" label="Stock class" onChange={_onStockClassChanged} placeholder="Select a stock class" options={refData.dairyReplacementStockClasses} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showDairyGoatFields && <Field name="dairyGoatStockClass" id="stockClass" label="Stock class" onChange={_onStockClassChanged} placeholder="Select a stock class" options={refData.dairyGoatStockClasses} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                        </FieldGroup>
                        <FieldGroup>
                            {this.state.showSheepFields && <Field name="sheepBreed" id="stockBreed" label="Breed" onChange={_onBreedChanged} placeholder="Select a stock breed" options={this.state.breedOptions} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showBeefFields && <Field name="beefBreed" id="stockBreed" label="Breed" onChange={_onBreedChanged} placeholder="Select a stock breed" options={this.state.breedOptions} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showDairyGrazingFields && <Field name="beefBreed" id="stockBreed" label="Breed" onChange={_onBreedChanged} placeholder="Select a stock breed" options={this.state.breedOptions} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showDeerFields && <Field name="deerBreed" id="stockBreed" label="Breed" onChange={_onBreedChanged} placeholder="Select a stock breed" options={this.state.breedOptions} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showDairyFields && <Field name="dairyBreed" id="stockBreed" label="Breed" onChange={_onBreedChanged} placeholder="Select a stock breed" options={dairyBreedOptions} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showDairyReplacementsFields && <Field name="dairyBreed" id="stockBreed" onChange={_onBreedChanged} label="Breed" placeholder="Select a stock breed" options={dairyBreedOptions} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showDairyGoatFields && <Field name="dairyGoatBreed" id="stockBreed" label="Breed" onChange={_onBreedChanged} placeholder="Select a stock breed" options={this.state.breedOptions} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}

                            {this.state.showSexFields && <Field name="sex" label="Sex" placeholder="Select a sex" options={SEX_OPTIONS} dataWidth="50" required={true} validate={[validations.required]} component={SelectPack} />}
                            {this.state.showBreedingReplacementFields && (
                                <div className="Field">
                                    <label className="Field-label">Replacements</label>
                                    <Field name="mated" label="Mated" dataWidth="50" component={CheckboxPack} />
                                </div>
                            )}
                        </FieldGroup>
                        {this.state.showDairyFields && this.state.stockClass === "MilkingHerd" && (
                            <div className={`Field${this.state.lactationDatesInvalid && this.state.lactationDatesTouched ? " has-error" : ""}`}>
                                <FieldGroup>
                                    <Field name="calvingDetails.midCalvingDate" label="Mean calving date" placeholder="Select date" clearable={true} dataWidth="50" component={MonthDayInputPack} onChange={(e, n) => _lactationDatesChanged("midCalvingDate", e, n)} />
                                    <Field name="calvingDetails.dryDate" label="Drying off date" placeholder="Select date" clearable={true} dataWidth="50" component={MonthDayInputPack} onChange={(e, n) => _lactationDatesChanged("dryDate", e, n)} />
                                </FieldGroup>
                                {this.state.lactationDatesInvalid && this.state.lactationDatesTouched && (
                                    <small className="Field-error" id="stockCounts-error">
                                        Lactation length (days from calving to drying off) must be between 100 and 335 days. Currently set at {this.state.lactationDays} {this.state.lactationDays === 1 ? "day" : "days"}
                                    </small>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="Grid-cell u-mt-lg">
                        <div className={`Field${this.state.stockCountsInvalid || this.state.eventCountsInvalid ? " has-error" : ""}`}>
                            <Panel title="Monthly stock counts" midBlue notCollapsible info="Stock counts are shown as at the end of each month. Animals will be proportioned according to the actual event dates when calculating results.">
                                <PanelBody>
                                    <div className="Table u-mt-md">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="u-textCenter"></th>
                                                    <th className="u-textCenter">Jul</th>
                                                    <th className="u-textCenter">Aug</th>
                                                    <th className="u-textCenter">Sep</th>
                                                    <th className="u-textCenter">Oct</th>
                                                    <th className="u-textCenter">Nov</th>
                                                    <th className="u-textCenter">Dec</th>
                                                    <th className="u-textCenter">Jan</th>
                                                    <th className="u-textCenter">Feb</th>
                                                    <th className="u-textCenter">Mar</th>
                                                    <th className="u-textCenter">Apr</th>
                                                    <th className="u-textCenter">May</th>
                                                    <th className="u-textCenter">Jun</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>Animals</th>
                                                    {!hasStock && (
                                                        <td colSpan="12">
                                                            <div className="u-flex u-flexAlignItemsCenter">
                                                                <i className="icon icon-info u-textSecondary u-mr-sm" />
                                                                <span>There are no animals defined. Please add livestock events below to define when these animals were brought on or removed from your farm.</span>
                                                            </div>
                                                        </td>
                                                    )}
                                                    {this.state.stockCounts.some((item) => item.stockCount !== 0) &&
                                                        this.state.stockCounts.map((item) => (
                                                            <td key={item.month} style={{ backgroundColor: "#f8f9f8" }} className={`u-textCenter ${item.stockCount < 0 ? "u-textError" : ""}`}>
                                                                {item.stockCount === 0 ? "-" : item.stockCount}
                                                            </td>
                                                        ))}
                                                </tr>
                                                <tr>
                                                    <th>Add event</th>
                                                    {domain.farmYear.map((month, i) => {
                                                        const menuItems = menuEvents
                                                            .filter((menuEvent) => menuEvent.value !== "Starting" || (!startingEvent && i === 0))
                                                            .filter((menuEvent) => menuEvent.value !== "Weaning" || (!startingEvent && (firstPurchaseEventMonthIndex < 0 || i <= firstPurchaseEventMonthIndex)))
                                                            .filter((menuEvent) => !["Sale", "SaleToWorks"].includes(menuEvent.value) || i >= firstPurchaseEventMonthIndex || !!startingEvent)
                                                            .filter((menuEvent) => i >= startingEventMonthIndex)
                                                            .map((menuEvent, j) => {
                                                                return <MenuItem key={j} icon={icons.add} month={month} event={menuEvent} />;
                                                            });

                                                        if (menuItems.length === 0) return <td key={month}>&nbsp;</td>;

                                                        return (
                                                            <td className="Calendar-slot" key={month}>
                                                                <MenuProvider event="onClick" id={`menu_${month}`}>
                                                                    <Menu id={`menu_${month}`}>
                                                                        {menuItems.map((menuItem, k) => (
                                                                            <Fragment key={k}>
                                                                                {menuItem} {k < menuItems.length - 1 && <Separator />}
                                                                            </Fragment>
                                                                        ))}
                                                                    </Menu>
                                                                    <div id={`${month}_event-add`} className="h1 a u-textMedium u-link">
                                                                        +
                                                                    </div>
                                                                </MenuProvider>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {this.state.stockCountsInvalid && (
                                        <small className="Field-error" id="stockCounts-error">
                                            Stock counts must not be negative
                                        </small>
                                    )}
                                    {this.state.eventsTouched && this.state.eventCountsInvalid && (
                                        <small className="Field-error" id="stockEvents-error">
                                            Please add at least one livestock event
                                        </small>
                                    )}
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                    <div className="Grid-cell u-mt-lg">
                        <Panel title="Deaths" midBlue open={Object.keys(this.state.deaths).length > 0} info="You can enter a number of deaths each month. These will be removed from the farm in the middle of that month.">
                            <PanelBody>
                                <div className="Table u-mt-md">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="u-textCenter"></th>
                                                <th className="u-textCenter">Jul</th>
                                                <th className="u-textCenter">Aug</th>
                                                <th className="u-textCenter">Sep</th>
                                                <th className="u-textCenter">Oct</th>
                                                <th className="u-textCenter">Nov</th>
                                                <th className="u-textCenter">Dec</th>
                                                <th className="u-textCenter">Jan</th>
                                                <th className="u-textCenter">Feb</th>
                                                <th className="u-textCenter">Mar</th>
                                                <th className="u-textCenter">Apr</th>
                                                <th className="u-textCenter">May</th>
                                                <th className="u-textCenter">Jun</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Deaths</th>
                                                {domain.farmYear.map((month, i) => {
                                                    return (
                                                        <td key={month} className="u-textCenter">
                                                            <Field name={`deaths[${month}]`} placeholder="0" type="text" maxLength="3" noLabel={true} hideErrors={true} normalize={normalizers.integer} validate={[validations.maxValue(500)]} component={InputPack} onChange={(e, newValue) => _onDeathChange(e, newValue, month)} />
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </PanelBody>
                        </Panel>
                    </div>
                    <div className="Grid-cell u-mt-lg">
                        <FieldArray alertMessage={eventMessage} name="events" calculateStockCounts={_calculateStockCounts} component={LivestockEventsFieldArray} enterpriseType={livestock.enterpriseType} stockClass={this.state.stockClass} showAgeAndWeightFields={this.state.showAgeAndWeightFields} primaryEventTypes={primaryEventTypes} secondaryEventTypes={secondaryEventTypes} maxWeight={_maxWeightForEnterprise()} />
                        <div className="ActionsBar ActionsBar--super u-print-none">
                            <div className="ActionsBar-right">
                                <ul className="ActionsBar-links">
                                    <li>
                                        <span id="add-event-link" onClick={addLivestockEvent} className="IconLink--arrow-plus a u-link">
                                            Add event
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {liveWeightWarning && (
                        <div className="Grid-cell">
                            <Alert type="warning" text={liveWeightWarning} />
                        </div>
                    )}
                    <div className="Grid-cell">
                        <MobWeights startMonth={this.state.startMonth} value={this.state.mobWeights} showWeaning={showWeaning || true} stockCounts={this.state.stockCounts} flattenWeightGain={YELLOW_BREED.includes(this.state.stockClass) || livestock.enterpriseType === "Dairy"} />
                    </div>
                    <div className="Grid-cell u-width1of2 u-pr-lg">
                        <Field name="maxWeight_kg" label={maxWeightLabel} disabled={!this.state.editMaxWeight && !isDairyReplacements} type="text" placeholder={maxWeightPlaceholder} uom="kg" warning={maxWeightWarning} normalize={normalizers.integer} validate={[validations.range(0, 2000)]} component={InputPack} tip={weightTip.text} />
                        {!isDairyReplacements && (
                            <div className="u-textRight u-pt-sm">
                                <ActionLink onClick={toggleMaxWeight} id="toggle_max-weight">
                                    {this.state.editMaxWeight ? "Use default" : "Override default"}
                                </ActionLink>
                            </div>
                        )}
                    </div>
                    {showEndWeight && (
                        <div className="Grid-cell u-width1of2">
                            <Field name="endWeight_kg" label={`End live weight for animals in ${lastMonthWithAnimals}`} type="text" disabled={!this.state.editEndWeight && !isDairyReplacements} placeholder={endWeightDefault} uom="kg" normalize={normalizers.integer} validate={[validations.range(0, 2000)]} component={InputPack} onChange={(e, n) => _endWeightChanged(e, n)} tip={endWeightTip.text} warning={endWeightWarning} />
                            {!isDairyReplacements && (
                                <div className="u-textRight u-pt-sm">
                                    <ActionLink onClick={toggleEndWeight} id="toggle_end-weight">
                                        {this.state.editEndWeight ? "Use default" : "Override default"}
                                    </ActionLink>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

const SEX_OPTIONS = [
    { value: "Male", text: "Male" },
    { value: "Female", text: "Female" },
    { value: "MixedSex", text: "Mixed" },
];

const YELLOW_BREED = ["BreedingEwesMixedAge", "BreedingRams", "CowsMixedAgeBreeding", "BullsBreeding", "DairyMilking", "BreedingHindsMixedAge", "BreedingStags", "StagsMixedAge", "DoesMilking", "DoesMilkingAllYear", "Bucks"];

const ORANGE_BREED = ["BreedingEwes", "Hoggets", "CowsBreeding", "HeifersBreeding", "BreedingHinds", "BreedingReplacements", "Replacements"];

const REQUIRES_SALE_WEIGHT = ["CalvesWeanedMixedSex", "Lambs", "EwesAndHoggets", "Wethers", "Rams", "Weaners", "HeifersAndCows", "Steers", "Bulls", "DairyReplacements", "Weaners", "Hinds", "Stags"];

function useMob() {
    const selector = reduxFormValueSelector("modal-form");

    const mob = useSelector((state) => {
        const name = selector(state, "name");
        const sheepStockClass = selector(state, "sheepStockClass");
        const beefStockClass = selector(state, "beefStockClass");
        const deerStockClass = selector(state, "deerStockClass");
        const dairyStockClass = selector(state, "dairyStockClass");
        const dairyReplacementStockClass = selector(state, "dairyReplacementStockClass");
        const dairyGoatStockClass = selector(state, "dairyGoatStockClass");
        const sheepBreed = selector(state, "sheepBreed");
        const beefBreed = selector(state, "beefBreed");
        const deerBreed = selector(state, "deerBreed");
        const dairyBreed = selector(state, "dairyBreed");
        const dairyGoatBreed = selector(state, "dairyGoatBreed");
        const sex = selector(state, "sex");
        const mated = selector(state, "mated");
        const midCalvingDate = selector(state, "calvingDetails.midCalvingDate");
        const dryDate = selector(state, "calvingDetails.dryDate");
        const maxWeight_kg = selector(state, "maxWeight_kg");
        const endWeight_kg = selector(state, "endWeight_kg");
        const events = selector(state, "events");

        const result = {
            name,
            sheepStockClass,
            beefStockClass,
            deerStockClass,
            dairyStockClass,
            dairyReplacementStockClass,
            dairyGoatStockClass,
            sheepBreed,
            beefBreed,
            deerBreed,
            dairyBreed,
            dairyGoatBreed,
            sex,
            mated,
            maxWeight_kg,
            endWeight_kg,
            events,
        };

        if (midCalvingDate || dryDate) {
            result.calvingDetails = {
                midCalvingDate,
                dryDate,
            };
        }

        return result;
    });

    return mob;
}

function useReduxFormChange() {
    const dispatch = useDispatch();
    return (form, field, value) => dispatch(reduxFormChange(form, field, value));
}

async function getMobWeights(farm, analysis, enterprise) {
    try {
        const content = {
            enterprise,
            region: farm.region,
            nearestTown: farm.nearestTown,
        };
        const response = await httpClient.post(`farms/${farm.id}/budgets/${analysis.id}/mobweights`, content)
        return response;
    } catch (error) {
        return [];
    }
}
