import * as utils from "common/utils";

export const getYearOptions = (farm, refData) => {
    const writeAccess = utils.canUpdateFarm(farm);

    return (refData.budgetYears || []).map((year) => {
        const exists = farm.budgets.find((b) => b.type === (writeAccess ? "YearEnd" : "PrivateYearEnd") && b.year === year);
        const text = `${year - 1}-${year} ${exists ? "(existing)" : ""}`;
        return {
            value: year,
            text,
            disabled: exists,
        };
    });
};
