import React from "react";
import * as icons from "common/icons";

const BrowserNotSupported = () => {
    return (
        <div className="u-flex u-flexJustifyCenter u-mt-xl">
            <div className="Browser-support u-textCenter">
                <div className="u-flex u-flexJustifyCenter">
                    <div>
                        <div className="bs-title u-mt-lg">
                            Oops!
                            <br />
                            Your browser is not supported.
                        </div>
                        <h4>To use overseer, please upgrade to the latest one of these browsers</h4>
                    </div>
                </div>
                <div className="u-flex u-flexJustifyCenter u-mt-xl">
                    <a href="https://www.google.com/chrome/">
                        <div className="bs-icon">
                            <img src={icons.chrome} alt="Chrome" />
                            <span>Chrome</span>
                        </div>
                    </a>
                    <a href="https://www.mozilla.org/en-US/firefox/new/">
                        <div className="bs-icon">
                            <img src={icons.firefox} alt="Firefox" />
                            <span>Firefox</span>
                        </div>
                    </a>
                    <a href="https://www.apple.com/nz/safari/">
                        <div className="bs-icon">
                            <img src={icons.safari} alt="Safari" />
                            <span>Safari</span>
                        </div>
                    </a>
                    <a href="https://www.microsoft.com/en-nz/windows/microsoft-edge">
                        <div className="bs-icon">
                            <img src={icons.edge} alt="Edge" />
                            <span>Edge</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default BrowserNotSupported;
