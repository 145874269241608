import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { httpClient } from "common/httpClient";

export function useMyOrg() {
    const query = useQuery({
        queryKey: ["my-org"],
        queryFn: async () => httpClient.get(`accounts`),
        retry: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error
    };
}

export function useUpdateMyOrgAsync() {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const mutation = useMutation({
        mutationFn: async (org) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.put(`accounts/${org.id}`, org, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["my-org"] });
            dispatch({ type: "MY_ORG_UPDATE_ORG_SUCCESS", payload: variables });
        }
    });

    return (org) => mutation.mutateAsync(org);
}

export function useToggleAdminUserAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (userId) => {
            try {
                await httpClient.put(`users/${userId}/toggleadmin`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["my-org"] });
        }
    });

    return (userId) => mutation.mutateAsync(userId);
}

export function useRemoveUserFromMyOrgAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (userId) => {
            try {
                await httpClient.del(`users/${userId}`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["my-org"] });
        }
    });

    return (userId) => mutation.mutateAsync(userId);
}

export function useInviteUserToMyOrgAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (invitation) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.post(`accounts/${invitation.accountId}/invitations`, invitation, timeout);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 409) {
                    throw new Error("A user with this email address already exists.");
                } else if (error.status === 400) {
                    throw new Error("Error sending invite. Please ensure the email address is valid.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["my-org"] });
        }
    });

    return (invitation) => mutation.mutateAsync(invitation);
}

export function useDeleteUserInvitationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (invitation) => {
            try {
                await httpClient.del(`accounts/${invitation.accountId}/invitations/${invitation.id}`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["my-org"] });
        }
    });

    return (invitation) => mutation.mutateAsync(invitation);
}
