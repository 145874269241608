import React from "react";
import * as api from "api";
import ReactGA from "react-ga4";

export const getFarmTimeline = (farmId, excludedIds, includedIds) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Farm",
            action: "Get farm timeline data",
        });
        return dispatch(
            api.post({
                path: `farms/${farmId}/timeline`,
                content: { farmId, excludedIds, includedIds },
                onSuccess: (response) => {
                    resolve(response);
                    return {};
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An error occurred while fetaching plan data. Please try again. If problem presists contact support.");
                    return {};
                },
            })
        );
    });
};

export const ComparisonTable = ({ comparison }) => {
    const someChangeInCategories = comparison && comparison.changeCategories && comparison.changeCategories.some((cc) => cc.changes.length > 0);

    return someChangeInCategories ? (
        <div className="ReportPage">
            <div className="ReportSection">
                <h1>
                    Changes between the years {comparison.fromYear} and {comparison.toYear}
                </h1>
                <div className="SimpleTable">
                    <table style={{ width: "100%" }}>
                        <tbody>
                            {comparison.changeCategories
                                .filter((cc) => cc.changes.length > 0)
                                .map((cc, i) => (
                                    <ComparisonSection sectionData={cc} key={cc.type + "-" + i} index={i} />
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    ) : (
        <div className="h3 u-pt-lg u-pb-lg">
            No significant changes between the years {comparison.fromYear} and {comparison.toYear}
        </div>
    );
};

export const ComparisonSection = ({ sectionData, index }) => {
    return (
        <tr key={sectionData.type + "-category-" + index} className="u-pb-lg">
            <td className={"u-textBold IconLink--" + sectionData.icon}>{sectionData.name}</td>
            <td>
                <ul className="disc">
                    {sectionData.changes.map((c, i) => {
                        return <li key={"change-section-" + i}>{c}</li>;
                    })}
                </ul>
            </td>
        </tr>
    );
};
