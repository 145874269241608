import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { modalInlineOpen, modalInlineClose } from "containers/App/_actions";
import { loginWithGoogle, cancelGoogleLogin, reAuthWithGoogle } from "./_actions";
import AcceptTermsModal from "./AcceptTermsModal";
import { useAuthContext, useLoadScript, useOnline } from "common/hooks";

function GoogleLoginButton({ isReAuth, originalPayload }) {
    const dispatch = useDispatch();
    const online = useOnline();

    const hiddenButtonRef = useRef(null);
    const googleIdToken = useGoogleIdToken(hiddenButtonRef);
    const showTermsForNewUsers = useShowTermsForNewUsers(googleIdToken);
    const authenticate = useAuthenticateWithGoogleIdToken(isReAuth, originalPayload);

    // Auto send an auth request to our API if user has successfully authenticated with google.
    useEffect(() => {
        if (googleIdToken) {
            authenticate(googleIdToken);
        }
    }, [googleIdToken, authenticate])

    function acceptTerms() {
        dispatch(modalInlineClose());
        authenticate(googleIdToken, true);
    };

    function declineTerms() {
        dispatch(modalInlineClose());
        dispatch(cancelGoogleLogin());
    };

    return (
        <>
            <div ref={hiddenButtonRef} id="hidden_google_btn" className="u-hidden"></div>
            <button type="button" className="Button--google" onClick={() => hiddenButtonRef.current.querySelector('div[role=button]').click()} disabled={!online}>
                <span className="icon"></span>
                <span className="text">Google</span>
            </button>
            {showTermsForNewUsers && <AcceptTermsModal fileName={`https://docs.overseer.org.nz/${process.env.REACT_APP_PRODUCT}/OverseerTermsOfUse.pdf`} title="Terms of use" accept={acceptTerms} decline={declineTerms} />}
        </>
    )
}

function useGoogleIdToken(hiddenButtonRef) {
    const [googleIdToken, setGoogleIdToken] = useState();

    useLoadScript({
        src: "https://accounts.google.com/gsi/client",
        onLoad: () => {
            window.google.accounts.id.initialize({
                client_id: "280751590458-s6tv6blvi8rldtqqek6i4tj3h4rkp5p0.apps.googleusercontent.com",
                callback: (authResponse) => {
                    setGoogleIdToken(authResponse.credential);
                },
            });
            if (hiddenButtonRef && hiddenButtonRef.current) {
                window.google.accounts.id.renderButton(hiddenButtonRef.current, { type: 'button' });
            }
        },
        onError: () => console.error("Error loading google sign in script")
    })

    return googleIdToken;
}

function useShowTermsForNewUsers(googleIdToken) {
    const dispatch = useDispatch();
    const termsRequired = useSelector((state) => state.auth.termsRequired);

    useEffect(() => {
        if (googleIdToken && termsRequired) {
            dispatch(modalInlineOpen());
        }
    }, [dispatch, googleIdToken, termsRequired])

    return googleIdToken && termsRequired;
}

function useAuthenticateWithGoogleIdToken(isReAuth, originalPayload) {
    const dispatch = useDispatch();
    const location = useLocation();
    const userId = useAuthContext()?.userId;

    const authenticate = useCallback((googleIdToken, withAcceptedTerms) => {
        if (googleIdToken) {
            if (isReAuth) {
                dispatch(reAuthWithGoogle(googleIdToken, userId, originalPayload));
            } else {
                dispatch(loginWithGoogle(googleIdToken, withAcceptedTerms, location.state));
            }
        }
    }, [dispatch, isReAuth, userId, originalPayload, location.state])

    return authenticate;
}

export default GoogleLoginButton;
