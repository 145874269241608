import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import * as utils from "common/utils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SelectField from "components/FormFields/SelectField";
import TextField from "components/FormFields/TextField";
import { getYearOptions, getFutureYearOptions, isYearEnd, isPredictive } from "../_utils";
import { useRefData, useModal } from "common/hooks";
import { useAnalysis, useUpdateAnalysisAsync } from "containers/hooks";

export default function RenameAnalysisModal({ farm, analysisId, close }) {
    const { data: analysis } = useAnalysis(farm.id, analysisId);
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const refData = useRefData();

    const validate = (formValues) => {
        const validation = {};
        validation.name = FormUtils.validators.required(formValues.name);
        validation.name = validation.name || FormUtils.validators.maxLength(50)(formValues.name);
        return validation;
    }

    const submit = async (formValues) => {
        try {
            await updateAnalysisAsync(formValues);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }

    const canCreatePlans = utils.canUpdateFarm(farm);
    const yearOptions = getYearOptions(farm, refData);
    const futureYearOptions = getFutureYearOptions(refData);

    return (
        <Form initialValues={analysis} validate={validate} onSubmit={submit}>
            {({ handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Rename analysis" close={close} submitting={submitting} skinny fluid>
                            <ModalBody loading={!analysis} error={submitError}>
                                <Grid>
                                    <GridCell>
                                        <Field name="name" label="Analysis name" placeholder="Enter the name of the analysis" required component={TextField} />
                                    </GridCell>
                                    <GridCell>
                                        {analysis && isYearEnd(analysis.type) && <Field name="year" label="Year" placeholder="Select a year" options={yearOptions} required component={SelectField} />}
                                        {analysis && canCreatePlans && isPredictive(analysis.type) && <Field name="year" label="Year" placeholder="Select a year" options={futureYearOptions} component={SelectField} />}
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary disabled={!analysis || submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    )
}

export function useRenameAnalysisModal(farm, analysisId) {
    const [modal, openModal] = useModal(RenameAnalysisModal);

    const openRenameAnalysisModal = () => {
        const modalProps = {
            farm,
            analysisId,
        };
        openModal(modalProps);
    };

    return [modal, openRenameAnalysisModal];
}
