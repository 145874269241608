import * as api from "api";
import ReactGA from "react-ga4";

export const getFarmSummaryAsync = (farmId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get Farm Summary",
        });
        return dispatch(
            api.get({
                path: `admin/farms/${farmId}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    return [];
                },
            })
        );
    });
};

export const getFarmPaymentsAsync = (farmId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get Farm Payments",
        });
        return dispatch(
            api.get({
                path: `admin/farms/${farmId}/payments`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    return [];
                },
            })
        );
    });
};
