import ActionLink from "components/ActionLink";
import { useBulkResetSoilsToSMapDefaultsModal } from "./BulkResetSoilsToSMapDefaultsModal";

export default function BulkResetSMapDefaultSoilsLink({ analyses, className = "IconLink--refresh" }) {
    const [bulkResetSoilsToSMapDefaultsModal, openBulkResetSoilsToSMapDefaultsModal] = useBulkResetSoilsToSMapDefaultsModal(analyses);

    return (
        <>
            <ActionLink id="import-map" className={className} onClick={() => openBulkResetSoilsToSMapDefaultsModal()}>
                Bulk reset soils to S-Map defaults
            </ActionLink>
            {bulkResetSoilsToSMapDefaultsModal}
        </>
    )
}
