import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import SelectPack from "components/SelectPack2";
import InputPack from "components/InputPack";
import Button from "components/Button/Button";
import { useRefData } from "common/hooks";

export default function FuelUsageActivities({ form, values }) {
    const refData = useRefData();

    const _addActivity = () => {
        form.mutators.push("fuelUsageActivities", {});
    };

    const _removeActivity = (index) => {
        const results = values.fuelUsageActivities.reduce((acc, cur, i) => {
            if (i !== index) acc.push(cur);
            return acc;
        }, []);
        form.change("fuelUsageActivities", results);
    };

    const _hasActivities = (values.fuelUsageActivities || []).length > 0;

    const _hasAllActivities = (values.fuelUsageActivities || []).length === (refData.ghgFuelUsageActivities || []).length;

    const _addActivityLink = !_hasAllActivities && (
        <span id="add-usage-activity-link" className="u-link IconLink--arrow-plus" onClick={_addActivity}>
            Add override
        </span>
    );

    return (
        <Tile title="On-farm fuel usage" actions={_addActivityLink} padTop tertiary>
            <TileBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th>Activity</th>
                                <th className="th--shrink">% of activity done using on-farm fuel</th>
                                {_hasActivities && <th className="th--shrink">&nbsp;</th>}
                            </tr>
                        </thead>
                        {!_hasActivities && (
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                        <div className="Tile-body">
                                            <div className="Tile-body-message">
                                                <h3 className="u-mt-md">This analysis is currently using the default on-farm fuel usage settings</h3>
                                                <Button id="add-usage-activity-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={_addActivity}>
                                                    Add override
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {_hasActivities && (
                            <tbody>
                                <FieldArray name="fuelUsageActivities" validate={_validate}>
                                    {({ fields }) => {
                                        return fields.map((field, i) => {
                                            const activityOptions = _getActivityOptions(refData, fields.value, i);
                                            const activity = fields.value[i];
                                            return (
                                                <tr key={field}>
                                                    <td>
                                                        <Field name={`${field}.activity`} component={SelectPack} options={activityOptions} placeholder="Select an activity" info={activity.activity === "AnimalTransport" ? "Only applies to dairy replacements, winter off and brought in or sold stock" : undefined} />
                                                    </td>
                                                    <td>
                                                        <Field name={`${field}.percentageUsingFarmFuel`} type="text" label="" uom="%" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                                    </td>
                                                    <td>
                                                        <span id={`delete-usage-activity-${i}`} className="a u-link IconLink--trash" onClick={() => _removeActivity(i)}></span>
                                                    </td>
                                                </tr>
                                            );
                                        });
                                    }}
                                </FieldArray>
                            </tbody>
                        )}
                    </table>
                </div>
            </TileBody>
        </Tile>
    )
}

const _getActivityOptions = (refData, selectedActivities, index) => {
    const options = (refData.ghgFuelUsageActivities || []).filter((option) => {
        const isSelected = (selectedActivities || []).some((selectedActivity, i) => i !== index && selectedActivity.activity === option.value);
        return !isSelected;
    });
    return options;
}

const _validate = (values) => {
    const errorArray = [];

    values &&
        values.forEach((value) => {
            const errors = {};

            errors.activity = FormUtils.validators.required(value.activity);

            errors.percentageUsingFarmFuel = FormUtils.validators.required(value.percentageUsingFarmFuel);
            errors.percentageUsingFarmFuel = errors.percentageUsingFarmFuel || FormUtils.validators.range(1, 100)(value.percentageUsingFarmFuel);

            errorArray.push(errors);
        });

    return errorArray;
}

