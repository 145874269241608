import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as domain from "common/domain";
import { useRefData, useConfirm } from "common/hooks";
import { ZendeskLink } from "components/Help";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileSubPanel from "components/TileSubPanel";
import TabMessages from "containers/BudgetHome/TabMessages";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";
import { useUpdateAnalysisAsync } from "containers/hooks";
import WetlandCard from "./WetlandCard";
import RiparianCard from "./RiparianCard";
import DrainageCard from "./DrainageCard";

export default function Wetlands({ farm, analysis }) {
    const refData = useRefData();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const blocks = analysis.blocks.filter((b) => b.type !== domain.BlockType.FodderCrop);
    let wetlandBlocks = blocks.filter((block) => block.type === domain.BlockType.NonProductiveWetland).map((b) => ({ block: b, wetland: b.wetland, unfenced: false }));
    if (analysis.wetlands) {
        let unfencedBlocks = analysis.wetlands.map((b) => ({ block: {}, wetland: b, unfenced: true }));
        wetlandBlocks = [...wetlandBlocks, ...unfencedBlocks];
    }
    let riparianBlocks = blocks.filter((block) => block.type === domain.BlockType.NonProductiveRiparian);

    const promptBeforeDeleteWetland = (e, name, id, unfenced) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete the “${name}” ${unfenced ? "unfenced" : ""} wetland?`, async () => {
            if (unfenced) {
                analysis.wetlands = analysis.wetlands.filter((w) => w.id !== id);
            } else {
                utils.deleteOrphanBlockRef(analysis, id);
                analysis.blocks = analysis.blocks.filter((b) => b.id !== id);
            }
            await updateAnalysisAsync(analysis);
        });
    };

    const promptBeforeDeleteRiparianStrip = (e, block) => {
        e.preventDefault();
        const isRiparianBlock = block.type === domain.BlockType.NonProductiveRiparian;
        confirm(`Are you sure you want to delete ${isRiparianBlock ? "riparian P model input" : "the grass filter strip"} from “${block.name}”?`, async () => {
            delete block.riparianStrip;
            analysis.blocks = analysis.blocks.map((b) => (b.id === block.id ? block : b));
            await updateAnalysisAsync(analysis);
        });
    };

    const blockList = utils.getBlockList(blocks.filter((b) => b.type !== domain.BlockType.NonProductiveWetland && b.type !== domain.BlockType.NonProductiveRiparian));

    const moleDrainage = (analysis.drainageSystems || []).find((d) => d.drainageMethod.toLowerCase() === "moletilesystem");
    const otherDrainage = (analysis.drainageSystems || []).find((d) => d.drainageMethod.toLowerCase() === "other");
    const availableDrainageBlocks = blocks.filter((b) => (b.drainageDetailsId === undefined || b.drainageDetailsId === null) && b.type !== domain.BlockType.NonProductiveTreesAndScrub && b.type !== domain.BlockType.NonProductiveHouse && b.type !== domain.BlockType.NonProductiveWetland);

    return (
        <>
            <Tile>
                <PageTabs farm={farm} analysis={analysis} tab={TAB.WETLANDS} />
                <TileBody>
                    <TabMessages farm={farm} analysis={analysis} tab={TAB.WETLANDS} />
                    <div className="ActionsBar ActionsBar--super u-print-none">
                        <div className="ActionsBar-left">
                            <ul className="ActionsBar-links">
                                <li>
                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/drainage/unfenced/new`} className="IconLink--arrow-plus" id="wetland-add-unfenced">
                                        <span>Add unfenced natural wetland</span>
                                    </Link>
                                </li>
                                <li>
                                    {availableDrainageBlocks.length > 0 && !moleDrainage ? (
                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/drainage/moletilesystem`} className="IconLink--arrow-plus" id="wetland-add-mole">
                                            Add mole/tile drainage
                                        </Link>
                                    ) : (
                                        moleDrainage && (
                                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/drainage/moletilesystem`} className="IconLink--edit" id="wetland-edit_mole">
                                                Edit mole/tile drainage
                                            </Link>
                                        )
                                    )}
                                </li>
                                <li>
                                    {availableDrainageBlocks.length > 0 && !otherDrainage ? (
                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/drainage/other`} className="IconLink--arrow-plus" id="wetland-add_other">
                                            Add other drainage
                                        </Link>
                                    ) : (
                                        otherDrainage && (
                                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/drainage/other`} className="IconLink--edit" id="wetland-edit_other">
                                                Edit other drainage
                                            </Link>
                                        )
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className="ActionsBar-right">
                            <ul className="ActionsBar-links">
                                {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                                    <li>
                                        <ScenarioToolLink farm={farm} analysis={analysis} />
                                    </li>
                                )}
                                <li>
                                    <ZendeskLink title="Page help" url="https://overseer-helpdesk.zendesk.com/hc/en-us/articles/900000960886" className="IconLink--new-tab u-font-green" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    {wetlandBlocks.length > 0 && (
                        <TileSubPanel title="Wetlands" stickyHeaderName="analysis.drainage.wetlands.collapsed">
                            {wetlandBlocks.map((b) => (
                                <WetlandCard key={wetlandBlocks.indexOf(b)} farm={farm} analysis={analysis} block={b.block} wetland={b.wetland} unfenced={b.unfenced} promptBeforeDelete={promptBeforeDeleteWetland} />
                            ))}
                        </TileSubPanel>
                    )}
                    {riparianBlocks.length > 0 && (
                        <TileSubPanel title="Riparian blocks" stickyHeaderName="analysis.drainage.riparianBlocks.collapsed">
                            {riparianBlocks.map((block) => {
                                return <RiparianCard key={block.id} farm={farm} analysis={analysis} block={block} promptBeforeDelete={promptBeforeDeleteRiparianStrip} />;
                            })}
                        </TileSubPanel>
                    )}
                    {refData &&
                        refData.blockTypes &&
                        refData.blockTypes.map((blockType) => {
                            const blocksByType = blockList.productiveBlocks.filter((block) => block.type === blockType.value);
                            return (
                                blocksByType.length > 0 && (
                                    <TileSubPanel key={blockType.value} title={`${blockType.text} ${blockType.value === "FodderCrop" ? "rotations" : " blocks"}`} stickyHeaderName={`analysis.drainage.${blockType.value}.collapsed`}>
                                        {blocksByType.map((block) => (
                                            <DrainageCard key={block.id} farm={farm} analysis={analysis} block={block} promptBeforeDelete={promptBeforeDeleteRiparianStrip} />
                                        ))}
                                    </TileSubPanel>
                                )
                            );
                        })}
                    {blockList.nonProductiveBlocks.length > 0 && (
                        <TileSubPanel title="Other blocks" stickyHeaderName="analysis.drainage.otherBlocks.collapsed">
                            {blockList.nonProductiveBlocks.map((block) => (
                                <DrainageCard key={block.id} farm={farm} analysis={analysis} block={block} />
                            ))}
                        </TileSubPanel>
                    )}
                </TileBody>
            </Tile>
        </>
    )
}
