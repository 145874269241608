import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import createDecorator from "final-form-calculate";
import * as FormUtils from "common/FormUtils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import SelectPack from "components/SelectPack2";
import InputPack from "components/InputPack";
import Button from "components/Button/Button";
import { useRefData } from "common/hooks";

export default function FuelCropActivities({ form, values }) {
    const refData = useRefData();

    const _addActivity = () => {
        form.mutators.push("fuelCropActivities", {});
    };

    const _removeActivity = (index) => {
        const results = values.fuelCropActivities.reduce((acc, cur, i) => {
            if (i !== index) acc.push(cur);
            return acc;
        }, []);
        form.change("fuelCropActivities", results);
    };

    const _hasActivities = (values.fuelCropActivities || []).length > 0;

    const _validate = (values) => {
        const errorArray = [];

        values &&
            values.forEach((value) => {
                const errors = {};

                errors.activity = FormUtils.validators.required(value.activity);

                if (value.activity || value.activity === "") {
                    const activityOption = _getActivityOption(refData, value.activity);
                    if (!activityOption) {
                        errors.fuelUseInLitresPerHectare = FormUtils.validators.required(value.fuelUseInLitresPerHectare);
                    }
                }
                errors.fuelUseInLitresPerHectare = errors.fuelUseInLitresPerHectare || FormUtils.validators.max(999999)(value.fuelUseInLitresPerHectare);

                errors.areaInHectares = FormUtils.validators.required(value.areaInHectares);
                errors.areaInHectares = errors.areaInHectares || FormUtils.validators.range(1, 999999)(value.areaInHectares);

                errorArray.push(errors);
            });

        return errorArray;
    };

    const _addActivityLink = (
        <span id="add-crop-activity-link" className="u-link IconLink--arrow-plus" onClick={_addActivity}>
            Add override
        </span>
    );

    return (
        <Tile title="Crop contractor fuel usage" actions={_addActivityLink} padTop tertiary>
            <TileBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th>Activity</th>
                                <th className="th--shrink">Diesel use (l/ha)</th>
                                <th className="th--shrink">Area (ha)</th>
                                {_hasActivities && <th className="th--shrink">&nbsp;</th>}
                            </tr>
                        </thead>
                        {!_hasActivities && (
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                        <div className="Tile-body">
                                            <div className="Tile-body-message">
                                                <h3 className="u-mt-md">This analysis is currently using the default crop contractor fuel usage settings</h3>
                                                <Button id="add-crop-activity-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={_addActivity}>
                                                    Add override
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {_hasActivities && (
                            <tbody>
                                <FieldArray name="fuelCropActivities" validate={_validate}>
                                    {({ fields }) => {
                                        return fields.map((field, i) => {
                                            const activityOptions = _getActivityOptions(refData, fields.value, i);
                                            const activity = fields.value[i];
                                            const activityOption = _getActivityOption(refData, activity.activity);
                                            const isUserDefined = !activityOption && (activity.activity === "" || activity.activity);
                                            return (
                                                <tr key={field}>
                                                    <td>
                                                        {!isUserDefined && <Field name={`${field}.activity`} component={SelectPack} options={activityOptions} placeholder="Select a type" />}
                                                        {isUserDefined && <Field name={`${field}.activity`} type="text" label="" component={InputPack} placeholder="Enter an activity name" />}
                                                    </td>
                                                    <td>
                                                        <Field name={`${field}.fuelUseInLitresPerHectare`} type="text" label="" uom="l/ha" placeholder={activityOption ? activityOption.defaultFuelUse : ""} component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                                    </td>
                                                    <td>
                                                        <Field name={`${field}.areaInHectares`} type="text" label="" uom="ha" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                                    </td>
                                                    <td>
                                                        <span id={`delete-crop-activity-${i}`} className="a u-link IconLink--trash" onClick={() => _removeActivity(i)}></span>
                                                    </td>
                                                </tr>
                                            );
                                        });
                                    }}
                                </FieldArray>
                            </tbody>
                        )}
                    </table>
                </div>
            </TileBody>
        </Tile>
    )
}

const _getActivityOptions = (refData, selectedActivities, index) => {
    const options = (refData.ghgFuelGeneralActivities || []).reduce((results, option) => {
        const isSelected = (selectedActivities || []).some((selectedActivity, i) => i !== index && selectedActivity.activity === option.value);
        if (!isSelected) {
            results.push({
                ...option,
                groupIndex: 0,
                groupLabel: "General activities",
            });
        }
        return results;
    }, []);

    (refData.ghgFuelCropActivities || []).forEach((option) => {
        const isSelected = (selectedActivities || []).some((selectedActivity, i) => i !== index && selectedActivity.activity === option.value);
        if (!isSelected) {
            options.push({
                ...option,
                groupIndex: 1,
                groupLabel: "Crop specific activities",
            });
        }
    });

    options.push({
        value: "UserDefined",
        text: "User defined",
        groupIndex: 2,
        groupLabel: "Custom activity",
    });

    return options;
}

const _getActivityOption = (refData, value) => {
    const generalActivity = (refData.ghgFuelGeneralActivities || []).find((a) => a.value === value);
    if (generalActivity) return generalActivity;

    const cropActivity = (refData.ghgFuelCropActivities || []).find((a) => a.value === value);
    if (cropActivity) return cropActivity;

    return undefined;
}

const activityChangeDecorator = {
    field: /fuelCropActivities\[\d+\]\.activity/g,
    updates: (fieldValue, fieldName) => {
        if (!fieldValue) return {};

        return {
            [fieldName]: fieldValue === "UserDefined" ? "" : fieldValue,
        };
    },
}

export const FuelCropActivitiesDecorators = createDecorator(activityChangeDecorator);
