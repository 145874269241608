export default function Comments({ before, after }) {
    const beforeActionComments = before && before.details && before.details.comments && before.details.comments.filter((c) => c.type !== "Actions" && c.includeInFarmImpactReport);
    const afterActionComments = after.details && after.details.comments && after.details.comments.filter((c) => c.type !== "Actions" && c.includeInFarmImpactReport);
    const hasComments = (beforeActionComments && beforeActionComments.length > 0) || (afterActionComments && afterActionComments.length > 0);

    if (!hasComments) return null;

    return (
        <div className={`ReportSection ${hasComments ? "" : "u-print-none"}`}>
            <h1>Comments</h1>
            <div className="Table Table-Compressed">
                <table>
                    <thead>
                        <tr>
                            <th data-width="25">Analysis</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {beforeActionComments &&
                            beforeActionComments.map((c, i) => {
                                return (
                                    <tr>
                                        <td>{i === 0 ? before.name : ""}</td>
                                        <td>{c.comment}</td>
                                    </tr>
                                );
                            })}
                        {afterActionComments &&
                            afterActionComments.map((c, i) => {
                                return (
                                    <tr>
                                        <td>{i === 0 ? after.name : ""}</td>
                                        <td>{c.comment}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
