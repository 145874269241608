import React from "react";

const CardHeaderField = ({ label, value, hasError }) => {
    return (
        <div className="FarmTable-keyValuePair">
            <div className={`FarmTable-keyValue ${hasError ? "has-error" : ""}`}>
                <span className="FarmTable-key">{label}</span>
                <span className="FarmTable-value">{value}</span>
            </div>
        </div>
    );
};
export default CardHeaderField;
