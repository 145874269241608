import Alert from "components/Alert";
import { Panel, PanelBody } from "components/Panel";
import LivestockEvent from "./LivestockEvent";
import { useRefData } from "common/hooks";

export default function LivestockEventsFieldArray({ fields, calculateStockCounts, canBeWeaned, showAgeAndWeightFields, enterpriseType, stockClass, primaryEventTypes, secondaryEventTypes, minDate, alertMessage, maxWeight }) {
    const refData = useRefData();

    const _remove = (e, index) => {
        e.preventDefault();
        fields.remove(index);
    };

    return (
        <Panel title="Livestock events" className="u-overflow-visible" midBlue>
            <Alert type="info" html={alertMessage} />
            <PanelBody className="u-overflow-visible">
                <div className="Table u-mt-md u-table-reset-overflow">
                    <table>
                        <thead>
                            <tr>
                                <th data-width="md-20 xl-20">Event type</th>
                                <th data-width="md-15 xl-15">Event date</th>
                                <th data-width="md-15 xl-15">Number of animals</th>
                                <th data-width="md-15 xl-15">Weight type</th>
                                <th data-width="md-10 xl-10">Weight</th>
                                <th data-width="md-15 xl-15">Age</th>
                                {fields.length > 0 && <th className="th--shrink">&nbsp;</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {fields.length === 0 && (
                                <tr>
                                    <td colSpan="6">
                                        <div className="u-flex u-flexAlignItemsCenter">
                                            <i className="icon icon-info u-textSecondary u-mr-sm" />
                                            <span>You do not have any livestock events</span>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {fields.length > 0 && fields.map((event, index) => <LivestockEvent key={index} fields={fields} event={event} index={index} refData={refData} enterpriseType={enterpriseType} stockClass={stockClass} calculateStockCounts={calculateStockCounts} remove={_remove} canBeWeaned={canBeWeaned} showAgeAndWeightFields={showAgeAndWeightFields} primaryEventTypes={primaryEventTypes} secondaryEventTypes={secondaryEventTypes} minDate={minDate} maxWeight={maxWeight} />)}
                        </tbody>
                    </table>
                </div>
            </PanelBody>
        </Panel>
    )
}
