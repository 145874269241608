import { v4 as uuidv4 } from "uuid";
import SoilTestsDetails from "./SoilTestsDetails";

export default function SoilTestsCreate({ farm, analysis }) {
    const soilTests = {
        id: uuidv4(),
        sType: "Organic",
        nutrients: {},
        applications: []
    };
    return <SoilTestsDetails farm={farm} analysis={analysis} soilTests={soilTests} />
}
