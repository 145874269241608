import React from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import { closeModal, toggleQuickTips } from "containers/App/_actions";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";

class Modal extends React.Component {
    closeModal = (e) => {
        e.preventDefault();
        this.props.closeModal();
        if (this.props.onClose) this.props.onClose();
    };

    closeModalOnEscape = (e) => {
        if (e.keyCode === 27) this.closeModal(e);
    };

    componentDidMount() {
        document.addEventListener("keydown", this.closeModalOnEscape);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.closeModalOnEscape);
    }

    render() {
        const { online, title, instructions, children, skinny, wide, toggleQuickTips, showQuickTips, allowOffline = false } = this.props;
        return (
            <div className="Modal_wrapper">
                <div className={`Modal Modal--${skinny ? "skinny" : wide ? "" : "medium"}`}>
                    <div className="Modal-head">
                        <span className="Modal-head--left">{title}</span>
                        <span className="Modal-head--right">
                            <ActionLink onClick={toggleQuickTips} className="Modal-close">
                                <i className="icon icon-question" title={`${showQuickTips ? "Hide" : "Show"} quick tips`} />
                            </ActionLink>
                            <ActionLink onClick={this.closeModal} className="Modal-close">
                                <i className="icon icon-cross" title="Close" />
                            </ActionLink>
                        </span>
                    </div>
                    <div className="Modal-subhead">
                        {(online || allowOffline) && instructions && <Alert type="info" text={instructions} />}
                        {!online && !allowOffline && <Alert type="error" text="Overseer is currently offline. Please try again later." />}
                    </div>
                    {children({ closeModal: this.closeModal })}
                </div>
            </div>
        );
    }
}

export default compose(withAppContext, withActions({ closeModal, toggleQuickTips }))(Modal);
