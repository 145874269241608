import "./FarmFields.css";
import { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useGoogleApi, useGoogleMap } from "components/Map/hooks";
import * as FormUtils from "common/FormUtils";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";
import TextField from "components/FormFields/TextField";
import AddressAutocompleteField from "components/FormFields/AddressAutocompleteField";
import YesNoRadioGroupField from "components/FormFields/YesNoRadioGroupField";
import SelectField from "components/FormFields/SelectField";
import * as utils from "common/utils";
import { ZendeskLink } from "components/Help";
import Button from "components/Button/Button";
import { useRefData } from "common/hooks";

export const ValidateFarmFields = (values) => {
    const validation = {};

    validation.location = FormUtils.validators.required(values.latitude) || FormUtils.validators.required(values.longitude);

    validation.name = FormUtils.validators.required(values.name);
    validation.name = validation.name || FormUtils.validators.minLength(4)(values.name);
    validation.name = validation.name || FormUtils.validators.maxLength(50)(values.name);
    validation.name = validation.name || FormUtils.validators.nameRegex(values.name);

    validation.supplierNumber = FormUtils.validators.minLength(3)(values.supplierNumber);
    validation.supplierNumber = validation.supplierNumber || FormUtils.validators.maxLength(10)(values.supplierNumber);

    validation.description = FormUtils.validators.maxLength(100)(values.description);

    validation.address = FormUtils.validators.required(values.address);
    validation.address = validation.address || FormUtils.validators.maxLength(100)(values.address);

    if (!values.nearestTown && !values.region) {
        validation.nearestTown = "Nearest town or region required";
        validation.region = "Nearest town or region required";
    }

    if (values.isNew) {
        validation.isOwner = FormUtils.validators.required(values.isOwner);

        if (values.isOwner === "No") {
            validation.ownerName = FormUtils.validators.required(values.ownerName);
            validation.ownerName = validation.ownerName || FormUtils.validators.maxLength(100)(values.ownerName);

            validation.ownerEmail = FormUtils.validators.required(values.ownerEmail);
            validation.ownerEmail = validation.ownerEmail || FormUtils.validators.maxLength(255)(values.ownerEmail);
            validation.ownerEmail = validation.ownerEmail || FormUtils.validators.email(values.ownerEmail);
        }
    }

    return validation;
};

export default function FarmFields({ farm, form, values, errors, submitFailed }) {
    const refData = useRefData();
    const nearestTowns = useNearestTowns();
    const isEducation = refData.deploymentType === "Education";
    const region = values.region ? utils.valueToText(refData.regions, farm.region) : "-";
    const council = values.council ? utils.valueToText(refData.councils, farm.council) : "-";
    const locationLocked = values.latitude && values.locationLocked;
    const farmQs = `${encodeURI(farm?.name)}`;
    const helpDeskLink = (
        <span className="u-flex">
            Please&nbsp;
            <ZendeskLink id="support" className="u-textUnderline" title="contact support" url={`https://support.overseer.org.nz/hc/en-us/requests/new?akuaku=${farmQs}`} rel="noopener noreferrer" target="_blank" />
            &nbsp;if the location is incorrect.
        </span>
    );

    const mapMessage = locationLocked ? <div className="u-flex">The location for this farm is locked.&nbsp;{helpDeskLink}</div> : "Drag the map pin to adjust the map location for this farm. This will set the default clmate for the farm.";

    const { google, map, setMapRef } = useGoogleMap();
    const mapData = useMapData(map, farm, form);
    useFarmMarker(mapData, farm);

    const handleAddressChange = (place) => {
        if (!place || !place.address_components) {
            form.change("latitude", null);
            form.change("longitude", null);
            return;
        }

        const region =
            refData &&
            (refData.regions || []).find((r) => {
                const isRegionMatch = place.address_components.some((ac) => ac && ac.long_name && ac.long_name.toLowerCase() === r.value.toLowerCase());
                const isTownMatch = r.children && r.children.some((t) => place.address_components.some((ac) => ac && ac.long_name && ac.long_name.toLowerCase() === t.value.toLowerCase()));
                return isRegionMatch || isTownMatch;
            });

        let nearestTown = region && region.children && region.children.find((t) => place.address_components.some((ac) => ac && ac.long_name && ac.long_name.toLowerCase() === t.value.toLowerCase()));

        form.change("nearestTown", nearestTown ? nearestTown.value : "");
        form.change("region", region ? region.value : "");
        form.change("address", place.formatted_address);

        const marker = mapData.getFeatureById(farm.id);
        if (marker) {
            marker.setGeometry(place.geometry.location);
        } else {
            mapData.add(new google.maps.Data.Feature({ id: farm.id, properties: { farmId: farm.id }, geometry: place.geometry.location }));
        }
        map.setCenter(place.geometry.location);
        map.setZoom(13);
    };

    const handleNearestTownChange = (values) => (selectedNearestTown) => {
        const region = refData && refData.regions.find((r) => r.children && r.children.some((t) => t.value === selectedNearestTown));
        if (!region) {
            form.change("region", "");
        } else if (region.value !== values.region) {
            form.change("region", region.value);
        }
    };

    const handleRegionChange = (values) => (selectedRegion) => {
        const region = refData && refData.regions.find((r) => r.value === selectedRegion);
        if (!region || !region.childred || !region.children.some((t) => t.value === values.nearestTown)) {
            form.change("nearestTown", "");
        }
    };

    const addIdentifier = () => {
        form.mutators.push("identifiers", "");
    };

    const removeIdentifier = (values, index) => () => {
        const results = values.identifiers.reduce((acc, cur, i) => {
            if (i !== index) acc.push(cur);
            return acc;
        }, []);
        form.change("identifiers", results);
        form.blur("identifiers");
    };

    const validateIdentifiers = (identifiers) => {
        const validations = [];

        identifiers &&
            identifiers.forEach((identifier) => {
                let error = "";
                error = FormUtils.validators.required(identifier);
                error = error || FormUtils.validators.minLength(3)(identifier);
                error = error || FormUtils.validators.maxLength(50)(identifier);
                error = error || FormUtils.validators.alphaNumeric(identifier);
                validations.push(error);
            });

        return validations;
    };

    return (
        <>
            <Field name="id" type="hidden" component="input" />
            <div className={`grid ${farm.isNew ? "grid--withOwnership" : ""} u-mt-md`}>
                <div className="flexWidget details">
                    <div className="flexWidget__header">
                        <h3>Farm details</h3>
                    </div>
                    <div className="flexWidget__body">
                        <Field name="name" label="Farm name" placeholder="Enter an identifiable name for this farm" required component={TextField} />
                        <Field name="description" label="Farm description" placeholder="Enter a farm description" component={TextField} />
                        <Field name="supplierNumber" label="Supplier number (optional)" placeholder="Enter the supplier number for this farm (if applicable)" component={TextField} />
                    </div>
                </div>

                <div className="flexWidget location">
                    <div className="flexWidget__header">
                        <h3>Farm address</h3>
                    </div>
                    <div className="flexWidget__body">
                        {!locationLocked && process.env.REACT_APP_PRODUCT === "fm" && <Alert type="warning" text="The farm address will be locked after saving" />}
                        {farm.locationLocked && <Alert type="info" text={<div className="u-flex">The address for this farm is locked.&nbsp;{helpDeskLink}</div>} />}
                        {mapData && <Field name="address" label="Physical address" readOnly={farm.locationLocked} placeholder="Enter the physical address of the farm" onChange={handleAddressChange} required google={google} component={AddressAutocompleteField} />}
                        <div className="fieldGroup">
                            <Field name="nearestTown" readOnly={farm.locationLocked} label="Nearest town" placeholder={values.region && !values.nearestTown ? "Using region" : "Select a town"} onChange={handleNearestTownChange(values)} options={nearestTowns} required component={SelectField} />
                            <Field name="region" readOnly={farm.locationLocked} label="Region" placeholder="Select a region" onChange={handleRegionChange(values)} options={refData.regions} required component={SelectField} />
                        </div>
                    </div>
                </div>

                {farm.isNew && (
                    <div className="flexWidget ownership">
                        <div className="flexWidget__header">
                            <h3>Farm ownership</h3>
                        </div>
                        <div className="flexWidget__body">
                            <Alert type="info" text="The farm owner represents the farm, e.g. farmer or administration person for the farm. The owner will be able to make this farm account available to organisations or consultancies that they work with." />
                            <Field name="isOwner" label="Are you the owner of this farm?" disabled={isEducation} inline type="radio" component={YesNoRadioGroupField} />
                            {values.isOwner === "No" && (
                                <>
                                    <Field name="ownerName" label="Farm owner's name" placeholder="Enter the farm owner's name" required component={TextField} />
                                    <Field name="ownerEmail" label="Farm owner's email" placeholder="Enter the farm owner's email" required component={TextField} />
                                </>
                            )}
                        </div>
                    </div>
                )}

                <div className="flexWidget identifiers">
                    <div className="flexWidget__header">
                        <h3>Farm identifiers</h3>
                        {values && values.identifiers && values.identifiers.length < 5 && (
                            <ActionLink id="add-farm-identifier-link" className="IconLink--arrow-plus u-link" onClick={addIdentifier}>
                                Add identifier
                            </ActionLink>
                        )}
                    </div>
                    <div className="flexWidget__body">
                        <Alert className="u-mb-sm" type="info" text="Add any identifiers that may be used to find this farm. Possible identifiers could be Agribase ID, account number or a Farms Online ID" />
                        <div className="Table">
                            {values.identifiers.length === 0 && (
                                <div className="Tile-body">
                                    <div className="Tile-body-message">
                                        <Button id="add-application-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={addIdentifier}>
                                            Add identifier
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {values.identifiers.length > 0 && (
                                <table>
                                    <tbody>
                                        <FieldArray name="identifiers" validate={validateIdentifiers}>
                                            {({ fields }) => {
                                                return fields.map((field, index) => {
                                                    return (
                                                        <tr key={`${field}.${index}`}>
                                                            <td>
                                                                <Field name={field} placeholder="Enter a farm identifier" type="text" component={TextField} />
                                                            </td>
                                                            <td className="th--shrink u-textCenter">
                                                                <ActionLink id={`remove-identifier-${index}`} className="IconLink--trash" title="Delete" onClick={removeIdentifier(values, index)}></ActionLink>
                                                            </td>
                                                        </tr>
                                                    );
                                                });
                                            }}
                                        </FieldArray>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flexWidget farmMap">
                    <div className="flexWidget__header">
                        <h3>Map location</h3>
                    </div>
                    <div className="flexWidget__body">
                        {!locationLocked && process.env.REACT_APP_PRODUCT === "fm" && <Alert type="warning" text="The farm location will be locked after saving" />}
                        <Alert className="u-mb-sm" type="info" text={values.latitude ? mapMessage : "Click on the map to drop a pin for the map location for this farm. This will set the default climate for the farm."} />
                        <div className="fieldGroup u-flex-wrap">
                            <Field name="latitude" label="Latitude" component={TextField} type="text" readOnly={true} required={true} />
                            <Field name="longitude" label="Longitude" component={TextField} type="text" readOnly={true} required={true} />
                            {region && false && (
                                <div className="Field u-pb-sm">
                                    <label htmlFor="region_text" className="Field-label">
                                        Model Region
                                    </label>
                                    <div className="Input_wrapper u-pt-sm">{region}</div>
                                </div>
                            )}
                            {council && (
                                <div className="Field u-pb-sm">
                                    <label htmlFor="region_text" className="Field-label">
                                        Region
                                    </label>
                                    <div className="Input_wrapper u-pt-sm">{council}</div>
                                </div>
                            )}
                        </div>

                        <div ref={setMapRef} style={{ flex: 1, boxShadow: submitFailed && errors.latitude ? "0 0 0 2px #973b36" : "" }} />
                        {submitFailed && errors.location && <div className="Field-error u-block u-mt-sm">Please select a valid address, or place the marker on the map for the farm's approximate location.</div>}
                    </div>
                </div>
            </div>
        </>
    );
}

function useMapData(map, farm, form) {
    const google = useGoogleApi();
    const [mapData, setMapData] = useState();

    useEffect(() => {
        if (google && map && farm && form && !mapData) {
            const options = {
                map,
                featureFactory: (geometry) => new google.maps.Data.Feature({ id: farm.id, properties: { farmId: farm.id }, geometry }),
                style: { draggable: !farm.locationLocked },
            };

            // Default map control to setting marker
            if (!farm.latitude) options.drawingMode = "Point";

            const data = new google.maps.Data(options);

            data.addListener("addfeature", ({ feature }) => {
                form.change("latitude", feature.getGeometry().get().lat());
                form.change("longitude", feature.getGeometry().get().lng());
                data.setDrawingMode(null);
                data.setControls(null);
            });

            data.addListener("setgeometry", ({ feature }) => {
                form.change("latitude", feature.getGeometry().get().lat());
                form.change("longitude", feature.getGeometry().get().lng());
            });

            setMapData(data);
        }
    }, [google, map, farm, form, mapData]);

    return mapData;
}

function useFarmMarker(mapData, farm) {
    const google = useGoogleApi();

    useEffect(() => {
        if (google && mapData && farm && farm.latitude) {
            let marker = mapData.getFeatureById(farm.id);
            if (!marker) {
                const latLng = { lat: farm.latitude, lng: farm.longitude };
                const marker = new google.maps.Data.Feature({ id: farm.id, properties: { farmId: farm.id }, geometry: latLng });
                mapData.add(marker);
                mapData.getMap().setCenter(latLng);
                mapData.getMap().setZoom(13);
            }
        }
    }, [google, mapData, farm]);
}

function useNearestTowns() {
    const refData = useRefData();
    const [nearestTowns, setNearestTowns] = useState();

    useEffect(() => {
        if (refData) {
            const towns = (refData.regions || [])
                .filter((region) => region.children && region.children.length > 0)
                .map((region, i) => region.children.map((town) => ({ value: town.value, text: town.text, groupIndex: i, groupLabel: region.text })))
                .reduce((a, b) => [...a, ...b], []);
            setNearestTowns(towns);
        }
    }, [refData]);

    return nearestTowns;
}
