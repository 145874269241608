import { useMemo, useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import Button from "components/Button/Button";

export default function NAppliedFromFertiliserByBlocksChart({ blocks, farmId, selectedBlock, onBlockSelected }) {
    const chartTopRef = useRef();

    const hasTooManyDataPoints = blocks.length > 0 && blocks.length > utils.MAX_DATA_POINTS;
    if (hasTooManyDataPoints) blocks = [];

    const sortedBlocks = blocks.map((b) => b).sort((a, b) => a.nAppliedFromFertiliser - b.nAppliedFromFertiliser);

    const title = "Nitrogen applied from fertiliser (kg/ha)";
    const chartData = getChartData(sortedBlocks, farmId, selectedBlock);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const chartOptions = useMemo(() => getChartOptions(sortedBlocks), [sortedBlocks.length]);

    const chartSizeStyle = { position: "relative", height: "500px" };
    if (sortedBlocks.length > 300) chartSizeStyle.width = `${sortedBlocks.length * 5}px`;

    const chartClicked = (event) => {
        const chartElement = getElementAtEvent(chartTopRef.current, event);
        if (!chartElement || !Array.isArray(chartElement) || chartElement.length !== 1 || isNaN(chartElement[0].index)) return;
        if (!onBlockSelected) return;
        if (sortedBlocks.length > chartElement[0].index) {
            const block = sortedBlocks[chartElement[0].index];
            onBlockSelected(block);
        }
    };

    return (
        <Panel title={title} nloss className="u-mt-md">
            <PanelBody>
                <div className="HeroPanel HeroPanel--nloss u-mt-md u-print-border" ref={chartTopRef}>
                    <div className="u-flex u-flexJustifyBetween">
                        <div className="HeroPanel-heading">{title}</div>
                    </div>
                    {!hasTooManyDataPoints && (
                        <div style={{ overflowX: "auto", overflowY: "hidden" }}>
                            <div style={chartSizeStyle}>
                                <Bar data={chartData} options={chartOptions} onClick={chartClicked} ref={chartTopRef} />
                            </div>
                            <div className="u-flex u-flexJustifyEnd">
                                <Button className="IconLink--search Button Button--secondary u-mt-md" id="add-application-btn" onClick={() => chartTopRef.current.resetZoom()}>
                                    <span>Reset zoom</span>
                                </Button>
                            </div>
                        </div>
                    )}
                    {hasTooManyDataPoints && (
                        <div className="Tile-body-message u-mt-lg u-mb-lg">
                            <i className="icon icon--md icon-alert u-textInfo" />
                            <h3 className="u-mt-md">Benchmarking not supported for comparisons of more than {utils.MAX_DATA_POINTS.toLocaleString()} blocks</h3>
                        </div>
                    )}
                </div>
            </PanelBody>
        </Panel>
    );
};

const getChartData = (blocks, farmId, selectedBlock) => {
    return {
        datasets: [
            {
                data: blocks.map((b) => b.nAppliedFromFertiliser || 0),
                backgroundColor: blocks.map((b) => {
                    if (selectedBlock && selectedBlock.analysisId === b.analysisId && selectedBlock.id === b.id) {
                        return "#ad343e";
                    }
                    if (farmId) {
                        return b.farmId === farmId ? "#44aa6c" : "rgba(68, 170, 108, 0.1)";
                    }
                    return "#44aa6c";
                }),
                borderColor: blocks.map((b) => {
                    if (selectedBlock && selectedBlock.analysisId === b.analysisId && selectedBlock.id === b.id) {
                        return "#ad343e";
                    }
                    return "#44aa6c";
                }),
                borderWidth: 1,
            },
        ],
        labels: blocks.map((b) => (b.farmId === farmId || b.isMyFarm ? `${b.farmName}, ${b.name}` : "")),
    };
};

const getChartOptions = (blocks) => {
    return {
        maintainAspectRatio: false,
        scales: {
            y: { ticks: { beginAtZero: true }, display: true },
            x: { ticks: { display: false } },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        if (blocks.length <= tooltipItem.dataIndex) return tooltipItem.label;
                        const block = blocks[tooltipItem.dataIndex];
                        return `${(block.nAppliedFromFertiliser || 0).toLocaleString()} kg/ha`;
                    },
                },
            },
            legend: {
                display: false,
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: "x",
                    modifierKey: "ctrl",
                },
                zoom: {
                    drag: {
                        enabled: true,
                    },
                    mode: "x",
                },
            },
        },
    };
};
