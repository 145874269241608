import { nutrients } from "common/domain";
import numeral from "numeral";
import { fertiliserViewModel } from "common/viewModels";
import Watermark from "components/Watermark";
import { useRefData } from "common/hooks";

export default function FertiliserList({ analysis }) {
    const refData = useRefData();
    const vm = fertiliserViewModel(analysis, refData).fertilisers;
    const farmNutrientTotals = { applied: 0 };

    return (
        <>
            {vm.length === 0 && "No fertiliser has been applied."}
            {vm.length > 0 && (
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="md-15 xl-15">Manufacturer/material</th>
                                <th data-width="md-20 xl-30">Name</th>
                                <th data-width="md-10 xl-10" className="u-textRight">
                                    Total applied (KG)
                                </th>
                                {nutrients.map((n, i) => {
                                    farmNutrientTotals[n] = 0;
                                    return (
                                        <th data-width="md-5 xl-5" key={i} className="u-textCenter">
                                            {n}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {vm
                                    .filter((f) => !f.isLastPreviousLime)
                                    .map((fertiliser) => {
                                        farmNutrientTotals.applied += fertiliser.total;
                                        return (
                                            <tr key={fertiliser.id}>
                                                <td>
                                                    <span className="u-flex u-flexAlignItemsCenter">
                                                        <img className="u-mr-xs u-p-5" src={fertiliser.icon} width="35" height="35" alt={`${fertiliser.material} icon`} />
                                                        <span id={`fertiliser-manufacturer-${fertiliser.id}`}>{fertiliser.manufacturer}</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>{fertiliser.displayName}</span>
                                                </td>
                                                <td className="u-textRight">
                                                    <span id={`fertiliser-amount-${fertiliser.id}`}>{fertiliser.total > 0 ? numeral(fertiliser.total).format("0,0") : "-"}</span>
                                                </td>
                                                {nutrients.map((n, i) => {
                                                    farmNutrientTotals[n] += fertiliser.nutrientTotals[n] || 0;
                                                    return (
                                                        <td key={i} className="u-textRight">
                                                            <span id={`fertiliser-nutrient-${n}-${fertiliser.id}`}>{fertiliser.nutrientTotals[n] ? numeral(fertiliser.nutrientTotals[n]).format("0,0") : "-"}</span>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                <tr>
                                    <th>
                                        <span className="u-flex u-flexAlignItemsCenter">
                                            <span>Total</span>
                                        </span>
                                    </th>
                                    <th></th>
                                    <th className="u-textRight">
                                        <h4 className="u-mt-0">{numeral(farmNutrientTotals.applied).format("0,0")}</h4>
                                    </th>
                                    {nutrients.map((n, i) => (
                                        <th key={i} className="u-textRight">
                                            <h4 className="u-mt-0">
                                                <span id={`fertiliser-nutrient-${n}-total`}>{farmNutrientTotals[n] ? numeral(farmNutrientTotals[n]).format("0,0") : "-"}</span>
                                            </h4>
                                        </th>
                                    ))}
                                </tr>
                            </>
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}
