import React from "react";

class Card extends React.Component {
    state = {
        collapsed: this.props.open ? false : true,
    };

    toggle = () => (e) => {
        e.stopPropagation();
        this.setState({ collapsed: !this.state.collapsed });
    };

    render() {
        const { collapsible, children } = this.props;

        return (
            <div className="FarmTable_wrapper">
                {collapsible && children({ ...this.state, toggle: this.toggle })}
                {!collapsible && children}
            </div>
        );
    }
}

export default Card;
