import "./ResultsWidget.css";
import * as utils from "common/utils";
import { useBlockResults } from "containers/BudgetHome";
import ResultsLoading from "./ResultsLoading";

export default function BlockResultsWidget({ analysis, block }) {
    const { isLoading, data: blockResults, error } = useBlockResults(analysis, block);

    const hasResults = blockResults !== undefined && !error;
    const nitrogenResults = blockResults?.nitrogen;
    const phosphorusResults = blockResults?.phosphorus;

    return (
        <div className="results-widget">
            <div className="results-widget__nutrient results-widget__nutrient--n">
                <div className="results-widget__name">N</div>
                <ResultsLoading isLoading={isLoading}>
                    {hasResults
                        ? <>
                            <div className="results-widget__item">
                                <span className="results-widget__value" id={`${block.id}-nLost-ha`}>
                                    {utils.numeric.formatAllowZero(nitrogenResults?.lossPerHectare?.value, 1)}
                                </span>
                                <span className="results-widget__uom">kg/ha</span>
                            </div>
                            <div className="results-widget__divider">|</div>
                            <div className="results-widget__item">
                                <span className="results-widget__value" id={`${block.id}-nLost`}>
                                    {utils.numeric.formatAllowZero(nitrogenResults?.totalLoss?.value)}
                                </span>
                                <span className="results-widget__uom">kg</span>
                            </div>
                        </>
                        : <div className="results-widget__item">
                            <span className="results-widget__value" id={`${block.id}-nLost-ha`}>-</span>
                        </div>
                    }
                </ResultsLoading>
            </div>
            <div className="results-widget__nutrient results-widget__nutrient--p">
                <div className="results-widget__name">P</div>
                <ResultsLoading isLoading={isLoading}>
                    {hasResults
                        ? <>
                            <div className="results-widget__item">
                                <span className="results-widget__value" id={`${block.id}-pLost-ha`}>
                                    {utils.numeric.formatAllowZero(phosphorusResults?.lossPerHectare?.value, 1)}
                                </span>
                                <span className="results-widget__uom">kg/ha</span>
                            </div>
                            <div className="results-widget__divider">|</div>
                            <div className="results-widget__item">
                                <span className="results-widget__value" id={`${block.id}-pLost`}>
                                    {utils.numeric.formatAllowZero(phosphorusResults?.totalLoss?.value)}
                                </span>
                                <span className="results-widget__uom">kg</span>
                            </div>
                        </>
                        : <div className="results-widget__item">
                            <span className="results-widget__value" id={`${block.id}-pLost-ha`}>-</span>
                        </div>
                    }
                </ResultsLoading>
            </div>
        </div>
    )
}
