import React from "react";
import * as icons from "common/icons";
import ActionLink from "components/ActionLink";

const SearchInputPack = ({ input, label, placeholder, onChange, onClear }) => {
    const handleChange = (e) => {
        input.onChange(e);
        if (onChange) onChange(e);
    };

    return (
        <div className="Input_wrapper">
            <input {...input} id={input.name} value={input.value || ""} placeholder={placeholder || label} className="Input Input--block has-clear has-value" onChange={handleChange} />
            <ActionLink id="farm-search-reset" onClick={onClear} className="Input-clear">
                <img src={icons.clear} height="24px" width="24px" className="u-p-5"  alt="Clear" />
            </ActionLink>
        </div>
    );
};

export default SearchInputPack;
