import { useAuthContext, useConfirm } from "common/hooks";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import { Button } from "components/Button";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import ActionLink from "components/ActionLink";
import { useInviteUserModal } from "./InviteUserModal";
import { useMyOrg, useDeleteUserInvitationAsync } from "./_hooks";

export default function MyOrgUserInvitations() {
    const { isLoading, data: myOrg } = useMyOrg();
    const authContext = useAuthContext();
    const [inviteUserModal, openInviteUserModal] = useInviteUserModal();
    const confirm = useConfirm();
    const deleteUserInvitationAsync = useDeleteUserInvitationAsync();

    const isAuthorised = authContext.isAdmin;
    const hasUserInvitations = myOrg?.userInvitations?.length > 0 || false;

    const inviteNewUser = () => {
        openInviteUserModal(myOrg);
    }

    const resendInvitation = (invitation) => {
        openInviteUserModal(myOrg, invitation);
    }

    const deleteInvitation = (invitation) => {
        confirm(`Are you sure you want to delete the invitation to "${invitation.email}"?`, async () => deleteUserInvitationAsync(invitation));
    }

    return (
        <>
            <Panel>
                <PanelBody loading={isLoading}>
                    {!isAuthorised && (
                        <div className="Tile-body-message">
                            <p className="lead">You do not have permission to view this information</p>
                        </div>
                    )}
                    {isAuthorised && !hasUserInvitations && (
                        <div className="Tile-body-message">
                            <p className="h4 u-mt-0">There are no outstanding user invitations for your organisation</p>
                            <Button id="invite-user-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={() => inviteNewUser()}>
                                Add a new staff member to your organisation
                            </Button>
                        </div>
                    )}
                    {isAuthorised && hasUserInvitations && (
                        <>
                            <SortableTable data={myOrg.userInvitations} defaultSortCriteria={{ fieldName: "email" }}>
                                {({ data, sortCriteria, handleSort }) => {
                                    return (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <SortableTableHeader label="Email" fieldName="email" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Sent" fieldName="invitedOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                    <SortableTableHeader label="Completed" fieldName="completedOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                    <SortableTableHeader label="Status" fieldName="status" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <th className="th--shrink"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((invitation) => {
                                                    return (
                                                        <tr key={invitation.id} id={invitation.id} className="hover">
                                                            <td>{invitation.email}</td>
                                                            <td>{utils.dateLong(invitation.invitedOn, "-")}</td>
                                                            <td>{utils.dateLong(invitation.completedOn, "-")}</td>
                                                            <td>{invitation.status}</td>
                                                            <td>
                                                                <div className="u-flex">
                                                                    <ActionLink id={`resend-${invitation.id}`} className="IconLink--edit" onClick={() => resendInvitation(invitation)}>
                                                                        Resend
                                                                    </ActionLink>
                                                                    <ActionLink id={`delete-${invitation.id}`} className="IconLink--trash u-ml-sm" onClick={() => deleteInvitation(invitation)}>
                                                                        Delete
                                                                    </ActionLink>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    );
                                }}
                            </SortableTable>
                        </>
                    )}
                </PanelBody>
            </Panel>
            {inviteUserModal}
        </>
    )
}
