import * as utils from "common/utils";

export function isSoilModified(soil) {
    return hasModifiedSoilProfile(soil) || hasModifiedSoilProperties(soil);
}

export function hasModifiedSoilProfile(soil) {
    if (soil) {
        if (soil.profileDrainageClass) return true;
        if (soil.topSoilTexture) return true;
        if (soil.isStony !== undefined) return true;
        if (soil.bottomRootingDepth > 0) return true;
        if (soil.impededLayerDepth > 0) return true;
        if (soil.parentMaterial) return true;
        if (soil.nonStandardLayerDepth > 0) return true;
    }
    return false;
}

export function hasModifiedSoilProperties(soil) {
    if (!soil) return false;

    if (!soil.advancedSettings) return false;

    if (soil.advancedSettings.wiltingPoint) {
        if (soil.advancedSettings.wiltingPoint.top > 0) return true;
        if (soil.advancedSettings.wiltingPoint.middle > 0) return true;
        if (soil.advancedSettings.wiltingPoint.bottom > 0) return true;
    }
    if (soil.advancedSettings.saturation) {
        if (soil.advancedSettings.saturation.top > 0) return true;
        if (soil.advancedSettings.saturation.middle > 0) return true;
        if (soil.advancedSettings.saturation.bottom > 0) return true;
    }
    if (soil.advancedSettings.fieldCapacity) {
        if (soil.advancedSettings.fieldCapacity.top > 0) return true;
        if (soil.advancedSettings.fieldCapacity.middle > 0) return true;
        if (soil.advancedSettings.fieldCapacity.bottom > 0) return true;
    }
    if (soil.advancedSettings.kLeaching > 0) return true;
    if (soil.advancedSettings.bulkDensity > 0) return true;
    if (soil.advancedSettings.carbon > 0) return true;
    if (soil.advancedSettings.clay > 0) return true;
    if (soil.advancedSettings.sand > 0) return true;
    if (soil.advancedSettings.structuralIntegrity > 0) return true;
    if (soil.advancedSettings.subClay > 0) return true;
    if (soil.advancedSettings.saturatedConductivity > 0) return true;
    if (soil.organicChangeMethod) return true;
    if (soil.advancedSettings.omStatus) return true;
    if (soil.advancedSettings.rocOfC > 0) return true;
    if (soil.advancedSettings.cMeasurementMethod) return true;
    if (soil.advancedSettings.initialCContent > 0) return true;
    if (soil.advancedSettings.finalCContent > 0) return true;
    if (soil.advancedSettings.numYearsChange > 0) return true;
    if (soil.advancedSettings.qTpH > 0) return true;

    return false;
}

export function getIsTextureGroupRequired(soil) {
    if (!soil) return false;

    if (soil.sibling) return false;

    if (soil.soilGroup === "Sedimentary") {
        return !soil.soilOrder || soil.soilOrder === "Brown";
    }

    if (soil.soilGroup === "RecentYGEBGE") {
        return !soil.soilOrder || soil.soilOrder === "Recent";
    }

    return false;
}

export function getModifiedSoilProfileData(soil, isTextureGroupRequired, refData) {
    const data = {};

    if (soil.profileDrainageClass) {
        data.soilProfile = {
            title: "Soil profile",
            items: [`Drainage class: ${utils.valueToText(refData.drainageClasses, soil.profileDrainageClass)}`],
        };
    }

    const topSoilItems = [];
    if (soil.isStony !== undefined) {
        topSoilItems.push(`Stony: ${soil.isStony === "false" || soil.isStony === false ? "No" : "Yes"}`);
    }
    if (soil.topSoilTexture && soil.topSoilTexture !== "Undefined") {
        topSoilItems.push(`Topsoil texture: ${utils.valueToText(refData.soilTextures, soil.topSoilTexture)}`);
    }
    if (topSoilItems.length > 0) {
        data.topSoil = { title: "Topsoil (0-10 cm)", items: topSoilItems };
    }

    const lowerProfileItems = [];
    if (soil.bottomRootingDepth) {
        lowerProfileItems.push(`Maximum rooting depth: ${soil.bottomRootingDepth}cm`);
    }
    if (soil.impededLayerDepth) {
        lowerProfileItems.push(`Impeded layer depth: ${soil.impededLayerDepth}cm`);
    }
    if (soil.parentMaterial && soil.parentMaterial !== "Undefined") {
        lowerProfileItems.push(`Non-standard layer: ${utils.valueToText(refData.parentMaterials, soil.parentMaterial)}`);
    }
    if (soil.nonStandardLayerDepth) {
        lowerProfileItems.push(`Non-standard layer depth: ${soil.nonStandardLayerDepth}cm`);
    }
    if (isTextureGroupRequired && soil.textureGroup) {
        lowerProfileItems.push(`Texture group: ${utils.valueToText(refData.textureGroups, soil.textureGroup)}`);
    }
    if (lowerProfileItems.length > 0) {
        data.lowerProfile = { title: "Lower profile", items: lowerProfileItems };
    }

    return data;
}

export function getModifiedSoilPropertiesData(soil, refData) {
    const data = {};

    const { advancedSettings = {} } = soil;

    if (advancedSettings.kLeaching) {
        data.kLeaching = {
            title: "K leaching",
            items: [advancedSettings.kLeaching],
        };
    }

    const wiltingPointItems = [];
    if (advancedSettings.wiltingPoint) {
        if (advancedSettings.wiltingPoint.top) wiltingPointItems.push(`Top: ${advancedSettings.wiltingPoint.top} mm`);
        if (advancedSettings.wiltingPoint.middle) wiltingPointItems.push(`Middle: ${advancedSettings.wiltingPoint.middle} mm`);
        if (advancedSettings.wiltingPoint.bottom) wiltingPointItems.push(`Bottom: ${advancedSettings.wiltingPoint.bottom} mm`);
    }
    if (wiltingPointItems.length > 0) {
        data.wiltingPoint = { title: "Wilting point", items: wiltingPointItems };
    }

    const fieldCapacityItems = [];
    if (advancedSettings.fieldCapacity) {
        if (advancedSettings.fieldCapacity.top) fieldCapacityItems.push(`Top: ${advancedSettings.fieldCapacity.top} mm`);
        if (advancedSettings.fieldCapacity.middle) fieldCapacityItems.push(`Middle: ${advancedSettings.fieldCapacity.middle} mm`);
        if (advancedSettings.fieldCapacity.bottom) fieldCapacityItems.push(`Bottom: ${advancedSettings.fieldCapacity.bottom} mm`);
    }
    if (fieldCapacityItems.length > 0) {
        data.fieldCapacity = { title: "Field capacity", items: fieldCapacityItems };
    }

    const saturationItems = [];
    if (advancedSettings.saturation) {
        if (advancedSettings.saturation.top) saturationItems.push(`Top: ${advancedSettings.saturation.top} mm`);
        if (advancedSettings.saturation.middle) saturationItems.push(`Middle: ${advancedSettings.saturation.middle} mm`);
        if (advancedSettings.saturation.bottom) saturationItems.push(`Bottom: ${advancedSettings.saturation.bottom} mm`);
    }
    if (saturationItems.length > 0) {
        data.saturation = { title: "Saturation", items: saturationItems };
    }

    const topSoilItems = [];
    if (advancedSettings.bulkDensity) {
        topSoilItems.push(`Bulk density: ${advancedSettings.bulkDensity}`);
    }
    if (advancedSettings.carbon) {
        topSoilItems.push(`Carbon: ${advancedSettings.carbon}`);
    }
    if (advancedSettings.clay) {
        topSoilItems.push(`Clay: ${advancedSettings.clay}`);
    }
    if (advancedSettings.sand) {
        topSoilItems.push(`Sand: ${advancedSettings.sand}`);
    }
    if (advancedSettings.structuralIntegrity) {
        topSoilItems.push(`Struct. int.: ${advancedSettings.structuralIntegrity}`);
    }
    if (topSoilItems.length > 0) {
        data.topSoil = { title: "Topsoil (0-10 cm)", items: topSoilItems };
    }

    const subSoilItems = [];
    if (advancedSettings.subClay) {
        subSoilItems.push(`Subsoil clay: ${advancedSettings.subClay}`);
    }
    if (advancedSettings.saturatedConductivity) {
        subSoilItems.push(`Sat. cond.: ${advancedSettings.saturatedConductivity}`);
    }
    if (subSoilItems.length > 0) {
        data.subSoil = { title: "Subsoil (10-60 cm)", items: subSoilItems };
    }

    const organicMatterItems = [];
    if (soil.organicChangeMethod === "OrganicMatterStatus" && advancedSettings.omStatus) {
        organicMatterItems.push(`Status: ${utils.valueToText(refData.omStatus, advancedSettings.omStatus)}`);
    }
    if (soil.organicChangeMethod === "RateOfChange" && advancedSettings.rocOfC) {
        organicMatterItems.push(`Rate of change: ${advancedSettings.rocOfC}`);
    }
    if (soil.organicChangeMethod === "MeasuredCContent") {
        if (advancedSettings.cMeasurementMethod) {
            organicMatterItems.push(`Method: ${utils.valueToText(refData.cMeasurementMethod, advancedSettings.cMeasurementMethod)}`);
        }
        if (advancedSettings.initialCContent) {
            organicMatterItems.push(`Init C content: ${advancedSettings.initialCContent}`);
        }
        if (advancedSettings.finalCContent) {
            organicMatterItems.push(`Final C content: ${advancedSettings.finalCContent}`);
        }
        if (advancedSettings.numYearsChange) {
            organicMatterItems.push(`Num years change: ${advancedSettings.numYearsChange}`);
        }
    }
    if (advancedSettings.qTpH) {
        organicMatterItems.push(`QT PH: ${advancedSettings.qTpH}`);
    }
    if (organicMatterItems.length > 0) {
        data.organicMatter = { title: "Organic matter change", items: organicMatterItems };
    }

    return data;
}
