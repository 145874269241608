import React, { Component } from "react";
import { compose, withAppContext, withState, withActions } from "containers/HOC";
import Loader from "components/Loader";
import { activateUser } from "./_actions";

class ActivateUser extends Component {
    componentDidMount() {
        this.props.activateUser(this.props.activationToken);
    }

    render() {
        return <Loader message="Activating..." />;
    }
}

export default compose(
    withAppContext,
    withState((state, ownProps) => {
        return {
            activationToken: ownProps.match.params.activationToken,
        };
    }),
    withActions({ activateUser })
)(ActivateUser);
