import { Component } from "react";
import FarmSummary from "../Sections/Cards/FarmSummary";
import FarmResults from "../Sections/Tables/FarmResults";
import FarmBlocks from "./Sections/Blocks";
import FarmSupplements from "../Sections/Tables/FarmSupplements";
import FarmSoils from "../Sections/Tables/FarmSoils";
import CheckboxPack from "components/CheckboxPack";
import { compose, withAppContext, withState, withActions } from "containers/HOC";
import { setFormState } from "containers/App/_actions";
import { StockNumbers } from "containers/BudgetHome/Animals/Reports";
import FarmEntRsu from "../Sections/Tables/FarmEntRsu";
import FarmCrops from "../Sections/Tables/FarmCrops";
import FarmIrrigators from "../Sections/Tables/FarmIrrigators";
import FarmStuctures from "../Sections/Tables/FarmStuctures";
import FarmFertiliser from "../Sections/Tables/FarmFertiliser";
import FarmGHG from "../Sections/Tables/FarmGHG";
import AnalysisCommentsTable from "../Sections/Tables/AnalysisCommentsTable";
import BlockDetails from "../Sections/BlockDetails";
import { monthlyBlockValuesModel, farmDetailsViewModel } from "common/viewModels";
import { FarmNutrientBudget } from "components/NutrientBudget";
import EffluentReport from "./EffluentReport";
import Alert from "components/Alert";
import Watermark from "components/Watermark";
import NameValueSplit from "../Sections/Tables/NameValueSplit";
import { getBlockList } from "common/utils";
import FeatureTracker, { FEATURES } from "components/FeatureTracker/FeatureTracker";
import { useAnalysisResults } from "containers/BudgetHome";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
function FarmDetails(props) {
    const { data: analysisResults } = useAnalysisResults(props.analysis);
    return <FarmDetailsClassComponent {...props} analysisResults={analysisResults} />
}

class FarmDetailsClassComponent extends Component {
    state = {};
    constructor(props) {
        super(props);
        document.title = "Overseer - Farm details report";
    }

    componentWillUnmount() {
        document.title = "Overseer";
        this.props.setFormState("nutrientBudget_expandAll", false);
    }

    render() {
        const { farm, analysis, analysisResults, formState, setFormState, refData } = this.props;
        const blocks = getBlockList(analysis.blocks);
        const monthlyBlockModel = monthlyBlockValuesModel(refData, analysis, farm);
        const { currentResults = {}, effluentSystem = {} } = analysis;
        const { effluentResults } = currentResults;
        const hasAnimalResults = analysisResults?.animals !== undefined;

        return (
            <>
                <FeatureTracker feature={FEATURES.FARM_DETAILS} farm={farm} />
                <Alert
                    className="u-print-none"
                    type="info"
                    text={
                        <div>
                            Shows all details for the analysis and blocks. You can turn on/off sections of the report under Report settings. Select print to print the report or write it to a PDF. Select PDF as the destination if you wish to save the file.
                            <br />
                            <b>Please note</b>: Firefox currently has a bug that affects the printing of this report. We are working on this and a problem showing block results in Microsoft Edge, in the meantime use Chrome for printing or writing to PDF.
                        </div>
                    }
                />

                <div className={`HeroPanel u-mb-lg u-pb-md u-print-none ${formState.print_showSettings ? "" : "u-shaded"}`}>
                    <div className="u-flex u-flexJustifyBetween">
                        <div>
                            <div className="h2 u-mt-sm">Report settings</div>
                        </div>
                        <div>
                            <button id="btnModify" className="Button Button--secondary" type="button" onClick={(e) => setFormState("print_showSettings", !formState.print_showSettings)}>
                                {formState.print_showSettings ? "Close report settings" : "Modify report settings"}
                            </button>
                            <button id="btnPrint" className="Button u-ml-sm" type="button" onClick={() => window.print()}>
                                Print
                            </button>
                        </div>
                    </div>

                    {formState.print_showSettings && (
                        <>
                            <hr className="u-mt-md" />
                            <div>
                                <div className="h5 u-pt-xxl">Farm settings</div>
                                <div className="u-pr-xxl u-flex u-flex-wrap">
                                    <CheckboxPack meta={{ nrf: true }} label="Farm details" value={formState.print_showFarmDetails} onChange={(e) => setFormState("print_showFarmDetails", !formState.print_showFarmDetails)} className="Field-mt-sm u-pr-lg" />
                                    {formState.print_showFarmDetails && (
                                        <>
                                            <CheckboxPack meta={{ nrf: true }} label="Farm results" value={formState.print_showFarmResults} onChange={(e) => setFormState("print_showFarmResults", !formState.print_showFarmResults)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Analysis comments" value={formState.print_showAnalysisComments} onChange={(e) => setFormState("print_showAnalysisComments", !formState.print_showAnalysisComments)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Blocks" value={formState.print_showFarmBlocks} onChange={(e) => setFormState("print_showFarmBlocks", !formState.print_showFarmBlocks)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Farm soils" value={formState.print_showFarmSoils} onChange={(e) => setFormState("print_showFarmSoils", !formState.print_showFarmSoils)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Enterprises" value={formState.print_showEnterprises} onChange={(e) => setFormState("print_showEnterprises", !formState.print_showEnterprises)} className="Field-mt-sm u-pr-lg" />
                                            {formState.print_showEnterprises && <CheckboxPack meta={{ nrf: true }} label="Mobs" value={formState.print_showMobs} onChange={(e) => setFormState("print_showMobs", !formState.print_showMobs)} className="Field-mt-sm u-pr-lg" />}
                                            <CheckboxPack meta={{ nrf: true }} label="Supplements" value={formState.print_showSupplements} onChange={(e) => setFormState("print_showSupplements", !formState.print_showSupplements)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Crops" value={formState.print_showCrops} onChange={(e) => setFormState("print_showCrops", !formState.print_showCrops)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Fertiliser" value={formState.print_showFertiliser} onChange={(e) => setFormState("print_showFertiliser", !formState.print_showFertiliser)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Irrigators" value={formState.print_showIrrigators} onChange={(e) => setFormState("print_showIrrigators", !formState.print_showIrrigators)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Structures/Effluent system" value={formState.print_showStructures} onChange={(e) => setFormState("print_showStructures", !formState.print_showStructures)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="GHG emissions" value={formState.print_showGHGResults} onChange={(e) => setFormState("print_showGHGResults", !formState.print_showGHGResults)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Nutrient budgets" value={formState.print_showNutrientBudget} onChange={(e) => setFormState("print_showNutrientBudget", !formState.print_showNutrientBudget)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Effluent report" value={formState.print_showEffluentReport} onChange={(e) => setFormState("print_showEffluentReport", !formState.print_showEffluentReport)} className="Field-mt-sm u-pr-lg" />
                                        </>
                                    )}
                                </div>
                                <div className="h5 u-pt-xxl">Block settings</div>
                                <div className="u-flex u-flex-wrap">
                                    <CheckboxPack meta={{ nrf: true }} label="Block details" value={formState.print_showBlocks} onChange={(e) => setFormState("print_showBlocks", !formState.print_showBlocks)} className="Field-mt-sm u-pr-lg" />
                                    {formState.print_showBlocks && (
                                        <>
                                            <CheckboxPack meta={{ nrf: true }} label="Climate" value={formState.print_showBlockClimate} onChange={(e) => setFormState("print_showBlockClimate", !formState.print_showBlockClimate)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Pasture" value={formState.print_showBlockPastureResults} onChange={(e) => setFormState("print_showBlockPastureResults", !formState.print_showBlockPastureResults)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Drainage" value={formState.print_showBlockDrainage} onChange={(e) => setFormState("print_showBlockDrainage", !formState.print_showBlockDrainage)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Supplements" value={formState.print_showBlockSupplementResults} onChange={(e) => setFormState("print_showBlockSupplementResults", !formState.print_showBlockSupplementResults)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Crops" value={formState.print_showBlockCrops} onChange={(e) => setFormState("print_showBlockCrops", !formState.print_showBlockCrops)} className="Field-mt-sm u-pr-lg" />
                                            {formState.print_showBlockCrops && false && <CheckboxPack meta={{ nrf: true }} label="Growth curves" value={formState.print_showBlockCurves} onChange={(e) => setFormState("print_showBlockCurves", !formState.print_showBlockCurves)} className="Field-mt-sm u-pr-lg" />}
                                            <CheckboxPack meta={{ nrf: true }} label="N Pool graphs" value={formState.print_showNPoolsGraphs} onChange={(e) => setFormState("print_showNPoolsGraphs", !formState.print_showNPoolsGraphs)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Nutrient budget" value={formState.print_showBlockNutrientBudget} onChange={(e) => setFormState("print_showBlockNutrientBudget", !formState.print_showBlockNutrientBudget)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Months" value={formState.print_showBlockMonths} onChange={(e) => setFormState("print_showBlockMonths", !formState.print_showBlockMonths)} className="Field-mt-sm u-pr-lg" />
                                            <CheckboxPack meta={{ nrf: true }} label="Soil/Irrigation results" value={formState.print_showSoilIrrigationResults} onChange={(e) => setFormState("print_showSoilIrrigationResults", !formState.print_showSoilIrrigationResults)} className="Field-mt-sm u-pr-lg" />
                                        </>
                                    )}
                                </div>
                                <div className="h5 u-pt-xxl">Report options</div>
                                <div className="u-flex u-flex-wrap">
                                    <CheckboxPack meta={{ nrf: true }} label="Expand nutrient budget sections" value={formState.nutrientBudget_expandAll} onChange={(e) => setFormState("nutrientBudget_expandAll", !formState.nutrientBudget_expandAll)} className="Field-mt-sm u-pr-lg" />
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="u-pt-0 u-pos-rel u-overflow-hidden">
                    <Watermark />
                    {formState.print_showFarmDetails && <FarmSummary analysis={analysis} />}

                    {formState.print_showFarmDetails && (
                        <div className="u-pt-0">
                            <NameValueSplit nameValues={farmDetailsViewModel(analysis, refData)} splitBy={1} />
                        </div>
                    )}

                    {formState.print_showFarmResults && formState.print_showFarmDetails && (
                        <div className="Print-Table u-mt-md u-page-break-avoid">
                            <FarmResults analysis={analysis} />
                        </div>
                    )}
                </div>

                {formState.print_showAnalysisComments && formState.print_showFarmDetails && analysis.comments && analysis.comments.length > 0 && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Analysis comments</div>
                        <div className="u-pt-sm">
                            <AnalysisCommentsTable analysis={analysis} />
                        </div>
                    </div>
                )}

                {formState.print_showFarmBlocks && formState.print_showFarmDetails && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Blocks</div>
                        <div className="u-pt-sm">
                            <FarmBlocks analysis={analysis} />
                        </div>
                    </div>
                )}

                {formState.print_showFarmSoils && formState.print_showFarmDetails && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Farm soils</div>
                        <div className="u-pt-sm">
                            <FarmSoils farm={farm} analysis={analysis} />
                        </div>
                    </div>
                )}

                {formState.print_showEnterprises && formState.print_showFarmDetails && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Enterprises</div>
                        {hasAnimalResults
                            ? <>
                                <h5>Stock numbers</h5>
                                <div className="u-pt-sm">
                                    <StockNumbers analysis={analysis} showMobs={formState.print_showMobs} />
                                </div>
                                <h5>RSU</h5>
                                <div className="u-pt-sm">
                                    <FarmEntRsu analysis={analysis} />
                                </div>
                            </>
                            : <p>There are no animal results for this analysis</p>}
                    </div>
                )}

                {formState.print_showIrrigators && formState.print_showFarmDetails && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Irrigators</div>
                        <div className="u-pt-sm">
                            <FarmIrrigators analysis={analysis} />
                        </div>
                    </div>
                )}

                {formState.print_showStructures && formState.print_showFarmDetails && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Structures</div>
                        <div className="u-pt-sm">
                            <FarmStuctures analysis={analysis} />
                        </div>
                    </div>
                )}

                {formState.print_showSupplements && formState.print_showFarmDetails && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Supplements</div>
                        <div className="u-pt-sm">
                            <FarmSupplements analysis={analysis} />
                        </div>
                    </div>
                )}

                {formState.print_showCrops && formState.print_showFarmDetails && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Crops</div>
                        <div className="u-pt-sm">
                            <FarmCrops analysis={analysis} />
                        </div>
                    </div>
                )}

                {formState.print_showFertiliser && formState.print_showFarmDetails && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Fertiliser</div>
                        <div className="u-pt-sm">
                            <FarmFertiliser analysis={analysis} />
                        </div>
                    </div>
                )}

                {formState.print_showGHGResults && formState.print_showFarmDetails && (
                    <div className="Print-Table u-mt-md u-page-break-avoid">
                        <div className="h2 u-pt-0">GHG - Total farm emissions</div>
                        <div className="u-pt-sm">
                            <FarmGHG analysis={analysis} />
                        </div>
                    </div>
                )}

                {formState.print_showNutrientBudget && formState.print_showFarmDetails && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Farm nutrient budget</div>
                        <div className="u-pt-sm">
                            <FarmNutrientBudget analysis={analysis} />
                        </div>
                    </div>
                )}

                {formState.print_showEffluentReport && formState.print_showFarmDetails && effluentResults && (
                    <div className="u-pt-lg u-page-break-avoid">
                        <div className="h2 u-pt-0">Effluent report</div>
                        <div className="u-pt-sm">
                            <Alert type="info" text="The report shows rates and target areas for farm liquid effluent only, assuming it is all applied to pastoral blocks. It excludes any farm solid effluent or imported effluent that may be added to effluent blocks. If this occurs, then target areas may need to be increased." />
                            <EffluentReport effluentResults={effluentResults} effluentSystem={effluentSystem} />
                        </div>
                    </div>
                )}

                {formState.print_showBlocks && (
                    <div className={`${formState.print_showFarmDetails && "u-page-break"} u-mt-lg`}>
                        <div className="h2">Blocks</div>
                        <div className="u-pt-0">
                            {
                                <>
                                    {blocks.productiveBlocks.map((b, i) => (
                                        <div key={i} className={`${i > 0 ? "u-page-break-avoid" : ""}`}>
                                            <BlockDetails farm={farm} analysis={analysis} block={b} monthlyBlockModel={monthlyBlockModel} />
                                        </div>
                                    ))}
                                    {blocks.nonProductiveBlocks.map((b, i) => (
                                        <div key={i} className={`${i > 0 ? "u-page-break-avoid" : ""}`}>
                                            <BlockDetails farm={farm} analysis={analysis} block={b} monthlyBlockModel={monthlyBlockModel} />
                                        </div>
                                    ))}
                                </>
                            }
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default compose(
    withAppContext,
    withState((state) => {
        if (!state.app.formState || state.app.formState.print_showSettings === undefined) {
            state.app.formState = {
                ...state.app.formState,
                print_showSettings: true,
                print_showFarmDetails: true,
                print_showFarmResults: true,
                print_showFarmSoils: true,
                print_showEnterprises: true,
                print_showMobs: false,
                print_showSupplements: true,
                print_showCrops: true,
                print_showGHGResults: true,
                print_showFertiliser: true,
                print_showIrrigators: true,
                print_showStructures: true,
                print_showBlocks: true,
                print_showBlockClimate: true,
                print_showBlockPastureResults: true,
                print_showBlockSupplementResults: true,
                print_showBlockCrops: true,
                print_showBlockDrainage: true,
                print_showBlockCurves: true,
                print_expandAll: true,
                print_showEffluentReport: true,
                print_showNutrientBudget: true,
                print_showFarmBlocks: true,
                print_showBlockNutrientBudget: true,
                print_showBlockMonths: true,
                print_showAnalysisComments: true,
            };
        }

        return {
            formState: state.app.formState,
        };
    }),
    withActions({ setFormState })
)(FarmDetails);
