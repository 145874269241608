import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useIsOverseerEd, useIsPaidVersionOfOverseer } from "common/hooks";
import * as utils from "common/utils";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { orgUtils } from "containers/Orgs";
import { CreateOrgActionLink } from "containers/Admin/Orgs";

export default function OrgList({ orgs }) {
    const isPaidVersionOfOverseer = useIsPaidVersionOfOverseer();
    const isOverseerEd = useIsOverseerEd();
    const location = useLocation();

    const viewModel = orgs.map((org) => {
        const parentOrg = isOverseerEd && orgUtils.isStudentOrg(org) ? orgs.find((o) => o.id === org.parentAccountId) : undefined;
        return {
            ...org,
            parentName: parentOrg ? parentOrg.name : "-",
        };
    });

    return (<>
        <OrgActions />
        <OrgCount orgs={orgs} />
        <SortableTable data={viewModel} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }} pageSize={100}>
            {({ data, sortCriteria, handleSort }) => {
                return (
                    <table>
                        <thead>
                            <tr>
                                <SortableTableHeader label="Org" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                <SortableTableHeader label="Type" fieldName="type" sortCriteria={sortCriteria} handleSort={handleSort} />
                                {isPaidVersionOfOverseer && <SortableTableHeader label="Credit limit" fieldName="creditLimit" sortCriteria={sortCriteria} handleSort={handleSort} number />}
                                <SortableTableHeader label="Created" fieldName="createdOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                <SortableTableHeader label="Modified" fieldName="modifiedOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                <SortableTableHeader label="Users" fieldName="userCount" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                {isOverseerEd && <SortableTableHeader label="Parent" fieldName="parentName" sortCriteria={sortCriteria} handleSort={handleSort} />}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((org, i) => {
                                return (
                                    <tr key={org.id} id={org.id} className="hover">
                                        <td>
                                            <Link id={org.id} name={`row_${i}`} data-test-id={org.id} to={{ pathname: `/app/admin/orgs/${org.id}`, state: { referrer: location.pathname } }}>
                                                {org.name}
                                            </Link>
                                        </td>
                                        <td>{utils.enumToSentence(org.type)}</td>
                                        {isPaidVersionOfOverseer && <td className="u-textCenter">{org.creditLimit}</td>}
                                        <td>{utils.dateLong(org.createdOn, "-")}</td>
                                        <td>{utils.dateLong(org.modifiedOn, "-")}</td>
                                        <td className="u-textRight">{org.userCount}</td>
                                        {isOverseerEd && <td>{org.parentName}</td>}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                );
            }}
        </SortableTable>
    </>);
}

function OrgActions() {
    return (
        <div className="ActionsBar ActionsBar--super u-print-none">
            <div className="ActionsBar-left">
                <ul className="ActionsBar-links">
                    <li>
                        <CreateOrgActionLink />
                    </li>
                </ul>
            </div>
        </div>
    );
}

function OrgCount({ orgs }) {
    if (!orgs) return null;

    return (
        <p className="u-mt-0 u-mb-md">
            <span className="h3">{orgs.length.toLocaleString()}</span> orgs(s) found
        </p>
    );
}
