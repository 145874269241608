import React from "react";
import * as validations from "common/validations";
import Modal from "components/new/Modal";
import ModalBody from "components/new/ModalBody";
import ModalFooter from "components/new/ModalFooter";
import InputPack from "components/InputPack";
import RadioGroupPack from "components/RadioGroupPack";
import SelectPack from "components/SelectPack2";
import { Form, Field } from "react-final-form";
import { Grid, GridCell } from "components/Grid";
import { compose, withAppContext, withActions, withState } from "containers/HOC";
import moment from "moment";
import DatePickerInputPack from "components/DatePickerInputPack";
import { updateMyOrg } from "./_actions";
import { getStudents } from "containers/AccountDetails/_actions";

class EditStudentModal extends React.Component {
    componentDidMount() {
        this.props.getStudents();
    }

    _validate = (formValues) => {
        const errors = {};

        errors.group = validations.required(formValues.group);
        errors.group = errors.group || validations.maxLength(255)(formValues.group);

        return errors;
    };

    _onSubmit = (account) => {
        account.expires = moment(account.expiresFormatted);
        this.props.updateMyOrg({
            id: account.id,
            expires: moment(account.expiresFormatted),
            group: account.group,
            name: account.name,
            type: "Student",
        });
    };

    render() {
        const { initialValues } = this.props;
        initialValues.isExisting = initialValues.isExisting || "true";
        const { invitations = [], students = [], groupReadolnly = false } = this.props;
        const invitationGroups = invitations.filter((s) => moment(s.expires).isAfter(moment().valueOf())).map((g) => g.group);
        const studentGroups = students.filter((s) => moment(s.expires).isAfter(moment().valueOf())).map((g) => g.group);
        const allGroups = [...new Set([...invitationGroups, ...studentGroups])]
            .filter((g) => g !== undefined)
            .sort((a, b) => (a < b ? -1 : 1))
            .map((g) => ({ value: g, text: g }));
        initialValues.expiresFormatted = moment(initialValues.expires || "2000-01-01").format("DD MMMM YYYY");

        return (
            <Form validate={this._validate} initialValues={initialValues} onSubmit={this._onSubmit}>
                {({ handleSubmit, submitSucceeded, values }) => {
                    return (
                        <Modal title="Student details" instructionText="Update the student's group" skinny>
                            {({ closeModal }) => (
                                <form onSubmit={handleSubmit}>
                                    <ModalBody>
                                        <Grid>
                                            <GridCell>
                                                <Field name="id" type="hidden" component="input" />
                                                <Field name="name" type="text" label="Name" disabled={true} component={InputPack} />

                                                {allGroups.length > 0 && (
                                                    <div className="u-pt-xl">
                                                        <Field
                                                            name="isExisting"
                                                            options={[
                                                                { value: "true", text: "Use existing group" },
                                                                { value: "false", text: "Create new group" },
                                                            ]}
                                                            inline={true}
                                                            component={RadioGroupPack}
                                                        />
                                                    </div>
                                                )}
                                                {values.isExisting === "true" && !groupReadolnly ? <Field name={`group`} label="Group" component={SelectPack} placeholderEnabled={false} required={true} options={allGroups} placeholder="Select a group" /> : <Field name="group" type="text" requiredLabel={true} label="Group" component={InputPack} placeholder="Enter the student group or course" disabled={groupReadolnly} />}

                                                <Field name="expiresFormatted" label="Expiry date" clearable={false} component={DatePickerInputPack} dataWidth="100" inline={true} showYearDropdown={true} />
                                            </GridCell>
                                        </Grid>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="ButtonBar">
                                            <div className="ButtonBar-left">
                                                <button id="cancel-button" type="button" className="Button Button--secondary" onClick={closeModal}>
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="ButtonBar-right">
                                                <button id="save-button" type="submit" className="Button" disabled={submitSucceeded}>
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </ModalFooter>
                                </form>
                            )}
                        </Modal>
                    );
                }}
            </Form>
        );
    }
}
export default compose(
    withAppContext,
    withState((state) => {
        return {
            students: state.userManagement.students,
            invitations: state.userManagement.studentInvitations ? state.userManagement.studentInvitations.filter((i) => i.status !== "Accepted" && i.target === "Organisation") : [],
        };
    }),
    withActions({ getStudents, updateMyOrg })
)(EditStudentModal);
