import moment from "moment";
import { Link } from "react-router-dom";
import { httpClient } from "common/httpClient";
import * as utils from "common/utils";
import ActionLink from "components/ActionLink";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { usePaymentModal } from "containers/Payments/PaymentModal";
import RenewSubscriptionButton from "containers/Payments/RenewSubscriptionButton";
import { getFarmDisplayName } from "containers/FarmHome/_utils";
import { RequestFarmAccessActionLink } from "containers/Farm/RequestFarmAccess";
import Button from "components/Button/Button";
import { useToggleMyFarmAsync } from "containers/hooks";
import { useAuthContext, useIsEducationProviderUser, useIsOverseerEd, useIsPaidVersionOfOverseer, useIsStudentUser, useNavigate, useOnline, useRefData } from "common/hooks";
import { useDispatch } from "react-redux";
import searchActionTypes from "containers/FarmSearch/_actions";
import ExtendSubscriptionLink from "containers/Payments/ExtendSubscriptionLink";

export default function FarmList({ farms, filterBy, reload, templates, pageSize, hideMyFarm }) {
    const online = useOnline();
    const authContext = useAuthContext();
    const refData = useRefData();
    const navigate = useNavigate();
    const toggleMyFarmAsync = useToggleMyFarmAsync();
    const isPaidVersionOfOverseer = useIsPaidVersionOfOverseer();
    const isStudent = useIsStudentUser();
    const isEducationProvider = useIsEducationProviderUser();
    const isOverseerEd = useIsOverseerEd();
    const [paymentModal, openPaymentModal] = usePaymentModal();
    const refreshSearchResults = useRefreshReduxSearchResults();

    const _toggleMyFarm = async (farm) => {
        const isMyFarm = !farm.isMyFarm;
        await toggleMyFarmAsync(farm.id, isMyFarm);
        refreshSearchResults(farm.id, isMyFarm);
    }

    const _cloneFarm = (farm) => async (e) => {
        e.preventDefault();
        const response = await cloneFarmToStudentAsync(farm);
        navigate(`/app/farm/${response}`);
    };

    const rawData = farms.map((farm) => {
        const isMyFarm = farm.isMyFarm;
        const hasFarmRole = farm.role;
        const isUnpaid = farm.expired || !farm.expirationDate;
        const daysTillExpired = isUnpaid || !farm.expirationDate ? 0 : moment.utc(farm.expirationDate).diff(moment.utc(), "days");
        const canExtend = daysTillExpired > 0 && daysTillExpired < 182;
        const newExpiryDate = isUnpaid ? moment().add(1, "years") : moment(farm.expirationDate).add(1, "years");

        return {
            id: farm.id,
            name: farm.name,
            displayName: getFarmDisplayName(farm),
            address: farm.address,
            role: farm.role,
            createdOn: farm.createdOn,
            expired: farm.expired,
            expirationDate: farm.expirationDate,
            ownershipStatus: farm.ownershipStatus,
            canPay: utils.canMakePayment(farm),
            canAccess: utils.canReadFarm(farm),
            access: hasFarmRole ? utils.farmRolesToText(farm, refData.farmRoles) : farm.accessRequest ? farm.accessRequest.status : "-",
            accessRequest: farm.accessRequest,
            farmClaims: farm.farmClaims,
            studentGroup: farm.studentGroup,
            isMyFarm,
            isUnpaid,
            daysTillExpired,
            canExtend,
            newExpiryDate,
        };
    });

    const data = isStudent ? rawData.filter((farm) => (templates && !farm.canAccess) || (!templates && farm.canAccess)) : isEducationProvider ? rawData.filter((farm) => (templates && farm.studentGroup) || (!templates && !farm.studentGroup)) : rawData;

    const bulkPay = () => {
        const farmsThatCanBeBulkPaid = data.filter((farm) => {
            //const daysTillExpired = (farm.isUnpaid || !farm.expirationDate) ? 0 : moment.utc(farm.expirationDate).diff(moment.utc(), 'days');
            //const canExtend = daysTillExpired > 0 && daysTillExpired < 182;
            return farm.isUnpaid;
        });
        openPaymentModal(farmsThatCanBeBulkPaid, reload);
    }

    const hasMultipleExpiredFarms = data.filter((farm) => farm.isUnpaid).length > 1;

    return (
        <>
            <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter u-mb-md">
                <p className="u-mt-0">
                    <span className="h3">{data.length}</span> farm{data.length === 1 ? "" : "s"}
                </p>
                {online && filterBy !== "AllFarms" && hasMultipleExpiredFarms && (
                    <ul className="ActionsBar-links">
                        <li>
                            <Button id="bulk-renew" className="Button Button--sm Button--alert u-print-none" onClick={bulkPay}>
                                <i className="icon icon--before icon-dollar" />
                                <span>Bulk pay for subscriptions</span>
                            </Button>

                            {false && (
                                <ActionLink id="renew-subscriptions" className="IconLink--dollar" onClick={bulkPay}>
                                    Bulk pay for subscriptions
                                </ActionLink>
                            )}
                        </li>
                    </ul>
                )}
            </div>

            {data.length === 0 ? (
                <div className="Tile-body-message" style={{ margin: "10px 0 40px 26%" }}>
                    <p className="lead">You do not have any farms in this list</p>
                </div>
            ) : (
                <SortableTable data={data} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }} pageSize={pageSize}>
                    {({ data, sortCriteria, handleSort }) => {
                        return (
                            <table>
                                <thead>
                                    <tr>
                                        <SortableTableHeader label="Farm" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        {false && <SortableTableHeader label="Address" fieldName="address" sortCriteria={sortCriteria} handleSort={handleSort} />}
                                        <SortableTableHeader label="Created" fieldName="createdOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                        {filterBy !== "AllFarms" && isPaidVersionOfOverseer && <SortableTableHeader label="Subscription" fieldName="expirationDate" sortCriteria={sortCriteria} handleSort={handleSort} date />}
                                        <SortableTableHeader label="Ownership status" fieldName="ownershipStatus" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Access" fieldName="access" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        {isEducationProvider && templates && <SortableTableHeader label="Student group" fieldName="studentGroup" sortCriteria={sortCriteria} handleSort={handleSort} />}
                                        {online && (!templates || isEducationProvider) && !hideMyFarm && <SortableTableHeader center label={isOverseerEd ? "Favourite" : "My farm"} fieldName="isMyFarm" sortCriteria={sortCriteria} handleSort={handleSort} bool />}
                                        {online && <th className="th--shrink u-textCenter"></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((farm) => {
                                        return (
                                            <tr key={farm.id} className={`hover ${farm.isUnpaid ? "" : ""}`}>
                                                <td>
                                                    <div>
                                                        {farm.canAccess ? (
                                                            <Link to={`/app/farm/${farm.id}`} id={`farm-view-${farm.id}`} className="u-textBold">
                                                                {farm.displayName}
                                                            </Link>
                                                        ) : (
                                                            <b>{farm.displayName}</b>
                                                        )}
                                                    </div>
                                                    <div>{farm.address}</div>
                                                </td>
                                                {false && <td>{farm.address}</td>}
                                                <td>{utils.dateOnly(farm.createdOn)}</td>
                                                {filterBy !== "AllFarms" && isPaidVersionOfOverseer && (
                                                    <td>
                                                        {!farm.isUnpaid && (
                                                            <div className="u-flex u-flexAlignItemsCenter u-flexWrap">
                                                                <div className="u-pl-sm">{moment(farm.expirationDate).format("DD MMM YYYY")}</div>
                                                                <div className="u-pl-sm">({farm.daysTillExpired} days)</div>
                                                                <div className="u-pl-sm">
                                                                    <ExtendSubscriptionLink farm={farm} onPaid={reload} />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {farm.isUnpaid && farm.canPay && (
                                                            <div className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
                                                                <RenewSubscriptionButton farm={farm} onPaid={reload} />
                                                            </div>
                                                        )}
                                                    </td>
                                                )}
                                                <td>{farm.ownershipStatus}</td>
                                                <td>{farm.access}</td>
                                                {isEducationProvider && templates && <td className="u-textLeft">{farm.studentGroup}</td>}
                                                {online && (!templates || isEducationProvider) && !hideMyFarm && (
                                                    <td className="u-textCenter">
                                                        {farm.canAccess && (
                                                            <label className="Checkbox" htmlFor={"my-farms-select-" + farm.id}>
                                                                <input className="Checkbox-input" type="checkbox" id={"my-farms-select-" + farm.id} checked={farm.isMyFarm} onChange={() => _toggleMyFarm(farm)} />
                                                                <span className="Checkbox-label"></span>
                                                            </label>
                                                        )}
                                                        {!farm.canAccess && <span>-</span>}
                                                    </td>
                                                )}
                                                {online && (
                                                    <td className="u-textCenter">
                                                        {farm.canAccess && (
                                                            <div className="u-flex">
                                                                {utils.canUpdateFarm(farm) && <Link to={`/app/farm/${farm.id}/settings`} id={`farm-settings-${farm.id}`} className="RoundButton RoundButton--settings u-mr-sm" title="Manage farm settings"></Link>}
                                                                {utils.canManageAccess(farm) && authContext.accountType !== "Student" && <Link to={`/app/farm/${farm.id}/access`} id={`farm-access-${farm.id}`} className="RoundButton RoundButton--lock u-mr-sm" title="Manage farm access"></Link>}
                                                            </div>
                                                        )}
                                                        {!farm.canAccess && !farm.accessRequest && refData.deploymentType !== "Education" && online && <RequestFarmAccessActionLink farm={farm} className="IconLink--arrow-plus u-link" />}
                                                        {!farm.canAccess && !farm.accessRequest && refData.deploymentType === "Education" && online && (
                                                            <ActionLink id={`farm-getcopy-${farm.id}`} className="IconLink--arrow-plus u-link" onClick={_cloneFarm(farm)}>
                                                                Make copy
                                                            </ActionLink>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )
                    }}
                </SortableTable>
            )}
            {paymentModal}
        </>
    )
}

async function cloneFarmToStudentAsync(farm) {
    try {
        return await httpClient.get(`farms/${farm.id}/studentcopy`);
    } catch (error) {
        throw new Error(error.message);
    }
}

/**
 * Get a function for refreshing the redux search results in case we're on the farm search page
 */
function useRefreshReduxSearchResults() {
    const dispatch = useDispatch();

    return (farmId, isMyFarm) => {
        // The farm search page still uses redux so we have to dispatch this action to refresh the search results in case we're on that page
        dispatch({ type: searchActionTypes.FARM_SEARCH_TOGGLE_MY_FARM, payload: { id: farmId, isMyFarm } });
    }
}
