import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as geoJsonUtils from "common/geoJsonUtils";
import MapLabel, { MAP_PANES } from "components/Map/MapLabel";

export default function MapErrorLabels({ map, geoJson }) {
    const [labels, setLabels] = useState();

    useEffect(() => {
        if (map && geoJson) {
            const labelData = geoJson.features
                .filter((feature) => feature.properties.error)
                .map((feature) => {
                    const position = geoJsonUtils.center(feature);
                    const innerHTML = `<div class="IconLink--alert">${feature.properties.error}</div>`;
                    return { id: uuidv4(), position, innerHTML };
                });
            setLabels(labelData);
        }
    }, [map, geoJson]);

    if (!labels) return null;

    return (
        <>
            {labels.map((label) => (
                <MapLabel key={label.id} map={map} {...label} className="mapErrorLabel" mapPane={MAP_PANES.FLOAT_PANE} />
            ))}
        </>
    );
}
