import { Form } from "react-final-form";
import { Field } from "react-final-form";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import * as FormUtils from "common/FormUtils";
import { usePortalModal } from "common/effects";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import SelectField from "components/FormFields/SelectField";
import CheckboxField from "components/FormFields/CheckboxField";
import { useRefData } from "common/hooks";

export default function ForestModal({ forests, forest, onSave, close }) {
    const refData = useRefData();

    return (
        <Form initialValues={forest} validate={validate(forests)} onSubmit={submit(onSave, close)}>
            {({ values, handleSubmit, submitting }) => {
                const forestAgeWarning = getForestAgeWarning(values);
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`${forest.isNew ? "Add" : "Edit"} forest`} close={close} submitting={submitting} allowOffline skinny fluid>
                            <ModalBody info="This will enable the indicative carbon stock estimation. Results will be shown in the Overview page.">
                                <Grid>
                                    <GridCell className="u-xl-width1of2">
                                        <Field name="id" type="hidden" component="input" />
                                        <Field name="forestType" label="Forest type" required placeholder="Select a forest type" options={refData.forestType} component={SelectField} />
                                    </GridCell>
                                    {values.forestType === "PinusRadiata" && (
                                        <GridCell className="u-xl-width1of2">
                                            <Field name="region" label="Region" required placeholder="Select a region" options={refData.forestRegion} disabled={!values.isNew} component={SelectField} />
                                        </GridCell>
                                    )}
                                </Grid>
                                <Grid>
                                    <GridCell className="u-xl-width1of1">
                                        <Field id="forestName" name="name" label="Name" required placeholder="Enter a name" component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-xl-width1of1">
                                        <Field name="isHarvested" label="Forest has been harvested in the last 10 years?" tip="Residual carbon stock values will be presented if the forest has been harvested within the last 10 years" type="checkbox" component={CheckboxField} />
                                    </GridCell>
                                    <GridCell className="u-xl-width1of2">
                                        <Field name="percentage" label="Percentage" required uom="%" tip="Percentage relative to this tree block" type="number" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-xl-width1of2">
                                        <Field name="forestAge" label={values.isHarvested ? "Forest age at harvest (years)" : "Current forest age (years)"} required tip="This will determine the initial carbon stocks" warning={forestAgeWarning} type="number" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                    </GridCell>
                                    {values.isHarvested && (
                                        <GridCell className="u-xl-width1of2">
                                            <Field name="numberOfYearsSinceHarvested" label="Years since harvest" required type="number" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                        </GridCell>
                                    )}
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancelForest" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submitForest" submit disabled={submitting}>
                                        Done
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useForestModal(forests, onSave) {
    const { openModal, getModal } = usePortalModal();

    const openForestModal = (forest) => {
        const modalProps = {
            forests,
            forest: forest || {
                id: uuidv4(),
                isNew: true,
            },
            onSave,
        };
        openModal("ForestModal", modalProps);
    };

    const forestModal = getModal("ForestModal", ForestModal);

    return [openForestModal, forestModal];
}

const getForestAgeWarning = (forest) => {
    const treeDateOfBirth = new Date().getFullYear() - (Number(forest.forestAge || 0) + Number(forest.numberOfYearsSinceHarvested || 0));
    if (treeDateOfBirth < 1989) {
        return `The lookup tables are for post 1989 forests. The forest entered was planted in ${treeDateOfBirth}.`;
    }
};

const validate = (forests) => (forest) => {
    const validation = {};

    validation.forestType = FormUtils.validators.required(forest.forestType);

    validation.name = FormUtils.validators.required(forest.name);
    validation.name = validation.name || FormUtils.validators.maxLength(50)(forest.name);
    if (!validation.name && forest.name && forests.filter((f) => f.id !== forest.id && f.name).find((f) => f.name.toLowerCase() === forest.name.toLowerCase())) {
        validation.name = "Name must be unique";
    }

    if (forest.forestType === "PinusRadiata") {
        validation.region = FormUtils.validators.required(forest.region);
    }

    validation.percentage = FormUtils.validators.required(forest.percentage);
    validation.percentage = validation.percentage || FormUtils.validators.range(1, 100)(forest.percentage);

    if (!validation.percentage && !forest.isHarvested) {
        const percentageSum = forests.filter((f) => f.id !== forest.id && !f.isHarvested).reduce((t, f) => (t += Number(f.percentage)), 0);
        if (percentageSum + Number(forest.percentage) > 100) {
            validation.percentage = `Sum of forest percentages exceeds 100%. Currently ${utils.round(percentageSum + Number(forest.percentage), 0)}%.`;
        }
    }

    validation.forestAge = FormUtils.validators.required(forest.forestAge);
    validation.forestAge = validation.forestAge || FormUtils.validators.range(1, 100)(forest.forestAge);

    if (forest.isHarvested) {
        validation.numberOfYearsSinceHarvested = FormUtils.validators.required(forest.numberOfYearsSinceHarvested);
        validation.numberOfYearsSinceHarvested = validation.numberOfYearsSinceHarvested || FormUtils.validators.range(0, 10)(forest.numberOfYearsSinceHarvested);
    }

    return validation;
};

const submit = (onSave, closeModal) => (forest) => {
    onSave(forest);
    closeModal();
};
