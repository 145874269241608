import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import { SelectField } from "components/FormFields";
import ActionLink from "components/ActionLink";
import MonthSelector from "./MonthSelector";
import Button from "components/Button/Button";
import { useRefData } from "common/hooks";
import { useOutdoorPigLiquidApplicationModal } from "./OutdoorPigLiquidApplicationModal";

export default function OutdoorPigLiquidApplications({ farm, analysis, title, form, fieldName, applications, sources, blocks, villagesHoldingPondManagement, barnsHoldingPondManagement }) {
    const refData = useRefData();
    const [outdoorPigLiquidApplicationModal, openOutdoorPigLiquidApplicationModal] = useOutdoorPigLiquidApplicationModal();

    const monthsAreRequired = villagesHoldingPondManagement === "SprayInfrequently";

    const hasApplications = applications && applications.length > 0;
    const selectedBlockIds = applications.reduce((results, application) => results.concat(application.blockIds), []);
    const unselectedBlocks = blocks.filter((b) => !selectedBlockIds.includes(b.id));
    const allBlocksAlreadyHaveApplications = unselectedBlocks.length === 0;
    const addApplicationLink = !allBlocksAlreadyHaveApplications && (
        <ActionLink id={`add-${fieldName}-link`} className="IconLink--arrow-plus u-link u-textWhite" onClick={addApplication(form, fieldName, unselectedBlocks, monthsAreRequired, openOutdoorPigLiquidApplicationModal)}>
            Add application
        </ActionLink>
    );

    const formState = form.getState();
    const isRequired = applications.length === 0 && formState && (formState.initialValues[fieldName].length > 0 || formState.submitFailed);

    const showMonthColumn = monthsAreRequired || doSelectedBlocksRequireMonths(blocks, selectedBlockIds);
    const showAreaColumn = doSelectedBlocksRequireArea(blocks, selectedBlockIds);

    const holdingPondManagementOptions = (refData.holdingPondManagement || []).filter((o) => o.value !== "Exported");

    return (
        <>
            <h3>Liquid effluent</h3>
            <p>The following outdoor pig housing structures have liquid effluent that must be spread on blocks</p>
            <ul className="disc">
                {sources.map((src) => (
                    <li key={src.type}>
                        <Link id={`${fieldName}-${src.type}-link`} to={src.url} className="">
                            {src.name}
                        </Link>
                    </li>
                ))}
            </ul>
            <Grid>
                {villagesHoldingPondManagement && sources.some((s) => s.type === "OutdoorPigFarrowingVillages") && (
                    <GridCell className="u-width1of3">
                        <Field name="villagesHoldingPondManagement" label="Liquid effluent from farrowing villages" options={holdingPondManagementOptions} component={SelectField} />
                    </GridCell>
                )}
                {barnsHoldingPondManagement && sources.some((s) => s.type === "OutdoorPigBarns") && (
                    <GridCell className="u-width1of3">
                        <Field name="barnsHoldingPondManagement" label="Liquid effluent from barns/sheds" options={holdingPondManagementOptions} component={SelectField} />
                    </GridCell>
                )}
            </Grid>
            <Panel title={`${title} applications`} actions={addApplicationLink} midBlue className="u-mt-lg">
                <PanelBody>
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="25">Blocks</th>
                                    {showMonthColumn && <th>Months</th>}
                                    <th>Application depth</th>
                                    {showAreaColumn && <th>% of block area</th>}
                                    {hasApplications && <th className="th--shrink"></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {hasApplications && (
                                    <FieldArray name={fieldName}>
                                        {({ fields }) => {
                                            return fields.map((field, index) => {
                                                const application = fields.value[index];
                                                const applicationDepth = utils.valueToText(refData.effluentAppRates, application.applicationDepth);
                                                const selectedBlocks = blocks.filter((b) => application.blockIds.includes(b.id));
                                                const selectedBlockNames = selectedBlocks.map((block) => block.name);

                                                const monthsRequiredForTheseBlockTypes = doSelectedBlocksRequireMonths(
                                                    blocks,
                                                    selectedBlocks.map((b) => b.id)
                                                );
                                                const showArea = doSelectedBlocksRequireArea(
                                                    blocks,
                                                    selectedBlocks.map((b) => b.id)
                                                );

                                                return (
                                                    <tr key={`${field}.${index}`} valign="top">
                                                        <td>
                                                            {selectedBlockNames.map((blockName) => (
                                                                <h5 key={blockName}>{blockName}</h5>
                                                            ))}
                                                        </td>
                                                        {showMonthColumn && (
                                                            <td>
                                                                {(monthsAreRequired || monthsRequiredForTheseBlockTypes) && <MonthSelector form={form} monthsFieldName={`${field}.months`} optional={!monthsRequiredForTheseBlockTypes} />}
                                                                {!monthsAreRequired && !monthsRequiredForTheseBlockTypes && "-"}
                                                            </td>
                                                        )}
                                                        <td className="u-pt-md">{applicationDepth}</td>
                                                        {showAreaColumn && (
                                                            <td className="u-textCenter u-pt-md">
                                                                {showArea && `${application.areaEffluent}%`}
                                                                {!showArea && "-"}
                                                            </td>
                                                        )}
                                                        <td className="u-textCenter">
                                                            <div className="u-flex">
                                                                <ActionLink id={`remove-${fieldName}-${application.id}`} onClick={removeApplication(form, fieldName, index)} className="IconLink--cross-circle"></ActionLink>
                                                                <ActionLink id={`edit-${fieldName}-${application.id}`} className="IconLink--edit u-ml-sm" onClick={editApplication(form, fieldName, application, index, selectedBlocks, unselectedBlocks, monthsAreRequired, openOutdoorPigLiquidApplicationModal)}></ActionLink>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            });
                                        }}
                                    </FieldArray>
                                )}
                                {!hasApplications && (
                                    <tr>
                                        <td colSpan="3">
                                            <div className="Tile-body">
                                                {unselectedBlocks.length === 0 && (
                                                    <div className="Tile-body-message">
                                                        <h3>One or more effluent application blocks are required</h3>
                                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/blocks/new`} id="create-block" className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                            Create block
                                                        </Link>
                                                    </div>
                                                )}
                                                {unselectedBlocks.length > 0 && (
                                                    <div className="Tile-body-message">
                                                        <Button id={`add-${fieldName}-button`} onClick={addApplication(form, fieldName, unselectedBlocks, monthsAreRequired, openOutdoorPigLiquidApplicationModal)} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                            Add application
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {isRequired && (
                            <div className="Field has-error u-mt-0">
                                <small className="Field-error">Required</small>
                            </div>
                        )}
                    </div>
                </PanelBody>
            </Panel>
            {outdoorPigLiquidApplicationModal}
        </>
    )
}

const addApplication = (form, fieldName, unselectedBlocks, monthsAreRequired, openOutdoorPigLiquidApplicationModal) => () => {
    const viewModel = {
        id: uuidv4(),
        blockIds: [],
        months: [],
        areaEffluent: 100,
        availableBlocks: unselectedBlocks,
        monthsAreRequired,
    };
    openOutdoorPigLiquidApplicationModal(viewModel, applicationAdded(form, fieldName, viewModel.availableBlocks, monthsAreRequired));
}

const applicationAdded = (form, fieldName, blocks, monthsAreRequired) => (application) => {
    const cropBlockIds = application.blockIds.filter((blockId) => (blocks || []).some((b) => b.id === blockId && ["ProductiveCrop", "FodderCrop"].includes(b.type)));
    const grazingBlockIds = application.blockIds.filter((blockId) => (blocks || []).some((b) => b.id === blockId && ["ProductivePasture"].includes(b.type) && b.runoffCharacteristics));
    const otherBlockIds = application.blockIds.filter((blockId) => (blocks || []).some((b) => b.id === blockId && !["ProductiveCrop", "FodderCrop"].includes(b.type) && !b.runoffCharacteristics));

    if (cropBlockIds.length > 0) {
        const cropApplication = { ...application, id: uuidv4(), blockIds: cropBlockIds };
        delete cropApplication.areaEffluent;
        form.mutators.push(fieldName, cropApplication);
    }

    if (grazingBlockIds.length > 0) {
        const grazingApplication = { ...application, id: uuidv4(), blockIds: grazingBlockIds };
        grazingApplication.months = monthsAreRequired ? grazingApplication.months : [];
        form.mutators.push(fieldName, grazingApplication);
    }

    if (otherBlockIds.length > 0) {
        const otherApplication = { ...application, id: uuidv4(), blockIds: otherBlockIds };
        delete otherApplication.areaEffluent;
        otherApplication.months = monthsAreRequired ? otherApplication.months : [];
        form.mutators.push(fieldName, otherApplication);
    }

    form.blur(fieldName);
    form.change("isModified", true);
}

const editApplication = (form, fieldName, application, applicationIndex, selectedBlocks, unselectedBlocks, monthsAreRequired, openOutdoorPigLiquidApplicationModal) => (e) => {
    const viewModel = {
        ...application,
        areaEffluent: application.areaEffluent || 100,
        availableBlocks: selectedBlocks.concat(unselectedBlocks),
        monthsAreRequired,
    };
    openOutdoorPigLiquidApplicationModal(viewModel, applicationEdited(form, fieldName, applicationIndex, viewModel.availableBlocks, monthsAreRequired));
}

const applicationEdited = (form, fieldName, applicationIndex, blocks, monthsAreRequired) => (application) => {
    const cropBlockIds = application.blockIds.filter((blockId) => (blocks || []).some((b) => b.id === blockId && ["ProductiveCrop", "FodderCrop"].includes(b.type)));
    const grazingBlockIds = application.blockIds.filter((blockId) => (blocks || []).some((b) => b.id === blockId && ["ProductivePasture"].includes(b.type) && b.runoffCharacteristics));
    const otherBlockIds = application.blockIds.filter((blockId) => (blocks || []).some((b) => b.id === blockId && !["ProductiveCrop", "FodderCrop"].includes(b.type) && !b.runoffCharacteristics));

    if (cropBlockIds.length > 0) {
        const cropApplication = { ...application, blockIds: cropBlockIds };
        delete cropApplication.areaEffluent;
        form.mutators.update(fieldName, applicationIndex, cropApplication);
    }

    if (grazingBlockIds.length > 0) {
        const grazingApplication = { ...application, blockIds: grazingBlockIds };
        grazingApplication.months = monthsAreRequired ? grazingApplication.months : [];
        if (cropBlockIds.length > 0) {
            grazingApplication.id = uuidv4();
            form.mutators.push(fieldName, grazingApplication);
        } else {
            form.mutators.update(fieldName, applicationIndex, grazingApplication);
        }
    }

    if (otherBlockIds.length > 0) {
        const otherApplication = { ...application, blockIds: otherBlockIds };
        delete otherApplication.areaEffluent;
        otherApplication.months = monthsAreRequired ? otherApplication.months : [];
        if (cropBlockIds.length > 0 || grazingBlockIds.length > 0) {
            otherApplication.id = uuidv4();
            form.mutators.push(fieldName, otherApplication);
        } else {
            form.mutators.update(fieldName, applicationIndex, otherApplication);
        }
    }

    form.blur(fieldName);
    form.change("isModified", true);
}

const removeApplication = (form, fieldName, index) => (e) => {
    form.mutators.remove(fieldName, index);
    form.blur(fieldName);
    form.change("isModified", true);
}

const doSelectedBlocksRequireMonths = (blocks, selectedBlockIds) => {
    const blockIdsRequiringMonths = (blocks || []).filter((b) => ["ProductiveCrop", "FodderCrop"].includes(b.type)).map((b) => b.id);
    return (selectedBlockIds || []).some((blockId) => blockIdsRequiringMonths.some((id) => id === blockId));
}

const doSelectedBlocksRequireArea = (blocks, selectedBlockIds) => {
    const blockIdsRequiringArea = (blocks || []).filter((b) => ["ProductivePasture"].includes(b.type) && b.runoffCharacteristics).map((b) => b.id);
    return (selectedBlockIds || []).some((blockId) => blockIdsRequiringArea.some((id) => id === blockId));
}