import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import { Grid, GridCell } from "components/Grid";
import InputPack from "components/InputPack";

export default function OutdoorPigNumbersBoars() {
    return (
        <Grid title="Boars">
            <GridCell className="u-width1of4">
                <Field name="numbers.boars.numbersOnHand" label="Number of boars on hand" placeholder="0" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
            </GridCell>
            <GridCell className="u-width1of4">
                <Field name="numbers.boars.replacementRate" label="Replacement rate" uom="%" placeholder="0" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
            </GridCell>
        </Grid>
    );
};

export const validateBoars = (boars) => {
    const errors = {};

    errors.numbersOnHand = FormUtils.validators.required(boars.numbersOnHand);
    errors.numbersOnHand = errors.numbersOnHand || FormUtils.validators.max(99999)(boars.numbersOnHand);

    errors.replacementRate = FormUtils.validators.required(boars.replacementRate);
    errors.replacementRate = errors.replacementRate || FormUtils.validators.range(30, 100)(boars.replacementRate);

    return errors;
}
