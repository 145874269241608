import { FieldArray } from "react-final-form-arrays";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import Alert from "components/Alert";
import { Grid, GridCell } from "components/Grid";
import OutdoorPigBlockAreaAllocationFieldArray from "./OutdoorPigBlockAreaAllocationFieldArray";

export default function OutdoorPigBlockAreaAllocation({ farm, analysis, form, outdoorPigs }) {
    const blockIcon = utils.getBlockIcon("OutdoorPigs");
    const width = outdoorPigs.areaAllocations.length > 2 ? "u-md-width1of2 u-xl-width1of3" : `u-width1of${outdoorPigs.areaAllocations.length}`;
    const hasOutdoorPigBlocks = outdoorPigs.areaAllocations.length > 0;

    const fieldState = form.getFieldState("areaAllocations");

    return (
        <Panel title="Block Allocations" midBlue notCollapsible>
            <Alert type="info" text="For each outdoor pig block, enter the percentange of block area occupied by each class, and the area taken by lanes and other areas. The total must equal 100%." />
            <PanelBody>
                {!hasOutdoorPigBlocks && (
                    <div className="Table u-mt-md">
                        <div className="Tile-body">
                            <div className="Tile-body-message">
                                <h3>One or more outdoor pigs blocks are required</h3>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/blocks/new`} id="create-outdoor-pig-block" className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                    Create outdoor pigs block
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                {hasOutdoorPigBlocks && (
                    <Grid>
                        <FieldArray name="areaAllocations" validate={validate}>
                            {({ fields }) => {
                                return fields.map((field, index) => {
                                    const areaAllocation = fields.value[index];
                                    return (
                                        <GridCell key={index} className={width}>
                                            <div className="FarmTable_wrapper u-mt-md">
                                                <ul className="DataWidthTable FarmTable">
                                                    <li className="FarmTable-title">
                                                        <div className="FarmTitle">
                                                            <img className="FarmTitle-icon" src={blockIcon} alt="Block" />
                                                            <div className="FarmTitle-heading">
                                                                <span className="FarmTitle-name" title={areaAllocation.blockName}>
                                                                    {areaAllocation.blockName}
                                                                </span>
                                                                <div className="FarmTitle-sub">Outdoor pigs - {areaAllocation.blockArea}ha</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="FarmTable-supplementary">
                                                    <OutdoorPigBlockAreaAllocationFieldArray form={form} outdoorPigs={outdoorPigs} areaAllocationIndex={index} />
                                                </div>
                                            </div>
                                        </GridCell>
                                    );
                                });
                            }}
                        </FieldArray>
                    </Grid>
                )}
                {hasOutdoorPigBlocks && fieldState && fieldState.error && fieldState.error.areaAllocations && <div className="Field-error u-block u-mt-md">{fieldState.error.areaAllocations}</div>}
            </PanelBody>
        </Panel>
    );
}

const validate = (areaAllocations, outdoorPigs) => {
    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const showGrowersFinishersFields = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    const totalAllocations = (areaAllocations || []).reduce((results, areaAllocation) => {
        (areaAllocation.allocations || []).forEach((allocation) => {
            if (allocation.stockClassGroup === "Growers" && !showGrowersFinishersFields) return;

            if (allocation.stockClassGroup !== undefined && allocation.stockClassGroup !== "Growers" && outdoorPigs.growOutUnitOnly) return;

            const result = results.find((r) => r.stockClassGroup === allocation.stockClassGroup);
            if (result) result.percentage += Number(allocation.percentage) || 0;
            else results.push({ stockClassGroup: allocation.stockClassGroup, percentage: allocation.percentage || 0 });
        });

        return results;
    }, []);

    const error = {};
    error.areaAllocations = totalAllocations.every((allocation) => allocation.percentage > 0) ? undefined : "All stock classes must be allocated to at least one block";
    return error;
};
