import { Range } from "rc-slider";
import * as domain from "common/domain";
import { useRefData } from "common/hooks";
import * as icons from "common/icons";
import * as utils from "common/utils";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";
import SelectPack from "components/SelectPack";
import NumericInputPack from "components/NumericInputPack";

export default function BlockDetails({ block, validation, onChange, changeAreaPercentage, includeArea, deleteArea }) {
    const refData = useRefData();

    const { cropBlock = { cultivatedAreaPercent: 100, headlandAreaPercent: 0, otherAreaPercentage: 0 } } = block;
    block.cropBlock = cropBlock;
    const { preCropManagement = [] } = refData;
    const monthResown = block.monthResown === "Undefined" ? undefined : block.monthResown;
    const handleStyle = { backgroundColor: "#509bc2", borderColor: "#509bc2", borderWidth: 4, height: 28, width: 28, marginTop: -12 };
    const handleStyles = [];
    const rangeVals = [];
    const { crops = [] } = block;
    if ((cropBlock.cultivatedAreaPercent || 100) !== 100) {
        rangeVals.push(cropBlock.cultivatedAreaPercent);
        handleStyles.push(handleStyle);

        if (cropBlock.headlandAreaPercent > 0 && cropBlock.otherAreaPercentage > 0) {
            rangeVals.push(cropBlock.cultivatedAreaPercent + cropBlock.headlandAreaPercent);
            handleStyles.push(handleStyle);
        }
    }

    const showYearsInPasture = cropBlock.preCrop !== "OutdoorPigs";

    return (
        <div>
            <h3 className="u-mt-0">Block land use</h3>
            <h4>Enter percentage of land use within the block in each of these categories</h4>
            <div className="SoilRange">
                <div className="Grid Grid--withGutter">
                    <div className="Grid-cell u-md-width1of3">
                        <div className={`SoilRange-item SoilRange-item--${utils.colourNameIndex[0]}`}>
                            <div className="SoilRange-name">Cultivated area</div>
                            <div className="SoilRange-value" id="cult_amount">
                                {cropBlock.cultivatedAreaPercent || 100}
                                <span>%</span>
                            </div>
                        </div>
                    </div>
                    {cropBlock.headlandAreaPercent > 0 ? (
                        <div className="Grid-cell u-md-width1of3">
                            <div className={`SoilRange-item SoilRange-item--${utils.colourNameIndex[1]}`}>
                                <div className="SoilRange-name">Headlands and tracks %</div>
                                <div className="SoilRange-value" id="headlands_amount">
                                    {cropBlock.headlandAreaPercent}
                                    <span>%</span>
                                </div>
                                <div>
                                    <ActionLink id="headlands_del" onClick={() => deleteArea("headlands")}>
                                        Delete
                                    </ActionLink>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="Grid-cell u-md-width1of3">
                            <div className="SoilRange-item SoilRange-item--add">
                                <img className="u-p-5" alt="Add Soil" height="45" src={icons.soil} title="Add headlands" width="45" />
                                <div>Headlands and tracks 0%</div>
                                <div>
                                    <ActionLink id="headlands_add" onClick={() => includeArea("headlands")}>
                                        Add
                                    </ActionLink>
                                </div>
                            </div>
                        </div>
                    )}
                    {cropBlock.otherAreaPercentage > 0 ? (
                        <div className="Grid-cell u-md-width1of3">
                            <div className={`SoilRange-item SoilRange-item--${utils.colourNameIndex[2]}`}>
                                <div className="SoilRange-name">Other areas</div>
                                <div className="SoilRange-value" id="other_amount">
                                    {cropBlock.otherAreaPercentage}
                                    <span>%</span>
                                </div>
                                <div>
                                    <ActionLink id="other_del" onClick={() => deleteArea("other")}>
                                        Delete
                                    </ActionLink>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="Grid-cell u-md-width1of3">
                            <div className="SoilRange-item SoilRange-item--add">
                                <img className="u-p-5" alt="Add Soil" height="45" src={icons.soil} title="Add headlands" width="45" />
                                <div>Other areas 0%</div>
                                <div>
                                    <ActionLink id="other_add" onClick={() => includeArea("other")}>
                                        Add
                                    </ActionLink>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {cropBlock.cultivatedAreaPercent !== 100 && (
                <div className="SoilRange-slider">
                    <Range step={1} onChange={changeAreaPercentage} allowCross={true} pushable={1} value={rangeVals} handleStyle={handleStyles} />
                </div>
            )}
            <div className="Field-group">
                <SelectPack id="monthResown" dataWidth="75" label="Crop rotation final month" meta={{ nrf: true }} onChange={(e) => onChange(e, { type: "block", key: "monthResown" })} value={monthResown} val={validation.monthResown} disabled={crops.length > 0} requiredLabel={true} tip={"This defines the end month for the year. To capture the full crop cycle this final month would typically be the month that the crop of interest is harvested or the month defoliation ends; however, it is important that any animals on crops line up with the animal numbers."}>
                    <option value="" disabled={true}>
                        Select the final month of this crop rotation
                    </option>
                    {domain.calendarYear.map((m, i) => (
                        <option key={i} value={m}>
                            {m}
                        </option>
                    ))}
                </SelectPack>
            </div>
            {crops.length > 0 && <Alert type="info" text="The final month cannot be changed while you have crops allocated to your block." />}

            <h3 className="u-mt-xl">Block history</h3>
            <div className="Field-group">
                <SelectPack id="preCrop" label="Prior land use" dataWidth="50" meta={{ nrf: true }} onChange={(e) => onChange(e, { type: "cropBlock", key: "preCrop" })} value={cropBlock.preCrop} val={validation.preCrop} tip="Select the crop grown prior to the start of Year 1 (previous year or crop rotation)" requiredLabel={true}>
                    <option value="" disabled={true}>
                        Select prior land use
                    </option>
                    {utils.mapRefDataItems(preCropManagement)}
                </SelectPack>
                {showYearsInPasture && <>
                    <NumericInputPack id="yearsInPasture" dataWidth="50" label="Years in pasture" requiredLabel={true} val={validation.yearsInPasture} onChange={(e) => onChange(e, { type: "cropBlock", key: "yearsInPasture" })} decimalPlaces={0} value={cropBlock.yearsInPasture} />
                </>}
            </div>
            {showYearsInPasture && <>
                <Alert type="info" html={"Enter the total number of years in pasture 3-12 years prior to the assessment year. Hence values will range from 0 to 10 years.<b>0:</b> indicates a continuous cropping regime, <b>10:</b> indicates perennial pasture with no cropping during the period.</li></ul><br/><b>Note: </b>The order of the rotation is not important. Thus 5 years in pasture + 5 years under cropping, or 5 years under cropping + 5 years in pasture, or 2 years in pasture, 3 years cropping, 3 years in pasture and 2 years cropping all have a value of 5. A year in pasture is defined as at least 8 months of the year in continuous pasture."} />
            </>}
        </div>
    )
}
