import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import * as utils from "common/utils";

class PaymentHistory extends React.Component {
    render() {
        const { paymentHistory = [] } = this.props;
        return (
            <Tile title="Subscription transactions" secondary collapsible>
                <TileBody>
                    <div className="Table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Method</th>
                                    <th>Invoice Num.</th>
                                    <th>Transaction ID</th>
                                    <th>Status</th>
                                    <th>Paid by</th>
                                    <th>Farm name</th>
                                    <th>Amount</th>
                                    <th>GST</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentHistory.map((txn) => {
                                    return (
                                        <tr key={txn.id} className="hover">
                                            <td>{utils.dateOnly(txn.transactionTime)}</td>
                                            <td>{txn.paymentMethod}</td>
                                            <td>
                                                <Link to={`/invoice/${txn.id}`} target="_blank">
                                                    {txn.invoiceNumber}
                                                </Link>
                                            </td>
                                            <td>{txn.paystationId}</td>
                                            <td>{txn.status}</td>
                                            <td>{txn.userFullName}</td>
                                            <td>
                                                {txn.farms.length > 0 &&
                                                    txn.farms.map((farm, index) => {
                                                        const isLastInTheList = index === txn.farms.length - 1;
                                                        return (
                                                            <span key={farm.id}>
                                                                <Link to={`/app/farm/${farm.id}`}>
                                                                    {farm.name}
                                                                    {isLastInTheList ? "" : ", "}
                                                                </Link>
                                                            </span>
                                                        );
                                                    })}
                                                {txn.farms.length === 0 && <>-</>}
                                            </td>
                                            <td>${txn.amountExclGst.toFixed(2)}</td>
                                            <td>${txn.gst.toFixed(2)}</td>
                                            <td>${txn.amountInclGst.toFixed(2)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </TileBody>
            </Tile>
        );
    }
}

const _mapStateToProps = (state, ownProps) => {
    return {
        online: state.app.online,
        isLoading: state.app.isLoading,
    };
};

export default connect(_mapStateToProps)(PaymentHistory);
