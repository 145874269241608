import React from "react";
import numeral from "numeral";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import Alert from "components/Alert";
import { blockIrrigationViewModel } from "common/viewModels";
import { Range, Handle } from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";

export default function BlockAreas({ analysis, existingSystems = [], thisSystem, onChange, isInvalid }) {
    const [viewModel, setViewModel] = React.useState([]);

    React.useEffect(() => {
        // Sample view model shape...
        /*
            const viewModel = [
                { id: '123', name: 'Cut and carry block', type: 'ProductivePasture', area: 34.5, irrigationSystems: [{ id: 'abc', name: 'Pivot 1, With a much longer name', percentage: 15, 1eadOnly: true, isDrawn: true }, { id: 'def', name: 'Pivot 2', percentage: 12 }] },
                { id: '456', name: 'Grazing block', type: 'ProductivePasture', area: 12.6, irrigationSystems: [{ id: 'abc', name: 'Pivot 1', percentage: 88, isReadOnly: true, isDrawn: true }, { id: 'ghi', name: 'Pivot 3', percentage: 5, isReadOnly: true, isDrawn: true }] },
                { id: '789', name: 'Kiwifruit block', type: 'ProductiveFruit', area: 6.1, irrigationSystems: [{ id: 'def', name: 'Pivot 2', percentage: 73 }] },
                { id: '101', name: 'Fodder crop block', type: 'FodderCrop', area: 10, irrigationSystems: [{ id: 'abc', name: 'Pivot 1', percentage: 100, isReadOnly: true, isDrawn: false }] }
            ];
        */
        // Map the api structure to the view model
        const systems = existingSystems.filter((system) => system.id !== thisSystem.id);
        systems.push(thisSystem);

        const viewModel = blockIrrigationViewModel(analysis, systems);
        setViewModel(viewModel);
    }, [analysis, existingSystems, thisSystem]);

    const onSliderChange = (blockId) => (sliderValues) => {
        const firstSystemIndex = 1;
        const secondSystemIndex = 2;

        const updatedBlocks = viewModel.map((b) => {
            if (b.id !== blockId) return b;

            if (b.irrigationSystems.every((sys) => sys.isReadOnly)) return b;

            const blocksFirstSystemIsReadOnly = b.irrigationSystems[0].isReadOnly;
            if (blocksFirstSystemIsReadOnly) {
                const originalValues = getRangeValues(b);

                // First system is read only so value must not change.
                sliderValues[firstSystemIndex] = originalValues[firstSystemIndex];

                const blockHasMultipleSystems = b.irrigationSystems.length > 1;
                if (blockHasMultipleSystems) {
                    const minValueForSecondSystem = originalValues[firstSystemIndex] + 10;
                    if (sliderValues[secondSystemIndex] <= minValueForSecondSystem) {
                        sliderValues[secondSystemIndex] = minValueForSecondSystem;
                    }
                }
            }

            return {
                ...b,
                irrigationSystems: b.irrigationSystems.map((sys, index) => {
                    return {
                        ...sys,
                        percentage: utils.round((sliderValues[index + 1] - sliderValues[index]) / 2, 1),
                    };
                }),
            };
        });

        setViewModel(updatedBlocks);
    };

    const afterSliderChange = () => {
        const updatedBlocks = viewModel.map((block) => {
            const percentageDrawn = block.irrigationSystems.reduce((sum, sys) => (sum += sys.isDrawn ? sys.percentage : 0), 0);
            const percentageRemaining = utils.round(100 - percentageDrawn, 1);
            block.irrigationSystems = block.irrigationSystems.map((sys) => {
                return {
                    ...sys,
                    percentage: sys.isReadOnly ? sys.percentage : utils.round((sys.percentage / percentageRemaining) * 100, 1),
                };
            });
            return block;
        });

        const isNew = !existingSystems.some((sys) => sys.id === thisSystem.id);
        const systems = isNew ? existingSystems.concat(thisSystem) : existingSystems;

        const updatedSystems = systems.map((system) => {
            const nonDrawnArea = updatedBlocks.reduce((results, block) => {
                const irrigationSystem = block.irrigationSystems.find((sys) => sys.id === system.id && !sys.isDrawn);
                if (irrigationSystem) {
                    results.push({
                        blockId: block.id,
                        percentageOfNonDrawnArea: irrigationSystem.percentage,
                    });
                }
                return results;
            }, []);

            if (nonDrawnArea.length > 0) {
                return {
                    ...system,
                    nonDrawnArea,
                };
            }

            return system;
        });

        onChange(updatedSystems);
    };

    const thisSystemDoesNotHaveAnyBlocks = !viewModel.some((vm) => vm.irrigationSystems.some((sys) => sys.id === thisSystem.id));
    if (thisSystemDoesNotHaveAnyBlocks) return null;

    // Do not display blocks that don't include the current system
    const blocksToDisplay = viewModel.filter((block) => block.irrigationSystems.some((sys) => sys.id === thisSystem.id));

    const fodderDetails = utils.getFodderBlockAreas(analysis.blocks);

    return (
        <Panel title="Blocks irrigated by this system" isInvalid={isInvalid} midBlue>
            <Alert type="info" text="The following is a list of blocks irrigated by this irrigation system. For pasture blocks that have fodder crops rotating through them, the area irrigated has been adjusted to remove the fodder crop area. The area removed is based on the total fodder area divided by the total pastoral area for the farm." />
            <PanelBody>
                <div className="Table">
                    <table>
                        <thead>
                            <tr>
                                <th>Block</th>
                                <th data-width="5">Irrigated area</th>
                                <th data-width="5">Un-irrigated area</th>
                                <th data-width="55">Irrigation systems</th>
                            </tr>
                        </thead>
                        <tbody>
                            {blocksToDisplay.map((block) => {
                                const area = fodderDetails.pastureBlockIds.has(block.id) ? block.area - block.area * fodderDetails.ratio : block.area;

                                let totalIrrigatedPercentage = utils.round(
                                    block.irrigationSystems.reduce((sum, sys) => (sum += sys.percentage), 0),
                                    1
                                );
                                let totalIrrigatedArea = utils.round(area * (totalIrrigatedPercentage / 100), 1);

                                const totalUnIrrigatedPercentage = utils.round(100 - totalIrrigatedPercentage, 1);
                                const totalUnIrrigatedArea = utils.round(area - totalIrrigatedArea, 1);

                                let runningTotalIrrigatedArea = 0;

                                return (
                                    <tr key={block.id}>
                                        <td valign="top">
                                            {block.name} - {block.area} ha
                                        </td>
                                        <td valign="top" className="u-textCenter">
                                            <div className="u-textSecondary u-mb-sm u-textBold" style={{ fontSize: "1.5rem" }}>
                                                <span id={`block_irrigated_percentage_${block.id}`}>{totalIrrigatedPercentage}</span>
                                                <small>%</small>
                                            </div>
                                            <div>
                                                <span id={`block_irrigated_area_${block.id}`}>{numeral(totalIrrigatedArea).format("0,0.0")}</span> ha
                                            </div>
                                        </td>
                                        <td valign="top" className="u-textCenter">
                                            <div className="u-textMidGrey u-mb-sm u-textBold" style={{ fontSize: "1.5rem" }}>
                                                <span id={`block_unirrigated_percentage_${block.id}`}>{totalUnIrrigatedPercentage}</span>
                                                <small>%</small>
                                            </div>
                                            <div>
                                                <span id={`block_unirrigated_area_${block.id}`}>{numeral(totalUnIrrigatedArea).format("0,0.0")}</span> ha
                                            </div>
                                        </td>
                                        <td>
                                            <div className="u-flex u-flexJustifyBetween">
                                                {block.irrigationSystems.map((sys) => {
                                                    let irrigatedArea = utils.round(area * (sys.percentage / 100), 1);
                                                    runningTotalIrrigatedArea += irrigatedArea;
                                                    if (runningTotalIrrigatedArea > area) {
                                                        irrigatedArea = utils.round(irrigatedArea - (runningTotalIrrigatedArea - area), 1);
                                                    }
                                                    const colour = getRangeTrackColours(sys, thisSystem);
                                                    const width = block.irrigationSystems.length === 1 ? "100%" : "49%";
                                                    const validStyle = { border: "1px solid #bec8c2", borderRadius: "5px", padding: "3px 12px", width };
                                                    const invalidStyle = { boxShadow: "#ff4c4c 0 0 10px", backgroundColor: "#ffe5e5", borderRadius: "5px", padding: "3px 12px", width };
                                                    const isValid = sys.percentage >= 5;

                                                    return (
                                                        <div key={sys.id} className={`u-flex u-flexJustifyBetween SoilRange-item--${colour}`} style={isValid ? validStyle : invalidStyle}>
                                                            <span>
                                                                <div className="h5">{sys.id === thisSystem.id ? "This system" : sys.name}</div>
                                                                <div>
                                                                    <span id={`block_system_area_${block.id}_${sys.id}`}>{irrigatedArea}</span> ha
                                                                </div>
                                                            </span>
                                                            <span className="u-textRight">
                                                                <div className={`${sys.isDrawn ? "h3" : "h2"} u-textBold ${isValid ? "u-textSecondary" : "u-textError"}`}>
                                                                    <span id={`block_system_percentage_${block.id}_${sys.id}`}>{numeral(sys.percentage).format("0,0.0")}</span>
                                                                    <small>%</small>
                                                                    {sys.isDrawn && <sup>*</sup>}
                                                                </div>
                                                                {sys.isDrawn && (
                                                                    <div>
                                                                        <span>
                                                                            <sup>*</sup>
                                                                            <small>{sys.id === thisSystem.id ? "Use map to edit" : "Edit in other system"}</small>
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="" style={{ margin: "15px 20px 5px 20px" }}>
                                                <Range
                                                    step={10}
                                                    max={200}
                                                    pushable={10}
                                                    value={getRangeValues(block)}
                                                    trackStyle={getRangeTrackStyles(block, thisSystem)}
                                                    onChange={onSliderChange(block.id)}
                                                    onAfterChange={afterSliderChange}
                                                    handle={(handle) => {
                                                        const { value, dragging, index, ...restProps } = handle;
                                                        if (index === 0) {
                                                            // Range has a handle on the left of the first item but we don't want users to be able to
                                                            // slide that handle so hide it. We want the first range to always start at zero!
                                                            restProps.disabled = true;
                                                            restProps.style = {
                                                                display: "none",
                                                            };
                                                        } else {
                                                            const sys = block.irrigationSystems[index - 1];
                                                            if (sys.isReadOnly) {
                                                                restProps.disabled = true;
                                                                restProps.style = {
                                                                    display: "none",
                                                                };
                                                            } else if (sys.percentage === 0) {
                                                                restProps.style = {
                                                                    borderColor: "#973b36",
                                                                    boxShadow: "#ff4c4c 0 0 10px",
                                                                };
                                                            }
                                                            restProps.id = `handle_${block.id}_${sys.id}`;
                                                        }
                                                        return <Handle key={index} value={value} {...restProps} />;
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {blocksToDisplay.some((block) => block.irrigationSystems.some((sys) => sys.percentage < 5)) && <div className="Field-error u-block u-mt-0">Percentage must be at least 5%</div>}
                </div>
            </PanelBody>
        </Panel>
    );
};

const THIS_SYSTEM_COLOR = "#509bc2";
const OTHER_SYSTEM_COLOR_HEX_INDEX = 0;

const getRangeValues = (block) => {
    const values = block.irrigationSystems.reduce(
        (results, sys, index) => {
            const currentPercentage = sys.percentage * 2;
            let runningTotal = results[index];
            if (index === 0) {
                runningTotal = currentPercentage;
            } else {
                runningTotal = utils.round(runningTotal + currentPercentage, 1);
            }
            results.push(runningTotal);
            return results;
        },
        [0]
    );
    return values;
}

const getRangeTrackStyles = (block, thisSystem) => {
    const styles = block.irrigationSystems.map((sys) => {
        const isThisSystem = sys.id === thisSystem.id;
        return { backgroundColor: isThisSystem ? THIS_SYSTEM_COLOR : utils.colourHexIndex[OTHER_SYSTEM_COLOR_HEX_INDEX] };
    });
    return styles;
}

const getRangeTrackColours = (currentSystem, thisSystem) => {
    const isThisSystem = currentSystem.id === thisSystem.id;
    return isThisSystem ? THIS_SYSTEM_COLOR : utils.colourNameIndex[OTHER_SYSTEM_COLOR_HEX_INDEX];
}
