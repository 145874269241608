import { v4 as uuidv4 } from "uuid";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as utils from "common/utils";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SelectField from "components/FormFields/SelectField";
import TextField from "components/FormFields/TextField";
import { getAnalysisTypeOptions, getYearOptions, isYearEnd } from "../_utils";
import { ForMoreInfoZendeskLink } from "components/Help";
import { httpClient } from "common/httpClient";
import { useRefData, useModal, useNavigate } from "common/hooks";
import { useCreateAnalysisAsync, useDeletedAnalyses } from "containers/hooks";

export default function RestoreAnalysisModal({ farm, close }) {
    const refData = useRefData();
    const { isLoading, data: deletedAnalysisOptions } = useRestorableAnalysesOptions(farm.id);
    const createAnalysisAsync = useCreateAnalysisAsync();
    const navigate = useNavigate();

    const analysisTypeOptions = getAnalysisTypeOptions(refData);
    const yearOptions = getYearOptions(farm, refData);
    const hasAnalysesToRestore = isLoading || deletedAnalysisOptions?.length > 0;

    const handleTypeChange = (form) => (newType) => {
        isYearEnd(newType) ? form.change("name", null) : form.change("year", null);
    };

    const validate = (formValues) => {
        const validation = {};
        validation.deletedAnalysisId = FormUtils.validators.required(formValues.deletedAnalysisId);
        validation.type = FormUtils.validators.required(formValues.type);
        if (isYearEnd(formValues.type)) {
            validation.year = FormUtils.validators.required(formValues.year);
        } else {
            validation.name = FormUtils.validators.required(formValues.name);
            validation.name = validation.name || FormUtils.validators.maxLength(50)(formValues.name);
        }
        return validation;
    };

    const submitAsync = async (viewModel) => {
        try {
            const analysis = await getAnalysis(viewModel.deletedAnalysisId);
            analysis.id = uuidv4();
            analysis.farmId = farm.id;
            analysis.type = viewModel.type;
            analysis.year = viewModel.year;
            analysis.name = viewModel.name;
            analysis.source = "Restored";
            analysis.sourceAnalysisId = viewModel.deletedAnalysisId;
            utils.setAnalysisType(farm, analysis);
            await createAnalysisAsync(analysis);
            navigate(`/app/farm/${farm.id}/analysis/${analysis.id}`);
            close();
        } catch (error) {
            return { [FORM_ERROR]: error };
        }
    };

    const info = (
        <span>
            Deleted year end analyses can be restored as a new analysis. Use the replace analysis function to replace an existing year end analysis.
            <ForMoreInfoZendeskLink url="https://support.overseer.org.nz/hc/en-us/articles/900000898643" />
        </span>
    );

    return (
        <Form initialValues={{}} validate={validate} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Restore deleted year end analysis" close={close} submitting={submitting} fluid>
                            <ModalBody loading={isLoading} info={info} error={submitError}>
                                {!hasAnalysesToRestore && (
                                    <div className="Tile-body-message">
                                        <h3 className="u-mt-md">This farm does not have any deleted year end analyses</h3>
                                    </div>
                                )}
                                {hasAnalysesToRestore && (
                                    <Grid>
                                        <GridCell>
                                            <Field name="deletedAnalysisId" label="Analysis to restore" placeholder="Select the version to restore" options={deletedAnalysisOptions} required component={SelectField} />
                                        </GridCell>
                                        {values.deletedAnalysisId && (
                                            <GridCell>
                                                <Field name="type" label="Restore as" placeholder="Select an analysis type" options={analysisTypeOptions} onChange={handleTypeChange(form)} required component={SelectField} />
                                            </GridCell>
                                        )}
                                        {values.type && (
                                            <GridCell>
                                                {isYearEnd(values.type) && <Field name="year" label="Year ending" placeholder="Select a year" options={yearOptions} required component={SelectField} />}
                                                {!isYearEnd(values.type) && <Field name="name" label="Analysis name" placeholder="Enter the name of the analysis" required component={TextField} />}
                                            </GridCell>
                                        )}
                                    </Grid>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting} disabled={!hasAnalysesToRestore}>
                                        Restore
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    )
}

function useRestorableAnalysesOptions(farmId) {
    const { isLoading, data: deletedAnalyses } = useDeletedAnalyses(farmId);

    return {
        isLoading,
        data: getAnalysisOptions(deletedAnalyses),
    };
}

function getAnalysisOptions(deletedAnalyses) {
    if (!deletedAnalyses) {
        return [];
    }

    const deletedAnalysesByYear = deletedAnalyses.reduce((results, analysis) => {
        let result = results.find((r) => r.year === analysis.year);
        if (result) {
            result.analyses.push(analysis);
        } else {
            results.push({
                year: analysis.year,
                analyses: [analysis],
            });
        }
        return results;
    }, []);
    deletedAnalysesByYear.sort((a, b) => b.year - a.year);

    const deletedAnalysisOptions = deletedAnalysesByYear.reduce((results, group, i) => {
        const opts = group.analyses.map((analysis, v) => {
            const version = group.analyses.length - v;
            const deletedBy = analysis.deletedBy ? analysis.deletedBy.userName : "Unknown";
            return {
                groupIndex: i,
                groupLabel: group.year,
                value: analysis.id,
                text: `${analysis.name} (v${version}) - deleted ${utils.dateLong(analysis.deleted)} by ${deletedBy}`,
            };
        });
        return [...results, ...opts];
    }, []);
    return deletedAnalysisOptions;
}

async function getAnalysis(analysisId) {
    try {
        return await httpClient.get(`v1/analysis-details/${analysisId}`);
    } catch (error) {
        if (error.status === 401 || error.status === 403) {
            throw new Error("You are not authorised to make this change.");
        } else {
            throw new Error(error.message);
        }
    }
}

export function useRestoreAnalysisModal(farm) {
    const [modal, openModal] = useModal(RestoreAnalysisModal);

    const openRestoreAnalysisModal = () => {
        const modalProps = {
            farm
        };
        openModal(modalProps);
    };

    return [modal, openRestoreAnalysisModal];
}
