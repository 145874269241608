import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useModal } from "common/hooks";
import * as domain from "common/domain";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import { CheckboxField, WholeNumberField } from "components/FormFields";
import BlockSelector from "components/new/BlockSelector";
import MonthSelector from "./MonthSelector";

function BlockLevelApplicationModal({ viewModel, onSubmit, close }) {
    const validate = (formValues) => {
        const validation = {};

        if (!formValues.blockIds || formValues.blockIds.length === 0) {
            validation.blockIds = "Required";
        }

        return validation;
    }

    const submitAsync = async (formValues) => {
        delete formValues.availableBlocks;
        if (onSubmit) {
            await onSubmit(formValues);
        }
        close();
    }

    return (
        <Form initialValues={viewModel} mutators={{ ...arrayMutators }} validate={validate} onSubmit={submitAsync}>
            {({ form, handleSubmit, submitting }) => {
                const monthsFieldName = `months`;
                const monthsFieldState = form.getFieldState(monthsFieldName);
                const monthsAreInvalid = monthsFieldState && monthsFieldState.touched && (monthsFieldState.error || []).length > 0;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="DCD application - by blocks" skinny fluid close={close}>
                            <ModalBody>
                                <Grid>
                                    <GridCell>
                                        <Field name="blockIds" component={BlockSelector} availableBlocks={viewModel.availableBlocks} />
                                    </GridCell>
                                    <GridCell>
                                        <h3>Application months</h3>
                                        <p className="u-mt-md">DCD can be applied up to 3 times per year. Typically, DCD is applied twice per year in April/May and July/August or March and April/May in Southland when grazing off in winter occurs."</p>
                                        <div className="FarmTable-supplementary u-mt-md">
                                            <div className="Table">
                                                <table>
                                                    <thead>
                                                        <tr className="Calendar-months1">
                                                            {domain.farmYear.map((month) => (
                                                                <th className="Calendar-month1 u-textCenter" key={month}>
                                                                    {month.substring(0, 3)}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className={monthsAreInvalid ? "In-error" : ""}>
                                                            <MonthSelector form={form} monthsFieldName={monthsFieldName} />
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </GridCell>
                                    <GridCell>
                                        <Field name="nFertiliserAppliedWithinSevenDays" label="N fertiliser applied in the same month as DCD is applied within 7 days of the DCD application" component={CheckboxField} />
                                    </GridCell>
                                    {viewModel.longTermFactor !== 100 && (
                                        <GridCell className="u-width1of5">
                                            <Field name="longTermFactor" label="DCD long term factor" uom="%" placeholder="0" component={WholeNumberField} />
                                        </GridCell>
                                    )}
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit waiting={submitting}>
                                        Done
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useBlockLevelApplicationModal() {
    const [modal, openModal] = useModal(BlockLevelApplicationModal);

    const open = (viewModel, onSubmit) => {
        const modalProps = {
            viewModel,
            onSubmit
        };
        openModal(modalProps);
    };

    return [modal, open];
}
