import { clearReportCache } from "../_actions";
import { useDispatch } from "react-redux";

export default function ReportCache() {
    const dispatch = useDispatch();

    async function clearCache() {
        await dispatch(clearReportCache());
    }

    return (
        <div>
            <input type="button" onClick={() => clearCache()} value="Clear Cache" className="Button  Button--primary" />
        </div>
    );
}
