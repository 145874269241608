import React from "react";
import { FieldArray } from "react-final-form-arrays";
import * as domain from "common/domain";

const toggleMonth = (form, monthsFieldName, selectedMonths, month) => (e) => {
    const index = selectedMonths.findIndex((m) => m === month);

    if (index >= 0) {
        form.mutators.remove(monthsFieldName, index);
    } else {
        form.mutators.push(monthsFieldName, month);
    }

    form.blur(monthsFieldName);
    form.change("isModified", true);
};

const validateMonths = (monthsFieldName, optional) => (months) => {
    const errors = [];

    if (!optional && (!months || months.length === 0)) errors.push({ [monthsFieldName]: "Required" });

    return errors;
};

const MonthsSelector = (props) => {
    const { form, monthsFieldName, optional } = props;

    const monthsFieldState = form.getFieldState(monthsFieldName);
    const isRequired = !optional && monthsFieldState && monthsFieldState.touched && (monthsFieldState.error || []).length > 0;

    return (
        <div className="AnnualCheckboxes u-mt-0">
            <div className={`Field u-mt-0 ${isRequired ? "has-error" : ""}`}>
                <FieldArray name={monthsFieldName} validate={validateMonths(monthsFieldName, optional)}>
                    {({ fields }) => {
                        const selectedMonths = fields.value || [];
                        return domain.farmYear.map((month, i) => {
                            const key = `${monthsFieldName}_${month}`;
                            const isSelected = selectedMonths.includes(month);
                            return (
                                <label key={key} htmlFor={key} className="Checkbox">
                                    <input id={key} className="Checkbox-input" type="checkbox" checked={isSelected} onChange={toggleMonth(form, monthsFieldName, selectedMonths, month)} />
                                    <span className="Checkbox-label">{month.substring(0, 3)}</span>
                                </label>
                            );
                        });
                    }}
                </FieldArray>
                {isRequired && <small className="Field-error">Required</small>}
            </div>
        </div>
    );
};

export default MonthsSelector;
