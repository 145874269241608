import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";

export function useNotifications() {
    const query = useQuery({
        queryKey: ["notifications"],
        queryFn: async () => httpClient.get(`messages`),
        retry: false,
        refetchOnWindowFocus: false
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
        refetch: query.refetch
    };
}

export function useDimssNotificationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (notificationId) => {
            try {
                await httpClient.put(`messages/${notificationId}/dismiss`);
            } catch (error) {
                throw new Error(error.message);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["notifications"] });
        }
    });

    return (notificationId) => mutation.mutateAsync(notificationId);
}