import React from "react";
import * as icons from "../common/icons";

const ImagePreloader = () => {
    const items = Object.keys(icons);
    return (
        <div id="preloader" style={{ position: "absolute" }}>
            {items.map((i, x) => (
                <div key={x} style={{ background: `url(${icons[i]}) no-repeat -9999px -9999px` }}></div>
            ))}
        </div>
    );
};
export default ImagePreloader;
