import * as utils from "common/utils";
import Watermark from "components/Watermark";
import { useRefData } from "common/hooks";

export default function EffluentReport({ effluentResults, effluentSystem }) {
    const refData = useRefData();
    return (
        <div className="Table Table-Compressed">
            <Watermark />
            <table>
                <thead>
                    <tr>
                        <th data-width="20" id="header-name">
                            Current area receiving liquid effluent
                        </th>
                        <th data-width="20" id="header-value"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr id={"AreaReceivingLiquid-row"}>
                        <td id={"AreaReceivingLiquid-label"}>Total area including crops</td>
                        <td id={"AreaReceivingLiquid-value"}>{effluentResults.areaReceivingLiquid + " ha"}</td>
                    </tr>
                    <tr id={"PastoralAreaReceivingLiquid-row"}>
                        <td id={"PastoralAreaReceivingLiquid-label"}>Pastoral area receiving liquid</td>
                        <td id={"PastoralAreaReceivingLiquid-value"}>{effluentResults.pastoralAreaReceivingLiquid + " ha"}</td>
                    </tr>
                    <tr id={"PastoralBlockPercentage-row"}>
                        <td className="u-pl-xxl" id={"PastoralBlockPercentage-label"}>
                            % of farm pastoral area
                        </td>
                        <td id={"PastoralBlockPercentage-value"}>{effluentResults.pastoralBlockPercentage + "%"}</td>
                    </tr>

                    <tr id={"AverageEffluentApplied-row"}>
                        <td className="u-pl-xxl" id={"AverageEffluentApplied-label"}>
                            Average liquid effluent
                        </td>
                        <td id={"AverageEffluentApplied-value"}>{effluentResults.averageEffluentApplied + " kg N/ha/yr"}</td>
                    </tr>
                    <tr id={"AverageFertiliserN-row"}>
                        <td className="u-pl-xxl" id={"AverageFertiliserN-label"}>
                            Average fertiliser
                        </td>
                        <td id={"AverageFertiliserN-value"}>{effluentResults.averageFertiliserN + " kg N/ha/yr"}</td>
                    </tr>
                    <tr id={"AverageOtherN-row"}>
                        <td className="u-pl-xxl" id={"AverageOtherN-label"}>
                            Average other
                        </td>
                        <td id={"AverageOtherN-value"}>{effluentResults.averageOtherN + " kg N/ha/yr"}</td>
                    </tr>
                </tbody>
                <thead>
                    <tr>
                        <th data-width="20" id="header-name">
                            Area of farm to apply all effluent to achieve rates of
                        </th>
                        <th data-width="20" id="header-value"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr id={"AchievementLiquid150kgN-row"}>
                        <td id={"AchievementLiquid150kgN-label"}>{effluentSystem.targetEffluentRate ? effluentSystem.targetEffluentRate : "150"} kg N/ha/yr - Liquid</td>
                        <td id={"AchievementLiquid150kgN-value"}>{effluentResults.achievementLiquid150kgN + " ha - based on the amount of effluent generated on the the farm and sprayed from sump."}</td>
                    </tr>
                    <tr id={"AchievementSolid150kgN-row"}>
                        <td id={"AchievementSolid150kgN-label"}>{effluentSystem.targetEffluentRate ? effluentSystem.targetEffluentRate : "150"} kg N/ha/yr - Solid</td>
                        <td id={"AchievementSolid150kgN-value"}>{effluentResults.achievementSolid150kgN + " ha"}</td>
                    </tr>
                    <tr id={"Achievement150kgN-row"}>
                        <td id={"Achievement150kgN-label"}>{effluentSystem.targetEffluentRate ? effluentSystem.targetEffluentRate : "150"} kg N/ha/yr - Total</td>
                        <td id={"Achievement150kgN-value"}>{effluentResults.achievement150kgN + " ha"}</td>
                    </tr>
                    <tr id={"AchievementMaintenanceK-row"}>
                        <td id={"AchievementMaintenanceK-label"}>Maintenance K</td>
                        <td id={"AchievementMaintenanceK-value"}>{effluentResults.achievementMaintenanceK + " ha"}</td>
                    </tr>
                    <tr id={"Achievement100kgK-row"}>
                        <td id={"Achievement100kgK-label"}>100 kg K/ha/yr</td>
                        <td id={"Achievement100kgK-value"}>{effluentResults.achievement100kgK + " ha"}</td>
                    </tr>
                    {effluentResults.maintenanceKWarning && effluentResults.maintenanceKWarning.length > 0 && (
                        <tr id={"MaintenanceKWarning-row"}>
                            <td id={"MaintenanceKWarning-label"}>Maintenance K Warning</td>
                            <td id={"MaintenanceKWarning-value"}>{effluentResults.maintenanceKWarning}</td>
                        </tr>
                    )}
                </tbody>
                <thead>
                    <tr>
                        <th data-width="20" id="header-name">
                            Source of N in effluent block(s)
                        </th>
                        <th data-width="20" id="header-value"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr id={"AverageEffluentDairy-row"}>
                        <td id={"AverageEffluentDairy-label"}>Effluent from farm dairy</td>
                        <td id={"AverageEffluentDairy-value"}>{effluentResults.averageEffluentDairy + "%"}</td>
                    </tr>
                    {effluentResults.averageEffluentStructure &&
                        Object.keys(effluentResults.averageEffluentStructure).map((key, i) => {
                            const name = key.endsWith("WinteringPad") ? "wintering pad(s)" : utils.valueToText(refData.structureType, key) || key;
                            return (
                                <tr id={key + "-row"} key={i}>
                                    <td id={key + "-label"}>{"Effluent from " + name}</td>
                                    <td id={key + "-value"}>{effluentResults.averageEffluentStructure[key] + "%"}</td>
                                </tr>
                            );
                        })}
                    <tr id={"SolidFatePercentage-row"}>
                        <td id={"SolidFatePercentage-label"}>Solids</td>
                        <td id={"SolidFatePercentage-value"}>{effluentResults.solidFatePercentage + "%"}</td>
                    </tr>
                    <tr id={"ExportedFatePercentage-row"}>
                        <td id={"ExportedFatePercentage-label"}>Exported</td>
                        <td id={"ExportedFatePercentage-value"}>{effluentResults.exportedFatePercentage + "%"}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
