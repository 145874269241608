import { useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { useModal } from "common/effects";
import * as utils from "common/utils";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import ActionLink from "components/ActionLink";
import { MergeFarmsModal, TransferSubscriptionsModal } from "containers/Admin/Farms";
import { httpClient } from "common/httpClient";
import { useConfirm, useOnline, useRefData } from "common/hooks";

export default function FarmList({ farms }) {
    const [vmFarms, setVmFarms] = useState(farms);
    const online = useOnline();
    const refData = useRefData();
    const { openModal, getModal } = useModal();
    const confirm = useConfirm();
    const unlockFarmLocationAsync = useUnlockFarmLocationAsync();

    const merge = (farm) => (e) => {
        const viewModel = { farm };
        openModal("MergeFarmsModal", viewModel);
    };
    const mergeFarmsModal = getModal("MergeFarmsModal");

    const transferSubscriptions = (farm) => () => {
        const viewModel = { farm };
        openModal("TransferSubscriptionsModal", viewModel);
    };

    const unLockLocationConfirm = (farm) => () => {
        confirm(`Are you sure you want to unlock this farms location ?`, async () => {
            await unlockFarmLocationAsync(farm);
            farm.locationLocked = false;
            setVmFarms([...vmFarms.filter((f) => f.id !== farm.id), farm]);
        });
    };

    const transferSubscriptionsModal = getModal("TransferSubscriptionsModal", TransferSubscriptionsModal);

    const getFarmRolesText = (roles) => {
        if (!roles || roles.length === 0) return "";
        return roles.map((role) => utils.valueToText(refData.farmRoles, role)).join(", ");
    };

    const showAccess = vmFarms.some((farm) => farm.roles && farm.roles.length > 0);

    return (
        <>
            <FarmCount farms={vmFarms} />
            {vmFarms.length > 0 && (
                <SortableTable data={vmFarms} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }}>
                    {({ data, sortCriteria, handleSort }) => {
                        return (
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <SortableTableHeader label="Farm" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Nearest town" fieldName="nearestTown" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Region" fieldName="region" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Subscription" fieldName="expirationDate" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                        {showAccess && <SortableTableHeader label="Access" fieldName="role" sortCriteria={sortCriteria} handleSort={handleSort} />}
                                        <SortableTableHeader label="Created" fieldName="createdOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                        <SortableTableHeader label="Modified" fieldName="modifiedOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((farm) => {
                                        return (
                                            <tr key={farm.id} className="hover">
                                                <td valign="top">
                                                    <Link id={`farm_home_${farm.id}`} to={`/app/farm/${farm.id}`} className="icon icon-home" title="Go to farm home" />
                                                </td>
                                                <td valign="top">
                                                    <div>
                                                        <Link id={`farm_summary_${farm.id}`} to={`/app/admin/farms/${farm.id}`} title="Go to farm summary" className="u-textBold">
                                                            {farm.name}
                                                        </Link>
                                                    </div>
                                                    <div>{farm.address}</div>
                                                </td>
                                                <td valign="top">{farm.nearestTown === "Undefined" ? "" : farm.nearestTown}</td>
                                                <td valign="top">{farm.region}</td>
                                                <td className={farm.expired ? "u-textError" : ""} valign="top">
                                                    {farm.expired ? "Expired" : utils.dateLong(farm.expirationDate, "-")}
                                                </td>
                                                {showAccess && <td valign="top">{getFarmRolesText(farm.roles)}</td>}
                                                <td valign="top">{utils.dateLong(farm.createdOn, "-")}</td>
                                                <td valign="top">{utils.dateLong(farm.modifiedOn, "-")}</td>
                                                <td valign="top">
                                                    {online && (
                                                        <div className="u-flex">
                                                            <ActionLink id={`merge_${farm.id}`} onClick={merge(farm)} className="icon icon-group" title="Merge Farm" />
                                                            {!farm.expired && <ActionLink id={`moveSub_${farm.id}`} onClick={transferSubscriptions(farm)} className="icon icon-dollar" title="Transfer Subscription" />}
                                                            {farm.locationLocked && <ActionLink id={`unlock_${farm.id}`} onClick={unLockLocationConfirm(farm)} className="icon icon-lock" title="Unlock location"></ActionLink>}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        );
                    }}
                </SortableTable>
            )}
            {mergeFarmsModal && <MergeFarmsModal {...mergeFarmsModal.modalProps} />}
            {transferSubscriptionsModal && <TransferSubscriptionsModal {...transferSubscriptionsModal.modalProps} />}
        </>
    );
}

function FarmCount({ farms }) {
    if (!farms) return null;

    return (
        <p className="u-mt-0 u-mb-md">
            <span className="h3">{farms.length.toLocaleString()}</span> farm(s) found
        </p>
    );
}

function useUnlockFarmLocationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (farm) => {
            try {
                await httpClient.put(`admin/farms/${farm.id}/unlock`, { farmId: farm.id });
            } catch (error) {
                throw new Error(error.message);
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.id] });
        }
    });

    return (farm) => mutation.mutateAsync(farm);
}
