import Alert from "components/Alert";
import { useFieldHelp } from "./useFieldHelp";

export default function CheckboxField({ input = {}, label, fieldLabel, className, dataWidth, vertical, indent, onChange, info, tip, disabled, meta, checked, testingId }) {
    const [fieldHelpLink, fieldHelp] = useFieldHelp(tip);

    const handleOnChange = (e) => {
        if (input.onChange) input.onChange(e.target.checked);

        if (onChange) onChange(e.target.checked);
    };

    const error = meta && meta.touched && meta.error;
    const isChecked = checked || input.checked || input.value || false;

    return vertical ? (
        <label htmlFor={input.name} className={`Checkbox ${disabled ? "is-disabled" : ""} ${indent ? "u-ml-md" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            <input {...input} id={input.name} data-test-id={testingId || input.name} data-testingid={testingId} checked={isChecked} className="Checkbox-input" type="checkbox" onChange={handleOnChange} />
            <span className="Checkbox-label">{label}</span>
        </label>
    ) : (
        <div className={`Field ${error ? "has-error" : ""} ${indent ? "u-ml-md" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            {fieldLabel && <label className="Field-label u-mb-sm">{fieldLabel}</label>}
            <label htmlFor={input.name} className={`Checkbox ${disabled ? "is-disabled" : ""}`}>
                <input {...input} id={input.name} data-test-id={testingId || input.name} data-testingid={testingId} checked={isChecked} className="Checkbox-input" type="checkbox" onChange={handleOnChange} />
                <span className="Checkbox-label">{label}</span>
                {fieldHelpLink}
            </label>
            {error && (
                <div id={`${input.name}-error`} className="Field-error u-block u-mt-sm">
                    {error}
                </div>
            )}
            {info && <Alert type="info" text={info} className="u-mb-0" />}
            {fieldHelp}
        </div>
    );
}
