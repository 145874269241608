import { useSelector, useDispatch } from "react-redux";
import { Field } from "redux-form";
import * as validations from "common/validations";
import Modal from "components/ModalOld";
import InputPack from "components/InputPack";
import { logout } from "./_actions";
import GoogleLoginButton from "./GoogleLoginButton";
import FacebookLoginButton from "./FacebookLoginButton";

function RefreshTokenModal({ initialValues, modal }) {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.auth.userId);

    function _login(formValues) {
        modal.onConfirm(formValues.email, formValues.password);
    };

    function _logout() {
        dispatch(logout());
    };

    return (
        <Modal title="Login required" submitLabel="Log in" cancelLabel={isAuthenticated ? "Log out" : "Cancel"} skinny={true} initialValues={initialValues} onSubmit={_login} onCancel={_logout}>
            <div className="Grid-cell Grid-cell--center u-lg-width1of2">
                <div className="u-textCenter u-mt-xl">
                    <h4>Log in with...</h4>
                </div>

                <hr className="u-mt-sm u-mb-lg" />
                <div className="Grid">
                    <div className="Grid-cell Grid-cell--center u-md-width1of1">
                        <GoogleLoginButton isReAuth={true} originalPayload={initialValues.originalPayload} />
                        <FacebookLoginButton isReAuth={true} originalPayload={initialValues.originalPayload} />
                    </div>
                </div>
            </div>

            <div className="Grid-cell Grid-cell--center u-lg-width1of2">
                <div className="u-textCenter u-mt-xl">
                    <h4>Or, log in with your email address and password</h4>
                </div>
                <hr className="u-mt-sm" />
                <div className="Grid-cell u-lg-width1of1">
                    <Field name="email" type="text" label="Email" disabled={initialValues.email && true} component={InputPack} />
                    <Field name="password" type="password" label="Password" requiredLabel={true} component={InputPack} validate={[validations.required, validations.maxLength(50), validations.minLength(1)]} placeholder="Enter your password" />
                </div>
                {isAuthenticated && <h3 className="u-textWarning">WARNING! if you log out from here you will lose any unsaved changes</h3>}
            </div>
        </Modal>
    );
}

export default RefreshTokenModal;
