import { Fragment } from "react";
import { numeric } from "common/utils";
import Watermark from "components/Watermark";
import ActionLink from "components/ActionLink";
import { setFormState } from "containers/App/_actions";
import { useDispatch } from "react-redux";
import { useFormState } from "common/hooks";

export default function NutrientBudgetSection({ section, title, uom, id }) {
    const formState = useFormState();
    const dispatch = useDispatch();

    const toggle = (name) => {
        const key = `nutrientBudgetSection_${id}_${name}`;
        dispatch(setFormState(key, !formState[key]));
    };

    return (
        <div className="Table Table-Compressed u-mt-md">
            <Watermark />
            <table id={`source-${title.toLowerCase().replaceAll(" ", "-")}`}>
                <thead>
                    <tr>
                        <th data-width="20">
                            {title} ({uom})
                        </th>
                        <th data-width="10" className="numeric">
                            N
                        </th>
                        <th data-width="10" className="numeric">
                            P
                        </th>
                        <th data-width="10" className="numeric">
                            K
                        </th>
                        <th data-width="10" className="numeric">
                            S
                        </th>
                        <th data-width="10" className="numeric">
                            Ca
                        </th>
                        <th data-width="10" className="numeric">
                            Mg
                        </th>
                        <th data-width="10" className="numeric">
                            Na
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {section.map((source) => {
                        const showExpandCollapseIcon = formState?.nutrientBudget_expandAll !== true;
                        return (
                            <Fragment key={source.name}>
                                <tr>
                                    <td>
                                        {source.subProcesses ? (
                                            <ActionLink className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween" onClick={() => toggle(source.name.split(" ").join("_"))}>
                                                {source.name}
                                                {showExpandCollapseIcon && <i className={`icon icon-arrow-${formState[`nutrientBudgetSection_${id}_${source.name.split(" ").join("_")}`] || formState?.nutrientBudget_expandAll ? "up" : "down"}`}></i>}
                                            </ActionLink>
                                        ) : (
                                            <>{source.name}</>
                                        )}
                                    </td>
                                    <td className="numeric">
                                        {numeric.format(source?.nutrients?.nitrogen?.value)}
                                    </td>
                                    <td className="numeric">
                                        {numeric.format(source?.nutrients?.phosphorus?.value, 1)}
                                    </td>
                                    <td className="numeric">
                                        {numeric.format(source?.nutrients?.potassium?.value)}
                                    </td>
                                    <td className="numeric">
                                        {numeric.format(source?.nutrients?.sulphur?.value)}
                                    </td>
                                    <td className="numeric">
                                        {numeric.format(source?.nutrients?.calcium?.value)}
                                    </td>
                                    <td className="numeric">
                                        {numeric.format(source?.nutrients?.magnesium?.value)}
                                    </td>
                                    <td className="numeric">
                                        {numeric.format(source?.nutrients?.sodium?.value)}
                                    </td>
                                </tr>
                                {(formState[`nutrientBudgetSection_${id}_${source.name.split(" ").join("_")}`] || formState?.nutrientBudget_expandAll) &&
                                    source?.subProcesses?.map((subValue) => (
                                        <tr key={subValue.name} className="tr-nested">
                                            <td>{subValue.name}</td>
                                            <td className="numeric">
                                                {numeric.format(subValue?.nutrients?.nitrogen?.value)}
                                            </td>
                                            <td className="numeric">
                                                {numeric.format(subValue?.nutrients?.phosphorus?.value, 1)}
                                            </td>
                                            <td className="numeric">
                                                {numeric.format(subValue?.nutrients?.potassium?.value)}
                                            </td>
                                            <td className="numeric">
                                                {numeric.format(subValue?.nutrients?.sulphur?.value)}
                                            </td>
                                            <td className="numeric">
                                                {numeric.format(subValue?.nutrients?.calcium?.value)}
                                            </td>
                                            <td className="numeric">
                                                {numeric.format(subValue?.nutrients?.magnesium?.value)}
                                            </td>
                                            <td className="numeric">
                                                {numeric.format(subValue?.nutrients?.sodium?.value)}
                                            </td>
                                        </tr>
                                    ))}
                            </Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}
