import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import { useAuthContext, useModal } from "common/hooks";
import { httpClient } from "common/httpClient";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import { TextField } from "components/FormFields";
import { getAccount } from "containers/AccountDetails/_actions";

export default function MyProfileModal({ close }) {

    const myProfile = useMyProfile();
    const updateMyProfileAsync = useUpdateMyProfileAsync();

    const validate = (formValues) => {
        const validation = {};

        validation.email = FormUtils.validators.required(formValues.email);
        validation.email = validation.email || FormUtils.validators.email(formValues.email);

        validation.fullName = FormUtils.validators.required(formValues.fullName);
        validation.fullName = validation.fullName || FormUtils.validators.maxLength(50)(formValues.fullName);
        validation.fullName = validation.fullName || FormUtils.validators.nameRegex(formValues.fullName);

        return validation;
    };

    const submitAsync = async (formValues) => {
        try {
            await updateMyProfileAsync(formValues.email, formValues.fullName);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }

    const title = myProfile.email ? "My profile" : "Email required";
    const info = myProfile.email ? "" : "Before you can continue using Overseer, we need to know your email address";
    const emailDisabled = myProfile.accountType === "Student";

    return (
        <Form initialValues={myProfile} validate={validate} onSubmit={submitAsync}>
            {({ handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={title} info={info} submitting={submitting} close={close} fluid skinny>
                            <ModalBody error={submitError}>
                                <Grid>
                                    <GridCell>
                                        <Field name="email" label="Email" disabled={emailDisabled} placeholder="Enter your email" required component={TextField} />
                                        <Field name="fullName" label="Full name" placeholder="Enter your full name" required component={TextField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit primary waiting={submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useMyProfileModal() {
    const [modal, openModal] = useModal(MyProfileModal);

    const openMyProfileModal = () => {
        const modalProps = {};
        openModal(modalProps);
    };

    return [modal, openMyProfileModal];
}

function useMyProfile() {
    const dispatch = useDispatch();
    const authContext = useAuthContext();

    useEffect(() => {
        dispatch(getAccount());
    }, [dispatch]);

    const myProfile = {
        email: authContext.email,
        fullName: authContext.fullName,
        accountType: authContext.accountType
    };
    return myProfile;
}

function useUpdateMyProfileAsync() {
    const dispatch = useDispatch();

    const mutation = useMutation({
        mutationFn: async ({ email, fullName }) => {
            try {
                await httpClient.put(`users`, { email, fullName });
                dispatch({ type: "AUTH_UPDATE_MY_PROFILE_SUCCESS", payload: { email, fullName } });
            } catch (error) {
                if (error.status === 400) {
                    throw new Error("Error updating details. Please ensure your email address is valid.");
                } else if (error.status === 409) {
                    throw new Error("The email address is already associated with another user.");
                } else {
                    throw new Error(error.message);
                }
            }
        }
    });

    return (email, fullName) => mutation.mutateAsync({ email, fullName });
}
