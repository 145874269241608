import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as icons from "common/icons";
import { useFormState, useIsPaidVersionOfOverseer } from "common/hooks";
import RenewSubscriptionButton from "containers/Payments/RenewSubscriptionButton";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import FarmMenu from "components/FarmMenu";
import { useCurrentWidth } from "common/effects";
import ActionLink from "components/ActionLink";
import { AnalysisResultsWidget } from "components/ResultsWidgets";
import { useFarm, useAnalysis } from "containers/hooks";

export default function BudgetHomePageHead({ breadcrumb }) {
    const { data: farm } = useFarm();
    const { data: analysis } = useAnalysis();

    let publication = undefined;
    if (analysis.publicationId) {
        publication = farm?.publications?.find(p => p.id === analysis.publicationId);
    }

    const isPaidVersionOfOverseer = useIsPaidVersionOfOverseer();
    const formState = useFormState();

    const [farmMenuOpen, setFarmMenuOpen] = useState(false);
    const [left, setLeft] = useState(0);
    const width = useCurrentWidth();

    const { currentResults, type, year, name } = analysis;

    const budgetTitle = name + (type === "Predictive" && year ? "-" + year : "") + (publication ? " (" + publication.publishedTo + ")" : "");

    const renew = (farm.expired || !farm.expirationDate) && utils.canMakePayment(farm) && isPaidVersionOfOverseer;
    const farmUrl = `/app/farm/${farm.id}`;

    const hamRef = useCallback(
        (element) => {
            if (element !== null && width > 0) {
                setLeft(element.getBoundingClientRect().left);
            }
        },
        [width]
    );

    return (
        <>
            {farmMenuOpen && <FarmMenu farm={farm} onClick={() => setFarmMenuOpen(false)} left={left} onMouseOut={() => setFarmMenuOpen(false)} />}
            <section className={formState.disableStandardPrintHeader ? "PageHead u-print-none" : "PageHead"}>
                <div className="Container BudgetHeader u-print-none" ref={hamRef}>
                    <div className="FarmTitle u-print-none">
                        {farmUrl && <ActionLink onClick={() => setFarmMenuOpen(!farmMenuOpen)} className="RoundButton RoundButton--hamburger FarmHome-hamburger"></ActionLink>}
                        <div className="FarmTitle-heading FarmTitle-heading--lg">
                            {farmUrl && (
                                <Link to={farmUrl}>
                                    <span id="page-head-title" className={"FarmTitle-name" + (farm.expired ? " UnpaidSubscription" : "")} title={farm.displayName}>
                                        {farm.displayName}
                                    </span>
                                </Link>
                            )}
                            {!farmUrl && (
                                <span id="page-head-title" className="FarmTitle-name" title={farm.displayName}>
                                    {farm.displayName}
                                </span>
                            )}
                            <div id="page-head-sub-title" className="FarmTitle-sub" title={farm.address}>
                                {farm.address}
                            </div>
                        </div>
                    </div>
                    <div className="PageHead-budgetOverview u-print-none">
                        <span id="page-head-page-title" title={budgetTitle}>
                            {budgetTitle}
                            {analysis.visibility === "Private" && <i className="icon icon-lock u-textPrimary u-print-none"></i>}
                        </span>
                    </div>
                    {renew && <RenewSubscriptionButton farm={farm} />}
                    {!renew && <AnalysisResultsWidget analysis={analysis} noPrint />}
                </div>
                <div className="PageHead-Print u-pt-md u-pl-lg u-pb-md">
                    <div className="u-print-flex u-pr-md">
                        <div className="FarmTitle-heading FarmTitle-heading--lg">
                            {farmUrl && (
                                <Link to={farmUrl}>
                                    <span id="page-head-title" className={"FarmTitle-name" + (farm.expired ? " UnpaidSubscription" : "")} title={farm.displayName}>
                                        {farm.displayName}
                                    </span>
                                </Link>
                            )}
                            {!farmUrl && (
                                <span id="page-head-title" className="FarmTitle-name" title={farm.displayName}>
                                    {farm.displayName}
                                </span>
                            )}
                            <div id="page-head-sub-title" className="FarmTitle-sub" title={farm.address}>
                                {farm.address}
                            </div>
                        </div>
                        <div className="u-flexJustifyEnd">
                            <img src={icons.logoPrint()} alt="logo" style={{ width: "200px", height: "50px" }} />
                        </div>
                    </div>

                    <div className="h2">
                        {analysis.name}
                        {publication ? " (v" + publication.version + ")" : ""}
                    </div>
                    <div className="u-flex u-pt-sm">
                        <div className="Print-Table u-minw-400 u-pr-xxl ">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Analysis type</td>
                                        <td className="Nvp-Value">{utils.enumToSentence(analysis.type)}</td>
                                    </tr>
                                    <tr>
                                        <td>Is publication</td>
                                        <td className="Nvp-Value">{publication ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <td>Application version</td>
                                        <td className="Nvp-Value">{process.env.REACT_APP_VERSION.split("+")[0]}</td>
                                    </tr>
                                    <tr>
                                        <td>Printed date</td>
                                        <td className="Nvp-Value">{utils.dateLong(new Date())}</td>
                                    </tr>
                                    {currentResults && (
                                        <tr>
                                            <td>Model version</td>
                                            <td className="Nvp-Value">{analysis.modelVersion}</td>
                                        </tr>
                                    )}
                                    {publication && (
                                        <>
                                            <tr>
                                                <td>Publised on</td>
                                                <td className="Nvp-Value">{utils.dateLong(publication.publishedOn)}</td>
                                            </tr>
                                            <tr>
                                                <td>Publised by</td>
                                                <td className="Nvp-Value">{publication.publishedBy.accountName}</td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td className="Nvp-Value">{publication.status}</td>
                                            </tr>
                                            <tr>
                                                <td>Version</td>
                                                <td className="Nvp-Value">{publication.version}</td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            {breadcrumb?.length > 0 && <Breadcrumb breadcrumb={breadcrumb} />}
        </>
    )
}
