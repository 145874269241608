import React from "react";
import { compose, withAppContext } from "containers/HOC";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import { getFarmDisplayName } from "containers/FarmHome/_utils";

const FarmSearchResults = ({ farms, selectedIndex, onSelected, loading, refData }) => {
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        const data = farms.map((farm) => {
            const isMyFarm = farm.isMyFarm;
            const hasFarmRole = farm.role;
            return {
                id: farm.id,
                name: farm.name,
                supplierNumber: farm.supplierNumber,
                address: farm.address,
                createdOn: farm.createdOn,
                isUnpaid: farm.expired || !farm.expirationDate,
                expirationDate: farm.expirationDate,
                ownershipStatus: farm.ownershipStatus,
                access: hasFarmRole ? <span className="u-font-grey">{utils.farmRolesToText(farm, refData.farmRoles)} access</span> : farm.accessRequest ? farm.accessRequest.status : <span className="u-font-red">No access</span>,
                accessRequest: farm.accessRequest,
                isMyFarm,
                hasFarmRole,
                ref: React.createRef(),
            };
        });
        setData(data);
    }, [farms, refData]);

    React.useEffect(() => {
        if (selectedIndex >= 0 && selectedIndex < data.length) {
            const selectedData = data[selectedIndex];
            selectedData.ref.current.focus();
        }
    }, [data, selectedIndex]);

    return (
        <div className="QuickSearch-results-section">
            {loading && (
                <div className="u-pb-sm u-pt-md">
                    <span className="u-font-600">Farms loading ...</span>
                </div>
            )}
            {!loading && (
                <>
                    <div className="u-pb-sm u-font-600">Farms ({data.length})</div>
                    {data.map((farm, i) => {
                        const farmDisplayName = getFarmDisplayName(farm);
                        return (
                            <Link key={farm.id} to={`/app/farm/${farm.id}`} onClick={onSelected} className="Quick-search-result" ref={farm.ref} id={farm.id} name={farm.name}>
                                <div className={`Search-result ${!farm.hasFarmRole ? "inactive" : ""} ${selectedIndex === i ? "selected" : ""}`}>
                                    <div className="u-pl-sm u-pr-sm">
                                        <div className="u-flex u-flexJustifyBetween">
                                            <span className="u-textBold a">{farmDisplayName}</span>
                                            <span>{farm.access}</span>
                                        </div>
                                        <div className="u-textPrimary">{farm.address}</div>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default compose(withAppContext)(FarmSearchResults);
