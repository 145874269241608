import { useState } from "react";
import ActionLink from "components/ActionLink";
import { useAnalysisResults } from "containers/BudgetHome";
import { Panel, PanelBody } from "components/Panel";
import NutrientLossGraphs from "./NutrientLossGraphs";
import NutrientLossTable from "./NutrientLossTable";

export default function OverviewPanel({ analysis }) {
    const [activeTab, setActiveTab] = useState(tabs.nitrogen);
    const { data: analysisResults } = useAnalysisResults(analysis);

    if (!analysisResults?.nitrogen) {
        return null;
    }

    return (
        <Panel title="Overview" className="u-mb-md" green open={true} stickyHeaderName="budget.overview.overview.graphs.collapsed">
            <PanelBody>
                <ul className="SubMenu u-mb-md">
                    <li className={`SubMenu-item ${activeTab.title === tabs.nitrogen.title ? "is-active" : ""}`}>
                        <ActionLink onClick={() => setActiveTab(tabs.nitrogen)}>
                            <span>{tabs.nitrogen.title}</span>
                        </ActionLink>
                    </li>
                    <li className={`SubMenu-item ${activeTab.title === tabs.phosphorus.title ? "is-active" : ""}`}>
                        <ActionLink onClick={() => setActiveTab(tabs.phosphorus)}>
                            <span>{tabs.phosphorus.title}</span>
                        </ActionLink>
                    </li>
                </ul>
                <NutrientLossGraphs analysis={analysis} showNitrogenChartData={activeTab.title === tabs.nitrogen.title} showPhosphorusChartData={activeTab.title === tabs.phosphorus.title} />
                <NutrientLossTable analysis={analysis} />
            </PanelBody>
        </Panel>
    )
}

const tabs = {
    nitrogen: { title: "Nitrogen" },
    phosphorus: { title: "Phosphorus" },
};
