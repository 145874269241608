import React from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import TagSelector from "./TagSelector";
import { Grid, GridCell } from "components/Grid";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import * as icons from "common/icons";
import SelectField from "components/FormFields/SelectField";
import { useFarmTags } from "containers/Reporting/_hooks";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { useModal } from "common/hooks";

const FarmTagsModal = ({ farms, close }) => {
    const { viewModel, availableTags, handleTagAdded } = useAvailableFarmTags(farms);
    const updateMyFarmTags = useUpdateMyFarmTagsAsync();

    const submit = async (viewModel) => {
        const farmTags = Object.keys(viewModel.indexedTaggedFarms).map((farmId) => ({ farmId, tags: viewModel.indexedTaggedFarms[farmId].tags }));
        await updateMyFarmTags(farmTags)
            .then(close)
            .catch((ex) => ({ [FORM_ERROR]: ex.message }));
    };

    const info = "Farm tags are used to filter farms by tag. Select an existing tag from the list or begin typing in the list to add a new tag.";

    return (
        <Form initialValues={viewModel} onSubmit={submit}>
            {({ values, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const { indexedTaggedFarms = [], fetchError } = values;
                const error = fetchError || (!dirtySinceLastSubmit && submitError);
                const { searchText = "" } = values;
                const farmsWithTags = Object.values(indexedTaggedFarms).map((farm) => ({ ...farm, tags: farm.tags || [] }));

                let filteredFarms = [];
                for (const farm of farmsWithTags) {
                    let formattedNameSearchResult = farm.name;
                    let formattedAddressSearchResult = farm.address;
                    let hasTag = !values.filterTags || (values.filterTags === "NONE" && farm.tags?.length === 0) || farm.tags?.includes(values.filterTags);
                    let found = true;
                    if (searchText.length > 2) {
                        found = false;
                        const rgx = new RegExp(searchText.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"), "gi");
                        const nameSearchResults = farm.name?.split(rgx);

                        if (nameSearchResults?.length > 1) {
                            formattedNameSearchResult = "";
                            for (let i = 0; i < nameSearchResults.length; i++) {
                                formattedNameSearchResult += trimCenter(nameSearchResults[i]) + (i < nameSearchResults.length - 1 ? `<mark>${searchText}</mark>` : "");
                            }
                            found = true;
                        }
                        const addressSearchResults = farm.address?.split(rgx);
                        if (addressSearchResults?.length > 1) {
                            formattedAddressSearchResult = "";
                            for (let i = 0; i < addressSearchResults.length; i++) {
                                formattedAddressSearchResult += trimCenter(addressSearchResults[i]) + (i < addressSearchResults.length - 1 ? `<mark>${searchText}</mark>` : "");
                            }
                            found = true;
                        }
                    }
                    if (found && hasTag) {
                        filteredFarms.push({ ...farm, name: formattedNameSearchResult, address: formattedAddressSearchResult });
                    }
                }

                const options = availableTags.map((tag) => ({ value: tag, text: tag, groupLabel: "Tags", groupIndex: -1 }));
                options.unshift({ value: "NONE", text: "No tags" });

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Farm tags" close={close} submitting={submitting} wide>
                            <ModalBody loading={!farmsWithTags} info={info} error={error}>
                                {farmsWithTags && farmsWithTags.length > 0 && (
                                    <>
                                        <Grid className="u-pt-lg">
                                            <GridCell className="u-width1of4 u-pr-sm">
                                                <label className="Field-label">Search</label>
                                                <Field name="searchText" component="input" className="Search-input" />
                                            </GridCell>
                                            <GridCell className="u-width1of4">
                                                <label className="Field-label u-pl-sm">Tag filter</label>
                                                <div className="Widget-header-select">
                                                    <Field name="filterTags" component={SelectField} options={options} placeholder="Show all" />
                                                </div>
                                            </GridCell>
                                        </Grid>

                                        {filteredFarms.length > 0 ? (
                                            <div className="u-pt-lg">
                                                <SortableTable data={filteredFarms} defaultSortCriteria={{ fieldName: "createdDate", fieldType: "date", desc: true }} pageSize={50}>
                                                    {({ data, sortCriteria, handleSort }) => {
                                                        return (
                                                            data.length > 0 && (
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <SortableTableHeader label="Farm" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <SortableTableHeader label="Address" fieldName="address" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <th data-width="50">Tags</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {data.map((farm) => {
                                                                            return (
                                                                                <tr key={farm.id} valign="top">
                                                                                    <td className="u-pt-lg">
                                                                                        <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: farm.name }} />
                                                                                    </td>
                                                                                    <td className="u-pt-lg">
                                                                                        <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: farm.address }} />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Field name={`indexedTaggedFarms[${farm.id}].tags`} availableTags={availableTags} onTagAdded={handleTagAdded} component={TagSelector} />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            )
                                                        );
                                                    }}
                                                </SortableTable>
                                            </div>
                                        ) : (
                                            <div className="u-mt-lg u-mb-lg">
                                                <div className="Tile-body">
                                                    <div className="Tile-body-message">
                                                        <img className="u-p-5" src={icons.info} alt="Plus" />
                                                        <p className="lead">There are no farms for this search criteria</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary disabled={fetchError || submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
};

export default FarmTagsModal;

function useAvailableFarmTags(farms) {
    const [viewModel, setViewModel] = React.useState();
    const [availableTags, setAvailableTags] = React.useState([]);
    const { data: farmTags } = useFarmTags();

    React.useEffect(() => {
        let isMounted = true;
        const fetchData = async (farms) => {
            try {
                const farmsWithTags = farms.map((farm) => {
                    const tags = (farmTags.find((t) => t.farmId === farm.id) || {}).tags || [];
                    return { ...farm, tags };
                });
                const availableTags = farmsWithTags.reduce((tags, farmWithTags) => {
                    const newTags = farmWithTags.tags.filter((tag) => !tags.includes(tag));
                    return tags.concat(newTags);
                }, []);
                const indexedTaggedFarms = farmsWithTags.reduce((indexed, farm) => {
                    indexed[farm.id] = farm;
                    return indexed;
                }, {});
                if (isMounted) {
                    setViewModel({ indexedTaggedFarms, searchText: "" });
                    setAvailableTags(availableTags);
                }
            } catch (fetchError) {
                if (isMounted) setViewModel({ fetchError });
            }
        };
        fetchData(farms);
        return () => (isMounted = false);
    }, [farmTags, farms]);

    const handleTagAdded = (newTag) => {
        if (newTag) setAvailableTags([...availableTags, newTag]);
    };

    return { viewModel, availableTags, handleTagAdded };
}

const trimCenter = (inputString, maxLength) => {
    if (!inputString) return "";
    // Ensure the input string is longer than the desired maximum length
    if (inputString.length <= maxLength) {
        return inputString;
    }

    // Calculate the length of the start and end portions
    const startLength = Math.ceil((maxLength - 1) / 2); // Ensure it's an integer
    const endLength = Math.floor((maxLength - 1) / 2);

    // Extract the start and end portions of the string
    const startPortion = inputString.substring(0, startLength);
    const endPortion = inputString.substring(inputString.length - endLength);

    // Concatenate the start and end portions with an ellipsis in the center
    const resultString = startPortion + endPortion;

    return resultString;
};

export function useUpdateMyFarmTagsAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (farmTags) => {
            try {
                await httpClient.put(`account/farmTags`, { farmTags });
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["reporting"] });
        },
    });

    return (farmTags) => mutation.mutateAsync(farmTags);
}

export function useFarmTagsModal(farms) {
    const [modal, openModal] = useModal(FarmTagsModal);

    const open = () => {
        const modalProps = {
            farms,
        };
        openModal(modalProps);
    };

    return [modal, open];
}
