import { useModal } from "common/hooks";
import ActionLink from "components/ActionLink";
import { UserModal } from "containers/Admin/Users";

export default function EditUserActionLink({ user, children = null, className = "IconLink--edit" }) {
    const [userModal, openEditUserModal] = useEditUserModal(user);
    return (<>
        <ActionLink id={`edit-user-${user.id}`} title="Edit user" className={className} onClick={openEditUserModal}>
            {children}
        </ActionLink>
        {userModal}
    </>);
}

const useEditUserModal = (user) => {
    const [modal, openModal] = useModal(UserModal);

    const openEditUserModal = () => {
        const modalProps = {
            user
        };
        openModal(modalProps);
    };

    return [modal, openEditUserModal];
}
