import React from "react";
import Page from "components/Page";
import PageBody from "components/PageBody";
import ActionLink from "components/ActionLink";
import Loader from "components/Loader";
import NotFound from "components/NotFound";
import { useFarmGroupDetailsModal } from "./FarmGroupDetailsModal";
import { useInviteFarmGroupMembersModal } from "./InviteFarmGroupMembersModal";
import { useFarmTagsModal } from "containers/FarmTags/FarmTagsModal";
import Reporting from "containers/Reporting/Reporting";
import { useParams } from "react-router-dom";
import { useFarmGroupReportData } from "containers/hooks";

const FarmGroup = (props) => {
    const { farmGroupId } = useParams();
    const data = useFarmGroupReportData(farmGroupId);
    const { farmGroup, farms } = data;
    const [farmTagsModal, openFarmTagsModal] = useFarmTagsModal(farms);
    const [farmGroupDetailsModal, openFarmGroupDetailsModal] = useFarmGroupDetailsModal(farmGroup);
    const [inviteFarmGroupMembersModal, openInviteFarmGroupMembersModal] = useInviteFarmGroupMembersModal(farmGroup);
    const loading = !farmGroup;
    const hasFarmGroup = farmGroup && farmGroup.id;
    const farmGroupNotFound = farmGroup && !farmGroup.id;
    const isFarmGroupAdmin = farmGroup && farmGroup.role === "Admin";

    return (
        <>
            <Page>
                <section className="PageHead">
                    <div className="Container">
                        <div className="FarmTitle">
                            {farmGroup && (
                                <div className="FarmTitle-heading FarmTitle-heading--lg">
                                    <span id="page-head-title" className="FarmTitle-name" title={farmGroup.name}>
                                        {farmGroup.name}
                                    </span>
                                    <div id="page-head-sub-title" className="FarmTitle-sub" title={farmGroup.description}>
                                        {farmGroup.description}
                                    </div>
                                </div>
                            )}
                        </div>
                        {isFarmGroupAdmin && (
                            <div className="PageHead-actions u-print-none">
                                <ActionLink id="manage-settings" className="IconLink--settings u-link" onClick={openFarmGroupDetailsModal}>
                                    Manage farm group settings
                                </ActionLink>
                                <ActionLink id="invite-members-link" className="IconLink--arrow-plus u-link" onClick={openInviteFarmGroupMembersModal}>
                                    Invite new members
                                </ActionLink>
                                {farms && farms.length > 0 && (
                                    <ActionLink id="tag-farms-link" className="IconLink--tag u-link" onClick={openFarmTagsModal}>
                                        Tag farms
                                    </ActionLink>
                                )}
                            </div>
                        )}
                    </div>
                </section>
                <PageBody>
                    {loading && <Loader />}
                    {farmGroupNotFound && <NotFound />}
                    {hasFarmGroup && <Reporting {...props} inviteMembers={openInviteFarmGroupMembersModal} {...data} />}
                </PageBody>
            </Page>
            {farmGroupDetailsModal}
            {inviteFarmGroupMembersModal}
            {farmTagsModal}
        </>
    );
};

export default FarmGroup;
