
export const isStudentOrg = (org) => org?.type === "Student";

export const isScienceOrg = (org) => org?.type === "Science";

export const isEducationProvider = (org) => org?.type === "EducationProvider";

export const isCouncil = (org) => org?.type === "Council";

export const isEducationOrg = (org) => isEducationProvider(org) || isStudentOrg(org);

export const isNotEducationOrg = (org) => !isEducationOrg(org);
