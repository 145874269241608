import React, { Component } from "react";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Button } from "components/Button";
import * as validations from "common/validations";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as domain from "common/domain";
import Alert from "components/Alert";
import SavePrompt from "components/SavePrompt";
import { v4 as uuidv4 } from "uuid";
import DrainageModal from "./DrainageModal";
import * as _utils from "./_utils";
import ActionLink from "components/ActionLink";
import { useModalInline, useRefData, useConfirm, useNavigate } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function DrainageDetails({ farm, analysis, drainageMethod }) {
    const { modalInlineOpen, modalInlineClose } = useModalInline();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const refData = useRefData();
    const navigate = useNavigate();

    const deleteDrainagePrompt = () => {
        confirm(`Are you sure you want to delete this drainage system?`, async () => {
            const drainageSystem = analysis.drainageSystems.find((d) => d.drainageMethod.toLowerCase() === drainageMethod.toLowerCase());
            if (!drainageSystem) return;
            /* eslint-disable no-unused-vars */
            for (const drainage of drainageSystem.drainage) {
                /* eslint-disable no-unused-vars */
                for (const block of analysis.blocks.filter((b) => b.drainageDetailsId === drainage.id)) {
                    block.drainageDetailsId = undefined;
                }
            }
            analysis.drainageSystems = analysis.drainageSystems.filter((d) => d.drainageMethod.toLowerCase() !== drainageMethod.toLowerCase());
            await updateAnalysisAsync(analysis);
            navigate(`/app/farm/${farm.id}/analysis/${analysis.id}/drainage`);
        })
    }

    return (
        <>
            <DrainageDetailsClassComponent farm={farm} analysis={analysis} drainageMethod={drainageMethod} deleteDrainagePrompt={deleteDrainagePrompt} modalInlineOpen={modalInlineOpen} modalInlineClose={modalInlineClose} refData={refData} updateAnalysisAsync={updateAnalysisAsync} />
        </>
    )
}

class DrainageDetailsClassComponent extends Component {
    constructor(props) {
        super(props);
        const analysis = props.analysis;
        const drainageSystem = analysis.drainageSystems?.find((d) => d.drainageMethod.toLowerCase() === props.drainageMethod.toLowerCase());
        this.fixOrphanBlocks(analysis);
        let isNew = false;
        if (!drainageSystem) {
            if (!analysis.drainageSystems) {
                analysis.drainageSystems = [];
            }
            analysis.drainageSystems.push({ drainageMethod: props.drainageMethod, drainage: [] });
            isNew = true;
        }
        const drainageMethodName = this.props.refData.drainageMethod.find((d) => d.value.toLowerCase() === props.drainageMethod.toLowerCase());
        const state = {
            analysis,
            validation: {},
            specifyWetland: false,
            specifyPlacement: false,
            drainageMethodName: drainageMethodName ? drainageMethodName.text : "",
            isNew: isNew,
            dirty: false,
            submitSucceeded: false,
        };
        if (!isNew) state.validation = this.validate(state, analysis);
        this.state = state;
    }

    fixOrphanBlocks(analysis) {
        //This should never happen, but just in case
        let drainageIds = [];
        /* eslint-disable no-unused-vars */
        for (const drainageSystem of analysis.drainageSystems) {
            drainageIds = [...drainageIds, ...drainageSystem.drainage.map((d) => d.id)];
        }
        /* eslint-disable no-unused-vars */
        for (const block of analysis.blocks) {
            if (block.drainageDetailsId && !drainageIds.includes(block.drainageDetailsId)) {
                block.drainageDetailsId = undefined;
            }
        }
    }

    onChange(e, source) {
        let analysis = this.state.selectedAnalysis;
        const drainage = this.state.drainage;
        const selectedBlock = analysis.blocks.find((b) => b.drainageDetailsId === drainage.id);

        switch (source.type) {
            case "state":
                switch (source.key) {
                    case "placement": {
                        const specifyPlacement = !this.state.specifyPlacement;
                        if (!specifyPlacement) {
                            drainage.depthToDrain = undefined;
                            drainage.drainSpace = undefined;
                        }
                        this.setState({ specifyPlacement: specifyPlacement });
                        break;
                    }
                    case "wetland": {
                        const specifyWetland = !this.state.specifyWetland;
                        if (!specifyWetland) {
                            drainage.artificialWetlandArea = undefined;
                            drainage.artificialWetlandCondition = undefined;
                        }
                        this.setState({ specifyWetland: specifyWetland });
                        break;
                    }
                    default:
                        console.log("Unkown Key:" + source.key);
                        break;
                }
                break;
            case "block": {
                /* eslint-disable no-unused-vars */
                for (const b of analysis.blocks.filter((b) => b.drainageDetailsId === drainage.id)) {
                    b.drainageDetailsId = undefined;
                }
                const blockId = utils.ctrlVal(e);
                const block = analysis.blocks.find((b) => b.id === blockId);
                block.drainageDetailsId = drainage.id;
                drainage.artificialWetlandArea = undefined;
                drainage.artificialWetlandPercentage = undefined;
                break;
            }
            case "drainage":
                switch (source.key) {
                    case "artificialWetlandArea":
                        drainage.artificialWetlandArea = utils.ctrlVal(e);
                        if (selectedBlock) {
                            const percentage = utils.round((drainage.artificialWetlandArea / selectedBlock.areaInHectares) * 100, 2);
                            drainage.artificialWetlandPercentage = percentage > 100 ? 100 : percentage < 0 ? 0 : percentage;
                        }
                        break;
                    case "artificialWetlandPercentage":
                        drainage.artificialWetlandPercentage = utils.ctrlVal(e);
                        if (selectedBlock) {
                            const area = utils.round((drainage.artificialWetlandPercentage * selectedBlock.areaInHectares) / 100, 2);
                            drainage.artificialWetlandArea = area > selectedBlock.areaInHectares ? selectedBlock.areaInHectares : area < 0 ? 0 : area;
                        }
                        break;
                    case "propDrained":
                        drainage[source.key] = isNaN(source.round) ? utils.ctrlVal(e) : utils.round(e.target.value, source.round);
                        break;
                    case "drainSpace":
                        drainage[source.key] = isNaN(source.round) ? utils.ctrlVal(e) : utils.round(e.target.value, source.round);
                        break;
                    default:
                        drainage[source.key] = utils.ctrlVal(e);
                        break;
                }
                break;
            default:
                console.log("No matching source type");
                break;
        }
        this.isValid(analysis, source);
        this.setState({ selectedAnalysis: analysis, drainage, dirty: true });
    }

    cancel() {
        this.setState({ drainage: undefined, selectedAnalysis: undefined, validation: {} });
        this.props.modalInlineClose();
    }

    saveModal() {
        if (this.isValid(this.state.selectedAnalysis)) {
            this.fixOrphanBlocks(this.state.selectedAnalysis);
            this.setState({ analysis: this.state.selectedAnalysis, drainage: undefined, selectedAnalysis: undefined });
            this.props.modalInlineClose();
        }
    }

    addDrainage() {
        const drainage = {};
        drainage.id = uuidv4();
        const selectedAnalysis = utils.clone(this.state.analysis);
        const drainageSystem = selectedAnalysis.drainageSystems.find((d) => d.drainageMethod.toLowerCase() === this.props.drainageMethod.toLowerCase());
        drainageSystem.drainage.push(drainage);
        this.setState({ selectedAnalysis: selectedAnalysis, drainage: drainage, specifyPlacement: false, specifyRunoff: false, specifyWetland: false, validation: {} });
        this.props.modalInlineOpen();
    }

    editDrainage(id) {
        const selectedAnalysis = utils.clone(this.state.analysis);
        const drainageSystem = selectedAnalysis.drainageSystems.find((d) => d.drainageMethod.toLowerCase() === this.props.drainageMethod.toLowerCase());
        const drainage = drainageSystem.drainage.find((d) => d.id === id);
        const selectedBlock = selectedAnalysis.blocks.find((b) => b.drainageDetailsId === drainage.id);
        if (selectedBlock) {
            const percentage = utils.round((drainage.artificialWetlandArea / selectedBlock.areaInHectares) * 100, 2);
            drainage.artificialWetlandPercentage = percentage > 100 ? 100 : percentage < 0 ? 0 : percentage;
        }
        this.setState({
            selectedAnalysis: selectedAnalysis,
            drainage: drainage,
            specifyPlacement: drainage.depthToDrain ? true : false,
            specifyWetland: drainage.artificialWetlandCondition ? true : false,
            validation: {},
        });
        this.props.modalInlineOpen();
    }

    deleteDrainage(id) {
        const analysis = this.state.analysis;
        const drainageSystem = analysis.drainageSystems.find((d) => d.drainageMethod.toLowerCase() === this.props.drainageMethod.toLowerCase());
        const drainage = drainageSystem.drainage.find((d) => d.id === id);
        /* eslint-disable no-unused-vars */
        for (const block of analysis.blocks) {
            if (block.drainageDetailsId === drainage.id) {
                block.drainageDetailsId = undefined;
            }
        }
        drainageSystem.drainage = drainageSystem.drainage.filter((d) => d.id !== drainage.id);
        this.setState({ analysis });
    }

    validate(state, analysis, source = undefined) {
        const currentValidation = state.validation;
        let validation = {};
        let message = undefined;
        const drainageSystem = analysis.drainageSystems.find((d) => d.drainageMethod.toLowerCase() === this.props.drainageMethod.toLowerCase());
        const drainageList = state.drainage ? [state.drainage] : drainageSystem.drainage;
        /* eslint-disable no-unused-vars */
        for (const drainage of drainageList) {
            const selectedBlock = analysis.blocks.find((b) => b.drainageDetailsId === drainage.id);
            message = selectedBlock ? undefined : "Required";

            const availableBlockArea = _utils.getAvailableBlockArea(analysis, selectedBlock, drainage.id);
            if (availableBlockArea <= 0) {
                message = message || "The drainage for this block has already been fully allocated as drainage catchment for one or more wetland areas.";
            }
            validation.blocks = { touched: isTouched(currentValidation, source, "blocks"), error: message !== undefined, message: message, drainage };

            message = validations.required(drainage.propDrained);
            message = message || validations.isNumeric(drainage.propDrained);
            if (availableBlockArea > 0) {
                const maxPropDrained = utils.round((availableBlockArea / selectedBlock.areaInHectares) * 100, 0);
                message = message || validations.maxValue(100)(drainage.propDrained);
                message = message || (drainage.propDrained > maxPropDrained ? `Must be between 1 and ${maxPropDrained} - only ${maxPropDrained}% of the block area is available for drainage` : undefined);
            }
            validation.propDrained = { touched: isTouched(currentValidation, source, "propDrained"), error: message !== undefined, message: message, drainage };

            //Placement
            if (drainage.depthToDrain || (state.drainage && state.specifyPlacement)) {
                message = validations.valueNotEmpty(drainage.depthToDrain);
                validation.depthToDrain = { touched: isTouched(currentValidation, source, "depthToDrain"), error: message !== undefined, message: message };

                message = validations.required(drainage.drainSpace);
                message = message || validations.isNumeric(drainage.drainSpace);
                message = message || validations.range(0, 10000)(drainage.drainSpace);
                validation.drainSpace = { touched: isTouched(currentValidation, source, "drainSpace"), error: message !== undefined, message: message };
            }

            //Wetlands

            if (drainage.artificialWetlandCondition || (state.drainage && state.specifyWetland)) {
                message = validations.valueNotEmpty(drainage.artificialWetlandCondition);
                validation.artificialWetlandCondition = { touched: isTouched(currentValidation, source, "artificialWetlandCondition"), error: message !== undefined, message: message };

                message = validations.required(drainage.artificialWetlandArea);
                message = message || validations.isNumeric(drainage.artificialWetlandArea);
                message = message || (selectedBlock && drainage.artificialWetlandArea > selectedBlock.areaInHectares ? "Area must be less than or equal to block size" : undefined);
                validation.artificialWetlandArea = { touched: isTouched(currentValidation, source, "artificialWetlandArea"), error: message !== undefined, message: message };

                if (state.drainage && state.specifyWetland) {
                    message = validations.required(drainage.artificialWetlandPercentage);
                    message = message || validations.isNumeric(drainage.artificialWetlandPercentage);
                    message = message || validations.range(0, 100)(drainage.artificialWetlandPercentage);
                    validation.artificialWetlandPercentage = { touched: isTouched(currentValidation, source, "artificialWetlandPercentage"), error: message !== undefined, message: message };
                }
            }
        }

        return validation;
    }

    isValid(analysis, source = undefined) {
        const validation = this.validate(this.state, analysis, source);
        this.setState({ validation: validation });
        /* eslint-disable no-unused-vars */
        for (let key of Object.keys(validation)) {
            if (validation[key].error) {
                return false;
            }
        }
        return true;
    }

    async save() {
        const analysis = this.state.analysis;
        if (this.isValid(analysis)) {
            this.setState({ submitting: true, submitSucceeded: false });
            this.fixOrphanBlocks(analysis);
            /* eslint-disable no-unused-vars */
            for (const drainageSystem of analysis.drainageSystems) {
                if (drainageSystem.drainage.length === 0) {
                    analysis.drainageSystems = analysis.drainageSystems.filter((d) => d.drainageMethod.toLowerCase() !== this.props.drainageMethod.toLowerCase());
                }
            }
            await this.props.updateAnalysisAsync(analysis);
            this.setState({ submitting: false, submitSucceeded: true });
        }
    } A

    render() {
        const _referrer = `/app/farm/${this.props.farm.id}/analysis/${this.state.analysis.id}/drainage`;
        const drainageSystem = this.state.analysis.drainageSystems.find((d) => d.drainageMethod.toLowerCase() === this.props.drainageMethod.toLowerCase());
        const activeAnalysis = this.state.selectedAnalysis || this.state.analysis;
        const drainageId = this.state.drainage ? this.state.drainage.id : undefined;
        const availableBlocks = activeAnalysis ? activeAnalysis.blocks.filter((b) => (b.riparianStrip === undefined || b.riparianStrip === null) && (b.drainageDetailsId === undefined || b.drainageDetailsId === null || b.drainageDetailsId === drainageId) && ![domain.BlockType.NonProductiveRiparian, domain.BlockType.NonProductiveWetland, domain.BlockType.NonProductiveHouse, domain.BlockType.NonProductiveTreesAndScrub, domain.BlockType.FodderCrop].includes(b.type)) : [];

        return (
            <div>
                <SavePrompt blockIf={this.state.dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={_referrer} />
                <Tile title={this.state.drainageMethodName + " drainage"} waiting={this.state.submitting} referrer={_referrer}>
                    <Alert type="info" text="Add each block that has this type of drainage system." />
                    <TileBody>
                        <div className="Grid Grid--withGutter">
                            <div className="Grid-cell">
                                <Tile
                                    title="Drainage"
                                    tertiary
                                    actions={
                                        <span id="drainage_add" className="IconLink--arrow-plus u-link" onClick={() => this.addDrainage()}>
                                            Add drainage
                                        </span>
                                    }
                                >
                                    <TileBody>
                                        <DrainageSummary drainageSystem={drainageSystem} analysis={this.state.analysis} addDrainage={() => this.addDrainage()} deleteDrainage={(id) => this.deleteDrainage(id)} editDrainage={(id) => this.editDrainage(id)} validation={this.state.validation} />
                                    </TileBody>
                                </Tile>
                                {this.state.drainage && <DrainageModal refData={this.props.refData} analysis={this.state.selectedAnalysis} drainageMethod={this.props.drainageMethod} availableBlocks={availableBlocks} drainage={this.state.drainage} cancel={() => this.cancel()} save={() => this.saveModal()} onChange={(e, source) => this.onChange(e, source)} validation={this.state.validation} specifyPlacement={this.state.specifyPlacement} specifyWetland={this.state.specifyWetland} />}
                            </div>
                        </div>
                    </TileBody>
                    <TileFooter>
                        <div className="ButtonBar ButtonBar--fixed">
                            <div className="ButtonBar-left">
                                <Link to={_referrer} className="Button Button--secondary">
                                    Cancel
                                </Link>
                                {!this.state.isNew && (
                                    <button type="button" onClick={() => this.props.deleteDrainagePrompt()} className="Button Button--secondary" id="drainage-system-delete">
                                        Delete
                                    </button>
                                )}
                            </div>
                            <div className="ButtonBar-right">
                                <Button submit primary id="drainage-details-submit" waiting={this.state.submitting} onClick={() => this.save()} disabled={drainageSystem?.drainage?.length === 0}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </TileFooter>
                </Tile>
            </div>
        );
    }
}

const isTouched = (validation, source, id) => (validation && validation[id] && validation[id].touched) || source === undefined || source.key === id;

const DrainageSummary = ({ drainageSystem, analysis, addDrainage, deleteDrainage, editDrainage, validation }) => {
    const refData = useRefData();

    const showPlacement = drainageSystem?.drainageMethod.toLowerCase() === "other" && drainageSystem?.drainage.some((d) => d.depthToDrain);
    const tbody =
        drainageSystem?.drainage?.length > 0 ? (
            <tbody>
                {drainageSystem.drainage.map((drainage, index) => {
                    const selectedBlock = analysis.blocks.find((b) => b.drainageDetailsId === drainage.id) || {};
                    const drainageCondition = refData.wetlandConditionType.find((c) => c.value === drainage.artificialWetlandCondition);
                    const depthToDrain = refData.depthLayers.find((c) => c.value === drainage.depthToDrain);

                    return (
                        <tr key={drainage.id} id={drainage.id}>
                            <td>
                                <b>{selectedBlock.name}</b>
                            </td>
                            <td>
                                {drainage.propDrained}
                                <small>%</small>
                                {validation.blocks && validation.blocks.error && validation.blocks.drainage && validation.blocks.drainage.id === drainage.id && <div className="Field-error u-block">{validation.blocks.message}</div>}
                                {validation.propDrained && validation.propDrained.error && validation.propDrained.drainage && validation.propDrained.drainage.id === drainage.id && <div className="Field-error u-block">{validation.propDrained.message}</div>}
                            </td>
                            {drainage.artificialWetlandCondition ? (
                                <td>
                                    <div>
                                        <b>Wetland area</b> - {drainage.artificialWetlandArea} <small>ha</small>
                                    </div>
                                    <div>
                                        <b>Wetland condition</b> - {drainageCondition && drainageCondition.text}
                                    </div>
                                </td>
                            ) : (
                                <td>None</td>
                            )}
                            {showPlacement &&
                                (drainage.depthToDrain ? (
                                    <td>
                                        <div>
                                            <b>Depth to drains</b> - {depthToDrain && depthToDrain.text}
                                        </div>
                                        <div>
                                            <b>Spacing between drains</b> - {drainage.drainSpace} <small>m</small>
                                        </div>
                                    </td>
                                ) : (
                                    <td>None</td>
                                ))}
                            <td>
                                <div className="u-flex">
                                    <ActionLink className="IconLink--trash" id={`${drainage.id}_summary_remove`} onClick={() => deleteDrainage(drainage.id)} title="Delete">
                                        <span></span>
                                    </ActionLink>
                                    <ActionLink className="IconLink--edit u-ml-sm" id={`${drainage.id}_summary_edit`} onClick={() => editDrainage(drainage.id)} title="Edit">
                                        <span></span>
                                    </ActionLink>
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        ) : (
            <tbody>
                <tr>
                    <td colSpan="6">
                        <div className="Tile-body">
                            <div className="Tile-body-message">
                                <p className="h4 u-mt-0">You do not have any drainage configured</p>
                                <Button className="IconLink--arrow-plus Button Button--secondary u-mt-md" id="drainage_summary_add" onClick={() => addDrainage()}>
                                    <span>Add drainage</span>
                                </Button>
                                {validation.drainage && validation.drainage.touched && validation.drainage.error && (
                                    <div className="Field has-error u-mt-sm">
                                        <small className="Field-error">Please add at least one drainage activity</small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        );

    return (
        <div className="Table">
            <table>
                <thead>
                    <tr>
                        <th>Block</th>
                        <th>Percentage drained</th>
                        <th>Wetland</th>
                        {showPlacement && <th>Placement</th>}
                        <th className="th--shrink"></th>
                    </tr>
                </thead>
                {tbody}
            </table>
        </div>
    );
};


