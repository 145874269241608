import { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import * as geoJsonUtils from "common/geoJsonUtils";
import { useGoogleApi } from "components/Map/hooks";

export default function MapLegend({ mapData, mapId, layersToShow }) {
    const google = useGoogleApi();
    const [toggled, setToggled] = useState(false);

    const toggleLayer = useCallback((mapData, layer) => {
        let wasToggled = false;
        mapData.forEach((mapFeature) => {
            if (mapFeature.getProperty("layer") === layer) {
                mapFeature.setProperty("visible", !mapFeature.getProperty("visible"));
                wasToggled = true;
            }
        });
        if (wasToggled) setToggled((prevState) => !prevState);
    }, []);

    useEffect(() => {
        if (google && mapData && mapId && layersToShow) {
            // Get which layers to show in the legend from the map and the layersToShow.
            const legendLayers = [];
            mapData.forEach((feature) => {
                const featureLayer = feature.getProperty("layer");
                const legendLayer = legendLayers.find((l) => l.id === featureLayer);
                if (!legendLayer) {
                    const layerToShow = layersToShow.find((l) => l.id === featureLayer);
                    if (layerToShow) {
                        legendLayers.push({
                            id: layerToShow.id,
                            title: layerToShow.title,
                            sortOrder: layerToShow.sortOrder,
                            controlId: `map-legend-${layerToShow.id}-${mapId}`,
                            color: geoJsonUtils.getMapLayerColor(featureLayer),
                            icon: featureLayer === "Irrigator" ? "irrigation" : "blocks",
                            visible: feature.getProperty("visible"),
                        });
                    }
                }
            });
            legendLayers.sort((a, b) => a.sortOrder - b.sortOrder);

            const map = mapData.getMap();
            const position = google.maps.ControlPosition.TOP_RIGHT;
            const controls = map.controls[position].getArray();

            // Remove any legend controls that are no longer correspond to a legendLayer.
            controls.forEach((control, i) => {
                if (control.id.startsWith("map-legend-") && !legendLayers.some((l) => l.controlId === control.id)) {
                    map.controls[position].removeAt(i);
                }
            });

            // Render the legend controls.
            legendLayers.forEach((layer) => {
                let div = controls.find((ctl) => ctl.id === layer.controlId);
                if (!div) {
                    div = document.createElement("div");
                    div.id = layer.controlId;
                    div.className = "mapButton mapButton--right";
                    div.addEventListener("click", () => toggleLayer(mapData, layer.id));
                    map.controls[position].push(div);
                }
                const borderColor = layer.visible ? layer.color : "white";
                ReactDOM.render(<i title={`${layer.visible ? "Hide" : "Show"} ${layer.title}`} className={`icon icon-${layer.icon}`} style={{ border: `3px solid ${borderColor}`, borderRadius: "3px" }}></i>, div);
            });
        }
    }, [google, mapData, mapId, layersToShow, toggleLayer, toggled]);

    return null;
}
