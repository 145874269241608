import React from "react";
import Button from "components/Button/Button";
import { ZendeskLink } from "components/Help";

export default function Header({ before, changeBase, togglePreview, printPreview, removeBase }) {
    const alertText = printPreview ? <div>You are now in print preview mode. Click 'Print' to open the print dialog or close to return to the tab view.</div> : before ? <div>This shows the difference between the base analysis and the current analysis. Select Farm Impact Report on the right to print a report that you can include in a farm plan. To select a different analysis as a base for comparison, click on the 'Change base analysis' button.</div> : <div>This is the farm summary report for this analysis. You can view/print a report for this analysis or select a base analysis to compare. To create a Farm Impact Report, select the starting year as the base analysis and print a report that can be included in a farm plan. </div>;

    const buttonText = before ? "Change base" : "Select base analysis";

    const alertHtml = (
        <div className="u-flex">
            {alertText}
            <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/4402677971225" className="IconLink--new-tab u-pl-md u-pr-md" />
        </div>
    );

    return (
        <div className={`u-print-none u-mt-0 u-flexJustifyBetween Alert Alert--${before || printPreview ? "info" : "warning"} u-flexStart`}>
            <div className="u-flex">
                <i className={"icon icon-alert"} />
                <div style={{ paddingTop: "3px", paddingRight: "10px" }}>{alertHtml}</div>
            </div>
            <div className="u-flex">
                {printPreview ? (
                    <Button id="base-analysis" tertiary={!before} secondary={before} onClick={() => togglePreview()}>
                        Close
                    </Button>
                ) : (
                    <Button id="base-analysis" tertiary={!before} secondary={before} onClick={changeBase}>
                        {buttonText}
                    </Button>
                )}
                {before && !printPreview && (
                    <div className="u-pl-sm">
                        <Button id="btnRemove" secondary={true} onClick={() => removeBase()}>
                            Remove base
                        </Button>
                    </div>
                )}
                <div className="u-pl-sm">
                    {printPreview ? (
                        <Button id="btnPrint" onClick={() => window.print()}>
                            Print
                        </Button>
                    ) : (
                        <Button id="btnPreview" onClick={() => togglePreview()}>
                            {before ? "Farm Impact Report" : "Print preview"}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}
