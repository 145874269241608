import { compose, createStore, applyMiddleware } from "redux";
import createRootReducer from "./containers/App/_reducer";
import epics from "./containers/App/_epics";
import { createEpicMiddleware } from "redux-observable";
import { fallbackTypeMiddleware } from "./common/fallbackTypeMiddleware";
import thunkMiddleware from "redux-thunk";
import multi from "redux-multi";
import logger from "redux-logger";
import { createHashHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage";
import routeHistoryMiddleware from "./routeHistoryMiddleware";
import googleAnalyticsMiddleware from "./googleAnalyticsMiddleware";

const history = createHashHistory();
const epicMiddleware = createEpicMiddleware();

const rootReducer = (h) =>
    persistCombineReducers(
        {
            key: "akuaku-store",
            blacklist: ["form", "router", "searchResults", "farmSearch"],
            storage,
        },
        createRootReducer(h)
    );

let middleware = [routerMiddleware(history), epicMiddleware, fallbackTypeMiddleware, thunkMiddleware, multi, googleAnalyticsMiddleware, routeHistoryMiddleware];
if (process.env.REACT_APP_ENABLE_CONSOLE === "true") {
    middleware = [...middleware, logger];
}

const composeEnhancers = process.env.REACT_APP_ENABLE_CONSOLE === "true" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const store = composeEnhancers(applyMiddleware(...middleware))(createStore)(rootReducer(history));

epicMiddleware.run(epics);

let persistor = persistStore(store);

export { persistor, store, history };
