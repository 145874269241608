import React from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import { useEmailTracking } from "common/effects";

const ForgotPasswordModal = ({ modal: { onConfirm: requestPasswordReset }, closeModal }) => {
    useEmailTracking("Reset password opened", "");

    const info = (
        <span>
            You will be sent a password reset email <b>ONLY</b> if this email is associated with an existing Overseer user
        </span>
    );
    return (
        <Form validate={validate} onSubmit={submit(requestPasswordReset, closeModal)}>
            {({ handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const error = !dirtySinceLastSubmit && submitError;
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Reset your password" close={closeModal} submitting={submitting} skinny fluid>
                            <ModalBody error={error}>
                                <Grid>
                                    <GridCell>
                                        <Field name="email" label="Your email" placeholder="Enter the email address associated with your existing Overseer user" info={info} required component={TextField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={closeModal} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary disabled={submitting}>
                                        Reset password
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
};

const validate = (formValues) => {
    const validation = {};

    validation.email = FormUtils.validators.required(formValues.email);
    validation.email = validation.email || FormUtils.validators.email(formValues.email);

    return validation;
};

const submit = (requestPasswordReset, closeModal) => async (formValues) => {
    const submitResult = await requestPasswordReset(formValues.email)
        .then(closeModal)
        .catch((error) => ({ [FORM_ERROR]: error }));
    return submitResult;
};

export default ForgotPasswordModal;
