import searchActionTypes from "./_actions";
import authActionTypes from "containers/Auth/_actions";

const INITIAL_STATE = {
    searchText: "",
    filterBy: "MyOrg",
    searchResults: [],
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case searchActionTypes.FARM_SEARCH:
            return {
                ...state,
                searchResultsLoading: true,
            };
        case searchActionTypes.FARM_SEARCH_SUCCESS:
            return {
                ...state,
                filterBy: action.payload.filterBy,
                searchText: action.payload.searchText,
                searchResults: action.payload.response,
                searchResultsLoading: false,
            };
        case searchActionTypes.FARM_SEARCH_FAILURE:
            return {
                ...state,
                searchResultsLoading: false,
            };
        case searchActionTypes.FARM_SEARCH_TOGGLE_MY_FARM:
            return {
                ...state,
                searchResults: state.searchResults.map((searchResult) => {
                    if (searchResult.id !== action.payload.id && searchResult.farmId !== action.payload.id) return searchResult;

                    return {
                        ...searchResult,
                        isMyFarm: action.payload.isMyFarm,
                    };
                }),
            };
        case searchActionTypes.FARM_SEARCH_CLEAR:
        case authActionTypes.AUTH_CLEAR_STORAGE:
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default reducer;
