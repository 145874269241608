import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalBodyAlert from "components/Modal/ModalBodyAlert";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import TextareaField from "components/FormFields/TextareaField";
import Alert from "components/Alert";
import { useModal } from "common/hooks";
import { useAcceptFarmGroupInvitationAsync, useDeclineFarmGroupInvitationAsync } from "containers/hooks";
import { useQueryClient } from "@tanstack/react-query";

export default function FarmGroupInvitationResponseModal({ farmGroup, close }) {
    const queryClient = useQueryClient();

    const acceptFarmGroupInvitationAsync = useAcceptFarmGroupInvitationAsync();
    const declineFarmGroupInvitationAsync = useDeclineFarmGroupInvitationAsync();

    const submitAsync = async (viewModel) => {
        try {
            if (viewModel.accepted) {
                await acceptFarmGroupInvitationAsync(viewModel.id, viewModel.farmId);
            } else {
                await declineFarmGroupInvitationAsync(viewModel.id, viewModel.farmId);
            }
            queryClient.invalidateQueries({ queryKey: ["my-farm-groups"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details"] });
            queryClient.invalidateQueries({ queryKey: ["reporting"] });
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    };

    const explainVis = (visibility) => {
        switch (visibility) {
            case "Private":
                return `Private access only allows the organisation, ${farmGroup.invitedByAccount}, who created the farm group to see the reports.`;
            case "Owner":
                return "Owner access allows all farm owners of the group to see the farm group reports.";
            default:
                return "Farm access allows any organisation who has access to the farm to view the farm group reports.";
        }
    };

    const invitedBy = farmGroup.invitedByUser.toUpperCase() === farmGroup.invitedByAccount.toUpperCase() ? `<b>${farmGroup.invitedByAccount}</b>` : `<b>${farmGroup.invitedByUser}</b> from <b>${farmGroup.invitedByAccount}</b>`;
    const infoText = `<span>${invitedBy} has set up a farm group in OverseerFM for you and other farmers to work together. The farm group is set to <b>${farmGroup.farmGroupVisibility}</b> access. ${explainVis(farmGroup.farmGroupVisibility)}<br/><br/>
    A farm group within OverseerFM is a group of farm accounts that can share your farm analysis results.<br/><br/>
    Once you accept membership of a farm group, you are able to publish Year End analyses to the group, enabling comparison of the member farms results. Your results will be anonymised for other members of the group – but you will have the ability to see where your farm sits in comparison to other farms in the group.<br/><br/>
    If you have questions about the farm group which you have invited to, please contact <b>${farmGroup.invitedByUser}</b>.</span>`;

    return (
        <Form initialValues={farmGroup} onSubmit={submitAsync}>
            {({ form, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`Invitation to join '${farmGroup.name}'`} close={close} submitting={submitting} fluid>
                            <ModalBody>
                                <ModalBodyAlert>
                                    <Alert type="info" html={infoText} />
                                    {!dirtySinceLastSubmit && submitError && <Alert type="error" text={submitError} />}
                                </ModalBodyAlert>
                                <Grid title="Farm group details">
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="id" type="hidden" component="input" />
                                        <Field name="name" label="Group name" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="description" label="Description" placeholder=" " readOnly component={TextField} />
                                    </GridCell>
                                </Grid>

                                <Grid title="Your invited farm">
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="farmName" label="Farm name" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="farmAddress" label="Farm address" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell>
                                        <Field name="invitationComments" label="Comments" readOnly component={TextareaField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                    <Button id="decline" submit onClick={() => form.change("accepted", false)} tertiary waiting={submitting}>
                                        Decline
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="accept" submit onClick={() => form.change("accepted", true)} primary waiting={submitting}>
                                        Accept
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useFarmGroupInvitationResponseModal() {
    const [modal, openModal] = useModal(FarmGroupInvitationResponseModal);

    const openFarmGroupInvitationResponseModal = (farmGroup) => {
        const modalProps = {
            farmGroup,
        };
        openModal(modalProps);
    };

    return [modal, openFarmGroupInvitationResponseModal];
}
