import { useRef, useState, useEffect, createElement } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logEmailTracking } from "./actions";

export const useModal = () => {
    const isMounted = useRef(true);
    const [modals, setModals] = useState([]);

    useEffect(() => {
        isMounted.current = true;
        return function cleanup() {
            isMounted.current = false;
        };
    }, [modals]);

    const openModal = (id, modalProps = {}, onClose) => {
        const modal = { id, modalProps, onClose };
        const newState = [...modals, modal];
        setModals(newState);
    };

    const getModal = (id) => {
        const modal = modals.find((s) => s.id === id);
        if (modal) {
            return {
                ...modal,
                modalProps: { ...modal.modalProps, close: closeModal },
            };
        }
    };

    const closeModal = (onClose) => {
        if (!isMounted.current) return;

        const newState = [...modals];
        const modal = newState.pop();
        setModals(newState);

        if (onClose && typeof onClose === "function") {
            onClose();
        } else if (modal && modal.onClose) {
            modal.onClose();
        }
    };

    return {
        openModal,
        getModal,
    };
};

export const usePortalModal = () => {
    const isMounted = useRef(true);
    const [modals, setModals] = useState([]);

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, [modals]);

    const openModal = (id, modalProps = {}, onClose) => {
        const newState = [...modals, { id, modalProps, onClose }];
        setModals(newState);
    };

    const getModal = (id, component) => {
        const modal = modals.find((s) => s.id === id);
        if (modal) {
            const modalComponent = createElement(component, { ...modal.modalProps, close: closeModal });
            const div = createElement("div", { id }, modalComponent);
            return createPortal(div, document.getElementById("modal"));
        }
    };

    const closeModal = (onClose) => {
        if (!isMounted.current) return;

        const newState = [...modals];
        const modal = newState.pop();
        setModals(newState);

        if (onClose && typeof onClose === "function") {
            onClose();
        } else if (modal && modal.onClose) {
            modal.onClose();
        }
    };

    return {
        openModal,
        getModal,
    };
};

export const useDebounce = (value, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const debounce = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(debounce);
    }, [value, delay]);

    return debouncedValue;
};

export const useQueryString = () => new URLSearchParams(useLocation().search);

export const useCloseOnEscape = (close, closeIf = true) => {
    useEffect(() => {
        function closeOnEscape(e) {
            if (e.keyCode === 27 && closeIf) {
                e.preventDefault();
                close();
            }
        }
        document.addEventListener("keydown", closeOnEscape);
        return () => document.removeEventListener("keydown", closeOnEscape);
    });
};

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const useCurrentWidth = () => {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId = null;
        const resizeListener = () => {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            // change width from the state object after 150 milliseconds
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        // set resize listener
        window.addEventListener("resize", resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener("resize", resizeListener);
        };
    }, []);

    return width;
};

export function useEmailId() {
    const emailState = useSelector((state) => state.app.emailId);
    const emailTime = useSelector((state) => state.app.emailTime);
    return emailState && emailTime > Date.now() - 86400000 ? emailState : undefined;
}

export function useEmailTracking(step, message) {
    const id = useEmailId();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logEmailTracking(step, id, message));
    }, [dispatch, id, message, step]);

    return id;
}
