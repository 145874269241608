import React from "react";
import { Observable } from "rxjs/Rx";
import { ajax } from "rxjs/observable/dom/ajax";
import { push } from "connected-react-router";
import moment from "moment";
import authActionTypes from "containers/Auth/_actions";
import appActionTypes from "containers/App/_actions";

export const get = (options) => send("GET", options);
export const post = (options) => send("POST", options);
export const put = (options) => send("PUT", options);
export const del = (options) => send("DELETE", options);

const send = (httpMethod, options) => {
    const payload = {
        method: httpMethod,
        url: options.url || `${process.env.REACT_APP_API_URL}/${options.path}`,
        anon: options.anon,
        authorization: options.authorization,
        content: options.content,
        contentType: options.contentType,
        actionType: options.actionType,
        cancelActionType: options.cancelActionType,
        onBeforeSend: options.onBeforeSend,
        onSuccess: options.onSuccess,
        onFailure: options.onFailure,
        onFinally: options.onFinally,
        silentMode: options.silentMode,
        timeout: options.timeout,
        token: options.token,
        correlationId: moment().valueOf(),
        suppressXUiVer: options.suppressXUiVer,
    };
    payload.requestId = `@@api/${payload.method}/${payload.url}`;
    return { type: payload.requestId, payload };
};

const onBeforeSend = (payload) => {
    const { method, onBeforeSend, silentMode = false, requestId, correlationId } = payload;
    return (dispatch, getState) => {
        process.env.REACT_APP_ENABLE_CONSOLE === "true" && console.log("API BEGIN:     " + requestId + "  " + correlationId);
        if (["POST", "PUT", "DELETE"].includes(method)) dispatch({ type: appActionTypes.APP_SAVING, payload: true });

        if (!silentMode && method === "GET") {
            dispatch({ type: appActionTypes.APP_LOADING_BEGIN, payload: requestId });
            setTimeout(() => {
                if (getState().app.isLoading) dispatch({ type: appActionTypes.APP_LOADING_END, payload: requestId });
            }, 10000);
        }
        if (onBeforeSend) {
            dispatch(onBeforeSend());
        }
    };
};

const onSuccess = (response, payload) => {
    const { onSuccess, requestId, correlationId } = payload;
    return (dispatch) => {
        process.env.REACT_APP_ENABLE_CONSOLE === "true" && console.log("API SUCCESS:   " + requestId + "  " + correlationId);
        if (onSuccess) dispatch(onSuccess(response));
    };
};

const onFailure = (error, payload) => {
    return (dispatch, getState) => {
        process.env.REACT_APP_ENABLE_CONSOLE === "true" && console.log("API FAILURE:       " + payload.requestId + "  " + payload.correlationId);
        const { onFailure, silentMode = false } = payload;
        const {
            app: { online },
            auth,
        } = getState();

        if (!online) {
            error.offline = true;
            if (onFailure) dispatch(onFailure(error));
            return;
        }

        // Handle invalid token.
        const isAuthenticated = auth && auth.isAuthenticated;
        if (isAuthenticated && error.status === 401) {
            dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
            dispatch({
                type: appActionTypes.APP_OPEN_MODAL,
                payload: {
                    modalType: "FATAL",
                    onConfirm: () => {
                        dispatch(push("/"));
                    },
                    props: {
                        requestId: ((error.xhr || {}).response || {}).requestId,
                        message: (
                            <div>
                                <h3>You're session has expired</h3>
                                <p className="lead u-mt-md">Please log out and back in again to continue.</p>
                            </div>
                        ),
                        logOutOnly: true,
                    },
                },
            });
            return;
        }

        if (error.status === 410) {
            dispatch({
                type: appActionTypes.APP_OPEN_MODAL,
                payload: {
                    modalType: "FATAL",
                    onConfirm: () => {
                        dispatch(push("/"));
                    },
                    props: {
                        requestId: ((error.xhr || {}).response || {}).requestId,
                        message: (
                            <div>
                                <h3>We've released a new version of the website</h3>
                                <p className="lead u-mt-md">You will need to log out and close all your open web browser windows to get the latest version.</p>
                            </div>
                        ),
                        logOutOnly: true,
                    },
                },
            });
        }

        if (onFailure) dispatch(onFailure(error));

        if (error.handled || error.status === 410) return;

        if (silentMode) {
            process.env.REACT_APP_ENABLE_CONSOLE === "true" && console.error(error);
            return;
        }

        switch (error.status) {
            case 0:
            case 400:
            case 403:
            case 404:
            case 500:
                dispatch({
                    type: appActionTypes.APP_OPEN_MODAL,
                    payload: {
                        modalType: "FATAL",
                        onConfirm: () => {
                            dispatch(push("/"));
                        },
                        props: {
                            requestId: ((error.xhr || {}).response || {}).requestId,
                            logOutOnly: error.status === 403,
                        },
                    },
                });
                break;
            case 426:
                dispatch({
                    type: appActionTypes.APP_OPEN_MODAL,
                    payload: {
                        modalType: "FATAL",
                        onConfirm: () => {
                            dispatch(push("/"));
                        },
                        props: {
                            requestId: ((error.xhr || {}).response || {}).requestId,
                            message: (
                                <div>
                                    <h2>The record you were working on has been modified by another user.</h2>
                                    <h4>You will have to undo your changes or copy your changes to a new record.</h4>
                                    <h4>You will be redirected to your dashboard where you can review this conflict.</h4>
                                </div>
                            ),
                        },
                    },
                });
                break;
            default:
                break;
        }
    };
};

const onFinally = (payload) => {
    const { onFinally, requestId, correlationId } = payload;
    return (dispatch, getState) => {
        process.env.REACT_APP_ENABLE_CONSOLE === "true" && console.log("API FINALLY:   " + requestId + "  " + correlationId);
        const {
            app: { online },
        } = getState();

        if (onFinally) dispatch(onFinally(online));

        dispatch({ type: appActionTypes.APP_LOADING_END, payload: requestId });
    };
};

const apiEpic = (action$, store) => {
    return action$
        .filter((action) => {
            if (!action.payload) return false;

            return action.type === `@@api/${action.payload.method}/${action.payload.url}`;
        })
        .mergeMap(({ payload }) => {
            const headers = {
                "Content-Type": payload.contentType || "application/json",
                Authorization: payload.anon ? "" : payload.authorization || "Bearer " + (payload.token || localStorage.getItem("token")),
            };
            if (!payload.suppressXUiVer) {
                headers["X-UI-Ver"] = process.env.REACT_APP_VERSION;
            }
            return Observable.concat(
                Observable.of(onBeforeSend(payload)),
                ajax({
                    url: payload.url,
                    method: payload.method,
                    crossDomain: true,
                    responseType: payload.contentType === "text/csv" ? "csv" : "json",
                    headers,
                    body: payload.content,
                })
                    .timeout(payload.timeout ? payload.timeout : 60000)
                    .map((response) => {
                        return onSuccess(response.response, payload);
                    })
                    .catch((error) => {
                        return Observable.of(onFailure(error, payload));
                    })
                    .takeUntil(Observable.merge(action$.ofType(payload.cancelActionType), action$.ofType(authActionTypes.AUTH_LOGOUT))),
                Observable.of(onFinally(payload))
            );
        });
};
export default apiEpic;
