
export function isPublicationLocked(publication) {
    if (!publication) return true;

    if (publication.hasPublisheeAccess) return false;

    if (["Draft", "Submitted", "Returned", "Processed"].includes(publication.status)) return false;

    return true;
};


export function isPublishingToMPIWholeFarmDataProject(publishToId) {
    return publishToId === "b935c5af-5237-4a5a-b3cc-7a6a36372a1f";
}