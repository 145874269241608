import * as utils from "common/utils";
import Watermark from "components/Watermark";
import { useRefData } from "common/hooks";

export default function Results({ crops = [] }) {
    const refData = useRefData();
    const { tillages = [], cropCategories = [], cropResidues = [], defoliationManagement = [] } = refData;
    if (crops.length === 0) return null;

    return (
        <>
            <div className="h5 u-mt-lg">Crops</div>
            <div className="u-pos-rel u-overflow-hidden">
                <Watermark />
                <ul className="DataWidthTable u-pl-0">
                    {crops.map((crop, i) => {
                        const { sown = {} } = crop;
                        const selectedCategory = cropCategories.find((c) => c.value === crop.category);
                        const cropTypes = selectedCategory ? selectedCategory.children : [];
                        return (
                            <li className="u-pr-lg" data-width="md-50 xl-30" key={i}>
                                <div className="FarmTitle">
                                    <img className="FarmTitle-icon u-icon-md Print u-p-5" src={crop.icon} alt="Block Type" />
                                    <div className="FarmTitle-heading Print">{crop.displayName}</div>
                                </div>
                                <table className="Print-Table">
                                    <tbody>
                                        <tr>
                                            <td>Category</td>
                                            <td className="Nvp-Value">{utils.valueToText(cropCategories, crop.category)}</td>
                                        </tr>
                                        <tr>
                                            <td>Crop type</td>
                                            <td className="Nvp-Value">{utils.valueToText(cropTypes, crop.cropId)}</td>
                                        </tr>
                                        <tr>
                                            <td>Sown</td>
                                            <td className="Nvp-Value">{`${sown.month} ${sown.reportingYear ? " - Reporting year" : " - Year 1"}`}</td>
                                        </tr>
                                        {crop.yield && (
                                            <tr>
                                                <td>Yield</td>
                                                <td className="Nvp-Value">{crop.yield}T</td>
                                            </tr>
                                        )}
                                        {crop.cultivationPractice && crop.cultivationPractice !== "Undefined" && (
                                            <tr>
                                                <td>Cultivation practice at sowing</td>
                                                <td className="Nvp-Value">{utils.valueToText(tillages, crop.cultivationPractice)}</td>
                                            </tr>
                                        )}
                                        {crop.residualDisposalMethod && crop.residualDisposalMethod !== "Undefined" && (
                                            <tr>
                                                <td>Residual management method</td>
                                                <td className="Nvp-Value">{utils.valueToText(cropResidues, crop.residualDisposalMethod)}</td>
                                            </tr>
                                        )}
                                        {crop.defoliationManagement && crop.defoliationManagement !== "Undefined" && (
                                            <tr>
                                                <td>Defoliation management</td>
                                                <td className="Nvp-Value">{utils.valueToText(defoliationManagement, crop.defoliationManagement)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    )
}
