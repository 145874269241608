import ActionLink from "components/ActionLink";
import { useCreateFarmModal } from "./CreateFarmModal";
import { useOnline } from "common/hooks";

export default function CreateFarmLink({ id, className }) {
    const online = useOnline();
    const [createFarmModal, openCreateFarmModal] = useCreateFarmModal();

    return (
        <>
            {online && (
                <ActionLink id={id} className={`IconLink--arrow-plus ${className}`} onClick={openCreateFarmModal}>
                    Create new farm
                </ActionLink>
            )}
            {createFarmModal}
        </>
    )
}
