import actionTypes from "./_actions";
import authActions from "containers/Auth/_actions";
import * as utils from "common/utils";

const INITIAL_STATE = {
    blockGrowthCurveResult: null,
    budgetGrowthCurveResult: null,
};

const _blockGrowthCurveReducer = (state = INITIAL_STATE.blockGrowthCurveResult, action) => {
    switch (action.type) {
        case actionTypes.BLOCK_GROWTH_CURVE_FETCH:
            return {};
        case actionTypes.BLOCK_GROWTH_CURVE_FETCH_SUCCESS:
            return action.payload;
        case actionTypes.BLOCK_GROWTH_CURVE_FETCH_CANCEL:
        case actionTypes.BLOCK_GROWTH_CURVE_CLEAR:
            return null;
        case actionTypes.BLOCK_GROWTH_CURVE_FETCH_FAILURE:
            return { errorLevel: "u-textSecondary", errorMessage: "Failed to load crop growth curve" };
        default:
            return state;
    }
};

const _budgetGrowthCurveReducer = (state = INITIAL_STATE.budgetGrowthCurveResult, action) => {
    switch (action.type) {
        case actionTypes.BUDGET_GROWTH_CURVE_FETCH:
            return {};
        case actionTypes.BUDGET_GROWTH_CURVE_FETCH_SUCCESS:
            return action.payload;
        case actionTypes.BUDGET_GROWTH_CURVE_FETCH_CANCEL:
        case actionTypes.BUDGET_GROWTH_CURVE_CLEAR:
            return null;
        case actionTypes.BUDGET_GROWTH_CURVE_FETCH_FAILURE:
            return { errorLevel: "u-textSecondary", errorMessage: "Failed to load budget growth curve" };
        default:
            return state;
    }
};

function reducer(state = INITIAL_STATE, action) {
    if (action && action.type === authActions.AUTH_CLEAR_STORAGE) {
        return INITIAL_STATE;
    }

    if (utils.skipReducer(action, ["BLOCK_GROWTH_CURVE_", "BUDGET_GROWTH_CURVE_"])) {
        return state;
    }

    return {
        blockGrowthCurveResult: _blockGrowthCurveReducer(state.blockGrowthCurveResult, action),
        budgetGrowthCurveResult: _budgetGrowthCurveReducer(state.budgetGrowthCurveResult, action),
    };
}

export default reducer;
