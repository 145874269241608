import { Switch } from "react-router-dom";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import TabMessages from "containers/BudgetHome/TabMessages";
import ScrollTopRoute from "components/ScrollTopRoute";
import Animals from "./Animals";
import Enterprise from "./Enterprise/Enterprise";
import Distribution from "./Distribution/Distribution";
import OutdoorPigs from "./Enterprise/OutdoorPigs";

export default function AnimalsTab({ farm, analysis }) {
    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.ANIMALS} />
            <TileBody>
                <TabMessages farm={farm} analysis={analysis} tab={TAB.ANIMALS} />
                <Switch>
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/animals" render={() => <Animals farm={farm} analysis={analysis} />} restoreScrollY />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/animals/enterprises/outdoorPigs" render={() => <OutdoorPigs farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/animals/enterprises/:enterpriseType" render={() => <Enterprise farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/animals/distribution" render={() => <Distribution farm={farm} analysis={analysis} />} />
                </Switch>
            </TileBody>
        </Tile>
    )
}
