import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import * as domain from "common/domain";
import { requiresOutdoorPigLiquidApplications, requiresOutdoorPigSolidApplications, requiresOutdoorPigStrawCompostApplications } from "common/viewModels";

export const DEFAULT_STOCK_COUNTS = [
    { name: "July", month: 7, stockCount: 0 },
    { name: "August", month: 8, stockCount: 0 },
    { name: "September", month: 9, stockCount: 0 },
    { name: "October", month: 10, stockCount: 0 },
    { name: "November", month: 11, stockCount: 0 },
    { name: "December", month: 12, stockCount: 0 },
    { name: "January", month: 1, stockCount: 0 },
    { name: "February", month: 2, stockCount: 0 },
    { name: "March", month: 3, stockCount: 0 },
    { name: "April", month: 4, stockCount: 0 },
    { name: "May", month: 5, stockCount: 0 },
    { name: "June", month: 6, stockCount: 0 },
];

export const getEnterpriseTypeOptions = (budget, refData) => {
    const { enterprises = [] } = budget ? budget : {};
    const enterpriseTypes = refData ? refData.enterpriseTypes || [] : [];

    const enterpriseTypeOptions = enterpriseTypes.reduce((options, option) => {
        const farmHasDairy = enterprises.some((e) => e.type === "Dairy");
        const currentOptionIsDairyReplacements = option.value === "DairyReplacements";

        if (enterprises.some((e) => e.type === option.value)) {
            options.push({
                ...option,
                text: currentOptionIsDairyReplacements && farmHasDairy ? `- ${option.text}` : option.text,
                disabled: true,
                groupIndex: 1,
                groupLabel: "Already on this farm",
            });
        } else {
            options.push({
                ...option,
                text: currentOptionIsDairyReplacements && !farmHasDairy ? `- ${option.text}` : option.text,
                disabled: currentOptionIsDairyReplacements && !farmHasDairy,
                groupIndex: 0,
                groupLabel: "Not on this farm",
            });
        }
        return options;
    }, []);

    return enterpriseTypeOptions;
};

const _getMonth = (event) => {
    const date = Date.parse(`${event.month} 1, 2000`);
    return new Date(date).getMonth() + 1;
};

export const getStartWeight = (events) => {
    if (!events || events.length === 0) {
        return;
    }
    let weightEvent = events.find((e) => e.eventType === "Starting");
    weightEvent = weightEvent || events.find((e) => e.eventType === "Purchase" && e.weightKg > 0);
    return weightEvent && weightEvent.weightKg;
};

export const getStockCountsForMob = (mob) => {
    return getStockCounts(mob.events, mob.deaths);
};

export const getStockCounts = (events, deaths) => {
    if (!events || events.length === 0) {
        return DEFAULT_STOCK_COUNTS;
    }

    let runningTotal = 0;
    const stockCounts = DEFAULT_STOCK_COUNTS.map((item) => {
        const eventsByMonth = events.filter((event) => {
            if (!event.numberAnimals) return false;

            if (event.eventDate) {
                return utils.parseDate(event.eventDate, "DD MMMM").month() + 1 === item.month;
            }

            if (event.month) {
                const eventMonth = _getMonth(event);
                return eventMonth === item.month;
            }

            return false;
        });

        const monthTotal = eventsByMonth.reduce((sum, event) => {
            if (!event.numberAnimals) return 0;

            let numberAnimals = parseInt(event.numberAnimals || 0, 10);
            if (["Sale", "SaleToWorks"].includes(event.eventType)) {
                numberAnimals = -numberAnimals;
            }
            return sum + numberAnimals;
        }, 0);

        runningTotal += monthTotal - (deaths && deaths[item.name] ? deaths[item.name] : 0);

        return {
            ...item,
            stockCount: runningTotal,
        };
    });
    return stockCounts;
};

export const getLivestockDetails = (livestock, refData) => {
    switch (livestock.enterpriseType) {
        case "Sheep":
            return { class: utils.valueToText(refData.sheepStockClasses, livestock.sheepStockClass), breed: utils.valueToText(refData.sheepBreeds, livestock.sheepBreed) };
        case "DairyGrazing":
            return { class: utils.valueToText(refData.dairyGrazingStockClasses, livestock.beefStockClass), breed: utils.valueToText(refData.beefBreeds, livestock.beefBreed) };
        case "Beef":
            return { class: utils.valueToText(refData.beefStockClasses, livestock.beefStockClass), breed: utils.valueToText(refData.beefBreeds, livestock.beefBreed) };
        case "Deer":
            return { class: utils.valueToText(refData.deerStockClasses, livestock.deerStockClass), breed: utils.valueToText(refData.deerBreeds, livestock.deerBreed) };
        case "Dairy":
            return { class: utils.valueToText(refData.dairyStockClasses, livestock.dairyStockClass), breed: utils.valueToText(refData.dairyBreeds, livestock.dairyBreed) };
        case "DairyReplacements":
            return { class: utils.valueToText(refData.dairyReplacementStockClasses, livestock.dairyReplacementStockClass), breed: utils.valueToText(refData.dairyBreeds, livestock.dairyBreed) };
        case "DairyGoat":
            return { class: utils.valueToText(refData.dairyGoatStockClasses, livestock.dairyGoatStockClass), breed: utils.valueToText(refData.dairyGoatBreeds, livestock.dairyGoatBreed) };
        default:
            return { class: utils.valueToText(refData.enterpriseTypes, livestock.enterpriseType), breed: "" };
    }
};

export const showRelativeProductivity = (relProdMethod) => {
    const RELATIVE_PRODUCTIVITY_METHODS = ["Relativeyield", "Grazingdays", "Pastureassessment", "Animalassessment"];
    return RELATIVE_PRODUCTIVITY_METHODS.includes(relProdMethod);
};

export const showPercenBlockPastureProd = (blockStockRatios) => {
    return blockStockRatios === "UserDefined";
};

export const canHaveAnimals = (block) => {
    if ([domain.BlockType.ProductiveCrop, domain.BlockType.FodderCrop, domain.BlockType.ProductiveOutdoorPigs].includes(block.type)) return true;
    if (block.type === "ProductiveFruit" && block.fruit && block.fruit.swardAnimalSource === "NonFarmAnimals") return true;

    return canDistributeAnimals(block);
};

export const canDistributeAnimals = (block) => {
    if (block.type === "ProductivePasture" && block.runoffCharacteristics) return true;

    if (block.type === "ProductiveFruit" && block.fruit && block.fruit.swardAnimalSource === "EnterpriseStock") return true;

    return false;
};

export const getBlockStockRatiosOptions = (refData) => {
    const blockStockRatiosOptions =
        refData &&
        refData.blockStockRatios &&
        refData.blockStockRatios
            .map((option) => {
                switch (option.value) {
                    case "UserDefined":
                        return { value: option.value, text: "User defined for each block" };
                    case "AnimalEquivalent":
                        return { value: option.value, text: "Same as ratio of total animal intake" };
                    case "BasedOnAnimalsOnBlock":
                        return { value: option.value, text: "Based on animals present on block" };
                    default:
                        return option;
                }
            })
            .sort((a, b) => (b.value < a.value ? 1 : -1));
    return blockStockRatiosOptions;
};

export const getSaltBlockTypeOptions = (refData) => {
    const options =
        refData &&
        refData.saltBlockTypes &&
        refData.saltBlockTypes.filter((option) => {
            return option.value !== "None";
        });
    return options;
};

export const getStockClass = (livestock) => {
    switch (livestock.enterpriseType) {
        case "Sheep":
            return livestock.sheepStockClass;
        case "DairyGrazing":
        case "Beef":
            return livestock.beefStockClass;
        case "Deer":
            return livestock.deerStockClass;
        case "Dairy":
            return livestock.dairyStockClass;
        case "DairyReplacements":
            return livestock.dairyReplacementStockClass;
        case "DairyGoat":
            return livestock.dairyGoatStockClass;
        default:
            return undefined;
    }
};

export const getBreed = (livestock) => {
    switch (livestock.enterpriseType) {
        case "Sheep":
            return livestock.sheepBreed;
        case "DairyGrazing":
        case "Beef":
            return livestock.beefBreed;
        case "Deer":
            return livestock.deerBreed;
        case "Dairy":
            return livestock.dairyBreed;
        case "DairyReplacements":
            return livestock.dairyBreed;
        case "DairyGoat":
            return livestock.dairyGoatBreed;
        default:
            return undefined;
    }
};

export const isBreedingReplacementStockClass = (enterpriseType, stockClass) => {
    return (enterpriseType === "Sheep" && stockClass === "Hoggets") || (enterpriseType === "Beef" && stockClass === "HeifersBreeding");
};

export const canStockClassBeWeaned = (enterpriseType, stockClass) => {
    const dairyStockClasses = [];
    const dairyGrazingStockClasses = [];
    const dairyReplacementStockClasses = ["HeiferReplacements"];
    const sheepStockClasses = ["Lambs", "Hoggets"];
    const beefStockClasses = ["Weaners", "HeifersBreeding"];
    const deerStockClasses = ["BreedingReplacements", "Weaners"];
    const dairyGoatStockClasses = ["Replacements"];

    return (enterpriseType === "Dairy" && dairyStockClasses.includes(stockClass)) || (enterpriseType === "DairyGrazing" && dairyGrazingStockClasses.includes(stockClass)) || (enterpriseType === "DairyReplacements" && dairyReplacementStockClasses.includes(stockClass)) || (enterpriseType === "Sheep" && sheepStockClasses.includes(stockClass)) || (enterpriseType === "Beef" && beefStockClasses.includes(stockClass)) || (enterpriseType === "Deer" && deerStockClasses.includes(stockClass)) || (enterpriseType === "DairyGoat" && dairyGoatStockClasses.includes(stockClass));
};

export const sexRequired = (enterpriseType, stockClass) => {
    const dairyStockClasses = [];
    const dairyGrazingStockClasses = [];
    const dairyReplacementStockClasses = ["CalvesWeanedMixedSex"];
    const sheepStockClasses = ["Lambs"];
    const beefStockClasses = ["Weaners"];
    const deerStockClasses = ["Weaners"];
    const dairyGoatStockClasses = [];

    return (enterpriseType === "Dairy" && dairyStockClasses.includes(stockClass)) || (enterpriseType === "DairyGrazing" && dairyGrazingStockClasses.includes(stockClass)) || (enterpriseType === "DairyReplacements" && dairyReplacementStockClasses.includes(stockClass)) || (enterpriseType === "Sheep" && sheepStockClasses.includes(stockClass)) || (enterpriseType === "Beef" && beefStockClasses.includes(stockClass)) || (enterpriseType === "Deer" && deerStockClasses.includes(stockClass)) || (enterpriseType === "DairyGoat" && dairyGoatStockClasses.includes(stockClass));
};

export const ageOrWeightRequired = (enterpriseType, stockClass) => {
    const dairyStockClasses = [];
    const dairyReplacementStockClasses = ["HeiferReplacements", "CalvesWeanedMixedSex"];
    const sheepStockClasses = ["BreedingEwes", "Hoggets", "Lambs", "EwesAndHoggets", "Wethers", "Rams"];
    const beefStockClasses = ["CowsBreeding", "HeifersBreeding", "Weaners", "HeifersAndCows", "Steers", "Bulls", "DairyReplacements"];
    const dairyGrazingStockClasses = ["DairyReplacements"];
    const deerStockClasses = ["BreedingHinds", "BreedingReplacements", "Weaners", "Hinds", "Stags"];
    const dairyGoatStockClasses = ["Replacements"];

    return (enterpriseType === "Dairy" && dairyStockClasses.includes(stockClass)) || (enterpriseType === "DairyReplacements" && dairyReplacementStockClasses.includes(stockClass)) || (enterpriseType === "Sheep" && sheepStockClasses.includes(stockClass)) || (enterpriseType === "Beef" && beefStockClasses.includes(stockClass)) || (enterpriseType === "DairyGrazing" && dairyGrazingStockClasses.includes(stockClass)) || (enterpriseType === "Deer" && deerStockClasses.includes(stockClass)) || (enterpriseType === "DairyGoat" && dairyGoatStockClasses.includes(stockClass));
};

export const dairyBreeds = (breedType, livestocks, refData) => {
    return (refData[`${breedType}Breeds`] || []).map((option) => {
        if (livestocks.some((l) => l[`${breedType}Breed`] === option.value)) {
            return {
                ...option,
                groupIndex: 0,
                groupLabel: "Already on this farm",
            };
        } else {
            return {
                ...option,
                groupIndex: 2,
                groupLabel: "Not on this farm",
            };
        }
    });
};

export const monthlyTotal = (enterprise, month) => {
    let monthTotal = 0;
    (enterprise.mobs || []).forEach((mob) => {
        const events = (mob.events || []).filter((e) => e.month === month);
        monthTotal -= (mob.deaths && mob.deaths[month]) || 0;
        events.forEach((event) => {
            let numberAnimals = parseInt(event.numberAnimals, 10);
            if (["Sale", "SaleToWorks"].includes(event.eventType)) {
                numberAnimals = -numberAnimals;
            }
            monthTotal += numberAnimals;
        });
    });
    return monthTotal;
};

export const animalMonthlyCounts = (enterprise) => {
    let runningTotal = 0;
    const slots = domain.farmYear.map((month) => {
        let monthTotal = monthlyTotal(enterprise, month);
        if (enterprise.peakCowNumbers && enterprise.specificationMethod !== "MonthlyStockRec" && enterprise.type === "Dairy") {
            runningTotal = enterprise.peakCowNumbers.amount;
        } else if (enterprise.specificationMethod === "RSU" && ["Sheep", "Deer", "Beef"].includes(enterprise.type)) {
            runningTotal = enterprise.numberOfRSU;
        } else {
            runningTotal += monthTotal;
        }
        return { month, text: runningTotal === 0 ? "-" : runningTotal };
    });
    return slots;
};

export const validateLactationDates = (strMidCalvingDate, strDryDate) => {
    if (!strDryDate || !strMidCalvingDate) return [true, 0];
    const dryDate = moment(strDryDate, "DD MMMM");
    dryDate.year(2014);
    const midCalvingDate = moment(strMidCalvingDate, "DD MMMM");
    midCalvingDate.year(2014);
    midCalvingDate.year(dryDate < midCalvingDate ? 2013 : 2014);
    const days = dryDate.diff(midCalvingDate, "days");
    return [days > 99 && days < 336, days];
};

const _otherLivestockTypes = [
    {
        value: "Goats",
        text: "Goats",
        children: [
            { name: "Bucks and does to 1 yr", rsu: "0.5" },
            { name: "Angora", rsu: "1.1" },
            { name: "Feral does", rsu: "0.9" },
            { name: "Feral wethers and bucks", rsu: "0.5" },
        ],
    },
    {
        value: "Horses",
        text: "Horses",
        children: [
            { name: "Pony in light work or turned out", rsu: "6.0" },
            { name: "Pony broodmare and foal", rsu: "8.0" },
            { name: "Small hack (up to 15.2 hands) in light work", rsu: "8.0" },
            { name: "Small hack broodmare and foal", rsu: "10.0" },
            { name: "Large (500-600 kg) hack in light work", rsu: "12.0" },
            { name: "Yearling thoroughbred", rsu: "12.0" },
            { name: "Large hack broodmare and foal", rsu: "14.0" },
        ],
    },
    {
        value: "Camelids",
        text: "Alpacas/Llamas",
        children: [
            { name: "Alpacas", rsu: "0.8" },
            { name: "Llamas", rsu: "1.6" },
        ],
    },
    {
        value: "Other",
        text: "Other",
        children: [],
    },
];

export const getOtherLivestockTypeOptions = (livestocks) => {
    const livestockTypeOptions = _otherLivestockTypes.reduce((options, option) => {
        if ((livestocks || []).some((l) => l.type === option.value)) {
            options.push({
                ...option,
                groupIndex: 0,
                groupLabel: "Already on this farm",
            });
        } else {
            options.push({
                ...option,
                groupIndex: 1,
                groupLabel: "Not on this farm",
            });
        }
        return options;
    }, []);
    return livestockTypeOptions;
};

export const getOtherLivestockType = (type) => {
    return _otherLivestockTypes.find((opt) => opt.value === type);
};

export const getOtherLivestockStockClassOptions = (livestockType) => {
    if (!livestockType) return [];

    const otherLivestockType = _otherLivestockTypes.find((t) => t.value === livestockType);
    if (!otherLivestockType) return [];

    const options = otherLivestockType.children.map((stockClass) => ({
        value: stockClass.name,
        text: stockClass.name,
        groupIndex: 0,
        groupLabel: "System stock classes",
    }));

    options.push({
        value: "UserDefined",
        text: "User defined",
        groupIndex: 1,
        groupLabel: "Custom stock class",
    });

    return options;
};

export const getOtherLivestockStockClass = (name) => {
    return _otherLivestockTypes.reduce((result, option) => {
        const stockClass = option.children.find((sc) => sc.name === name);
        if (stockClass) result = stockClass;
        return result;
    }, undefined);
};

export const isCustomOtherLivestockStockClass = (livestock) => {
    if (livestock.type === "Other") return true;

    const stockClass = getOtherLivestockStockClass(livestock.stockClass);
    if (!stockClass && (livestock.stockClass || livestock.stockClass === "")) return true;

    return false;
};

export const getNonDairyGoatEnterpriesesOnPastureBlocks = (enterprises, blocks) => {
    if (!enterprises || enterprises.length === 0) return [];

    if (!blocks || blocks.length === 0) return [];

    const dairyGoat = enterprises.find((enterprise) => enterprise.type === "DairyGoat");
    if (!dairyGoat) return [];

    const isDairyGoatOnPastureBlock = blocks.filter((b) => b.type === "ProductivePasture").some((b) => b.animals.some((a) => a.enterpriseId === dairyGoat.id));
    if (!isDairyGoatOnPastureBlock) return [];

    const nonDairyGoatEnterprises = enterprises.filter((enterprise) => enterprise.type !== "DairyGoat");
    const nonDairyGoatEnterprisesOnPastureBlocks = nonDairyGoatEnterprises.reduce((results, enterprise) => {
        const enterpriseIsOnAPastureBlock = blocks.filter((b) => b.type === "ProductivePasture").some((b) => b.animals.some((a) => a.enterpriseId === enterprise.id));
        if (enterpriseIsOnAPastureBlock) results.push(enterprise);

        return results;
    }, []);
    return nonDairyGoatEnterprisesOnPastureBlocks;
};

export const getNonDairyGoatWinterpingPads = (budget) => {
    const dairyGoatEnterprise = (budget.enterprises || []).find((e) => e.type === "DairyGoat");
    if (!dairyGoatEnterprise) return [];

    return (budget.structures || []).filter((s) => ["CoveredWinteringPad", "UncoveredWinteringPad"].includes(s.type) && s.enterpriseId !== dairyGoatEnterprise.id);
};

export const hasDairyGoatWinteringPad = (budget) => {
    const dairyGoatEnterprise = (budget.enterprises || []).find((e) => e.type === "DairyGoat");
    if (!dairyGoatEnterprise) return false;

    return (budget.structures || []).some((s) => ["CoveredWinteringPad", "UncoveredWinteringPad"].includes(s.type) && s.enterpriseId === dairyGoatEnterprise.id);
};

export const hasNonDairyGoatEnterprises = (budget) => {
    const nonDairyGoatEnterprises = getNonDairyGoatEnterpriesesOnPastureBlocks(budget.enterprises, budget.blocks);
    return nonDairyGoatEnterprises.length > 0;
};

export const hasNonDairyGoatWinteringPads = (budget) => {
    const nonDairyGoatWinteringPads = getNonDairyGoatWinterpingPads(budget);
    return nonDairyGoatWinteringPads.length > 0;
};

/**
* Clean up the analysis from saving the enterprise and return the updated analysis
*/
export function getUpdatedAnalysisFromSavingEnterprise(analysis, enterprise) {

    const enterprises = (analysis.enterprises || []).map((e) => {
        return e.id === enterprise.id ? enterprise : e;
    });

    if (!enterprises.some((e) => e.id === enterprise.id)) {
        enterprises.push(enterprise);
        // Must be a new enterprise. Add it to any dairy goat left overs.
        const dairyGoat = enterprises.find((e) => e.type === "DairyGoat");
        if (dairyGoat && dairyGoat.dairyGoatLeftOvers) {
            enterprises.forEach((ent) => {
                if (ent.id !== dairyGoat.id) {
                    if (dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers) {
                        if (!dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers.enterprisePercentages.some((ep) => ep.enterpriseId === ent.id)) {
                            dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers.enterprisePercentages.push({ enterpriseId: ent.id, percentage: 0 });
                        }
                    }

                    if (dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers) {
                        if (dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.fate === "FedOnPastoralBlocks" && !dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages.some((ep) => ep.enterpriseId === ent.id)) {
                            dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages.push({ enterpriseId: ent.id, percentage: 0 });
                        }
                    }
                }
            });
        }
        // Add an outdoor pig hut if enterprise is outdoor pig
        if (enterprise.type === "OutdoorPigs") {
            analysis.structures.push({ id: uuidv4(), type: "OutdoorPigHuts", enterpriseId: enterprise.id });
        }
    }

    // Clean up outdoor pig structures
    if (enterprise.type === "OutdoorPigs") {
        if (enterprise.outdoorPigs.growOutUnitOnly) analysis.structures = analysis.structures.filter((s) => s.type !== "OutdoorPigFarrowingVillages");

        if (analysis.outdoorPigEffluentSystem) {
            if (!analysis.structures.some((s) => requiresOutdoorPigLiquidApplications(s))) delete analysis.outdoorPigEffluentSystem.liquidEffluentApplications;

            if (!analysis.structures.some((s) => requiresOutdoorPigSolidApplications(s))) delete analysis.outdoorPigEffluentSystem.pondSolidApplications;

            if (!analysis.structures.some((s) => requiresOutdoorPigStrawCompostApplications(s))) delete analysis.outdoorPigEffluentSystem.strawCompostApplications;
        }
    }

    const animalDistribution = {
        ...analysis.animalDistribution,
    };

    const blocks = enterprise.isNew
        ? (analysis.blocks || []).map((block) => {
            if (canDistributeAnimals(block)) {
                let animals = block.animals || [];
                if (!animals.some((a) => a.enterpriseId === enterprise.id)) {
                    animals = animals.concat({
                        enterpriseId: enterprise.id,
                        months: [],
                    });
                }
                return {
                    ...block,
                    relativeProductivity: animalDistribution.relProdMethod ? block.relativeProductivity : 1,
                    animals,
                };
            } else {
                return {
                    ...block,
                    animals: [],
                };
            }
        })
        : analysis.blocks;

    return {
        ...analysis,
        animalDistribution: {
            ...animalDistribution,
            relProdMethod: animalDistribution.relProdMethod || 0,
        },
        blocks,
        enterprises,
    }
}

/**
 * Clean up the analysis cleanup from saving animal distribution and return the updated analysis.
 */
export function getUpdatedAnalyisFromSavingDistribution(analysis, animalDistribution) {

    const selectedEnterpriseTypes = [];
    animalDistribution.distributions.forEach((distribution) => {
        distribution.animals.forEach((animal) => {
            if (selectedEnterpriseTypes.includes(animal.enterpriseType) === false) {
                selectedEnterpriseTypes.push(animal.enterpriseType);
            }
        });
    });
    if (animalDistribution.hasOutdoorPigs) {
        selectedEnterpriseTypes.push("OutdoorPigs");
    }

    let enterprises = analysis.enterprises.filter((enterprise) => selectedEnterpriseTypes.includes(enterprise.type));
    const blocks = analysis.blocks.map((block) => {
        if (!canDistributeAnimals(block)) return { ...block, animals: [] };

        const distribution = animalDistribution.distributions.find((d) => d.blockId === block.id);
        if (!distribution) return { ...block, animals: [] };

        distribution.animals = distribution.animals.map((animal) => {
            let enterprise = enterprises.find((e) => e.type === animal.enterpriseType);
            if (!enterprise) {
                enterprise = { id: uuidv4(), type: animal.enterpriseType, mobs: [] };
                enterprises = enterprises.concat(enterprise);
            }

            return {
                months: animal.months,
                enterpriseId: enterprise.id,
                percenBlockPastureProd: showPercenBlockPastureProd(animalDistribution.blockStockRatios) ? utils.round(animal.percenBlockPastureProd, 0) : null,
                hasStreamAccess: animal.hasStreamAccess,
                isFinishing: animal.isFinishing,
                isWallows: animal.isWallows,
                isPaceFenceline: animal.isPaceFenceline,
                isMerino: animal.isMerino,
            };
        });

        return {
            ...block,
            relativeProductivity: showRelativeProductivity(animalDistribution.relProdMethod) ? utils.round(distribution.relativeProductivity, 2) : 1,
            animals: distribution.animals,
        };
    });

    let structures = undefined;
    if (analysis.structures) {
        structures = analysis.structures.map((s) => {
            return enterprises.find((e) => e.id === s.enterpriseId) ? s : { ...s, ...{ enterpriseId: undefined, animals: undefined } };
        });
    }

    if (analysis.effluentSystem && !enterprises.some((e) => ["Dairy", "DairyGoat"].includes(e.type))) {
        analysis.effluentSystem = undefined;
    }

    // Clean up associated feed supplements.
    if (analysis.feedSupplements && analysis.feedSupplements.length > 0) {
        const enterpriseIds = enterprises.map((e) => e.id);
        const supplements = analysis.feedSupplements.reduce((results, supplement) => {
            const destinations = (supplement.destinations || []).filter((destination) => destination.type !== "Enterprise" || enterpriseIds.includes(destination.enterpriseId));
            results.push({ ...supplement, destinations });
            return results;
        }, []);
        analysis.feedSupplements = supplements;
    }

    // Clean up dairy goat left overs
    // Make sure any enterprises newly distributed/removed to/from pasture blocks are added/removed to/from specified left over feeding.
    const dairyGoat = enterprises.find((e) => e.type === "DairyGoat");
    if (dairyGoat && dairyGoat.dairyGoatLeftOvers) {
        const nonDairyGoatEnterprisesOnPastureBlocks = getNonDairyGoatEnterpriesesOnPastureBlocks(enterprises, blocks);
        if (dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers) {
            nonDairyGoatEnterprisesOnPastureBlocks.forEach((ent) => {
                if (!dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers.enterprisePercentages.some((ep) => ep.enterpriseId === ent.id)) {
                    dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers.enterprisePercentages.push({ enterpriseId: ent.id, percentage: 0 });
                }
            });

            const enterprisePercentages = dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers.enterprisePercentages.filter((ep) => {
                return nonDairyGoatEnterprisesOnPastureBlocks.some((e) => e.id === ep.enterpriseId);
            });
            if (enterprisePercentages.length === 0) {
                delete dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers;
            } else {
                dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers.enterprisePercentages = enterprisePercentages;
            }
        }
        if (dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers && dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.fate === "FedOnPastoralBlocks") {
            nonDairyGoatEnterprisesOnPastureBlocks.forEach((ent) => {
                if (!dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages.some((ep) => ep.enterpriseId === ent.id)) {
                    dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages.push({ enterpriseId: ent.id, percentage: 0 });
                }
            });

            const enterprisePercentages = dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages.filter((ep) => {
                return nonDairyGoatEnterprisesOnPastureBlocks.some((e) => e.id === ep.enterpriseId);
            });
            if (enterprisePercentages.length === 0) {
                delete dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers;
            } else {
                dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages = enterprisePercentages;
            }
        }
    }

    return {
        ...analysis,
        animalDistribution: { relProdMethod: animalDistribution.relProdMethod, blockStockRatios: animalDistribution.blockStockRatios },
        enterprises: enterprises,
        blocks: blocks,
        structures: structures,
    };
}

/**
 * Clean up the analysis cleanup from deleting an enterprise and return the updated analysis.
 */
export function getUpdatedAnalysisFromDeletingEnterprise(analysis, enterpriseId) {

    let enterprises = analysis.enterprises || [];

    const idsToBeDeleted = [enterpriseId];
    const enterpriseBeingDeleted = enterprises.find((e) => e.id === enterpriseId);
    if (enterpriseBeingDeleted && enterpriseBeingDeleted.type === "Dairy") {
        // If dairy being deleted, delete dairy effluent system.
        delete analysis.effluentSystem;

        // If dairy being deleted, delete dairy replacements too.
        const dairyReplacement = enterprises.find((e) => e.type === "DairyReplacements");
        if (dairyReplacement) {
            idsToBeDeleted.push(dairyReplacement.id);
        }
    }

    // Clean up associated structures
    analysis.structures = (analysis.structures || []).filter((s) => s.enterpriseId !== enterpriseId);

    enterprises = enterprises.filter((e) => !idsToBeDeleted.includes(e.id));

    // Clean up associated dairy goat left overs.
    const dairyGoat = enterprises.find((e) => e.type === "DairyGoat");
    if (dairyGoat && dairyGoat.dairyGoatLeftOvers) {
        if (dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers) {
            const enterprisePercentages = dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers.enterprisePercentages.filter((ep) => !idsToBeDeleted.includes(ep.enterpriseId));
            if (enterprisePercentages.length === 0) {
                delete dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers;
            } else {
                dairyGoat.dairyGoatLeftOvers.pastureBlockLeftOvers.enterprisePercentages = enterprisePercentages;
            }
        }

        if (dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers && dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.fate === "FedOnPastoralBlocks") {
            const enterprisePercentages = dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages.filter((ep) => !idsToBeDeleted.includes(ep.enterpriseId));
            if (enterprisePercentages.length === 0) {
                delete dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers;
            } else {
                dairyGoat.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages = enterprisePercentages;
            }
        }
    }

    // Clean up associated block animal distributions.
    const animalDistribution = analysis.animalDistribution || {};
    if (enterprises.length === 1) {
        animalDistribution.blockStockRatios = "AnimalEquivalent";
    }

    // Removed animal grazing references
    const filterAnimalGrazing = (parent) => {
        if (parent.animalGrazing && parent.animalGrazing.percentageGrazed) {
            parent.animalGrazing.percentageGrazed = parent.animalGrazing.percentageGrazed.filter((a) => !idsToBeDeleted.includes(a.enterpriseId));
            const { length } = parent.animalGrazing.percentageGrazed;
            if (length === 0) {
                //No enterprises exist so delete the animal grazing
                delete parent.animalGrazing;
            } else if (length === 1) {
                //We now only have one enterprise so allocate all to that
                parent.animalGrazing.percentageGrazed[0].percentage = 100;
            }
        }
    }

    const blocks = (analysis.blocks || []).map((block) => {
        const animals = (block.animals || []).filter((a) => !idsToBeDeleted.includes(a.enterpriseId));
        const { crops = [], cropBlock = {} } = block;
        //History
        filterAnimalGrazing(cropBlock);
        /* eslint-disable no-unused-vars */
        for (const crop of crops) {
            //Sown
            filterAnimalGrazing(crop);
            const { events = [] } = crop;
            /* eslint-disable no-unused-vars */
            for (const event of events.filter((e) => e.type === "Defoliation")) {
                //Defoliation
                filterAnimalGrazing(event);
                if (["Winteringpadbarn", "Onpastoralblocks"].includes(event.defoliationFate) && !event.animalGrazing) {
                    delete event.defoliationFate;
                }
            }
        }
        if (animals.length === 1 && enterprises.length === 1) {
            delete animals[0].percenBlockPastureProd;
        }
        return {
            ...block,
            animals,
        };
    });

    // Clean up associated feed supplement destinations.
    const feedSupplements = (analysis.feedSupplements || []).map((supplement) => {
        const destinations = (supplement.destinations || []).filter((destination) => {
            const enterpriseStillExists = !idsToBeDeleted.includes(destination.enterpriseId);
            const structureStillExists = destination.type !== "Structure" || analysis.structures.some((s) => s.id === destination.structureId);
            return enterpriseStillExists && structureStillExists;
        });
        return {
            ...supplement,
            destinations,
        };
    });

    // Clean up associated dcd applications.
    const nitrateInhibitors = (analysis.nitrateInhibitors || [])
        .map((nitrateInhibitor) => {
            const enterpriseIds = (nitrateInhibitor.enterpriseIds || []).filter((id) => !idsToBeDeleted.includes(id));
            return {
                ...nitrateInhibitor,
                enterpriseIds,
            };
        })
        .filter((nitrateInhibitor) => nitrateInhibitor.enterpriseIds.length > 0);

    // Clean up any associated outdoor pig structures
    if (enterpriseBeingDeleted.type === "OutdoorPigs") {
        delete analysis.outdoorPigEffluentSystem;
        analysis.structures = (analysis.structures || []).filter((s) => !["OutdoorPigHuts", "OutdoorPigBarns", "OutdoorPigFarrowingVillages"].includes(s.type));
    }

    return {
        ...analysis,
        enterprises,
        blocks,
        feedSupplements,
        nitrateInhibitors,
        animalDistribution,
    }
}