import EffluentActionBar from "./EffluentActionBar";
import StructureList from "./StructureList";
import BlockList from "./BlockList";
import NoEffluent from "./NoEffluent";

export default function Effluent({ farm, analysis }) {
    const { structures = [], enterprises = [] } = analysis;

    const hasCows = enterprises.some((e) => ["Dairy"].includes(e.type));
    const structuresNotRequired = enterprises.every((e) => ["Sheep", "Other"].includes(e.type));
    const hasDairy = enterprises.some((e) => ["Dairy", "DairyGoat"].includes(e.type));
    const hasOutdoorPigs = enterprises.some((e) => ["OutdoorPigs"].includes(e.type));
    const noEnterprises = enterprises.length === 0;
    let mainControl = null;

    if (structures.length > 0 || analysis.effluentSystem) {
        mainControl = (
            <div>
                <StructureList farm={farm} analysis={analysis} />
                <BlockList farm={farm} analysis={analysis} />
            </div>
        );
    } else {
        mainControl = <NoEffluent farm={farm} analysis={analysis} noEnterprises={noEnterprises} hasDairy={hasDairy} hasCows={hasCows} hasOutdoorPigs={hasOutdoorPigs} structuresNotRequired={structuresNotRequired} />;
    }

    return (
        <>
            <EffluentActionBar farm={farm} analysis={analysis} hasDairy={hasDairy} hasCows={hasCows} hasOutdoorPigs={hasOutdoorPigs} />
            {mainControl}
        </>
    )
}
