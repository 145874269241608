import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import Soil from "./Soil";
import SoilTestsCreate from "./SoilTestsCreate";
import SoilTestsEdit from "./SoilTestsEdit";
import BlockSoils from "./BlockSoils";

export default function SoilTab({ farm, analysis }) {
    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.SOIL} />
            <TileBody>
                <Switch>
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/soil" render={() => <Soil farm={farm} analysis={analysis} />} restoreScrollY />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/soil/blocks/:blockId" render={() => <BlockSoils farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/soil/soilTests/new" render={() => <SoilTestsCreate farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/soil/soilTests/:soilTestsId" render={() => <SoilTestsEdit farm={farm} analysis={analysis} />} />
                </Switch>
            </TileBody>
        </Tile>
    )
}
