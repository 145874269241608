import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as domain from "common/domain";
import ActionLink from "components/ActionLink";
import Card from "components/new/Card";
import CardHeader from "components/new/CardHeader";
import CardHeaderColumn from "components/new/CardHeaderColumn";
import CardHeaderField from "components/new/CardHeaderField";
import CardBody from "components/new/CardBody";
import CardActions from "components/new/CardActions";
import CardActionsRight from "components/new/CardActionsRight";
import { useConfirm, useNavigate, useRefData } from "common/hooks";
import * as structureUtils from "./_utils";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function OutdoorPigStructureCard({ farm, analysis, structure, icon }) {
    const refData = useRefData();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const structureName = utils.valueToText(refData.structureType, structure.type);
    const editStructureUrl = `/app/farm/${farm.id}/analysis/${analysis.id}/structures/${structure.type}`;

    const _editStructure = () => navigate(editStructureUrl);

    const _deleteStructure = () => {
        confirm(`Are you sure you want to delete the "${structureName}"?`, async () => {
            const updatedAnalysis = structureUtils.getUpdatedAnalysisFromDeletingStructure(analysis, structure.id);
            await updateAnalysisAsync(updatedAnalysis);
        });
    };

    const isOutdoorPigHuts = structure.type === "OutdoorPigHuts";

    const liquidManagement = ["Item2ponddischarge", undefined].includes(structure.effluentDisposal) ? "-" : utils.valueToText(refData.winPadEffTreat, structure.effluentDisposal);
    const pondSolidsManagement = structure.sludgeDisposal ? utils.valueToText(refData.solidsManagement, structure.sludgeDisposal) : "-";

    let strawDisposalRequired = false;
    let strawCompostManagemnt = structure.strawDisposal ? utils.valueToText(refData.solidsManagement, structure.strawDisposal) : "-";
    if (isOutdoorPigHuts) {
        strawDisposalRequired = (structure.strawDisposals || []).length === 0;
        strawCompostManagemnt = strawDisposalRequired ? "Required" : (structure.strawDisposals || []).map((sd) => `${utils.valueToText(refData.outdoorPigStrawDisposalMethods, sd.strawDisposal)} (${sd.proportion}%)`).join(", ");
    }

    return (
        <>
            <Card>
                <CardHeader id={structure.id} dataWidth="md-100 xl-40" hasError={strawDisposalRequired} titleIcon={icon} title={structureName} onTitleClick={_editStructure} onHeaderClick={_editStructure}>
                    <CardHeaderColumn dataWidth="md-30 xl-20">
                        <CardHeaderField label="Straw compost management" value={strawCompostManagemnt} hasError={strawDisposalRequired} />
                    </CardHeaderColumn>
                    <CardHeaderColumn dataWidth="md-30 xl-20">
                        <CardHeaderField label="Pond solids management" value={pondSolidsManagement} />
                    </CardHeaderColumn>
                    <CardHeaderColumn dataWidth="md-30 xl-20">
                        <CardHeaderField label="Liquid management" value={liquidManagement} />
                    </CardHeaderColumn>
                </CardHeader>
                {structure.type === "OutdoorPigBarns" && (
                    <CardBody className="u-ml-sm">
                        <div className="Table">
                            <table className="Calendar">
                                <thead>
                                    <tr className="Calendar-months">
                                        <th className="Calendar-title">Animals</th>
                                        {domain.farmYear.map((m, i) => (
                                            <th className="Calendar-month" key={i}>
                                                {m.substring(0, 3)}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="Calendar-slots Calendar-slots--fertiliser">
                                        <td className="td--keyValuePair">
                                            <span className="td-key">Outdoor pigs</span>

                                            <Link to={editStructureUrl} id={`${structure.id}_structure`} className="td-value">
                                                <span className="td-value-text">{structureName}</span>
                                                <span className="icon icon-edit" />
                                            </Link>
                                        </td>
                                        {domain.farmYear.map((month) => {
                                            let hasPlacements = false;
                                            Object.keys(structure.pigBarnPlacements).forEach((key) => {
                                                if (structure.pigBarnPlacements[key].some((p) => p.month === month && p.amount > 0)) {
                                                    hasPlacements = true;
                                                }
                                            });

                                            return (
                                                <td key={month} id={`${structure.id}-${month}`} className="Calendar-slot">
                                                    {hasPlacements && (
                                                        <>
                                                            <img className="Calendar-icon u-p-xxs" src={utils.getAnimalIcon("OutdoorPigs")} width={45} height={45} alt="Outdoor pigs" />
                                                            <span className="Calendar-icon-text"></span>
                                                        </>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                )}
                <CardActions>
                    <CardActionsRight>
                        <ul className="ActionsBar-links">
                            {!isOutdoorPigHuts && (
                                <li>
                                    <ActionLink id={`delete-structure-${structure.id}`} className="IconLink--cross-circle" onClick={_deleteStructure}>
                                        Delete structure
                                    </ActionLink>
                                </li>
                            )}
                            {!isOutdoorPigHuts && (
                                <li>
                                    <Link to={editStructureUrl} id={`edit_${structure.id}_structure`} name={`${structure.type}_${structure.id}`} className="IconLink--edit">
                                        Edit structure
                                    </Link>
                                </li>
                            )}
                            {isOutdoorPigHuts && (
                                <li>
                                    <Link to={editStructureUrl} id={`edit_${structure.id}_structure`} name={`manage-straw-disposal`} className={`IconLink--settings ${strawDisposalRequired ? "u-textError" : ""}`}>
                                        Manage straw disposal
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </CardActionsRight>
                </CardActions>
            </Card>
        </>
    )
}
