import { useMutation, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { useConfirm } from "common/hooks";
import ActionLink from "components/ActionLink";
import { useRefreshOrgSearchResultsIfAny } from "containers/Admin/Search";

export default function DeleteUserActionLink({ user, children = null, className = "IconLink--trash" }) {
    const confirm = useConfirm();
    const deleteUserAsync = useDeleteUserAsync();

    const confirmDeleteUser = () => {
        confirm(`Are you sure you want to delete ${user.fullName}?`, () => deleteUserAsync(user));
    };

    return (<>
        <ActionLink id={`delete-user-${user.id}`} title="Delete user" className={className} onClick={confirmDeleteUser}>
            {children}
        </ActionLink>
    </>);
}

const useDeleteUserAsync = () => {
    const queryClient = useQueryClient();
    const refreshOrgSearchResultsIfAny = useRefreshOrgSearchResultsIfAny();

    const mutation = useMutation({
        mutationFn: async (user) => httpClient.del(`admin/accounts/${user.accountId}/users/${user.id}`, { id: user.id, accountId: user.accountId }),
        // These callbacks ares called BEFORE their mutateAsync versions, even if the component is unmounted
        onSuccess: () => queryClient.removeQueries({ queryKey: ["orgs"] }),
        onSettled: () => refreshOrgSearchResultsIfAny()
    });

    return (user) => mutation.mutateAsync(user, {
        // These callbacks are called AFTER their useMutation versions but ONLY if the component is still mounted
        onSuccess: () => console.log("success and still mounted"),
        onSettled: () => console.log("settled and still mounted"),
    });
}
