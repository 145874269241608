import React, { Component } from "react";
import { Page, pdfjs, Document } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import ScrollToTop from "react-scroll-up";
import * as icons from "common/icons";
import ActionLink from "./ActionLink";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Pdf extends Component {
    constructor(props) {
        super(props);
        const { location = {} } = this.props;
        const params = new URLSearchParams(location.search);
        const page = params.get("page");
        const pageNum = page && page !== null ? parseInt(page, 10) : undefined;
        this.state = {
            pageNumber: pageNum || 1,
            usePaging: pageNum,
        };
    }

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    };

    render() {
        const { pageNumber, numPages, usePaging } = this.state;
        const { fileName, scale = 1.8 } = this.props;
        const loading = (
            <div>
                <h2>Loading document...</h2>
            </div>
        );

        const paging = (
            <div className="u-split">
                <p>
                    Page {pageNumber} of {numPages}
                </p>
                <div></div>
                <div>
                    {pageNumber > 1 && (
                        <ActionLink onClick={() => this.setState({ pageNumber: this.state.pageNumber - 1 })} className="RoundButton RoundButton--left u-mr-sm">
                            <span></span>
                        </ActionLink>
                    )}
                    {pageNumber < numPages && (
                        <ActionLink onClick={() => this.setState({ pageNumber: this.state.pageNumber + 1 })} className="RoundButton RoundButton--right">
                            <span></span>
                        </ActionLink>
                    )}
                </div>
            </div>
        );

        return (
            <div style={{ padding: "15px" }}>
                {usePaging && numPages && paging}
                <div className="flex-center">
                    <Document file={fileName} onLoadSuccess={this.onDocumentLoad} loading={loading}>
                        {usePaging ? <Page pageNumber={pageNumber} scale={scale} loading="" /> : Array.from(new Array(numPages), (el, index) => <Page key={`page_${index + 1}`} pageNumber={index + 1} loading="" scale={scale} />)}
                    </Document>
                </div>
                {usePaging && numPages && paging}

                {!usePaging && (
                    <ScrollToTop
                        showUnder={160}
                        style={{
                            position: "fixed",
                            bottom: 6,
                            right: 75,
                            cursor: "pointer",
                            transitionDuration: "0.2s",
                            transitionTimingFunction: "linear",
                            transitionDelay: "0s",
                        }}
                    >
                        <img src={icons.gotoTop} style={{ width: "56px", height: "56px" }} alt="Goto top" className="u-p-5"/>
                    </ScrollToTop>
                )}
            </div>
        );
    }
}

export default Pdf;
