import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import Supplements from "./Supplements";
import FeedSupplement from "./FeedSupplement";
import Distribution from "./Distribution";

export default function SupplementsTab({ farm, analysis }) {
    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.SUPPLEMENTS} />
            <TileBody>
                <Switch>
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/supplements" render={() => <Supplements farm={farm} analysis={analysis} />} restoreScrollY />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/supplements/harvested" render={() => <FeedSupplement farm={farm} analysis={analysis} type="Harvested" />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/supplements/harvested/:feedSupplementId" render={() => <FeedSupplement farm={farm} analysis={analysis} type="Harvested" />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/supplements/purchased" render={() => <FeedSupplement farm={farm} analysis={analysis} type="Purchased" />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/supplements/purchased/:feedSupplementId" render={() => <FeedSupplement farm={farm} analysis={analysis} type="Purchased" />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/supplements/fromStorage" render={() => <FeedSupplement farm={farm} analysis={analysis} type="FromStorage" />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/supplements/fromStorage/:feedSupplementId" render={() => <FeedSupplement farm={farm} analysis={analysis} type="FromStorage" />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/supplements/:feedSupplementId/distribution" render={() => <Distribution farm={farm} analysis={analysis} />} />
                </Switch>
            </TileBody>
        </Tile>
    )
}
