import { useParams } from "react-router-dom";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import Details from "./Details";

export default function IrrigationEdit({ farm, analysis }) {
    const { irrigatorId } = useParams();
    const irrigator = analysis.irrigators.find((i) => i.id === irrigatorId);

    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.IRRIGATION} />
            <TileBody>
                <Details farm={farm} analysis={analysis} irrigator={irrigator} />
            </TileBody>
        </Tile>
    )
}
