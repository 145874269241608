import { Panel, PanelBody } from "components/Panel";
import OutdoorPigNumbersSows, { validateSows } from "./OutdoorPigNumbersSows";
import OutdoorPigNumbersBoars, { validateBoars } from "./OutdoorPigNumbersBoars";
import OutdoorPigNumbersUnmatedGilts, { validateUnmatedGilts } from "./OutdoorPigNumbersUnmatedGilts";
import OutdoorPigNumbersGrowersFinishers, { validateGrowersFinishers } from "./OutdoorPigNumbersGrowersFinishers";

export default function OutdoorPigNumbers({ form, outdoorPigs }) {
    return (
        <Panel title="Numbers" midBlue notCollapsible>
            <PanelBody>
                {!outdoorPigs.growOutUnitOnly && (
                    <>
                        <OutdoorPigNumbersSows outdoorPigs={outdoorPigs} />
                        <OutdoorPigNumbersBoars />
                        <OutdoorPigNumbersUnmatedGilts form={form} outdoorPigs={outdoorPigs} />
                    </>
                )}
                <OutdoorPigNumbersGrowersFinishers form={form} outdoorPigs={outdoorPigs} />
            </PanelBody>
        </Panel>
    )
}

export const validateNumbers = (growOutUnitOnly, numbers) => {
    const errors = {};

    if (!growOutUnitOnly) {
        errors.sows = validateSows(numbers.sows || {});
        errors.boars = validateBoars(numbers.boars || {});
        errors.unmatedGilts = validateUnmatedGilts(numbers.unmatedGilts || {});
    }
    errors.growersFinishers = validateGrowersFinishers(growOutUnitOnly, numbers.growersFinishers || {});

    return errors;
}
