import { Component } from "react";
import NumericInputPack from "components/NumericInputPack";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import ActionLink from "./ActionLink";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function AnimalDistribution(props) {

    return <AnimalDistributionClassComponent {...props} />
}

class AnimalDistributionClassComponent extends Component {
    render() {
        const { structure, applications = [], validation, onChange, deleteApplication, dairyOnly, hideTime, animalsTab, enterprises, daysPerMonth, canDeleteAnimals, percentLabel, addApplication, disableAddAnimals, noAnimalHeading, readOnly } = this.props;
        let tbody = null;

        if (enterprises.length === 0 && !disableAddAnimals) {
            tbody = (
                <tbody>
                    <tr>
                        <td colSpan="4">
                            <div className="Tile-body">
                                <div className="Tile-body-message">
                                    <p className="h4 u-mt-0">You do not have any {dairyOnly && "dairy"} enterprises</p>
                                    <Link to={animalsTab} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                        <span>Take me to animals</span>
                                    </Link>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            );
        } else if (applications.length > 0) {
            tbody = (
                <tbody>
                    {applications.map((application, index) => {
                        const { months = [] } = application;
                        const monthGroupVal = validation[`${application.id}_month`] || {};
                        let timeGrazing = utils.round(!isNaN(application.time) ? 24 - application.time : "", 2);
                        timeGrazing = timeGrazing < 0 ? "-" : timeGrazing;
                        return (
                            <tr key={index} id={application.id} name={index}>
                                <td>
                                    <NumericInputPack id={`${application.id}_percentageOfAnimals`} uom="%" readOnly={readOnly} requiredLabel={false} val={validation[`${application.id}_percentageOfAnimals`]} onChange={(e) => onChange(e, { type: "animals", key: "percentageOfAnimals", id: `${application.id}_percentageOfAnimals`, applicationId: application.id })} decimalPlaces={0} value={application.percentageOfAnimals} />
                                </td>
                                {!hideTime && (
                                    <>
                                        <td>
                                            <NumericInputPack id={`${application.id}_time`} uom={daysPerMonth ? "Days" : "Hours"} readOnly={readOnly} requiredLabel={false} val={validation[`${application.id}_time`]} onChange={(e) => onChange(e, { type: "animals", key: "time", id: `${application.id}_time`, applicationId: application.id })} decimalPlaces={1} value={application.time} />
                                        </td>
                                        {!daysPerMonth && <td>{timeGrazing}</td>}
                                    </>
                                )}
                                <td>
                                    <div className="AnnualCheckboxes">
                                        <ApplicationMonth label="Jul" id="July" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("July")} />
                                        <ApplicationMonth label="Aug" id="August" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("August")} />
                                        <ApplicationMonth label="Sep" id="September" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("September")} />
                                        <ApplicationMonth label="Oct" id="October" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("October")} />
                                        <ApplicationMonth label="Nov" id="November" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("November")} />
                                        <ApplicationMonth label="Dec" id="December" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("December")} />
                                        <ApplicationMonth label="Jan" id="January" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("January")} />
                                        <ApplicationMonth label="Feb" id="February" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("February")} />
                                        <ApplicationMonth label="Mar" id="March" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("March")} />
                                        <ApplicationMonth label="Apr" id="April" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("April")} />
                                        <ApplicationMonth label="May" id="May" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("May")} />
                                        <ApplicationMonth label="Jun" id="June" readOnly={readOnly} applicationId={application.id} onChange={(e, o) => onChange(e, o)} checked={months.includes("June")} />
                                    </div>
                                    {monthGroupVal.touched && monthGroupVal.error && (
                                        <div className="Field has-error u-mt-sm">
                                            <small className="Field-error" id={`${application.id}_months-error`}>
                                                {monthGroupVal.message}
                                            </small>
                                        </div>
                                    )}
                                </td>
                                <td>{(canDeleteAnimals || applications.length > 1) && <ActionLink className="IconLink--trash" id={`${application.id}_summary_remove`} onClick={() => deleteApplication(application.id)} />}</td>
                            </tr>
                        );
                    })}
                </tbody>
            );
        } else {
            tbody = (
                <tbody>
                    <tr>
                        <td colSpan={4}>
                            <div className="Tile-body">
                                <div className="Tile-body-message">
                                    <p className="h4 u-mt-0">{noAnimalHeading}</p>
                                    {!readOnly && <button className="IconLink--arrow-plus Button Button--secondary u-mt-md" id="add-application-btn" onClick={() => addApplication()}>
                                        <span>Add animal distribution</span>
                                    </button>}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            );
        }

        return (
            <div className="Table u-mt-md">
                <table>
                    <thead>
                        <tr>
                            <th>{percentLabel ? percentLabel : structure.type === "MilkingShed" ? "% of cows fed in-shed" : `% of ${dairyOnly ? "dairy" : ""} animals`}</th>
                            {!hideTime &&
                                (daysPerMonth ? (
                                    <th>Days per month</th>
                                ) : (
                                    <>
                                        <th>Hours on structure per day</th>
                                        <th>Hours grazing per day</th>
                                    </>
                                ))}
                            <th>Months</th>
                            <th className="th--shrink"></th>
                        </tr>
                    </thead>
                    {tbody}
                </table>
            </div>
        );
    }
}

function ApplicationMonth({ label, id, readOnly, applicationId, checked, onChange }) {
    return (
        <label className="Checkbox" htmlFor={`${applicationId}_${id}`}>
            <input className="Checkbox-input" type="checkbox" disabled={readOnly} checked={checked || false} id={`${applicationId}_${id}`} name={`${applicationId}_${id}`} onChange={(e) => onChange(e, { type: "animals", key: "month", id: `${applicationId}_month`, month: id, applicationId: applicationId })} />
            <span className="Checkbox-label">{label}</span>
        </label>
    )
}
