import React from "react";

const Results = ({ nameValues = [], splitBy = 3 }) => {
    const splitArrayIntoChunks = () => {
        var chunkList = [];
        var chunkLen = Math.ceil(nameValues.length / splitBy);
        for (var i = 0; i < splitBy; i++) {
            chunkList.push(nameValues.splice(0, chunkLen));
        }
        return chunkList;
    };

    const chunks = splitArrayIntoChunks();

    return (
        <div className="u-flex u-flex-wrap">
            {chunks.map((chunk, i) => {
                return (
                    <div key={i} className="Print-Table u-minw-400 u-pr-xxl">
                        <table>
                            <tbody>
                                {chunk.map((nvp, x) => (
                                    <tr key={x} style={{ borderBottom: "1px solid #eee" }}>
                                        <td style={{ verticalAlign: "top", padding: "5px 0 5px 0" }}>{nvp.name}</td>
                                        <td className="Nvp-Value" style={{ padding: "5px 0 5px 0", fontWeight: "600" }}>
                                            {nvp.value}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            })}
        </div>
    );
};

export default Results;
