import { JsonHubProtocol, HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr"; // version 1.0.4

import datasetActionTypes, { getDataset, getDatasets } from "./containers/Datasets/_actions";

const startSignalRConnection = (connection) =>
    connection
        .start()
        .then(() => {
            console.info("**************SignalR Connected");
        })
        .catch((err) => console.error("SignalR Connection Error: ", err));

const signalRMiddleware = (dispatch) => {
    // register signalR after the user logged in
    const connectionHub = process.env.REACT_APP_API_URL + "/signalrcontroller";

    const protocol = new JsonHubProtocol();

    // let transport to fall back to to LongPolling if it needs to
    const transport = HttpTransportType.WebSockets;
    const token = localStorage.getItem("token");

    const options = {
        transport,
        logMessageContent: true,
        logger: LogLevel.Info,
        accessTokenFactory: () => token,
    };

    // create the connection instance
    const connection = new HubConnectionBuilder().withUrl(connectionHub, options).withHubProtocol(protocol).build();

    // event handlers, you can use these to dispatch actions to update your Redux store
    connection.on(datasetActionTypes.DATASET_MODEL_TEST_COMPLETED, (data) => {
        dispatch(getDatasets());
        dispatch({ type: datasetActionTypes.DATASET_MODEL_TEST_COMPLETED, notification: data });
    });

    connection.on(datasetActionTypes.DATASET_PROCESSING_DATASET, (data) => {
        dispatch({ type: datasetActionTypes.DATASET_PROCESSING_DATASET, notification: data });
    });

    connection.on(datasetActionTypes.DATASET_MODEL_RUN_STARTED, (data) => {
        dispatch({ type: datasetActionTypes.DATASET_MODEL_RUN_STARTED, notification: data });
    });

    connection.on(datasetActionTypes.DATASET_MODEL_RUN_COMPLETED, (data) => {
        dispatch(getDataset(data.entityId));
        dispatch({ type: datasetActionTypes.DATASET_MODEL_RUN_COMPLETED, notification: data });
    });

    connection.on("disconnected", (data) => {
        console.info("**************SignalR Disconnected");
        setTimeout(startSignalRConnection(connection), 2000);
    });

    // re-establish the connection if connection dropped
    connection.onclose(() => {
        setTimeout(startSignalRConnection(connection), 1);
        console.info("**************SignalR Closed");
    });
    //connection.connectionClosed(() => setTimeout(startSignalRConnection(connection), 5000));

    startSignalRConnection(connection, dispatch);

    return connection;
};

export default signalRMiddleware;
