import { Fragment } from "react";
import { Form } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import * as utils from "common/utils";
import { usePortalModal } from "common/effects";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import { isSoilModified, getIsTextureGroupRequired, getModifiedSoilProfileData, getModifiedSoilPropertiesData } from "containers/Soils/_utils";
import { useRefData } from "common/hooks";

export default function ExistingSmapRefModal({ smapSoil, existingSoils, onSubmit, close }) {
    const refData = useRefData();
    const smapReference = smapSoil.sibling.smapReference;

    const onOptionSelected = (form) => (e) => {
        form.change("soilId", e.target.value);
    };

    const validate = (values) => {
        const validation = {};
        validation.soilId = FormUtils.validators.required(values.soilId);
        return validation;
    };

    const submit = (values) => {
        const selectedSoil = values.soilId === smapSoil.id ? smapSoil : existingSoils.find((s) => s.id === values.soilId);
        onSubmit && onSubmit(selectedSoil);
        close();
    };

    const warning = `You have selected to add the "${smapReference}" S-Map soil to this block. This analysis already has one or more existing soils with this same S-Map reference.
        These existing soils have user modified soil properties that no longer match the S-Map defaults. Do you want to add one of these existing soils to this block or do
        you want to add the selected S-Map soil as a new soil to this analysis?`;

    return (
        <Form initialValues={{}} validate={validate} onSubmit={submit}>
            {({ form, submitFailed, errors, handleSubmit, submitting }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`Existing S-Map reference - ${smapReference}`} close={close} waiting={submitting} wide>
                            <ModalBody warning={warning}>
                                <Grid title={`You have existing farm soils with this same S-Map reference - ${smapReference}`}>
                                    <GridCell>
                                        {errors.soilId && submitFailed && (
                                            <div className="Field has-error u-mt-xs">
                                                <small className="Field-error u-mt-0">{errors.soilId}</small>
                                            </div>
                                        )}
                                        <p>Either, select an existing modified version of this S-Map soil to add to this block</p>
                                        <div className="Table u-mt-xs">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="th--shrink"></th>
                                                        <th data-width="15">S-Map ref/Name</th>
                                                        <th>Order</th>
                                                        <th>Group</th>
                                                        <th data-width="15">Description</th>
                                                        <th data-width="20">Soil profile</th>
                                                        <th data-width="20">Soil properties</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {existingSoils.map((existingSoil) => {
                                                        const soilLabel = existingSoil.name || existingSoil.subTitle || "-";
                                                        const isTextureGroupRequired = getIsTextureGroupRequired(existingSoil);
                                                        const customSoilProfileData = getModifiedSoilProfileData(existingSoil, isTextureGroupRequired, refData);
                                                        const customSoilPropertiesData = getModifiedSoilPropertiesData(existingSoil, refData);
                                                        const id = `soilId-${existingSoil.id}`;
                                                        return (
                                                            <tr key={existingSoil.id}>
                                                                <td valign="top">
                                                                    <div className="Field">
                                                                        <div className="Radio_group--inline">
                                                                            <label className="Radio" htmlFor={id}>
                                                                                <input name="soilId" id={id} value={existingSoil.id} onChange={onOptionSelected(form)} className="Radio-input" type="radio" />
                                                                                <span className="Radio-label"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td valign="top">
                                                                    <div className="u-flexSplit">
                                                                        <span>{soilLabel}</span>
                                                                        {isSoilModified(existingSoil) && (
                                                                            <span className="IconLink--soil u-textWarning u-ml-md" title="Modified soil properties">
                                                                                Modified
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td valign="top">{utils.valueToText(refData.soilOrders, existingSoil.soilOrder) || "-"}</td>
                                                                <td valign="top">{utils.valueToText(refData.soilGroups, existingSoil.soilGroup)}</td>
                                                                <td valign="top">{existingSoil.sibling ? existingSoil.sibling.description : existingSoil.description || "-"}</td>
                                                                <td valign="top">
                                                                    {Object.keys(customSoilProfileData).length > 0
                                                                        ? Object.keys(customSoilProfileData).map((key, i) => {
                                                                              return (
                                                                                  <Fragment key={key}>
                                                                                      <div>
                                                                                          <b>{customSoilProfileData[key].title}</b>
                                                                                      </div>
                                                                                      {customSoilProfileData[key].items.map((item) => (
                                                                                          <div key={item}>{item}</div>
                                                                                      ))}
                                                                                  </Fragment>
                                                                              );
                                                                          })
                                                                        : "Using S-Map defaults"}
                                                                </td>
                                                                <td valign="top">
                                                                    {Object.keys(customSoilPropertiesData).length > 0
                                                                        ? Object.keys(customSoilPropertiesData).map((key, i) => {
                                                                              return (
                                                                                  <Fragment key={key}>
                                                                                      <div>
                                                                                          <b>{customSoilPropertiesData[key].title}</b>
                                                                                      </div>
                                                                                      {customSoilPropertiesData[key].items.map((item) => (
                                                                                          <div key={item}>{item}</div>
                                                                                      ))}
                                                                                  </Fragment>
                                                                              );
                                                                          })
                                                                        : "Using S-Map defaults"}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridCell>
                                </Grid>
                                <Grid>
                                    <GridCell>
                                        <p>
                                            Or, select the unmodified version of this S-Map soil to add to this block. <b>NOTE:</b> this will add another {smapReference} soil to this analysis.
                                        </p>
                                        <div className="Table u-mt-xs">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="th--shrink"></th>
                                                        <th data-width="15">S-Map ref/Name</th>
                                                        <th>Group</th>
                                                        <th>Order</th>
                                                        <th data-width="15">Description</th>
                                                        <th data-width="20">Soil profile</th>
                                                        <th data-width="20">Soil properties</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td valign="top">
                                                            <div className="Field">
                                                                <div className="Radio_group--inline">
                                                                    <label className="Radio" htmlFor="soilId-new">
                                                                        <input name="soilId" id="soilId-new" value={smapSoil.id} onChange={onOptionSelected(form)} className="Radio-input" type="radio" />
                                                                        <span className="Radio-label"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td valign="top">
                                                            <div className="u-flexSplit">{smapSoil.sibling.smapReference}</div>
                                                        </td>
                                                        <td valign="top">{utils.valueToText(refData.soilGroups, smapSoil.soilGroup) || "-"}</td>
                                                        <td valign="top">{utils.valueToText(refData.soilOrders, smapSoil.soilOrder) || "-"}</td>
                                                        <td valign="top">{smapSoil.sibling.description || "-"}</td>
                                                        <td valign="top">Using S-Map defaults</td>
                                                        <td valign="top">Using S-Map defaults</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit waiting={submitting} disabled={submitting}>
                                        Done
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useExistingSmapRefModal() {
    const { openModal, getModal } = usePortalModal();

    const openExistingSmapRefModal = (smapSoil, existingSoils, onSubmit) => {
        const modalProps = {
            smapSoil,
            existingSoils,
            onSubmit,
        };
        openModal("ExistingSmapRefModal", modalProps);
    };

    const existingSmapRefModal = getModal("ExistingSmapRefModal", ExistingSmapRefModal);

    return [openExistingSmapRefModal, existingSmapRefModal];
}
