import React from "react";
import ReactDOMServer from "react-dom/server";
import DropzoneComponent from "react-dropzone-component";
import { Field } from "redux-form";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as validations from "common/validations";
import InputPack from "components/InputPack";
import Modal from "components/ModalOld";
import Alert from "components/Alert";

const mapStateToProps = (state, ownProps) => {
    return {
        datasetId: ownProps.modal.props.datasetId,
        datasetName: ownProps.modal.props.datasetName,
        maxFiles: ownProps.modal.props.maxFiles,
        initialValues: ownProps.modal.props,
    };
};

class LoadXMLFarms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datasetName: this.props.datasetName,
        };
    }

    datasetChange(e, value) {
        this.setState({ datasetName: value });
    }

    _submit = (formValues) => {
        this.props.modal.onConfirm(formValues);
    };

    render() {
        const { datasetId, initialValues, maxFiles } = this.props;

        var url = this.state === null ? "" : process.env.REACT_APP_API_URL + "/science/datasets/" + datasetId + "/loadxml/" + this.state.datasetName;

        const componentConfig = {
            iconFiletypes: [".xml"],
            showFiletypeIcon: true,
            postUrl: url,
        };

        const djsConfig = {
            addRemoveLinks: true,
            uploadMultiple: true,
            maxFiles: maxFiles,
            maxFileSize: 2,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            previewTemplate: ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview">
                    <div className="dz-details">
                        <span data-dz-name="true"></span>
                    </div>
                    <div className="dz-progress">
                        <span className="dz-upload" data-dz-uploadprogress="true"></span>
                    </div>
                    <div className="dz-error-message">
                        <span data-dz-errormessage="true"></span>
                    </div>
                </div>
            ),
        };

        return (
            <Modal title="Upload farm files" submitLabel="Done" initialValues={initialValues} onSubmit={this._submit}>
                <Alert
                    type="info"
                    className="u-mb-0"
                    text="Upload Overseer legacy xml files and/or ovr files below. Once the files are dropped into the box below the files will get uploaded even if the file is removed after it is uploaded. Please note: 
                     Selecting cancel or done will close the modal and show the new dataset in the list. All files will be uploaded even if cancel or done is selected while processing."
                />
                <div className="Grid-cell">
                    <Field name="datasetName" id="farm-dataset-name" requiredLabel={true} readOnly={initialValues.readOnly} component={InputPack} type="text" label="Dataset name" onChange={this.datasetChange.bind(this)} validate={[validations.required, validations.maxLength(100), validations.minLength(1)]} placeholder="Enter a name for this farm data set" />
                </div>
                {this.state != null && this.state.datasetName && this.state.datasetName.length > 0 && (
                    <div className="Grid-cell">
                        <DropzoneComponent id="budget-file-upload" config={componentConfig} djsConfig={djsConfig} />
                    </div>
                )}
            </Modal>
        );
    }
}

const LoadXMLFarmsForm = reduxForm({
    form: "load-budegt-xml-form",
})(LoadXMLFarms);

export default connect(mapStateToProps, null)(LoadXMLFarmsForm);
