import { useState } from "react";
import { useModal } from "common/hooks";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";

export default function FeatureUsageModal({ feature, since, close }) {
    const [showFarms, setShowFarms] = useState(false);
    return (
        <Modal title={"Organisations that have used the " + feature.name + " in the " + since} close={close} fluid wide>
            <ModalBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th>Organisation</th>
                                <th>Number of sessions</th>
                                <th>Number of users</th>
                                <th>Users</th>
                                {showFarms && <th>Farms</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {feature.orgs.map((org) => {
                                const sessionCount = feature.sessions.filter((s) => s.accountId === org.id).length;
                                const users = feature.users.filter((u) => u.orgId === org.id);
                                const farms = feature.farms.filter((f) => f.orgs.includes(org.id));
                                return (
                                    <tr key={org.id}>
                                        <td valign="top">{org.name}</td>
                                        <td valign="top">{sessionCount}</td>
                                        <td valign="top">{users.length}</td>
                                        <td valign="top">
                                            {users.map((user) => (
                                                <p key={`${org.id}-${user.id}`}>{user.name}</p>
                                            ))}
                                        </td>
                                        {showFarms && (
                                            <td valign="top">
                                                {farms.map((farm) => (
                                                    <p key={`${farm.id}`}>{farm.name}</p>
                                                ))}
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </ModalBody>
            <ModalFooter>
                <ModalFooterLeft>
                    <Button id="cancel" onClick={() => setShowFarms(!showFarms)} secondary>
                        {showFarms ? "Hide farms" : "Show farms"}
                    </Button>
                </ModalFooterLeft>
                <ModalFooterRight>
                    <Button id="cancel" onClick={close} secondary>
                        Cancel
                    </Button>
                </ModalFooterRight>
            </ModalFooter>
        </Modal>
    );
}

export function useFeatureUsageModal() {
    const [modal, openModal] = useModal(FeatureUsageModal);

    const openFeatureUsageModal = (feature, since) => {
        const modalProps = {
            feature,
            since,
        };
        openModal(modalProps);
    };

    return [modal, openFeatureUsageModal];
}
