import React from "react";

const SectionHeading = ({ title, children, secondary, className }) => {
    return (
        <div className={`Heading--${secondary === true ? "secondary" : "primary"} u-mt-lg ${className ? className : ""}`}>
            <div className="u-split">
                <span>{title}</span>
                {children}
            </div>
        </div>
    );
};

export default SectionHeading;
