import { Field } from "react-final-form";
import { FieldGroup, RadioGroupField, SelectField, TextField } from "components/FormFields";
import { ExistingWarning, GlobalCopy } from "./Dialogs";
import { useRefData } from "common/hooks";

export default function Fertiliser({ viewModel, customFertiliser, customCompositions }) {
    const options = [
        { value: "MANUFACTURER", text: "Select from a manufacturer" },
        { value: "CUSTOM", text: "Custom fertiliser" },
    ];
    return (
        <>
            {!viewModel.preventCustomSwitch && (
                <div className="Grid-cell">
                    <Field name="productSource" label="Select the source of your fertiliser" options={options} component={RadioGroupField} inline />
                </div>
            )}
            {viewModel.productSource === "MANUFACTURER" && <ManufacturedFertiliser viewModel={viewModel} />}
            {viewModel.productSource === "CUSTOM" && <CustomFertiliser customFertiliser={customFertiliser} viewModel={viewModel} customCompositions={customCompositions} />}
            <ExistingWarning viewModel={viewModel} />
        </>
    )
}

const ManufacturedFertiliser = ({ viewModel }) => {
    const refData = useRefData();

    const { analysis, fertiliser } = viewModel;
    const manufacturerId = isNaN(viewModel.fertiliser.manufacturerId) ? undefined : parseInt(viewModel.fertiliser.manufacturerId, 10);
    const manufacturer = refData.fertiliser.find((p) => p.id === manufacturerId) || { products: [] };
    const usedProductIds = viewModel.isNew ? analysis.fertiliser.filter((f) => f.manufacturerId > 0 && f.productId !== fertiliser.productId).map((f) => f.productId) : [];
    const prodOptions = manufacturer.products.filter((p) => !usedProductIds.includes(p.id)).map((f) => ({ value: f.id, text: f.name }));
    prodOptions.sort((a, b) => (b.text < a.text ? 1 : -1));
    if (viewModel.retired && String(viewModel.retired.manufacturerId) === String(viewModel.fertiliser.manufacturerId)) {
        prodOptions.forEach((o) => {
            o.groupIndex = 1;
            o.groupLabel = "Current";
        });
        prodOptions.unshift({ ...viewModel.retired, ...{ groupLabel: "Retired", groupIndex: 0 } });
    }

    const { fertiliser: products = [] } = refData;
    const fertOptions = products.filter((f) => f.name !== "User defined" && f.products.length > 0).map((f) => ({ value: f.id, text: f.name }));
    return (
        <div className="Grid-cell">
            <FieldGroup>
                <Field name="fertiliser.manufacturerId" label="Manufacturer" options={fertOptions} placeholder="Select a manufacturer" dataWidth="50" required component={SelectField} />
                <Field name="fertiliser.productId" label="Product" options={prodOptions} placeholder="Select a product" disabled={!manufacturer.id} dataWidth="50" required component={SelectField} />
            </FieldGroup>
        </div>
    )
}

const CustomFertiliser = ({ customFertiliser, customCompositions, viewModel }) => {
    const localProducts = viewModel.isNew ? [] : customFertiliser.map((c) => ({ value: c.id, text: c.productName, groupIndex: 1, groupLabel: "Use existing custom fertiliser" }));
    localProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    const myProducts = customCompositions.filter((c) => c.userId).map((c) => ({ value: c.id, text: c.name, groupIndex: 2, groupLabel: "Copy my custom fertiliser" }));
    const orgProducts = customCompositions.filter((c) => !c.userId).map((c) => ({ value: c.id, text: c.name, groupIndex: 3, groupLabel: "Copy organisation custom fertiliser" }));
    myProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    orgProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    const products = [...[{ value: "NEW", text: "New custom fertiliser", groupLabel: "Add new custom fertiliser", groupIndex: 0 }], ...localProducts, ...myProducts, ...orgProducts];

    return (
        <div className="Grid-cell">
            {(localProducts.length > 0 || myProducts.length > 0 || orgProducts.length > 0) && <Field name="fertiliser.id" label="Custom products" options={products} required component={SelectField} />}
            <GlobalCopy viewModel={viewModel} />
            <Field name={`fertiliser.productName`} label="Name" disabled={viewModel.existing} required component={TextField} />
        </div>
    )
}
