import React, { useState, useEffect } from "react";
import { compose, withAppContext, withActions, withState } from "containers/HOC";
import InputPack from "components/InputPack";
import { Grid, GridCell } from "components/Grid";
import { edLogin } from "containers/Auth/_actions";
import jwtDecode from "jwt-decode";
import LoaderSection from "components/LoaderSection";
import * as api from "api";
import * as validations from "common/validations";
import Button from "components/Button/Button";

const queryFarms = (token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                url: `${process.env.REACT_APP_ED_URL}/search/farms?searchText=&filterBy=MyOrg`,
                token,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

const queryFarm = (token, farmId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                url: `${process.env.REACT_APP_ED_URL}/farms/${farmId}`,
                token,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

const queryBudget = (token, farmId, budgetId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                url: `${process.env.REACT_APP_ED_URL}/farms/${farmId}/budgets/${budgetId}`,
                token,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

const ImportExternal = ({ token, edLogin, loginLoading, queryFarms, queryFarm, budget, setBudget, queryBudget, loginError }) => {
    const [user, setUser] = useState({});
    const [farms, setFarms] = useState([]);
    const [budgetLoading, setBudgetLoading] = useState(false);

    const login = () => {
        edLogin(user);
    };

    const isValidToken = (token) => {
        if (!token) {
            return false;
        }
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const diff = decoded.exp - currentTime;
        return diff > 0;
    };

    useEffect(() => {
        async function fetchData() {
            const farms = await queryFarms(token);
            setFarms(farms);
        }
        if (token && token.length > 0) fetchData();
    }, [token, queryFarms]);

    useEffect(() => {
        async function fetchData(farm) {
            const farmWithBudgets = await queryFarm(token, farm.id);
            const newFarms = farms.filter((f) => f.id !== farm.id);
            farmWithBudgets.isFull = true;
            newFarms.push(farmWithBudgets);
            setFarms(newFarms);
        }
        const firstFarm = farms.find((f) => !f.isFull);
        if (firstFarm) fetchData(firstFarm);
    }, [token, farms, queryFarm]);

    const isLoggedIn = isValidToken(token);

    const loadBudget = async (farm, b) => {
        setBudgetLoading(true);
        const edBudget = await queryBudget(token, farm.id, b.id);
        setBudgetLoading(false);
        setBudget(edBudget);
    };

    const validateLogin = (values) => {
        const errors = {};

        errors.email = validations.required(values.email);
        errors.email = errors.email || validations.email(values.email);
        errors.email = errors.email || validations.maxLength(255)(values.email);

        errors.password = validations.required(values.password);
        errors.password = errors.password || validations.maxLength(50)(values.password);

        return errors;
    };

    const validation = validateLogin(user);
    const disableLogin = Object.keys(validation).filter((k) => validation[k]).length > 0;

    return (
        <Grid>
            {isLoggedIn ? (
                <GridCell>
                    {budget ? (
                        <div className="u-mt-lg">
                            You have selected to import the analysis listed below. Click upload to complete the import.
                            <h2>{budget.name}</h2>
                            <Button className="Button Button--secondary u-mt-lg" type="button" onClick={() => setBudget(undefined)}>
                                Remove
                            </Button>
                        </div>
                    ) : (
                        <div>
                            {farms.some((f) => !f.isFull) || budgetLoading ? (
                                <LoaderSection message="loading farms and analyses..." dark={true} />
                            ) : (
                                <div>
                                    {farms
                                        .filter((f) => f.budgets.length > 0)
                                        .map((f) => {
                                            return (
                                                <div className="u-mt-lg">
                                                    <div className="Table">
                                                        <table>
                                                            <tr>
                                                                <th colSpan="2">{f.name}</th>
                                                            </tr>
                                                            {f.budgets.map((b) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{b.name}</td>
                                                                        <td className="th--shrink">
                                                                            <Button className="Button Button--secondary" type="button" onClick={() => loadBudget(f, b)}>
                                                                                Select
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            )}
                        </div>
                    )}
                </GridCell>
            ) : (
                <GridCell className="Grid-cell--center">
                    {loginLoading ? (
                        <LoaderSection message="Validating credentials..." dark={true} />
                    ) : (
                        <>
                            <div className="h2 u-mt-xl">Login to OverseerEd</div>
                            {loginError && (
                                <div className="Alert Alert--error u-mt-lg">
                                    <i className="icon icon-alert" id="login-errormsg" />
                                    {loginError}
                                </div>
                            )}
                            <InputPack label="Email" type="text" value={user.email} meta={{ nrf: true }} onChange={(e) => setUser({ ...user, ...{ email: e.target.value } })} />
                            <InputPack label="Password" type="password" value={user.password} meta={{ nrf: true }} onChange={(e) => setUser({ ...user, ...{ password: e.target.value } })} />
                            <Grid className="u-mt-lg">
                                <GridCell>
                                    <button type="button" disabled={disableLogin} id="login-submit" className="Button Button--primary Button--block" onClick={() => login()}>
                                        Log in
                                    </button>
                                </GridCell>
                            </Grid>
                        </>
                    )}
                </GridCell>
            )}
        </Grid>
    );
};

export default compose(
    withAppContext,
    withActions({ edLogin, queryFarms, queryFarm, queryBudget }),
    withState((state) => {
        return {
            token: state.auth.edToken,
            loginLoading: state.auth.isEdLoading,
            loginError: state.auth.edLoginError,
        };
    })
)(ImportExternal);
