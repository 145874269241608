import React from "react";
import { compose, withAppContext } from "containers/HOC";
import * as utils from "common/utils";
import { Bar } from "react-chartjs-2";

const NLossByBlockTypesChart = ({ blocks }) => {
    const [showPerHa, setShowPerHa] = React.useState(false);
    const chartData = getChartData(blocks, showPerHa);
    const chartOptions = getChartOptions(blocks);
    const title = `Nitrogen loss by block type ${showPerHa ? "(kg/ha)" : "(kg)"}`;
    return (
        <div className="HeroPanel HeroPanel--nloss u-mt-md">
            <div className="u-flex u-flexJustifyBetween">
                <div className="HeroPanel-heading">{title}</div>
                <div>
                    <div className="Field u-mt-xs">
                        <div className="Radio_group Radio_group--inline">
                            <label className="Radio u-mr-md">
                                <input checked={!showPerHa} onChange={() => setShowPerHa(false)} className="Radio-input" type="radio" />
                                <span className="Radio-label">Show total kg</span>
                            </label>
                            <label className="Radio">
                                <input checked={showPerHa} onChange={() => setShowPerHa(true)} className="Radio-input" type="radio" />
                                <span className="Radio-label">Show kg per ha</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <Bar data={chartData} options={chartOptions} height={80} />
        </div>
    );
};

const getChartData = (blocks, showPerHa) => {
    return {
        datasets: [
            {
                data: blocks.map((b) => utils.round((showPerHa ? b.nLossPerHa : b.nLossTotal) || 0, 1)),
                backgroundColor: blocks.map((b, i) => utils.GetBlockColour(b.type, b.cutAndCarry)),
            },
        ],
        labels: blocks.map((b) => b.name),
    };
};

const getChartOptions = (blocks) => {
    return {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        if (blocks.length <= tooltipItem.dataIndex) return tooltipItem.label;
                        const block = blocks[tooltipItem.dataIndex];
                        return `${Number(utils.round(block.nLossPerHa || 0, 1)).toLocaleString()} kg/ha | ${Number(utils.round(block.nLossTotal || 0, 1)).toLocaleString()} kg`;
                    },
                },
            },
        },
        scales: {
            x: { display: true },
            y: {
                ticks: {
                    beginAtZero: true,
                    display: true,
                    callback: function (label, index, labels) {
                        return Number(label).toLocaleString();
                    },
                },
            },
        },
    };
};

export default compose(withAppContext)(NLossByBlockTypesChart);
