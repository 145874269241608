import appActionTypes from "containers/App/_actions";
import * as api from "api";
import ReactGA from "react-ga4";

const actionTypes = {
    ACCOUNT_GET_SUCCESS: "ACCOUNT_GET_SUCCESS",
    ACCOUNT_GET_STUDENTS_SUCCESS: "ACCOUNT_GET_STUDENTS_SUCCESS",
    ACCOUNT_REMOVE_STUDENT_SUCCESS: "ACCOUNT_REMOVE_STUDENT_SUCCESS",
    ACCOUNT_UPDATE_STUDENT_SUCCESS: "ACCOUNT_UPDATE_STUDENT_SUCCESS",
    ACCOUNT_GET_PAYMENT_HISTORY_SUCCESS: "ACCOUNT_GET_PAYMENT_HISTORY_SUCCESS",
    ACCOUNT_GET_INVOICE_SUCCESS: "ACCOUNT_GET_INVOICE_SUCCESS",
    ACCOUNT_CLEAR_INVOICE: "ACCOUNT_CLEAR_INVOICE",
};
export default actionTypes;

export function getAccount() {
    return api.get({
        path: `accounts`,
        onSuccess: (response) => {
            return { type: actionTypes.ACCOUNT_GET_SUCCESS, payload: response };
        },
    });
}

export function getStudents() {
    return api.get({
        path: `students`,
        onSuccess: (response) => {
            return { type: actionTypes.ACCOUNT_GET_STUDENTS_SUCCESS, payload: response };
        },
    });
}

export function getInvoice(farmId, invoiceId) {
    return api.get({
        path: `farms/${farmId}/invoice/${invoiceId}`,
        onSuccess: (response) => {
            return { type: actionTypes.ACCOUNT_GET_INVOICE_SUCCESS, payload: response };
        },
    });
}

export function clearInvoice() {
    return { type: actionTypes.ACCOUNT_CLEAR_INVOICE };
}

export function getPaymentHistory() {
    return api.get({
        path: `payments`,
        onSuccess: (response) => {
            return { type: actionTypes.ACCOUNT_GET_PAYMENT_HISTORY_SUCCESS, payload: response };
        },
    });
}

export function inviteUser(invitation) {
    ReactGA.event({
        category: "Account",
        action: "Invite",
    });
    return api.post({
        path: `invitations`,
        content: invitation,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
                dispatch(getAccount());
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                switch (error.status) {
                    case 409:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "User already exists." });
                        break;
                    case 403:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "You no longer have admin privileges to this account and so are unable to invite users. Please contact your account administrator." });
                        break;
                    case 400:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR_REPEATABLE, payload: "Error sending invite. Please ensure the email address is valid." });
                        break;
                    default:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." });
                        break;
                }
            };
        },
    });
}

export function removeUser(id) {
    ReactGA.event({
        category: "Account",
        action: "Remove User",
    });
    return api.del({
        path: `users/${id}`,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
                dispatch(getAccount());
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                switch (error.status) {
                    case 403:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "You no longer have admin privileges to this account and so are unable to remove users. Please contact your account administrator." });
                        break;
                    default:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." });
                        break;
                }
            };
        },
    });
}

export function removeStudent(id) {
    ReactGA.event({
        category: "Account",
        action: "Remove Student",
    });
    return api.del({
        path: `students/${id}`,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
                dispatch({ type: actionTypes.ACCOUNT_REMOVE_STUDENT_SUCCESS, payload: id });
                dispatch(getAccount());
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                switch (error.status) {
                    case 403:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "You no longer have admin privileges to this account and so are unable to remove users. Please contact your account administrator." });
                        break;
                    default:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." });
                        break;
                }
            };
        },
    });
}

export function toggleAdminForUser(id) {
    ReactGA.event({
        category: "Account",
        action: "Switch Admin",
    });
    return api.put({
        path: `users/${id}/toggleadmin`,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
                dispatch(getAccount());
            };
        },
    });
}

export function revokeUserInvitation(id) {
    ReactGA.event({
        category: "Account",
        action: "Revoke Invite",
    });
    return api.del({
        path: `invitations/${id}`,
        content: { id },
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
                dispatch(getAccount());
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return { type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." };
        },
    });
}
