import { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import * as api from "api";
import TextareaField from "components/FormFields/TextareaField";
import { Grid, GridCell } from "components/Grid";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";
import Alert from "components/Alert";
import ReactGA from "react-ga4";
import { useModal } from "common/hooks";
import { useDispatch } from "react-redux";
import { useFarmAccess } from "containers/hooks";

function NotificationModal({ analysis, close, farm = {}, type }) {
    const trackerType = type === "Ghg" ? FEATURES.EMAIL_GHG_TO_OWNER : FEATURES.EMAIL_SUMMARY_TO_OWNER;
    const featureTracker = useFeatureTracker(trackerType, type, farm);
    const { data: farmAccess } = useFarmAccess(farm?.id);

    const [preview, setPreview] = useState();
    const [invalid, setInvalid] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            await dispatch(getNotification(analysis.farmId, analysis.id, type))
                .then((response) => {
                    if (response && response.status === 202) {
                        setInvalid(response.detail);
                    }
                    setPreview(response);
                })
                .catch((e) => {});
        }
        fetchData();
    }, [analysis.farmId, analysis.id, type, dispatch]);

    const owners = farmAccess?.access?.filter((a) => a.role === "Owner") || [];
    const pendingOwnerInvites = farmAccess?.accessInvitations?.filter((i) => i.role === "Owner" && i.status === "Pending") || [];
    const [selectedOwners, setSelectedOwners] = useState(owners.map((o) => o.accountId));
    const [selectedInvites, setSelectedInvites] = useState(pendingOwnerInvites.map((o) => o.id));

    const submit = (values) => {
        featureTracker.track("Sent");
        const { farmId, budgetId, comments } = values;
        const submitResult = dispatch(sendNotification(farmId, budgetId, comments, selectedOwners, selectedInvites, type)).then(close).catch();
        return submitResult;
    };

    const toggleSendTo = (id, isOwner) => {
        if (isOwner) {
            if (selectedOwners.includes(id)) setSelectedOwners(selectedOwners.filter((i) => i !== id));
            else setSelectedOwners([...selectedOwners, id]);
        } else {
            if (selectedInvites.includes(id)) setSelectedInvites(selectedInvites.filter((i) => i !== id));
            else setSelectedInvites([...selectedInvites, id]);
        }
    };

    return (
        <Form initialValues={{ budgetId: analysis.id, farmId: analysis.farmId }} onSubmit={submit}>
            {({ handleSubmit, submitting }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Preview email" close={close} wide>
                            <ModalBody>
                                <Grid className="u-pl-lg u-pr-lg u-mb-lg">
                                    {owners.length > 0 && (
                                        <GridCell className="u-width1of2">
                                            <div className="email-header u-mb-0 u-mt-lg">Send to owner accounts</div>
                                            <div className="u-mb-md">
                                                <i>
                                                    <small>An email will be sent to each admin of the selected owner accounts</small>
                                                </i>
                                            </div>
                                            <SendTo users={owners.map((o) => ({ name: o.accountName, id: o.accountId }))} toggleSendTo={(id) => toggleSendTo(id, true)} selectedUsers={selectedOwners} />
                                        </GridCell>
                                    )}
                                    {pendingOwnerInvites.length > 0 && (
                                        <GridCell className="u-width1of2">
                                            <div className="email-header u-mb-0 u-mt-lg">Send to pending owners</div>
                                            <div className="u-mb-md">
                                                <i>
                                                    <small>These users have not yet set up an account on OverseerFM</small>
                                                </i>
                                            </div>
                                            <SendTo users={pendingOwnerInvites.map((o) => ({ id: o.id, name: o.inviteeEmail }))} toggleSendTo={(id) => toggleSendTo(id, false)} selectedUsers={selectedInvites} />
                                        </GridCell>
                                    )}
                                </Grid>
                                <hr />
                                <div className="u-pl-lg u-pr-lg">
                                    <div className="email-header u-mt-md">Comments</div>
                                    <Field name="comments" label="" placeholder="Add comments to accompany the email." component={TextareaField} />
                                </div>
                                {invalid && (
                                    <div className="u-mt-lg u-mb-md u-pl-lg u-pr-lg ">
                                        <div className="u-border">
                                            <Alert type="Warning" className="u-mb-0 " text={invalid}></Alert>
                                        </div>
                                    </div>
                                )}
                                {preview && <div dangerouslySetInnerHTML={{ __html: preview.content }} />}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary disabled={submitting || invalid || (selectedOwners.length === 0 && selectedInvites.length === 0)}>
                                        Send
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

function SendTo({ users = [], selectedUsers = [], toggleSendTo }) {
    return (
        <ul className="BlockList">
            {users.map((user) => {
                return (
                    <li key={user.id} className="BlockList-item BlockList-item--soil">
                        <span>{user.name}</span>
                        <label className="Checkbox Checkbox--tick">
                            <input className="Checkbox-input" type="checkbox" checked={selectedUsers.includes(user.id)} onClick={() => toggleSendTo(user.id)} />
                            <span className="Checkbox-label u-ml-md"></span>
                        </label>
                    </li>
                );
            })}
        </ul>
    );
}

export function useNotificationModal(farm, analysis) {
    const [modal, openModal] = useModal(NotificationModal);

    const openNotificationModal = (type) => {
        const modalProps = {
            farm,
            analysis,
            type,
        };
        openModal(modalProps);
    };

    return [modal, openNotificationModal];
}

const getNotification = (farmId, budgetId, type) => (dispatch) => {
    ReactGA.event({
        category: "EmailNotification",
        action: "Preview: " + type,
    });
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                url: `${process.env.REACT_APP_API_URL}/farms/${farmId}/analysis/${budgetId}/FarmNotification/${type}`,
                contentType: "text/html",
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

const sendNotification = (farmId, budgetId, comments, selectedOwners, selectedInvites, type) => (dispatch) => {
    ReactGA.event({
        category: "EmailNotification",
        action: "Send: " + type,
    });
    return new Promise((resolve, reject) => {
        return dispatch(
            api.post({
                url: `${process.env.REACT_APP_API_URL}/farms/${farmId}/analysis/${budgetId}/FarmNotification/${type}`,
                content: { farmId, budgetId, comments, selectedOwners, selectedInvites },
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};
