import * as utils from "common/utils";
import { Grid, GridCell } from "components/Grid";

export default function FileSummary({ before, after, mini, print }) {
    const formatAnalysis = (analysis, prefix) => {
        const { publication } = analysis;
        return (
            <>
                <table className="u-table-clear">
                    <tbody>
                        <tr>
                            {prefix && (
                                <td valign="top" className="u-text-xl u-textBold">
                                    {prefix}
                                </td>
                            )}
                            <td valign="top" className={`u-text-xl ${prefix ? "u-textRight" : ""}`}>
                                {analysis.name}
                                {publication ? ` (v${publication.version})` : ""}
                            </td>
                        </tr>
                    </tbody>
                </table>

                {!mini && (
                    <table className="u-table-clear">
                        <tbody>
                            <tr className="u-text-md">
                                <td className="u-textBold">Analysis type</td>
                                <td className="u-textRight">{utils.enumToSentence(analysis.type)}</td>
                            </tr>
                            {analysis.modelVersion && (
                                <tr className="u-text-md">
                                    <td className="u-textBold">Model version</td>
                                    <td className="u-textRight">{analysis.modelVersion}</td>
                                </tr>
                            )}
                            {publication && (
                                <>
                                    <tr className="u-text-md">
                                        <td className="u-textBold">Publication date</td>
                                        <td className="u-textRight">{utils.dateLong(publication.publishedOn)}</td>
                                    </tr>
                                    <tr className="u-text-md">
                                        <td className="u-textBold">Published by</td>
                                        <td className="u-textRight">{publication.publishedBy.accountName}</td>
                                    </tr>
                                    <tr className="u-text-md">
                                        <td className="u-textBold">Publication status</td>
                                        <td className="u-textRight">{publication.status}</td>
                                    </tr>
                                    <tr className="u-text-md">
                                        <td className="u-textBold">Publication version</td>
                                        <td className="u-textRight">{publication.version}</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                )}
            </>
        );
    };

    return (
        <div className="Container">
            <Grid className={print ? "" : ""}>
                {before && (
                    <GridCell className={print ? "u-mt-md u-width1of1" : "u-mt-md u-xxl-width1of2"}>
                        <div className="Table">
                            <table>
                                <tbody>
                                    <tr className="Table-tr--before u-before">
                                        <td style={{ border: "none" }}>{formatAnalysis(before.details, "BEFORE")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </GridCell>
                )}
                <GridCell className={print ? "u-mt-md u-width1of1" : "u-mt-md u-xxl-width1of2"}>
                    <div className="Table">
                        <table>
                            <tbody>
                                <tr className="Table-tr--after u-after">
                                    <td style={{ border: "none" }}>{formatAnalysis(after.details, before ? "AFTER" : "")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </GridCell>
            </Grid>
        </div>
    )
}
