import ActionLink from "components/ActionLink";
import { usePublicationDetailsModal } from "./PublicationDetailsModal";

export default function AddPublicationCommentActionLink({ publication, label="Comment" }) {
    const [addOrEditPublicationModal, openAddOrEditPublicationModal] = usePublicationDetailsModal(publication, true);
    return (
        <>
            <ActionLink id={`manage-publication-${publication.id}`} className="IconLink--chat" onClick={openAddOrEditPublicationModal}>
                {label}
            </ActionLink>
            {addOrEditPublicationModal}
        </>
    )
}
