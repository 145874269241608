import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import Blocks from "./Blocks";

export default function BlocksTab({ farm, analysis }) {
    return (
        <Switch>
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId" render={() => <Blocks farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/list" render={() => <Blocks farm={farm} analysis={analysis}/>} restoreScrollY />
        </Switch>
    )
}
