import React from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";

const FarmContacts = ({ farm }) => {
    const ownerOrgs = farm && (farm.orgs || []).filter((org) => org.role === "Owner");
    const adminOrgs = farm && (farm.orgs || []).filter((org) => org.role === "Admin");
    const otherOrgs = farm && (farm.orgs || []).filter((org) => !["Owner", "Admin"].includes(org.role));
    const users = farm && (farm.users || []);
    const pendingInvitations = farm && (farm.pendingInvitations || []);
    const pendingAccessRequests = farm && (farm.pendingAccessRequests || []);

    return (
        <Panel>
            <PanelBody loading={!farm}>
                <div className="Table">
                    <table>
                        <thead>
                            <tr>
                                <th>Owner orgs</th>
                                <th data-width="20">Farm role</th>
                                <th data-width="40">Org admins</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ownerOrgs && ownerOrgs.length === 0 && (
                                <tr>
                                    <td colSpan="3">
                                        <div className="Tile-body-message">
                                            <span className="u-textError u-text-lg u-textBold">This farm has no owners</span>
                                            {pendingInvitations.some((i) => i.role === "Owner") && <p>There are pending ownership invitations. Scroll down to see them.</p>}
                                            {pendingAccessRequests.some((i) => i.role === "Owner") && <p>There are pending ownership access requests. Scroll down to see them.</p>}
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {ownerOrgs &&
                                ownerOrgs.length > 0 &&
                                ownerOrgs.map((org) => {
                                    return (
                                        <tr key={org.id} className="hover">
                                            <td valign="top">
                                                <Link id={`account_${org.id}`} to={`/app/admin/orgs/${org.id}`} title="Go to org summary">
                                                    {org.name}
                                                </Link>
                                            </td>
                                            <td valign="top">{org.role}</td>
                                            <td>
                                                {org.admins.map((admin) => (
                                                    <div key={admin.id} className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                                                        <b>{admin.name}</b>
                                                        <span>{admin.email}</span>
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
                <div className="Table">
                    <table>
                        <thead>
                            <tr>
                                <th>Admin orgs</th>
                                <th data-width="20">Farm role</th>
                                <th data-width="40">Org admins</th>
                            </tr>
                        </thead>
                        <tbody>
                            {adminOrgs && adminOrgs.length === 0 && (
                                <tr>
                                    <td colSpan="3">
                                        <div className="Tile-body-message">
                                            <span className="u-text-lg">This farm has no admins</span>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {adminOrgs &&
                                adminOrgs.length > 0 &&
                                adminOrgs.map((org) => {
                                    return (
                                        <tr key={org.id} className="hover">
                                            <td valign="top">
                                                <Link id={`account_${org.id}`} to={`/app/admin/orgs/${org.id}`} title="Go to org summary">
                                                    {org.name}
                                                </Link>
                                            </td>
                                            <td valign="top">{org.role}</td>
                                            <td>
                                                {org.admins.map((admin) => (
                                                    <div key={admin.id} className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                                                        <b>{admin.name}</b>
                                                        <span>{admin.email}</span>
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
                {otherOrgs && otherOrgs.length > 0 && (
                    <div className="Table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Others</th>
                                    <th data-width="20">Farm Role</th>
                                    <th data-width="40">Org admins</th>
                                </tr>
                            </thead>
                            <tbody>
                                {otherOrgs.map((org) => {
                                    return (
                                        <tr key={org.id} className="hover">
                                            <td valign="top">
                                                <Link id={`account_${org.id}`} to={`/app/admin/orgs/${org.id}`} title="Go to org summary">
                                                    {org.name}
                                                </Link>
                                            </td>
                                            <td valign="top">{org.role}</td>
                                            <td>
                                                {org.admins.map((admin) => (
                                                    <div key={admin.id} className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                                                        <b>{admin.name}</b>
                                                        <span>{admin.email}</span>
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
                {users && users.length > 0 && (
                    <div className="Table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Users</th>
                                    <th data-width="60">Farm Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user) => {
                                    return (
                                        <tr key={user.id} className="hover">
                                            <td valign="top">
                                                <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                                                    <b>{user.name}</b>
                                                    <span>{user.email}</span>
                                                </div>
                                            </td>
                                            <td valign="top">{user.role}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
                {pendingInvitations && pendingInvitations.length > 0 && (
                    <div className="Table" id="invitations">
                        <table>
                            <thead>
                                <tr>
                                    <th>Pending invitations</th>
                                    <th data-width="20">Invited to be</th>
                                    <th data-width="20">Invited by</th>
                                    <th data-width="20">Invited on</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingInvitations.map((pendingInvitation) => {
                                    return (
                                        <tr key={pendingInvitation.id} className="hover">
                                            <td valign="top">{pendingInvitation.inviteeEmail}</td>
                                            <td valign="top">{pendingInvitation.role}</td>
                                            <td valign="top">
                                                <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                                                    <span>{pendingInvitation.invitorOrgName}</span>
                                                    <span>{pendingInvitation.invitorOrgEmail}</span>
                                                </div>
                                            </td>
                                            <td valign="top">{utils.dateLong(pendingInvitation.invitedOn, "-")}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
                {pendingAccessRequests && pendingAccessRequests.length > 0 && (
                    <div className="Table" id="access-requests">
                        <table>
                            <thead>
                                <tr>
                                    <th>Pending access requests</th>
                                    <th data-width="20">Requested role</th>
                                    <th data-width="20">Requested by</th>
                                    <th data-width="20">Requested on</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingAccessRequests.map((pendingAccessRequest) => {
                                    return (
                                        <tr key={pendingAccessRequest.id} className="hover">
                                            <td valign="top">{pendingAccessRequest.requestorOrgName}</td>
                                            <td valign="top">{pendingAccessRequest.role}</td>
                                            <td valign="top">{pendingAccessRequest.requestorEmail}</td>
                                            <td valign="top">{utils.dateLong(pendingAccessRequest.requestedOn, "-")}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </PanelBody>
        </Panel>
    );
};

export default FarmContacts;
