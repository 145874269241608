import React from "react";
import { compose, withAppContext, withActions, withState } from "containers/HOC";
import { Panel, PanelBody } from "components/Panel";
import { getBlockAccountBenchmarks, clearBlockAccountBenchmarks, getAllFarms } from "./_actions";
import { Grid, GridCell } from "components/Grid";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { Link } from "react-router-dom";
import SelectPack from "components/SelectPack";
import * as utils from "common/utils";
import { Bar } from "react-chartjs-2";
import { setFormState } from "containers/App/_actions";
import Button from "components/Button/Button";

class MyOrgAnalysisSummary extends React.Component {
    componentDidMount() {
        this.props.getAllFarms();
    }

    setYearEnd(e) {
        const yearEnd = e.target.value;
        const { authContext } = this.props;
        const { canBePublishedTo } = authContext;
        const { myOrgAnalysisSummary_source: source = canBePublishedTo ? "published_analysis" : "budget" } = this.props.formState;
        this.props.getBlockAccountBenchmarks(yearEnd, source);
        this.props.setFormState("myOrgAnalysisSummary_yearEnd", yearEnd);
    }

    setSource(e) {
        const source = e.target.value;
        const { myOrgAnalysisSummary_yearEnd: yearEnd } = this.props.formState;
        if (yearEnd) {
            this.props.getBlockAccountBenchmarks(yearEnd, source);
        }
        this.props.setFormState("myOrgAnalysisSummary_source", source);
    }

    refreshResults() {
        const { authContext } = this.props;
        const { canBePublishedTo } = authContext;
        const { myOrgAnalysisSummary_yearEnd: yearEnd, myOrgAnalysisSummary_source: source = canBePublishedTo ? "published_analysis" : "budget" } = this.props.formState;
        this.props.getBlockAccountBenchmarks(yearEnd, source);
        this.props.getAllFarms();
    }

    render() {
        const { analysisSummary = [], analysisSummaryLoading, authContext, refData = {}, formState = {}, setFormState, allFarms = [] } = this.props;
        const { isAdmin: isAuthorised, canBePublishedTo } = authContext;
        const { myOrgAnalysisSummary_yearEnd: yearEnd, myOrgAnalysisSummary_source: source = canBePublishedTo ? "published_analysis" : "budget" } = formState;
        const { budgetYears = [] } = refData;
        const allFarmIds = [];
        let allBlocks = [];
        analysisSummary.forEach((a) => {
            const { blocks = [] } = a;
            blocks.forEach((a) => allFarmIds.push(a.farmId));
            allBlocks.push(...blocks);
        });
        const farmIds = [...new Set(allFarmIds)];
        const farms = farmIds.map((id) => allBlocks.find((b) => b.farmId === id));
        const tbcFarms = allFarms.filter((f) => !farmIds.includes(f.id));
        //const totalArea = farms.reduce((total,farm)=> total+= farm.totalFarmArea,0)
        const totalArea = analysisSummary.reduce((total, summary) => (total += summary.totalAreaInHectares), 0);
        const productiveArea = analysisSummary.reduce((total, summary) => (total += summary.productiveAreaInHectares), 0);
        const totalIrrigatedArea = analysisSummary.reduce((total, summary) => (total += summary.totalIrrigatedArea), 0);
        const totalP = farms.reduce((total, farm) => (total += farm.budgetPLossTotal), 0);
        const totalN = farms.reduce((total, farm) => (total += farm.budgetNLossTotal), 0);
        const avgN = totalArea > 0 ? totalN / totalArea : 0;
        const avgP = totalArea > 0 ? totalP / totalArea : 0;
        const avgGhg = farms.length > 0 ? farms.reduce((total, farm) => (total += farm.budgetGhgkPerHa), 0) / farms.length / 1000 : 0;

        const datasets = analysisSummary.map((benchmark) => {
            const { blocks = [] } = benchmark;
            const nSortedBlocks = utils.clone(blocks);
            const pSortedBlocks = utils.clone(blocks);
            pSortedBlocks.sort((a, b) => (b.pLossPerHectareInKg < a.pLossPerHectareInKg ? 1 : -1));

            const barNData = {
                data: {
                    labels: nSortedBlocks.map((b, i) => {
                        return `${b.farmName} - ${b.blockName}`;
                    }),
                    datasets: [
                        {
                            label: "Actuals",
                            fill: "origin",
                            lineTension: 0,
                            backgroundColor: blocks.map((b) => {
                                return "#47A96D";
                            }),
                            borderColor: "black",
                            pointRadius: 0,
                            data: nSortedBlocks.map((b) => {
                                return b.nLossPerHectareInKg;
                            }),
                        },
                    ],
                },
            };

            const barPData = {
                data: {
                    labels: pSortedBlocks.map((b, i) => {
                        return `${b.farmName} - ${b.blockName}`;
                    }),
                    datasets: [
                        {
                            label: "Actuals",
                            fill: "origin",
                            lineTension: 0,
                            backgroundColor: pSortedBlocks.map((b) => {
                                return "#e39153";
                            }),
                            borderColor: "black",
                            pointRadius: 0,
                            data: pSortedBlocks.map((b) => {
                                return b.pLossPerHectareInKg;
                            }),
                        },
                    ],
                },
            };

            return { benchmark, barNData, barPData };
        });

        const nOptions = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (tooltipItem) => `${tooltipItem.label} kg/ha`,
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: { autoSkip: true },
                    display: false,
                },
                y: {
                    ticks: { beginAtZero: true },
                    display: true,
                    title: {
                        display: true,
                        text: "N loss per hectare (kg/ha)",
                    },
                },
            },
        };

        const pOptions = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (tooltipItem) => `${tooltipItem.label} kg/ha`,
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: { autoSkip: true },
                    display: false,
                },
                y: {
                    ticks: { beginAtZero: true },
                    display: true,
                    title: {
                        display: true,
                        text: "P loss per hectare (kg/ha)",
                    },
                },
            },
        };

        return (
            <Panel loading={analysisSummaryLoading}>
                <PanelBody>
                    {!isAuthorised && (
                        <div className="Tile-body-message">
                            <p className="lead">Access denied</p>
                        </div>
                    )}
                    {isAuthorised && (
                        <React.Fragment>
                            <div className="Grid">
                                {canBePublishedTo && (
                                    <div className="Grid-cell u-width1of3 u-pr-md">
                                        <SelectPack meta={{ nrf: true }} onChange={(e) => this.setSource(e)} autoFocus={true} value={source} id="source" label="Analysis source" requiredLabel={true}>
                                            <option value="budget">Farms you have access to</option>
                                            <option value="published_analysis">Published analysis</option>
                                        </SelectPack>
                                    </div>
                                )}
                                <div className="Grid-cell u-width1of3">
                                    <SelectPack meta={{ nrf: true }} onChange={(e) => this.setYearEnd(e)} autoFocus={true} value={yearEnd} id="yearEndData" label="Year ending" requiredLabel={true}>
                                        <option value="">Select a year end</option>
                                        {budgetYears.map((year) => (
                                            <option key={year} value={year}>{`${year - 1}-${year}`}</option>
                                        ))}
                                    </SelectPack>
                                </div>
                            </div>
                            {yearEnd && (
                                <Button onClick={() => this.refreshResults()} className="IconLink--sync Button Button--secondary u-mt-md">
                                    Refresh
                                </Button>
                            )}
                            {yearEnd && analysisSummary.length === 0 && <div className="u-mt-lg h3">There are no farm results for the selected year</div>}
                            {yearEnd && analysisSummary.length > 0 && (
                                <React.Fragment>
                                    <div className="h2 u-mt-lg">{source === "budget" ? "Farm analysis summary" : "Farm publication summary"}</div>

                                    <Grid className="u-mb-lg u-mt-sm">
                                        <GridCell className="u-width1of1">
                                            <div className="Table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Overview</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="HeroValue">
                                                                    <div className="HeroValue-item">
                                                                        <h5>Total farms</h5>
                                                                        <p className="HeroValue-item-number">{farms.length + tbcFarms.length}</p>
                                                                    </div>
                                                                    {source === "budget" && (
                                                                        <div className="HeroValue-item">
                                                                            <h5>farms with results</h5>
                                                                            <p className="HeroValue-item-number">{farms.length.toLocaleString(undefined, { maximumFractionDigits: 0 })}</p>
                                                                        </div>
                                                                    )}
                                                                    {source === "budget" && (
                                                                        <div className="HeroValue-item">
                                                                            <h5>farms to be completed</h5>
                                                                            <p className="HeroValue-item-number">{tbcFarms.length.toLocaleString(undefined, { maximumFractionDigits: 0 })}</p>
                                                                        </div>
                                                                    )}
                                                                    <div className="HeroValue-item">
                                                                        <h5>Farm area</h5>
                                                                        <p className="HeroValue-item-number">
                                                                            {totalArea.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                            <small>ha</small>
                                                                        </p>
                                                                    </div>
                                                                    <div className="HeroValue-item">
                                                                        <h5>Productive area</h5>
                                                                        <p className="HeroValue-item-number">
                                                                            {productiveArea.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                            <small>ha</small>
                                                                        </p>
                                                                    </div>
                                                                    <div className="HeroValue-item">
                                                                        <h5>Irrigated area</h5>
                                                                        <p className="HeroValue-item-number">
                                                                            {totalIrrigatedArea.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                            <small>ha</small>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </GridCell>

                                        <GridCell>
                                            <Grid className="u-mt-lg">
                                                <GridCell className="u-width1of3">
                                                    <div className="Table">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Nitrogen</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="HeroValue">
                                                                            <div className="HeroValue-item">
                                                                                <h5>Total</h5>
                                                                                <p className="HeroValue-item-number">
                                                                                    {totalN.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                                    <small>kg</small>
                                                                                </p>
                                                                            </div>
                                                                            <div className="HeroValue-item">
                                                                                <h5>Avg. N/ha</h5>
                                                                                <p className="HeroValue-item-number">
                                                                                    {avgN.toFixed(1)}
                                                                                    <small>kg</small>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </GridCell>
                                                <GridCell className="u-width1of3">
                                                    <div className="Table">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Phosphorus</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="HeroValue">
                                                                            <div className="HeroValue-item">
                                                                                <h5>Total</h5>
                                                                                <p className="HeroValue-item-number">
                                                                                    {totalP.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                                    <small>kg</small>
                                                                                </p>
                                                                            </div>
                                                                            <div className="HeroValue-item">
                                                                                <h5>Avg. P/ha</h5>
                                                                                <p className="HeroValue-item-number">
                                                                                    {avgP.toFixed(1)}
                                                                                    <small>kg</small>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </GridCell>
                                                <GridCell className="u-width1of3">
                                                    <div className="Table">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>GHG</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="HeroValue">
                                                                            <div className="HeroValue-item">
                                                                                <h5>Avg. GHG</h5>
                                                                                <p className="HeroValue-item-number">
                                                                                    {avgGhg.toFixed(1)}
                                                                                    <small>k</small>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </GridCell>
                                            </Grid>
                                        </GridCell>
                                    </Grid>

                                    {source === "budget" && (
                                        <Button onClick={() => setFormState("myOrgAnalysisSummary_tbcView", !formState.myOrgAnalysisSummary_tbcView)} className="IconLink--search Button Button--secondary u-mt-md">
                                            {formState.myOrgAnalysisSummary_tbcView ? "Show farms with results" : "Show to be completed farms"}
                                        </Button>
                                    )}
                                    {source === "budget" ? <div className="h5 u-mt-md">{formState.myOrgAnalysisSummary_tbcView ? `Farms yet to complete the ${yearEnd} year end analysis` : `Farms that have completed the ${yearEnd} year end analysis`}</div> : <div className="h5 u-mt-md">{`Farms that have published the ${yearEnd} year end analysis`}</div>}
                                    <Grid className="u-mb-lg u-mt-sm">
                                        <GridCell>
                                            <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "400px" }}>
                                                {formState.myOrgAnalysisSummary_tbcView ? (
                                                    <SortableTable data={tbcFarms} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }}>
                                                        {({ data, sortCriteria, handleSort }) => {
                                                            return (
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <SortableTableHeader label="Name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <SortableTableHeader label="Region" fieldName="region" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {data.map((farm, f) => {
                                                                            return (
                                                                                <tr key={f} className="hover">
                                                                                    <td>
                                                                                        <Link to={`/app/farm/${farm.id}`}>{farm.name}</Link>
                                                                                    </td>
                                                                                    <td>{utils.valueToText(refData.regions, farm.region)}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            );
                                                        }}
                                                    </SortableTable>
                                                ) : (
                                                    <SortableTable data={farms} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }}>
                                                        {({ data, sortCriteria, handleSort }) => {
                                                            return (
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <SortableTableHeader label="Name" fieldName="farmName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <SortableTableHeader label="Region" fieldName="region" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <SortableTableHeader label="Area HA" fieldName="area" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <SortableTableHeader label="N" fieldName="budgetNLossTotal" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                                            <SortableTableHeader label="N/ha" fieldName="budgetNLossPerHa" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                                            <SortableTableHeader label="P" fieldName="budgetPLossTotal" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                                            <SortableTableHeader label="P/ha" fieldName="budgetPLossPerHa" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                                            <SortableTableHeader label="Avg. GHG" fieldName="budgetGhgkPerHa" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {data.map((farm) => {
                                                                            return (
                                                                                <tr key={farm.farmId} className="hover">
                                                                                    <td>
                                                                                        <Link to={`/app/farm/${farm.farmId}`}>{farm.farmName || "Not set"}</Link>
                                                                                    </td>
                                                                                    <td>{utils.valueToText(refData.regions, farm.region)}</td>
                                                                                    <td className="u-textCenter">{(farm.totalFarmArea || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                                                                                    <td className="u-textCenter">{(farm.budgetNLossTotal || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                                                                                    <td className="u-textCenter">{farm.budgetNLossPerHa}</td>
                                                                                    <td className="u-textCenter">{(farm.budgetPLossTotal || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                                                                                    <td className="u-textCenter">{farm.budgetPLossPerHa}</td>
                                                                                    <td className="u-textCenter">{farm.budgetGhgkPerHa > 0 ? utils.round(farm.budgetGhgkPerHa / 1000, 1) : ""}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            );
                                                        }}
                                                    </SortableTable>
                                                )}
                                            </div>
                                        </GridCell>
                                    </Grid>

                                    <div className="h2">Block level analysis</div>
                                    <Grid className="u-mt-md">
                                        <GridCell>
                                            {datasets &&
                                                refData &&
                                                refData.blockTypes &&
                                                refData.blockTypes.map((blockType, i) => {
                                                    const benchmarksByType = datasets.filter((ds) => ds.benchmark.blockType === blockType.value);
                                                    return (
                                                        benchmarksByType.length > 0 && (
                                                            <Panel key={i} title={`${blockType.text} ${blockType.value === "FodderCrop" ? "Rotations" : " Blocks"}`} midBlue>
                                                                <PanelBody>
                                                                    {benchmarksByType.map((b, i) => {
                                                                        if (b.barNData) {
                                                                            const { benchmark = {} } = b;
                                                                            const blockIcon = utils.getBlockIcon(blockType.value);
                                                                            const { enterpriseTypes = "", farmCount = 0 } = benchmark;
                                                                            const entTypeNames = enterpriseTypes
                                                                                .split(",")
                                                                                .map((e) => utils.valueToText(refData.enterpriseTypes, e))
                                                                                .reduce((c, n) => (c += n + " "), "");

                                                                            return (
                                                                                <Grid key={i} className="u-mb-lg">
                                                                                    <GridCell className="u-mt-md">
                                                                                        <div className="FarmTable_wrapper">
                                                                                            <ul className="DataWidthTable FarmTable">
                                                                                                <li className="FarmTable-title">
                                                                                                    <div className="FarmTitle">
                                                                                                        <img className="FarmTitle-icon" src={blockIcon} alt="Block Type" />
                                                                                                        <div className="FarmTitle-heading">
                                                                                                            <span className="FarmTitle-name">{blockType.value}</span>
                                                                                                            <div className="u-text-md">
                                                                                                                {b.benchmark.region ? `Region: ${utils.valueToText(refData.regions, b.benchmark.region)}` : ""}
                                                                                                                {entTypeNames.length > 1 ? `. Enterprises: ${entTypeNames}` : ". Cut and Carry"}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <div className="FarmTable-supplementary">
                                                                                                <Bar data={b.barNData.data} height={50} options={nOptions} />
                                                                                            </div>
                                                                                            <div className="FarmTable-supplementary">
                                                                                                <Bar data={b.barPData.data} height={50} options={pOptions} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </GridCell>
                                                                                    <GridCell className="u-mt-md">
                                                                                        <Grid>
                                                                                            <GridCell className="u-width1of3">
                                                                                                <div className="Table">
                                                                                                    <table className="u-tbl-fix">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th colSpan="2">Nitrogen</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td data-width="30">Max</td>
                                                                                                                <td className="u-textRight">
                                                                                                                    {(benchmark.maxNLossPerHectareInKg || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                                                                    <small className="u-ml-sm">N loss/ha</small>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Mean</td>
                                                                                                                <td className="u-textRight">
                                                                                                                    {(benchmark.meanNLossPerHectareInKg || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                                                                    <small className="u-ml-sm">N loss/ha</small>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Min</td>
                                                                                                                <td className="u-textRight">
                                                                                                                    {(benchmark.minNLossPerHectareInKg || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                                                                    <small className="u-ml-sm">N loss/ha</small>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </GridCell>
                                                                                            <GridCell className="u-width1of3">
                                                                                                <div className="Table">
                                                                                                    <table className="u-tbl-fix">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th colSpan="2">Phosphorus</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td data-width="30">Max</td>
                                                                                                                <td className="u-textRight">
                                                                                                                    {(benchmark.maxPLossPerHectareInKg || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                                                                    <small className="u-ml-sm">P loss/ha</small>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Mean</td>
                                                                                                                <td className="u-textRight">
                                                                                                                    {(benchmark.meanPLossPerHectareInKg || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                                                                    <small className="u-ml-sm">P loss/ha</small>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Min</td>
                                                                                                                <td className="u-textRight">
                                                                                                                    {(benchmark.minPLossPerHectareInKg || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                                                                    <small className="u-ml-sm">P loss/ha</small>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </GridCell>
                                                                                            <GridCell className="u-width1of3">
                                                                                                <div className="Table">
                                                                                                    <table className="u-tbl-fix">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th colSpan="2">Summary</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td data-width="30">Total area</td>
                                                                                                                <td className="u-textRight">
                                                                                                                    {(benchmark.totalAreaInHectares || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                                                                    <small className="u-ml-sm">ha</small>
                                                                                                                </td>
                                                                                                            </tr>

                                                                                                            <tr>
                                                                                                                <td>Irrigated area</td>
                                                                                                                <td className="u-textRight">
                                                                                                                    {(benchmark.totalIrrigatedArea || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                                                                                                    <small className="u-ml-sm">ha</small>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Farms</td>
                                                                                                                <td className="u-textRight">{farmCount.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Blocks</td>
                                                                                                                <td className="u-textRight">{b.barNData.data.labels.length.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </GridCell>
                                                                                        </Grid>
                                                                                    </GridCell>
                                                                                </Grid>
                                                                            );
                                                                        }
                                                                        return undefined;
                                                                    })}
                                                                </PanelBody>
                                                            </Panel>
                                                        )
                                                    );
                                                })}
                                        </GridCell>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </PanelBody>
            </Panel>
        );
    }
}

export default compose(
    withAppContext,
    withActions({ getBlockAccountBenchmarks, clearBlockAccountBenchmarks, setFormState, getAllFarms }),
    withState((state) => {
        return {
            analysisSummary: state.myOrg.analysisSummary,
            analysisSummaryLoading: state.myOrg.analysisSummaryLoading,
            allFarms: state.myOrg.allFarms,
            formState: state.app.formState,
        };
    })
)(MyOrgAnalysisSummary);
