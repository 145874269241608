import * as domain from "common/domain";
import * as utils from "common/utils";
import Watermark from "components/Watermark";
import { EnterpriseNameWithIcon } from "components/EnterpriseNameWithIcon";
import { useAnalysisResults } from "containers/BudgetHome";

export default function FarmEntRsu({ analysis }) {
    const rsuByEnterprise = useRsuByEnterprise(analysis);

    return (
        <>
            <div className="Table Table-Compressed">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th data-width="20">Name</th>
                            {domain.farmYear.map((month) => (
                                <th key={month} data-width="5" className="numeric">
                                    {utils.month.format(month)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rsuByEnterprise?.map((enterprise) => {
                            const enterpriseKey = `enterprise_rsu_${enterprise.name}`;
                            return (
                                <tr key={enterpriseKey}>
                                    <td>
                                        <EnterpriseNameWithIcon enterprise={enterprise} />
                                    </td>
                                    {enterprise.byMonth.map((m) => {
                                        const monthKey = `${enterpriseKey}_${m.month}`;
                                        return (
                                            <td key={monthKey} id={monthKey} className="numeric">
                                                {utils.numeric.format(m.revisedStockUnits.value)}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

function useRsuByEnterprise(analysis) {
    const { data: analysisResults } = useAnalysisResults(analysis);

    if (analysisResults) {
        const data = analysisResults?.animals?.diet?.byEnterprise?.map((dietByEnterprise) => {
            const enterprise = analysis.enterprises.find((e) => e.id === dietByEnterprise.id);
            const result = {
                ...dietByEnterprise,
                type: enterprise?.type // Spread in the enterprise type for use in the EnterpriseNameWithIcon component
            }
            return result;
        });
        return data;
    }
}
