import React from "react";
import ActionLink from "components/ActionLink";

const ActionLinks = ({ add, addCrop, refData, authContext, settings }) => {
    return (
        <div className="ActionsBar ActionsBar--super u-print-none">
            <div className="ActionsBar-left">
                <ul className="ActionsBar-links">
                    <li>
                        <ActionLink onClick={() => add("Fertiliser")} className="IconLink--arrow-plus" id="add-fertiliser">
                            Add fertiliser
                        </ActionLink>
                    </li>
                    <li>
                        <ActionLink onClick={() => add("Effluent")} className="IconLink--arrow-plus" id="add-effluent">
                            Add dairy factory effluent
                        </ActionLink>
                    </li>
                    <li>
                        <ActionLink onClick={() => add("Irrigation")} className="IconLink--arrow-plus" id="add-irrigation">
                            Add irrigation
                        </ActionLink>
                    </li>
                    <li>
                        <ActionLink onClick={() => add("Lime")} className="IconLink--arrow-plus" id="add-lime">
                            Add lime
                        </ActionLink>
                    </li>
                    <li>
                        <ActionLink onClick={() => add("Supplements")} className="IconLink--arrow-plus" id="add-supplements">
                            Add supplements
                        </ActionLink>
                    </li>
                    {refData.deploymentType === "Science" && authContext.accountType === "Science" && settings && settings.allowedFunctions && settings.allowedFunctions.includes("UserDefinedCrops") && (
                        <li>
                            <ActionLink onClick={() => addCrop()} className="IconLink--arrow-plus" id="add-crop">
                                Add crop
                            </ActionLink>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default ActionLinks;
