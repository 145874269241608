import { useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import { useModal } from "common/hooks";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SelectField from "components/FormFields/SelectField";
import TextField from "components/FormFields/TextField";
import TextareaField from "components/FormFields/TextareaField";
import { createNotification, updateNotification } from "./_actions";

export default function ManageNotificationModal({ notification, onSave, close }) {
    const dispatch = useDispatch();

    const validate = (values) => {
        const validation = {};

        validation.type = FormUtils.validators.required(values.type);
        validation.startsAt = FormUtils.validators.required(values.startsAt);
        validation.expiresAt = FormUtils.validators.required(values.expiresAt);
        validation.content = FormUtils.validators.required(values.content);

        return validation;
    };

    const submit = async (values) => {
        const saveNotification = values.isNew ? createNotification : updateNotification;
        delete values.isNew;

        const result = await dispatch(saveNotification(values))
            .then(onSave)
            .then(close)
            .catch((error) => ({ [FORM_ERROR]: error }));
        return result;
    };

    return (
        <Form initialValues={notification} validate={validate} onSubmit={submit}>
            {({ values, handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`${values.isNew ? "Add" : "Edit"} notification`} close={close} submitting={submitting} wide allowOffline fluid>
                            <ModalBody error={submitError}>
                                <Grid>
                                    <GridCell>
                                        <Field name="type" label="Notification type" placeholder="Select a type" options={NOTIFICATION_TYPES} required component={SelectField} />
                                        <Field name="startsAt" label="Starts at" required component={TextField} />
                                        <Field name="expiresAt" label="Expires at" required component={TextField} />
                                        <Field name="content" label="Content" rows={10} required component={TextareaField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting} disabled={submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useManageNotificationModal() {
    const [modal, openModal] = useModal(ManageNotificationModal);

    const openManageNotificationModal = (notification, onSave) => {
        const modalProps = {
            notification,
            onSave,
        };
        openModal(modalProps);
    };

    return [modal, openManageNotificationModal];
}

const NOTIFICATION_TYPES = [
    { value: "info", text: "Information" },
    { value: "warning", text: "Warning" },
    { value: "error", text: "Error" },
    { value: "maintenance", text: "Maintenance window" },
];
