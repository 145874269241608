import { v4 as uuidv4 } from "uuid";
import { factoryIrrigator } from "common/domain";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import Details from "./Details";

export default function IrrigationCreate({ farm, analysis }) {
    const irrigator = factoryIrrigator();
    irrigator.id = uuidv4();
    irrigator.isNew = true;

    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.IRRIGATION} />
            <TileBody>
                <Details farm={farm} analysis={analysis} irrigator={irrigator} />
            </TileBody>
        </Tile>
    )
}
