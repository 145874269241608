
export const getGroupLabel = (group) => {
    switch (group) {
        case 1:
            return "Fixed depth and return period; visual assessment/dig a hole";
        case 2:
            return "Trigger point; fixed depth applied";
        case 3:
            return "Depth applied to achieve target; fixed return period";
        case 4:
            return "Trigger point and depth applied to achieve target";
        default:
            return "Application depth";
    }
}
