import * as utils from "common/utils";
import * as domain from "common/domain";
import Watermark from "components/Watermark";
import { useOverseerModelRun } from "containers/BudgetHome";

export default function RsuFromPastureByBlock({ analysis, enterpriseId }) {
    const rsuFromPasture = useRsuFromPasture(analysis, enterpriseId);
    if (!rsuFromPasture)
        return null;

    const rootKey = `rsu_from_pasture_${rsuFromPasture.name}`;

    return (
        <div className="Table">
            <Watermark />
            <table>
                <thead>
                    <tr>
                        <th data-width="20">RSU from pasture (RSU/ha)</th>
                        <th colSpan="13">
                            <span>Average RSU from pasture: </span>
                            <span id={`${rootKey}_average`}>{utils.numeric.format(rsuFromPasture.averagePastoralRSU.value, 2)} </span>
                            <span id={`${rootKey}_uom`}>RSU/ha</span>
                        </th>
                    </tr>
                    <tr>
                        <th className="u-borderTop-none">&nbsp;</th>
                        {domain.farmYear.map((month) => (
                            <th key={month} data-width="5" className="numeric">
                                {utils.month.format(month)}
                            </th>
                        ))}
                        <th data-width="5" className="numeric">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {rsuFromPasture.byBlock.map((block) => {
                        const blockKey = `${rootKey}_${block.name}`;
                        return (
                            <tr key={blockKey}>
                                <td>{block.name}</td>
                                {block.byMonth.map((m) => {
                                    const monthKey = `${blockKey}_${m.month}`;
                                    return (
                                        <td key={monthKey} id={monthKey} className="numeric">
                                            {utils.numeric.format(m.amount.value, 2)}
                                        </td>
                                    )
                                })}
                                <td id={`${blockKey}_total`} className="numeric u-text-md u-textBold">
                                    {utils.numeric.format(block.total?.value, 2)}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

function useRsuFromPasture(analysis, enterpriseId) {
    const { data } = useOverseerModelRun(analysis);
    if (!data) {
        return null;
    }

    const enterprise = analysis?.enterprises?.find((e) => e.id === enterpriseId);

    const blockResults = data?.modelResults?.blocks?.filter((block) => block.animals) || [];
    const fodderCropRotationResults = data?.modelResults?.fodderCropRotations?.filter((rotation) => rotation.animals) || [];
    const combinedResults = blockResults.concat(fodderCropRotationResults);

    const rsuFromPastureByBlock = combinedResults.map((block) => {
        const blockResultsForThisEnterprise = block.animals.pastureRSU.byEnterprise.find((e) => e.id === enterpriseId);
        const result = {
            id: block.id,
            name: block.name
        }
        if (blockResultsForThisEnterprise) {
            result.byMonth = blockResultsForThisEnterprise.byMonth;
            result.total = blockResultsForThisEnterprise.total;
        }
        return result;
    }) || [];

    const pastureFedForThisEnterprise = data?.modelResults?.farm?.animals?.pastureFed?.byEnterprise?.find((e) => e.id === enterpriseId);

    if (rsuFromPastureByBlock.length === 0) {
        return null;
    }

    return {
        id: enterprise?.id,
        name: enterprise?.name,
        averagePastoralRSU: pastureFedForThisEnterprise?.revisedStockUnitsFromPasturePerHectare,
        byBlock: rsuFromPastureByBlock?.filter(x => x.byMonth?.length > 0)
    }
}
