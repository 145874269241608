import TileSubPanel from "components/TileSubPanel";
import SoilListCard from "./SoilListCard";
import * as domain from "common/domain";
import { getBlockList } from "common/utils";
import { useRefData } from "common/hooks";

export default function SoilList({ farm, analysis }) {
    const refData = useRefData();

    if (!refData.blockTypes) return null;

    const blocks = analysis.blocks.filter((b) => b.type !== domain.BlockType.FodderCrop);

    const blockList = getBlockList(blocks);

    return (
        <div>
            {refData.blockTypes.map((blockType) => {
                const blocksByType = blockList.productiveBlocks.filter((block) => block.type === blockType.value);

                return (
                    blocksByType.length > 0 && (
                        <TileSubPanel key={blockType.value} title={`${blockType.text} ${blockType.value === "FodderCrop" ? "rotations" : " blocks"}`} stickyHeaderName={`budget.soil.${blockType.value}.collapsed`}>
                            {blocksByType.map((block) => (
                                <SoilListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                            ))}
                        </TileSubPanel>
                    )
                );
            })}
            {blockList.nonProductiveBlocks.length > 0 && (
                <TileSubPanel title="Other blocks" stickyHeaderName="analysis.soil.otherBlocks.collapsed">
                    {blockList.nonProductiveBlocks.map((block) => (
                        <SoilListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
        </div>
    );
}
