import { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-focus";
import arrayMutators from "final-form-arrays";
import SavePrompt from "components/SavePrompt";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import Alert from "components/Alert";
import CheckboxPack from "components/CheckboxPack";
import { getOutdoorPigsViewModel } from "common/viewModels";
import { pig as pigIcon } from "common/icons";
import OutdoorPigNumbers, { validateNumbers } from "./OutdoorPigNumbers";
import OutdoorPigFeedingSystem, { validateFeedingSystem } from "./OutdoorPigFeedingSystem";
import OutdoorPigFeedAmounts from "./OutdoorPigFeedAmounts";
import OutdoorPigFeedCompositions from "./OutdoorPigFeedCompositions";
import OutdoorPigBlockAreaAllocation from "./OutdoorPigBlockAreaAllocation";
import OutdoorPigGreenCoverApplications from "./OutdoorPigGreenCoverApplications";
import { useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";
import * as animalUtils from "../_utils";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function OutdoorPigs({ farm, analysis }) {
    const refData = useRefData();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const outdoorPigBlocks = (analysis.blocks || []).filter((b) => b.type === "ProductiveOutdoorPigs");
    const viewModel = getOutdoorPigsViewModel(farm, analysis, refData);

    return <OutdoorPigsClassComponent farm={farm} analysis={analysis} outdoorPigBlocks={outdoorPigBlocks} viewModel={viewModel} updateAnalysisAsync={updateAnalysisAsync} refData={refData} />
}

class OutdoorPigsClassComponent extends Component {
    constructor(props) {
        super(props);
        const { farm, analysis, viewModel } = props;

        this.state = {
            referrer: `/app/farm/${farm.id}/analysis/${analysis.id}/animals`,
            focusOnErrors: createDecorator(),
            viewModel,
        };
    }

    validate = (outdoorPigs) => {
        const errors = {};

        errors.numbers = validateNumbers(outdoorPigs.growOutUnitOnly, outdoorPigs.numbers || {});
        errors.feedingSystem = validateFeedingSystem(outdoorPigs);

        return errors;
    };

    save = (refData) => async (viewModel) => {
        // Map view model back to dto.
        const numbers = viewModel.numbers || {};
        const growersFinishers = numbers.growersFinishers || {};
        const hasWeanedNumber = growersFinishers.weanedNumber > 0;
        const hasBroughtIn = growersFinishers.broughtIn > 0;
        const includeWeanersGrowersFinishers = viewModel.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

        if (viewModel.growOutUnitOnly) {
            delete numbers.boars;
            delete numbers.sows;
            delete numbers.unmatedGilts;
        }

        if (!hasBroughtIn) {
            delete growersFinishers.purchaseLiveweight;
            delete growersFinishers.purchaseSaleAge;
        }

        if (!hasWeanedNumber && !hasBroughtIn) {
            delete growersFinishers.saleCarcassWeight;
            delete growersFinishers.saleAge;
        }

        const areaAllocations = viewModel.areaAllocations.map((viewModelAreaAllocation) => {
            const areaAllocation = {
                blockId: viewModelAreaAllocation.blockId,
                laneAndOtherAllocation: viewModelAreaAllocation.allocations.find((a) => a.stockClassGroup === undefined).percentage,
                stockAllocations: {},
            };

            const allocations = viewModelAreaAllocation.allocations.filter((allocation) => {
                if (!allocation.stockClassGroup) return false;

                if (allocation.stockClassGroup === "Growers" && !includeWeanersGrowersFinishers) return false;

                if (allocation.stockClassGroup !== "Growers" && viewModel.growOutUnitOnly) return false;

                if (allocation.percentage === 0) return false;

                return true;
            });
            allocations.forEach((allocation) => (areaAllocation.stockAllocations[allocation.stockClassGroup] = allocation.percentage));

            return areaAllocation;
        });

        const greenCoverApplications = viewModel.greenCoverApplications.map((viewModelGreenCoverApplication) => {
            const cover = {
                blockIds: viewModelGreenCoverApplication.blockIds,
                seasons: {},
            };

            const applications = viewModelGreenCoverApplication.applications.filter((application) => {
                if (application.stockClassGroup === "Growers" && !includeWeanersGrowersFinishers) return false;

                if (application.stockClassGroup !== "Growers" && viewModel.growOutUnitOnly) return false;

                return true;
            });
            applications.forEach((application) => (cover.seasons[application.stockClassGroup] = application.seasons));

            return cover;
        });

        const outdoorPigs = {
            growOutUnitOnly: viewModel.growOutUnitOnly,
            feedingSystem: {
                ...viewModel.feedingSystem,
                feedingMethods: {},
            },
            feedAmounts: {},
            feedCompositions: {},
            numbers,
            areaAllocations,
            greenCoverApplications,
        };

        // Feeding system
        const feedingMethods = viewModel.feedingSystem.feedingMethods.filter((feedingMethod) => {
            if (!feedingMethod.stockClassGroup) return false;

            if (feedingMethod.stockClassGroup === "Growers" && !includeWeanersGrowersFinishers) return false;

            if (feedingMethod.stockClassGroup !== "Growers" && viewModel.growOutUnitOnly) return false;

            return true;
        });
        feedingMethods.forEach((feedingMethod) => (outdoorPigs.feedingSystem.feedingMethods[feedingMethod.stockClassGroup] = feedingMethod));

        // Feed amounts
        let feedAmounts = viewModel.nonSowFeedAmounts.filter((feedAmount) => {
            if (["Weaners", "Growers", "Finishers"].includes(feedAmount.stockClass) && !includeWeanersGrowersFinishers) return false;

            if (feedAmount.stockClass === "GiltDevelopers" && viewModel.growOutUnitOnly) return false;

            if (feedAmount.stockClass === "Creep" && !viewModel.feedingSystem.creepFeedSupplied) return false;

            return true;
        });
        if (!viewModel.growOutUnitOnly) feedAmounts = feedAmounts.concat(viewModel.sowFeedAmounts);
        feedAmounts.forEach((feedAmount) => (outdoorPigs.feedAmounts[feedAmount.stockClass] = feedAmount));

        // Feed compositions
        let feedCompositions = viewModel.nonSowFeedCompositions.filter((feedComposition) => {
            if (["Weaners", "Growers", "Finishers"].includes(feedComposition.feedCompositionGroup) && !includeWeanersGrowersFinishers) return false;

            if (feedComposition.feedCompositionGroup === "GiltDevelopers" && viewModel.growOutUnitOnly) return false;

            if (feedComposition.feedCompositionGroup === "Creep" && !viewModel.feedingSystem.creepFeedSupplied) return false;

            return true;
        });
        if (!viewModel.growOutUnitOnly) feedCompositions = feedCompositions.concat(viewModel.sowFeedCompositions);
        feedCompositions.forEach((feedComposition) => (outdoorPigs.feedCompositions[feedComposition.feedCompositionGroup] = feedComposition));

        if (includeWeanersGrowersFinishers) {
            const { ageGrowersFedFeed, ageFinishersFedFeed } = refData.outdoorPigDefaults || {};
            if (!outdoorPigs.feedingSystem.ageGrowersFedFeed) outdoorPigs.feedingSystem.ageGrowersFedFeed = ageGrowersFedFeed;
            if (!outdoorPigs.feedingSystem.ageFinishersFedFeed) outdoorPigs.feedingSystem.ageFinishersFedFeed = ageFinishersFedFeed;
        } else {
            delete outdoorPigs.feedingSystem.ageGrowersFedFeed;
            delete outdoorPigs.feedingSystem.ageFinishersFedFeed;
        }

        const { analysis } = this.props;
        const enterprise = {
            id: viewModel.id,
            type: "OutdoorPigs",
            outdoorPigs,
        };
        const updatedAnalysis = animalUtils.getUpdatedAnalysisFromSavingEnterprise(analysis, enterprise);
        await this.props.updateAnalysisAsync(updatedAnalysis);
    };

    render() {
        const { farm, analysis, outdoorPigBlocks, refData } = this.props;
        return (
            <Form
                initialValues={this.state.viewModel}
                decorators={[this.state.focusOnErrors]}
                mutators={{
                    setProperty: (args, state, utils) => {
                        utils.changeValue(state, args[0], () => args[1]);
                    },
                    ...arrayMutators,
                }}
                validate={this.validate}
                onSubmit={this.save(refData)}
            >
                {({ form, values, handleSubmit, dirty, submitSucceeded }) => {
                    const disableSave = false;
                    return (
                        <form onSubmit={handleSubmit}>
                            {true && <SavePrompt blockIf={dirty && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={this.state.referrer} />}
                            <Panel title="Outdoor pigs" img={pigIcon} referrer={this.state.referrer}>
                                {false && <Alert className="u-mb-sm" type="info" text="Enter the enterprise details and add livestock by stock class. Health supplements fed out to the enterprise may also be added at the bottom of the page." />}
                                <PanelBody>
                                    <Grid>
                                        <GridCell>
                                            <Field name="id" type="hidden" component="input" />
                                            <Field name="growOutUnitOnly" label="Grow-out unit only (no sows)" className="u-mt-md" type="checkbox" component={CheckboxPack} />
                                        </GridCell>
                                    </Grid>
                                    <div className="u-mt-lg">
                                        {true && <OutdoorPigNumbers form={form} outdoorPigs={values} />}
                                        {true && <OutdoorPigFeedingSystem outdoorPigs={values} />}
                                        {true && <OutdoorPigFeedAmounts form={form} outdoorPigs={values} />}
                                        {true && <OutdoorPigFeedCompositions form={form} outdoorPigs={values} />}
                                        {true && <OutdoorPigBlockAreaAllocation form={form} outdoorPigs={values} farm={farm} analysis={analysis} />}
                                        {true && <OutdoorPigGreenCoverApplications form={form} outdoorPigs={values} farm={farm} analysis={analysis} outdoorPigBlocks={outdoorPigBlocks} />}
                                    </div>
                                </PanelBody>
                                <PanelFooter>
                                    <div className="ButtonBar ButtonBar--fixed">
                                        <div className="ButtonBar-left">
                                            <Link to={this.state.referrer} id="cancel-button" className="Button Button--secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="ButtonBar-right">
                                            <button type="submit" id="save-button" className="Button Button--primary" disabled={disableSave}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </PanelFooter>
                            </Panel>
                        </form>
                    );
                }}
            </Form>
        )
    }
}
