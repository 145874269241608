import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "components/Loader";
import { getUserInvitation } from "./_actions";

class Invitation extends Component {
    componentDidMount() {
        this.props.getUserInvitation(this.props.invitationId);
    }

    render() {
        const { online, isLoading, isAuthenticated, userInvitationRequested, userInvitation, invitationId } = this.props;

        if (online && (isLoading || !userInvitationRequested)) {
            return <Loader message="Verifying your invitation..." />;
        }

        if (!online || isAuthenticated || (userInvitationRequested && !userInvitation)) {
            return <Redirect to="/" />;
        }

        const authRedirect = userInvitation.status === "Pending" ? `/pub/register/${invitationId}` : "/pub/login";
        return <Redirect to={authRedirect} />;
    }
}

const _mapStateToProps = (state, ownProps) => {
    const { app, auth } = state;
    return {
        ...ownProps,
        online: app.online,
        isAuthenticated: auth.isAuthenticated,
        isLoading: auth.isLoading,
        invitationId: ownProps.match.params.invitationId,
        userInvitationRequested: auth.userInvitationRequested,
        userInvitation: auth.userInvitation,
    };
};

export default connect(_mapStateToProps, { getUserInvitation })(Invitation);
