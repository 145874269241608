import { useState } from "react";
import { useDispatch } from "react-redux";
import Pdf from "./Pdf";
import ActionLink from "components/ActionLink";
import { modalInlineClose, modalInlineOpen } from "containers/App/_actions";

export default function PdfModal(props) {
    const { title, fileName, css = "Modal--medium" } = props;

    const close = () => {
        props.close();
    };

    return (
        <div className="Modal_wrapper">
            <div className={`Modal ${css}`}>
                <div className="Modal-head">
                    <ActionLink onClick={() => close()} className="Modal-close" id="fertiliser_modal_close">
                        <i className="icon icon-cross" />
                    </ActionLink>
                    <span>{title}</span>
                </div>
                <div className="Modal-body" style={{ height: "800px", overflowY: "auto", overflowX: "hidden" }}>
                    <Pdf {...props} />
                </div>
                <div className="Modal-footer">
                    <div className="ButtonBar">
                        <div className="ButtonBar-left">
                            <button id="pdf-close" type="button" onClick={() => close()} className="Button Button--secondary">
                                Close
                            </button>
                        </div>
                        <div className="ButtonBar-right">
                            <a href={fileName} className="Button" download={`${title}.pdf`} target="_blank" rel="noopener noreferrer">
                                Download
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function usePdfModal(title, fileName, css = "") {
    const dispatch = useDispatch();
    const [showPdfModal, setShowPdfModal] = useState(false);

    const openPdfModal = () => {
        setShowPdfModal(true);
        dispatch(modalInlineOpen());
    };

    const closePdfModal = () => {
        setShowPdfModal(false);
        dispatch(modalInlineClose());
    };

    const pdfModal = showPdfModal ? <PdfModal fileName={fileName} title={title} close={closePdfModal} css={css} /> : null;

    return [pdfModal, openPdfModal, closePdfModal];
}
