import React, { useEffect } from "react";
import { useQueryString } from "common/effects";
import { useDispatch, useSelector } from "react-redux";
import { logEmailTracking } from "common/actions";

export default function Body({ children }) {
    const queryString = useQueryString();
    const dispatch = useDispatch();
    const emailState = useSelector((state) => state.app.emailId);

    useEffect(() => {
        const emailQueryString = queryString.get("emailid");
        if (emailQueryString && emailQueryString !== emailState) {
            dispatch({ type: "EMAIL_ID", payload: emailQueryString });
            dispatch(logEmailTracking("User clicked on link in email", emailQueryString));
        }
    }, [dispatch, emailState, queryString]);

    return <div className="react-body">{children}</div>;
}
