import { Link } from "react-router-dom";
import * as domain from "common/domain";
import { useOnline, useRefData } from "common/hooks";
import * as utils from "common/utils";
import Calendar from "components/Calendar";
import * as animalUtils from "./_utils";
import * as cropUtils from "../Crops/_utils";

export default function BlockListCard({ farm, analysis, block }) {
    const online = useOnline();
    const refData = useRefData();

    const hasErrors = online && analysis.messages?.some((m) => m.category === "Animals" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error");
    const blockTitle = _getBlockTitle(block, refData, hasErrors);
    const calendarData = _getCalenderData(farm, analysis, block, refData);
    const summaryIcons = _getSummaryIcons(calendarData);

    const hasCrops = utils.hasCrops(block) || utils.priorLandUseSeed(block);
    const cropName = block.type === domain.BlockType.ProductiveFruit ? "fruit" : block.type === domain.BlockType.ProductiveCrop ? "crops" : "pasture";
    const canHaveAnimals = animalUtils.canHaveAnimals(block);
    const canDistributeAnimals = canHaveAnimals && animalUtils.canDistributeAnimals(block) && block.type !== "ProductiveOutdoorPigs";
    const hasAnimals = calendarData.length > 0;
    const showRelativeProductivity = block.type !== domain.BlockType.ProductiveCrop && block.type !== domain.BlockType.FodderCrop && analysis.animalDistribution ? animalUtils.showRelativeProductivity(analysis.animalDistribution.relProdMethod) : false;

    return (
        <div className="FarmTable_wrapper">
            <ul className="DataWidthTable FarmTable">
                {blockTitle}
                {hasAnimals && summaryIcons}
                {canHaveAnimals && hasAnimals && showRelativeProductivity && block.type !== "ProductiveFruit" && (
                    <li data-width="md-grow xl-grow">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Relative productivity</span>
                                <span className="FarmTable-value">{block.relativeProductivity}</span>
                            </div>
                        </div>
                    </li>
                )}
                {canDistributeAnimals && !hasAnimals && block.type !== domain.BlockType.ProductiveCrop && block.type !== domain.BlockType.FodderCrop && (
                    <>
                        {analysis.enterprises?.length > 0 ? (
                            <li data-width="md-grow xl-grow" className="FarmTable-link">
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/animals/distribution`} className="IconLink--arrow-plus">
                                    Animal distribution
                                </Link>
                            </li>
                        ) : (
                            <li data-width="md-grow xl-grow" className="FarmTable-link">
                                <span>Please add animal enterprises</span>
                            </li>
                        )}
                    </>
                )}
                {canHaveAnimals && !hasCrops && block.type !== "ProductiveOutdoorPigs" && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <span>
                            This block does not have any {cropName} defined. Select the pasture/crops tab to add {cropName}.
                        </span>
                    </li>
                )}
                {!canHaveAnimals && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <span>Animals are not required for this block</span>
                    </li>
                )}
            </ul>
            {canHaveAnimals && hasAnimals && <div className="FarmTable-supplementary">{calendarData && <Calendar data={calendarData} />}</div>}
        </div>
    )
}

const _getBlockTitle = (block, refData, hasError) => {
    const blockIcon = utils.getBlockIcon(block.type);
    const blockType = utils.getBlockTypeText(refData, block);

    return (
        <li className="FarmTable-title" data-width="md-100 xl-40">
            {hasError && (
                <span className="Todo Todo--error error-anchor">
                    <i className="icon icon-alert" />
                </span>
            )}
            <div className="FarmTitle">
                <img className="FarmTitle-icon" src={blockIcon} alt="Block" />
                <div className="FarmTitle-heading">
                    <span className="FarmTitle-name" title={block.name}>
                        {block.name}
                    </span>
                    <div className="FarmTitle-sub">{blockType}</div>
                </div>
            </div>
        </li>
    );
}

const _getSummaryIcons = (calendarData = []) => {
    const icons = [];
    calendarData.forEach((c) => {
        const { slots = [] } = c;
        if (c.id === "NonFarmAnimals" && slots.length > 0) {
            icons.push(...slots.map((s) => s.icon));
        } else {
            icons.push(c.icon);
        }
    });

    const uniqueIcons = [...new Set(icons)];
    return (
        <li className="FarmTable-summaryIcons" data-width="md-shrink xl-shrink">
            {uniqueIcons.map((c, i) => {
                const cd = calendarData.find((c) => c.icon === c) || {}; //Not finding this ???
                return <img key={i} src={c} className="u-p-10" width="45" height="45" alt={cd.title} title={cd.title} />;
            })}
        </li>
    );
}

const _getCalenderData = (farm, analysis, block, refData) => {
    const { enterpriseTypes = [], swardAnimalTypes = [] } = refData;
    var enterprises = [];
    const animalResultsMap = block.currentResults && block.currentResults.pastureResults && block.currentResults.pastureResults.pastureRsu ? block.currentResults.pastureResults.pastureRsu : undefined;
    const findEnterprise = (analysis, id) => analysis.enterprises.find((e) => e.id === id);
    let canDistributeAnimals = !["ProductiveCrop", "ProductiveOutdoorPigs", "FodderCrop"].includes(block.type);

    if (block.type === domain.BlockType.ProductiveFruit && block.fruit && block.fruit.swardAnimalSource === "NonFarmAnimals") {
        const label = utils.valueToText(swardAnimalTypes, block.fruit.swardAnimalType);
        enterprises.push({ id: "NonFarmAnimals", type: block.fruit.swardAnimalType, name: "Non farm animals", icon: cropUtils.getNonFarmAnimalIcon(block.fruit.swardAnimalType), isNonFarmAnimal: true, months: [], subTitle: label });
        canDistributeAnimals = false;
    } else if (block.type === domain.BlockType.ProductiveCrop || block.type === domain.BlockType.FodderCrop) {
        const rotationYear = cropUtils.rotationYear(block);
        const _enterpriseMap = cropUtils.getAnimalsOnCropBlock(block, rotationYear, analysis);

        for (var id in _enterpriseMap) {
            const enterprise = id === "NonFarmAnimals" ? { isNonFarmAnimal: true } : findEnterprise(analysis, id);
            if (enterprise) {
                const type = enterprise.isNonFarmAnimal ? _enterpriseMap[id].type : enterprise.type;
                const name = enterprise.isNonFarmAnimal ? "Non farm animals" : utils.valueToText(enterpriseTypes, type);
                const icon = enterprise.isNonFarmAnimal ? cropUtils.getNonFarmAnimalIcon(type) : utils.getAnimalIcon(type);
                let months = _enterpriseMap[id];
                const reportingYear = rotationYear.length > 12 ? rotationYear.slice(12) : rotationYear;
                const rsuMonths = animalResultsMap && animalResultsMap[id];
                if (months.length === 0) {
                    months = (rsuMonths || [])
                        .filter((m) => m.value > 0)
                        .map((m) => {
                            return { month: m.month, text: `${m.value.toFixed(2)} rsu` };
                        });
                } else {
                    months = months.map((m) => {
                        const blockResults = rsuMonths ? rsuMonths.find((r) => r.month === m.month) : undefined;
                        const hasRsu = blockResults && blockResults.value > 0;
                        const rsu = hasRsu ? `${blockResults.value.toFixed(2)} rsu` : "Defol"; //(m.text && m.text.trim() ? m.text.trim() + ' ' : '-')
                        //use this if you want a different icon for defoliation
                        //const modIcon = hasRsu ? icon : icons.defol
                        const modIcon = icon;
                        return {
                            ...m,
                            text: enterprise.isNonFarmAnimal ? m.text : rsu,
                            icon: modIcon,
                        };
                    });
                }
                enterprises.push({ id, type, name, icon, months, rotationYear: reportingYear, isNonFarmAnimal: enterprise.isNonFarmAnimal });
            }
        }
    } else if (block.type === "ProductiveOutdoorPigs") {
        const enterprise = analysis.enterprises.find((e) => e.type === "OutdoorPigs") || {};
        const id = enterprise.id;
        const type = enterprise.type;
        const name = utils.valueToText(enterpriseTypes, enterprise.type);
        const icon = utils.getAnimalIcon(enterprise.type);
        enterprises.push({ id, type, name, icon, months: [] });
    } else {
        const { animals = [] } = block;

        enterprises = animals.map((animal) => {
            const id = animal.enterpriseId;
            const enterprise = findEnterprise(analysis, id);
            const type = enterprise.type;
            const name = utils.valueToText(enterpriseTypes, enterprise.type);
            const icon = utils.getAnimalIcon(enterprise.type);

            let months = animal.months.map((m) => {
                var blockResults = animalResultsMap && animalResultsMap[id] ? animalResultsMap[id].find((r) => r.month === m) : undefined;
                var rsu = blockResults && blockResults.value > 0 ? `${blockResults.value.toFixed(2)} rsu` : "-";
                return { month: m, text: rsu };
            });

            if (months.length === 0) {
                const rsuMonths = animalResultsMap && animalResultsMap[id];
                months = (rsuMonths || [])
                    .filter((m) => m.value > 0)
                    .map((m) => {
                        return { month: m.month, text: `${m.value.toFixed(2)} rsu` };
                    });
            }

            return { id, type, name, icon, months };
        });
    }

    const sortedByRefData = enterpriseTypes && enterpriseTypes.filter((et) => enterprises.some((e) => e.type === et.value)).map((et) => enterprises.find((e) => e.type === et.value) || {});
    const nonFarmAnimals = enterprises.find((e) => e.id === "NonFarmAnimals");
    if (nonFarmAnimals) {
        sortedByRefData.push(nonFarmAnimals);
    }

    const calendarData =
        sortedByRefData &&
        sortedByRefData.map((enterprise) => {
            let subTitle = canDistributeAnimals && (
                <span className="FarmTable-value-link a">
                    <span>Animal distribution</span>
                </span>
            );
            const slots = enterprise.months.map((month) => {
                return {
                    month: month.month,
                    monthIndx: month.monthIndx,
                    icon: month.isNonFarmAnimals ? cropUtils.getNonFarmAnimalIcon(month.type) : month.text ? month.icon || enterprise.icon : null,
                    iconText: month.text ? month.text : "",
                };
            });

            return {
                id: enterprise.id,
                title: enterprise.name,
                icon: enterprise.icon,
                subTitle: subTitle || enterprise.subTitle,
                url: canDistributeAnimals && `/app/farm/${farm.id}/analysis/${analysis.id}/animals/distribution`,
                months: enterprise.rotationYear,
                slots: slots,
                noMonthsMessage:
                    block.type === "ProductiveCrop" && slots.length === 0 ? (
                        <span>Animals grazing in previous year only</span>
                    ) : (
                        <span className="u-flex u-flexAlignItemsCenter">
                            <img src={enterprise.icon} className="u-p-5" width="45" height="45" alt={subTitle || enterprise.subTitle} />
                            <span>Using default grazing months</span>
                        </span>
                    ),
            };
        });
    return calendarData;
}
