import React from "react";
import { compose, withAppContext } from "containers/HOC";
import { Route } from "react-router-dom";

class ScrollTopRoute extends React.Component {
    componentDidMount() {
        this.scrollTop();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) this.scrollTop();
    }

    scrollTop() {
        window.scrollTo(0, !this.props.restoreScrollY ? 0 : this.props.location.scrollY);
    }

    render() {
        return <Route {...this.props} />;
    }
}

export default compose(withAppContext)(ScrollTopRoute);
