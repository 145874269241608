import AnalysisDetails from "./AnalysisDetails";
import Page from "components/Page";
import PageBody from "components/PageBody";
import * as datasetUtils from "./_utils";
import { useParams } from "react-router-dom";
import { useUpdateDatasetAnalysisAsync } from "./_hooks";

export default function AnalysisEdit({ farm, analysis }) {
    const { datasetId } = useParams();
    const updateDatasetAnalysisAsync = useUpdateDatasetAnalysisAsync();

    if (!farm || !analysis) {
        return null;
    }

    if (!analysis?.blocks) {
        analysis.blocks = [datasetUtils.createEmptyBlock("Block 1")];
    }

    const farmViewModel = {
        farm,
        analysis: datasetUtils.analysisViewModel(analysis),
    };

    return (
        <Page>
            <PageBody>
                <AnalysisDetails datasetId={datasetId} farmViewModel={farmViewModel} onSave={updateDatasetAnalysisAsync} />
            </PageBody>
        </Page>
    )
}
