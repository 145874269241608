import React from "react";
import { compose, withAppContext } from "containers/HOC";
import { usePortalModal } from "common/effects";
import PaymentModal from "containers/Payments/PaymentModal";
import ActionLink from "components/ActionLink";
import moment from "moment";

const ExtendSubscriptionLink = ({ farm, onPaid, online, refData }) => {
    const { openModal, getModal } = usePortalModal();

    const pay = (farm) => (e) => {
        openModal("PaymentModal", { farms: [farm], onPaid });
    };

    const paymentModal = getModal("PaymentModal", PaymentModal);
    const daysTillExpired = farm.expired ? 0 : farm.expirationDate ? moment.utc(farm.expirationDate).diff(moment.utc(), "days") : 0;

    const canExtend = !farm.expired && daysTillExpired >= 0 && daysTillExpired < 182 && refData.deploymentType === "Commercial" && online;

    return canExtend ? (
        <>
            <ActionLink id="farm-home-make-payment" className="IconLink--dollar ExtendSubscription" onClick={pay(farm)}>
                Extend subscription
            </ActionLink>
            {paymentModal}
        </>
    ) : null;
};

export default compose(withAppContext)(ExtendSubscriptionLink);
