import { useState, useEffect } from "react";
import Alert from "components/Alert";
import { useShowQuickTips } from "common/hooks";

export function useFieldHelp(helpText) {
    const [showHelp, setShowHelp] = useState(helpText);
    const showQuickTips = useShowQuickTips();

    useEffect(() => {
        setShowHelp(showQuickTips);
    }, [showQuickTips]);


    const toggleHelp = () => {
        setShowHelp(prevState => !prevState);
    }

    const fieldHelpLink = helpText && <span title={showHelp ? "Hide help for this field" : "Show help for this field"} className="help" onClick={toggleHelp}>?</span>;
    const fieldHelp = showHelp && <Alert type="help" text={helpText} className="u-mb-0" />;

    return [fieldHelpLink, fieldHelp]
}