import * as utils from "common/utils";
import * as domain from "common/domain";

export const getAvailableBlockArea = (analysis, block, id) => {
    if (!block) return 0;

    let availableBlockArea = block.areaInHectares;

    // Drainage systems
    if (block.drainageDetailsId && block.drainageDetailsId !== id) {
        /*eslint-disable no-unused-vars*/
        for (const drainageSystem of analysis.drainageSystems || []) {
            const drainage = (drainageSystem.drainage || []).find((ds) => ds.id === block.drainageDetailsId);
            if (drainage) {
                const allocatedBlockArea = utils.round(availableBlockArea * (drainage.propDrained / 100), 1);
                availableBlockArea -= allocatedBlockArea;
            }
        }
    }

    // Unfenced wetlands
    /*eslint-disable no-unused-vars*/
    for (const unfencedWetland of (analysis.wetlands || []).filter((w) => w.id !== id)) {
        const catchmentPercentage = (unfencedWetland.catchmentPercentages || []).find((cp) => cp.blockId === block.id);
        if (catchmentPercentage) {
            const allocatedBlockArea = utils.round(unfencedWetland.catchmentArea * (catchmentPercentage.percentage / 100), 1);
            availableBlockArea -= allocatedBlockArea;
        }
    }

    // Fenced wetlands
    /*eslint-disable no-unused-vars*/
    for (const fencedWetland of (analysis.blocks || []).filter((b) => b.type === domain.BlockType.NonProductiveWetland && b.wetland && b.wetland.id !== id)) {
        const catchmentPercentage = (fencedWetland.wetland.catchmentPercentages || []).find((cp) => cp.blockId === block.id);
        if (catchmentPercentage) {
            const allocatedBlockArea = utils.round(fencedWetland.wetland.catchmentArea * (catchmentPercentage.percentage / 100), 1);
            availableBlockArea -= allocatedBlockArea;
        }
    }

    return utils.round(availableBlockArea, 1);
};
