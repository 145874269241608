import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import { useModal } from "common/hooks";
import { httpClient } from "common/httpClient";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import { HiddenField, TextField } from "components/FormFields";

export default function GrantPublicationAccessModal({ accessInvitation, close }) {
    const grantPublicationAccessAsync = useGrantPublicationAccessAsync();
    const isAResend = accessInvitation.inviteeEmail ? true : false;

    const validate = (formValues) => {
        const validation = {};

        validation.inviteeEmail = FormUtils.validators.required(formValues.inviteeEmail);
        validation.inviteeEmail = validation.inviteeEmail || FormUtils.validators.email(formValues.inviteeEmail);
        validation.inviteeEmail = validation.inviteeEmail || FormUtils.validators.maxLength(255)(formValues.inviteeEmail);

        return validation;
    }

    const submitAsync = async (formValues) => {
        try {
            formValues.role = "PublicationAgent";
            await grantPublicationAccessAsync(formValues);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }


    return (
        <Form initialValues={accessInvitation} validate={validate} onSubmit={submitAsync}>
            {({ handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Grant publication access" submitting={submitting} close={close}>
                            <ModalBody info="Add a new user to act as your agent for publications that are published to you for this farm." error={submitError}>
                                <Grid>
                                    <GridCell>
                                        <Field name="id" component={HiddenField} />
                                        <Field name="inviteeEmail" label="Email" placeholder="Email address" disabled={isAResend} required component={TextField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit primary waiting={submitting}>
                                        Send
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useGrantPublicationAccessModal() {
    const [modal, openModal] = useModal(GrantPublicationAccessModal);

    const openGrantPublicationAccessModal = (accessInvitation) => {
        const modalProps = {
            accessInvitation
        };
        openModal(modalProps);
    };

    return [modal, openGrantPublicationAccessModal];
}

function useGrantPublicationAccessAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (accessInvitation) => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            try {
                await httpClient.put(`farms/${accessInvitation.farmId}/access`, accessInvitation, timeout);
            } catch (error) {
                if (error.status === 400) {
                    throw new Error("Error sending invite. Please ensure the email address is valid.");
                } else if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        }
    });

    return (accessInvitation) => mutation.mutateAsync(accessInvitation);
}
