import { useAuthContext, useConfirm } from "common/hooks";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import ActionLink from "components/ActionLink";
import { useMyOrg, useToggleAdminUserAsync, useRemoveUserFromMyOrgAsync } from "./_hooks";

export default function MyOrgUsers() {
    const { isLoading, data: myOrg } = useMyOrg();
    const authContext = useAuthContext();
    const confirm = useConfirm();
    const toggleAdminUserAsync = useToggleAdminUserAsync();
    const removeUserFromMyOrgAsync = useRemoveUserFromMyOrgAsync();

    const isAuthorised = authContext.isAdmin;
    const hasUsers = myOrg?.users?.length > 0 || false;

    const removeUserFromMyOrg = (user) => {
        confirm(`Are you sure you want to remove "${user.fullName}" from your organisation?`, async () => removeUserFromMyOrgAsync(user.id));
    }

    return (
        <Panel>
            <PanelBody loading={isLoading}>
                {!isAuthorised && (
                    <div className="Tile-body-message">
                        <p className="lead">You do not have permission to view this information</p>
                    </div>
                )}
                {isAuthorised && !hasUsers && (
                    <div className="Tile-body-message">
                        <p className="lead">No data</p>
                    </div>
                )}
                {isAuthorised && hasUsers && (
                    <>
                        <SortableTable data={myOrg.users} defaultSortCriteria={{ fieldName: "fullName" }}>
                            {({ data, sortCriteria, handleSort }) => {
                                return (
                                    <table>
                                        <thead>
                                            <tr>
                                                <SortableTableHeader label="Name" fieldName="fullName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Email" fieldName="email" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Activated?" fieldName="isActivated" sortCriteria={sortCriteria} handleSort={handleSort} bool />
                                                <SortableTableHeader label="Created" fieldName="createdOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                <SortableTableHeader label="Modified" fieldName="modifiedOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                <SortableTableHeader label="Org admin?" fieldName="isAdmin" sortCriteria={sortCriteria} handleSort={handleSort} bool shrink />
                                                <th className="th--shrink"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((user) => {
                                                const isCurrentUser = user.id === authContext.userId;
                                                return (
                                                    <tr key={user.email} id={user.email} className="hover">
                                                        <td>{user.fullName}</td>
                                                        <td>{user.email}</td>
                                                        <td className="u-textCenter">{user.isActivated ? "Yes" : "-"}</td>
                                                        <td>{utils.dateLong(user.createdOn, "-")}</td>
                                                        <td>{utils.dateLong(user.modifiedOn, "-")}</td>
                                                        <td className="u-textCenter">
                                                            {!user.isAPIUser && !isCurrentUser && (
                                                                <label className="Checkbox" htmlFor={"users-isadmin-select-" + user.id}>
                                                                    <input className="Checkbox-input" type="checkbox" id={"users-isadmin-select-" + user.id} checked={user.isAdmin} onChange={async () => toggleAdminUserAsync(user.id)} />
                                                                    <span className="Checkbox-label"></span>
                                                                </label>
                                                            )}
                                                            {!user.isAPIUser && isCurrentUser && "Yes"}
                                                            {user.isAPIUser && "API"}
                                                        </td>
                                                        <td className="u-textCenter">{!user.isAPIUser && !isCurrentUser && <ActionLink id={`remove-user-${user.id}`} className="IconLink--trash" onClick={() => removeUserFromMyOrg(user)}></ActionLink>}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                )
                            }}
                        </SortableTable>
                    </>
                )}
            </PanelBody>
        </Panel>
    )
}
