import * as icons from "common/icons";
import { useIsPaidVersionOfOverseer } from "common/hooks";

export default function Watermark() {
    const isPaidVersionOfOveseer = useIsPaidVersionOfOverseer();

    if (isPaidVersionOfOveseer) {
        return null;
    }

    return (
        <div className="Watermark u-pos-rel u-overflow-hidden" style={{ backgroundImage: `url(${icons.logoPrint()})` }} />
    )
}
