import { Component } from "react";
import numeral from "numeral";
import { withActions } from "containers/HOC";
import { Field, change, touch } from "redux-form";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import * as validations from "common/validations";
import InputPack from "components/InputPack";


/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
function DairyGoatLeftOversEnterpriseList({ analysis, ...rest }) {
    const refData = useRefData();

    return <DairyGoatLeftOversEnterpriseListClassComponent analysis={analysis} refData={refData} {...rest} />
}

const _calculateTotal = (fields) => {
    const total = fields.reduce((sum, field, index) => {
        const enterprisePercentage = fields.get(index);
        const percentage = numeral(numeral(enterprisePercentage.percentage).format("0")).value();
        return (sum += percentage);
    }, 0);
    return total;
}

const _getEnterpriseName = (analysis, enterpriseId, refData) => {
    const enterprise = (analysis.enterprises || []).find((e) => e.id === enterpriseId);
    if (!enterprise) return "Unknown";

    const enterpriseName = utils.valueToText(refData.enterpriseTypes, enterprise.type);
    return enterpriseName;
}

class DairyGoatLeftOversEnterpriseListClassComponent extends Component {
    state = {
        total: _calculateTotal(this.props.fields),
    };

    _onPercentageChanged = (e, newValue, oldValue) => {
        const newPercentage = numeral(numeral(newValue).format("0")).value();
        const oldPercentage = numeral(numeral(oldValue).format("0")).value();
        const diff = newPercentage - oldPercentage;
        this.setState({ total: this.state.total + diff });
    };

    _formatPercentage = (e, newValue, index) => {
        if (!newValue) return;

        e.preventDefault();
        this.props.change("enterprise-details-form", `${this.props.fields.name}[${index}].percentage`, numeral(newValue).format("0"));
        this.props.touch("enterprise-details-form", `${this.props.fields.name}[${index}].percentage`);
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ total: _calculateTotal(nextProps.fields) });
    }

    render() {
        const { fields, analysis, refData, meta } = this.props;

        return (
            <>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th>Enterprise</th>
                                <th data-width="10">Percentage eaten</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields.map((field, index) => {
                                const enterprisePercentage = fields.get(index);
                                const enterpriseName = _getEnterpriseName(analysis, enterprisePercentage.enterpriseId, refData);
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Field name={`${field}.enterpriseId`} type="hidden" component="input" />
                                            {enterpriseName}
                                        </td>
                                        <td>
                                            <Field name={`${field}.percentage`} type="text" component={InputPack} noLabel={true} placeholder="0" uom="%" validate={[validations.range(0, 100)]} onChange={this._onPercentageChanged} onBlur={(e, newValue) => this._formatPercentage(e, newValue, index)} />
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td></td>
                                <td className={`td--total ${meta.error ? "has-error" : ""}`}>
                                    <div>TOTAL: {this.state.total}%</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {meta.error && meta.submitFailed && (
                    <div className="Grid-cell">
                        <div className="Field-error u-block">{meta.error}</div>
                    </div>
                )}
            </>
        );
    }
}

export default withActions({ change, touch })(DairyGoatLeftOversEnterpriseList);
