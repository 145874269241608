import React from "react";
import * as utils from "common/utils";

const SystemComparison = ({ results = {}, refData }) => {
    return (
        <div className="Table Table-Compressed">
            <table>
                <thead>
                    <tr>
                        <th className="h3" data-width="40">
                            Farm management
                        </th>
                        <th className="u-textCenter" data-width="20">
                            Before
                        </th>
                        <th className="u-textCenter" data-width="20">
                            After
                        </th>
                        <th className="u-textRight" data-width="20">
                            Change
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <SystemComparisonRow result={results.pastureProduction} label="Pasture production (kg DM)" />
                    <SystemComparisonRow result={results.averagePastureProduction} label="Average pasture production (kg DM/ha)" />
                    <SystemComparisonRow result={results.milkSolidsProductionKG} label="Milk soilds (kg)" />
                    <SystemComparisonRow result={results.croppingArea} label="Cropping area (ha)" />
                    {results.animalRSU && Object.keys(results.animalRSU).map((key, index) => <SystemComparisonRow key={index} result={results.animalRSU[key]} label={utils.valueToText(refData.enterpriseTypes, key)} />)}
                    {results.supplementsImportedKG && Object.keys(results.supplementsImportedKG).map((key, index) => <SystemComparisonRow key={index} result={results.supplementsImportedKG[key]} label={`Imported supplements - ${key}`} />)}
                </tbody>
            </table>
        </div>
    );
};

const SystemComparisonRow = ({ result, label }) => {
    const diff = (result && result.to - result.from) || 0;
    const increased = diff > 0;
    const decreased = diff < 0;

    const from = result && result.from >= 0 ? result.from.toLocaleString() : "-";
    const to = result && result.to >= 0 ? result.to.toLocaleString() : "-";
    const change = increased ? `+${diff.toLocaleString()}` : decreased ? diff.toLocaleString() : "-";
    const changeTextClassName = increased ? "u-textError u-textBold" : decreased ? "u-textSuccess u-textBold" : "";
    const changeArrowClassName = increased ? "IconLink--arrow-up-right IconLink--compressed" : decreased ? "IconLink--arrow-down-right IconLink--compressed" : "";

    return (
        <tr>
            <td className="">{label}</td>
            <td className="u-textCenter">{from}</td>
            <td className="u-textCenter">{to}</td>
            <td className={changeTextClassName}>
                <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                    <span className={changeArrowClassName}></span>
                    <span>{change}</span>
                </div>
            </td>
        </tr>
    );
};

export default SystemComparison;
