import { v4 as uuidv4 } from "uuid";
import React from "react";

export default function MapLegend({ mapData, onFeatureAdded }) {
    const textboxId = `testing_geojson`;

    const setMapJson = () => {
        const jsonString = document.getElementById(textboxId).value;
        const geoJson = JSON.parse(jsonString);
        geoJson.id = uuidv4();
        mapData.addGeoJson(geoJson);
        onFeatureAdded(mapData, geoJson.id);
    };

    return (
        <div style={{ display: "none" }} id="testing_panel">
            <input type="text" id={textboxId} />
            <input type="button" id="testing_submit" onClick={() => setMapJson()} value="submit" />
        </div>
    );
}
