import React, { useEffect, useMemo, useState } from "react";
import { Panel, PanelBody } from "components/Panel";
import Page from "components/Page";
import PageBody from "components/PageBody";
import DashboardPageHead from "./DashboardPageHead";
import * as contentful from "contentful";
import { Grid, GridCell } from "components/Grid";

const MyWhatsNew = () => {
    const space = process.env.REACT_APP_CMS_SPACE;
    const accessToken = process.env.REACT_APP_CMS_ACCESS_TOKEN;
    const host = process.env.REACT_APP_CMS_HOST;
    const [article, setArticle] = useState();

    const client = useMemo(() => {
        return contentful.createClient({
            space,
            accessToken,
            host,
        });
    }, [space, accessToken, host]);

    useEffect(() => {
        client &&
            client
                .getEntry(process.env.REACT_APP_CMS_ENTRY_ID)
                .then((a) => {
                    setArticle(a);
                })
                .catch((err) => {
                    console.log(err);
                });
    }, [client, setArticle]);

    if (!article) return null;
    const { fields = {} } = article;
    const { articles = [] } = fields;
    return (
        <Page>
            <DashboardPageHead breadcrumb={[{ name: "What's new" }]} />
            <PageBody>
                <Panel title="What's new" referrer={"/"}>
                    <PanelBody>
                        <Grid>
                            {articles.map((f) => {
                                const { fields: aFields = {} } = f;
                                const { media, youtubeUrl } = aFields;
                                return (
                                    <GridCell key={f.sys.id} className="u-xl-width1of4 u-lg-width1of2 u-md-width1of1">
                                        <div className="NewsArticle-item">
                                            {media && media.fields && media.fields.file && media.fields.file.url && (
                                                <a href={aFields.link} target="_blank" rel="noreferrer">
                                                    <img className="u-p-5" src={`https:${media.fields.file.url}`} alt={aFields.title} />
                                                </a>
                                            )}

                                            {youtubeUrl && <iframe width="400" height="225" src={youtubeUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
                                            <a href={aFields.link} target="_blank" rel="noreferrer">
                                                <div className="h4 u-mt-sm u-font-lg">{aFields.title}</div>
                                            </a>
                                            <div className="u-mt-sm">{aFields.description}</div>
                                        </div>
                                    </GridCell>
                                );
                            })}
                        </Grid>
                    </PanelBody>
                </Panel>
            </PageBody>
        </Page>
    );
};

export default MyWhatsNew;
