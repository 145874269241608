import { Switch, Route } from "react-router-dom";
import { Panel, PanelBody, PanelTabs } from "components/Panel";
import BlockList from "./BlockList";
import MapView from "./MapView";
//import AnalysisMap from 'containers/AnalysisMap/AnalysisMap';

export default function BlocksTabViews({ farm, analysis }) {
    const baseUrl = `/app/farm/${farm.id}/analysis/${analysis.id}`;

    const mapView = { title: "Map view", path: `${baseUrl}`, exact: true };
    const listView = { title: "List view", path: `${baseUrl}/list`, exact: true };

    let tabs = { mapView, listView };
    if (!analysis.isDrawn) {
        tabs = { listView, mapView };
    }

    return (
        <Panel>
            <PanelTabs
                tabs={tabs}
                actions={
                    <ul className="FarmAreaTotals">
                        <li className="sub-area">Total area declared as blocks: {analysis.totalBlockAreaInHectares} ha</li>
                        <li className="sub-area">Area not declared as blocks: {analysis.nonProductiveAreaInHectares} ha</li>
                        <li>Total farm area: {analysis.totalFarmArea} ha</li>
                    </ul>
                }
            />
            <PanelBody>
                <Switch>
                    <Route exact path={tabs.mapView.path} render={() => <MapView farm={farm} analysis={analysis} canEditBlocks />} />
                    <Route exact path={tabs.listView.path} render={() => <BlockList farm={farm} analysis={analysis} />} />
                </Switch>
            </PanelBody>
        </Panel>
    )
}
