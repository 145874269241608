import React from "react";

class CardPanel extends React.Component {
    state = {
        collapsed: this.props.open === undefined ? false : !this.props.open,
    };

    toggle = (e) => this.setState({ collapsed: !this.state.collapsed });

    render() {
        const { id, title, midBlue, skyBlue, green, orange, className, children } = this.props;

        let classNames = "u-pos-rel u-overflow-hidden FarmTableGroup";
        if (this.state.collapsed) classNames += " u-print-none";

        if (midBlue) classNames += " midblue";
        else if (skyBlue) classNames += " skyblue";
        else if (green) classNames += " green";
        else if (orange) classNames += " orange";
        else classNames += " darkblue";

        if (className) classNames += ` ${className}`;

        return (
            <div id={id} className={classNames}>
                {title && (
                    <div className={`FarmTableGroup-heading u-link u-print-none`} onClick={this.toggle}>
                        <span>
                            {title}
                            {this.state.collapsed && <span> ...</span>}
                        </span>
                        <i className={`Collapsible-head-affordance icon  ${this.state.collapsed ? "icon-arrow-down" : "icon-arrow-up"}`}></i>
                    </div>
                )}
                {!this.state.collapsed && children}
            </div>
        );
    }
}

export default CardPanel;
