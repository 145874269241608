import React from "react";
import { Panel, PanelBody } from "components/Panel";
import OutdoorPigFeedCompositionsFieldArray from "./OutdoorPigFeedCompositionsFieldArray";

export default function OutdoorPigFeedCompositions({ form, outdoorPigs }) {
    return (
        <Panel title="Feed composition" midBlue notCollapsible>
            <PanelBody>
                <OutdoorPigFeedCompositionsFieldArray title="Growers & finishers" fieldName="nonSowFeedCompositions" form={form} outdoorPigs={outdoorPigs} />
                {!outdoorPigs.growOutUnitOnly && <OutdoorPigFeedCompositionsFieldArray title="Sows" fieldName="sowFeedCompositions" form={form} outdoorPigs={outdoorPigs} />}
            </PanelBody>
        </Panel>
    )
}
