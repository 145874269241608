import { numeric } from "common/utils";
import Spinner from "components/Spinner/Spinner";
import { HelpTooltip } from "components/Help";
import { useAnalysisResults } from "containers/BudgetHome";

export default function NutrientLossTable({ analysis }) {
    const { isLoading, data: analysisResults } = useAnalysisResults(analysis);

    if (!analysisResults) return null;

    return (
        <div className="Table u-mt-md u-pos-rel u-overflow-hidden">
            <table>
                <thead>
                    <tr>
                        <th id="header-nutrient">
                            Nutrient loss
                        </th>
                        <th id="header-blocks" className="numeric">
                            Load from blocks (kg)
                            <HelpTooltip tip="The estimated nutrient load on the farm from blocks, before accounting for any load removed by wetlands or riparian strips" />
                        </th>
                        <th id="header-other" className="numeric">
                            Load from other sources (kg)
                            <HelpTooltip tip="The estimated nutrient load on the farm from sources other than blocks, before accounting for any load removed by wetlands or riparian strips" />
                        </th>
                        <th id="header-other" className="numeric">
                            Total load (kg)
                            <HelpTooltip tip="The estimated total nutrient load on the farm, before accounting for any load removed by wetlands or riparian strips, calculated as the sum of the load from blocks and other sources" />
                        </th>
                        <th id="header-wetalnds" className="numeric">
                            Nitrogen load removed by wetlands (kg)
                            <HelpTooltip tip="The estimated nitrogen load removed from the farm by wetlands" />
                        </th>
                        <th id="header-riparia" className="numeric">
                            Phosphorus load removed by riparian strips (kg)
                            <HelpTooltip tip="The estimated phosphorus load removed from the farm by riparian strips" />
                        </th>
                        <th id="header-farm" className="numeric">
                            Total loss (kg)
                            <HelpTooltip tip="The estimated total nutrient loss from the farm, accounting for any load removed by wetlands or riparian strips" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="N" key="N">
                        <td id="N-label">Nitrogen</td>
                        <td id="N-Total" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>
                                    {numeric.format(analysisResults?.nitrogen?.loadFromBlocks?.value)}
                                </>
                            }
                        </td>
                        <td id="N-Other" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>
                                    {numeric.format(analysisResults?.nitrogen?.loadFromOtherSources?.value)}
                                </>
                            }
                        </td>
                        <td id="N-Total" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>
                                    {numeric.format(analysisResults?.nitrogen?.totalLoad?.value)}
                                </>
                            }
                        </td>
                        <td id="N-Wetlands" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>
                                    {numeric.format(analysisResults?.nitrogen?.loadRemovedByWetlands?.value)}
                                </>
                            }
                        </td>
                        <td id="N-Riparian" className="numeric">-</td>
                        <td id="N-Farm" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>
                                    {numeric.format(analysisResults?.nitrogen?.totalLoss?.value)}
                                </>
                            }
                        </td>
                    </tr>
                    <tr id="P" key="P">
                        <td id="P-label">Phosphorus</td>
                        <td id="P-Total" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>
                                    {numeric.format(analysisResults?.phosphorus?.loadFromBlocks?.value)}
                                </>
                            }
                        </td>
                        <td id="P-Other" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>
                                    {numeric.format(analysisResults?.phosphorus?.loadFromOtherSources?.value)}
                                </>
                            }
                        </td>
                        <td id="P-Total" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>
                                    {numeric.format(analysisResults?.phosphorus?.totalLoad?.value)}
                                </>
                            }
                        </td>
                        <td id="P-Wetlands" className="numeric">-</td>
                        <td id="P-Riparian" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>
                                    {numeric.format(analysisResults?.phosphorus?.loadRemovedByRiparianStrips?.value)}
                                </>
                            }
                        </td>
                        <td id="P-Farm" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>
                                    {numeric.format(analysisResults?.phosphorus?.totalLoss?.value)}
                                </>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}