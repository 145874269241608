import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import WidgetContainer from "components/WidgetContainer";
import Alert from "components/Alert";
import { RequestFarmAccessActionLink } from "containers/Farm/RequestFarmAccess";

export default function Access({ farm, className, height }) {
    const refData = useRefData();
    const canManageAccess = utils.canManageAccess(farm);
    const canGrantAgentAccess = utils.canGrantPublicationAccess(farm);

    const url = (canManageAccess && `/app/farm/${farm.id}/access`) || (canGrantAgentAccess && `/app/farm/${farm.id}/publicationAccess`);
    const linkTitle = "Manage access";
    const currentRole = utils.valueToText(refData.farmRoles, farm.role);
    const title = `Farm access (${currentRole})`;

    const invitesMsg = canManageAccess && farm.numberRoles && farm.numberInvites && `There ${farm.numberInvites > 1 ? "are" : "is"} ${farm.numberInvites} ${farm.numberInvites > 1 ? "invites" : "invite"} to this farm.`;
    const requestsMsg = canManageAccess && farm.numberRoles && farm.numberRequests && `There ${farm.numberRequests > 1 ? "are" : "is"} ${farm.numberRequests} ${farm.numberRequests > 1 ? "requests" : "request"} for access to this farm.`;
    const warningMsg = (invitesMsg ? invitesMsg + " " : "") + (requestsMsg ? requestsMsg + " " : "");

    const pill = { level: !farm.numberRoles || farm.numberRoles.Owner ? "info" : "error", text: !farm.numberRoles || farm.numberRoles.Owner ? "OK" : "No owner" };

    if (pill.level === "info" && warningMsg && warningMsg !== "") {
        pill.level = "warning";
        pill.text = "Outstanding requests";
    }

    return (
        <WidgetContainer id="access" title={title} icon="IconLink--lock" className={className} hideLink={!canManageAccess && !canGrantAgentAccess} linkTitle={linkTitle} linkUrl={url} pill={pill} height={height}>
            <div>
                {warningMsg && warningMsg !== "" && <Alert type="warning" text={warningMsg + `Please select manage access link to review ${farm.numberRequests + farm.numberInvites > 1 ? "these requests" : "this request"}.`} />}

                <div className="HeroValue u-mb-md">
                    <div className="HeroValue-item compressed">
                        <h5>Owners</h5>
                        <p className="HeroValue-item-number">{(farm.numberRoles && farm.numberRoles.Owner) || 0}</p>
                    </div>
                    <div className="HeroValue-item compressed">
                        <h5>Admins</h5>
                        <p className="HeroValue-item-number">{(farm.numberRoles && farm.numberRoles.Admin) || 0}</p>
                    </div>
                    <div className="HeroValue-item compressed">
                        <h5>Write</h5>
                        <p className="HeroValue-item-number">{(farm.numberRoles && farm.numberRoles.Write) || 0}</p>
                    </div>
                    <div className="HeroValue-item compressed">
                        <h5>Invitations</h5>
                        <p className="HeroValue-item-number">{(farm.numberRoles && farm.numberInvites) || 0}</p>
                    </div>
                    <div className="HeroValue-item compressed">
                        <h5>Requests</h5>
                        <p className="HeroValue-item-number">{(farm.numberRoles && farm.numberRequests) || 0}</p>
                    </div>
                </div>
            </div>
            {currentRole !== "Owner" && (
                <span>
                    Your organisation's current farm role is <b>{currentRole}</b>. To request a different role for your organisation,{" "}
                    <RequestFarmAccessActionLink farm={farm} className="u-link">click here</RequestFarmAccessActionLink>.
                </span>
            )}
        </WidgetContainer>
    );
};
