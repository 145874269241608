import React, { Component } from "react";
import { compose, withActions, withState } from "containers/HOC";
import { Redirect } from "react-router-dom";
import Loader from "components/Loader";
import { getFarmAccessInvitation } from "./_actions";
import { withRouter } from "react-router-dom";

class FarmAccessInvitation extends Component {
    componentDidMount() {
        this.props.getFarmAccessInvitation(this.props.invitationId);
    }

    render() {
        const { online, isLoading, isAuthenticated, farmAccessInvitationRequested, farmAccessInvitation, match } = this.props;
        const displayPayment = match.path.endsWith("dp");

        if (online && (isLoading || !farmAccessInvitationRequested)) {
            return <Loader message="Verifying your invitation..." />;
        }

        if (!online || isAuthenticated || (farmAccessInvitationRequested && !farmAccessInvitation)) {
            return <Redirect to="/" />;
        }

        const ext = displayPayment ? `${farmAccessInvitation.farmId}/dp` : "";
        const authRedirect = farmAccessInvitation.inviteeUserId ? `/pub/login/${ext}` : `/pub/register/${ext}`;
        return <Redirect to={authRedirect} />;
    }
}

export default compose(
    withRouter,
    withState((state, ownProps) => {
        const { app, auth } = state;
        return {
            ...ownProps,
            online: app.online,
            isAuthenticated: auth.isAuthenticated,
            isLoading: auth.isLoading,
            invitationId: ownProps.match.params.invitationId,
            farmAccessInvitationRequested: auth.farmAccessInvitationRequested,
            farmAccessInvitation: auth.farmAccessInvitation,
        };
    }),
    withActions({ getFarmAccessInvitation })
)(FarmAccessInvitation);
