export const BUDGETED_SALES = {
    none: [
        { quarter: 1, sales: 0 },
        { quarter: 1, sales: 0 },
        { quarter: 1, sales: 0 },
        { quarter: 2, sales: 0 },
        { quarter: 2, sales: 0 },
        { quarter: 2, sales: 0 },
        { quarter: 3, sales: 0 },
        { quarter: 3, sales: 0 },
        { quarter: 3, sales: 0 },
        { quarter: 4, sales: 0 },
        { quarter: 4, sales: 0 },
        { quarter: 4, sales: 0 },
    ],
    2019: [
        { quarter: 3, sales: 0 },
        { quarter: 3, sales: 48 },
        { quarter: 3, sales: 111 },
        { quarter: 4, sales: 162 },
        { quarter: 4, sales: 267 },
        { quarter: 4, sales: 499 },
    ],
    2020: [
        { quarter: 1, sales: 500 },
        { quarter: 1, sales: 800 },
        { quarter: 1, sales: 900 },
        { quarter: 2, sales: 500 },
        { quarter: 2, sales: 400 },
        { quarter: 2, sales: 200 },
        { quarter: 3, sales: 200 },
        { quarter: 3, sales: 400 },
        { quarter: 3, sales: 400 },
        { quarter: 4, sales: 200 },
        { quarter: 4, sales: 300 },
        { quarter: 4, sales: 300 },
    ],
    2021: [
        { quarter: 1, sales: 1200 },
        { quarter: 1, sales: 1000 },
        { quarter: 1, sales: 700 },
        { quarter: 2, sales: 500 },
        { quarter: 2, sales: 450 },
        { quarter: 2, sales: 300 },
        { quarter: 3, sales: 300 },
        { quarter: 3, sales: 350 },
        { quarter: 3, sales: 300 },
        { quarter: 4, sales: 300 },
        { quarter: 4, sales: 400 },
        { quarter: 4, sales: 450 },
    ],
    2022: [
        { quarter: 1, sales: 603 },
        { quarter: 1, sales: 457 },
        { quarter: 1, sales: 514 },
        { quarter: 2, sales: 427 },
        { quarter: 2, sales: 186 },
        { quarter: 2, sales: 93 },
        { quarter: 3, sales: 117 },
        { quarter: 3, sales: 163 },
        { quarter: 3, sales: 186 },
        { quarter: 4, sales: 117 },
        { quarter: 4, sales: 117 },
        { quarter: 4, sales: 186 },
    ],
    2023: [
        { quarter: 1, sales: 150 },
        { quarter: 1, sales: 150 },
        { quarter: 1, sales: 300 },
        { quarter: 2, sales: 400 },
        { quarter: 2, sales: 450 },
        { quarter: 2, sales: 250 },
        { quarter: 3, sales: 300 },
        { quarter: 3, sales: 300 },
        { quarter: 3, sales: 500 },
        { quarter: 4, sales: 400 },
        { quarter: 4, sales: 450 },
        { quarter: 4, sales: 450 },
    ],
    2024: [
        { quarter: 1, sales: 350 },
        { quarter: 1, sales: 350 },
        { quarter: 1, sales: 400 },
        { quarter: 2, sales: 450 },
        { quarter: 2, sales: 450 },
        { quarter: 2, sales: 250 },
        { quarter: 3, sales: 200 },
        { quarter: 3, sales: 350 },
        { quarter: 3, sales: 450 },
        { quarter: 4, sales: 450 },
        { quarter: 4, sales: 500 },
        { quarter: 4, sales: 600 },
    ],
};
