import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext, useConfirm, useNavigate } from "common/hooks";
import { httpClient } from "common/httpClient";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import ActionLink from "components/ActionLink";
import * as utils from "common/utils";
import * as icons from "common/icons";
import { Link } from "react-router-dom";
import { useRevokeFarmAccessAsync } from "containers/hooks";

const GROUPBY_OPTIONS = [
    { value: "Organisation", text: "Organisation" },
    { value: "Farm", text: "Farm" },
];

const ROLE_OPTIONS = [
    { value: "All", text: "Any role" },
    { value: "Write", text: "Write" },
    { value: "Admin", text: "Admin" },
    { value: "Owner", text: "Owner" },
    { value: "AdminOwner", text: "Owner or Admin" },
];

const FarmLink = ({ farm }) => (
    <div className="u-flex u-flexJustifyBetween">
        <Link to={`/app/farm/${farm.id}`}>
            <span>{farm.name || "No name"}</span>
        </Link>
        <Link to={`/app/farm/${farm.id}`} target="_blank" alt="Open in new tab">
            <span className="IconLink--new-tab u-font-grey" style={{ opacity: "0.3" }}></span>
        </Link>
    </div>
);

export default function MyOrgFarmAccessSummary() {
    const { data: farms } = useFarmsIHaveAccessTo();
    const [selectedId, setSelectedId] = useState();
    const [groupBy, setGroupBy] = useState("Organisation");
    const [roleOf, setRoleOf] = useState("Owner");

    const confirm = useConfirm();
    const revokeFarmAccessAsync = useRevokeFarmAccessAsync();
    const navigate = useNavigate();
    const authContext = useAuthContext();

    if (!farms) {
        return (
            <div className="Tile-body-message">
                <img className="u-p-5" src={icons.search} alt="Plus" />
                <p className="lead">Loading your farms</p>
            </div>
        );
    }

    const _revokeAccess = (farmId, farmAccess) => {
        const farm = farms.find((f) => f.id === farmId);
        const isMyAccess = farmAccess.accountId === authContext.accountId;
        const confirmMsg = isMyAccess ? `Are you sure you want to revoke your own access for the farm ${farm.name}?` : `Are you sure you want to revoke access for ${farmAccess.accountName} for the farm ${farm.name}?`;
        confirm(confirmMsg, async () => {
            await revokeFarmAccessAsync(farmId, farmAccess);
            if (isMyAccess) {
                navigate("/");
            }
        });
    }

    const filteredFarms = farms.filter((f) => f.access.find((a) => a.accountId === authContext.accountId && (roleOf === "All" || (roleOf === "AdminOwner" && ["Owner", "Admin"].includes(a.role)) || a.role === roleOf)));

    const organisations = filteredFarms.reduce((allOrgs, farm) => {
        const { access = [] } = farm;
        const combinedOrgs = [...allOrgs];

        access.forEach((a) => {
            let existingOrg = combinedOrgs.find((o) => o.accountId === a.accountId);
            if (!existingOrg) {
                existingOrg = { ...a };
                combinedOrgs.push(existingOrg);
                existingOrg.farmIds = [];
            }
            existingOrg.farmIds.push({ id: farm.id, role: a.role });
        });
        return combinedOrgs;
    }, []);

    const OrganisationList = () => (
        <>
            {organisations
                .sort((a, b) => (a.accountName > b.accountName ? 1 : -1))
                .map((org) => (
                    <div key={org.accountId} style={{ padding: "8px", borderBottom: "1px solid #eee", backgroundColor: org.accountId === selectedId ? "lightblue" : "white" }}>
                        <ActionLink onClick={() => setSelectedId(org.accountId)}>{org.accountName || "No name"}</ActionLink>
                    </div>
                ))}
        </>
    );

    const FarmList = () => (
        <>
            {filteredFarms
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((farm) => (
                    <div key={farm.id} style={{ padding: "8px", borderBottom: "1px solid #eee", backgroundColor: farm.id === selectedId ? "lightblue" : "white" }}>
                        <ActionLink onClick={() => setSelectedId(farm.id)}>{farm.name || "No name"}</ActionLink>
                    </div>
                ))}
        </>
    );

    const OrganisationResults = () => {
        const farm = farms.find((f) => f.id === selectedId);
        if (!farm || !selectedId)
            return (
                <div className="Tile-body-message">
                    <img className="u-p-5" src={icons.search} alt="Plus" />
                    <p className="lead">Please select a farm</p>
                </div>
            );
        const yourAccess = farm.access.find((a) => a.accountId === authContext.accountId);
        const canManageAccess = utils.canManageAccess(farm);
        const isOwner = utils.isOwner(farm);

        return (
            <>
                <div className="h5 u-mb-sm">Farm details</div>
                <Grid className="u-mb-lg">
                    <GridCell className="u-width3of4">
                        <dl>
                            <dt>Name</dt>
                            <dd>
                                <FarmLink farm={farm} />
                                &nbsp;
                            </dd>
                            <dt>Address</dt>
                            <dd>{farm.address}&nbsp;</dd>
                            {false && (
                                <>
                                    <dt>Your role</dt>
                                    <dd>{yourAccess.role}&nbsp;</dd>
                                </>
                            )}
                        </dl>
                    </GridCell>
                </Grid>

                <div className="h5 u-mb-sm">Organisations</div>
                <div className="Table">
                    <table>
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>Their role</th>
                                <th className="td--shrink"></th>
                            </tr>
                            {organisations
                                .filter((o) => o.farmIds.some((f) => f.id === selectedId))
                                .sort((a, b) => (a.accountName > b.accountName ? 1 : -1))
                                .map((org) => {
                                    const theirAccess = farm.access.find((a) => a.accountId === org.accountId);
                                    const isOwnerAccess = theirAccess.role === "Owner";
                                    return (
                                        <tr key={org.accountId} style={{ padding: "8px", borderBottom: "1px solid #eee" }}>
                                            <td>{org.accountName || org.email}</td>
                                            <td>{theirAccess.role}</td>
                                            <td className="u-textRight td--shrink">
                                                {(isOwner || (canManageAccess && !isOwnerAccess)) && !["Guest", "Publication", "PublicationAgent"].includes(theirAccess.role) ? (
                                                    <ActionLink className="IconLink--cross-circle u-ml-sm" id={`revoke-${org.accountId}`} onClick={() => _revokeAccess(farm.id, theirAccess)}>
                                                        Revoke
                                                    </ActionLink>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    const FarmResults = () => {
        const organisation = organisations.find((o) => o.accountId === selectedId);
        if (!organisation || !selectedId) {
            return (
                <div className="Tile-body-message">
                    <img className="u-p-5" src={icons.search} height="45px" width="45px" alt="Plus" />
                    <p className="lead">Please select an organisation</p>
                </div>
            );
        }

        return (
            <>
                <div className="h5 u-mb-sm">Organisation details</div>
                <Grid className="u-mb-lg">
                    <GridCell className="u-width3of4">
                        <dl>
                            <dt>Name</dt>
                            <dd>{organisation.accountName}&nbsp;</dd>
                        </dl>
                    </GridCell>
                </Grid>
                <div className="h5 u-mb-sm">Farms</div>
                <div className="Table">
                    <table>
                        <tbody>
                            <tr>
                                <th>Name</th>
                                {false && <th>Your role</th>}
                                <th>Their role</th>
                                <th className="td--shrink"></th>
                            </tr>
                            {filteredFarms
                                .filter((f) => f.access.some((a) => a.accountId === selectedId))
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((farm) => {
                                    const theirAccess = farm.access.find((a) => a.accountId === selectedId);
                                    const yourAccess = farm.access.find((a) => a.accountId === authContext.accountId);
                                    const iAmAFarmOwner = yourAccess.role === "Owner";
                                    const iAmAFarmAdmin = yourAccess.role === "Admin";
                                    const isOwnerAccess = theirAccess.role === "Owner";

                                    return (
                                        <tr key={farm.id}>
                                            <td>
                                                <FarmLink farm={farm} />
                                            </td>
                                            {false && <td>{yourAccess.role}</td>}
                                            <td>{theirAccess.role}</td>
                                            <td className="u-textRight td--shrink">
                                                {(authContext.isSystemAdmin || iAmAFarmOwner || (iAmAFarmAdmin && !isOwnerAccess)) && !["Guest", "Publication", "PublicationAgent"].includes(theirAccess.role) ? (
                                                    <ActionLink className="IconLink--cross-circle u-ml-sm" id={`revoke-${theirAccess.accountId}`} onClick={() => _revokeAccess(farm.id, theirAccess)}>
                                                        Revoke
                                                    </ActionLink>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    return (
        <>
            <Panel loading={farms.length === 0}>
                <PanelBody>
                    <Grid className="u-mb-lg">
                        <GridCell className="u-width1of5">
                            <div className="Field u-mt-sm">
                                <label htmlFor="payment-method-filter" className="Select Select--block">
                                    <span className="Field-label">Group by</span>
                                    <select id="group-by" value={groupBy} onChange={(e) => setGroupBy(e.target.value)}>
                                        {GROUPBY_OPTIONS.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.text}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                            </div>
                        </GridCell>
                        {false && (
                            <GridCell className="u-width1of5 ">
                                <div className="Field u-mt-sm">
                                    <label htmlFor="payment-method-filter" className="Select Select--block">
                                        <span className="Field-label">Filter farms by your role of</span>
                                        <select id="group-by" value={roleOf} onChange={(e) => setRoleOf(e.target.value)}>
                                            {ROLE_OPTIONS.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.text}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </GridCell>
                        )}
                    </Grid>
                    <Grid>
                        <GridCell className="u-width1of4 u-pt-lg">
                            <div className="h5 u-mb-sm">{groupBy === "Farm" ? "Farms" : "Organisations"}</div>
                            <div style={{ height: "calc(100vh - 500px)", overflowY: "scroll", border: "1px solid #eee", borderRadius: "4px" }}>{groupBy === "Farm" ? <FarmList /> : <OrganisationList />}</div>
                        </GridCell>
                        <GridCell className="u-width3of4 u-pt-lg">{groupBy === "Farm" ? <OrganisationResults /> : <FarmResults />}</GridCell>
                    </Grid>
                </PanelBody>
            </Panel>
        </>
    )
}

function useFarmsIHaveAccessTo() {
    const authContext = useAuthContext();

    const query = useQuery({
        queryKey: ["my-org", "farm-access"],
        queryFn: async () => httpClient.get(`accounts/${authContext.accountId}/farmAccess`),
        retry: false,
        refetchOnWindowFocus: false
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error
    };
}
