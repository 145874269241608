import { useQuery } from "@tanstack/react-query";
import { Redirect } from "react-router-dom";
import { useAuthContext, useIsStudentUser } from "common/hooks";
import { httpClient } from "common/httpClient";
import * as icons from "common/icons";
import * as utils from "common/utils";
import Page from "components/Page";
import PageBody from "components/PageBody";
import { Panel, PanelBody } from "components/Panel";
import TileBodyMessage from "components/TileBodyMessage";
import ActionLink from "components/ActionLink";
import TileActionsBar from "components/TileActionsBar";
import FarmHomePageHead from "containers/FarmHome/FarmHomePageHead";
import FarmAccessList from "./FarmAccessList";
import PendingFarmAccessInvitations from "./PendingFarmAccessInvitations";
import { useGrantPublicationAccessModal } from "./GrantPublicationAccessModal";

export default function PublicationAccess({ farm }) {
    const { isFetching, isLoading, data: publicationAccess } = usePublicationAccess(farm?.id);
    const authContext = useAuthContext();
    const isStudent = useIsStudentUser();
    const [grantPublicationAccessModal, openGrantPublicationAccessModal] = useGrantPublicationAccessModal();
    const referrer = `/app/farm/${farm.id}`;

    const hasPublications = farm?.publications?.length > 0 || false;
    const canGrantAgentAccess = utils.canGrantPublicationAccess(farm);
    const pendingAccessInvitations = publicationAccess?.accessInvitations?.filter((a) => a.status === "Pending") || [];

    const _grantAccess = (accessInvitation) => {
        if (!accessInvitation) {
            accessInvitation = {};
        }
        openGrantPublicationAccessModal({ ...accessInvitation, farmId: farm.id });
    };

    const breadcrumb = [{ name: farm.name, to: referrer }, { name: "Manage publication access" }];
    const info = <>Use this screen to manage the <b>users</b> who can act as your agent for all publications that have been published to you for this farm.</>

    if ((!authContext.isSystemAdmin && !canGrantAgentAccess) || !hasPublications) {
        return <Redirect push to={referrer} />
    }

    return (
        <>
            <Page>
                <FarmHomePageHead breadcrumb={breadcrumb} />
                <PageBody>
                    <Panel title="Manage publication access" info={info} waiting={isFetching} referrer={referrer}>
                        <PanelBody waiting={isFetching}>
                            {!isStudent && (
                                <TileActionsBar>
                                    <ul className="ActionsBar-links">
                                        <li>
                                            <ActionLink className="IconLink--arrow-plus" id="add-access-action" onClick={() => _grantAccess()}>
                                                Grant publication access
                                            </ActionLink>
                                        </li>
                                    </ul>
                                </TileActionsBar>
                            )}
                            {pendingAccessInvitations.length > 0 && <PendingFarmAccessInvitations farm={farm} pendingAccessInvitations={pendingAccessInvitations} resend={_grantAccess} isPublicationAccess />}
                            {publicationAccess?.access?.length > 0 && <FarmAccessList farm={farm} access={publicationAccess.access} isPublicationAccess />}
                            {publicationAccess?.access?.length === 0 && !isLoading && (
                                <Panel title="Users with publication access" midBlue>
                                    <PanelBody>
                                        <TileBodyMessage>
                                            <img className="u-p-5" src={icons.add} alt="Plus" />
                                            <p className="lead">You have not granted any users publication access for this farm</p>
                                            {!isStudent && (
                                                <ActionLink className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-access-link" onClick={() => _grantAccess()}>
                                                    Grant access
                                                </ActionLink>
                                            )}
                                        </TileBodyMessage>
                                    </PanelBody>
                                </Panel>
                            )}
                        </PanelBody>
                    </Panel>
                </PageBody>
            </Page>
            {grantPublicationAccessModal}
        </>
    )
}

function usePublicationAccess(farmId) {
    const query = useQuery({
        queryKey: ["farm-details", farmId, "publication-access"],
        queryFn: async () => httpClient.get(`farms/${farmId}/publications/access`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error
    };
}
