import React from "react";
import * as icons from "common/icons";
import moment from "moment";

export const Card = ({ card, cardId, onCardSelected }) => {
    const cardDet = (c) => {
        if (c.paymentMethod === "Invoice") return { icon: icons.invoice, name: "Invoice" };
        const map = {
            VC: { icon: icons.visa, name: "VISA" },
            MC: { icon: icons.mastercard, name: "MasterCard" },
            AE: { icon: icons.americanExpress, name: "AMEX" },
        };
        return map[c.cardType] || { icon: icons.visa, name: "VISA" };
    };

    const date = moment(card.expiresOn);
    const expDate = date.isValid() ? date.format("MM/YY") : "-";
    const cd = cardDet(card);

    return (
        <div className={`u-flexSplit Credit-card u-mb-md u-mr-md ${cardId === card.id ? "selected" : ""} ${card.isExpired ? "expired" : ""}`} style={{ width: "500px" }} onClick={() => onCardSelected && onCardSelected(card)}>
            <div>
                {onCardSelected ? (
                    <label className="Radio" for={`radio_${card.id}`}>
                        <input type="radio" checked={cardId === card.id} className="Radio-input" id={`radio_${card.id}`} name={`${card.cardIdentifier}${expDate.replace("/", "")}`} />
                        <span className="Radio-label">
                            <img src={cd.icon} alt={cd.name} />
                        </span>
                    </label>
                ) : (
                    <img src={cd.icon} alt={cd.name} />
                )}
            </div>
            <div className="Credit-card-details">
                {card.paymentMethod === "Invoice" ? (
                    <div className="u-pt-sm u-font-600">Pay by invoice</div>
                ) : (
                    <div className="u-pt-sm u-font-600">
                        {card.cardIdentifier} - {expDate} {card.isExpired ? <span className="u-font-red"> (expired)</span> : ""}
                    </div>
                )}
                <div className="u-pt-sm u-pb-sm" style={{ color: "#999" }}>
                    {cd.name}
                </div>
            </div>
        </div>
    );
};

const CreditCards = ({ cards = [], cardId, onCardSelected, className }) => {
    return (
        <div className={`u-flex u-flexWrap ${className}`}>
            {cards.map((c) => (
                <Card card={c} onCardSelected={onCardSelected} cardId={cardId} key={c.id} />
            ))}
        </div>
    );
};

export default CreditCards;
