import { Fragment } from "react";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import Alert from "components/Alert";
import Watermark from "components/Watermark";
import { SummaryRow } from "components/PlanningReport";
import { getTableRowClass, getFertiliserNutrientsApplied } from "./_utils";

export default function FertiliserSummary({ before, after, farmNutrientTotals, baseNutrientTotals }) {
    const refData = useRefData();
    const hasAfterResults = after?.details?.nLossPerHa > 0 || after?.details?.currentResults?.nitrogenOverview?.lostToWater > 0;
    const hasBeforeResults = before?.details?.nLossPerHa > 0 || before?.details?.currentResults?.nitrogenOverview?.lostToWater > 0;
    const hasResults = hasAfterResults || hasBeforeResults;

    const noFert = (!after.details.fertiliser || after.details.fertiliser.length === 0) && (!before || !before.details.fertiliser || before.details.fertiliser.length === 0);

    if (noFert) {
        return null;
    }

    if (!hasResults) {
        return (
            <div className="ReportSection">
                <h1>Fertiliser</h1>
                <div className="u-pt-lg">
                    <Alert type="info" text="There are no fertiliser results for this analysis" />
                </div>
            </div>
        );
    }

    if (!farmNutrientTotals) {
        farmNutrientTotals = getFertiliserNutrientsApplied(after.details);
        baseNutrientTotals = before && getFertiliserNutrientsApplied(before.details);
    }
    const blockTypes = new Set(farmNutrientTotals.map((bt) => bt.blockType).concat(baseNutrientTotals ? baseNutrientTotals.map((bt) => bt.blockType) : []));

    const isComparing = !!before && !!after;

    return (
        <div className="ReportSection">
            <h1>Fertiliser</h1>
            <div className="Table Table-Compressed">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th data-width="25"></th>
                            <th></th>
                            {isComparing && (
                                <th className="numeric u-before" data-width="12.5">
                                    {utils.truncateText(before.displayName, 35)}
                                </th>
                            )}
                            <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                {utils.truncateText(after.displayName, 35)}
                            </th>
                            {isComparing && (
                                <th className="numeric" data-width="16.7">
                                    Change
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {["N", "P"].map((nutrient) => {
                            return ["Synthetic", "Organic"].map((fertiliserType) => {
                                return Array.from(blockTypes).map((bt) => {
                                    const typeLabel = utils.valueToText(refData.blockTypes, bt);
                                    const baseValue = baseNutrientTotals?.find((bnt) => bnt.blockType === bt && bnt.fertiliserType === fertiliserType && bnt.nutrient === nutrient);
                                    const actualValue = farmNutrientTotals?.find((bnt) => bnt.blockType === bt && bnt.fertiliserType === fertiliserType && bnt.nutrient === nutrient);

                                    if (baseValue?.total === 0 || actualValue?.total === 0) return null;

                                    return (
                                        <Fragment key={bt}>
                                            <SummaryRow beforeValue={baseValue?.total} afterValue={actualValue?.total} isComparing={isComparing} category={`${fertiliserType} ${nutrient}`} label={typeLabel} unit="kg" rowClass={`${getTableRowClass(nutrient)} Table-tr--end`} />
                                            <SummaryRow beforeValue={baseValue?.total / baseValue?.area} afterValue={actualValue?.total / actualValue?.area} isComparing={isComparing} category=" " label={typeLabel} unit="kg/ha" rowClass={`${getTableRowClass(nutrient)} Table-tr--end`} />
                                        </Fragment>
                                    );
                                });
                            });
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
