import * as api from "api";

export function logEmailTracking(step, id, message = "") {
    try {
        //If the email id is within the last week
        if (id) {
            return api.post({
                path: `emails/track`,
                content: { logId: id, event: step, message },
                onFailure: (error) => {
                    error.handled = true;
                    return [];
                },
            });
        }
        return [];
    } catch (e) {
        //most likely a corrupt email time
        console.log(e);
    }
}

export function clearEmailTracking() {
    return (dispatch) => {
        dispatch({ type: "EMAIL_ID_CLEAR" });
    };
}
