import { useBlockResults } from "containers/BudgetHome";
import { NutrientBudget } from "components/NutrientBudget";

export default function BlockNutrientBudget({ id, analysis, block }) {
    const { data: blockResults } = useBlockResults(analysis, block);

    if (!blockResults?.nutrientBudget) {
        return <>There are no nutrient results for this block</>
    }

    const nitrogenResults = blockResults.nitrogen;
    const phosphorusResults = blockResults.phosphorus;
    const nutrientBudget = blockResults.nutrientBudget;

    return <NutrientBudget nitrogenResults={nitrogenResults} phosphorusResults={phosphorusResults} nutrientBudget={nutrientBudget} id={`BlockNutrientBudget_${id}`} />;
}
