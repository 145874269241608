import React, { Component } from "react";
import LiquidModal from "./LiquidModal";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import Button from "components/Button/Button";

const InitState = { application: undefined, dirty: false };

const ApplicationMonth = ({ label, id, name, applicationId, checked, onChange, type }) => (
    <label className="Checkbox" htmlFor={`${type}_${applicationId}_${id}`}>
        <input className="Checkbox-input" type="checkbox" checked={checked || false} id={`${type}_${applicationId}_${id}`} name={`${applicationId}_${id}`} onChange={(e) => onChange(e, { type: "application", key: "appplication-month", applicationId: applicationId, month: id, id: `${applicationId}_${id}` })} />
        <span className="Checkbox-label">{label}</span>
    </label>
);

class LiquidApplications extends Component {
    constructor(props) {
        super(props);
        this.state = InitState;
    }

    changeMonth(e, o) {
        const { applications } = this.props;
        const application = applications.find((a) => a.id === o.applicationId);
        const { months = [] } = application;
        application.months = e.target.checked ? [...[{ month: o.month, reportingYear: true }], ...months] : months.filter((m) => m.month !== o.month);
        this.props.saveApplication(application, true);
        this.setState({ dirty: true });
    }

    saveApplication() {
        if (this.props.saveApplication(this.state.application, this.state.dirty)) {
            this.props.modalInlineClose();
            this.setState(InitState);
        }
    }

    componentWillUnmount() {
        this.props.modalInlineClose();
    }

    closeModal() {
        this.setState(InitState);
        this.props.cancelApplication();
        this.props.modalInlineClose();
    }

    addApplication() {
        const application = { id: uuidv4(), areaEffluent: 100 };
        this.setState({ application: application });
        this.props.modalInlineOpen();
    }

    editApplication(id) {
        const application = this.props.applications.find((a) => a.id === id);
        this.setState({ application: utils.clone(application) });
        this.props.modalInlineOpen();
    }

    onChange(e, source) {
        const application = this.state.application;
        application[source.key] = utils.ctrlVal(e);
        this.props.validate(application, source);
        this.setState({ dirty: true, application: application });
    }

    deleteConfirm(id) {
        this.props.confirm(`Are you sure you want to delete this application?`, () => this.deleteApplication(id));
    }

    deleteApplication(id) {
        this.props.deleteApplication(id);
    }

    render() {
        const { blocks, validation, applications, type, liquidEffluentDisposal, analysis, isSystem } = this.props;
        const { application = {} } = this.state;
        const { blockIds = [] } = application;
        const { effluentSystem = {} } = analysis;
        const hasFarmLiquid = effluentSystem.liquidManagement;

        const effluentShowMonths = liquidEffluentDisposal === "SprayInfrequently" ? true : false;
        const effluentBlocks = blocks.filter((b) => applications.some((a) => a.blockIds.includes(b.id)));
        const applicationsContainCrops = effluentShowMonths || applications.some((a) => ["FodderCrop", "ProductiveCrop"].some((t) => effluentBlocks.some((f) => f.type === t)));

        const applicationSelectedBlocks = blockIds.map((id) => {
            return blocks.find((b) => b.id === id);
        });

        const usedBlockIds = applications
            .filter((a) => a.id !== application.id)
            .map((a) => a.blockIds)
            .reduce((a, b) => [...a, ...b], []);

        const availableBlocks = blocks.filter((b) => !usedBlockIds.includes(b.id));
        const appVal = validation[`${type}_applications`] || {};

        const tbody =
            applications.length > 0 ? (
                <tbody>
                    {applications.map((application, index) => {
                        const { blockIds = [], months = [] } = application;
                        const selectedBlocks = blockIds
                            .filter((id) => blocks.some((b) => b.id === id))
                            .map((id) => {
                                return blocks.find((b) => b.id === id);
                            });
                        const isPasture = selectedBlocks.some((b) => b.type === "ProductivePasture");
                        const showMonths = effluentShowMonths || selectedBlocks.some((b) => ["ProductiveCrop", "FodderCrop"].includes(b.type));
                        const monthGroupVal = validation[`monthGroupVal_${application.id}`] || {};

                        return (
                            <tr id={application.id} key={index}>
                                <td>
                                    {selectedBlocks.map((block) => (
                                        <div key={block.id}>
                                            <b id={`${application.id}_liquid_block`}>{block.name}</b>
                                        </div>
                                    ))}
                                </td>

                                {showMonths && (
                                    <td>
                                        <div className="AnnualCheckboxes">
                                            <ApplicationMonth label="Jul" id="July" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "July")} type={type} />
                                            <ApplicationMonth label="Aug" id="August" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "August")} type={type} />
                                            <ApplicationMonth label="Sep" id="September" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "September")} type={type} />
                                            <ApplicationMonth label="Oct" id="October" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "October")} type={type} />
                                            <ApplicationMonth label="Nov" id="November" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "November")} type={type} />
                                            <ApplicationMonth label="Dec" id="December" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "December")} type={type} />
                                            <ApplicationMonth label="Jan" id="January" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "January")} type={type} />
                                            <ApplicationMonth label="Feb" id="February" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "February")} type={type} />
                                            <ApplicationMonth label="Mar" id="March" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "March")} type={type} />
                                            <ApplicationMonth label="Apr" id="April" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "April")} type={type} />
                                            <ApplicationMonth label="May" id="May" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "May")} type={type} />
                                            <ApplicationMonth label="Jun" id="June" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "June")} type={type} />
                                        </div>
                                        {monthGroupVal.touched && monthGroupVal.error && (
                                            <div className="Field has-error u-mt-sm">
                                                <small className="Field-error" id={`${type}_month-error`}>
                                                    Please select at least one month
                                                </small>
                                            </div>
                                        )}
                                    </td>
                                )}
                                {!showMonths && applicationsContainCrops && <td>-</td>}
                                <td>
                                    <span id={`${application.id}_liquid_depth`}>{utils.valueToText(this.props.effluentAppRates, application.applicationDepth)}</span>
                                </td>
                                <td>
                                    <span id={`${application.id}_liquid_area`}>
                                        {isPasture ? (
                                            <span>
                                                {application.areaEffluent}
                                                <small>%</small>
                                            </span>
                                        ) : (
                                            "-"
                                        )}
                                    </span>
                                </td>

                                <td>
                                    <div className="u-flex">
                                        <ActionLink className="IconLink--trash" id={`${application.id}_liquid_remove`} onClick={() => this.deleteConfirm(application.id)} title="Delete">
                                            <span></span>
                                        </ActionLink>
                                        <ActionLink className="IconLink--edit u-ml-sm" id={`${application.id}_liquid_edit`} onClick={() => this.editApplication(application.id)} title="Edit">
                                            <span></span>
                                        </ActionLink>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            ) : (
                <tbody>
                    <tr>
                        <td colSpan={applicationsContainCrops ? 5 : 4}>
                            <div className="Tile-body">
                                <div className="Tile-body-message">
                                    <p className="h4 u-mt-0">You do not have any applications</p>
                                    <Button className="IconLink--arrow-plus Button Button--secondary u-mt-md" id="liquid_add_app-btn" onClick={() => this.addApplication()}>
                                        <span>Add application</span>
                                    </Button>
                                    {appVal.touched && appVal.error && (
                                        <div className="Field has-error u-mt-sm">
                                            <small className="Field-error">Please add at least one application</small>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            );

        return (
            <div className="u-mt-xl">
                {this.state.application && <LiquidModal onChange={(e, o) => this.onChange(e, o)} cancel={() => this.closeModal()} save={() => this.saveApplication()} selectedBlocks={applicationSelectedBlocks} effluentAppRates={this.props.effluentAppRates} application={this.state.application} blocks={availableBlocks} type={this.props.type} validation={validation} />}
                <Tile
                    title="Application of liquid effluent on blocks"
                    actions={
                        <span className="IconLink--arrow-plus u-link" id="liquid_add_app-lnk" onClick={() => this.addApplication()}>
                            Add application
                        </span>
                    }
                    tertiary
                >
                    {applicationsContainCrops && (
                        <Alert
                            type="info"
                            text={
                                <span>
                                    <b>Note</b> - If no months are selected it is assumed that farm dairy effluent is applied each month of the lactation period and wintering pad effluent is applied when animals are on the pad
                                </span>
                            }
                        />
                    )}
                    {hasFarmLiquid && !isSystem && (
                        <Alert
                            type="warning"
                            text={
                                <span>
                                    <b>Warning</b> - Liquid applications have been defined within the farm effluent system. Actively managed, application depth, and percentage of block size may be overriden with the farm effluent system values
                                </span>
                            }
                        />
                    )}
                    <TileBody>
                        <div className="Table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Blocks</th>
                                        {applicationsContainCrops && <th>Months</th>}
                                        <th>Application depth</th>
                                        <th>% of block area</th>
                                        <th className="th--shrink">&nbsp;</th>
                                    </tr>
                                </thead>
                                {tbody}
                            </table>
                        </div>
                    </TileBody>
                </Tile>
            </div>
        );
    }
}

export default LiquidApplications;
