import React from "react";
import * as validations from "common/validations";
import Modal from "components/new/Modal";
import ModalBody from "components/new/ModalBody";
import ModalFooter from "components/new/ModalFooter";
import InputPack from "components/InputPack";
import RadioGroupPack from "components/RadioGroupPack";
import SelectPack from "components/SelectPack2";
import { Form, Field } from "react-final-form";
import { Grid, GridCell } from "components/Grid";
import { compose, withAppContext, withActions } from "containers/HOC";
import { inviteUsers } from "./_actions";
import moment from "moment";
import DatePickerInputPack from "components/DatePickerInputPack";

class InviteStudentModal extends React.Component {
    _validate = (formValues) => {
        const errors = {};

        const { initialValues } = this.props;
        const { students = [] } = initialValues;
        const registeredStudents = students.filter((s) => moment(s.expires).isAfter(moment().valueOf()));
        errors.formEmail = validations.required(formValues.formEmail);

        if (formValues.formEmail) {
            const emailList = this._extractEmails(formValues.formEmail);
            if (emailList.length < 1) errors.formEmail = "Please enter at least one email";
            emailList.forEach((e) => {
                const emailAddress = e.trim();
                errors.formEmail = errors.formEmail || validations.emailExt(emailAddress);
                if (!errors.formEmail) {
                    if (registeredStudents.some((s) => s.users.some((u) => u.email === emailAddress))) errors.formEmail = `Student already exists: ${e}`;
                }
            });
        }

        errors.group = validations.required(formValues.group);
        errors.group = errors.group || validations.maxLength(255)(formValues.group);

        return errors;
    };

    _extractEmails = (formEmail) => {
        const emailList = formEmail
            .replace(/(^[,\s]+)|([,\s]+$)/g, "")
            .split(",")
            .map((e) => e.toLowerCase());
        return [...new Set(emailList)];
    };

    _onSubmit = (formValues) => {
        formValues.emails = this._extractEmails(formValues.formEmail);
        formValues.expires = moment(formValues.expiresFormatted);
        this.props.inviteUsers(formValues, false);
    };

    render() {
        const { initialValues } = this.props;

        const { invitation, invitations = [], students = [] } = initialValues;
        const invitationGroups = invitations.filter((s) => moment(s.expires).isAfter(moment().valueOf())).map((g) => g.group);
        const studentGroups = students.filter((s) => moment(s.expires).isAfter(moment().valueOf())).map((g) => g.group);
        const allGroups = [...new Set([...invitationGroups, ...studentGroups])]
            .filter((g) => g !== undefined)
            .sort((a, b) => (a < b ? -1 : 1))
            .map((g) => ({ value: g, text: g }));
        const isResend = invitation && invitation.email ? true : false;
        invitation.isExisting = allGroups.length > 0 && !isResend ? "true" : "false";
        if (isResend) {
            invitation.formEmail = invitation.email;
            invitation.expiresFormatted = moment(invitation.expires).format("DD MMMM YYYY");
        } else {
            invitation.expiresFormatted = moment().add(3, "months").format("DD MMMM YYYY");
        }

        return (
            <Form validate={this._validate} initialValues={invitation} onSubmit={this._onSubmit}>
                {({ handleSubmit, submitting, values, submitted }) => {
                    return (
                        <Modal title="Student invite" instructionText="An email invitation will be sent to the students." skinny>
                            {({ closeModal }) => (
                                <form onSubmit={handleSubmit}>
                                    <ModalBody>
                                        <Grid>
                                            <GridCell>
                                                <Field name="id" type="hidden" component="input" />
                                                <Field name="formEmail" type="text" label="Email addresses" requiredLabel={true} disabled={invitation && invitation.email ? true : false} component={InputPack} placeholder="Enter the email addresses of students you wish to invite (comma separated)" />
                                                {allGroups.length > 0 && !isResend && (
                                                    <div className="u-pt-xl">
                                                        <Field
                                                            name="isExisting"
                                                            options={[
                                                                { value: "true", text: "Use existing group" },
                                                                { value: "false", text: "Create new group" },
                                                            ]}
                                                            inline={true}
                                                            component={RadioGroupPack}
                                                        />
                                                    </div>
                                                )}
                                                {values.isExisting === "true" && !isResend ? <Field name={`group`} label="Group" component={SelectPack} placeholderEnabled={false} required={true} options={allGroups} placeholder="Select a group" /> : <Field name="group" type="text" requiredLabel={true} label="Group" component={InputPack} placeholder="Enter the students group or course" disabled={isResend} />}
                                                <Field name="expiresFormatted" label="Expiry date" clearable={false} component={DatePickerInputPack} dataWidth="100" inline={true} showYearDropdown={false} minDate={new Date()} />
                                            </GridCell>
                                        </Grid>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="ButtonBar">
                                            <div className="ButtonBar-left">
                                                <button id="cancel-button" type="button" className="Button Button--secondary" onClick={closeModal}>
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="ButtonBar-right">
                                                <button id="save-button" type="submit" className="Button" disabled={submitting || submitted}>
                                                    Send invite
                                                </button>
                                            </div>
                                        </div>
                                    </ModalFooter>
                                </form>
                            )}
                        </Modal>
                    );
                }}
            </Form>
        );
    }
}
export default compose(withAppContext, withActions({ inviteUsers }))(InviteStudentModal);
