import { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import * as FormUtils from "common/FormUtils";
import SavePrompt from "components/SavePrompt";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SelectPack from "components/SelectPack2";
import Alert from "components/Alert";
import InputPack from "components/InputPack";
import { getOutdoorPigFarrowingVillagesViewModel, requiresOutdoorPigEffluentSystem, OUTDOOR_PIG_SOLID_DISPOSAL_OPTIONS } from "common/viewModels";
import * as structureUtils from "./_utils";
import { useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function OutdoorPigFarrowingVillages({ farm, analysis }) {
    const refData = useRefData();
    const viewModel = getOutdoorPigFarrowingVillagesViewModel(analysis);
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    return <OutdoorPigFarrowingVillagesClassComponent farm={farm} analysis={analysis} viewModel={viewModel} updateAnalysisAsync={updateAnalysisAsync} refData={refData} />
}

class OutdoorPigFarrowingVillagesClassComponent extends Component {
    constructor(props) {
        super(props);
        const { viewModel } = props;
        this.state = {
            viewModel,
        };
    }

    showSludgeDisposal = (viewModel) => OUTDOOR_PIG_SOLID_DISPOSAL_OPTIONS.includes(viewModel.effluentDisposal);

    validate = (viewModel) => {
        const errors = {};

        const timePreFarrowing = Number(viewModel.timePreFarrowing);
        const timePostFarrowing = Number(viewModel.timePostFarrowing);
        const maxPostFarrowing = viewModel.weaningAge > 41 ? 41 : viewModel.weaningAge || 41;
        const totalDaysInVillage = timePreFarrowing + timePostFarrowing;

        errors.timePreFarrowing = FormUtils.validators.requiredIf(timePreFarrowing, timePostFarrowing, "Required if Time Post Farrowing not specified");
        errors.timePreFarrowing = errors.timePreFarrowing || FormUtils.validators.range(0, 41)(timePreFarrowing);

        errors.timePostFarrowing = FormUtils.validators.requiredIf(timePostFarrowing, timePreFarrowing, "Required if Time Pre Farrowing not specified");
        errors.timePostFarrowing = errors.timePostFarrowing || FormUtils.validators.range(0, maxPostFarrowing)(timePostFarrowing);
        errors.timePostFarrowing = errors.timePostFarrowing || (totalDaysInVillage > 70 ? "Total time in village must be 70 days or less" : undefined);

        errors.effluentDisposal = FormUtils.validators.required(viewModel.effluentDisposal);

        if (this.showSludgeDisposal(viewModel)) errors.sludgeDisposal = FormUtils.validators.required(viewModel.sludgeDisposal);

        return errors;
    };

    submit = async (structure) => {
        if (!this.showSludgeDisposal(structure)) {
            delete structure.sludgeDisposal;
        }
        const updatedAnalysis = structureUtils.getUpdatedAnalysisFromSavingStructure(this.props.analysis, structure);
        await this.props.updateAnalysisAsync(updatedAnalysis);
    };

    render() {
        const { farm, analysis, refData } = this.props;
        const baseRedirectUrl = `/app/farm/${farm.id}/analysis/${analysis.id}/structures`;

        return (
            <Form
                initialValues={this.state.viewModel}
                validate={this.validate}
                onSubmit={this.submit}
                mutators={{
                    setProperty: (args, state, utils) => {
                        utils.changeValue(state, args[0], () => args[1]);
                    },
                    ...arrayMutators,
                }}
            >
                {({ form, values, handleSubmit, dirty, submitting, submitSucceeded }) => {
                    const redirectOnSaveTo = values.manageEffluent ? `${baseRedirectUrl}/outdoorPigEffluent` : baseRedirectUrl;

                    const enableManageEffluentButton = requiresOutdoorPigEffluentSystem(values);

                    return (
                        <form onSubmit={handleSubmit}>
                            <SavePrompt blockIf={dirty && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={redirectOnSaveTo} />
                            <Panel title="Outdoor pig farrowing villages" waiting={submitting} referrer={baseRedirectUrl}>
                                <Alert className="u-mb-sm" type="info" text="A farrowing village is a purpose-built facility to remove sows off paddocks where the effluent can be contained and handled in a separate manner. The sows will enter the facility shortly before farrowing and remain for a period post farrowing." />
                                <PanelBody waiting={submitting}>
                                    <Grid title="Sows placed in farrowing villages">
                                        <GridCell className="u-width1of4">
                                            <Field name="id" type="hidden" component="input" />
                                            <Field name="timePreFarrowing" label="Time pre farrowing" placeholder="0" uom="days" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                        </GridCell>
                                        <GridCell className="u-width1of4">
                                            <Field name="timePostFarrowing" label="Time post farrowing" placeholder="0" uom="days" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                        </GridCell>
                                    </Grid>
                                    <Grid title="Effluent from farrowing villages">
                                        <GridCell className="u-width1of4">
                                            <Field name="effluentDisposal" label="Effluent management" placeholder="Select an effluent management system" options={refData.winPadEffTreat} required component={SelectPack} />
                                        </GridCell>
                                        {this.showSludgeDisposal(values) && (
                                            <GridCell className="u-width1of4">
                                                <Field name="sludgeDisposal" label="Pond solids management" placeholder="Select a pond solids management system" options={refData.solidsManagement} required component={SelectPack} />
                                            </GridCell>
                                        )}
                                    </Grid>
                                </PanelBody>
                                <PanelFooter>
                                    <div className="ButtonBar ButtonBar--fixed">
                                        <div className="ButtonBar-left">
                                            <Link to={baseRedirectUrl} id="cancel-button" className="Button Button--secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="ButtonBar-right">
                                            <button type="submit" onClick={(e) => form.change("manageEffluent", true)} disabled={!enableManageEffluentButton} id="manage-button" className="Button Button--tertiary">
                                                Save and manage effluent
                                            </button>
                                            <Button id="save-button" submit primary onClick={() => form.change("manageEffluent", false)} waiting={submitting}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </PanelFooter>
                            </Panel>
                        </form>
                    );
                }}
            </Form>
        )
    }
}
