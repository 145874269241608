import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { FieldArray } from "react-final-form-arrays";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import MonthSelector from "./MonthSelector";
import { Button } from "components/Button";
import { useOutdoorPigSolidApplicationModal } from "./OutdoorPigSolidApplicationModal";

export default function OutdoorPigSolidApplications({ farm, analysis, title, form, fieldName, applications, sources, blocks, values }) {
    const [outdoorPigSolidApplicationModal, openOutdoorPigSolidApplicationModal] = useOutdoorPigSolidApplicationModal();

    const hasApplications = applications && applications.length > 0;
    const selectedBlockIds = applications.reduce((results, application) => results.concat(application.blockIds), []);
    const unselectedBlocks = blocks.filter((b) => !selectedBlockIds.includes(b.id));
    const allBlocksAlreadyHaveApplications = unselectedBlocks.length === 0;
    const addApplicationLink = !allBlocksAlreadyHaveApplications && (
        <ActionLink id={`add-${fieldName}-link`} className="IconLink--arrow-plus u-link u-textWhite" onClick={addApplication(title, form, fieldName, unselectedBlocks, openOutdoorPigSolidApplicationModal)}>
            Add application
        </ActionLink>
    );

    const formState = form.getState();
    const isRequired = applications.length === 0 && formState && (formState.initialValues[fieldName].length > 0 || formState.submitFailed);

    return (
        <>
            <h3>{title}</h3>
            {fieldName === "strawCompostApplications" && <p>The following outdoor pig housing structures have straw compost that must be spread on blocks</p>}
            {fieldName === "pondSolidApplications" && <p>The following outdoor pig housing structures have pond solids that must be spread on blocks</p>}
            <ul className="disc">
                {sources.map((src) => (
                    <li key={src.type}>
                        <Link id={`${fieldName}-${src.type}-link`} to={src.url} className="">
                            {src.name}
                        </Link>
                    </li>
                ))}
            </ul>
            <Panel title={`${title} applications`} actions={addApplicationLink} midBlue className="u-mt-lg">
                <PanelBody>
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="25">Blocks</th>
                                    <th>Months</th>
                                    {hasApplications && <th className="th--shrink"></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {hasApplications && (
                                    <FieldArray name={fieldName}>
                                        {({ fields }) => {
                                            return fields.map((field, index) => {
                                                const application = fields.value[index];
                                                const selectedBlocks = blocks.filter((b) => application.blockIds.includes(b.id));
                                                const selectedBlockNames = selectedBlocks.map((block) => block.name);

                                                return (
                                                    <tr key={`${field}.${index}`} valign="top">
                                                        <td className="u-pt-sm">
                                                            {selectedBlockNames.map((blockName) => (
                                                                <h5 key={blockName}>{blockName}</h5>
                                                            ))}
                                                        </td>
                                                        <td>
                                                            <MonthSelector form={form} monthsFieldName={`${field}.months`} />
                                                        </td>
                                                        <td className="u-textCenter">
                                                            <div className="u-flex">
                                                                <ActionLink id={`remove-${fieldName}-${application.id}`} onClick={removeApplication(form, values, fieldName, index)} className="IconLink--cross-circle"></ActionLink>
                                                                <ActionLink id={`edit-${fieldName}-${application.id}`} className="IconLink--edit u-ml-sm" onClick={editApplication(title, form, fieldName, application, index, selectedBlocks, unselectedBlocks, openOutdoorPigSolidApplicationModal)}></ActionLink>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            });
                                        }}
                                    </FieldArray>
                                )}
                                {!hasApplications && (
                                    <tr>
                                        <td colSpan="3">
                                            <div className="Tile-body">
                                                {unselectedBlocks.length === 0 && (
                                                    <div className="Tile-body-message">
                                                        <h3>One or more effluent application blocks are required</h3>
                                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/blocks/new`} id="create-block" className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                            Create block
                                                        </Link>
                                                    </div>
                                                )}
                                                {unselectedBlocks.length > 0 && (
                                                    <div className="Tile-body-message">
                                                        <Button id={`add-${fieldName}-button`} onClick={addApplication(title, form, fieldName, unselectedBlocks, openOutdoorPigSolidApplicationModal)} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                            Add application
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {isRequired && (
                            <div className="Field has-error u-mt-0">
                                <small className="Field-error">Required</small>
                            </div>
                        )}
                    </div>
                </PanelBody>
            </Panel>
            {outdoorPigSolidApplicationModal}
        </>
    )
}

const addApplication = (title, form, fieldName, unselectedBlocks, openOutdoorPigSolidApplicationModal) => () => {
    const viewModel = {
        id: uuidv4(),
        blockIds: [],
        months: [],
        availableBlocks: unselectedBlocks,
        title,
    };
    openOutdoorPigSolidApplicationModal(viewModel, applicationAdded(form, fieldName));
}

const applicationAdded = (form, fieldName) => (application) => {
    form.mutators.push(fieldName, application);
    form.blur(fieldName);
    form.change("isModified", true);
}

const editApplication = (title, form, fieldName, application, applicationIndex, selectedBlocks, unselectedBlocks, openOutdoorPigSolidApplicationModal) => () => {
    const viewModel = {
        ...application,
        availableBlocks: selectedBlocks.concat(unselectedBlocks),
        title,
    };
    openOutdoorPigSolidApplicationModal(viewModel, applicationEdited(form, fieldName, applicationIndex));
}

const applicationEdited = (form, fieldName, applicationIndex) => (application) => {
    form.mutators.update(fieldName, applicationIndex, application);
    form.blur(fieldName);
    form.change("isModified", true);
}

const removeApplication = (form, values, fieldName, index) => (e) => {
    const results = values[fieldName].reduce((acc, cur, i) => {
        if (i !== index) acc.push(cur);
        return acc;
    }, []);
    form.change(fieldName, results);
    form.blur(fieldName);
    form.change("isModified", true);
}
