import Page from "components/Page";
import PageBody from "components/PageBody";
import DashboardPageHead from "./DashboardPageHead";
import MyRequiredActions from "./MyRequiredActions";

export default function MyRequiredActions_TMP() {
    return (
        <Page>
            <DashboardPageHead breadcrumb={[{ name: "Actions" }]} />
            <PageBody>
                <MyRequiredActions />
            </PageBody>
        </Page>
    )
}
