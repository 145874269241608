import React from "react";
import { Switch, Route } from "react-router-dom";
import { Panel, PanelBody } from "components/Panel";
import RenewalsReport from "./RenewalsReport";
import InvoiceReport from "./InvoiceReport";
import AccessReport from "./AccessReport";
import SubscriptionsReport from "./SubscriptionsReport";

const Reports = () => {
    const baseUrl = "/app/admin/reports";
    const tabs = {
        renewals: { title: "Renewals report", path: `${baseUrl}`, exact: true },
        ownership: { title: "Access requests", path: `${baseUrl}/access`, exact: true },
        invoice: { title: "Invoice report", path: `${baseUrl}/invoice`, exact: true },
        subsriptions: { title: "Subscriptions report", path: `${baseUrl}/subscriptions`, exact: true },
    };
    return (
        <Panel tabs={tabs}>
            <PanelBody>
                <Switch>
                    <Route exact path={tabs.renewals.path} render={() => <RenewalsReport />} />
                    <Route exact path={tabs.ownership.path} render={() => <AccessReport />} />
                    <Route exact path={tabs.invoice.path} render={() => <InvoiceReport />} />
                    <Route exact path={tabs.subsriptions.path} render={() => <SubscriptionsReport />} />
                </Switch>
            </PanelBody>
        </Panel>
    );
};

export default Reports;
