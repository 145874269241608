import Page from "components/Page";
import PageBody from "components/PageBody";
import FarmHomePageHead from "containers/FarmHome/FarmHomePageHead";
import NationalReporting from "containers/Admin/NationalReporting/NationalReporting";

export default function Benchmarks({ farm }) {
    const referrer = `/app/farm/${farm.id}`;

    const breadcrumb = [{ name: farm.displayName, to: referrer }, { name: "Benchmarks" }];

    return (
        <Page>
            <FarmHomePageHead breadcrumb={breadcrumb} />
            <PageBody>
                <NationalReporting farm={farm} isBenchmarksOnly={true} />
            </PageBody>
        </Page>
    )
}
