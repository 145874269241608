import * as utils from "common/utils";

export default function DistributionGraphTable({ stats }) {
    if (!stats) return null;

    return (
        <div className="Table u-mt-md">
            <table>
                <thead>
                    <tr>
                        <th>Min</th>
                        <th>Max</th>
                        <th>Mean</th>
                        <th>Median</th>
                        <th>Std dev</th>
                        <th>Lower quartile</th>
                        <th>Upper quartile</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{(stats.min && utils.round(stats.min, 1).toLocaleString()) || 0}</td>
                        <td>{(stats.max && utils.round(stats.max, 1).toLocaleString()) || 0}</td>
                        <td>{(stats.mean && stats.mean && utils.round(stats.mean, 1).toLocaleString()) || 0}</td>
                        <td>{(stats.median && stats.median && utils.round(stats.median, 1).toLocaleString()) || 0}</td>
                        <td>{(stats.standardDeviation && utils.round(stats.standardDeviation, 1).toLocaleString()) || 0}</td>
                        <td>{(stats.lowerQuartile && utils.round(stats.lowerQuartile, 1).toLocaleString()) || 0}</td>
                        <td>{(stats.upperQuartile && utils.round(stats.upperQuartile, 1).toLocaleString()) || 0}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
