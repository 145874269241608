import { useParams } from "react-router-dom";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import DrainageDetails from "./DrainageDetails";

export default function DrainageEdit({ farm, analysis }) {
    const { drainageMethod } = useParams();

    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.WETLANDS} />
            <TileBody>
                <DrainageDetails farm={farm} analysis={analysis} drainageMethod={drainageMethod} />
            </TileBody>
        </Tile>
    )
}
