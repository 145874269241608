import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useAuthContext, useConfirm, useNavigate, useRefData } from "common/hooks";
import { httpClient } from "common/httpClient";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import Toggle from "react-toggle";
import { useRevokeFarmAccessAsync } from "containers/hooks";

export default function FarmAccessList({ farm, access, isPublicationAccess  }) {
    const refData = useRefData();
    const authContext = useAuthContext();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const revokeFarmAccessAsync = useRevokeFarmAccessAsync();
    const setPaymentNotificationAsync = useSetPaymentNotificationAsync();

    const _revokeAccess = (farmAccess) => {
        const isMyAccess = farmAccess.accountId === authContext.accountId;
        const confirmMsg = isMyAccess ? "Are you sure you want to revoke your own access?" : "Are you sure you want to revoke this access?";
        confirm(confirmMsg, async () => {
            await revokeFarmAccessAsync(farm.id, farmAccess);
            if (isMyAccess) {
                navigate("/");
            }
        });
    }

    const noPaymentNotifcations = !access.some((a) => a.paymentNotification);
    const canManageAccess = utils.canManageAccess(farm);
    const isOwner = utils.isOwner(farm);

    const title = isPublicationAccess ? "Users with publication access" : "Organisations with farm access";
    const info = isPublicationAccess ? "The list of users who can act as your agent for publications published to you for this farm." : "The list of organisations that you have granted access for this farm.";

    return (
        <>
            <Panel title={title} info={info} midBlue>
                <PanelBody>
                    {access.length > 0 && (
                        <div className="Table">
                            <table>
                                <thead>
                                    <tr>
                                        {!isPublicationAccess && <th>Organisation</th>}
                                        {isPublicationAccess && <th>User</th>}
                                        <th>Date</th>
                                        <th>Role</th>
                                        {!isPublicationAccess && <th className="th--shrink">Subscription reminders</th>}
                                        <th className="th--shrink"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {access
                                        .sort((a, b) => (a.accountName > b.accountName ? 1 : -1))
                                        .filter((a) => (isPublicationAccess ? a.role === "PublicationAgent" : a.role !== "PublicationAgent"))
                                        .map((farmAccess) => {
                                            const key = `${farmAccess.accountId}-${farmAccess.role}`;
                                            const isOwnerAccess = farmAccess.role === "Owner";
                                            return (
                                                <tr key={key} className={isOwnerAccess ? "u-textBold is-selected" : ""}>
                                                    {!isPublicationAccess && <td>{farmAccess.accountName}</td>}
                                                    {isPublicationAccess && <td>{farmAccess.email}</td>}
                                                    <td>{utils.dateOnly(farmAccess.createdOn)}</td>
                                                    <td>{utils.valueToText(refData.farmRoles, farmAccess.role)}</td>
                                                    {!isPublicationAccess && <td className="td--shrink u-textCenter">{canManageAccess && <>{farmAccess.role === "Owner" && noPaymentNotifcations ? <div>On - Default</div> : <Toggle defaultChecked={farmAccess.paymentNotification} onClick={(e) => setPaymentNotificationAsync(farm.id, farmAccess.accountId, e.target.checked)} className="My-farm-toggle" />}</>}</td>}
                                                    <td>
                                                        {isPublicationAccess ? (
                                                            <div className="u-flex">
                                                                {"PublicationAgent" === farmAccess.role && (
                                                                    <ActionLink className="IconLink--cross-circle u-ml-sm" id={`revoke-${key}`} onClick={() => _revokeAccess(farmAccess)}>
                                                                        Revoke
                                                                    </ActionLink>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className="u-flex">
                                                                {(isOwner || (canManageAccess && !isOwnerAccess)) && !["Publication", "PublicationAgent"].includes(farmAccess.role) && (
                                                                    <ActionLink className="IconLink--cross-circle u-ml-sm" id={`revoke-${key}`} onClick={() => _revokeAccess(farmAccess)}>
                                                                        Revoke
                                                                    </ActionLink>
                                                                )}
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </PanelBody>
            </Panel>
        </>
    )
}

function useSetPaymentNotificationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({ farmId, accountId, isActive }) => {
            try {
                const content = {
                    id: farmId,
                    accountId,
                    isActive,
                }
                await httpClient.put(`farms/${farmId}/access/paymentNotification`, content);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 404) {
                    // Do nothing, the farm has already been deleted
                }
                else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["farm-details"] });
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-group-details"] });
        }
    });

    return (farmId, accountId, isActive) => mutation.mutateAsync({ farmId, accountId, isActive });
}
