import { Link } from "react-router-dom";
import { Spinner } from "components/Spinner";
import "./Breadcrumb.css";
import { useIsFetchingModelResults } from "containers/hooks";

export default function Breadcrumb({ breadcrumb = [], hideDashboard }) {
    return (
        <div className="PageHead u-print-none">
            <div className="Container u-flexSplit" style={{ minHeight: "auto" }}>
                <ul className="breadcrumb">
                    {!hideDashboard && (
                        <li>
                            <Link to="/" className="IconLink--home">
                                Dashboard
                            </Link>
                        </li>
                    )}
                    {breadcrumb.map((b) => {
                        const { name = "unknown" } = b;
                        const id = name.replace(" ", "_").toLowerCase();
                        return (
                            <li key={b.name}>
                                {b.to && (
                                    <Link to={b.to} id={id}>
                                        {b.name}
                                    </Link>
                                )}
                                {!b.to && <span id={id}>{b.name}</span>}
                            </li>
                        )
                    })}
                </ul>
                <ModelResultsFetchingToast />
            </div>
        </div>
    )
}

function ModelResultsFetchingToast() {
    const isFetchingModelResults = useIsFetchingModelResults();

    if (isFetchingModelResults) {
        return (
            <div className="u-textWarning u-textBold">
                <span>Refreshing results... </span>
                <Spinner tiny dark />
            </div>
        )
    } else {
        return null;
    }
}
