import React from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import moment from "moment";
import { Link } from "react-router-dom";
import { modal as launchModal } from "containers/App/_actions";
import { updateMyOrg } from "containers/MyOrg/_actions";

const StudentSearchResults = ({ students, selectedIndex, onSelected, loading, launchModal, updateMyOrg }) => {
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        if (students) {
            const data = students.map((student) => {
                const { users = [] } = student;
                const email = users.length > 0 ? users[0].email : "";
                const isExpired = moment(student.expires).isBefore(moment().valueOf());
                return {
                    ...student,
                    email,
                    isExpired,
                    ref: React.createRef(),
                };
            });
            setData(data);
        }
    }, [students]);

    React.useEffect(() => {
        if (selectedIndex >= 0 && selectedIndex < data.length) {
            const selectedData = data[selectedIndex];
            selectedData.ref.current.focus();
        }
    }, [data, selectedIndex]);

    const editStudent = (student) => (e) => {
        e.preventDefault();
        launchModal("EditStudentModal", updateMyOrg, student);
        onSelected();
    };

    return (
        <div className="QuickSearch-results-section">
            {loading && (
                <div className="u-pb-sm u-pt-md">
                    <span className="u-font-600">Students loading ...</span>
                </div>
            )}
            {!loading && (
                <>
                    <div className="u-pb-sm u-font-600">Students ({data.length})</div>
                    {data.map((student, i) => {
                        return (
                            <Link to="#" onClick={editStudent(student)} key={student.id} ref={student.ref}>
                                <div className={`Search-result ${selectedIndex === i ? "selected" : ""}`}>
                                    <div className="u-pl-sm u-pr-sm">
                                        <div className="u-flex u-flexJustifyBetween">
                                            <span className="u-textBold a">
                                                {student.name} {student.group !== student.name ? `(${student.group})` : ""}
                                            </span>
                                            {student.isExpired && <span className="u-font-red">Expired</span>}
                                        </div>
                                        <div className="u-textPrimary">{student.email}</div>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default compose(withAppContext, withActions({ launchModal, updateMyOrg }))(StudentSearchResults);
