import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import { httpClient } from "common/httpClient";
import { FORM_ERROR } from "final-form";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalBodyAlert from "components/Modal/ModalBodyAlert";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import Alert from "components/Alert";
import { Subject } from "rxjs";
import SearchInputPack from "components/SearchInputPack";
import InputPack from "components/InputPack";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import CheckboxPack from "components/CheckboxPack";
import { Panel, PanelBody } from "components/Panel";
import { useRefreshOrgSearchResultsIfAny } from "containers/Admin/Search";
import { findAccountsAsync } from "../_actions";

export default function MoveUserModal({ user, close }) {
    const [searchResults, setSearchResults] = useState([]);
    const dispatch = useDispatch();
    const moveUserAsync = useMoveUserAsync();

    const submitAsync = async (values) => {
        try {
            const request = {
                userId: values.id,
                fromAccountId: values.accountId,
                toAccountId: values.destinationAccountId,
                isAdmin: values.isAdmin ? true : false,
            };
            await moveUserAsync(request);
            close();
        } catch (error) {
            return {
                [FORM_ERROR]: error.message,
            };
        }
    };

    useEffect(() => {
        async function handleFindOrgs(searchText) {
            const newSearchResults = await dispatch(findAccountsAsync(searchText))
                .then((response) => response)
                .catch((error) => []);

            setSearchResults(newSearchResults);
        }

        const subscription = onSearch$.debounceTime(500).subscribe((searchParams) => handleFindOrgs(searchParams.searchText));

        // Unmount
        return () => {
            subscription?.unsubscribe();
        };
    }, [dispatch]);

    return (
        <Form initialValues={user} validate={validate} onSubmit={submitAsync}>
            {({ form, handleSubmit, pristine, submitting, submitError, dirtySinceLastSubmit, values }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Move user to another account" close={close} submitting={submitting}>
                            <ModalBody>
                                <ModalBodyAlert>{!dirtySinceLastSubmit && submitError && <Alert type="error" text={submitError} />}</ModalBodyAlert>

                                <Panel title="Source account" skyBlue className="u-mt-lg" notCollapsible>
                                    <PanelBody>
                                        <Grid>
                                            <GridCell className="u-lg-width1of2">
                                                <InputPack label="Account name" type="text" value={user.accountName} meta={{ nrf: true }} disabled={true} enableLabel={true} />
                                            </GridCell>
                                            <GridCell className="u-lg-width1of2">
                                                <InputPack label="User to move" type="text" value={user.fullName} meta={{ nrf: true }} disabled={true} enableLabel={true} />
                                            </GridCell>
                                            <GridCell className="u-lg-width1of2">
                                                <InputPack label="Email" type="text" value={user.email} meta={{ nrf: true }} disabled={true} enableLabel={true} />
                                            </GridCell>
                                        </Grid>
                                    </PanelBody>
                                </Panel>

                                <Panel title="Destination account" green notCollapsible>
                                    <PanelBody>
                                        <Grid>
                                            <GridCell className="u-lg-width2of3">
                                                <div className="Field u-mt-md">
                                                    <label className="Field-label">Account search</label>
                                                    <Field name="search" placeholder="Search accounts" onChange={handleSearchTextChange()} onClear={handleClearSearch(form)} component={SearchInputPack} />
                                                </div>
                                            </GridCell>
                                        </Grid>
                                        {values.search && (
                                            <Grid className="u-mt-md">
                                                <GridCell>
                                                    <span className="h3">{searchResults.length}</span> account(s) found
                                                </GridCell>
                                            </Grid>
                                        )}
                                        {searchResults.length > 0 && (
                                            <Grid className="u-mt-md">
                                                <GridCell>
                                                    <SortableTable data={searchResults} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }}>
                                                        {({ data, sortCriteria, handleSort }) => {
                                                            return (
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <SortableTableHeader label="Name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <SortableTableHeader label="Type" fieldName="type" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <SortableTableHeader label="Members" fieldName="members" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <th className="th--shrink"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {data.map((acc) => {
                                                                            const isCurrent = acc.id === user.accountId;
                                                                            return (
                                                                                <tr key={acc.id} className="hover">
                                                                                    <td>
                                                                                        {acc.name} {isCurrent && <span> - (current)</span>}
                                                                                    </td>
                                                                                    <td>{acc.type}</td>
                                                                                    <td>{(acc.users || []).length}</td>
                                                                                    <td className="u-textCenter">{!isCurrent && <Field name="destAccId" type="radio" component="input" checked={values.destinationAccountId === acc.id} onChange={destinationAccountSelected(form, acc.id)} />}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            );
                                                        }}
                                                    </SortableTable>
                                                </GridCell>
                                            </Grid>
                                        )}
                                        {values.destinationAccountId && (
                                            <Grid>
                                                <GridCell>
                                                    <Field name="isAdmin" type="checkbox" component={CheckboxPack} label="Is admin in destination account" />
                                                </GridCell>
                                            </Grid>
                                        )}
                                    </PanelBody>
                                </Panel>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="btnFarmGroupCreate_cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="btnFarmGroupCreate_save" submit primary disabled={pristine || submitting || !values.destinationAccountId}>
                                        Move
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

const useMoveUserAsync = () => {
    const queryClient = useQueryClient();
    const refreshOrgSearchResultsIfAny = useRefreshOrgSearchResultsIfAny();

    const mutation = useMutation({
        mutationFn: async (request) => httpClient.put(`admin/accounts/${request.fromAccountId}/users/move`, request),
        // These callbacks ares called BEFORE their mutateAsync versions, even if the component is unmounted
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["orgs"] }),
        onSettled: () => refreshOrgSearchResultsIfAny(),
    });

    return (request) =>
        mutation.mutateAsync(request, {
            // These callbacks are called AFTER their useMutation versions but ONLY if the component is still mounted
            onSuccess: () => console.log("success and still mounted"),
            onSettled: () => console.log("settled and still mounted"),
        });
};

const onSearch$ = new Subject();

const handleSearchTextChange = () => (e) => {
    const searchText = e.target.value;
    onSearch$.next({ searchText });
};

const handleClearSearch = (form) => (e) => {
    form.change("search", "");
};

const destinationAccountSelected = (form, id) => (e) => {
    form.change("destinationAccountId", id);
};

const validate = (viewModel) => {
    const error = {};
    error.search = FormUtils.validators.required(viewModel.destinationAccountId);
    return error;
};
