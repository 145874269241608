import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import { useConfirm, useModal, useRefData } from "common/hooks";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import ActionLink from "components/ActionLink";
import { useAnalysisCommentModal } from "./AnalysisCommentModal";
import { useAnalysis, useUpdateAnalysisAsync } from "containers/hooks";

export default function AnalysisCommentsModal({ farmId, analysisId, close }) {
    const { data: analysis } = useAnalysis(farmId, analysisId);
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const confirm = useConfirm();
    const refData = useRefData();

    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState();
    const [analysisCommentModal, openAnalysisCommentModal] = useAnalysisCommentModal(analysis);

    const addComment = () => {
        const newComment = {
            id: uuidv4(),
            type: "General",
            isNew: true,
        };
        openAnalysisCommentModal(newComment);
    };

    const editComment = (comment) => () => {
        openAnalysisCommentModal(comment);
    };

    const deleteComment = (id) => () => {
        confirm("Are you sure you want to delete this comment?", async () => {
            setSaving(true);
            try {
                const updatedAnalysis = { ...analysis, comments: analysis.comments.filter((c) => c.id !== id) };
                await updateAnalysisAsync(updatedAnalysis);
                analysis.comments = updatedAnalysis.comments;
            } catch (ex) {
                setSaveError(ex.message);
            } finally {
                setSaving(false);
            }
        });
    };

    const waiting = !analysis || saving;
    const comments = analysis && (analysis.comments || []).sort(utils.compareDate("createdOn", true));
    const hasComments = (comments || []).length > 0;

    return (
        <>
            <Modal title={analysis ? `${analysis.name} - Comments` : "Comments"} close={close} waiting={waiting} fluid wide allowOffline>
                <ModalBody loading={!analysis} error={saveError}>
                    <div className="ActionsBar ActionsBar--super u-mt-sm u-mb-sm">
                        <div className="ActionsBar-right">
                            <ul className="ActionsBar-links">
                                <li>
                                    <ActionLink id="add-comment-link" className="IconLink--arrow-plus" onClick={addComment}>
                                        Add comment
                                    </ActionLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th>Created</th>
                                    <th>By</th>
                                    <th>Type</th>
                                    <th>Included in report</th>
                                    <th>Comment</th>
                                    {hasComments && <th className="th--shrink">&nbsp;</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {hasComments &&
                                    comments.map((comment) => {
                                        return (
                                            <tr key={comment.id} className="hover" valign="top">
                                                <td>{utils.dateLong(comment.createdOn)}</td>
                                                <td>{comment.createdBy.userFullName}</td>
                                                <td>{utils.valueToText(refData.budgetCommentTypes, comment.type)}</td>
                                                <td>{comment.includeInFarmImpactReport ? "Yes" : "No"}</td>
                                                <td>{comment.comment}</td>
                                                <td>
                                                    <div className="u-flex">
                                                        <ActionLink id={`edit-comment-${comment.id}`} className="IconLink--edit" title="Edit" onClick={editComment(comment)}></ActionLink>
                                                        <ActionLink id={`delete-comment-${comment.id}`} className="IconLink--trash" title="Delete" onClick={deleteComment(comment.id)}></ActionLink>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {!hasComments && (
                                    <tr>
                                        <td colSpan="5">
                                            <div className="Tile-body">
                                                <div className="Tile-body-message">
                                                    <h4 className="u-mt-0">There are no comments</h4>
                                                    <Button id="add-comment-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={addComment}>
                                                        Add comment
                                                    </Button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ModalFooterLeft></ModalFooterLeft>
                    <ModalFooterRight>
                        <Button id="cancel" onClick={close} secondary>
                            Close
                        </Button>
                    </ModalFooterRight>
                </ModalFooter>
            </Modal>
            {analysisCommentModal}
        </>
    );
}

export function useAnalysisCommentsModal(farmId, analysisId) {
    const [modal, openModal] = useModal(AnalysisCommentsModal);

    const openAnalysisCommentsModal = () => {
        const modalProps = {
            farmId,
            analysisId,
        };
        openModal(modalProps);
    };

    return [modal, openAnalysisCommentsModal];
}
