import TileSubPanel from "components/TileSubPanel";
import BlockListCard from "./BlockListCard";
import * as domain from "common/domain";
import { getBlockList } from "common/utils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileBodyMessage from "components/TileBodyMessage";
import * as icons from "common/icons";
import ActionLink from "components/ActionLink";
import { useBlockModal } from "./Modals/BlockModal";
import { useRefData } from "common/hooks";

export default function BlockList({ farm, analysis }) {
    const [openBlockModal, blockModal] = useBlockModal(farm, analysis);

    const refData = useRefData();
    if (!refData.blockTypes) return null;

    const blocks = analysis.blocks.filter((b) => b.type !== domain.BlockType.FodderCrop);

    const blockList = getBlockList(blocks);

    if (blocks.length === 0) {
        return (
            <>
                <Tile>
                    <TileBody>
                        <TileBodyMessage>
                            <img src={icons.add} className="u-p-5" alt="Plus" />
                            <p className="lead">Let's get started...</p>
                            <ActionLink onClick={openBlockModal} className="IconLink--blocks Button Button--secondary Button--lg u-mt-md">
                                Add new block
                            </ActionLink>
                        </TileBodyMessage>
                    </TileBody>
                </Tile>
                {blockModal}
            </>
        );
    }

    return (
        <div>
            {refData.blockTypes.map((blockType) => {
                const blocksByType = blockList.productiveBlocks.filter((block) => block.type === blockType.value);

                return (
                    blocksByType.length > 0 && (
                        <TileSubPanel key={blockType.value} title={`${blockType.text} ${blockType.value === "FodderCrop" ? "rotations" : " blocks"}`} stickyHeaderName={`analysis.blocks.${blockType.value}.collapsed`}>
                            {blocksByType.map((block) => (
                                <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                            ))}
                        </TileSubPanel>
                    )
                );
            })}
            {blockList.nonProductiveBlocks.length > 0 && (
                <TileSubPanel title="Other blocks" stickyHeaderName="analysis.blocks.otherBlocks.collapsed">
                    {blockList.nonProductiveBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
        </div>
    );
}
