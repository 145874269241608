import { useNavigate } from "common/hooks";
import { TAB } from "containers/BudgetHome/PageTabs";
import TabMessages, { MESSAGE_CODE } from "containers/BudgetHome/TabMessages";
import SoilTestsActionsBar from "./SoilTestsActionsBar";
import SoilTestsList from "./SoilTestsList";
import SoilList from "./SoilList";
import { SoilsGreenBox } from "./SoilsGreenBox";

export default function Soil({ farm, analysis }) {
    const navigate = useNavigate();

    const addBlockSoils = () => {
        const firstMissingSoilsError = analysis.messages.find((m) => m.code === 2001);
        if (firstMissingSoilsError) {
            navigate(`/app/farm/${farm.id}/analysis/${analysis.id}/soil/blocks/${firstMissingSoilsError.entityId}`);
        }
    };

    return (
        <>
            <TabMessages farm={farm} analysis={analysis} tab={TAB.SOIL} actions={{ [MESSAGE_CODE.SOIL_REQUIRED]: addBlockSoils }} />
            <SoilTestsActionsBar farm={farm} analysis={analysis} />
            <SoilsGreenBox analysis={analysis} />
            <SoilTestsList farm={farm} analysis={analysis} />
            <SoilList farm={farm} analysis={analysis} />
        </>
    );
}
