export default function Actions({ before, after }) {
    const beforeActionComments = before && before.details && before.details.comments && before.details.comments.filter((c) => c.type === "Actions");
    const afterActionComments = after.details && after.details.comments && after.details.comments.filter((c) => c.type === "Actions");
    const hasComments = (beforeActionComments && beforeActionComments.length > 0) || (afterActionComments && afterActionComments.length > 0);

    return (
        <div className={`ReportSection ${hasComments ? "" : "u-print-none"}`}>
            <h1>Actions</h1>
            {!hasComments && <h4 className="u-mt-0">There are no actions. You can add actions to any analysis by adding comments that have the type "Action".</h4>}
            {hasComments && (
                <div className="Table Table-Compressed">
                    <table>
                        <thead>
                            <tr>
                                <th data-width="25">Analysis</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {beforeActionComments &&
                                beforeActionComments.map((c, i) => {
                                    return (
                                        <tr>
                                            <td>{i === 0 ? before.name : ""}</td>
                                            <td>{c.comment}</td>
                                        </tr>
                                    );
                                })}
                            {afterActionComments &&
                                afterActionComments.map((c, i) => {
                                    return (
                                        <tr>
                                            <td>{i === 0 ? after.name : ""}</td>
                                            <td>{c.comment}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}
