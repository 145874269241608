import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import { httpClient } from "common/httpClient";
import { startSignalR } from "containers/App/_actions";
import { getDataset, getDatasets, getOrgDatasets } from "./_actions";
import * as utils from "common/utils";
import { executeAnalysis } from "./_actions";
import { useAuthContext, useIsOverseerSci } from "common/hooks";

export function useDataset(id) {
    const dispatch = useDispatch();
    const currentDataset = useSelector((state) => state.dataset.currentDataset);

    useEffect(() => {
        if (id) {
            dispatch(getDataset(id));
        }
    }, [id, dispatch]);

    const hasCurrentDataset = currentDataset && currentDataset.details.id === id;

    return {
        dataset: hasCurrentDataset ? currentDataset.details : null,
        analyses: hasCurrentDataset ? currentDataset.analyses.sort((a) => a.name) : null,
        runs: hasCurrentDataset ? currentDataset.runs : null,
    };
}

export function useDatasets() {
    const dispatch = useDispatch();
    const datasets = useSelector((state) => state.dataset.datasets);

    useEffect(() => {
        dispatch(getDatasets());
    }, [dispatch]);

    return datasets;
}

export function useOrgDatasets(orgId) {
    const dispatch = useDispatch();
    const datasets = useSelector((state) => state.dataset.datasets);

    useEffect(() => {
        dispatch(getOrgDatasets(orgId));
    }, [orgId, dispatch]);

    return datasets;
}

export function useScienceSettings() {
    const { accountId: orgIdFromQueryString } = useParams();
    const orgIdOfLoggedInUser = useAuthContext()?.accountId;
    const idToUse = orgIdFromQueryString || orgIdOfLoggedInUser;
    const isOverseerSci = useIsOverseerSci();

    const query = useQuery({
        queryKey: ["org-details", idToUse, "science-settings"],
        queryFn: async () => {
            if (isOverseerSci) {
                return httpClient.get(`science/${idToUse}/settings`)
            } else {
                return null;
            }
        }            ,
        enabled: !!idToUse,
        retry: false,
        refetchOnWindowFocus: false
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error
    };
}

export function useServerNotifications() {
    const dispatch = useDispatch();
    const serverNotification = useSelector((state) => state.dataset.serverNotification);

    useEffect(() => {
        dispatch(startSignalR());
    }, [dispatch]);

    return serverNotification;
}

export function useCopyDatasetAnalysisAsync() {
    const dispatch = useDispatch();

    const mutation = useMutation({
        mutationFn: async (analysis) => {
            try {
                const farmCopy = await httpClient.get(`v1/farm-details/${analysis.farmId}`);
                farmCopy.id = uuidv4();
                farmCopy.name = `${utils.truncateText(farmCopy.name, 40)} - copy`;
                farmCopy.isNew = true;
                farmCopy.budgets = [];
                await httpClient.post("farms", farmCopy);

                const analysisCopy = await httpClient.get(`v1/analysis-details/${analysis.id}`);
                analysisCopy.id = uuidv4();
                analysisCopy.farmId = farmCopy.id;
                analysisCopy.name = utils.truncateText(farmCopy.name, 40) + " - copy";
                analysisCopy.datasetId = analysis.datasetId;
                await httpClient.post(`farms/${analysisCopy.farmId}/budgets`, analysisCopy);

            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            dispatch(getDataset(variables.datasetId));
        }
    });

    return (analysis) => mutation.mutateAsync(analysis);
}

export function useCreateDatasetAnalysisAsync() {
    const dispatch = useDispatch();

    const mutation = useMutation({
        mutationFn: async ({ farm, analysis }) => {
            try {
                await httpClient.post("farms", { ...farm, originUri: window.location.origin });
                await httpClient.post(`farms/${analysis.farmId}/budgets`, analysis);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            dispatch(getDataset(variables.analysis.datasetId));
        }
    });

    return (farm, analysis) => mutation.mutateAsync({ farm, analysis });
}

export function useUpdateDatasetAnalysisAsync() {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const mutation = useMutation({
        mutationFn: async ({ farm, analysis }) => {
            try {
                await httpClient.put(`farms/${farm.id}`, { ...farm, publications: undefined });
                await httpClient.put(`farms/${analysis.farmId}/budgets/${analysis.id}`, analysis);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farm.id] });
            dispatch(getDataset(variables.analysis.datasetId));
        }
    });

    return (farm, analysis) => mutation.mutateAsync({ farm, analysis });
}

export function useExecuteAnalysis() {
    const dispatch = useDispatch();
    return (farm, analysis) => {
        dispatch(executeAnalysis(farm, analysis));
    };
}
