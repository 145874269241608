import React from "react";
import { compose, withAppContext, withActions, withState } from "containers/HOC";
import moment from "moment";
import numeral from "numeral";
import * as icons from "common/icons";
import { Panel, PanelBody } from "components/Panel";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileBodyMessage from "components/TileBodyMessage";
import ActionLink from "components/ActionLink";
import { getInvoiceAsync } from "./_actions";

const Invoice = ({ authContext, paymentId, getInvoiceAsync }) => {
    const [invoice, setInvoice] = React.useState();

    React.useEffect(() => {
        const getInvoice = async (paymentId) => {
            const resposne = await getInvoiceAsync(paymentId);
            setInvoice(resposne);
        };

        if (!invoice) {
            getInvoice(paymentId);
        }
    }, [paymentId, invoice, getInvoiceAsync]);

    const senderDetailLines = ["Overseer Limited", "4/2 Woodward Street", "Wellington 6143", "GST Number: 119-429-595"];
    const paymentMethod = invoice ? (invoice.paymentMethod === "CreditCard" ? "Credit card" : invoice.paymentMethod) : "";

    return (
        <div className="react-page">
            <header className="Header">
                <div className="Header-logo">
                    <ActionLink className="Header-logo-sm" id="home_small">
                        <img src={icons.logoSmall()} alt="Overseer logo" />
                    </ActionLink>
                    <ActionLink className="Header-logo-lg" id="home_large">
                        <img src={icons.logo()} alt="Overseer logo" />
                    </ActionLink>
                </div>
            </header>
            <div className="Page">
                <Panel>
                    {!authContext.isAuthenticated && (
                        <Tile>
                            <TileBody>
                                <TileBodyMessage>
                                    <p className="lead">Your session has expired</p>
                                    <p>
                                        <b>Please re-authenticate by clinking on the button below</b>
                                    </p>
                                    <p>
                                        <a href="/#/pub/login" className="Button Button--block u-mt-lg" alt="Login">
                                            Log in
                                        </a>
                                    </p>
                                </TileBodyMessage>
                            </TileBody>
                        </Tile>
                    )}
                    {authContext.isAuthenticated && (
                        <PanelBody loading={!invoice}>
                            {!invoice && (
                                <div className="Tile-body-message">
                                    <p className="lead">Invoice not found</p>
                                </div>
                            )}
                            {invoice && (
                                <div className="invoice-box">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img src={icons.logoPrint()} alt="logo" style={{ width: "200px", height: "50px" }} />
                                                </td>
                                                <td width="170px" className="u-textRight">
                                                    <div className="h2 u-textBold u-pt-md">TAX INVOICE</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                                                        <span className="u-textBold u-text-sm">INVOICE #</span>
                                                        <span>{invoice.invoiceNumber}</span>
                                                    </div>
                                                    <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                                                        <span className="u-textBold u-text-sm">DATE</span>
                                                        <span>{moment(invoice.transactionTime).format("DD MMM YYYY")}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className="u-mt-lg">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className="u-textBold u-text-sm">FROM</span>
                                                    <br />
                                                    {senderDetailLines.map((l, i) => (
                                                        <span key={i}>
                                                            {l}
                                                            <br />
                                                        </span>
                                                    ))}
                                                </td>
                                                <td className="u-textRight">
                                                    <span className="u-textBold u-text-sm">TO</span>
                                                    <br />
                                                    {invoice.accountName}
                                                    <br />
                                                    {invoice.accountName !== invoice.userFullName ? (
                                                        <span>
                                                            {invoice.userFullName}
                                                            <br />
                                                        </span>
                                                    ) : null}
                                                    {invoice.userEmail}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="Table u-mt-lg">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Payment method</th>
                                                    {invoice.reference && <th>Reference</th>}
                                                    {invoice.paystationId && <th>Transaction ID</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{paymentMethod}</td>
                                                    {invoice.reference && <td>{invoice.reference}</td>}
                                                    {invoice.paystationId && <td>{invoice.paystationId}</td>}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="Table">
                                        <table>
                                            <thead style={{ display: "table-row-group" }}>
                                                <tr>
                                                    <th>Farm</th>
                                                    <th width="150px">Subscription</th>
                                                    <th width="150px" className="u-textRight">
                                                        Amount
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice.farms.map((farm) => (
                                                    <tr key={farm.id} className="item">
                                                        <td valign="top">
                                                            <div className="u-textBold">{farm.name}</div>
                                                            <div>{farm.address}</div>
                                                        </td>
                                                        <td valign="top">1 Year</td>
                                                        <td className="u-textRight" valign="top">
                                                            {numeral(farm.amountExclGst).format("$0,0.00")}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot style={{ borderTop: "1px solid #e5e9e7", display: "table-row-group" }}>
                                                <tr>
                                                    <th></th>
                                                    <th className="u-textRight" colSpan="1">
                                                        Sub total:
                                                    </th>
                                                    <th className="u-textRight">{numeral(invoice.amountExclGst).format("$0,0.00")}</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th className="u-textRight" colSpan="1">
                                                        GST (15%):
                                                    </th>
                                                    <th className="u-textRight">{numeral(invoice.gst).format("$0,0.00")}</th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th className="u-textRight u-text-lg" colSpan="1">
                                                        Total:
                                                    </th>
                                                    <th className="u-textRight u-text-lg">{numeral(invoice.amountInclGst).format("$0,0.00")}</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </PanelBody>
                    )}
                </Panel>
            </div>
        </div>
    );
};

export default compose(
    withAppContext,
    withActions({ getInvoiceAsync }),
    withState((state, ownProps) => {
        return {
            paymentId: ownProps.match.params.paymentId,
        };
    })
)(Invoice);
