import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useModal } from "common/hooks";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import BlockSelector from "components/new/BlockSelector";
import MonthSelector from "./MonthSelector";

function OutdoorPigSolidApplicationModal({ viewModel, onSubmit, close }) {
    const submitAsync = async (formValues) => {
        delete formValues.availableBlocks;
        if (onSubmit) {
            await onSubmit(formValues);
        }
        close();
    }

    return (
        <Form initialValues={viewModel} mutators={{ ...arrayMutators }} onSubmit={submitAsync}>
            {({ form, handleSubmit, submitting }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`${viewModel.title} application`} skinny fluid close={close}>
                            <ModalBody>
                                <Grid>
                                    <Field name="id" type="hidden" component="input" />
                                    <GridCell className="u-width4of5">
                                        <Field name="blockIds" availableBlocks={viewModel.availableBlocks} component={BlockSelector} />
                                    </GridCell>
                                </Grid>
                                <Grid title="Months">
                                    <GridCell className="u-mt-md">
                                        <MonthSelector form={form} monthsFieldName="months" />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-pig-application" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-pig-application" submit waiting={submitting}>
                                        Done
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    )
}

export function useOutdoorPigSolidApplicationModal() {
    const [modal, openModal] = useModal(OutdoorPigSolidApplicationModal);

    const open = (viewModel, onSubmit) => {
        const modalProps = {
            viewModel,
            onSubmit
        };
        openModal(modalProps);
    };

    return [modal, open];
}
