import * as utils from "common/utils";
import Alert from "components/Alert";
import { useFieldHelp } from "components/FormFields/useFieldHelp";

export default function NumericInputPack({ label, name, className = "", placeholder, id, readOnly, isHidden, requiredLabel, val, value, onChange, disabled, uom, autoFocus, dataWidth, decimalPlaces, max, min, info, tip }) {
    const [fieldHelpLink, fieldHelp] = useFieldHelp(tip);
    const keyPress = (evt) => {
        const charCode = evt.which;

        // Allow: backspace, delete, tab, escape, enter, minus and .
        if (
            [46, 8, 9, 27, 13, 110, 190, 91, 189, 109, 173].includes(charCode) ||
            // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
            ([65, 67, 86, 88].includes(charCode) && (evt.ctrlKey === true || evt.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (charCode >= 35 && charCode <= 40)
        ) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((evt.shiftKey || charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
            evt.preventDefault();
        }
    };
    const onBlur = (e) => {
        let value = e.target.value;
        if (value === "") {
            value = undefined;
        } else if (!utils.isNumeric(value, -1)) {
            value = 0;
        } else {
            value = utils.round(value, decimalPlaces || 0);
        }
        if (!isNaN(max) && value > max) value = max;
        if (!isNaN(min) && value < min) value = min;
        const evt = { target: { value: value } };
        onChange(evt);
    };
    const localChange = (e) => {
        let value = e.target.value;
        if (value === "") {
            value = undefined;
        } else if (value && value.length > 0 && value.charAt(0) === "-") {
            if (value.length > 1 && !utils.isNumeric(value.substring(1), -1)) {
                value = 0;
            }
        } else if (!utils.isNumeric(value, -1)) {
            value = 0;
        }

        const evt = { target: { value: value } };
        onChange(evt);
    };
    if (isHidden) return null;
    return (
        <div className={"Field" + (val && val.touched && val.error ? " has-error " : " " + className)} data-width={dataWidth}>
            <label htmlFor={id} className={`Field-label ${disabled ? "is-disabled" : ""}`}>
                {label}
                {requiredLabel === true && <sup className="required">*</sup>}
                {label === undefined && "\u00A0"}
                {fieldHelpLink}
            </label>
            <div className="Input_wrapper">
                <input type="text" id={id} name={id} placeholder={placeholder || label} disabled={disabled} className={`Input Input--block ${disabled ? "is-disabled" : ""}`} readOnly={readOnly || disabled} autoFocus={autoFocus ? true : false} onKeyDown={(e) => keyPress(e)} onChange={(e) => localChange(e)} onBlur={(e) => onBlur(e)} value={value || value === 0 ? value : ""} />
                {uom && <small className="Input-helper">{uom}</small>}
            </div>
            <small className="Field-error" id={`${id}-error`}>
                {val && val.message}
            </small>
            {info && <Alert type="info" text={info} />}
            {fieldHelp}
        </div>
    )
}
