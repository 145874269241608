import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as domain from "common/domain";
import ActionLink from "components/ActionLink";
import { useOnline, useRefData } from "common/hooks";

export default function DrainageCard({ farm, analysis, block, promptBeforeDelete }) {
    const online = useOnline();
    const refData = useRefData();

    const blockIcon = utils.getBlockIcon(block.type);
    const blockType = utils.getBlockTypeText(refData, block);

    let drainageList = [];
    if (analysis.wetlands) {
        /* eslint-disable no-unused-vars */
        for (const wetland of analysis.wetlands.filter((w) => w.catchmentPercentages)) {
            drainageList = [...drainageList, ...wetland.catchmentPercentages.filter((cp) => cp.blockId === block.id).map((c) => ({ id: wetland.id, type: "unfenced", percentage: c.percentage, wetland: wetland, name: wetland.name }))];
        }
    }
    /* eslint-disable no-unused-vars */
    for (const wetlandBlock of analysis.blocks.filter((b) => b.type === domain.BlockType.NonProductiveWetland)) {
        if (wetlandBlock.wetland && wetlandBlock.wetland.catchmentPercentages) {
            drainageList = [...drainageList, ...wetlandBlock.wetland.catchmentPercentages.filter((cp) => cp.blockId === block.id).map((c) => ({ id: wetlandBlock.id, type: "fenced", percentage: c.percentage, wetland: wetlandBlock.wetland, name: wetlandBlock.name }))];
        }
    }

    const _isPopulatedDrainage = drainageList.length > 0;
    let drainageSystem = undefined;
    let drainage = undefined;
    let drainageCondition = undefined;
    let drainageMethodName = undefined;
    const nonDrainageBlocks = [domain.BlockType.NonProductiveTreesAndScrub, domain.BlockType.NonProductiveHouse, domain.BlockType.NonProductiveWetland, domain.BlockType.NonProductiveRiparian];
    const isDrainageBlock = !nonDrainageBlocks.includes(block.type);
    const isPastureBlock = block.type === domain.BlockType.ProductivePasture && block.animals && block.animals.length > 0;

    const riparianStrip = block.riparianStrip;
    const _isPopulatedRiparianStrip = isPastureBlock && riparianStrip && riparianStrip.catchmentArea > 0;

    const editRiparianLink = `/app/farm/${farm.id}/analysis/${analysis.id}/drainage/riparian/${block.id}`;

    const messages = analysis.messages || [];
    const alertIcon = online && messages.find((m) => m.category === "Wetlands" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error") ? "error" : undefined;

    /* eslint-disable no-unused-vars */
    for (const system of analysis.drainageSystems || []) {
        const dr = system.drainage.find((d) => d.id === block.drainageDetailsId);
        if (dr) {
            drainageSystem = system;
            drainage = dr;
            // eslint-disable-next-line
            drainageCondition = refData.wetlandConditionType.find((c) => c.value === drainage.artificialWetlandCondition) || {};
            // eslint-disable-next-line
            drainageMethodName = refData.drainageMethod.find((d) => d.value.toLowerCase() === system.drainageMethod.toLowerCase()) || {};
            break;
        }
    }

    return (
        <div className="FarmTable_wrapper" id={block.id}>
            <ul className="DataWidthTable FarmTable">
                <li className="FarmTable-title" data-width="md-100 xl-30">
                    <div className="FarmTitle">
                        {alertIcon && (
                            <span className={`Todo Todo--` + alertIcon}>
                                <i className="icon icon-alert" />
                            </span>
                        )}
                        <img className="FarmTitle-icon" src={blockIcon} alt="Block Type" />
                        <div className="FarmTitle-heading">
                            <span className="FarmTitle-name" id={`${block.id}_name`} title={block.name}>
                                {block.name}
                            </span>
                            <div className="FarmTitle-sub">{blockType}</div>
                        </div>
                    </div>
                </li>

                {_isPopulatedDrainage && (
                    <li data-width="md-20 xl-20">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Catchment wetlands</span>
                                {drainageList.map((d) => {
                                    return (
                                        <span key={drainageList.indexOf(d)} className="FarmTable-value">
                                            <Link id={`${d.wetland.id}_catch-for`} to={`/app/farm/${farm.id}/analysis/${analysis.id}/drainage/${d.type}/${d.id}${d.type === "unfenced" ? "?uf=1" : ""}`}>{`${d.name} - ${calcBlockArea(d.percentage, d.wetland.catchmentArea)}ha`}</Link>
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </li>
                )}

                {((!isPastureBlock && isDrainageBlock) || (isPastureBlock && drainageSystem)) && (
                    <li data-width="md-20 xl-20">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Drainage Method</span>
                                {drainageSystem && drainageMethodName && drainageMethodName.value && (
                                    <span className="FarmTable-value">
                                        <Link id={`${block.id}_drain-method`} to={`/app/farm/${farm.id}/analysis/${analysis.id}/drainage/${drainageMethodName.value.toLowerCase()}`}>
                                            {drainageMethodName.text}
                                        </Link>
                                    </span>
                                )}
                                {!drainageSystem && (
                                    <span id={`${block.id}_drain-method`} className="FarmTable-value">
                                        None
                                    </span>
                                )}
                            </div>
                        </div>
                    </li>
                )}

                {isPastureBlock && !_isPopulatedRiparianStrip && !drainageSystem && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        <Link to={editRiparianLink} className="IconLink--arrow-plus" type="button" id={`${block.id}_add-riparian-strip-details`}>
                            <span>Add grass filter strip</span>
                        </Link>
                    </li>
                )}

                {_isPopulatedRiparianStrip && (
                    <li data-width="md-20 xl-20">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Grass filter strip catchment area</span>
                                <span className="FarmTable-value" id={`${block.id}_catchment-area`}>
                                    {riparianStrip.catchmentArea} ha
                                </span>
                            </div>
                        </div>
                    </li>
                )}

                {drainage && drainage.artificialWetlandCondition && (
                    <li data-width="md-20 xl-20">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Drains into</span>
                                <span id={`${block.id}_drain-into`}>{`${drainageCondition.text} - ${drainage.artificialWetlandArea} ha`}</span>
                            </div>
                        </div>
                    </li>
                )}
            </ul>
            {_isPopulatedRiparianStrip && (
                <div className="ActionsBar">
                    <div className="ActionsBar-right">
                        <ul className="ActionsBar-links">
                            <li>
                                <ActionLink onClick={(e) => promptBeforeDelete(e, block)} className="IconLink--trash" id={`${block.id}_delete-riparian-strip`}>
                                    Delete grass filter strip
                                </ActionLink>
                            </li>
                            <li>
                                <Link to={editRiparianLink} className="IconLink--edit" id={`${block.id}_edit-riparian-strip`}>
                                    Edit grass filter strip
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    )
}

const calcBlockArea = (percentage, catchmentArea) => (isNaN(percentage) || isNaN(catchmentArea) ? 0 : utils.truncateDecimal(catchmentArea * (percentage / 100), 2));
