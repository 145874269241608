
export default function RadioButtonOptionSelector({ name, options, value, onChange }) {
    return (
        <div className="Field u-mt-0">
            <div className="Radio_group Radio_group--inline u-mt-0">
                {options.map((option) => {
                    return (
                        <RadioButtonOption key={option.value} name={name} value={option.value} checked={value === option.value} onChange={onChange}>
                            {option.text}
                            {option.uom && <>&nbsp;({option.uom})</>}
                        </RadioButtonOption>
                    )
                })}
            </div>
        </div>
    )
}

function RadioButtonOption({ name, value, checked, children, onChange }) {
    const id = `${name}_${value}`;
    return (
        <label className="Radio" htmlFor={id}>
            <input type="radio" id={id} name={name} value={value} checked={checked} data-test-id={id} onChange={(e) => onChange(e.target.value)} className="Radio-input" />
            <span className="Radio-label">{children}</span>
        </label>
    )
}
