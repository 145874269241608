import Alert from "components/Alert";
import { useFieldHelp } from "components/FormFields/useFieldHelp";

export default function SelectPack(props) {
    const {
        id,
        label,
        placeholder,
        placeholderEnabled,
        options,
        required,
        noLabel,
        dataWidth,
        disabled,
        readOnly,
        autoFocus,
        input,
        info,
        warning,
        tip,
        meta: { touched, error },
        className,
    } = props;

    const [fieldHelpLink, fieldHelp] = useFieldHelp(tip);

    const hasError = touched && error;

    const _onChange = (e) => {
        input.onChange(e, e.target.value);

        if (props.onChange) props.onChange(e, e.target.value);
    };

    const optionGroups = (options || []).reduce((groups, option) => {
        const groupIndex = option.groupIndex !== undefined ? option.groupIndex : "";
        const groupLabel = option.groupLabel || "";
        groups[groupIndex] = groups[groupIndex] || [];
        groups[groupIndex].push({ ...option, groupLabel });
        return groups;
    }, {});

    if (input.value.length > 0 && !(options || []).some((o) => o.value.toString() === input.value)) {
        _onChange({ target: { value: "" } });
    }

    return (
        <div className={`Field ${hasError ? "has-error" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            <label htmlFor={id || input.name} className="Select Select--block">
                {noLabel !== true && (
                    <span className={`Field-label${disabled ? " is-disabled" : ""}`}>
                        {label}
                        {required === true && <sup className="required">*</sup>}
                        {fieldHelpLink}
                    </span>
                )}
                <select {...input} id={id || input.name} disabled={disabled || readOnly} autoFocus={autoFocus === true} onChange={_onChange}>
                    {placeholder && (
                        <option value="" disabled={!placeholderEnabled || placeholderEnabled !== true}>
                            {placeholder}
                        </option>
                    )}
                    {Object.keys(optionGroups).map((key) => {
                        if (key === "") {
                            return optionGroups[key].map((option) => (
                                <option key={option.value} disabled={option.disabled} value={option.value} className={`${disabled ? "is-disabled" : ""}`}>
                                    {option.text}
                                </option>
                            ));
                        } else {
                            const groupLabel = optionGroups[key][0].groupLabel;
                            return (
                                <optgroup key={key} label={groupLabel}>
                                    {optionGroups[key].map((option) => (
                                        <option key={option.value} disabled={option.disabled} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </optgroup>
                            );
                        }
                    })}
                </select>
            </label>
            {hasError && (
                <small className="Field-error" id={`${id || input.name}-error`}>
                    {error}
                </small>
            )}
            {warning && <Alert type="warning" text={warning} className="u-mb-0" />}
            {info && <Alert type="info" text={info} className="u-mb-0" />}
            {fieldHelp}
        </div>
    )
}
