import { useConfirm, useRefData } from "common/hooks";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import { useRevokeAccessInvitationAsync } from "containers/hooks";

export default function PendingFarmAccessInvitations({ pendingAccessInvitations, resend, isPublicationAccess }) {
    const refData = useRefData();
    const confirm = useConfirm();
    const revokeAccessInvitationAsync = useRevokeAccessInvitationAsync();

    const _revokeInvitation = (accessInvitation) => {
        confirm(`Are you sure you want to delete this invitation?`, async () => {
            await revokeAccessInvitationAsync(accessInvitation);
        });
    }

    return (
        <>
            <Panel title={isPublicationAccess ? "Pending publication access invitations" : "Pending farm access invitations"} skyBlue>
                {isPublicationAccess && <Alert type="info" text="The list of pending invitations sent to users to act as your agent for publications published to you for this farm." />}
                {!isPublicationAccess && <Alert type="info" text="The list of pending invitations to users who you have granted permissions for this farm." />}
                <PanelBody>
                    <div className="Table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th>Email status</th>
                                    <th>Role</th>
                                    <th>Invited by</th>
                                    <th className="th--shrink"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingAccessInvitations.map((accessInvitation, i) => {
                                    const { emailLog } = accessInvitation;
                                    const status = emailLog ? emailLog.status : "Unknown";

                                    return (
                                        <tr key={accessInvitation.id} className={emailLog && emailLog.failed ? "u-font-red u-font-600" : ""}>
                                            <td id={`invitee_${accessInvitation.inviteeEmail}`}>{accessInvitation.inviteeEmail}</td>
                                            <td id={`date_${accessInvitation.inviteeEmail}`}>{utils.dateOnly(accessInvitation.invitedOn)}</td>
                                            <td id={`status_${accessInvitation.inviteeEmail}`}>{status}</td>
                                            <td id={`account_${accessInvitation.inviteeEmail}`}>{utils.valueToText(refData.farmRoles, accessInvitation.role)}</td>
                                            {accessInvitation.invitedBy.accountName !== accessInvitation.invitedBy.userFullName ? (
                                                <td>
                                                    {accessInvitation.invitedBy.accountName} - {accessInvitation.invitedBy.userFullName}
                                                </td>
                                            ) : (
                                                <td>{accessInvitation.invitedBy.accountName}</td>
                                            )}
                                            <td>
                                                <div className="u-flex">
                                                    {accessInvitation.status !== "Granted" && (
                                                        <ActionLink className="IconLink--edit" id={`resend-${accessInvitation.id}`} onClick={() => resend(accessInvitation)}>
                                                            Resend
                                                        </ActionLink>
                                                    )}
                                                    {
                                                        <ActionLink className="IconLink--trash u-ml-sm" id={`revoke-${accessInvitation.id}`} onClick={() => _revokeInvitation(accessInvitation)}>
                                                            Delete
                                                        </ActionLink>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </PanelBody>
            </Panel>
        </>
    )
}