import Alert from "components/Alert";
import { FileSummary } from "components/PlanningReport";
import NutrientsSummary from "./NutrientsSummary";
import NutrientsExtraSummary from "./NutrientsExtraSummary";

export default function Nutrients({ before, after, showFileComparison }) {
    const scenarios = [after];
    if (before) scenarios.unshift(before);

    if (!hasOverview(after.details) && (!before || !hasOverview(before.details))) {
        return (
            <div className="u-pt-lg">
                <Alert type="info" text="There are no nutrient results for this analysis" />
            </div>
        )
    }

    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <NutrientsSummary before={before} after={after} />
            <NutrientsExtraSummary before={before} after={after} />
        </div>
    )
}

const hasOverview = (budget = {}) => {
    const { currentResults = {} } = budget;
    const { nitrogenOverview, phosphorusOverview } = currentResults;
    return nitrogenOverview || phosphorusOverview;
}