import { Link, useLocation } from "react-router-dom";
import * as utils from "common/utils";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { DeleteUserActionLink, EditUserActionLink, MoveUserActionLink } from "containers/Admin/Users";

export default function UserList({ org, users }) {
    const location = useLocation();

    const showOrgName = !org;
    const defaultSortCriteria = showOrgName ? { fieldName: "accountName", fieldType: "string" } : { fieldName: "fullName", fieldType: "string" };

    return (<>
        <UserCount users={users} />
        <SortableTable data={users} defaultSortCriteria={defaultSortCriteria}>
            {({ data, sortCriteria, handleSort }) => {
                return (
                    <table>
                        <thead>
                            <tr>
                                {showOrgName && <SortableTableHeader label="Org" fieldName="accountName" sortCriteria={sortCriteria} handleSort={handleSort} />}
                                <SortableTableHeader label="User" fieldName="fullName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                <th>Id</th>
                                <SortableTableHeader label="Email" fieldName="email" sortCriteria={sortCriteria} handleSort={handleSort} />
                                <SortableTableHeader label="Org admin?" fieldName="isAdmin" sortCriteria={sortCriteria} handleSort={handleSort} bool />
                                <SortableTableHeader label="Activated?" fieldName="isActivated" sortCriteria={sortCriteria} handleSort={handleSort} bool />
                                <SortableTableHeader label="Login attempts" fieldName="loginAttempts" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                <SortableTableHeader label="Created" fieldName="createdOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                <SortableTableHeader label="Modified" fieldName="modifiedOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((user) => {
                                return (
                                    <tr key={user.email} id={user.email} className="hover">
                                        {showOrgName && (
                                            <td>
                                                <Link to={{ pathname: `/app/admin/orgs/${user.accountId}`, state: { referrer: location.pathname } }}>{user.accountName}</Link>
                                            </td>
                                        )}
                                        <td>{user.fullName}</td>
                                        <td>{user.id}</td>
                                        <td>{user.email}</td>
                                        <td className="u-textCenter">
                                            {!user.isApiUser && (user.isAdmin ? "Yes" : "No")}
                                            {user.isApiUser && "API"}
                                        </td>
                                        <td className="u-textCenter">{user.isActivated ? "Yes" : "-"}</td>
                                        <td className="u-textCenter">{user.loginAttempts ? user.loginAttempts : "-"}</td>
                                        <td>{utils.dateLong(user.createdOn, "-")}</td>
                                        <td>{utils.dateLong(user.modifiedOn, "-")}</td>
                                        <td>
                                            <EditUserActionLink user={user} className="icon icon-edit" />
                                            <DeleteUserActionLink user={user} className="icon icon-trash" />
                                            <MoveUserActionLink user={user} className="icon icon-users" />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                );
            }}
        </SortableTable>
    </>);
};

function UserCount({ users }) {
    if (!users) return null;

    return (
        <p className="u-mt-0 u-mb-md">
            <span className="h3">{users.length.toLocaleString()}</span> user(s) found
        </p>
    )
}
