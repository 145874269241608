import React from "react";

const Input = (props) => {
    const { id, name, value, label, placeholder, unit, noLabel, required, invalid, dataWidth, disabled, onChange, onKeyPress, meta } = props;

    const hasError = meta && meta.touched && meta.error;

    return (
        <div className={`Field${hasError || invalid === true ? " has-error" : ""}`} data-width={dataWidth}>
            {noLabel !== true && (
                <label htmlFor={id || name} className={`Field-label${disabled ? " is-disabled" : ""}`}>
                    {label}
                    {required === true && <sup className="required">*</sup>}
                </label>
            )}
            <div className="Input_wrapper">
                <input type="text" name={name} id={id || name} value={value || ""} onChange={onChange} onKeyPress={onKeyPress} placeholder={placeholder || label} className={`Input Input--block${invalid === true ? " invalid" : ""}`} disabled={disabled} />
                {unit && <small className="Input-helper">{unit}</small>}
            </div>
            {hasError && (
                <small id={`${id || name}-error`} className="Field-error">
                    {meta.error}
                </small>
            )}
        </div>
    );
};

export default Input;
