import React from "react";
import { Link } from "react-router-dom";
import { withActions } from "containers/HOC";
import { useQueryString } from "common/effects";
import * as utils from "common/utils";
import moment from "moment";
import { Panel, PanelBody } from "components/Panel";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { getRenewalStats } from "../_actions";

const ChurnReport = ({ getRenewalStats }) => {
    const queryString = useQueryString();

    const [yearMonth, setYearMonth] = React.useState(() => {
        const defaultYear = moment().month() === 0 ? moment().year() - 1 : moment().year();
        const defaultToLastMonth = moment().month() === 0 ? 12 : moment().month();
        const year = Number(queryString.get("year") || defaultYear);
        const month = Number(queryString.get("month") || defaultToLastMonth);
        return { year, month };
    });

    const [renewalStats, setRenewalStats] = React.useState();

    React.useEffect(() => {
        getRenewalStats(yearMonth.year, yearMonth.month)
            .then((response) => {
                const farms = response.farms.map((farm) => {
                    const expiryForQueriedYearMonth = farm.subscriptions.find((sub) => sub.expiryYear === yearMonth.year && sub.expiryMonth === yearMonth.month);
                    const nextRenewal = farm.subscriptions.find((sub) => sub.startDate > expiryForQueriedYearMonth.expiryDate);
                    const renewalDays = nextRenewal ? moment(nextRenewal.startDate).diff(moment(expiryForQueriedYearMonth.expiryDate), "days") : moment(expiryForQueriedYearMonth.expiryDate).diff(moment(), "days");
                    const ownerAccount = farm.accounts.find((a) => a.role === "Owner");
                    const owner = ownerAccount ? ownerAccount.accountName : "-";
                    const purchasedBy = `${expiryForQueriedYearMonth.paymentByUserName} from ${expiryForQueriedYearMonth.paymentByAccountName}`;
                    const renewedBy = nextRenewal ? `${nextRenewal.paymentByUserName} from ${nextRenewal.paymentByAccountName}` : "-";
                    return { ...farm, renewalDays, owner, purchasedBy, renewedBy };
                });
                const totalExpired = farms.length;
                const stillExpired = farms.filter((f) => f.renewalDays < 0).length;
                const renewed = farms.filter((f) => f.renewalDays >= 0).length;
                const owned = farms.filter((f) => f.owner !== "-").length;
                setRenewalStats({
                    ...response,
                    farms,
                    totalExpired,
                    stillExpired,
                    renewed,
                    owned,
                    error: null,
                });
            })
            .catch((error) => setRenewalStats({ error }));
    }, [yearMonth, getRenewalStats]);

    const handleYearMonthChange = (e) => {
        setRenewalStats(null);
        const year = Number(e.target.value.substring(0, 4));
        const month = Number(e.target.value.substring(5, yearMonth.length));
        setYearMonth({ year, month });
    };

    const yearMonthOptions = getYearMonthOptions();
    const yearMonthSelect = (
        <div className="u-flex flex-center">
            <div className="Panel-action-label">Month:</div>
            <div className="Select Select--slim">
                <select id="yearMonth" onChange={handleYearMonthChange} value={`${yearMonth.year}-${yearMonth.month}`}>
                    {yearMonthOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.text}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );

    const loading = !renewalStats;
    const yearMonthOption = yearMonthOptions.find((opt) => opt.year === yearMonth.year && opt.month === yearMonth.month);

    const error = renewalStats && renewalStats.error;
    const hasStats = renewalStats && (renewalStats.farms || []).length > 0;

    return (
        <Panel title={`Farm accounts that expired in ${yearMonthOption.text}`} actions={yearMonthSelect} loading={loading} green>
            <PanelBody loading={loading}>
                {!error && hasStats && (
                    <>
                        <div className="Table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Farm accounts expired in {yearMonthOption.text}</th>
                                        <th>Owned</th>
                                        <th>Ownership rate</th>
                                        <th>Renewed</th>
                                        <th>Renewal rate</th>
                                        <th>Still not renewed</th>
                                        <th>Non-renewal rate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="u-textBold1">
                                        <td>{renewalStats.totalExpired.toLocaleString()}</td>
                                        <td>{renewalStats.owned.toLocaleString()}</td>
                                        <td>{utils.round((renewalStats.owned / renewalStats.totalExpired) * 100, 1)}%</td>
                                        <td>{renewalStats.renewed.toLocaleString()}</td>
                                        <td>{utils.round((renewalStats.renewed / renewalStats.totalExpired) * 100, 1)}%</td>
                                        <td className="u-textError">{renewalStats.stillExpired.toLocaleString()}</td>
                                        <td className="u-textError">{utils.round((renewalStats.stillExpired / renewalStats.totalExpired) * 100, 1)}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <SortableTable data={renewalStats.farms} defaultSortCriteria={{ fieldName: "renewalDays", fieldType: "number" }}>
                            {({ data, sortCriteria, handleSort }) => {
                                return (
                                    <table>
                                        <thead>
                                            <tr>
                                                <SortableTableHeader label="Farm" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Address" fieldName="address" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Expiry" fieldName="expiryDate" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                <SortableTableHeader label="Renewed after (days)" fieldName="renewalDays" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                <SortableTableHeader label="Owner" fieldName="owner" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Purchased by" fieldName="purchasedBy" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Renewed by" fieldName="renewedBy" sortCriteria={sortCriteria} handleSort={handleSort} />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((farm) => {
                                                return (
                                                    <tr key={farm.id} id={farm.id} className={farm.expired ? "u-textError hover" : "hover"}>
                                                        <td>
                                                            <Link to={`/app/farm/${farm.id}`}>{farm.name}</Link>
                                                        </td>
                                                        <td>{farm.address}</td>
                                                        <td>{utils.dateLong(farm.expiryDate, "-")}</td>
                                                        <td className="u-textRight">{farm.renewalDays}</td>
                                                        <td>{farm.owner}</td>
                                                        <td>{farm.purchasedBy}</td>
                                                        <td>{farm.renewedBy}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                );
                            }}
                        </SortableTable>
                    </>
                )}
                {!error && !hasStats && (
                    <div className="Tile-body-message">
                        <p className="lead">No data</p>
                    </div>
                )}
                {error && (
                    <div className="Tile-body-message">
                        <i className="icon icon--md icon-alert u-textError" />
                        <h3 className="u-textError">{renewalStats.error}</h3>
                    </div>
                )}
            </PanelBody>
        </Panel>
    );
};

const getYearMonthOptions = () => {
    const options = [];
    let start = moment("2020-02-01"); // Renewals weren't possible unitl Feb 2020
    const now = moment().subtract(1, "months"); // Up until last month.
    while (start.isBefore(now)) {
        options.unshift({ value: start.format("YYYY-M"), text: start.format("MMMM YYYY"), year: start.year(), month: start.month() + 1 });
        start.add(1, "months");
    }
    return options;
};

export default withActions({ getRenewalStats })(ChurnReport);
