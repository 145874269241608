import React from "react";
import { compose, withAppContext, withState, withActions } from "containers/HOC";
import * as icons from "common/icons";
import Loader from "components/Loader";
import Alert from "components/Alert";
import Page from "components/Page";
import PageBody from "components/PageBody";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileBodyMessage from "components/TileBodyMessage";
import { activateUser, resendActivationEmail } from "containers/Auth/_actions";
import ActionLink from "components/ActionLink";

const ActivationRequired = (props) => {
    const { online, isLoading, referredFromFailedActivation, resendActivationEmail } = props;

    const _resend = () => {
        resendActivationEmail();
    };

    return (
        <Page>
            <section className="PageHead">
                <div className="Container">
                    <div className="FarmTitle">
                        <div className="FarmTitle-heading">
                            <span id="page-head-title" className="FarmTitle-name">
                                Dashboard
                            </span>
                        </div>
                    </div>
                    <div className="PageHead-actions"></div>
                </div>
            </section>
            <PageBody>
                {online && isLoading && <Loader message="Loading..." />}
                {referredFromFailedActivation && <Alert type="error" text="The verification link is invalid. Please check your email and try again." />}
                <Tile title="Email verification">
                    <TileBody>
                        <TileBodyMessage>
                            <img className="u-p-5" src={icons.blocks} alt="Overseer" />
                            <h1 className="u-mt-0">Please verify your email</h1>
                            <h2>Before you continue using Overseer, we need to verify your email address. Please check your email for a verification link.</h2>
                            {online && (
                                <ActionLink id="resend-activation" className="IconLink--arrow-plus Button Button--lg u-mt-lg" onClick={_resend}>
                                    Resend verification email
                                </ActionLink>
                            )}
                        </TileBodyMessage>
                    </TileBody>
                </Tile>
            </PageBody>
        </Page>
    );
};

export default compose(
    withAppContext,
    withState((state, ownProps) => {
        return {
            referredFromFailedActivation: state.auth.activationFailed && ownProps.location.state && ownProps.location.state.from === "/pub/activate",
        };
    }),
    withActions({ activateUser, resendActivationEmail })
)(ActivationRequired);
