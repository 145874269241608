import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import { Grid, GridCell } from "components/Grid";
import ProgressBar from "components/ProgressBar";
import NLossBySoilOrdersChart from "./NLossBySoilOrdersChart";
import PLossBySoilOrdersChart from "./PLossBySoilOrdersChart";
import Alert from "components/Alert";
import { Doughnut } from "react-chartjs-2";

const SoilOrders = ({ aggregatedData }) => {
    const refData = useRefData();

    if (!aggregatedData || !aggregatedData.results || aggregatedData.totalArea === 0) return null;

    const clonedData = utils.clone(aggregatedData);
    const { soils = [] } = clonedData;

    const allColours = refData.soilOrders.map((s, i) => {
        return { order: s.value, colour: utils.seqColourPalette[i] };
    });

    const orderedSoils = soils.sort((a, b) => b.totalArea - a.totalArea);
    const colours = orderedSoils.map((s) => {
        const colourOrder = allColours.find((c) => c.order === s.soilOrder);
        return colourOrder ? colourOrder.colour : utils.seqColourPalette[allColours.length];
    });

    const data = {
        labels: orderedSoils.map((soil) => soil.soilOrder),
        datasets: [
            {
                data: orderedSoils.map((soil) => utils.round(soil.totalArea, 0)),
                backgroundColor: colours,
                hoverBackgroundColor: colours,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        responsive: false,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        return `${tooltipItem.label}: ${Number(tooltipItem.raw).toLocaleString()} ha`;
                    },
                },
            },
        },
    };

    return (
        <div className="HeroPanel u-mt-md u-page-break u-print-border">
            <div className="HeroPanel-heading">Soils</div>
            <Grid>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1">
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th>Soil order</th>
                                    <th className="u-textRight">Farms</th>
                                    <th className="u-textRight">Blocks</th>
                                    <th className="u-textRight">Area (ha)</th>
                                    <th className="u-textCenter">Percentage of productive area</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderedSoils.map((soil, i) => {
                                    const progress = utils.round((soil.totalArea / aggregatedData.productiveArea) * 100, 1);
                                    const { farmCount = 0, blockCount = 0, totalArea = 0 } = soil;
                                    return (
                                        <tr key={soil.soilOrder} className="Table-tr--color" style={{ "--row-color": colours[i] }}>
                                            <td>{soil.soilOrder}</td>
                                            <td className="u-textRight">{farmCount.toLocaleString()}</td>
                                            <td className="u-textRight">{blockCount.toLocaleString()}</td>
                                            <td className="u-textRight">{utils.round(totalArea, 0).toLocaleString()}</td>
                                            <td>
                                                <ProgressBar colour={colours[i]} endLabel={`${progress}%`} progress={progress} showZero={true} />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td className="u-textRight">{utils.round(aggregatedData.productiveArea, 0).toLocaleString()}</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </GridCell>
                <GridCell className="u-lg-width1of2 u-md-width1of2 u-print-none">
                    <div className="u-pt-xl u-pl-xl u-pb-lg">
                        <div className="u-flex u-flexJustifyCenter">
                            <Doughnut data={data} options={options} height={400} width={400} />
                        </div>
                    </div>
                </GridCell>
            </Grid>
            <Grid className="u-page_break">
                <GridCell className="u-mt-lg">
                    <Alert className="u-mb-md" type="info" text="The following graphs show the nitrogen and phosphorus losses by soil order. You can switch between total loss and loss per hectare using the buttons on the right. Losses by hectare are the total losses for that soil order divided by the total area of that soil order." />
                </GridCell>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1">
                    <NLossBySoilOrdersChart soils={orderedSoils} colours={colours} />
                </GridCell>
                <GridCell className="u-xxl-width1of2 u-xl-width1of1 u-page_break">
                    <PLossBySoilOrdersChart soils={orderedSoils} colours={colours} />
                </GridCell>
            </Grid>
        </div>
    );
};

export default SoilOrders;
