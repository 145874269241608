import { Link } from "react-router-dom";
import * as icons from "common/icons";
import CardPanel from "components/new/CardPanel";
import Card from "components/new/Card";
import CardBody from "components/new/CardBody";
import { GHG_SECTION_NAMES, GHG_SECTIONS } from "./_constants";
import { useConfirm, useNavigate } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function GHGList({ farm, analysis }) {
    const navigate = useNavigate();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const _editSection = (e, section) => {
        navigate(`/app/farm/${farm.id}/analysis/${analysis.id}/ghg/${section.name}`);
    };

    const _deleteSection = (e, section) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete the overrides for "${section.title}"?`, async () => {
            const updatedAnalysis = {
                ...analysis,
                ghg: {
                    ...analysis.ghg,
                    [section.name]: null,
                }
            }
            await updateAnalysisAsync(updatedAnalysis);
        });
    };

    const ghgSections = [];
    if (analysis.ghg) {
        if (analysis.ghg.fuel) ghgSections.push(GHG_SECTIONS.find((s) => s.name === GHG_SECTION_NAMES.FUEL));
        if (analysis.ghg.fertiliser) ghgSections.push(GHG_SECTIONS.find((s) => s.name === GHG_SECTION_NAMES.FERTILISER));
        if (analysis.ghg.transport) ghgSections.push(GHG_SECTIONS.find((s) => s.name === GHG_SECTION_NAMES.TRANSPORT));
        if (analysis.ghg.electricity) ghgSections.push(GHG_SECTIONS.find((s) => s.name === GHG_SECTION_NAMES.ELECTRICITY));
    }

    const hasGHGSections = ghgSections.length > 0;

    return (
        <>
            <CardPanel title="Greenhouse gas energy footprint" green>
                <Card>
                    <CardBody className="u-mt-md">
                        {!hasGHGSections && (
                            <div className="Tile-body-message">
                                <img src={icons.add} className="u-p-5" alt="Plus" />
                                <p className="lead">This analysis is currently using the default settings for estimating the greenhouse gas energy footprint of this farm</p>
                                {GHG_SECTIONS.map((section) => {
                                    return (
                                        <Link key={section.name} id={`ghg-${section.name}-button`} to={`/app/farm/${farm.id}/analysis/${analysis.id}/ghg/${section.name}`} className="IconLink--arrow-plus Button Button--lg u-mt-md">
                                            {section.actionText}
                                        </Link>
                                    );
                                })}
                            </div>
                        )}
                        {hasGHGSections && (
                            <div className="Table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>GHG overrides</th>
                                            <th className="th--shrink">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ghgSections.map((section) => {
                                            return (
                                                <tr key={section.name} className="hover u-link">
                                                    <td id={`edit-section-${section.name}`} onClick={(e) => _editSection(e, section)}>
                                                        <span className="a">{section.title} overrides</span>
                                                    </td>
                                                    <td id={`delete-section-${section.name}`} onClick={(e) => _deleteSection(e, section)}>
                                                        <span className="IconLink--trash a" title="Delete"></span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </CardPanel>
        </>
    )
}
