import { createContext, useCallback, useState } from "react";
import ReactDOM from 'react-dom';
import ConfirmModal from "./ConfirmModal";

export default function ConfirmProvider({ children }) {
    const [state, setState] = useState({
        isOpen: false
    });

    const confirm = useCallback((prompt, onConfirm) => {
        setState({
            isOpen: true,
            prompt,
            onConfirm
        });
    }, []);

    const close = useCallback(() => {
        setState({
            isOpen: false
        })
    }, [])

    return (
        <ConfirmContext.Provider value={confirm}>
            {children}
            {state.isOpen
                ? ReactDOM.createPortal(<>
                    <ConfirmModal prompt={state.prompt} onConfirm={state.onConfirm} close={close} />
                </>, document.getElementById("confirm-modal"))
                : <></>
            }
        </ConfirmContext.Provider>
    )
}

export const ConfirmContext = createContext();
