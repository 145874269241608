import { Fragment } from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as cropUtils from "../Crops/_utils";
import TileSubPanel from "components/TileSubPanel";
import * as domain from "common/domain";
import * as fertUtils from "./_utils";
import { useFormState, useSetFormState, useOnline, useRefData } from "../../../common/hooks";

export default function BlockListGroup({ farm, analysis, blockGroup }) {
    const online = useOnline();
    const refData = useRefData();
    const formState = useFormState();
    const setFormState = useSetFormState();

    const { fertiliser: unsortedProducts = [] } = analysis;
    const nonLimeProducts = unsortedProducts.filter((p) => p.material !== "Lime");
    const limeProducts = unsortedProducts.filter((p) => p.material === "Lime" && !p.isLastPreviousLime);
    const prevLimeProducts = unsortedProducts.filter((p) => p.material === "Lime" && p.isLastPreviousLime);
    const products = [...nonLimeProducts, ...limeProducts, ...prevLimeProducts];

    const { blockTypes = [] } = refData;
    const blockType = blockTypes.find((t) => t.value === blockGroup.type) || {};
    const { blocks = [], calendar = [] } = blockGroup;
    const { messages = [] } = analysis;

    const toggleBlock = (block) => (e) => {
        const expanded = formState[`analysis.fertiliser.${block.id}.expanded`] === true;
        setFormState(`analysis.fertiliser.${block.id}.expanded`, !expanded);
    };

    const formatAmount = (fertiliser, application, block) => {
        if (!application || application.amount === 0) return "";
        let blockAmount = application.amount || 0;
        if (application.unit !== "kgha") {
            const totalArea = analysis.blocks.filter((b) => application.blockIds.includes(b.id)).reduce((a, b) => a + calcBlockSize(b), 0);
            const blockSize = calcBlockSize(block);
            blockAmount = parseFloat((blockSize / totalArea) * application.amount);
        }

        const unit = fertiliser.dairyFactoryEffluent && fertiliser.dairyFactoryEffluent === "Userdefined" ? "lt/ha" : application.unit === "kgha" ? "kg/ha" : application.unit;
        return `${+blockAmount.toFixed(2)} ${unit}`;
    };

    const renderMonth = (product, month, block, reportingYear, index) => {
        const monthApps = product.applications.filter((a) => (a.blockIds || []).includes(block.id) && a.months.find((m) => m.reportingYear === reportingYear && m.month === month));
        return monthApps.length > 0 ? (
            <td key={index} className="Calendar-slot">
                <img className="Calendar-icon u-p-xxs" src={utils.getFertiliserIcon(product.material)} alt="fertiliser icon" />
                <span className="Calendar-icon-text">{fertUtils.formatFertAmount(product, monthApps, analysis)}</span>
            </td>
        ) : (
            <td key={index} id={`${block.id}_${month}`} className="Calendar-slot"></td>
        );
    };

    const calcBlockSize = (block) => (block.type === "FodderCrop" ? block.rotationArea : fertUtils.getApplicationBlockArea([block.id], analysis.blocks));

    const PreviousLime = ({ product, application, block }) => {
        const yearsAgo = isNaN(application.limeYearsSinceApplied) ? "" : application.limeYearsSinceApplied === 0 ? " - 1 year ago (dissolved)" : application.limeYearsSinceApplied === 1 ? " - 1 year ago (not dissolved)" : ` - ${application.limeYearsSinceApplied} years ago`;
        return (
            <div className="u-pl-lg">
                <span className="td-key">Previous lime</span>
                <span className="td-value">
                    {formatAmount(product, application, block)}
                    {yearsAgo}
                </span>
            </div>
        );
    };

    return (
        <TileSubPanel title={`${blockType.text} ${blockGroup.type === "FodderCrop" ? "rotations" : " blocks"}`} stickyHeaderName={`analysis.fertiliser.${blockGroup.type}.collapsed`}>
            {
                <div className="FarmTable_wrapper">
                    <div className="Table Table-Compressed u-scroll-x">
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan="2"></th>
                                    {calendar.map((m, i) => (
                                        <th key={i} className="u-textCenter">
                                            {m.substring(0, 3)}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {blocks.map((block, i) => {
                                    const blockIcon = utils.getBlockIcon(block.type);
                                    const blockType = utils.getBlockTypeText(refData, block);
                                    const blockExpanded = formState[`analysis.fertiliser.${block.id}.expanded`] === true;
                                    const { crops = [] } = block;
                                    const rotationYear = crops.length > 0 ? cropUtils.rotationYear(block) : domain.farmYear;
                                    const productsOnBlock = products.filter((p) => p.applications.some((a) => (a.blockIds || []).includes(block.id)));
                                    const nonPrevLimeProductsOnBlock = productsOnBlock.filter((p) => p.applications.some((a) => !a.isLastPreviousLime));
                                    const blockPrevLimeProducts = productsOnBlock.filter((p) => p.applications.some((a) => (a.blockIds || []).includes(block.id) && a.isLastPreviousLime));
                                    const hasErrors = online && messages.some((m) => m.category === "Fertiliser" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error");
                                    const hasWarnings = online && messages.some((m) => m.category === "Fertiliser" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Warning");
                                    const blockSize = fertUtils.getApplicationBlockArea([block.id], analysis.blocks);
                                    const blocksTotalArea = analysis.blocks.reduce((a, b) => a + fertUtils.getApplicationBlockArea([b.id], analysis.blocks), 0);

                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                {productsOnBlock.length > 0 && (
                                                    <td className="td--shrink u-textCenter u-link" onClick={toggleBlock(block)}>
                                                        <span className="a">
                                                            <i className={`u-mt-sm icon icon-arrow-${blockExpanded ? "up" : "down"}`}></i>
                                                        </span>
                                                    </td>
                                                )}
                                                {productsOnBlock.length === 0 && <td className="td--shrink"></td>}
                                                <td data-width="20">
                                                    <div className="u-flex">
                                                        {hasWarnings && !hasErrors && (
                                                            <span className={`Todo Todo--warning`}>
                                                                <i className="icon icon-alert" />
                                                            </span>
                                                        )}
                                                        {hasErrors && (
                                                            <span className={`Todo Todo--error error-anchor`}>
                                                                <i className="icon icon-alert" />
                                                            </span>
                                                        )}
                                                        <img className="FarmTitle-icon" src={blockIcon} alt="Block Type" />
                                                        <div className="FarmTitle-heading--sm">
                                                            <Link id={`blockid-${block.id}`} className="FarmTitle-name a" to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/applications?blockId=${block.id}`}>
                                                                {block.name}
                                                            </Link>
                                                            <div className="FarmTitle-sub--sm">{blockType}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                {nonPrevLimeProductsOnBlock.length === 0 && blockPrevLimeProducts.length > 0 && (
                                                    <td colSpan={calendar.length + 1}>
                                                        <PreviousLime product={blockPrevLimeProducts[0]} application={blockPrevLimeProducts[0].applications.find((a) => a.isLastPreviousLime)} block={block} />
                                                    </td>
                                                )}
                                                {productsOnBlock.length === 0 && (
                                                    <td colSpan={calendar.length + 1}>
                                                        <Link className="IconLink--arrow-plus u-ml-sm" id={`fertiliser-add-applications_${block.id}`} to={`/app/farm/${analysis.farmId}/analysis/${analysis.id}/fertiliser/applications?blockId=${block.id}`}>
                                                            <span>Add applications</span>
                                                        </Link>
                                                    </td>
                                                )}
                                                {nonPrevLimeProductsOnBlock.length > 0 && (
                                                    <>
                                                        {calendar.map((m, i) => {
                                                            const isReportingYear = crops.length > 0 ? cropUtils.indexToReportingYear(i, rotationYear) : true;
                                                            const productsOnBlockForMonth = nonPrevLimeProductsOnBlock.filter((p) => p.applications.some((a) => a.months.some((x) => x.month === m && x.reportingYear === isReportingYear) && (a.blockIds || []).includes(block.id)));
                                                            const applicationCountOnBlockForMonth = productsOnBlockForMonth.reduce((t, p) => (t += (p.applications || []).filter((a) => a.months.some((x) => x.month === m && x.reportingYear === isReportingYear) && (a.blockIds || []).includes(block.id)).length), 0);
                                                            let icon = undefined;
                                                            crops.forEach((crop) => {
                                                                if (cropUtils.isCropMonth(rotationYear, crop, i)) {
                                                                    icon = utils.getCropIcon(crop);
                                                                }
                                                            });

                                                            return (
                                                                <td key={i} className="u-textCenter td--alignBottom">
                                                                    <div className="u-flexColumn">
                                                                        {productsOnBlockForMonth.length > 0 && (
                                                                            <div className="Calendar-slot">
                                                                                <img className="Calendar-icon u-icon-sm u-p-5" src={utils.getFertiliserIcon(productsOnBlockForMonth[0].material)} alt={productsOnBlockForMonth[0].material} />
                                                                                <span className="Calendar-icon-text">x {applicationCountOnBlockForMonth}</span>
                                                                            </div>
                                                                        )}
                                                                        {icon && <img className="u-icon-sm u-opacity-sm u-p-5" src={icon} alt="" />}
                                                                        {crops.length > 0 && !icon && <div className="u-icon-sm">&nbsp;</div>}
                                                                    </div>
                                                                </td>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </tr>
                                            <tr></tr>
                                            {blockExpanded && (
                                                <>
                                                    <tr>
                                                        <th colSpan="2"></th>
                                                        {calendar.map((m, i) => (
                                                            <th key={i} className="u-textCenter">
                                                                {m.substring(0, 3)}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                    {productsOnBlock.map((item, i) => {
                                                        const onlyPrevLime = blockPrevLimeProducts.length > 0 && nonPrevLimeProductsOnBlock.filter((p) => p.id === item.id).length === 0;
                                                        return (
                                                            <tr className="Calendar-slots Calendar-slots--fertiliser tr-nested" key={i}>
                                                                <td></td>
                                                                <td>
                                                                    <div className="u-flex">
                                                                        <img src={utils.getFertiliserIcon(item.material)} className="u-p-5" width="35" height="35" alt="fertiliser icon" />
                                                                        <div className="u-flex">
                                                                            <div className="u-pl-sm">
                                                                                <span className="td-key">{item.manufacturer}</span>
                                                                                <span className="td-value">
                                                                                    <Link className="a" to={`/app/farm/${analysis.farmId}/analysis/${analysis.id}/fertiliser/applications?productId=${item.id}&blockId=${block.id}`}>
                                                                                        {item.productName}
                                                                                    </Link>
                                                                                </span>
                                                                            </div>
                                                                            {blockPrevLimeProducts.length > 0 && blockPrevLimeProducts[0].id === item.id && !onlyPrevLime && <PreviousLime product={blockPrevLimeProducts[0]} application={blockPrevLimeProducts[0].applications.find((a) => a.isLastPreviousLime)} block={block} />}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {onlyPrevLime ? (
                                                                    <td colSpan={calendar.length}>
                                                                        <PreviousLime product={blockPrevLimeProducts[0]} application={blockPrevLimeProducts[0].applications.find((a) => a.isLastPreviousLime)} block={block} />
                                                                    </td>
                                                                ) : (
                                                                    <>
                                                                        {rotationYear.map((month, index) => {
                                                                            const reportingYear = cropUtils.indexToReportingYear(index, rotationYear);
                                                                            return renderMonth(item, month, block, reportingYear, index, blocksTotalArea, blockSize);
                                                                        })}
                                                                    </>
                                                                )}
                                                            </tr>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </TileSubPanel>
    )
}
