export const canHaveSnowfall = (blockType) => {
    return ["ProductivePasture", "ProductiveOutdoorPigs"].includes(blockType);
};

export const getAvailableSnowfallBlocks = (budget, snowfall) => {
    const selectedBlockIds = (budget.snowfalls || []).reduce((results, snowfall) => results.concat(snowfall.blockIds), []);

    return budget.blocks.filter((block) => {
        const notSelected = selectedBlockIds.some((id) => id === block.id) === false;
        const partOfThisSnowfall = snowfall.blockIds ? snowfall.blockIds.some((id) => id === block.id) === true : false;
        return canHaveSnowfall(block.type) && (notSelected || partOfThisSnowfall);
    });
};
