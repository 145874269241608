import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { Panel, PanelBody } from "components/Panel";
import NoData from "components/NoData/NoData";
import { FarmList }  from "containers/Admin/Farms";

export default function OrgFarms({ org }) {
    const { isLoading, error, data: farms } = useOrgFarms(org?.id);
    const hasData = farms?.length > 0;

    return (
        <Panel loading={isLoading} error={error} >
            <PanelBody loading={isLoading}>
                {hasData
                    ? <FarmList farms={farms} />
                    : <NoData />
                }
            </PanelBody>
        </Panel>
    );
}

function useOrgFarms(orgId) {
    const query = useQuery({
        queryKey: ["orgs", orgId, "farms"],
        queryFn: async () => httpClient.get(`admin/accounts/${orgId}/farms`),
        retry: false
    });

    return {
        isLoading: query.isLoading,
        error: query.error?.message,
        data: query.data
    };
}
