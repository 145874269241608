export default function BlocksUsingSoils({ analysis, soil }) {
    if (!soil || !soil.id) return null;

    const farmSoilBlocks = analysis.farmSoilBlocks
        .filter((fsb) => fsb.soilId === soil.id)
        .map((fsb) => {
            const block = analysis.blocks.find((b) => b.id === fsb.blockId);
            return block && { id: fsb.id, name: block.name, area: block.areaInHectares, soilArea: fsb.area, percentage: fsb.percentageOfBlock };
        });

    return (
        <>
            <h3 className="h5 u-text-md">Blocks using this soil</h3>
            <div className={`Table u-mt-xs`}>
                <table>
                    <thead>
                        <tr>
                            <th>Block</th>
                            <th>Block area</th>
                            <th>Soil area</th>
                            <th>Percentage of block</th>
                        </tr>
                    </thead>
                    <tbody>
                        {farmSoilBlocks.length > 0 &&
                            farmSoilBlocks.map((fsb) => {
                                return (
                                    <tr key={fsb.id}>
                                        <td>{fsb.name}</td>
                                        <td>{fsb.area}</td>
                                        <td>{fsb.soilArea}</td>
                                        <td>{fsb.percentage}</td>
                                    </tr>
                                );
                            })}
                        {farmSoilBlocks.length === 0 && (
                            <tr>
                                <td colSpan="4">
                                    <div className="u-flex u-flexJustifyCenter u-pb-sm">
                                        <span className="IconLink--alert u-textError">This soil is not used on any blocks</span>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
