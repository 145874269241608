import * as utils from "common/utils";
import { v4 as uuidv4 } from "uuid";
import * as fertUtils from "containers/BudgetHome/Fertiliser/_utils";
import { findCropEvent } from "containers/BudgetHome/Crops/_utils";

export const getAnalysisTypeOptions = (refData) => (refData.budgetTypes || []).filter((bt) => bt.value !== "Account" && bt.value !== "PrivateYearEnd");

export const getYearOptions = (farm, refData) => {
    const writeAccess = utils.canUpdateFarm(farm);
    const years = (refData.budgetYears || []).sort().reverse();
    return years.map((year) => {
        const exists = farm.budgets.find((b) => ((writeAccess && b.type === "YearEnd") || (!writeAccess && b.type === "PrivateYearEnd")) && b.year === year);
        return {
            value: year,
            text: exists ? `${year} (existing)` : year,
            disabled: exists,
        };
    });
};

export const getFutureYearOptions = (refData) => {
    const years = (refData.budgetYears || []).sort().reverse();
    return [...Array(10).keys()].map((i) => {
        return { value: years[0] + i, text: years[0] + i };
    });
};

export const isYearEnd = (analysisType) => analysisType === "YearEnd";
export const isPredictive = (analysisType) => analysisType === "Predictive";

export const getAnalysisOptions = (farm, excludedAnalysisIds = [], includeAnalysesWithUncompletedBlocks = false) => {
    const analyses = farm?.budgets
        ?.filter((b) => b.name && (includeAnalysesWithUncompletedBlocks || b.hasCompletedBlocks) && b.role === "Write" && !excludedAnalysisIds.includes(b.id))
        ?.map((b) => {
            const publication = b.publicationId && farm.publications.find((p) => p.id === b.publicationId);
            return { id: b.id, type: b.type, class: b.class, publishedTo: publication && publication.publishedTo, text: publication ? `${b.name} (${publication.publishedTo})` : b.name, name: b.name, year: b.year };
        });

    const groupedAnalyses = analyses?.map((analysis) => {
        return {
            ...analysis,
            groupIndex: getAnalysisGroupIndex(analysis),
            groupLabel: getAnalysisGroupLabel(analysis),
        };
    });

    const sortedAnalyses = groupedAnalyses?.sort((a, b) => {
        // Sort by groupIndex
        if (a.groupIndex > b.groupIndex) return 1;
        if (a.groupIndex < b.groupIndex) return -1;

        // Sort by year (if any)
        if (a.year && b.year) {
            if (a.year > b.year) return -1;
            if (a.year < b.year) return 1;
        }

        // Sort by name
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;

        return 0;
    });

    const options = sortedAnalyses?.map((a) => {
        return {
            groupIndex: getAnalysisGroupIndex(a),
            groupLabel: getAnalysisGroupLabel(a),
            value: a.id,
            text: a.text,
            type: a.type,
            name: a.name,
            publishedTo: a.publishedTo,
            year: a.year,
        };
    });
    return options;
};

export const getAnalysisDisplayName = (farm, analysisId) => {
    const analysis = farm.budgets.find((b) => b.id === analysisId);
    if (analysis) {
        return analysis.publishedTo ? `${analysis.name} (${analysis.publishedTo})` : analysis.name;
    }
};

export const getAnalysisGroupLabel = (analysis) => {
    if (analysis.class === "PublishedAnalysis") return "Publications";

    if (analysis.class === "WorkingCopy") return "Working copies";

    if (isYearEnd(analysis.type)) return "Year end analyses";

    if (analysis.type === "Predictive" && analysis.year) return "Plans";

    if (analysis.type === "Predictive" && !analysis.year) return "Predictive analyses";

    if (analysis.type === "PrivateYearEnd") return "Private year ends";

    if (analysis.type === "Scenario") return "Scenarios";

    return "Other analyses";
};

export const getAnalysisGroupIndex = (analysis) => {
    if (analysis.class === "PublishedAnalysis") return 2;

    if (analysis.class === "WorkingCopy") return 6;

    if (isYearEnd(analysis.type)) return 0;

    if (analysis.type === "PrivateYearEnd") return 1;

    if (analysis.type === "Predictive" && analysis.year) return 3;

    if (analysis.type === "Predictive" && !analysis.year) return 4;

    if (analysis.type === "Scenario") return 5;

    return 7;
};

export const getCopyOptions = (analysis) => {
    const copyOptions = {};
    if (analysis.snowfalls && analysis.snowfalls.length > 0) copyOptions.copySnowfall = true;

    if ((analysis.blocks || []).some((b) => b.soilTestId && analysis.soilTests.find((t) => t.id === b.soilTestId))) copyOptions.copySoilTests = true;

    if ((analysis.drainageSystems && analysis.drainageSystems.length > 0) || (analysis.wetlands && analysis.wetlands.length > 0)) copyOptions.copyDrainage = true;

    if ((analysis.blocks || []).some((b) => (b.pasture && b.pasture.pastureCategory) || b.fruit)) copyOptions.copyPastureFruit = true;

    if ((analysis.blocks || []).some((b) => b.type === "ProductiveCrop" && b.crops && b.crops.length > 0)) copyOptions.copyCrops = true;

    if ((analysis.blocks || []).some((b) => b.type === "FodderCrop")) copyOptions.copyFodderCrops = true;

    if (analysis.enterprises && analysis.enterprises.length > 0) copyOptions.copyAnimals = true;

    if (analysis.feedSupplements && analysis.feedSupplements.length > 0) copyOptions.copySupplements = true;

    if (analysis.fertiliser && analysis.fertiliser.length > 0) copyOptions.copyFertilisers = true;

    if (analysis.irrigators && analysis.irrigators.length > 0) copyOptions.copyIrrigators = true;

    if (Object.keys(analysis.effluentSystem || {}).length > 0 || Object.keys(analysis.outdoorPigEffluentSystem || {}).length > 0 || (analysis.structures || []).some((s) => Object.keys(s.effluentSystem || {}).length > 0)) copyOptions.copyEffluentApplications = true;

    if (analysis.ghg && Object.keys(analysis.ghg).length > 0) copyOptions.copyGHG = true;

    if (analysis.comments && analysis.comments.length > 0) copyOptions.copyComments = true;

    const hasTrees =
        (analysis.blocks || []).some((b) => b.type === "ProductiveFruit" && b.fruit) || //&& b.fruit.ageOfTrees_yrs
        (analysis.blocks || []).some((b) => b.type === "NonProductiveTreesAndScrub" && b.forests && b.forests.length > 0);
    if (hasTrees) {
        copyOptions.ageTrees = analysis.year ? true : false;
        copyOptions.ageTreesBy = 1;
    }

    return copyOptions;
};

export const getCopyOptionLabel = (key) => {
    switch (key) {
        case "copySnowfall":
            return "Snowfall";
        case "copySoilTests":
            return "Soil tests";
        case "copyDrainage":
            return "Drainage/unfenced wetlands";
        case "copyPastureFruit":
            return "Pasture/fruit";
        case "copyCrops":
            return "Crops";
        case "copyCropsToPreviousYearOnly":
            return "Copy reporting year to previous year";
        case "copyFodderCrops":
            return "Fodder crops";
        case "copyAnimals":
            return "Animals";
        case "copySupplements":
            return "Supplements";
        case "copyFertilisers":
            return "Fertiliser";
        case "copyIrrigators":
            return "Irrigation";
        case "copyEffluentApplications":
            return "Effluent applications";
        case "copyGHG":
            return "Greenhouse gas overrides";
        case "copyComments":
            return "Comments";
        case "ageTrees":
            return "Age forest and/or fruit trees";
        default:
            return null;
    }
};

export const getFileTypeOptions = (authContext) => {
    const options = [
        { value: "3rdparty", text: "3rd party data file", fileExtensions: ["json"] },
        { value: "ed", text: "OverseerEd analysis", fileExtensions: ["json"] },
    ];
    if (authContext.isSystemAdmin || process.env.REACT_APP_VERSION === "Donald") {
        options.push({ value: "legacy", text: "Legacy farm file (v6.2.3 or v6.3.0)", fileExtensions: ["xml"] });
        options.push({ value: "fm", text: "OverseerFm farm file", fileExtensions: ["json"] });
    }
    return options;
};

export const getFileTypeInfo = (fileTypeOptions, fileType) => {
    const seletedFileTypeOption = fileTypeOptions.find((option) => option.value === fileType);
    if (fileType === "legacy") {
        return (
            <>
                <h5 className="u-mt-0">{seletedFileTypeOption.text}</h5>
                <p>You can upload model version 6.2.3 or 6.3.0 farm files to create a new analysis for this farm.</p>
                <p className="u-textBold">If the file contains any of the following, it will be imported without these details and so will effect the results:</p>
                <ul className="disc u-mt-sm">
                    <li>Advanced soil properties for specifying immobilisation potential</li>
                    <li>Pasture quality and clover levels</li>
                    <li>Greenhouse gas settings - other farm inputs, capital emissions and unique greenhouse gas emission factors</li>
                    <li>As of v6.4.0 climate data will not be imported. It will be set based on location.</li>
                    <li>Fertiliser by form</li>
                    <li>Soil defined by soil series will be imported based on its soil order</li>
                </ul>
                <p>
                    <b>Note: </b>If you have defined animal mobs that are sold and have animals on farm at the end of the year a sales event will be created to sell these animals in June. This is to create consistent results; however if this should not be the case then edit the mob details and remove the event.
                </p>
                <p>
                    <b>Note: </b>Defoliation management will be required if your file contains crop blocks with pasture or pasture seed crops.
                </p>
                <p className="u-textBold">If any of this causes any issues for you, please contact support and explain what you require.</p>
            </>
        );
    } else if (fileType === "3rdparty") {
        return (
            <>
                <h5 className="u-mt-0">{seletedFileTypeOption.text}</h5>
                <p>You can upload a file generated by third party software by dragging and dropping the file onto the space below, or click on the space and select the file from your computer.</p>
                <p className="u-textBold">OverseerFM supports uploading the following information:</p>
                <ul className="disc u-mt-sm">
                    <li>Blocks and block drawings</li>
                    <li>Soils</li>
                    <li>Fertiliser and fertiliser applications</li>
                    <li>Animal enterprises and mobs</li>
                    <li>Crops</li>
                </ul>
                <p>
                    <b>Note:</b> Overseer is not responsible for the data contained in the file. Not all information required for a full analysis will be in the file, so additional information will need to be entered into OverseerFM directly.
                </p>
                <p>Please validate the data is consistent with the farm system being modelled after uploading. This may require going into the crop and animal screens to check.</p>
                <p className="u-textBold">You will need to contact the provider of the file if you have any issues with the information loaded into OverseerFM. This feature is only available to create new analyses. It is not possible to update the analysis with new data if anything changes, so please ensure the information is accurate before loading.</p>
            </>
        );
    } else if (fileType === "ed") {
        return (
            <>
                <h5 className="u-mt-0">{seletedFileTypeOption.text}</h5>
                <p>You can import an analysis that you created in the education version of Overseer. You will need a valid OverseerEd login. If your education account has expired you will need to contact your education provider for an extension</p>
            </>
        );
    }
};

export const copyAnalysis = (farm, analysis) => {
    const clone = utils.clone(analysis);
    clone.farmId = farm.id;
    clone.id = uuidv4();
    clone.sourceAnalysisId = analysis.sourceId;
    utils.setAnalysisType(farm, clone);
    clone.source = clone.copyType === "Previous" ? "CopyToPreviousYear" : "CopyExact";

    // Snowfall
    if (!clone.copyOptions.copySnowfall) {
        clone.snowfalls = [];
    }

    // Drainage
    if (!clone.copyOptions.copyDrainage) {
        clone.drainageSystems = [];
        clone.wetlands = [];
    }

    // Animals
    if (!clone.copyOptions.copyAnimals) {
        clone.enterprises = [];
        clone.feedSupplements.forEach((feedSupplement) => {
            feedSupplement.destinations = (feedSupplement.destinations || []).filter((d) => d.type !== "Enterprise" && d.type !== "Structure");
        });
        clone.structures = [];
        delete clone.effluentSystem;
        delete clone.animalDistribution;
    }

    if (!clone.copyOptions.copySoilTests) delete clone.soilTests;

    clone.blocks.forEach((block) => {
        // Soil tests
        if (!clone.copyOptions.copySoilTests) {
            block.soilTestId = null;
        }

        // Drainage
        if (!clone.copyOptions.copyDrainage) {
            block.drainageDetailsId = null;
        }

        // Pasture/fruit
        if (!clone.copyOptions.copyPastureFruit) {
            if (block.pasture) block.pasture = { topography: block.pasture.topography };

            if (block.fruit) block.fruit = null;
        }

        // Animals
        if (!clone.copyOptions.copyAnimals) {
            block.animals = [];
            block.relativeProductivity = null;
        }
    });

    // Crops
    if (!clone.copyOptions.copyCrops) {
        clone.blocks.forEach((b) => {
            if (b.type === "ProductiveCrop") {
                b.cropBlock = null;
                b.crops = null;
                b.croppingEvents = null;
                b.events = null;
            }
        });
    } else if (clone.copyType === "Previous") {
        if (clone.copyOptions.copyFertilisers) {
            fertUtils.updateApplicationModel(clone);

            const cropBlockIds = clone.blocks.filter((b) => b.type === "ProductiveCrop").map((b) => b.id);
            clone.fertiliser = (clone.fertiliser || []).reduce((ferts, fert) => {
                const isOrganicCompost = fert.material === "Organic" && fert.organicType === "CompostMulches";

                const newApps = fert.applications.reduce((apps, app) => {
                    const hasCropBlocks = app.blockIds.some((blockId) => cropBlockIds.includes(blockId));
                    const hasNonCropBlocks = app.blockIds.some((blockId) => !cropBlockIds.includes(blockId));
                    const hasCropBlocksOnly = hasCropBlocks && !hasNonCropBlocks;
                    const hasNonCropBlocksOnly = !hasCropBlocks && hasNonCropBlocks;

                    if (hasNonCropBlocksOnly) {
                        apps.push(app);
                    } else if (hasCropBlocksOnly) {
                        // Copy "reporting year" applications to "previous year".
                        const months = app.months.filter((m) => m.reportingYear === true).map((m) => ({ ...m, reportingYear: false }));
                        if (months.length > 0) {
                            apps.push({
                                ...app,
                                months,
                            });
                        }
                    } else if (hasCropBlocks && hasNonCropBlocks) {
                        // This fert app is for both crop and non-crop blocks.
                        // We need to split the fert app to separate the crop blocks from the non-crop blocks.
                        const [calculatedAmount] = fertUtils.getApplicationAmount(app, analysis);
                        const amount = app.unit === "kgha" ? app.amount : isOrganicCompost ? undefined : calculatedAmount;
                        const unit = isOrganicCompost ? app.unit : "kgha";
                        // Crop blocks first.
                        const months = app.months.filter((m) => m.reportingYear === true).map((m) => ({ ...m, reportingYear: false }));
                        if (months.length > 0) {
                            apps.push({
                                ...app,
                                id: uuidv4(), // Create a new fert app
                                blockIds: app.blockIds.filter((blockId) => cropBlockIds.includes(blockId)),
                                amount,
                                unit,
                                months,
                            });
                        }
                        // Now non-crop blocks (we'll re-use the existing fert app id)
                        apps.push({
                            ...app,
                            blockIds: app.blockIds.filter((blockId) => !cropBlockIds.includes(blockId)),
                            amount,
                            unit,
                        });
                    }

                    return apps;
                }, []);
                if (newApps.length > 0) {
                    ferts.push({ ...fert, applications: newApps });
                }
                return ferts;
            }, []);
        }

        // Copy source crops from "reporting year" to "previous year".
        clone.blocks.forEach((block) => {
            if (block.type === "ProductiveCrop") {
                block.crops = (block.crops || []).reduce((results, crop) => {
                    var sown = findCropEvent(crop, "Cropsown");
                    if (sown && sown.reportingYear) {
                        crop.events.forEach((e) => {
                            e.reportingYear = false;
                        });
                        if (crop.thermalTimeEndReason !== "Ongoing") {
                            crop.thermalTimeEndIndex -= 12;
                        }
                        results.push(crop);
                    }
                    return results;
                }, []);
                block.croppingEvents = (block.croppingEvents || []).reduce((results, event) => {
                    if (event.reportingYear) {
                        event.reportingYear = false;
                        results.push(event);
                    }
                    return results;
                }, []);
                block.cropGrowthCurves = (block.cropGrowthCurves || []).reduce((results, curve) => {
                    if (curve.assessmentYearIndex === 1) {
                        curve.assessmentYearIndex = 0;
                        results.push(curve);
                    }
                    return results;
                }, []);
                const { cropBlock = {} } = block;
                delete cropBlock.yearsInPasture;
                delete cropBlock.preCrop;
                delete cropBlock.animalGrazing;
                cropBlock.supplementsRemoved = false;

                if (clone.copyOptions.copyIrrigators) {
                    // Irrigators
                    clone.irrigators = (clone.irrigators || []).reduce((results, irrigator) => {
                        irrigator.applications = (irrigator.applications || []).reduce((apps, app) => {
                            if ((app.blockIds || []).includes(block.id)) {
                                app.months = (app.months || []).reduce((months, m) => {
                                    if (m.reportingYear === true) {
                                        months.push({ ...m, reportingYear: false });
                                    }
                                    return months;
                                }, []);
                                if (app.months.length > 0) {
                                    apps.push(app);
                                }
                            } else {
                                apps.push(app);
                            }
                            return apps;
                        }, []);
                        if (irrigator.applications.length > 0) {
                            results.push(irrigator);
                        }
                        return results;
                    }, []);
                }
            }
        });
    }

    // Fodder crops
    if (!clone.copyOptions.copyFodderCrops) {
        clone.blocks
            .filter((b) => b.type === "FodderCrop")
            .forEach((b) => {
                utils.deleteOrphanBlockRef(clone, b.id);
            });
        clone.blocks = clone.blocks.filter((b) => b.type !== "FodderCrop");
    }

    // Supplements
    if (!clone.copyOptions.copySupplements) {
        clone.feedSupplements = [];
    }

    // Fertilisers
    if (!clone.copyOptions.copyFertilisers) {
        clone.fertiliser = [];
        clone.nitrateInhibitors = [];
    }

    // Irrigators
    if (!clone.copyOptions.copyIrrigators) {
        clone.irrigators = [];
    }

    // Effluent applications
    if (!clone.copyOptions.copyEffluentApplications) {
        // Dairy effluent system applications
        if (clone.effluentSystem) {
            if (clone.effluentSystem.solidManagement) {
                clone.effluentSystem.solidManagement.solidApplications = [];
                clone.effluentSystem.solidManagement.pondApplications = [];
            }
            if (clone.effluentSystem.liquidManagement) {
                clone.effluentSystem.liquidManagement.applications = [];
            }
        }
        // Structure level effluent applications
        (clone.structures || []).forEach((structure) => {
            if (structure.effluentSystem) {
                if (structure.effluentSystem.solidManagement) {
                    structure.effluentSystem.solidManagement.solidApplications = [];
                    structure.effluentSystem.solidManagement.pondApplications = [];
                }
                if (structure.effluentSystem.liquidManagement) {
                    structure.effluentSystem.liquidManagement.applications = [];
                }
            }
        });
        // Outdoor pig effluent system applications
        if (clone.outdoorPigEffluentSystem) {
            if (clone.outdoorPigEffluentSystem.liquidEffluentApplications) {
                clone.outdoorPigEffluentSystem.liquidEffluentApplications = [];
            }
            if (clone.outdoorPigEffluentSystem.pondSolidApplications) {
                clone.outdoorPigEffluentSystem.pondSolidApplications = [];
            }
            if (clone.outdoorPigEffluentSystem.strawCompostApplications) {
                clone.outdoorPigEffluentSystem.strawCompostApplications = [];
            }
        }
    }

    // GHG
    if (!clone.copyOptions.copyGHG) {
        clone.ghg = {};
    }

    // Comments
    if (!clone.copyOptions.copyComments) {
        clone.comments = [];
    }

    if (clone.copyOptions.ageTrees) {
        const { ageTreesBy = 1 } = clone.copyOptions;
        const numYears = Number(ageTreesBy);
        const modYears = isNaN(numYears) ? 1 : numYears > 10 ? 10 : numYears < 1 ? 1 : numYears;

        clone.blocks.forEach((block) => {
            if (block.type === "ProductiveFruit") {
                const { fruit = {} } = block;
                fruit.ageOfTrees_yrs += modYears;
                if (fruit.ageOfTrees_yrs < 1) fruit.ageOfTrees_yrs = 1;
            } else if (block.type === "NonProductiveTreesAndScrub") {
                const { forests = [] } = block;
                forests.forEach((f) => {
                    if (f.isHarvested) {
                        f.numberOfYearsSinceHarvested += modYears;
                    } else {
                        f.forestAge += modYears;
                    }
                });
            }
        });
    }

    // Do not copy version number or dataset id.
    delete clone.version;
    delete clone.datasetId;
    delete clone.datasetName;

    return clone;
};

export function getFarmDisplayName(farm) {
    if (farm.supplierNumber && farm.supplierNumber.length > 0) {
        return `${farm.name} (${farm.supplierNumber})`;
    } else {
        return farm.name;
    }
}

export function isPublishingToMPIWholeFarmDataProject(publishToId) {
    return publishToId === "b935c5af-5237-4a5a-b3cc-7a6a36372a1f";
}
