import React from "react";

const CardHeader = ({ id, titleIcon, title, subTitle, hasError, dataWidth, collapsed, onHeaderClick, onTitleClick, children, name, markerIcon }) => {
    const _onClick = (e) => {
        const titleClicked = e.target.dataset.target === "title";
        const headerClicked = e.target.dataset.target === undefined;
        if (onTitleClick && (titleClicked || !onHeaderClick)) {
            onTitleClick();
        } else if (headerClicked && onHeaderClick) {
            onHeaderClick();
        }
    };

    const clickable = onHeaderClick || onTitleClick;
    const selected = collapsed === false;

    return (
        <ul id={`card-toggle-${id}`} className={`DataWidthTable FarmTable ${clickable ? "hover" : ""} ${selected ? "selected" : ""}`}>
            {title && (
                <li data-width={dataWidth} onClick={_onClick} className={`FarmTable-title ${clickable ? "u-link" : ""} `}>
                    <div className="FarmTitle">
                        {hasError && <i className="icon icon-alert u-textError" />}
                        {titleIcon && <img className="FarmTitle-icon" src={titleIcon} alt={title} />}
                        <div className="FarmTitle-heading">
                            <span className="FarmTitle-link">
                                <span id={`card-title-${id}`} name={name} className={`FarmTitle-name ${onTitleClick ? "a" : ""}`} data-target="title" title={title}>
                                    {title}
                                    {markerIcon}
                                </span>
                            </span>
                            {subTitle && <div className="FarmTitle-sub">{subTitle}</div>}
                        </div>
                    </div>
                </li>
            )}
            {children}
        </ul>
    );
};
export default CardHeader;
