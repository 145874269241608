import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import { modalType } from "components/ModalConductor";
import * as _utils from "../_utils";
import LivestockEventList from "./LivestockEventList";
import ActionLink from "components/ActionLink";
import Button from "components/Button/Button";
import { useConfirm, useCloseReduxModal, useOnline, useOpenReduxModal, useRefData } from "common/hooks";

export default function LivestockList({ farm, analysis, livestocks, enterpriseType, saved, addEvent, deleted, showLivestockEvents, messages, enterprise }) {
    const online = useOnline();
    const refData = useRefData();
    const openReduxModal = useOpenReduxModal();
    const closeReduxModal = useCloseReduxModal();
    const confirm = useConfirm();

    const _onAdded = (livestock) => {
        livestock.id = uuidv4();
        saved(livestock);
        closeReduxModal();
    };

    const sortEvents = (events) => {
        events.sort((a, b) => {
            const aDate = utils.parseDate(a.eventDate, "DD MMMM");
            const bDate = utils.parseDate(b.eventDate, "DD MMMM");
            if (aDate.month() < 6) aDate.add(1, "year");
            if (bDate.month() < 6) bDate.add(1, "year");
            const sameDay = aDate.day === bDate.day && aDate.month === bDate.month;
            if (sameDay && ["Starting", "Weaning"].includes(b.eventType)) {
                return 1;
            }
            return bDate.isBefore(aDate) ? 1 : -1;
        });
    };

    const _addLivestock = (e) => {
        e.preventDefault();
        const livestock = {
            events: [],
            enterpriseType,
        };
        if (enterpriseType === "DairyGoat") {
            livestock.dairyGoatBreed = "Saanen";
        }
        openReduxModal(modalType.LIVESTOCK_DETAILS, _onAdded, { livestock, livestocks, addEvent, analysis, farm, enterprise });
    };

    const _onEdited = (livestock) => {
        saved(livestock);
        closeReduxModal();
    };

    const _editLivestock = (livestock) => (e) => {
        e.preventDefault();
        if (e.target.title === "Delete") return;
        const { events = [] } = livestock;
        sortEvents(events);
        openReduxModal(modalType.LIVESTOCK_DETAILS, _onEdited, { livestock, livestocks, addEvent, analysis, farm, enterprise });
    };

    const _deleteLivestock = (livestock) => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete this livestock?`, () => {
            deleted(livestock)
        });
    };

    const _totals = _getTotals(livestocks);

    const addLivestockAction = (
        <span id="add-livestock-link" onClick={_addLivestock} className="IconLink--arrow-plus u-link">
            Add livestock
        </span>
    );

    return (
        <>
            <Tile title="Livestock" actions={addLivestockAction} tertiary>
                <TileBody>
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="md-20 xl-25">Name</th>
                                    <th data-width="md-10 xl-12.5">Class</th>
                                    <th data-width="md-10 xl-10">Breed</th>
                                    <th data-width="md-5 xl-5">Age (months)</th>
                                    <th className="u-textCenter">Jul</th>
                                    <th className="u-textCenter">Aug</th>
                                    <th className="u-textCenter">Sep</th>
                                    <th className="u-textCenter">Oct</th>
                                    <th className="u-textCenter">Nov</th>
                                    <th className="u-textCenter">Dec</th>
                                    <th className="u-textCenter">Jan</th>
                                    <th className="u-textCenter">Feb</th>
                                    <th className="u-textCenter">Mar</th>
                                    <th className="u-textCenter">Apr</th>
                                    <th className="u-textCenter">May</th>
                                    <th className="u-textCenter">Jun</th>
                                    {livestocks.length > 0 && <th className="th--shrink"></th>}
                                </tr>
                            </thead>
                            {livestocks.length === 0 && (
                                <tbody>
                                    <tr>
                                        <td colSpan="16">
                                            <div className="Tile-body">
                                                <div className="Tile-body-message">
                                                    <p className="h4 u-mt-0">You do not have any livestock</p>
                                                    <Button id="add-livestock-button" onClick={_addLivestock} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                        Add livestock
                                                    </Button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                            {livestocks.length > 0 && (
                                <tbody>
                                    {livestocks.map((livestock, index) => {
                                        const hasErrors = online && messages.some((m) => m.entityType === "Enterprise" && m.data && (m.data.mobIds || []).some((id) => id === livestock.id) && m.severity === "Error");
                                        const colour = utils.getColour(index);
                                        const livestockDetails = _utils.getLivestockDetails(livestock, refData);
                                        const start = livestock.events.find((e) => e.eventType === "Starting" || e.month === "July");
                                        const age = start && start.ageInMonths;
                                        //const displayName = _utils.getLivestockDisplayName(livestock, refData)
                                        return (
                                            <tr key={index} className={`Table-tr--${colour} u-link hover`} onClick={_editLivestock(livestock)}>
                                                <td>
                                                    <span id={`edit-livestock-link-${index}`} className="a">
                                                        {hasErrors && <i className="icon icon-alert u-textError" />}
                                                        {livestock.name}
                                                    </span>
                                                </td>
                                                <td>{livestockDetails.class}</td>
                                                <td>{livestockDetails.breed}</td>
                                                <td className="u-textCenter">{age ? age : "-"}</td>
                                                {livestock.stockCounts.map((stockCount) => (
                                                    <td key={stockCount.month} className={`u-textCenter ${stockCount.stockCount < 0 ? "u-textError" : ""}`}>
                                                        {stockCount.stockCount === 0 ? "-" : stockCount.stockCount}
                                                    </td>
                                                ))}
                                                <td>
                                                    <ActionLink id={`delete-livestock-action-${index}`} onClick={_deleteLivestock(livestock)} className="IconLink--trash" title="Delete">
                                                        <span></span>
                                                    </ActionLink>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            )}
                            {livestocks.length > 0 && (
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>
                                            <span className="u-floatRight">
                                                <h4 className="u-mt-0">Totals</h4>
                                            </span>
                                        </th>
                                        {_totals.map((total) => {
                                            return (
                                                <th key={total.month} className={`u-textCenter ${total.stockCount < 0 ? "u-textError" : ""}`}>
                                                    <h4 className="u-mt-0">{total.stockCount === 0 ? "-" : total.stockCount}</h4>
                                                </th>
                                            );
                                        })}
                                        <th></th>
                                    </tr>
                                </tfoot>
                            )}
                        </table>
                    </div>
                    {showLivestockEvents && <LivestockEventList enterpriseType={enterpriseType} livestocks={livestocks} refData={refData} />}
                </TileBody>
            </Tile>
        </>
    )
}

const _getTotals = (livestocks) => {
    const totals = _utils.DEFAULT_STOCK_COUNTS.map((sc) => {
        return { ...sc };
    });

    (livestocks || []).forEach((livestock) => {
        livestock.stockCounts.forEach((item, index) => {
            totals[index].stockCount += item.stockCount;
        });
    });

    return totals;
}
