import { useEffect, useState } from "react";
import * as utils from "common/utils";
import * as geoJsonUtils from "common/geoJsonUtils";
import { useGoogleApi } from "components/Map/hooks";
import MapLabel from "components/Map/MapLabel";
import { useRefData } from "common/hooks";

const MAX_ZOOM = 15;

export default function MapBlockLabels({ mapData, mapId, geoJson, blocks }) {
    const google = useGoogleApi();
    const refData = useRefData();
    const [labels, setLabels] = useState();
    const [hidden, setHidden] = useState(false);

    // Add 'Hide/show block labels' control.
    useEffect(() => {
        if (google && mapData && mapId && geoJson) {
            if (geoJson.features.some((f) => f.properties.blockId)) {
                const map = mapData.getMap();

                const position = google.maps.ControlPosition.TOP_RIGHT;
                const controls = map.controls[position].getArray();
                const id = `map-toggle-block-labels-${mapId}`;
                let div = controls.find((ctl) => ctl.id === id);
                if (!div) {
                    div = document.createElement("div");
                    div.id = id;
                    div.className = "mapButton mapButton--right";
                    div.addEventListener("click", () => setHidden((prevState) => !prevState));
                    map.controls[position].push(div);
                }
                div.title = hidden ? "Show Block Labels" : "Hide Block Labels";
                const blockLayerColor = geoJsonUtils.getMapLayerColor("Block");
                const borderColor = hidden ? "#fff" : blockLayerColor;
                div.innerHTML = `<i class="icon icon-tag" style="border: 3px solid ${borderColor}; border-radius: 3px;"></i>`;

                // Set hide by zoom
                map.addListener("zoom_changed", () => {
                    setHidden((prevState) => {
                        if (map.getZoom() < MAX_ZOOM) return true;
                        return prevState;
                    });
                });
            }
        }
    }, [google, mapData, mapId, geoJson, hidden]);

    // Set lablels data.
    useEffect(() => {
        if (blocks && geoJson && refData) {
            const blockFeatures = geoJson.features.filter((f) => f.properties.blockId);
            const labelData = blockFeatures.reduce((results, feature) => {
                const block = blocks.find((b) => b.id === feature.properties.blockId);
                if (block) {
                    const position = geoJsonUtils.center(feature);
                    const blockDescription = utils.getBlockTypeText(refData, block);
                    const innerHTML = `<div class="u-textBold">${block.name}${feature.properties.imported ? " (auto assigned)" : ""}</div><div>${blockDescription}</div>`;
                    results.push({ id: feature.id, position, innerHTML });
                }
                return results;
            }, []);

            setLabels(labelData);
        }
    }, [blocks, geoJson, refData]);

    if (!mapData || !labels) return null;

    return (
        <>
            {labels.map((label) => (
                <MapLabel key={label.id} map={mapData.getMap()} {...label} hidden={hidden} />
            ))}
        </>
    );
}
