import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { updateMeasured, getMeasured } from "./_actions";
import Page from "components/Page";
import PageBody from "components/PageBody";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import { Panel, PanelBody } from "components/Panel";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useOnline } from "common/hooks";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";

const drainageHeaders = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const otherHeaders = ["N/Ha", "Soil N", "Plant N", "Root N", "Stover N", "Soil moisture", "Soil moisture 600"];

const calendarMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const otherFields = ["nLossPerHa", "initialSoilN", "initialPlantN", "initialResidualRootN", "initialResidualStoverN", "initialSoilMoisture", "initialSoilMoisture600"];

export default function MeasuredValues() {
    const [rows, setRows] = useState({ rows: [] });
    const dispatch = useDispatch();
    const dataset = useSelector((state) => state.dataset?.currentDataset);
    const { datasetId } = useParams();
    const online = useOnline();
    const referrer = `/app/datasets/` + datasetId;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        datasetId && dispatch(getMeasured(datasetId));
    }, [datasetId, dispatch]);

    useEffect(() => {
        let measuredRows = [];
        if (!loaded && dataset?.measuredValues) {
            dataset.measuredValues.forEach((b) => {
                let row = { analysisId: b.analysisId, farmName: b.farmName, analysisName: b.analysisName, blockId: b.blockId, blockName: b.blockName };

                if (b.measurements && b.measurements.drainageMM && b.measurements.drainageMM.length > 0) {
                    b.measurements.drainageMM.forEach((d) => (row[d.month] = d.value));
                }
                if (b.measurements) {
                    /* eslint-disable no-unused-vars */
                    for (const k of otherFields) {
                        if (b.measurements[k]) row[k] = b.measurements[k];
                    }
                }
                measuredRows.push(row);
            });
            setLoaded(true);
            setRows({ rows: measuredRows });
        }
    }, [dataset, loaded]);

    const updateMeasuredResults = async (values) => {
        console.log("values", values);
        const blocks = values?.rows?.map((b) => {
            let hasDrainage = false;
            const drainage = calendarMonths.map((c, i) => {
                const value = isNaN(b[c]) ? 0 : Number(b[c]);
                hasDrainage |= value > 0;
                return { month: i + 1, value };
            });

            const block = { analysisId: b.analysisId, analysisName: b.analysisName, blockId: b.blockId, blockName: b.blockName, measurements: { drainageMM: hasDrainage ? drainage : undefined } };
            /* eslint-disable no-unused-vars */
            for (const k of otherFields) {
                if (!isNaN(b[k])) block.measurements[k] = Number(b[k]);
            }
            return block;
        });
        if (blocks?.length > 0) {
            dispatch(updateMeasured(datasetId, blocks, `/app/datasets/` + datasetId));
        }
    };

    return (
        <Page>
            <PageBody>
                <Tile title={`Dataset: ${dataset?.details?.name}`} referrer={referrer}>
                    <Form initialValues={rows} onSubmit={updateMeasuredResults} mutators={{ ...arrayMutators }}>
                        {({ form, handleSubmit, pristine, submitting, submitError, dirtySinceLastSubmit, values }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <TileBody>
                                        <div className="u-mt-md">
                                            <Panel title="Measured drainage" skyBlue>
                                                <PanelBody>
                                                    <div className="Table Table-Grid">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "300px" }}>Block ID</th>
                                                                    <th style={{ width: "220px" }}>Farm</th>
                                                                    <th style={{ width: "200px" }}>Analysis</th>
                                                                    <th style={{ width: "200px" }}>Block</th>
                                                                    {drainageHeaders.map((h, i) => (
                                                                        <th key={h}>{calendarMonths[i]}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray name="rows">
                                                                    {({ fields }) => {
                                                                        return fields.map((field, index) => {
                                                                            const row = fields.value[index];
                                                                            return (
                                                                                <tr key={`${field}.${index}`}>
                                                                                    <td>{row.blockId}</td>
                                                                                    <td>{row.farmName}</td>
                                                                                    <td>{row.analysisName}</td>
                                                                                    <td>{row.blockName}</td>
                                                                                    {calendarMonths.map((m) => (
                                                                                        <td key={m}>
                                                                                            <Field name={`${field}.${m}`} component="input" type="number" min={0} max={999} step="any" style={{ textAlign: "right", width: "75px" }} className={`Input Input--block`} />
                                                                                        </td>
                                                                                    ))}
                                                                                </tr>
                                                                            );
                                                                        });
                                                                    }}
                                                                </FieldArray>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </PanelBody>
                                            </Panel>
                                            <Panel title="Other measured" skyBlue>
                                                <PanelBody>
                                                    <div className="Table Table-Grid">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "300px" }}>Block ID</th>
                                                                    <th style={{ width: "220px" }}>Farm</th>
                                                                    <th style={{ width: "200px" }}>Analysis</th>
                                                                    <th style={{ width: "200px" }}>Block</th>
                                                                    {otherFields.map((h, i) => (
                                                                        <th key={h}>{otherHeaders[i]}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray name="rows">
                                                                    {({ fields }) => {
                                                                        return fields.map((field, index) => {
                                                                            const row = fields.value[index];
                                                                            return (
                                                                                <tr key={`${field}.${index}`}>
                                                                                    <td>{row.blockId}</td>
                                                                                    <td>{row.farmName}</td>
                                                                                    <td>{row.analysisName}</td>
                                                                                    <td>{row.blockName}</td>
                                                                                    {otherFields.map((m) => (
                                                                                        <td key={m}>
                                                                                            <Field name={`${field}.${m}`} component="input" type="number" min={0} max={999999} step="any" style={{ textAlign: "right", width: "105px" }} className={`Input Input--block`} />
                                                                                        </td>
                                                                                    ))}
                                                                                </tr>
                                                                            );
                                                                        });
                                                                    }}
                                                                </FieldArray>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </PanelBody>
                                            </Panel>

                                            <div className="Modal-footer">
                                                <div className="ButtonBar">
                                                    <div className="ButtonBar-left">
                                                        <Link to={referrer} className="Button Button--secondary">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                    <div className="ButtonBar-right">
                                                        <button id="confirm-button" onClick={updateMeasuredResults} disabled={!online || pristine} className="Button">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TileBody>
                                </form>
                            );
                        }}
                    </Form>
                </Tile>
            </PageBody>
        </Page>
    );
}
