import React from "react";
import { Bar } from "react-chartjs-2";
import { GridCell } from "components/Grid";
import WidgetContainer from "components/WidgetContainer";
import * as utils from "common/utils";
import Alert from "components/Alert";

export default function HistoryGraphs({ loading, error, data, isPublicationReporting }) {
    const [showTotal, setShowTotal] = React.useState([]);
    if (loading) return null;
    if (!data) return <div>No Data</div>;

    const evalCharts = (trendData) => {
        const years = trendData && Object.keys(trendData);
        const types = years && years.length > 0 && trendData[years[0]].map((s) => ({ type: s.type, colour: s.colour, name: s.name, units: s.units, allowTotals: s.allowTotals, unitTotal: s.unitTotal }));
        const c =
            types &&
            types.map((typeInfo) => {
                const unit = showTotal.includes(typeInfo.type) ? typeInfo.unitTotal : typeInfo.units;
                return {
                    name: typeInfo.name,
                    type: typeInfo.type,
                    units: unit,
                    allowTotals: typeInfo.allowTotals,
                    options: {
                        scales: {
                            x: { display: true },
                            y: { ticks: { beginAtZero: true }, display: true, title: { display: true, text: unit } },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    label: (tooltipItem) => {
                                        const { dataset, dataIndex } = tooltipItem;
                                        return dataset.counts[dataIndex] ? `${dataset.counts[dataIndex]} farms` : "";
                                    },
                                    afterLabel: (tooltipItem) => {
                                        return `${showTotal.includes(typeInfo.type) ? "TOTAL - " : ""} ${tooltipItem.raw.toLocaleString()} ${unit}`;
                                    },
                                },
                            },
                        },
                    },
                    data: {
                        datasets: [
                            {
                                data: years.map((year) => getData(typeInfo.type, year, trendData)),
                                counts: years.map((year) => getCount(typeInfo.type, year, trendData)),
                                backgroundColor: typeInfo.colour,
                                borderColor: typeInfo.colour,
                            },
                        ],
                        labels: years,
                    },
                };
            });
        return c;
    };

    const getData = (type, year, trendData) => {
        const trend = trendData && trendData[year] && trendData[year].find((t) => t.type === type);
        return trend && utils.round(showTotal.includes(type) ? trend.totalValue : trend.perUnit, 1);
    };

    const getCount = (type, year, trendData) => {
        const trend = trendData && trendData[year] && trendData[year].find((t) => t.type === type);
        return trend && trend.count;
    };

    const charts = evalCharts(data);

    const message = isPublicationReporting ? <div>The trends graphs show the average results for the selected group of farms over time based on the group's published year end analyses. Each graph shows year ending results (y-axis) for the year shown on the x-axis. Please note: each year may not include the same group of farms if individual farms have not published a year end analysis for that year.</div> : <div>The trends graphs show the average results for the selected group of farms over time based on the group's year end analyses. Each graph shows year ending results (y-axis) for the year shown on the x-axis. Please note: each year may not include the same group of farms if individual farms do not have an analysis for that year.</div>;

    if (!charts)
        return (
            <div className="HeroPanel">
                <div className="Tile-body-message">
                    <h3 className="u-mt-md">There are no results for this period</h3>
                </div>
            </div>
        );
    return (
        <>
            <div className="u-ml-sm u-mr-sm">
                <Alert type="info" text={message} />
            </div>

            {charts.map((chartData) => (
                <WidgetContainer id="trends" loading={loading} error={error} className="u-width1of2 Farm-trends">
                    <GridCell className={`FarmDashboard-cell`}>
                        <div className="Widget">
                            <div className="u-flex u-flexJustifyBetween">
                                <div className="HeroPanel-heading">
                                    {chartData.name} ({chartData.units})
                                </div>
                                <div>
                                    {chartData.allowTotals && (
                                        <div className="Field u-mt-xs">
                                            <div className="Radio_group Radio_group--inline">
                                                <label className="Radio u-mr-md">
                                                    <input checked={showTotal.includes(chartData.type)} onClick={() => setShowTotal([...showTotal, chartData.type])} className="Radio-input" type="radio" />
                                                    <span className="Radio-label">Total</span>
                                                </label>
                                                <label className="Radio">
                                                    <input checked={!showTotal.includes(chartData.type)} onClick={() => setShowTotal(showTotal.filter((a) => a !== chartData.type))} className="Radio-input" type="radio" />
                                                    <span className="Radio-label">Average {chartData.units}</span>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="Widget-content">
                                <Bar data={chartData.data} height={100} options={chartData.options} legend={{ display: false }} />
                            </div>
                        </div>
                    </GridCell>
                </WidgetContainer>
            ))}
        </>
    );
}
