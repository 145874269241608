import { useQueryClient, useMutation } from "@tanstack/react-query";
import { FORM_ERROR } from "final-form";
import { Form, Field } from "react-final-form";
import { useNavigate, useRefData, useModal } from "common/hooks";
import { httpClient } from "common/httpClient";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import { HiddenField, SelectField, TextareaField, TextField } from "components/FormFields";

export default function RequestFarmAccessModal({ accessRequest, close }) {
    const sendFarmAccessRequestAsync = useSendFarmAccessRequestAsync();
    const navigate = useNavigate();
    const roleOptions = useRoleOptions(accessRequest?.currentRole);

    const validate = (values) => {
        const validation = {};

        validation.role = FormUtils.validators.required(values.role);

        validation.comments = FormUtils.validators.required(values.comments);
        validation.comments = validation.comments || FormUtils.validators.maxLength(255)(values.comments);

        return validation;
    };

    const submitAsync = async (values) => {
        try {
            await sendFarmAccessRequestAsync(values);
            close();
            navigate("/");
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    };

    const roleInfo = (
        <ul>
            <li>
                <b>Owner</b> - view and manage all farm account access, edit all farm account data
            </li>
            <li>
                <b>Admin</b> - view and manage only farm account access granted by you, edit all farm account data
            </li>
            <li>
                <b>Write</b> - edit all farm account data
            </li>
        </ul>
    );

    return (
        <Form initialValues={accessRequest} validate={validate} onSubmit={submitAsync}>
            {({ handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Request farm access" close={close} submitting={submitting} fluid instructionText="By requesting farm access you will be granted guest access to this farm where you can create and publish analysis generated by your organisation. To view year end analysis created by other organisations the farm owner will need to accept this farm access request.">
                            <ModalBody error={submitError}>
                                <Field name="farmId" component={HiddenField} />
                                <Grid>
                                    <GridCell className="u-width1of2">
                                        <Field name="farmName" label="Farm name" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-width1of2">
                                        <Field name="address" label="Farm address" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-width1of2">
                                        <Field name="ownershipStatus" label="Ownership" readOnly component={TextField} />
                                    </GridCell>
                                </Grid>
                                <Grid>
                                    <GridCell>
                                        <Field name="role" label="Access" placeholder="Select your required access" info={roleInfo} options={roleOptions} required component={SelectField} />
                                    </GridCell>
                                    <GridCell>
                                        <Field name="comments" label="Reason for access request" placeholder="Enter your reason for access" required component={TextareaField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting} disabled={submitting}>
                                        Send
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useRequestFarmAccessModal(farm) {
    const [modal, openModal] = useModal(RequestFarmAccessModal);

    const openRequestFarmAccessModal = () => {
        const modalProps = {
            accessRequest: {
                farmId: farm.id,
                farmName: farm.name,
                address: farm.address,
                ownershipStatus: farm.ownershipStatus,
                currentRole: farm.role,
            }
        };
        openModal(modalProps);
    };

    return [modal, openRequestFarmAccessModal];
}

function useSendFarmAccessRequestAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (accessRequest) => {
            try {
                await httpClient.post(`farms/${accessRequest.farmId}/access`, accessRequest);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        }
    });

    return (accessRequest) => mutation.mutateAsync(accessRequest);
}

function useRoleOptions(currentRole) {
    const refData = useRefData();

    const availableRoles = [];
    switch (currentRole) {
        case "Admin":
            availableRoles.push("Owner");
            break;
        case "Write":
            availableRoles.push("Owner");
            availableRoles.push("Admin");
            break;
        case "Owner":
            return null;
        default:
            availableRoles.push("Owner");
            availableRoles.push("Admin");
            availableRoles.push("Write");
    }

    const options = (refData.farmRoles || []).filter((r) => availableRoles.includes(r.value) && r.value !== currentRole);
    return options;
}
