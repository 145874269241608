import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useRefData, useModal } from "common/hooks";
import * as FormUtils from "common/FormUtils";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import { CheckboxField, SelectField, WholeNumberField } from "components/FormFields";
import BlockSelector from "components/new/BlockSelector";
import MonthSelector from "./MonthSelector";

function OutdoorPigLiquidApplicationModal({ viewModel, onSubmit, close }) {
    const refData = useRefData();

    const doSelectedBlocksRequireMonths = (blocks, selectedBlockIds) => {
        const blockIdsRequiringMonths = (blocks || []).filter((b) => ["ProductiveCrop", "FodderCrop"].includes(b.type)).map((b) => b.id);
        return (selectedBlockIds || []).some((blockId) => blockIdsRequiringMonths.some((id) => id === blockId));
    }

    const doSelectedBlocksRequireArea = (blocks, selectedBlockIds) => {
        const blockIdsRequiringArea = (blocks || []).filter((b) => ["ProductivePasture"].includes(b.type) && b.runoffCharacteristics).map((b) => b.id);
        return (selectedBlockIds || []).some((blockId) => blockIdsRequiringArea.some((id) => id === blockId));
    }

    const validate = (formValues) => {
        const validation = {};

        validation.applicationDepth = FormUtils.validators.required(formValues.applicationDepth);

        const showArea = doSelectedBlocksRequireArea(formValues.availableBlocks, formValues.blockIds);
        if (showArea) {
            validation.areaEffluent = FormUtils.validators.required(formValues.areaEffluent);
            validation.areaEffluent = validation.areaEffluent || FormUtils.validators.range(1, 100)(formValues.areaEffluent);
        }

        return validation;
    }

    const submitAsync = async (formValues) => {
        delete formValues.availableBlocks;
        if (onSubmit) {
            await onSubmit(formValues);
        }
        close();
    }

    return (
        <Form initialValues={viewModel} mutators={{ ...arrayMutators }} validate={validate} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting }) => {
                const showMonths = viewModel.monthsAreRequired || doSelectedBlocksRequireMonths(viewModel.availableBlocks, values.blockIds);
                const showArea = doSelectedBlocksRequireArea(viewModel.availableBlocks, values.blockIds);

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Liquid effluent application" skinny fluid close={close}>
                            <ModalBody>
                                <Grid>
                                    <Field name="id" type="hidden" component="input" />
                                    <GridCell className="u-width4of5">
                                        <Field name="blockIds" availableBlocks={viewModel.availableBlocks} component={BlockSelector} />
                                    </GridCell>
                                </Grid>
                                <Grid title="Application">
                                    <GridCell className="u-width1of3">
                                        <Field name="applicationDepth" label="Application depth" placeholder="Select an application depth" options={refData.effluentAppRates} required component={SelectField} />
                                    </GridCell>
                                    {showArea && (
                                        <GridCell className="u-width1of3">
                                            <Field name="areaEffluent" label="Percentage of block area" uom="%" placeholder="0" required component={WholeNumberField} />
                                        </GridCell>
                                    )}
                                    <GridCell>
                                        <Field name="activeManagement" label="Actively managed" component={CheckboxField} />
                                    </GridCell>
                                </Grid>
                                {showMonths && (
                                    <Grid title="Months">
                                        <GridCell>
                                            <MonthSelector form={form} monthsFieldName="months" />
                                        </GridCell>
                                    </Grid>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit waiting={submitting}>
                                        Done
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useOutdoorPigLiquidApplicationModal() {
    const [modal, openModal] = useModal(OutdoorPigLiquidApplicationModal);

    const open = (viewModel, onSubmit) => {
        const modalProps = {
            viewModel,
            onSubmit
        };
        openModal(modalProps);
    };

    return [modal, open];
}
