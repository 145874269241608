import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as utils from "common/utils";

const MonthDayInputPack = (props) => {
    const { input, name, label, placeholder = "Select date", defaultDate = "01 July", minDate = "20130701", maxDate = "20140630", clearable, required, disabled, placement = "bottom-start", dataWidth, meta, dateFormat = "DD MMMM" } = props;
    const hasError = meta && meta.touched && meta.error;

    const _handleChange = (date) => {
        const val = utils.parseDate(date, "DD MMMM");

        if (val) input.onChange(val.format("DD MMMM"));
        else input.onChange("");
    };

    const _value = input.value || defaultDate;
    const _date = utils.parseDate(_value, "DD MMMM");

    return (
        <div className={`Field${hasError ? " has-error" : ""}`} data-width={dataWidth}>
            {label && (
                <label htmlFor={input.name} className="Field-label">
                    {label}
                    {required && <sup className="required">*</sup>}
                </label>
            )}
            <div className="Input_wrapper Input--block">
                <DatePicker {...input} dateFormat={dateFormat} dateFormatCalendar="MMMM" minDate={moment(minDate).toDate()} maxDate={moment(maxDate).toDate()} isClearable={clearable} openToDate={_date.toDate()} selected={_date.toDate()} onChange={_handleChange} placeholderText={placeholder} className="Input Input--block" popperPlacement={placement} fixedHeight disabled={disabled} />
            </div>
            {hasError && (
                <small id={`${name}-error`} className="Field-error">
                    {meta.error}
                </small>
            )}
        </div>
    );
};

export default MonthDayInputPack;
