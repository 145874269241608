import React, { Component } from "react";
import { compose, withAppContext, withState, withActions } from "containers/HOC";
import { Form, Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import Modal from "components/new/Modal";
import FieldGroup from "components/FieldGroup";
import ModalBody from "components/new/ModalBody";
import ModalFooter from "components/new/ModalFooter";
import ModalHeader from "components/new/ModalHeader";
import SelectPack from "components/SelectPack2";
import * as cropUtils from "./_utils";
import * as utils from "common/utils";
import { getBlockGrowthCurve, clearBlockGrowthCurves } from "./_actions";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import { v4 as uuidv4 } from "uuid";
import * as icons from "common/icons";
import Alert from "components/Alert";
import CheckboxPack from "components/CheckboxPack";

const DEST_OPTIONS = [
    { value: "year1", text: "Year 1" },
    { value: "reportingYear", text: "Reporting year" },
    { value: "both", text: "Both" },
];

class CopyCropsModal extends Component {
    constructor(props) {
        super(props);
        const { viewModel } = this.props;
        const { block = {} } = viewModel;
        this.state = {
            originalBlock: utils.clone(block),
        };
    }

    componentDidMount() {
        this.props.clearBlockGrowthCurves();
    }

    componentDidUpdate() {
        const { destGrowthCurve } = this.state;
        const { growthCurve } = this.props;
        if (growthCurve !== destGrowthCurve && Array.isArray(growthCurve)) {
            this.setState({ destGrowthCurve: growthCurve });
        }
    }

    render() {
        const validate = (viewModel) => {
            const error = {};
            error.blockId = FormUtils.validators.required(viewModel.blockId);
            error.copyOptions = FormUtils.validators.required(viewModel.copyOptions);
            return error;
        };

        const submit = (viewModel) => {
            const { block, selectedBlock } = viewModel;
            const cropBlock = {};
            const { crops = [], croppingEvents = [] } = block;
            if (viewModel.copyHistory) {
                const { cropBlock: s = {} } = selectedBlock;
                cropBlock.yearsInPasture = s.yearsInPasture;
                cropBlock.preCrop = s.preCrop;
                cropBlock.animalGrazing = s.animalGrazing;
                cropBlock.supplementsRemoved = s.supplementsRemoved;
                cropBlock.monthSeedHarvested = s.monthSeedHarvested;
            }
            crops.forEach((c) => (c.id = uuidv4()));
            this.props.modal.onConfirm([crops, croppingEvents, cropBlock]);
            this.props.closeModal();
        };

        const onBlockChange = (form, e) => {
            const { analysis = {}, block } = viewModel;
            const { blocks = [] } = analysis;
            const selectedBlock = utils.clone(blocks.find((b) => b.id === e.target.value) || {});
            const { originalBlock } = this.state;
            block.crops = originalBlock.crops;
            block.croppingEvents = originalBlock.croppingEvents;
            form.change("block", block);
            form.change("selectedBlock", selectedBlock);
            form.change("copyOptions", undefined);
            this.props.getBlockGrowthCurve(analysis.farmId, analysis.id, block);
        };

        const renderEventMonth = (rotationYear, crops, i, block, analysis, refData) => {
            const { croppingEvents = [] } = block;
            const isReportingYear = cropUtils.indexToReportingYear(i, rotationYear);
            const [crop, cropEvt] = cropUtils.getFirstCropEvent(crops, i, ["Cropsown", "Endcrop", "Enduptake", "Cropharvest", "Defoliation"], rotationYear, true);
            const croppingEvt = croppingEvents.find((e) => e.month === rotationYear[i] && e.reportingYear === isReportingYear);
            const { cropManagement = [] } = refData;

            return (
                <td className="Calendar-slot" style={{ height: "50px" }} key={i}>
                    {cropEvt && (
                        <div>
                            <img className="Calendar-icon u-p-xxs" src={cropUtils.getEventIcon(cropEvt, crop, analysis)} alt="crop icon" style={cropEvt.isAdditionalDefoliationEvent ? { opacity: ".3" } : null} />
                            {!cropEvt.isAdditionalDefoliationEvent && (
                                <span className="Calendar-icon-text" id={crop.id}>
                                    {utils.valueToText(cropManagement, cropEvt.type)}
                                </span>
                            )}
                        </div>
                    )}
                    {croppingEvt && (
                        <div>
                            <img className="Calendar-icon u-p-xxs" src={croppingEvt.type === "Cultivation" ? icons.cultivate1 : cropUtils.getDefoliationIcon(analysis, croppingEvt)} alt="cultivate" />
                            <span className="Calendar-icon-text">{croppingEvt.type === "Cultivation" ? "Cultivate" : "Defoliation"}</span>
                        </div>
                    )}
                </td>
            );
        };

        const onChange = (form, values, e) => {
            const { block = {}, selectedBlock } = values;
            if (!selectedBlock || !selectedBlock.crops) return;
            const sourceBlock = utils.clone(selectedBlock);
            let year1Crops = [];
            let year2Crops = [];
            let year1Events = [];
            let year2Events = [];
            const { value } = e.target;
            const updatedValues = { ...values, ...{ copyOptions: value } };
            const { originalBlock } = this.state;
            const rotationYear = cropUtils.rotationYear(block);

            switch (updatedValues.copyOptions) {
                case "year1": {
                    year2Crops = originalBlock.crops.filter((c) => c.events.find((e) => e.type === "Cropsown" && e.reportingYear));
                    year2Events = originalBlock.croppingEvents.filter((e) => e.reportingYear);
                    //We don't really want events that are after the source crop sown reporting year
                    const sourceBlockFirstYear2SownIndx =
                        cropUtils.getFirstCropSownIndex(
                            sourceBlock.crops.filter((c) => c.events.find((e) => e.type === "Cropsown" && e.reportingYear)),
                            rotationYear
                        ) || 25;
                    //Find the first year 2 index
                    const year2CropIndx = cropUtils.getFirstCropSownIndex(year2Crops, rotationYear) || 25;
                    const year2EvtIndxs = year2Events.map((e) => cropUtils.rotationIndex(e, rotationYear));
                    const combinedIndx = [...year2EvtIndxs, ...[year2CropIndx], ...[sourceBlockFirstYear2SownIndx]];
                    //We don't want any year one events past this index
                    const firstYear2CropIndex1 = Math.min(...combinedIndx);

                    year1Crops = sourceBlock.crops.filter((c) => c.events.find((e) => e.type === "Cropsown" && !e.reportingYear));
                    year1Crops.forEach((c) => {
                        const { events: yearOneCropEvents = [] } = c;
                        c.events = yearOneCropEvents.filter((e) => cropUtils.rotationIndex(e, rotationYear) < firstYear2CropIndex1);
                    });
                    year1Events = sourceBlock.croppingEvents.filter((e) => cropUtils.rotationIndex(e, rotationYear) < firstYear2CropIndex1);
                    break;
                }
                case "reportingYear": {
                    year2Crops = sourceBlock.crops.filter((c) => c.events.find((e) => e.type === "Cropsown" && e.reportingYear));
                    const firstCropIndxRptYear = cropUtils.getFirstCropSownIndex(year2Crops, rotationYear) || 25;
                    year1Crops = originalBlock.crops.filter((c) => c.events.find((e) => e.type === "Cropsown" && !e.reportingYear));

                    //All year 1 crop events must be less than the first crop in the reporting year
                    year1Events = originalBlock.croppingEvents.filter((e) => cropUtils.rotationIndex(e, rotationYear) < firstCropIndxRptYear);
                    let year1EventIndexes = year1Events.map((e) => cropUtils.rotationIndex(e, rotationYear));
                    year1Crops.forEach((c) => {
                        const { events: yearOneCropEvents = [] } = c;
                        c.events = yearOneCropEvents.filter((e) => cropUtils.rotationIndex(e, rotationYear) < firstCropIndxRptYear);
                        year1EventIndexes = [...year1EventIndexes, ...yearOneCropEvents.map((e) => cropUtils.rotationIndex(e, rotationYear))];
                    });

                    //All year 2 events must be greater than the last year one event
                    const lastYearOneEvent = year1EventIndexes.length > 0 ? Math.max(...year1EventIndexes) : 0;
                    year2Events = sourceBlock.croppingEvents.filter((e) => cropUtils.rotationIndex(e, rotationYear) > lastYearOneEvent && e.reportingYear);
                    year2Crops.forEach((c) => {
                        const { events: yearTwoCropEvents = [] } = c;
                        c.events = yearTwoCropEvents.filter((e) => cropUtils.rotationIndex(e, rotationYear) > lastYearOneEvent && e.reportingYear);
                    });
                    break;
                }
                case "both":
                    year2Crops = sourceBlock.crops;
                    year2Events = sourceBlock.croppingEvents;
                    break;
                default:
                    break;
            }

            block.crops = [...year1Crops, ...year2Crops];
            block.croppingEvents = [...year1Events, ...year2Events];
            let allEventIndexes = block.croppingEvents.map((e) => cropUtils.rotationIndex(e, rotationYear));
            block.crops.forEach((c) => {
                const { events = [] } = c;
                allEventIndexes = [...allEventIndexes, ...events.map((e) => cropUtils.rotationIndex(e, rotationYear))];
            });
            allEventIndexes.sort((a, b) => (a < b ? -1 : 1));
            const uniqueIndexes = [...new Set(allEventIndexes)];
            //Need to adjust the number of months defoliating
            block.crops.forEach((c) => {
                c.id = uuidv4();
                const { events = [] } = c;
                events
                    .filter((e) => e.type === "Defoliation" && e.numberMonthsDefoliating > 1)
                    .forEach((e) => {
                        const evtIndx = cropUtils.rotationIndex(e, rotationYear);
                        const arrayIndx = uniqueIndexes.indexOf(evtIndx);
                        if (uniqueIndexes.length > arrayIndx) {
                            const nextEvtIndx = uniqueIndexes[arrayIndx + 1] || 34; //Can defoliate 10 days after the last month (24+10)
                            e.numberMonthsDefoliating = Math.min(e.numberMonthsDefoliating, nextEvtIndx - evtIndx);
                        }
                    });
            });

            this.props.getBlockGrowthCurve(values.analysis.farmId, values.analysis.id, block);
            form.change("block", block);
        };

        const { viewModel } = this.props;
        const { destGrowthCurve = [] } = this.state;
        const { block = {}, analysis = {} } = viewModel;
        const { blocks = [] } = analysis;

        const cropBlockOptions = blocks.filter((b) => b.type === block.type && b.id !== block.id && b.monthResown === block.monthResown).map((b) => ({ value: b.id, text: b.name }));
        const reset = (form, values) => {
            const { originalBlock } = this.state;
            block.crops = originalBlock.crops;
            block.croppingEvents = originalBlock.croppingEvents;
            form.change("block", block);
            form.change("copyOptions", undefined);
            this.props.getBlockGrowthCurve(values.analysis.farmId, values.analysis.id, block);
        };

        return (
            <Form initialValues={viewModel} validate={validate} onSubmit={submit}>
                {({ form, values, handleSubmit, dirty, submitSucceeded }) => {
                    const disableSave = !dirty || submitSucceeded;
                    const renderSelectedCrop = () => {
                        const sourceRotationYear = cropUtils.rotationYear(values.selectedBlock);
                        const destRotationYear = cropUtils.rotationYear(values.block);
                        let { crops: sourceCrops = [] } = values.selectedBlock;
                        sourceCrops = cropUtils.sortCrops(sourceCrops, sourceRotationYear);
                        let { crops: destCrops = [] } = values.block;
                        destCrops = cropUtils.sortCrops(destCrops, destRotationYear);
                        const sourceGrowthCurve = values.selectedBlock.cropGrowthCurves || [];
                        const sourceCurveCells = cropUtils.mapGrowthCurve(sourceGrowthCurve, sourceCrops, values.selectedBlock, sourceRotationYear, analysis);
                        const destCurveCells = cropUtils.mapGrowthCurve(destGrowthCurve, destCrops, values.block, destRotationYear, analysis);
                        const sourceLabelCells = cropUtils.mapLabels(values.selectedBlock, sourceGrowthCurve, this.props.refData);
                        const destLabelCells = cropUtils.mapLabels(values.block, destGrowthCurve, this.props.refData);
                        const sourceAnimalCells = cropUtils.mapAnimals(values.selectedBlock, analysis, this.props.refData);
                        const destAnimalCells = cropUtils.mapAnimals(values.block, analysis, this.props.refData);

                        return (
                            <React.Fragment>
                                <div className="u-mt-lg">
                                    <Tile title="Source" tertiary>
                                        <TileBody>
                                            <div className="h2 u-mt-lg">{values.selectedBlock.name}</div>
                                            <div className="Table u-mt-sm">
                                                <table className="Calendar u-tbl-fix">
                                                    <thead>
                                                        {sourceRotationYear.length > 12 && (
                                                            <tr className="Calendar-reportingyears">
                                                                <td colSpan="12" className="Year1 Solid-border">
                                                                    <span className="h5">Year 1</span>
                                                                </td>
                                                                <td colSpan="12">
                                                                    <span className="h5">Reporting Year</span>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr className="Calendar-months">
                                                            {sourceRotationYear.map((m, i) => (
                                                                <th key={i} className="Calendar-month">
                                                                    {m.substring(0, 3)}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sourceCrops.length > 0 && sourceRotationYear.length === sourceGrowthCurve.length && <tr>{cropUtils.growthCurveCells(sourceCurveCells)}</tr>}
                                                        {sourceCrops.length > 0 && (
                                                            <tr className="Calendar-slots Calendar-slots--crop">
                                                                {sourceRotationYear.map((m, i) => {
                                                                    return cropUtils.renderCropIconMonth(sourceRotationYear, sourceCrops, i, values.selectedBlock, sourceGrowthCurve, analysis);
                                                                })}
                                                            </tr>
                                                        )}
                                                        {sourceAnimalCells.some((l) => l.iconText) && (
                                                            <tr className="Calendar-slots Calendar-slots--crop">
                                                                {sourceAnimalCells.map((cell, i) => {
                                                                    return (
                                                                        <td key={i} className={"Calendar-slot"}>
                                                                            {cell.icon && <img className="Calendar-icon u-p-xxs" src={cell.icon} alt="crop icon" style={{ opacity: cell.preCrop ? "0.2" : "1" }} />}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )}
                                                        {sourceLabelCells.some((l) => l.iconText) && (
                                                            <tr className="Calendar-slots Calendar-slots--crop">
                                                                {sourceLabelCells.map((cell, i) => {
                                                                    return (
                                                                        <td key={i} colSpan={cell.colSpan} className={"Calendar-slot"}>
                                                                            {cell.iconText && (
                                                                                <span className="h6" style={{ opacity: cell.preCrop ? "0.6" : "1" }}>
                                                                                    {cell.iconText}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )}
                                                        <tr className="Calendar-slots Calendar-slots--crop">{sourceRotationYear.map((m, i) => renderEventMonth(sourceRotationYear, sourceCrops, i, values.selectedBlock, analysis, this.props.refData))}</tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TileBody>
                                    </Tile>
                                </div>

                                <div className="u-mt-lg">
                                    <Tile title="Destination" tertiary>
                                        <TileBody>
                                            <div className="h2 u-mt-lg">{block.name}</div>
                                            <div className="Table u-mt-sm">
                                                <table className="Calendar u-tbl-fix">
                                                    <thead>
                                                        {destRotationYear.length > 12 && (
                                                            <tr className="Calendar-reportingyears">
                                                                <td colSpan="12" className="Year1 Solid-border">
                                                                    <span className="h5">Year 1</span>
                                                                </td>
                                                                <td colSpan="12">
                                                                    <span className="h5">Reporting Year</span>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr className="Calendar-months">
                                                            {destRotationYear.map((m, i) => (
                                                                <th key={i} className="Calendar-month">
                                                                    {m.substring(0, 3)}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {destCrops.length > 0 && <tr>{cropUtils.growthCurveCells(destCurveCells)}</tr>}
                                                        {destCrops.length > 0 && (
                                                            <tr className="Calendar-slots Calendar-slots--crop">
                                                                {destRotationYear.map((m, i) => {
                                                                    return cropUtils.renderCropIconMonth(destRotationYear, destCrops, i, values.block, destGrowthCurve, analysis);
                                                                })}
                                                            </tr>
                                                        )}
                                                        {destAnimalCells.some((l) => l.iconText) && (
                                                            <tr className="Calendar-slots Calendar-slots--crop">
                                                                {destAnimalCells.map((cell, i) => {
                                                                    return (
                                                                        <td key={i} className={"Calendar-slot"}>
                                                                            {cell.icon && <img className="Calendar-icon u-p-xxs" src={cell.icon} alt="crop icon" style={{ opacity: cell.preCrop ? "0.2" : "1" }} />}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )}
                                                        {destLabelCells.some((l) => l.iconText) && (
                                                            <tr className="Calendar-slots Calendar-slots--crop">
                                                                {destLabelCells.map((cell, i) => {
                                                                    return (
                                                                        <td key={i} colSpan={cell.colSpan} className={"Calendar-slot"}>
                                                                            {cell.iconText && (
                                                                                <span className="h6" style={{ opacity: cell.preCrop ? "0.6" : "1" }}>
                                                                                    {cell.iconText}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )}
                                                        <tr className="Calendar-slots Calendar-slots--crop">{destRotationYear.map((m, i) => renderEventMonth(destRotationYear, destCrops, i, values.block, analysis, this.props.refData))}</tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TileBody>
                                    </Tile>
                                </div>
                            </React.Fragment>
                        );
                    };

                    return (
                        <Modal title="Copy crops" allowOffline={true}>
                            {({ closeModal }) => (
                                <form onSubmit={handleSubmit}>
                                    <ModalHeader>
                                        <Alert
                                            type="info"
                                            text="Use this form to copy crops from one block to another. First select a block that contains the crops you want to copy and then choose the copy option. 
                                        The resulting merge of your crops will be displayed in the preview section. If you are not happy with the merge click on the reset button to try other options or cancel to return to the previous screen."
                                        />
                                    </ModalHeader>
                                    <ModalBody>
                                        <FieldGroup>
                                            <Field name="blockId" placeholder="Select a block" options={cropBlockOptions} required component={SelectPack} onChange={(e) => onBlockChange(form, e)} dataWidth="50" label="Select a block to copy from" />
                                            <Field name="copyOptions" placeholder="Copy options" options={DEST_OPTIONS} required component={SelectPack} onChange={(e) => onChange(form, values, e)} dataWidth="50" label="Copy options" disabled={!values.selectedBlock} />
                                        </FieldGroup>
                                        {block.type === "ProductiveCrop" && <Field name="copyHistory" required component={CheckboxPack} label="Copy block history" />}
                                        {values.selectedBlock && renderSelectedCrop()}
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="ButtonBar">
                                            <div className="ButtonBar-left">
                                                <button id="cancel-button" type="button" className="Button Button--secondary" onClick={closeModal}>
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="ButtonBar-right">
                                                {values.copyOptions && (
                                                    <button id="reset-button" type="button" className="Button Button--tertiary" onClick={() => reset(form, values)}>
                                                        Reset
                                                    </button>
                                                )}
                                                <button id="save-button" type="submit" className="Button Button--primary" disabled={disableSave}>
                                                    Done
                                                </button>
                                            </div>
                                        </div>
                                    </ModalFooter>
                                </form>
                            )}
                        </Modal>
                    );
                }}
            </Form>
        );
    }
}

export default compose(
    withAppContext,
    withState((state, ownProps) => {
        const { blockGrowthCurveResult = [] } = state.searchResults;
        return {
            ...ownProps,
            growthCurve: blockGrowthCurveResult,
        };
    }),
    withActions({ getBlockGrowthCurve, clearBlockGrowthCurves })
)(CopyCropsModal);
