import React from "react";
import { compose, withAppContext, withState, withActions } from "containers/HOC";
import { reduxForm } from "redux-form";
import { closeModal, toggleQuickTips } from "containers/App/_actions";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";

class Modal extends React.Component {
    _close = (e) => {
        e.preventDefault();
        this.props.closeModal();
        if (this.props.onCancel) this.props.onCancel();
    };

    _closeOnEscape = (e) => {
        if (e.keyCode === 27) {
            this._close(e);
        }
    };

    _primarySubmit = (formValues) => {
        this.props.onSubmit({ ...formValues, modalWasDirty: this.props.dirty }, "primary");
    };

    _tertiarySubmit = (formValues) => {
        this.props.onSubmit({ ...formValues, modalWasDirty: this.props.dirty }, "tertiary");
    };

    componentDidMount() {
        document.addEventListener("keydown", this._closeOnEscape);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._closeOnEscape);
    }

    render() {
        const { online, title, cancelLabel = "Cancel", submitLabel = "Save", tertiarySubmitLabel, instructionText, toggleQuickTips, showQuickTips, skinny = false, allowOffline = true, children, modalError, isSaving, handleSubmit, submitDisabled, tertiarySubmitDisabled } = this.props;

        const offlineDisplay = !allowOffline && !online;
        return (
            <div className="Modal_wrapper">
                <div className={`Modal Modal--${skinny ? "skinny" : "medium"}`}>
                    <form {...this.props.validate}>
                        <div className="Modal-head">
                            <span className="Modal-head--left">{title}</span>
                            <span className="Modal-head--right">
                                <ActionLink onClick={toggleQuickTips} className="Modal-close">
                                    <i className="icon icon-question" title={`${showQuickTips ? "Hide" : "Show"} quick tips`} />
                                </ActionLink>
                                <ActionLink onClick={this._close} className="Modal-close">
                                    <i className="icon icon-cross" title="Close" />
                                </ActionLink>
                            </span>
                        </div>
                        <div className="Modal-subhead">
                            {instructionText && !offlineDisplay && <Alert type="help" text={instructionText} />}
                            {offlineDisplay && <Alert type="error" text="Overseer is currently offline. Please try again later." />}
                            {online && modalError && (
                                <div className="Alert Alert--error">
                                    <i className="icon icon-alert" id="modal-errormsg" />
                                    {modalError}
                                </div>
                            )}
                        </div>
                        <div className="Modal-body">{children}</div>
                        <div className="Modal-footer">
                            <div className="ButtonBar">
                                <div className="ButtonBar-left">
                                    <button id="cancel-button" onClick={this._close} disabled={isSaving && !modalError} className="Button Button--secondary" type="button" name="button">
                                        {cancelLabel}
                                    </button>
                                </div>
                                <div className="ButtonBar-right">
                                    {tertiarySubmitLabel && (
                                        <button id="tertiary-submit-button" onClick={handleSubmit(this._tertiarySubmit)} disabled={(!allowOffline && !online) || tertiarySubmitDisabled || isSaving} className="Button Button--tertiary">
                                            {tertiarySubmitLabel}
                                        </button>
                                    )}
                                    <button id="confirm-button" onClick={handleSubmit(this._primarySubmit)} disabled={(!allowOffline && !online) || submitDisabled || isSaving} className="Button">
                                        {submitLabel}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const ModalForm = reduxForm({
    form: "modal-form",
})(Modal);

export default compose(
    withAppContext,
    withState((state) => {
        return { modalError: state.app.modalError };
    }),
    withActions({ closeModal, toggleQuickTips })
)(ModalForm);
