import React from "react";
import { compose, withAppContext } from "containers/HOC";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { calendarYear } from "common/domain";
import * as FormUtils from "common/FormUtils";
import InputPack from "components/InputPack";

const validatePlacements = (placements, field) => {
    const errors = [];
    const { outdoorPigs = {} } = field;
    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const includeGrowers = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    const { growOutUnitOnly } = outdoorPigs;
    const filteredPlacements = (placements || []).filter((placement) => {
        if (placement.stockClassGroup && placement.stockClassGroup === "Growers" && !includeGrowers) return false;

        if (placement.stockClassGroup && placement.stockClassGroup !== "Growers" && growOutUnitOnly) return false;

        return true;
    });

    const hasAtLeastOne = filteredPlacements.reduce((result, placement) => {
        if (placement.months.some((m) => m.amount > 0)) result = true;
        return result;
    }, false);
    errors.push({ placements: hasAtLeastOne ? undefined : "Required" });

    return errors;
};

const validateMonths = (months) => {
    const errors = [];

    months &&
        months.forEach((month) => {
            const error = {};

            error.amount = FormUtils.validators.range(0, 100)(month.amount);

            errors.push(error);
        });

    return errors;
};

const getErrorMessages = (fieldState) => {
    const errorMessages =
        fieldState &&
        (fieldState.error || []).reduce((errors, error, index) => {
            if (error.placements && fieldState.touched) {
                errors.push(error.placements);
            }
            if (error.months && error.months.some((m) => m.amount)) {
                const monthIndex = error.months.findIndex((m) => m.amount);
                const message = error.months[monthIndex].amount.toLowerCase();

                const label = fieldState.value[index].label;
                errors.push(`${label} - ${message}`);
            }
            return errors;
        }, []);
    return errorMessages;
};

const onAmountChanged = (form, fieldName) => (e) => {
    form.blur(fieldName);
};

export const OutdoorPigBarnPlacements = ({ form, outdoorPigs }) => {
    const fieldName = `placements`;
    const fieldState = form.getFieldState(fieldName);
    const errorMessages = getErrorMessages(fieldState);

    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const showGrowersFinishersFields = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    return (
        <React.Fragment>
            <h3>Pigs placed in barns/sheds</h3>
            <p>Percentage of pig days per month</p>
            <div className="Table u-mt-md">
                <table>
                    <thead>
                        <tr>
                            <th data-width="10"></th>
                            {calendarYear.map((m) => (
                                <th key={m} className="u-textCenter" style={{ minWidth: "65px" }}>
                                    {m.substring(0, 3)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray name={fieldName} validate={validatePlacements}>
                            {({ fields }) => {
                                return fields.map((field, index) => {
                                    const pigBarnMonth = fields.value[index];

                                    if (pigBarnMonth.stockClassGroup && pigBarnMonth.stockClassGroup === "Growers" && !showGrowersFinishersFields) return null;

                                    if (pigBarnMonth.stockClassGroup && pigBarnMonth.stockClassGroup !== "Growers" && outdoorPigs.growOutUnitOnly) return null;

                                    return (
                                        <tr key={index}>
                                            <td className="u-textBold">{pigBarnMonth.label}</td>
                                            <FieldArray name={`${field}.months`} validate={validateMonths}>
                                                {({ fields }) => {
                                                    return calendarYear.map((calendarMonth) => {
                                                        const monthIndex = fields.value.findIndex((f) => f.month === calendarMonth);
                                                        const month = fields.value[monthIndex];
                                                        return (
                                                            <td key={month.month} className="u-textCenter">
                                                                <Field name={`${field}.months[${monthIndex}].amount`} onChange={onAmountChanged(form, fieldName)} noLabel maxLength="5" hideErrors uom="%" placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                                            </td>
                                                        );
                                                    });
                                                }}
                                            </FieldArray>
                                        </tr>
                                    );
                                });
                            }}
                        </FieldArray>
                    </tbody>
                </table>
                {errorMessages && errorMessages.length > 0 && (
                    <div className="Field-error u-block u-mt-0">
                        {errorMessages.map((msg) => (
                            <p key={msg}>{msg}</p>
                        ))}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default compose(withAppContext)(OutdoorPigBarnPlacements);
