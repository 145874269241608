import { Fragment, useState } from "react";
import { Panel, PanelBody } from "components/Panel";
import Watermark from "components/Watermark";
import * as utils from "common/utils";
import { Bar, Pie } from "react-chartjs-2";
import { useAnalysisResults } from "containers/BudgetHome";
import ActionLink from "components/ActionLink";

const methaneColour = { name: utils.getColour(4), hex: utils.colourHexIndex[4] };
const n2oColour = { name: utils.getColour(0), hex: utils.colourHexIndex[0] };
const co2Colour = { name: utils.getColour(9), hex: utils.colourHexIndex[9] };

export default function Emissions({ analysis }) {
    const { isLoading, data } = useAnalysisResults(analysis);
    const ghgResults = data?.ghg;

    const emissionsPerHectareNotation = getEmissionsPerHectareNotation(ghgResults);

    return (
        <Panel title="Greenhouse gases" loading={isLoading} waiting={isLoading} skyBlue info={`The 'Emissions by source' table and graphs display the GHG emissions for the farm as kilograms of CO2 equivalents per hectare per year (${emissionsPerHectareNotation}). Clicking on the blue rows displays a further breakdown of that source. The 'Total farm emissions' table shows the total farm emissions in tonnes of CO2e per year (t CO2e/yr) broken down by methane, nitrous oxide and carbon dioxide`}>
            {ghgResults && (
                <PanelBody className="u-print-noborder">
                    <div className="h2 u-pb-sm">Emissions by source</div>
                    <div className="Grid-cell u-lg-width1of3">
                        <div className="Table u-pos-rel u-overflow-hidden">
                            <Watermark />
                            <EmmisionsBySourceTable emission={ghgResults.emissions.methane} title="Methane" colour={methaneColour.name} />
                        </div>
                        <div className="Table u-pos-rel u-overflow-hidden">
                            <Watermark />
                            <EmmisionsBySourceTable emission={ghgResults.emissions.nitrousOxide} title="Nitrous oxide" colour={n2oColour.name} />
                        </div>
                        <div className="Table u-page-break u-pos-rel u-overflow-hidden">
                            <Watermark />
                            <EmmisionsBySourceTable emission={ghgResults.emissions.carbonDioxide} title="Carbon dioxide" colour={co2Colour.name} />
                        </div>
                    </div>
                    <div className="Grid-cell u-lg-width2of3 u-page-break">
                        <div style={{ paddingTop: "0px", paddingLeft: "30px" }} className="u-pos-rel u-overflow-hidden">
                            <div className="u-pt-lg">
                                <Watermark />
                                <EmissionsGraph ghgResults={ghgResults} />
                            </div>
                        </div>
                    </div>
                    <div className="u-pb-sm u-pt-xxl">
                        <div className="h2 u-pt-md">Total farm emissions</div>
                    </div>
                    <div className="Grid-cell">
                        <TotalFarmEmissions ghgResults={ghgResults} />
                    </div>
                </PanelBody>
            )}
        </Panel>
    );
}

const EmmisionsBySourceTable = ({ emission, title, colour }) => {
    const [expanded, setExpanded] = useState([]);
    const toggle = (name) => {
        if (expanded.includes(name)) {
            setExpanded(expanded.filter((item) => item !== name));
        } else {
            setExpanded([...expanded, name]);
        }
    };
    return (
        <table className="u-tbl-fix">
            <thead>
                <tr>
                    <th>{title}</th>
                    <th className="u-textRight">
                        {emission?.emissionsPerHectare?.unit?.notation}
                        <h4 className="u-mt-0">{emission?.emissionsPerHectare?.value?.toLocaleString()}</h4>
                    </th>
                </tr>
            </thead>
            <tbody>
                {emission?.sources?.map((source) => (
                    <Fragment key={source.name}>
                        <tr className={`Table-tr--${colour}`}>
                            <td>
                                {source.subSources ? (
                                    <ActionLink className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween" onClick={() => toggle(source.name)}>
                                        {source.name}
                                        <i className={`icon icon-arrow-${expanded.includes(source.name) ? "up" : "down"}`}></i>
                                    </ActionLink>
                                ) : (
                                    <> {source.name}</>
                                )}
                            </td>
                            <td className="u-textRight">{source.emissionsPerHectare.value.toLocaleString()}</td>
                        </tr>
                        {expanded.includes(source.name) &&
                            source.subSources?.map((subSource, index) => (
                                <tr key={index} className="tr-nested">
                                    <td>{subSource.name}</td>
                                    <td className="u-textRight">{subSource.emissionsPerHectare.value.toLocaleString()}</td>
                                </tr>
                            ))}
                    </Fragment>
                ))}
            </tbody>
        </table>
    );
};

const TotalFarmEmissions = ({ ghgResults }) => {
    if (!ghgResults) return null;

    const totalMethaneEmissions = ghgResults?.emissions?.methane?.totalEmissions;
    const totalNitrousOxideEmissions = ghgResults?.emissions?.nitrousOxide?.totalEmissions;
    const totalCarbonDioxideEmissions = ghgResults?.emissions?.carbonDioxide?.totalEmissions;
    const totalEmissions = ghgResults?.emissions?.totalEmissions;

    return (
        <div className="Table u-mt-0 u-mb-md">
            <table>
                <thead>
                    <tr>
                        <th>Total farm emissions in tonnes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="HeroValue">
                                <div className="HeroValue-item">
                                    <h5>Methane</h5>
                                    <div className="u-flex u-flexColumn">
                                        <span id="methane-emissions" className="h1">
                                            {utils.numeric.format(totalMethaneEmissions?.value, 1)} <small>{totalMethaneEmissions?.unit?.notation}</small>
                                        </span>
                                    </div>
                                </div>
                                <div className="HeroValue-item">
                                    <h5>Nitrous oxide</h5>
                                    <div className="u-flex u-flexColumn">
                                        <span id="n2O-emissions" className="h1">
                                            {utils.numeric.format(totalNitrousOxideEmissions?.value, 1)} <small>{totalNitrousOxideEmissions?.unit?.notation}</small>
                                        </span>
                                    </div>
                                </div>
                                <div className="HeroValue-item">
                                    <h5>Carbon dioxide</h5>
                                    <div className="u-flex u-flexColumn">
                                        <span id="cO2-emissions" className="h1">
                                            {utils.numeric.format(totalCarbonDioxideEmissions?.value, 1)} <small>{totalCarbonDioxideEmissions?.unit?.notation}</small>
                                        </span>
                                    </div>
                                </div>
                                <div className="HeroValue-item">
                                    <h5>Total GHG emissions</h5>
                                    <div className="u-flex u-flexColumn">
                                        <span id="total-emissions" className="h1">
                                            {utils.numeric.format(totalEmissions?.value, 1)} <small>{totalCarbonDioxideEmissions?.unit?.notation}</small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const EmissionsGraph = ({ ghgResults }) => {
    if (!ghgResults) return null;

    const CreateEmissionsData = (source, colour) => {
        return { colour: colour, label: source.name, value: source.emissionsPerHectare.value };
    };

    const emissionsData = [].concat(
        ghgResults.emissions.methane.sources.map((e) => CreateEmissionsData(e, methaneColour.hex)),
        ghgResults.emissions.nitrousOxide.sources.map((e) => CreateEmissionsData(e, n2oColour.hex)),
        ghgResults.emissions.carbonDioxide.sources.map((e) => CreateEmissionsData(e, co2Colour.hex))
    );

    const pieData = [ghgResults.emissions.methane.sources.reduce((t, c) => (t += c.emissionsPerHectare.value), 0), ghgResults.emissions.nitrousOxide.sources.reduce((t, c) => (t += c.emissionsPerHectare.value), 0), ghgResults.emissions.carbonDioxide.sources.reduce((t, c) => (t += c.emissionsPerHectare.value), 0)];
    const totalPie = pieData.reduce((t, d) => (t += d || 0), 0);
    if (totalPie > 0) {
        pieData[0] = ((pieData[0] || 0) / totalPie) * 100;
        pieData[1] = ((pieData[1] || 0) / totalPie) * 100;
        pieData[2] = ((pieData[2] || 0) / totalPie) * 100;
    }

    const pData = {
        labels: ["Methane", "Nitrous oxide", "Carbon dioxide"],
        datasets: [
            {
                fill: "origin",
                lineTension: 0,
                backgroundColor: [methaneColour.hex, n2oColour.hex, co2Colour.hex],
                labels: ["%", "%", "%"],
                data: pieData.map((d) => utils.round(d, 1)),
            },
        ],
    };

    const nData = {
        labels: emissionsData.map((d) => d.label),
        datasets: [
            {
                fill: "origin",
                lineTension: 0,
                backgroundColor: emissionsData.map((d) => d.colour),
                borderColor: "black",
                pointRadius: 0,
                data: emissionsData.map((d) => utils.round(d.value, 2)),
            },
        ],
    };

    const options = {
        scales: {
            x: { display: true, ticks: { autoSkip: false } },
            y: {
                ticks: { beginAtZero: true },
                display: true,
                title: { display: true, text: getEmissionsPerHectareNotation(ghgResults) },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const pOptions = {
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        return `${tooltipItem.raw.toLocaleString()} %`;
                    },
                },
            },
        },
        responsive: false,
        maintainAspectRatio: false,
    };

    return (
        <>
            <Bar data={nData} height={175} options={options} />
            <div className="u-flex u-flexJustifyCenter">
                <Pie data={pData} options={pOptions} height={400} width={400} />
            </div>
        </>
    );
};

const getEmissionsPerHectareNotation = (ghgResults) => ghgResults?.emissions?.emissionsPerHectare?.unit?.notation || "kg CO2e/ha";
