import * as api from "api";
import authActionTypes from "../Auth/_actions";
import appActionTypes from "containers/App/_actions";
import ReactGA from "react-ga4";
import { push } from "connected-react-router";

const actionTypes = {
    GET_SETTINGS: "GET_SETTINGS",
    GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
    GET_SETTINGS_FAILURE: "GET_SETTINGS_FAILURE",
    GET_SETTINGS_CANCEL: "GET_SETTINGS_CANCEL",
    GET_SETTINGS_CLEAR: "GET_SETTINGS_CLEAR",
    UPDATE_CROPS_PREVIOUS_YEAR: "UPDATE_CROPS_PREVIOUS_YEAR",
    UPDATE_CROPS_PREVIOUS_YEAR_CANCEL: "UPDATE_CROPS_PREVIOUS_YEAR_CANCEL",
    IMPORT_DATASET: "IMPORT_DATASET",
    EXPORT_DATASET: "EXPORT_DATASET",
    EXPORT_DATASET_CANCEL: "EXPORT_DATASET_CANCEL",
    GET_DATASETS: "GET_DATASETS",
    GET_DATASETS_SUCCESS: "GET_DATASETS_SUCCESS",
    GET_DATASETS_FAILURE: "GET_DATASETS_FAILURE",
    GET_DATASETS_CANCEL: "GET_DATASETS_CANCEL",
    GET_DATASETS_CLEAR: "GET_DATASETS_CLEAR",
    GET_DATASET: "GET_DATASET",
    GET_DATASET_SUCCESS: "GET_DATASET_SUCCESS",
    GET_DATASET_FAILURE: "GET_DATASET_FAILURE",
    GET_DATASET_CANCEL: "GET_DATASET_CANCEL",
    GET_DATASET_CLEAR: "GET_DATASET_CLEAR",
    DELETE_DATASET: "DELETE_DATASET",
    DELETE_DATASET_SUCCESS: "DELETE_DATASET_SUCCESS",
    DELETE_DATASET_FAILURE: "DELETE_DATASET_FAILURE",
    GET_MEASURED_VALUES: "GET_MEASURED_VALUES",
    GET_MEASURED_VALUES_SUCCESS: "GET_MEASURED_VALUES_SUCCESS",
    GET_MEASURED_VALUES_FAILURE: "GET_MEASURED_VALUES_FAILURE",
    UPDATE_MEASURED_VALUES: "UPDATE_MEASURED_VALUES",
    UPDATE_MEASURED_VALUES_SUCCESS: "UPDATE_MEASURED_VALUES_SUCCESS",
    UPDATE_MEASURED_VALUES_FAILURE: "UPDATE_MEASURED_VALUES_FAILURE",
    GET_RUN: "GET_RUN",
    GET_RUN_SUCCESS: "GET_RUN_SUCCESS",
    GET_RUN_FAILURE: "GET_RUN_FAILURE",
    GET_RUN_CANCEL: "GET_RUN_CANCEL",
    GET_RUN_CLEAR: "GET_RUN_CLEAR",
    EXECUTE_MODEL_TEST: "EXECUTE_MODEL_TEST",
    EXECUTE_MODEL_TEST_SUCCESS: "EXECUTE_MODEL_TEST_SUCCESS",
    EXECUTE_MODEL_TEST_FAILURE: "EXECUTE_MODEL_TEST_FAILURE",
    CREATE_MODEL_RUN: "CREATE_MODEL_RUN",
    CREATE_MODEL_RUN_SUCCESS: "CREATE_MODEL_RUN_SUCCESS",
    CREATE_MODEL_RUN_FAILURE: "CREATE_MODEL_RUN_FAILURE",
    UPDATE_MODEL_RUN: "UPDATE_MODEL_RUN",
    UPDATE_MODEL_RUN_SUCCESS: "UPDATE_MODEL_RUN_SUCCESS",
    UPDATE_MODEL_RUN_FAILURE: "UPDATE_MODEL_RUN_FAILURE",
    SAVE_DATASET: "SAVE_DATASET",
    SAVE_DATASET_SUCCESS: "SAVE_DATASET_SUCCESS",
    SAVE_DATASET_FAILURE: "SAVE_DATASET_FAILURE",
    DELETE_RUN: "DELETE_RUN",
    DELETE_RUN_SUCCESS: "DELETE_RUN_SUCCESS",
    DELETE_RUN_FAILURE: "DELETE_RUN_FAILURE",
    DATASET_BUDGET_SUBMIT: "DATASET_BUDGET_SUBMIT",
    DATASET_BUDGET_COPY: "DATASET_BUDGET_COPY",
    DATASET_BUDGET_FETCH_FARM: "DATASET_BUDGET_FETCH_FARM",
    DATASET_BUDGET_FETCH_BUDGET: "DATASET_BUDGET_FETCH_BUDGET",
    DATASET_BUDGET_COPY_FARM: "DATASET_BUDGET_COPY_FARM",
    DATASET_BUDGET_COPY_BUDGET: "DATASET_BUDGET_COPY_BUDGET",
    DATASET_BUDGET_FETCH_DATASET: "DATASET_BUDGET_FETCH_DATASET",
    DATASET_BUDGET_COPY_FAILURE: "DATASET_BUDGET_COPY_FAILURE",
    DATASET_MODEL_TEST_COMPLETED: "DATASET_MODEL_TEST_COMPLETED",
    DATASET_PROCESSING_DATASET: "DATASET_PROCESSING_DATASET",
    DATASET_MODEL_RUN_STARTED: "DATASET_MODEL_RUN_STARTED",
    DATASET_MODEL_RUN_COMPLETED: "DATASET_MODEL_RUN_COMPLETED",
    DATASET_MODEL_RUN_CANCEL: "DATASET_MODEL_RUN_CANCEL",
    LYSIMETER_RESULTS_FETCHED: "LYSIMETER_RESULTS_FETCHED",
};
export default actionTypes;

export function getSettings(organisationId) {
    return api.get({
        path: `science/${organisationId}/settings`,
        onSuccess: (response) => {
            return { type: actionTypes.GET_SETTINGS_SUCCESS, payload: { ...response, organisationId } };
        },
    });
}

export function getDatasets() {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_DATASETS_CANCEL });
        dispatch(
            api.get({
                path: `science/datasets`,
                onSuccess: (response) => {
                    return { type: actionTypes.GET_DATASETS_SUCCESS, payload: response };
                },
                onFailure: (error) => {
                    return (dispatch) => {
                        dispatch({ type: actionTypes.GET_DATASETS_CANCEL });
                        dispatch({ type: actionTypes.GET_DATASETS_FAILURE, payload: error.message });
                        switch ((error || {}).status) {
                            case 401:
                            case 403:
                                dispatch({ type: authActionTypes.AUTH_UNAUTHORISED });
                                break;
                            default:
                                break;
                        }
                    };
                },
                cancelActionType: actionTypes.GET_DATASETS_CANCEL,
            })
        );
    };
}

export function getOrgDatasets(organisationId) {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_DATASETS_CANCEL });
        dispatch(
            api.get({
                path: `science/${organisationId}/datasets`,
                onSuccess: (response) => {
                    return { type: actionTypes.GET_DATASETS_SUCCESS, payload: response };
                },
                onFailure: (error) => {
                    return (dispatch) => {
                        dispatch({ type: actionTypes.GET_DATASETS_CANCEL });
                        dispatch({ type: actionTypes.GET_DATASETS_FAILURE, payload: error.message });
                        switch ((error || {}).status) {
                            case 401:
                            case 403:
                                dispatch({ type: authActionTypes.AUTH_UNAUTHORISED });
                                break;
                            default:
                                break;
                        }
                    };
                },
                cancelActionType: actionTypes.GET_DATASETS_CANCEL,
            })
        );
    };
}

export function getDatasetsClear() {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_DATASETS_CANCEL });
        dispatch({ type: actionTypes.GET_DATASETS_CLEAR });
    };
}

export function getDataset(datasetId) {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_DATASET_CANCEL });
        dispatch(
            api.get({
                path: `science/datasets/${datasetId}`,
                onSuccess: (response) => {
                    return { type: actionTypes.GET_DATASET_SUCCESS, payload: response };
                },
                onFailure: (error) => {
                    return (dispatch) => {
                        dispatch({ type: actionTypes.GET_DATASET_RESULTS_CANCEL });
                        dispatch({ type: actionTypes.GET_DATASET_FAILURE, payload: error.message });
                    };
                },
                cancelActionType: actionTypes.GET_DATASET_CANCEL,
            })
        );
    };
}

export function getDatasetClear() {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_DATASET_CANCEL });
        dispatch({ type: actionTypes.GET_DATASET_CLEAR });
    };
}

export function deleteDataset(datasetId) {
    ReactGA.event({
        category: "Dataset",
        action: "Delete",
    });
    return (dispatch) => {
        dispatch({ type: actionTypes.DELETE_DATASET_CANCEL });
        dispatch(
            api.del({
                path: `science/datasets/${datasetId}`,
                onSuccess: (response) => {
                    return (dispatch) => {
                        dispatch({ type: actionTypes.DELETE_DATASET_SUCCESS, payload: response });
                        dispatch(getDatasets());
                    };
                },
                onFailure: (error) => {
                    return (dispatch) => {
                        dispatch({ type: actionTypes.DELETE_DATASET_CANCEL });
                        dispatch({ type: actionTypes.DELETE_DATASET_FAILURE, payload: error.message });
                    };
                },
                cancelActionType: actionTypes.DELETE_DATASET_CANCEL,
            })
        );
    };
}

export function cancelUpload() {
    return { type: appActionTypes.APP_SUBMISSION_CANCEL };
}

export function getMeasured(datasetId) {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_MEASURED_VALUES });
        dispatch(
            api.get({
                path: `science/datasets/${datasetId}/measured`,
                onSuccess: (response) => {
                    return { type: actionTypes.GET_MEASURED_VALUES_SUCCESS, payload: response };
                },
                onFailure: (error) => {
                    return (dispatch) => {
                        dispatch({ type: actionTypes.GET_MEASURED_VALUES_FAILURE, payload: error.message });
                    };
                },
                cancelActionType: actionTypes.GET_MEASURED_VALUES_CANCEL,
            })
        );
    };
}

export function updateMeasured(datasetId, blocks, referrer) {
    return api.post({
        path: `science/datasets/${datasetId}/measured`,
        content: { results: blocks },
        cancelActionType: appActionTypes.APP_SUBMISSION_CANCEL,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: appActionTypes.UPDATE_MEASURED_VALUES_SUCCESS, payload: { type: actionTypes.UPDATE_MEASURED_VALUES } });
                dispatch(push(referrer));
            };
        },
        onFailure: (error) => {
            error.handled = true;
            var messages = [];
            if (error.xhr && error.xhr.response) {
                if (error.xhr.response.length) {
                    for (var i = 0; i < error.xhr.response.length; i++) {
                        messages.push(error.xhr.response[i].message ? error.xhr.response[i].message.text : error.xhr.response[i].errorMessage);
                    }
                } else {
                    messages.push(error.xhr.response.error ? error.xhr.response.error + ". Please try again and if problem persists call support." : "An unexpected error has occured. Please try again or call support if the problem persists.");
                }
            } else {
                messages.push("An unexpected error has occured. Please try again or call support if the problem persists.");
            }
            return { type: appActionTypes.APP_SUBMISSION_OUTCOME, payload: { type: actionTypes.UPDATE_MEASURED_VALUES_SUCCESS, errors: messages } };
        },
    });
}

export function getRun(datasetId, runId) {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_RUN_CANCEL });
        dispatch(
            api.get({
                path: `science/datasets/${datasetId}/runs/${runId}`,
                onBeforeSend: () => {
                    return { type: actionTypes.GET_RUN, payload: { datasetId, runId } };
                },
                onSuccess: (response) => {
                    return { type: actionTypes.GET_RUN_SUCCESS, payload: response };
                },
                onFailure: (error) => {
                    return (dispatch) => {
                        dispatch({ type: actionTypes.GET_RUN_CANCEL });
                        dispatch({ type: actionTypes.GET_RUN_FAILURE, payload: error.message });
                    };
                },
                cancelActionType: actionTypes.GET_RUN_CANCEL,
            })
        );
    };
}

export function deleteRun(datasetId, runId) {
    ReactGA.event({
        category: "ModelRun",
        action: "Delete",
    });
    return (dispatch) => {
        dispatch({ type: actionTypes.DELETE_RUN_CANCEL });
        dispatch(
            api.del({
                path: `science/datasets/${datasetId}/runs/${runId}`,
                onSuccess: (response) => {
                    return (dispatch) => {
                        dispatch({ type: actionTypes.DELETE_RUN_SUCCESS, payload: response });
                        dispatch(getDataset(datasetId));
                    };
                },
                onFailure: (error) => {
                    return (dispatch) => {
                        dispatch({ type: actionTypes.DELETE_RUN_CANCEL });
                        dispatch({ type: actionTypes.DELETE_RUN_FAILURE, payload: error.message });
                    };
                },
                cancelActionType: actionTypes.DELETE_RUN_CANCEL,
            })
        );
    };
}

export const createModelRun = (datasetId, run) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Datasets",
            action: "Create Model Run",
        });
        dispatch(
            api.post({
                path: `science/datasets/${datasetId}/runs`,
                timeout: 300000,
                content: run,
                cancelActionType: appActionTypes.APP_SUBMISSION_CANCEL,
                onSuccess: () => {
                    resolve();
                    return (dispatch) => {
                        dispatch({ type: appActionTypes.CREATE_MODEL_RUN_SUCCESS, payload: { type: actionTypes.CREATE_MODEL_RUN } });
                        dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
                    };
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    var messages = [];
                    if (error.xhr && error.xhr.response) {
                        if (error.xhr.response.length) {
                            for (var i = 0; i < error.xhr.response.length; i++) {
                                messages.push(error.xhr.response[i].message ? error.xhr.response[i].message.text : error.xhr.response[i].errorMessage);
                            }
                        } else {
                            messages.push(error.xhr.response.error ? error.xhr.response.error + ". Please try again and if problem persists call support." : "An unexpected error has occured. Please try again or call support if the problem persists.");
                        }
                    } else {
                        messages.push("An unexpected error has occured. Please try again or call support if the problem persists.");
                    }
                    return { type: appActionTypes.APP_SUBMISSION_OUTCOME, payload: { type: actionTypes.CREATE_MODEL_RUN, errors: messages } };
                },
            })
        );
    });
};

export function updateModelRun(datasetId, run) {
    return api.put({
        path: `science/datasets/${datasetId}/runs/${run.id}`,
        content: run,
        cancelActionType: appActionTypes.APP_SUBMISSION_CANCEL,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: appActionTypes.UPDATE_MODEL_RUN_SUCCESS, payload: { type: actionTypes.UPDATE_MODEL_RUN } });
            };
        },
        onFailure: (error) => {
            error.handled = true;
            var messages = [];
            if (error.xhr && error.xhr.response) {
                if (error.xhr.response.length) {
                    for (var i = 0; i < error.xhr.response.length; i++) {
                        messages.push(error.xhr.response[i].message ? error.xhr.response[i].message.text : error.xhr.response[i].errorMessage);
                    }
                } else {
                    messages.push(error.xhr.response.error ? error.xhr.response.error + ". Please try again and if problem persists call support." : "An unexpected error has occured. Please try again or call support if the problem persists.");
                }
            } else {
                messages.push("An unexpected error has occured. Please try again or call support if the problem persists.");
            }
            return { type: appActionTypes.APP_SUBMISSION_OUTCOME, payload: { type: actionTypes.UPDATE_MODEL_RUN, errors: messages } };
        },
    });
}

export const updateDataset = (dataset) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Datasets",
            action: "Update Dataset",
        });
        dispatch(
            api.put({
                path: `science/datasets`,
                content: dataset,
                cancelActionType: appActionTypes.APP_SUBMISSION_CANCEL,
                onSuccess: () => {
                    resolve();
                    return (dispatch) => {
                        dispatch({ type: appActionTypes.SAVE_DATASET_SUCCESS, payload: { type: actionTypes.SAVE_DATASET } });
                    };
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    var messages = [];
                    if (error.xhr && error.xhr.response) {
                        if (error.xhr.response.length) {
                            for (var i = 0; i < error.xhr.response.length; i++) {
                                messages.push(error.xhr.response[i].message ? error.xhr.response[i].message.text : error.xhr.response[i].errorMessage);
                            }
                        } else {
                            messages.push(error.xhr.response.error ? error.xhr.response.error + ". Please try again and if problem persists call support." : "An unexpected error has occured. Please try again or call support if the problem persists.");
                        }
                    } else {
                        messages.push("An unexpected error has occured. Please try again or call support if the problem persists.");
                    }
                    return { type: appActionTypes.APP_SUBMISSION_OUTCOME, payload: { type: actionTypes.SAVE_DATASET, errors: messages } };
                },
            })
        );
    });
};

export const executeModelTest = (datasetId = undefined, failedOnly = false, silentMode = false) => {
    return api.post({
        path: `science/testrun`,
        content: { datasetId, failedOnly },
        cancelActionType: appActionTypes.APP_SUBMISSION_CANCEL,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: actionTypes.EXECUTE_MODEL_TEST_SUCCESS });
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                dispatch({ type: actionTypes.EXECUTE_MODEL_TEST_FAILURE });
            };
        },
    });
};

// Create dataset
export const createDataset = (dataset) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Datasets",
            action: "Create Dataset",
        });
        dispatch(
            api.post({
                path: `science/datasets`,
                content: dataset,
                cancelActionType: appActionTypes.APP_SUBMISSION_CANCEL,
                onSuccess: (response) => {
                    resolve(response);
                    return [{ type: appActionTypes.SAVE_DATASET_SUCCESS, payload: { type: actionTypes.SAVE_DATASET } }];
                },
                onFailure: (error) => {
                    error.handled = true;
                    if (error.status === 409) {
                        reject("A dataset with the same name already exists. Please enter a different name.");
                    } else {
                        reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    }
                    var messages = [];
                    if (error.xhr && error.xhr.response) {
                        if (error.xhr.response.length) {
                            for (var i = 0; i < error.xhr.response.length; i++) {
                                messages.push(error.xhr.response[i].message ? error.xhr.response[i].message.text : error.xhr.response[i].errorMessage);
                            }
                        } else {
                            messages.push(error.xhr.response.error ? error.xhr.response.error + ". Please try again and if problem persists call support." : "An unexpected error has occured. Please try again or call support if the problem persists.");
                        }
                    } else {
                        messages.push("An unexpected error has occured. Please try again or call support if the problem persists.");
                    }
                    return [{ type: appActionTypes.APP_SUBMISSION_OUTCOME, payload: { type: actionTypes.SAVE_DATASET, errors: messages } }];
                },
            })
        );
    });
};

export const executeAnalysis = (farm, budget) => {
    return api.post({
        path: `engine/execute/lysimeter`,
        content: { region: farm.region, town: farm.nearestTown, budget },
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: actionTypes.LYSIMETER_RESULTS_FETCHED, payload: response });
            };
        },
    });
};

export const exportDataset = (datasetId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Datasets",
            action: "exportDataset",
        });
        dispatch({ type: actionTypes.EXPORT_DATASET });
        dispatch(
            api.get({
                path: `science/datasets/${datasetId}/export`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
                cancelActionType: actionTypes.EXPORT_DATASET_CANCEL,
            })
        );
    });
};

// Import a dataset of analyses
export const importDataset = (datasetId, json) => {
    ReactGA.event({
        category: "Dataset",
        action: "importDataset",
    });
    return api.post({
        path: `science/datasets/${datasetId}/import`,
        content: json,
        cancelActionType: appActionTypes.APP_SUBMISSION_CANCEL,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: appActionTypes.APP_SUBMISSION_OUTCOME, payload: { type: actionTypes.IMPORT_DATASET } });
                dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
                dispatch(push(`/app/datasets/${datasetId}`));
            };
        },
        onFailure: (error) => {
            error.handled = true;
            var messages = [];
            if (error.response && error.response.error) {
                messages = [error.response.error + " (" + error.response.requestId + ")"];
            } else if (error.response && error.response.errors) {
                messages = error.response.errors.map((e) => `${e.field} (${e.code}): ${e.message}`);
            } else if (error.xhr && error.response) {
                messages = Object.keys(error.response).map((k) => k + ": " + error.response[k].map((t) => t).join(", "));
            } else {
                messages.push("An unexpected error has occured. Please try again or call support if the problem persists.");
            }
            return { type: appActionTypes.APP_SUBMISSION_OUTCOME, payload: { type: actionTypes.IMPORT_DATASET, errors: messages } };
        },
    });
};

export function cancelModelRun() {
    return { type: actionTypes.DATASET_MODEL_RUN_CANCEL };
}

// Cancel import dataset data
export const cancelImportDataset = () => {
    return { type: appActionTypes.APP_SUBMISSION_CANCEL };
};

export const updateCropsPreviousYears = (datasetId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Datasets",
            action: "updateCropsPreviousYears",
        });
        dispatch({ type: actionTypes.UPDATE_CROPS_PREVIOUS_YEAR });
        dispatch(
            api.post({
                path: `science/datasets/${datasetId}/updatecroppreviousyears`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
                cancelActionType: actionTypes.UPDATE_CROPS_PREVIOUS_YEAR_CANCEL,
            })
        );
    });
};

export const copyDataset = (datasetId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Datasets",
            action: "Copy Dataset",
        });
        dispatch(
            api.post({
                path: `science/datasets/${datasetId}/copy`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};
