import { Component } from "react";
import { Link } from "react-router-dom";
import { farmYear } from "common/domain";
import * as utils from "common/utils";
import * as domain from "common/domain";
import * as validations from "common/validations";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Button } from "components/Button";
import * as animalUtils from "../_utils";
import DistributionErrors from "../DistributionErrors";
import BlockDistribution from "./BlockDistribution";
import AnimalReports from "./AnimalReports";
import Field from "./Field";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Alert from "components/Alert";
import SavePrompt from "components/SavePrompt";
import { useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { useAnalysisResults } from "containers/BudgetHome";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function DistributionDetails({ farm, analysis, viewModel, isMerino }) {
    const refData = useRefData();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const { data: analysisResults } = useAnalysisResults(analysis);

    return <DistributionDetailsClassComponent farm={farm} analysis={analysis} viewModel={viewModel} isMerino={isMerino} updateAnalysisAsync={updateAnalysisAsync} analysisResults={analysisResults} refData={refData} />
}

class DistributionDetailsClassComponent extends Component {
    constructor(props) {
        super(props);

        const fields = {
            enterpriseTypes: { value: props.viewModel.enterpriseTypes },
            blockIds: { value: props.viewModel.distributions.map((d) => d.blockId) },
            relProdMethod: { value: props.viewModel.relProdMethod },
            blockStockRatios: { value: props.viewModel.blockStockRatios },
            distributions: { value: props.viewModel.distributions },
        };

        /* eslint-disable no-unused-vars */
        for (const distribution of props.viewModel.distributions) {
            let key = `${distribution.blockId}.relativeProductivity`;
            fields[key] = { value: distribution.relativeProductivity };

            /* eslint-disable no-unused-vars */
            for (const animal of distribution.animals) {
                key = `${distribution.blockId}.${animal.enterpriseType}.specifyGrazingMonths`;
                fields[key] = { value: animal.specifyGrazingMonths };
                key = `${distribution.blockId}.${animal.enterpriseType}.percenBlockPastureProd`;
                fields[key] = { value: animal.percenBlockPastureProd };
                key = `${distribution.blockId}.${animal.enterpriseType}.accesstostreams`;
                fields[key] = { value: animal.hasStreamAccess };
                key = `${distribution.blockId}.${animal.enterpriseType}.finishing`;
                fields[key] = { value: animal.isFinishing };
                key = `${distribution.blockId}.${animal.enterpriseType}.wallows`;
                fields[key] = { value: animal.isWallows };
                key = `${distribution.blockId}.${animal.enterpriseType}.pace`;
                fields[key] = { value: animal.isPaceFenceline };
                key = `${distribution.blockId}.${animal.enterpriseType}.merino`;
                fields[key] = { value: animal.isMerino };
            }
        }

        this.state = {
            fields: fields,
            showRelProdMethod: this._showRelProdMethod(props.analysis.blocks, fields.distributions.value),
            dirty: false,
            isMerino: props.isMerino,
            submitting: false,
            submitSucceeded: false,
            isAnimalRptOpen: false,
            hasOutdoorPigs: props.viewModel.hasOutdoorPigs,
        };
    }

    _showRelProdMethod = (blocks, distributions) => {
        const allAreFruitBlocks = distributions.every((d) => blocks.find((b) => b.type === "ProductiveFruit" && b.id === d.blockId));
        return !allAreFruitBlocks;
    };

    _enterpriseTypesRequired = (enterpriseTypes) => (!enterpriseTypes || enterpriseTypes.length === 0 ? "Required" : undefined);

    _blocksRequired = (selectedBlockIds) => (!selectedBlockIds || selectedBlockIds.length === 0 ? "Required" : undefined);

    _pastureGrazedMustEqual100Percent = (distribution) => (distribution.animals.reduce((sum, animal) => (sum += utils.round(animal.percenBlockPastureProd, 0)), 0) !== 100 ? "The sum of pasture grazed for a block must equal 100%" : undefined);

    _validatePercentageGrazed = (enterpriseTypes, distributions, blockStockRatios) => {
        if (enterpriseTypes.length < 2 || distributions.length < 2 || !animalUtils.showPercenBlockPastureProd(blockStockRatios)) return undefined;

        const invalidBlocks = distributions.reduce((blockIds, distribution) => {
            const total = distribution.animals.reduce((sum, animal) => (sum += utils.round(animal.percenBlockPastureProd, 0)), 0);
            if (total === 100) return blockIds;

            blockIds.push({ blockId: distribution.blockId, total: total });
            return blockIds;
        }, []);

        if (invalidBlocks.length === 0) return undefined;

        return {
            blockErrors: invalidBlocks,
            error: "The sum of pasture grazed for a block must equal 100%",
        };
    };

    _validateEnterpriseDistribution = (enterpriseTypes, distributions) => {
        const distributedEnterpriseTypes = distributions.reduce((enterpriseTypes, distribution) => {
            distribution.animals.forEach((a) => {
                if (!enterpriseTypes.includes(a.enterpriseType)) enterpriseTypes.push(a.enterpriseType);
            });
            return enterpriseTypes;
        }, []);
        const missing = enterpriseTypes.filter((enterpriseType) => !distributedEnterpriseTypes.some((distributed) => distributed === enterpriseType));
        const isInvalid = distributedEnterpriseTypes.length < enterpriseTypes.length;
        return isInvalid ? `The following enterprises have not been distributed on any blocks - ${missing.join(", ")}` : undefined;
    };

    _validateField = (key, value, enterpriseTypes, distributions, relProdMethod, blockStockRatios, blocks) => {
        switch (key) {
            case "enterpriseTypes":
                return this._enterpriseTypesRequired(value);
            case "blockIds":
                return this._blocksRequired(value);
            case "relProdMethod":
                return this.state.showRelProdMethod ? validations.required(value) : undefined;
            default:
                break;
        }

        const keySplit = key.split(".");
        const blockId = keySplit[0];

        if (!distributions.some((d) => d.blockId === blockId)) return undefined;

        if (this.state.showRelProdMethod) {
            if (key.endsWith(".relativeProductivity") && animalUtils.showRelativeProductivity(relProdMethod)) {
                const block = (blocks || []).find((b) => b.id === blockId);
                if (!block || block.type === "ProductiveFruit") return undefined;

                let error = validations.required(value);
                error = error || validations.range(0.1, 9999999)(value);
                return error;
            }
        }

        if (key.endsWith(".percenBlockPastureProd") && enterpriseTypes.length > 1 && distributions.length > 1 && animalUtils.showPercenBlockPastureProd(blockStockRatios)) {
            const enterpriseType = keySplit[1];
            const distribution = distributions.find((d) => d.blockId === blockId && d.animals.some((a) => a.enterpriseType === enterpriseType));
            if (!distribution) return undefined;

            let error = validations.required(value);
            error = error || validations.range(1, 100)(value);
            return error;
        }
    };

    _validateForm = (analysis, fields) => {
        let submitError = false;

        const blocks = analysis.blocks.filter((block) => animalUtils.canDistributeAnimals(block));
        const enterpriseTypes = fields.enterpriseTypes.value;
        const distributions = fields.distributions.value;
        const relProdMethod = fields.relProdMethod.value;
        const blockStockRatios = fields.blockStockRatios.value;

        /* eslint-disable no-unused-vars */
        for (const key in fields) {
            const error = this._validateField(key, fields[key].value, enterpriseTypes, distributions, relProdMethod, blockStockRatios, blocks);
            if (error) submitError = true;

            fields = {
                ...fields,
                [key]: {
                    ...fields[key],
                    value: fields[key].value,
                    touched: fields[key].touched || error !== undefined,
                    error: error,
                },
            };
        }

        const percentageGrazedError = this._validatePercentageGrazed(enterpriseTypes, distributions, blockStockRatios);
        if (percentageGrazedError) submitError = true;

        const enterpriseDistributionError = this._validateEnterpriseDistribution(enterpriseTypes, distributions);
        if (enterpriseDistributionError) submitError = true;

        this.setState({ fields: fields, percentageGrazedError: percentageGrazedError, enterpriseDistributionError: enterpriseDistributionError });
        return submitError === false;
    };

    _setFieldValues = (values, trigger) => {
        let fields = this.state.fields;

        const enterpriseTypes = values.enterpriseTypes || fields.enterpriseTypes.value;
        const distributions = values.distributions || fields.distributions.value;
        const relProdMethod = values.relProdMethod || fields.relProdMethod.value;
        const blockStockRatios = values.blockStockRatios || fields.blockStockRatios.value;

        /* eslint-disable no-unused-vars */
        for (const key in values) {
            fields = {
                ...fields,
                [key]: {
                    ...fields[key],
                    value: values[key],
                    touched: fields[key].touched || key === trigger,
                    error: this._validateField(key, values[key], enterpriseTypes, distributions, relProdMethod, blockStockRatios),
                },
            };
        }

        /* eslint-disable no-unused-vars */
        for (const key in fields) {
            if (key.endsWith(".relativeProductivity") || key.endsWith(".percenBlockPastureProd") || key.endsWith(".specifyGrazingMonths")) {
                const keySplit = key.split(".");
                const blockId = keySplit[0];
                const distribution = distributions.find((d) => d.blockId === blockId);
                if (!distribution) {
                    fields = {
                        ...fields,
                        [key]: {
                            value: undefined,
                        },
                    };
                }

                if (keySplit.length > 2) {
                    const enterpriseType = keySplit[1];
                    const animal = distribution && distribution.animals.find((a) => a.enterpriseType === enterpriseType);
                    if (!animal) {
                        fields = {
                            ...fields,
                            [key]: {
                                value: undefined,
                            },
                        };
                    }
                }
            }
        }

        /* eslint-disable no-unused-vars */
        for (const distribution of distributions) {
            let key = `${distribution.blockId}.relativeProductivity`;
            let error = this._validateField(key, distribution.relativeProductivity, enterpriseTypes, distributions, relProdMethod, blockStockRatios);
            if (this.state.showRelProdMethod) {
                fields = {
                    ...fields,
                    [key]: {
                        ...fields[key],
                        value: distribution.relativeProductivity,
                        touched: (fields[key] && fields[key].touched) || key === trigger,
                        error: error,
                    },
                };
            }

            /* eslint-disable no-unused-vars */
            for (const animal of distribution.animals) {
                key = `${distribution.blockId}.${animal.enterpriseType}.percenBlockPastureProd`;
                error = this._validateField(key, animal.percenBlockPastureProd, enterpriseTypes, distributions, relProdMethod, blockStockRatios);
                fields = {
                    ...fields,
                    [key]: {
                        ...fields[key],
                        value: animal.percenBlockPastureProd,
                        touched: (fields[key] && fields[key].touched) || key === trigger,
                        error: error,
                    },
                };

                key = `${distribution.blockId}.${animal.enterpriseType}.specifyGrazingMonths`;
                error = this._validateField(key, animal.specifyGrazingMonths, enterpriseTypes, distributions, relProdMethod, blockStockRatios);
                fields = {
                    ...fields,
                    [key]: {
                        ...fields[key],
                        value: animal.specifyGrazingMonths,
                        touched: (fields[key] && fields[key].touched) || key === trigger,
                        error: error,
                    },
                };
            }
        }

        const percentageGrazedError = this._validatePercentageGrazed(enterpriseTypes, distributions, blockStockRatios);

        const enterpriseDistributionError = this._validateEnterpriseDistribution(enterpriseTypes, distributions);

        const showRelProdMethod = this._showRelProdMethod(this.props.analysis.blocks, distributions);

        this.setState({ fields, percentageGrazedError, enterpriseDistributionError, showRelProdMethod, dirty: true });
    };

    _onRelProdMethodChanged = (e) => {
        const newValue = e.target.value;
        this._setFieldValues({ relProdMethod: newValue }, "relProdMethod");
    };

    _onBlockStockRatiosChanged = (e) => {
        const fields = this.state.fields;
        const newValue = e.target.value;
        let distributions = fields.distributions.value.map((distribution) => {
            let animals = distribution.animals;
            fields.enterpriseTypes.value.forEach((enterpriseType) => {
                if (!animals.some((a) => a.enterpriseType === enterpriseType)) {
                    animals = animals.concat({
                        enterpriseType: enterpriseType,
                        months: [],
                    });
                }
            });
            return {
                ...distribution,
                animals: animals,
            };
        });

        this._setFieldValues(
            {
                distributions: distributions,
                blockStockRatios: newValue,
            },
            "blockStockRatios"
        );
    };

    _onBlockEnterpriseChanged = (blockId, enterpriseType, selected) => {
        const fields = this.state.fields;
        const distributions = fields.distributions.value.map((distribution) => {
            if (distribution.blockId !== blockId) return distribution;

            const animals = distribution.animals.filter((a) => a.enterpriseType !== enterpriseType);
            if (selected === true) {
                animals.push({
                    enterpriseType: enterpriseType,
                    months: [],
                });
            }

            return {
                ...distribution,
                animals: animals,
            };
        });
        this._setFieldValues({ distributions: distributions }, "distributions");
    };

    _onBlockSpecifyGrazingMonthsChanged = (blockId, enterpriseType, selected) => {
        const fields = this.state.fields;
        const distributions = fields.distributions.value.map((distribution) => {
            if (distribution.blockId !== blockId) return distribution;

            const animals = distribution.animals.map((animal) => {
                if (animal.enterpriseType !== enterpriseType) return animal;

                const months =
                    selected === false
                        ? []
                        : (animal.pastureRsu || []).reduce((results, rsu) => {
                            if (rsu.value > 0) results.push(rsu.month);
                            return results;
                        }, []);

                return {
                    ...animal,
                    months,
                    specifyGrazingMonths: selected,
                };
            });

            return {
                ...distribution,
                animals,
            };
        });
        const trigger = `${blockId}.${enterpriseType}.specifyGrazingMonths`;
        this._setFieldValues(
            {
                distributions: distributions,
            },
            trigger
        );
    };

    _onBlockMonthChanged = (blockId, enterpriseType, month, selected) => {
        const fields = this.state.fields;
        const distributions = fields.distributions.value.map((distribution) => {
            if (distribution.blockId !== blockId) return distribution;

            const animals = distribution.animals.map((animal) => {
                if (animal.enterpriseType !== enterpriseType) return animal;

                let months = animal.months.filter((m) => m !== month);
                if (selected) months.push(month);

                // Select all if last one deselected
                if (months.length === 0) months = farmYear.map((month) => month);

                return {
                    ...animal,
                    months,
                };
            });

            return {
                ...distribution,
                animals: animals,
            };
        });
        this._setFieldValues({ distributions: distributions }, "distributions");
    };

    _onBlockRelativeProductivityChanged = (blockId, newValue) => {
        const fields = this.state.fields;
        const distributions = fields.distributions.value.map((distribution) => {
            if (distribution.blockId !== blockId) return distribution;

            return {
                ...distribution,
                relativeProductivity: newValue,
            };
        });
        const trigger = `${blockId}.relativeProductivity`;
        this._setFieldValues(
            {
                distributions: distributions,
            },
            trigger
        );
    };

    _onBlockPercenBlockPastureProdChanged = (blockId, enterpriseType, newValue) => {
        const fields = this.state.fields;
        const distributions = fields.distributions.value.map((distribution) => {
            if (distribution.blockId !== blockId) return distribution;

            const animals = distribution.animals.map((animal) => {
                if (animal.enterpriseType !== enterpriseType) return animal;

                return {
                    ...animal,
                    percenBlockPastureProd: newValue,
                };
            });

            return {
                ...distribution,
                animals: animals,
            };
        });
        const trigger = `${blockId}.${enterpriseType}.percenBlockPastureProd`;
        this._setFieldValues(
            {
                distributions: distributions,
            },
            trigger
        );
    };

    _onBlockCheckBoxesChanged = (blockId, enterpriseType, type, newValue) => {
        const fields = this.state.fields;
        const distributions = fields.distributions.value.map((distribution) => {
            if (distribution.blockId !== blockId) return distribution;

            const animals = distribution.animals.map((animal) => {
                if (animal.enterpriseType !== enterpriseType) return animal;

                if (type === "accesstostreams") {
                    return {
                        ...animal,
                        hasStreamAccess: newValue,
                    };
                } else if (type === "finishing") {
                    return {
                        ...animal,
                        isFinishing: newValue,
                    };
                } else if (type === "wallows") {
                    return {
                        ...animal,
                        isWallows: newValue,
                    };
                } else if (type === "pace") {
                    return {
                        ...animal,
                        isPaceFenceline: newValue,
                    };
                } else if (type === "merino") {
                    return {
                        ...animal,
                        isMerino: newValue,
                    };
                } else return animal;
            });

            return {
                ...distribution,
                animals: animals,
            };
        });
        this._setFieldValues(
            {
                distributions: distributions,
            },
            "distributions"
        );
    };

    _save = (analysis) => async (e) => {
        e.preventDefault();

        const fields = this.state.fields;
        const isValid = this._validateForm(analysis, fields);
        if (!isValid) return;

        this.setState({ submitting: true, submitSucceeded: false });

        const animalDistribution = {
            enterpriseTypes: fields.enterpriseTypes.value,
            hasOutdoorPigs: this.state.hasOutdoorPigs,
            distributions: fields.distributions.value,
            relProdMethod: fields.relProdMethod.value,
            blockStockRatios: fields.blockStockRatios.value,
        };

        const updatedAnalysis = animalUtils.getUpdatedAnalyisFromSavingDistribution(analysis, animalDistribution);
        await this.props.updateAnalysisAsync(updatedAnalysis);

        this.setState({ submitting: false, submitSucceeded: true });
    };

    render() {
        const { farm, analysis, refData, isMerino, analysisResults } = this.props;
        const referrer = `/app/farm/${farm.id}/analysis/${analysis.id}/animals`;
        const title = "Animal distribution";
        const animalBlocks = analysis.blocks.filter((block) => animalUtils.canDistributeAnimals(block));
        const fields = this.state.fields;
        const blockStockRatiosOptions = animalUtils.getBlockStockRatiosOptions(refData);
        const animalResults = analysisResults?.animals;
        const relProdTip = this.state.showRelProdMethod && domain.RelativeProductivity.find((t) => t.key === fields.relProdMethod.value);
        const blockStockRatiosTip = domain.BlockStockRatios.find((t) => t.key === fields.blockStockRatios.value);

        return (
            <form onSubmit={this._save(analysis)}>
                <SavePrompt blockIf={this.state.dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={referrer} />
                <Tile title={title} waiting={this.state.submitting} referrer={referrer}>
                    <Alert
                        type="info"
                        html="<p>By default, animal enterprises are distributed evenly on the farm based on their energy (hence feed) requirements and the availability of food. This can be overridden by setting the location of enterprises (to blocks) and/or changing the relative productivity of blocks (how much feed they grow).</p>                    
                                            <p>Productivity is the rate at which pasture is grown, not the total amount produced. Relative productivity describes each blocks rate of production relative to each other. For example if one block is 1 and another is 2, the second block produces at a rate per ha that is twice the first block.</p>
                    "
                    />
                    <TileBody>
                        <DistributionErrors analysis={analysis} />
                        <div className="Grid Grid--withGutter">
                            <div className="Grid-cell u-lg-width1of1">
                                {this.state.showRelProdMethod && (
                                    <>
                                        <Field name="relProdMethod" fields={this.state.fields} label="Relative productivity" required={true} placeholder="Select relative productivity method" onChange={this._onRelProdMethodChanged} dataWidth="75" options={refData.relativeProductivityMethods} component={Select} />
                                        {relProdTip && <Alert type="info" text={relProdTip.text} />}
                                    </>
                                )}
                                {fields.enterpriseTypes.value.length > 1 && fields.blockIds.value.length > 1 && refData && refData.blockStockRatios && (
                                    <>
                                        <Field name="blockStockRatios" fields={this.state.fields} label="How do you define the % of pasture eaten by each animal enterprise on each block?" required={true} onChange={this._onBlockStockRatiosChanged} options={blockStockRatiosOptions} component={RadioGroup} />
                                        {blockStockRatiosTip && <Alert type="info" text={blockStockRatiosTip.text} />}
                                    </>
                                )}
                            </div>
                            {fields.distributions.value.length > 0 && (
                                <div className="Grid-cell">
                                    <BlockDistribution
                                        fields={fields}
                                        blocks={animalBlocks}
                                        enterpriseTypes={fields.enterpriseTypes.value}
                                        distributions={fields.distributions.value}
                                        relProdMethod={this.state.showRelProdMethod ? fields.relProdMethod.value : undefined}
                                        blockStockRatios={fields.blockStockRatios.value}
                                        percentageGrazedError={this.state.percentageGrazedError}
                                        enterpriseDistributionError={this.state.enterpriseDistributionError}
                                        onEnterpriseChanged={this._onBlockEnterpriseChanged}
                                        onSpecifyGrazingMonthsChanged={this._onBlockSpecifyGrazingMonthsChanged}
                                        onBlockCheckBoxesChanged={this._onBlockCheckBoxesChanged}
                                        onMonthChanged={this._onBlockMonthChanged}
                                        onRelativeProductivityChanged={this._onBlockRelativeProductivityChanged}
                                        onPercenBlockPastureProdChanged={this._onBlockPercenBlockPastureProdChanged}
                                        isMerino={isMerino} />
                                </div>
                            )}
                            {animalResults && (
                                <div className="Grid-cell">
                                    <AnimalReports farm={farm} analysis={analysis} />
                                </div>
                            )}
                        </div>
                    </TileBody>
                    <TileFooter>
                        <div className="ButtonBar ButtonBar--fixed">
                            <div className="ButtonBar-left">
                                <Link to={referrer} className="Button Button--secondary" id="cancel-button">
                                    Cancel
                                </Link>
                            </div>
                            <div className="ButtonBar-right">
                                <Button id="submit-button" submit primary waiting={this.state.submitting}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </TileFooter>
                </Tile>
            </form>
        )
    }
}
