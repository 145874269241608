import React from "react";
import { withAppContext } from "containers/HOC";
import { FieldArray } from "react-final-form-arrays";
import * as utils from "common/utils";
import ActionLink from "components/ActionLink";

const toggleEnterpriseSelection = (form, enterprisesFieldName, selectedEnterpriseIds, enterpriseId) => (e) => {
    const index = selectedEnterpriseIds.findIndex((id) => id === enterpriseId);

    form.blur(enterprisesFieldName);

    if (index >= 0) form.mutators.remove(enterprisesFieldName, index);
    else form.mutators.push(enterprisesFieldName, enterpriseId);
};

const validateEnterprises = (enterprisesFieldName) => (enterpriseIds) => {
    const errors = [];

    if (!enterpriseIds || enterpriseIds.length === 0) errors.push({ [enterprisesFieldName]: "Required" });

    return errors;
};

const EnterpriseSelector = (props) => {
    const { form, enterprisesFieldName, availableEnterprises, refData } = props;

    const enterprisesFieldState = form.getFieldState(enterprisesFieldName);
    const enterprisesAreInvalid = enterprisesFieldState && enterprisesFieldState.touched && enterprisesFieldState.error.length > 0;

    return (
        <React.Fragment>
            <h3>Animal enterprises</h3>
            <p>Select the enterprises where DCD is applied after grazing</p>
            <div className={`Field u-mt-md ${enterprisesAreInvalid ? "has-error" : ""}`}>
                <ul className="BlockList">
                    <FieldArray name={enterprisesFieldName} validate={validateEnterprises(enterprisesFieldName)}>
                        {({ fields }) => {
                            const selectedEnterpriseIds = fields.value || [];
                            return availableEnterprises.map((enterprise, i) => {
                                const key = `${enterprisesFieldName}_${enterprise.id}`;
                                const isSelected = selectedEnterpriseIds.includes(enterprise.id);
                                const enterpriseType = utils.valueToText(refData.enterpriseTypes, enterprise.type);
                                return (
                                    <li key={key} id={key} className={`BlockList-item ${isSelected ? "is-active" : ""}`} onClick={toggleEnterpriseSelection(form, enterprisesFieldName, selectedEnterpriseIds, enterprise.id)}>
                                        <span id={`${key}_name`}>{enterpriseType}</span>
                                        {isSelected && (
                                            <ActionLink className="BlockList-item-close">
                                                <i className="icon icon-cross" />
                                            </ActionLink>
                                        )}
                                    </li>
                                );
                            });
                        }}
                    </FieldArray>
                </ul>
                {enterprisesAreInvalid && <small className="Field-error">Required</small>}
            </div>
        </React.Fragment>
    );
};

export default withAppContext(EnterpriseSelector);
