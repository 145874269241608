import { Component } from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";
import SavePrompt from "components/SavePrompt";
import NotFound from "components/NotFound";
import Alert from "components/Alert";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import { Button } from "components/Button";
import PastureFormFields, { validatePastureFormFields } from "./PastureFormFields";
import * as cropUtils from "../_utils";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function Pasture({ farm, analysis }) {
    const { blockId } = useParams();
    const block = analysis.blocks.find((b) => b.id === blockId);
    let viewModel = undefined;
    if (block) {
        const pasture = block.pasture || {};
        const availableBlocks = analysis.blocks.filter((b) => b.type === "ProductivePasture");
        const hasAnimals = block.runoffCharacteristics ? true : false;
        const primaryLandUse = block.pasture && block.pasture.pastureCategory ? (hasAnimals ? "Pastoral" : "CutAndCarry") : undefined;

        viewModel = {
            ...pasture,
            blockIds: [block.id],
            runoffCharacteristics: block.runoffCharacteristics,
            hasAnimals,
            primaryLandUse,
            availableBlocks,
        };
    }
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis); 

    return <PastureClassComponent farm={farm} analysis={analysis} block={block} viewModel={viewModel} updateAnalysisAsync={updateAnalysisAsync} />
}

class PastureClassComponent extends Component {
    state = {
        referrer: `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/crops`,
        submitSucceeded: false,
    };

    save = async (viewModel) => {
        const { analysis } = this.props;

        const { blockIds, runoffCharacteristics } = viewModel;

        const pasture = {
            pastureCategory: viewModel.pastureCategory,
            pastureCultivated: viewModel.pastureCultivated,
            plantainPercentage: viewModel.pastureCategory === "Plantain" ? viewModel.plantainPercentage : 0,
            hasAnimals: viewModel.hasAnimals,
        };

        const updatedAnalysis = cropUtils.getUpdatedAnalysisFromSavingPasture(analysis, blockIds, pasture, runoffCharacteristics);
        await this.props.updateAnalysisAsync(updatedAnalysis);

        this.setState({ submitSucceeded: true });
    };

    render() {
        const { farm, analysis, block, viewModel } = this.props;

        if (!block) {
            return <NotFound />;
        }

        return (
            <Panel>
                <PageTabs farm={farm} analysis={analysis} tab={TAB.CROPS} />
                <PanelBody>
                    <Form initialValues={viewModel} keepDirtyOnReinitialize={true} validate={validatePastureFormFields} onSubmit={this.save}>
                        {({ form, values, handleSubmit, pristine, submitting, dirty }) => {
                            const selectedBlockNames = values.availableBlocks
                                .filter((b) => values.blockIds.includes(b.id))
                                .map((b) => b.name)
                                .join(", ");
                            const title = selectedBlockNames.length > 0 ? `Pasture details: ${selectedBlockNames}` : "Pasture details";

                            return (
                                <form onSubmit={handleSubmit}>
                                    <SavePrompt blockIf={dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={this.state.referrer} />
                                    <Panel title={title} waiting={submitting} referrer={this.state.referrer}>
                                        <Alert
                                            type="info"
                                            html="<p>The pasture type affects ME and pasture N content hence N leaching. Therefore it is very important to select the correct pasture type for the block. It is important to differentiate between lucerne and other pasture types. Differences between other pasture types for N leaching is less pronounced as there is a rebalancing between lower quality pasture, low N content but higher intake.</p>
                                                                <p>You can set the pasture and runoff characteristics for multiple blocks at the same time. Simply add each block to the selection below. This will apply these values to each block. Individual blocks can be changed after saving.</p>
                                                                "
                                        />
                                        <PanelBody waiting={submitting}>
                                            <PastureFormFields form={form} values={values} />
                                        </PanelBody>
                                        <PanelFooter>
                                            <div className="ButtonBar ButtonBar--fixed">
                                                <div className="ButtonBar-left">
                                                    <Link to={this.state.referrer} className="Button Button--secondary" id="pasture-cancel" disabled={submitting}>
                                                        Cancel
                                                    </Link>
                                                </div>
                                                <div className="ButtonBar-right">
                                                    <Button id="pasture-submit" submit primary waiting={submitting} disabled={pristine}>
                                                        Save
                                                    </Button>
                                                </div>
                                            </div>
                                        </PanelFooter>
                                    </Panel>
                                </form>
                            );
                        }}
                    </Form>
                </PanelBody>
            </Panel>
        );
    }
}
