import * as domain from "common/domain";
import { getBlockList } from "common/utils";
import { useRefData } from "common/hooks";
import TileSubPanel from "components/TileSubPanel";
import BlockListCard from "./BlockListCard";

export default function BlockList({ farm, analysis }) {
    const refData = useRefData();
    const blocks = analysis.blocks.filter((b) => b.type !== domain.BlockType.FodderCrop);

    const blockList = getBlockList(blocks);

    return (
        <div>
            {refData.blockTypes &&
                refData.blockTypes.map((blockType) => {
                    const blocksByType = blockList.productiveBlocks.filter((block) => block.type === blockType.value);
                    return (
                        blocksByType.length > 0 && (
                            <TileSubPanel key={blockType.value} title={`${blockType.text} ${blockType.value === "FodderCrop" ? "rotations" : " blocks"}`} stickyHeaderName={`analysis.climate.${blockType.value}.collapsed`}>
                                {blocksByType.map((block) => (
                                    <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                                ))}
                            </TileSubPanel>
                        )
                    );
                })}
            {blockList.nonProductiveBlocks.length > 0 && (
                <TileSubPanel title="Other blocks" stickyHeaderName="analysis.climate.otherBlocks.collapsed">
                    {blockList.nonProductiveBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
        </div>
    )
}
