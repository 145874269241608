import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import * as validations from "common/validations";
import InputPack from "components/InputPack";
import { resetPassword } from "./_actions";

class ResetPassword extends Component {
    _resetPassword = (formValues) => {
        this.props.resetPassword(this.props.resetToken, formValues.password);
    };

    render() {
        const { online, isAuthenticated, handleSubmit } = this.props;

        if (isAuthenticated) {
            return <Redirect to="/" />;
        }

        return (
            <div className="Tile u-pb-xl">
                <div className="Grid">
                    <div className="Grid-cell Grid-cell--center u-textCenter u-pt-lg">
                        <h2>Reset your password</h2>
                    </div>
                    <div className="Grid-cell Grid-cell--center u-md-width2of3 u-lg-width1of2">
                        {!online && (
                            <div className="Alert Alert--error u-mt-lg">
                                <i className="icon icon-alert" id="reset-errormsg" />
                                Overseer is currently offline. Please try again later.
                            </div>
                        )}
                        <form onSubmit={handleSubmit(this._resetPassword)}>
                            <Field name="password" type="password" label="Password" requiredLabel={true} component={InputPack} validate={[validations.required, validations.maxLength(50), validations.minLength(8)]} placeholder="Enter your password" />
                            <Field name="confirmPassword" type="password" label="Confirm password" requiredLabel={true} component={InputPack} validate={[validations.required, validations.maxLength(50), validations.minLength(8)]} placeholder="Confirm your password" />
                            <div className="Grid--alignCenter u-mt-lg">
                                <div className="Grid-cell--center u-md-width1of2">
                                    <button type="submit" disabled={!online} className="Button Button--block u-mb-lg">
                                        Reset password
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const _validate = (formValues) => {
    const errors = {};

    if (formValues.confirmPassword !== formValues.password) errors.confirmPassword = "Password does not match";

    return errors;
};

const _mapStateToProps = (state, ownProps) => {
    const { app, auth } = state;
    return {
        ...ownProps,
        resetToken: ownProps.match.params.resetToken,
        online: app.online,
        isAuthenticated: auth.isAuthenticated,
    };
};

const ResetPasswordForm = reduxForm({
    form: "resetPasswordForm",
    validate: _validate,
})(ResetPassword);

export default connect(_mapStateToProps, { resetPassword })(ResetPasswordForm);
