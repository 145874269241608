import { connect } from "react-redux";
import LoadXMLFarms from "containers/Datasets/LoadXMLFarms";
import LoadAnalyses from "containers/Datasets/LoadAnalyses";
import LivestockModal from "containers/BudgetHome/Animals/Livestock/LivestockModal";
import InviteUserModal1 from "containers/AccountDetails/InviteUserModal";
import ForgotPasswordModal from "containers/Auth/ForgotPasswordModal";
import RefreshTokenModal from "containers/Auth/RefreshTokenModal";
import MyAccountModal from "containers/AccountDetails/MyAccountModal";
import ModelRunModal from "containers/Datasets/ModelRunModal";
import DatasetModal from "containers/Datasets/DatasetModal";
import { closeModal } from "containers/App/_actions";
import FatalModal from "./FatalModal";
import InviteStudentModal from "containers/MyOrg/InviteStudentModal";
import EditStudentModal from "containers/MyOrg/EditStudentModal";
import CopyCropsModal from "../containers/BudgetHome/Crops/CopyCropsModal";

export const modalType = {
    LOAD_XML_FARMS: "LOAD_XML_FARMS",
    IMPORT_DATASET: "IMPORT_DATASET",
    LIVESTOCK_DETAILS: "LIVESTOCK_DETAILS",
    INVITE_USER: "INVITE_USER",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    MY_ACCOUNT: "MY_ACCOUNT",
    MODEL_RUN_MODAL: "MODEL_RUN_MODAL",
    DATASET_MODAL: "DATASET_MODAL",
    INVITE_STUDENT: "INVITE_STUDENT",
};

const ModalConductor = (props) => {
    if (props.modal.onConfirm === undefined) return null;

    switch (props.modal.modalType) {
        case "FATAL":
            return <FatalModal {...props} />;
        case "LOGIN":
            return <RefreshTokenModal initialValues={props.modal.props} {...props} />;
        case modalType.LOAD_XML_FARMS:
            return <LoadXMLFarms {...props} />;
        case modalType.IMPORT_DATASET:
            return <LoadAnalyses {...props} />;
        case modalType.LIVESTOCK_DETAILS:
            return <LivestockModal initialValues={props.modal.props} {...props} />;
        case modalType.INVITE_USER:
            return <InviteUserModal1 initialValues={props.modal.props} {...props} />;
        case modalType.INVITE_STUDENT:
            return <InviteStudentModal initialValues={props.modal.props} {...props} />;
        case modalType.FORGOT_PASSWORD:
            return <ForgotPasswordModal initialValues={props.modal.props} {...props} />;
        case modalType.MY_ACCOUNT:
            return <MyAccountModal initialValues={props.modal.props} {...props} />;
        case modalType.MODEL_RUN_MODAL:
            return <ModelRunModal viewModel={props.modal.props} {...props} />;
        case modalType.DATASET_MODAL:
            return <DatasetModal initialValues={props.modal.props} {...props} />;
        case "InviteStudentModal":
            return <InviteStudentModal initialValues={props.modal.props} {...props} />;
        case "EditStudentModal":
            return <EditStudentModal initialValues={props.modal.props} {...props} />;
        case "CopyCropsModal":
            return <CopyCropsModal viewModel={props.modal.props} {...props} />;
        default:
            return null;
    }
};

const _mapStateToProps = (state) => {
    return {
        refData: state.app.refData,
        authContext: {
            accountId: state.auth.accountId,
            accountName: state.auth.accountName,
            userId: state.auth.userId,
            fullName: state.auth.fullName,
            isSystemAdmin: state.auth.isSystemAdmin,
            isAdmin: state.auth.isAdmin,
        },
        modal: state.app.modal,
        modalError: state.app.modalError,
    };
};

export default connect(_mapStateToProps, { closeModal })(ModalConductor);
