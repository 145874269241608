import { useRefData } from "common/hooks";
import ActionLink from "components/ActionLink";
import { getModifiedSoilProfileData } from "./_utils";

export default function SoilProfileSummary({ soil, editMode, toggleEditMode, isTextureGroupRequired, isModified }) {
    const refData = useRefData();
    const data = getModifiedSoilProfileData(soil, isTextureGroupRequired, refData);
    const isSmapSoil = soil.sibling && soil.sibling.smapReference;

    return (
        <ul className="DataWidthTable FarmTable">
            {(isModified || editMode || isTextureGroupRequired) && (
                <>
                    {data.soilProfile && (
                        <li data-width="xl-25">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">{data.soilProfile.title}</span>
                                    <span className="FarmTable-value">
                                        {data.soilProfile.items.map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                    {data.topSoil && (
                        <li data-width="xl-25">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">{data.topSoil.title}</span>
                                    <span className="FarmTable-value">
                                        {data.topSoil.items.map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                    {data.lowerProfile && (
                        <li data-width="xl-30">
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">{data.lowerProfile.title}</span>
                                    <span className="FarmTable-value">
                                        {data.lowerProfile.items.map((item) => (
                                            <div key={item}>{item}</div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                </>
            )}
            {!isModified && (!isTextureGroupRequired || !soil.textureGroup) && (
                <li data-width="xl-20">
                    <h4>{isSmapSoil ? "Using S-Map defaults" : "Using system defaults"}</h4>
                </li>
            )}
            {!editMode && !isTextureGroupRequired && !isModified && (
                <li data-width="xl-12.5">
                    <ActionLink className="IconLink--edit u-mt-sm" onClick={toggleEditMode(true)}>
                        {isSmapSoil ? "Override S-Map defaults" : "Override system defaults"}
                    </ActionLink>
                </li>
            )}
            {editMode && !isTextureGroupRequired && !isModified && (
                <li data-width="xl-12.5">
                    <ActionLink className="IconLink--cross-circle u-mt-sm" onClick={toggleEditMode(false)}>
                        {isSmapSoil ? "Use S-Map defaults" : "Use system defaults"}
                    </ActionLink>
                </li>
            )}
            {isModified && (
                <li data-width="xl-12.5">
                    <ActionLink className="IconLink--refresh u-mt-md" onClick={toggleEditMode(false)}>
                        {isSmapSoil ? "Restore S-Map defaults" : "Restore system defaults"}
                    </ActionLink>
                </li>
            )}
        </ul>
    );
}
