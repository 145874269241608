import * as validations from "common/validations";
import * as utils from "common/utils";
import { diff } from "deep-object-diff";
import { v4 as uuidv4 } from "uuid";
import * as cropUtils from "containers/BudgetHome/Crops/_utils";
import * as domain from "common/domain";
import TruckActivities from "../GHG/Transport/TruckActivities";

export const CustomManufacturerId = 1;
export const NoManufacturerId = -1;
export const NoProductId = "0";
export const CompostMulches = "CompostMulches";
export const CreateNewCustomId = "CreateNewCustomId";
export const ProductType = { Product: "Product", Lime: "Lime", Organic: "Organic", Soluble: "Soluble" };
export const amountId = (id) => `fertiliser_activity_amount_${id}`;
export const monthGroupId = (id) => `fertiliser_activity_months_${id}`;
export const nutrients = ["N", "P", "K", "S", "Ca", "Mg", "Na"];

export const groupApplications = (applications = [], onlyKgha = true) => {
    const matchApp = (combinedApp, testApp) => {
        if (onlyKgha && testApp.unit !== "kgha") return false;
        const { blockIds: testAppBlockIds = [] } = testApp;
        const { blockIds: combinedAppBlockIds = [] } = combinedApp;
        //This caters for two identical applications for the same block for the same month.
        //If we didn't have this the two would be merged into one and therefore we would lose an application
        if (testAppBlockIds.find((id) => combinedAppBlockIds.includes(id))) return false;

        const diffObj = diff(combinedApp, testApp);
        delete diffObj.id;
        delete diffObj.month;
        delete diffObj.addedAt;
        delete diffObj.fertiliser;
        delete diffObj.blockIds;
        return Object.keys(diffObj).length === 0;
    };
    const newApplications = applications.reduce((results, app) => {
        const matchedApp = results.find((newApp) => matchApp(newApp, app));
        if (matchedApp) {
            matchedApp.blockIds = [...app.blockIds, ...matchedApp.blockIds];
        } else {
            results.push({ ...app });
        }

        return results;
    }, []);
    return newApplications;
};

export const ungroupApplications = (applications = []) => {
    let newApplications = [];
    applications.forEach((app) => {
        const { blockIds = [] } = app;
        if (blockIds.length > 1 && app.unit === "kgha") {
            const blockApplications = blockIds.map((b) => {
                return { ...app, ...{ id: uuidv4(), blockIds: [b] } };
            });
            newApplications = [...newApplications, ...blockApplications];
        } else {
            newApplications.push(app);
        }
    });
    return newApplications;
};

export const getApplicationAmount = (application, budget) => {
    let amount = 0;
    let kgAmount = 0;
    const { blocks = [] } = budget;
    let amountLabel = "kg/ha";
    const { blockIds: selectedBlockIds = [] } = application;
    const selectedBlocks = selectedBlockIds.map((id) => blocks.find((b) => b.id === id));
    const filteredBlockIds = Number(application.rotationIndex) > 11 || application.isLastPreviousLime ? selectedBlockIds : selectedBlocks.filter((b) => b.type === "ProductiveCrop").map((b) => b.id);

    const kgHaMth = () => {
        const tonnesHaMth = calcTonnesPerHaMonth(application.amount, application.unit, budget.blocks, filteredBlockIds);
        return utils.round((filteredBlockIds.length === 0 ? 0 : tonnesHaMth) * 1000, 2);
    };

    switch (application.unit) {
        case "kgha":
            amount = calcTotalKgPerMonth(application.amount, getApplicationBlockArea(filteredBlockIds, budget.blocks));
            amountLabel = "kgs";
            kgAmount = application.amount || 0;
            break;
        case "kg":
        case "tonnes":
            amount = kgHaMth();
            amountLabel = "kg/ha";
            kgAmount = amount;
            break;
        default:
            amount = 0;
            break;
    }
    return [utils.round(amount, 0), amountLabel, utils.round(kgAmount, 0)];
};

const mapPrevLimeToRotationIndex = (app) => {
    if (app.isLastPreviousLime) {
        if (app.limeDissolvesWithinYear) {
            app.rotationIndex = -1;
        } else {
            switch (app.limeYearsSinceApplied) {
                case 1:
                    app.rotationIndex = -2;
                    break;
                case 2:
                    app.rotationIndex = -3;
                    break;
                case 3:
                    app.rotationIndex = -4;
                    break;
                case 4:
                    app.rotationIndex = -5;
                    break;
                case 5:
                    app.rotationIndex = -6;
                    break;
                default:
                    break;
            }
        }
    }
};

export const updateApplicationModel = (analysis, blockId) => {
    const { fertiliser = [], blocks = [] } = analysis;
    const selectedBlock = blockId && blocks.find((b) => b.id === blockId);
    fertiliser.forEach((f) => {
        const { applications = [] } = f;
        const newApplications = [];
        applications.forEach((app) => {
            const { blockIds = [] } = app;
            const applicationBlocks = blockIds.map((id) => blocks.find((b) => b.id === id));
            const noCrops = applicationBlocks.every((b) => b.type !== "ProductiveCrop");
            const { months = [] } = app;
            months.forEach((m, i) => {
                const newApp = utils.clone(app);
                if (i > 0) newApp.id = uuidv4();
                m.reportingYear = noCrops || m.reportingYear;
                newApp.months = [m];
                const rotationYear = selectedBlock ? cropUtils.rotationYear(selectedBlock, true) : domain.farmYear;
                const indexedYear = rotationYear.length > 12 ? rotationYear : [...rotationYear, ...rotationYear];
                newApp.rotationIndex = cropUtils.rotationIndex(m, indexedYear);
                newApp.isLastPreviousLime = app.isLastPreviousLime || f.isLastPreviousLime;
                if (newApp.isLastPreviousLime) {
                    mapPrevLimeToRotationIndex(newApp);
                }
                newApplications.push(newApp);
            });
            if (months.length === 0) {
                const newApp = utils.clone(app);
                newApp.months = [];
                newApp.isLastPreviousLime = app.isLastPreviousLime || f.isLastPreviousLime;
                if (newApp.isLastPreviousLime) {
                    mapPrevLimeToRotationIndex(newApp);
                }
                newApplications.push(newApp);
            }
        });
        f.applications = newApplications;
        f.isLastPreviousLime = false;
    });
};

export const removeMonth = (application, month, reportingYear) => {
    const newMonths = application.months.filter((m) => !(m.reportingYear === reportingYear && m.month === month));
    if (newMonths.length !== application.months.length) {
        application.months = newMonths;
        return true;
    }
    return false;
};

export const kghaOnly = (viewModel) => viewModel.fertiliser.material === "Organic" && (viewModel.fertiliser.dairyFactoryEffluent === "Userdefined" || viewModel.globalSelect);

export const initFertiliser = (fertiliser, productType) => {
    fertiliser.manufacturerId = CustomManufacturerId;
    fertiliser.manufacturer = productType;
    fertiliser.productName = "";
    fertiliser.nutrientUnit = "Percent";
    fertiliser.nutrients = {};
};

export const calcTonnesPerHaMonth = (amount, unit, blocks, blockIds) => {
    let tonnesperha = 0;
    if (utils.isNumeric(amount, -1)) {
        if (unit === "kgha") return amount / 1000;

        const selectedBlockArea = getApplicationBlockArea(blockIds, blocks);
        switch (unit) {
            case "tonnes":
                tonnesperha = amount / selectedBlockArea;
                break;
            case "kg":
                tonnesperha = amount / 1000 / selectedBlockArea;
                break;
            default:
                console.log("Unit not set");
        }
    }
    return tonnesperha;
};

export const calcTonnesPerMonth = (amount, units, area) => {
    let tonnesMth = 0;
    if (utils.isNumeric(amount, -1)) {
        const parsedAmount = parseFloat(amount);
        switch (units) {
            case "kgha":
                tonnesMth = calcTotalKgPerMonth(amount, area) / 1000;
                break;
            case "tonnes":
                tonnesMth = parsedAmount;
                break;
            case "kg":
                tonnesMth = parsedAmount / 1000;
                break;
            default:
                console.log("Unit not set");
                break;
        }
    }
    return tonnesMth;
};

export const calcTotalKgPerMonth = (amount, area) => {
    if (utils.isNumeric(amount, -1)) {
        const parsedAmount = parseFloat(amount);
        return utils.round(parsedAmount * area, 2);
    }
    return 0;
};

export const getApplicationBlockArea = (blockIds, blocks, fodderDetails) => {
    const selectedBlocks = blockIds.map((id) => {
        return blocks.find((b) => b.id === id);
    });

    if (!fodderDetails) fodderDetails = utils.getFodderBlockAreas(blocks);

    return selectedBlocks.reduce((a, b) => {
        a += GetProductiveBlockArea(b, fodderDetails, TruckActivities);
        return a;
    }, 0);
};

export const GetProductiveBlockArea = (block, fodderDetails, cultivatedOnly) => {
    if (block.type === "FodderCrop") return block.rotationArea;
    else if (block.type === "ProductivePasture") return fodderDetails.pastureBlockIds.has(block.id) ? block.areaInHectares - block.areaInHectares * fodderDetails.ratio : block.areaInHectares;
    else if (block.type === "ProductiveCrop") return cultivatedOnly ? (block.cropBlock && block.cropBlock.cultivatedAreaPercent ? (block.cropBlock.cultivatedAreaPercent * block.areaInHectares) / 100 : block.areaInHectares) : block.areaInHectares;
    else return block.areaInHectares;
};

export const isTouched = (validation, source, id) => (validation && validation[id] && validation[id].touched) || source === undefined || source.key === id;

export const validatePercentage = (value, required) => {
    if (!value && !required) return [true, undefined];

    let message = utils.isNumeric(value, -1) ? undefined : "Required";
    let isValid = message === undefined;
    if (isValid) {
        message = validations.range(1, 100)(parseFloat(value));
        isValid = isValid && message === undefined;
    }
    return [isValid, message];
};

export const validateYearsApplied = (value) => {
    let message = utils.isNumeric(value, -1) ? undefined : "Required";
    let isValid = message === undefined;
    message = message || validations.range(1, 5)(parseInt(value, 10));
    isValid = isValid && message === undefined;
    return [isValid, message];
};

export const validateProductName = (fertiliser, customFertiliser) => {
    let message = validations.valueNotEmpty(fertiliser.productName);
    let isValid = message === undefined;

    message = message || validations.maxLength(200)(fertiliser.productName);
    isValid = isValid && message === undefined;

    if (fertiliser.productName) {
        const duplicate = customFertiliser.filter((f) => f.productName && f.id !== fertiliser.id && f.productName.toUpperCase() === fertiliser.productName.toUpperCase() && f.material === fertiliser.material);
        if (duplicate.length > 0) {
            message = fertiliser.productName + " already exists";
            isValid = false;
        }
    }
    return [isValid, message];
};

export const validateNutrientGroup = (fertiliser, validation, currentValidation, source) => {
    if (nutrientsOnApplication(fertiliser)) {
        return;
    }
    let nutrientTotal = 0;
    /* eslint-disable no-unused-vars */
    for (const n of nutrients) {
        const value = utils.isNumeric(fertiliser.nutrients[n], 6) ? parseFloat(fertiliser.nutrients[n]) : 0;
        if (value > 100) {
            validation[n] = { touched: true, error: true, message: "Must be 100 or less" };
            return;
        }
        nutrientTotal += value;
    }
    if (nutrientTotal === 0) {
        validation.nutrientGroup = { touched: isTouched(currentValidation, source, "nutrientGroup"), error: true, message: "At least one nutrient value should be greater than 0" };
    }
    if (fertiliser.dairyFactoryEffluent && fertiliser.dairyFactoryEffluent === "Userdefined" && nutrientTotal > 20) {
        validation.nutrientGroup = { touched: true, error: true, message: "The total should not exceed 20%" };
    } else if (nutrientTotal > 100) {
        validation.nutrientGroup = { touched: true, error: true, message: "The total should not exceed 100%" };
    }
};

export const validateApplicationNutrientGroup = (fertiliser, validation, currentValidation, source) => {
    if (!nutrientsOnApplication(fertiliser)) {
        return;
    }
    /* eslint-disable no-unused-vars */
    for (const n of nutrients) {
        const value = utils.isNumeric(fertiliser.nutrients[n], 6) ? parseFloat(fertiliser.nutrients[n]) : 0;
        if (value > 0) return;
    }
    validation.nutrientGroup = { touched: isTouched(currentValidation, source, "nutrientGroup"), error: true, message: "At least one nutrient value should be greater than 0" };
};

export const nutrientsOnApplication = (fertiliser) => fertiliser.material === "Soluble" || fertiliser.organicType === "Piggeryeffluent" || fertiliser.organicType === "Importeddairyeffluent" || (fertiliser.organicType === "Dairyfactory" && fertiliser.dairyFactoryEffluent === "None");

export const hideNFertAppMethod = (nutrients, hasCrops, fertiliser) => [undefined, "0", 0].includes(nutrients.N) || !hasCrops || ![ProductType.Soluble, ProductType.Product].includes(fertiliser.material);

export const formatFertAmount = (fertiliser, monthCalcApps = [], budget) => {
    const { blocks = [] } = budget;
    if (monthCalcApps.length > 0 && !nutrientsOnApplication(fertiliser)) {
        //We need to convert unit
        const blockAmount = monthCalcApps
            .filter((a) => !a.isLastPreviousLime)
            .reduce((t, a) => {
                const { blockIds = [] } = a;
                const totalSize = getApplicationBlockArea(blockIds, budget.blocks);
                switch (a.unit) {
                    case "tonnes":
                        t += parseFloat((a.amount * 1000) / totalSize);
                        break;
                    case "kg":
                        t += parseFloat(a.amount / totalSize);
                        break;
                    default:
                        t += a.amount;
                        break;
                }
                return t;
            }, 0);
        const unit = fertiliser.dairyFactoryEffluent && fertiliser.dairyFactoryEffluent === "Userdefined" ? "lt/ha" : "kg/ha";
        return `${+blockAmount.toFixed(0)} ${unit}`;
    }

    return "";
};
