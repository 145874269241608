import React from "react";
import debounce from "lodash/debounce";
import { compose, withActions, withState, withAppContext } from "containers/HOC";
import * as icons from "common/icons";
import ActionLink from "components/ActionLink";
import { Panel, PanelBody } from "components/Panel";
import PageHead from "components/PageHead";
import { OrgList, OrgUserInvitationList } from "containers/Admin/Orgs";
import { UserList } from "containers/Admin/Users";
import { FarmList } from "containers/Admin/Farms";
import { findAccounts, findFarms } from "../_actions";

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchFor: this.props.searchFor || "Orgs",
            searchText: this.props.searchText || "",
        };

        this.debouncedSearch = debounce(this.search, 500);
    }

    componentWillUnmount() {
        this.debouncedSearch.cancel();
    }

    onSearchForChange = (e) => {
        const searchFor = e.target.value;
        this.setState({ searchFor }, () => {
            this.debouncedSearch();
        });
    };

    onSearchTextChange = (e) => {
        const searchText = e.target.value;
        this.setState({ searchText }, () => {
            this.debouncedSearch();
        });
    };

    onClear = () => {
        this.searchText.value = "";
        this.setState({ searchText: "" }, () => {
            this.search();
        });
    };

    search = () => {
        const searchFor = this.state.searchFor;
        const searchText = this.state.searchText;

        switch (searchFor) {
            case "Orgs":
            case "Users":
            case "UserInvitations":
                this.props.findAccounts(searchFor, searchText);
                break;
            case "Farms":
                this.props.findFarms(searchFor, searchText);
                break;
            default:
                break;
        }
    };

    render() {
        const { accounts, accountsLoading, farms, farmsLoading, authContext } = this.props;

        const orgs = accounts || [];

        const users = (accounts || []).reduce((results, account) => {
            var accountUsers = account.users.map((user) => {
                return {
                    ...user,
                    accountId: account.id,
                    accountName: account.name,
                };
            });
            results = results.concat(accountUsers);
            return results;
        }, []);

        const userInvitations = (accounts || []).reduce((results, account) => {
            var accountUserInvitations = account.userInvitations.map((userInvitation) => {
                return {
                    ...userInvitation,
                    accountId: account.id,
                    accountName: account.name,
                };
            });
            results = results.concat(accountUserInvitations);
            return results;
        }, []);

        const isAuthorised = authContext.isSystemAdmin;
        const isOrgSearch = this.state.searchFor === "Orgs";
        const isUserSearch = this.state.searchFor === "Users";
        const isUserInvitationSearch = this.state.searchFor === "UserInvitations";
        const isFarmSearch = this.state.searchFor === "Farms";

        const hasOrgs = isOrgSearch && orgs.length > 0;
        const hasUsers = isUserSearch && users.length > 0;
        const hasUserInvitations = isUserInvitationSearch && userInvitations.length > 0;
        const hasFarms = isFarmSearch && farms && farms.length > 0;

        const searchTextPlaceholder = isFarmSearch ? "Search by farm name or address" : "Search by org name, org type, user name or user email";

        const hasSearchResults = hasOrgs && hasUsers && hasUserInvitations && hasFarms;
        const loading = accountsLoading || farmsLoading;

        return (
            <Panel>
                <PanelBody>
                    <PageHead className="u-mb-md">
                        <div className="PageHead-actions">
                            <label className="Select Select--sort">
                                <span className="Select--sort-label">Search for</span>
                                <select id="searchFor" className="Select--sort-select" onChange={this.onSearchForChange} value={this.state.searchFor}>
                                    <option value="Orgs">Orgs</option>
                                    <option value="Users">Users</option>
                                    <option value="UserInvitations">Pending user invitations</option>
                                    <option value="Farms">Farms</option>
                                </select>
                            </label>
                        </div>
                        <div className="PageHead-searchInput">
                            <div className="Input_wrapper">
                                <input id="searchText" onChange={this.onSearchTextChange} value={this.state.searchText} autoFocus type="search" placeholder={searchTextPlaceholder} ref={(component) => (this.searchText = component)} className="Input Input--block has-clear has-value" />
                                <ActionLink id="searchClear" className="Input-clear" onClick={this.onClear}>
                                    <img src={icons.clear} height="24px" width="24px" className="u-p-5" alt="Clear" />
                                </ActionLink>
                            </div>
                        </div>
                    </PageHead>
                    <Panel title="Search results" loading={loading} midBlue>
                        <PanelBody loading={!hasSearchResults && loading}>
                            {!hasOrgs && !hasUsers && !hasUserInvitations && !hasFarms && (
                                <div className="Tile-body-message">
                                    <p className="lead">No results</p>
                                </div>
                            )}
                            {isAuthorised && hasOrgs && <OrgList orgs={orgs} />}
                            {isAuthorised && hasUsers && <UserList users={users} />}
                            {isAuthorised && hasUserInvitations && <OrgUserInvitationList userInvitations={userInvitations} />}
                            {isAuthorised && hasFarms && <FarmList farms={farms} />}
                        </PanelBody>
                    </Panel>
                </PanelBody>
            </Panel>
        );
    }
}

export default compose(
    withAppContext,
    withActions({ findAccounts, findFarms }),
    withState((state) => {
        return {
            searchFor: state.admin.searchFor,
            searchText: state.admin.searchText,
            accounts: state.admin.accounts,
            accountsLoading: state.admin.accountsLoading,
            farms: state.admin.farms,
            farmsLoading: state.admin.farmsLoading,
        };
    })
)(Search);
