import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import TabMessages from "containers/BudgetHome/TabMessages";
import GHG from "./GHG";
import Fuel from "./Fuel/Fuel";
import Fertiliser from "./Fertiliser/Fertiliser";
import Transport from "./Transport/Transport";
import Electricity from "./Electricity/Electricity";

export default function GHGTab({ farm, analysis }) {
    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.GHG} />
            <TileBody>
                <TabMessages farm={farm} analysis={analysis} tab={TAB.GHG} />
                <Switch>
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/ghg" render={() => <GHG farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/ghg/fuel" render={() => <Fuel farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/ghg/fertiliser" render={() => <Fertiliser farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/ghg/transport" render={() => <Transport farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/ghg/electricity" render={() => <Electricity farm={farm} analysis={analysis} />} />
                </Switch>
            </TileBody>
        </Tile>
    )
}
