import React, { Component } from "react";
import { compose, withAppContext, withState, withActions } from "containers/HOC";
import { modalInlineOpen, modalInlineClose } from "containers/App/_actions";
import { loginWithFacebook, cancelFacebookLogin, reAuthWithFacebook } from "./_actions";
import AcceptTermsModal from "./AcceptTermsModal";

class FacebookLoginButton extends Component {
    state = {
        clicked: false,
    };

    componentDidMount() {
        this.initSdk();
    }

    componentDidUpdate(prevProps) {
        if (this.state.clicked && this.props.showTerms && !prevProps.showTerms) this.props.modalInlineOpen();
    }

    initSdk = () => {
        // This function will run as soon as the fb sdk has completed loading.
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: process.env.NODE_ENV === "production" ? "309139859850930" : "319321695297850",
                version: "v8.0",
            });
        };

        // Load the fb sdk.
        let fbSdk = document.getElementById("facebook-jssdk");
        if (!fbSdk) {
            fbSdk = document.createElement("script");
            fbSdk.id = "facebook-jssdk";
            fbSdk.src = "https://connect.facebook.net/en_NZ/sdk.js";
            fbSdk.async = true;
            fbSdk.defer = true;
            fbSdk.crossOrigin = "anonymous";

            const firstScript = document.getElementsByTagName("script")[0];
            firstScript.parentNode.insertBefore(fbSdk, firstScript);
        }
    };

    login = () => {
        this.setState({ clicked: true });
        if (window.FB) {
            window.FB.getLoginStatus((loginStatus) => {
                if (loginStatus.authResponse) this.onLoginSuccess(loginStatus.authResponse);
                else
                    window.FB.login(
                        (loginResult) => {
                            if (loginResult.authResponse) this.onLoginSuccess(loginResult.authResponse);
                        },
                        { scope: "public_profile,email" }
                    );
            }, true);
        }
    };

    onLoginSuccess = (authResponse, termsAccepted = false) => {
        if (this.props.isReAuth) this.props.reAuthWithFacebook(authResponse.accessToken, this.props.authContext, this.props.originalPayload);
        else this.props.loginWithFacebook(authResponse.accessToken, termsAccepted, this.props.location.state);
    };

    acceptTerms = () => {
        this.props.modalInlineClose();
        if (window.FB) {
            window.FB.getLoginStatus((loginStatus) => {
                if (loginStatus.authResponse) this.onLoginSuccess(loginStatus.authResponse, true);
            });
        }
    };

    declineTerms = () => {
        this.props.modalInlineClose();
        this.props.cancelFacebookLogin();
        this.setState({ clicked: false });
    };

    render() {
        const { online } = this.props;
        return (
            <React.Fragment>
                {this.state.clicked && this.props.showTerms && <AcceptTermsModal fileName={`https://docs.overseer.org.nz/${process.env.REACT_APP_PRODUCT}/OverseerTermsOfUse.pdf`} title="Terms of use" accept={this.acceptTerms} decline={this.declineTerms} />}
                <button type="button" className="Button--facebook" onClick={this.login} disabled={!online}>
                    <span className="icon"></span>
                    <span className="text">Facebook</span>
                </button>
            </React.Fragment>
        );
    }
}

export default compose(
    withAppContext,
    withState((state) => {
        return {
            showTerms: state.auth.termsRequired,
        };
    }),
    withActions({ loginWithFacebook, cancelFacebookLogin, reAuthWithFacebook, modalInlineOpen, modalInlineClose })
)(FacebookLoginButton);
