import React from "react";
import ReactDOMServer from "react-dom/server";
import { compose, withAppContext, withActions } from "containers/HOC";
import DropzoneComponent from "react-dropzone-component";
import { reduxForm } from "redux-form";
import actionTypes, { importDataset, cancelImportDataset } from "./_actions";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";

class LoadAnalyses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitDisabled: false,
            validationErrors: [],
        };
    }

    _closeOnEscape = (e) => {
        if (e.keyCode === 27) {
            this._cancel(e);
        }
    };

    _cancel = (e) => {
        e.preventDefault();
        this._close();
    };

    _close = () => {
        this.props.closeModal();
    };

    componentDidMount() {
        this.props.cancelImportDataset();
        document.addEventListener("keydown", this._closeOnEscape);
    }

    componentWillUnmount() {
        this.props.cancelImportDataset();
        document.removeEventListener("keydown", this._closeOnEscape);
    }

    _confirm = () => {
        var cmp = this;
        const { datasetId } = this.props.modal.props;
        cmp.setState({ submitDisabled: true });
        cmp.props.importDataset(datasetId, { analyses: cmp.state.jsonfile });
    };

    removeFile() {
        this.props.cancelImportDataset();
        this.setState({ submitDisabled: false });
    }

    addFile(file) {
        const reader = new FileReader();
        const cmp = this;
        reader.onload = function (e) {
            cmp.setState({ jsonfile: reader.result });
        };
        reader.readAsText(file);
    }

    render() {
        const { online, isSaving } = this.props;

        var eventHandlers = {
            addedfile: (file) => this.addFile(file),
            removedfile: (file) => this.removeFile(file),
        };

        const componentConfig = {
            iconFiletypes: [".json"],
            showFiletypeIcon: true,
            postUrl: "no-url",
        };

        const djsConfig = {
            addRemoveLinks: true,
            autoProcessQueue: false,
            maxFilesize: 5,
            maxFiles: 1,
            uploadMultiple: false,
            previewTemplate: ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview dz-file-preview">
                    <div className="dz-details">
                        <div className="dz-filename">
                            <span data-dz-name="true"></span>
                        </div>
                    </div>
                    <div className="dz-progress">
                        <span className="dz-upload" data-dz-uploadprogress="true"></span>
                    </div>
                    <div className="dz-error-message">
                        <span data-dz-errormessage="true"></span>
                    </div>
                </div>
            ),
        };

        var errorMessages = [];
        if (this.state.validationErrors && this.state.validationErrors.length > 0) errorMessages = this.state.validationErrors;
        else if (this.props.outcome && this.props.outcome.type && this.props.outcome.type === actionTypes.BUDGET_XML_UPLOAD) {
            if (this.props.outcome.errors && this.props.outcome.errors.length > 0) {
                errorMessages = this.props.outcome.errors;
            }
        }

        return (
            <div className="Modal_wrapper">
                <div className="Modal Modal--skinny">
                    <form onSubmit={this.props.handleSubmit(this._confirm)}>
                        <div className="Modal-head">
                            <span className="Modal-head--left">Select dataset file to upload</span>
                            <span className="Modal-head--right">
                                <ActionLink onClick={this.props.toggleQuickTips} className="Modal-close">
                                    <i className="icon icon-question" title={`${this.props.showQuickTips ? "Hide" : "Show"} quick tips`} />
                                </ActionLink>
                                <ActionLink onClick={this._cancel} className="Modal-close">
                                    <i className="icon icon-cross" title="Close" />
                                </ActionLink>
                            </span>
                        </div>
                        {!online && (
                            <div className="Modal-subhead">
                                <Alert type="error" text="Files cannot be uploaded when the system is offline. Please click cancel to continue." />
                            </div>
                        )}

                        {errorMessages.map((e, index) => (
                            <div key={index} className={"Alert Alert--error"}>
                                <i className={"icon icon-alert"} />
                                <div className="u-split">
                                    <span id="upload-error">{e}</span>
                                </div>
                            </div>
                        ))}
                        <div className="Modal-body">
                            <div className="Grid">
                                <div className="Grid-cell">
                                    <DropzoneComponent id="budget-file-upload" config={componentConfig} djsConfig={djsConfig} eventHandlers={eventHandlers} />
                                </div>
                            </div>
                        </div>
                        <div className="Modal-footer">
                            <div className="ButtonBar">
                                <ActionLink id="cancel-button" onClick={this._cancel} className="Button Button--secondary" type="button" name="button">
                                    Cancel
                                </ActionLink>
                                <button id="confirm-button" type="submit" disabled={isSaving || !online} className="Button Button--primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const LoadAnalysesForm = reduxForm({
    form: "load-analyses-json-form",
})(LoadAnalyses);

export default compose(withAppContext, withActions({ importDataset, cancelImportDataset }))(LoadAnalysesForm);
