import { useState } from "react";
import { FORM_ERROR } from "final-form";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";
import { Link } from "react-router-dom";
import Page from "components/Page";
import PageBody from "components/PageBody";
import FarmHomePageHead from "containers/FarmHome/FarmHomePageHead";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import Button from "components/Button/Button";
import FarmFields, { ValidateFarmFields } from "./FarmFields";
import { useFarm, useUpdateFarmAsync } from "containers/hooks";
import { useNavigate } from "common/hooks";

export default function FarmSettings() {
    const { isFetching, data: farm } = useFarm();
    const referrer = `/app/farm/${farm.id}`;
    const breadcrumb = [{ name: farm.displayName, to: referrer }, { name: "Farm settings" }];
    const [decorators] = useState([createDecorator()]);
    const updateFarmAsync = useUpdateFarmAsync();
    const navigate = useNavigate();

    const submitAsync = async (values) => {
        try {
            if (values.isOwner === "Yes") {
                delete values.ownerName;
                delete values.ownerEmail;
            }
            await updateFarmAsync(values);
            navigate(referrer);
        } catch (error) {
            return {
                [FORM_ERROR]: error.message,
            }
        }
    }

    const infoText = "Enter the farm name, physical address and select the farm's location on the map. Selecting a valid address will set the farm's initial location. If this is incorrect, click on the marker and drag to a new location. It is important that this location is correct as it will be used to get the climate for any blocks that are not drawn.";
    const errorText = (!farm.latitude || !farm.longitude) ? "All farms now require a location to be entered for climate data. You can set a location by typing an address and selecting from the list shown. If you already have an address, try retyping it and then selecting from the list. If the address is not shown in the list or the actual farm location is different you can use the map to set an approximate location for the farm. Zoom into the map and click or drag the marker to the farm's location." : undefined;

    return (
        <Page>
            <FarmHomePageHead breadcrumb={breadcrumb} />
            <PageBody>
                <Form initialValues={farm} validate={ValidateFarmFields} mutators={arrayMutators} decorators={decorators} onSubmit={submitAsync}>
                    {({ form, values, errors, handleSubmit, submitting, submitFailed, submitError }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Panel title="Farm settings" iconClassName="IconLink--settings" waiting={isFetching || submitting} info={infoText} error={submitError || errorText} referrer={referrer}>
                                    <PanelBody waiting={isFetching}>
                                        <FarmFields form={form} values={values} farm={values} errors={errors} submitFailed={submitFailed} />
                                    </PanelBody>
                                </Panel>
                                <PanelFooter>
                                    <div className="ButtonBar ButtonBar--fixed">
                                        <div className="ButtonBar-left">
                                            <Link to={referrer} id="cancel-button" type="cancel" className="Button Button--secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="ButtonBar-right">
                                            <Button id="save-button" submit primary waiting={submitting} disabled={isFetching}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </PanelFooter>
                            </form>
                        )
                    }}
                </Form>
            </PageBody>
        </Page>
    )
}
