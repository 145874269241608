import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import ActionLink from "components/ActionLink";
import { modal } from "containers/App/_actions";

export default function OrgUserInvitationList ({ org, userInvitations }) {
    const dispatch = useDispatch();

    const resendInvitation = (invitation) => (e) => {
        const viewModel = {
            invitation,
        };
        dispatch(modal("InviteUserModal", null, viewModel));
    };

    const showOrg = org === undefined;
    const defaultSortCriteria = showOrg ? { fieldName: "accountName", fieldType: "string" } : { fieldName: "email", fieldType: "string" };
    return (<>
        <UserInvitationCount userInvitations={userInvitations} />
        <SortableTable data={userInvitations} defaultSortCriteria={defaultSortCriteria}>
            {({ data, sortCriteria, handleSort }) => {
                return (
                    <table>
                        <thead>
                            <tr>
                                {showOrg && <SortableTableHeader label="Org" fieldName="accountName" sortCriteria={sortCriteria} handleSort={handleSort} />}
                                <SortableTableHeader label="Email" fieldName="email" sortCriteria={sortCriteria} handleSort={handleSort} />
                                <SortableTableHeader label="Sent" fieldName="invitedOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                <th className="th--shrink"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((invitation) => {
                                return (
                                    <tr key={invitation.id} id={invitation.id} className="hover">
                                        {showOrg && (
                                            <td>
                                                <Link to={`/app/admin/orgs/${invitation.accountId}`}>{invitation.accountName}</Link>
                                            </td>
                                        )}
                                        <td>{invitation.email}</td>
                                        <td>{utils.dateLong(invitation.invitedOn, "-")}</td>
                                        <td>
                                            <div className="u-flex">
                                                <ActionLink id={`resend-${invitation.id}`} className="IconLink--edit" onClick={resendInvitation(invitation)}>
                                                    Resend
                                                </ActionLink>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                );
            }}
        </SortableTable>
    </>);
};

function UserInvitationCount({ userInvitations }) {
    if (!userInvitations) return null;

    return (
        <p className="u-mt-0 u-mb-md">
            <span className="h3">{userInvitations.length.toLocaleString()}</span> invitation(s) found
        </p>
    )
}

