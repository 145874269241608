import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import TabMessages from "containers/BudgetHome/TabMessages";
import Effluent from "./Effluent";
import SystemDetails from "./SystemDetails";
import StructureDetails from "./StructureDetails";
import OutdoorPigFarrowingVillages from "./OutdoorPigFarrowingVillages";
import OutdoorPigBarns from "./OutdoorPigBarns";
import OutdoorPigHuts from "./OutdoorPigHuts";
import OutdoorPigEffluentSystem from "./OutdoorPigEffluentSystem";

export default function EffluentTab({ farm, analysis }) {
    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.EFFLUENT} />
            <TileBody>
                <TabMessages farm={farm} analysis={analysis} tab={TAB.EFFLUENT} />
                <Switch>
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/structures" render={() => <Effluent farm={farm} analysis={analysis} />} restoreScrollY />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/structures/outdoorPigFarrowingVillages" render={() => <OutdoorPigFarrowingVillages farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/structures/outdoorPigBarns" render={() => <OutdoorPigBarns farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/structures/outdoorPigHuts" render={() => <OutdoorPigHuts farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/structures/outdoorPigEffluent" render={() => <OutdoorPigEffluentSystem farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/structures/effluent" render={() => <SystemDetails farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/structures/structure" render={() => <StructureDetails farm={farm} analysis={analysis} />} />
                </Switch>
            </TileBody>
        </Tile>
    )
}
