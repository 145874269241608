import SelectPack from "components/SelectPack";
import NumericInputPack from "components/NumericInputPack";
import * as utils from "common/utils";
import * as validations from "common/validations";
import CheckboxPack from "components/CheckboxPack";
import { useRefData } from "common/hooks";

export default function FeedingApron({ feedingApron, switchApron, validation, onChange, activeControls }) {
    const refData = useRefData();
    const { cleaningMethod = [] } = refData;
    if (!activeControls[`feedingApron_apronHeading`]) return null;

    return (
        <div className="Grid Grid--withGutter u-mt-lg">
            <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                <h3>Feeding apron</h3>
                <CheckboxPack meta={{ nrf: true }} id="strcture-apron-present" label="Concrete feeding apron present and used" value={feedingApron} onChange={(e) => switchApron()} tip="A separate area of concrete used for feeding animals, similar in concept to a feedpad. Because it is used for feeding only, effluent is sometimes managed differently from the sleeping/resting area." />
                {feedingApron && (
                    <div className="Field-group">
                        <SelectPack meta={{ nrf: true }} isHidden={!activeControls[`feedingApron_cleaningMethod`]} onChange={(e) => onChange(e, { type: "feedingApron", key: "cleaningMethod" })} value={feedingApron.cleaningMethod} val={validation[`feedingApron_cleaningMethod`]} id={`feedingApron_cleaningMethod`} label="Manure removal method" requiredLabel={true}>
                            <option value="" disabled={true}>
                                Select a cleaning method
                            </option>
                            {utils.mapRefDataItems(cleaningMethod.filter((c) => c.value !== "ScrapingAdded"))}
                        </SelectPack>
                        <NumericInputPack label="Time on apron" isHidden={!activeControls[`feedingApron_timeApron`]} id={`feedingApron_timeApron`} uom="hours" requiredLabel={true} val={validation[`feedingApron_timeApron`]} dataWidth="50" onChange={(e) => onChange(e, { type: "feedingApron", key: "timeApron" })} decimalPlaces={0} value={feedingApron.timeApron} />
                    </div>
                )}
            </div>
        </div>
    )
}

export function validateFeedingApron(activeControls, validation, feedingApron, currentValidation, source, setVal) {
    let message = undefined;
    let key = undefined;
    let objkey = undefined;

    objkey = "cleaningMethod";
    key = `feedingApron_${objkey}`;
    if (activeControls[key]) {
        message = validations.required(feedingApron[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }

    objkey = "timeApron";
    key = `feedingApron_${objkey}`;
    if (activeControls[key]) {
        message = validations.requiredExt(feedingApron[objkey]);
        message = message || validations.isNumeric(feedingApron[objkey]);
        message = message || validations.range(1, 15)(feedingApron[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }
}
