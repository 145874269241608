import React from "react";

const RadioGroupPack = (props) => {
    if (props.isHidden || !props.options) return null;
    const { meta = {}, disabled, className } = props;

    if (meta.nrf) {
        const { val = {}, onChange } = props;
        return (
            <div className={`Field ${val.touched && val.error ? "has-error" : ""} ${props.inline === true ? "u-mt-0" : ""} `} data-width={props.dataWidth}>
                <label className={`Field-label ${disabled ? "is-disabled" : ""}`}>
                    {props.label}
                    {props.requiredLabel === true && <sup className="required">*</sup>}
                </label>
                <div className={"Radio_group" + (props.inline === true ? " Radio_group--inline" : "")}>
                    {props.options.map((option) => {
                        const id = `${props.id || props.name}_${option.value}`;
                        return (
                            <label key={option.value} className="Radio" htmlFor={id}>
                                <input name={props.name} value={option.value} className="Radio-input" type="radio" checked={props.value ? props.value === option.value : option.checked || false} onChange={(e) => onChange(e)} id={id} disabled={disabled} />
                                <span className={`Radio-label ${className}`}>{option.text}</span>
                            </label>
                        );
                    })}
                </div>
                <small className="Field-error" id={`${props.id}-error`}>
                    {val.message}
                </small>
            </div>
        );
    }

    const handleChange = (option) => (e) => {
        e.target.value = option.value;
        if (props.onChange) props.onChange(e);
    };

    return (
        <div className={`Field ${props.meta.touched && props.meta.error ? "has-error" : ""} ${props.inline === true ? "u-mt-0" : ""} `} data-width={props.dataWidth}>
            <label className={`Field-label ${disabled ? "is-disabled" : ""}`}>
                {props.label}
                {props.requiredLabel === true && <sup className="required">*</sup>}
            </label>
            <div className={"Radio_group" + (props.inline && props.inline === true ? " Radio_group--inline" : "")}>
                {props.options.map((option) => {
                    const id = `${props.id || props.input.name}_${option.value}`;
                    return (
                        <label key={option.value} className="Radio" htmlFor={id}>
                            <input {...props.input} className="Radio-input" type="radio" checked={props.input.value === option.value} onClick={handleChange(option)} id={id} disabled={disabled || option.disabled} />
                            <span className={option.disabled ? `Radio-label u-textMidGrey ${className}` : `Radio-label ${className}`}>{option.text}</span>
                        </label>
                    );
                })}
            </div>
            {props.meta.touched && props.meta.error && (
                <small id={`${props.id || props.input.name}-error`} className="Field-error">
                    {props.meta.error}
                </small>
            )}
        </div>
    );
};

export default RadioGroupPack;
