import React, { Component } from "react";
import SolidModal from "./SolidModal";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import ActionLink from "components/ActionLink";
import Button from "components/Button/Button";

const ApplicationMonth = ({ label, id, name, applicationId, checked, onChange, prefix }) => (
    <label className="Checkbox" htmlFor={`${prefix}_${applicationId}_${id}`}>
        <input className="Checkbox-input" type="checkbox" checked={checked || false} id={`${prefix}_${applicationId}_${id}`} name={`${applicationId}_${id}`} onChange={(e) => onChange(e, { type: "application", key: "appplication-month", applicationId: applicationId, month: id, id: `${applicationId}_${id}` })} />
        <span className="Checkbox-label">{label}</span>
    </label>
);

const InitState = { application: undefined, dirty: false };

class SolidApplications extends Component {
    constructor(props) {
        super(props);
        this.state = InitState;
    }

    onChange(e, source) {
        const application = this.state.application;
        application[source.key] = utils.ctrlVal(e);
        this.props.validate(application, source);
        this.setState({ dirty: true, application: application });
    }

    changeMonth(e, o) {
        const { applications } = this.props;
        const application = applications.find((a) => a.id === o.applicationId);
        const { months = [] } = application;
        application.months = e.target.checked ? [...[{ month: o.month, reportingYear: true }], ...months] : months.filter((m) => m.month !== o.month);
        this.props.saveApplication(application, true);
        this.setState({ dirty: true });
    }

    saveApplication() {
        this.props.saveApplication(this.state.application, this.state.dirty);
        this.props.modalInlineClose();
        this.setState(InitState);
    }

    componentWillUnmount() {
        this.props.modalInlineClose();
    }

    closeModal() {
        this.setState(InitState);
        this.props.cancelApplication();
        this.props.modalInlineClose();
    }

    addApplication() {
        const application = { id: uuidv4() };
        this.setState({ application: application });
        this.props.modalInlineOpen();
    }

    editApplication(id) {
        const application = this.props.applications.find((a) => a.id === id);
        this.setState({ application: utils.clone(application) });
        this.props.modalInlineOpen();
    }

    deleteConfirm(id) {
        this.props.confirm(`Are you sure you want to delete this application?`, () => this.deleteApplication(id));
    }

    deleteApplication(id) {
        this.props.deleteApplication(id);
    }

    render() {
        const { blocks, validation, applications, type, prefix } = this.props;
        const { application = {} } = this.state;
        const { blockIds = [] } = application;
        const usedBlockIds = applications
            .filter((a) => a.id !== application.id)
            .map((a) => a.blockIds)
            .reduce((a, b) => [...a, ...b], []);
        const availableBlocks = blocks.filter((b) => !usedBlockIds.includes(b.id));
        const selectedBlocks = blockIds.map((id) => {
            return blocks.find((b) => b.id === id);
        });
        const appVal = validation[`${type}_applications`] || {};

        const tbody =
            applications.length > 0 ? (
                <tbody>
                    {applications.map((application, index) => {
                        const { blockIds = [], months = [] } = application;
                        const selectedBlocks = blockIds
                            .filter((id) => blocks.some((b) => b.id === id))
                            .map((id) => {
                                return blocks.find((b) => b.id === id);
                            });
                        const monthGroupVal = validation[`monthGroupVal_${application.id}`] || {};

                        return (
                            <tr id={application.id} key={application.id}>
                                <td>
                                    {selectedBlocks.map((block) => (
                                        <div key={block.id}>
                                            <b id={`${prefix}_${block.id}_block`}>{block.name}</b>
                                        </div>
                                    ))}
                                </td>
                                <td>
                                    <div className="AnnualCheckboxes">
                                        <ApplicationMonth label="Jul" id="July" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "July")} prefix={prefix} />
                                        <ApplicationMonth label="Aug" id="August" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "August")} prefix={prefix} />
                                        <ApplicationMonth label="Sep" id="September" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "September")} prefix={prefix} />
                                        <ApplicationMonth label="Oct" id="October" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "October")} prefix={prefix} />
                                        <ApplicationMonth label="Nov" id="November" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "November")} prefix={prefix} />
                                        <ApplicationMonth label="Dec" id="December" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "December")} prefix={prefix} />
                                        <ApplicationMonth label="Jan" id="January" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "January")} prefix={prefix} />
                                        <ApplicationMonth label="Feb" id="February" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "February")} prefix={prefix} />
                                        <ApplicationMonth label="Mar" id="March" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "March")} prefix={prefix} />
                                        <ApplicationMonth label="Apr" id="April" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "April")} prefix={prefix} />
                                        <ApplicationMonth label="May" id="May" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "May")} prefix={prefix} />
                                        <ApplicationMonth label="Jun" id="June" applicationId={application.id} onChange={(e, o) => this.changeMonth(e, o)} checked={months.find((m) => m.month === "June")} prefix={prefix} />
                                    </div>
                                    {monthGroupVal.touched && monthGroupVal.error && (
                                        <div className="Field has-error u-mt-sm">
                                            <small className="Field-error" id={`${prefix}_month-error`}>
                                                Please select at least one month
                                            </small>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    <div className="u-flex">
                                        <ActionLink className="IconLink--trash" id={`${prefix}_${application.id}_remove`} onClick={() => this.deleteConfirm(application.id)} title="Delete">
                                            <span></span>
                                        </ActionLink>
                                        <ActionLink className="IconLink--edit u-ml-sm" id={`${prefix}_${application.id}_edit`} onClick={() => this.editApplication(application.id)} title="Edit">
                                            <span></span>
                                        </ActionLink>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            ) : (
                <tbody>
                    <tr>
                        <td colSpan="3">
                            <div className="Tile-body">
                                <div className="Tile-body-message">
                                    <p className="h4 m-mt-0">You do not have any applications</p>
                                    <Button className="IconLink--arrow-plus Button Button--secondary u-mt-md" id={`${prefix}_app_app-btn`} onClick={() => this.addApplication()}>
                                        <span>Add application</span>
                                    </Button>
                                    {appVal.touched && appVal.error && (
                                        <div className="Field has-error u-mt-sm">
                                            <small className="Field-error" id="app-error">
                                                Please add at least one application
                                            </small>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            );

        return (
            <div className="u-mt-xl">
                {this.state.application && <SolidModal onChange={(e, o) => this.onChange(e, o)} cancel={() => this.closeModal()} save={() => this.saveApplication()} selectedBlocks={selectedBlocks} blocks={availableBlocks} application={this.state.application} type={type} prefix={prefix} validation={validation} />}
                <Tile
                    title={this.props.title}
                    actions={
                        <span className="IconLink--arrow-plus u-link" id={`${prefix}_app_app-lnk`} onClick={() => this.addApplication()}>
                            Add application
                        </span>
                    }
                    tertiary
                >
                    <TileBody>
                        <div className="Table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Blocks</th>
                                        <th>Months</th>
                                        <th className="th--shrink"></th>
                                    </tr>
                                </thead>
                                {tbody}
                            </table>
                        </div>
                    </TileBody>
                </Tile>
            </div>
        );
    }
}

export default SolidApplications;
