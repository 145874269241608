import { useMemo, useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import Button from "components/Button/Button";

export default function NceByFarmsChart({ farms, farmId, selectedFarm, onFarmSelected }) {
    const chartTopRef = useRef();

    const hasTooManyDataPoints = farms.length > 0 && farms.length > utils.MAX_DATA_POINTS;
    const sortedFarms = hasTooManyDataPoints ? [] : farms.map((f) => ({ ...f })).sort((a, b) => a.nce - b.nce);

    const title = "Nitrogen conversion efficiency (%)";
    const chartData = getChartData(sortedFarms, farmId, selectedFarm);
    const chartOptions = useMemo(() => getChartOptions(), []);

    const chartSizeStyle = { position: "relative", height: "500px" };
    if (sortedFarms.length > 300) chartSizeStyle.width = `${sortedFarms.length * 5}px`;

    const chartClicked = (event) => {
        const chartElement = getElementAtEvent(chartTopRef.current, event);
        if (!chartElement || !Array.isArray(chartElement) || chartElement.length !== 1 || isNaN(chartElement[0].index)) return;
        if (!onFarmSelected) return;
        if (sortedFarms.length > chartElement[0].index) {
            const farm = sortedFarms[chartElement[0].index];
            onFarmSelected(farm);
        }
    };

    return (
        <Panel title={title} nloss className="u-mt-md">
            <PanelBody>
                <div className="HeroPanel HeroPanel--nloss u-mt-md u-print-border" ref={chartTopRef}>
                    <div className="u-flex u-flexJustifyBetween">
                        <div className="HeroPanel-heading">{title}</div>
                    </div>
                    {!hasTooManyDataPoints && (
                        <div style={{ overflowX: "auto", overflowY: "hidden" }}>
                            <div style={chartSizeStyle}>
                                <Bar data={chartData} options={chartOptions} onClick={chartClicked} ref={chartTopRef} />
                            </div>
                            <div className="u-flex u-flexJustifyEnd">
                                <Button className="IconLink--search Button Button--secondary u-mt-md" id="add-application-btn" onClick={() => chartTopRef.current.resetZoom()}>
                                    <span>Reset zoom</span>
                                </Button>
                            </div>
                        </div>
                    )}
                    {hasTooManyDataPoints && (
                        <div className="Tile-body-message u-mt-lg u-mb-lg">
                            <i className="icon icon--md icon-alert u-textInfo" />
                            <h3 className="u-mt-md">Benchmarking not supported for comparisons of more than {utils.MAX_DATA_POINTS.toLocaleString()} farms</h3>
                        </div>
                    )}
                </div>
            </PanelBody>
        </Panel>
    );
}

const getChartData = (farms, farmId, selectedFarm) => {
    const barColourSelected = "rgb(68,170,108)";
    const barColour = "rgb(68,170,108,0.4)";
    const hasSelection = farms.find((f) => f.farmId === farmId || (selectedFarm && selectedFarm.analysisId === f.analysisId));
    return {
        datasets: [
            {
                data: farms.map((f) => utils.round(f.nce || 0, 1)),
                backgroundColor: farms.map((f) => {
                    if ((selectedFarm && selectedFarm.analysisId === f.analysisId) || (farmId && farmId === f.farmId)) {
                        return barColourSelected;
                    }
                    return hasSelection ? barColour : barColourSelected;
                }),
                borderColor: farms.map((f) => {
                    return barColourSelected;
                }),
                borderWidth: 1,
            },
        ],
        labels: farms.map((f) => (f.farmId === farmId || f.isMyFarm ? f.farmName || "" : "")),
    };
};

const getChartOptions = () => {
    const uom = "%";
    return {
        maintainAspectRatio: false,
        scales: {
            x: { display: false },
            y: { ticks: { beginAtZero: true }, display: true },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `${tooltipItem.raw.toLocaleString()} ${uom}`,
                },
            },
            legend: {
                display: false,
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: "x",
                    modifierKey: "ctrl",
                },
                zoom: {
                    drag: {
                        enabled: true,
                    },
                    mode: "x",
                },
            },
        },
    };
};
