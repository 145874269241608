import React from "react";

const CardBody = ({ id, collapsed, children, className }) => {
    return (
        <div id={id} className={`FarmTable-supplementary ${className ? className : ""}`} data-collapsed={collapsed}>
            {children}
        </div>
    );
};
export default CardBody;
