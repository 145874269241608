import React, { useEffect, useState } from "react";
import { compose, withAppContext } from "containers/HOC";
import { useGoogleMap } from "components/Map/hooks";

const layers = [
    { title: "N loss per ha", field: "nLossPerHa", icon: "polygon", colour: "#44aa6c" },
    { title: "N loss", field: "nLossTotal", icon: "polygon", colour: "#44aa6c" },
    { title: "P loss per ha", field: "pLossPerHa", icon: "polygon", colour: "#e39153" },
    { title: "P loss", field: "pLossTotal", icon: "polygon", colour: "#e39153" },
    { title: "GHG per ha", field: "ghgPerHa", icon: "polygon", colour: "#4285f4" },
    { title: "GHG", field: "ghgTotal", icon: "polygon", colour: "#4285f4" },
];

const ReportingHeatmap = ({ style, locations }) => {
    const { google, map, setMapRef } = useGoogleMap();
    const [layer = layers[0], setLayer] = useState();

    const drawnLocations = locations.filter((f) => f.longitude && f.latitude);

    const zoom = (google, map, data) => {
        const bounds = new google.maps.LatLngBounds();
        data.forEach((feature) => {
            bounds.extend(feature.location);
        });
        if (!bounds.isEmpty() && map) {
            map.fitBounds(bounds);
        }
    };

    const toggleLayer = (layer) => {
        setLayer(layer);
    };

    // Add GeoJSON to the map.
    useEffect(() => {
        if (google && drawnLocations && layer && map) {
            const heatMapData = drawnLocations.map((f) => {
                return { location: new google.maps.LatLng(f.latitude, f.longitude), weight: f[layer.field] };
            });

            const heatmap = new google.maps.visualization.HeatmapLayer({
                data: heatMapData,
            });

            heatmap.setOptions({ radius: 15, dissipating: true });
            heatmap.setMap(map);
            zoom(google, map, heatMapData);

            // Special case for custom layer toggle map controls to allow us to change their
            // styling when toggle state changes.
            const toggleControls = map.controls[google.maps.ControlPosition.TOP_LEFT];
            if (toggleControls.getLength() > 0 && toggleControls.getLength() !== layers.length) {
                toggleControls.clear();
            }

            layers
                .filter((l) => drawnLocations[0] && drawnLocations[0][l.field] && drawnLocations[0][l.field])
                .forEach((l, i) => {
                    const isVisible = layer && l.field === layer.field;
                    let toggleControl = toggleControls.getAt(i);
                    if (!toggleControl) {
                        toggleControl = document.createElement("div");
                        toggleControl.id = `toggle-layer-icon-${l.field}`;
                        toggleControl.className = "MapCustomControl";
                        toggleControl.style.marginTop = "10px";
                        toggleControl.style.marginLeft = "10px";
                        toggleControl.style.borderStyle = "solid";
                        toggleControl.style.borderWidth = "2px";
                        toggleControl.addEventListener("click", () => toggleLayer(l));
                        toggleControls.push(toggleControl);
                    }
                    toggleControl.title = `${isVisible ? "" : "Show"} ${l.title}`;
                    toggleControl.style.borderColor = isVisible ? l.colour : "#fff";
                    toggleControl.innerHTML = `<i class="icon icon-${l.icon}" style="color: ${isVisible ? "#364052" : ""}"></i>`;
                });
        }
    }, [google, map, drawnLocations, layer]);

    return (
        <div className="MapWithList-wrapper" style={style}>
            <div className="MapWithList u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                <div style={{ minHeight: "100%", display: "flex", flexDirection: "column", flex: 1 }}>
                    <div ref={setMapRef} style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }} />
                </div>
            </div>
        </div>
    );
};

export default compose(withAppContext)(ReportingHeatmap);
