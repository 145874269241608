import { useEffect, useState } from "react";
import { useDebounce } from "common/effects";
import { supplementUtils } from "containers/BudgetHome/Supplements";
import { getDryMatterWeight } from "./_queries";

export default function EstimatedDryMatterWeight({ farm, analysis, feedSupplement }) {
    const { dryMatterWeightInTonnes, dryMatterWeightPerHectare } = useEstimatedDryMatterWeight(farm.id, analysis.id, feedSupplement);
    const isHarvested = supplementUtils.isHarvested(feedSupplement);

    if (!canFetchEstimatedDryMatterWeight(feedSupplement)) {
        return null;
    }

    return (
        <div className="u-flex u-mt-md">
            <div className="u-mt-lg u-ml-auto" style={{ backgroundColor: "#f8f9f8", textAlign: "center", padding: "10px 10px 15px 15px", width: "295px", border: "solid 2px #bec8c2", borderRadius: "5px" }}>
                <div className="u-textSuccess u-textBold small">Estimated dry matter weight</div>
                <div className="u-mt-sm">
                    <div className="Grid Grid--withGutter">
                        <div className="Grid-cell u-md-width1of2 u-textBold">
                            <span className="h3 u-mt-0"></span>
                            <small>Total dry weight:</small>
                        </div>
                        <div className="Grid-cell u-md-width1of2 u-textLeft u-textBold">
                            <span className="h3 u-mt-0" id="dryMatterWeightInTonnes">
                                {dryMatterWeightInTonnes || "0"}
                            </span>{" "}
                            <small>t DM</small>
                        </div>
                    </div>
                </div>
                {isHarvested && (
                    <div className="u-mt-sm">
                        <div className="Grid Grid--withGutter">
                            <div className="Grid-cell u-md-width1of2 u-textBold">
                                <span className="h3 u-mt-0"></span>
                                <small>Weight per ha:</small>
                            </div>
                            <div className="Grid-cell u-md-width1of2 u-textLeft u-textBold">
                                <span className="h3 u-mt-0" id="dryMatterWeightPerHectare">
                                    {dryMatterWeightPerHectare || "0.0"}
                                </span>{" "}
                                <small>t DM/ha</small>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

function useEstimatedDryMatterWeight(farmId, analysisId, feedSupplement) {
    const feedSupplementAsDebouncedString = useDebounce(JSON.stringify(feedSupplement), 500);
    const [estimatedDryMatterWeight, setEstimatedDryMatterWeight] = useState({ dryMatterWeightInTonnes: 0, dryMatterWeightPerHectare: 0 });

    useEffect(() => {
        if (farmId && analysisId && feedSupplementAsDebouncedString) {
            const parsedFeedSupplement = JSON.parse(feedSupplementAsDebouncedString);
            if (canFetchEstimatedDryMatterWeight(parsedFeedSupplement)) {
                const feedSupplements = [parsedFeedSupplement];
                getDryMatterWeight(farmId, analysisId, feedSupplements)
                    .then((response) => {
                        if (response?.length > 0) {
                            setEstimatedDryMatterWeight(response[0]);
                        }
                    });
            }
        }
    }, [farmId, analysisId, feedSupplementAsDebouncedString]);

    return estimatedDryMatterWeight;
}

function canFetchEstimatedDryMatterWeight(feedSupplement) {
    if (feedSupplement.type === "Harvested") {
        return feedSupplement.sources.some(s => s.blockId && s.month && s.amount > 0);
    }

    if (feedSupplement.type === "Purchased" && feedSupplement.amountType === "Cuts") {
        return feedSupplement.sources.some(s => s.amount > 0) && feedSupplement.areaCut > 0;
    }

    return feedSupplement.sources.some(s => s.amount > 0);
}
