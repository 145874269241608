import React from "react";
import { compose, withAppContext } from "containers/HOC";
import { Grid, GridCell } from "components/Grid";
import BlockSelector from "components/new/BlockSelector";
import NumericInputPack from "components/NumericInputPack";
import SelectPack from "components/SelectPack";
import CheckboxPack from "components/CheckboxPack";
import * as utils from "common/utils";
import ActionLink from "components/ActionLink";

class LiquidModal extends React.Component {
    _closeOnEscape = (e) => {
        if (e.keyCode === 27) {
            this.props.cancel();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this._closeOnEscape);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._closeOnEscape);
    }

    render() {
        const { cancel, save, blocks, selectedBlocks, application, type, validation, effluentAppRates, onChange } = this.props;
        const anyPasture = selectedBlocks.some((b) => b.type === "ProductivePasture" && b.animals && b.animals.length > 0);
        const blockIdsVal = validation[`${type}_blockIds`] || {};

        return (
            <div className="Modal_wrapper">
                <input type="hidden" value={application.id} id="applicationId" />
                <div className="Modal Modal--skinny">
                    <div className="Modal-head">
                        <span className="Modal-head--left">Liquid effluent application</span>
                        <span className="Modal-head--right">
                            <ActionLink onClick={this.props.toggleQuickTips} className="Modal-close">
                                <i className="icon icon-question" title={`${this.props.showQuickTips ? "Hide" : "Show"} quick tips`} />
                            </ActionLink>
                            <ActionLink onClick={cancel} id="modal_close" className="Modal-close">
                                <i className="icon icon-cross" title="Close" />
                            </ActionLink>
                        </span>
                    </div>
                    <div className="Modal-body">
                        <Grid>
                            <GridCell>
                                <BlockSelector
                                    input={{
                                        value: application.blockIds || [],
                                        onChange: (blockIds) => onChange({ target: { value: blockIds } }, { id: `${type}_blockIds`, key: "blockIds", type }),
                                    }}
                                    availableBlocks={blocks}
                                    meta={{
                                        touched: blockIdsVal.touched,
                                        error: blockIdsVal.message,
                                    }}
                                />
                            </GridCell>
                        </Grid>
                        <Grid title="Application">
                            <GridCell className="u-width1of2">
                                <SelectPack meta={{ nrf: true }} onChange={(e) => onChange(e, { id: `${type}_applicationDepth`, key: "applicationDepth", type })} value={application.applicationDepth} val={validation[`${type}_applicationDepth`]} id={`${type}_applicationDepth`} label="Application depth" requiredLabel={true}>
                                    <option value="" disabled={true}>
                                        Select an application depth
                                    </option>
                                    {utils.mapRefDataItems(effluentAppRates)}
                                </SelectPack>
                            </GridCell>
                            {anyPasture && (
                                <GridCell className="u-width1of2">
                                    <NumericInputPack name="areaEffluent" label="Percentage of block area" uom="%" requiredLabel={true} val={validation[`${type}_areaEffluent`]} id={`${type}_areaEffluent`} onChange={(e) => onChange(e, { id: `${type}_areaEffluent`, key: "areaEffluent", type })} decimalPlaces={1} value={application.areaEffluent} />
                                </GridCell>
                            )}
                            <GridCell>
                                <CheckboxPack meta={{ nrf: true }} id="application_activeManagement" label="Actively managed" value={application.activeManagement} onChange={(e) => onChange(e, { type: type, key: "activeManagement" })} />
                            </GridCell>
                        </Grid>
                    </div>

                    <div className="Modal-footer">
                        <div className="ButtonBar">
                            <div className="ButtonBar-left">
                                <button type="button" onClick={cancel} className="Button Button--secondary" id="modal-cancel">
                                    Cancel
                                </button>
                            </div>
                            <div className="ButtonBar-right">
                                <button type="button" onClick={() => save()} className="Button" id="modal-save" disabled={selectedBlocks.length < 1}>
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default compose(withAppContext)(LiquidModal);
