import React from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";

const PublicationSearchResults = ({ publications, selectedIndex, onSelected, loading }) => {
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        const data = publications.map((publication) => {
            const isPrivateYearEnd = publication.budgetType === "Predictive" && publication.year;
            return {
                id: publication.id,
                publishedAnalysisId: publication.publishedAnalysisId,
                farmId: publication.farmId,
                farmName: publication.farmName,
                address: publication.address,
                budgetName: isPrivateYearEnd ? `Private year end ${publication.year}` : publication.budgetName,
                reference: publication.reference,
                version: publication.version,
                publishedBy: publication.publishedBy && publication.publishedBy.userFullName,
                email: publication.publishedBy && publication.publishedBy.userEmail,
                publishedOn: publication.publishedOn,
                status: publication.status,
                isMyFarm: publication.isMyFarm,
                publishedTo: publication.publishedTo,
                ref: React.createRef(),
            };
        });
        setData(data);
    }, [publications]);

    React.useEffect(() => {
        if (selectedIndex >= 0 && selectedIndex < data.length) {
            const selectedData = data[selectedIndex];
            selectedData.ref.current.focus();
        }
    }, [data, selectedIndex]);

    return (
        <div className="QuickSearch-results-section">
            {loading && (
                <div className="u-pb-sm u-pt-md">
                    <span className="u-font-600">Publications loading ...</span>
                </div>
            )}
            {!loading && (
                <>
                    <div className="u-pb-sm u-font-600">Publications received ({data.length})</div>
                    {data.map((publication, i) => {
                        return (
                            <Link to={`/app/farm/${publication.farmId}/analysis/${publication.publishedAnalysisId}/overview`} onClick={onSelected} key={publication.id} ref={publication.ref}>
                                <div className={`Search-result ${selectedIndex === i ? "selected" : ""}`}>
                                    <div className="u-pl-sm u-pr-sm">
                                        <span className="u-textBold a">
                                            {publication.farmName} &gt; {publication.budgetName}
                                        </span>
                                        <div className="u-textPrimary">
                                            {utils.dateLong(publication.publishedOn)} | {publication.status} | {publication.publishedBy}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default PublicationSearchResults;
