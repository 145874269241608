import { httpClient } from "common/httpClient";

export function getDryMatterWeight(farmId, analysisId, feedSupplements) {
    const defaultResponse = [{ dryMatterWeightInTonnes: 0, dryMatterWeightPerHectare: 0 }];

    if (feedSupplements?.length === 0 || !feedSupplements[0].category) {
        return defaultResponse;
    }

    try {
        const timeout = 1000 * 60 * 2; // 2 minutes
        return httpClient.post(`farms/${farmId}/budgets/${analysisId}/feedSupplementDryWeight`, { feedSupplements }, timeout);
    } catch {
        return defaultResponse;
    }
}
