import { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getUserSessions } from "./_actions";
import UserSessions from "./UserSessions";

export default function UserSessionsWidget() {
    const [minutes, setMinutes] = useState(MINUTES[0].value);
    const sessions = useUserSessions(minutes);

    return (
        <div className="activeUsers">
            <div className="flexWidget">
                <div className="flexWidget__header">
                    <h3>Active users</h3>
                    <select id="user-sessions-minutes" value={minutes} onChange={(e) => setMinutes(Number(e.target.value))} className="u-border-none">
                        {MINUTES.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                                {opt.text}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flexWidget__body">
                    <h1 className="u-mt-0 u-mb-md">{sessions.length}</h1>
                    <UserSessions sessions={sessions} />
                </div>
            </div>
        </div>
    );
}

function useUserSessions(minutes) {
    const dispatch = useDispatch();
    const [userSessions, setUserSessions] = useState([]);
    const intervalRef = useRef(null); // Using ref to store interval ID

    const search = useCallback(
        async (minutes) => {
            const sessions = await dispatch(getUserSessions(minutes));
            setUserSessions(sessions);
        },
        [dispatch]
    );

    useEffect(() => {
        search(minutes);
    }, [minutes, search]);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => search(minutes), 5000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [minutes, search]);

    return userSessions;
}

const MINUTES = [
    { value: 5, text: "Last 5 mins" },
    { value: 30, text: "Last 30 mins" },
    { value: 60, text: "Last hour" },
];
