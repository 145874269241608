import { Component } from "react";
import { useParams } from "react-router-dom";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import SavePrompt from "components/SavePrompt";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Button } from "components/Button";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import Alert from "components/Alert";
import * as validations from "common/validations";
import { v4 as uuidv4 } from "uuid";
import BlockDetails from "./BlockDetails";
import Crops, { validate as validateCrops } from "../Crops";
import * as cropUtils from "../_utils";
import { useBlockGrowthCurve, useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function FodderRotationDetails({ farm, analysis }) {
    const { blockId } = useParams();
    const block = analysis.blocks.find((b) => b.id === blockId) || { id: uuidv4(), type: "FodderCrop", isProductive: true, isNew: true };
    const { growthCurve, resetGrowthCurve } = useBlockGrowthCurve(farm, analysis, block);
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    return <FodderRotationDetailsClassComponent farm={farm} analysis={analysis} block={block} growthCurve={growthCurve} resetGrowthCurve={resetGrowthCurve} updateAnalysisAsync={updateAnalysisAsync} />;
}

class FodderRotationDetailsClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            analysis: props.analysis,
            block: props.block,
            dirty: false,
            submitting: false,
            submitSucceeded: false,
        };
    }

    onChange(e, source) {
        const { block = {} } = this.state;
        block[source.key] = utils.ctrlVal(e);
        if (source.key === "monthResown") {
            this.insertDefaultPasture(block);
        }
        this.isValid(block, source);
        this.setState({ block, dirty: true });
    }

    insertDefaultPasture(block) {
        const { crops = [] } = block;
        block.crops = crops;
        let pastureCrop = crops.find((c) => c.category === "Pasture");
        if (!pastureCrop) {
            pastureCrop = {
                id: uuidv4(),
                category: "Pasture",
                cropId: "Pasture",
                cultivationPractice: "Conventional",
                defoliationManagement: "GrazeOnly",
                thermalTimeEndIndex: 36,
                thermalTimeEndReason: "Ongoing",
                events: [],
            };
            pastureCrop.events.push({
                type: "Cropsown",
                reportingYear: true,
            });
            crops.push(pastureCrop);
        }
        const sown = cropUtils.findCropEvent(pastureCrop, "Cropsown");
        sown.month = block.monthResown;
    }

    isValid(block, source = undefined) {
        const currentValidation = this.state.validation;
        let validation = {};
        let message = undefined;
        const { blockIds = [] } = block;
        const { blocks = [] } = this.props.analysis;
        const totalArea = blockIds.map((id) => blocks.find((b) => b.id === id)).reduce((t, b) => (t += b.areaInHectares), 0);

        const required = (parent, keys) => {
            /* eslint-disable no-unused-vars */
            for (const key of keys) {
                const objKey = key.split("_").pop();
                validation[key] = utils.setVal(key, currentValidation, source, validations.requiredExt(parent[objKey]));
            }
        };
        required(block, ["name", "rotationArea", "monthResown"]);
        validateCrops(block, validation, currentValidation, source);

        message = (validation.name && validation.name.message) || validations.maxLength(50)(block.name);
        validation.name = utils.setVal("name", currentValidation, source, message);

        message = (validation.rotationArea && validation.rotationArea.message) || validations.range(0.1, 40000)(block.rotationArea);
        validation.rotationArea = utils.setVal("rotationArea", currentValidation, source, message);

        if (totalArea > 0 && block.rotationArea > 0 && !message && !source) {
            const rotationPercentage = Math.round((block.rotationArea / totalArea) * 100);
            message = rotationPercentage > 25 ? `Fodder crop rotation area must be less than or equal to 25% of the total area of the selected pastoral blocks. Currently ${rotationPercentage}%` : undefined;
            validation.rotationArea = utils.setVal("rotationArea", currentValidation, source, message);
        }

        message = (validation.nonPastureApplication && validation.nonPastureApplication.message) || validations.range(0, 12)(block.nonPastureApplication);
        validation.nonPastureApplication = utils.setVal("nonPastureApplication", currentValidation, source, message);

        message = blockIds.length === 0 ? "Required" : undefined;
        validation.blockIds = utils.setVal("blockIds", currentValidation, source, message);

        this.setState({ validation: validation });
        /* eslint-disable no-unused-vars */
        for (let key of Object.keys(validation)) {
            if (validation[key].error) {
                return false;
            }
        }
        return true;
    }

    submitEvent(evtType, data) {
        const { block, validation = {} } = this.state;
        cropUtils.handleEvent(evtType, data, block);
        validateCrops(block, validation, validation, undefined);
        this.setState({ block: block, validation: validation, dirty: true });
        this.props.resetGrowthCurve();
    }

    delCultivation(data) {
        const block = this.state.block;

        this.setState({ block: block });
    }

    getAnalysis() {
        const { analysis, block } = this.state;
        const { blocks = [] } = analysis;
        analysis.blocks = blocks;
        utils.merge(block, analysis.blocks);
        return analysis;
    }

    submit = async () => {
        const analysis = this.getAnalysis();
        const block = this.state.block;
        if (this.isValid(block)) {
            this.setState({ submitting: true, submitSucceeded: false });
            cropUtils.clearSupplementValues(block, analysis);
            await this.props.updateAnalysisAsync(analysis);
            this.setState({ submitting: false, submitSucceeded: true });
        }
        this.setState({ touched: true });
    };

    selectBlock(blockId) {
        const { block = {} } = this.state;
        const { blockIds = [] } = block;
        block.blockIds = blockIds;
        if (!blockIds.includes(blockId)) blockIds.push(blockId);
        this.setState({ block, dirty: true });
    }

    deselectBlock(blockId) {
        const { block = {} } = this.state;
        const { blockIds = [] } = block;
        block.blockIds = blockIds.filter((b) => b !== blockId);
        this.setState({ block, dirty: true });
    }

    render() {
        const { block = {}, validation = {} } = this.state;
        const { crops = [] } = block;

        const { blocks = [] } = this.props.analysis;
        const referrer = `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/crops`;
        const availableBlocks = blocks.filter((b) => b.type === "ProductivePasture" && b.runoffCharacteristics);

        let incompleteDefols = false;
        for (const c of crops) {
            const { events = [] } = c;
            if (events.find((e) => e.incomplete)) {
                incompleteDefols = true;
                break;
            }
        }

        return (
            <Tile waiting={this.state.submitting}>
                <PageTabs farm={this.props.farm} analysis={this.props.analysis} tab={TAB.CROPS} />
                <TileBody>
                    <SavePrompt blockIf={this.state.dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={referrer} />
                    <Tile title="Crop rotation" referrer={referrer}>
                        <Alert type="info" text="Fodder blocks are blocks on which crops are grown specifically as a supplementary feed for livestock. The fodder crop area is an area of the farm's pasture that is cultivated, sown with a fodder crop and then re-sown back to pasture all within the space of 12 months. A pasture fallow area is an area of pasture that is shut up for 6-12 months." />
                        <TileBody>
                            <BlockDetails block={block} blocks={availableBlocks} validation={validation} onChange={(e, source) => this.onChange(e, source)} />
                            <Crops analysis={this.state.analysis} block={block} isRotation={true} growthCurve={this.props.growthCurve} validation={validation} submitEvent={(e, d) => this.submitEvent(e, d)} showCopyCrops={false} />
                        </TileBody>
                        <TileFooter>
                            <div className="ButtonBar ButtonBar--fixed">
                                <div className="ButtonBar-left">
                                    <Link to={referrer} className="Button Button--secondary" id="crop-cancel">
                                        Cancel
                                    </Link>
                                </div>
                                <div className="ButtonBar-right">
                                    <Button id="crop-submit" submit primary onClick={() => this.submit()} waiting={this.state.submitting} disabled={incompleteDefols}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </TileFooter>
                    </Tile>
                </TileBody>
            </Tile>
        );
    }
}
