import AnimalsActionsBar from "./AnimalsActionsBar";
import { EnterprisesGreenBox } from "containers/BudgetHome/Animals/EnterprisesGreenBox";
import EnterpriseList from "./EnterpriseList";
import BlockList from "./BlockList";

export default function Animals({ farm, analysis }) {
    const useNewGreenBox = true;
    return (
        <>
            <AnimalsActionsBar farm={farm} analysis={analysis} />
            {useNewGreenBox
                ? <EnterprisesGreenBox farm={farm} analysis={analysis} />
                : <EnterpriseList farm={farm} analysis={analysis} />
            }
            <BlockList farm={farm} analysis={analysis} />
        </>
    )
}
