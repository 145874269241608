import { Range } from "rc-slider";
import "rc-slider/assets/index.css";

export default function SoilProportionsSlider({ soilProportions, label = "Move slider to adjust % of each soil on this polygon", onChange, step = 10 }) {
    if (soilProportions.length === 0) {
        return null;
    }

    let sliderValuesTotal = 0;
    const sliderValues = soilProportions.reduce(
        (results, proportion) => {
            results.push((sliderValuesTotal += proportion));
            return results;
        },
        [0]
    );

    let sliderHandles = [];
    if (sliderValues.length === 2) {
        sliderHandles = [HIDDEN_HANDLE_STYLE, ACTIVE_HANDLE_STYLE];
    } else if (sliderValues.length === 3) {
        sliderHandles = [HIDDEN_HANDLE_STYLE, ACTIVE_HANDLE_STYLE, HIDDEN_HANDLE_STYLE];
    } else if (sliderValues.length === 4) {
        sliderHandles = [HIDDEN_HANDLE_STYLE, ACTIVE_HANDLE_STYLE, ACTIVE_HANDLE_STYLE, HIDDEN_HANDLE_STYLE];
    }

    const onSliderChange = async (proportions) => {
        // Prevent dragging of hidden end handles.
        const startHiddenHandleDragged = proportions[0] !== 0;
        const endHiddenHandleDragged = sliderValues.length > 2 && proportions[proportions.length - 1] !== 100;
        if (startHiddenHandleDragged || endHiddenHandleDragged) return;

        if (onChange) {
            onChange(proportions);
        }
    };

    return (
        <>
            {label && <h5 className="u-mt-sm u-mb-sm">{label}</h5>}
            <Range onChange={onSliderChange} step={step} pushable={10} value={sliderValues} handleStyle={sliderHandles} />
        </>
    );
}

const HIDDEN_HANDLE_STYLE = { display: "none" };
const ACTIVE_HANDLE_STYLE = { backgroundColor: "#509bc2", borderColor: "#509bc2", height: "16px", width: "16px", marginTop: "-6px" };
