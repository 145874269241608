import React from "react";
import SelectPack from "components/SelectPack";
import * as utils from "common/utils";
import * as cropUtils from "./_utils";

//Event Properties
// 1. RotationMonth (1-36)
// 2. Month (Jan, Feb etc)
// 3. RotationYear (Year1, ReportingYear, PostReportingYear)

const RotationYear = (props) => {
    if (props.isHidden) return null;

    const { rotationYear = [], disableYear3 } = props;
    if (rotationYear.length < 12) return null;
    let options = [];
    const rotationIndex = props.rotationIndex || cropUtils.rotationIndex(props.value, rotationYear);
    const rotationIndexValue = isNaN(rotationIndex) ? "" : String(rotationIndex);
    const rotationYearCount = rotationYear.length > 24 ? 3 : rotationYear.length > 12 ? 2 : 1;

    options.push(
        <option value="" key="" disabled={props.initReadonly}>
            {props.initLabel}
        </option>
    );

    const formatOption = (i) => {
        const { disabledMonths = [] } = props;
        const m = rotationYear[i];
        const isReportingYear = rotationYearCount === 1 ? i <= 11 : i > 11 && i <= 23;
        const isPostReportingYear = i > 23;
        const disabled = disabledMonths.filter((o) => Number(i) === Number(o)).length > 0;
        const selectedExt = rotationYearCount > 1 && i === rotationIndex ? (isReportingYear ? "- Reporting Year" : isPostReportingYear ? "- Post Reporting Year" : "- Year 1") : "";
        const shift = props.shift || 0;
        if (disabled) return;
        return (
            <option disabled={disabled} key={i} value={i + shift}>
                {disabled ? m : `${m} ${selectedExt}`}
            </option>
        );
    };

    const year1 = [];
    for (let i = 0; i < 12; i++) {
        const option = formatOption(i);
        option && year1.push(option);
    }

    if (rotationYearCount > 1) {
        const year2 = [];
        for (let i = 12; i < 24; i++) {
            const option = formatOption(i);
            option && year2.push(option);
        }
        year1.length > 0 &&
            options.push(
                <optgroup key="Year1" label="Year 1">
                    {year1}
                </optgroup>
            );
        year2.length > 0 &&
            options.push(
                <optgroup key="ReportingYear" label="Reporting Year">
                    {year2}
                </optgroup>
            );
        if (rotationYearCount > 2 && !disableYear3) {
            const year3 = [];
            for (let i = 24; i < rotationYear.length; i++) {
                const option = formatOption(i);
                option && year3.push(option);
            }

            year3.length > 0 &&
                options.push(
                    <optgroup key="PostReportingYear" label="Post Reporting Year">
                        {year3}
                    </optgroup>
                );
        }
    } else {
        options = [...options, ...year1];
    }

    const onChange = (e) => {
        const monthIndx = parseInt(utils.ctrlVal(e), 10);
        const month = rotationYear[monthIndx];
        const ry = (monthIndx > 11 && monthIndx < 24) || rotationYearCount === 1 ? "ReportingYear" : monthIndx >= 24 ? "PostReportingYear" : "PreReportingYear";
        props.onChange(
            {
                month: month,
                rotationYear: ry,
                rotationMonth: monthIndx,
            },
            { type: props.type }
        );
    };

    return (
        <SelectPack id={props.id} tip={props.tip} dataWidth={props.dataWidth} disabled={props.disabled} label={props.label} isHidden={props.isHidden} val={props.val} value={rotationIndexValue} requiredLabel={props.requiredLabel} meta={{ nrf: true }} onChange={(e) => onChange(e)}>
            {options}
        </SelectPack>
    );
};

export default RotationYear;
