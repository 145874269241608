import React from "react";
import { useLocation, matchPath, Switch, Route, useParams } from "react-router-dom";
import useDetectPrint from "react-detect-print";
import * as utils from "common/utils";
import ActionLink from "components/ActionLink";
import { Panel, PanelBody, PanelTabs } from "components/Panel";
import Alert from "components/Alert";
import Overview from "./Overview";
import AggregatedReporting from "./AggregatedReporting/AggregatedReporting";
import BenchmarkingByFarms from "./BenchmarkingByFarms/BenchmarkingByFarms";
import BenchmarkingByBlocks from "./BenchmarkingByBlocks/BenchmarkingByBlocks";
import DistributionGraphs from "./DistributionGraphs";
import Publications from "./Publications/Publications";
import Membership from "./Membership/Membership";
import ReportingError from "./ReportingError";
import ReportingHeatmap from "./ReportingHeatmap";
import FeatureTracker, { FEATURES } from "components/FeatureTracker/FeatureTracker";
import { useAuthContext, useOnline, useRefData, useShowQuickTips } from "common/hooks";
import HistoryGraphs from "./HistoryGraphs";
import { useQueryClient } from "@tanstack/react-query";

export default function Reporting({ historyData, historyError, historyLoading, publications, farmIds, farm, farmTag, year, includeLatestYearEnds, publicationsLoading, reportData, reportDataError, reportDataLoading, farmGroup, inviteMembers, onYearChanged, onIncludeLatestYearEndChanged, onFarmTagChanged, availableFarmTags, availableYears }) {
    const { farmId, farmGroupId } = useParams();

    const authContext = useAuthContext();
    const isSystemAdmin = authContext?.isSystemAdmin;

    const online = useOnline();
    const refData = useRefData();
    const showQuickTips = useShowQuickTips();
    const location = useLocation();

    const printing = useDetectPrint();
    const queryClient = useQueryClient();

    const reloadFarmGroup = () => {
        queryClient.invalidateQueries({ queryKey: ["reporting"] });
    };

    const hasPrivateAccess = farmGroup && farmGroup.farmGroupVisibility === "Private" && farmGroup.role === "Admin";
    const hasOwnerAccess = farmGroup && farmGroup.farmGroupVisibility === "Owner" && farm && farm.role === "Owner";
    const isPublicationReporting = !farmGroup;
    const hideAllButMembership = farmGroup && farmGroup.farmGroupVisibility !== "FarmAccess" && farm && !hasPrivateAccess && !hasOwnerAccess && !isSystemAdmin;

    const showPublicationsTab = isPublicationReporting || !hideAllButMembership;
    const showHistoryTab = isPublicationReporting || !hideAllButMembership;
    const showAggregatedReportingTab = isPublicationReporting || (!hideAllButMembership && farmGroup && (farmGroup.showAggregatedReport || farmGroup.role === "Admin" || isSystemAdmin));
    const showBenchmarkingTabs = isPublicationReporting || (!hideAllButMembership && farmGroup && (farmGroup.showBenchmarkReport || farmGroup.role === "Admin" || isSystemAdmin));
    const showMembershipTab = farmGroup && (farmGroup.showMembers || farmGroup.role === "Admin" || isSystemAdmin);
    const heatMapData = reportData && reportData.benchmarkedFarms && reportData.benchmarkedFarms.farms;

    const baseUrl = getBaseUrl(farmId, farmGroupId);
    const tabs = {
        aggregatedReporting: { title: "Aggregated reporting", path: `${baseUrl}/aggregated`, exact: true, show: !!showAggregatedReportingTab },
        benchmarkingByFarms: { title: "Benchmarking by farms", path: `${baseUrl}/farms`, exact: true, show: !!showBenchmarkingTabs },
        benchmarkingByBlocks: { title: "Benchmarking by blocks", path: `${baseUrl}/blocks`, exact: true, show: !!showBenchmarkingTabs },
        distributions: { title: "Distributions", path: `${baseUrl}/distributions`, exact: true, show: !!showBenchmarkingTabs },
        publications: { title: "Publications", path: `${baseUrl}/publications`, exact: true, show: !!showPublicationsTab },
        history: { title: "Trends", path: `${baseUrl}/history`, exact: true, show: !!showHistoryTab },
        membership: { title: "Membership", path: `${baseUrl}/membership`, exact: true, show: !!showMembershipTab },
    };

    const activeTab = getActiveTab(tabs, location);
    const isPublicationsTab = activeTab.path === tabs.publications.path;
    const isAggregatedReportingTab = activeTab.path === tabs.aggregatedReporting.path;
    //const isMapTab = activeTab.path === tabs.map.path;
    const isBenchmarkingByFarmsTab = activeTab.path === tabs.benchmarkingByFarms.path;
    const isBenchmarkingByBlocksTab = activeTab.path === tabs.benchmarkingByBlocks.path;
    const isDistributionsTab = activeTab.path === tabs.distributions.path;
    const isMembershipTab = activeTab.path === tabs.membership.path;
    const isHistoryTab = activeTab.path === tabs.history.path;
    const showOverview = !isPublicationsTab && !isMembershipTab && !isHistoryTab && reportData && reportData.aggregatedFarmData && reportData.aggregatedFarmData.aggregatedData && reportData.aggregatedFarmData.aggregatedData.results && reportData.aggregatedFarmData.aggregatedData.totalArea > 0;

    const actions = !isMembershipTab && (
        <>
            {availableFarmTags && <FarmTagFilter availableFarmTags={availableFarmTags} onFarmTagChanged={onFarmTagChanged} />}
            <YearEndingFilter availableYears={availableYears} year={year} onYearChanged={onYearChanged} includeLatestYearEnds={includeLatestYearEnds} onIncludeLatestYearEndChanged={onIncludeLatestYearEndChanged} />
        </>
    );

    const farmTagFilterHelpText = availableFarmTags && availableFarmTags.length > 0 ? 'Use the "Tag farms" link above to group and filter the available farms by tag. ' : "";
    const yearEndingFilterHelpText = 'When filtering by year, use the "Or most recent" checkbox to include farms whose most recent publication is prior to the selected year.';

    const downloadCsv = () => {
        const fileName = farmGroup && farmGroup.name ? `${farmGroup.name.replaceAll(" ", "")}-${year}.csv` : `Publications-${year}.csv`;
        const header = [
            "FarmName",
            "SupplierNumber",
            "AnalysisName",
            "Region",
            "FarmArea",
            "ProductiveArea",
            "EffluentArea",
            "NLossInKg",
            "NLossInKgPerHa",
            "PLossInKg",
            "PLossInKgPerHa",
            "GHGEmissionsInKg",
            "GHGEmissionsKgPerHa",
            "NSurplusInKgPerHa",
            "NAddedFertiliser",
            "NAddedSupplments",
            "NRemovedProduct",
            "NCE%",
            "MethaneInKg",
            "MethaneKgPerHa",
            "CO2InKg",
            "CO2KgPerHa",
            "N2OInKg",
            "N2OKgPerHa",
            "SyntheticNPastureRate",
            "OrganicNPastureRate",
            "SyntheticPPastureRate",
            "OrganicPPastureRate",
            "PercentPastureFed",
            "Latitude",
            "Longitude",
            "AchievementLiquid150kgN",
            "AchievementSolid150kgN",
            "MilkProduction",
            "PastureArea",
            "PastureSyntheticFertiliserTotalN",
            "PastureSyntheticFertiliserTotalP",
            "PastureOrganicFertiliserTotalN",
            "PastureOrganicFertiliserTotalP",
            //wrap
        ];
        const { regions = [] } = refData;

        const extendCsv = publications && publications.length > 0;
        if (extendCsv) {
            header.push("PublicationReference");
            header.push("PublisheeFarmId");
        }

        // Add a csv header column for each possible enterprise type
        refData.enterpriseTypes.forEach((enterpriseType) => header.push(`${enterpriseType.value}Rsu`));

        const body = reportData.benchmarkedFarms.farms.map((farm) => {
            let pastureArea = utils.round(farm.pastureArea, 2) || 1;
            if (pastureArea <= 0) pastureArea = 1;

            const syntheticN = utils.round((utils.round(farm.pastureSyntheticFertiliserTotalN, 2) || 0) / pastureArea, 2);
            const organicN = utils.round((utils.round(farm.pastureOrganicFertiliserTotalN, 2) || 0) / pastureArea, 2);
            const syntheticP = utils.round((utils.round(farm.pastureSyntheticFertiliserTotalP, 2) || 0) / pastureArea, 2);
            const organicP = utils.round((utils.round(farm.pastureOrganicFertiliserTotalP, 2) || 0) / pastureArea, 2);
            const pastureRSU = farm.enterprises && farm.enterprises.reduce((t, e) => (t += e.rsuPasture), 0);
            const totalRSU = farm.enterprises && farm.enterprises.reduce((t, e) => (t += e.rsu), 0);
            const publication = publications.find((p) => p.farmId === farm.id);

            const bodyValues = [
                farm.isMyFarm ? farm.farmName.replace(/,/g, "") : "",
                farm.isMyFarm ? farm.supplierNumber?.replace(/,/g, "") : "",
                farm.analysisName.replace(/,/g, ""),
                utils.valueToText(regions, farm.farmRegion),
                farm.farmArea,
                utils.round(farm.productiveArea, 2),
                utils.round(farm.effluentArea, 2),
                farm.nLossTotal,
                farm.nLossPerHa,
                farm.pLossTotal,
                farm.pLossPerHa,
                farm.ghgTotal,
                farm.ghgPerHa,
                farm.nSurplus,
                farm.nitrogen?.addedFertiliser,
                farm.nitrogen?.addedSupplements,
                farm.nitrogen?.removedProduct,
                farm.nce,
                farm.methaneTotal,
                farm.methanePerHa,
                farm.cO2Total,
                farm.cO2PerHa,
                farm.n2OTotal,
                farm.n2OPerHa,
                syntheticN,
                organicN,
                syntheticP,
                organicP,
                totalRSU ? utils.round((100 * pastureRSU) / totalRSU, 1) : 0,
                farm.latitude,
                farm.longitude,
                farm.achievementLiquid150kgN,
                farm.achievementSolid150kgN,
                farm.milkProduction,
                utils.round(farm.pastureArea, 2),
                utils.round(farm.pastureSyntheticFertiliserTotalN, 2),
                utils.round(farm.pastureSyntheticFertiliserTotalP, 2),
                utils.round(farm.pastureOrganicFertiliserTotalN, 2),
                utils.round(farm.pastureOrganicFertiliserTotalP, 2),
                //Keep wrap
            ];

            if (extendCsv) {
                bodyValues.push(publication ? publication.reference : "");
                bodyValues.push(publication ? publication.farmIdentifier : "");
            }
            // Add a csv body column entry for each possible enterprise type
            refData.enterpriseTypes.forEach((refDataEnterpriseType) => {
                // Default rsu to zero if this enterprise on not on this farm.
                const enterpriseFromFarms = (farm.enterprises || []).find((entRsu) => entRsu.type === refDataEnterpriseType.value) || { rsu: 0 };
                bodyValues.push(utils.round(enterpriseFromFarms.rsu, 0));
            });

            return bodyValues.map((v) => (v ? String(v).replaceAll(",", "") : v)).join(",");
        });
        const csv = header.join(",") + "\n" + body.join("\n");

        var blob = new Blob([csv], { type: "text/csv" });
        var url = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    const farmLookup =
        isSystemAdmin && reportData && reportData.benchmarkedFarms && reportData.benchmarkedFarms.farms
            ? reportData.benchmarkedFarms.farms.reduce((d, f) => {
                  d[f.id] = f;
                  return d;
              }, {})
            : undefined;

    const loading = reportDataLoading || historyLoading || publicationsLoading;

    return (
        <Panel className="u-print-white">
            <PanelTabs tabs={tabs} actions={actions} />
            {showQuickTips && <Alert className="u-mt-0 u-mb-0" type="help" text={`${farmTagFilterHelpText}${yearEndingFilterHelpText}`} />}
            <PanelBody>
                {showOverview && <Overview aggregatedData={reportData.aggregatedFarmData.aggregatedData} farmsWithResultsCount={reportData.aggregatedFarmData.farmsWithResultsCount} includeLatestYearEnds={includeLatestYearEnds} />}
                {(isAggregatedReportingTab || isBenchmarkingByFarmsTab || isBenchmarkingByBlocksTab || isDistributionsTab || isHistoryTab) && (
                    <Panel
                        title={activeTab.title}
                        className={showOverview ? "u-mt-md" : ""}
                        loading={loading}
                        actions={
                            reportData && reportData.benchmarkedFarms && isBenchmarkingByFarmsTab ? (
                                <ActionLink id="download-benchmarking-by-farms" className="IconLink--download u-link u-textWhite" onClick={downloadCsv}>
                                    Download as csv
                                </ActionLink>
                            ) : null
                        }
                        skyBlue
                    >
                        <PanelBody grey={!printing} loading={loading}>
                            {reportDataError && <ReportingError msg={reportDataError} online={online} />}
                            {!reportDataError && (
                                <Switch>
                                    <Route
                                        exact
                                        path={tabs.history.path}
                                        render={() => {
                                            return (
                                                <>
                                                    {farmGroup && <FeatureTracker feature={FEATURES.FARM_GROUP_REPORTING} action="History" farm={farm} />}
                                                    {!farmGroup && <FeatureTracker feature={FEATURES.PUBLICATION_REPORTING} action="History" farm={farm} />}
                                                    <HistoryGraphs farmGroup={farmGroup} loading={historyLoading} error={historyError} data={historyData} isPublicationReporting={isPublicationReporting} />
                                                </>
                                            );
                                        }}
                                    />
                                    <Route
                                        exact
                                        path={tabs.aggregatedReporting.path}
                                        render={() => {
                                            return (
                                                <>
                                                    {farmGroup && <FeatureTracker feature={FEATURES.FARM_GROUP_REPORTING} action="Aggregated reporting" farm={farm} />}
                                                    {!farmGroup && <FeatureTracker feature={FEATURES.PUBLICATION_REPORTING} action="Aggregated reporting" farm={farm} />}
                                                    <AggregatedReporting aggregatedData={reportData.aggregatedFarmData.aggregatedData} farmLookup={farmLookup} farmsWithResultsCount={reportData.aggregatedFarmData.farmsWithResultsCount} farmGroup={farmGroup} />
                                                </>
                                            );
                                        }}
                                    />

                                    {false && <Route exact path={tabs.map.path} render={() => <ReportingHeatmap locations={heatMapData} />} />}

                                    <Route
                                        exact
                                        path={tabs.benchmarkingByFarms.path}
                                        render={() => {
                                            return (
                                                <>
                                                    {farmGroup && <FeatureTracker feature={FEATURES.FARM_GROUP_REPORTING} action="Benchmarking by farm" farm={farm} />}
                                                    {!farmGroup && <FeatureTracker feature={FEATURES.PUBLICATION_REPORTING} action="Benchmarking by farm" farm={farm} />}
                                                    <BenchmarkingByFarms benchmarkData={reportData.benchmarkedFarms} farmId={farmId} year={year} farmGroup={farmGroup} refData={refData} />
                                                </>
                                            );
                                        }}
                                    />
                                    <Route
                                        exact
                                        path={tabs.benchmarkingByBlocks.path}
                                        render={() => {
                                            return (
                                                <>
                                                    {farmGroup && <FeatureTracker feature={FEATURES.FARM_GROUP_REPORTING} action="Benchmarking by block" farm={farm} />}
                                                    {!farmGroup && <FeatureTracker feature={FEATURES.PUBLICATION_REPORTING} action="Benchmarking by block" farm={farm} />}
                                                    <BenchmarkingByBlocks benchmarkData={reportData.benchmarkedFarms} farmId={farmId} year={year} farmGroup={farmGroup} refData={refData} />
                                                </>
                                            );
                                        }}
                                    />

                                    <Route
                                        exact
                                        path={tabs.distributions.path}
                                        render={() => {
                                            return (
                                                <>
                                                    {farmGroup && <FeatureTracker feature={FEATURES.FARM_GROUP_REPORTING} action="Distributions" farm={farm} />}
                                                    {isPublicationReporting && <FeatureTracker feature={FEATURES.PUBLICATION_REPORTING} action="Distributions" farm={farm} />}
                                                    <DistributionGraphs benchmarkData={reportData.aggregatedFarmData.benchmarkData} farm={farm} farmGroup={farmGroup} farmLookup={farmLookup} />
                                                </>
                                            );
                                        }}
                                    />
                                </Switch>
                            )}
                        </PanelBody>
                    </Panel>
                )}
                {isPublicationsTab && <Publications farmIds={farmIds} year={year} includeLatestYearEnds={includeLatestYearEnds} refData={refData} farmGroup={farmGroup} publications={publications} />}
                {isMembershipTab && <Membership farmGroup={farmGroup} inviteMembers={inviteMembers} reloadFarmGroup={reloadFarmGroup} />}
            </PanelBody>
        </Panel>
    );
}

function getBaseUrl(farmId, farmGroupId) {
    if (farmId && farmGroupId) return `/app/farm/${farmId}/farmGroup/${farmGroupId}`;

    if (farmGroupId) return `/app/farmGroup/${farmGroupId}`;

    return `/app/publicationReporting`;
}

function getActiveTab(tabs, location) {
    if (matchPath(location.pathname, tabs.aggregatedReporting.path)) return tabs.aggregatedReporting;

    //if (matchPath(location.pathname, tabs.map.path))
    //    return tabs.map;

    if (matchPath(location.pathname, tabs.benchmarkingByFarms.path)) return tabs.benchmarkingByFarms;

    if (matchPath(location.pathname, tabs.benchmarkingByBlocks.path)) return tabs.benchmarkingByBlocks;

    if (matchPath(location.pathname, tabs.distributions.path)) return tabs.distributions;

    if (matchPath(location.pathname, tabs.membership.path)) return tabs.membership;

    if (matchPath(location.pathname, tabs.publications.path)) return tabs.publications;

    if (matchPath(location.pathname, tabs.history.path)) return tabs.history;
}

function FarmTagFilter({ availableFarmTags, onFarmTagChanged }) {
    if (!availableFarmTags || availableFarmTags.length === 0) return null;

    const farmTagOptions = availableFarmTags.map((tag) => ({ value: tag, text: tag }));
    return (
        <div className="u-flex flex-center">
            <div className="Panel-action-label">Farm tag:</div>
            <div className="Select Select--slim">
                <select id="farmTag" onChange={(e) => onFarmTagChanged(e.target.value)}>
                    <option value="">All farms</option>
                    {farmTagOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.text}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

function YearEndingFilter({ availableYears, year, includeLatestYearEnds, onYearChanged, onIncludeLatestYearEndChanged }) {
    if (!availableYears || availableYears.length === 0 || !year) return null;

    const yearOptions = availableYears.map((year) => ({ value: year, text: year }));
    return (
        <div className="u-flex flex-center">
            <div className="Panel-action-label">Year ending:</div>
            <div className="Select Select--slim">
                <select id="year" onChange={(e) => onYearChanged(e.target.value)} value={year}>
                    {yearOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.text}
                        </option>
                    ))}
                </select>
            </div>
            <label className="Checkbox Checkbox--slim u-ml-xs" htmlFor="includeLatestYearEnds">
                <input id="includeLatestYearEnds" className="Checkbox-input" type="checkbox" checked={includeLatestYearEnds} onChange={(e) => onIncludeLatestYearEndChanged(e.target.checked)} />
                <span className="Checkbox-label u-textNoWrap">Or most recent</span>
            </label>
        </div>
    );
}
