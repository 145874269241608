import React from "react";
import Select, { components } from "react-select";

const getBlockOptions = (availableBlocks) => {
    return availableBlocks
        .map((b) => {
            return { value: b.id, label: b.name };
        })
        .sort((a, b) => (a.label >= b.label ? 1 : -1));
};

const getGroupedBlockOptions = (defaultLabel, availableBlocks) => {
    const uniqueLabels = Array.from(new Set([defaultLabel, ...availableBlocks.filter((b) => b.groupLabel).map((b) => b.groupLabel)]));
    return uniqueLabels.map((g) => {
        return {
            label: g,
            options: getBlockOptions(availableBlocks.filter((b) => b.groupLabel === g || (g === defaultLabel && !b.groupLabel))),
        };
    });
};

const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <i className="icon icon-cross-circle" title="Clear all" />
        </components.ClearIndicator>
    );
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="icon icon-arrow-filled" />
        </components.DropdownIndicator>
    );
};

const BlockSelector = ({ input, ...rest }) => {
    const { availableBlocks, instructions, isMulti = true, hideLabel, blockId, onChange, onBlur, defaultGroupLabel } = rest;

    const options = defaultGroupLabel ? getGroupedBlockOptions(defaultGroupLabel, availableBlocks) : getBlockOptions(availableBlocks);
    const flatOptions = defaultGroupLabel ? getBlockOptions(availableBlocks) : options;
    const value = isMulti ? flatOptions.filter((o) => (input.value || []).includes(o.value)) : flatOptions.find((o) => o.value === input.value);
    const hasError = rest.meta && rest.meta.touched && rest.meta.error;

    const handleChange = (input, isMulti) => (value, action) => {
        const values = isMulti ? (value ? value.map((v) => v.value) : []) : value.value;
        if (input.onChange) input.onChange(values, action);
        if (onChange) onChange(values, action);
    };

    const handleBlur = (input) => (e) => {
        if (input.onBlur) input.onBlur(input.value);
        if (onBlur) onBlur(input.value);
    };

    const colourStyles = {
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                background: data.value === blockId ? "repeating-linear-gradient(-60deg, #E39153, #E39153 5px, #F39153 5px, #F39153 10px) !important" : "repeating-linear-gradient(-60deg, #4591b9, #4591b9 5px, #549bc0 5px, #549bc0 10px) !important",
                color: "#fff !important",
                padding: "5px 10px !important",
                marginRight: "3px !important",
                marginBottom: "3px !important",
            };
        },
    };

    return (
        <React.Fragment>
            {!hideLabel && <h3>Blocks</h3>}
            {instructions && <p>{instructions}</p>}
            <div className={`Field ${hasError ? "has-error" : ""}`}>
                <Select {...input} {...rest} id={input.id || input.name || "blockIds"} value={value} onChange={handleChange(input, isMulti)} onBlur={handleBlur(input)} options={options} isMulti={isMulti} components={{ ClearIndicator, DropdownIndicator }} closeMenuOnSelect={!isMulti} classNamePrefix="ovr_multiSelect" menuShouldScrollIntoView={false} styles={colourStyles} placeholder="Select one or more blocks" noOptionsMessage={(props) => "All available blocks selected"} />
                {hasError && (
                    <small className="Field-error u-mt-xs" id={`${input.id || input.name || "blockIds"}-error`}>
                        {rest.meta.error}
                    </small>
                )}
            </div>
        </React.Fragment>
    );
};

export default BlockSelector;
