import React from "react";
import { compose, withAppContext } from "containers/HOC";
import * as iu from "./_utils";
import NumericInputPack from "components/NumericInputPack";
import SelectPack from "components/SelectPack";
import CheckboxPack from "components/CheckboxPack";
import * as domain from "common/domain";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import BlockSelector from "components/new/BlockSelector";

class ActivityModal extends React.Component {
    _closeOnEscape = (e) => {
        if (e.keyCode === 27) {
            this.props.cancelApplication();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this._closeOnEscape);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._closeOnEscape);
    }

    render() {
        const { availableBlocks, intersectedBlocks, irrigator, saveApplication, cancelApplication, onChange, validation, irrigationSoilMoistureUsage, irrigationManagementUnits, isFrost, application } = this.props;

        const multiManagementSysDef = iu.evalMultiManagementSysDef(irrigator);
        const showBasedOn = irrigator.type !== "";
        const basedOnOptions = domain.irrigationMoistureAssesmentTypes.filter((option) => {
            if (irrigator.type === iu.IrrigatorTypes.BorderDyke) {
                return [iu.Schedule.BorderDyke, iu.Schedule.Depth].includes(option.value);
            } else if (irrigator.type === iu.IrrigatorTypes.Flood) {
                return [iu.Schedule.Flood, iu.Schedule.Depth].includes(option.value);
            } else {
                return ![iu.Schedule.BorderDyke, iu.Schedule.Flood].includes(option.value);
            }
        });
        const showOutwash = irrigator.type === iu.IrrigatorTypes.BorderDyke && application.schedule === iu.Schedule.BorderDyke;

        const basedOnVal = validation[iu.basedOnId] || {};
        const blockVal = validation[iu.blksId] || {};
        const stratVal = validation[iu.soilMoistureUsageId] || {};
        const minDepVal = validation[iu.minDepthId] || {};
        const maxDepVal = validation[iu.maxDepthId] || {};
        const depVal = validation[iu.depthId] || {};
        const rpVal = validation[iu.retPerId] || {};
        const mrpVal = validation[iu.minRetPerId] || {};
        const unVal = validation[iu.unitsId] || {};
        const tpVal = validation[iu.trigPtId] || {};
        const tarVal = validation[iu.targetId] || {};
        const mDefVal = validation[iu.manDefaultId] || {};
        const aftVal = validation[iu.avgFrostTempId] || {};
        const afdVal = validation[iu.avgFrostDurId] || {};
        const fdVal = validation[iu.frostDaysId] || {};
        const appDepthVal = validation[iu.appDepthId] || {};

        const isUserDefined = application.systemDefinition === iu.SystemDefinitions.UserDefined;
        const scheduleConfig = iu.evalScheduleConfig(application, irrigator.type);
        const soilMoistureUsageConfig = iu.evalSoilMoistureUsageConfig(application, irrigator.type);
        const showSoilMoistureUsage = scheduleConfig.SoilMoistureUsageControls.find((s) => s.Controls.includes(iu.Ctrl.SoilMoistureUsage));
        const moistureUsageAlert = showSoilMoistureUsage && iu.IrrigationApplicationTips[application.soilMoistureUsage];

        const scheduleTip = !isFrost && (showBasedOn || showSoilMoistureUsage) && (application.schedule === iu.Schedule.Depth ? "Use with caution: Entered depth must align with 30 year climate data. Divides the entered depth by the number of days in the month and applies that daily." : application.schedule === iu.Schedule.Fixed || application.schedule === iu.Schedule.Visual ? "Uses irrigation system defaults for application depth and return period. These defaults are based on soil profile available water (PAW) content to 60cm. Can override defaults below." : undefined);

        const { blockIds = [] } = application;

        const availableBlocksFilteredForFrost = isFrost ? (availableBlocks || []).filter((b) => b.type === domain.BlockType.ProductiveFruit) : availableBlocks;
        const intersectedBlocksFilteredForFrost = isFrost ? (intersectedBlocks || []).filter((b) => b.type === domain.BlockType.ProductiveFruit) : intersectedBlocks;
        const availableFodderCropBlocks = availableBlocksFilteredForFrost.filter((b) => b.type === domain.BlockType.FodderCrop);
        const selectableBlocks = (intersectedBlocksFilteredForFrost || []).length > 0 ? [...intersectedBlocksFilteredForFrost, ...availableFodderCropBlocks] : availableBlocksFilteredForFrost;

        return (
            <div className="Modal_wrapper">
                <input type="hidden" id="irrigation_activity_id" value={application.id} />
                <div className="Modal Modal--medium">
                    <div className="Modal-head">
                        <span className="Modal-head--left">{isFrost ? "Frost protection" : "Irrigation activities"}</span>
                        <span className="Modal-head--right">
                            <ActionLink onClick={this.props.toggleQuickTips} className="Modal-close">
                                <i className="icon icon-question" title={`${this.props.showQuickTips ? "Hide" : "Show"} quick tips`} />
                            </ActionLink>
                            <ActionLink onClick={this.props.cancelApplication} id="irrigation_modal_close" className="Modal-close">
                                <i className="icon icon-cross" title="Close" />
                            </ActionLink>
                        </span>
                    </div>
                    <div className="Modal-body" style={{ minHeight: "300px" }}>
                        <Alert
                            type="info"
                            html="<div>
                            Select the strategy that is used to determine the return period and application depth of applications.
                            <br><b>Profile available water (PAW)</b> is the amount of water that a soil can hold for plant growth. Heavy, deep soils have higher PAW then light stony soils. 
                            <br><b>Return period</b> is the number of days between applications.
                            <br><b>Application depth</b> is the depth of water applied in an application.
                            <br><b>Trigger point</b> is the soil water content at which irrigation is deemed necessary to avoid plant stress. Also called ‘refill point’. The trigger point may be defined as a deficit from (in mm) or a % of the soils PAW.
                            <br><b>Target</b> is the soil water content irrigation is applied to achieve. Usually set to just below saturation to allow space for rainfall. Targets may be defined as a deficit from or a % of the soils PAW.
                            <p>The use of %PAW or mm deficit for trigger point and target will depend on the equipment that is used to measure soil mositure against PAW.</p>
                        </div>"
                        />

                        <div className="Grid">
                            <div className="Grid-cell">
                                <BlockSelector
                                    input={{
                                        value: blockIds,
                                        onChange: (blockIds) => onChange({ type: "application", key: "irrigator-blocks", applicationId: application.id, id: iu.blksId }, blockIds),
                                    }}
                                    availableBlocks={selectableBlocks}
                                    meta={{
                                        touched: blockVal.touched,
                                        error: blockVal.message,
                                    }}
                                />
                                {isFrost && (
                                    <div className="Field-group">
                                        <NumericInputPack id={iu.avgFrostTempId} name={iu.avgFrostTempId} label="Average frost temperature" placeholder="" uom="&deg;C" requiredLabel={true} val={aftVal} dataWidth="33" onChange={(e) => onChange({ type: "application", key: "averageFrostTempC", applicationId: application.id, id: iu.avgFrostTempId }, e)} decimalPlaces={2} value={application.averageFrostTempC || ""} />
                                        <NumericInputPack id={iu.avgFrostDurId} name={iu.avgFrostDurId} label="Average frost duration" placeholder="" uom="Hours" requiredLabel={true} val={afdVal} dataWidth="33" onChange={(e) => onChange({ type: "application", key: "averageFrostDurationHrs", applicationId: application.id, id: iu.avgFrostDurId }, e)} decimalPlaces={0} value={application.averageFrostDurationHrs || ""} />
                                        <NumericInputPack id={iu.frostDaysId} name={iu.frostDaysId} label="Number of days of frost in a month" placeholder="" uom="days" requiredLabel={true} val={fdVal} dataWidth="33" onChange={(e) => onChange({ type: "application", key: "numberOfDaysOfFrost", applicationId: application.id, id: iu.frostDaysId }, e)} decimalPlaces={0} value={application.numberOfDaysOfFrost || ""} />
                                    </div>
                                )}

                                {!isFrost && (showBasedOn || showSoilMoistureUsage) && (
                                    <div className="Field-group">
                                        <SelectPack id={iu.basedOnId} isHidden={!showBasedOn} name={iu.basedOnId} dataWidth={50} meta={{ nrf: true }} onChange={(e) => onChange({ type: "application", key: "schedule", applicationId: application.id, id: iu.basedOnId }, e)} val={basedOnVal} label="Based on" value={application.schedule || ""} requiredLabel={true}>
                                            <option value="" disabled={true}>
                                                Select a soil moisture assessment type
                                            </option>
                                            {basedOnOptions.map((option) => (
                                                <option value={option.value} key={option.value}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </SelectPack>

                                        <SelectPack id={iu.soilMoistureUsageId} isHidden={!showSoilMoistureUsage} name={iu.soilMoistureUsageId} dataWidth={50} meta={{ nrf: true }} onChange={(e) => onChange({ type: "application", key: "soilMoistureUsage", applicationId: application.id, id: iu.soilMoistureUsageId }, e)} val={stratVal} label="Strategy" value={application.soilMoistureUsage || ""} requiredLabel={true}>
                                            <option value="" disabled={true}>
                                                Select a soil moisture strategy
                                            </option>
                                            {irrigationSoilMoistureUsage.map((soilMoistureUsage) => (
                                                <option value={soilMoistureUsage.value} key={soilMoistureUsage.value}>
                                                    {soilMoistureUsage.text}
                                                </option>
                                            ))}
                                        </SelectPack>
                                        <NumericInputPack id={iu.appDepthId} name={iu.appDepthId} isHidden={application.schedule !== iu.Schedule.Depth} label="Application depth" placeholder="" uom="mm" requiredLabel={true} val={appDepthVal} dataWidth="33" onChange={(e) => onChange({ type: "application", key: "depthAmountMm", applicationId: application.id, id: iu.appDepthId }, e)} decimalPlaces={0} value={application.depthAmountMm} />
                                    </div>
                                )}
                                {scheduleTip && <Alert type="info" text={scheduleTip} />}
                                {showSoilMoistureUsage && moistureUsageAlert && <Alert type="info" text={moistureUsageAlert.text} />}

                                <CheckboxPack meta={{ nrf: true }} isHidden={isFrost || !showOutwash} id={iu.outwashId} label="Outwash" value={application.outWashOccurs} onChange={(e) => onChange({ type: "application", key: "outWashOccurs", applicationId: application.id, id: iu.outwashId }, e)} />

                                {multiManagementSysDef && soilMoistureUsageConfig.Controls.length > 0 && (
                                    <div className={"Field" + (mDefVal.touched && mDefVal.error ? " has-error" : "")}>
                                        <label className="Field-label">
                                            Management System Definition<sup className="required">*</sup>
                                        </label>
                                        <div className="Radio_group Radio_group--inline">
                                            <label className="Radio" htmlFor={iu.manShift1Id}>
                                                <input id={iu.manShift1Id} name={iu.manShift1Id} className="Radio-input" type="radio" checked={application.systemDefinition === iu.SystemDefinitions.DefaultSingleShift} onChange={onChange.bind(this, { type: "application", key: "systemDefinition", applicationId: application.id, id: iu.manShift1Id, value: iu.SystemDefinitions.DefaultSingleShift })} />
                                                <span className="Radio-label">Default 1 shift per day</span>
                                            </label>
                                            <label className="Radio" htmlFor={iu.manShift2Id}>
                                                <input id={iu.manShift2Id} name={iu.manShift2Id} className="Radio-input" type="radio" checked={application.systemDefinition === iu.SystemDefinitions.DefaultDoubleShift} onChange={onChange.bind(this, { type: "application", key: "systemDefinition", applicationId: application.id, id: iu.manShift2Id, value: iu.SystemDefinitions.DefaultDoubleShift })} />
                                                <span className="Radio-label">Default 2 shifts per day</span>
                                            </label>
                                            <label className="Radio" htmlFor={iu.manDefaultId}>
                                                <input id={iu.manDefaultId} name={iu.manDefaultId} className="Radio-input" type="radio" checked={isUserDefined} onChange={onChange.bind(this, { type: "application", key: "systemDefinition", applicationId: application.id, id: iu.manDefaultId, value: iu.SystemDefinitions.UserDefined })} />
                                                <span className="Radio-label">User defined</span>
                                            </label>
                                        </div>
                                        <small className="Field-error">{mDefVal.message}</small>
                                    </div>
                                )}

                                <CheckboxPack meta={{ nrf: true }} isHidden={multiManagementSysDef || soilMoistureUsageConfig.Controls.length === 0} id={iu.manDefaultId} label="Override management defaults" value={isUserDefined} onChange={(e) => onChange({ type: "application", key: "systemDefinition", applicationId: application.id, id: iu.manDefaultId, value: iu.SystemDefinitions.UserDefined }, e)} />

                                {isUserDefined && soilMoistureUsageConfig.Controls.length > 0 && (
                                    <div>
                                        <div className="Field-group">
                                            <NumericInputPack id={iu.minDepthId} name={iu.minDepthId} isHidden={!soilMoistureUsageConfig.Controls.includes(iu.Ctrl.MinimumDepth)} label="Minimum depth" placeholder="" uom="mm/month" requiredLabel={true} val={minDepVal} dataWidth="33" onChange={(e) => onChange({ type: "application", key: "depth", applicationId: application.id, id: iu.minDepthId }, e)} decimalPlaces={0} value={application.depth} />

                                            <NumericInputPack id={iu.maxDepthId} name={iu.maxDepthId} isHidden={!soilMoistureUsageConfig.Controls.includes(iu.Ctrl.MaximumDepth)} label="Maximum depth" placeholder="" uom="mm/application" requiredLabel={true} val={maxDepVal} dataWidth="33" onChange={(e) => onChange({ type: "application", key: "maximumDepth", applicationId: application.id, id: iu.maxDepthId }, e)} decimalPlaces={0} value={application.maximumDepth} />

                                            <NumericInputPack id={iu.depthId} name={iu.depthId} isHidden={!soilMoistureUsageConfig.Controls.includes(iu.Ctrl.Depth)} label="Depth per application" placeholder="" uom="mm/application" requiredLabel={true} val={depVal} dataWidth="33" onChange={(e) => onChange({ type: "application", key: "depth", applicationId: application.id, id: iu.depthId }, e)} decimalPlaces={0} value={application.depth} />

                                            <NumericInputPack id={iu.retPerId} name={iu.retPerId} isHidden={!soilMoistureUsageConfig.Controls.includes(iu.Ctrl.ReturnPeriod)} label="Return period" requiredLabel={true} val={rpVal} uom="days" dataWidth="33" onChange={(e) => onChange({ type: "application", key: "returnPeriod", applicationId: application.id, id: iu.retPerId }, e)} decimalPlaces={0} value={application.returnPeriod} />

                                            <NumericInputPack id={iu.minRetPerId} name={iu.minRetPerId} isHidden={!soilMoistureUsageConfig.Controls.includes(iu.Ctrl.MinReturnPeriod)} label="Minimum return period" uom="days" requiredLabel={true} val={mrpVal} dataWidth="33" onChange={(e) => onChange({ type: "application", key: "returnPeriod", applicationId: application.id, id: iu.minRetPerId }, e)} decimalPlaces={0} value={application.returnPeriod} />

                                            <SelectPack id={iu.unitsId} isHidden={!soilMoistureUsageConfig.Controls.includes(iu.Ctrl.Units)} name={iu.unitsId} dataWidth={33} meta={{ nrf: true }} onChange={(e) => onChange({ type: "application", key: "units", applicationId: application.id, id: iu.unitsId }, e)} val={unVal} label="Units" value={application.units} requiredLabel={true}>
                                                <option value="" disabled={true}>
                                                    Select a unit
                                                </option>
                                                {irrigationManagementUnits.map((unit) => (
                                                    <option value={unit.value} key={unit.value}>
                                                        {unit.text}
                                                    </option>
                                                ))}
                                            </SelectPack>

                                            <NumericInputPack id={iu.trigPtId} name={iu.trigPtId} isHidden={!soilMoistureUsageConfig.Controls.includes(iu.Ctrl.TriggerPoint)} label="Trigger point" requiredLabel={true} val={tpVal} dataWidth="33" uom={application.units === "Deficit" ? "mm" : "%"} onChange={(e) => onChange({ type: "application", key: "trigger", applicationId: application.id, id: iu.trigPtId }, e)} decimalPlaces={0} value={application.trigger} />

                                            <NumericInputPack id={iu.targetId} name={iu.targetId} isHidden={!soilMoistureUsageConfig.Controls.includes(iu.Ctrl.Target)} label="Target" requiredLabel={true} val={tarVal} uom={application.units === "Deficit" ? "mm" : "%"} dataWidth="33" onChange={(e) => onChange({ type: "application", key: "target", applicationId: application.id, id: iu.targetId }, e)} decimalPlaces={0} value={application.target} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="Modal-footer">
                        <div className="ButtonBar">
                            <div className="ButtonBar-left">
                                <button type="button" onClick={() => cancelApplication()} className="Button Button--secondary" id="irrigation-modal-cancel">
                                    Cancel
                                </button>
                            </div>
                            <div className="ButtonBar-right">
                                <button type="button" onClick={() => saveApplication()} className="Button" id="irrigation-modal-save">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(withAppContext)(ActivityModal);
