import * as api from "api";
import "containers/BudgetHome/SoilTests/BlockSoils.css";
import { useState, useEffect, createRef, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as geoJsonUtils from "common/geoJsonUtils";
import * as utils from "common/utils";
import SavePrompt from "components/SavePrompt";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import Button from "components/Button/Button";
import ActionLink from "components/ActionLink";
import MapLegend from "components/Map/MapLegend";
import MapZoomButton from "components/Map/MapZoomButton";
import MapBlockLabels from "components/Map/MapBlockLabels";
import SoilProportionsSlider from "components/Soils/SoilProportionsSlider";
import { useSoilModal } from "containers/Soils/SoilModal";
import { useGoogleApi, useGoogleMap } from "components/Map/hooks";
import Spinner from "components/Spinner/Spinner";
import { useExistingSmapRefModal } from "./ExistingSmapRefModal";
import { isSoilModified } from "containers/Soils/_utils";
import { FEATURES, useFeatureTracker } from "components/FeatureTracker/FeatureTracker";
import { useNavigate, useOnline, useRefData, useConfirm } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function BlockSoils({ farm, analysis }) {
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const { blockId } = useParams();
    const [viewModel] = useState({ block: analysis.blocks.find((b) => b.id === blockId), soils: utils.clone(analysis.soils), farmSoilBlocks: utils.clone(analysis.farmSoilBlocks) });
    const referrer = `/app/farm/${farm.id}/analysis/${analysis.id}/soil`;
    const online = useOnline();
    const refData = useRefData();
    const { map, mapId, setMapRef } = useGoogleMap({ center: { lat: farm.latitude, lng: farm.longitude }, zoom: 12, mapTypeControl: false });
    const [geoJson, syncMapDataToGeoJson] = useAnalysisGeoJson(analysis);
    const featureTracker = useFeatureTracker(FEATURES.BLOCK_SOILS, undefined, farm);
    const mapData = useMapData(map, geoJson);
    const [smapData, smapError, isFarmLocated] = useSmapData(mapData, geoJson, viewModel.soils, blockId, farm);
    const [fslData, fslError] = useFslData(geoJson, blockId, farm);
    const fslSoilOrder = fslData && !fslError && fslData.soilOrder ? fslData.soilOrder : undefined;
    const navigate = useNavigate();

    useMapStyle(mapData, blockId);
    useOnSoilPolygonClick(mapData, syncMapDataToGeoJson);

    const validate = (values) => {
        const validation = {};

        const blockSoils = values.farmSoilBlocks.filter((b) => b.blockId === blockId);

        if (blockSoils.length === 0) {
            validation.farmSoilBlocks = "Must have at least one soil";
        } else {
            const not100Percent = blockSoils.reduce((a, b) => a + b.percentageOfBlock, 0) !== 100;
            if (not100Percent) {
                validation.farmSoilBlocks = "Must allocate soils to 100% of the block";
            }
        }

        return validation;
    };

    const submitAsync = async (values) => {
        const { block, soils, farmSoilBlocks } = values;

        analysis.soils = soils;
        analysis.farmSoilBlocks = farmSoilBlocks;
        analysis.blocks = analysis.blocks.map((b) => {
            if (b.id === blockId) {
                return {
                    ...b,
                    autoGeneratedSoils: block.autoGeneratedSoils,
                    regenerateSoils: block.regenerateSoils,
                };
            }
            return b;
        });

        try {
            await updateAnalysisAsync(analysis);
            featureTracker.track("Block Soils: Save");
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    };

    const cancel = () => {
        featureTracker.track("Block Soils: Cancel");
        navigate(referrer);
    };

    const blockIsDrawn = geoJson?.features?.some((f) => f.properties.blockId === blockId);

    return (
        <Form initialValues={viewModel} validate={validate} onSubmit={submitAsync}>
            {({ form, values, invalid, handleSubmit, dirty, submitting, submitSucceeded }) => {
                const { block, farmSoilBlocks, soils } = values;
                const blockSoils = farmSoilBlocks.filter((b) => b.blockId === block.id);
                const blockSoilsContainFsl = blockSoils.some((b) => soils.find((s) => s.id === b.soilId && s.soilOrder === fslSoilOrder));

                const loading = online && !geoJson && !smapData;
                const effectiveArea = block.areaInHectares || block.area;
                const drawnArea = geoJson
                    ? utils.round(
                        geoJson.features.filter((f) => f.properties.layer === "Block").reduce((sum, feature) => (sum += feature.properties.drawnArea), 0),
                        1
                    )
                    : undefined;

                return (
                    <form onSubmit={handleSubmit}>
                        <SavePrompt blockIf={dirty && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={referrer} />
                        <Panel title="Edit block soils" referrer={referrer} waiting={submitting} info="If soil information is available, the list of soils and their approximate percentages will be shown. If no soil data is available you will need to add soils manually. You can add up to three soils for a block.">
                            <PanelBody>
                                <ul className="DataWidthTable FarmTable">
                                    <li data-width="25">
                                        <div className="FarmTable-keyValuePair">
                                            <div className="FarmTable-keyValue">
                                                <span className="FarmTable-key">Block</span>
                                                <span className="FarmTable-value">
                                                    <div className="u-flexSplit">
                                                        <span>{block.name}</span>
                                                        {block.autoGeneratedSoils && !dirty && <span className="IconLink--alert u-textWarning">Using auto assigned soils</span>}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-width="25">
                                        <div className="FarmTable-keyValuePair">
                                            <div className="FarmTable-keyValue">
                                                <span className="FarmTable-key">Block type</span>
                                                <span className="FarmTable-value">{utils.getBlockTypeText(refData, block, false)}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-width="25">
                                        <div className="FarmTable-keyValuePair">
                                            <div className="FarmTable-keyValue">
                                                <span className="FarmTable-key">Effective area</span>
                                                <span className="FarmTable-value">{`${effectiveArea} ha`}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-width="25">
                                        <div className="FarmTable-keyValuePair">
                                            <div className="FarmTable-keyValue">
                                                <span className="FarmTable-key">Drawn area</span>
                                                {drawnArea > 0 && (
                                                    <span className="FarmTable-value u-flex u-flexAlignItemsCenter">
                                                        <span>{`${drawnArea} ha`}</span>
                                                        {effectiveArea !== drawnArea && <i className="icon icon-alert u-textWarning u-ml-sm" />}
                                                    </span>
                                                )}
                                                {drawnArea === 0 && (
                                                    <span className="FarmTable-value">
                                                        <span className="IconLink--alert u-textWarning">Not drawn</span>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className={`blockSoils_map ${!online ? "blockSoils_map_mapOnly" : ""}`}>
                                    <div className="blockSoils_map_map">
                                        {loading && (
                                            <div className="Tile-body-message">
                                                <Spinner dark />
                                                <p className="lead u-text-lg u-mt-md">Loading map...</p>
                                            </div>
                                        )}
                                        {!online && (
                                            <div className="Tile-body-message u-textError">
                                                <i className="icon icon--md icon-alert" />
                                                <p className="lead">Overseer is currently offline. Please try again later.</p>
                                            </div>
                                        )}
                                        <div className={`map ${loading || !online ? "u-hidden" : ""}`} ref={setMapRef} />
                                        {mapData && <BlockSoilsSlider mapData={smapData} featureTracker={featureTracker} analysis={analysis} map={map} mapId={mapId} form={form} values={values} fslSoilOrder={fslSoilOrder} blockSoilsContainFsl={blockSoilsContainFsl} />}
                                        <MapBlockLabels mapData={mapData} mapId={mapId} geoJson={geoJson} blocks={analysis.blocks} />
                                        <MapLegend mapData={mapData} mapId={mapId} layersToShow={[{ id: "Irrigator", title: "Drawn Irrigators", sortOrder: 0 }]} />
                                        <MapZoomButton mapData={mapData} mapId={mapId} where={(f) => f.properties.blockId === blockId} padding={100} />
                                    </div>
                                    {online && (
                                        <div className={`blockSoils_map_soils ${online && !smapData ? "waiting" : ""}`}>
                                            <SmapSoilList blockIsDrawn={blockIsDrawn} isFarmLocated={isFarmLocated} featureTracker={featureTracker} smapData={smapData} smapError={smapError} mapData={mapData} form={form} values={values} fslSoilOrder={fslSoilOrder} blockSoilsContainFsl={blockSoilsContainFsl} />
                                        </div>
                                    )}
                                </div>
                            </PanelBody>
                            <PanelFooter>
                                <div className="ButtonBar ButtonBar--fixed">
                                    <div className="ButtonBar-left">
                                        <Button id="cancel" onClick={() => cancel()} className="Button Button--secondary">
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="ButtonBar-right">
                                        <Button id="save-block-soil" submit primary waiting={submitting} disabled={invalid || submitting}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </PanelFooter>
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
}

function useAnalysisGeoJson(analysis) {
    const [geoJson, setGeoJson] = useState();

    const syncMapDataToGeoJson = (mapData) => mapData.toGeoJson((geoJson) => setGeoJson(geoJson));

    useEffect(() => {
        if (!geoJson) {
            const features = (analysis.features || []).reduce((results, feature) => {
                // Flatten and hide irrigators and unassigned land by default.
                const flattenedFeatures = geoJsonUtils.flatten(feature, ["Irrigator", "UnassignedLand"]);
                return [...results, ...flattenedFeatures];
            }, []);
            setGeoJson({ type: "FeatureCollection", features });
        }
    }, [analysis.features, geoJson]);

    return [geoJson, syncMapDataToGeoJson];
}

function useMapData(map, geoJson) {
    const google = useGoogleApi();
    const [mapData, setMapData] = useState();

    useEffect(() => {
        if (google && map && geoJson && !mapData) {
            const data = new google.maps.Data({ map });
            data.addGeoJson(geoJson);
            setMapData(data);
        }
    }, [google, map, geoJson, mapData]);

    return mapData;
}

function useMapStyle(mapData, blockId) {
    useEffect(() => {
        if (mapData && blockId) {
            mapData.setStyle((feature) => {
                const selected = feature.getProperty("selected");
                const visible = feature.getProperty("visible");
                const layer = feature.getProperty("layer");
                const smapColor = feature.getProperty("color");
                const layerColor = geoJsonUtils.getMapLayerColor(layer);

                const featureBlockId = feature.getProperty("blockId");
                const isCurrentBlock = featureBlockId === blockId;

                let zIndex = 0;
                if (layer === "NewSoil") {
                    zIndex = 1;
                } else if (selected) {
                    zIndex = 2;
                }

                return {
                    strokeColor: selected ? "yellow" : smapColor || layerColor,
                    strokeWeight: selected ? 4 : 3,
                    fillColor: isCurrentBlock || layer === "Irrigator" ? layerColor : smapColor || "white",
                    fillOpacity: isCurrentBlock ? 0.6 : 0.3,
                    zIndex,
                    visible,
                };
            });
        }
    }, [mapData, blockId]);
}

function useOnSoilPolygonClick(mapData, onMapDataChange) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && onMapDataChange && !runOnce) {
            mapData.addListener("click", ({ feature }) => {
                if (feature.getProperty("smapEntityId")) {
                    mapData.forEach((mapFeature) => {
                        if (mapFeature.getId() === feature.getId()) {
                            const selected = !mapFeature.getProperty("selected");
                            mapFeature.setProperty("selected", selected);
                            if (selected) {
                                const scrollRef = feature.getProperty("scrollRef");
                                if (scrollRef && scrollRef.current) {
                                    scrollRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
                                }
                            }
                        } else {
                            mapFeature.setProperty("selected", false);
                        }
                    });
                    onMapDataChange(mapData);
                }
            });
            setRunOnce(true);
        }
    }, [mapData, onMapDataChange, runOnce]);
}

function useSmapData(mapData, geoJson, existingSoils, blockId, farm) {
    const online = useOnline();
    const dispatch = useDispatch();
    const [smapData, setSmapData] = useState();
    const [smapError, setSmapError] = useState();
    const [isFarmLocated, setIsFarmLocated] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let features = geoJson.features.filter((f) => f.properties.layer === "Block" && f.properties.blockId === blockId);

            if (features.length === 0) {
                setIsFarmLocated(true);
                const farmCircle = geoJsonUtils.circle(farm.latitude, farm.longitude, 250);
                features = [farmCircle];
            }

            await dispatch(getBlockSmapSoilsAsync.send(features))
                .then((response) => {
                    if (response) {
                        const hasSmapSoils = (response.soils && response.soils.length > 0) || response.geoJson;
                        if (hasSmapSoils) {
                            response.geoJson.features.forEach((feature, i) => {
                                if (!feature.id) feature.id = uuidv4();
                                if (feature.properties.layer === "NewSoil") {
                                    const moreFeaturesThanColors = i >= utils.smapSoilPolygonColours.length;
                                    feature.properties.color = moreFeaturesThanColors ? "#aaaaaa" : utils.smapSoilPolygonColours[i];
                                    feature.properties.scrollRef = createRef();
                                }

                                // Update smap data to use existing soil ids
                                feature.properties.soils.forEach((featureSoil) => {
                                    const existingSoil = existingSoils.find((soil) => !isSoilModified(soil) && soil.sibling && soil.sibling.smapReference === featureSoil.subTitle);
                                    if (existingSoil) {
                                        featureSoil.soilId = existingSoil.id;
                                    }
                                });
                            });

                            // Update smap data to use existing soil ids
                            response.soils.forEach((smapSoil) => {
                                const existingSoil = existingSoils.find((soil) => !isSoilModified(soil) && soil.sibling && soil.sibling.smapReference === smapSoil.subTitle);
                                if (existingSoil) {
                                    smapSoil.id = existingSoil.id;
                                }
                            });

                            mapData.addGeoJson(response.geoJson);
                            setSmapData(response);
                        }
                    }
                })
                .catch((error) => {
                    setSmapError(error);
                });
        };

        if (online && mapData && geoJson && existingSoils && blockId && !smapData) {
            fetchData();
        }
    }, [mapData, geoJson, existingSoils, blockId, smapData, online, dispatch, farm.latitude, farm.longitude]);

    return [smapData, smapError, isFarmLocated];
}

function useFslData(geoJson, blockId, farm) {
    const online = useOnline();
    const dispatch = useDispatch();
    const [fslData, setFslData] = useState();
    const [fslError, setFslError] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const blockFeatures = geoJson.features.filter((f) => f.properties.layer === "Block" && f.properties.blockId === blockId);
            if (blockFeatures.length === 0) {
                if (!farm.latitude || !farm.longitude) {
                    setFslError("This block is not mapped, please map the block or set the farm's location.");
                } else {
                    await dispatch(getBlockFslSoilsByLatLngAsync.send(farm.latitude, farm.longitude))
                        .then((response) => {
                            if (response) {
                                setFslData(response);
                            }
                        })
                        .catch((error) => {
                            setFslError(error);
                        });
                }
            } else {
                await dispatch(getBlockFslSoilsByGeoJsonAsync.send(blockFeatures))
                    .then((response) => {
                        if (response) {
                            setFslData(response);
                        }
                    })
                    .catch((error) => {
                        setFslError(error);
                    });
            }
        };

        if (online && geoJson && blockId && !fslData) {
            fetchData();
        }
    }, [geoJson, blockId, fslData, online, dispatch, farm.latitude, farm.longitude]);

    return [fslData, fslError];
}

function BlockSoilsSlider({ analysis, map, mapId, form, values, fslSoilOrder, blockSoilsContainFsl, featureTracker, mapData }) {
    const google = useGoogleApi();
    const hasSmapSoils = mapData && mapData.soils.length > 0;

    // DOM ref for adding the slider as a control on the map
    const [domRef, setDomRef] = useState();
    useEffect(() => {
        if (google && map && mapId && domRef) {
            const position = google.maps.ControlPosition.TOP_RIGHT;
            const controls = map.controls[position].getArray();

            const id = `block-soils-slider-${mapId}`;
            let div = controls.find((ctl) => ctl.id === id);
            if (!div) {
                div = document.createElement("div");
                div.id = id;
                div.className = "blockSoils_soils_wrapper";
                div.appendChild(domRef);
                map.controls[position].push(div);
            }
        }
    }, [google, map, mapId, domRef]);

    const refData = useRefData();
    const [soilModal, openSoilModal] = useSoilModal();
    const blockColorIndices = [3, 1, 9, 2, 7];

    const { block, soils, farmSoilBlocks } = values;
    const blockSoils = farmSoilBlocks.filter((b) => b.blockId === block.id);
    const emptyBlockSoils = [];
    let emptySlots = 3 - blockSoils.length;
    while (emptySlots > 0) {
        emptyBlockSoils.push({ id: uuidv4(), blockId: block.id });
        emptySlots--;
    }
    const not100PercentOfBlock = blockSoils.reduce((a, b) => a + b.percentageOfBlock, 0) !== 100;

    const addSoil = () => {
        const blockSoils = values.farmSoilBlocks.filter((f) => f.blockId === block.id);
        const soilsNotAlreadyOnThisBlock = values.soils.filter((s) => !blockSoils.some((fsb) => fsb.soilId === s.id));
        featureTracker.track("Block Soils: Add soil");

        const soilModalOptions = {
            existingSoils: soilsNotAlreadyOnThisBlock,
            maxSoilCount: 3 - blockSoils.length,
            featureTracker,
            onSubmit: (addedSoils) => {
                if (!Array.isArray(addedSoils)) addedSoils = [addedSoils];

                const newSoils = [];

                // Add new soils.
                const percentageOfBlock = blockSoils.length > 0 ? 10 : Math.round(100 / addedSoils.length);
                addedSoils.forEach((addedSoil) => {
                    if (!soils.some((soil) => utils.isEqualSoil(soil, addedSoil))) {
                        newSoils.push(addedSoil);
                    }

                    blockSoils.push({
                        id: uuidv4(),
                        soilId: addedSoil.id,
                        blockId: block.id,
                        percentageOfBlock: percentageOfBlock,
                    });
                });

                // Re-calc existing soils to remaining % given the new ones will tak 10% each.
                const target = 100 - blockSoils.reduce((sum, fsb) => (sum += fsb.percentageOfBlock === 10 ? fsb.percentageOfBlock : 0), 0);
                let remaining = target * 1; // By val
                blockSoils.forEach((fsb) => {
                    if (fsb.percentageOfBlock > 10) {
                        let updatedPercentage = Math.round((fsb.percentageOfBlock / 100) * target);
                        if (remaining - updatedPercentage < 0) {
                            updatedPercentage = remaining;
                        }
                        remaining -= updatedPercentage;
                        fsb.percentageOfBlock = updatedPercentage;
                    }
                });

                // Add any remaining to the first one.
                if (remaining > 0) {
                    blockSoils[0].percentageOfBlock += remaining;
                }

                form.batch(() => {
                    if (newSoils.length > 0) {
                        form.change("soils", [...values.soils, ...newSoils]);
                    }

                    const updatedFarmSoilBlocks = [...values.farmSoilBlocks.filter((fsb) => fsb.blockId !== block.id), ...blockSoils];
                    form.change("farmSoilBlocks", updatedFarmSoilBlocks);
                    form.change("block.autoGeneratedSoils", false);
                });
            },
        };

        if (!blockSoilsContainFsl && blockSoils.length === 0 && !hasSmapSoils) {
            soilModalOptions.fslSoilOrder = fslSoilOrder;
        }
        openSoilModal({ id: uuidv4(), isNew: true }, soilModalOptions);
    };

    const editSoil = (soil) => {
        const soilModalOptions = {
            analysis: analysis,
            featureTracker,
            onSubmit: async (editedSoil) => {
                form.change(
                    "soils",
                    soils.map((s) => (s.id === editedSoil.id ? editedSoil : s))
                );
            },
        };
        openSoilModal(soil, soilModalOptions);
    };

    const removeSoilFromBlock = (farmSoilBlock) => {
        // Re-distribute percentageOfBlock as proportions of remaining.
        featureTracker.track("Block Soils: Remove soil");
        const remaining = 100 - farmSoilBlock.percentageOfBlock;
        const updatedFarmSoilBlocks = values.farmSoilBlocks
            .filter((fsb) => fsb.id !== farmSoilBlock.id)
            .map((fsb) => {
                if (fsb.blockId === farmSoilBlock.blockId) {
                    return { ...fsb, percentageOfBlock: Math.round((fsb.percentageOfBlock / remaining) * 100) };
                }
                return fsb;
            });
        form.change("farmSoilBlocks", updatedFarmSoilBlocks);
        form.change("block.autoGeneratedSoils", false);
    };

    const onSoilProportionsChange = (proportions) => {
        let proportionsHaveChanged = false;

        let i = 0;
        const updatedFarmSoilBlocks = values.farmSoilBlocks.map((fsb) => {
            if (fsb.blockId === block.id) {
                const percentageOfBlock = proportions[i + 1] - proportions[i];
                i++;

                if (percentageOfBlock >= 10 && fsb.percentageOfBlock !== percentageOfBlock) {
                    proportionsHaveChanged = true;
                    return { ...fsb, percentageOfBlock };
                }
            }
            return fsb;
        });

        if (proportionsHaveChanged) {
            form.change("farmSoilBlocks", updatedFarmSoilBlocks);
            form.change("block.autoGeneratedSoils", false);
        }
    };

    return (
        <>
            <Field name="farmSoilBlocks" type="hidden" component="input" />
            <div className="blockSoils_soils" ref={setDomRef}>
                <div className="u-flex u-flexWrap u-flexJustifyBetween">
                    {blockSoils.map((fsb, i) => {
                        const soil = soils.find((s) => s.id === fsb.soilId);
                        if (!soil) return null;
                        const area = fsb.percentageOfBlock > 0 && block.areaInHectares > 0 && utils.round((fsb.percentageOfBlock * block.areaInHectares) / 100, 1);
                        const soilTitle = utils.getSoilTitle(soil, refData.soilGroups);
                        return (
                            <div key={fsb.id} id={`${block.id}-${fsb.soilId}`} className={`ManualSoil ManualSoil-item--${utils.colourNameIndex[blockColorIndices[i]]} ${not100PercentOfBlock ? "ManualSoil--error" : ""}`}>
                                <div className="ManualSoil-name-parent">
                                    <ActionLink id={`${fsb.soilId}_edit`} name={`edit_soil_${i + 1}`} className="ManualSoil-name" onClick={() => editSoil(soil)}>
                                        {soilTitle}
                                    </ActionLink>
                                    {isSoilModified(soil) && <i className="icon icon-soil u-textWarning" title="Modified soil properties" />}
                                </div>
                                <ActionLink id={`${fsb.soilId}_remove`} name={`remove_soil_${i + 1}`} title="Remove" className="IconLink--cross-circle SoilRange-delete" onClick={() => removeSoilFromBlock(fsb)} />
                                <div className="u-flex u-flexAlignItemsCenter u-flexJustifyCenter">
                                    <div className="ManualSoilRange-value u-pr-sm">
                                        {fsb.percentageOfBlock}
                                        <span>%</span>
                                    </div>
                                    {area && (
                                        <div className="u-pr-sm u-textNoWrap u-textGrey">
                                            {area} <span>ha</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {emptyBlockSoils.map((fsb, i) => {
                        return (
                            <div key={fsb.id} className={`ManualSoil ManualSoil--add u-flex u-flexWrap u-flexJustifyCenter u-flexAlignItemsCenter ${i === 0 && blockSoils.length === 0 ? "ManualSoil--error" : ""}`}>
                                <p>Add up to 3 soils</p>
                                <ActionLink id={`add-soil-${i + 1}`} className="IconLink--arrow-plus u-link" onClick={addSoil}>
                                    Add soil
                                </ActionLink>
                            </div>
                        );
                    })}
                </div>
                {blockSoils.length > 1 && (
                    <div className="blockSoils_slider">
                        <SoilProportionsSlider soilProportions={blockSoils.map((s) => s.percentageOfBlock)} onChange={onSoilProportionsChange} step={1} label="" />
                    </div>
                )}
            </div>
            {soilModal}
        </>
    )
}

function SmapSoilList({ smapData, smapError, mapData, form, values, fslSoilOrder, blockSoilsContainFsl, featureTracker, isFarmLocated, blockIsDrawn }) {
    const google = useGoogleApi();
    const { block, soils, farmSoilBlocks } = values;
    const blockSoils = farmSoilBlocks.filter((b) => b.blockId === block.id);
    const [viewSoilsBy, setViewSoilsBy] = useState(0);
    const [openExistingSmapRefModal, existingSmapRefModal] = useExistingSmapRefModal();
    const [soilModal, openSoilModal] = useSoilModal();
    const confirm = useConfirm();

    const showFsl = blockSoilsContainFsl && blockSoils.length === 1;
    const showSelectFsl = fslSoilOrder && blockSoils.length === 0;

    // Scroll when viewing by polygon
    useEffect(() => {
        if (smapData && viewSoilsBy === 1) {
            const selectedFeature = smapData.geoJson.features.find((f) => f.properties.selected);
            if (selectedFeature && selectedFeature.properties.scrollRef && selectedFeature.properties.scrollRef.current) {
                selectedFeature.properties.scrollRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
            }
        }
    }, [smapData, viewSoilsBy]);

    const addSoil = () => {
        const blockSoils = values.farmSoilBlocks.filter((f) => f.blockId === block.id);
        const soilsNotAlreadyOnThisBlock = values.soils.filter((s) => !blockSoils.some((fsb) => fsb.soilId === s.id));
        featureTracker.track("Block Soils: Add FSL soil");
        const soilModalOptions = {
            existingSoils: soilsNotAlreadyOnThisBlock,
            maxSoilCount: 3 - blockSoils.length,
            featureTracker,
            onSubmit: async (addedSoils) => {
                if (!Array.isArray(addedSoils)) addedSoils = [addedSoils];

                const newSoils = [];

                // Add new soils.
                const percentageOfBlock = blockSoils.length > 0 ? 10 : Math.round(100 / addedSoils.length);
                addedSoils.forEach((addedSoil) => {
                    if (!soils.some((soil) => utils.isEqualSoil(soil, addedSoil))) {
                        newSoils.push(addedSoil);
                    }

                    blockSoils.push({
                        id: uuidv4(),
                        soilId: addedSoil.id,
                        blockId: block.id,
                        percentageOfBlock: percentageOfBlock,
                    });
                });

                // Re-calc existing soils to remaining % given the new ones will tak 10% each.
                const target = 100 - blockSoils.reduce((sum, fsb) => (sum += fsb.percentageOfBlock === 10 ? fsb.percentageOfBlock : 0), 0);
                let remaining = target * 1; // By val
                blockSoils.forEach((fsb) => {
                    if (fsb.percentageOfBlock > 10) {
                        let updatedPercentage = Math.round((fsb.percentageOfBlock / 100) * target);
                        if (remaining - updatedPercentage < 0) {
                            updatedPercentage = remaining;
                        }
                        remaining -= updatedPercentage;
                        fsb.percentageOfBlock = updatedPercentage;
                    }
                });

                // Add any remaining to the first one.
                if (remaining > 0) {
                    blockSoils[0].percentageOfBlock += remaining;
                }

                form.batch(() => {
                    if (newSoils.length > 0) {
                        form.change("soils", [...values.soils, ...newSoils]);
                    }

                    const updatedFarmSoilBlocks = [...values.farmSoilBlocks.filter((fsb) => fsb.blockId !== block.id), ...blockSoils];
                    form.change("farmSoilBlocks", updatedFarmSoilBlocks);
                    form.change("block.autoGeneratedSoils", false);
                });
            },
        };

        if (!blockSoilsContainFsl && blockSoils.length === 0 && !hasSmapSoils) {
            soilModalOptions.fslSoilOrder = fslSoilOrder;
            soilModalOptions.autoSelectSoilOrder = true;
        }

        openSoilModal({ id: uuidv4(), isNew: true }, soilModalOptions);
    }

    const addBlockSoil = (smapSoil) => {
        // Add to soils if new.
        const newSoils = [];
        const existingSoil = soils.find((soil) => utils.isEqualSoil(soil, smapSoil));
        if (existingSoil) {
            smapSoil.id = existingSoil.id;
        } else {
            newSoils.push(smapSoil);
        }

        // Add block soil.
        const percentageOfBlock = blockSoils.length > 0 ? 10 : 100;
        blockSoils.push({
            id: uuidv4(),
            soilId: smapSoil.id,
            blockId: block.id,
            percentageOfBlock,
        });

        // Re-calc existing soils to remaining % given the new ones will tak 10% each.
        const target = 100 - blockSoils.reduce((sum, fsb) => (sum += fsb.percentageOfBlock === 10 ? fsb.percentageOfBlock : 0), 0);
        let remaining = target * 1; // By val
        blockSoils.forEach((fsb) => {
            if (fsb.percentageOfBlock > 10) {
                let updatedPercentage = Math.round((fsb.percentageOfBlock / 100) * target);
                if (remaining - updatedPercentage < 0) {
                    updatedPercentage = remaining;
                }
                remaining -= updatedPercentage;
                fsb.percentageOfBlock = updatedPercentage;
            }
        });

        // Add any remaining to the first one.
        if (remaining > 0) {
            blockSoils[0].percentageOfBlock += remaining;
        }

        form.batch(() => {
            if (newSoils.length > 0) {
                form.change("soils", [...soils, ...newSoils]);
            }

            const updatedFarmSoilBlocks = [...farmSoilBlocks.filter((fsb) => fsb.blockId !== block.id), ...blockSoils];
            form.change("farmSoilBlocks", updatedFarmSoilBlocks);
            form.change("block.autoGeneratedSoils", false);
        });
    }

    const addSmapSoil = (smapSoil) => (e) => {
        e.stopPropagation();
        const existingSoil = soils.find((s) => utils.isEqualSoil(s, smapSoil));
        const existingModifiedSoilsWithSameSmapRef = soils.filter((s) => !utils.isEqualSoil(s, smapSoil) && s.sibling && s.sibling.smapReference === smapSoil.sibling.smapReference);
        if (existingSoil || existingModifiedSoilsWithSameSmapRef.length === 0) {
            addBlockSoil(smapSoil);
        } else if (existingModifiedSoilsWithSameSmapRef.length > 0) {
            openExistingSmapRefModal(smapSoil, existingModifiedSoilsWithSameSmapRef, (selectedSoil) => addBlockSoil(selectedSoil));
        }
    }

    const resetBlockSoilsToSMapDefaults = () => {
        confirm(`Are you sure want to reset the soils for this block to the S-Map defaults?`, async () => {
            form.change("block.autoGeneratedSoils", true);
            form.change("block.regenerateSoils", true);
            form.submit();
        })
    }

    if (!smapData && !smapError) {
        return (
            <div className="Tile-body-message">
                <Spinner dark />
                <p className="lead u-text-lg u-mt-md">Loading S-Map data...</p>
            </div>
        )
    }

    const viewBySoils = viewSoilsBy === 0;
    const viewByPolygons = viewSoilsBy === 1;
    const hasSmapSoils = smapData && smapData.soils.length > 0;
    const blockAlreadyHasMaxNumberOfSoils = blockSoils.length === 3;

    const selectPolygon = (featureId) => () => {
        const mapFeature = mapData.getFeatureById(featureId);
        google.maps.event.trigger(mapData, "click", { feature: mapFeature });
    }

    const NoSMapSoil = () => (
        <div className="u-flex u-flexAlignItemsCenter">
            <i className="icon icon-alert icon--md u-textWarning" />
            <span>S-Map soil data is not available for this block location.</span>
        </div>
    )

    return (
        <>
            <div className={`smapSoilList ${hasSmapSoils ? "" : "smapSoilList--noData"}`}>
                {blockIsDrawn && !block.autoGeneratedSoils && hasSmapSoils && (
                    <div>
                        <Button onClick={() => resetBlockSoilsToSMapDefaults()} className="IconLink--target Button Button--secondary u-widthFull u-mb-sm">Reset to S-Map defaults</Button>
                    </div>
                )}
                {!smapError && hasSmapSoils && (
                    <>
                        {isFarmLocated && hasSmapSoils && (
                            <div className="u-mb-lg">
                                <div className="u-flex u-pt-md u-mb-md">
                                    <i className="icon icon-alert icon--md u-textWarning" />
                                    <span className="u-pl-sm">
                                        S-Map soils shown on the map are based on your farm location.
                                        <br />
                                        Please draw your block to get more accurate soil data.
                                    </span>
                                </div>
                                <hr />
                            </div>
                        )}

                        <div className="u-flexSplit">
                            <h3 className="u-text-md">S-Map soils</h3>
                            <div className="Radio_group Radio_group--inline u-mt-0">
                                <label className="Radio u-mr-sm">
                                    <input className="Radio-input" type="radio" id="viewBySoils" name="viewSoilsBy" value={1} checked={viewBySoils} onChange={() => setViewSoilsBy(0)} />
                                    <span className="Radio-label">By soils ({smapData.soils.length})</span>
                                </label>
                                <label className="Radio u-mr-sm">
                                    <input className="Radio-input" type="radio" id="viewByPolygons" name="viewSoilsBy" value={0} checked={viewByPolygons} onChange={() => setViewSoilsBy(1)} />
                                    <span className="Radio-label">By polygons ({smapData.geoJson.features.length})</span>
                                </label>
                            </div>
                        </div>
                        {viewBySoils && (
                            <>
                                <span className="small">
                                    <sup>*</sup> Soil as a percentage of the block
                                </span>
                                <div className="Table u-mt-sm">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Reference</th>
                                                <th data-width="35">Description</th>
                                                <th className="th--shrink">
                                                    %<sup>*</sup>
                                                </th>
                                                <th className="th--shrink">Add</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {smapData.soils.map((smapSoil) => {
                                                const soilIsAlreadyOnThisBlock = blockSoils.some((blockSoil) => {
                                                    const soil = soils.find((s) => s.id === blockSoil.soilId);
                                                    return utils.isEqualSoil(soil, smapSoil);
                                                });
                                                const canAdd = !blockAlreadyHasMaxNumberOfSoils && !soilIsAlreadyOnThisBlock;
                                                return (
                                                    <tr key={smapSoil.id}>
                                                        <td valign="top">{smapSoil.sibling.smapReference || ""}</td>
                                                        <td valign="top">
                                                            <span className="small">{smapSoil.sibling.description}</span>
                                                        </td>
                                                        <td valign="top">{smapSoil.percentageOfBlock >= 1 ? utils.round(smapSoil.percentageOfBlock, 0) : "<1"}%</td>
                                                        <td valign="top" className="u-textCenter">
                                                            {blockAlreadyHasMaxNumberOfSoils && !soilIsAlreadyOnThisBlock && <span>-</span>}
                                                            {soilIsAlreadyOnThisBlock && <i className="icon icon-tick-circle u-textSuccess" />}
                                                            {canAdd && <ActionLink id={`${smapSoil.sibling.smapReference}_add`} className="IconLink--arrow-plus u-link" onClick={addSmapSoil(smapSoil)} />}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                        {viewByPolygons && (
                            <>
                                <span className="small">
                                    <sup>*</sup> Soil as a percentage of the polygon
                                </span>
                                <div className="Table u-mt-sm">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Reference</th>
                                                <th data-width="35">Description</th>
                                                <th className="th--shrink">
                                                    %<sup>*</sup>
                                                </th>
                                                <th className="th--shrink">Add</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {smapData.geoJson.features.map((feature, i) => {
                                                return (
                                                    <Fragment key={feature.id}>
                                                        <tr onClick={selectPolygon(feature.id)} className="Table-tr--color u-link" style={feature.properties.selected ? { "--row-color": feature.properties.color, backgroundColor: utils.hexToRGBA(feature.properties.color, 0.1) } : { "--row-color": feature.properties.color }}>
                                                            <td colSpan="4" ref={feature.properties.scrollRef}>
                                                                <div className="u-flexSplit">
                                                                    <span>S-Map soil polygon</span>
                                                                    <span>{feature.properties.intersectingPercentage >= 1 ? utils.round(feature.properties.intersectingPercentage, 0) : "<1"}% of block</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {feature.properties.soils.map((featureSoil) => {
                                                            const smapSoil = smapData.soils.find((s) => s.id === featureSoil.soilId);
                                                            const soilIsAlreadyOnThisBlock = blockSoils.some((blockSoil) => {
                                                                const soil = soils.find((s) => s.id === blockSoil.soilId);
                                                                return utils.isEqualSoil(soil, smapSoil);
                                                            });
                                                            const canAdd = !blockAlreadyHasMaxNumberOfSoils && !soilIsAlreadyOnThisBlock;
                                                            return (
                                                                <tr key={`${feature.id}-${featureSoil.soilId}`} onClick={selectPolygon(feature.id)} className="Table-tr--color u-link" style={feature.properties.selected ? { backgroundColor: utils.hexToRGBA(feature.properties.color, 0.1) } : {}}>
                                                                    <td valign="top">{featureSoil.name}</td>
                                                                    <td valign="top">
                                                                        <span className="small">{smapSoil.sibling.description}</span>
                                                                    </td>
                                                                    <td valign="top" className="u-textCenter">
                                                                        {featureSoil.proportion}%
                                                                    </td>
                                                                    <td valign="top" className="u-textCenter">
                                                                        {blockAlreadyHasMaxNumberOfSoils && !soilIsAlreadyOnThisBlock && <span>-</span>}
                                                                        {soilIsAlreadyOnThisBlock && <i className="icon icon-tick-circle u-textSuccess" />}
                                                                        {canAdd && <ActionLink id={`${smapSoil.sibling.smapReference}_add_${i}`} className="IconLink--arrow-plus u-link" onClick={addSmapSoil(smapSoil)} />}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                        <div className="small u-mt-sm">S-Map soil data reproduced with the permission of Manaaki Whenua - Landcare Research</div>
                    </>
                )}
                {!smapError && !hasSmapSoils && (
                    <>
                        <h3 className="u-text-md">S-Map data unavailable</h3>

                        {showFsl && (
                            <div className="u-flex u-pt-md">
                                <i className="icon icon-alert icon--md u-textWarning" />
                                <span className="u-pl-sm">
                                    Using an automatically selected soil order.
                                    <br />
                                    If you have an S-Map reference or this looks incorrect, please update your soils manually.{" "}
                                </span>
                            </div>
                        )}
                        {showSelectFsl && (
                            <>
                                <NoSMapSoil />
                                <Button onClick={() => addSoil()} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                    Automatically select soil order
                                </Button>
                                <div className="u-mt-sm u-pl-sm u-pt-sm">OR, add your soil manually.</div>
                            </>
                        )}
                        {!showFsl && !showSelectFsl && <NoSMapSoil />}
                    </>
                )}
                {smapError && (
                    <>
                        <h3 className="u-text-md">S-Map offline</h3>
                        <div className="u-flex u-flexAlignItemsCenter">
                            <i className="icon icon-alert icon--md u-textWarning" />
                            <span>S-Map is temporarily offline. Please add your soil manually or try again later.</span>
                        </div>
                    </>
                )}
            </div>
            {existingSmapRefModal}
            {soilModal}
        </>
    )
}

const getBlockSmapSoilsAsync = {
    send: (features) => (dispatch) => {
        (features || []).forEach((f) => {
            delete f.properties.scrollRef;
        });

        return new Promise((resolve, reject) => {
            dispatch(
                api.post({
                    path: `soil/smap/block`,
                    timeout: 300000,
                    content: { features },
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("Error loading S-Map soils");
                        return [];
                    },
                    cancelActionType: "abort_getBlockSmapSoilsAsync",
                })
            );
        });
    },
    abort: () => (dispatch) => dispatch({ type: "abort_getBlockSmapSoilsAsync" }),
}

const getBlockFslSoilsByGeoJsonAsync = {
    send: (features) => (dispatch) => {
        (features || []).forEach((f) => {
            delete f.properties.scrollRef;
        });

        return new Promise((resolve, reject) => {
            dispatch(
                api.post({
                    path: `soil/fsl/block`,
                    timeout: 300000,
                    content: { features },
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("Error loading FSL soils");
                        return [];
                    },
                    cancelActionType: "abort_getBlockFslSoilsAsync",
                })
            );
        });
    },
    abort: () => (dispatch) => dispatch({ type: "abort_getBlockFslSoilsAsync" }),
}

const getBlockFslSoilsByLatLngAsync = {
    send: (latitude, longitude) => (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                api.post({
                    path: `soil/fsl/latlng`,
                    timeout: 300000,
                    content: { latitude, longitude },
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("Error loading FSL soils");
                        return [];
                    },
                    cancelActionType: "getBlockFslSoilsByLatLngAsync",
                })
            );
        });
    },
    abort: () => (dispatch) => dispatch({ type: "getBlockFslSoilsByLatLngAsync" }),
}
