import { useAuthContext, useConfirm } from "common/hooks";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import { Link } from "react-router-dom";
import { useMyFarmAccessRequests, useResendFarmAccessRequestAsync, useWithdrawFarmAccessRequestAsync } from "containers/hooks";

export default function MyPendingFarmAccessRequests() {
    const { isFetching, data: myFarmAccessRequests } = useMyFarmAccessRequests();
    const confirm = useConfirm();
    const authContext = useAuthContext();
    const resendFarmAccessRequestAsync = useResendFarmAccessRequestAsync();
    const withdrawFarmAccessRequestAsync = useWithdrawFarmAccessRequestAsync();

    const myPendingFarmAccessRequests = myFarmAccessRequests?.filter((ar) => ar.status === "Pending" && ar.requestedBy.userId === authContext.userId) || [];
    if (!myPendingFarmAccessRequests || myPendingFarmAccessRequests.length === 0) {
        return null;
    }

    const resend = (accessRequest) => () => {
        confirm(`Are you sure you want to resend your request to access,'${accessRequest.farmName}'?`, async () => {
            await resendFarmAccessRequestAsync(accessRequest);
        });
    }

    const withdraw = (accessRequest) => () => {
        confirm(`Are you sure you want to withdraw your request to access, '${accessRequest.farmName}'?`, async () => {
            await withdrawFarmAccessRequestAsync(accessRequest);
        });
    }

    const info = "The list of farms that you have requested access to and the status of the emails that have been sent to the farm owner(s)";

    return (
        <>
            <Panel title={`My pending farm access requests (${myPendingFarmAccessRequests.length})`} iconClassName="IconLink--clock" info={info} waiting={isFetching} skyBlue open={false}>
                <PanelBody>
                    <div className="Table">
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="20">Farm</th>
                                    <th data-width="15">Email status</th>
                                    <th data-width="10">Requested access</th>
                                    <th data-width="10">Requested on</th>
                                    <th data-width="25">Comments</th>
                                    <th className="th--shrink"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {myPendingFarmAccessRequests
                                    .sort((a, b) => (b.farmName < a.farmName ? 1 : -1))
                                    .map((accessRequest, i) => {
                                        const isUnknown = accessRequest.emailStatus === "Unknown";
                                        return (
                                            <tr key={accessRequest.id} id={accessRequest.id} className={accessRequest.failed && !isUnknown ? "u-font-red u-font-600 hover" : "hover"}>
                                                <td valign="top" id={`farmname_${i}`}>
                                                    <Link to={`/app/farm/${accessRequest.farmId}`} className="u-link u-textBold">
                                                        {accessRequest.farmName}
                                                    </Link>
                                                    <br />
                                                    {accessRequest.farmAddress}
                                                </td>
                                                <td valign="top" id={`status_${i}`}>
                                                    {(accessRequest.emailStatus || "").split(",").map((s) => (
                                                        <div key={s} className={s.includes("failed") && !s.includes(": 0") && !isUnknown ? "u-font-red" : ""}>
                                                            {s}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td valign="top" id={`role_${i}`}>
                                                    {accessRequest.role}
                                                </td>
                                                <td valign="top" id={`date_${i}`}>
                                                    {utils.dateLong(accessRequest.requestedOn)}
                                                </td>
                                                <td valign="top" id={`comments_${i}`}>
                                                    {accessRequest.comments}
                                                </td>
                                                <td>
                                                    <div className="u-flex">
                                                        <ActionLink id={`delete-access-request-${accessRequest.id}`} className="IconLink--edit u-ml-sm" onClick={resend(accessRequest)}>
                                                            Resend
                                                        </ActionLink>
                                                        <ActionLink id={`delete-access-request-${accessRequest.id}`} className="IconLink--cross-circle u-ml-sm" onClick={withdraw(accessRequest)}>
                                                            Withdraw
                                                        </ActionLink>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </PanelBody>
            </Panel>
        </>
    )
}
