import React from "react";
import { Link } from "react-router-dom";
import { compose, withActions, withState, withAppContext } from "containers/HOC";
import { farmYear, calendarYear } from "common/domain";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import { getStats } from "../_actions";
import { Line } from "react-chartjs-2";
import * as utils from "common/utils";
import { BUDGETED_SALES } from "./BudgetedSales";

class Stats extends React.Component {
    state = {
        financialYear: this.props.financialYears[0],
        budgetedSales: BUDGETED_SALES[this.props.financialYears[0]] ? BUDGETED_SALES[this.props.financialYears[0]] : BUDGETED_SALES.none,
    };

    componentDidMount() {
        this.props.getStats();
    }

    handleFinancialYearChange = (e) => {
        const financialYear = Number(e.target.value);
        this.setState({ financialYear, budgetedSales: BUDGETED_SALES[financialYear] ? BUDGETED_SALES[financialYear] : BUDGETED_SALES.none });
    };

    displayStatValue = (statValue, highlightDiff) => {
        if ((statValue || 0) === 0) return "-";

        if (highlightDiff && statValue > 0) {
            return <span className="u-textSuccess">+{statValue.toLocaleString()}</span>;
        }

        if (highlightDiff && statValue < 0) {
            return <span className="u-textError">{statValue.toLocaleString()}</span>;
        }

        return statValue.toLocaleString();
    };

    render() {
        const { stats, financialYears, subStats, statsLoading } = this.props;

        const financialYearOptions = financialYears.map((year) => ({ value: year, text: year }));
        const financialYearSelect = (
            <div className="u-flex flex-center">
                <div className="Panel-action-label">Financial year:</div>
                <div className="Select Select--slim">
                    <select id="year" onChange={this.handleFinancialYearChange} value={this.state.financialYear}>
                        {financialYearOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );

        const subsForSelectedFinancialYear = subStats.filter((sub) => sub.financialYear === this.state.financialYear).sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));

        const yearTotal = subsForSelectedFinancialYear.reduce((total, sub) => (total += sub.count), 0);
        const newTotal = subsForSelectedFinancialYear.reduce((total, sub) => (total += sub.newSubCount), 0);
        const renewedTotal = subsForSelectedFinancialYear.reduce((total, sub) => (total += sub.renewedSubCount), 0);
        const expiredTotal = subsForSelectedFinancialYear.reduce((total, sub) => (total += sub.expiringSubCount || 0), 0);
        const netGainTotal = yearTotal - expiredTotal;
        const budgetedTotal = this.state.budgetedSales.reduce((total, budgeted) => (total += budgeted.sales), 0);
        const budgetedVsActualTotal = yearTotal - budgetedTotal;

        const chartDataByMonth = financialYears.map((financialYear, index) => {
            const subStatsByYear = subStats.filter((sub) => sub.financialYear === financialYear);
            const data = farmYear.map((monthName) => {
                const subStatsByMonth = subStatsByYear.find((s) => s.monthName === monthName);
                if (subStatsByMonth && subStatsByMonth.count) {
                    return subStatsByMonth.count;
                } else {
                    return null;
                }
            });
            return {
                label: financialYear,
                data,
                borderColor: utils.graphColours[index],
                backgroundColor: utils.graphColours[index],
                fill: false,
            };
        });

        const chartData = {
            labels: farmYear.map((month) => month),
            datasets: chartDataByMonth,
        };

        const chartOptions = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (tooltipItem) => Number(tooltipItem.raw).toLocaleString(),
                    },
                },
            },
            datasets: {
                line: {
                    tension: 0.4,
                },
            },
        };

        return (
            <Panel>
                <PanelBody loading={!stats && statsLoading}>
                    {!stats && (
                        <div className="Tile-body-message">
                            <p className="lead">No data</p>
                        </div>
                    )}
                    {stats && (
                        <>
                            <div className="Table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Total farm count</th>
                                            <th>Active subscription count</th>
                                            <th>Total org count</th>
                                            <th>Multi user org count</th>
                                            <th>User count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="hover">
                                            <td>{this.displayStatValue(stats.farmCount)}</td>
                                            <td>{this.displayStatValue(stats.activeSubscriptionCount)}</td>
                                            <td>{this.displayStatValue(stats.accountCount)}</td>
                                            <td>{this.displayStatValue(stats.multiUserAccountCount)}</td>
                                            <td>{this.displayStatValue(stats.userCount)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <Grid className="u-pt-lg">
                                <GridCell className="u-xl-width3of5">
                                    <Panel title="Subscriptions by year" actions={financialYearSelect} green>
                                        <PanelBody>
                                            <div className="Table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Year</th>
                                                            <th>Qrtr</th>
                                                            <th>Month</th>
                                                            <th className="u-textCenter">Exp</th>
                                                            <th className="u-textCenter">Renew</th>
                                                            <th className="u-textCenter">New</th>
                                                            <th className="u-textCenter">Gain</th>
                                                            <th className="u-bg-skyblue u-textWhite u-textCenter">Target</th>
                                                            <th className="u-bg-green u-textWhite u-textCenter">Actual</th>
                                                            <th className="u-bg-orange u-textWhite u-textCenter">Diff</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {subsForSelectedFinancialYear.map((sub, i) => {
                                                            const expiringSubCountQuarterTotal = subsForSelectedFinancialYear.filter((subStat) => subStat.quarter === sub.quarter).reduce((total, subStat) => (total += subStat.expiringSubCount || 0), 0);
                                                            const renewedSubCountQuarterTotal = subsForSelectedFinancialYear.filter((subStat) => subStat.quarter === sub.quarter).reduce((total, subStat) => (total += subStat.renewedSubCount), 0);
                                                            const newSubCountQuarterTotal = subsForSelectedFinancialYear.filter((subStat) => subStat.quarter === sub.quarter).reduce((total, subStat) => (total += subStat.newSubCount), 0);
                                                            const quarterTotal = subsForSelectedFinancialYear.filter((subStat) => subStat.quarter === sub.quarter).reduce((total, subStat) => (total += subStat.count), 0);
                                                            const budgetedQuarterTotal = this.state.budgetedSales.filter((budgeted) => budgeted.quarter === sub.quarter).reduce((total, budgeted) => (total += budgeted.sales), 0);
                                                            const budgetedVsActualQuarterTotal = quarterTotal - budgetedQuarterTotal;
                                                            const netGain = (sub.count || 0) - (sub.expiringSubCount || 0);
                                                            const netGainQuarterTotal = quarterTotal - expiringSubCountQuarterTotal;
                                                            const budgeted = this.state.budgetedSales[i].sales;
                                                            const budgetedVsActual = sub.count - budgeted;
                                                            return (
                                                                <React.Fragment key={sub.sortOrder}>
                                                                    <tr className="hover">
                                                                        <td className="u-textCenter">{sub.financialYear}</td>
                                                                        <td className="u-textCenter">Q{sub.quarter}</td>
                                                                        <td>
                                                                            {sub.monthName} {sub.year}
                                                                        </td>
                                                                        {sub.isInCompletedMonth ? (
                                                                            <td className="u-textRight">
                                                                                <Link to={`/app/admin/reports?year=${sub.year}&month=${sub.month}`}>{this.displayStatValue(sub.expiringSubCount)}</Link>
                                                                            </td>
                                                                        ) : (
                                                                            <td className="u-textRight">{this.displayStatValue(sub.expiringSubCount)}</td>
                                                                        )}
                                                                        <td className="u-textRight">{this.displayStatValue(sub.renewedSubCount)}</td>
                                                                        <td className="u-textRight">{this.displayStatValue(sub.newSubCount)}</td>
                                                                        <td className="u-textRight">{sub.count ? this.displayStatValue(netGain, true) : "-"}</td>
                                                                        <td className="u-textRight">{this.displayStatValue(budgeted)}</td>
                                                                        <td className="u-textRight">{this.displayStatValue(sub.count)}</td>
                                                                        <td className="u-textRight">{sub.count ? this.displayStatValue(budgetedVsActual, true) : "-"}</td>
                                                                    </tr>
                                                                    {[9, 12, 3, 6].includes(sub.month) && (
                                                                        <tr>
                                                                            <th colSpan="3" className="u-textRight">
                                                                                {this.state.financialYear} Q{sub.quarter} total
                                                                            </th>
                                                                            <th className="u-textRight">{this.displayStatValue(expiringSubCountQuarterTotal)}</th>
                                                                            <th className="u-textRight">{this.displayStatValue(renewedSubCountQuarterTotal)}</th>
                                                                            <th className="u-textRight">{this.displayStatValue(newSubCountQuarterTotal)}</th>
                                                                            <th className="u-textRight">{quarterTotal ? this.displayStatValue(netGainQuarterTotal, true) : "-"}</th>
                                                                            <th className="u-textRight">{this.displayStatValue(budgetedQuarterTotal)}</th>
                                                                            <th className="u-textRight">{this.displayStatValue(quarterTotal)}</th>
                                                                            <th className="u-textRight">{quarterTotal ? this.displayStatValue(budgetedVsActualQuarterTotal, true) : "-"}</th>
                                                                        </tr>
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th colSpan="3" className="u-textRight">
                                                                {this.state.financialYear} financial year total
                                                            </th>
                                                            <th className="u-textRight">{this.displayStatValue(expiredTotal)}</th>
                                                            <th className="u-textRight">{this.displayStatValue(renewedTotal)}</th>
                                                            <th className="u-textRight">{this.displayStatValue(newTotal)}</th>
                                                            <th className="u-textRight">{this.displayStatValue(netGainTotal, true)}</th>
                                                            <th className="u-textRight u-bg-skyblue u-textWhite">{this.displayStatValue(budgetedTotal)}</th>
                                                            <th className="u-textRight u-bg-green u-textWhite">{this.displayStatValue(yearTotal)}</th>
                                                            <th className="u-textRight u-bg-orange u-textWhite">{this.displayStatValue(budgetedVsActualTotal, true)}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </PanelBody>
                                    </Panel>
                                </GridCell>
                                <GridCell className="u-xl-width2of5">
                                    <Panel title="Subscriptions by month" skyBlue notCollapsible medium>
                                        <PanelBody>
                                            <Line data={chartData} options={chartOptions} />
                                        </PanelBody>
                                    </Panel>
                                </GridCell>
                            </Grid>
                        </>
                    )}
                </PanelBody>
            </Panel>
        );
    }
}

export default compose(
    withAppContext,
    withActions({ getStats }),
    withState((state) => {
        const stats = state.admin.stats || { subscriptions: [] };

        const currentMonth = new Date().getMonth() + 1;
        const currentFinancialYear = new Date().getFullYear() + (currentMonth >= 7 ? 1 : 0);
        const currentDate = new Date();
        currentDate.setDate(1);
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);

        const financialYears = stats.subscriptions.reduce(
            (years, sub) => {
                if (!years.includes(sub.year) && sub.year <= currentFinancialYear) years.push(sub.year);
                return years;
            },
            [currentFinancialYear]
        );

        const subStats = financialYears.reduce((results, year) => {
            const subsByYear = stats.subscriptions.filter((sub) => sub.year === year);

            const subsByMonth = farmYear.reduce((subs, monthName) => {
                const month = calendarYear.indexOf(monthName) + 1;

                // Only map up until the end of the current financial year in June
                const isBeyondCurrentFinancialYearEnd = year === currentFinancialYear && month > 6;
                if (isBeyondCurrentFinancialYearEnd) return subs;

                // Set financial year and quarter
                const sub = subsByYear.find((s) => s.month === month) || { year, month, count: 0, newSubCount: 0, renewedSubCount: 0 };
                if (sub.month < 4) {
                    sub.quarter = 3;
                    sub.financialYear = sub.year;
                } else if (sub.month < 7) {
                    sub.quarter = 4;
                    sub.financialYear = sub.year;
                } else if (sub.month < 10) {
                    sub.quarter = 1;
                    sub.financialYear = sub.year + 1;
                } else {
                    sub.quarter = 2;
                    sub.financialYear = sub.year + 1;
                }

                const subDate = new Date(sub.year, sub.month - 1, 1);

                subs.push({
                    ...sub,
                    monthName,
                    isInCompletedMonth: subDate < currentDate,
                    sortOrder: Number(`${sub.quarter}.${sub.month}`),
                });
                return subs;
            }, []);

            results = results.concat(subsByMonth);
            return results;
        }, []);

        return {
            stats: state.admin.stats,
            statsLoading: state.admin.statsLoading,
            financialYears: financialYears.sort().reverse(),
            subStats,
        };
    })
)(Stats);
