import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import * as FormUtils from "common/FormUtils";
import * as utils from "common/utils";
import InputPack from "components/InputPack";
import CheckboxPack from "components/CheckboxPack";
import { useRefData } from "common/hooks";

export default function OutdoorPigFeedCompositionsFieldArray({ form, outdoorPigs, fieldName, title }) {
    const refData = useRefData();

    const fieldState = form.getFieldState(fieldName);
    const errorMessages = getErrorMessages(fieldState, refData);

    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const showGrowersFinishersFields = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;
    const feedingSystem = outdoorPigs.feedingSystem || {};

    return (
        <>
            <h3>{title}</h3>
            <div className="Table u-mt-md">
                <table>
                    <thead>
                        <tr>
                            <th data-width="10"></th>
                            <th className="th--shrink">
                                Override
                                <br />
                                defaults
                            </th>
                            <th className="u-textCenter" style={{ minWidth: "65px" }}>
                                DM
                                <br />
                                content
                                <br />
                            </th>
                            <th className="u-textCenter" style={{ minWidth: "65px" }}>
                                Volatile
                                <br />
                                solids
                            </th>
                            <th className="u-textCenter" style={{ minWidth: "65px" }}>
                                ME
                                <br />
                                content
                            </th>
                            <th className="u-textCenter" style={{ minWidth: "65px" }}>
                                Crude
                                <br />
                                protein
                            </th>
                            <th className="u-textCenter" style={{ minWidth: "65px" }}>
                                Ileal
                                <br />
                                digestible
                                <br />
                                protein
                            </th>
                            <th className="u-textCenter" style={{ minWidth: "65px" }}>
                                P
                            </th>
                            <th className="u-textCenter" style={{ minWidth: "65px" }}>
                                Digestible
                                <br />P
                            </th>
                            {NUTRIENT_KEYS.map((nutrient) => (
                                <th key={nutrient} className="u-textCenter u-textCapitalise" style={{ minWidth: "65px" }}>
                                    {nutrient === "H" ? "Cl" : nutrient}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            <th className="u-pt-xs u-pb-xs"></th>
                            <th className="u-pt-xs u-pb-xs"></th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter">
                                MJ ME/<span className="u-textLower">kg</span>
                            </th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                            <th className="u-pt-xs u-pb-xs u-textCenter u-textLower">g/kg</th>
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray name={fieldName} validate={validateFeedCompositions}>
                            {({ fields }) => {
                                return fields.map((field, index) => {
                                    const feedComposition = fields.value[index];
                                    const feedCompositionGroupDisplayText = utils.valueToText(refData.outdoorPigFeedCompositionGroups, feedComposition.feedCompositionGroup);

                                    if (["Weaners", "Growers", "Finishers"].includes(feedComposition.feedCompositionGroup) && !showGrowersFinishersFields) return null;

                                    if (feedComposition.feedCompositionGroup === "GiltDevelopers" && outdoorPigs.growOutUnitOnly) return null;

                                    if (feedComposition.feedCompositionGroup === "Creep" && !feedingSystem.creepFeedSupplied) return null;

                                    return (
                                        <tr key={index}>
                                            <td className="u-textBold">{feedCompositionGroupDisplayText}</td>
                                            <td className="u-textCenter">
                                                <Field name={`${field}.override`} onChange={toggleDefaults(feedComposition.feedCompositionGroup, field, form, refData)} type="checkbox" component={CheckboxPack} />
                                            </td>
                                            <td className="u-textCenter">
                                                <Field name={`${field}.dmContent`} noLabel maxLength="6" disabled={!feedComposition.override} hideErrors placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                            </td>
                                            <td className="u-textCenter">
                                                <Field name={`${field}.volatileSolids`} noLabel maxLength="6" disabled={!feedComposition.override} hideErrors placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                            </td>
                                            <td className="u-textCenter">
                                                <Field name={`${field}.meContent`} noLabel maxLength="6" disabled={!feedComposition.override} hideErrors placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
                                            </td>
                                            <td className="u-textCenter">
                                                <Field name={`${field}.crudeProtein`} noLabel maxLength="6" disabled={!feedComposition.override} hideErrors placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                            </td>
                                            <td className="u-textCenter">
                                                <Field name={`${field}.ilealDigestibleProtein`} noLabel maxLength="6" disabled={!feedComposition.override} hideErrors placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                            </td>
                                            <td className="u-textCenter">
                                                <Field name={`${field}.nutrients[P]`} noLabel maxLength="6" disabled={!feedComposition.override} hideErrors placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatDecimal(1)} formatOnBlur />
                                            </td>
                                            <td className="u-textCenter">
                                                <Field name={`${field}.digestibleP`} noLabel maxLength="6" disabled={!feedComposition.override} hideErrors placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatDecimal(1)} formatOnBlur />
                                            </td>
                                            {NUTRIENT_KEYS.map((key, i) => {
                                                return (
                                                    <td key={i} className="u-textCenter">
                                                        <Field name={`${field}.nutrients[${key}]`} noLabel maxLength="6" disabled={!feedComposition.override} hideErrors placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatDecimal(1)} formatOnBlur />
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                });
                            }}
                        </FieldArray>
                    </tbody>
                </table>
                {errorMessages && errorMessages.length > 0 && (
                    <div className="Field-error u-block u-mt-0">
                        {errorMessages.map((msg) => (
                            <p key={msg}>{msg}</p>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

const NUTRIENT_KEYS = ["K", "S", "Ca", "Mg", "Na", "H"];
const NUTRIENT_KEYS_WITH_P = NUTRIENT_KEYS.concat("P");
const FEED_COMPOSITION_OBJECT_KEYS = ["dmContent", "volatileSolids", "meContent", "crudeProtein", "ilealDigestibleProtein", "digestibleP"];
const FEED_COMPOSITION_DISPLAY_TEXT_MAP = { dmContent: "DM Content", volatileSolids: "Volatile Solids", meContent: "ME Content", crudeProtein: "Crude Protein", ilealDigestibleProtein: "Ileal Digestible Protein", digestibleP: "Digestible P" };

const toggleDefaults = (feedCompositionGroup, field, form, refData) => (e) => {
    const selected = e.target.checked;
    if (!selected) {
        const feedCompositionDefaults = (refData.outdoorPigDefaults || {}).feedCompositionDefaults[feedCompositionGroup];
        form.mutators.setProperty(`${field}`, { ...feedCompositionDefaults, feedCompositionGroup });
    }
}

const validateFeedCompositions = (feedCompositions) => {
    const errors = [];

    feedCompositions &&
        feedCompositions.forEach((feedComposition) => {
            const error = {
                nutrients: {},
            };

            error.dmContent = FormUtils.validators.required(feedComposition.dmContent);
            error.dmContent = error.dmContent || FormUtils.validators.range(0.01, 1000)(feedComposition.dmContent);

            error.volatileSolids = FormUtils.validators.required(feedComposition.volatileSolids);
            error.volatileSolids = error.volatileSolids || (feedComposition.volatileSolids > feedComposition.dmContent ? "Must be less than DM Content" : undefined);
            error.volatileSolids = error.volatileSolids || FormUtils.validators.range(1, 300)(feedComposition.volatileSolids);

            error.meContent = FormUtils.validators.required(feedComposition.meContent);
            error.meContent = error.meContent || FormUtils.validators.range(1, 18)(feedComposition.meContent);

            error.crudeProtein = FormUtils.validators.required(feedComposition.crudeProtein);
            error.crudeProtein = error.crudeProtein || FormUtils.validators.range(1, 250)(feedComposition.crudeProtein);

            error.ilealDigestibleProtein = FormUtils.validators.required(feedComposition.ilealDigestibleProtein);
            error.ilealDigestibleProtein = error.ilealDigestibleProtein || (feedComposition.ilealDigestibleProtein >= feedComposition.crudeProtein ? "Must be less than Crude Protein" : undefined);

            error.digestibleP = FormUtils.validators.required(feedComposition.digestibleP);
            error.digestibleP = error.digestibleP || (feedComposition.digestibleP > feedComposition.nutrients["P"] ? "Must be less than P" : undefined);

            NUTRIENT_KEYS_WITH_P.forEach((key) => {
                error.nutrients[key] = FormUtils.validators.required(feedComposition.nutrients[key]);
                error.nutrients[key] = error.nutrients[key] || FormUtils.validators.range(0.01, 20)(feedComposition.nutrients[key]);
            });

            errors.push(error);
        });

    return errors;
}

const getErrorMessages = (fieldState, refData) => {
    const errorMessages =
        fieldState &&
        (fieldState.error || []).reduce((errors, error, index) => {
            const feedComposition = fieldState.value[index];
            const feedCompositionGroupDisplayText = utils.valueToText(refData.outdoorPigFeedCompositionGroups, feedComposition.feedCompositionGroup);

            if (FEED_COMPOSITION_OBJECT_KEYS.some((key) => error[key])) {
                const property = FEED_COMPOSITION_OBJECT_KEYS.find((key) => error[key]);
                const message = error[property];
                const formattedMessage = message.charAt(0).toLowerCase() + message.slice(1);
                errors.push(`${feedCompositionGroupDisplayText} - ${FEED_COMPOSITION_DISPLAY_TEXT_MAP[property]} ${formattedMessage}`);
            } else if (NUTRIENT_KEYS_WITH_P.some((key) => error.nutrients[key])) {
                const property = NUTRIENT_KEYS_WITH_P.find((key) => error.nutrients[key]);
                const message = error.nutrients[property].toLowerCase();
                errors.push(`${feedCompositionGroupDisplayText} - ${property} ${message}`);
            }

            return errors;
        }, []);
    return errorMessages;
}
