import Alert from "components/Alert";
import { FileSummary } from "components/PlanningReport";
import FeedSummary from "./FeedSummary";
import FeedSummaryByEnterprise from "./FeedSummaryByEnterprise";
import { useAnalysisResults } from "containers/BudgetHome";

export default function Feed({ before, after, showFileComparison }) {
    const { isLoading, data: afterResults } = useAnalysisResults(after?.details);

    const hasResults = isLoading || afterResults?.animals?.diet?.totalRevisedStockUnits?.value > 0;
    if (!hasResults) {
        return (
            <div className="u-pt-lg">
                <Alert type="info" text="There is no feed information for this analysis" />
            </div>
        );
    }

    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <FeedSummary before={before} after={after} />
            <FeedSummaryByEnterprise before={before} after={after} />
        </div>
    )
}
