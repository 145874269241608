import React from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as domain from "common/domain";
import ActionLink from "components/ActionLink";
import { useOnline, useRefData } from "common/hooks";

const WetlandCard = ({ farm, analysis, block, wetland, unfenced, promptBeforeDelete }) => {
    const online = useOnline();
    const refData = useRefData();

    const _blockIcon = utils.getBlockIcon(block.type);
    const _blockType = utils.getBlockTypeText(refData, block);

    const _isPopulatedWetland = unfenced || (block.type === domain.BlockType.NonProductiveWetland && wetland && wetland.catchmentArea > 0);
    const catchmentConvergence = _isPopulatedWetland ? refData.wetlandConvergence.find((c) => c.value === wetland.catchmentConvergence) : undefined;
    const aquitardDepth = _isPopulatedWetland ? refData.wetlandAquitard.find((c) => c.value === wetland.catchmentAquitardDepth) : undefined;

    const wetlandId = unfenced ? wetland.id : block.id;
    const wetlandName = unfenced ? wetland.name : block.name;

    const editLink = unfenced ? `/app/farm/${farm.id}/analysis/${analysis.id}/drainage/unfenced/${wetland.id}?uf=1` : `/app/farm/${farm.id}/analysis/${analysis.id}/drainage/fenced/${block.id}`;

    const messages = analysis.messages || [];
    const alertIcon = online && messages.find((m) => m.category === "Wetlands" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error") ? "error" : undefined;
    const wetlandErrors = online && messages.find((m) => m.category === "Wetlands" && m.entityType === "Wetland" && m.entityId === wetland.id && m.severity === "Error") ? "error" : undefined;

    return (
        <div className="FarmTable_wrapper" id={wetlandId}>
            <ul className="DataWidthTable FarmTable">
                <li className="FarmTable-title" data-width="md-100 xl-30">
                    <div className="FarmTitle">
                        {alertIcon && (
                            <span className={`Todo Todo--` + alertIcon}>
                                <i className="icon icon-alert" />
                            </span>
                        )}
                        {wetlandErrors && (
                            <span className="Todo Todo--error error-anchor">
                                <i className="icon icon-alert" />
                            </span>
                        )}
                        <img className="FarmTitle-icon" src={_blockIcon} alt="Wetland" />
                        <div className="FarmTitle-heading">
                            <Link to={editLink} className="FarmTitle-link">
                                <span className="FarmTitle-name" id={`${wetland.id}_name`} title={wetlandName}>
                                    {wetlandName}
                                </span>
                            </Link>
                            <div className="FarmTitle-sub">{unfenced ? "Unfenced wetland" : _blockType}</div>
                        </div>
                    </div>
                </li>

                {_isPopulatedWetland && (
                    <li data-width="md-20 xl-20">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Catchment area</span>
                                <span className="FarmTable-value" id={`${wetland.id}_catchment-area`}>
                                    {wetland.catchmentArea} ha
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {_isPopulatedWetland && (
                    <li data-width="md-20 xl-20">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Catchment convergence</span>
                                <span className="FarmTable-value" id={`${wetland.id}_convergence`}>
                                    {catchmentConvergence && catchmentConvergence.text}
                                </span>
                            </div>
                        </div>
                    </li>
                )}
                {_isPopulatedWetland && (
                    <li data-width="md-20 xl-20">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Aquitard depth</span>
                                <span className="FarmTable-value" id={`${wetland.id}_aquitard`}>
                                    {aquitardDepth && aquitardDepth.text}
                                </span>
                            </div>
                        </div>
                    </li>
                )}

                {!_isPopulatedWetland && (
                    <li data-width="md-grow xl-grow" className="FarmTable-link">
                        {block.type === domain.BlockType.NonProductiveWetland && (
                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/drainage/fenced/${block.id}`} className="IconLink--arrow-plus" type="button" id={`${block.id}_catchment-add`}>
                                <span>Add catchment details</span>
                            </Link>
                        )}
                    </li>
                )}
            </ul>
            <div className="ActionsBar">
                <div className="ActionsBar-right">
                    <ul className="ActionsBar-links">
                        {unfenced && (
                            <li>
                                <ActionLink onClick={(e) => promptBeforeDelete(e, wetlandName, wetlandId, unfenced)} className="IconLink--trash" id={`${wetlandId}_wetland-delete`}>
                                    Delete unfenced wetland
                                </ActionLink>
                            </li>
                        )}
                        {_isPopulatedWetland && (
                            <li>
                                <Link to={editLink} className="IconLink--edit" id={`${wetland.id}_wetland-edit`}>
                                    Edit wetland
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default WetlandCard;
