import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import { copyDataset } from "containers/Datasets/_actions";
import { useOnline } from "common/hooks";
import { useOrgDatasets } from "../../Datasets/_hooks";

export default function OrgDatasets({ org }) {
    const datasets = useOrgDatasets(org.id);
    const online = useOnline();
    const dispatch = useDispatch();

    const DatasetListRow = ({ dataset }) => {
        const className = dataset.testDataset ? (dataset.testsPassed ? "Table-tr--green" : "Table-tr--red") : "";
        return (
            <tr key={dataset.id} className={className + " hover u-link"}>
                <td>{dataset.project || "-"}</td>
                <td>
                    {online && (
                        <Link to={`/app/admin/orgs/${org.id}/datasets/${dataset.id}`} id={"view-dataset-link-" + dataset.id}>
                            {dataset.name}
                        </Link>
                    )}
                </td>
                <td>{utils.dateLong(dataset.dateCreated)}</td>
                <td>{dataset.createdBy ? dataset.createdBy.userFullName : "-"}</td>
                <td>
                    <ActionLink onClick={_copyDataset(dataset.id)} id={`copy-datasset-${dataset.id}`} className="IconLink--copy">
                        Copy
                    </ActionLink>
                </td>
            </tr>
        );
    };

    const _copyDataset = (datasetId) => () => {
        dispatch(copyDataset(datasetId)).then(() => dispatch(push(`/app/datasets`)));
    };

    return (
        <Panel>
            <PanelBody loading={!datasets}>
                {datasets && (
                    <p className="u-mt-0 u-mb-md">
                        <span className="h3">{datasets.length}</span> datasets found
                    </p>
                )}
                <div className="Table">
                    <table>
                        <thead>
                            <tr>
                                <th>Project</th>
                                <th>Dataset name</th>
                                <th>Date created</th>
                                <th>Created by</th>
                                <th className="th--shrink"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {datasets &&
                                datasets
                                    .sort(function (a, b) {
                                        return a.name.localeCompare(b.name, undefined, {
                                            numeric: true,
                                            sensitivity: "base",
                                        });
                                    })
                                    .map((d) => {
                                        return <DatasetListRow key={d.id} dataset={d} />;
                                    })}
                        </tbody>
                    </table>
                </div>
            </PanelBody>
        </Panel>
    );
}
