import React from "react";
import NutrientInputPack from "components/NutrientInputPack";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack";
import * as domain from "common/domain";
import Alert from "components/Alert";
import { useRefData } from "common/hooks";

const IrrigationDetails = ({ validation, irrigator, onChange, customCompositions = [], isGlobal, fieldRefs }) => {
    const refData = useRefData();

    const itVal = validation.type || {};
    const ngVal = validation.nutrientGroup || {};
    const nameVal = validation.name || {};
    const sourceVal = validation.nutrientSource || {};

    const { irrigatorSource = [] } = refData;
    const sourceList = irrigatorSource.filter((s) => s.value !== "Computerdefault");
    const irrigatorTypeTip = domain.IrrigatorTypeTips[irrigator.type];

    const irrigatorSourceOptions = sourceList.map((s) => (
        <option value={s.value} key={s.value}>
            {s.text}
        </option>
    ));
    const myProducts = customCompositions
        .filter((c) => c.userId)
        .map((s) => (
            <option value={s.id} key={s.id}>
                {s.name}
            </option>
        ));
    const orgProducts = customCompositions
        .filter((c) => !c.userId)
        .map((s) => (
            <option value={s.id} key={s.id}>
                {s.name}
            </option>
        ));
    const nutrientSourceOptions =
        customCompositions.length > 0 ? (
            <React.Fragment>
                {irrigatorSourceOptions}
                {myProducts.length > 0 && <optgroup label="Copy my irrigation nutrients">{myProducts}</optgroup>}
                {orgProducts.length > 0 && <optgroup label="Copy organisation irrigation nutrients">{orgProducts}</optgroup>}
            </React.Fragment>
        ) : (
            irrigatorSourceOptions
        );

    const ValidationAnchor = ({ field }) => fieldRefs[field] && <span ref={fieldRefs[field]} />;

    return (
        <div className="Grid Grid--withGutter">
            <div className="Grid-cell u-lg-width1of1 u-xl-width1of2">
                <ValidationAnchor field="type" />
                <SelectPack id="irrigatorDetailsType" meta={{ nrf: true }} onChange={(e) => onChange({ type: "irrigator", key: "type" }, e)} val={itVal} label="Irrigation system type" disabled={irrigator.applications.length > 0} value={irrigator.type} className="u-mt-sm" tip={irrigatorTypeTip ? irrigatorTypeTip.text : undefined} requiredLabel={true}>
                    <option value="" disabled={true}>
                        Select an irrigation type
                    </option>
                    {refData.irrigatorTypes &&
                        refData.irrigatorTypes.map((irrigatorType) => (
                            <option value={irrigatorType.value} key={irrigatorType.value}>
                                {irrigatorType.text}
                            </option>
                        ))}
                </SelectPack>

                <ValidationAnchor field="name" />
                <InputPack id="irrigator-name" meta={{ nrf: true }} label="Name" placeholder="Enter a name for this irrigator" requiredLabel={true} val={nameVal} onChange={(e) => onChange({ type: "irrigator", key: "name" }, e)} value={irrigator.name} />

                <SelectPack id="irrigatorDetailsNutrientSource" meta={{ nrf: true }} onChange={(e) => onChange({ type: "irrigator", key: "nutrientSource" }, e)} val={sourceVal} label="Nutrient source" disabled={false} value={irrigator.nutrientSource ? irrigator.nutrientSource : "Overseerdefaultfixed"} requiredLabel={true}>
                    {nutrientSourceOptions}
                </SelectPack>
                {isGlobal && <Alert type="info" text="Nutrient details have been copied from the global nutrient irrigation make up. Editing these nutrients within the analysis will only edit the irrigation for the analysis - not the global irrigation" />}

                <ValidationAnchor field="N" />
                <ValidationAnchor field="P" />
                <ValidationAnchor field="K" />
                <ValidationAnchor field="S" />
                <ValidationAnchor field="Ca" />
                <ValidationAnchor field="Mg" />
                <ValidationAnchor field="Na" />
                <div className="Field-group">
                    <NutrientInputPack id="N" label="N" component="input" value={irrigator.nutrients.N} onChange={onChange} nutrient="N" validation={validation} />
                    <NutrientInputPack id="P" label="P" component="input" value={irrigator.nutrients.P} onChange={onChange} nutrient="P" validation={validation} />
                    <NutrientInputPack id="K" label="K" component="input" value={irrigator.nutrients.K} onChange={onChange} nutrient="K" validation={validation} />
                    <NutrientInputPack id="S" label="S" component="input" value={irrigator.nutrients.S} onChange={onChange} nutrient="S" validation={validation} />
                    <NutrientInputPack id="Ca" label="Ca" component="input" value={irrigator.nutrients.Ca} onChange={onChange} nutrient="Ca" validation={validation} />
                    <NutrientInputPack id="Mg" label="Mg" component="input" value={irrigator.nutrients.Mg} onChange={onChange} nutrient="Mg" validation={validation} />
                    <NutrientInputPack id="Na" label="Na" component="input" value={irrigator.nutrients.Na} onChange={onChange} nutrient="Na" validation={validation} />
                    <div className="Field Nutrient-field" data-width="20">
                        <label className="Select Select--block">
                            <span className="Field-label">units</span>
                            <select className="is-unpopulated" id="nutrientDetailsUnit" onChange={onChange.bind(this, { type: "irrigator", key: "nutrientUnit" })} value={irrigator.nutrientUnit}>
                                {refData.irrigatorNutrientUnits &&
                                    refData.irrigatorNutrientUnits.map((unit) => (
                                        <option value={unit.value} key={unit.value}>
                                            {unit.text}
                                        </option>
                                    ))}
                            </select>
                        </label>
                    </div>
                </div>

                <div className={"Field" + (ngVal.touched && ngVal.error ? " has-error" : "")}>
                    <small className="Field-error">At least one nutrient value should be greater than 0</small>
                </div>
            </div>
        </div>
    );
};

export default IrrigationDetails;
