import { Component } from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";
import arrayMutators from "final-form-arrays";
import SavePrompt from "components/SavePrompt";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import { getOutdoorPigEffluentSystemViewModel } from "common/viewModels";
import OutdoorPigLiquidApplications from "./OutdoorPigLiquidApplications";
import OutdoorPigSolidApplications from "./OutdoorPigSolidApplications";
import { Button } from "components/Button";
import * as structureUtils from "./_utils";
import { useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function OutdoorPigEffluentSystem({ farm, analysis }) {
    const refData = useRefData();
    const viewModel = getOutdoorPigEffluentSystemViewModel(farm, analysis, refData);
    viewModel.isModified = false;
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    return <OutdoorPigEffluentSystemClassComponent farm={farm} analysis={analysis} viewModel={viewModel} updateAnalysisAsync={updateAnalysisAsync} refData={refData} />
}

class OutdoorPigEffluentSystemClassComponent extends Component {
    constructor(props) {
        super(props);
        const { viewModel } = props;
        this.state = {
            viewModel,
        };
    }

    getEffluentDisposal = (holdingPondManagement) => {
        switch (holdingPondManagement) {
            case "SprayRegularly":
                return "HoldingpondSprayregularly";
            case "StirSprayRegularly":
                return "HoldingpondStirsprayregularly";
            case "SprayInfrequently":
                return "HoldingpondSprayinginfrequently";
            default:
                return null;
        }
    };

    validate = (viewModel) => {
        const error = {};

        if (viewModel.liquidEffluentSources.length > 0) error.liquidEffluentApplicationsGroup = !viewModel.liquidEffluentApplications || viewModel.liquidEffluentApplications.length === 0 ? "Required" : undefined;

        if (viewModel.pondSolidSources.length > 0) error.pondSolidApplicationsGroup = !viewModel.pondSolidApplications || viewModel.pondSolidApplications.length === 0 ? "Required" : undefined;

        if (viewModel.strawCompostSources.length > 0) error.strawCompostApplicationsGroup = !viewModel.strawCompostApplications || viewModel.strawCompostApplications.length === 0 ? "Required" : undefined;

        return error;
    };

    submit = async (viewModel) => {
        // Map view model back to dto.
        const outdoorPigEffluentSystem = {};

        if (viewModel.liquidEffluentApplications) {
            if (viewModel.villagesHoldingPondManagement) outdoorPigEffluentSystem.villagesEffluentDisposal = this.getEffluentDisposal(viewModel.villagesHoldingPondManagement);

            if (viewModel.barnsHoldingPondManagement) outdoorPigEffluentSystem.barnsEffluentDisposal = this.getEffluentDisposal(viewModel.barnsHoldingPondManagement);

            const monthsAreRequired = viewModel.villagesHoldingPondManagement === "SprayInfrequently";
            outdoorPigEffluentSystem.liquidEffluentApplications = viewModel.liquidEffluentApplications.map((application) => {
                const isForCropBlocks = application.blockIds.some((blockId) => (viewModel.blocks || []).some((b) => b.id === blockId && ["ProductiveCrop", "FodderCrop"].includes(b.type)));
                if (!monthsAreRequired && !isForCropBlocks) return { ...application, months: [] };

                return {
                    ...application,
                    months: application.months.map((month) => ({ month, reportingYear: true })),
                };
            });
        }

        if (viewModel.pondSolidApplications) {
            outdoorPigEffluentSystem.pondSolidApplications = viewModel.pondSolidApplications.map((application) => {
                return {
                    ...application,
                    months: application.months.map((month) => ({ month, reportingYear: true })),
                };
            });
        }

        if (viewModel.strawCompostApplications) {
            outdoorPigEffluentSystem.strawCompostApplications = viewModel.strawCompostApplications.map((application) => {
                return {
                    ...application,
                    months: application.months.map((month) => ({ month, reportingYear: true })),
                };
            });
        }

        const updatedAnalysis = structureUtils.getUpdatedAnalysisFromSavingOutdoorPigEffluentSystem(this.props.analysis, outdoorPigEffluentSystem);
        await this.props.updateAnalysisAsync(updatedAnalysis);
    };

    render() {
        const { farm, analysis } = this.props;
        const referrer = `/app/farm/${farm.id}/analysis/${analysis.id}/structures`;

        return (
            <Form
                initialValues={this.state.viewModel}
                validate={this.validate}
                onSubmit={this.submit}
                mutators={{
                    setProperty: (args, state, utils) => {
                        utils.changeValue(state, args[0], () => args[1]);
                    },
                    ...arrayMutators,
                }}
            >
                {(formRenderProps) => {
                    const { form, values, handleSubmit, dirty, submitting, submitSucceeded } = formRenderProps;

                    const hasChanged = values.isModified === true;
                    const isModified = hasChanged || dirty;

                    return (
                        <form onSubmit={handleSubmit}>
                            {true && <SavePrompt blockIf={isModified && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={referrer} />}
                            <Panel title="Outdoor pigs effluent system" waiting={submitting} referrer={referrer}>
                                <PanelBody>
                                    {values.liquidEffluentApplications && <OutdoorPigLiquidApplications values={values} title="Liquid effluent" form={form} fieldName="liquidEffluentApplications" applications={values.liquidEffluentApplications} sources={values.liquidEffluentSources} farm={farm} analysis={analysis} blocks={values.blocks} villagesHoldingPondManagement={values.villagesHoldingPondManagement} barnsHoldingPondManagement={values.barnsHoldingPondManagement} />}
                                    {values.pondSolidApplications && <OutdoorPigSolidApplications values={values} title="Pond solids" form={form} fieldName="pondSolidApplications" applications={values.pondSolidApplications} sources={values.pondSolidSources} farm={farm} analysis={analysis} blocks={values.blocks} />}
                                    {values.strawCompostApplications && <OutdoorPigSolidApplications values={values} title="Straw compost" form={form} fieldName="strawCompostApplications" applications={values.strawCompostApplications} sources={values.strawCompostSources} farm={farm} analysis={analysis} blocks={values.blocks} />}
                                </PanelBody>
                                <PanelFooter>
                                    <div className="ButtonBar ButtonBar--fixed">
                                        <div className="ButtonBar-left">
                                            <Link to={referrer} id="cancel-button" className="Button Button--secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="ButtonBar-right">
                                            <Button id="save-button" submit primary waiting={submitting}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </PanelFooter>
                            </Panel>
                        </form>
                    );
                }}
            </Form>
        )
    }
}
