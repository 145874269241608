import actionTypes from "./_actions";
import authActions from "containers/Auth/_actions";

const INITIAL_STATE = {
    isLoading: false,
    settings: undefined,
    serverNotification: undefined,
    datasets: undefined,
    currentDataset: undefined,
    modelledResults: undefined,
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.GET_SETTINGS:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.GET_SETTINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                settings: action.payload,
            };
        case actionTypes.DATASET_MODEL_RUN_CANCEL:
            return {
                ...state,
                serverNotification: undefined,
            };
        case actionTypes.DATASET_MODEL_RUN_STARTED:
            return {
                ...state,
                serverNotification: action.notification,
            };
        case actionTypes.DATASET_MODEL_RUN_COMPLETED:
            return {
                ...state,
                serverNotification: action.notification,
            };
        case actionTypes.DATASET_MODEL_TEST_COMPLETED:
            return {
                ...state,
                serverNotification: action.notification,
            };
        case actionTypes.DATASET_PROCESSING_DATASET:
            return {
                ...state,
                serverNotification: action.notification,
            };
        case actionTypes.GET_DATASETS:
            return {
                ...state,
                datasets: action.payload,
                isLoading: true,
            };
        case actionTypes.GET_DATASETS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                datasets: action.payload,
            };
        case actionTypes.GET_DATASET:
            if (!action.payload) {
                action.payload = { datasetId: state.datasetId };
            }

            return {
                ...state,
                ...action.payload,
                isLoading: true,
            };
        case actionTypes.GET_DATASET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                currentDataset: action.payload,
            };
        case actionTypes.GET_DATASET_CLEAR:
            return {
                ...state,
                isLoading: false,
                currentDataset: undefined,
            };
        case actionTypes.GET_DATASETS_CLEAR:
            return {
                ...state,
                isLoading: false,
                datasets: undefined,
            };
        case actionTypes.GET_MEASURED_VALUES:
            if (!action.payload) {
                action.payload = { datasetId: state.datasetId };
            }

            return {
                ...state,
                ...action.payload,
                isLoading: true,
            };
        case actionTypes.GET_MEASURED_VALUES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                currentDataset: {
                    ...state.currentDataset,
                    measuredValues: action.payload,
                },
            };
        case actionTypes.GET_MEASURED_VALUES_CLEAR:
            return {
                ...state,
                isLoading: false,
                currentDataset: {
                    ...state.currentDataset,
                    measuredValues: undefined,
                },
            };

        case actionTypes.GET_RUN:
            if (!action.payload) {
                action.payload = { datasetId: state.datasetId };
            }

            return {
                ...state,
                ...action.payload,
                isLoading: true,
            };
        case actionTypes.GET_RUN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                currentDataset: {
                    ...state.currentDataset,
                    runs: state.currentDataset.runs.map((r) => {
                        return r.id === action.payload.id ? action.payload : r;
                    }),
                },
            };
        case authActions.AUTH_CLEAR_STORAGE:
            return INITIAL_STATE;
        case actionTypes.EXECUTE_MODEL_TEST_SUCCESS:
            return {
                ...state,
                modelExecutionMessage: "Model test execution started...",
            };
        case actionTypes.EXECUTE_MODEL_TEST_FAILURE:
            return {
                ...state,
                modelExecutionMessage: "Unable to contact the server to execute the model run.",
            };
        case actionTypes.LYSIMETER_RESULTS_FETCHED:
            return {
                ...state,
                modelledResults: action.payload,
            };
        default:
            return state;
    }
}

export default reducer;
