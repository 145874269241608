import { useState } from "react";
import { Bar } from "react-chartjs-2";
import WidgetContainer from "components/WidgetContainer";
import { useFarmTrendCharts } from "containers/Farm/Trends";
import ActionLink from "components/ActionLink";

export default function Trends({ farm, className, height }) {
    const { loading, error, charts } = useFarmTrendCharts(farm);
    const [pageNumber, setPageNumber] = useState(0);

    const trendChart = charts && charts.length > 0 && charts[pageNumber];

    const numPages = charts && charts.length - 1;
    const leadBase = trendChart && (
        <div className="u-mt-md">
            <ActionLink onClick={() => setPageNumber(pageNumber > 0 ? pageNumber - 1 : numPages)} className="RoundButton RoundButton--left u-mr-sm">
                <span></span>
            </ActionLink>
            <ActionLink onClick={() => setPageNumber(pageNumber < numPages ? pageNumber + 1 : 0)} className="RoundButton RoundButton--right">
                <span></span>
            </ActionLink>
        </div>
    );

    const pill = !loading && (trendChart ? { level: "info", text: "Active" } : { level: "warning", text: "Insufficient data" });

    return (
        <WidgetContainer id="trends" title="Trends" icon="IconLink--stats-dots" className={className} loading={loading} error={error} linkTitle={trendChart ? "View all" : null} linkUrl={trendChart && `/app/farm/${farm.id}/trends`} pill={pill} leadBase={leadBase} height={height}>
            {trendChart && (
                <div>
                    <div className="Widget-content-title u-mb-md">{trendChart.data.title}</div>
                    <Bar data={trendChart.data} height={100} options={trendChart.options} />
                </div>
            )}
            {!trendChart && !error && (
                <div>
                    <div className="Widget-content-title">Insufficient trend data</div>
                    <p>For trends to display there must be at least two year end analyses with results.</p>
                </div>
            )}
        </WidgetContainer>
    )
}
