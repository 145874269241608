import React from "react";

const PageBody = ({ children, style, className }) => {
    return (
        <main className={`PageBody u-print-nopadding`} style={style}>
            <div className={`Container ${className}`} style={{ height: "100%" }}>
                {children}
            </div>
        </main>
    );
};
export default PageBody;
