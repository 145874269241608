import React, { useEffect, useState } from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import { findEmails } from "./_actions";
import { Grid, GridCell } from "components/Grid";
import InputPack from "components/InputPack";
import { useDebounce } from "common/effects";
import * as validations from "common/validations";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import * as utils from "common/utils";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import moment from "moment";

const Emails = ({ findEmails }) => {
    const endDate = moment().format("YYYY-MM-DDTHH:mm");
    const startDate = moment().subtract(1, "days").format("YYYY-MM-DDTHH:mm");
    const [emails, setEmails] = useState([]);
    const [emailId, setEmailId] = useState();
    const [emailType, setEmailType] = useState();
    const [query, setQuery] = useState({ start: startDate, end: endDate });
    const [lastQuery, setLastQuery] = useState({});
    const debouncedSearchQuery = useDebounce(query, 500);

    const doSearch = async () => {
        if (query.start && query.end && (query.start !== lastQuery.start || query.end !== lastQuery.end || query.toEmail !== lastQuery.toEmail || query.fromEmail !== lastQuery.fromEmail)) {
            const emailResults = await findEmails(query);
            setEmails(emailResults);
            setLastQuery(query);
        }
    };

    useEffect(() => {
        const search = async (vm) => {
            const query = {};
            if (vm.toEmail && vm.toEmail.length > 0 && !validations.email(vm.toEmail)) {
                query.toEmail = vm.toEmail;
            }
            if (vm.fromEmail && vm.fromEmail.length > 0 && !validations.email(vm.fromEmail)) {
                query.fromEmail = vm.fromEmail;
            }
            if (vm.start && vm.start.length > 0) {
                query.start = moment(vm.start).toJSON();
            }
            if (vm.end && vm.end.length > 0) {
                query.end = moment(vm.end).toJSON();
            }
            if (query.start && query.end && (query.start !== lastQuery.start || query.end !== lastQuery.end || query.toEmail !== lastQuery.toEmail || query.fromEmail !== lastQuery.fromEmail)) {
                const emailResults = await findEmails(query);
                setEmails(emailResults);
                setLastQuery(query);
            }
        };
        search(debouncedSearchQuery);
    }, [findEmails, debouncedSearchQuery, lastQuery.start, lastQuery.end, lastQuery.toEmail, lastQuery.fromEmail]);

    const uniqueTypes = Array.from(new Set(emails.map((e) => e.emailType)));

    const farmAccessInvitations = ["FarmAccessInviteEmail", "FarmAccessReminderEmail", "FarmGroupInvitePendingOwnerEmail", "FarmOwnerReminderEmail"];
    const userInvitations = ["InviteStudentEmail", "InviteOrganisationViaAdminEmail", "InviteUserEmail"];
    const envUrl = process.env.REACT_APP_PRODUCT === "fm" ? "https://fm.overseer.org.nz" : process.env.REACT_APP_PRODUCT === "ed" ? "https://edu.overseer.org.nz" : "https://sci.overseer.org.nz";

    const formatPercentageCell = (value, total) => {
        return (
            <div className="u-flex u-flexJustifyBetween">
                {total > 0 ? <div> {Math.round((value / total) * 100)}%</div> : <div>-</div>}
                <div>{value}</div>
            </div>
        );
    };

    return (
        <div>
            <Alert type="warning" text="Be careful with the 'Validate email link'. The user's email will be validated if you click on it. The links are also only valid in production environments." />
            <Grid>
                <GridCell className="u-width1of5">
                    <InputPack label="Start" id="start" type="datetime-local" value={query.start} meta={{ nrf: true }} onChange={(e) => setQuery({ ...query, ...{ start: e.target.value } })} />
                </GridCell>
                <GridCell className="u-width1of5">
                    <InputPack label="End" id="end" type="datetime-local" value={query.end} meta={{ nrf: true }} onChange={(e) => setQuery({ ...query, ...{ end: e.target.value } })} />
                </GridCell>
                <GridCell className="u-width1of5">
                    <InputPack label="To email" id="toEmail" type="text" value={query.toEmail} meta={{ nrf: true }} onChange={(e) => setQuery({ ...query, ...{ toEmail: e.target.value } })} />
                </GridCell>
                <GridCell className="u-width1of5">
                    <InputPack label="From email" id="fromEmail" type="text" value={query.fromEmail} meta={{ nrf: true }} onChange={(e) => setQuery({ ...query, ...{ fromEmail: e.target.value } })} />
                </GridCell>
            </Grid>
            <div className="u-pt-md">
                <ActionLink onClick={() => doSearch()} className="u-flex">
                    <i className="icon icon--sm icon-refresh" />
                    <div className="u-ml-sm u-flexJustifyCenter">Reload</div>
                </ActionLink>
            </div>
            <div className="u-pt-xl">
                {emails && emails.length > 0 && (
                    <>
                        {uniqueTypes && uniqueTypes.length > 0 && (
                            <Grid>
                                <GridCell>
                                    <div div className="h3 u-mb-md u-mt-sm">
                                        Sent email statistics
                                    </div>
                                    <div className="Table u-mb-md">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th data-width="12.5" align="right">
                                                        Count
                                                    </th>
                                                    <th data-width="12.5" align="right">
                                                        Success
                                                    </th>
                                                    <th data-width="12.5" align="right">
                                                        Failed
                                                    </th>
                                                    <th data-width="12.5" align="right">
                                                        Opened
                                                    </th>
                                                    <th data-width="12.5" align="right">
                                                        Link clicked
                                                    </th>
                                                    <th data-width="12.5" align="right">
                                                        Subscribed at send
                                                    </th>
                                                    <th data-width="12.5" align="right">
                                                        Subscribed now
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {uniqueTypes.map((t) => {
                                                    const typeEmails = emails.filter((e) => e.emailType === t);
                                                    const opened = typeEmails.filter((t) => t.history.find((h) => h.type === "Email opened by user"));
                                                    const clicked = typeEmails.filter((t) => t.history.find((h) => h.type === "User clicked on link in email"));
                                                    const subAtSend = typeEmails.filter((t) => t.hadSubscriptionAtSending);
                                                    const subNow = typeEmails.filter((t) => t.hasSubscription);
                                                    const allHistory = typeEmails.reduce((t, e) => [...t, ...e.history], []);

                                                    const uniqueActions = Array.from(new Set(allHistory.map((e) => e.type)));
                                                    const isExpanded = t === emailType;

                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <div className="u-flex u-flexJustifyBetween">
                                                                        <span>{t}</span>
                                                                        <ActionLink className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween" onClick={() => setEmailType(isExpanded ? false : t)}>
                                                                            <i className={`icon icon-arrow-${isExpanded ? "up" : "down"}`}></i>
                                                                        </ActionLink>
                                                                    </div>
                                                                </td>
                                                                <td align="right">{typeEmails.length}</td>
                                                                <td align="right">{formatPercentageCell(typeEmails.filter((t) => !t.failed).length, typeEmails.length)}</td>
                                                                <td align="right">{formatPercentageCell(typeEmails.filter((t) => t.failed).length, typeEmails.length)}</td>
                                                                <td align="right">{formatPercentageCell(opened.length, typeEmails.length)}</td>
                                                                <td align="right">{formatPercentageCell(clicked.length, typeEmails.length)}</td>
                                                                <td align="right">{formatPercentageCell(subAtSend.length, typeEmails.length)}</td>
                                                                <td align="right">{formatPercentageCell(subNow.length, typeEmails.length)}</td>
                                                            </tr>

                                                            {isExpanded && (
                                                                <>
                                                                    <tr className="tr-nested">
                                                                        <td colSpan={6}>
                                                                            <b>Event</b>
                                                                        </td>
                                                                        <td>
                                                                            <b>Count</b>
                                                                        </td>
                                                                        <td>
                                                                            <b>Percentage</b>
                                                                        </td>
                                                                    </tr>
                                                                    {uniqueActions
                                                                        .filter((a) => !a.toLowerCase().includes("payment success"))
                                                                        .map((u) => {
                                                                            const emailActionFiltered = typeEmails.filter((t) => t.history.find((h) => h.type === u));
                                                                            return (
                                                                                <tr className="tr-nested">
                                                                                    <td colSpan={6}>{u}</td>
                                                                                    <td>{emailActionFiltered.length}</td>
                                                                                    <td>{emailActionFiltered.length > 0 ? Math.round((emailActionFiltered.length / typeEmails.length) * 100) : "0"}%</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    {uniqueActions
                                                                        .filter((a) => a.toLowerCase().includes("payment success"))
                                                                        .map((u) => {
                                                                            const emailActionFiltered = typeEmails.filter((t) => t.history.find((h) => h.type === u));
                                                                            return (
                                                                                <tr className="tr-nested">
                                                                                    <td colSpan={6}>
                                                                                        <b>{u}</b>
                                                                                    </td>
                                                                                    <td>{emailActionFiltered.length}</td>
                                                                                    <td>{emailActionFiltered.length > 0 ? Math.round((emailActionFiltered.length / typeEmails.length) * 100) : "0"}%</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                </>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>Totals</td>
                                                    <td align="right">{emails.length}</td>
                                                    <td align="right">{formatPercentageCell(emails.filter((t) => !t.failed).length, emails.length)}</td>
                                                    <td align="right">{formatPercentageCell(emails.filter((t) => t.failed).length, emails.length)}</td>
                                                    <td align="right">{formatPercentageCell(emails.filter((t) => t.history.find((h) => h.type === "Email opened by user")).length, emails.length)}</td>
                                                    <td align="right">{formatPercentageCell(emails.filter((t) => t.history.find((h) => h.type === "User clicked on link in email")).length, emails.length)}</td>
                                                    <td align="right">{formatPercentageCell(emails.filter((t) => t.hadSubscriptionAtSending).length, emails.length)}</td>
                                                    <td align="right">{formatPercentageCell(emails.filter((t) => t.hasSubscription).length, emails.length)}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </GridCell>
                            </Grid>
                        )}
                        <div div className="h3 u-mb-md u-mt-sm">
                            Sent emails
                        </div>
                        <SortableTable data={emails} defaultSortCriteria={{ fieldName: "createdDate", fieldType: "date", desc: true }} pageSize={50}>
                            {({ data, sortCriteria, handleSort }) => {
                                return (
                                    <table>
                                        <thead>
                                            <tr>
                                                <SortableTableHeader label="Date" fieldName="createdDate" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                <SortableTableHeader label="Type" fieldName="emailType" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="To" fieldName="toEmail" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="From" fieldName="fromEmail" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Failed" fieldName="failed" sortCriteria={sortCriteria} handleSort={handleSort} bool />
                                                <th>
                                                    <div className="u-pt-xs">Admin links</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((email) => {
                                                const isExpanded = email.id === emailId;
                                                const { history = [] } = email;
                                                return (
                                                    <>
                                                        <tr key={email.id} className={email.failed ? "u-font-red u-font-600 hover" : "hover"}>
                                                            <td valign="top">
                                                                <div className="u-flex u-flexJustifyBetween">
                                                                    <span>{utils.dateLong(email.createdDate, "-")}</span>
                                                                    <ActionLink className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween" onClick={() => setEmailId(isExpanded ? false : email.id)}>
                                                                        <i className={`icon icon-arrow-${isExpanded ? "up" : "down"}`}></i>
                                                                    </ActionLink>
                                                                </div>
                                                            </td>
                                                            <td valign="top">{email.emailType}</td>
                                                            <td valign="top">{email.toEmail}</td>
                                                            <td valign="top">{email.fromEmail}</td>
                                                            <td valign="top">{email.failed ? "Yes" : ""}</td>
                                                            <td>
                                                                {email.emailType === "ActivateUserEmail" && email.linkToken && (
                                                                    <a href={`${envUrl}/#/pub/activate/${email.linkToken}`} target="_blank" rel="noreferrer">
                                                                        Validate email link
                                                                    </a>
                                                                )}
                                                                {email.emailType === "ForgottonPasswordEmail" && email.linkToken && (
                                                                    <a href={`${envUrl}/#/pub/resetPassword/${email.linkToken}`} target="_blank" rel="noreferrer">
                                                                        Forgot password link
                                                                    </a>
                                                                )}
                                                                {userInvitations.includes(email.emailType) && email.invitationId && (
                                                                    <a href={`${envUrl}/#/pub/userInvitations/${email.invitationId}`} target="_blank" rel="noreferrer">
                                                                        Invitation link
                                                                    </a>
                                                                )}
                                                                {farmAccessInvitations.includes(email.emailType) && email.invitationId && (
                                                                    <a href={`${envUrl}/#/pub/farmAccessInvitations/${email.invitationId}`} target="_blank" rel="noreferrer">
                                                                        Farm access link
                                                                    </a>
                                                                )}
                                                            </td>
                                                        </tr>

                                                        {isExpanded && (
                                                            <>
                                                                <tr className="tr-nested">
                                                                    <td>
                                                                        <b>Date</b>
                                                                    </td>
                                                                    <td>
                                                                        <b>Source</b>
                                                                    </td>
                                                                    <td>
                                                                        <b>Type</b>
                                                                    </td>
                                                                    <td colSpan={3}>
                                                                        <b>Message</b>
                                                                    </td>
                                                                </tr>
                                                                {history.map((h) => {
                                                                    return (
                                                                        <tr className="tr-nested">
                                                                            <td>{utils.dateLong(h.logDate, "-")}</td>
                                                                            <td>{h.statusCode}</td>
                                                                            <td>{h.type}</td>
                                                                            <td colSpan={3}>{h.message}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                );
                            }}
                        </SortableTable>
                    </>
                )}
            </div>
        </div>
    );
};

export default compose(withAppContext, withActions({ findEmails }))(Emails);
