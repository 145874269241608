import { categoriseMessage } from "./TabMessages";

export default function TabAlertIcon({ analysis, category }) {
    const hasMessages = analysis?.messages?.length > 0;
    if (!hasMessages) {
        return null;
    }

    const errorMessage = analysis.messages.find((m) => categoriseMessage(m, category) && m.severity === "Error");
    if (errorMessage) {
        return (
            <span className={`Todo Todo--error`}>
                <i className="icon icon-alert" />
            </span>
        )
    }

    const message = analysis.messages.find((m) => categoriseMessage(m, category));
    if (!message) {
        return null;
    }

    if (analysis.suppressedMessageCodes.includes(message.code)) {
        return null;
    }

    const severity = message.severity === "Error" ? "error" : "warning";
    return (
        <span className={`Todo Todo--${severity}`}>
            <i className="icon icon-alert" />
        </span>
    )
}
