import React from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import { useIsPaidVersionOfOverseer } from "common/hooks";

export default function FarmMenu({ farm, left, onMouseOut, displayPayment }) {
    const isPaidVersionOfOverseer = useIsPaidVersionOfOverseer();
    const canManageAccess = utils.canManageAccess(farm);
    const canUpdateFarm = utils.canUpdateFarm(farm);
    const hasTrends = farm?.budget?.filter(b => b.budget.class === "Budget" && b.budget.year && b.budget.role === "Write" && b.budget.nLossPerHa)?.length >= 2;
    const viewTimeline = !displayPayment && (canUpdateFarm || utils.canCreateWorkingCopy(farm)) && farm?.budgets?.some(b => b.type === "YearEnd");

    return (
        <div className="FarmMenu" style={{ left: left + 20 }} onMouseLeave={() => onMouseOut()}>
            <div className="FarmMenu-panel">
                <ul className="Farm-actions">
                    <li>
                        <Link to={`/app/farm/${farm.id}`} className="IconLink--home u-link">
                            Farm home
                        </Link>
                    </li>
                    <li>
                        <Link to={`/app/farm/${farm.id}/analysis`} className="IconLink--compare u-link">
                            Analyses
                        </Link>
                    </li>
                    {hasTrends && (
                        <li>
                            <Link to={`/app/farm/${farm.id}/trends`} className="IconLink--stats-dots u-link">
                                Trends
                            </Link>
                        </li>
                    )}
                    {canUpdateFarm && isPaidVersionOfOverseer && (
                        <li>
                            <Link to={`/app/farm/${farm.id}/benchmarks`} className="IconLink--stats-bars u-link">
                                Benchmarks
                            </Link>
                        </li>
                    )}
                    {viewTimeline && !farm.expired && (
                        <li>
                            <Link to={`/app/farm/${farm.id}/timeline`} className="IconLink--clock u-link">
                                Farm timeline
                            </Link>
                        </li>
                    )}
                    {canUpdateFarm && isPaidVersionOfOverseer && (
                        <li>
                            <Link to={`/app/farm/${farm.id}/groups`} className="IconLink--users u-link">
                                Farm groups
                            </Link>
                        </li>
                    )}
                    {canUpdateFarm && (
                        <li>
                            <Link to={`/app/farm/${farm.id}/activity`} className="IconLink--stack u-link">
                                Farm activity
                            </Link>
                        </li>
                    )}
                    {canManageAccess && isPaidVersionOfOverseer && (
                        <li>
                            <Link to={`/app/farm/${farm.id}/access`} className="IconLink--lock u-link">
                                Farm access
                            </Link>
                        </li>
                    )}
                    {canUpdateFarm && (
                        <li>
                            <Link to={`/app/farm/${farm.id}/settings`} className="IconLink--settings u-link">
                                Farm settings
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}
