import Alert from "components/Alert";
import { useOnline } from "common/hooks";

const LIVESTOCK_DATA_REQUIRED = 8001;
const ANIMALS_NOT_ON_FARM = 8002;
const ANIMALS_NOT_DISTRIBUTED = 8003;

export default function DistributionErrors({ analysis, enterprise }) {
    const online = useOnline();

    if (analysis.messages === undefined || analysis.messages.length === 0) return null;

    const messagesByCategory = analysis.messages.filter((m) => m.category === "Animals");
    if (!messagesByCategory) return null;

    const messages = [];

    const _processMessages = (code, text) => {
        const messagesByCode = messagesByCategory.filter((m) => m.code === code);
        if (!messagesByCode.length === 0) return;

        /* eslint-disable no-unused-vars */
        for (const messageByCode of messagesByCode) {
            if (enterprise && enterprise !== messageByCode.info) continue;

            let messageText = text || messageByCode.text;

            switch (code) {
                case LIVESTOCK_DATA_REQUIRED:
                    messageText = `${messageByCode.info} livestock data is required.`;
                    break;
                case ANIMALS_NOT_ON_FARM:
                    messageText = `${messageByCode.info} - you have distributed livestock to one or more blocks when there are no livestock on your farm for those months.`;
                    break;
                case ANIMALS_NOT_DISTRIBUTED:
                    messageText = `${messageByCode.info} - there are livestock on your farm that have not been distributed to any blocks.`;
                    break;
                default:
                    break;
            }

            if (messages.find((m) => m.text === messageText)) continue;

            messages.push({
                entityType: messageByCode.entityType,
                entityId: messageByCode.entityId,
                code: code,
                severity: "error",
                text: messageText,
            });
        }
    };

    _processMessages(LIVESTOCK_DATA_REQUIRED);
    _processMessages(ANIMALS_NOT_ON_FARM);
    _processMessages(ANIMALS_NOT_DISTRIBUTED);

    if (online && messages.length > 0) {
        return (
            <div className="u-mt-md">
                {messages.map((message, index) => (
                    <Alert key={message.code + index} type={message.severity} code={message.code} text={message.text} />
                ))}
            </div>
        );
    } else {
        return null;
    }
}
