import React from "react";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import find from "lodash/find";
import reject from "lodash/reject";
import * as validations from "common/validations";
import * as normalizers from "common/normalizers";
import * as utils from "common/utils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Button } from "components/Button";
import BlockSelector from "./BlockSelector";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack2";
import SavePrompt from "components/SavePrompt";
import Alert from "components/Alert";
import * as domain from "common/domain";
import { useRefData, useShowQuickTips } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

const MG_PER_KG = "mg/kg or ppm";
const ASC_OPTIONS = [
    { value: "Usesystemdefault", text: "Use system default" },
    { value: "SpecifyASCorPR", text: "Specify ASC or PR" },
];
const SLOW_RELEASE_K_OPTIONS = [
    { value: "SpecifyTBKreserveK", text: "Specify TBK reserve K" },
    { value: "SpecifyKreservestatus", text: "Specify K reserve status" },
];

const _getBlocks = (soilTests) => {
    if (soilTests === undefined) return [];

    return (soilTests.applications || []).map((application) => {
        return {
            id: application.block.id,
            block: application.block,
        };
    });
};

let _sMaxValue = 100;

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function SoilTestsDetails({ farm, analysis, soilTests }) {
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const showQuickTips = useShowQuickTips();
    const refData = useRefData();

    return <SoilTestForm farm={farm} analysis={analysis} soilTests={soilTests} updateAnalysisAsync={updateAnalysisAsync} showQuickTips={showQuickTips} refData={refData} />
}

class SoilTestsDetailsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sUnitOfMeasure: MG_PER_KG,
            showASC: false,
            showTBKReserveK: false,
            showKReserveStatus: false,
            blocks: _getBlocks(props.soilTests),
            blocksChanged: false,
            submitting: false,
            submitSucceeded: false,
        };
    }

    _mapStateModelToViewModel = (stateModel) => {
        stateModel = stateModel || { nutrients: {} };

        const viewModel = {
            id: stateModel.id,
            Name: stateModel.name,
            P: stateModel.nutrients.P,
            K: stateModel.nutrients.K,
            Ca: stateModel.nutrients.Ca,
            Mg: stateModel.nutrients.Mg,
            Na: stateModel.nutrients.Na,
            S: stateModel.nutrients.S,
            SType: stateModel.sType,
            ASC: stateModel.asc,
            TBKReserveK: stateModel.tbkReserveK,
            KReserveStatusOption: stateModel.kReserveStatusOption,
        };

        if (viewModel.ASC) viewModel.ASCOption = ASC_OPTIONS[1].value;
        else viewModel.ASCOption = ASC_OPTIONS[0].value;

        if (viewModel.TBKReserveK) viewModel.SlowReleaseKOption = SLOW_RELEASE_K_OPTIONS[0].value;
        else if (!viewModel.KReserveStatusOption || viewModel.KReserveStatusOption === "Usedefault") viewModel.SlowReleaseKOption = SLOW_RELEASE_K_OPTIONS[0].value;
        else viewModel.SlowReleaseKOption = SLOW_RELEASE_K_OPTIONS[1].value;

        return viewModel;
    };

    _mapViewModelToStateModel = (viewModel) => {
        const stateModel = {
            ...this.props.soilTests,
            id: viewModel.id,
            name: viewModel.Name,
            defaultTest: false,
            applications: this.state.blocks,
            nutrients: {
                S: utils.round(viewModel.S, 0) || undefined,
            },
            sType: viewModel.SType,
            asc: viewModel.ASC && utils.round(viewModel.ASC, 0),
            tbkReserveK: viewModel.SlowReleaseKOption === SLOW_RELEASE_K_OPTIONS[0].value && viewModel.TBKReserveK ? utils.round(viewModel.TBKReserveK, 2) : null,
            kReserveStatusOption: viewModel.SlowReleaseKOption === SLOW_RELEASE_K_OPTIONS[1].value ? viewModel.KReserveStatusOption : null,
        };
        const isForCropBlocks = stateModel.applications.some((app) => app.block.type === "ProductiveCrop");
        if (!isForCropBlocks) {
            stateModel.nutrients = {
                ...stateModel.nutrients,
                P: utils.round(viewModel.P, 0) || 0,
                K: utils.round(viewModel.K, 0) || 0,
                Ca: utils.round(viewModel.Ca, 0) || 0,
                Mg: utils.round(viewModel.Mg, 0) || 0,
                Na: utils.round(viewModel.Na, 0) || 0,
            };
        }
        return stateModel;
    };

    _getAvailableBlocks = (analysis, hasCropBlockSelected, nonCropBlockSelected) => {
        let types = [];
        if (hasCropBlockSelected) types = [domain.BlockType.ProductiveCrop];
        else if (nonCropBlockSelected) types = [domain.BlockType.ProductivePasture, domain.BlockType.ProductiveFruit, domain.BlockType.ProductiveOutdoorPigs];
        else types = [domain.BlockType.ProductivePasture, domain.BlockType.ProductiveFruit, domain.BlockType.ProductiveCrop, domain.BlockType.ProductiveOutdoorPigs];

        const blocks = analysis.blocks.filter((b) => types.includes(b.type));

        return blocks.filter((block) => !block.soilTestId || !analysis.soilTests.some((t) => !t.defaultTest && t.id === block.soilTestId));
    };

    _selectBlock = (blockId) => {
        const block = find(this.props.analysis.blocks, { id: blockId });
        const selectedBlock = {
            id: block.id,
            block: block,
        };
        this.setState({ blocks: this.state.blocks.concat(selectedBlock), blocksChanged: true });
    };

    _deselectBlock = (blockId) => {
        this.setState({ blocks: reject(this.state.blocks, { id: blockId }), blocksChanged: true });
    };

    _sTypeChanged = (e) => {
        const sType = e.target.selectedOptions && e.target.selectedOptions.length > 0 ? e.target.selectedOptions[0].value : "";

        this._toggleSMax(sType);
    };

    _ascOptionChanged = (e) => {
        this._toggleASCFields(e.target.value);
    };

    _toggleASCFields = (ascOption) => {
        switch (ascOption) {
            case ASC_OPTIONS[1].value:
                this.setState({ showASC: true });
                break;
            case ASC_OPTIONS[0].value:
            default:
                this.setState({ showASC: false });
                this.props.change("ASC", null);
                break;
        }
    };

    _slowReleaseKOptionChanged = (e) => {
        this._toggleSlowReleaseKFields(e.target.value);
    };

    _toggleSlowReleaseKFields = (slowReleaseKOption) => {
        switch (slowReleaseKOption) {
            case SLOW_RELEASE_K_OPTIONS[0].value:
                this.setState({ showTBKReserveK: true, showKReserveStatus: false });
                this.props.change("KReserveStatusOption", null);
                break;
            case SLOW_RELEASE_K_OPTIONS[1].value:
                this.setState({ showTBKReserveK: false, showKReserveStatus: true });
                this.props.change("TBKReserveK", null);
                this.props.change("KReserveStatusOption", "High");
                break;
            default:
                this.setState({ showTBKReserveK: false, showKReserveStatus: false });
                this.props.change("TBKReserveK", null);
                this.props.change("KReserveStatusOption", null);
                break;
        }
    };

    _toggleSMax = (sType) => {
        if (sType === "QTSO4") {
            this.setState({ sUnitOfMeasure: null });
            _sMaxValue = 100;
        } else if (sType === "Total") {
            this.setState({ sUnitOfMeasure: MG_PER_KG });
            _sMaxValue = 2000;
        } else {
            this.setState({ sUnitOfMeasure: MG_PER_KG });
            _sMaxValue = 100;
        }
        this.props.touch("S");
    };

    _sRangeValidator = (value) => {
        return validations.range(1, _sMaxValue)(value);
    };

    _save = async (formValues) => {
        if (this.state.blocks.length === 0) {
            throw new SubmissionError({ BlockSelector: "Required" });
        }

        this.setState({ submitting: true, submitSucceeded: false });

        const soilTests = this._mapViewModelToStateModel(formValues);

        const analysis = this.props.analysis;
        if (!analysis.soilTests) {
            analysis.soilTests = [];
        }
        if (analysis.soilTests.some(st => st.id === soilTests.id)) {
            analysis.soilTests = analysis.soilTests.map(st => st.id === soilTests.id ? soilTests : st);
        } else {
            analysis.soilTests.push(soilTests);
        }
        analysis.blocks = analysis.blocks.map(block => {
            return {
                ...block,
                soilTestId: this.state.blocks.some(b => b.id === block.id) ? soilTests.id : block.soilTestId === soilTests.id ? null : block.soilTestId
            }
        });
        await this.props.updateAnalysisAsync(analysis);

        this.setState({ submitting: false, submitSucceeded: true });
    };

    componentDidMount() {
        const viewModel = this._mapStateModelToViewModel(this.props.soilTests);
        this._toggleSMax(viewModel.SType);
        this._toggleASCFields(viewModel.ASCOption);
        this._toggleSlowReleaseKFields(viewModel.SlowReleaseKOption);
        this.props.initialize(viewModel);
    }

    render() {
        const {
            farm,
            analysis,
            soilTests,
            handleSubmit,
            dirty,
            showQuickTips,
            refData: { soilTestSTypes, kReserveStatuses },
        } = this.props;
        const _referrer = `/app/farm/${farm.id}/analysis/${analysis.id}/soil`;
        const hasCropBlockSelected = this.state.blocks.find((b) => b.block.type === "ProductiveCrop");
        const nonCropBlockSelected = this.state.blocks.find((b) => b.block.type !== "ProductiveCrop");

        const _availableBlocks = this._getAvailableBlocks(analysis, hasCropBlockSelected, nonCropBlockSelected);

        const updatedSTypes = hasCropBlockSelected ? soilTestSTypes.filter((t) => t.value !== "QTSO4") : soilTestSTypes;

        return (
            <form onSubmit={handleSubmit(this._save)}>
                <SavePrompt blockIf={(dirty || this.state.blocksChanged) && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={_referrer} />
                <Tile title={soilTests ? "Edit soil tests" : "Add soil tests"} waiting={this.state.submitting} referrer={_referrer}>
                    <Alert type="info" text="Where multiple blocks have similar nutrient results, select each block and enter the average results below. Unless capital fertiliser dressings have been applied, it is recommended that an average value over time is used. A consultant can recommend an appropriate soil testing protocol." />
                    {soilTests.defaultTest && <Alert type="info" text="This test has been generated by Overseer based on the block details and soil. If you save this test it will no longer be default and so will not be regenerated." />}
                    <TileBody>
                        <Field name="id" type="hidden" component="input" />
                        <div className="Grid Grid--withGutter">
                            <div className="Grid-cell">
                                <h3 className="u-mt-sm">Blocks</h3>
                            </div>
                            <div className="Grid-cell">
                                <Field name="BlockSelector" id="BlockSelector" label="Select the blocks that were tested" blocks={_availableBlocks} selectedBlocks={this.state.blocks.map((b) => b.block)} selectBlock={this._selectBlock} deselectBlock={this._deselectBlock} component={BlockSelector} />
                            </div>
                            <div className="Grid-cell u-mt-lg">
                                <div className="Grid Grid--withGutter">
                                    <div className="Grid-cell">
                                        <h3 className="u-mt-0">Soil test details</h3>
                                        {hasCropBlockSelected && nonCropBlockSelected && (
                                            <Alert
                                                type="info"
                                                text={
                                                    <React.Fragment>
                                                        <b>Crop block soil tests:</b> <span id="typical_yield">The Olsen P, QT K, QT Ca, QT Mg and QT Na values for crop blocks are managed via the sown event for individual crops on the pasture/crops screen. Values entered below will only apply to the non crop blocks selected.</span>
                                                    </React.Fragment>
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                        <Field name="Name" label="Reference" placeholder="Enter an identifier for these test results" type="text" requiredLabel={true} component={InputPack} validate={[validations.required, validations.maxLength(200)]} />
                                    </div>
                                </div>
                                {nonCropBlockSelected && (
                                    <div className="Grid Grid--withGutter">
                                        <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                            <Field name="P" label="Olsen P" placeholder="0" type="text" normalize={normalizers.numeric} requiredLabel={true} component={InputPack} validate={[validations.required, validations.range(1, 200)]} />
                                        </div>
                                        <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                            <Field name="K" label="QT K" placeholder="0" type="text" normalize={normalizers.numeric} requiredLabel={true} component={InputPack} validate={[validations.required, validations.range(1, 100)]} />
                                        </div>
                                        <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                            <Field name="Ca" label="QT Ca" placeholder="0" type="text" normalize={normalizers.numeric} requiredLabel={true} component={InputPack} validate={[validations.required, validations.range(1, 100)]} />
                                        </div>
                                        <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                            <Field name="Mg" label="QT Mg" placeholder="0" type="text" normalize={normalizers.numeric} requiredLabel={true} component={InputPack} validate={[validations.required, validations.range(1, 150)]} />
                                        </div>
                                        <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                            <Field name="Na" label="QT Na" placeholder="0" type="text" normalize={normalizers.numeric} requiredLabel={true} component={InputPack} validate={[validations.required, validations.range(1, 100)]} />
                                        </div>
                                    </div>
                                )}
                                <div className="Grid Grid--withGutter">
                                    <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                        <Field name="SType" label="S" options={updatedSTypes} onChange={this._sTypeChanged} component={SelectPack} />
                                    </div>
                                    <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                        <Field name="S" uom={this.state.sUnitOfMeasure} placeholder="0" type="text" normalize={normalizers.numeric} requiredLabel={!hasCropBlockSelected} component={InputPack} validate={hasCropBlockSelected ? [this._sRangeValidator] : [validations.required, this._sRangeValidator]} />
                                        {showQuickTips && !this.state.sUnitOfMeasure && (
                                            <Alert
                                                type="help"
                                                text={
                                                    <div>
                                                        In Overseer, QT SO4 is only used to give an estimate of the organic S test. Note that the QT SO4 is also used in making fertiliser recommendations, which is not considered here.
                                                        <br />
                                                        If QT SO4 is used, it is preferrable that no sulphate fertiliser has been used within 6 months of soil testing, and soil testing has not been preceded by high rainfall.
                                                    </div>
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="Grid Grid--withGutter u-mt-md">
                                    <div className="Grid-cell">
                                        <h3>Override defaults for anion storage capacity (ASC) or phosphate retention (PR)</h3>
                                    </div>
                                    <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                        <Field name="ASC" label="ASC or PR" uom="%" placeholder={soilTests.defaultASC} type="text" normalize={normalizers.numeric} requiredLabel={false} component={InputPack} validate={[validations.range(0, 100)]} />
                                    </div>
                                </div>
                                <div className="Grid Grid--withGutter u-mt-md">
                                    <div className="Grid-cell">
                                        <h3>Override defaults for slow release K</h3>
                                        {showQuickTips && (
                                            <Alert
                                                type="help"
                                                className="u-mb-0"
                                                text={
                                                    <div>
                                                        When estimating the slow release K status, the model uses either the entered TBK reserve K, the selected K reserve status, or a soil type or soil order based default value (in that order).
                                                        <br />
                                                        For sedimentary or brown soils, it is recommended that TBK reserve K is entered or a K reserve status is selected due to the high variability of the slow release K status within these groups.
                                                    </div>
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                        <Field name="SlowReleaseKOption" label="Slow release K option" options={SLOW_RELEASE_K_OPTIONS} onChange={this._slowReleaseKOptionChanged} component={SelectPack} />
                                    </div>
                                    <div className="Grid-cell u-md-width1of2 u-lg-width1of2 u-xl-width1of5">
                                        {this.state.showTBKReserveK && <Field name="TBKReserveK" label="TBK reserve K" uom="me/100g" placeholder={soilTests.defaultTBKReserveK} type="text" normalize={normalizers.numeric} requiredLabel={false} component={InputPack} validate={[validations.range(0.01, 20)]} />}
                                        {this.state.showKReserveStatus && <Field name="KReserveStatusOption" label="K reserve status" placeholder="Select a K reserve status" options={kReserveStatuses} required={true} component={SelectPack} validate={[validations.required]} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TileBody>
                    <TileFooter>
                        <div className="ButtonBar ButtonBar--fixed">
                            <div className="ButtonBar-left">
                                <Link to={_referrer} className="Button Button--secondary" id="cancel-button">
                                    Cancel
                                </Link>
                            </div>
                            <div className="ButtonBar-right">
                                <Button id="submit-button" submit primary waiting={this.state.submitting}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </TileFooter>
                </Tile>
            </form>
        );
    }
}

const SoilTestForm = reduxForm({
    form: "soil-tests-form",
})(SoilTestsDetailsComponent);

