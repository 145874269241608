import React, { useState } from "react";
import * as utils from "common/utils";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";

const SortableTable = ({ defaultSortCriteria = { desc: false }, data = [], children, pageSize = 0, hideTopPager = false }) => {
    const [sortCriteria, setSortCriteria] = useState(defaultSortCriteria);
    const [page, setPage] = useState(1);

    const handleSort = (fieldName, fieldType) => (e) => {
        if (sortCriteria.fieldName === fieldName) {
            setSortCriteria({
                ...sortCriteria,
                desc: !sortCriteria.desc,
            });
            setPage(1);
        } else {
            setSortCriteria({
                desc: false,
                fieldName,
                fieldType,
            });
            setPage(1);
        }
    };

    const compareString = (sortBy, desc) => (a, b) => {
        const fieldA = a[sortBy];
        const fieldB = b[sortBy];
        const stringA = fieldA ? fieldA.toUpperCase() : "";
        const stringB = fieldB ? fieldB.toUpperCase() : "";
        if (stringA < stringB) return desc ? 1 : -1;
        if (stringA > stringB) return desc ? -1 : 1;
        return 0;
    };

    const compareNumber = (sortBy, desc) => (a, b) => {
        const fieldA = a[sortBy];
        const fieldB = b[sortBy];
        const numberA = fieldA ? Number(fieldA) : 0;
        const numberB = fieldB ? Number(fieldB) : 0;
        if (numberA < numberB) return desc ? 1 : -1;
        if (numberA > numberB) return desc ? -1 : 1;
        return 0;
    };

    const compareBool = (sortBy, desc) => (a, b) => {
        const fieldA = a[sortBy];
        const fieldB = b[sortBy];
        if (fieldA < fieldB) return desc ? 1 : -1;
        if (fieldA > fieldB) return desc ? -1 : 1;
        return 0;
    };

    const changeCurrentPage = (numPage) => {
        setPage(numPage);
    };

    let dataCopy = utils.clone(data);
    const { fieldName, fieldType = "string", desc = false } = sortCriteria;
    switch (fieldType) {
        case "number":
            dataCopy.sort(compareNumber(fieldName, desc));
            break;
        case "date":
            dataCopy.sort(utils.compareDate(fieldName, desc));
            break;
        case "bool":
            dataCopy.sort(compareBool(fieldName, desc));
            break;
        case "string":
        default:
            dataCopy.sort(compareString(fieldName, desc));
            break;
    }
    const recordCount = dataCopy.length;
    const pagingEnabled = recordCount > pageSize && pageSize > 0;
    if (pagingEnabled) {
        const startIndx = pageSize * (page - 1);
        dataCopy = dataCopy.slice(startIndx, startIndx + pageSize);
    }

    const pager = <Pagination currentPage={page} theme="bootstrap" totalSize={recordCount} sizePerPage={pageSize} changeCurrentPage={changeCurrentPage} />;

    return (
        <>
            {pagingEnabled && !hideTopPager && (
                <div className="u-flex u-flexJustifyEnd">
                    <div className="u-pb-md">{pager}</div>
                </div>
            )}
            <div className="Table">
                {children({
                    sortCriteria: sortCriteria,
                    handleSort: handleSort,
                    data: dataCopy,
                })}
            </div>
            {pagingEnabled && (
                <div className="u-flex u-flexJustifyEnd">
                    <div className="u-pt-md">{pager}</div>
                </div>
            )}
        </>
    );
};

export default SortableTable;
