import React from "react";

const PageHead = ({ className, children }) => {
    return (
        <section className={`PageHead ${className ? className : ""}`}>
            <div className="Container">{children}</div>
        </section>
    );
};
export default PageHead;
