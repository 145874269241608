import React from "react";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileBodyMessage from "components/TileBodyMessage";
import { Link } from "react-router-dom";

const NotFound = () => (
    <Tile>
        <TileBody>
            <TileBodyMessage>
                <p className="lead">Not found</p>
                <p>The item requested was not found. Please navigate back to the dashboard</p>
                <Link id="dashboard" className="Button Button--lg u-mt-lg" to="/">
                    Return to dashboard
                </Link>
            </TileBodyMessage>
        </TileBody>
    </Tile>
);

export default NotFound;
