import React from "react";
import numeral from "numeral";
import { withActions } from "containers/HOC";
import { Field, change, touch } from "redux-form";
import * as validations from "common/validations";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack2";
import * as _utils from "containers/BudgetHome/Animals/_utils";
import Button from "components/Button/Button";

const OtherLivestockList = (props) => {
    const { fields, onAddOrRemove, change, touch } = props;

    const _hasLivestock = (fields || []).length > 0;

    const _addLivestock = () => {
        fields.push({});
        onAddOrRemove(fields.length + 1);
    };

    const _removeLivestock = (index) => {
        fields.remove(index);
        onAddOrRemove(fields.length - 1);
    };

    const _onLivestockTypeChanged = (newValue, index) => {
        change("enterprise-details-form", `otherLivestocks[${index}].stockClass`, null);
    };

    const _onStockClassChanged = (newValue, index) => {
        const stockClass = _utils.getOtherLivestockStockClass(newValue);
        if (stockClass) change("enterprise-details-form", `otherLivestocks[${index}].rsu`, stockClass.rsu);
    };

    const _formatRSU = (e, newValue, index) => {
        if (!newValue) return;

        e.preventDefault();
        change("enterprise-details-form", `otherLivestocks[${index}].rsu`, numeral(newValue).format("0.0"));
        touch("enterprise-details-form", `otherLivestocks[${index}].rsu`);
    };

    const _formatNumber = (e, newValue, index) => {
        if (!newValue) return;

        e.preventDefault();
        change("enterprise-details-form", `otherLivestocks[${index}].number`, numeral(newValue).format("0"));
        touch("enterprise-details-form", `otherLivestocks[${index}].number`);
    };

    return (
        <Tile
            title="Livestock"
            actions={
                <span id="add-livestock-link" className="u-link IconLink--arrow-plus" onClick={_addLivestock}>
                    Add livestock
                </span>
            }
            tertiary
        >
            <TileBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th data-width="25">Type</th>
                                <th data-width="45">Stock class</th>
                                <th data-width="10">RSU</th>
                                <th data-width="10">Number of animals</th>
                                {_hasLivestock && <th className="th--shrink">&nbsp;</th>}
                            </tr>
                        </thead>
                        {!_hasLivestock && (
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                        <div className="Tile-body">
                                            <div className="Tile-body-message">
                                                <p className="h4 u-mt-0">You do not have any livestock</p>
                                                <Button id="add-livestock-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={_addLivestock}>
                                                    Add livestock
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {_hasLivestock && (
                            <tbody>
                                {fields.map((field, index) => {
                                    const livestockTypeOptions = _utils.getOtherLivestockTypeOptions(fields.getAll());
                                    const livestock = fields.get(index);
                                    const stockClassOptions = _utils.getOtherLivestockStockClassOptions(livestock.type);
                                    const hasStockClassOptions = stockClassOptions.length > 0;
                                    const isCustomStockClass = _utils.isCustomOtherLivestockStockClass(livestock);
                                    if (livestock.stockClass === "UserDefined") {
                                        livestock.stockClass = "";
                                    }

                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Field name={`${field}.type`} component={SelectPack} options={livestockTypeOptions} placeholder="Select a type" validate={[validations.required]} onChange={(e, newValue) => _onLivestockTypeChanged(newValue, index)} />
                                            </td>
                                            <td>
                                                {!isCustomStockClass && <Field name={`${field}.stockClass`} component={SelectPack} options={stockClassOptions} placeholder={hasStockClassOptions ? "Select a stock class" : undefined} validate={[validations.required]} disabled={!hasStockClassOptions} onChange={(e, newValue) => _onStockClassChanged(newValue, index)} />}
                                                {isCustomStockClass && <Field name={`${field}.stockClass`} type="text" component={InputPack} noLabel={true} placeholder="Enter a stock class name" validate={[validations.required, validations.maxLength(100)]} />}
                                            </td>
                                            <td>
                                                <Field name={`${field}.rsu`} type="text" component={InputPack} noLabel={true} placeholder="0.0" validate={[validations.required, validations.range(0.1, 15)]} disabled={!isCustomStockClass} onBlur={(e, newValue) => _formatRSU(e, newValue, index)} />
                                            </td>
                                            <td>
                                                <Field name={`${field}.number`} type="text" component={InputPack} noLabel={true} placeholder="0" validate={[validations.required, validations.range(1, 999999)]} onBlur={(e, newValue) => _formatNumber(e, newValue, index)} />
                                            </td>
                                            <td>
                                                <span id={`delete-livestock-${index}`} className="a u-link IconLink--cross-circle" onClick={() => _removeLivestock(index)}></span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </table>
                </div>
            </TileBody>
        </Tile>
    );
};

export default withActions({ change, touch })(OtherLivestockList);
