import React from "react";
import SelectPack from "components/SelectPack";
import * as utils from "common/utils";
import * as domain from "common/domain";
import * as validations from "common/validations";
import LiquidApplications from "./LiquidApplications";
import { useRefData } from "common/hooks";

export default function LiquidEffluent({ liquidEffluent, validation, onChange, type, activeControls, cancelApplication, saveApplication, modalInlineClose, modalInlineOpen, confirm, deleteApplication, analysis, isFirst, validate, isSystem }) {
    const refData = useRefData();

    const { holdingPondManagement = [], effluentAppRates = [] } = refData;
    const holdingPondManagementTip = liquidEffluent.liquidEffluentDisposal && domain.HoldingPondManagementTips.find((t) => t.key === liquidEffluent.liquidEffluentDisposal);

    if (!activeControls[`${type}_liquidHeader`]) return null;

    return (
        <div className={`Grid Grid--withGutter ${isFirst ? "" : "u-mt-lg"}`}>
            <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                <h3>Liquid effluent</h3>
                <SelectPack isHidden={!activeControls[`${type}_liquidEffluentDisposal`]} meta={{ nrf: true }} onChange={(e) => onChange(e, { type: type, key: "liquidEffluentDisposal" })} value={liquidEffluent.liquidEffluentDisposal} val={validation[`${type}_liquidEffluentDisposal`]} id={`${type}_liquidEffluentDisposal`} label="Liquid effluent management" tip={holdingPondManagementTip && holdingPondManagementTip.text} requiredLabel={true}>
                    <option value="" disabled={true}>
                        Select how liquid effluent is managed
                    </option>
                    {utils.mapRefDataItems(holdingPondManagement)}
                </SelectPack>
            </div>
            {activeControls[`${type}_liquidApplications`] && (
                <div className="Grid-cell">
                    <LiquidApplications validation={validation} cancelApplication={cancelApplication} saveApplication={(application, dirty) => saveApplication(application, dirty)} confirm={confirm} deleteApplication={(id) => deleteApplication(id)} onChange={onChange} modalInlineClose={modalInlineClose} modalInlineOpen={modalInlineOpen} effluentAppRates={effluentAppRates} analysis={analysis} isSystem={isSystem} liquidEffluentDisposal={liquidEffluent.liquidEffluentDisposal} blocks={analysis.blocks.filter((b) => b.type !== "ProductiveOutdoorPigs" && (b.type.startsWith("Productive") || b.type === "FodderCrop"))} applications={liquidEffluent.applications || []} validate={validate} type={type} />
                </div>
            )}
        </div>
    )
}

export function validateLiquidEffluent(activeControls, validation, liquidEffluent, currentValidation, source, setVal, type, analysis) {
    let message = undefined;
    let key = undefined;
    let objkey = undefined;

    objkey = "liquidEffluentDisposal";
    key = `${type}_${objkey}`;
    if (activeControls[key]) {
        message = validations.required(liquidEffluent[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }

    objkey = "liquidApplications";
    key = `${type}_${objkey}`;
    if (activeControls[key]) {
        const { applications = [] } = liquidEffluent;
        /* eslint-disable no-unused-vars */
        for (const application of applications) {
            const blocks = application.blockIds.map((blockId) => analysis.blocks.find((b) => b.id === blockId));
            const cropTypes = ["FodderCrop", "ProductiveCrop"];
            const monthsReq = blocks.some((b) => cropTypes.includes(b.type));
            validateLiquidApplication(validation, application, currentValidation, source, setVal, type, monthsReq);
        }

        if (applications.length === 0) {
            key = `${type}_applications`;
            validation[key] = setVal(key, currentValidation, source, "Required");
        }
    }
}

export function validateLiquidApplication(validation, application, currentValidation, source, setVal, type, monthsReq) {
    let error = undefined;
    const val = {};

    let key = `${type}_blockIds`;
    val.blockIds = validations.required(application.blockIds);
    validation[key] = setVal(key, currentValidation, source, val.blockIds);
    error = val.blockIds;

    key = `${type}_applicationDepth`;
    val.applicationDepth = validations.required(application.applicationDepth);
    validation[key] = setVal(key, currentValidation, source, val.applicationDepth);
    error = error || val.applicationDepth;

    key = `${type}_areaEffluent`;
    val.areaEffluent = validations.required(application.areaEffluent);
    val.areaEffluent = val.areaEffluent || validations.isNumeric(application.areaEffluent);
    val.areaEffluent = val.areaEffluent || validations.range(0.1, 100)(application.areaEffluent);
    validation[key] = setVal(key, currentValidation, source, val.areaEffluent);
    error = error || val.areaEffluent;

    key = `monthGroupVal_${application.id}`;
    val.months = !monthsReq || (application.months || []).length > 0 ? undefined : "Required";
    validation[key] = setVal(key, currentValidation, source, val.months);
    error = error || val.months;

    return error === undefined;
}
