import { forwardRef, useRef } from "react";
import { httpClient } from "common/httpClient";

function ZendeskLink({ children, title, url, className }, ref) {
    const formRef = useRef(null);
    const jwtRef = useRef(null);
    const returnToRef = useRef(null);
    const handleClick = async (e) => {
        if (process.env.REACT_APP_ZENDESK_JWT === "true") {
            e.preventDefault();
            const url = e.currentTarget.href;
            const zendeskToken = await httpClient.get(`accounts/zendesktoken`);
            jwtRef.current.value = zendeskToken.token;
            returnToRef.current.value = encodeURI(url);
            formRef.current.submit();
        }
    };
    return (
        <>
            <a href={url} onClick={handleClick} className={className} ref={ref} target="_blank" rel="noopener noreferrer">
                {children || title || url}
            </a>
            <form ref={formRef} action={`https://overseer-helpdesk.zendesk.com/access/jwt`} method="post" target="_blank">
                <input ref={jwtRef} type="hidden" name="jwt"></input>
                <input ref={returnToRef} type="hidden" name="return_to"></input>
            </form>
        </>
    );
}
export default forwardRef(ZendeskLink);