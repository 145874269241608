import React, { useEffect } from "react";
import { withAppContext } from "containers/HOC";
import ActionLink from "components/ActionLink";
import Spinner from "components/Spinner/Spinner";
import Alert from "components/Alert";

const Modal = ({ title, children, close, waiting, submitting, child, toggleQuickTips, showQuickTips, full, wide, skinny, fluid, allowOffline, offline, style, notification, instructionText, offlineDisplay }) => {
    useEffect(() => {
        function closeOnEscape(e) {
            if (e.keyCode === 27 && close) {
                e.preventDefault();
                close();
            }
        }
        document.addEventListener("keydown", closeOnEscape);
        return () => document.removeEventListener("keydown", closeOnEscape);
    });

    let size = "Modal--medium";
    if (skinny) size = "Modal--skinny";
    if (full) size = "Modal--full";
    if (notification) size = "Modal--notification";
    if (wide) size = "";

    return (
        <div className={`Modal2-wrapper  ${waiting || submitting ? "waiting" : ""} ${child ? "child" : ""} ${fluid ? "Modal2--fluid" : ""} ${offline && !allowOffline ? "offline" : ""}`}>
            <div className={`Modal2 ${size}`} style={style}>
                {!notification && (
                    <>
                        {" "}
                        <div className="Modal2-header">
                            <div className="Modal2-header--left">
                                {title}
                                {(waiting || submitting) && <Spinner />}
                            </div>
                            <div className="Modal2-header--right">
                                <ActionLink onClick={toggleQuickTips} className="Modal2-close">
                                    <i className="icon icon-question" title={`${showQuickTips ? "Hide" : "Show"} quick tips`} />
                                </ActionLink>
                                <ActionLink onClick={close} className="Modal2-close">
                                    <i className="icon icon-cross" title="Close" />
                                </ActionLink>
                            </div>
                        </div>
                        <div className="Modal-subhead u-mr-0 u-ml-0">
                            {instructionText && !offlineDisplay && <Alert type="help" text={instructionText} />}
                            {offlineDisplay && <Alert type="error" text="Overseer is currently offline. Please try again later." />}
                        </div>
                    </>
                )}
                {children}
            </div>
        </div>
    );
};
export default withAppContext(Modal);
