import * as utils from "common/utils";
import BarChart from "components/BarChart/BarChart";
import DistributionGraphTable from "./DistributionGraphTable";
import { useBenchmarkChartData } from "./_hooks";

export default function DistributionGraphs({ benchmarkData, farm, legend, farmLookup, hideTables }) {
    const { loading, data } = useBenchmarkChartData(benchmarkData, farmLookup !== undefined);

    if (!data || data.length === 0 || loading)
        return (
            <div className="HeroPanel">
                <div className="Tile-body-message">
                    <h3 className="u-mt-md">There are no results for this period</h3>
                </div>
            </div>
        );

    return data
        .filter((chartData) => chartData.data && chartData.data.labels.length > 0)
        .map((chartData) => {
            const alert = chartData.farmValue !== undefined && chartData.outlier && `The farm value ${utils.round(chartData.farmValue, 1).toLocaleString()} ${chartData.units} is outside of the distribution range. This may indicate a problem with the analysis or you have selected an option that is not compatible with this farm.`;
            return (
                <BarChart farmLookup={farmLookup} legend={legend} key={chartData.data.title} className="u-xxl-width1of2 u-xl-width1of1" data={chartData.data} options={chartData.options} alert={alert}>
                    {!farm && chartData.statistics && !hideTables && <DistributionGraphTable stats={chartData.statistics} />}
                </BarChart>
            );
        });
}
