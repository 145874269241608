export const httpClient = {
    get: async (endpoint, timeout, contentType) => await sendAsync("GET", endpoint, null, timeout, contentType),
    post: async (endpoint, body, timeout, contentType) => await sendAsync("POST", endpoint, body, timeout, contentType),
    postBinary: async (endpoint, body, timeout, contentType) => await sendAsync("POST", endpoint, body, timeout, contentType, true),
    put: async (endpoint, body, timeout, contentType) => await sendAsync("PUT", endpoint, body, timeout, contentType),
    del: async (endpoint, body, timeout, contentType) => await sendAsync("DELETE", endpoint, body, timeout, contentType),
};

const sendAsync = async (method, endpoint, body, timeout = 30000, contentType = "application/json", isBinary = false) => {
    const options = {
        method,
        headers: {
            "Content-Type": contentType,
            "X-UI-Ver": process.env.REACT_APP_VERSION,
        },
        signal: AbortSignal.timeout(timeout),
    };

    const token = localStorage.getItem("token");
    if (token) {
        options.headers.Authorization = `Bearer ${token}`;
    }

    if (body) {
        options.body = isBinary ? body : JSON.stringify(body);
    }

    return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, options)
        .then(async (response) => {
            if (response.ok) {
                try {
                    return await response.json();
                } catch {
                    // Handle empty response.
                    return;
                }
            } else {
                let json = null;
                try {
                    json = await response.json();
                } catch (error) {
                    throw Error("Request error", { cause: response });
                }

                if (json) {
                    if (Array.isArray(json)) {
                        throw Error("Request error", { cause: { status: response.status, errors: json } });
                    } else {
                        throw Error("Request error", { cause: response });
                    }
                }
            }
        })
        .catch((error) => {
            if (error.name === "TimeoutError" || error.name === "AbortError") {
                return Promise.reject({ status: 408, message: "Request timed out" });
            } else if (error.cause) {
                const { cause: response } = error;
                if (response.errors) {
                    return Promise.reject({ status: response.status, errors: response.errors });
                } else {
                    return Promise.reject({ status: response.status, message: "Oops, something didn't quite go as expected. Please try again later." });
                }
            } else {
                return Promise.reject({ message: "Oops, something didn't quite go as expected. Please try again later." });
            }
        });
};
