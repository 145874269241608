import React from "react";
import ActionLink from "components/ActionLink";

const SectionButton = ({ sections, setSections, id, title }) => {
    return (
        <li className={`SubMenu-item ${sections[id] ? "is-active" : ""}`}>
            <ActionLink onClick={() => setSections({ [id]: true })} id={id}>
                {title}
            </ActionLink>
        </li>
    );
};

const SectionTabs = ({ sections, setSections }) => {
    return (
        <ul className="SubMenu">
            <SectionButton sections={sections} setSections={setSections} id="summary" title="Summary" />
            <SectionButton sections={sections} setSections={setSections} id="nutrients" title="Nutrients" />
            <SectionButton sections={sections} setSections={setSections} id="ghg" title="GHG" />
            <SectionButton sections={sections} setSections={setSections} id="physical" title="Climate/Soils" />
            <SectionButton sections={sections} setSections={setSections} id="blocks" title="Blocks" />
            <SectionButton sections={sections} setSections={setSections} id="wetlands" title="Drainage" />
            <SectionButton sections={sections} setSections={setSections} id="crops" title="Crops" />
            <SectionButton sections={sections} setSections={setSections} id="animals" title="Animals" />
            <SectionButton sections={sections} setSections={setSections} id="structures" title="Structures/Effluent" />
            <SectionButton sections={sections} setSections={setSections} id="feed" title="Feed" />
            <SectionButton sections={sections} setSections={setSections} id="fertiliser" title="Fertiliser" />
            <SectionButton sections={sections} setSections={setSections} id="irrigation" title="Irrigation" />
        </ul>
    );
};

export default SectionTabs;
