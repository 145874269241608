import React from "react";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import ClimateActionsBar from "./ClimateActionsBar";
import BlockList from "./BlockList";
import TabMessages from "containers/BudgetHome/TabMessages";

export default function Climate({ farm, analysis }) {
    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.CLIMATE} />
            <TileBody>
                <TabMessages farm={farm} analysis={analysis} tab={TAB.CLIMATE} />
                <ClimateActionsBar farm={farm} analysis={analysis} />
                <BlockList farm={farm} analysis={analysis} />
            </TileBody>
        </Tile>
    )
}
