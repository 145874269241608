import "url-search-params-polyfill";
import { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import * as domain from "common/domain";
import * as utils from "common/utils";
import * as validations from "common/validations";
import Alert from "components/Alert";
import SavePrompt from "components/SavePrompt";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Link, useLocation } from "react-router-dom";
import SelectPack from "components/SelectPack";
import FieldGroup from "components/FieldGroup";
import NumericInputPack from "components/NumericInputPack";
import CheckboxPack from "components/CheckboxPack"
import { Button } from "components/Button";
import SolidEffluent, { validateSolidEffluent, validateSolidApplication } from "./SolidEffluent";
import PondEffluent, { validatePondEffluent } from "./PondEffluent";
import LiquidEffluent, { validateLiquidEffluent, validateLiquidApplication } from "./LiquidEffluent";
import * as structureUtils from "./_utils";
import { useConfirm, useModalInline, useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { ForMoreInfoZendeskLink } from "components/Help";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function SystemDetails({ farm, analysis }) {
    const refData = useRefData();
    const { modalInlineOpen, modalInlineClose } = useModalInline();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const location = useLocation();

    return (
        <>
            <SystemDetailsClassComponent farm={farm} analysis={analysis} location={location} modalInlineOpen={modalInlineOpen} modalInlineClose={modalInlineClose} confirm={confirm} updateAnalysisAsync={updateAnalysisAsync} refData={refData} />
        </>
    )
}

class SystemDetailsClassComponent extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(this.props.location.search);
        const id = params.get("id");
        const { analysis } = this.props;
        const { structures = [] } = analysis;
        let structure = structures.find((s) => s.id === id);
        let effluentSystem = (structure ? structure.effluentSystem : analysis.effluentSystem) || {};

        this.state = {
            effluentSystem: utils.clone(effluentSystem),
            validation: {},
            structure: utils.clone(structure),
            dirty: false,
            submitting: false,
            submitSucceeded: false,
        };
    }

    getActiveControls(effluentSystem) {
        let { solidManagement = {}, liquidManagement = {} } = effluentSystem;
        if (solidManagement === null) solidManagement = {};
        if (liquidManagement === null) liquidManagement = {};
        const { structure } = this.state;
        const [structureSolids, structureLiquids] = structureUtils.structureEffluent(structure);
        const activeControls = {};
        const showSolids = (!structure && ["HoldingPondSeparated"].includes(effluentSystem.effluentDisposal)) || structureSolids;
        const showPond = ["PondDischarge", "HoldingPond", "HoldingPondSeparated"].includes(effluentSystem.effluentDisposal);
        const showLiquids = ["SprayFromSump", "HoldingPond", "HoldingPondSeparated"].includes(effluentSystem.effluentDisposal);
        const hasDairyCows = this.props.analysis?.enterprises?.some((e) => e.type === "Dairy") === true;

        //Global (used for pond and liquid)
        activeControls.effluentSystem_effluentDisposal = structureLiquids || !structure;
        activeControls.effluentSystem_targetEffluentRate = !structure;
        activeControls.effluentSystem_isTreatedWithPFS = hasDairyCows && !structure && ["PondDischarge", "HoldingPond"].includes(effluentSystem.effluentDisposal);

        //Pond
        if (showPond) {
            activeControls.solidManagement_pondHeader = true;
            activeControls.solidManagement_pondDisposalMethod = true;
            activeControls.solidManagement_pondFrequency = solidManagement.pondDisposalMethod === "SpreadOnBlocks" && !structure;
            activeControls.solidManagement_pondApplications = solidManagement.pondDisposalMethod === "SpreadOnBlocks";
        }

        //Solids
        if (showSolids) {
            activeControls.solidManagement_solidsHeader = true;
            activeControls.solidManagement_disposalMethod = true;
            activeControls.solidManagement_effluentStorageMethod = ["SpreadOnBlocks", "Exported"].includes(solidManagement.disposalMethod);
            activeControls.solidManagement_timeInStorage = ["Covered", "Open"].includes(solidManagement.effluentStorageMethod);
            activeControls.solidManagement_solidApplications = solidManagement.disposalMethod === "SpreadOnBlocks";
        }

        //Liquids
        if (showLiquids) {
            activeControls.liquidManagement_liquidHeader = true;
            activeControls.liquidManagement_liquidEffluentDisposal = ["HoldingPond", "HoldingPondSeparated"].includes(effluentSystem.effluentDisposal);
            activeControls.liquidManagement_liquidApplications = ["SprayFromSump", "PondDischarge", "HoldingPond", "HoldingPondSeparated"].includes(effluentSystem.effluentDisposal);
            activeControls.liquidManagement_liquidApplications &= liquidManagement.liquidEffluentDisposal !== "Exported";
            activeControls.liquidManagement_liquidApplications_months = ["SprayInfrequently"].includes(liquidManagement.liquidEffluentDisposal);
        }

        return activeControls;
    }

    setDefaults(effluentSystem, activeControls) {
        let { solidManagement = {}, liquidManagement = {} } = effluentSystem;
        const cropTypes = ["FodderCrop", "ProductiveCrop"];

        if (solidManagement === null) solidManagement = {};
        if (liquidManagement === null) liquidManagement = {};

        if (!activeControls.solidManagement_pondApplications && effluentSystem.solidManagement) {
            effluentSystem.solidManagement.pondApplications = undefined;
        }

        if (!activeControls.solidManagement_solidApplications && effluentSystem.solidManagement) {
            effluentSystem.solidManagement.solidApplications = undefined;
        }

        if (effluentSystem.liquidManagement) {
            if (!activeControls.liquidManagement_liquidApplications) {
                effluentSystem.liquidManagement.applications = undefined;
            } else {
                const { applications = [] } = effluentSystem.liquidManagement;
                /*eslint-disable no-unused-vars*/
                for (const application of applications) {
                    const blocks = application.blockIds.map((blockId) => this.props.analysis.blocks.find((b) => b.id === blockId));
                    if (blocks.some((b) => !cropTypes.includes(b.type) && effluentSystem.liquidManagement.liquidEffluentDisposal !== "SprayInfrequently")) {
                        application.months = undefined;
                    }
                }
            }
        }

        const clearValues = (parent, keys) => {
            /*eslint-disable no-unused-vars*/
            for (const key of keys.filter((k) => !activeControls[k])) {
                const objKey = key.split("_").pop();
                parent[objKey] = undefined;
            }
        };
        clearValues(solidManagement, ["solidManagement_disposalMethod", "solidManagement_effluentStorageMethod", "solidManagement_timeInStorage", "solidManagement_pondDisposalMethod", "solidManagement_pondFrequency"]);
        clearValues(liquidManagement, ["liquidManagement_liquidEffluentDisposal"]);

        if (!activeControls.effluentSystem_isTreatedWithPFS) {
            effluentSystem.isTreatedWithPFS = false;
        }
    }

    onChange(e, source) {
        const effluentSystem = this.state.effluentSystem;
        const { liquidManagement = {} } = effluentSystem;

        switch (source.type) {
            case "solidManagement": {
                const { solidManagement = {} } = effluentSystem;
                solidManagement[source.key] = utils.ctrlVal(e);
                effluentSystem.solidManagement = solidManagement;
                break;
            }
            case "liquidManagement":
                liquidManagement[source.key] = utils.ctrlVal(e);
                effluentSystem.liquidManagement = liquidManagement;
                break;
            default:
                effluentSystem[source.key] = utils.ctrlVal(e);
                break;
        }
        const activeControls = this.getActiveControls(effluentSystem);
        this.setDefaults(effluentSystem, activeControls);
        this.isValid(effluentSystem, activeControls, source);
        this.setState({ effluentSystem, dirty: true });
    }

    isValid(effluentSystem, activeControls, source = undefined) {
        const currentValidation = this.state.validation;
        const { solidManagement = {}, liquidManagement = {} } = effluentSystem;
        let validation = {};
        let message = undefined;
        let key = undefined;
        let objkey = undefined;

        //Effluent System
        if (activeControls.effluentSystem_effluentDisposal) {
            objkey = "effluentDisposal";
            key = `effluentSystem_${objkey}`;
            message = validations.required(effluentSystem[objkey]);
            validation[key] = setVal(key, currentValidation, source, message);
        }

        if (activeControls.effluentSystem_targetEffluentRate) {
            objkey = "targetEffluentRate";
            key = `effluentSystem_${objkey}`;

            //message = message || validations.isNumeric(effluentSystem[objkey])
            message = message || validations.range(0, 1000)(effluentSystem[objkey]);
            validation[key] = setVal(key, currentValidation, source, message);
        }

        validateSolidEffluent(activeControls, validation, solidManagement, currentValidation, source, setVal, "solidManagement");
        validatePondEffluent(activeControls, validation, solidManagement, currentValidation, source, setVal, "solidManagement");
        validateLiquidEffluent(activeControls, validation, liquidManagement, currentValidation, source, setVal, "liquidManagement", this.props.analysis);

        this.setState({ validation: validation });
        /*eslint-disable no-unused-vars*/
        for (let key of Object.keys(validation)) {
            if (validation[key].error) {
                return false;
            }
        }
        return true;
    }

    clearEmptyObjects(effluentSystem, activeControls) {
        if (!activeControls.solidManagement_solidsHeader && !activeControls.solidManagement_pondHeader) {
            effluentSystem.solidManagement = undefined;
        }

        if (!activeControls.liquidManagement_liquidHeader) {
            effluentSystem.liquidManagement = undefined;
        }
    }

    async save() {
        const effluentSystem = this.state.effluentSystem;
        const activeControls = this.getActiveControls(effluentSystem);
        this.setDefaults(effluentSystem, activeControls);
        if (this.isValid(effluentSystem, activeControls)) {
            this.setState({ submitting: true, submitSucceeded: false });
            this.clearEmptyObjects(effluentSystem, activeControls);
            const updatedAnalysis = structureUtils.getUpdatedAnalysisFromSavingEffluentSystem(this.props.analysis, effluentSystem, this.state.structure?.id);
            await this.props.updateAnalysisAsync(updatedAnalysis);
            this.setState({ submitSucceeded: true });
        }
    }

    saveLiquidApplication(selectedApplication, dirty) {
        const validation = {};
        let isValid = false;
        if (validateLiquidApplication(validation, selectedApplication, this.state.validation, undefined, setVal, "liquidManagement")) {
            const effluentSystem = this.state.effluentSystem;
            this.populateSystem(effluentSystem);
            const blocks = selectedApplication.blockIds.map((blockId) => this.props.analysis.blocks.find((b) => b.id === blockId));
            const cropTypes = ["FodderCrop", "ProductiveCrop"];
            const needsSplit = blocks.some((b) => cropTypes.includes(b.type)) && blocks.some((b) => !cropTypes.includes(b.type));
            if (needsSplit) {
                effluentSystem.liquidManagement.applications = effluentSystem.liquidManagement.applications.filter((a) => a.id !== selectedApplication.id);
                const applications = splitCropApplications(selectedApplication, blocks, cropTypes);
                /*eslint-disable no-unused-vars*/
                for (const application of applications) {
                    utils.merge(application, effluentSystem.liquidManagement.applications);
                }
            } else {
                utils.merge(selectedApplication, effluentSystem.liquidManagement.applications);
            }
            this.setState({ effluentSystem: effluentSystem });
            isValid = true;
        }
        this.setState({ validation, dirty });
        return isValid;
    }

    cancelLiquidApplication() {
        const validation = {};
        for (var key in this.state.validation || {}) {
            if (!key.startsWith("liquidManagement_")) {
                validation[key] = this.state.validation[key];
            }
        }
        this.setState({ validation });
    }

    validateLiquidApp(application, source) {
        const validation = { ...this.state.validation };
        validateLiquidApplication(validation, application, this.state.validation, source, setVal, "liquidManagement");
        this.setState({ validation });
    }

    validateSolidApp(application, source) {
        const validation = { ...this.state.validation };
        validateSolidApplication(validation, application, this.state.validation, source, setVal, "solidManagement");
        this.setState({ validation });
    }

    cancelSolidApplication() {
        const validation = {};
        for (var key in this.state.validation || {}) {
            if (!key.startsWith("solidManagement_")) {
                validation[key] = this.state.validation[key];
            }
        }
        this.setState({ validation });
    }

    saveSolidApplication(application, dirty) {
        const effluentSystem = this.state.effluentSystem;
        this.populateSystem(effluentSystem);
        utils.merge(application, effluentSystem.solidManagement.solidApplications);
        this.setState({ effluentSystem, dirty });
    }

    savePondApplication(application, dirty) {
        const effluentSystem = this.state.effluentSystem;
        this.populateSystem(effluentSystem);
        utils.merge(application, effluentSystem.solidManagement.pondApplications);
        this.setState({ effluentSystem, dirty });
    }

    deleteLiquidApplication(id) {
        const effluentSystem = this.state.effluentSystem;
        this.populateSystem(effluentSystem);
        effluentSystem.liquidManagement.applications = effluentSystem.liquidManagement.applications.filter((a) => a.id !== id);
        this.setState({ effluentSystem, dirty: true });
    }

    deletePondApplication(id) {
        const effluentSystem = this.state.effluentSystem;
        this.populateSystem(effluentSystem);
        effluentSystem.solidManagement.pondApplications = effluentSystem.solidManagement.pondApplications.filter((a) => a.id !== id);
        this.setState({ effluentSystem, dirty: true });
    }

    deleteSolidApplication(id) {
        const effluentSystem = this.state.effluentSystem;
        this.populateSystem(effluentSystem);
        effluentSystem.solidManagement.solidApplications = effluentSystem.solidManagement.solidApplications.filter((a) => a.id !== id);
        this.setState({ effluentSystem, dirty: true });
    }

    populateSystem(effluentSystem) {
        if (!effluentSystem.solidManagement || effluentSystem.solidManagement === null) effluentSystem.solidManagement = {};
        if (!effluentSystem.liquidManagement || effluentSystem.liquidManagement === null) effluentSystem.liquidManagement = {};
        if (!effluentSystem.solidManagement.pondApplications || effluentSystem.solidManagement.pondApplications === null) effluentSystem.solidManagement.pondApplications = [];
        if (!effluentSystem.solidManagement.solidApplications || effluentSystem.solidManagement.solidApplications === null) effluentSystem.solidManagement.solidApplications = [];
        if (!effluentSystem.liquidManagement.applications || effluentSystem.liquidManagement.applications === null) effluentSystem.liquidManagement.applications = [];
    }

    render() {
        const _referrer = `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/structures`;
        const { effluentMethod = [], solidsManagement = [], structureType = [] } = this.props.refData;
        const { effluentSystem, validation, structure } = this.state;
        const { enterprises = [] } = this.props.analysis;
        const budgetContainsDairy = enterprises.some((e) => ["Dairy", "DairyGoat"].includes(e.type));
        if (!effluentMethod) return null;
        const systemEffluentMethod = effluentMethod.filter((m) => m.value !== "UseDairySystem" || (budgetContainsDairy && structure));
        const systemSolidsManagement = solidsManagement.filter((s) => s.value !== "NotSeparated");
        this.populateSystem(effluentSystem);
        const { liquidManagement, solidManagement } = effluentSystem;
        const activeControls = this.getActiveControls(effluentSystem);

        const systemTip = effluentSystem.effluentDisposal && domain.EffluentManagementSystem.find((t) => t.key === effluentSystem.effluentDisposal);

        return (
            <>
                <SavePrompt blockIf={this.state.dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={_referrer} />
                <Tile title={`${structure ? utils.valueToText(structureType, structure.type) : "Dairy"} effluent system`} waiting={this.state.submitting} referrer={_referrer}>
                    <Alert type="info" text="Select the type of system and describe how the effluent is managed and applied to blocks on the farm." />
                    <TileBody>
                        {activeControls[`effluentSystem_effluentDisposal`] && (
                            <div className="Grid Grid--withGutter">
                                <div className="Grid-cell u-md-width1of1 u-lg-width1of1">
                                    <h3>Effluent management</h3>
                                    <FieldGroup>
                                        <SelectPack meta={{ nrf: true }} dataWidth="50" onChange={(e) => this.onChange(e, { type: "effluentSystem", key: "effluentDisposal" })} value={effluentSystem.effluentDisposal} val={validation.effluentSystem_effluentDisposal} id="effluentSystem_effluentDisposal" label="Management system" tip={systemTip && systemTip.text} requiredLabel={true}>
                                            <option value="" disabled={true}>
                                                Select a management system
                                            </option>
                                            {utils.mapRefDataItems(systemEffluentMethod)}
                                        </SelectPack>
                                        <NumericInputPack id="targetEffluentRate" dataWidth="50" label="Target N application rate as effluent" placeholder="Default target rate is 150 kg N/ha/year." isHidden={!activeControls[`effluentSystem_targetEffluentRate`]} uom="kg N/ha/year" requiredLabel={false} val={validation.effluentSystem_targetEffluentRate} onChange={(e) => this.onChange(e, { type: "effluentSystem", key: "targetEffluentRate" })} decimalPlaces={0} value={effluentSystem.targetEffluentRate} tip={"Most regional councils recommend a value of 150 kg N/ha/year. This should be applied to the total of solid and liquid effluent applied."} />
                                    </FieldGroup>
                                    <CheckboxPack id="isTreatedWithPFS" dataWidth={50} isHidden={!activeControls[`effluentSystem_isTreatedWithPFS`]} meta={{ nrf: true }} value={effluentSystem.isTreatedWithPFS} onChange={(e) => this.onChange(e, { type: "effluentSystem", key: "isTreatedWithPFS" })} label="Effluent is treated with polyferric sulphate (BETA)" tip={<>Polyferric sulphate is a methane emission mitigation applied to the dairy effluent system. <ForMoreInfoZendeskLink url="https://support.overseer.org.nz/hc/en-us/articles/37755924570009" /></>} />
                                </div>
                            </div>
                        )}

                        <SolidEffluent onChange={(e, evt) => this.onChange(e, evt)} solidManagement={solidManagement} validation={validation} activeControls={activeControls} cancelApplication={() => this.cancelSolidApplication()} saveApplication={(application, dirty) => this.saveSolidApplication(application, dirty)} deleteApplication={(id) => this.deleteSolidApplication(id)} modalInlineClose={this.props.modalInlineClose} modalInlineOpen={this.props.modalInlineOpen} confirm={this.props.confirm} validate={(application, source) => this.validateSolidApp(application, source)} analysis={this.props.analysis} type="solidManagement" isFirst={!activeControls[`effluentSystem_effluentDisposal`] && !activeControls[`liquidManagement_liquidHeader`] && !activeControls[`solidManagement_pondHeader`]} />

                        <LiquidEffluent onChange={(e, evt) => this.onChange(e, evt)} liquidEffluent={liquidManagement} validation={validation} isSystem={structure ? false : true} activeControls={activeControls} cancelApplication={() => this.cancelLiquidApplication()} saveApplication={(application, dirty) => this.saveLiquidApplication(application, dirty)} deleteApplication={(id) => this.deleteLiquidApplication(id)} modalInlineClose={this.props.modalInlineClose} modalInlineOpen={this.props.modalInlineOpen} analysis={this.props.analysis} confirm={this.props.confirm} validate={(application, source) => this.validateLiquidApp(application, source)} type="liquidManagement" effluentSystem={{}} effluentSystemType="" isFirst={!activeControls[`effluentSystem_effluentDisposal`]} />

                        <PondEffluent onChange={(e, evt) => this.onChange(e, evt)} solidManagement={solidManagement} validation={validation} solidsManagement={systemSolidsManagement} activeControls={activeControls} cancelApplication={() => this.cancelSolidApplication()} saveApplication={(application, dirty) => this.savePondApplication(application, dirty)} deleteApplication={(id) => this.deletePondApplication(id)} modalInlineClose={this.props.modalInlineClose} modalInlineOpen={this.props.modalInlineOpen} analysis={this.props.analysis} confirm={this.props.confirm} validate={(application, source) => this.validateSolidApp(application, source)} type="solidManagement" isFirst={!activeControls[`effluentSystem_effluentDisposal`] && !activeControls[`liquidManagement_liquidHeader`]} />
                    </TileBody>
                    <TileFooter>
                        <div className="ButtonBar ButtonBar--fixed">
                            <div className="ButtonBar-left">
                                <Link id="system-cancel" to={_referrer} className="Button Button--secondary">
                                    Cancel
                                </Link>
                            </div>
                            <div className="ButtonBar-right">
                                <Button id="system-submit" primary submit waiting={this.state.submitting} onClick={() => this.save()}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </TileFooter>
                </Tile>
            </>
        )
    }
}

export const splitCropApplications = (application, blocks, cropTypes) => {
    const cropApp = utils.clone(application);
    cropApp.id = uuidv4();
    cropApp.blockIds = blocks.filter((b) => cropTypes.includes(b.type)).map((b) => b.id);

    const nonCropApp = utils.clone(application);
    nonCropApp.id = uuidv4();
    nonCropApp.blockIds = blocks.filter((b) => !cropTypes.includes(b.type)).map((b) => b.id);
    return [cropApp, nonCropApp];
}

const isTouched = (validation, source, id) => (validation && validation[id] && validation[id].touched) || source === undefined || source.key === id || source.id === id;

const setVal = (ctrlName, currentValidation, source, message) => {
    return { touched: isTouched(currentValidation, source, ctrlName), error: message !== undefined, message: message };
}
