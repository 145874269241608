function isReachable() {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("HEAD", process.env.REACT_APP_API_URL + "/ping");
        xhr.timeout = 3000;
        xhr.setRequestHeader("X-UI-Ver", process.env.REACT_APP_VERSION);

        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                var version = this.getResponseHeader("pragma");
                resolve([true, version]);
            } else {
                resolve([false, 0]);
            }
        };
        xhr.onerror = function () {
            resolve([false, 0]);
        };
        xhr.timeout = function () {
            resolve([false, 0]);
        };
        xhr.send();
    });
}

export default isReachable;
