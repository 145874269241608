import moment from "moment";
import * as utils from "common/utils";
import WidgetContainer from "components/WidgetContainer";
import { useIsPaidVersionOfOverseer } from "common/hooks";

export default function Subscriptions({ farm, className, height }) {
    const isPaidVersionOfOverseer = useIsPaidVersionOfOverseer();

    const daysTillExpired = farm.expired ? 0 : farm.expirationDate ? moment.utc(farm.expirationDate).diff(moment.utc(), "days") : 0;
    const expired = isPaidVersionOfOverseer && (farm.expired || !farm.expirationDate || daysTillExpired < 0);
    const pill = isPaidVersionOfOverseer ? (expired ? { level: "error", text: "Expired" } : daysTillExpired < 32 ? { level: "warning", text: daysTillExpired + " days remaining" } : { level: "info", text: "Active" }) : { level: "info", text: "Active" };
    const hasSubscriptions = farm?.subscriptions?.length > 0 || false;

    return (
        <WidgetContainer id="subscription" title="Subscriptions" icon="IconLink--dollar" className={className} hideLink={!hasSubscriptions} pill={pill} height={height} farm={farm} expired={expired}>
            <div>
                {!hasSubscriptions ? (
                    <div>
                        <div className="Widget-content-title">No subscriptions</div>
                        <p>This farm has no subscriptions to display</p>
                    </div>
                ) : (
                    <div className="SimpleTable">
                        <table>
                            <thead>
                                <tr>
                                    <th>Created</th>
                                    <th>Expires</th>
                                    <th>Organisation</th>
                                    <th>Person</th>
                                </tr>
                            </thead>
                            <tbody>
                                {farm.subscriptions
                                    .sort((a, b) => (a.expiresAt > b.expiresAt ? -1 : 1))
                                    .slice(0, 3)
                                    .map((a) => (
                                        <tr key={a.createdOn}>
                                            <td>{utils.dateOnly(a.createdOn)}</td>
                                            <td>{utils.dateOnly(a.expiresAt)}</td>
                                            <td>{a.accountName}</td>
                                            <td>{a.userFullName}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </WidgetContainer>
    )
}
