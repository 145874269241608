import { useState } from "react";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import * as utils from "common/utils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";

export default function UserSessions({ sessions }) {
    const [selectedSesh, setSelectedSesh] = useState();

    if (!sessions || sessions.length === 0) return null;

    return (
        <>
            <SortableTable data={sessions} defaultSortCriteria={{ fieldName: "firstLogDate", fieldType: "date", desc: true }} pageSize={50}>
                {({ data, sortCriteria, handleSort }) => {
                    return (
                        <table>
                            <thead>
                                <tr>
                                    <SortableTableHeader label="Account" fieldName="accountName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                    <SortableTableHeader label="User" fieldName="userName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                    <SortableTableHeader label="Last active" fieldName="latestActivity" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                    <SortableTableHeader label="Current location" fieldName="latestLocation" sortCriteria={sortCriteria} handleSort={handleSort} />
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((sesh) => {
                                    const latestDate = Date.parse(sesh.latestActivity);
                                    const isNew = latestDate && new Date().getTime() - latestDate < 10000;
                                    return (
                                        <tr key={sesh.userId} className={`hover ${isNew ? "glow" : ""}`} onClick={() => setSelectedSesh(sesh)}>
                                            <td valign="top">{sesh.accountName}</td>
                                            <td valign="top">{sesh.userName}</td>
                                            <td valign="top">{utils.dateLong(sesh.latestActivity, "-")}</td>
                                            <td valign="top">{sesh.latestLocation}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    );
                }}
            </SortableTable>
            {selectedSesh && (
                <Modal title={`Last 50 locations - ${selectedSesh.userName} (${selectedSesh.accountName})`} full allowOffline close={() => setSelectedSesh(undefined)}>
                    <ModalBody>
                        <div className="Table u-mt-md">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedSesh.userLocations.map((sesh) => {
                                        const latestDate = Date.parse(sesh.date);
                                        const isNew = latestDate && new Date().getTime() - latestDate < 10000;
                                        return (
                                            <tr key={sesh.date} className={`hover ${isNew ? "glow" : ""}`}>
                                                <td valign="top">{utils.dateLong(sesh.date, "-")}</td>
                                                <td valign="top">
                                                    {" "}
                                                    <a target="_blank" rel="noreferrer" href={`http://${window.location.host}/${sesh.location}`}>
                                                        {sesh.location}
                                                    </a>{" "}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ModalFooterRight>
                            <Button id="cancel" onClick={() => setSelectedSesh(undefined)} secondary>
                                Cancel
                            </Button>
                        </ModalFooterRight>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}
