import React from "react";
import Pdf from "components/Pdf";
import ActionLink from "components/ActionLink";

const AcceptTermsModal = (props) => {
    const { title, fileName } = props;

    const accept = () => {
        props.accept();
    };

    const decline = () => {
        props.decline();
    };

    return (
        <div className="Modal_wrapper">
            <div className="Modal Modal--medium">
                <div className="Modal-head">
                    <ActionLink onClick={decline} className="Modal-close" id="cancel-terms">
                        <i className="icon icon-cross" />
                    </ActionLink>
                    <span>{title}</span>
                </div>
                <div className="Modal-body">
                    <div className="Grid">
                        <div className="Grid-cell">
                            <div style={{ height: "800px", overflowY: "auto", overflowX: "hidden" }}>
                                <Pdf {...props} />
                            </div>
                            <div className="ButtonBar u-mt-xl u-pb-0">
                                <div className="ButtonBar-left">
                                <a id="download-terms" href={fileName} className="Button Button--secondary" download={`${title}.pdf`} target="_blank" rel="noopener noreferrer">
                                    Download
                                </a>
                                </div>
                                <div className="ButtonBar-right">
                                    <button type="button" onClick={decline} className="Button" id="decline-terms">
                                        Decline
                                    </button>
                                    <button type="button" onClick={accept} className="Button" id="accept-terms">
                                        Accept
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AcceptTermsModal;
