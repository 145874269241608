import { useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { SelectField, RadioGroupField } from "components/FormFields";
import moment from "moment";
import { useIsEducationProviderUser, useModal } from "common/hooks";
import { useGrantAnalyisAccessAsync, useAddAnalysisToStudentGroupAsync } from "containers/hooks";

export default function ShareAnalysisModal({ analysis, close }) {
    const shareWithOptions = useShareWithOptions(analysis);
    const studentGroupOptions = useStudentGroupOptions();
    const grantAnalysisAccessAsync = useGrantAnalyisAccessAsync();
    const addAnalysisToStudentGroupAsync = useAddAnalysisToStudentGroupAsync();

    const analysisIsAlreadyFullyShared = analysis.visibility === "Farm";
    const warningText = analysisIsAlreadyFullyShared ? "This analysis is currently shared with all users with farm access so your only option is to share with students" : "Please ensure you select the correct user or group. Share actions cannot be undone.";

    const validate = (formValues) => {
        const validation = {};

        if (formValues.visibility === "Students") {
            validation.studentGroup = FormUtils.validators.required(formValues.studentGroup);
        }

        return validation;
    }

    const submitAsync = async (formValues) => {
        try {
            if (formValues.visibility === "Students") {
                await addAnalysisToStudentGroupAsync(analysis, formValues);
            } else {
                await grantAnalysisAccessAsync(analysis, formValues);
            }
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }

    return (
        <Form validate={validate} onSubmit={submitAsync}>
            {({ values, handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Share analysis" close={close} submitting={submitting} skinny fluid>
                            <ModalBody warning={warningText} error={submitError}>
                                <h3>Who do you wish to share the analysis with?</h3>
                                <p><strong>Note:</strong> any analysis you have access to will always be available to everyone in your organisation</p>
                                <Field name="visibility" options={shareWithOptions} type="radio" component={RadioGroupField} />
                                {values.visibility === "Students" && <Field name="studentGroup" label="Student group" placeholder="Select a group" options={studentGroupOptions} required component={SelectField} />}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit primary waiting={submitting} disabled={!values.visibility}>
                                        Share
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useShareAnalysisModal(analysis) {
    const [modal, openModal] = useModal(ShareAnalysisModal);

    const openShareAnalysisModal = () => {
        const modalProps = {
            analysis
        };
        openModal(modalProps);
    };

    return [modal, openShareAnalysisModal];
}

function useShareWithOptions(analysis) {
    const isEducationProviderUser = useIsEducationProviderUser();
    const analysisIsAlreadyFullyShared = analysis.visibility === "Farm";
    const disabled = isEducationProviderUser && analysisIsAlreadyFullyShared;

    const shareWithOptions = [
        { value: "Farm", text: "All users with farm access", disabled },
        { value: "Owner", text: "The owners of this farm", disabled },
    ];

    if (isEducationProviderUser) {
        shareWithOptions.push({ value: "Students", text: "Copy this analysis to student group" });
    }

    return shareWithOptions;
}

function useStudentGroupOptions() {
    const students = useSelector(state => state.userManagement?.students || []);
    const studentIvitations = useSelector(state => state.userManagement?.studentInvitations?.filter((i) => i.status !== "Accepted") || []);

    const invitationGroups = studentIvitations.filter((s) => moment(s.expires).isAfter(moment().valueOf())).map((g) => g.group);
    const studentGroups = students.filter((s) => moment(s.expires).isAfter(moment().valueOf())).map((g) => g.group);
    const allGroups = [...new Set([...invitationGroups, ...studentGroups])]
        .filter((g) => g !== undefined)
        .sort((a, b) => (a < b ? -1 : 1))
        .map((g) => ({ value: g, text: g }));

    return allGroups;
}
