import SelectPack from "components/SelectPack";
import * as utils from "common/utils";
import * as validations from "common/validations";
import CheckboxPack from "components/CheckboxPack";
import { useRefData } from "common/hooks";

export default function BeddingPad({ pad, validation, onChange, activeControls, isFirst }) {
    const refData = useRefData();

    const { standOffSurface = [] } = refData;

    if (!activeControls[`pad_header`]) return null;

    return (
        <div className={`Grid Grid--withGutter ${isFirst ? "" : "u-mt-lg"}`}>
            <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                <h3>Bedding pad</h3>
                <SelectPack meta={{ nrf: true }} isHidden={!activeControls[`pad_padSurface`]} onChange={(e) => onChange(e, { type: "pad", key: "padSurface" })} value={pad.padSurface} val={validation.pad_padSurface} id={`pad_padSurface`} label="Pad surface" requiredLabel={true}>
                    <option value="" disabled={true}>
                        Select a pad surface
                    </option>
                    {utils.mapRefDataItems(standOffSurface)}
                </SelectPack>
                <CheckboxPack meta={{ nrf: true }} isHidden={!activeControls[`pad_lined`]} id={`pad_lined`} label="Lined, subsurface drained and effluent captured" value={pad.lined} onChange={(e) => onChange(e, { type: "pad", key: "lined" })} />
                <CheckboxPack meta={{ nrf: true }} isHidden={!activeControls[`pad_scraped`]} id={`pad_scraped`} label="Surface scraped regularly" value={pad.scraped} onChange={(e) => onChange(e, { type: "pad", key: "scraped" })} />
            </div>
        </div>
    )
}

export function validateBeddingPad(activeControls, validation, pad, currentValidation, source, setVal) {
    let message = undefined;
    let key = undefined;
    let objkey = undefined;

    objkey = "padSurface";
    key = `pad_${objkey}`;
    if (activeControls[key]) {
        message = validations.required(pad[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }
}
