import React from "react";
//import find from 'lodash/find'
//import * as utils from 'common/utils'
import ActionLink from "components/ActionLink";

/*const SelectedBlocksAndSoils = (props) => {
    const { farm, budget, selectedBlocks, overseerBlocksByBlock, deselectBlock, toggleOverseerBlock } = props
    return (
        <div className="Field">
            {
                selectedBlocks.map(block => {
                    const blockSoils = farm.soils.filter(s => s.blockId === block.id)
                    return (
                        <ul key={block.id} className="BlockList">
                            <li id={block.id} className="BlockList-item is-active">
                                <span id={`${block.id}-name`}>{block.name}</span>
                                <ActionLink id={`${block.id}-deselect`} onClick={e => { deselectBlock(e, block.id) }}  className="BlockList-item-close"><i className="icon icon-cross" /></ActionLink>
                            </li>
                            {
                                blockSoils.map(bs => {
                                    const overseerBlockByBlock = find(overseerBlocksByBlock, { id: block.id })
                                    const checked = overseerBlockByBlock.overseerBlockIds.some(id => id === overseerBlock.id) === true
                                    const soil = overseerBlock.soilId !== undefined ? utils.getSoilFromFarm(farm, overseerBlock.soilId) : undefined
                                    const label = soil? soil.subTitle === "" ? soil.title : soil.subTitle : "unknown"
                                    return (
                                        <li id={`${block.id}-${overseerBlock.id}`} key={overseerBlock.id} className="BlockList-item BlockList-item--soil">
                                            <span id={`${block.id}-${overseerBlock.id}-label`}>{`${overseerBlock.percentageOfBlock}% ${label}`}</span>
                                            <label className="Checkbox Checkbox--tick">
                                                <input id={`${block.id}-${overseerBlock.id}-checkbox`} className="Checkbox-input" type="checkbox" checked={checked} onChange={e => { toggleOverseerBlock(e, block.id, overseerBlock.id) }} />
                                                <span className="Checkbox-label u-ml-md"></span>
                                            </label>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    )
                })
            }
        </div>
    )
}*/

const SelectedBlocksOnly = (props) => {
    const { selectedBlocks, deselectBlock } = props;
    return (
        <div className="Field">
            <ul className="BlockList">
                {selectedBlocks.map((block) => {
                    return (
                        <li id={block.id} className="BlockList-item is-active" key={block.id}>
                            <span id={`${block.id}-name`}>{block.name}</span>
                            <ActionLink
                                id={`${block.id}-deselect`}
                                onClick={(e) => {
                                    deselectBlock(e, block.id);
                                }}
                                className="BlockList-item-close"
                            >
                                <i className="icon icon-cross" />
                            </ActionLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

class BlockSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            touched: false,
        };
    }

    _selectBlock = (e) => {
        const blockId = e.target.selectedOptions && e.target.selectedOptions.length > 0 ? e.target.selectedOptions[0].value : "";
        this.props.selectBlock(blockId);
    };

    _deselectBlock = (e, blockId) => {
        e.preventDefault();
        this.setState({ touched: true });
        this.props.deselectBlock(blockId);
    };

    /*_toggleOverseerBlock = (e, blockId, overseerBlockId) => {
        this.setState({ touched: true })
        if (e.target.checked) {
            this.props.selectOverseerBlock(blockId, overseerBlockId)
        }
        else {
            this.props.deselectOverseerBlock(blockId, overseerBlockId)
        }
    }*/

    render() {
        const { blocks, selectedBlocks, id, label, meta, input } = this.props;
        const _unselectedBlocks = blocks.filter((b) => b && selectedBlocks.some((s) => s && s.id === b.id) === false);
        const _invalid = (meta.touched || this.state.touched) && selectedBlocks.length === 0;

        return (
            <div className="BlockSelect">
                <div className={"Field" + (_invalid ? " has-error" : "")}>
                    <label htmlFor={id} className="Select">
                        <span className="Field-label">
                            {label}
                            <sup className="required">*</sup>
                        </span>
                        <select {...input} onChange={this._selectBlock} value="" id={id}>
                            <option value="" disabled={true}>
                                {_unselectedBlocks.length > 0 ? "Select a block" : "All blocks selected"}
                            </option>
                            {_unselectedBlocks.map((block) => (
                                <option key={block.id} value={block.id}>
                                    {block.name}
                                </option>
                            ))}
                        </select>
                    </label>
                    {_invalid && <small className="Field-error">Required</small>}
                </div>
                <SelectedBlocksOnly selectedBlocks={selectedBlocks} deselectBlock={this._deselectBlock} />
            </div>
        );
    }
}

export default BlockSelector;
