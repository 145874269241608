import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { FORM_ERROR } from "final-form";
import * as utils from "common/utils";
import * as domain from "common/domain";
import { usePortalModal } from "common/effects";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";
import { CheckboxField, RadioGroupField, SelectField, TextField } from "components/FormFields";
import { useScienceSettings } from "./_hooks";
import { createModelRun } from "./_actions";
import { useRefData, useShowQuickTips } from "common/hooks";

export default function ModelRunModal({ run, analyses, isVirtualDataset, onSave, close }) {
    const dispatch = useDispatch();
    const refData = useRefData();
    const { data: settings } = useScienceSettings();
    const modelRunTypes = useModelRunTypes(settings);
    const enterpriseTypes = useEnterpriseTypes();

    const validate = async (run) => {
        const validation = {};

        validation.modelType = FormUtils.validators.required(run.modelType);

        validation.description = FormUtils.validators.required(run.description);
        validation.description = validation.description || FormUtils.validators.maxLength(100)(run.description);

        const runTypeRequireAnalysesToBeDrawn = RUN_TYPE_OPTIONS.filter(t => t.analysesMustBeMapped).some(t => t.value === run.type);
        const analysesThatArentSpatiallyMapped = analyses.filter((a) => !a.isDrawn);
        const someAnalysesArentDrawn = analysesThatArentSpatiallyMapped.length > 0;
        if (runTypeRequireAnalysesToBeDrawn && someAnalysesArentDrawn) {
            validation.type = `All analyses must be spatially mapped in order to run this type. The following analyses are not spatially mapped - ${analysesThatArentSpatiallyMapped.map((a) => a.name).sort().join(", ") }`;
        }

        return validation;
    };

    const submit = async (run) => {
        if (!run.setUrineParams && !run.setCropParams && !run.overridePasture) {
            delete run.modelParams;
        } else {
            if (!run.setUrineParams && run.modelParams) delete run.modelParams.urineParams;
            if (!run.setCropParams && run.modelParams) delete run.modelParams.cropParams;
            if (!run.lysimeterPastureSettings && run.modelParams) delete run.modelParams.pasture;
            if (!run.overridePasture && run.modelParams) delete run.modelParams.pastureOverride;
        }
        if (run.isSensivityAnalysis) {
            run.type = "Sensitivity";
        } else if (run.sensitivityCriteria) {
            delete run.sensitivityCriteria;
        }

        // clear out other params
        if (run.modelParams && run.modelParams.urineParams)
            if (run.modelType === "Full") {
                run.modelParams.urineParams = {
                    removeDenitrification: run.modelParams.urineParams.removeDenitrification,
                    initialFertiliserImmobilisation: run.modelParams.urineParams.initialFertiliserImmobilisation,
                    pUrine: run.modelParams.urineParams.pUrine,
                    pUrineFactor: run.modelParams.urineParams.pUrineFactor,
                    urineLoad: run.modelParams.urineParams.urineLoad,
                };
            } else {
                delete run.modelParams.urineParams.pUrine;
                delete run.modelParams.urineParams.urineLoad;
            }

        const submitResult = await dispatch(createModelRun(run.datasetId, run))
            .then(() => {
                close();
                onSave && onSave(run);
            })
            .catch((error) => ({ [FORM_ERROR]: error }));
        return submitResult;
    };

    return (
        <Form initialValues={run} validate={validate} mutators={{ ...arrayMutators }} onSubmit={submit}>
            {({ form, values, handleSubmit, submitting, submitError }) => {
                const disableSave = submitting;
                const selectedModelType = modelRunTypes.find((rt) => rt.value === values.modelType);
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Model run" close={close} submitting={submitting} full>
                            <ModalBody info="A model run is when all analyses within a dataset are run through the model. Model parameters and/or settings can be changed within this screen. The entered model run parameters/settings are used in the model instead of what is currently in the model when the analyses are run through the model." error={submitError}>
                                <Grid className="u-mb-lg">
                                    <GridCell className="u-width2of3">
                                        <Field name="modelType" label="Model run type" placeholder="Select a run type" info={selectedModelType ? selectedModelType.info : undefined} options={modelRunTypes} required component={SelectField} />
                                        <Field name="description" label="Description" placeholder="Enter a description for this run" required component={TextField} />
                                        {isVirtualDataset && <Field name="criteria.numberOfAnalyses" label="Number of analyses" placeholder="Enter the maximum number of analyses to return" type="number" required component={TextField} />}
                                        {isVirtualDataset && <Field name="criteria.blockType" label="Block type" placeholder="Select an block type" options={refData.blockTypes} component={SelectField} />}
                                        {isVirtualDataset && <Field name="criteria.enterpriseType" label="Enterprise" placeholder="Select an enterprise" options={enterpriseTypes} component={SelectField} />}
                                    </GridCell>
                                </Grid>
                                <SensitivityAnalysisAttributesPanel form={form} run={values} settings={settings} />
                                <ModelRunParametersPanel run={values} settings={settings} />
                                <MonthlyResultsPanel run={values} settings={settings} />
                                <SmapSoilSiblingUpdatesPanel run={values} settings={settings} />
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting} disabled={disableSave}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useModelRunModal(dataset, analyses) {
    const { openModal, getModal } = usePortalModal();

    const openModelRunModal = (modelType, isSensivityAnalysis, onSave) => {
        const modalProps = {
            isVirtualDataset: dataset.type === "Virtual",
            run: {
                datasetId: dataset.id,
                modelType,
                isSensivityAnalysis,
                analysisCount: analyses?.length || 0,
            },
            analyses,
            onSave,
        };

        if (isSensivityAnalysis) {
            modalProps.run.sensitivityCriteria = {
                attributes: [{ attributeType: "NumberAnimals", start: undefined, end: undefined, step: undefined }],
            };
        }

        openModal("ModelRunModal", modalProps);
    };

    const modelRunModal = getModal("ModelRunModal", ModelRunModal);

    return [openModelRunModal, modelRunModal];
}

function useModelRunTypes(settings) {
    const modelRunTypes = [
        {
            value: "Full",
            text: "Full",
            info: "Runs all analyses in the dataset through the current Overseer model version using the specified parameters below and stores analysis and block results.",
        },
    ];

    if (settings && settings.allowedFunctions.includes("Lysimeter")) {
        modelRunTypes.push({
            value: "Lysimeter",
            text: "Lysimeter",
            info: "Runs all analyses in the dataset through the lysimeter model and stores results.",
        });
    }

    if (settings && settings.allowedFunctions.includes("AnimalME")) {
        modelRunTypes.push({
            value: "AnimalME",
            text: "Animal ME",
            info: "Runs all analyses in the dataset through the current Overseer ME sub model using the specified parameters below and stores outputs of the sub model.",
        });
    }

    return modelRunTypes;
}

function useEnterpriseTypes() {
    const refData = useRefData();
    return refData.enterpriseTypes.filter((e) => e.value !== "Other" && e.value !== "OutdoorPigs");
}

function SensitivityAnalysisAttributesPanel({ form, run, settings }) {
    const [totalAnalyses, setTotalAnalyses] = useState(0);
    const refData = useRefData();
    const enterpriseTypes = useEnterpriseTypes();
    const supplementTypes = refData.supplementCategories.filter((e) => e.children && e.value !== "None" && e.value !== "Userdefined");
    const [sensitivtyAttributeTypes] = useState([
        { value: "NumberAnimals", text: "Number of animals (%)", min: 50, max: 200 },
        { value: "SoilOrder", text: "Soil order", refData: "soilOrders" },
        { value: "MilkProduction", text: "Milk production (%)", min: 50, max: 200 },
        { value: "RainfallSeasonality", text: "Rain seasonality", refData: "rainDailyPattern" },
        { value: "Rainfall", text: "Rainfall (mm)", min: 200, max: 2000 },
        { value: "Temperature", text: "Temperature (C)", min: 0, max: 30 },
        { value: "PET", text: "PET (mm)", min: 400, max: 1500 },
        { value: "PETClassification", text: "PET Classes", refData: "petValues" },
        { value: "PETSeason", text: "PET seasonality", refData: "peTseasonality" },
        { value: "SupplementAmount", text: "Supplement amount (%)", min: 50, max: 150 },
        { value: "SupplementType", text: "Supplement type", refData: "supplementCategories" },
        { value: "Area", text: "Area (%)", min: 50, max: 150 },
        { value: "FertiliserApplied", text: "Fertiliser applied (%)", min: 50, max: 150 },
        { value: "UrineProportion", text: "Urine proportion (%)", min: 50, max: 100 },
        { value: "UrinePatchNLoad", text: "Urine N load (%)", min: 20, max: 250 },
        { value: "PastureNPercentage", text: "Pasture N (%)", min: 2.5, max: 5 },
        { value: "MilkNContent", text: "Milk N Content (%)", min: 50, max: 150 },
        { value: "DrainageVolume", text: "Drainage (%)", min: 50, max: 150 },
        { value: "PastureME", text: "Pasture ME (%)", min: 50, max: 150 },
        { value: "PastureType", text: "Pasture types", refData: "pastureTypes" },
        { value: "PlantainPercentage", text: "Plantain %", min: 5, max: 60 },
    ]);

    const isAllowedToRunSensitivityAnalysis = settings && settings.allowedFunctions && settings.allowedFunctions.includes("SensitivityAnalysis");
    if (!isAllowedToRunSensitivityAnalysis || !run.isSensivityAnalysis || run.modelType !== "Full") return null;

    const getRefDataForSensitivity = (attributeType) => {
        return (
            refData[attributeType.refData] &&
            refData[attributeType.refData].map((r, i) => {
                return { value: i, text: r.text };
            })
        );
    };

    const removeSensitivityAttribute = (index) => () => {
        const results = run.sensitivityCriteria.attributes.reduce((acc, cur, i) => {
            if (i !== index) acc.push(cur);
            return acc;
        }, []);
        form.change("sensitivityCriteria.attributes", results);
    };

    const addSensitivityAttribute = () => {
        form.mutators.push("sensitivityCriteria.attributes", { attributeType: sensitivtyAttributeTypes[0].value, start: undefined, end: undefined, step: undefined });
    };

    const validateAttributes = (attributes) => {
        let num = 0;
        const validations = [];

        attributes &&
            attributes.forEach((attribute) => {
                const validation = {};

                validation.attributeType = FormUtils.validators.required(attribute.attributeType);

                const selected = sensitivtyAttributeTypes.find((a) => a.value === attribute.attributeType);
                validation.start = FormUtils.validators.requiredAllowZero(attribute.start);
                validation.end = FormUtils.validators.required(attribute.end);

                if (selected.min !== undefined && selected.max !== undefined) {
                    validation.start = validation.start || FormUtils.validators.range(selected.min, selected.max)(attribute.start);
                    validation.end = validation.end || FormUtils.validators.range(selected.min, selected.max)(attribute.end);
                }
                validation.step = selected.refData ? undefined : FormUtils.validators.required(attribute.step);

                const startNum = Number(attribute.start);
                const endNum = Number(attribute.end);
                const stepNum = attribute.step ? Number(attribute.step) : 1;

                validation.start = validation.start || (endNum < startNum ? "End cannot be greater then start" : undefined);
                validation.step = validation.step || (!selected.refData && endNum - startNum < stepNum ? "Step cannot be greater then difference between start and end" : undefined);

                const attributeNum = utils.round((endNum - startNum) / stepNum + 1, 0);

                num = num === 0 ? attributeNum : attributeNum * num;

                validation.attributeType = validation.attributeType || (num * run.analysisCount > 1000 ? "Cannot create a run with more then 1000 analyses currently." : undefined);

                validations.push(validation);
            });

        setTotalAnalyses(num || 0);
        return validations;
    };

    const info = `Possible combinatons = ${totalAnalyses}. Number of analyses in dataset = ${run.analysisCount}. Total analyses to create = ${totalAnalyses * run.analysisCount}`;

    return (
        <Panel
            title="Sensitivity attributes"
            info={info}
            midBlue
            actions={
                <ActionLink id="add-attribute-link" className="IconLink--arrow-plus u-textWhite" onClick={addSensitivityAttribute}>
                    Add attribute
                </ActionLink>
            }
        >
            <PanelBody>
                <FieldArray name="sensitivityCriteria.attributes" validate={validateAttributes}>
                    {({ fields }) => {
                        return fields.map((field, index) => {
                            const attributeTypeSelected = fields.value[index].attributeType && sensitivtyAttributeTypes.find((a) => a.value === fields.value[index].attributeType);
                            const suppCategory = fields.value[index].attributeType === "SupplementType" && supplementTypes.find((t) => t.value === fields.value[index].supplementCategory);
                            const sensitivityRefData =
                                attributeTypeSelected &&
                                attributeTypeSelected.refData &&
                                (fields.value[index].attributeType === "SupplementType"
                                    ? suppCategory &&
                                      suppCategory.children &&
                                      suppCategory.children.map((c, i) => {
                                          return { text: c.text, value: i };
                                      })
                                    : getRefDataForSensitivity(attributeTypeSelected));

                            return (
                                <Grid key={index}>
                                    <GridCell className="u-lg-width1of5 u-md-width1of5">
                                        <Field name={`${field}.attributeType`} label="Attrbute" options={sensitivtyAttributeTypes} required component={SelectField} />
                                    </GridCell>
                                    {fields.value[index].attributeType === "NumberAnimals" && (
                                        <GridCell className="u-lg-width1of5 u-md-width1of5">
                                            <Field name={`${field}.enterpriseType`} label="Enterprise" options={enterpriseTypes} placeholder="All enterprises" component={SelectField} />
                                        </GridCell>
                                    )}
                                    {fields.value[index].attributeType === "SupplementType" && (
                                        <GridCell className="u-lg-width1of5 u-md-width1of5">
                                            <Field name={`${field}.supplementCategory`} label="Supplement category" options={supplementTypes} placeholder="Select" component={SelectField} />
                                        </GridCell>
                                    )}
                                    {sensitivityRefData && (
                                        <>
                                            <GridCell className="u-lg-width1of5 u-md-width1of5">
                                                <Field name={`${field}.start`} label="Start" options={sensitivityRefData} placeholder="Select" component={SelectField} />
                                            </GridCell>
                                            <GridCell className="u-lg-width1of5 u-md-width1of5">
                                                <Field name={`${field}.end`} label="End" options={sensitivityRefData} placeholder="Select" component={SelectField} />
                                            </GridCell>
                                            {fields.length > 1 && (
                                                <GridCell className="u-lg-width1of5 u-md-width1of5 u-textRight Field">
                                                    <ActionLink id={`remove-sensitivity-attribute-${index}`} className="IconLink--cross-circle" onClick={removeSensitivityAttribute(index)}></ActionLink>
                                                </GridCell>
                                            )}
                                        </>
                                    )}
                                    {(!attributeTypeSelected || !attributeTypeSelected.refData) && (
                                        <>
                                            <GridCell className="u-lg-width1of5 u-md-width1of5">
                                                <Field name={`${field}.start`} label="Start" placeholder="0" required format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                            </GridCell>
                                            <GridCell className="u-lg-width1of5 u-md-width1of5">
                                                <Field name={`${field}.end`} label="End" placeholder="0" required format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                            </GridCell>
                                            <GridCell className="u-lg-width1of5 u-md-width1of5">
                                                <GridCell className="u-lg-width3of4 u-md-width3of4">
                                                    <Field name={`${field}.step`} label="Step" placeholder="0" required format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                                </GridCell>
                                                {fields.length > 1 && (
                                                    <GridCell className="u-lg-width1of4 u-md-width1of4 u-textRight Field">
                                                        <ActionLink id={`remove-sensitivity-attribute-${index}`} className="IconLink--cross-circle" onClick={removeSensitivityAttribute(index)}></ActionLink>
                                                    </GridCell>
                                                )}
                                            </GridCell>
                                        </>
                                    )}
                                </Grid>
                            );
                        });
                    }}
                </FieldArray>
            </PanelBody>
        </Panel>
    );
}

function ModelRunParametersPanel({ run, settings }) {
    const refData = useRefData();
    const showQuickTips = useShowQuickTips();

    const isAllowedToSetFullModelRunParameters = settings && settings.allowedFunctions && settings.allowedFunctions.includes("FullModelParameters");
    if (!isAllowedToSetFullModelRunParameters || run.isSensivityAnalysis || !run.modelType || run.modelType === "AnimalME") return null;

    const orderedValues = (prefix, decPlaces = 2) =>
        domain.calendarYear.map((c, i) => {
            return (
                <td key={i}>
                    <Field name={`${prefix}[${c}]`} format={FormUtils.formatters.formatDecimal(decPlaces)} formatOnBlur component={TextField} />
                </td>
            );
        });

    const info = "Select the model parameters you want to use in the model instead of what is currently set in the model. These will be used in the model during the model run.";

    return (
        <Panel title="Model run parameters" info={info} midBlue>
            <PanelBody>
                <div className="modelParameterGrouping u-mt-sm">
                    <Field name="setUrineParams" label="Set urine N leaching model parameters" component={CheckboxField} />
                    {showQuickTips && <Alert className="u-mb-0" type="help" text="Set parameters that will be passed through to the urine patch model during model execution instead of using the urine patch parameters that are currently used in the model. See the 'Urine patch sub model' technical manual chapter for more information on these parameters" />}
                    {run.setUrineParams && (
                        <>
                            <Grid title="Urine patch model parameters">
                                {run.modelType === "Full" && (
                                    <>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.pUrine" label="Proportion of urine" placeholder="Enter fraction of urine N" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.pUrineFactor" label="Proportion of urine factor" placeholder="Enter factor for proportion of urine N" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.urineLoad" label="Urine load" placeholder="Enter urine load" format={FormUtils.formatters.formatDecimal(0)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.removeDenitrification" label="Remove denitrification" component={CheckboxField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.initialFertiliserImmobilisation" label="Initial fertiliser immobilisation" placeholder="0.5 adjust by temp" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                    </>
                                )}
                                {run.modelType === "Lysimeter" && (
                                    <>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.npvFactors.npv1Factor" label="NPV1 factor" placeholder="NPV1 multiplier" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.npvFactors.npv2Factor" label="NPV2 factor" placeholder="NPV2 multiplier" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.npvFactors.npv3Factor" label="NPV3 factor" placeholder="NPV3 multiplier" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.baseNUptake" label="Base N uptake (all blocks)" placeholder="Use default" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.volatilisation" label="Volatilisation" placeholder="0.2 adj by temp" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.denitrification" label="Denitrification" placeholder="Calculated" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.initialImmobilisation" label="Immobilisation (initial)" placeholder="0.20, 0.30, 0.05 adjust by temp" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.monthlyImmobilisationRate" label="Monthly immobilisation" placeholder="0.025, 0.07, 0 adjust by temp" format={FormUtils.formatters.formatDecimal(3)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.initialFertiliserImmobilisation" label="Initial fertiliser immobilisation" placeholder="0.5 adjust by temp" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.southIslandAdditionalUptake" label="South island add uptake" placeholder="20" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.urineParams.removeDenitrification" label="Remove denitrification" component={CheckboxField} />
                                        </GridCell>
                                        <div className="Grid-cell u-mt-lg">
                                            <div className="Table">
                                                <table className="u-tbl-fix">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan="2"></th>
                                                            {domain.calendarYear.map((m) => (
                                                                <th key={"monthly-urine-factors-" + m}>{m.substring(0, 3)}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="2">Uptake factors</td>
                                                            {orderedValues(`modelParams.urineParams.uptakeFactors`)}
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">Leach factors</td>
                                                            {orderedValues(`modelParams.urineParams.leachFactors`)}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                </div>
                <div className="modelParameterGrouping u-mt-sm">
                    <Field name="setCropParams" label="Set crop model parameters" component={CheckboxField} />
                    {showQuickTips && <Alert className="u-mb-0" type="help" text="Set parameters that will be passed through to the crop model during model execution instead of using the crop model parameters that are currently used in the model. See the 'Crop based nitrogen sub-model' technical manual chapter for more information on these parameters" />}
                    {run.setCropParams && (
                        <>
                            <Grid title="Crop model parameters">
                                {run.modelType === "Full" && (
                                    <>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.cropParams.DMPercentage" label="DM percentage" placeholder="% of dry matter" uom="%" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.cropParams.MEPerKgDM" label="ME" placeholder="Energy produced" uom="MJ/kg DM" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                        </GridCell>
                                    </>
                                )}
                                {run.modelType === "Lysimeter" && (
                                    <>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.cropParams.backgroundFactor" label="Background factor" placeholder="0.9" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.cropParams.inorganicProportion" label="Inorganic proportion" placeholder="19" uom="%" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.cropParams.releaseRateRoots" label="Release rate roots" placeholder="Enter fraction" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.cropParams.releaseRateStover" label="Release rate stover" placeholder="Enter fraction" format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                </div>
                {run.modelType === "Full" && (
                    <div className="modelParameterGrouping u-mt-sm">
                        <Field name="overridePasture" label="Set pasture parameters and settings" component={CheckboxField} />
                        {showQuickTips && <Alert className="u-mb-0" type="help" text="Set all analyses in the datasets pasture settings to the same when executing the model. See the 'Characteristics of pasture' technical manual chapter or more information on these parameters." />}
                        {run.overridePasture && (
                            <>
                                <Grid title="Pasture parameters">
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pastureOverride.pastureCategory" label="Pasture" options={refData.pastureTypes} placeholderEnabled={true} placeholder="Select a pasture" required component={SelectField} />
                                    </GridCell>
                                    {run.modelParams && run.modelParams.pastureOverride && run.modelParams.pastureOverride.pastureCategory === "Plantain" && (
                                        <GridCell className="u-lg-width1of4">
                                            <Field name="modelParams.pastureOverride.plantainPercentage" label="Plantain %" placeholder="0" uom="%" required format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                        </GridCell>
                                    )}
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pastureOverride.topography" label="Topography" placeholder="Select a topography" options={refData.slopes} required component={SelectField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pastureOverride.cloverLevels" label="Clover level" placeholder="Default settings" options={refData.cloverLevels} component={SelectField} />
                                    </GridCell>
                                </Grid>
                                <Grid>
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pastureOverride.pastureUtilisationPercentage" label="Pasture utilisation" uom="%" placeholder="Default" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pastureOverride.nitrogenConcentrationPercentage" label="Average pasture N concentration" uom="%" placeholder="Default" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                    </GridCell>
                                </Grid>
                            </>
                        )}
                    </div>
                )}
                {run.modelType === "Lysimeter" && (
                    <div className="modelParameterGrouping u-mt-sm">
                        <Field name="lysimeterPastureSettings" label="Set pasture parameters and settings" component={CheckboxField} />
                        {showQuickTips && <Alert className="u-mb-0" type="help" text="Set all analyses in the datasets pasture settings to the same when executing the model. See the 'Characteristics of pasture' technical manual chapter or more information on these parameters." />}
                        {run.lysimeterPastureSettings && (
                            <>
                                <Grid title="Pasture parameters">
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pasture.rootTurnoverFactor" label="Roots turnover factor" placeholder="0.3" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pasture.shootsFactor" label="Shoots factor" placeholder="0.1" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                    </GridCell>
                                </Grid>
                                <Grid title="Grass yield parameters">
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pasture.grassYieldParameters.yieldMultiplier" label="Yield multiplier" placeholder="1.2" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pasture.grassYieldParameters.minTemperature" label="Minimum temperature" placeholder="1.4" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pasture.grassYieldParameters.temperatureAdjuster" label="Temperature adjustment" placeholder="0.2" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                    </GridCell>
                                </Grid>
                                <Grid title="Clover yield parameters">
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pasture.cloverYieldParameters.yieldMultiplier" label="Yield multiplier" placeholder="1.2" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pasture.cloverYieldParameters.minTemperature" label="Minimum temperature" placeholder="1.4" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of4">
                                        <Field name="modelParams.pasture.cloverYieldParameters.temperatureAdjuster" label="Temperature adjustment" placeholder="0.2" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                    </GridCell>
                                </Grid>
                            </>
                        )}
                    </div>
                )}
            </PanelBody>
        </Panel>
    );
}

function MonthlyResultsPanel({ run, settings }) {
    const isAllowedDetaildRuns = settings && settings.allowedFunctions && settings.allowedFunctions.includes("DetailedRuns");
    if (!isAllowedDetaildRuns || run.isSensivityAnalysis || run.modelType !== "Full") return null;

    const info = "Will also save monthly analysis and block results.";

    return (
        <Panel title="Monthly results" info={info} midBlue>
            <PanelBody>
                <Grid>
                    <GridCell>
                        <Field name="detailedResults" label="Include monthly results" component={CheckboxField} />
                    </GridCell>
                </Grid>
            </PanelBody>
        </Panel>
    );
}

function SmapSoilSiblingUpdatesPanel({ run, settings }) {
    const isAllowedLatestSmap = settings && settings.allowedFunctions && settings.allowedFunctions.includes("LatestSmap");
    if (!isAllowedLatestSmap || run.isSensivityAnalysis || run.modelType !== "Full") return null;

    const info = 'Blocks within the dataset analyses that have SMap soils will be used with the most up to date SMap sibling data from Landcare. This will create two types of model runs to compare: a "Modelled" run (using the current analysis soil data) and a "Soil update" run (using the most up to date soil data from Landcare).';

    return (
        <Panel title="Climate and S-Map soil updates" info={info} midBlue>
            <PanelBody>
                <Grid>
                    <GridCell>
                        <Field name="type" options={RUN_TYPE_OPTIONS} component={RadioGroupField} inline />
                    </GridCell>
                </Grid>
            </PanelBody>
        </Panel>
    );
}

const RUN_TYPE_OPTIONS = [
    { value: "SoilUpdate", text: "Use latest S-Map sibling data", analysesMustBeMapped: false },
    { value: "UseDefaultSMapSoils", text: "Use spatially located default S-Map soils", analysesMustBeMapped: true },
    { value: "UseDefaultClimate", text: "Use spatially located default block climate", analysesMustBeMapped: true },
    { value: "UseDefaultClimateAndSMapSoils", text: "Use spatially located default block climate and S-Map soils", analysesMustBeMapped: true },
]
