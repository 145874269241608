import Alert from "components/Alert";
import Spinner from "components/Spinner/Spinner";
import ModalBodyAlert from "./ModalBodyAlert";

export default function ModalBody({ children, loading, info, warning, error, help, moreInfo }) {

    const showAlerts = info || warning || error || help;

    return (
        <div className={`Modal2-body ${loading ? "waiting" : ""}`}>
            {loading && (
                <div className="Tile-body-message">
                    <Spinner dark />
                    <p className="lead">Loading...</p>
                </div>
            )}
            {!loading && (
                <>
                    {showAlerts && (
                        <ModalBodyAlert>
                            {error && <Alert type="error" text={error} moreInfo={moreInfo} />}
                            {warning && <Alert type="warning" text={warning} moreInfo={moreInfo} />}
                            {info && <Alert type="info" text={info} moreInfo={moreInfo} />}
                            {help && <Alert type="help" text={help} moreInfo={moreInfo} />}
                        </ModalBodyAlert>
                    )}
                    {children}
                </>
            )}
        </div>
    )
}
