import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { useConfirm, useReferrer } from "common/hooks";
import { httpClient } from "common/httpClient";
import ActionLink from "components/ActionLink";

export default function DeleteOrgActionLink({ org, children = "Delete organisation", className = "IconLink--trash" }) {
    const deleteOrgAsync = useDeleteOrgAsync();
    const confirm = useConfirm();

    const confirmDeleteOrg = () => {
        confirm("Are you sure you want to delete this org?", () => deleteOrgAsync(org.id));
    };

    return (<>
        <ActionLink id={`delete-org-${org.id}`} title="Delete org" className={className} onClick={confirmDeleteOrg}>
            {children}
        </ActionLink>
    </>);
}

const useDeleteOrgAsync = () => {
    const queryClient = useQueryClient();
    const history = useHistory();
    const referrer = useReferrer();

    const mutation = useMutation({
        mutationFn: async (orgId) => httpClient.del(`admin/accounts/${orgId}`, { accountId: orgId }),
        // These callbacks ares called BEFORE their mutateAsync versions, even if the component is unmounted
        onSuccess: () => queryClient.removeQueries({ queryKey: ["orgs"] }),
    });

    return (orgId) => mutation.mutateAsync(orgId, {
        // These callbacks are called AFTER their useMutation versions but ONLY if the component is still mounted
        onSuccess: () => history.push(referrer)
    });
}
