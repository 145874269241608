import React from "react";
import moment from "moment";
import { TAB } from "containers/BudgetHome/PageTabs";

export const domainConsts = {
    entityStatus: {
        VALID: "Valid",
        ERROR: "Error",
        WARNING: "Warning",
        UNINITILIZED: "Uninitilized",
    },
};

export function factoryWetland() {
    return {
        type: "",
        condition: "",
        wetlandOffFarmType: "Undefined",
        catchmentAquitardDepth: "",
        catchmentConvergence: "",
        catchmentPercentages: [],
    };
}

export function factoryRiparianStrip() {
    return {
        runoffPercentages: [],
    };
}

export function factoryFarm() {
    return {
        id: "",
        name: "",
        createdBy: "",
        address: "",
        location: "",
        expires: 0,
        budgets: [],
        latitude: 0,
        longitude: 0,
    };
}

export function factoryIrrigator() {
    return {
        id: "",
        type: "",
        name: "",
        nutrientSource: "Overseerdefaultfixed",
        nutrientUnit: "mgl",
        nutrients: {},
        applications: [],
    };
}

export function factoryIrrigatorApplication() {
    return {
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        type: "Manual",
        schedule: "",
        systemDefinition: "Default",
        blockIds: [],
        units: "Paw",
    };
}

export function factoryFertiliser() {
    return {
        id: "",
        material: "Product",
        dairyFactoryEffluent: "",
        manufacturer: "",
        product: "",
        dmContent: "",
        percentageOrganicN: "",
        applications: [],
        nutrients: {},
    };
}

export function factoryFertiliserApplication() {
    return {
        id: "",
        months: [],
        unit: "kgha",
        blockIds: [],
    };
}

export function factoryBudget() {
    return {
        id: "",
        year: "",
        type: "",
        name: "",
        created: moment().format(),
        lastUpdated: moment().format(),
    };
}

export const fertiliserUnits = [
    { name: "tonnes/month", value: "tonnes" },
    { name: "kgs/month ", value: "kg" },
    { name: "kgs/ha/month", value: "kgha" },
];

export const fertiliserUnitsForDairy = [{ name: "litres/ha/month", value: "kgha" }];

export const irrigationMoistureAssesmentTypes = [
    { value: "None", name: "Fixed depth and return period" },
    { value: "BorderDyke", name: "Fixed depth and return period" },
    { value: "Flood", name: "Fixed depth and return period" },
    { value: "Visual", name: "Visual assessment/dig a hole" },
    { value: "Manual", name: "Soil water budget" },
    { value: "Probes", name: "Soil moisture sensors: probes/tapes" },
    { value: "Depth", name: "Enter application depth" },
];

export const WetlandCondition = {
    Class1: { key: "Class1", bold: "Class 1", text: "Fenced, well-vegetated, surface flow evenly distributed. There is no channelisation." },
    Class2: { key: "Class2", bold: "Class 2", text: "Unfenced. Lightly grazed by sheep. No visible signs of pugging. Surface flow evenly distributed." },
    Class3: { key: "Class3", bold: "Class 3", text: "Unfenced. Lightly grazed by sheep or set stocked cattle in summer - not mob grazed by cattle. At most, minor pugging damage. Sign of channelisation." },
    Class4: { key: "Class4", bold: "Class 4", text: "Unfenced. Accessible by cattle. Signs of pugging damage. Signs of channelisation." },
    Class5: { key: "Class5", bold: "Class 5", text: "Highly channelised wetlands even if fenced. Deeply incised. Inflowing water bypasses vegetated, organic soils." },
    Natural: { key: "Natural", bold: "Natural", text: "is defined as well vegetated, with evenly distributed surface flow and no channelization." },
    Artificialtype1: { key: "Artificialtype1", bold: "Type 1", text: "Flow path length to width ratio >5 (2 or more stage wetland, with even elongated channel or serpentine path created using internal bunds), well vegetated with good dispersion and even flow through the majority of wetland and minimal channelisation or dead-zones" },
    Artificialtype2: { key: "Artificialtype2", bold: "Type 2", text: "Single stage wetland with flow path length to width ratio >3, well vegetated with even flow through majority of wetland and minimal channelisation or dead-zones" },
    Artificialtype3: { key: "Artificialtype3", bold: "Type 3", text: "Single wetland with length to width ratio >3. Still well vegetated, but with some potential for flow channelisation and short-circuiting." },
};

export const RelativeProductivity = [
    { key: "Nodifferencebetweenblocks", text: "All blocks provide a similar yield and animals are rotated based on their feed requirements." },
    { key: "Relativeyieldfromsoilfertiliserinputs", text: "Animals are rotated based on their feed requirements, but there is a difference in yield between blocks. The model calculates this difference in yield based on soil and fertiliser inputs." },
    { key: "Relativeyield", text: "Provides an option to enter relative yields for each block if known. This is where the user wants to override the model estimations of yield." },
    { key: "Grazingdays", text: "Uses the number of days animals are grazed to set a relative value for each block. This is used when animals aren’t necessary allocated based on feed but rather some management practice or rotation." },
    { key: "Pastureassessment", text: "Use an assessment of pasture growth to set a relative value for each block." },
    { key: "Animalassessment", text: "Use an assessment of animal intake to set a relative value for each block." },
];

export const BlockStockRatios = [
    { key: "UserDefined", text: "Can assign enterprises to blocks and set percentage of pasture grazed for each enterprise. The model will allocate intake to each enterprise based on these percentages. Use this when enterprises graze specific areas but pasture is not allocated based on feed requirements of each enterprise. Should only be used in rare situations where animals do not graze based on feed requirements. This is likely to be experimental." },
    { key: "AnimalEquivalent", text: "Model will allocate enterprises evenly across blocks based on their total energy requirements. Use this when enterprises are not assigned to particular blocks on the farm. All enterprises graze all locations based on requirements and availability." },
    { key: "BasedOnAnimalsOnBlock", text: "Assign different enterprises to different blocks. The model will allocate ME intake for each enterprise based on the blocks that they have been assigned only. Use this when enterprises graze specific blocks and they graze based on requirements and availability." },
];

export const EffluentManagementSystem = [
    { key: "UseDairySystem", text: "Uses dairy/farm effluent syystem." },
    { key: "SprayFromSump", text: "All effluent is applied to land directly from a sump at the time it is generated (following each milking). There is no separation of solids. There is a volatilisation loss of N associated with the process of spraying." },
    { key: "PondDischarge", text: "Effluent is added to 2 stage oxidation ponds. The effluent is separated into atmospheric loss (N), sludge, and nutrients lost direct to freshwater." },
    { key: "HoldingPond", text: "Effluent is added to a holding pond with no separation of solids. For N, atmospheric losses vary with each treatment." },
    { key: "HoldingPondSeparated", text: "Effluent is added to a holding pond. Solids are separated from liquids. For N, atmospheric losses vary with each treatment." },
    { key: "Exported", text: "Effluent and hence nutrients are sent off farm." },
];

export const HoldingPondManagementTips = [
    { key: "SprayRegularly", text: "Some settling of effluent to sludge, the rest applied to land." },
    { key: "StirSprayRegularly", text: "Little sludge formed due to stirring process." },
    { key: "SprayInfrequently", text: "Effluent is held in a holding pond long enough for solids to settle out forming a sludge layers, typically about 2 weeks." },
    { key: "Exported", text: "Effluent is exported off the farm." },
];

export const OrganicFertiliserTypeTips = [
    { key: "CompostMulches", text: "Composts are made up of entirely organic material. If a mixture or organic and inorganic then use other organic material." },
    { key: "Dairyfactory", text: "By-products from milk processing factories and are typically liquids with low nutrient content." },
    { key: "Piggeryeffluent", text: "Effluent imported from piggery farm. Can be untreated, liquids or solids after spearation." },
    { key: "Importeddairyeffluent", text: "Effluent imported onto the farm from a dairy farm or associated pads." },
    { key: "Otherorganicmaterial", text: "Contains a mixture of organic and inorganic components." },
];

export const EnterpriseTypes = {
    Dairy: "Dairy",
    DairyReplacements: "DairyReplacements",
    DairyGoat: "DairyGoat",
    Deer: "Deer",
    Sheep: "Sheep",
    Beef: "Beef",
    OutdoorPigs: "OutdoorPigs",
    Other: "Other",
};

export const BlockType = {
    ProductiveFruit: "ProductiveFruit",
    ProductiveCrop: "ProductiveCrop",
    ProductivePasture: "ProductivePasture",
    ProductiveOutdoorPigs: "ProductiveOutdoorPigs",
    NonProductiveTreesAndScrub: "NonProductiveTreesAndScrub",
    NonProductiveHouse: "NonProductiveHouse",
    NonProductiveWetland: "NonProductiveWetland",
    NonProductiveRiparian: "NonProductiveRiparian",
    FodderCrop: "FodderCrop",
};

export const BlockTypeDescription = {
    ProductiveFruit: { key: BlockType.ProductiveFruit, bold: "Fruit", text: "Has a permanent fruit crop grown on it." },
    ProductiveCrop: { key: BlockType.ProductiveCrop, bold: "Crop", text: "Has arable or vegetable crop grown on it." },
    ProductivePasture: { key: BlockType.ProductivePasture, bold: "Pasture", text: "Grows pasture for animals (pastoral) or for supplements (cut and carry). It is assumed that cut and carry blocks have a flat topography." },
    NonProductiveTreesAndScrub: { key: BlockType.NonProductiveTreesAndScrub, bold: "Trees", text: "Covered in trees, scrub or native bush. No animal grazing occurs." },
    NonProductiveHouse: { key: BlockType.NonProductiveHouse, bold: "House", text: "Contains house and surrounding gardens. No animal grazing occurs." },
    NonProductiveWetland: { key: BlockType.NonProductiveWetland, bold: "Fenced wetland", text: "Area of land fenced off to all grazing. May include artificial wetlands." },
    FodderCrop: { key: BlockType.FodderCrop, bold: "Fodder crop", text: "Grows a fodder crop where animals graze." },
};

export const BudgetTypeDescription = {
    YearEnd: { key: "YearEnd", bold: "Year end", text: "Describes the farm system for a particular year in the past." },
    PrivateYearEnd: { key: "PrivateYearEnd", bold: "Private year end", text: "Year end analysis created as a guest and so is not visible to others yet." },
    Predictive: { key: "Predictive", bold: "Predictive", text: "Describes a future/proposed farm system." },
    Scenario: { key: "Scenario", bold: "Scenario", text: "Perform what if scenario analysis to understand the effect of change." },
};

export const SoilTextureTips = {
    Light: { key: "Light", bold: "Light", text: "Predominantly sand or loamy sand" },
    Medium: { key: "Medium", bold: "Medium", text: "Is used if neither light or heavy apply" },
    Heavy: { key: "Heavy", bold: "Heavy", text: "Predominantly clay (clay content >35%)" },
};

export const SoilNonStandLayerTips = {
    Sandy: { key: "Sandy", bold: "Sandy", text: "Subsoils where there is a sandy layer in the profile. They are often found on flood plains." },
    Stony: { key: "Stony", bold: "Stony", text: "Subsoils with a high stone content (> 50% stones) and the fine material is sandy, leading to reduced water holding capacities." },
    StonyMatrix: { key: "StonyMatrix", bold: "Stony matrix", text: "Subsoils with a moderate to high stone content (> 50% stones) and the fine material is loamy or clayey. Water holding capacity is higher than a stony soil but lower than a non-stony soil." },
};

export const IrrigatorTypeTips = {
    CentrePivot: {
        key: "CentrePivot",
        text: (
            <div>
                Laterals and pivots have a main pipeline supported above the field by a series of A-frame towers, each having two driven wheels at the base that keep the machines travelling in a straight formation. Water is discharged under pressure from sprinklers or sprayers mounted along the pipeline.
                <br />
                Laterals traverse the field in a straight path creating a rectangular wetted area. Water is supplied from a drag hose connected to a series of hydrants off a mainline.
                <br />A centre pivot machine consists of a lateral circulating around a fixed pivot point. Depending on the field layout, the pivot may complete the full circle or only a segment. The inside of the pivot covers less area than the outside of the pivot. The model assumes that water discharge is tailored accordingly to ensure the rate of application per unit area is the same along the length of the pivot.
            </div>
        ),
    },
    Flood: { key: "Flood", text: <div>Water is directed to areas of land via a network of channels and ditches. This method utilises the natural contours and fall of the land to distribute the water. It is common only in older Central Otago schemes. Furrow irrigation is practically unknown in New Zealand but if practiced should be included under controlled flood.</div> },
    Solid: {
        key: "Solid",
        text: (
            <div>
                Solid set irrigation systems are characterised by permanently fixed sprinklers on rigid supports. They are typically arranged in a triangular or rectangular grid pattern with spacing dependent on sprinkler throw capacity.
                <br />
                Solid set sprinkler systems are commonly used for over-head frost protection and under tree orchard irrigation. They are also used for nurseries and amenity irrigation including sports grounds and golf courses. Pastoral applications are increasing.
            </div>
        ),
    },
    Drip: {
        key: "Drip",
        text: (
            <div>
                A micro-irrigation system consists of a network of lateral pipelines fitted with low discharge emitters or sprinklers. It encompasses a number of methods; drip, subsurface, bubbler and micro-spray irrigation.
                <br />
                In a drip system, water is discharged under low pressure from emitters mounted on or built into the laterals which may lie on or above the soil surface, or be buried below the ground in the crop root zone. These systems are distinguished by the fact that water is delivered by the system to some point, for distribution laterally (and vertically) by the soil medium. Discharge rates are generally less than 8 litres/hour for point-source emitters and 12 litres/hour per metre for line-source emitters.
                <br />
                Micro-sprayer (micro-jet) and mini-sprinkler systems rely on the aerial spread of water droplets to achieve significant lateral displacement before water enters the soil. There may be further lateral spread within the soil itself. Discharge rates are typically less than 60 litres/hour
            </div>
        ),
    },
    Spray: {
        key: "Spray",
        text: (
            <div>
                A sprayline irrigation system irrigates a field by sequentially moving a static line of sprinklers to predetermined parallel locations across a field. Water is discharged under pressure from the sprinklers which are set at even intervals along a lateral pipeline. There are four basic types; hand-move pipes, side-roll systems, towable systems and long lateral systems.
                <ul>
                    <li>
                        <b>Hand-move pipes</b> are typically aluminium lengths that clip together with quick couplings to fit field dimensions. Shifting is manual, with pipe sections separated, moved and re-joined at each position. A sprinkler is mounted on a riser at one end of each pipe section, so the sprinkler spacing is set.
                    </li>
                    <li>
                        <b>Side-roll systems</b> consist of sprinklers mounted on aluminium or steel pipeline sections. Each section acts as the spindle of a centrally fitted wheel. Repeating units are joined to form the sprayline to fit field dimensions. The sprinklers are mounted on rotating couplings to ensure horizontal alignment regardless of spindle position. Sprinklers are mounted at pipeline height, and spacing is essentially set. Shifting is done by rolling the complete line sideways to the next position in the irrigation sequence.
                    </li>
                    <li>
                        <b>Towable spraylines</b> consist of smaller sized impact sprinklers fitted at set intervals on a alkathene pipe. The laterals are connected to hydrants off buried mainlines. The sprayline length is set. Shifting is by towing the complete sprayline by one end to the next position in the field. Each lateral is moved manually around 6-14 positions.
                    </li>
                    <li>
                        <b>Long-lateral systems</b> have medium sized impact sprinklers mounted on a moveable stand on the end of a length of alkathene pipe. The pipe is connected to hydrants off buried mainlines. The pipe length is typically 60-80 metres long and each sprinkler is moved manually usually by motorbike around 6-10 positions to cover an average 0.8 ha.
                    </li>
                </ul>
                Sprayline irrigation systems are typically arranged so successive shifts create a grid pattern of sprinkler positions. The spacing between sprinklers may vary considerably. The sprinkler layout pattern that is achieved in practice may be either square, triangular or somewhere in between. Multiple shifts over time overlap to water all of the area.
            </div>
        ),
    },
    Travelling: {
        key: "Travelling",
        text: (
            <div>
                There are three categories of travelling irrigators; either gun, fixed boom and rotating boom. Each consists of two parts:
                <ul>
                    <li>a winch mechanism with a reel or spool</li>
                    <li>a gun-cart carrying the water distribution system.</li>
                </ul>
                A travelling irrigator moves across a field sequentially, strip by strip drawing the gun-cart. It is connected to successive hydrants along a buried mainline.
                <br /> Travelling irrigators are characterised by either a soft hose or hard hose. Soft hose travelling irrigators have a wire rope anchored at the end of the run. The water distribution system and a winch are mounted on the gun-cart. The winch pulls the gun cart along by coiling the rope on to the reel. The hose, pulled by the gun cart, drags behind. At the end of each run the hose is flattened and coiled onto a drum when moving to the next position. Hard hose travellers have a large stationary reel anchored at the run end. The reel acts as a winch, coiling a delivery tube that both supplies water to the distribution system and drags the gun-cart along the field.
            </div>
        ),
    },
    BorderDyke: { key: "BorderDyke", text: <div>Water is carried by canal and race networks to head-races on the farm. A series of gates in the head-race progressively hold water back, raising its level until it spills over a sill and on to graded land contained within borders. The gates are controlled by clocks connected to a release mechanism to ensure and they fall at set intervals along the race.</div> },
};

export const SupplementStorageTips = {
    Excellent: {
        key: "Excellent",
        text: (
            <div>
                <ul>
                    <li>Bunker with concrete floor</li>
                    <li>Well consolidated stack, air tight</li>
                    <li>Covered</li>
                    <li>Inoculants used</li>
                    <li>Grass harvested at optimum DM</li>
                </ul>
            </div>
        ),
    },
    Average: {
        key: "Average",
        text: (
            <div>
                <ul>
                    <li>Bunker with concrete floor</li>
                    <li>Well consolidated stack, air tight</li>
                    <li>Covered</li>
                    <li>No inoculants used, OR</li>
                    <li>Harvested when grass is too dry</li>
                </ul>
            </div>
        ),
    },
    Poor: {
        key: "Poor",
        text: (
            <div>
                <ul>
                    <li>Permeable floor (e.g. soil)</li>
                    <li>Stack not air-tight due to either poor consolidation or poorly covered</li>
                    <li>No inoculants used</li>
                </ul>
            </div>
        ),
    },
};

export const SupplementStructureConditionTips = {
    Verygood: {
        key: "Verygood",
        text: (
            <div>
                <ul>
                    <li>Fed out in dry weather conditions</li>
                    <li>Loading directly into bin</li>
                    <li>No evidence of spilt silage around stack</li>
                    <li>Excellent stack management</li>
                </ul>
            </div>
        ),
    },
    Poor: {
        key: "Poor",
        text: (
            <div>
                <ul>
                    <li>Fed out day in advance or in wet conditions</li>
                    <li>Bins not emptied at each feed leading to spoiling of feed</li>
                    <li>Poor quality, not as palatable (&lt;10.0 MJME/kg DM)</li>
                    <li>Poor stack management and loading of silage into bins</li>
                </ul>
            </div>
        ),
    },
};

export const SupplementPaddockConditionTips = {
    Excellent: {
        key: "Excellent",
        text: (
            <div>
                <ul>
                    <li>Fed out in dry weather conditions</li>
                    <li>No wastage when loading wagon, silage not run over</li>
                    <li>Tight face</li>
                    <li>Silage good quality (&gt;10.5 MJME/kg DM)</li>
                </ul>
            </div>
        ),
    },
    Average: {
        key: "Average",
        text: (
            <div>
                <ul>
                    <li>Fed out in dry to moderately wet weather conditions</li>
                    <li>Loading and stack management average</li>
                    <li>Silage average quality (10.0-10.5 MJME/kg DM)</li>
                </ul>
            </div>
        ),
    },
    Poor: {
        key: "Poor",
        text: (
            <div>
                <ul>
                    <li>Fed out in wet conditions</li>
                    <li>High wastage loading wagon</li>
                    <li>Poor quality affecting palatability</li>
                </ul>
            </div>
        ),
    },
};

export const AnimalWeightTips = [
    {
        key: "Average",
        text: (
            <span>
                <b>Average mob weight: </b>Enter the greatest, average weight of animals, during the year, excluding the conceptus. Typically this is the weight in autumn. If no weight is entered, the default weight based on breed is used.
            </span>
        ),
    },
    {
        key: "Mature",
        text: (
            <span>
                <b>Mature weight: </b>This is the average weight of animals in the mob when all are fully grown (&gt; 5 years old). This weight may not be reached on-farm, or before slaughter occurs if the animals are sold before they are fully mature. If not known, leave blank or enter zero and a default weight based on breed will be used.
            </span>
        ),
    },
    {
        key: "End",
        text: (
            <span>
                <b>End weight: </b>This is the weight of the last animals in this mob. This will be the weight of ay remaining animals or if all sold, the weight of the animals in that sale. Where default weight is used for earlier sales these animals track to this same weight.
            </span>
        ),
    },
];

export const SupplementaryFeedAssumptions = [{ key: "Dairy goat feed (DDG)", bold: "Assumptions for DDG", text: "N content of crude protein is 16 %. ME is translated from Mcal to ME by multiplying by 4.1868. Digestibility is estimated from ME content. Assume embodied CO2 and energy costs are similar to Brewers grain." }];

export const StructureTypeTips = {
    FeedingPad: { key: "FeedingPad", text: "A feed pad is a hard surface area (usually concrete) normally sited adjacent to the farm dairy where stock can be held for some time (1-2 hours), either prior to, or after, milking, and provided with supplementary feed. Feed pads are usually included in a farm system to improve the efficiency of supplementary feeding compared to paddock feeding. If the feed pad is used in such a manner that no extra time is spent on the milking platform, yards, or feed pad (i.e. there is no additional effluent generated other than that associated with milking) then use the separate in-shed feeding option.<br/><b>Note:</b> If the feed pad has a lining (e.g. sawdust, bark), then use options for a wintering pad. If animals use a feed pad on the way to, or as part of, a wintering pad or animal shelter, then it is better to set this up as a wintering pad/animal shelter and set time on concrete to the time spent on the feed pad." },
    StandoffPad: { key: "StandoffPad", text: "A stand-off or loafing pad is a specially built area where stock can be withheld from grazing during wet periods to minimise damage to pasture. These pads are typically constructed of free-draining materials such as sawdust, bark, wood chips, lime, or a soft metal (rock) mix. Because cows may be withheld for extended periods (20 hours/day) they need 8-10 m2 per cow. There is no provision for stock feeding while the animals are on the pad.<br/><b>Note:</b> A stand-off or loafing pad will only have an impact on the nutrient budget if it is used frequently." },
    CoveredWinteringPad: { key: "CoveredWinteringPad", text: 'A wintering pad or animal shelter is a specially built area constructed where animals are withheld from pasture for extended periods and supplementary feeds are brought to them. As the animals may spend several months on the pad and/or in shelter, the animals require an area to lie down as in stand-off pads, as well as additional space for feeding (including separate concrete areas, known as "the concrete feeding apron"). For some wintering pads, animals are allowed to graze pastures for a limited time (3-6 hours) each day. In this case they may not be fed supplements.' },
    UncoveredWinteringPad: { key: "UncoveredWinteringPad", text: 'A wintering pad or animal shelter is a specially built area constructed where animals are withheld from pasture for extended periods and supplementary feeds are brought to them. As the animals may spend several months on the pad and/or in shelter, the animals require an area to lie down as in stand-off pads, as well as additional space for feeding (including separate concrete areas, known as "the concrete feeding apron"). For some wintering pads, animals are allowed to graze pastures for a limited time (3-6 hours) each day. In this case they may not be fed supplements.' },
    MilkingShed: { key: "MilkingShed", text: "Use this page when meal/supplements are fed to animals while milking (in-shed feeding). In this case, it is assumed that the animals spend no extra time in the milking shed, and hence there is no additional effluent to be managed. If the animals spend additional time in the shed or on a separate pad while feeding, then use the Feed pad option." },
};

export const FeedpadCleaningTips = {
    Flushing: { key: "Flushing", text: "All flushed material is treated the same as the dairy effluent system" },
    FlushingSeparated: { key: "FlushingSeparated", text: "Please define how the soilds are managed by selecting the manage effluent button below. Liquids are treated the same as the dairy effluent system." },
    Scraping: { key: "Scraping", text: "Scraped material is treated as a solid. Please define how they are managed using the manage effluent button below." },
    ScrapingSeparated: { key: "ScrapingSeparated", text: "Please define how the soilds are managed by selecting the manage effluent button below. Liquids are treated the same as the dairy effluent system." },
    ScrapingAdded: { key: "ScrapingAdded", text: "All scraped material is treated the same as the dairy effluent system." },
};

export const TopographyTips = {
    Flat: { key: "Flat", bold: "Flat", text: "Flat", slope: "0" + String.fromCharCode(176) + " to 7" + String.fromCharCode(176), lriClass: "A-B" },
    Rolling: { key: "Rolling", bold: "Rolling", text: "Area mostly navigable by tractor", slope: "8" + String.fromCharCode(176) + " to 15" + String.fromCharCode(176), lriClass: "C" },
    Easyhill: { key: "Easyhill", bold: "Easy hill", text: ">50% area navigable by tractor", slope: "16" + String.fromCharCode(176) + " to 25" + String.fromCharCode(176), lriClass: "D-E" },
    Steephill: { key: "Steephill", bold: "Steep hill", text: "<50% area navigable by tractor", slope: "26" + String.fromCharCode(176) + " or more", lriClass: "F-G" },
};

export const PuggingTips = {
    Rare: { key: "Rare", bold: "Rare", text: "Can be grazed with minimal pugging damage most of the year." },
    Occasional: { key: "Occasional", bold: "Occasional", text: "Must be grazed with caution during winter to avoid pugging damage." },
    Winter: { key: "Winter", bold: "Winter", text: "Heavy pugging damage occurs if grazed anytime over winter." },
    Winterorrain: { key: "Winterorrain", bold: "Winter or rain", text: "Heavy pugging damage occurs if grazed anytime over winter, and pugging damage occurs after rain events." },
};

export const AnimalGrazingTips = {
    NoAnimals: { key: "NoAnimals", bold: "No animals", text: "No animals grazing." },
    EnterpriseStock: { key: "EnterpriseStock", bold: "Enterprise stock", text: "A default grazing regime for your enterprise stock will be assumed. Edit the distribution from the animals tab if you wish to override defaults." },
    NonFarmAnimals: { key: "NonFarmAnimals", bold: "Non-farm animals", text: "Animals that are not included in your enterprise stock numbers and do not interact with other parts of the farm." },
};

export const WetlandType = {
    TypeA: {
        wetness: "Water always flows",
        vegetation: "Dominated by sedges and reeds. May contain flaxes, willows, etc.",
        stock: "Easily damaged by mob stocking of cattle. Avoided by sheep.",
    },
    TypeB: {
        wetness: "Flows most of the year. Dry in drought.",
        vegetation: "Abundant sedges and rushes.",
        stock: "Moderate pugging if cattle have access all year. Avoided by sheep in winter.",
    },
    TypeC: {
        wetness: "Flows in autumn, winter and spring. Dry in summer.",
        vegetation: "Some sedges and reeds. Dominated by pasture grasses.",
        stock: "Pugging in winter if cattle have access. Grazed by sheep much of the year.",
    },
    TypeD: {
        wetness: "Only flows during rain.",
        vegetation: "Pasture grasses.",
        stock: "Grazed by sheep all year.",
    },
};
export const winterMonths = ["April", "May", "June", "July", "August", "September"];
export const farmYear = ["July", "August", "September", "October", "November", "December", "January", "February", "March", "April", "May", "June"];
export const calendarYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const nutrients = ["N", "P", "K", "S", "Ca", "Mg", "Na"];

export const tabHelpMessages = {
    [TAB.BLOCKS.route]: 'This screen shows the blocks for this analysis. The "Map view" shows the blocks visually on a map. The "List view" also shows the climate details for each bock. A block may be made up of several land parcels but they should have very similar characteristics and management applied. Each block may be drawn on the map to provide a visual representation, approximate size and locate climate and soil information; however, the block outline does not have to be exact as the accuracy of the drawing it has no effect on the modelled results.',
    [TAB.CLIMATE.route]: "This screen shows the climate details for each block. The climate is based on a NIWA 30 year average for the block's location. It does not represent the climate for a particular year. In this way the model can compare the effect of changes in the farm system over time rather then changes in the weather. If snow fall occurs on the farm you can enter it here.",
    [TAB.SOIL.route]: 'This screen shows the soils and soil tests for this farm. Each block may contain up to three soils. A soil test can be applied to mutiple blocks. If you do not enter a soil test for a block, default soil fertility levels will be generated and shown below. You can override a default test by editing it and saving the new values. If a block is marked as "Using auto assigned soils", then the system has auto assigned the top three dominant soil siblings from S-Map when the block was created or edited. These can be reviewed and edited by clicking on the block name in the list below.',
    [TAB.WETLANDS.route]: "This screen shows any wetlands or drainage systems set up for blocks. You can add mole/tile or other artificial drainage systems and then add blocks to each system. You can add unfenced wetlands or add riparian strips to pasture blocks.",
    [TAB.CROPS.route]: "This screen shows all pasture and crops grown on the farm. You can add pasture, crops or fruit to blocks. You can also add fodder crops that rotate through the pasture blocks.",
    [TAB.ANIMALS.route]: "This screen shows all animal enterprises for the farm. Use animal distribution to adjust how the model distributes animals to blocks or simply let the model use default values. If stock numbers have been entered, the green table shows the number of animals at the end of each month for each enterprise. When the model has run, the estimated revised stock unit (RSU) is shown for each block and month. RSU is 6000 MJ ME intake/yr.",
    [TAB.EFFLUENT.route]: "This screen shows the structures, effluent systems and effluent applications for the farm. Each structure defines the animals that are on the structure, how effluent is captured, and the system used to manage/distribute the effluent.",
    [TAB.SUPPLEMENTS.route]: "This screen shows the supplements harvested or imported and where they were fed out. Multiple harvests can be entered to create a total amount that can be distributed to different locations or animals.",
    [TAB.FERTILISER.route]: "This screen shows the fertiliser and DCD applications for this farm. Add fertiliser by selecting 'Add Product', 'Add Lime', 'Add Organic', 'Add Soluble' or 'Add DCD' below. You can add applications for a block by clicking on the block or by clicking on 'Add Application' in the task bar below. Merge applications combines the same applications across multiple blocks into a single application that is applied to the set of blocks. Split applications separates them back out into an application per block.",
    [TAB.IRRIGATION.route]: "This screen shows the irrigation systems for this farm. Each irrigator may be applied to multiple blocks with different application methods. The irrigated area can be drawn, and the system will determine the percentage of the block that is irrigated by that irrigator. If the model has run the amount applied in mm is shown for each block and month.",
    [TAB.GHG.route]: "This screen provides a way to override defaults for GHG modelling.",
};

export const animalEventDates = [
    { value: "0701", text: "01 July" },
    { value: "0702", text: "02 July" },
    { value: "0703", text: "03 July" },
    { value: "0704", text: "04 July" },
    { value: "0705", text: "05 July" },
    { value: "0706", text: "06 July" },
    { value: "0707", text: "07 July" },
    { value: "0708", text: "08 July" },
    { value: "0709", text: "09 July" },
    { value: "0710", text: "10 July" },
    { value: "0711", text: "11 July" },
    { value: "0712", text: "12 July" },
    { value: "0713", text: "13 July" },
    { value: "0714", text: "14 July" },
    { value: "0715", text: "15 July" },
    { value: "0716", text: "16 July" },
    { value: "0717", text: "17 July" },
    { value: "0718", text: "18 July" },
    { value: "0719", text: "19 July" },
    { value: "0720", text: "20 July" },
    { value: "0721", text: "21 July" },
    { value: "0722", text: "22 July" },
    { value: "0723", text: "23 July" },
    { value: "0724", text: "24 July" },
    { value: "0725", text: "25 July" },
    { value: "0726", text: "26 July" },
    { value: "0727", text: "27 July" },
    { value: "0728", text: "28 July" },
    { value: "0729", text: "29 July" },
    { value: "0730", text: "30 July" },
    { value: "0731", text: "31 July" },
    { value: "0801", text: "01 August" },
    { value: "0802", text: "02 August" },
    { value: "0803", text: "03 August" },
    { value: "0804", text: "04 August" },
    { value: "0805", text: "05 August" },
    { value: "0806", text: "06 August" },
    { value: "0807", text: "07 August" },
    { value: "0808", text: "08 August" },
    { value: "0809", text: "09 August" },
    { value: "0810", text: "10 August" },
    { value: "0811", text: "11 August" },
    { value: "0812", text: "12 August" },
    { value: "0813", text: "13 August" },
    { value: "0814", text: "14 August" },
    { value: "0815", text: "15 August" },
    { value: "0816", text: "16 August" },
    { value: "0817", text: "17 August" },
    { value: "0818", text: "18 August" },
    { value: "0819", text: "19 August" },
    { value: "0820", text: "20 August" },
    { value: "0821", text: "21 August" },
    { value: "0822", text: "22 August" },
    { value: "0823", text: "23 August" },
    { value: "0824", text: "24 August" },
    { value: "0825", text: "25 August" },
    { value: "0826", text: "26 August" },
    { value: "0827", text: "27 August" },
    { value: "0828", text: "28 August" },
    { value: "0829", text: "29 August" },
    { value: "0830", text: "30 August" },
    { value: "0831", text: "31 August" },
    { value: "0901", text: "01 September" },
    { value: "0902", text: "02 September" },
    { value: "0903", text: "03 September" },
    { value: "0904", text: "04 September" },
    { value: "0905", text: "05 September" },
    { value: "0906", text: "06 September" },
    { value: "0907", text: "07 September" },
    { value: "0908", text: "08 September" },
    { value: "0909", text: "09 September" },
    { value: "0910", text: "10 September" },
    { value: "0911", text: "11 September" },
    { value: "0912", text: "12 September" },
    { value: "0913", text: "13 September" },
    { value: "0914", text: "14 September" },
    { value: "0915", text: "15 September" },
    { value: "0916", text: "16 September" },
    { value: "0917", text: "17 September" },
    { value: "0918", text: "18 September" },
    { value: "0919", text: "19 September" },
    { value: "0920", text: "20 September" },
    { value: "0921", text: "21 September" },
    { value: "0922", text: "22 September" },
    { value: "0923", text: "23 September" },
    { value: "0924", text: "24 September" },
    { value: "0925", text: "25 September" },
    { value: "0926", text: "26 September" },
    { value: "0927", text: "27 September" },
    { value: "0928", text: "28 September" },
    { value: "0929", text: "29 September" },
    { value: "0930", text: "30 September" },
    { value: "1001", text: "01 October" },
    { value: "1002", text: "02 October" },
    { value: "1003", text: "03 October" },
    { value: "1004", text: "04 October" },
    { value: "1005", text: "05 October" },
    { value: "1006", text: "06 October" },
    { value: "1007", text: "07 October" },
    { value: "1008", text: "08 October" },
    { value: "1009", text: "09 October" },
    { value: "1010", text: "10 October" },
    { value: "1011", text: "11 October" },
    { value: "1012", text: "12 October" },
    { value: "1013", text: "13 October" },
    { value: "1014", text: "14 October" },
    { value: "1015", text: "15 October" },
    { value: "1016", text: "16 October" },
    { value: "1017", text: "17 October" },
    { value: "1018", text: "18 October" },
    { value: "1019", text: "19 October" },
    { value: "1020", text: "20 October" },
    { value: "1021", text: "21 October" },
    { value: "1022", text: "22 October" },
    { value: "1023", text: "23 October" },
    { value: "1024", text: "24 October" },
    { value: "1025", text: "25 October" },
    { value: "1026", text: "26 October" },
    { value: "1027", text: "27 October" },
    { value: "1028", text: "28 October" },
    { value: "1029", text: "29 October" },
    { value: "1030", text: "30 October" },
    { value: "1031", text: "31 October" },
    { value: "1101", text: "01 November" },
    { value: "1102", text: "02 November" },
    { value: "1103", text: "03 November" },
    { value: "1104", text: "04 November" },
    { value: "1105", text: "05 November" },
    { value: "1106", text: "06 November" },
    { value: "1107", text: "07 November" },
    { value: "1108", text: "08 November" },
    { value: "1109", text: "09 November" },
    { value: "1110", text: "10 November" },
    { value: "1111", text: "11 November" },
    { value: "1112", text: "12 November" },
    { value: "1113", text: "13 November" },
    { value: "1114", text: "14 November" },
    { value: "1115", text: "15 November" },
    { value: "1116", text: "16 November" },
    { value: "1117", text: "17 November" },
    { value: "1118", text: "18 November" },
    { value: "1119", text: "19 November" },
    { value: "1120", text: "20 November" },
    { value: "1121", text: "21 November" },
    { value: "1122", text: "22 November" },
    { value: "1123", text: "23 November" },
    { value: "1124", text: "24 November" },
    { value: "1125", text: "25 November" },
    { value: "1126", text: "26 November" },
    { value: "1127", text: "27 November" },
    { value: "1128", text: "28 November" },
    { value: "1129", text: "29 November" },
    { value: "1130", text: "30 November" },
    { value: "1201", text: "01 December" },
    { value: "1202", text: "02 December" },
    { value: "1203", text: "03 December" },
    { value: "1204", text: "04 December" },
    { value: "1205", text: "05 December" },
    { value: "1206", text: "06 December" },
    { value: "1207", text: "07 December" },
    { value: "1208", text: "08 December" },
    { value: "1209", text: "09 December" },
    { value: "1210", text: "10 December" },
    { value: "1211", text: "11 December" },
    { value: "1212", text: "12 December" },
    { value: "1213", text: "13 December" },
    { value: "1214", text: "14 December" },
    { value: "1215", text: "15 December" },
    { value: "1216", text: "16 December" },
    { value: "1217", text: "17 December" },
    { value: "1218", text: "18 December" },
    { value: "1219", text: "19 December" },
    { value: "1220", text: "20 December" },
    { value: "1221", text: "21 December" },
    { value: "1222", text: "22 December" },
    { value: "1223", text: "23 December" },
    { value: "1224", text: "24 December" },
    { value: "1225", text: "25 December" },
    { value: "1226", text: "26 December" },
    { value: "1227", text: "27 December" },
    { value: "1228", text: "28 December" },
    { value: "1229", text: "29 December" },
    { value: "1230", text: "30 December" },
    { value: "1231", text: "31 December" },
    { value: "0101", text: "01 January" },
    { value: "0102", text: "02 January" },
    { value: "0103", text: "03 January" },
    { value: "0104", text: "04 January" },
    { value: "0105", text: "05 January" },
    { value: "0106", text: "06 January" },
    { value: "0107", text: "07 January" },
    { value: "0108", text: "08 January" },
    { value: "0109", text: "09 January" },
    { value: "0110", text: "10 January" },
    { value: "0111", text: "11 January" },
    { value: "0112", text: "12 January" },
    { value: "0113", text: "13 January" },
    { value: "0114", text: "14 January" },
    { value: "0115", text: "15 January" },
    { value: "0116", text: "16 January" },
    { value: "0117", text: "17 January" },
    { value: "0118", text: "18 January" },
    { value: "0119", text: "19 January" },
    { value: "0120", text: "20 January" },
    { value: "0121", text: "21 January" },
    { value: "0122", text: "22 January" },
    { value: "0123", text: "23 January" },
    { value: "0124", text: "24 January" },
    { value: "0125", text: "25 January" },
    { value: "0126", text: "26 January" },
    { value: "0127", text: "27 January" },
    { value: "0128", text: "28 January" },
    { value: "0129", text: "29 January" },
    { value: "0130", text: "30 January" },
    { value: "0131", text: "31 January" },
    { value: "0201", text: "01 February" },
    { value: "0202", text: "02 February" },
    { value: "0203", text: "03 February" },
    { value: "0204", text: "04 February" },
    { value: "0205", text: "05 February" },
    { value: "0206", text: "06 February" },
    { value: "0207", text: "07 February" },
    { value: "0208", text: "08 February" },
    { value: "0209", text: "09 February" },
    { value: "0210", text: "10 February" },
    { value: "0211", text: "11 February" },
    { value: "0212", text: "12 February" },
    { value: "0213", text: "13 February" },
    { value: "0214", text: "14 February" },
    { value: "0215", text: "15 February" },
    { value: "0216", text: "16 February" },
    { value: "0217", text: "17 February" },
    { value: "0218", text: "18 February" },
    { value: "0219", text: "19 February" },
    { value: "0220", text: "20 February" },
    { value: "0221", text: "21 February" },
    { value: "0222", text: "22 February" },
    { value: "0223", text: "23 February" },
    { value: "0224", text: "24 February" },
    { value: "0225", text: "25 February" },
    { value: "0226", text: "26 February" },
    { value: "0227", text: "27 February" },
    { value: "0228", text: "28 February" },
    { value: "0301", text: "01 March" },
    { value: "0302", text: "02 March" },
    { value: "0303", text: "03 March" },
    { value: "0304", text: "04 March" },
    { value: "0305", text: "05 March" },
    { value: "0306", text: "06 March" },
    { value: "0307", text: "07 March" },
    { value: "0308", text: "08 March" },
    { value: "0309", text: "09 March" },
    { value: "0310", text: "10 March" },
    { value: "0311", text: "11 March" },
    { value: "0312", text: "12 March" },
    { value: "0313", text: "13 March" },
    { value: "0314", text: "14 March" },
    { value: "0315", text: "15 March" },
    { value: "0316", text: "16 March" },
    { value: "0317", text: "17 March" },
    { value: "0318", text: "18 March" },
    { value: "0319", text: "19 March" },
    { value: "0320", text: "20 March" },
    { value: "0321", text: "21 March" },
    { value: "0322", text: "22 March" },
    { value: "0323", text: "23 March" },
    { value: "0324", text: "24 March" },
    { value: "0325", text: "25 March" },
    { value: "0326", text: "26 March" },
    { value: "0327", text: "27 March" },
    { value: "0328", text: "28 March" },
    { value: "0329", text: "29 March" },
    { value: "0330", text: "30 March" },
    { value: "0331", text: "31 March" },
    { value: "0401", text: "01 April" },
    { value: "0402", text: "02 April" },
    { value: "0403", text: "03 April" },
    { value: "0404", text: "04 April" },
    { value: "0405", text: "05 April" },
    { value: "0406", text: "06 April" },
    { value: "0407", text: "07 April" },
    { value: "0408", text: "08 April" },
    { value: "0409", text: "09 April" },
    { value: "0410", text: "10 April" },
    { value: "0411", text: "11 April" },
    { value: "0412", text: "12 April" },
    { value: "0413", text: "13 April" },
    { value: "0414", text: "14 April" },
    { value: "0415", text: "15 April" },
    { value: "0416", text: "16 April" },
    { value: "0417", text: "17 April" },
    { value: "0418", text: "18 April" },
    { value: "0419", text: "19 April" },
    { value: "0420", text: "20 April" },
    { value: "0421", text: "21 April" },
    { value: "0422", text: "22 April" },
    { value: "0423", text: "23 April" },
    { value: "0424", text: "24 April" },
    { value: "0425", text: "25 April" },
    { value: "0426", text: "26 April" },
    { value: "0427", text: "27 April" },
    { value: "0428", text: "28 April" },
    { value: "0429", text: "29 April" },
    { value: "0430", text: "30 April" },
    { value: "0501", text: "01 May" },
    { value: "0502", text: "02 May" },
    { value: "0503", text: "03 May" },
    { value: "0504", text: "04 May" },
    { value: "0505", text: "05 May" },
    { value: "0506", text: "06 May" },
    { value: "0507", text: "07 May" },
    { value: "0508", text: "08 May" },
    { value: "0509", text: "09 May" },
    { value: "0510", text: "10 May" },
    { value: "0511", text: "11 May" },
    { value: "0512", text: "12 May" },
    { value: "0513", text: "13 May" },
    { value: "0514", text: "14 May" },
    { value: "0515", text: "15 May" },
    { value: "0516", text: "16 May" },
    { value: "0517", text: "17 May" },
    { value: "0518", text: "18 May" },
    { value: "0519", text: "19 May" },
    { value: "0520", text: "20 May" },
    { value: "0521", text: "21 May" },
    { value: "0522", text: "22 May" },
    { value: "0523", text: "23 May" },
    { value: "0524", text: "24 May" },
    { value: "0525", text: "25 May" },
    { value: "0526", text: "26 May" },
    { value: "0527", text: "27 May" },
    { value: "0528", text: "28 May" },
    { value: "0529", text: "29 May" },
    { value: "0530", text: "30 May" },
    { value: "0531", text: "31 May" },
    { value: "0601", text: "01 June" },
    { value: "0602", text: "02 June" },
    { value: "0603", text: "03 June" },
    { value: "0604", text: "04 June" },
    { value: "0605", text: "05 June" },
    { value: "0606", text: "06 June" },
    { value: "0607", text: "07 June" },
    { value: "0608", text: "08 June" },
    { value: "0609", text: "09 June" },
    { value: "0610", text: "10 June" },
    { value: "0611", text: "11 June" },
    { value: "0612", text: "12 June" },
    { value: "0613", text: "13 June" },
    { value: "0614", text: "14 June" },
    { value: "0615", text: "15 June" },
    { value: "0616", text: "16 June" },
    { value: "0617", text: "17 June" },
    { value: "0618", text: "18 June" },
    { value: "0619", text: "19 June" },
    { value: "0620", text: "20 June" },
    { value: "0621", text: "21 June" },
    { value: "0622", text: "22 June" },
    { value: "0623", text: "23 June" },
    { value: "0624", text: "24 June" },
    { value: "0625", text: "25 June" },
    { value: "0626", text: "26 June" },
    { value: "0627", text: "27 June" },
    { value: "0628", text: "28 June" },
    { value: "0629", text: "29 June" },
    { value: "0630", text: "30 June" },
];
