import { useQuery } from "@tanstack/react-query";
import { useAuthContext, useConfirm, useIsStudentUser } from "common/hooks";
import { httpClient } from "common/httpClient";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import { useFarmGroupInvitationResponseModal } from "containers/FarmGroups/FarmGroupInvitationResponseModal";
import { useFarmAccessRequestResponseModal } from "./Modals/FarmAccessRequestResponseModal";
import { useFarmOwnershipNominationResponseModal } from "./Modals/FarmOwnershipNominationResponseModal";
import { useOrgInvitationResposneModal } from "./Modals/OrgInvitationResposneModal";
import { useMyFarmGroups, useMyFarmAccessRequests, useResendFarmAccessRequestAsync } from "containers/hooks";

export default function MyRequiredActions() {
    const myActions = useMyRequiredActions();
    const [farmGroupInvitationResponseModal, openFarmGroupInvitationResponseModal] = useFarmGroupInvitationResponseModal();
    const [farmAccessRequestResponseModal, openFarmAccessRequestResponseModal] = useFarmAccessRequestResponseModal();
    const [farmOwnershipNominationResponseModal, openFarmOwnershipNominationResponseModal] = useFarmOwnershipNominationResponseModal();
    const [orgInvitationResposneModal, openOrgInvitationResposneModal] = useOrgInvitationResposneModal();
    const resendFarmAccessRequestAsync = useResendFarmAccessRequestAsync();
    const confirm = useConfirm();
    const authContext = useAuthContext();

    const thereAreNoActions = myActions.actionCount === 0;
    if (thereAreNoActions) {
        return null;
    }

    const respondToInvitationToJoinNewOrg = (invitation) => () => {
        openOrgInvitationResposneModal(invitation);
    };

    const respondToFarmOwnershipNomination = (nomination) => () => {
        openFarmOwnershipNominationResponseModal(nomination);
    };

    const respondToRequestToAccessYourFarm = (accessRequest) => () => {
        openFarmAccessRequestResponseModal(accessRequest);
    };

    const respondToInvitationToJoinFarmGroup = (farmGroup) => () => {
        openFarmGroupInvitationResponseModal(farmGroup);
    };

    const resendRequestToAccessAFarm = (accessRequest) => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to resend your request to access,'${accessRequest.farmName}'?`, async () => {
            await resendFarmAccessRequestAsync(accessRequest);
        });
    };

    return (
        <>
            <Panel title={`Action required (${myActions.actionCount})`} open={!authContext.isSystemAdmin} iconClassName="IconLink--alert" orange>
                <PanelBody>
                    <div className="Table">
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="20">Action</th>
                                    <th data-width="20">Farm</th>
                                    <th data-width="10">Requested on</th>
                                    <th data-width="30">Details</th>
                                    <th className="th--shrink">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {myActions.invitationsToJoinNewOrgs.map((action, i) => {
                                    return (
                                        <tr key={i} className="hover">
                                            <td valign="top">{action.action}</td>
                                            <td valign="top">-</td>
                                            <td valign="top">{action.when}</td>
                                            <td valign="top">{action.details}</td>
                                            <td valign="top">
                                                <div className="u-flex">
                                                    <ActionLink id={`respond-to-invite-to-join-new-org-${action.payload.id}`} className="IconLink--edit" onClick={respondToInvitationToJoinNewOrg(action.payload)}>
                                                        Respond
                                                    </ActionLink>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {myActions.farmOwnershipNominations.map((action, i) => {
                                    return (
                                        <tr key={action.payload.id} className="hover">
                                            <td valign="top">{action.action}</td>
                                            <td valign="top" id={`farmname_${i}`}>
                                                <h4 className="u-mt-0">{action.payload.farmName}</h4>
                                                {action.payload.farmAddress}
                                            </td>
                                            <td valign="top">{action.when}</td>
                                            <td valign="top">{action.details}</td>
                                            <td valign="top">
                                                <div className="u-flex">
                                                    <ActionLink id={`respond-to-farm-ownership-nomination-${action.payload.id}`} className="IconLink--edit" onClick={respondToFarmOwnershipNomination(action.payload)}>
                                                        Respond
                                                    </ActionLink>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {myActions.requestsToAccessYourFarms.map((action, i) => {
                                    return (
                                        <tr key={i} className="hover">
                                            <td valign="top">{action.action}</td>
                                            <td valign="top" id={`farmname_${i}`}>
                                                <h4 className="u-mt-0">{action.payload.farmName}</h4>
                                                {action.payload.farmAddress}
                                            </td>
                                            <td valign="top">{action.when}</td>
                                            <td valign="top">{action.details}</td>
                                            <td valign="top">
                                                <div className="u-flex">
                                                    <ActionLink id={`respond-to-farm-access-request-${action.payload.id}`} className="IconLink--edit" onClick={respondToRequestToAccessYourFarm(action.payload)}>
                                                        Respond
                                                    </ActionLink>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {authContext.isSystemAdmin &&
                                    myActions.outstandingFarmAccessRequestsForSysAdmin.map((action, i) => {
                                        return (
                                            <tr key={i} className="hover">
                                                <td valign="top">{action.action}</td>
                                                <td valign="top" id={`farmname_${i}`}>
                                                    <h4 className="u-mt-0">{action.payload.farmName}</h4>
                                                    {action.payload.farmAddress}
                                                </td>
                                                <td valign="top">{action.when}</td>
                                                <td valign="top">{action.details}</td>
                                                <td valign="top">
                                                    <div className="u-flex">
                                                        <ActionLink id={`respond-to-farm-access-request-${action.payload.id}`} className="IconLink--edit" onClick={respondToRequestToAccessYourFarm(action.payload)}>
                                                            Respond
                                                        </ActionLink>
                                                        <ActionLink id={`resend-farm-access-request-${action.payload.id}`} className="IconLink--shield u-ml-sm" onClick={resendRequestToAccessAFarm(action.payload)}>
                                                            Resend
                                                        </ActionLink>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {myActions.farmGroups.map((action, i) => {
                                    return (
                                        <tr key={i} className="hover">
                                            <td valign="top">{action.action}</td>
                                            <td valign="top" id={`farmname_${i}`}>
                                                <h4 className="u-mt-0">{action.payload.farmName}</h4>
                                                {action.payload.farmAddress}
                                            </td>
                                            <td valign="top">{action.when}</td>
                                            <td valign="top">{action.details}</td>
                                            <td valign="top">
                                                <div className="u-flex">
                                                    <ActionLink id={`respond-to-farm-group-invitation-${action.payload.id}`} className="IconLink--edit" onClick={respondToInvitationToJoinFarmGroup(action.payload)}>
                                                        Respond
                                                    </ActionLink>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </PanelBody>
            </Panel>
            {farmAccessRequestResponseModal}
            {farmOwnershipNominationResponseModal}
            {orgInvitationResposneModal}
            {farmGroupInvitationResponseModal}
        </>
    );
}

function useOrgInvitations() {
    const query = useQuery({
        queryKey: ["dashboard", "my-required-actions", "org-invitations"],
        queryFn: async () => await httpClient.get(`invitations`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

function useFarmAccessInvitations() {
    const query = useQuery({
        queryKey: ["dashboard", "my-required-actions", "farm-access-invitations"],
        queryFn: async () => await httpClient.get(`farmAccessInvitations`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

function useMyRequiredActions() {
    const authContext = useAuthContext();
    const isStudent = useIsStudentUser();

    const { data: orgInvitations } = useOrgInvitations();
    const { data: farmAccessInvitations } = useFarmAccessInvitations();
    const { data: farmAccessRequests } = useMyFarmAccessRequests();
    const { data: farmGroups } = useMyFarmGroups();

    const myActions = {
        invitationsToJoinNewOrgs: [],
        farmOwnershipNominations: [],
        requestsToAccessYourFarms: [],
        farmGroups: [],
        actionCount: 0,
    };

    myActions.invitationsToJoinNewOrgs = isStudent
        ? []
        : (orgInvitations || [])
              .filter((invite) => invite.status === "Pending")
              .map((invite) => {
                  myActions.actionCount++;
                  const details = (
                      <div>
                          You are currently registered as a user in the <b>{authContext.accountName}</b> organisation. The <b>{invite.accountName}</b> organisation has invited you to leave your current organisation and join their organisation.
                          <p className="u-textWarning">WARNING - IF YOU ACCEPT THIS INVITATION YOU WILL LOSE ACCESS TO ALL YOUR CURRENT FARM ACCOUNTS</p>
                      </div>
                  );
                  return {
                      action: "You've been asked to leave your current organisation and join a new organisation",
                      when: utils.dateLong(invite.invitedOn),
                      user: "",
                      org: invite.accountName,
                      payload: invite,
                      details,
                  };
              });

    myActions.farmOwnershipNominations = (farmAccessInvitations || [])
        .filter((invite) => invite.status === "Pending")
        .map((invite) => {
            myActions.actionCount++;
            const details = (
                <div>
                    <b>{invite.invitedBy.userFullName}</b> from the <b>{invite.invitedBy.accountName}</b> organisation has nominated you as an owner of the <b>{invite.farmName}</b> farm at <b>{invite.farmAddress}</b>
                </div>
            );
            return {
                action: "You've been nominated as an owner of a farm",
                when: utils.dateLong(invite.invitedOn),
                user: invite.invitedBy.userFullName,
                org: invite.invitedBy.accountName,
                payload: invite,
                details,
            };
        });

    myActions.requestsToAccessYourFarms = (farmAccessRequests || [])
        .filter((request) => request.status === "Pending" && authContext.userId && request.requestedBy.userId !== authContext.userId && !authContext.isSystemAdmin)
        .map((request) => {
            myActions.actionCount++;
            const details = (
                <div>
                    <b>{request.requestedBy.userFullName}</b> from the <b>{request.requestedBy.accountName}</b> organisation has requested <b>{request.role}</b> access to <b>{request.farmName}</b> farm at <b>{request.farmAddress}</b>
                </div>
            );
            return {
                action: "Someone has requested access to your farm",
                when: utils.dateLong(request.requestedOn),
                user: request.requestedBy.userFullName,
                org: request.requestedBy.accountName,
                payload: request,
                details,
            };
        });

    myActions.outstandingFarmAccessRequestsForSysAdmin = (farmAccessRequests || [])
        .filter((request) => request.status === "Pending" && request.requestedBy.userId !== authContext.userId && authContext.isSystemAdmin)
        .map((request) => {
            myActions.actionCount++;
            const isUnknown = request.emailStatus === "Unknown";
            const details = (
                <div>
                    <b>{request.requestedBy.userFullName}</b> from the <b>{request.requestedBy.accountName}</b> organisation requested <b>{request.role}</b> access to the <b>{request.farmName}</b> farm at <b>{request.farmAddress}</b>
                    <p className={request.failed && !isUnknown ? "u-font-red u-font-600 hover" : "hover"}>Email status: {request.emailStatus}</p>
                </div>
            );
            return {
                action: "Outstanding farm access request",
                when: utils.dateLong(request.requestedOn),
                user: request.requestedBy.userFullName,
                org: request.requestedBy.accountName,
                payload: request,
                details,
            };
        });

    myActions.farmGroups = (farmGroups || [])
        .filter((fg) => !fg.isMember)
        .map((invite) => {
            myActions.actionCount++;
            const details = (
                <div>
                    <b>{invite.invitedByUser}</b> from the <b>{invite.invitedByAccount}</b> organisation has invited your <b>{invite.farmName}</b> farm at <b>{invite.farmAddress}</b> to join their <b>{invite.name}</b> farm collaboration group
                </div>
            );
            return {
                action: "A farm you own has been invited to join a farm group",
                when: utils.dateLong(invite.invitedOn),
                user: invite.invitedByUser,
                org: invite.invitedByAccount,
                payload: invite,
                details,
            };
        });

    return myActions;
}
