import TileSubPanel from "components/TileSubPanel";
import BlockListCard from "./BlockListCard";
import * as domain from "common/domain";
import { getBlockList } from "common/utils";
import { useRefData } from "common/hooks";

export default function BlockList({ farm, analysis, onDelete }) {
    const refData = useRefData();

    const blockList = getBlockList(analysis.blocks);

    const fodderCropBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.FodderCrop);
    const pastureBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductivePasture);
    const fruitBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductiveFruit);
    const cropBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductiveCrop);
    const outdoorPigBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductiveOutdoorPigs);
    const otherBlocks = blockList.nonProductiveBlocks;

    return (
        <>
            {fodderCropBlocks.length > 0 && (
                <TileSubPanel title="Fodder crop rotations" green stickyHeaderName="analysis.crops.fodderCropRotations.collapsed">
                    {fodderCropBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} onDelete={onDelete} />
                    ))}
                </TileSubPanel>
            )}
            {pastureBlocks.length > 0 && (
                <TileSubPanel title="Pasture blocks" stickyHeaderName="analysis.crops.pastureBlocks.collapsed">
                    {pastureBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
            {cropBlocks.length > 0 && (
                <TileSubPanel title="Crop blocks" stickyHeaderName="analysis.crops.cropBlocks.collapsed">
                    {cropBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
            {fruitBlocks.length > 0 && (
                <TileSubPanel title="Fruit blocks" stickyHeaderName="analysis.crops.fruitBlocks.collapsed">
                    {fruitBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
            {outdoorPigBlocks.length > 0 && (
                <TileSubPanel title="Outdoor pigs blocks" stickyHeaderName="analysis.crops.outdoorPigBlocks.collapsed">
                    {outdoorPigBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
            {otherBlocks.length > 0 && (
                <TileSubPanel title={refData.blockTypes ? "Other blocks" : "Blocks"} stickyHeaderName="analysis.crops.otherBlocks.collapsed">
                    {otherBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} />
                    ))}
                </TileSubPanel>
            )}
        </>
    )
}
