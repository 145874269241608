import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import { useModal } from "common/hooks";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import { TextField } from "components/FormFields";
import { useUpdateMyOrgAsync } from "./_hooks";

export default function MyOrgModal({ org, close }) {
    const updateMyOrgAsync = useUpdateMyOrgAsync();

    const validate = (formValues) => {
        const validation = {};

        validation.name = FormUtils.validators.required(formValues.name);
        validation.name = validation.name || FormUtils.validators.nameRegex(formValues.name);
        validation.name = validation.name || FormUtils.validators.maxLength(50)(formValues.name);

        return validation;
    }

    const submitAsync = async (formValues) => {
        try {
            await updateMyOrgAsync(formValues);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }

    return (
        <Form initialValues={org} validate={validate} onSubmit={submitAsync}>
            {({ handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Edit my org" close={close} submitting={submitting} skinny fluid>
                            <ModalBody error={submitError}>
                                <Grid>
                                    <GridCell>
                                        <Field name="name" label="Name" placeholder="This may be the organisation/farm name or your own name." required component={TextField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit primary waiting={submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useMyOrgModal() {
    const [modal, openModal] = useModal(MyOrgModal);

    const openMyOrgModal = (org) => {
        const modalProps = {
            org
        };
        openModal(modalProps);
    };

    return [modal, openMyOrgModal];
}
