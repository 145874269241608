import { useAuthContext, useIsStudentUser } from "common/hooks";
import * as icons from "common/icons";
import Page from "components/Page";
import PageBody from "components/PageBody";
import { Panel, PanelBody } from "components/Panel";
import TileBodyMessage from "components/TileBodyMessage";
import TileActionsBar from "components/TileActionsBar";
import FarmHomePageHead from "containers/FarmHome/FarmHomePageHead";
import MyFarmAccessRequestApprovals from "containers/Dashboard/MyFarmAccessRequestApprovals";
import FarmAccessList from "./FarmAccessList";
import PendingFarmAccessInvitations from "./PendingFarmAccessInvitations";
import ActionLink from "components/ActionLink";
import { Redirect } from "react-router";
import { useFarmAccess } from "containers/hooks";
import { useGrantFarmAccessModal } from "containers/Farm/GrantFarmAccessModal";

export default function FarmAccess({ farm }) {
    const { isFetching, isLoading, data: farmAccess } = useFarmAccess(farm?.id);
    const authContext = useAuthContext();
    const isStudent = useIsStudentUser();
    const [grantFarmAccessModal, openGrantFarmAccessModal] = useGrantFarmAccessModal();
    const referrer = `/app/farm/${farm.id}`;

    const iAmAnOwner = farmAccess?.access?.some((a) => a.accountId === authContext.accountId && a.role === "Owner") || false;
    const iAmAnAdmin = farmAccess?.access?.some((a) => a.accountId === authContext.accountId && a.role === "Admin") || false;
    const pendingAccessInvitations = farmAccess?.accessInvitations?.filter((a) => a.status === "Pending" && a.role !== "PublicationAgent") || [];
    const pendingAccessRequests = farmAccess?.accessRequests?.filter((a) => a.status === "Pending") || [];

    const _grantAccess = (accessInvitation) => {
        const farmHasAnOwner = farmAccess?.access?.some((a) => a.role === "Owner") || false;
        const farmHasAnOwnerInvite = farmAccess?.accessInvitations?.some((i) => i.role === "Owner" && i.status === "Pending") || false;
        const canInviteOwner = authContext.isSystemAdmin || iAmAnOwner || (iAmAnAdmin && !farmHasAnOwner && !farmHasAnOwnerInvite);

        if (!accessInvitation) {
            accessInvitation = {};
        }
        openGrantFarmAccessModal({ ...accessInvitation, canInviteOwner, farmId: farm.id });
    }

    const breadcrumb = [{ name: farm.displayName, to: referrer }, { name: "Farm access" }];
    const info = <>Use this screen to manage the <b>organisations</b> that have access to this farm.</>

    if (!authContext.isSystemAdmin && !iAmAnAdmin && !iAmAnOwner) {
        return <Redirect to={referrer} />
    }

    return (
        <>
            <Page>
                <FarmHomePageHead breadcrumb={breadcrumb} />
                <PageBody>
                    <Panel title="Farm access" iconClassName="IconLink--lock" info={info} waiting={isFetching} loading={isLoading} referrer={referrer}>
                        <PanelBody waiting={isFetching} loading={isLoading}>
                            {!isStudent && (
                                <TileActionsBar>
                                    <ul className="ActionsBar-links">
                                        <li>
                                            <ActionLink className="IconLink--arrow-plus" id="add-access-action" onClick={() => _grantAccess()}>
                                                <span>Grant farm access</span>
                                            </ActionLink>
                                        </li>
                                    </ul>
                                </TileActionsBar>
                            )}
                            {pendingAccessInvitations.length > 0 && <PendingFarmAccessInvitations farm={farm} pendingAccessInvitations={pendingAccessInvitations} resend={_grantAccess} />}
                            {pendingAccessRequests.length > 0 && <MyFarmAccessRequestApprovals farm={farm} myFarmAccessRequestApprovals={pendingAccessRequests} hideFarmDetails />}
                            {farmAccess?.access?.length > 0 && <FarmAccessList farm={farm} access={farmAccess.access} />}
                            {farmAccess?.access?.length === 0 && !isLoading && (
                                <Panel title="Existing farm access" midBlue>
                                    <PanelBody>
                                        <TileBodyMessage>
                                            <img src={icons.add} alt="Plus" />
                                            <p className="lead">You have not granted any farm access for this farm</p>
                                            {!isStudent && (
                                                <ActionLink className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-access-link" onClick={() => _grantAccess()}>
                                                    <span>Grant access</span>
                                                </ActionLink>
                                            )}
                                        </TileBodyMessage>
                                    </PanelBody>
                                </Panel>
                            )}
                        </PanelBody>
                    </Panel>
                </PageBody>
            </Page>
            {grantFarmAccessModal}
        </>
    )
}
