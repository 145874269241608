import React, { Component } from "react";
import { compose, withActions, withState } from "containers/HOC";
import { Redirect, Link, withRouter } from "react-router-dom";
import { Form, Field } from "react-final-form";
import * as validations from "common/validations";
import TextField from "components/FormFields/TextField";
import { modalType } from "components/ModalConductor";
import { modal } from "containers/App/_actions";
import { login, loginCancel, requestPasswordReset, getZendeskToken, clearZendeskToken } from "./_actions";
import GoogleLoginButton from "./GoogleLoginButton";
import FacebookLoginButton from "./FacebookLoginButton";
import ActionLink from "components/ActionLink";
import Loader from "components/Loader";
import { Grid, GridCell } from "components/Grid";
import { logEmailTracking } from "containers/App/_actions";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isZendeskLogin: window.location.pathname.includes("zendesk"),
        };
    }

    componentDidMount() {
        this.props.clearZendeskToken();
        if (this.props.isAuthenticated && this.state.isZendeskLogin) {
            this.props.getZendeskToken();
        }
        this.setState({ isMounted: true });
        !this.props.isAuthenticated && this.props.logEmailTracking("Login page opened", this.props.emailId, this.props.emailTime);
    }
    componentWillUnmount() {
        this.props.loginCancel();
    }

    componentDidUpdate() {
        if (this.props.isAuthenticated && this.state.isZendeskLogin && !this.props.zendeskToken && !this.props.loginError) {
            this.props.getZendeskToken();
        }
    }

    _validate = (values) => {
        const errors = {};

        errors.email = validations.required(values.email);
        errors.email = errors.email || validations.email(values.email);
        errors.email = errors.email || validations.maxLength(255)(values.email);

        errors.password = validations.required(values.password);
        errors.password = errors.password || validations.maxLength(50)(values.password);

        return errors;
    };

    _loginWithPassword = (values) => {
        this.props.login(values, this.props.location.state);
    };

    _launchForgotPasswordModal = (e) => {
        e.preventDefault();
        this.props.modal(modalType.FORGOT_PASSWORD, this.props.requestPasswordReset, {});
    };

    render() {
        const { online, isAuthenticated, referrer, passwordResetRequested, loginError, zendeskToken, farmId, match } = this.props;
        const displayPayment = match.path.endsWith("dp");

        if (isAuthenticated) {
            if (this.state.isZendeskLogin) {
                if (zendeskToken) {
                    const redirectUrl = `https://overseer-helpdesk.zendesk.com/access/jwt?jwt=${zendeskToken.token}&${this.props.location.search}`;
                    window.location = redirectUrl;
                }
            } else {
                const path = farmId && displayPayment ? `/app/farm/${farmId}/dp` : farmId ? `/app/farm/${farmId}` : "/";
                return <Redirect to={referrer || path} />;
            }
        }

        if (!this.state.isMounted && this.state.isZendeskLogin) {
            return <Loader />;
        }

        return (
            <div className="Tile u-pb-xl">
                <ul className="SubTabs SubTabs--simple SubTabs--center">
                    <li className="SubTabs-item is-active">
                        <Link id="auth-login-tab" to="/pub/login">
                            Login
                        </Link>
                    </li>
                    {process.env.REACT_APP_NO_REGISTER !== "true" && (
                        <li className="SubTabs-item">
                            <Link id="auth-register-tab" to="/pub/register">
                                Register
                            </Link>
                        </li>
                    )}
                </ul>
                <Grid>
                    {!online && (
                        <div className="Grid-cell Grid-cell--center u-md-width2of3 u-lg-width1of2">
                            <div className="Alert Alert--error u-mt-lg">
                                <i className="icon icon-alert" id="login-errormsg" />
                                Overseer is currently offline. Please try again later.
                            </div>
                        </div>
                    )}
                    {process.env.REACT_APP_NO_REGISTER !== "true" && (
                        <div className="Grid-cell Grid-cell--center u-md-width2of3 u-lg-width1of2">
                            <div className="u-textCenter u-mt-xl">
                                <h4>Log in with...</h4>
                            </div>
                            <hr className="u-mt-sm u-mb-lg" />
                            <div className="Grid">
                                <div className="Grid-cell Grid-cell--center u-md-width1of1 u-lg-width1of3">
                                    <GoogleLoginButton />
                                    <FacebookLoginButton />
                                </div>
                            </div>
                        </div>
                    )}

                    <GridCell className="Grid-cell--center u-md-width2of3 u-lg-width1of2">
                        {process.env.REACT_APP_NO_REGISTER !== "true" ? (
                            <div className="u-textCenter u-mt-xl">
                                <h4>Or, log in with your email address and password</h4>
                            </div>
                        ) : (
                            <div className="u-textCenter u-mt-xl">
                                <h4>Log in with your email address and password</h4>
                            </div>
                        )}
                        <hr className="u-mt-sm" />
                        {passwordResetRequested && (
                            <div className="Alert Alert--help u-mt-lg">
                                <i className="icon icon-alert" id="login-passwordResetMsg" />
                                <p>
                                    You have requested a password reset. If the email address you provided is associated with an existing Overseer user then a password reset email will be sent to that address. <b>NOTE:</b> If you do not receive a password reset email within the next 5 minutes then please try again, making sure you enter an email address that is associated with an existing Overseer user.
                                </p>
                            </div>
                        )}
                        {online && loginError && (
                            <div className="Alert Alert--error u-mt-lg">
                                <i className="icon icon-alert" id="login-errormsg" />
                                {loginError}
                            </div>
                        )}
                        <Form validate={this._validate} onSubmit={this._loginWithPassword}>
                            {({ handleSubmit }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Field name="email" label="Email" placeholder="Enter your email" type="text" required component={TextField} />
                                        <Field name="password" type="password" label="Password" placeholder="Enter your password" required component={TextField} />
                                        <Grid className="u-mt-lg">
                                            <GridCell>
                                                <button type="submit" id="login-submit" disabled={!online} className="Button Button--primary Button--block">
                                                    Log in
                                                </button>
                                            </GridCell>
                                            <GridCell className="">
                                                {online && (
                                                    <ActionLink id="forgot-password" className="Button Button--secondary Button--block u-mt-md" onClick={this._launchForgotPasswordModal}>
                                                        Forgot password?
                                                    </ActionLink>
                                                )}
                                            </GridCell>
                                            <GridCell className="u-mt-lg">
                                                {process.env.REACT_APP_NO_REGISTER !== "true" && (
                                                    <p className="u-mt-lg u-textCenter">
                                                        Don't have an account? <Link to="/pub/register">Register</Link>
                                                    </p>
                                                )}
                                                <p className="u-textCenter">
                                                    Please read our{" "}
                                                    <a href={`https://docs.overseer.org.nz/${process.env.REACT_APP_PRODUCT}/OverseerTermsOfUse.pdf`} rel="noopener noreferrer" target="_blank">
                                                        terms of use
                                                    </a>{" "}
                                                    and{" "}
                                                    <a href={`https://docs.overseer.org.nz/${process.env.REACT_APP_PRODUCT}/OverseerPrivacyPolicy.pdf`} rel="noopener noreferrer" target="_blank">
                                                        privacy policy
                                                    </a>
                                                </p>
                                            </GridCell>
                                        </Grid>
                                    </form>
                                );
                            }}
                        </Form>
                    </GridCell>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withState((state, ownProps) => {
        const { app, auth } = state;
        return {
            online: app.online,
            isAuthenticated: auth.isAuthenticated,
            zendeskToken: auth.zendeskToken,
            referrer: auth.referrer,
            loginError: auth.loginError,
            initialValues: auth.invitation,
            passwordResetRequested: auth.passwordResetRequested,
            farmId: ownProps.match.params.farmId,
            showTerms: auth.termsRequired,
            emailId: app.emailId,
            emailTime: app.emailTime,
        };
    }),
    withActions({ login, loginCancel, modal, requestPasswordReset, getZendeskToken, clearZendeskToken, logEmailTracking })
)(Login);
