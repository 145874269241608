import { Switch } from "react-router-dom";
import { useAuthContext, useIsOverseerSci, useIsPaidVersionOfOverseer, useIsStudentUser } from "common/hooks";
import ScrollTopRoute from "components/ScrollTopRoute";
import Page from "components/Page";
import PageHead from "components/PageHead";
import ActionLink from "components/ActionLink";
import PageBody from "components/PageBody";
import { Panel, PanelBody } from "components/Panel";
import Tabs from "components/new/Tabs";
import TabsItem from "components/new/TabsItem";
import MyOrgUserInvitations from "./MyOrgUserInvitations";
import MyOrgAnalysisSummary from "./MyOrgAnalysisSummary";
import MyOrgFarmAccessSummary from "./MyOrgFarmAccessSummary";
import MyOrgRenewals from "./MyOrgRenewals";
import MyOrgPayments from "./MyOrgPayments";
import MyOrgUsers from "./MyOrgUsers";
import { useMyOrg } from "./_hooks";
import { useScienceSettings } from "containers/Datasets/_hooks";
import { useMyOrgModal } from "./MyOrgModal";
import { useInviteUserModal } from "./InviteUserModal";

export default function MyOrg() {
    const { data: myOrg } = useMyOrg();
    const { data: scienceSettings } = useScienceSettings();
    const isPaidVersionOfOverseer = useIsPaidVersionOfOverseer();
    const isOverseerSci = useIsOverseerSci();
    const authContext = useAuthContext();
    const isStudentUser = useIsStudentUser();
    const [inviteUserModal, openInviteUserModal] = useInviteUserModal();
    const [myOrgModal, openMyOrgModal] = useMyOrgModal();

    const canAddUsers = !isStudentUser;
    const maxUsers = scienceSettings?.maxUsers || 999;
    const numUsers = myOrg?.users && myOrg?.userInvitations ? myOrg.users.length + myOrg.userInvitations.length : 99;

    const inviteNewUser = () => {
        openInviteUserModal(myOrg);
    }

    const editMyOrg = () => {
        openMyOrgModal(myOrg);
    }

    return (
        <>
            <Page>
                <PageHead>
                    <div className="FarmTitle">
                        <div className="FarmTitle-heading">
                            <span className="FarmTitle-name">{myOrg?.name || authContext.accountName}</span>
                            <div className="FarmTitle-sub"></div>
                        </div>
                    </div>
                    {authContext.isAdmin && (
                        <div className="PageHead-actions">
                            {canAddUsers && numUsers < maxUsers && (
                                <ActionLink id="invite-user" className="IconLink--arrow-plus" onClick={() => inviteNewUser()}>
                                    Add a new staff member to your organisation
                                </ActionLink>
                            )}
                            {canAddUsers && isOverseerSci && numUsers >= maxUsers && (
                                <span className="UnpaidSubscription">You have reached the maximum number of users for your organisation.</span>
                            )}
                            <ActionLink id="edit-org" className="IconLink--edit" onClick={() => editMyOrg()}>
                                Edit my organisation
                            </ActionLink>
                        </div>
                    )}
                </PageHead>
                <PageBody>
                    <Panel title="My organisation" referrer="/" green>
                        <PanelBody>
                            <Panel>
                                <PanelBody>
                                    <Tabs>
                                        {authContext.isAdmin && <TabsItem id="users-tab" title={myOrg ? `Users (${myOrg?.users?.length || 0})` : "Users"} to={`/app/myOrg`} exact />}
                                        {authContext.isAdmin && <TabsItem id="user-invitations-tab" title={myOrg ? `User invitations (${myOrg?.userInvitations?.length || 0})` : "User invitations"} to={`/app/myOrg/invitations`} exact />}
                                        {authContext.isAdmin && false && <TabsItem id="user-summary-tab" title="Analysis summary" to={`/app/myOrg/summary`} exact />}
                                        {isPaidVersionOfOverseer && <TabsItem id="payments-tab" title="Payment transactions" to={authContext.isAdmin ? "/app/myOrg/payments" : "/app/myOrg"} exact />}
                                        <TabsItem id="farm-access-tab" title="Farm access" to={`/app/myOrg/farmaccess`} exact />
                                        <TabsItem id="recurring-payments" title="Billing" to={`/app/myOrg/renewal`} exact />
                                    </Tabs>
                                    <Switch>
                                        {authContext.isAdmin && <ScrollTopRoute exact path="/app/myOrg" render={() => <MyOrgUsers />} />}
                                        {authContext.isAdmin && <ScrollTopRoute exact path="/app/myOrg/invitations" render={() => <MyOrgUserInvitations />} />}
                                        {authContext.isAdmin && <ScrollTopRoute exact path="/app/myOrg/summary" render={() => <MyOrgAnalysisSummary />} />}
                                        <ScrollTopRoute exact path="/app/myOrg/farmaccess" render={(r) => <MyOrgFarmAccessSummary />} />
                                        <ScrollTopRoute exact path="/app/myOrg/renewal" render={(r) => <MyOrgRenewals />} />
                                        {isPaidVersionOfOverseer && <ScrollTopRoute exact path={authContext.isAdmin ? "/app/myOrg/payments" : "/app/myOrg"} render={() => <MyOrgPayments />} />}
                                    </Switch>
                                </PanelBody>
                            </Panel>
                        </PanelBody>
                    </Panel>
                </PageBody>
            </Page>
            {inviteUserModal}
            {myOrgModal}
        </>
    )
}
