import { Component } from "react";
import { useParams, Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as domain from "common/domain";
import { useNavigate } from "common/hooks";
import * as utils from "common/utils";
import * as validations from "common/validations";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import { useBlockGrowthCurve, useUpdateAnalysisAsync } from "containers/hooks";
import Alert from "components/Alert";
import CheckboxPack from "components/CheckboxPack";
import SelectPack from "components/SelectPack";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Button } from "components/Button";
import * as cropUtils from "../_utils";
import Crops, { validate as validateCrops } from "../Crops";
import AnimalGrazing from "../AnimalGrazing";
import BlockDetails from "./BlockDetails";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function CropDetails({ farm, analysis }) {
    const { blockId } = useParams();
    const block = analysis.blocks.find((b) => b.id === blockId) || { id: uuidv4(), type: "ProductiveCrop" };
    if (block.cropBlock && block.cropBlock.preCrop === "Undefined") {
        block.cropBlock.preCrop = undefined;
    }

    const { growthCurve, resetGrowthCurve } = useBlockGrowthCurve(farm, analysis, block);
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const navigate = useNavigate();

    return <CropDetailsClassComponent farm={farm} analysis={analysis} block={block} growthCurve={growthCurve} resetGrowthCurve={resetGrowthCurve} updateAnalysisAsync={updateAnalysisAsync} navigate={navigate} />
}

class CropDetailsClassComponent extends Component {
    constructor(props) {
        super(props);
        cropUtils.insertFinalDefoliationEvent(props.block);
        this.state = {
            analysis: props.analysis,
            block: props.block,
            submitting: false,
        };
    }

    onChange(e, source) {
        let { block = {} } = this.state;
        const { cropBlock = {} } = block;
        switch (source.type) {
            case "cropBlock":
                block.cropBlock = cropBlock;
                cropBlock[source.key] = utils.ctrlVal(e);
                if (source.key === "preCrop" && cropBlock[source.key] === "Pasture") {
                    const { animalGrazing = {} } = cropBlock;
                    cropBlock.animalGrazing = animalGrazing;
                }
                break;
            case "animalGrazing":
                block.cropBlock = cropBlock;
                cropBlock.animalGrazing = source.animalGrazing;
                break;
            default:
                block[source.key] = utils.ctrlVal(e);
                break;
        }
        this.isValid(block, source);
        this.setDefaults(block);
        this.setState({ block: block });
    }

    setDefaults(block) {
        const { cropBlock = {} } = block;
        const isPasture = cropBlock.preCrop === "Pasture";
        const isSeed = utils.priorLandUseSeed(block);
        if (!isPasture && !isSeed) {
            delete cropBlock.animalGrazing;
            delete cropBlock.supplementsRemoved;
        }
        if (!isSeed) {
            delete cropBlock.monthSeedHarvested;
        }
        if (cropUtils.priorLandUseHasCropEvents(block) || cropUtils.anyCropStartsOnReportingYearStart(block)) {
            delete cropBlock.supplementsRemoved;
        }
        if (cropBlock.preCrop === "OutdoorPigs") {
            delete cropBlock.yearsInPasture;
        }
        this.clearCroppingEvents(block);
    }

    isValid(block, source = undefined) {
        const currentValidation = this.state.validation;
        const { cropBlock = {} } = block;
        let validation = {};
        let message = undefined;
        const { animalGrazing = {} } = cropBlock;

        const required = (parent, keys) => {
            /* eslint-disable no-unused-vars */
            for (const key of keys) {
                const objKey = key.split("_").pop();
                validation[key] = utils.setVal(key, currentValidation, source, validations.requiredExt(parent[objKey]));
            }
        };

        const animalGrazingEnabled = ["Pasture", "Firstyearofseedcrop", "Secondyearofseedcrop"].includes(cropBlock.preCrop);

        const animalGrazingRequired = [];

        if (animalGrazingEnabled && !cropUtils.priorLandUseHasCropEvents(block) && !cropUtils.priorLandUseHasCultivationEvents(block) && !cropUtils.anyCropStartsOnReportingYearStart(block)) {
            animalGrazingRequired.push("animalGrazing_animalSource");
        }

        if (animalGrazingEnabled && animalGrazing.animalSource === "Standalonegeneralisedanimaltype") {
            animalGrazingRequired.push("animalGrazing_animalType");
        }

        required(block, ["monthResown"]);
        required(animalGrazing, animalGrazingRequired);

        const yearsInPastureRequired = cropBlock.preCrop !== "OutdoorPigs";
        if (yearsInPastureRequired) {
            required(cropBlock, ["yearsInPasture", "preCrop"]);
        } else {
            required(cropBlock, ["preCrop"]);
        }
        validateCrops(block, validation, currentValidation, source);

        if (yearsInPastureRequired) {
            message = validations.range(0, 10)(cropBlock.yearsInPasture);
            validation.yearsInPasture = validation.yearsInPasture.error ? validation.yearsInPasture : utils.setVal("yearsInPasture", currentValidation, source, message);
        }

        if (!source && animalGrazingEnabled && animalGrazing.animalSource === "Integratedwithpastoralblocks") {
            const { percentageGrazed = [] } = animalGrazing;
            let message = animalGrazing.specifyAnimalConsumption && (!animalGrazing.percentageGrazed || percentageGrazed.length === 0) ? "Please select at least one enterprise" : undefined;
            message = message || (percentageGrazed.length > 0 && percentageGrazed.reduce((a, b) => (a += b.percentage), 0) !== 100 ? "Percentages need to sum to 100" : undefined);
            validation.animalConsumption = utils.setVal("animalConsumption", currentValidation, source, message);
        }

        this.setState({ validation: validation });
        /* eslint-disable no-unused-vars */
        for (let key of Object.keys(validation)) {
            if (validation[key].error) {
                return false;
            }
        }
        return true;
    }

    submitEvent(evtType, data) {
        const { block, validation = {} } = this.state;
        cropUtils.handleEvent(evtType, data, block);
        validateCrops(block, validation, validation, undefined);
        this.clearCroppingEvents(block);
        this.setState({ block: block, validation: validation });
        this.props.resetGrowthCurve();
    }

    copyCrops(cropData) {
        const { block, validation = {} } = this.state;
        this.clearCroppingEvents(block);
        block.crops = cropData[0];
        block.croppingEvents = cropData[1];
        block.cropBlock = {
            ...block.cropBlock,
            ...cropData[2],
        };
        validateCrops(block, validation, validation, undefined);
        this.setState({ block: block, validation: validation });
        this.props.resetGrowthCurve();
    }

    includeArea(area) {
        const block = this.state.block;
        const { cropBlock = {} } = block;
        block.cropBlock = cropBlock;

        if (area === "headlands") {
            cropBlock.headlandAreaPercent = 5;
            cropBlock.otherAreaPercentage = cropBlock.otherAreaPercentage > 15 ? 15 : cropBlock.otherAreaPercentage;
        } else {
            cropBlock.otherAreaPercentage = 5;
            cropBlock.headlandAreaPercent = cropBlock.headlandAreaPercent > 15 ? 15 : cropBlock.headlandAreaPercent;
        }

        cropBlock.cultivatedAreaPercent = 100 - (cropBlock.headlandAreaPercent || 0) - (cropBlock.otherAreaPercentage || 0);
        this.setState({ block: block });
    }

    deleteArea(area) {
        const block = this.state.block;
        const { cropBlock = {} } = block;
        block.cropBlock = cropBlock;

        cropBlock.headlandAreaPercent = area === "headlands" ? 0 : cropBlock.headlandAreaPercent;
        cropBlock.otherAreaPercentage = area === "other" ? 0 : cropBlock.otherAreaPercentage;

        cropBlock.cultivatedAreaPercent = 100 - (cropBlock.headlandAreaPercent || 0) - (cropBlock.otherAreaPercentage || 0);
        this.setState({ block: block });
    }

    changeAreaPercentage(values) {
        const inputData = utils.clone(values);
        const block = this.state.block;
        const { cropBlock = {} } = block;
        block.cropBlock = cropBlock;

        const min60 = (area) => (area < 60 ? 60 : area);
        const max20 = (area) => (area > 20 ? 20 : area < 1 ? 1 : area);
        if (inputData.length === 1) {
            if (cropBlock.headlandAreaPercent > 0) {
                cropBlock.headlandAreaPercent = max20(100 - inputData[0]);
                cropBlock.otherAreaPercentage = 0;
                cropBlock.cultivatedAreaPercent = 100 - cropBlock.headlandAreaPercent;
            } else {
                cropBlock.otherAreaPercentage = max20(100 - inputData[0]);
                cropBlock.headlandAreaPercent = 0;
                cropBlock.cultivatedAreaPercent = 100 - cropBlock.otherAreaPercentage;
            }
        } else if (inputData.length > 1) {
            const newCult = inputData[0];
            if (newCult !== cropBlock.cultivatedAreaPercent) {
                //First slider moved
                cropBlock.cultivatedAreaPercent = min60(newCult);
                cropBlock.headlandAreaPercent = max20(100 - inputData[0] - cropBlock.otherAreaPercentage);
                if (cropBlock.cultivatedAreaPercent + cropBlock.headlandAreaPercent === 100) {
                    cropBlock.headlandAreaPercent = 1;
                    cropBlock.otherAreaPercentage = 1;
                    cropBlock.cultivatedAreaPercent = 98;
                } else {
                    cropBlock.otherAreaPercentage = 100 - cropBlock.cultivatedAreaPercent - cropBlock.headlandAreaPercent;
                }
            } else {
                //second slider moved
                cropBlock.otherAreaPercentage = max20(100 - inputData[1]);
                cropBlock.headlandAreaPercent = max20(100 - cropBlock.otherAreaPercentage - cropBlock.cultivatedAreaPercent);
                cropBlock.cultivatedAreaPercent = 100 - cropBlock.otherAreaPercentage - cropBlock.headlandAreaPercent;
            }
        }
        this.setState({ block: block });
    }

    getAnalysis() {
        const { analysis, block } = this.state;
        const { blocks = [] } = analysis;
        analysis.blocks = blocks;
        utils.merge(block, analysis.blocks);
        return analysis;
    }

    submit = async () => {
        const analysis = this.getAnalysis();
        const block = this.state.block;
        this.setDefaults(block);
        if (this.isValid(block)) {
            this.setState({ submitting: true });
            cropUtils.clearSupplementValues(block, analysis);
            await this.props.updateAnalysisAsync(analysis);
            this.props.navigate(`/app/farm/${analysis.farmId}/analysis/${analysis.id}/crops`);
        }
    };

    clearCroppingEvents(block) {
        const { croppingEvents: c1 = [], cropBlock = {}, crops = [] } = block;
        if (cropBlock.preCrop !== "Graincrop") {
            block.croppingEvents = c1.filter((e) => e.type !== "Defoliation");
        }

        const { croppingEvents: c2 = [] } = block;
        if (crops.length > 0 && c2.some((c) => c.type === "Defoliation")) {
            const rotationYear = cropUtils.rotationYear(block);
            const firstCropSownIndx = cropUtils.getFirstCropSownIndex(crops, rotationYear);
            block.croppingEvents = c2.filter((e) => e.type !== "Defoliation" || cropUtils.rotationIndex(e, rotationYear) < firstCropSownIndx);
        }
    }

    render() {
        const { block = {}, validation = {} } = this.state;
        const { cropBlock = {}, crops = [] } = block;
        const { animalGrazing = {} } = cropBlock;
        const referrer = `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/crops`;
        const isPasture = cropBlock.preCrop === "Pasture";
        const isSeed = utils.priorLandUseSeed(block);
        const priorLandUseHasCropEvents = cropUtils.priorLandUseHasCropEvents(block);
        const anyCropStartsOnReportingYearStart = cropUtils.anyCropStartsOnReportingYearStart(block);
        const priorLandUseHasCultivationEvents = cropUtils.priorLandUseHasCultivationEvents(block);
        const animalGrazingRequired = (isPasture || isSeed) && !priorLandUseHasCropEvents && !priorLandUseHasCultivationEvents && !anyCropStartsOnReportingYearStart;

        let incompleteDefols = false;
        for (const c of crops) {
            const { events = [] } = c;
            if (events.find((e) => e.incomplete)) {
                incompleteDefols = true;
                break;
            }
        }
        return (
            <Tile waiting={this.state.submitting}>
                <PageTabs farm={this.props.farm} analysis={this.props.analysis} tab={TAB.CROPS} />
                <TileBody>
                    <Tile title={block.name} referrer={referrer}>
                        <Alert type="info" text="Select the final month for the crops that rotate through this block. This is typically the month that the crop is harvested. Enter the years in pasture and land use prior to the year 1 (previous year) of the rotation. Where lime has been applied between 2 and 5 years ago, add a previous lime under the fertiliser tab." />
                        <TileBody>
                            <div className="Grid Grid--withGutter">
                                <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                    <BlockDetails block={block} validation={validation} onChange={(e, source) => this.onChange(e, source)} changeAreaPercentage={(o) => this.changeAreaPercentage(o)} includeArea={(area) => this.includeArea(area)} deleteArea={(area) => this.deleteArea(area)} />
                                </div>
                                <div className="Grid-cell"></div>
                                {(isPasture || isSeed) && (
                                    <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                        <AnimalGrazing onChange={(e, source) => this.onChange(e, source)} animalGrazing={animalGrazing} validation={validation} source="cropblock" enterprises={this.state.analysis.enterprises.filter((e) => e.type !== "OutdoorPigs")} cropBlock={cropBlock} displayEqualRatio={true} isRequired={animalGrazingRequired} />
                                    </div>
                                )}
                                <div className="Grid-cell"></div>
                                {((isPasture && !priorLandUseHasCropEvents && !anyCropStartsOnReportingYearStart) || isSeed) && (
                                    <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                                        <h3 className="u-mt-0">Management</h3>
                                        {priorLandUseHasCropEvents || anyCropStartsOnReportingYearStart ? (
                                            <div>
                                                <h5>Supplement removed in reporting year</h5>
                                                <Alert type="info" text="To enable supplement removal, no crops must exist in year 1" />
                                            </div>
                                        ) : (
                                            <CheckboxPack info={`${isPasture ? "Pasture" : "Seed"} is harvested in the reporting year. This will require you to harvest a new supplement in the supplements tab.`} meta={{ nrf: true }} id="supplementsRemoved" label="Supplement removed in reporting year" value={cropBlock.supplementsRemoved} onChange={(e) => this.onChange(e, { type: "cropBlock", key: "supplementsRemoved" })} />
                                        )}
                                        <SelectPack id="crop_monthSeedHarvested" dataWidth="50" label="Month seed harvested" meta={{ nrf: true }} isHidden={!isSeed} onChange={(e) => this.onChange(e, { type: "cropBlock", key: "monthSeedHarvested" })} value={cropBlock.monthSeedHarvested} val={validation.monthSeedHarvested} requiredLabel={true}>
                                            <option value="">Use default</option>
                                            {domain.calendarYear.map((m) => (
                                                <option key={m} value={m}>
                                                    {m}
                                                </option>
                                            ))}
                                        </SelectPack>
                                    </div>
                                )}
                                <div className="Grid-cell">
                                    <Crops analysis={this.state.analysis} block={block} isRotation={false} growthCurve={this.props.growthCurve} validation={validation} submitEvent={(e, d) => this.submitEvent(e, d)} showCopyCrops={true} copyCrops={(d) => this.copyCrops(d)} />
                                </div>
                            </div>
                        </TileBody>
                        <TileFooter>
                            <div className="ButtonBar ButtonBar--fixed">
                                <div className="ButtonBar-left">
                                    <Link to={referrer} className="Button Button--secondary" id="crop-cancel">
                                        Cancel
                                    </Link>
                                </div>
                                <div className="ButtonBar-right">
                                    <Button id="crop-submit" submit primary waiting={this.state.submitting} disabled={incompleteDefols} onClick={() => this.submit()}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </TileFooter>
                    </Tile>
                </TileBody>
            </Tile>
        )
    }
}
