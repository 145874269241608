import React, { Component } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { factoryIrrigatorApplication } from "common/domain";
import * as validations from "common/validations";
import * as utils from "common/utils";
import * as domain from "common/domain";
import * as geoJsonUtils from "common/geoJsonUtils";
import SavePrompt from "components/SavePrompt";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import * as irrigationUtils from "./_utils";
import ActivitySummary from "./ActivitySummary";
import ActivityModal from "./ActivityModal";
import BlockAreas from "./BlockAreas";
import IrrigationDetails from "./IrrigationDetails";
import IrrigatorMap from "./IrrigatorMap";
import Button from "components/Button/Button";
import { useGetCustomNutrientCompositions, useCustomNutrientCompositions, useRefData, useModalInline } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function Details({ farm, analysis, irrigator }) {
    const refData = useRefData();
    const getCustomCompositions = useGetCustomNutrientCompositions();
    const customCompositions = useCustomNutrientCompositions();
    const { modalInlineOpen, modalInlineClose } = useModalInline();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    return <DetailsClassComponent farm={farm} analysis={analysis} irrigator={irrigator} updateAnalysisAsync={updateAnalysisAsync} getCustomCompositions={getCustomCompositions} customCompositions={customCompositions} modalInlineOpen={modalInlineOpen} modalInlineClose={modalInlineClose} refData={refData} />
}

class DetailsClassComponent extends Component {
    constructor(props) {
        super(props);

        const irrigator = props.irrigator;
        if (!irrigator.nutrients || !irrigator.nutrients.N) {
            this.setDefaultNutrients(irrigator);
        }

        const availableBlocks = this.props.analysis.blocks.reduce((available, block) => {
            if (!block.isProductive || block.type === "ProductiveOutdoorPigs") return available;

            const numberOfSyystemsOnThisBlock = props.analysis.irrigators.reduce((count, irr) => {
                if (irr.applications.some((app) => app.blockIds.includes(block.id))) count++;
                return count;
            }, 0);

            const isThisSystemOnThisBlock = irrigator.applications.some((app) => app.blockIds.includes(block.id));

            // FodderCrop blocks can only be irrigated by 1 irrigation system.
            // All other block types can only be irrigated by a max of 2 irrigation systems
            const blockCanHaveMoreSystems = (block.type === "FodderCrop" && numberOfSyystemsOnThisBlock === 0) || (block.type !== "FodderCrop" && numberOfSyystemsOnThisBlock < 2);

            if (isThisSystemOnThisBlock || blockCanHaveMoreSystems) available.push(block);

            return available;
        }, []);

        const irrigators = this.props.analysis.irrigators || [];

        const fields = ["type", "name", "N", "P", "K", "S", "Ca", "Mg", "Na", "map", "sliders", "applications"];
        const fieldRefs = fields.reduce((acc, field) => {
            acc[field] = React.createRef();
            return acc;
        }, {});

        this.state = {
            blocks: [],
            availableBlocks: availableBlocks,
            validation: {},
            isSubmit: false,
            irrigator: irrigator,
            initId: irrigator.id,
            showLabels: true,
            dirty: false,
            submitting: false,
            submitSucceeded: false,
            irrigators,
            fields,
            fieldRefs,
        };
    }

    componentDidMount() {
        this.props.getCustomCompositions();
        setTimeout(() => {
            this.setState({ initId: this.props.irrigator.id });
        }, 500);
    }

    addApplication() {
        const application = factoryIrrigatorApplication();
        application.id = uuidv4();
        application.months = [];
        application.reportingYear = true;
        this.setState({ selectedApplication: application, isFrost: false });
        this.props.modalInlineOpen();
    }

    addFrost() {
        const application = factoryIrrigatorApplication();
        application.id = uuidv4();
        application.months = [];
        application.schedule = irrigationUtils.Schedule.Frost;
        application.reportingYear = true;
        this.setState({ selectedApplication: application, isFrost: true });
        this.props.modalInlineOpen();
    }

    isCropBlock = (block) => {
        return ["ProductiveCrop", "FodderCrop"].includes(block.type);
    };

    isNonCropBlock = (block) => {
        return ["ProductivePasture", "ProductiveFruit"].includes(block.type);
    };

    isSameMonth = (m1, m2) => {
        return m1.month === m2.month && m1.reportingYear === m2.reportingYear;
    };

    saveApplication() {
        this.validate(undefined, true).then((isValid) => {
            if (!isValid) return;

            const selectedApp = this.state.selectedApplication;
            const { irrigator, features } = this.state;
            const selectedBlockIds = selectedApp.blockIds;
            const selectedBlocks = selectedBlockIds.map((blockId) => (this.props.analysis.blocks || []).find((block) => block.id === blockId));

            const otherApps = (irrigator.applications || []).filter((app) => app.id !== selectedApp.id);
            const updatedApps = [];

            const existingApp = irrigator.applications.find((app) => app.id === selectedApp.id);
            if (existingApp) {
                // Keep the original app with at least it's blocks that are still selected
                const blocksThatAreStillSelected = selectedBlocks.filter((block) => existingApp.blockIds.includes(block.id));
                if (blocksThatAreStillSelected.length > 0) {
                    const isForNonCropBlocks = this.isNonCropBlock(blocksThatAreStillSelected[0]);
                    updatedApps.push({
                        ...utils.clone(selectedApp),
                        blockIds: blocksThatAreStillSelected.map((b) => b.id),
                        isForNonCropBlocks, // Helper property to make it easier to append other non crop blocks to this application later in this code block
                    });
                }

                // Any newly selected 'non crop/fodder blocks' should either be added to the original app (if it's also for 'non crop/fodder' crops)
                // or added as part of a new app
                const newNonCropBlockIds = selectedBlocks.filter((block) => !existingApp.blockIds.includes(block.id) && this.isNonCropBlock(block)).map((b) => b.id);
                if (newNonCropBlockIds.length > 0) {
                    const existingNonCropBlockApp = updatedApps.find((app) => app.isForNonCropBlocks);
                    if (existingNonCropBlockApp) {
                        existingNonCropBlockApp.blockIds = [...existingNonCropBlockApp.blockIds, ...newNonCropBlockIds];
                    } else {
                        updatedApps.push({
                            ...utils.clone(selectedApp),
                            id: uuidv4(),
                            blockIds: newNonCropBlockIds,
                            months: selectedApp.months.filter((m) => m.reportingYear),
                        });

                        // If there are any other apps for these blocks make sure we don't have duplicate months
                        otherApps.forEach((app) => {
                            if (app.blockIds.some((blockId) => selectedBlockIds.includes(blockId))) {
                                app.months = app.months.filter((oldMonth) => !selectedApp.months.some((newMonth) => this.isSameMonth(oldMonth, newMonth)));
                            }
                        });
                    }
                }

                // Any newly selected 'crop/fodder blocks' should each get their own new app.
                const newCropBlocks = selectedBlocks.filter((block) => !existingApp.blockIds.includes(block.id) && this.isCropBlock(block));
                newCropBlocks.forEach((block) => {
                    updatedApps.push({
                        ...utils.clone(selectedApp),
                        id: uuidv4(),
                        blockIds: [block.id],
                    });

                    // If there are any other apps for this 'crop/fodder block' make sure we don't have duplicate months
                    otherApps.forEach((app) => {
                        if (app.blockIds[0] === block.id) {
                            app.months = app.months.filter((oldMonth) => !selectedApp.months.some((newMonth) => this.isSameMonth(oldMonth, newMonth)));
                        }
                    });
                });
            } else {
                const newNonCropBlockIds = selectedBlocks.filter((block) => this.isNonCropBlock(block)).map((b) => b.id);
                if (newNonCropBlockIds.length > 0) {
                    updatedApps.push({
                        ...utils.clone(selectedApp),
                        id: uuidv4(),
                        blockIds: newNonCropBlockIds,
                    });
                }

                const newCropBlocks = selectedBlocks.filter((block) => this.isCropBlock(block));
                newCropBlocks.forEach((block) => {
                    updatedApps.push({
                        ...utils.clone(selectedApp),
                        id: uuidv4(),
                        blockIds: [block.id],
                    });
                });
            }

            irrigator.applications = [...otherApps, ...updatedApps];

            const isDrawn = features && features.some((f) => f.properties.irrigatorId === irrigator.id);
            if (isDrawn) delete irrigator.nonDrawnArea;

            const isNonDrawn = !isDrawn;
            if (isNonDrawn) {
                // Add any new applications as non drawn areas.
                irrigator.applications.forEach((app) => {
                    app.blockIds.forEach((blockId) => {
                        const isNewApplication = !(irrigator.nonDrawnArea || []).some((nda) => nda.blockId === blockId);
                        if (isNewApplication) {
                            const otherSystems = this.state.irrigators.filter((irr) => irr.id !== irrigator.id);
                            const blockIrrigatedByAnotherSystem = otherSystems.some((sys) => sys.applications.some((app) => app.blockIds.includes(blockId)));
                            const percentageOfNonDrawnArea = blockIrrigatedByAnotherSystem ? 0 : 100;
                            irrigator.nonDrawnArea = (irrigator.nonDrawnArea || []).concat({ blockId, percentageOfNonDrawnArea });
                        }
                    });
                });

                // Remove any non drawn areas that have no associated applications.
                irrigator.nonDrawnArea = (irrigator.nonDrawnArea || []).reduce((results, nda) => {
                    const hasApplications = irrigator.applications.some((app) => app.blockIds.includes(nda.blockId));
                    if (hasApplications) results.push(nda);
                    return results;
                }, []);
            }

            this.props.modalInlineClose();
            this.setState({
                selectedApplication: undefined,
                irrigator: irrigator,
                pristine: true,
                irrigators: this.state.irrigators.map((irr) => {
                    if (irr.id === irrigator.id) return irrigator;

                    return irr;
                }),
            });
        });
    }

    updateSiblingApplications(application, irrigator) {
        const otherApplications = irrigationUtils.otherAppsWithSameBlocks(irrigator, application);
        /* eslint-disable no-unused-vars */
        for (const otherApplication of otherApplications) {
            if (application.months) {
                otherApplication.months = otherApplication.months.filter((m) => !application.months.find((m2) => m2.month === m.month && m2.reportingYear === m.reportingYear));
            }
        }
    }

    cancelApplication() {
        const validation = {};
        for (var key in this.state.validation) {
            if (!key.startsWith("irrigator_activity_")) {
                validation[key] = this.state.validation[key];
            }
        }
        this.setState({ selectedApplication: undefined, validation });
        this.props.modalInlineClose();
    }

    deleteApplication(applicationId) {
        const validation = this.state.validation;
        const irrigator = this.state.irrigator;
        irrigator.applications = irrigator.applications.filter((app) => app.id !== applicationId);

        if (irrigator.nonDrawnArea) {
            irrigator.nonDrawnArea = irrigator.nonDrawnArea.filter((nda) => irrigator.applications.some((app) => app.blockIds.includes(nda.blockId)));
        }

        this.setState({
            irrigator: irrigator,
            validation: validation,
            irrigators: this.state.irrigators.map((irr) => {
                if (irr.id === irrigator.id) return irrigator;

                return irr;
            }),
        });
    }

    editApplication(applicationId) {
        const irrigator = this.state.irrigator;
        const application = irrigator.applications.find((a) => a.id === applicationId);
        this.setState({ selectedApplication: utils.clone(application), isFrost: application.schedule === irrigationUtils.Schedule.Frost });
        this.props.modalInlineOpen();
    }

    isValidNutrientGroup(irrigator) {
        let nutrientTotal = 0;
        /* eslint-disable no-unused-vars */
        for (const n of irrigationUtils.nutrients) {
            nutrientTotal += utils.isNumeric(irrigator.nutrients[n], 6) ? parseFloat(irrigator.nutrients[n]) : 0;
        }
        return nutrientTotal > 0;
    }

    submitIrrigator() {
        this.validate().then(async (isValid) => {
            if (isValid) {
                this.setState({ submitting: true, submitSucceeded: false });

                const { irrigator, irrigators, features } = this.state;
                const customComposition = this.props.customCompositions.find((c) => c.id === irrigator.nutrientSource);
                if (customComposition || irrigator.nutrientUnit !== "mgl") {
                    irrigator.nutrientSource = "Blockspecific";
                }
                const featuresToSave = (features || []).map((f) => {
                    // Only save the feature properties we won't to store.
                    const properties = geoJsonUtils.sanitizeProperties(f.properties);

                    // Set irrigator names.
                    if (properties.irrigatorId === irrigator.id) {
                        properties.irrigatorName = irrigator.name;
                    } else if (properties.irrigatorId && !properties.irrigatorName) {
                        const otherIrrigator = irrigators.find((i) => i.id === properties.irrigatorId);
                        if (otherIrrigator) {
                            properties.irrigatorName = otherIrrigator.name;
                        }
                    }

                    return { ...f, properties };
                });

                const updatedAnalysis = this.props.analysis;
                utils.merge(irrigator, updatedAnalysis.irrigators);
                updatedAnalysis.irrigators = updatedAnalysis.irrigators.map((irr) => {
                    const irrigator = irrigators.find((i) => i.id === irr.id);
                    if (irrigator) {
                        const allBlockIds = updatedAnalysis.blocks.map((b) => b.id);
                        return {
                            ...irr,
                            nonDrawnArea: irrigator.nonDrawnArea ? irrigator.nonDrawnArea.filter((nda) => allBlockIds.includes(nda.blockId)) : undefined,
                        };
                    }
                    return irr;
                });
                updatedAnalysis.features = featuresToSave;
                await this.props.updateAnalysisAsync(updatedAnalysis);
                this.setState({ submitting: false, submitSucceeded: true });
            } else {
                const firstFieldInError = this.state.fields.reduce((acc, field) => {
                    if (acc === "" && this.state.validation[field].error) acc = field;
                    return acc;
                }, "");

                if (this.state.fieldRefs[firstFieldInError]) {
                    this.state.fieldRefs[firstFieldInError].current.scrollIntoView({ behavior: "smooth", block: "start" });
                } else {
                    const hasActivityMonthsErrors = Object.keys(this.state.validation).some((key) => key.startsWith("irrigator_activity_months_"));
                    if (hasActivityMonthsErrors) {
                        this.state.fieldRefs["applications"].current.scrollIntoView({ behavior: "smooth", block: "start" });
                    }
                }
            }
        });
    }

    onBlockAreasChange(irrigators) {
        const updatedIrrigator = irrigators.find((irr) => irr.id === this.state.irrigator.id);
        const updatedIrrigators = this.state.irrigators.map((irrigator) => {
            return irrigators.find((irr) => irr.id === irrigator.id);
        });
        this.setState({ irrigator: updatedIrrigator, irrigators: updatedIrrigators });
    }

    onChange(source, e) {
        let { irrigator, isGlobal = false } = this.state;
        switch (source.type) {
            case "nutrients":
                irrigator.nutrients[source.nutrient] = e.target.value;
                break;
            case "application": {
                const application = this.state.selectedApplication || irrigator.applications.find((a) => a.id === source.applicationId);
                switch (source.key) {
                    case "appplication-month":
                        if (!irrigationUtils.removeMonth(application, source.value, source.reportingYear)) {
                            application.months.push({ month: source.value, reportingYear: source.reportingYear || false });
                            this.updateSiblingApplications(application, irrigator);
                        }
                        break;
                    case "irrigator-blocks": {
                        // Special case using array value of multi select block selector
                        const blockIds = e;
                        application.blockIds = blockIds;
                        break;
                    }
                    case "systemDefinition":
                        application.systemDefinition = e.target.checked ? source.value : irrigationUtils.SystemDefinitions.Default;
                        break;
                    case "schedule":
                        application.schedule = e.target.value;
                        if ([irrigationUtils.Schedule.Visual, irrigationUtils.Schedule.Fixed, irrigationUtils.Schedule.BorderDyke, irrigationUtils.Schedule.Flood, irrigationUtils.Schedule.Depth].includes(application.schedule)) {
                            application.soilMoistureUsage = undefined;
                        }
                        break;
                    default:
                        application[source.key] = utils.ctrlVal(e);
                        break;
                }
                break;
            }
            default:
                switch (source.key) {
                    case "nutrientSource": {
                        irrigator.nutrientSource = e.target.value;
                        isGlobal = false;

                        const customComposition = this.props.customCompositions.find((c) => c.id === irrigator.nutrientSource);
                        if (irrigator.nutrientSource === "Blockspecific") {
                            this.clearDefaults();
                        } else if (customComposition) {
                            this.setCustomComposition(customComposition, irrigator);
                            isGlobal = true;
                        } else {
                            this.overseerDefaults(irrigator.nutrientSource, irrigator);
                        }
                        break;
                    }
                    case "nutrientUnit": {
                        const nutrientUnit = e.target.value;
                        irrigator[source.key] = nutrientUnit;
                        break;
                    }
                    case "type":
                        irrigator.type = e.target.value;
                        break;
                    default:
                        irrigator[source.key] = e.target.value;
                        break;
                }
        }

        this.validate(source, this.state.selectedApplication !== undefined).then(() => this.setState({ irrigator, dirty: true, isGlobal }));
    }

    validate(source = undefined, applicationOnly = false) {
        const currentValidation = this.state.validation;
        let validation = {};
        let message = undefined;
        const { irrigator, features = [] } = this.state;
        const drawnIrrigator = features && features.some((f) => f.properties.irrigatorId === irrigator.id);
        let touched = false;

        if (!applicationOnly) {
            /* eslint-disable no-unused-vars */
            for (const n of irrigationUtils.nutrients) {
                touched = irrigationUtils.existingTouched(currentValidation, n) || source === undefined || (source.type === "nutrients" && source.id === n);
                message = utils.isNumeric(irrigator.nutrients[n], -1) ? undefined : "Required";
                if (!message) {
                    message = n === "K" || n === "N" ? validations.rangeShort(0, 200)(parseFloat(irrigator.nutrients[n])) : validations.rangeShort(0, 100)(parseFloat(irrigator.nutrients[n]));
                }
                validation[n] = { touched: touched, error: message !== undefined, message: message };
            }

            message = validations.valueNotEmpty(irrigator.type);
            touched = irrigationUtils.existingTouched(currentValidation, "type") || source === undefined || source.key === "type";
            validation.type = { touched: touched, error: message !== undefined, message: message };

            touched = irrigationUtils.existingTouched(currentValidation, "nutrientGroup") || source === undefined || source.type === "nutrientGroup";
            validation.nutrientGroup = { touched: touched, error: this.isValidNutrientGroup(irrigator) === false };

            message = validations.valueNotEmpty(irrigator.name);
            message = message || validations.maxLength(200)(irrigator.name);
            touched = irrigationUtils.existingTouched(currentValidation, "name") || source === undefined || source.key === "name";
            validation.name = { touched: touched, error: message !== undefined, message: message };

            message = validations.valueNotEmpty(irrigator.nutrientSource);
            touched = irrigationUtils.existingTouched(currentValidation, "nutrientSource") || source === undefined || source.key === "nutrientSource";
            validation.nutrientSource = { touched: touched, error: message !== undefined, message: message };

            const intersectedBlocks = irrigationUtils.getBlocksIntersectedByDrawnIrrigators({ ...this.props.analysis, features }, irrigator.id);

            // There must be at least one application and all intersected blocks must have at least one corresponding application
            const blocksWithoutAnApplication = intersectedBlocks.filter((intersectedBlock) => !irrigator.applications.some((app) => app.blockIds.includes(intersectedBlock.id)));
            message = irrigator.applications.length === 0 || blocksWithoutAnApplication.length > 0 ? "Required" : undefined;
            validation.applications = { touched: source === undefined, error: message !== undefined, message: message, blocks: blocksWithoutAnApplication };

            // There must not be any applications for blocks that are not intersected
            const intersectedBlockIds = intersectedBlocks.map((intersectedBlock) => intersectedBlock.id);
            const nonIntersectedBlocksWithApplications = irrigator.applications.reduce((results, app) => {
                const blockIdsNotIntersected = app.blockIds.filter((blockId) => !intersectedBlockIds.includes(blockId));
                const nonIntersectedBlocks = this.props.analysis.blocks.filter((block) => blockIdsNotIntersected.includes(block.id));
                nonIntersectedBlocks.forEach((nonIntersectedBlock) => {
                    if (nonIntersectedBlock.type !== "FodderCrop" && !results.some((b) => b.id === nonIntersectedBlock.id)) results.push(nonIntersectedBlock);
                });
                return results;
            }, []);
            message = intersectedBlockIds.length > 0 && nonIntersectedBlocksWithApplications.length > 0 ? "Invalid" : undefined;
            validation.nonIntersectingApplications = { touched: source === undefined, error: message !== undefined, message: message, blocks: nonIntersectedBlocksWithApplications };

            const hasFeatureErrors = features.some((f) => f.properties.irrigatorId === irrigator.id && f.properties.error);
            const doesNotIntersectAnyBlocks = !hasFeatureErrors && drawnIrrigator && intersectedBlocks.length === 0;
            const blocksWithTooManyIrrigators = !hasFeatureErrors && irrigationUtils.getBlocksWithTooManyIrrigators(irrigator, { ...this.props.analysis, features }, intersectedBlocks);
            const blocksWithDrawnAreaTooSmall = !hasFeatureErrors && irrigationUtils.getIrrigatedBlocksWithInsignificantDrawnArea(intersectedBlocks);
            const blocksWithDrawnAreaTooLarge = !hasFeatureErrors && irrigationUtils.getIrrigatedBlocksWithTooMuchDrawnArea(intersectedBlocks, { ...this.props.analysis, features });

            const mapIsInvalid = hasFeatureErrors || doesNotIntersectAnyBlocks || blocksWithTooManyIrrigators.length > 0 || blocksWithDrawnAreaTooSmall.length > 0 || blocksWithDrawnAreaTooLarge.length > 0;
            validation["map"] = { touched: touched, error: mapIsInvalid, message: mapIsInvalid ? "Required" : undefined };

            const hasInvalidSlider = (irrigator.nonDrawnArea || []).some((nda) => nda.percentageOfNonDrawnArea === 0);
            validation["sliders"] = { touched: touched, error: hasInvalidSlider, message: hasInvalidSlider ? "Required" : undefined };
        }

        const isMultiManSys = irrigationUtils.evalMultiManagementSysDef(irrigator);

        const applications = this.state.selectedApplication ? [this.state.selectedApplication] : irrigator.applications;

        /* eslint-disable no-unused-vars */
        for (const application of applications) {
            const isFrost = application.schedule === irrigationUtils.Schedule.Frost;
            const soilMoistureUsageConfig = irrigationUtils.evalSoilMoistureUsageConfig(application, irrigator.type);
            const scheduleConfig = irrigationUtils.evalScheduleConfig(application, irrigator.type);
            const showSoilMoistureUsage = scheduleConfig.SoilMoistureUsageControls.find((s) => s.Controls.includes(irrigationUtils.Ctrl.SoilMoistureUsage)) && !isFrost;

            //Schedule
            message = validations.valueNotEmpty(application.schedule);
            touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.basedOnId);
            validation[irrigationUtils.basedOnId] = { touched: touched, error: message !== undefined, message: message };

            if (!isFrost) {
                //Management System Definition
                message = isMultiManSys && application.schedule !== irrigationUtils.Schedule.Depth && application.systemDefinition === irrigationUtils.SystemDefinitions.Default ? "Required" : undefined;
                touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.manDefaultId);
                validation[irrigationUtils.manDefaultId] = { touched: touched, error: message !== undefined, message: message };
            }

            //SoilMoistureUsage
            if (showSoilMoistureUsage) {
                message = !application.soilMoistureUsage ? "Required" : undefined;
                touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.soilMoistureUsageId);
                validation[irrigationUtils.soilMoistureUsageId] = { touched: touched, error: message !== undefined, message: message };
            } else {
                application.soilMoistureUsage = undefined;
            }

            //Outwash
            if (irrigator.type !== irrigationUtils.IrrigatorTypes.BorderDyke) {
                application.outWashOccurs = undefined;
            }

            // using application depth
            if (application.schedule === irrigationUtils.Schedule.Depth) {
                message = validations.required(application.depthAmountMm);
                message = message || validations.range(0, 1000)(application.depthAmountMm);
                message = message || validations.isNumeric(application.depthAmountMm);
                touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.appDepthId);
                validation[irrigationUtils.appDepthId] = { touched: touched, error: message !== undefined, message: message };
            } else {
                application.depthAmountMm = undefined;
            }

            //Trigger point
            if (isFrost) {
                message = validations.required(application.averageFrostTempC);
                message = message || validations.isNumeric(application.averageFrostTempC);
                message = message || validations.range(0, 9)(application.averageFrostTempC);
                touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.avgFrostTempId);
                validation[irrigationUtils.avgFrostTempId] = { touched: touched, error: message !== undefined, message: message };

                message = validations.required(application.averageFrostDurationHrs);
                message = message || validations.isNumeric(application.averageFrostDurationHrs);
                message = message || validations.range(1, 24)(application.averageFrostDurationHrs);
                touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.avgFrostDurId);
                validation[irrigationUtils.avgFrostDurId] = { touched: touched, error: message !== undefined, message: message };

                message = validations.required(application.numberOfDaysOfFrost);
                message = message || validations.isNumeric(application.numberOfDaysOfFrost);
                message = message || validations.range(1, 31)(application.numberOfDaysOfFrost);
                touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.frostDaysId);
                validation[irrigationUtils.frostDaysId] = { touched: touched, error: message !== undefined, message: message };
            } else {
                application.averageFrostTempC = undefined;
                application.averageFrostDurationHrs = undefined;
                application.numberOfDaysOfFrost = undefined;
            }

            if (application.systemDefinition === irrigationUtils.SystemDefinitions.UserDefined) {
                //Depth and Min Depth
                if (soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.Depth) || soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.MinimumDepth)) {
                    message = validations.required(application.depth);
                    message = message || validations.range(0, 100000)(application.depth);
                    message = message || validations.isNumeric(application.depth);
                    message = message || (parseFloat(application.maximumDepth) <= parseFloat(application.depth) ? "Must be less than maximum depth" : undefined);
                    let id = soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.Depth) ? irrigationUtils.depthId : irrigationUtils.minDepthId;
                    touched = irrigationUtils.isTouched(currentValidation, source, id);
                    validation[id] = { touched: touched, error: message !== undefined, message: message };
                } else {
                    application.depth = undefined;
                }

                //Max Depth
                if (soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.MaximumDepth)) {
                    message = validations.required(application.maximumDepth);
                    message = message || validations.isNumeric(application.maximumDepth);
                    message = message || validations.range(0, 100000)(application.maximumDepth);
                    message = message || (parseFloat(application.maximumDepth) <= parseFloat(application.depth) ? "Must be greater than minimum depth" : undefined);
                    touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.maxDepthId);
                    validation[irrigationUtils.maxDepthId] = { touched: touched, error: message !== undefined, message: message };
                } else {
                    application.maximumDepth = undefined;
                }

                //Return Period
                if (soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.ReturnPeriod) || soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.MinReturnPeriod)) {
                    message = validations.required(application.returnPeriod);
                    message = message || validations.isNumeric(application.returnPeriod);
                    message = message || validations.range(0, 100000)(application.returnPeriod);
                    let id = soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.ReturnPeriod) ? irrigationUtils.retPerId : irrigationUtils.minRetPerId;
                    touched = irrigationUtils.isTouched(currentValidation, source, id);
                    validation[id] = { touched: touched, error: message !== undefined, message: message };
                } else {
                    application.returnPeriod = undefined;
                }

                //Units
                if (soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.Units)) {
                    message = validations.required(application.units);
                    touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.unitsId);
                    validation[irrigationUtils.unitsId] = { touched: touched, error: message !== undefined, message: message };
                } else {
                    application.units = undefined;
                }

                const triggerMsg = soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.TriggerPoint) && soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.Target) && application.trigger !== undefined && application.target !== undefined && application.units ? (application.units === "Paw" ? (application.trigger >= application.target ? "Trigger point must be less then target" : undefined) : application.trigger <= application.target ? "Target must be less then trigger point" : undefined) : undefined;

                const maxValue = application.units === "Deficit" ? 250 : 100;
                //Trigger point
                if (soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.TriggerPoint)) {
                    message = validations.required(application.trigger);
                    message = message || validations.isNumeric(application.trigger);
                    message = message || validations.range(0, maxValue)(application.trigger);
                    message = message || triggerMsg;
                    touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.trigPtId);
                    validation[irrigationUtils.trigPtId] = { touched: touched, error: message !== undefined, message: message };
                } else {
                    application.trigger = undefined;
                }

                //Target
                if (soilMoistureUsageConfig.Controls.includes(irrigationUtils.Ctrl.Target)) {
                    message = validations.required(application.target);
                    message = message || validations.isNumeric(application.target);
                    message = message || validations.range(0, maxValue)(application.target);
                    message = message || triggerMsg;
                    touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.targetId);
                    validation[irrigationUtils.targetId] = { touched: touched, error: message !== undefined, message: message };
                } else {
                    application.target = undefined;
                }
            } else {
                application.depth = undefined;
                application.maximumDepth = undefined;
                application.returnPeriod = undefined;
                application.trigger = undefined;
                application.target = undefined;
                application.units = undefined;
            }

            //Months
            const monthGroupId = irrigationUtils.monthGroupId(application.id);
            if (!this.state.selectedApplication) {
                message = application.months.length === 0 ? "Required" : undefined;
                touched = irrigationUtils.isTouched(currentValidation, source, monthGroupId);
                validation[monthGroupId] = { touched: touched, error: message !== undefined, message: message };
            }

            //Blocks
            if (!drawnIrrigator) {
                message = application.blockIds.length === 0 ? "Required" : undefined;
                touched = irrigationUtils.isTouched(currentValidation, source, irrigationUtils.blksId);
                validation[irrigationUtils.blksId] = { touched: touched, error: message !== undefined, message: message };
                validation.irrigator_activity_missing_blocks = { touched: touched, error: message !== undefined, message: message };
                /* eslint-disable no-unused-vars */
                for (const blockId of application.blockIds) {
                    message = this.state.availableBlocks.find((b) => b.id === blockId) ? undefined : "A block has been included that is already defined within another irrigator. Please remove this activity.";
                    if (message) {
                        validation[monthGroupId] = { touched: touched, error: message !== undefined, message: message };
                        break;
                    }
                }
            }
        }

        return new Promise((resolve) => {
            const isValid = !Object.keys(validation).some((key) => validation[key].error);
            this.setState({ validation }, () => {
                resolve(isValid);
            });
        });
    }

    setDefaultNutrients(irrigator) {
        irrigator.nutrients.N = 2.5;
        irrigator.nutrients.P = 0.1;
        irrigator.nutrients.K = 1.6;
        irrigator.nutrients.S = 2.5;
        irrigator.nutrients.Ca = 9.3;
        irrigator.nutrients.Mg = 2.2;
        irrigator.nutrients.Na = 9.5;
        irrigator.nutrientUnit = "mgl";
    }

    setBorderDykeNutrients(irrigator) {
        irrigator.nutrients.N = 11.0;
        irrigator.nutrients.P = 2.1;
        irrigator.nutrients.K = 13.1;
        irrigator.nutrients.S = 10.0;
        irrigator.nutrients.Ca = 26.9;
        irrigator.nutrients.Mg = 9.5;
        irrigator.nutrients.Na = 37.5;
        irrigator.nutrientUnit = "mgl";
    }

    overseerDefaults(source, irrigator) {
        const validation = this.state.validation;
        if (source === "Overseerdefaultfixed") this.setDefaultNutrients(irrigator);
        else this.setBorderDykeNutrients(irrigator);
        validation.N = { touched: true, error: false, message: null };
        validation.P = { touched: true, error: false, message: null };
        validation.K = { touched: true, error: false, message: null };
        validation.S = { touched: true, error: false, message: null };
        validation.Ca = { touched: true, error: false, message: null };
        validation.Mg = { touched: true, error: false, message: null };
        validation.Na = { touched: true, error: false, message: null };
        validation.nutrientGroup = { touched: false, error: false, message: "" };
        this.setState({ irrigator: irrigator, validation: validation });
    }

    setCustomComposition(source, irrigator) {
        const validation = this.state.validation;
        const { nutrients = {} } = source;
        irrigator.nutrients.N = nutrients.N || 0;
        irrigator.nutrients.P = nutrients.P || 0;
        irrigator.nutrients.K = nutrients.K || 0;
        irrigator.nutrients.S = nutrients.S || 0;
        irrigator.nutrients.Ca = nutrients.Ca || 0;
        irrigator.nutrients.Mg = nutrients.Mg || 0;
        irrigator.nutrients.Na = nutrients.Na || 0;
        irrigator.nutrientUnit = source.irrigationUnit;
        validation.N = { touched: true, error: false, message: null };
        validation.P = { touched: true, error: false, message: null };
        validation.K = { touched: true, error: false, message: null };
        validation.S = { touched: true, error: false, message: null };
        validation.Ca = { touched: true, error: false, message: null };
        validation.Mg = { touched: true, error: false, message: null };
        validation.Na = { touched: true, error: false, message: null };
        validation.nutrientGroup = { touched: false, error: false, message: "" };
        this.setState({ irrigator: irrigator, validation: validation });
    }

    clearDefaults() {
        const validation = this.state.validation;
        const irrigator = this.state.irrigator;
        irrigator.nutrients.N = 0;
        irrigator.nutrients.P = 0;
        irrigator.nutrients.K = 0;
        irrigator.nutrients.S = 0;
        irrigator.nutrients.Ca = 0;
        irrigator.nutrients.Mg = 0;
        irrigator.nutrients.Na = 0;
        irrigator.nutrientUnit = "mgl";
        validation.N = { touched: true, error: false, message: null };
        validation.P = { touched: true, error: false, message: null };
        validation.K = { touched: true, error: false, message: null };
        validation.S = { touched: true, error: false, message: null };
        validation.Ca = { touched: true, error: false, message: null };
        validation.Mg = { touched: true, error: false, message: null };
        validation.Na = { touched: true, error: false, message: null };
        validation.nutrientGroup = { touched: false, error: false, message: "" };
        this.setState({ irrigator: irrigator, validation: validation });
    }

    updateIrrigatorForUpdatedDrawing(irrigator, analysis) {
        const intersectedBlocks = irrigationUtils.getBlocksIntersectedByDrawnIrrigators(analysis, irrigator.id);
        const intersectedBlockIds = intersectedBlocks.map((b) => b.id);

        // Filter out blocks that are no longer intersected by the irrigation system drawing.
        let applications = (irrigator.applications || []).reduce((apps, app) => {
            const blockIds = app.blockIds.filter((blockId) => {
                // FodderCrop blocks are a special case. Because they can't be drawn, they are the only block type that can be selected
                // along with drawn intersected blocks. If there is one in this application, leave it there.
                const block = (analysis.blocks || []).find((b) => b.id === blockId);
                if (block.type === domain.BlockType.FodderCrop) return true;

                // Filter out those blocks that are still intersected by the irrigation system drawing.
                if (intersectedBlockIds.includes(blockId)) return true;

                return false;
            });

            if (blockIds.length > 0) apps.push({ ...app, blockIds });

            return apps;
        }, []);

        for (var application of applications) {
            this.updateSiblingApplications(application, irrigator);
        }

        this.validate().then(() => {
            const updatedIrrigator = {
                ...irrigator,
                applications: [...applications],
                nonDrawnArea: undefined,
            };
            delete updatedIrrigator.nonDrawnArea;

            this.setState({
                irrigator: updatedIrrigator,
                irrigators: this.state.irrigators.map((irr) => {
                    if (irr.id === updatedIrrigator.id) return updatedIrrigator;

                    return irr;
                }),
            });
        });
    }

    handleMapChange = (features) => {
        if (this.state.features) this.updateIrrigatorForUpdatedDrawing(this.state.irrigator, { ...this.props.analysis, features });

        this.setState({ features });
    };

    render() {
        const { irrigator, features, availableBlocks } = this.state;
        if (!irrigator) return null;

        const drawnIrrigator = features && features.some((f) => f.properties.irrigatorId === irrigator.id); // irrigator.drawnArea && irrigator.drawnArea.length > 0
        var showFrostProtection;
        var intersectedBlocks;
        var showAddButton = irrigator.type;

        if (drawnIrrigator) {
            intersectedBlocks = irrigationUtils.getBlocksIntersectedByDrawnIrrigators({ ...this.props.analysis, features }, irrigator.id);
            showAddButton = showAddButton && intersectedBlocks.every((ib) => availableBlocks.some((b) => ib.id === b.id));
            showFrostProtection = intersectedBlocks.some((b) => b.type === domain.BlockType.ProductiveFruit) && ![irrigationUtils.IrrigatorTypes.BorderDyke, irrigationUtils.IrrigatorTypes.Flood, ""].includes(this.state.irrigator.type);
        } else {
            showFrostProtection = availableBlocks.some((b) => b.type === domain.BlockType.ProductiveFruit) && ![irrigationUtils.IrrigatorTypes.BorderDyke, irrigationUtils.IrrigatorTypes.Flood, ""].includes(irrigator.type);
        }

        const _referrer = `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/irrigation`;

        const { irrigator_activity_missing_blocks = {} } = this.state.validation;

        const irrigationActivitiesActions = (
            <div className="u-flex">
                <ActionLink className="IconLink--arrow-plus u-textWhite" id="irrigation_add_activity" onClick={() => this.addApplication()}>
                    <span>Add irrigation activity</span>
                </ActionLink>
                {showFrostProtection && (
                    <ActionLink className="IconLink--arrow-plus u-ml-sm u-textWhite" id="irrigation_add_frost" onClick={() => this.addFrost()}>
                        <span>Add frost activity</span>
                    </ActionLink>
                )}
            </div>
        );

        return (
            <div>
                <input type="hidden" id="irrigation_details_id" value={irrigator.id} />

                <SavePrompt blockIf={this.state.dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={_referrer} />
                <Tile title={this.props.isNew ? "Add irrigation system" : "Edit irrigation system"} waiting={this.state.submitting} referrer={_referrer}>
                    <Alert type="info" text="Enter the irrigation system details and nutrient concentration or use defaults. Add irrigation activities for the blocks and months that were irrigated." />
                    <TileBody className="u-pt-0">
                        <IrrigationDetails irrigator={irrigator} customCompositions={this.props.customCompositions} isGlobal={this.state.isGlobal} blocks={this.state.blocks} onChange={this.onChange.bind(this)} validation={this.state.validation} fieldRefs={this.state.fieldRefs} />
                        {irrigator.type && (
                            <div ref={this.state.fieldRefs["map"]}>
                                <IrrigatorMap farm={this.props.farm} analysis={this.props.analysis} irrigator={this.state.irrigator} onChange={this.handleMapChange} />
                            </div>
                        )}
                        {(drawnIrrigator || (irrigator.applications && irrigator.applications.length > 0)) && (
                            <div className="u-mt-lg" ref={this.state.fieldRefs["sliders"]}>
                                <BlockAreas analysis={{ ...this.props.analysis, features }} existingSystems={this.state.irrigators} thisSystem={irrigator} onChange={this.onBlockAreasChange.bind(this)} isInvalid={this.state.validation["sliders"] && this.state.validation["sliders"].touched && this.state.validation["sliders"].error} />
                            </div>
                        )}
                        {irrigator.type && ((irrigator.applications || []).length > 0 || showAddButton) && (
                            <div className="Grid-cell u-mt-lg" ref={this.state.fieldRefs["applications"]}>
                                <Tile title="Irrigation activities" actions={showAddButton && irrigationActivitiesActions} tertiary>
                                    <TileBody>
                                        {irrigator.applications && irrigator.applications.length > 0 && this.state.validation.applications && this.state.validation.applications.error && (
                                            <div className="Field has-error Tile-body--first-child u-mb-md">
                                                <small className="Field-error u-mt-0">
                                                    <span>The following blocks are intersected by the drawn irrigated area of this irrigation system and therefore must have at least one corresponding irrigation activity. Please add an irrigation activity for these blocks or adjust the drawing to exclude them:</span>
                                                    <ul className="disc">
                                                        {this.state.validation.applications.blocks.map((block) => (
                                                            <li key={block.id}>{block.name}</li>
                                                        ))}
                                                    </ul>
                                                </small>
                                            </div>
                                        )}
                                        {irrigator.applications && irrigator.applications.length > 0 && this.state.validation.nonIntersectingApplications && this.state.validation.nonIntersectingApplications.error && (
                                            <div className="Field has-error u-mt-0 u-mb-md">
                                                <small className="Field-error u-mt-0">
                                                    <span>The following blocks have irrigation activities but are not intersected by the drawn irrigated area of this irrigation system. Please remove the irrigation activities for these blocks or adjust the drawing to include them:</span>
                                                    <ul className="disc">
                                                        {this.state.validation.nonIntersectingApplications.blocks.map((block) => (
                                                            <li key={block.id}>{block.name}</li>
                                                        ))}
                                                    </ul>
                                                </small>
                                            </div>
                                        )}
                                        <ActivitySummary blocks={this.props.analysis.blocks} irrigator={irrigator} deleteApplication={this.deleteApplication.bind(this)} onChange={this.onChange.bind(this)} editApplication={this.editApplication.bind(this)} validation={this.state.validation} analysis={{ ...this.props.analysis, features }} />
                                        {(!irrigator.applications || irrigator.applications.length === 0) && (
                                            <div className="Tile-body-message u-mt-md u-mb-md">
                                                <p className="h4 m-mt-0">You do not have any irrigation activity</p>
                                                <Button className="IconLink--arrow-plus Button Button--secondary u-mt-md" id="summary_add_irrigation" onClick={() => this.addApplication()} disabled={!irrigator.type}>
                                                    <span>Add irrigation activity</span>
                                                </Button>
                                                {showFrostProtection && (
                                                    <Button className="IconLink--arrow-plus Button Button--secondary u-mt-md" id="summary_add_frost" onClick={() => this.addFrost()} disabled={!irrigator.type}>
                                                        <span>Add frost activity</span>
                                                    </Button>
                                                )}
                                                {this.state.validation.applications && this.state.validation.applications.touched && this.state.validation.applications.error && (
                                                    <div className="Field has-error u-mt-sm">
                                                        <small className="Field-error">Please add at least one activity</small>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {irrigator_activity_missing_blocks.touched && irrigator_activity_missing_blocks.error && irrigator.type && (
                                            <div className="Field has-error u-mt-sm">
                                                <small className="Field-error">There are activities that do not have any blocks defined. Please draw an irrigated area or edit/remove these activities.</small>
                                            </div>
                                        )}
                                    </TileBody>
                                </Tile>
                            </div>
                        )}
                        {this.state.selectedApplication && <ActivityModal key={this.state.selectedApplication.id} application={this.state.selectedApplication} blocks={this.props.analysis.blocks} availableBlocks={availableBlocks} intersectedBlocks={intersectedBlocks} irrigator={irrigator} deleteApplication={this.deleteApplication.bind(this)} cancelApplication={this.cancelApplication.bind(this)} saveApplication={this.saveApplication.bind(this)} onChange={this.onChange.bind(this)} isFrost={this.state.isFrost} validation={this.state.validation} irrigationSoilMoistureUsage={this.props.refData.irrigationSoilMoistureUsage} irrigationManagementUnits={this.props.refData.irrigationManagementUnits} soilMoistureTypes={this.props.refData.soilMoistureAssessmentTypes} />}
                    </TileBody>
                    <TileFooter>
                        <div className="ButtonBar ButtonBar--fixed">
                            <div className="ButtonBar-left">
                                <Link to={_referrer} className="Button Button--secondary" id="irrigation-details-cancel">
                                    Cancel
                                </Link>
                            </div>
                            <div className="ButtonBar-right">
                                <Button id="irrigation-system-save" primary waiting={this.state.submitting} onClick={this.submitIrrigator.bind(this)}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </TileFooter>
                </Tile>
            </div>
        );
    }
}
