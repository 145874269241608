import React from "react";

const SortableTableHeader = (props) => {
    const { label, fieldName, sortCriteria, handleSort, number, date, bool, shrink, dataWidth, center = false } = props;
    const fieldType = number ? "number" : date ? "date" : bool ? "bool" : "string";
    return (
        <th data-width={dataWidth} className={`u-link ${shrink ? "th--shrink" : ""}`} onClick={handleSort(fieldName, fieldType)}>
            <div className={`u-flex ${center ? "u-flexJustifyCenter" : ""}`}>
                <span className="u-mt-xs">{label}</span>
                {sortCriteria.fieldName === fieldName && !sortCriteria.desc && <i className="icon icon-arrow-up u-ml-auto" />}
                {sortCriteria.fieldName === fieldName && sortCriteria.desc === true && <i className="icon icon-arrow-down u-ml-auto" />}
            </div>
        </th>
    );
};

export default SortableTableHeader;
