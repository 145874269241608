import { useState } from "react";
import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import RadioGroupField from "components/FormFields/RadioGroupField";
import SelectField from "components/FormFields/SelectField";
import SoilPropertiesSummary from "./SoilPropertiesSummary";
import { hasModifiedSoilProperties } from "./_utils";
import { useRefData } from "common/hooks";

export default function SoilProperties({ soil, defaultProperties, form }) {
    const refData = useRefData();
    const [editMode, setEditMode] = useState(false);
    const isModified = hasModifiedSoilProperties(soil);
    const isSmapSoil = soil.sibling && soil.sibling.smapReference;

    const toggleEditMode = (toggle) => () => {
        setEditMode(toggle);
        resetSoilProperties(form);
    };

    const panelActions = (
        <div className="u-flexSplit">
            {!editMode && !isModified && (
                <ActionLink id="override-defaults-soil-properties" className="IconLink--edit u-textWhite" onClick={toggleEditMode(true)}>
                    {isSmapSoil ? "Override S-Map defaults" : "Override system defaults"}
                </ActionLink>
            )}
            {editMode && !isModified && (
                <ActionLink id="use-defaults-soil-properties" className="IconLink--cross-circle u-textWhite" onClick={toggleEditMode(false)}>
                    {isSmapSoil ? "Use S-Map defaults" : "Use system defaults"}
                </ActionLink>
            )}
            {isModified && (
                <ActionLink id="restore-defaults-soil-properties" className="IconLink--refresh u-textWhite" onClick={toggleEditMode(false)}>
                    {isSmapSoil ? "Restore S-Map defaults" : "Restore system defaults"}
                </ActionLink>
            )}
        </div>
    );

    let panelInfo = editMode && "Soil properties should only be overridden if there is good reason to believe that the defaults are not accurate.";

    return (
        <Panel title="Soil properties" actions={panelActions} info={panelInfo} skyBlue className="u-mt-lg">
            <PanelBody>
                <SoilPropertiesSummary soil={soil} editMode={editMode} toggleEditMode={toggleEditMode} isModified={isModified} refData={refData} />
                {(isModified || editMode) && (
                    <div className="flexWidget u-mt-md">
                        <div className="flexWidget__body">
                            <Grid>
                                <GridCell className="u-width1of4">
                                    <Field name="advancedSettings.kLeaching" label="K leaching potential (0-6)" placeholder="0" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                </GridCell>
                            </Grid>
                            <Grid title="Water content">
                                <GridCell className="u-width4of5">
                                    <div className="Table u-mt-sm u">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th className="u-textCenter">Top 0-30 cm</th>
                                                    <th className="u-textCenter">Middle 30-60 cm</th>
                                                    <th className="u-textCenter">Bottom &gt; 60 cm</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Wilting point (15 bar)</td>
                                                    <td>
                                                        <Field name={`advancedSettings.wiltingPoint.top`} uom="mm/10 cm" placeholder={soil.sibling && soil.sibling.wiltingPoint && soil.sibling.wiltingPoint.top} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                                    </td>
                                                    <td>
                                                        <Field name={`advancedSettings.wiltingPoint.middle`} uom="mm/10 cm" placeholder={soil.sibling && soil.sibling.wiltingPoint && soil.sibling.wiltingPoint.middle} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                                    </td>
                                                    <td>
                                                        <Field name={`advancedSettings.wiltingPoint.bottom`} uom="mm/10 cm" placeholder={soil.sibling && soil.sibling.wiltingPoint && soil.sibling.wiltingPoint.bottom} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Field capacity</td>
                                                    <td>
                                                        <Field name={`advancedSettings.fieldCapacity.top`} uom="mm/10 cm" placeholder={soil.sibling && soil.sibling.fieldCapacity && soil.sibling.fieldCapacity.top} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                                    </td>
                                                    <td>
                                                        <Field name={`advancedSettings.fieldCapacity.middle`} uom="mm/10 cm" placeholder={soil.sibling && soil.sibling.fieldCapacity && soil.sibling.fieldCapacity.middle} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                                    </td>
                                                    <td>
                                                        <Field name={`advancedSettings.fieldCapacity.bottom`} uom="mm/10 cm" placeholder={soil.sibling && soil.sibling.fieldCapacity && soil.sibling.fieldCapacity.bottom} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Saturation</td>
                                                    <td>
                                                        <Field name={`advancedSettings.saturation.top`} uom="mm/10 cm" placeholder={soil.sibling && soil.sibling.saturation && soil.sibling.saturation.top} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                                    </td>
                                                    <td>
                                                        <Field name={`advancedSettings.saturation.middle`} uom="mm/10 cm" placeholder={soil.sibling && soil.sibling.saturation && soil.sibling.saturation.middle} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                                    </td>
                                                    <td>
                                                        <Field name={`advancedSettings.saturation.bottom`} uom="mm/10 cm" placeholder={soil.sibling && soil.sibling.saturation && soil.sibling.saturation.bottom} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </GridCell>
                            </Grid>
                            <Grid title="Chemical and physical parameters (Topsoil 0-10 cm)">
                                <GridCell className="u-width1of5">
                                    <Field
                                        name="advancedSettings.bulkDensity"
                                        label="Bulk density"
                                        uom={
                                            <span>
                                                kg/m<sup>3</sup>
                                            </span>
                                        }
                                        placeholder={(soil.sibling && soil.sibling.bulkDensity) || (defaultProperties && defaultProperties.bulkDensity)}
                                        format={FormUtils.formatters.formatInt}
                                        formatOnBlur
                                        component={TextField}
                                    />
                                </GridCell>
                                <GridCell className="u-width1of5">
                                    <Field name="advancedSettings.carbon" label="Carbon" uom="% C" placeholder={(soil.sibling && soil.sibling.carbon) || (defaultProperties && defaultProperties.carbon)} format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                </GridCell>
                                <GridCell className="u-width1of5">
                                    <Field name="advancedSettings.clay" label="Clay" uom="%" placeholder={(soil.sibling && soil.sibling.clay) || (defaultProperties && defaultProperties.clay)} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                </GridCell>
                                <GridCell className="u-width1of5">
                                    <Field name="advancedSettings.sand" label="Sand" uom="%" placeholder={(soil.sibling && soil.sibling.sand) || (defaultProperties && defaultProperties.sand)} format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                </GridCell>
                                <GridCell className="u-width1of5">
                                    <Field name="advancedSettings.structuralIntegrity" label="Structural integrity" placeholder={(soil.sibling && soil.sibling.structuralIntegrity) || (defaultProperties && defaultProperties.structuralIntegrity)} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                </GridCell>
                            </Grid>
                            <Grid title="Chemical and physical parameters (Subsoil 10-60 cm)">
                                <GridCell className="u-width1of5">
                                    <Field name="advancedSettings.subClay" label="Subsoil clay" uom="%" placeholder={(soil.sibling && soil.sibling.subClay) || (defaultProperties && defaultProperties.subSoilClay)} format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                </GridCell>
                                <GridCell className="u-width1of5">
                                    <Field name="advancedSettings.saturatedConductivity" label="Saturated conductivity" uom="mm/day" placeholder="0" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                </GridCell>
                            </Grid>
                            <Grid title="Change in soil organic matter levels" instructions="These values affect maintenance lime recommendations only, has no affect on N leaching">
                                <GridCell>
                                    <Field name="organicChangeMethod" options={ORGANIC_CHANGE_METHOD_OPTIONS} inline type="radio" component={RadioGroupField} />
                                </GridCell>
                                {!soil.organicChangeMethod && (
                                    <GridCell>
                                        <div className="u-font-600 u-pt-md">By default, effect of changing carbon on lime maintenance is estimated from the block N immobilisation status.</div>
                                    </GridCell>
                                )}
                                {soil.organicChangeMethod === "OrganicMatterStatus" && (
                                    <GridCell className="u-width1of5">
                                        <Field name="advancedSettings.omStatus" label="Status" placeholder="Select a status" required options={refData.omStatus} component={SelectField} />
                                    </GridCell>
                                )}
                                {soil.organicChangeMethod === "RateOfChange" && (
                                    <GridCell className="u-width1of5">
                                        <Field name="advancedSettings.rocOfC" label="Change" required uom="kg C/ha/yr of total soil carbon" placeholder="0" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                    </GridCell>
                                )}
                                {soil.organicChangeMethod === "MeasuredCContent" && (
                                    <>
                                        <GridCell className="u-width1of5">
                                            <Field name="advancedSettings.cMeasurementMethod" label="Carbon measurement method" required placeholder="Select a method" options={refData.cMeasurementMethod} component={SelectField} />
                                        </GridCell>
                                        <GridCell className="u-width1of5">
                                            <Field name="advancedSettings.initialCContent" label="Initial carbon content" required format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-width1of5">
                                            <Field name="advancedSettings.finalCContent" label="Final carbon content" required format={FormUtils.formatters.formatDecimal(2)} formatOnBlur component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-width1of5">
                                            <Field name="advancedSettings.numYearsChange" label="# years change occured over" required format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                                        </GridCell>
                                    </>
                                )}
                                <GridCell className="u-width1of5">
                                    <Field name="advancedSettings.qTpH" label="QT pH" placeholder="Use default" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                                </GridCell>
                            </Grid>
                        </div>
                    </div>
                )}
            </PanelBody>
        </Panel>
    );
}

export const validateSoilPropertiesFields = (soil) => {
    const isModified = hasModifiedSoilProperties(soil);
    if (!isModified) {
        return {};
    }

    const validation = {
        advancedSettings: {
            wiltingPoint: {},
            saturation: {},
            fieldCapacity: {},
        },
    };

    const { advancedSettings = {} } = soil;

    if (advancedSettings.wiltingPoint) {
        if (advancedSettings.wiltingPoint.top) {
            validation.advancedSettings.wiltingPoint.top = FormUtils.validators.range(1, 100)(advancedSettings.wiltingPoint.top);
        }
        if (advancedSettings.wiltingPoint.middle) {
            validation.advancedSettings.wiltingPoint.middle = FormUtils.validators.range(1, 100)(advancedSettings.wiltingPoint.middle);
        }
        if (advancedSettings.wiltingPoint.bottom) {
            validation.advancedSettings.wiltingPoint.bottom = FormUtils.validators.range(1, 100)(advancedSettings.wiltingPoint.bottom);
        }
    }
    if (advancedSettings.saturation) {
        if (advancedSettings.saturation.top) {
            validation.advancedSettings.saturation.top = FormUtils.validators.range(1, 100)(advancedSettings.saturation.top);
        }
        if (advancedSettings.saturation.middle) {
            validation.advancedSettings.saturation.middle = FormUtils.validators.range(1, 100)(advancedSettings.saturation.middle);
        }
        if (advancedSettings.saturation.bottom) {
            validation.advancedSettings.saturation.bottom = FormUtils.validators.range(1, 100)(advancedSettings.saturation.bottom);
        }
    }
    if (advancedSettings.fieldCapacity) {
        if (advancedSettings.fieldCapacity.top) {
            validation.advancedSettings.fieldCapacity.top = FormUtils.validators.range(1, 100)(advancedSettings.fieldCapacity.top);
        }
        if (advancedSettings.fieldCapacity.middle) {
            validation.advancedSettings.fieldCapacity.middle = FormUtils.validators.range(1, 100)(advancedSettings.fieldCapacity.middle);
        }
        if (advancedSettings.fieldCapacity.bottom) {
            validation.advancedSettings.fieldCapacity.bottom = FormUtils.validators.range(1, 100)(advancedSettings.fieldCapacity.bottom);
        }
    }

    if (advancedSettings.kLeaching) {
        validation.advancedSettings.kLeaching = FormUtils.validators.range(0.1, 6)(advancedSettings.kLeaching);
    }
    if (advancedSettings.bulkDensity) {
        validation.advancedSettings.bulkDensity = FormUtils.validators.range(200, 2000)(advancedSettings.bulkDensity);
    }
    if (advancedSettings.carbon) {
        validation.advancedSettings.carbon = FormUtils.validators.range(0, 100)(advancedSettings.carbon);
    }
    if (advancedSettings.clay) {
        validation.advancedSettings.clay = FormUtils.validators.range(0, 100)(advancedSettings.clay);
    }
    if (advancedSettings.sand) {
        validation.advancedSettings.sand = FormUtils.validators.range(0, 100)(advancedSettings.sand);
    }
    if (advancedSettings.structuralIntegrity) {
        validation.advancedSettings.structuralIntegrity = FormUtils.validators.range(0, 1)(advancedSettings.structuralIntegrity);
    }
    if (advancedSettings.subClay) {
        validation.advancedSettings.subClay = FormUtils.validators.range(0, 100)(advancedSettings.subClay);
    }
    if (advancedSettings.saturatedConductivity) {
        validation.advancedSettings.saturatedConductivity = FormUtils.validators.range(0, 1000)(advancedSettings.saturatedConductivity);
    }
    if (advancedSettings.clay && advancedSettings.sand) {
        const totalPercentageOfClayAndSand = parseFloat(advancedSettings.clay) + parseFloat(advancedSettings.sand);
        validation.advancedSettings.sand = FormUtils.validators.max(100)(totalPercentageOfClayAndSand, "The sum of sand and clay should not exceed 100%");
    }

    if (soil.organicChangeMethod === "OrganicMatterStatus") {
        validation.advancedSettings.omStatus = FormUtils.validators.required(advancedSettings.omStatus);
    } else if (soil.organicChangeMethod === "RateOfChange") {
        validation.advancedSettings.rocOfC = FormUtils.validators.required(advancedSettings.rocOfC);
        validation.advancedSettings.rocOfC = validation.advancedSettings.rocOfC || FormUtils.validators.range(1, 10000)(advancedSettings.rocOfC);
    } else if (soil.organicChangeMethod === "MeasuredCContent") {
        validation.advancedSettings.cMeasurementMethod = FormUtils.validators.required(advancedSettings.cMeasurementMethod);
        validation.advancedSettings.initialCContent = FormUtils.validators.required(advancedSettings.initialCContent);
        validation.advancedSettings.initialCContent = validation.advancedSettings.initialCContent || FormUtils.validators.range(0.01, 10000)(advancedSettings.initialCContent);
        validation.advancedSettings.finalCContent = FormUtils.validators.required(advancedSettings.finalCContent);
        validation.advancedSettings.finalCContent = validation.advancedSettings.finalCContent || FormUtils.validators.range(0.01, 10000)(advancedSettings.finalCContent);
        validation.advancedSettings.numYearsChange = FormUtils.validators.required(advancedSettings.numYearsChange);
        validation.advancedSettings.numYearsChange = validation.advancedSettings.numYearsChange || FormUtils.validators.range(1, 20)(advancedSettings.numYearsChange);
    }
    if (advancedSettings.qTpH) {
        validation.advancedSettings.qTpH = FormUtils.validators.range(3, 11)(advancedSettings.qTpH);
    }

    return validation;
};

export const resetSoilProperties = (form) => {
    form.change("advancedSettings", undefined);
    form.change("organicChangeMethod", undefined);
};

const ORGANIC_CHANGE_METHOD_OPTIONS = [
    { value: "", text: "Use default" },
    { value: "OrganicMatterStatus", text: "Select organic matter status" },
    { value: "RateOfChange", text: "Enter rate of change of carbon" },
    { value: "MeasuredCContent", text: "Use measured carbon content data" },
];
