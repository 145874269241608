import { Fragment } from "react";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import Alert from "components/Alert";
import Watermark from "components/Watermark";
import { FileSummary, SummaryRow } from "components/PlanningReport";
import { useAnalysisResults } from "containers/BudgetHome";
import GhgSummary from "./GhgSummary";

export default function Ghg({ before, after, showFileComparison, beforeSeq, afterSeq }) {
    const scenarios = [after];
    if (before) scenarios.unshift(before);

    const { data: afterResults } = useAnalysisResults(after?.details);
    const { data: beforeResults } = useAnalysisResults(before?.details);

    if (!afterResults?.ghg && !beforeResults?.ghg) {
        return (
            <div className="u-pt-lg">
                <Alert type="info" text="There are no GHG results for this analysis" />
            </div>
        )
    }

    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <GhgSummary before={before} after={after} />
            <Eco2Footprint before={before} after={after} />
            <ForestSummary before={before} after={after} beforeSeq={beforeSeq} afterSeq={afterSeq} />
        </div>
    )
}

function Eco2Footprint({ before, after }) {
    const refData = useRefData();

    const getGhgTotals = (analysis) => {
        const { currentResults = {} } = analysis;
        const { ghgResults } = currentResults;
        if (!ghgResults) return { sources: [] };

        const sources = Object.keys(ghgResults.productionResults)
            .filter((k) => ghgResults.productionResults[k].cO2Source > 0)
            .map((k, i) => {
                const text = ((refData.productionTypes && refData.productionTypes.find((r) => r.value === k)) || { text: k }).text;
                const value = ghgResults.productionResults[k].cO2Footprint;
                const unit = k === "Dairy" ? "kg/cow" : k === "FruitCrop" || k === "VegetableArable" || k === "ExportedSupplement" ? "kg/ha" : "kg/SU";
                return { text, value, unit };
            });

        return {
            sources,
        };
    };

    const afterDetails = getGhgTotals(after.details);
    const beforeDetails = before && getGhgTotals(before.details);

    const afterSources = afterDetails.sources.map((s) => s.text);
    const beforeSources = beforeDetails && beforeDetails.sources.map((s) => s.text);
    const uniqueSources = Array.from(new Set([...afterSources, ...(beforeSources || [])]));

    const isComparing = !!before && !!after;

    if (uniqueSources.length === 0) {
        return null;
    }

    return (
        <div className="ReportSection">
            <h1>
                <span className="u-textTransformNone">CO2e</span> footprint
            </h1>
            <div className="Table Table-Compressed">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            {isComparing && (
                                <th className="numeric u-before" data-width="12.5">
                                    {utils.truncateText(before.displayName, 35)}
                                </th>
                            )}
                            <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                {utils.truncateText(after.displayName, 35)}
                            </th>
                            {isComparing && (
                                <th className="numeric" data-width="16.7">
                                    Change
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {uniqueSources.map((s, i) => {
                            const actualSource = afterDetails.sources.find((src) => src.text === s);
                            const beforeSource = beforeDetails && beforeDetails.sources.find((src) => src.text === s);
                            const first = actualSource || beforeSource;
                            return <SummaryRow key={i} beforeValue={beforeSource?.value} afterValue={actualSource?.value} isComparing={isComparing} label={s} unit={first.unit} dp={s === "Exported supplements" ? 2 : 0} />;
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function ForestSummary({ before, after, beforeSeq, afterSeq }) {
    const refData = useRefData();

    const getSequestration = (budget, seq, type, isHarvested) => {
        const { blocks = [] } = budget;
        const forestTypeTotal = seq.reduce((total, s) => {
            const block = blocks.find((blk) => blk.id === s.blockId) || { forests: [] };
            const { forests = [] } = block;
            const forest = forests.find((f) => f.id === s.forestId && ((f.forestType === type && f.isHarvested === isHarvested) || !type));
            if (forest) {
                const { carbonStockValues = [] } = s;
                const area = utils.round((block.areaInHectares * forest.percentage) / 100 || 1, 1);
                const value = utils.round(carbonStockValues[0] * area, 2);
                return (total += value);
            }
            return total;
        }, 0);
        return forestTypeTotal;
    };

    const getForestData = (budget) => {
        const { blocks = [] } = budget;
        const csBlocks = blocks.filter((b) => b.type === "NonProductiveTreesAndScrub" && b.forests && b.forests.length > 0);
        const result = [];
        csBlocks.forEach((b, i) => {
            const { forests = [] } = b;
            forests.forEach((f, x) => {
                let forestType = result.find((r) => r.type === f.forestType && r.isHarvested === f.isHarvested);
                if (!forestType) {
                    forestType = {
                        type: f.forestType,
                        area: 0,
                        isHarvested: f.isHarvested,
                        typeId: `${f.forestType}-${f.isHarvested}`,
                    };
                    result.push(forestType);
                }
                forestType.area += utils.round((b.areaInHectares * f.percentage) / 100 || 1, 1);
            });
        });
        return result;
    };

    const actualForestData = getForestData(after.details);
    const baseForestData = before && getForestData(before.details);
    const actualTypes = actualForestData.map((s) => s.typeId);
    const baseTypes = baseForestData && baseForestData.map((s) => s.typeId);
    const uniqueTypes = Array.from(new Set([...actualTypes, ...(baseTypes || [])]));

    const totalActualSeq = afterSeq && getSequestration(after.details, afterSeq);
    const totalBaseSeq = baseForestData && beforeSeq && getSequestration(before.details, beforeSeq);
    const actualForestArea = actualForestData.filter((a) => !a.isHarvested).reduce((t, f) => (t += f.area), 0);
    const baseForestArea = baseTypes && baseForestData.filter((a) => !a.isHarvested).reduce((t, f) => (t += f.area), 0);

    const isComparing = !!before && !!after;

    if (uniqueTypes.length === 0) {
        return null;
    }

    return (
        <div className="ReportSection">
            <h1>Forests</h1>
            <div className="Table Table-Compressed">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            {isComparing && (
                                <th className="numeric u-before" data-width="12.5">
                                    {utils.truncateText(before.displayName, 35)}
                                </th>
                            )}
                            <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                {utils.truncateText(after.displayName, 35)}
                            </th>
                            {isComparing && (
                                <th className="numeric" data-width="16.7">
                                    Change
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {uniqueTypes.map((t, i) => {
                            const actualType = actualForestData.find((a) => a.typeId === t);
                            const baseType = baseForestData && baseForestData.find((a) => a.typeId === t);
                            const type = actualType ? actualType.type : baseType.type;
                            const isHarvested = actualType ? actualType.isHarvested : baseType.isHarvested;

                            const actualSeq = afterSeq && getSequestration(after.details, afterSeq, type, isHarvested);
                            const baseSeq = baseForestData && beforeSeq && getSequestration(before.details, beforeSeq, type, isHarvested);

                            const category = `${utils.valueToText(refData.forestType, type)}  ${isHarvested ? " (harvested)" : ""}`;
                            const showSeq = actualSeq || baseSeq ? true : false;

                            return (
                                <Fragment key={i}>
                                    {!isHarvested && <SummaryRow beforeValue={baseType?.area} afterValue={actualType?.area} isComparing={isComparing} category={category} label="Area" unit="ha" dp={1} />}
                                    {showSeq && <SummaryRow beforeValue={baseSeq} afterValue={actualSeq} isComparing={isComparing} category={isHarvested ? category : " "} label="Sequestration" unit="tonnes" dp={1} />}
                                </Fragment>
                            );
                        })}
                        <SummaryRow beforeValue={baseForestArea} afterValue={actualForestArea} isComparing={isComparing} category="Total" label="Area" unit="ha" dp={1} />
                        {((totalActualSeq && totalActualSeq > 0) || (totalBaseSeq && totalBaseSeq > 0)) && <SummaryRow beforeValue={totalBaseSeq} afterValue={totalActualSeq} isComparing={isComparing} category=" " label="Sequestration" unit="tonnes" dp={1} />}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
