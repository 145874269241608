import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import Wetlands from "./Wetlands";
import CatchmentCreate from "./CatchmentCreate";
import CatchmentEdit from "./CatchmentEdit";
import DrainageEdit from "./DrainageEdit";
import RiparianStripEdit from "./RiparianStripEdit";

export default function WetlandsTab({ farm, analysis }) {
    return (
        <Switch>
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/drainage" render={() => <Wetlands farm={farm} analysis={analysis} />} restoreScrollY />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/drainage/unfenced/new" render={() => <CatchmentCreate farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/drainage/unfenced/:wetlandId" render={() => <CatchmentEdit farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/drainage/fenced/:blockId" render={() => <CatchmentEdit farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/drainage/:drainageMethod" render={() => <DrainageEdit farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/drainage/riparian/:blockId" render={() => <RiparianStripEdit farm={farm} analysis={analysis} />} />
        </Switch>
    )
}
