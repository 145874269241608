import { Link } from "react-router-dom";
import * as icons from "common/icons";
import TileSubPanel from "components/TileSubPanel";
import Card from "components/new/Card";
import CardBody from "components/new/CardBody";
import ActionLink from "components/ActionLink";
import { useConfirm, useNavigate } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function AdditiveList({ farm, analysis }) {
    const navigate = useNavigate();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const _editDCD = () => {
        navigate(`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/dcd`);
    };

    const _deleteDCD = (analysis) => () => {
        confirm(`Are you sure you want to delete the applicationsof DCD additives?`, async () => {
            analysis.nitrateInhibitors = [];
            await updateAnalysisAsync(analysis);
        });
    };

    const hasDCD = (analysis.nitrateInhibitors || []).length > 0;

    return (
        <>
            <TileSubPanel title="Additives" className="u-mb-md" green stickyHeaderName="analysis.fertiliser.additives.collapsed">
                <Card>
                    <CardBody className="u-mt-md">
                        {!hasDCD && (
                            <div className="Tile-body-message">
                                <img src={icons.add} className="u-p-5" alt="Plus" />
                                <p className="lead">You do not have any additives</p>
                                <Link id="add-dcd" to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/dcd`} className="IconLink--arrow-plus Button Button--lg u-mt-lg">
                                    Add DCD
                                </Link>
                            </div>
                        )}
                        {hasDCD && (
                            <div className="Table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Additive</th>
                                            <th className="th--shrink">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="hover u-link">
                                            <td id="edit-dcd" onClick={_editDCD} className="u-textBold a">
                                                DCD - Nitrification Inhibitors
                                            </td>
                                            <td className="u-textCenter">
                                                <ActionLink id="delete-dcd" className="u-ml-xs IconLink--trash" onClick={_deleteDCD(analysis)}></ActionLink>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </TileSubPanel>
        </>
    )
}
