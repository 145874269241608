import React from "react";
import Modal from "components/Modal/Modal";
import ModalBody from "components/new/ModalBody";
import ModalFooter from "components/new/ModalFooter";
import ActionLink from "components/ActionLink";

export default function DownloadFarms({ close, farmIds = [], farmLookup, fileName }) {
    const farmVm = farmLookup ? farmIds.map((id) => farmLookup[id]) : [];

    const downloadCsv = () => {
        const header = ["Id", "FarmName", "AnalysisName", "SupplierNumber", "Type", "Region", "Area", "GhgHa", "NLossHa", "PLossHa", "NCE", "NSurplus"];
        const body = farmVm
            .filter((f) => f?.id)
            .map((farm) => {
                const bodyValues = [farm.id, farm.farmName?.replace(/,/g, ""), farm.analysisName?.replace(/,/g, ""), farm.supplierNumber?.replace(/,/g, ""), farm.type, farm.farmRegion, farm.farmArea, farm.ghgPerHa, farm.nLossPerHa, farm.pLossPerHa, farm.nce, farm.nSurplus];
                return bodyValues.join(",");
            });
        const csv = header.join(",") + "\n" + body.join("\n");
        var blob = new Blob([csv], { type: "text/csv" });
        var url = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    return (
        <Modal notification={true} style={{ height: "350px", width: "400px" }} close={close}>
            <ModalBody>
                <div>
                    <i className="icon icon-info icon--lg icon--confirm" />
                    <h2>Download</h2>
                    <h4>{farmVm.length} farms</h4>
                    <p>Click below to download the farms as a csv file</p>
                    <div className="u-flex u-flexJustifyCenter u-pt-md">
                        <ActionLink id="download-benchmarking-by-farms" className="IconLink--download u-link" onClick={downloadCsv}>
                            Download as csv
                        </ActionLink>
                    </div>
                </div>
                <ModalFooter>
                    <button id="cancel-button" onClick={close} className="Button Button--primary" type="button" name="cancel-button">
                        Close
                    </button>
                </ModalFooter>
            </ModalBody>
        </Modal>
    );
}
