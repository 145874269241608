import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import * as utils from "common/utils";
import { useModal } from "common/hooks";
import { useAnalysis } from "containers/hooks";

export default function ViewPublicationHistoryModal({ publication, close }) {
    const { data: analysis } = useAnalysis(publication.farmId, publication.publishedAnalysisId);

    const title = `Publication history - ${analysis && analysis.name} (${publication.publishedTo})`;

    return (
        <Modal title={title} close={close} wide allowOffline fluid>
            <ModalBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th>Version</th>
                                <th>Status</th>
                                <th>Action</th>
                                <th>Date</th>
                                <th>By</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(publication.history || [])
                                .sort((a, b) => (b.createdOn < a.createdOn ? -1 : 1))
                                .map((history, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{history.newVersion}</td>
                                            <td>{history.newStatus}</td>
                                            <td>{history.action}</td>
                                            <td>{utils.dateLong(history.createdOn)}</td>
                                            <td>{history.createdBy.userFullName}</td>
                                            <td>{history.comment}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </ModalBody>
            <ModalFooter>
                <ModalFooterRight>
                    <Button id="cancel" onClick={close} secondary>
                        Close
                    </Button>
                </ModalFooterRight>
            </ModalFooter>
        </Modal>
    );
}

export function useViewPublicationHistoryModal(publication) {
    const [modal, openModal] = useModal(ViewPublicationHistoryModal);

    const openViewPublicationHistoryModal = () => {
        const modalProps = {
            publication,
        };
        openModal(modalProps);
    };

    return [modal, openViewPublicationHistoryModal];
}
