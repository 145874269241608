import { Component } from "react";
import * as validations from "common/validations";
import SelectPack from "components/SelectPack";
import AnimalDistribution from "components/AnimalDistribution";
import * as utils from "common/utils";
import { availableEnterprises, hideAnimalsTimeOnPad } from "./_utils";
import CheckboxPack from "components/CheckboxPack";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import Alert from "components/Alert";
import { useRefData } from "common/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function AnimalsOnStructure({ analysis, structure, addApplication, deleteApplication, name, activeControls, isFirst, enterpriseDisabled, validation, onChange, applications, animalsTab }) {
    const refData = useRefData();

    return <AnimalsOnStructureClassComponent analysis={analysis} structure={structure} addApplication={addApplication} deleteApplication={deleteApplication} name={name} activeControls={activeControls} isFirst={isFirst} enterpriseDisabled={enterpriseDisabled} validation={validation} onChange={onChange} refData={refData} applications={applications} animalsTab={animalsTab} />
}

class AnimalsOnStructureClassComponent extends Component {
    render() {
        const { addApplication, validation, onChange, name, analysis, structure, isFirst, refData, activeControls } = this.props;
        const dairyOnly = isDairyOnly(structure);
        const hideTime = hideAnimalsTimeOnPad(structure);
        const enterprises = availableEnterprises(analysis, refData, structure.type, structure.id);
        const isMilkingShed = structure.type === "MilkingShed";
        const showDaysPerMonth = structure.type === "StandoffPad";
        const daysPerMonth = structure.units === "DaysPerMonth";
        const hoursMessage = hideTime ? null : (
            <span>
                and the <b>{daysPerMonth ? `days per month spent on the ${name.toLowerCase()}` : `hours spent on the ${name.toLowerCase()} per day`}</b>
            </span>
        );
        const message = isMilkingShed ? (
            <p>
                Enter the <b>percentage of cows</b> fed in the shed for the <b>months</b> in which milking occurs.
            </p>
        ) : (
            <p>
                Enter the <b>percentage of animals</b> on the {name.toLowerCase()} {hoursMessage} and select the <b>months it applies to</b>.
            </p>
        );
        const canDeleteAnimals = isMilkingShed;
        const addAnimalDistributionLink = (
            <span id="structure_add_animals" className="IconLink--arrow-plus u-link" onClick={addApplication}>
                Add animal distribution
            </span>
        );

        return (
            <div className={`Grid Grid--withGutter ${isFirst ? "" : "u-mt-lg"}`}>
                <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                    {name && <h3>{isMilkingShed ? "Feeding in the milking shed" : `Time animals are on the ${name.toLowerCase()}`}</h3>}
                    {!isMilkingShed && (
                        <SelectPack meta={{ nrf: true }} onChange={(e) => onChange(e, { type: "structure", key: "enterpriseId" })} value={structure.enterpriseId} id="structure_enterpriseId" val={validation.enterpriseId} label="Enterprise" disabled={enterprises.length === 0 || this.props.enterpriseDisabled} requiredLabel={true}>
                            <option value="" disabled={true}>
                                Select an enterprise
                            </option>
                            {utils.mapRefDataItems(enterprises)}
                        </SelectPack>
                    )}
                    <CheckboxPack meta={{ nrf: true }} isHidden={!activeControls.grazingHours} id="grazingHours" label="Feeding regime of winter pad and grazing hours" tip="Animals on the pad also graze pasture for a limited time each day. They may still be fed supplements while on the wintering pad. Leave unchecked if the animals are all on the feed pad continuously for the month" value={structure.grazingHours} onChange={(e) => onChange(e, { type: "structure", key: "grazingHours" })} />
                    <CheckboxPack meta={{ nrf: true }} isHidden={!activeControls.isFarmGrazedOutPrior} id="isFarmGrazedOutPrior" label="Grazed out most of farm before moving animals onto the pad" tip="The farm pasture is mostly grazed out prior to moving the animals onto the pad. This means that most of paddocks were grazed down to residual covers." value={structure.isFarmGrazedOutPrior} onChange={(e) => onChange(e, { type: "structure", key: "isFarmGrazedOutPrior" })} />
                    <CheckboxPack meta={{ nrf: true }} isHidden={!showDaysPerMonth} id="application_activeManagement" label="Use days per month" value={daysPerMonth} onChange={(e) => onChange(e, { type: "units" })} />
                </div>
                <div className="Grid-cell u-mt-lg">
                    <Tile title="Animal distribution" actions={addAnimalDistributionLink} tertiary>
                        <TileBody>
                            {validation.animalDistribution && validation.animalDistribution.error && <Alert type="error" text={validation.animalDistribution.message} />}
                            {message}
                            <AnimalDistribution {...this.props} enterprises={enterprises} daysPerMonth={daysPerMonth} addApplication={() => addApplication()} canDeleteAnimals={canDeleteAnimals} dairyOnly={dairyOnly} noAnimalHeading="There are no animals on this structure" hideTime={hideTime} applications={this.props.applications} animalsTab={this.props.animalsTab} />
                        </TileBody>
                    </Tile>
                </div>
            </div>
        );
    }
}

export function validateAnimals(validation, structure, currentValidation, source, setVal, hideTime) {
    let message = undefined;
    let key = undefined;
    let objkey = undefined;
    const isDaysPerMonth = structure.units === "DaysPerMonth";

    objkey = "enterpriseId";
    message = validations.required(structure[objkey]);
    validation[objkey] = setVal(objkey, currentValidation, source, message);
    const daysPerMonth = structure.units === "DaysPerMonth";

    if (!daysPerMonth && structure.grazingHours) {
        const totalGrazingHours = structure.animals.reduce((t, a) => t + (24 - Number(a.time)), 0);
        if (totalGrazingHours === 0) {
            validation.animalDistribution = setVal("animalDistribution", currentValidation, source, 'When selecting "Feeding regime of winter pad and grazing hours" at least one animal distribution must contain grazing hours');
        }
    }

    /*eslint-disable no-unused-vars*/
    for (const application of structure.animals) {
        objkey = "percentageOfAnimals";
        key = `${application.id}_${objkey}`;
        message = validations.requiredExt(application[objkey]);
        message = message || validations.isNumeric(application[objkey]);
        message = message || validations.range(1, 100)(application[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);

        if (!hideTime) {
            objkey = "time";
            key = `${application.id}_${objkey}`;
            message = validations.requiredExt(application[objkey]);
            message = message || validations.isNumeric(application[objkey]);
            message = message || (isDaysPerMonth ? validations.range(0, 31)(application[objkey]) : validations.range(1, 24)(application[objkey]));
            message = message || (!structure.feedingApron || !structure.feedingApron.timeApron || application[objkey] > structure.feedingApron.timeApron ? undefined : "Time on structure must be greater then time on apron");

            validation[key] = setVal(key, currentValidation, source, message);
        }

        objkey = "month";
        key = `${application.id}_${objkey}`;
        message = application.months && application.months.length > 0 ? undefined : "Please select at least one month";
        validation[key] = setVal(key, currentValidation, source, message);
    }
}

const isDairyOnly = (structure) => ["StandoffPad", "FeedingPad", "MilkingShed"].includes(structure.type);
