import { Panel, PanelBody } from "components/Panel";
import Alert from "components/Alert";
import OutdoorPigFeedAmountsFieldArray from "./OutdoorPigFeedAmountsFieldArray";

export default function OutdoorPigFeedAmounts({ form, outdoorPigs }) {
    return (
        <Panel title="Feed amounts" midBlue notCollapsible>
            <Alert type="info" text="The amount is the amount brought in through the farm gate. Feed for pigs (sows, gilts, boars, outdoor raised weaners) in villages or in barns or standoff pads for part of the year must be included. Feed to weaners (growers, porkers and baconers) raised in sheds should not be included." />
            <PanelBody>
                <OutdoorPigFeedAmountsFieldArray title="Growers & finishers" fieldName="nonSowFeedAmounts" form={form} outdoorPigs={outdoorPigs} />
                {!outdoorPigs.growOutUnitOnly && <OutdoorPigFeedAmountsFieldArray title="Sows" fieldName="sowFeedAmounts" form={form} outdoorPigs={outdoorPigs} />}
            </PanelBody>
        </Panel>
    )
}
