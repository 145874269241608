import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import { v4 as uuidv4 } from "uuid";
import * as FormUtils from "common/FormUtils";
import * as geoJsonUtils from "common/geoJsonUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import SelectField from "components/FormFields/SelectField";
import FileUploadField from "components/FormFields/FileUploadField";
import CheckboxField from "components/FormFields/CheckboxField";
import { ForMoreInfoZendeskLink } from "components/Help";
import { getAnalysisGroupIndex, getAnalysisGroupLabel } from "containers/FarmHome/_utils";
import { getFarmAnalysesWithGeoJsonAsync } from "containers/BudgetHome/Blocks/_queries";
import { useModal } from "common/hooks";

export default function ImportMapModal({ analysis, onSubmit, close }) {
    const [analysesWithGeoJson, fetchError] = useFetchAnalysesWithGeoJson(analysis.farmId);
    const copyFromOptions = useCopyFromOptions(analysesWithGeoJson, analysis.id);
    const sourceOptions = getSourceOptions(copyFromOptions);
    const loading = !analysesWithGeoJson && !fetchError;
    const hasBlocks = analysis.blocks.length > 0;

    const handleSourceChanged = (form) => (source) => {
        const fileExtensions = sourceOptions.find((src) => src.value === source).fileExtensions;
        form.change("fileExtensions", fileExtensions);
        if (!fileExtensions) form.change("file", undefined);
    };

    const validate = (values) => {
        const validation = {};

        validation.source = FormUtils.validators.required(values.source);

        if (values.fileExtensions) {
            validation.file = FormUtils.validators.required(values.file);
            validation.file = validation.file || values.file.error;
            validation.file = validation.file || FormUtils.validators.required(values.file.json);

            if (!validation.file) {
                const supportedFeatures = values.file.json.features.filter((f) => ["Polygon", "MultiPolygon", "GeometryCollection"].includes(f.geometry.type));
                if (supportedFeatures.length === 0) {
                    if (values.source === "kml") validation.file = "KML files must contain at least one geometry of type Polygon, MultiPolygon or MultiGeometry.";
                    if (values.source === "geoJson") validation.file = "GeoJSON files must contain at least one geometry of type Polygon, MultiPolygon or GeometryCollection.";
                    validation.file += " Please contact whoever provided this file to you to get an updated version with valid geometry types.";
                } else {
                    // Crude validation of coordinate reference system. Center of first feature must have valid lat/lng values.
                    const centerOfFirstFeature = geoJsonUtils.center(supportedFeatures[0]);
                    if (centerOfFirstFeature.lat < -90 || centerOfFirstFeature.lat > 90 || centerOfFirstFeature.lng < -180 || centerOfFirstFeature.lng > 180) {
                        validation.file = `Unsupported coordinate reference system. File must use the WGS84 datum with longitude and latitude (in that order) units of decimal degrees. Please contact whoever provided this file to you to get an updated version with the correct coordinate reference system.`;
                    }
                }
            }
        } else {
            validation.copyFromAnalysisId = FormUtils.validators.required(values.copyFromAnalysisId);
        }

        return validation;
    };

    const submit = (values) => {
        let importedGeoJson = undefined;
        if (values.file && values.file.json) {
            importedGeoJson = values.file.json;
        } else {
            importedGeoJson = analysesWithGeoJson.find((a) => a.id === values.copyFromAnalysisId).geoJson;
        }

        // Auto assign polygons to blocks if id or name matches.
        importedGeoJson.features = importedGeoJson.features.reduce((results, feature) => {
            // Only import features with no specified layer or with 'Block' layer.
            if (!feature.properties.layer || feature.properties.layer === "Block") {
                const properties = { ...feature.properties, imported: true };

                const block = values.autoAssignBlocks && analysis.blocks.find((b) => properties.blockId === b.id || (properties.name && properties.name.toLowerCase() === b.name.toLowerCase()) || (properties.blockName && properties.blockName.toLowerCase() === b.name.toLowerCase()));
                if (block) {
                    properties.layer = "Block";
                    properties.blockId = block.id;
                } else {
                    properties.layer = "UnassignedLand";
                    delete properties.blockId;
                }

                results.push({ ...feature, properties });
            }
            return results;
        }, []);

        if (onSubmit && importedGeoJson) onSubmit(importedGeoJson, values.source);
        close();
    };

    const info = (
        <>
            Use this screen to import a map of this farm into this analysis. You can either copy the map from another analysis or import it from a KML or GeoJSON file. {hasBlocks ? 'The "Auto assign" feature will automatically assign imported land parcels to any existing blocks with matching names. ' : ""}
            <ForMoreInfoZendeskLink url="https://support.overseer.org.nz/hc/en-us/articles/5412455621913" />
        </>
    );

    return (
        <Form initialValues={{ autoAssignBlocks: true }} validate={validate} onSubmit={submit}>
            {({ form, values, handleSubmit, submitting }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Import map" close={close} waiting={loading || submitting} fluid>
                            <ModalBody info={info} error={fetchError}>
                                <Field name="source" label="Import from" required placeholder="Select an import source" onChange={handleSourceChanged(form)} options={sourceOptions} component={SelectField} />
                                {values.source === "copy" && <Field name="copyFromAnalysisId" label="Copy map from" required placeholder="Select an analysis" options={copyFromOptions} disabled={loading} component={SelectField} />}
                                {values.fileExtensions && <Field name="file" fileExtensions={values.fileExtensions} required component={FileUploadField} />}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    {hasBlocks && <Field name="autoAssignBlocks" label="Auto assign imported land parcels to existing blocks" className="u-mt-0 u-mr-md" type="checkbox" component={CheckboxField} />}
                                    <Button id="submit" submit waiting={submitting} disabled={fetchError || loading || submitting}>
                                        Preview imported map
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useImportMapModal(analysis) {
    const [modal, openModal] = useModal(ImportMapModal);

    const openImportMapModal = (onSubmit) => {
        const modalProps = {
            analysis,
            onSubmit
        };
        openModal(modalProps);
    };

    return [modal, openImportMapModal];
}

function useFetchAnalysesWithGeoJson(farmId) {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getFarmAnalysesWithGeoJsonAsync.send(farmId))
                .then((response) => {
                    const analysesWithGeoJson = response || [];
                    analysesWithGeoJson.forEach((a) => a.geoJson.features.forEach((f) => (f.id = uuidv4())));
                    setData(analysesWithGeoJson);
                })
                .catch(() => {
                    setError("Error loading list of analyses");
                });
        };

        if (farmId && !data && !error) {
            fetchData();
        }

        return () => dispatch(getFarmAnalysesWithGeoJsonAsync.abort());
    }, [farmId, data, error, dispatch]);

    return [data, error];
}

function useCopyFromOptions(analysesWithGeoJson, copyToAnalysisId) {
    const [options, setOptions] = useState();

    useEffect(() => {
        if (analysesWithGeoJson && copyToAnalysisId) {
            const grouped = analysesWithGeoJson
                .filter((a) => a.id !== copyToAnalysisId)
                .map((a) => {
                    return {
                        ...a,
                        groupIndex: getAnalysisGroupIndex(a),
                        groupLabel: getAnalysisGroupLabel(a),
                    };
                });

            const sorted = grouped.sort((a, b) => {
                // Sort by groupIndex
                if (a.groupIndex > b.groupIndex) return 1;
                if (a.groupIndex < b.groupIndex) return -1;

                // Sort by year (if any)
                if (a.year && b.year) {
                    if (a.year > b.year) return -1;
                    if (a.year < b.year) return 1;
                }

                // Sort by name
                if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;

                return 0;
            });

            setOptions(sorted.map((a) => ({ value: a.id, text: a.name, groupIndex: a.groupIndex, groupLabel: a.groupLabel })));
        }
    }, [analysesWithGeoJson, copyToAnalysisId]);

    return options;
}

function getSourceOptions(copyFromOptions) {
    if (!copyFromOptions) return [];

    const options = [];

    if (copyFromOptions.length > 0) options.push({ value: "copy", text: "Copy from another analysis" });

    options.push({ value: "kml", text: "Import from KML file", fileExtensions: ["kml"] });
    options.push({ value: "geoJson", text: "Import from GeoJSON file", fileExtensions: ["geojson", "json"] });

    return options;
}
