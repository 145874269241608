import React from "react";
import SelectPack from "components/SelectPack2";
import { Field } from "react-final-form";
import InputPack from "components/InputPack";
import * as FormUtils from "common/FormUtils";
import FieldGroup from "components/FieldGroup";
import Alert from "components/Alert";
import * as domain from "common/domain";
import { ExistingWarning, GlobalCopy } from "./Dialogs";
import { GridCell } from "components/Grid";
import { useRefData } from "common/hooks";

export default function Organic({ viewModel, customFertiliser, customCompositions }) {
    return (
        <>
            <CustomOrganic customFertiliser={customFertiliser} viewModel={viewModel} />
            <ExistingWarning viewModel={viewModel} />
            <OrganicFields viewModel={viewModel} customCompositions={customCompositions} />
        </>
    )
}

const showDmContent = (fertiliser) => ["CompostMulches", "Otherorganicmaterial"].includes(fertiliser.organicType);
const showPerOrganicN = (fertiliser) => ["Otherorganicmaterial"].includes(fertiliser.organicType);
const showDairyEffluent = (fertiliser) => ["Dairyfactory"].includes(fertiliser.organicType);
const showEffluentForm = (fertiliser) => ["Piggeryeffluent", "Importeddairyeffluent"].includes(fertiliser.organicType);

export { showDmContent, showPerOrganicN, showDairyEffluent, showEffluentForm };

const OrganicFields = ({ viewModel, customCompositions }) => {
    const refData = useRefData();

    const { fertiliser = {} } = viewModel;
    const { importedDairyEffluentForm = [], piggeryEffluentForm = [], otherFertilisers = [], factoryEffluent = [] } = refData;
    const effluentForm = fertiliser.organicType === "Importeddairyeffluent" ? importedDairyEffluentForm : piggeryEffluentForm;
    const disPerOrganicN = showPerOrganicN(fertiliser);
    const disDmContent = showDmContent(fertiliser);
    const myProducts = customCompositions.filter((c) => c.userId).map((c) => ({ value: c.id, text: c.name, groupIndex: 2, groupLabel: "Copy my dairy factory effluent" }));
    const orgProducts = customCompositions.filter((c) => !c.userId).map((c) => ({ value: c.id, text: c.name, groupIndex: 3, groupLabel: "Copy organisation dairy factory effluent" }));
    myProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    orgProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    const groupedFactoryEffluent = factoryEffluent.map((f) => {
        const hasWhey = f.value.includes("whey");
        return { ...f, ...{ groupIndex: hasWhey ? 0 : 1, groupLabel: hasWhey ? "Whey" : "Custom nutrients" } };
    });
    const dairyFactoryEffluent = [...groupedFactoryEffluent, ...myProducts, ...orgProducts];

    const organicFertiliserTypeTip = domain.OrganicFertiliserTypeTips.find((t) => t.key === viewModel.fertiliser.organicType);

    return (
        <GridCell>
            <Field name="fertiliser.organicType" label="Organic types" component={SelectPack} required={true} options={otherFertilisers} placeholder="Select an organic material" disabled={viewModel.existing} />
            {organicFertiliserTypeTip && <Alert type="info" text={organicFertiliserTypeTip.text} />}

            {(disDmContent || disPerOrganicN) && (
                <FieldGroup>
                    {disDmContent && <Field name="fertiliser.dmContent" type="text" label="Dry matter content percentage" uom="%" disabled={viewModel.existing} requiredLabel={true} component={InputPack} placeholder="Dry matter content as a percentage" format={FormUtils.formatters.formatInt} formatOnBlur dataWidth={disPerOrganicN ? "50" : "100"} />}
                    {disPerOrganicN && <Field name="fertiliser.percentageOrganicN" type="text" disabled={viewModel.existing} label="Percentage of N in inorganic form" uom="%" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur dataWidth={disPerOrganicN ? "50" : "100"} />}
                </FieldGroup>
            )}
            {showDairyEffluent(fertiliser) && <Field name="fertiliser.dairyFactoryEffluent" disabled={viewModel.existing} label="Factory effluent" component={SelectPack} required={true} options={dairyFactoryEffluent} placeholder="Select a factory effluent" />}
            <GlobalCopy viewModel={viewModel} />
            {showEffluentForm(fertiliser) && <Field name="fertiliser.effluentForm" disabled={viewModel.existing} label="Effluent form" component={SelectPack} required={true} options={effluentForm} placeholder="Select an effluent form" />}
        </GridCell>
    )
}

const CustomOrganic = ({ customFertiliser, viewModel }) => {
    const localProducts = customFertiliser.filter((c) => c.userId).map((c) => ({ value: c.id, text: c.productName, groupIndex: 1, groupLabel: "Copy my custom organic product" }));
    const orgProducts = customFertiliser.filter((c) => !c.userId).map((c) => ({ value: c.id, text: c.productName, groupIndex: 2, groupLabel: "Copy organisation custom organic product" }));
    localProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    orgProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    const products = [...[{ value: "NEW", text: "New custom organic", groupLabel: "Add new custom organic", groupIndex: 0 }], ...localProducts, ...orgProducts];

    return (
        <div className="Grid-cell">
            {(localProducts.length > 0 || orgProducts.length > 0) && <Field name="fertiliser.id" label="Custom products" component={SelectPack} required={true} options={products} />}
            <Field name={`fertiliser.productName`} label="Name" component={InputPack} requiredLabel={true} disabled={viewModel.existing} />
        </div>
    )
}
