import * as utils from "common/utils";

export default function AnalysisCommentsTable({ analysis }) {
    const comments = (analysis.comments || []).sort(utils.compareDate("createdOn", true));
    const hasComments = comments.length > 0;

    return (
        <div className="Table">
            <table>
                <thead>
                    <tr>
                        <th data-width="17.5">Date</th>
                        <th data-width="17.5">By</th>
                        <th data-width="10">Type</th>
                        <th>Comment</th>
                    </tr>
                </thead>
                {!hasComments && (
                    <tbody>
                        <tr>
                            <td colSpan="4">
                                <div className="Tile-body">
                                    <div className="Tile-body-message">
                                        <h4 className="u-mt-0">There are no comments</h4>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
                {hasComments && (
                    <tbody>
                        {comments.map((comment, i) => {
                            return (
                                <tr key={comment.id} className="hover" valign="top">
                                    <td>{utils.dateLong(comment.createdOn)}</td>
                                    <td>{comment.createdBy.userFullName}</td>
                                    <td>{comment.type}</td>
                                    <td>{comment.comment}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </table>
        </div>
    )
}
