import * as iu from "./_utils";
import * as domain from "common/domain";
import * as utils from "common/utils";
import * as cropUtils from "../Crops/_utils";
import { farmYear } from "common/domain";
import ActionLink from "components/ActionLink";
import { useRefData } from "common/hooks";

export default function ActivitySummary({ blocks, irrigator, onChange, deleteApplication, validation, editApplication, analysis }) {
    const refData = useRefData();

    let rowIndex = 0;

    const irrigationApplications = irrigator.applications.reduce((a, b) => {
        const blockNames = blocks
            .filter((block) => b.blockIds.includes(block.id))
            .sort((a, b) => (b.name < a.name ? 1 : -1))
            .map((block) => block.name)
            .join(" | ");

        const blockApps = a.find((x) => x.blockIds.every((i) => b.blockIds.includes(i)) && b.blockIds.every((i) => x.blockIds.includes(i)));
        if (blockApps) {
            blockApps.applications.push(b);
        } else {
            a.push({
                blockIds: b.blockIds,
                blockNames,
                applications: [b],
            });
        }
        return a;
    }, []);

    return irrigationApplications
        .sort((a, b) => (b.blockNames < a.blockNames ? 1 : -1))
        .map((irrigatorApplication, index) => {
            const _blockIcon = utils.getBlockIcon(irrigatorApplication.type);

            let selectedBlocks = irrigatorApplication.blockIds.map((id) => {
                return blocks.find((b) => b.id === id);
            });
            let rotationYear = selectedBlocks.length > 0 ? cropUtils.rotationYear(selectedBlocks[0], true) : farmYear;
            const cropBlock = selectedBlocks.length > 0 ? selectedBlocks[0] : undefined;
            return (
                <div key={index + 1} className="FarmTable_wrapper">
                    <ul className="DataWidthTable FarmTable">
                        <li className="FarmTable-title">
                            <div className="FarmTitle">
                                <img className="FarmTitle-icon" src={_blockIcon} alt="Block Type" />
                                <div className="FarmTitle-heading u-white-space-normal">
                                    <span className="FarmTitle-name" title={irrigatorApplication.blockNames}>
                                        {irrigatorApplication.blockNames}
                                    </span>
                                    <div className="FarmTitle-sub"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="FarmTable-supplementary">
                        <div className="Table u-tbl-lg">
                            <table className="Calendar u-tbl-fix">
                                {cropUtils.cropTableHeader(analysis, rotationYear, cropBlock, refData, false, 3, 1)}
                                {irrigatorApplication.applications.map((application, appIndex) => {
                                    const monthGroupVal = validation[iu.monthGroupId(application.id)] || {};
                                    let management = undefined;
                                    const isFrost = application.schedule === iu.Schedule.Frost;
                                    const rowErrors = monthGroupVal.touched && monthGroupVal.error;

                                    if (isFrost) {
                                        selectedBlocks = selectedBlocks.filter((b) => b.type === "ProductiveFruit");
                                    }

                                    switch (application.schedule) {
                                        case iu.Schedule.Frost:
                                            if (application.averageFrostTempC) {
                                                management = (
                                                    <div>
                                                        Frost: Avg frost temp: {application.averageFrostTempC} <small>&deg;C</small>
                                                    </div>
                                                );
                                            } else {
                                                management = <div>Frost</div>;
                                            }
                                            break;
                                        case iu.Schedule.Depth:
                                            management = <div>Entered depth: {application.depthAmountMm}mm</div>;
                                            break;
                                        default: {
                                            const schedule = domain.irrigationMoistureAssesmentTypes.find((m) => m.value === application.schedule);
                                            const depth = application.depth ? application.depth + "mm" : "";
                                            const period = application.returnPeriod ? " | " + application.returnPeriod + " days" : "";
                                            if (schedule) {
                                                management = <div>{schedule.name + ": " + depth + period}</div>;
                                            } else {
                                                management = <div>Irrigation</div>;
                                            }
                                            break;
                                        }
                                    }

                                    return (
                                        <tbody key={appIndex}>
                                            {!irrigator.isLastPreviousLime && (
                                                <tr name={`row_${rowIndex++}`} id={application.id} className={`${rowErrors ? "In-error" : ""}`}>
                                                    <td colSpan="3">
                                                        <ActionLink className="FarmTable-value-link" onClick={() => editApplication(application.id)} id={`${application.id}_application_edit`} title="Edit">
                                                            <span>
                                                                <small>{management}</small>
                                                            </span>
                                                            <span className="icon icon-edit"></span>
                                                        </ActionLink>
                                                    </td>
                                                    {rotationYear.map((month, rIndx) => {
                                                        const reportingYear = cropUtils.indexToReportingYear(rIndx, rotationYear);
                                                        const checked = application.months.some((appMonth) => appMonth.month === month && appMonth.reportingYear === reportingYear);
                                                        return (
                                                            <td key={`${month}-${rIndx}`} style={{ textAlign: "center" }}>
                                                                <ApplicationMonth month={month} application={application} onChange={(e, t) => onChange(e, t)} key={rIndx} checked={checked} reportingYear={reportingYear} />
                                                            </td>
                                                        );
                                                    })}
                                                    <td>
                                                        <ActionLink className="IconLink--trash" style={{ width: "16px" }} onClick={() => deleteApplication(application.id)} id={`${application.id}_application_remove`} title="Delete"></ActionLink>
                                                    </td>
                                                </tr>
                                            )}
                                            {false && rowErrors && (
                                                <tr className="In-error">
                                                    <td colSpan={16}>
                                                        <small>{monthGroupVal.message}</small>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    );
                                })}
                            </table>
                        </div>
                    </div>
                </div>
            );
        });
}

function ApplicationMonth({ label, month, checked, name, application, onChange, reportingYear }) {
    return (
        <label className="Checkbox" htmlFor={`${application.id}_${month}_${reportingYear}`}>
            <input className="Checkbox-input" type="checkbox" checked={checked} id={`${application.id}_${month}_${reportingYear}`} name={`${application.id}_${month}_${reportingYear}`} onChange={(e) => onChange({ type: "application", key: "appplication-month", applicationId: application.id, month: month, reportingYear: reportingYear, value: month, id: `${application.id}_${month}_${reportingYear}` }, e)} />
            <span className="Checkbox-label">{label}</span>
        </label>
    )
}
