import "components/ResultsWidgets/ResultsWidget.css";
import * as utils from "common/utils";
import { useAnalysisResults } from "containers/BudgetHome";
import { getCategorySums, sumNutrientCategory } from "./_utils";

export default function NutrientBudgetMovementTotals({ before, after, nutrient }) {
    const { data: beforeResults } = useAnalysisResults(before?.details);
    const { data: afterResults } = useAnalysisResults(after?.details);
    if (!beforeResults?.nutrientBudget && !afterResults?.nutrientBudget) {
        return null;
    }

    const afterNutrientBudget = afterResults?.nutrientBudget;
    const beforeNutrientBudget = beforeResults?.nutrientBudget;

    const afterSums = getCategorySums(afterNutrientBudget);
    const beforeSums = getCategorySums(beforeNutrientBudget);

    const TotalColourPanel = ({ total, beforeTotal, name, dp }) => {
        const diff = total - beforeTotal || 0;
        const increased = diff > 0;
        const decreased = diff < 0;
        const diffPercentage = total !== 0 && total !== "0" ? (100 * diff) / parseFloat(total) : 100;
        const diffPerFormatted = beforeTotal === 0 && total > 0 ? "+100%" : diffPercentage > 0 ? `+${utils.numeric.format(diffPercentage)}%` : `${utils.numeric.format(diffPercentage)}%`;
        const change = increased ? `+${utils.numeric.format(diff, dp)}` : decreased ? utils.numeric.format(diff, dp) : "-";

        const changeTextClassName = decreased ? "u-textError u-textBold" : "u-textSuccess u-textBold";
        const changeArrowClassName = increased ? "icon-arrow-up1 IconLink--compressed" : decreased ? "icon-arrow-down1 IconLink--compressed" : "";

        return (
            <div className={`NutrientPanelSmall ColourPanel--${nutrient.display.toLowerCase()}loss`}>
                <div className="u-pl-md">
                    <h5>{name}</h5>
                    <div>
                        <div className="HeroValue-item-number">
                            {utils.numeric.format(total, dp)} <small>kg/ha</small>
                        </div>
                        {diff !== 0 && (
                            <div className="u-flex u-flexJustifyEnd u-flexAlignItemsCenter">
                                <div className="u-text-sm">({diffPerFormatted})</div>
                                <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter u-textBold u-textSecondary u-ml-xs">
                                    <span>{change}</span>
                                    <span className={`${changeArrowClassName} ${changeTextClassName}`}></span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const isPhosphorus = nutrient?.value === "phosphorus";
    const showLossToAir = !isPhosphorus && (afterSums?.lossToAir?.length || beforeSums?.lossToAir?.length);
    const dp = isPhosphorus ? 1 : 0;

    return (
        <>
            <h1>{nutrient.text}</h1>
            <p className="u-pb-md">{`Nutrients are brought onto the farm and taken up by plants that are eaten by animals. Animals move around the farm and deposit nutrients in the form of urine and dung. Nutrients are removed in the form of products (meat, crops and milk). The difference between the nutrients added and products removed is the ${nutrient.value} surplus. Remaining nutrients undergo various biological processes, are lost to the atmosphere and when drainage occurs may leach or runoff from the farm.`}</p>
            <div className="u-flex u-mt-0 u-flex-wrap">
                <TotalColourPanel total={sumNutrientCategory(afterSums.addedItems, nutrient)} beforeTotal={sumNutrientCategory(beforeSums.addedItems, nutrient)} name="Additions" dp={dp} />
                <div className="Arithmetic-symbols">-</div>
                <TotalColourPanel total={sumNutrientCategory(afterSums.productItems, nutrient)} beforeTotal={sumNutrientCategory(beforeSums.productItems, nutrient)} name="Products" dp={dp} />
                <div className="Arithmetic-symbols">=</div>
                <TotalColourPanel total={sumNutrientCategory(afterSums.biological, nutrient)} beforeTotal={sumNutrientCategory(beforeSums.biological, nutrient)} name="Transfers" dp={dp} />
                {showLossToAir && (
                    <>
                        <div className="Arithmetic-symbols">+</div>
                        <TotalColourPanel total={sumNutrientCategory(afterSums.lossToAir, nutrient)} beforeTotal={sumNutrientCategory(beforeSums.lossToAir, nutrient)} name="Loss to air" dp={dp} />
                    </>
                )}
                <div className="Arithmetic-symbols">+</div>
                <TotalColourPanel total={sumNutrientCategory(afterSums.otherLosses, nutrient)} beforeTotal={sumNutrientCategory(beforeSums.otherLosses, nutrient)} name="Other losses" dp={dp} />
            </div>
            <div className="u-ml-sm">
                <sub style={{ lineHeight: "1rem" }}>{`1 - ${nutrient.text} surplus is total additions minus product removed (${sumNutrientCategory(afterSums.addedItems, nutrient) - sumNutrientCategory(afterSums.productItems, nutrient)} kg/ha)`}</sub>
            </div>
            <div className="u-ml-sm">
                <sub style={{ lineHeight: "1rem" }}>{`2 - The numbers in the nutrient budget have been rounded and so may not balance exactly`}</sub>
            </div>
        </>
    )
}
