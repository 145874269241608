import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { GridCell } from "components/Grid";
import * as utils from "common/utils";
import { fertiliserViewModel, cropViewModel } from "common/viewModels";
import ActionLink from "components/ActionLink";
import { getAnalysisOptions } from "containers/FarmHome/_utils";
import { irrigatorViewModel } from "common/viewModels";
import SelectField from "components/FormFields/SelectField";
import Spinner from "components/Spinner/Spinner";
import Button from "components/Button/Button";
import FarmMap from "components/FarmMap/FarmMap";
import { useOnline, useRefData, useAuthContext } from "common/hooks";
import { useNotificationModal } from "../Modals/NotificationModal";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";
import { useAnalysis, useFarmAccess } from "containers/hooks";

export default function LatestAnalysis({ farm, selectedAnalysisId, onSelectedAnalysisChange, className, height }) {
    const online = useOnline();
    const refData = useRefData();
    const authContext = useAuthContext();
    const { data: farmAccess } = useFarmAccess();

    const { isLoading, data: selectedAnalysis } = useAnalysis(farm.id, selectedAnalysisId);

    const [tab, setTab] = useState("details");
    const loading = isLoading || !selectedAnalysis.blocks;
    const analysis = !loading && selectedAnalysis;
    const owners = farmAccess?.access?.filter((a) => a.role === "Owner") || [];
    const featureTracker = useFeatureTracker(FEATURES.ANALYSIS_LINKS_LATEST, undefined, farm);
    const [notificationModal, openNotificationModal] = useNotificationModal(farm, selectedAnalysis);

    const pendingInvitations = farmAccess?.accessInvitations || [];
    const hasOwners = owners.length > 0 || pendingInvitations.some((i) => i.role === "Owner");
    const hasSendSummaryAccess = utils.canUpdateFarm(farm) || authContext.isSystemAdmin;
    const results = analysis && analysis.currentResults;
    const fertiliser = analysis && analysis.blocks && fertiliserViewModel(analysis, refData);
    const crops = analysis && analysis.blocks && cropViewModel(analysis, refData);
    const farmArea = analysis && analysis.blocks && getFarmArea(analysis);
    const enterprises =
        results &&
        analysis.blocks &&
        Object.keys(results.animalResults).map((k) => {
            const enterprise = analysis.enterprises.find((e) => e.id === k);
            const label = utils.valueToText(refData.enterpriseTypes, enterprise.type);
            return { label: label, total: results.animalResults[k].total, perHa: results.animalResults[k].totalPerHa };
        });
    const effluent = results && results.effluentResults;
    const irrVm = !loading && selectedAnalysis && irrigatorViewModel(selectedAnalysis, refData);
    const irrTotalArea = irrVm && irrVm.reduce((t, i) => (t += i.area), 0);

    const TabContent = () => (
        <div className="u-pt-md">
            {tab === "details" && (
                <div className="SimpleTable">
                    <table>
                        <tbody>
                            <tr valign="top">
                                <td className="u-font-600">Name</td>
                                <td className="u-textRight">{farm.name}</td>
                            </tr>
                            <tr valign="top">
                                <td className="u-font-600">Address</td>
                                <td className="u-textRight">{farm.address}</td>
                            </tr>
                            <tr valign="top">
                                <td className="u-font-600">Region</td>
                                <td className="u-textRight">{utils.valueToText(refData.regions, farm.region)}</td>
                            </tr>
                            <tr valign="top">
                                <td className="u-font-600">Default climate</td>
                                <td className="u-textRight">{farm.defaultClimate ? `${farm.defaultClimate.averageTemp} ˚ C,  ${farm.defaultClimate.averageRain} mm/yr` : "Farm not located"}</td>
                            </tr>
                            {owners.map((o, i) => (
                                <tr key={`owners-${i}`} valign="top">
                                    <td className="u-font-600">{i === 0 ? `Owner${owners.length > 1 ? "s" : ""}` : ""}</td>
                                    <td className="u-textRight">{o.accountName}</td>
                                </tr>
                            ))}
                            <tr valign="top">
                                <td className="u-font-600">Productive area</td>
                                <td className="u-textRight">{utils.round(farmArea.productiveArea, 1).toLocaleString()} ha</td>
                            </tr>
                            <tr valign="top">
                                <td className="u-font-600">Irrigated area</td>
                                <td className="u-textRight">{utils.round(irrTotalArea, 1).toLocaleString()} ha</td>
                            </tr>
                            {selectedAnalysis.totalFarmArea > 0 && (
                                <tr valign="top">
                                    <td className="u-font-600">Total area</td>
                                    <td className="u-textRight">{utils.round(selectedAnalysis.totalFarmArea, 1).toLocaleString()} ha</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}

            {tab === "enterprises" && (
                <>
                    {enterprises && enterprises.length > 0 ? (
                        <div className="SimpleTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th> </th>
                                        <th className="u-textRight">Total RSU</th>
                                        <th className="u-textRight">RSU per ha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {enterprises.map((e) => (
                                        <tr key={`enterprises-${e.label}`} valign="top">
                                            <td className="u-font-600">{e.label}</td>
                                            <td className="u-textRight">{utils.round(e.total, 0).toLocaleString()} RSU</td>
                                            <td className="u-textRight">{utils.round(e.perHa, 1).toLocaleString()} RSU/ha</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="u-pl-lg">This analysis does not contain enterprises</div>
                    )}
                </>
            )}

            {tab === "crops" && (
                <>
                    {Object.keys(crops).length > 0 ? (
                        <div className="SimpleTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className="u-textRight">Total yield</th>
                                        <th className="u-textRight">Yield per ha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(crops).map((key) => {
                                        const e = crops[key];
                                        if ((e.yield && e.yield === 0) || (!e.yield && (!e.grown || e.grown === 0))) return null;
                                        return e.yield ? (
                                            <tr key={`crops-${key}`} valign="top">
                                                <td className="u-font-600">{e.name}</td>
                                                <td className="u-textRight">
                                                    {utils.round(e.yield, 1).toLocaleString()} {e.units}
                                                </td>
                                                <td className="u-textRight">
                                                    {utils.round(e.yield / e.area, 1).toLocaleString()} {e.units}/ha
                                                </td>
                                            </tr>
                                        ) : (
                                            <Fragment key={`crops-${key}`}>
                                                <tr valign="top">
                                                    <td className="u-font-600">{e.name} grown</td>
                                                    <td className="u-textRight">{utils.round(e.grown, 1).toLocaleString()} T DM</td>
                                                    <td className="u-textRight">{utils.round(e.grown / e.area, 1).toLocaleString()} T DM/ha</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td className="u-font-600">{e.name} intake</td>
                                                    <td className="u-textRight">{utils.round(e.intake, 1).toLocaleString()} T DM</td>
                                                    <td className="u-textRight">{utils.round(e.intake / e.area, 1).toLocaleString()} T DM/ha</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td className="u-font-600">{e.name} supplements</td>
                                                    <td className="u-textRight">{utils.round(e.supplements, 1).toLocaleString()} T DM</td>
                                                    <td className="u-textRight">{utils.round(e.supplements / e.area, 1).toLocaleString()} T DM/ha</td>
                                                </tr>
                                            </Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="u-pl-lg">This analysis does not contain crops</div>
                    )}
                </>
            )}

            {tab === "nutrients" && (
                <>
                    {(effluent && effluent.averageOtherN && effluent.averageOtherN > 0) || (effluent && effluent.averageEffluentApplied && effluent.averageEffluentApplied > 0) || (fertiliser && fertiliser.nutrients && fertiliser.nutrients.N > 0) || (fertiliser && fertiliser.nutrients && fertiliser.nutrients.P > 0) ? (
                        <>
                            <div className="u-pb-md">N and P applied to farm via fertiliser and/or effluent</div>
                            <div className="SimpleTable">
                                <table>
                                    <tbody>
                                        {effluent && effluent.averageOtherN > 0 && (
                                            <>
                                                <tr valign="top">
                                                    <td className="u-font-600">N solid effluent</td>
                                                    <td className="u-textRight">{utils.round(effluent.averageOtherN, 1).toLocaleString()} kg/ha</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td className="u-font-600">N liquid effluent</td>
                                                    <td className="u-textRight">{utils.round(effluent.averageEffluentApplied, 1).toLocaleString()} kg/ha</td>
                                                </tr>
                                            </>
                                        )}
                                        {fertiliser && fertiliser.nutrients && (
                                            <>
                                                <tr valign="top">
                                                    <td className="u-font-600">N fertiliser</td>
                                                    <td className="u-textRight">{utils.round(fertiliser.nutrients.N, 1).toLocaleString()} kg</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td className="u-font-600"></td>
                                                    <td className="u-textRight">{utils.round(fertiliser.nutrients.N / farmArea.productiveArea, 1).toLocaleString()} kg/ha</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td className="u-font-600">P fertiliser</td>
                                                    <td className="u-textRight">{utils.round(fertiliser.nutrients.P, 1).toLocaleString()} kg</td>
                                                </tr>
                                                <tr valign="top">
                                                    <td className="u-font-600"></td>
                                                    <td className="u-textRight">{utils.round(fertiliser.nutrients.P / farmArea.productiveArea, 1).toLocaleString()} kg/ha</td>
                                                </tr>
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <div className="u-pl-lg">This analysis has no nutrients applied</div>
                    )}
                </>
            )}

            {tab === "blocks" && <>{analysis && analysis.blocks && analysis.blocks.length > 0 ? BlockTable(analysis, refData) : <div className="u-pl-lg">This analysis does not contain blocks</div>}</>}
        </div>
    );

    const analysisOptions = getAnalysisOptions(farm, [], true);

    const linkUrl = `/app/farm/${farm.id}/analysis/${selectedAnalysis?.id}${selectedAnalysis?.class === "PublishedAnalysis" ? "/overview" : ""}`;

    return (
        <>
            <GridCell className={`${className} FarmDashboard-cell`}>
                <div className="Widget" style={{ height, minWidth: "800px" }}>
                    <div className="Widget-header">
                        <div className="Widget-header-select">
                            <SelectField
                                label={
                                    <Link to={linkUrl} onClick={() => featureTracker.track("Latest analysis - Clicked")}>
                                        <span className="Widget-title IconLink--compare" id="link_latest_analysis">
                                            <span>Latest analysis</span>
                                        </span>
                                    </Link>
                                }
                                input={{ name: "selectedAnalysis", value: selectedAnalysisId, onChange: onSelectedAnalysisChange }}
                                options={analysisOptions}
                            />
                        </div>
                        <div className="u-flexSplit u-ml-auto">
                            <div className="h5 u-link u-ml-lg" style={{ fontSize: "1rem" }}>
                                <Link to={linkUrl} className="IconText" onClick={() => featureTracker.track("View analysis - Clicked")}>
                                    View analysis
                                    <i className="icon icon-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="Widget-content">
                        {loading && (
                            <div className="Tile-body-message u-mt-lg u-mb-lg waiting">
                                <Spinner dark />
                            </div>
                        )}
                        {!loading && (
                            <div className="u-flex">
                                <div className="u-pr-lg">
                                    <LossResults results={results} />
                                    {results && hasOwners && hasSendSummaryAccess && online && (
                                        <div className="u-flex">
                                            <Button cid="cancel-button" onClick={() => openNotificationModal("Farm")} className="Button Button--secondary u-mr-sm" type="button">
                                                Email farm summary
                                            </Button>
                                            <Button cid="cancel-button" onClick={() => openNotificationModal("Ghg")} className="Button Button--secondary" type="button">
                                                Email GHG summary
                                            </Button>
                                        </div>
                                    )}
                                    <ul className="SubMenu u-mt-md" style={{ borderBottom: "none", paddingLeft: "none", marginBottom: "10px" }}>
                                        <li className={`SubMenu-item ${tab === "details" ? "is-active" : ""}`}>
                                            <ActionLink onClick={() => setTab("details")}>
                                                <span>Details</span>
                                            </ActionLink>
                                        </li>
                                        <li className={`SubMenu-item ${tab === "blocks" ? "is-active" : ""}`}>
                                            <ActionLink onClick={() => setTab("blocks")} className="u-pr-md u-pl-md">
                                                <span>Blocks</span>
                                            </ActionLink>
                                        </li>
                                        <li className={`SubMenu-item ${tab === "enterprises" ? "is-active" : ""}`}>
                                            <ActionLink onClick={() => setTab("enterprises")} className="u-pr-md u-pl-md">
                                                <span>Animals</span>
                                            </ActionLink>
                                        </li>
                                        <li className={`SubMenu-item ${tab === "crops" ? "is-active" : ""}`}>
                                            <ActionLink onClick={() => setTab("crops")} className="u-pr-md u-pl-md">
                                                <span>Crops</span>
                                            </ActionLink>
                                        </li>
                                        <li className={`SubMenu-item ${tab === "nutrients" ? "is-active" : ""}`}>
                                            <ActionLink onClick={() => setTab("nutrients")} className="u-pr-md u-pl-0">
                                                <span>Nutrients</span>
                                            </ActionLink>
                                        </li>
                                    </ul>
                                    <TabContent />
                                </div>
                                <FarmMap farm={farm} analysis={selectedAnalysis} showUnassignedLand />
                            </div>
                        )}
                    </div>
                </div>
            </GridCell>
            {notificationModal}
        </>
    );
}

const getFarmArea = (budget) => {
    const productiveBlocks = budget.blocks.filter((b) => b.isProductive && b.type !== "FodderCrop");
    const productiveArea = productiveBlocks.reduce((total, block) => (total += isNaN(block.areaInHectares) ? 0 : block.areaInHectares), 0);
    const { totalFarmArea = 1 } = budget;
    return { productiveArea, totalFarmArea };
};

const BlockTable = (budget, refData) => {
    const { blocks = [] } = budget;
    const totalBlockArea = blocks.reduce((total, block) => (total += block.totalArea), 0);
    const nonBlockedArea = utils.round((budget.totalFarmArea || totalBlockArea) - totalBlockArea, 0);

    const allBlocks = blocks.reduce((blockTypes, block) => {
        const blockType = blockTypes.find((b) => b.type === block.type) || {
            type: block.type,
            blockCount: 0,
            totalArea: 0,
            name: block.type === "ProductivePasture" && (!block.animals || block.animals.length === 0) ? "Cut and carry" : utils.valueToText(refData.blockTypes, block.type),
        };
        blockType.totalArea += block.type === "FodderCrop" ? block.rotationArea : block.areaInHectares;
        blockType.blockCount += 1;
        return [...blockTypes.filter((b) => b.type !== block.type), blockType];
    }, []);

    return (
        <>
            <div className="SimpleTable">
                <table>
                    <thead>
                        <tr>
                            <th>Block type</th>
                            <th className="u-textRight">Blocks</th>
                            <th className="u-textRight">Area</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allBlocks.map((block, i) => {
                            const { blockCount = 0, totalArea = 0 } = block;
                            return (
                                <tr key={i}>
                                    <td>{block.name}</td>
                                    <td className="u-textRight" id={`${block.type}-blocks`}>
                                        {blockCount.toLocaleString()}
                                    </td>
                                    <td className="u-textRight" id={`${block.type}-area`}>
                                        {utils.round(totalArea, 0).toLocaleString()} <small>ha</small>
                                    </td>
                                </tr>
                            );
                        })}
                        {budget.totalFarmArea > totalBlockArea && (
                            <tr className="Table-tr--NonBlock">
                                <td>Unblocked area</td>
                                <td></td>
                                <td className="u-textRight">
                                    {nonBlockedArea.toLocaleString()} <small>ha</small>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

const ColourPanel = ({ className, title, values }) => {
    return (
        <div className={`ColourPanelSmall ${className}`}>
            <div className="u-pl-md">
                <h5>{title}</h5>
                <div>
                    <div className="HeroValue-item-number">
                        {values[1].value.toLocaleString()} <small>{values[1].units}</small>
                    </div>
                    <div className="">
                        {values[0].value.toLocaleString()} <small>{values[0].units}</small>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LossResults = ({ results }) => {
    const nitrogen = results && results.nitrogenOverview;
    const phosphorus = results && results.phosphorusOverview;
    const ghg = results && results.ghgResults;

    return (
        <div className="u-flex u-mt-0 u-flex-wrap">
            <ColourPanel
                className="ColourPanel--nloss"
                title="N loss"
                values={[
                    { value: nitrogen ? utils.round(nitrogen.farmTotalLoss, 1) : "-", units: "kg" },
                    { value: nitrogen ? utils.round(nitrogen.farmLossPerHa, 1) : "-", units: "kg/ha" },
                ]}
            />
            <ColourPanel
                className="ColourPanel--ploss"
                title="P loss"
                values={[
                    { value: phosphorus ? utils.round(phosphorus.farmTotalLoss, 1) : "-", units: "kg" },
                    { value: phosphorus ? utils.round(phosphorus.farmLossPerHa, 1) : "-", units: "kg/ha" },
                ]}
            />
            <ColourPanel
                className="ColourPanel--ghg"
                title="GHG"
                values={[
                    { value: ghg && ghg.total >= 0 && ghg.reportingArea > 0 ? utils.round((ghg.total * ghg.reportingArea) / 1000, 1) : "-", units: "tonnes" },
                    { value: ghg && ghg.total >= 0 ? utils.round(ghg.total, 1) : "-", units: "kg/ha" },
                ]}
            />
        </div>
    );
};
