import Alert from "components/Alert";
import { FileSummary, MonthlySummary } from "components/PlanningReport";
import FertiliserSummary from "./FertiliserSummary";
import FertiliserExtraSummary from "./FertiliserExtraSummary";
import { getFertiliserNutrientsApplied } from "./_utils";

export default function Fertiliser({ before, after, showFileComparison }) {
    const scenarios = [after];
    if (before) scenarios.unshift(before);

    const hasAfterResults = after?.details?.nLossPerHa > 0 || after?.details?.currentResults?.nitrogenOverview?.lostToWater > 0;
    const hasBeforeResults = before?.details?.nLossPerHa > 0 || before?.details?.currentResults?.nitrogenOverview?.lostToWater > 0;
    const hasResults = hasAfterResults || hasBeforeResults;

    if (!hasResults) {
        return (
            <div className="u-pt-lg">
                <Alert type="info" text="There are no fertiliser results for this analysis" />
            </div>
        );
    }

    const farmNutrientTotals = getFertiliserNutrientsApplied(after.details);
    const baseNutrientTotals = before && getFertiliserNutrientsApplied(before.details);
    const farmNutrientTotalsAreaSum = farmNutrientTotals.reduce((t, n) => t + n.area, 0);
    const baseFarmNutrientTotalsAreaSum = before ? baseNutrientTotals.reduce((t, n) => t + n.area, 0) : 0;

    if (farmNutrientTotalsAreaSum + baseFarmNutrientTotalsAreaSum === 0) {
        return (
            <div className="u-pt-lg">
                <Alert type="info" text="There is no fertiliser information for this analysis" />
            </div>
        );
    }

    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <FertiliserSummary before={before} after={after} farmNutrientTotals={farmNutrientTotals} baseNutrientTotals={baseNutrientTotals} />
            {false && <FertiliserExtraSummary before={before} after={after} farmNutrientTotals={farmNutrientTotals} baseNutrientTotals={baseNutrientTotals} />}
            <div className="ReportSection u-page-break-avoid">
                <h1>Fertiliser nutrients by month</h1>
                <MonthlySummary scenarios={scenarios} categoryName="Fertiliser" rowName="N" />
                <MonthlySummary scenarios={scenarios} categoryName="Fertiliser" rowName="P" />
                <MonthlySummary scenarios={scenarios} categoryName="Fertiliser" rowName="K" />
                <MonthlySummary scenarios={scenarios} categoryName="Fertiliser" rowName="S" />
            </div>
        </div>
    )
}
