import { useDispatch, useSelector } from "react-redux";
import { findAccounts } from "../_actions";

export const useRefreshOrgSearchResultsIfAny = () => {
    const { searchFor, searchText } = useSelector(state => state.admin);
    const dispatch = useDispatch();

    return () => {
        const validSearchFors = ["Orgs", "Users", "UserInvitations"];
        if (validSearchFors.includes(searchFor)) {
            dispatch(findAccounts(searchFor, searchText));
        }
    }
}
