import React from "react";
import * as utils from "common/utils";
import * as _utils from "../_utils";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";

class LivestockEventList extends React.Component {
    constructor(props) {
        super(props);
        const state = this._initState(props);
        this.state = { ...state };
    }

    componentWillReceiveProps(props) {
        const state = this._initState(props);
        this.setState({ ...state });
    }

    _initState = (props) => {
        const events = [];

        props.livestocks.forEach((livestock, i) => {
            const colour = utils.getColour(i);
            const livestockDetails = _utils.getLivestockDetails(livestock, props.refData);

            const { events: livestockEvents = [] } = livestock;
            livestockEvents.forEach((event, j) => {
                const eventType = utils.valueToText(props.refData.animalEventTypes, event.eventType);
                const eventDate = utils.parseDate(event.eventDate, "DD MMMM");
                events.push({
                    id: `${i}-${j}`,
                    colour: colour,
                    name: livestock.name,
                    class: livestockDetails.class,
                    breed: livestockDetails.breed,
                    type: eventType,
                    sortDate: eventDate.format(),
                    date: eventDate.format("DD MMMM"),
                    numberAnimals: event.numberAnimals,
                });
            });
        });

        return { events };
    };

    _sort = (sortBy) => (e) => {
        const sorted = this.state.sortBy === sortBy;
        this.setState({
            events: this.state.events
                .map((e) => e)
                .sort((a, b) => {
                    const comparisonResult = a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0;
                    return sorted ? -comparisonResult : comparisonResult;
                }),
            sortBy: sorted ? undefined : sortBy,
        });
    };

    render() {
        return (
            <div className="Grid-cell">
                <h3 className="u-mt-md">Livestock events</h3>
                <SortableTable data={this.state.events} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }}>
                    {({ data, sortCriteria, handleSort }) => {
                        return (
                            <table>
                                <thead>
                                    <tr>
                                        <SortableTableHeader label="Livestock name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Class" fieldName="class" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Breed" fieldName="breed" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Event type" fieldName="type" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Date" fieldName="sortDate" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Number of animals" fieldName="numberAnimals" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((event) => {
                                        return (
                                            <tr key={event.id} className={`Table-tr--${event.colour}`}>
                                                <td>
                                                    <b>{event.name}</b>
                                                </td>
                                                <td>{event.class}</td>
                                                <td>{event.breed}</td>
                                                <td>{event.type}</td>
                                                <td>{event.date}</td>
                                                <td>{event.numberAnimals}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        );
                    }}
                </SortableTable>
            </div>
        );
    }
}

export default LivestockEventList;
