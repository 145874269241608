import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import CropList from "./CropList";
import Fruit from "./Fruit/Fruit";
import Pasture from "./Pasture/Pasture";
import CropDetails from "./Block/Details";
import RotationDetails from "./Rotation/Details";

export default function CropsTab({ farm, analysis }) {
    return (
        <Switch>
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/crops" render={() => <CropList farm={farm} analysis={analysis} />} restoreScrollY />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/crops/pasture/:blockId" render={() => <Pasture farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/crops/crop/:blockId" render={() => <CropDetails farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/crops/fruit/:blockId" render={() => <Fruit farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/crops/rotation/new" render={() => <RotationDetails farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/crops/rotation/:blockId" render={() => <RotationDetails farm={farm} analysis={analysis} />} />
        </Switch>
    )
}
