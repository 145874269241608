import * as utils from "common/utils";
import ActionLink from "components/ActionLink";
import { useFarmAccessRequestResponseModal } from "./Modals/FarmAccessRequestResponseModal";
import { Panel, PanelBody } from "components/Panel";
import { useAuthContext, useConfirm } from "common/hooks";
import { useResendFarmAccessRequestAsync } from "containers/hooks";

export default function MyFarmAccessRequestApprovals({ myFarmAccessRequestApprovals, hideFarmDetails }) {
    const isSystemAdmin = useAuthContext()?.isSystemAdmin;
    const confirm = useConfirm();
    const [farmAccessRequestResponseModal, openFarmAccessRequestResponseModal] = useFarmAccessRequestResponseModal();
    const resendFarmAccessRequestAsync = useResendFarmAccessRequestAsync();

    const respond = (accessRequest) => (e) => {
        utils.stopPropagation(e);
        openFarmAccessRequestResponseModal(accessRequest);
    };

    const _resend = (accessRequest) => (e) => {
        utils.stopPropagation(e);
        confirm(`Are you sure you want to resend your request to access,'${accessRequest.farmName}'?`, async () => {
            await resendFarmAccessRequestAsync(accessRequest);
        });
    }

    return (
        <>
            <Panel title="Farm access requests requiring my approval" skyBlue stickyHeaderName="MyFarmAccessRequestApprovals">
                <PanelBody>
                    <div className="Table">
                        <table>
                            <thead>
                                {hideFarmDetails ? (
                                    <tr>
                                        <th>Requested access</th>
                                        <th>Requesting org</th>
                                        <th>Requesting user</th>
                                        <th>Requested on</th>
                                        <th className="th--shrink">&nbsp;</th>
                                    </tr>
                                ) : (
                                    <tr>
                                        <th data-width="20">Farm name</th>
                                        <th data-width="20">Address</th>
                                        <th className="th-shrink">Requested access</th>
                                        <th data-width="10">Requesting org</th>
                                        <th data-width="10">Requesting user</th>
                                        <th data-width="10">Requested on</th>
                                        <th className="th--shrink">&nbsp;</th>
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                                {myFarmAccessRequestApprovals.map((accessRequest) => {
                                    const { owners = [] } = accessRequest;
                                    return (
                                        <tr key={accessRequest.id} id={`access-request-${accessRequest.id}`} className="hover a u-link u-textMedium" onClick={respond(accessRequest)}>
                                            {!hideFarmDetails && <td>{accessRequest.farmName}</td>}
                                            {isSystemAdmin && !hideFarmDetails && (
                                                <td>
                                                    {owners.map((own, i) => (
                                                        <div key={i}>{own.ownerName}</div>
                                                    ))}
                                                </td>
                                            )}
                                            {!hideFarmDetails && <td>{accessRequest.farmAddress}</td>}
                                            <td>{accessRequest.role}</td>
                                            <td>{accessRequest.requestedBy.accountName}</td>
                                            <td>{accessRequest.requestedBy.userFullName}</td>
                                            <td>{utils.dateOnly(accessRequest.requestedOn)}</td>
                                            <td>
                                                <div className="u-flex">
                                                    {isSystemAdmin && !accessRequest.declined && !accessRequest.cancelled && (
                                                        <ActionLink id={`delete-access-request-${accessRequest.id}`} className="IconLink--shield u-mr-sm" onClick={_resend(accessRequest)}>
                                                            Resend
                                                        </ActionLink>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </PanelBody>
            </Panel>
            {farmAccessRequestResponseModal}
        </>
    )
}
