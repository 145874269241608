import * as api from "api";
import ReactGA from "react-ga4";
import appActionTypes from "containers/App/_actions";
import { getAccount } from "containers/Admin/_actions";
import { getAccount as adGetAccount } from "../AccountDetails/_actions";
import accountActionTypes from "../AccountDetails/_actions";

const actionTypes = {
    MY_ORG_GET_ORG: "MY_ORG_GET_ORG",
    MY_ORG_GET_ORG_SUCCESS: "MY_ORG_GET_ORG_SUCCESS",
    MY_ORG_GET_ORG_FAILURE: "MY_ORG_GET_ORG_FAILURE",

    MY_ORG_GET_PAYMENTS: "MY_ORG_GET_PAYMENTS",
    MY_ORG_GET_PAYMENTS_SUCCESS: "MY_ORG_GET_PAYMENTS_SUCCESS",
    MY_ORG_GET_PAYMENTS_FAILURE: "MY_ORG_GET_PAYMENTS_FAILURE",

    MY_ORG_GET_ACTIVITY: "MY_ORG_GET_ACTIVITY",
    MY_ORG_GET_ACTIVITY_SUCCESS: "MY_ORG_GET_ACTIVITY_SUCCESS",
    MY_ORG_GET_ACTIVITY_FAILURE: "MY_ORG_GET_ACTIVITY_FAILURE",

    MY_ORG_GET_FARMACCESS: "MY_ORG_GET_FARMACCESS",
    MY_ORG_GET_FARMACCESS_SUCCESS: "MY_ORG_GET_FARMACCESS_SUCCESS",
    MY_ORG_GET_FARMACCESS_FAILURE: "MY_ORG_GET_FARMACCESS_FAILURE",

    MY_ORG_GET_BALANCE: "MY_ORG_GET_BALANCE",
    MY_ORG_GET_BALANCE_SUCCESS: "MY_ORG_GET_BALANCE_SUCCESS",
    MY_ORG_GET_BALANCE_FAILURE: "MY_ORG_GET_BALANCE_FAILURE",

    MY_ORG_GET_ANALYSIS_SUMMARY: "MY_ORG_GET_ANALYSIS_SUMMARY",
    MY_ORG_GET_ANALYSIS_SUMMARY_CLEAR: "MY_ORG_GET_ANALYSIS_SUMMARY_CLEAR",
    MY_ORG_GET_ANALYSIS_SUMMARY_SUCCESS: "MY_ORG_GET_ANALYSIS_SUMMARY_SUCCESS",
    MY_ORG_GET_ANALYSIS_SUMMARY_FAILURE: "MY_ORG_GET_ANALYSIS_SUMMARY_FAILURE",

    MY_ORG_UPDATE_ORG_SUCCESS: "MY_ORG_UPDATE_ORG_SUCCESS",

    MY_ORG_GET_ALL_FARMS_SUCCESS: "MY_ORG_GET_ALL_FARMS_SUCCESS",
};
export default actionTypes;

export function getMyOrg() {
    ReactGA.event({
        category: "My Org",
        action: "Get My Org",
    });
    return api.get({
        path: `accounts`,
        onBeforeSend: () => {
            return { type: actionTypes.MY_ORG_GET_ORG };
        },
        onSuccess: (response) => {
            return { type: actionTypes.MY_ORG_GET_ORG_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.MY_ORG_GET_ORG_FAILURE };
        },
    });
}

export function clearBlockAccountBenchmarks() {
    return (dispatch) => {
        dispatch({ type: actionTypes.MY_ORG_GET_ANALYSIS_SUMMARY_CLEAR });
    };
}

export function getBlockAccountBenchmarks(year, source) {
    ReactGA.event({
        category: "Reporting",
        action: "Get Block Benchmarks",
    });
    return api.get({
        path: `reporting/blockAccountBenchmarks/${year}?documentSource=${source}`,
        onBeforeSend: () => {
            return { type: actionTypes.MY_ORG_GET_ANALYSIS_SUMMARY };
        },
        onSuccess: (response) => {
            return {
                type: actionTypes.MY_ORG_GET_ANALYSIS_SUMMARY_SUCCESS,
                payload: response,
            };
        },
        onFailure: (error) => {
            return { type: actionTypes.MY_ORG_GET_ANALYSIS_SUMMARY_FAILURE };
        },
    });
}

export function getAllFarms() {
    return api.get({
        path: `search/farms?searchText=&filterBy=MyOrg`,
        onSuccess: (response) => {
            return { type: actionTypes.MY_ORG_GET_ALL_FARMS_SUCCESS, payload: response };
        },
    });
}

export function getMyOrgBalance(accountId) {
    ReactGA.event({
        category: "My Org",
        action: "Get My Org Balance",
    });
    return api.get({
        path: `accounts/${accountId}/balance`,
        onBeforeSend: () => {
            return { type: actionTypes.MY_ORG_GET_BALANCE };
        },
        onSuccess: (response) => {
            return { type: actionTypes.MY_ORG_GET_BALANCE_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.MY_ORG_GET_BALANCE_FAILURE };
        },
    });
}

export function getMyOrgPaymentsByMonthYear(yearMonth, accountId) {
    ReactGA.event({
        category: "My Org",
        action: "Get My Org Payments",
    });
    return api.get({
        path: `accounts/${accountId}/payments/${yearMonth}`,
        onBeforeSend: () => {
            return { type: actionTypes.MY_ORG_GET_PAYMENTS };
        },
        onSuccess: (response) => {
            return { type: actionTypes.MY_ORG_GET_PAYMENTS_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.MY_ORG_GET_PAYMENTS_FAILURE };
        },
    });
}

export function getMyOrgRecentPayments(lastCount, accountId) {
    ReactGA.event({
        category: "My Org",
        action: "Get My Org Payments",
    });
    return api.get({
        path: `accounts/${accountId}/payments/last/${lastCount}`,
        onBeforeSend: () => {
            return { type: actionTypes.MY_ORG_GET_PAYMENTS };
        },
        onSuccess: (response) => {
            return { type: actionTypes.MY_ORG_GET_PAYMENTS_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.MY_ORG_GET_PAYMENTS_FAILURE };
        },
    });
}

export function getMyOrgRecentActivity(lastCount, accountId) {
    ReactGA.event({
        category: "My Org",
        action: "Get My Org activity",
    });
    return api.get({
        path: `accounts/${accountId}/activity/${lastCount}`,
        onBeforeSend: () => {
            return { type: actionTypes.MY_ORG_GET_ACTIVITY };
        },
        onSuccess: (response) => {
            return { type: actionTypes.MY_ORG_GET_ACTIVITY_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.MY_ORG_GET_ACTIVITY_FAILURE };
        },
    });
}

export function getMyOrgFarmAccess(accountId) {
    ReactGA.event({
        category: "My Org",
        action: "Get My Org Farm Access",
    });
    return api.get({
        path: `accounts/${accountId}/farmaccess`,
        onBeforeSend: () => {
            return { type: actionTypes.MY_ORG_GET_FARMACCESS };
        },
        onSuccess: (response) => {
            return { type: actionTypes.MY_ORG_GET_FARMACCESS_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.MY_ORG_GET_FARMACCESS_FAILURE };
        },
    });
}

export function updateMyOrg(account) {
    ReactGA.event({
        category: "My Org",
        action: "Update My Org",
    });
    return api.put({
        path: `accounts/${account.id}`,
        content: account,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: actionTypes.MY_ORG_UPDATE_ORG_SUCCESS, payload: account });
                dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
                if (account.type === "Student") dispatch({ type: accountActionTypes.ACCOUNT_UPDATE_STUDENT_SUCCESS, payload: account });
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                switch (error.status) {
                    case 403:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "You are not authorised to make this change. Please contact your organisation administrator." });
                        break;

                    default:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." });
                        break;
                }
            };
        },
    });
}

export function updateStudent(student) {
    ReactGA.event({
        category: "My Org",
        action: "Update student",
    });
    return api.put({
        path: `accounts/${student.id}/student`,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch(getMyOrg());
                dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
            };
        },
    });
}

export function inviteUsers(invitation, isSystemAdmin) {
    ReactGA.event({
        category: "My Org",
        action: "Invite User",
    });
    return api.post({
        path: `accounts/${invitation.accountId}/groupinvitations`,
        content: invitation,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch(getMyOrg());
                if (invitation.target === "Organisation") {
                    //Need to see if Students should be migrated to here
                    dispatch(adGetAccount());
                }
                if (isSystemAdmin) dispatch(getAccount(invitation.accountId));
                dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                switch (error.status) {
                    case 409:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "One of the users already exists." });
                        break;
                    case 403:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "You are not authorised to make this change. Please contact your organisation administrator." });
                        break;
                    case 400:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR_REPEATABLE, payload: "Error sending invite. Please ensure the email addresses are valid." });
                        break;
                    default:
                        dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." });
                        break;
                }
            };
        },
    });
}

export function deleteUserInvitation(invitation) {
    ReactGA.event({
        category: "My Org",
        action: "Delete User Invitation",
    });
    return api.del({
        path: `accounts/${invitation.accountId}/invitations/${invitation.id}`,
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch(getMyOrg());
                if (invitation.target === "Organisation") {
                    //Need to see if Students should be migrated to here
                    dispatch(adGetAccount());
                }
                dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return { type: appActionTypes.APP_MODAL_ERROR, payload: "An unexpected error has occured. Please try again or call support if the problem persists." };
        },
    });
}

export const getRenewals = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                path: `payments/renewals`,
                silentMode: true,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getNonRenewals = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                path: `payments/nonrenewals`,
                silentMode: true,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const deleteCard = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.del({
                path: `payments/savedcards/${id}`,
                silentMode: true,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const deleteAutoRenew = (farmIds) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.put({
                path: `payments/deleteAutoRenew`,
                content: { farmIds },
                silentMode: true,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const setCardForFarms = (farmIds, cardId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.put({
                path: `payments/setcardforfarms`,
                content: { farmIds, cardId },
                silentMode: true,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getAccess = (accountId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                path: `accounts/${accountId}/farmaccess`,
                silentMode: true,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};
