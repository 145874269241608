import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import { Panel, PanelBody } from "components/Panel";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import TabMessages, { MESSAGE_CODE } from "containers/BudgetHome/TabMessages";
import PublicationActionsBar from "../../Publications/PublicationActionsBar";
import ReportActions from "./ReportActions";
import PlanningReport from "../Reporting/PlanningReport";
import { FarmDetails } from "./FarmDetails";
import { BlocksSummary } from "./BlocksSummary";
import { BlockDetails } from "./BlockDetails";
import { CompareBlocks } from "./CompareBlocks";
import { AnimalReports } from "./AnimalReports";
import { Ghg } from "./Ghg";
import { Feasibility } from "./Feasibility";
import { AnalysisComments } from "./AnalysisComments";
import { LoadingPanel } from "components/LoadingPanel";
import { useIsStudentUser, useIsEducationProviderUser, useNavigate } from "common/hooks";
import { useAnalysisResults } from "containers/BudgetHome";
import RenewSubscriptionButton from "containers/Payments/RenewSubscriptionButton";

export default function OverviewTab({ farm, analysis, publication }) {
    const referrer = `/app/farm/${farm.id}/analysis`;
    const isPublication = analysis.class === "PublishedAnalysis" && publication;
    if (isPublication) {
        return (
            <Panel title="Published analysis" referrer={referrer}>
                <PanelBody>
                    <PublicationActionsBar farm={farm} publication={publication} />
                    <Reports farm={farm} analysis={analysis} publication={publication} />
                </PanelBody>
            </Panel>
        )
    } else {
        return (
            <Panel referrer={referrer}>
                <PageTabs farm={farm} analysis={analysis} tab={TAB.OVERVIEW} />
                <PanelBody>
                    <ReportActions farm={farm} analysis={analysis} />
                    <Reports farm={farm} analysis={analysis} publication={publication} />
                </PanelBody>
            </Panel>
        )
    }
}

function Reports({ farm, analysis, publication }) {
    const { isStale, paymentRequired } = useAnalysisResults(analysis);
    const isStudentUser = useIsStudentUser();
    const isEducationProviderUser = useIsEducationProviderUser();
    const navigate = useNavigate();

    const baseUrl = `/app/farm/${farm.id}/analysis/${analysis.id}/overview`;
    const tabs = {
        planning: { title: "Farm summary", path: `${baseUrl}`, exact: true },
        farmDetails: { title: "Farm details", path: `${baseUrl}/farmDetails`, exact: true },
        blocks: { title: "Blocks summary", path: `${baseUrl}/blocks`, exact: true },
        blockDetails: { title: "Block details", path: `${baseUrl}/blockDetails`, exact: true },
        compareBlocks: { title: "Compare blocks", path: `${baseUrl}/compareBlocks`, exact: true },
        animalReports: { title: "Animal reports", path: `${baseUrl}/animalReports`, exact: true },
        ghg: { title: "GHG", path: `${baseUrl}/ghg`, exact: true },
        feasibility: { title: "Feasibility", path: `${baseUrl}/feasibility`, exact: true },
        comments: { title: `Analysis comments (${(analysis.comments || []).length})`, path: `${baseUrl}/comments`, exact: true },
    };
    if (isStudentUser || isEducationProviderUser) {
        delete tabs.feasibility;
    }

    const reviewAnimalReports = () => {
        navigate(`/app/farm/${farm.id}/analysis/${analysis.id}/overview/animalReports`);
    }

    return (
        <LoadingPanel isLoading={isStale} message="Generating reports..." subMessage="This can take a while for analyses with many blocks">
            <Panel tabs={tabs}>
                <PanelBody>
                    <TabMessages farm={farm} analysis={analysis} publication={publication} tab={TAB.OVERVIEW} actions={{ [MESSAGE_CODE.ENGINE_ERROR]: reviewAnimalReports }} />
                    {paymentRequired
                        ? <>
                            <div className="Tile-body-message">
                                <RenewSubscriptionButton farm={farm} />
                            </div>
                        </>
                        : <>
                            <Switch>
                                <ScrollTopRoute exact path={tabs.planning.path} render={() => <PlanningReport farm={farm} analysis={analysis} />} />
                                <ScrollTopRoute exact path={tabs.farmDetails.path} render={() => <FarmDetails farm={farm} analysis={analysis} />} />
                                <ScrollTopRoute exact path={tabs.blocks.path} render={() => <BlocksSummary farm={farm} analysis={analysis} />} />
                                <ScrollTopRoute exact path={tabs.blockDetails.path} render={() => <BlockDetails farm={farm} analysis={analysis} />} />
                                <ScrollTopRoute exact path={tabs.compareBlocks.path} render={() => <CompareBlocks farm={farm} analysis={analysis} />} />
                                <ScrollTopRoute exact path={tabs.animalReports.path} render={() => <AnimalReports farm={farm} analysis={analysis} />} />
                                <ScrollTopRoute exact path={tabs.ghg.path} render={() => <Ghg farm={farm} analysis={analysis} />} />
                                {tabs.feasibility && <ScrollTopRoute exact path={tabs.feasibility.path} render={() => <Feasibility farm={farm} analysis={analysis} />} />}
                                <ScrollTopRoute exact path={tabs.comments.path} render={() => <AnalysisComments farm={farm} analysis={analysis} />} />
                            </Switch>
                        </>
                    }
                </PanelBody>
            </Panel>
        </LoadingPanel>
    )
}


