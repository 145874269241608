import authActionTypes from "./_actions";
import userActionTypes from "containers/AccountDetails/_actions";
import myOrgActionTypes from "containers/MyOrg/_actions";

const INITIAL_STATE = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("token") !== null,
    registrationError: null,
    loginError: null,
    userId: null,
    email: null,
    fullName: null,
    initials: null,
    activationFailed: false,
    isActivated: false,
    isSystemAdmin: false,
    creditLimit: 0,
    isAdmin: false,
    accountId: null,
    accountName: null,
    accountType: null,
    edToken: null,
    edLoginError: null,
    isEdLoading: false,
    referrer: "/",
    passwordResetRequested: false,
    userInvitationRequested: false,
    userInvitation: null,
    farmAccessInvitationRequested: false,
    farmAccessInvitation: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
    let { referrer } = state;
    switch (action.type) {
        // Register
        case authActionTypes.AUTH_REGISTER:
            if (!referrer && action.payload.locationState && action.payload.locationState.from) referrer = action.payload.locationState.from.pathname;
            if (referrer && referrer.startsWith("/pub/")) referrer = "/";

            return {
                ...state,
                isLoading: true,
                isAuthenticated: false,
                registrationError: null,
                referrer: referrer || "/",
            };
        case authActionTypes.AUTH_REGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                registrationError: action.payload,
            };
        case authActionTypes.AUTH_REGISTER_CANCEL:
            return {
                ...state,
                isLoading: false,
                registrationError: null,
            };

        // Login
        case authActionTypes.AUTH_LOGIN:
            if ((!referrer || referrer === "/") && action.payload.locationState && action.payload.locationState.from) referrer = action.payload.locationState.from.pathname;
            if (referrer && referrer.startsWith("/pub/")) referrer = "/";

            return {
                ...state,
                termsRequired: false,
                isLoading: true,
                passwordResetRequested: false,
                isAuthenticated: false,
                loginError: null,
                referrer: referrer || "/",
            };
        case authActionTypes.AUTH_LOGIN_SUCCESS:
            localStorage.setItem("token", action.payload.access_token);
            return {
                ...state,
                termsRequired: false,
                isLoading: false,
                isAuthenticated: true,
                userId: action.payload.userId,
                email: action.payload.email,
                council: action.payload.council,
                fullName: action.payload.fullName,
                initials: action.payload.fullName.substring(0, 1),
                activationToken: action.payload.activationToken,
                isActivated: action.payload.isActivated,
                isSystemAdmin: action.payload.isSystemAdmin,
                creditLimit: action.payload.creditLimit,
                isAdmin: action.payload.isAdmin,
                accountId: action.payload.accountId,
                accountName: action.payload.accountName,
                accountType: action.payload.accountType,
                accountExpires: action.payload.expires,
                parentAccountId: action.payload.parentAccountId,
                canChangePassword: action.payload.canChangePassword,
                canBePublishedTo: action.payload.canBePublishedTo,
                canPublishToMPIWholeFarmDataProject: action.payload.canPublishToMPIWholeFarmDataProject,
                canCreateFertiliserRecommendations: action.payload.canCreateFertiliserRecommendations,
                orgApiAccess: action.payload.orgApiAccess,
                lastLoginTime: Date.now(),
            };
        case authActionTypes.AUTH_ED_LOGIN:
            return {
                ...state,
                isEdLoading: true,
                edLoginError: null,
            };
        case authActionTypes.AUTH_ED_LOGIN_SUCCESS:
            return {
                ...state,
                edLoginError: null,
                isEdLoading: false,
                edToken: action.payload.access_token,
            };
        case authActionTypes.AUTH_ED_LOGIN_FAILURE:
            return {
                ...state,
                edLoginError: action.payload,
                isEdLoading: false,
                edToken: null,
            };
        case authActionTypes.AUTH_ZENDESK_SUCCESS:
            return {
                ...state,
                zendeskToken: action.payload,
            };
        case authActionTypes.AUTH_ZENDESK_FAILURE:
            return {
                ...state,
                loginError: action.payload,
            };
        case authActionTypes.AUTH_ZENDESK_CLEAR:
            return {
                ...state,
                zendeskToken: undefined,
                loginError: null,
            };
        case authActionTypes.AUTH_LOGIN_FAILURE:
            localStorage.removeItem("token");
            return {
                ...state,
                termsRequired: false,
                isLoading: false,
                passwordResetRequested: false,
                isAuthenticated: false,
                loginError: action.payload,
            };
        case authActionTypes.AUTH_LOGIN_CANCEL:
            return {
                ...state,
                isLoading: false,
                passwordResetRequested: false,
                loginError: null,
                referrer: null,
                termsRequired: false,
            };
        case authActionTypes.AUTH_TERMS_REQUIRED:
            return {
                ...state,
                isLoading: false,
                passwordResetRequested: false,
                loginError: null,
                referrer: null,
                isAuthenticated: false,
                termsRequired: true,
            };
        case authActionTypes.AUTH_TERMS_NOT_REQUIRED:
            return {
                ...state,
                isLoading: false,
                passwordResetRequested: false,
                loginError: null,
                referrer: null,
                termsRequired: false,
            };

        // Logout
        case authActionTypes.AUTH_LOGOUT:
            localStorage.removeItem("token");
            return {
                ...INITIAL_STATE,
                isAuthenticated: false,
                referrer: "/",
            };
        case authActionTypes.AUTH_UNAUTHORISED:
            return {
                ...INITIAL_STATE,
                isAuthenticated: false,
                referrer: null,
            };

        // Forgot Password
        case authActionTypes.AUTH_REQUEST_PASSWORD_RESET:
            return {
                ...state,
                passwordResetRequested: false,
            };
        case authActionTypes.AUTH_REQUEST_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordResetRequested: true,
                loginError: null,
            };

        // Reset Password
        case authActionTypes.AUTH_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordResetRequested: false,
                loginError: null,
            };

        // Invitation
        case authActionTypes.AUTH_GET_INVITATION:
            return {
                ...state,
                isLoading: true,
                userInvitationRequested: true,
                userInvitation: null,
            };
        case authActionTypes.AUTH_GET_INVITATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userInvitation: action.payload,
            };
        case authActionTypes.AUTH_GET_INVITATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                userInvitation: null,
            };
        case authActionTypes.AUTH_GET_INVITATION_CANCEL:
            return {
                ...state,
                isLoading: false,
                userInvitationRequested: false,
                userInvitation: null,
            };

        // Farm Access Invitation
        case authActionTypes.AUTH_ACCESS_GET_INVITATION:
            return {
                ...state,
                isLoading: true,
                farmAccessInvitationRequested: true,
                farmAccessInvitation: null,
            };
        case authActionTypes.AUTH_ACCESS_GET_INVITATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                farmAccessInvitation: action.payload,
            };
        case authActionTypes.AUTH_ACCESS_GET_INVITATION_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case authActionTypes.AUTH_ACCESS_GET_INVITATION_CANCEL:
            return {
                ...state,
                isLoading: false,
                farmAccessInvitationRequested: false,
                farmAccessInvitation: null,
            };

        // Update my profile
        case "AUTH_UPDATE_MY_PROFILE_SUCCESS": {
            const emailChanged = state.email !== action.payload.email;
            return {
                ...state,
                email: action.payload.email,
                fullName: action.payload.fullName,
                initials: action.payload.fullName.substring(0, 1),
                isActivated: emailChanged ? false : state.isActivated,
            };
        }
        case myOrgActionTypes.MY_ORG_UPDATE_ORG_SUCCESS:
        case userActionTypes.ACCOUNT_GET_SUCCESS:
            return {
                ...state,
                accountName: action.payload.name,
            };

        case authActionTypes.AUTH_USER_ACTIVATION_SUCCESS:
            return {
                ...state,
                activationFailed: false,
                isActivated: true,
            };

        case authActionTypes.AUTH_USER_ACTIVATION_FAILURE:
            return {
                ...state,
                activationFailed: true,
            };

        default:
            return state;
    }
};

export default authReducer;
