import { Form, Field } from "react-final-form";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import { Grid, GridCell } from "components/Grid";
import Button from "components/Button/Button";
import SelectPack from "components/SelectPack2";
import InputPack from "components/InputPack";
import TextareaPack from "components/TextareaPack";
import * as FormUtils from "common/FormUtils";
import Alert from "components/Alert";
import { useFarmAccess } from "containers/hooks";
import { useAuthContext, useModal } from "common/hooks";
import { useUnitPriceExclGst, sendPaymentAdvice } from "./_queries";

export default function PaymentAdviceModal({ farm, close }) {
    const authContext = useAuthContext();
    const { data: farmAccess } = useFarmAccess(farm.id);
    const { data: unitPriceExclGst } = useUnitPriceExclGst();

    const isLoading = !farm || !farmAccess;
    const accounts = farmAccess?.access?.filter((a) => a.role === "Owner" && a.accountId !== authContext.accountId)?.map((a) => ({ value: a.accountId, text: a.accountName, groupLabel: "Existing owner account", groupIndex: 0 })) || [];

    const iAmAnOwner = farmAccess?.access?.some((a) => a.accountId === authContext.accountId && a.role === "Owner") || false;
    const iAmAnAdmin = farmAccess?.access?.some((a) => a.accountId === authContext.accountId && a.role === "Admin") || false;
    const farmHasAnOwner = farmAccess?.access?.some((a) => a.role === "Owner") || false;
    const farmHasAnOwnerInvite = farmAccess?.accessInvitations.some((i) => i.role === "Owner" && i.status === "Pending") || false;
    const canInviteOwner = authContext.isSystemAdmin || iAmAnOwner || (iAmAnAdmin && !farmHasAnOwner && !farmHasAnOwnerInvite);

    if (iAmAnOwner || iAmAnAdmin || authContext.isSystemAdmin) {
        farmAccess?.accessInvitations
            ?.filter((a) => a.status === "Pending" && a.role === "Owner")
            ?.forEach((a) => {
                accounts.push({ value: a.id, text: `${a.inviteeEmail} - ${a.role}`, groupLabel: "Pending invites", groupIndex: 1 });
            });
    }
    const sendToAccount = accounts.length > 0;

    return (
        <Form
            initialValues={{ role: "Owner" }}
            validate={(formValues) => {
                const validation = {};
                if (sendToAccount) {
                    validation.account = FormUtils.validators.required(formValues.account);
                } else {
                    validation.email = FormUtils.validators.required(formValues.email);
                    validation.email = validation.email || FormUtils.validators.email(formValues.email);
                }
                return validation;
            }}
            onSubmit={async (values) => {
                const paymentAdvice = { ...values };
                paymentAdvice.farmId = farm.id;

                if (sendToAccount) {
                    delete paymentAdvice.email;
                    const invite = farmAccess?.accessInvitations?.find((a) => a.id === paymentAdvice.account);
                    if (invite) {
                        paymentAdvice.invitationId = paymentAdvice.account;
                        paymentAdvice.email = invite.inviteeEmail;
                    } else {
                        paymentAdvice.accountId = paymentAdvice.account;
                    }
                } else {
                    delete paymentAdvice.account;
                }
                await sendPaymentAdvice(paymentAdvice);
                close();
            }}
        >
            {({ values, handleSubmit, submitting }) => {
                const subTotal = unitPriceExclGst || 0;
                const gst = subTotal * 0.15;
                const total = subTotal + gst;
                const loading = !unitPriceExclGst || isLoading;
                const accountInfo = values.account ? (farmAccess?.accessInvitations?.find((a) => a.id === values.account) ? "Payment advice will be sent to the email address along with the original farm account invitation" : "Payment advice will be sent to each admin of the account") : null;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Request farm payment" close={close} submitting={submitting}>
                            <ModalBody loading={loading} info={`Send a payment request to a farm account owner or invite a new owner to pay for the subscription if the farm does not currently have an owner. The payment request amount will be $${total.toLocaleString()} and can include a comment as configured below.`}>
                                <Grid>
                                    <GridCell>
                                        {sendToAccount ? (
                                            <Field name="account" label="Owner" placeholder="Select an owner" options={accounts} required={true} component={SelectPack} dataWidth="50" info={accountInfo} />
                                        ) : (
                                            <>
                                                {canInviteOwner ? (
                                                    <>
                                                        <Field name="email" label="Email" placeholder="Enter an email" required={true} component={InputPack} dataWidth="50" />
                                                        <Alert type="warning" text="Sending a payment request to an email will also invite this user to be an owner of this farm account. If the email is an existing OverseerFM user you will be inviting the user's organisation to be the owner (along with all of the members of the organisation). Are you sure you want to do this?" />
                                                    </>
                                                ) : (
                                                    <Alert type="error" text="You do not have the access rights to invite an owner." />
                                                )}
                                            </>
                                        )}
                                    </GridCell>
                                    <GridCell>
                                        <Field name="comments" label="Comments" placeholder="Enter any comments to include with the email" type="text" component={TextareaPack} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary disabled={submitting}>
                                        Send
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    )
}

export function usePaymentAdviceModal(farm) {
    const [modal, openModal] = useModal(PaymentAdviceModal);

    const openPaymentAdviceModal = () => {
        const modalProps = {
            farm
        };
        openModal(modalProps);
    };

    return [modal, openPaymentAdviceModal];
}
