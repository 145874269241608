import React from "react";

const CardHeaderColumn = ({ dataWidth, nutrientsLost, slideToggle, justify, className, children }) => {
    return (
        <li data-width={nutrientsLost || slideToggle ? "shrink" : dataWidth} className={`${className ? className : ""} ${nutrientsLost ? "FarmTable-nutrientsLost u-ml-auto u-flexJustifyEnd" : ""} ${slideToggle ? "FarmTable-slideToggle" : ""} ${justify ? "u-ml-auto" : ""}`}>
            {children}
        </li>
    );
};
export default CardHeaderColumn;
