import { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { useQueryClient } from "@tanstack/react-query";
import * as utils from "common/utils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import Spinner from "components/Spinner/Spinner";
import ConfirmationField from "components/FormFields/ConfirmationField";
import ResultsComparison from "components/Comparison/ResultsComparison";
import Alert from "components/Alert";
import { httpClient } from "common/httpClient";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";
import { useModal } from "common/hooks";

export default function UpdateSmapSoilsModal({ farm, close }) {
    const featureTracker = useFeatureTracker(FEATURES.SMAP_UPDATES, "Open", farm);
    const queryClient = useQueryClient();

    const [analyses, setAnalyses] = useState(() => {
        const budgets = (farm.budgets || [])
            .filter((b) => b.class !== "PublishedAnalysis" && utils.canEditBudget(b))
            .map((b) => {
                const workingCopy = b.type === "Account" && (farm.publications || []).find((p) => p.id === b.publicationId);
                return {
                    id: b.id,
                    farmId: b.farmId,
                    name: b.name,
                    publishedTo: workingCopy && `Working copy - ${workingCopy.publishedTo}`,
                };
            });

        const publications = (farm.publications || []).map((publication) => {
            const publishedAnalysis = (farm.budgets || []).find((b) => b.id === publication.publishedAnalysisId);
            return {
                id: publishedAnalysis.id,
                farmId: publishedAnalysis.farmId,
                name: `${publishedAnalysis.name} (v${publication.version})`,
                publishedTo: `Publication - ${publication.publishedTo}`,
                isPublication: true,
            };
        });

        const results = [...budgets, ...publications];
        results.sort((a, b) => (a.name < b.name ? -1 : 1));
        return results;
    });

    useEffect(() => {
        let isMounted = true;
        // Checks for smap updates, one analysis at a time
        const nextAnalysisToCheck = analyses.find((a) => a.smapUpdates === undefined);
        if (nextAnalysisToCheck) {
            getSmapUpdates(nextAnalysisToCheck.farmId, nextAnalysisToCheck.id)
                .then((response) => {
                    if (isMounted) {
                        setAnalyses((prevState) => {
                            const nextState = prevState.map((a) => {
                                if (a.id === nextAnalysisToCheck.id) {
                                    const upToDate = (response.smapReferences || []).length === 0;
                                    const outOfDate = (response.smapReferences || []).length > 0;
                                    const resultsWillChange = outOfDate && Object.keys(response.results).some((key) => response.results[key].from !== response.results[key].to);

                                    return {
                                        ...a,
                                        smapUpdates: response,
                                        upToDate,
                                        outOfDate,
                                        resultsWillChange,
                                    };
                                }
                                return a;
                            });
                            return nextState;
                        });
                    }
                })
                .catch(() => {
                    if (isMounted) {
                        setAnalyses((prevState) => {
                            const nextState = prevState.map((a) => {
                                if (a.id === nextAnalysisToCheck.id) {
                                    return {
                                        ...a,
                                        smapUpdates: {},
                                        fetchFailed: true,
                                    };
                                }
                                return a;
                            });
                            return nextState;
                        });
                    }
                });
        }

        // Updates smap soils, one selected analysis at a time
        const nextAnalysisToUpdate = analyses.find((a) => a.submitting);
        if (nextAnalysisToUpdate) {
            updateSmapSoils(nextAnalysisToUpdate.farmId, nextAnalysisToUpdate.id)
                .then(() => {
                    if (isMounted) {
                        setAnalyses((prevState) => {
                            const nextState = prevState.map((a) => {
                                if (a.id === nextAnalysisToUpdate.id) {
                                    return {
                                        ...a,
                                        submitting: false,
                                        updateFailed: false,
                                        upToDate: true,
                                    };
                                }
                                return a;
                            });
                            return nextState;
                        });
                    }
                    queryClient.invalidateQueries({ queryKey: ["farms-details", nextAnalysisToUpdate.farmId] });
                })
                .catch(() => {
                    if (isMounted) {
                        setAnalyses((prevState) => {
                            const nextState = prevState.map((a) => {
                                if (a.id === nextAnalysisToUpdate.id) {
                                    return {
                                        ...a,
                                        submitting: false,
                                        updateFailed: true,
                                    };
                                }
                                return a;
                            });
                            return nextState;
                        });
                    }
                });
        }

        return () => (isMounted = false);
    }, [analyses, queryClient]);

    const submit = () => {
        if (hasUpdatableAnalyses() && hasOutOfDateSoils()) {
            featureTracker.track("Saved");
            setAnalyses((analyses) => {
                return analyses.map((analysis) => {
                    if (analysis.fetchFailed || analysis.upToDate) {
                        return { ...analysis, submitting: false, updateFailed: false };
                    } else {
                        return { ...analysis, submitting: true };
                    }
                });
            });
        } else {
            close();
        }
    };

    const hasUpdatableAnalyses = () => analyses?.some((analysis) => !analysis.isPublication && !analysis.upToDate);
    const hasOutOfDateSoils = () => analyses?.some((analysis) => !analysis.upToDate);

    const submitting = analyses?.some((analysis) => analysis.submitting || !analysis.smapUpdates);
    const info = (
        <>
            <span>
                S-Map soil data is periodically updated by S-Map. <b>Use this screen to...</b>
            </span>
            <ul className="disc">
                <li>Check if there are any S-Map soil data updates for the analyses on this farm</li>
                <li>Review the potential impact of any S-Map soil data updates on model results</li>
                {hasUpdatableAnalyses() && <li>Update the S-Map soil data for analyses with available updates</li>}
            </ul>
        </>
    );
    const error = analyses.some((analysis) => analysis.updateFailed || (analysis.smapUpdates && analysis.smapUpdates.errors && analysis.smapUpdates.errors.length > 0)) && "One or more analyses failed to update. Please try again or call support if the problem persists.";

    return (
        <>
            <Form initialValues={{}} onSubmit={submit}>
                {({ values, handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Modal title="Check for S-Map soil updates" close={close} submitting={submitting}>
                                <ModalBody info={info} error={error}>
                                    <div className="Table u-mt-md">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th data-width="30">Analysis/Publication</th>
                                                    <th data-width="15">Outdated soils</th>
                                                    <th>Results if updated</th>
                                                    <th className="th--shrink u-textCenter"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {analyses.map((analysis) => {
                                                    const loading = !analysis.smapUpdates || analysis.submitting;
                                                    const failed = analysis.fetchFailed || analysis.updateFailed;
                                                    const showError = !loading && failed;
                                                    const showSuccess = !loading && !failed;
                                                    return (
                                                        <tr key={analysis.id}>
                                                            <td valign="top" className="u-pt-sm">
                                                                <div className="u-textBold">{analysis.name}</div>
                                                                {analysis.version && <div>{analysis.version}</div>}
                                                                {analysis.publishedTo && <div>{analysis.publishedTo}</div>}
                                                            </td>
                                                            <td valign="top" className="u-pt-sm">
                                                                {analysis.outOfDate ? (
                                                                    <ul className="disc u-pl-sm u-mt-0 u-mb-0">
                                                                        {analysis.smapUpdates.smapReferences.map((smapRef) => (
                                                                            <li key={smapRef}>{smapRef}</li>
                                                                        ))}
                                                                    </ul>
                                                                ) : (
                                                                    <>-</>
                                                                )}
                                                            </td>
                                                            <td valign="top">
                                                                {analysis.outOfDate && analysis.resultsWillChange && (!analysis.smapUpdates.errors || analysis.smapUpdates.errors.length === 0) && <ResultsComparison analysisId={analysis.id} results={analysis.smapUpdates.results} />}
                                                                {analysis.outOfDate && analysis.resultsWillChange && analysis.smapUpdates.errors && analysis.smapUpdates.errors.length > 0 && analysis.smapUpdates.errors.map((e) => <p>{e}</p>)}
                                                                {analysis.outOfDate && analysis.isPublication && <Alert className="u-mb-0" type="info" text="To update the soils for this publication you must update the soils on the original analysis and re-publish." />}
                                                                {analysis.outOfDate && !analysis.resultsWillChange && !analysis.isPublication && <Alert className="u-mb-0" type="info" text="These soils can be updated without changing the overall model results for this analysis" />}
                                                                {!analysis.outOfDate && !analysis.resultsWillChange && <>No change in soils</>}
                                                            </td>
                                                            <td valign="top" className="u-textCenter">
                                                                {loading && <Spinner dark />}
                                                                <div className="u-flex">{showError && <i className="icon icon-alert u-textError" />}</div>
                                                                {showSuccess && <i className="icon icon-tick-circle u-textSuccess" />}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="u-flex u-flexJustifyEnd">{!hasOutOfDateSoils() && <h3 className="IconLink--tick-circle">All soils are up to date</h3>}</div>
                                </ModalBody>
                                <ModalFooter>
                                    <ModalFooterLeft>
                                        <Button id="cancel" onClick={close} secondary>
                                            Cancel
                                        </Button>
                                    </ModalFooterLeft>
                                    {hasUpdatableAnalyses() && hasOutOfDateSoils() && (
                                        <ModalFooterRight>
                                            <Field name="confirmed" confirmationPhrase="UPDATE" component={ConfirmationField} />
                                            <Button id="submit" submit primary disabled={!values.confirmed || !analyses.some((a) => !a.fetchFailed && !a.upToDate) || submitting}>
                                                Update all
                                            </Button>
                                        </ModalFooterRight>
                                    )}
                                    {(!hasUpdatableAnalyses() || !hasOutOfDateSoils()) && (
                                        <ModalFooterRight>
                                            <Button id="submit" submit>
                                                Close
                                            </Button>
                                        </ModalFooterRight>
                                    )}
                                </ModalFooter>
                            </Modal>
                        </form>
                    );
                }}
            </Form>
        </>
    );
}

export function useUpdateSmapSoilsModal(farm) {
    const [modal, openModal] = useModal(UpdateSmapSoilsModal);

    const openUpdateSmapSoilsModal = () => {
        const modalProps = {
            farm
        };
        openModal(modalProps);
    };

    return [modal, openUpdateSmapSoilsModal];
}

async function getSmapUpdates(farmId, analysisId) {
    const timeout = 1000 * 60 * 5; // 5 minutes
    return httpClient.get(`farms/${farmId}/budgets/${analysisId}/smapUpdates`, timeout);
}

async function updateSmapSoils(farmId, analysisId) {
    const timeout = 1000 * 60 * 5; // 5 minutes
    return httpClient.put(`farms/${farmId}/budgets/${analysisId}/smapUpdates`, null, timeout);
}
