import { useState } from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { httpClient } from "common/httpClient";
import { useModal } from "common/hooks";
import * as utils from "common/utils";
import * as geoJsonUtils from "common/geoJsonUtils";
import * as FormUtils from "common/FormUtils";
import { MultiFileUploadField, SelectField } from "components/FormFields";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import ProgressBar from "components/ProgressBar";
export default function BulkImportMapsModal({ analyses, close }) {
    const [completedCounters, setCompletedCounters] = useState(0);

    const handleSourceChanged = (form) => (source) => {
        const fileExtensions = SOURCE_OPTIONS.find((src) => src.value === source).fileExtensions;
        form.change("fileExtensions", fileExtensions);
        if (!fileExtensions) {
            form.change("files", []);
        }
    }

    const validate = (values) => {
        const validation = {};

        validation.source = FormUtils.validators.required(values.source);

        validation.files = FormUtils.validators.required(values.files);

        if (!validation.files) {
            // Check if any files have errors.
            const firstError = values.files.reduce((msg, file) => {
                if (!msg && file.error) {
                    msg = file.error;
                }
                return msg;
            }, undefined);
            validation.files = firstError;
        }

        if (!validation.files) {
            // Check if any files are missing content.
            if (values.files.some((file) => !file.json)) {
                validation.files = "Required";
            }
        }

        if (!validation.files) {
            // Check if all files contain valid coordinate reference system (WGS84 datum with longitude and latitude units of decimal degrees).
            for (const file of values.files) {
                const supportedFeatures = file.json.features.filter((f) => ["Polygon", "MultiPolygon", "GeometryCollection"].includes(f.geometry.type));
                if (supportedFeatures.length === 0) {
                    if (values.source === "kml") validation.files = "KML files must contain at least one geometry of type Polygon, MultiPolygon or MultiGeometry.";
                    if (values.source === "geoJson") validation.files = "GeoJSON files must contain at least one geometry of type Polygon, MultiPolygon or GeometryCollection.";
                    validation.files += " Please contact whoever provided this file to you to get an updated version with valid geometry types.";
                } else {
                    // Crude validation of coordinate reference system. Center of first feature must have valid lat/lng values.
                    const centerOfFirstFeature = geoJsonUtils.center(supportedFeatures[0]);
                    if (centerOfFirstFeature.lat < -90 || centerOfFirstFeature.lat > 90 || centerOfFirstFeature.lng < -180 || centerOfFirstFeature.lng > 180) {
                        validation.files = `Unsupported coordinate reference system. File must use the WGS84 datum with longitude and latitude (in that order) units of decimal degrees. Please contact whoever provided this file to you to get an updated version with the correct coordinate reference system.`;
                    }
                }
            }
        }

        return validation;
    }

    const submitAsync = async (values) => {
        try {
            const importedFiles = [];

            var domParser = new DOMParser();

            for (const file of values.files) {
                const analysisKey = file.name.replace(".kml", "");
                const analysesForThisFile = analyses.filter(a => a.name.startsWith(analysisKey));
                if (analysesForThisFile?.length > 0) {
                    for (const feature of file.json.features) {
                        const htmlString = feature.properties.description;
                        feature.properties = {};
                        const htmlDoc = domParser.parseFromString(htmlString, "text/html");
                        const rows = htmlDoc.querySelectorAll("tr");
                        for (const row of rows) {
                            const cells = row.querySelectorAll("td");
                            if (cells.length > 1) {
                                const key = cells[0].textContent.trim();
                                const value = cells[1].textContent.trim();
                                if (key === "Latitude") {
                                    feature.properties.climateLatitude = Number(value);
                                } else if (key === "Longitude") {
                                    feature.properties.climateLongitude = Number(value);
                                }
                            }
                        }
                    }
                    for (const analysis of analysesForThisFile) {
                        importedFiles.push({
                            farmId: analysis.farmId,
                            analysisId: analysis.id,
                            fileName: file.name,
                            analysisName: analysis.name,
                            features: file.json.features
                        })
                    }
                }
            }

            for (const file of importedFiles) {
                const denormalisedFeatures = [];
                const analysis = await getAnalysisAsync(file.analysisId);
                const blocksThatRequireClimate = analysis.blocks.filter(b => utils.requiresClimate(b.type));

                for (const feature of file.features) {
                    for (const block of blocksThatRequireClimate) {

                        const blockMatchesThisFeature = feature.properties.blockId === block.id
                            || (feature.properties.name?.toLowerCase() === block.name.toLowerCase())
                            || (feature.properties.blockName?.toLowerCase() === block.name.toLowerCase())
                            || (block.climateLatitude === feature.properties.climateLatitude && block.climateLongitude === feature.properties.climateLongitude);

                        if (blockMatchesThisFeature) {
                            if (feature.geometry.type === "Polygon") {
                                denormalisedFeatures.push({
                                    type: feature.type,
                                    properties: {
                                        ...feature.properties,
                                        layer: "Block",
                                        blockId: block.id,
                                    },
                                    geometry: feature.geometry,
                                });
                            } else if (feature.geometry.type === "GeometryCollection") {
                                const denormalisedGeometryCollection = feature.geometry.geometries.map(geometry => ({
                                    type: "Feature",
                                    properties: {
                                        ...feature.properties,
                                        layer: "Block",
                                        blockId: block.id,
                                    },
                                    geometry
                                }));
                                denormalisedFeatures.push(...denormalisedGeometryCollection);
                            }
                        }
                    }
                }

                await updateAnalysisGeoJsonAsync(file.farmId, file.analysisId, denormalisedFeatures);
                setCompletedCounters((prevState) => prevState + 1);
            }
            close();
        } catch (ex) {
            return {
                [FORM_ERROR]: ex.message
            };
        }
    }

    return (
        <Form initialValues={{ autoAssignBlocks: true }} validate={validate} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting }) => {
                const progress = Math.round((completedCounters / analyses.length) * 100, 0);
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Bulk import maps" close={close} submitting={submitting} fluid>
                            <ModalBody>
                                <Field name="source" label="Import from" required placeholder="Select an import source" onChange={handleSourceChanged(form)} options={SOURCE_OPTIONS} component={SelectField} />
                                {values.source && <Field name="files" fileExtensions={values.fileExtensions} required component={MultiFileUploadField} />}
                                {completedCounters > 0 && <>
                                    <div className="u-mt-lg u-mb-sm">
                                        <strong>Processing:</strong> {completedCounters} of {analyses.length}
                                    </div>
                                    <ProgressBar colour="#36975c" progress={progress} endLabel={`${progress}%`} showZero noPadLeft />
                                </>}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting}>
                                        Import
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useBulkImportMapsModal(analyses) {
    const [modal, openModal] = useModal(BulkImportMapsModal);

    const onClose = () => window.location.reload();

    const open = () => {
        const modalProps = {
            analyses
        };
        openModal(modalProps, onClose);
    }

    return [modal, open];
}

async function getAnalysisAsync(analysisId) {
    try {
        return await httpClient.get(`v1/analysis-details/${analysisId}`);
    } catch (error) {
        if (error.status === 401 || error.status === 403) {
            throw new Error("You are not authorised to make this change.");
        } else {
            throw new Error(error.message);
        }
    }
}

async function updateAnalysisGeoJsonAsync(farmId, analysisId, features) {
    try {
        await httpClient.put(`farms/${farmId}/analyses/${analysisId}/geojson`, { farmId, analysisId, features });
    } catch (error) {
        if (error.status === 401 || error.status === 403) {
            throw new Error("You are not authorised to make this change.");
        } else {
            throw new Error(error.message);
        }
    }
}

const SOURCE_OPTIONS = [
    { value: "kml", text: "Import from KML file", fileExtensions: ["kml"] },
    { value: "geoJson", text: "Import from GeoJSON file", fileExtensions: ["geojson", "json"] }
];
