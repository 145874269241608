import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import SelectPack from "components/SelectPack2";
import Button from "components/Button/Button";
import { useRefData } from "common/hooks";

export default function FertiliserApplicationActivities({ form, values }) {
    const refData = useRefData();

    const _addActivity = () => {
        const applicationMethods = (refData.ghgFertiliserApplicationTopographies || []).map((topo) => {
            return { topography: topo.value };
        });
        form.mutators.push("fertiliserApplicationActivities", { applicationMethods });
    };

    const _removeActivity = (index) => {
        const results = values.fertiliserApplicationActivities.reduce((acc, cur, i) => {
            if (i !== index) acc.push(cur);
            return acc;
        }, []);
        form.change("fertiliserApplicationActivities", results);
    };

    const _hasActivities = (values.fertiliserApplicationActivities || []).length > 0;

    const _hasAllActivities = (values.fertiliserApplicationActivities || []).length === (refData.ghgFertiliserApplicationActivities || []).length;

    const _validate = (values) => {
        const errorArray = [];

        values &&
            values.forEach((value) => {
                const errors = {};

                errors.activity = FormUtils.validators.required(value.activity);

                errorArray.push(errors);
            });

        return errorArray;
    };

    const _addActivityLink = !_hasAllActivities && (
        <span id="add-activity-link" className="IconLink--arrow-plus u-link" onClick={_addActivity}>
            Add override
        </span>
    );

    return (
        <Tile title="Fertiliser application methods" actions={_addActivityLink} padTop tertiary>
            <TileBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th data-width="30">Fertiliser type</th>
                                {(refData.ghgFertiliserApplicationTopographies || []).map((topo) => (
                                    <th key={topo.value} data-width="17.5">
                                        {topo.text}
                                    </th>
                                ))}
                                {_hasActivities && <th className="th--shrink">&nbsp;</th>}
                            </tr>
                        </thead>
                        {!_hasActivities && (
                            <tbody>
                                <tr>
                                    <td colSpan="5">
                                        <div className="Tile-body">
                                            <div className="Tile-body-message">
                                                <h3 className="u-mt-md">This analysis is currently using the default fertiliser application methods for all topographies</h3>
                                                <p className="">Fertiliser is assumed to be applied by ground for blocks with flat/rolling topography and by air for blocks with hilly topography</p>
                                                <Button id="add-fertiliser-application-activity-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={_addActivity}>
                                                    Add override
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {_hasActivities && (
                            <tbody>
                                <FieldArray name="fertiliserApplicationActivities" validate={_validate}>
                                    {({ fields }) => {
                                        return fields.map((field, i) => {
                                            const activityOptions = _getActivityOptions(refData, fields.value, i);
                                            return (
                                                <tr key={field}>
                                                    <td>
                                                        <Field name={`${field}.activity`} component={SelectPack} options={activityOptions} placeholder="Select a fertiliser type" />
                                                    </td>
                                                    {
                                                        <FieldArray name={`${field}.applicationMethods`}>
                                                            {({ fields }) => {
                                                                // Table headings are rendered by ref data. Sort fields by ref data too.
                                                                const applicationMethods = [];
                                                                (refData.ghgFertiliserApplicationTopographies || []).forEach((topographyOption) => {
                                                                    fields.forEach((field, i) => {
                                                                        if (fields.value[i].topography === topographyOption.value) applicationMethods.push({ field, topographyOption });
                                                                    });
                                                                });
                                                                return applicationMethods.map((applicationMethod) => {
                                                                    var applicationMethodOptions = _getAplicationMethodOptions(refData, applicationMethod.topographyOption);
                                                                    return (
                                                                        <td key={applicationMethod.field}>
                                                                            <Field name={`${applicationMethod.field}.method`} component={SelectPack} options={applicationMethodOptions} />
                                                                        </td>
                                                                    );
                                                                });
                                                            }}
                                                        </FieldArray>
                                                    }
                                                    <td>
                                                        <span id={`delete-fertiliser-application-activity-${i}`} className="a u-link IconLink--cross-circle" onClick={() => _removeActivity(i)}></span>
                                                    </td>
                                                </tr>
                                            );
                                        });
                                    }}
                                </FieldArray>
                            </tbody>
                        )}
                    </table>
                </div>
            </TileBody>
        </Tile>
    )
}

const _getActivityOptions = (refData, selectedActivities, index) => {
    const options = (refData.ghgFertiliserApplicationActivities || []).filter((option) => {
        const isSelected = (selectedActivities || []).some((selectedActivity, i) => i !== index && selectedActivity.activity === option.value);
        return !isSelected;
    });
    return options;
}

const _getAplicationMethodOptions = (refData, topography) => {
    const options = (refData.ghgFertiliserApplicationMethods || []).reduce((results, option) => {
        results.push({
            ...option,
            text: option.value === "Undefined" ? `${option.text}: ${topography.defaultApplicationMethod}` : option.text,
        });
        return results;
    }, []);
    return options;
}

