import Watermark from "components/Watermark";
import NutrientBudgetSection from "./NutrientBudgetSection";

export default function NutrientBudget({ nitrogenResults, phosphorusResults, nutrientBudget, id }) {
    var averageNitrogenLoss = nitrogenResults?.lossPerHectare;
    var totalNitrogenLoss = nitrogenResults?.totalLoss;

    var averagePhosphorusLoss = phosphorusResults?.lossPerHectare;
    var totalPhosphorusLoss = phosphorusResults?.totalLoss;

    return (
        <div className="FarmTable-supplementary">
            <>
                <div className="Table Table-Compressed u-mt-sm">
                    <Watermark />
                    <table id="NutrientBudgetTotals">
                        <thead>
                            <tr>
                                <th data-width="20">Nutrient loss</th>
                                <th data-width="30" className="numeric">
                                    Average loss (kg/ha)
                                </th>
                                <th data-width="40" className="numeric">
                                    Total loss (kg)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Nitrogen</td>
                                <td id="Nitrogen_lostToWater" className="numeric">
                                    {averageNitrogenLoss?.value?.toLocaleString()}
                                </td>
                                <td id="Nitrogen_totalLost" className="numeric">
                                    {totalNitrogenLoss?.value?.toLocaleString()}
                                </td>
                            </tr>
                            <tr>
                                <td>Phosphorus</td>
                                <td id="Phosphorus_lostToWater" className="numeric">
                                    {averagePhosphorusLoss?.value?.toFixed(1)?.toLocaleString()}
                                </td>
                                <td id="Phosphorus_totalLost" className="numeric">
                                    {totalPhosphorusLoss?.value?.toLocaleString()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
            <NutrientBudgetSection section={nutrientBudget?.added} title={`Nutrients added`} uom="kg/ha" id={id} />
            <NutrientBudgetSection section={nutrientBudget?.removed} title={`Nutrients removed`} uom="kg/ha" id={id} />
            <NutrientBudgetSection section={nutrientBudget?.changeInPools} title={`Change in pools`} uom="kg/ha" id={id} />
        </div>
    )
}
