import { Line } from "react-chartjs-2";
import * as cropUtils from "containers/BudgetHome/Crops/_utils";
import { useNavigate, useRefData } from "common/hooks";

export default function Calendar({ data, growthCurve, analysis, block = {} }) {
    const refData = useRefData();
    const navigate = useNavigate();

    const rotationYear = cropUtils.rotationYear(block);
    const months = data.length > 0 && data[0].months ? data[0].months : rotationYear;
    const { crops = [] } = block;

    const options = {
        scales: {
            x: { display: false },
            y: { display: false },
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            filler: {
                propagate: true,
            },
        },
    };

    const curveCells = cropUtils.mapGrowthCurve(growthCurve, crops, block, rotationYear, analysis);
    const labelCells = cropUtils.mapLabels(block, growthCurve, refData);

    return (
        <div className="Table u-tbl-lg">
            <table className="Calendar u-tbl-fix">
                <thead>
                    {rotationYear.length > 12 && (
                        <tr className="Calendar-reportingyears">
                            <td colSpan="12" className="Year1 Solid-border">
                                <span className="h5">Year 1</span>
                            </td>
                            <td colSpan="12">
                                <span className="h5">Reporting Year</span>
                            </td>
                        </tr>
                    )}
                    <tr className="Calendar-months">
                        {months.map((m, i) => (
                            <th key={i} className="Calendar-month">
                                {m.substring(0, 3)}
                            </th>
                        ))}
                        {data.some((i) => i.actions) && <th className="th--shrink">&nbsp;</th>}
                    </tr>
                </thead>
                <tbody>
                    {crops.length > 0 && rotationYear.length === growthCurve.length && (
                        <tr>
                            {curveCells.map((c, i) => {
                                //const colSpan = isNaN(c.cs) || c.cs === 0 ? 1 : c.cs;
                                //const sidePercentage = Math.round((1 / colSpan) * 50);
                                const sidePercentage = 0;
                                return (
                                    <td key={i} style={{ padding: 0, borderLeft: "none", borderRight: "none" }} colSpan={c.cs}>
                                        {c.data && c.data.labels.length > 1 && (
                                            <div style={{ width: "100%", paddingRight: `${sidePercentage}%`, paddingLeft: `${sidePercentage}%`, paddingTop: 4 }}>
                                                <Line data={c.data} height={50} options={options} />
                                            </div>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    )}
                    {data.map((item, i) => {
                        return (
                            <tr key={i} className={"Calendar-slots" + (item.url && " hover u-link")} onClick={_gotoUrl(item.url, navigate)}>
                                {item.noMonthsMessage && item.slots.length === 0 && (
                                    <td colSpan="12">
                                        <p id={`calendar.noMonthsMessage.${item.id}`} className="u-mt-0">
                                            {item.noMonthsMessage}
                                        </p>
                                    </td>
                                )}
                                {item.slots.length > 0 &&
                                    months.map((calendarMonth, i) => {
                                        let slot = item.slots.find((slot) => slot.monthIndx === i) || item.slots.find((slot) => slot.month === calendarMonth);

                                        if (slot && !slot.preCrop && growthCurve && growthCurve.length > slot.monthIndx && growthCurve.reduce((a, b) => a + (b === null ? 0 : b.uptake), 0) !== 0) {
                                            if (growthCurve[slot.monthIndx].uptake === 0) slot = undefined;
                                        }

                                        if (slot) {
                                            return (
                                                <td key={i} className="Calendar-slot">
                                                    {slot.icon && <img id={`calendar.${calendarMonth}.icon.${item.id}`} src={slot.icon} style={{ opacity: slot.preCrop ? "0.2" : "1" }} className="Calendar-icon u-p-xxs" alt={slot.iconText} />}
                                                </td>
                                            );
                                        } else {
                                            return <td key={i} className="Calendar-slot"></td>;
                                        }
                                    })}
                                {item.actions && <td>{item.actions}</td>}
                            </tr>
                        );
                    })}
                    {labelCells.length > 0 && (
                        <tr className={"Calendar-slots" + (data[0].url && " hover u-link")} onClick={_gotoUrl(data[0].url, navigate)}>
                            {labelCells.map((cell, i) => {
                                return (
                                    <td key={i} colSpan={cell.colSpan} className={"Calendar-slot"}>
                                        {cell.iconText && (
                                            <span className="h6" style={{ opacity: cell.preCrop ? "0.6" : "1" }}>
                                                {cell.iconText}
                                            </span>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

const _gotoUrl = (url, navigate) => (e) => {
    if (url && navigate) {
        e.preventDefault();
        navigate(url);
    }
}
