import Tile from "components/Tile";
import TileBody from "components/TileBody";
import ActionLink from "components/ActionLink";
import Button from "components/Button/Button";
import { useConfirm } from "common/hooks";
import { useHealthSupplementsModal } from "containers/BudgetHome/Animals/HealthSupplements";

export default function HealthSupplementsSummary({ enterpriseType, healthSupplements, saved }) {
    const confirm = useConfirm();
    const [healthSupplementsModal, openHealthSupplementsModal] = useHealthSupplementsModal();

    const _onEdited = (healthSupplements) => {
        saved(healthSupplements);
    };

    const _editHealthSupplements = (e) => {
        if (e.target.id === "delete-health-supplements") return;

        e.preventDefault();
        const supplementsType = healthSupplements.totals || !healthSupplements.drenching ? "Totals" : "Drenching";

        let drenching = healthSupplements.drenching;
        if (drenching) {
            const saltMaterial = healthSupplements.drenching.saltRate_gCowDay || healthSupplements.drenching.saltNumberOfWeeksUsed ? true : false;
            const limeFlourMaterial = healthSupplements.drenching.limeFlourRate_gCowDay || healthSupplements.drenching.limeFlourNumberOfWeeksUsed ? true : false;
            drenching = {
                ...drenching,
                saltMaterial,
                limeFlourMaterial,
            };
        }

        const viewModel = {
            ...healthSupplements,
            enterpriseType,
            supplementsType: supplementsType,
            drenching: drenching,
        };
        openHealthSupplementsModal(viewModel, _onEdited);
    };

    const _deleteHealthSupplements = (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete the health supplements?`, () => {
            saved({ saltBlocks: [] });
        });
    };

    const totals = healthSupplements.totals ? healthSupplements.totals : healthSupplements.drenching ? null : {};
    const hasTotals = (totals && Object.keys(totals).length > 0) || (healthSupplements.saltBlocks || []).length > 0 || healthSupplements.nitrogen_kgYr !== undefined || healthSupplements.phosphate_kgYr !== undefined;

    const drenching = healthSupplements.drenching ? healthSupplements.drenching : null;
    const hasDrenching = drenching && Object.keys(drenching).length > 0;

    const addHealthSuppAction = !hasTotals && !hasDrenching && (
        <span id="add-health-supplements-action" onClick={_editHealthSupplements} className="IconLink--arrow-plus u-link">
            Add health supplements
        </span>
    );

    return (
        <>
            <Tile title="Health supplements" actions={addHealthSuppAction} tertiary>
                <TileBody>
                    <div className="Table u-mt-md">
                        {totals && (
                            <table>
                                <thead>
                                    <tr>
                                        {hasTotals === true && <th>Supplements by</th>}
                                        <th>Magnesium oxide</th>
                                        <th>Magnesium sulphate</th>
                                        <th>Magnesium chloride</th>
                                        <th>Salt</th>
                                        <th>Lime flour</th>
                                        <th>Urea(N)</th>
                                        <th>Phosphate(P)</th>
                                        {hasTotals === true && <th className="th--shrink">&nbsp;</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {hasTotals === false && (
                                        <tr>
                                            <td colSpan="7">
                                                <div className="Tile-body">
                                                    <div className="Tile-body-message">
                                                        <p className="h4 u-mt-0">You do not have any health supplements</p>
                                                        <Button id="add-health-supplements-button" onClick={_editHealthSupplements} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                            Add health supplements
                                                        </Button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {hasTotals === true && (
                                        <tr id="edit-health-supplements-action" onClick={_editHealthSupplements} className="hover u-link">
                                            <td>
                                                <span className="a">Total annual amount applied</span>
                                            </td>
                                            <td>{totals.mgOxide_kgYr ? `${totals.mgOxide_kgYr} kg/yr` : "None"}</td>
                                            <td>{totals.mgSulphate_kgYr ? `${totals.mgSulphate_kgYr} kg/yr` : "None"}</td>
                                            <td>{totals.mgChloride_kgYr ? `${totals.mgChloride_kgYr} kg/yr` : "None"}</td>
                                            <td>{totals.salt_kgYr ? `${totals.salt_kgYr} kg/yr` : "None"}</td>
                                            <td>{totals.limeFlour_kgYr ? `${totals.limeFlour_kgYr} kg/yr` : "None"}</td>
                                            <td>{healthSupplements.nitrogen_kgYr ? `${healthSupplements.nitrogen_kgYr} kg/yr` : "None"}</td>
                                            <td>{healthSupplements.phosphate_kgYr ? `${healthSupplements.phosphate_kgYr} kg/yr` : "None"}</td>

                                            <td>
                                                <div className="u-flex">
                                                    <ActionLink id="delete-health-supplements" onClick={_deleteHealthSupplements} className="IconLink--trash" title="Delete"></ActionLink>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                        {drenching && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Supplements by</th>
                                        <th>Magnesium (drench)</th>
                                        <th>Salt (drench)</th>
                                        <th>Lime flour (drench)</th>
                                        <th>Magnesium (dust)</th>
                                        <th>Salt (dust)</th>
                                        <th>Lime flour (dust)</th>
                                        <th>Urea(N)</th>
                                        <th>Phosphate(P)</th>
                                        {hasDrenching && <th className="th--shrink">&nbsp;</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {hasDrenching === true && (
                                        <tr id="edit-health-supplements-action" onClick={_editHealthSupplements} className="hover u-link">
                                            <td>
                                                <span className="a">Drenching and dusting regimes</span>
                                            </td>
                                            <td>{drenching.mgRate_gCowDay ? `${drenching.mgRate_gCowDay} g/cow/day` : "None"}</td>
                                            <td>{drenching.saltRate_gCowDay ? `${drenching.saltRate_gCowDay} g/cow/day` : "None"}</td>
                                            <td>{drenching.limeFlourRate_gCowDay ? `${drenching.limeFlourRate_gCowDay} g/cow/day` : "None"}</td>
                                            <td>{drenching.mgDusting_kghaYr ? `${drenching.mgDusting_kghaYr} kg/yr` : "None"}</td>
                                            <td>{drenching.saltDusting_kghaYr ? `${drenching.saltDusting_kghaYr} kg/yr` : "None"}</td>
                                            <td>{drenching.limeFlourDusting_kghaYr ? `${drenching.limeFlourDusting_kghaYr} kg/yr` : "None"}</td>
                                            <td>{healthSupplements.nitrogen_kgYr ? `${healthSupplements.nitrogen_kgYr} kg/yr` : "None"}</td>
                                            <td>{healthSupplements.phosphate_kgYr ? `${healthSupplements.phosphate_kgYr} kg/yr` : "None"}</td>
                                            <td>
                                                <div className="u-flex">
                                                    <ActionLink id="delete-health-supplements" onClick={_deleteHealthSupplements} className="IconLink--trash" title="Delete"></ActionLink>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </TileBody>
            </Tile>
            {healthSupplementsModal}
        </>
    )
}
