import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import Irrigation from "./Irrigation";
import IrrigationCreate from "./IrrigationCreate";
import IrrigationEdit from "./IrrigationEdit";

export default function IrrigationTab({ farm, analysis }) {
    return (
        <Switch>
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/irrigation" render={() => <Irrigation farm={farm} analysis={analysis} />} restoreScrollY />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/irrigation/new" render={() => <IrrigationCreate farm={farm} analysis={analysis} />} />
            <ScrollTopRoute exact path="/app/farm/:farmId/analysis/:budgetId/irrigation/:irrigatorId" render={() => <IrrigationEdit farm={farm} analysis={analysis} />} />
        </Switch>
    )
}
