import * as domain from "common/domain";
import * as utils from "common/utils";
import * as effluentUtils from "./_utils";
import TileSubPanel from "components/TileSubPanel";
import { getBlockList } from "common/utils";
import { useRefData } from "common/hooks";

export default function BlockList({ farm, analysis }) {
    const refData = useRefData();

    const blockList = getBlockList(analysis.blocks);

    return refData.blockTypes.map((blockType) => {
        const blocksByType = blockList.productiveBlocks.filter((block) => block.type === blockType.value);

        return (
            blocksByType.length > 0 && (
                <TileSubPanel key={blockType.value} title={`${blockType.text} ${blockType.value === "FodderCrop" ? "rotations" : " blocks"}`} stickyHeaderName={`analysis.structures.${blockType.value}.collapsed`}>
                    {blocksByType.map((block, index) => {
                        const blockIcon = utils.getBlockIcon(block.type);
                        const blockType = utils.getBlockTypeText(refData, block);

                        let blockActivity = [];
                        if (analysis.effluentSystem) {
                            blockActivity.push(...effluentUtils._getEffluentApplicationsForSystem(farm, analysis, block, refData, analysis.effluentSystem));
                        }
                        /* eslint-disable no-unused-vars */
                        for (const s of analysis.structures.filter((s) => s.effluentSystem)) {
                            blockActivity.push(...effluentUtils._getEffluentApplicationsForSystem(farm, analysis, block, refData, s.effluentSystem, false, s));
                        }
                        if (analysis.outdoorPigEffluentSystem) {
                            blockActivity.push(...effluentUtils._getEffluentApplicationsForOutdoorPigSystem(farm, analysis, block, refData, analysis.outdoorPigEffluentSystem));
                        }
                        return (
                            <div className="FarmTable_wrapper" key={index}>
                                <ul className="DataWidthTable FarmTable">
                                    <li className="FarmTable-title" data-width="md-100 xl-30">
                                        <div className="FarmTitle">
                                            <img className="FarmTitle-icon" src={blockIcon} alt="Block" />
                                            <div className="FarmTitle-heading">
                                                <span className="FarmTitle-name" id={`${block.id}_name`} title={block.name}>
                                                    {block.name}
                                                </span>
                                                <div className="FarmTitle-sub">{blockType}</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                {blockActivity.length > 0 && (
                                    <div className="FarmTable-supplementary is-open">
                                        <div className="Table" style={{ backgroundColor: "#fff" }}>
                                            <table className="Calendar">
                                                <thead>
                                                    <tr className="Calendar-months">
                                                        <th className="Calendar-title">Source</th>
                                                        {domain.farmYear.map((m, i) => (
                                                            <th className="Calendar-month" key={i}>
                                                                {m.substring(0, 3)}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {blockActivity.map((b, i) => {
                                                        return (
                                                            <tr className="Calendar-slots Calendar-slots--fertiliser" key={"effluent-app-" + i}>
                                                                <td className="td--keyValuePair">
                                                                    <span className="td-key">{b.heading}</span>
                                                                    <span className="td-value-text">{b.titleContent}</span>
                                                                </td>
                                                                {b.activity[0].month ? (
                                                                    domain.farmYear.map((m, j) => _renderMonth(b, m, "effluent-app-month-" + i + "-" + j))
                                                                ) : (
                                                                    <td key={"effluent-app-year" + i} colSpan="12" className="Calendar-slot">
                                                                        {b.activity[0].content}
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </TileSubPanel>
            )
        );
    });
};

const _renderMonth = (blockActivity, month, key) => {
    const monthActivity = blockActivity.activity.find((a) => a.month === month);
    return monthActivity ? monthActivity.content : <td key={key} className="Calendar-slot"></td>;
}
