import { useEffect } from "react";
import { Field } from "react-final-form";
import * as domain from "common/domain";
import * as FormUtils from "common/FormUtils";
import Alert from "components/Alert";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import SelectField from "components/FormFields/SelectField";
import CheckboxField from "components/FormFields/CheckboxField";
import Forests from "./Forests";
import { useRefData } from "common/hooks";

export default function BlockFields({ title, form, values, drawnArea }) {
    const refData = useRefData();
    const blockTypeTip = values.type && domain.BlockTypeDescription[values.type];
    const topographyTips = values.pasture && domain.TopographyTips[values.pasture.topography];
    const wetlandConditionTips = values.wetland && domain.WetlandCondition[values.wetland.condition];
    const wetlandTypeTips = values.wetland && domain.WetlandType[values.wetland.type];
    const climateLocationTip = "Only change the block coordinates if you have a specific location for the block climate data.";
    const drawnAreaIsDifferent = drawnArea > 0 && drawnArea !== values.areaInHectares;
    const isNonProductive = values.type && [domain.BlockType.NonProductiveTreesAndScrub, domain.BlockType.NonProductiveHouse, domain.BlockType.NonProductiveWetland].includes(values.type);
    const areaLabel = isNonProductive ? "Area (ha)" : "Effective area (ha)";
    const areaTip = `${isNonProductive ? "" : "The area excludes raceways and fenced-off wetlands or non-productive areas. "}The area should be the sum of all outlines drawn.`;

    const onOverrideClimateToggled = () => {
        if (values.climate) {
            form.change("climate.latitude", undefined);
            form.change("climate.longitude", undefined);
        }
    };

    // Set effective area from drawn area if not set.
    useEffect(() => {
        if (drawnArea && !values.areaInHectares) {
            form.change("areaInHectares", drawnArea);
        }
    }, [form, values, drawnArea]);

    return (
        <>
            <Grid title={title}>
                <GridCell className="u-xl-width1of1">
                    <Field name="id" type="hidden" component="input" />
                    <Field name="climate.latitude" type="hidden" component="input" />
                    <Field name="climate.longitude" type="hidden" component="input" />
                    <Field
                        name="type"
                        label="Block type"
                        required
                        placeholder="Select a block type"
                        tip={
                            blockTypeTip ? (
                                <div>
                                    <b>{blockTypeTip.bold}:</b> <span>{blockTypeTip.text}</span>
                                </div>
                            ) : undefined
                        }
                        options={refData.blockTypes.filter((x) => x.value !== "FodderCrop")}
                        disabled={!values.isNew}
                        component={SelectField}
                    />
                </GridCell>
                <GridCell className="u-xl-width1of1">
                    <Field name="name" label="Block name" required placeholder="Enter the name of the block" maxLength="50" component={TextField} />
                </GridCell>
            </Grid>
            <Grid>
                <GridCell className="u-xl-width1of1">
                    <Field name="areaInHectares" label={areaLabel} required placeholder="0" uom="ha" tip={areaTip} type="number" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                    {drawnAreaIsDifferent && drawnArea < 40000 && (
                        <Alert
                            type="warning"
                            text={
                                <span>
                                    <b>Drawn area:</b> {drawnArea + " ha"}
                                </span>
                            }
                            actionMessage="Use drawn area"
                            onAction={() => form.change("areaInHectares", drawnArea)}
                            actionIcon="IconLink--arrow-plus"
                            actionAlignRight
                            className="u-mb-0"
                        />
                    )}
                    {!drawnArea && (
                        <Alert
                            type="info"
                            text={
                                <span>
                                    <b>TIP:</b> Draw or assign the land parcels that makes up this block
                                </span>
                            }
                            className="u-mb-0"
                        />
                    )}
                    {drawnArea > 40000 && <Alert type="error" text={<span>Drawn area cannot be greater than 40,000 ha, drawn: {drawnArea}</span>} className="u-mb-0" />}
                </GridCell>
                {showDistanceFromCoast(values.type) && (
                    <GridCell className="u-xl-width1of1">
                        <Field name="distanceFromCoastInKilometres" label="Distance from coast (km)" required placeholder="In the direction of the prevailing wind" uom="km" tip="This is the distance from the coast in the direction of the prevailing wind, expressed in kilometres. As you move further from the coast the concentration of sulphur (S) and cations (K, Ca, Mg, Na) in the rainfall decreases. Therefore, the distance from the coast in the direction of the prevailing wind will influence the amount of S, K, Ca, Mg and Na deposited annually in the rainfall. It is important to choose the distance of the prevailing wind as this is the direction from where the majority of the rainfall comes." type="number" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                    </GridCell>
                )}
                {showFencedWetlandFields(values.type) && (
                    <GridCell className="u-xl-width1of1">
                        <Field name="wetland.effectiveArea" label="Wetland area (ha)" required placeholder="Enter the area of the fenced off wetland" uom="ha" type="number" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                    </GridCell>
                )}
            </Grid>
            {showPastureFields(values.type) && (
                <Grid>
                    <GridCell className="u-xl-width1of1">
                        <Field name="pasture.topography" label="Topography" required placeholder="Select the topography that best represents this block" options={refData.slopes} component={SelectField} />
                        {topographyTips && (
                            <Alert
                                type="info"
                                text={
                                    <div>
                                        <b>Description:</b> <span>{topographyTips.text}</span>
                                        <br />
                                        <b>Slope:</b> <span>{topographyTips.slope}</span>
                                        <br />
                                        <b>LRI1 class:</b> <span>{topographyTips.lriClass}</span>
                                    </div>
                                }
                            />
                        )}
                    </GridCell>
                </Grid>
            )}
            {showTreesAndScrubFields(values.type) && (
                <Grid>
                    <GridCell className="u-xl-width1of1">
                        <Field name="bushType" label="Bush type" required placeholder="Select a bush type" options={refData.bushTypes} component={SelectField} />
                    </GridCell>
                </Grid>
            )}
            {showHouseFields(values.type) && (
                <>
                    <Grid>
                        <GridCell className="u-xl-width1of1">
                            <Field name="countOfResidents" label="Number of residents on the property" required placeholder="Enter the number of people living on the property" type="number" format={FormUtils.formatters.formatInt} formatOnBlur component={TextField} />
                        </GridCell>
                        <GridCell className="u-xl-width1of1">
                            <Field name="sewageDisposalMethod" label="Sewage management method" required placeholder="Select a sewage management method" options={refData.sewageDisposalMethods} component={SelectField} />
                        </GridCell>
                    </Grid>
                    <Grid>
                        <GridCell className="u-xl-width1of1">
                            <Field name="cultivatedAreaPercent" label="Cultivated area (%)" placeholder="Enter % of the block that is under cultivation" uom="% of block area" type="number" format={FormUtils.formatters.formatDecimal(1)} formatOnBlur component={TextField} />
                        </GridCell>
                    </Grid>
                </>
            )}
            {showFencedWetlandFields(values.type) && (
                <Grid>
                    <GridCell className="u-xl-width1of1">
                        <Field name="wetland.condition" label="Wetland condition" required placeholder="Select a wetland condition" options={refData.wetlandConditionType.filter((x) => !x.value.startsWith("Class"))} component={SelectField} />
                        {wetlandConditionTips && (
                            <Alert
                                type="info"
                                className="u-mb-0"
                                text={
                                    <div>
                                        <b>{wetlandConditionTips.bold}:</b> <span>{wetlandConditionTips.text}</span>
                                    </div>
                                }
                            />
                        )}
                    </GridCell>
                    <GridCell className="u-xl-width1of1">
                        <Field name="wetland.type" label="Wetland type" required placeholder="Select a wetland type" options={refData.wetlandType} component={SelectField} />
                        {wetlandTypeTips && (
                            <Alert
                                type="info"
                                className="u-mb-0"
                                text={
                                    <div>
                                        <b>Wetness:</b> <span>{wetlandTypeTips.wetness}</span>
                                        <br />
                                        <b>Vegetation:</b> <span>{wetlandTypeTips.vegetation}</span>
                                        <br />
                                        <b>Stock:</b> <span>{wetlandTypeTips.stock}</span>
                                    </div>
                                }
                            />
                        )}
                    </GridCell>
                </Grid>
            )}
            {showClimateLocationFields(values.type) && (
                <Grid>
                    <GridCell>
                        <Field name="overrideClimateLocation" label="Override climate location" onChange={onOverrideClimateToggled} type="checkbox" component={CheckboxField} />
                    </GridCell>
                    {values.overrideClimateLocation && (
                        <>
                            <GridCell className="u-lg-width1of2">
                                <Field name="climateLatitude" label="Latitude" placeholder="Climate location latitude" tip={climateLocationTip} type="number" format={FormUtils.formatters.formatDecimal(13)} formatOnBlur component={TextField} />
                            </GridCell>
                            <GridCell className="u-lg-width1of2">
                                <Field name="climateLongitude" label="Longitude" placeholder="Climate location longitude" tip={climateLocationTip} type="number" format={FormUtils.formatters.formatDecimal(13)} formatOnBlur component={TextField} />
                            </GridCell>
                        </>
                    )}
                </Grid>
            )}
            {showTreesAndScrubFields(values.type) && <Forests form={form} forests={values.forests} />}
        </>
    );
}

export function validateBlockFields(block) {
    const validation = {};

    validation.type = FormUtils.validators.required(block.type);
    validation.name = FormUtils.validators.required(block.name);
    validation.name = validation.name || FormUtils.validators.maxLength(50)(block.name);
    validation.areaInHectares = FormUtils.validators.required(block.areaInHectares);
    validation.areaInHectares = validation.areaInHectares || FormUtils.validators.range(0.1, 40000)(block.areaInHectares);

    if (showDistanceFromCoast(block.type)) {
        validation.distanceFromCoastInKilometres = FormUtils.validators.required(block.distanceFromCoastInKilometres);
        validation.distanceFromCoastInKilometres = validation.distanceFromCoastInKilometres || FormUtils.validators.range(1, 9999)(block.distanceFromCoastInKilometres);
    }

    if (showPastureFields(block.type)) {
        validation.pasture = {};
        validation.pasture.topography = FormUtils.validators.required(block.pasture && block.pasture.topography);
    }

    if (showTreesAndScrubFields(block.type)) {
        validation.bushType = FormUtils.validators.required(block.bushType);
    }

    if (showHouseFields(block.type)) {
        validation.countOfResidents = FormUtils.validators.required(block.countOfResidents);
        validation.countOfResidents = validation.countOfResidents || FormUtils.validators.range(1, 9999)(block.countOfResidents);
        validation.sewageDisposalMethod = FormUtils.validators.required(block.sewageDisposalMethod);
        validation.cultivatedAreaPercent = FormUtils.validators.range(0, 80)(block.cultivatedAreaPercent);
    }

    if (showFencedWetlandFields(block.type)) {
        validation.wetland = {};
        validation.wetland.effectiveArea = FormUtils.validators.required(block.wetland && block.wetland.effectiveArea);
        validation.wetland.effectiveArea = validation.wetland.effectiveArea || FormUtils.validators.range(0.1, block.areaInHectares)(block.wetland.effectiveArea);
        validation.wetland.condition = FormUtils.validators.required(block.wetland && block.wetland.condition);
        validation.wetland.type = FormUtils.validators.required(block.wetland && block.wetland.type);
    }

    if (showClimateLocationFields(block.type) && block.overrideClimateLocation) {
        validation.climateLatitude = FormUtils.validators.required(block.climateLatitude);
        validation.climateLatitude = validation.climateLatitude || FormUtils.validators.range(-48, -34)(block.climateLatitude);
        validation.climateLongitude = FormUtils.validators.required(block.climateLongitude);
        validation.climateLongitude = validation.climateLongitude || FormUtils.validators.range(165, 179)(block.climateLongitude);
    }

    return validation;
}

const showDistanceFromCoast = (blockType) => blockType && blockType !== "NonProductiveWetland" && blockType !== "NonProductiveRiparian";
const showPastureFields = (blockType) => blockType === "ProductivePasture";
const showTreesAndScrubFields = (blockType) => blockType === "NonProductiveTreesAndScrub";
const showHouseFields = (blockType) => blockType === "NonProductiveHouse";
const showFencedWetlandFields = (blockType) => blockType === "NonProductiveWetland";
const showClimateLocationFields = (blockType) => blockType && blockType !== "NonProductiveWetland" && blockType !== "NonProductiveRiparian";
