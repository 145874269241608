import React, { Component } from "react";
import Page from "components/Page";
import PageBody from "components/PageBody";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import ActionBar from "./ActionBar";
import CustomList from "./CustomList";
import { modalInlineClose, modalInlineOpen } from "containers/App/_actions";
import ProductModal from "./ProductModal";
import UserDefinedCropModal from "./UserDefinedCropModal";
import { getCompositions, submitComposition, updateComposition, removeComposition, updateUserDefinedCrops } from "./_actions";
import { v4 as uuidv4 } from "uuid";
import { compose, withState, withActions, withAppContext } from "containers/HOC";
import { withRouter } from "react-router-dom";
import * as utils from "common/utils";
import EmptyCustom from "./EmptyCustom";
import { useConfirm } from "common/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
function Index(props) {
    const confirm = useConfirm();

    return <IndexClassComponent {...props} confirm={confirm} />
}

class IndexClassComponent extends Component {
    state = {};

    componentDidMount() {
        this.props.getCompositions();
    }

    add(t) {
        const product = {
            id: uuidv4(),
            type: t,
            isNew: true,
        };
        if (t === "Effluent") {
            product.effluentUnit = "Percent";
        }
        this.setState({ product });
        this.props.modalInlineOpen();
    }

    addCrop(c) {
        const crop = {
            id: uuidv4(),
            isNew: true,
        };
        this.setState({ crop });
        this.props.modalInlineOpen();
    }

    closeModal() {
        this.setState({ product: undefined });
        this.props.modalInlineClose();
    }

    edit(t, isOrganisation) {
        const product = utils.clone(t);
        product.isNew = false;
        product.isOrganisation = isOrganisation;
        this.setState({ product, isOrganisation, crop: undefined });
        this.props.modalInlineOpen();
    }

    remove(t, isOrganisation) {
        const confirmDelete = (t) => this.props.removeComposition(t);
        this.props.confirm(`Are you sure you want to delete the ${t.name} ${t.type}${isOrganisation ? " for all organisation users" : ""}?`, () => confirmDelete(t));
    }

    editCrop(c) {
        const crop = utils.clone(c);
        crop.isNew = false;
        this.setState({ crop, product: undefined });
        this.props.modalInlineOpen();
    }

    removeCrop(crop) {
        let { crops = [] } = this.props;
        crops = crops.filter((c) => c.id !== crop.id);

        const confirmDelete = (crops) => this.props.updateUserDefinedCrops(crops);
        this.props.confirm(`Are you sure you want to delete ${crop.name}?`, () => confirmDelete(crops));
    }

    submitProduct(p) {
        const { authContext = {} } = this.props;
        p.userId = p.isOrganisation ? undefined : authContext.userId;
        p.accountId = authContext.accountId;
        p.isNew ? this.props.submitComposition(p) : this.props.updateComposition(p);

        this.props.modalInlineClose();
        this.setState({ product: undefined });
    }

    submitCrop(crop) {
        let { crops = [] } = this.props;
        if (!crop.isNew) {
            crops = crops.filter((c) => c.id !== crop.id);
        }
        crops.push(crop);
        this.props.updateUserDefinedCrops(crops);

        this.props.modalInlineClose();
        this.setState({ crop: undefined });
    }

    render() {
        const { product, crop } = this.state;
        const { customCompositions = [], crops = [], authContext = {}, online, refData, settings } = this.props;
        const accountCompositions = customCompositions.filter((c) => !c.userId);
        const myCompositions = customCompositions.filter((c) => c.userId === authContext.userId);
        return (
            <Page>
                {product && <ProductModal submit={(i) => this.submitProduct(i)} product={product} myCompositions={myCompositions} isReadOnly={!product.userId && !authContext.isAdmin && !product.isNew} compositions={customCompositions} cancel={() => this.closeModal()} />}
                {crop && <UserDefinedCropModal submit={(i) => this.submitCrop(i)} crop={crop} crops={crops} cancel={() => this.closeModal()} />}
                <section className="PageHead">
                    <div className="Container">
                        <div className="FarmTitle">
                            <div className="FarmTitle-heading">
                                <span id="page-head-title" className="FarmTitle-name">
                                    Custom setup
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <PageBody>
                    <Tile title="Custom nutrients">
                        <TileBody>
                            <React.Fragment>
                                {this.props.online && <ActionBar add={(t) => this.add(t)} addCrop={() => this.addCrop()} refData={refData} authContext={this.props.authContext} settings={settings} />}
                                {myCompositions.length === 0 && accountCompositions.length === 0 && (!crops || crops.length === 0) ? (
                                    <Tile>
                                        <TileBody>
                                            <EmptyCustom label="You do not have any custom items setup" />
                                        </TileBody>
                                    </Tile>
                                ) : (
                                    <React.Fragment>
                                        <CustomList compositions={myCompositions} crops={crops} edit={(t) => this.edit(t, false)} remove={(t) => this.remove(t, false)} editCrop={(t) => this.editCrop(t, false)} removeCrop={(t) => this.removeCrop(t, false)} refData={refData} online={online} title="My custom setup" emptyLabel="You do not have any personal custom items setup" showDelete={true} />
                                        <CustomList compositions={accountCompositions} edit={(t) => this.edit(t, true)} remove={(t) => this.remove(t, true)} refData={refData} online={online} title="Organisation custom setup" emptyLabel="You do not have any organisation custom items setup" showDelete={authContext.isAdmin} />
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </TileBody>
                    </Tile>
                </PageBody>
            </Page>
        );
    }
}

export default compose(
    withRouter,
    withAppContext,
    withState((state, ownProps) => {
        return {
            ...ownProps,
            customCompositions: state.customNutrients.nutrientCompositions,
            crops: state.customNutrients.crops,
            settings: state.dataset.settings,
        };
    }),
    withActions({ modalInlineClose, modalInlineOpen, getCompositions, submitComposition, updateComposition, removeComposition, updateUserDefinedCrops })
)(Index);
