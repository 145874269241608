import { useAuthContext } from "common/hooks";
import * as icons from "common/icons";
import NotFound from "components/NotFound";
import Page from "components/Page";
import PageBody from "components/PageBody";
import FarmHomePageHead from "./FarmHomePageHead";
import { Panel, PanelBody } from "components/Panel";
import TileBodyMessage from "components/TileBodyMessage";
import ActionLink from "components/ActionLink";
import AnalysisCard from "./AnalysisCard";
import { useCreateFertiliserRecommendationModal } from "./Modals/CreateFertiliserRecommendationModal";

export default function FertiliserRecommendations({ farm }) {
    const [createFertiliserRecommendationModal, openCreateFertiliserRecommendation] = useCreateFertiliserRecommendationModal(farm);

    const { canCreateFertiliserRecommendations } = useAuthContext();
    if (!canCreateFertiliserRecommendations) {
        return <NotFound />
    }

    const referrer = `/app/farm/${farm.id}`;
    const breadcrumb = [{ name: farm.displayName, to: referrer }, { name: "Fertiliser recommendations" }];
    const fertiliserRecommendations = farm?.budgets?.filter(b => b.type === "FertiliserRecommendation") || [];
    const hasFertiliserRecommendations = fertiliserRecommendations.length > 0;
    const title = "Fertiliser recommendations";

    return (
        <>
            <Page>
                <FarmHomePageHead breadcrumb={breadcrumb} />
                <PageBody>
                    <Panel title={title} iconClassName="IconLink--fertiliser" referrer={referrer}>
                        <PanelBody>
                            <div className="ActionsBar ActionsBar--super u-print-none">
                                <div className="ActionsBar-left">
                                    <ul className="ActionsBar-links">
                                        <li>
                                            <ActionLink id="create-fertiliser-recommendation" className="IconLink--arrow-plus" onClick={openCreateFertiliserRecommendation}>
                                                Create fertiliser recommendation
                                            </ActionLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {hasFertiliserRecommendations
                                ? <>
                                    <Panel title={title} midBlue className="u-mb-lg">
                                        <PanelBody grey>
                                            {fertiliserRecommendations.map((analysis) => (
                                                <AnalysisCard key={analysis.id} farm={farm} analysis={analysis} parentTitle={title} />
                                            ))}
                                        </PanelBody>
                                    </Panel>
                                </>
                                : <>
                                    <TileBodyMessage>
                                        <img src={icons.add} alt="Plus" />
                                        <p className="lead">You haven't created any fertiliser recommendations yet</p>
                                        <ActionLink className="IconLink--arrow-plus Button Button--lg u-mt-lg" onClick={openCreateFertiliserRecommendation}>
                                            Create fertiliser recommendation
                                        </ActionLink>
                                    </TileBodyMessage>
                                </>
                            }
                        </PanelBody>
                    </Panel>
                </PageBody>
            </Page>
            {createFertiliserRecommendationModal}
        </>
    )
}
