import "./ResultsWidget.css";
import * as utils from "common/utils";
import { useAnalysisResults } from "containers/BudgetHome";
import ResultsLoading from "./ResultsLoading";

export default function AnalysisResultsWidget({ analysis, perHaOnly, showNCE, noPrint, hideVersion }) {
    const { isStale, data: analysisResults } = useAnalysisResults(analysis);

    const modelVersion = !hideVersion && analysisResults?.modelVersion;
    const nitrogenResults = analysisResults?.nitrogen;
    const phosphorusResults = analysisResults?.phosphorus;
    const ghgResults = analysisResults?.ghg;

    return (
        <div className={`results-widget ${noPrint ? "u-print-none" : ""}`}>
            <div className="results-widget__nutrient results-widget__nutrient--n">
                <div className="results-widget__name">N</div>
                <ResultsLoading id="n-results-waiting" isLoading={isStale}>
                    {nitrogenResults
                        ? <>
                            <div className="results-widget__item">
                                <span className="results-widget__value" id={`${analysis.id}-nLost-ha`}>
                                    {utils.numeric.format(nitrogenResults?.lossPerHectare?.value)}
                                </span>
                                <span className="results-widget__uom">kg/ha</span>
                            </div>
                            {!perHaOnly && (
                                <>
                                    <div className="results-widget__divider">|</div>
                                    <div className="results-widget__item">
                                        <span className="results-widget__value" id={`${analysis.id}-nLost`}>
                                            {utils.numeric.format(nitrogenResults?.totalLoss?.value)}
                                        </span>
                                        <span className="results-widget__uom">kg</span>
                                    </div>
                                </>
                            )}
                        </>
                        : <div className="results-widget__item">
                            <span className="results-widget__value" id={`${analysis.id}-nLost-ha`}>-</span>
                        </div>
                    }
                </ResultsLoading>
            </div>
            <div className="results-widget__nutrient results-widget__nutrient--p">
                <div className="results-widget__name">P</div>
                <ResultsLoading id="p-results-waiting" isLoading={isStale}>
                    {phosphorusResults
                        ? <>
                            <div className="results-widget__item">
                                <span className="results-widget__value" id={`${analysis.id}-pLost-ha`}>
                                    {utils.numeric.format(phosphorusResults?.lossPerHectare?.value, 1)}
                                </span>
                                <span className="results-widget__uom">kg/ha</span>
                            </div>
                            {!perHaOnly && (
                                <>
                                    <div className="results-widget__divider">|</div>
                                    <div className="results-widget__item">
                                        <span className="results-widget__value" id={`${analysis.id}-pLost`}>
                                            {utils.numeric.format(phosphorusResults?.totalLoss?.value)}
                                        </span>
                                        <span className="results-widget__uom">kg</span>
                                    </div>
                                </>
                            )}
                        </>
                        : <div className="results-widget__item">
                            <span className="results-widget__value" id={`${analysis.id}-pLost-ha`}>-</span>
                        </div>
                    }
                </ResultsLoading>
            </div>
            <div className="results-widget__nutrient results-widget__nutrient--ghg">
                <div className="results-widget__name">GHG</div>
                <ResultsLoading id="ghg-results-waiting" isLoading={isStale}>
                    {ghgResults
                        ? <>
                            <div className="results-widget__item">
                                <span className="results-widget__value" id={`${analysis.id}-ghg-ha`}>
                                    {utils.numeric.format(ghgResults?.emissions?.emissionsPerHectare?.value) || "-"}
                                </span>
                                <span className="results-widget__uom">kg/ha</span>
                            </div>
                            {!perHaOnly && (
                                <>
                                    <div className="results-widget__divider">|</div>
                                    <div className="results-widget__item">
                                        <span className="results-widget__value" id={`${analysis.id}-ghg`}>
                                            {utils.numeric.format(ghgResults?.emissions?.totalEmissions?.value, 1)}
                                        </span>
                                        <span className="results-widget__uom">t</span>
                                    </div>
                                </>
                            )}
                        </>
                        : <div className="results-widget__item">
                            <span className="results-widget__value" id={`${analysis.id}-ghg-ha`}>-</span>
                        </div>
                    }
                </ResultsLoading>
            </div>
            {!isStale && <>
                {showNCE && (
                    <div className="NCEIndicator" data-complete="75" style={{ marginLeft: 0, paddingLeft: 0 }}>
                        <span className="">NCE: </span>
                        <div className="is-neutral">
                            <svg className="NCEIndicator-path" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                                <circle cx="17.5" cy="17.5" r="16" fill="none" stroke="#cccccc" strokeWidth="3" strokeMiterlimit="0" />
                            </svg>
                            <span id={`${analysis.id}-nce`}>
                                {utils.numeric.format(nitrogenResults?.conversionEfficiency?.value)}
                            </span>
                        </div>
                    </div>
                )}
                {modelVersion && (
                    <div className="results-widget__version" id={`${analysis.id}-ver`}>
                        v{modelVersion}
                    </div>
                )}
            </>}
        </div>
    )
}
