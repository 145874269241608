import { useOnline } from "common/hooks";
import ActionLink from "components/ActionLink";
import { useScenarioToolModal } from "./ScenarioToolModal";

export default function ScenarioToolLink({ farm, analysis }) {
    const online = useOnline();
    const [scenarioToolModal, openScenarioToolModal] = useScenarioToolModal(farm, analysis);

    return (
        <>
            {online && (
                <ActionLink id="scenario-tool" className="IconLink--wand u-font-green" onClick={openScenarioToolModal}>
                    Scenario tool
                </ActionLink>
            )}
            {scenarioToolModal}
        </>
    )
}
