import React from "react";

const ResultsComparison = ({ analysisId, results = {} }) => {
    return (
        <div className="Table Table-Compressed">
            <table>
                <thead>
                    <tr>
                        <th className="h3" data-width="40">
                            Model results
                        </th>
                        <th className="u-textCenter" data-width="20">
                            Before
                        </th>
                        <th className="u-textCenter" data-width="20">
                            After
                        </th>
                        <th className="u-textRight" data-width="20">
                            Change
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ResultsComparisonRow analysisId={analysisId} name="nLossTotal" result={results.nLossTotal} label="Total N loss (kg)" type="nloss" />
                    <ResultsComparisonRow analysisId={analysisId} name="nLossPerHa" result={results.nLossPerHa} label="N loss (kg/ha)" type="nloss" />
                    <ResultsComparisonRow analysisId={analysisId} name="pLossTotal" result={results.pLossTotal} label="Total P loss (kg)" type="ploss" />
                    <ResultsComparisonRow analysisId={analysisId} name="pLossPerHa" result={results.pLossPerHa} label="P loss (kg/ha)" type="ploss" />
                    <ResultsComparisonRow analysisId={analysisId} name="ghg" result={results.ghg} label="GHG (kg CO2e/ha/yr)" type="ghg" />
                </tbody>
            </table>
        </div>
    );
};

const ResultsComparisonRow = ({ analysisId, name, result, label, type }) => {
    const diff = (result && result.to - result.from) || 0;
    const increased = diff > 0;
    const decreased = diff < 0;

    const from = result && result.from >= 0 ? <span className={`NutrientsLost-indicator ${type}`}>{result.from.toLocaleString()}</span> : "-";
    const to = result && result.to >= 0 ? <span className={`NutrientsLost-indicator ${type}`}>{result.to.toLocaleString()}</span> : "-";
    const change = increased ? `+${diff.toLocaleString()}` : decreased ? diff.toLocaleString() : "-";
    const changeTextClassName = increased ? "u-textError u-textBold" : decreased ? "u-textSuccess u-textBold" : "";
    const changeArrowClassName = increased ? "IconLink--arrow-up-right IconLink--compressed" : decreased ? "IconLink--arrow-down-right IconLink--compressed" : "";

    return (
        <tr>
            <td className="">{label}</td>
            <td id={`${analysisId}-${name}-before`} className="u-textCenter">
                {from}
            </td>
            <td id={`${analysisId}-${name}-after`} className="u-textCenter">
                {to}
            </td>
            <td className={changeTextClassName}>
                <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                    <span className={changeArrowClassName}></span>
                    <span>{change}</span>
                </div>
            </td>
        </tr>
    );
};

export default ResultsComparison;
