import React from "react";
import { compose, withAppContext } from "containers/HOC";
import moment from "moment";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";

const FarmList = ({ farms, refData = {}, tokenId, selectedFarmIds, setSelectedFarmIds, message, pageSize, showPaidBy, showFarmLink }) => {
    const data = farms.map((farm) => {
        const isMyFarm = farm.isMyFarm;
        const hasFarmRole = farm.role;
        const isUnpaid = farm.expired || !farm.expirationDate;
        const daysTillExpired = isUnpaid || !farm.expirationDate ? 0 : moment.utc(farm.expirationDate).diff(moment.utc(), "days");
        const canExtend = daysTillExpired > 0 && daysTillExpired < 182;
        const newExpiryDate = isUnpaid ? moment().add(1, "years") : moment(farm.expirationDate).add(1, "years");

        return {
            id: farm.id,
            name: farm.name,
            address: farm.address,
            role: farm.role,
            createdOn: farm.createdOn,
            expired: farm.expired,
            expirationDate: farm.expirationDate,
            ownershipStatus: farm.ownershipStatus,
            canPay: utils.canMakePayment(farm),
            canAccess: utils.canReadFarm(farm),
            access: hasFarmRole ? utils.farmRolesToText(farm, refData.farmRoles) : farm.accessRequest ? farm.accessRequest.status : "-",
            accessRequest: farm.accessRequest,
            farmClaims: farm.farmClaims,
            studentGroup: farm.studentGroup,
            paidBy: farm.paidBy,
            isMyFarm,
            isUnpaid,
            daysTillExpired,
            canExtend,
            newExpiryDate,
        };
    });

    const toggleSelectAll = (e) => {
        const selectedIds = [];
        if (selectedFarmIds.length < farms.length) {
            farms.forEach((farm) => selectedIds.push(farm.id));
        }
        setSelectedFarmIds(selectedIds);
    };

    const toggleSelect = (farmId) => (e) => {
        let selectedIds = [];
        if (selectedFarmIds.includes(farmId)) {
            selectedIds = selectedFarmIds.filter((id) => id !== farmId);
        } else {
            selectedIds = [...selectedFarmIds, farmId];
        }
        setSelectedFarmIds(selectedIds);
    };

    return (
        <>
            {data.length > 0 && (
                <>
                    <div className="h3 u-pt-sm u-pb-sm">{message}</div>
                    <SortableTable data={data} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }} pageSize={pageSize}>
                        {({ data, sortCriteria, handleSort }) => {
                            const allSelected = farms.map((f) => f.id).every((id) => selectedFarmIds.includes(id));
                            return (
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="th--shrink">
                                                <label className="Checkbox" htmlFor={`select-all-${tokenId}`}>
                                                    <input className="Checkbox-input" type="checkbox" id={`select-all-${tokenId}`} checked={allSelected} onChange={toggleSelectAll} />
                                                    <span className={`Checkbox-label`}></span>
                                                </label>
                                            </th>
                                            <SortableTableHeader label="Farm" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                            {showPaidBy && <SortableTableHeader label="Paid by" fieldName="paidBy" sortCriteria={sortCriteria} handleSort={handleSort} />}
                                            {false && <SortableTableHeader label="Address" fieldName="address" sortCriteria={sortCriteria} handleSort={handleSort} />}
                                            <SortableTableHeader label="Subscription expiry" fieldName="expirationDate" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((farm) => {
                                            return (
                                                <tr key={farm.id} className={`hover ${farm.isUnpaid ? "" : ""}`}>
                                                    <td valign="top">
                                                        <label className="Checkbox" htmlFor={`select-farm-for-payment-${farm.id}`}>
                                                            <input className="Checkbox-input" type="checkbox" id={`select-farm-for-payment-${farm.id}`} checked={selectedFarmIds.includes(farm.id)} onChange={toggleSelect(farm.id)} />
                                                            <span className="Checkbox-label u-textError u-textBold"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {farm.canAccess || showFarmLink ? (
                                                                <Link to={`/app/farm/${farm.id}`} id={`farm-view-${farm.id}`} className="u-textBold">
                                                                    {farm.name}
                                                                </Link>
                                                            ) : (
                                                                <b>{farm.name}</b>
                                                            )}
                                                        </div>
                                                        <div>{farm.address}</div>
                                                    </td>
                                                    {showPaidBy && <td>{farm.paidBy}</td>}
                                                    {false && <td>{farm.address}</td>}
                                                    <td>
                                                        {!farm.isUnpaid && (
                                                            <div className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
                                                                <div className="u-flex u-flexAlignItemsCenter">
                                                                    <span className="u-ml-xs">{moment(farm.expirationDate).format("DD MMM YYYY")}</span>
                                                                </div>
                                                                <span className="u-ml-auto">({farm.daysTillExpired} days)</span>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            );
                        }}
                    </SortableTable>
                </>
            )}
        </>
    );
};

export default compose(withAppContext)(FarmList);
