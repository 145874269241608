import ActionLink from "components/ActionLink";
import { useRequestFarmAccessModal } from "./RequestFarmAccessModal";

export default function RequestFarmAccessActionLink({ farm, children = "Request access", className = "IconLink--arrow-plus" }) {
    const [requestFarmAccessModal, openRequestFarmAccessModal] = useRequestFarmAccessModal(farm);
    return (
        <>
            <ActionLink id={`request-farm-access-${farm?.id}`} className={className} onClick={openRequestFarmAccessModal}>
                {children}
            </ActionLink>
            {requestFarmAccessModal}
        </>
    )
}
