import { Grid, GridCell } from "components/Grid";
import FileSummary from "./FileSummary";
import { ComparisonTable } from "components/AnalysisComparisons/ComparisonUtils";
import { Actions } from "components/PlanningReport/Actions";
import { Comments } from "components/PlanningReport/Comments";
import { NutrientsSummary } from "components/PlanningReport/Nutrients";
import { GhgSummary } from "components/PlanningReport/Ghg";
import { NutrientBudgetMovementTotals } from "components/NutrientBudget";
import { FarmTrends } from "components/PlanningReport/FarmTrends";
import { PhysicalCharacteristics } from "components/PlanningReport/ClimateSoils";
import { AnimalsSummary } from "components/PlanningReport/Animals";
import { CropsSummary } from "components/PlanningReport/Crops";
import { FeedSummary } from "components/PlanningReport/Feed";
import { FertiliserSummary } from "components/PlanningReport/Fertiliser";
import { IrrigationSummary } from "components/PlanningReport/Irrigation";

export default function Summary({ farm, before, after, showFileComparison, comparison }) {
    const someChangeInCategories = comparison && comparison.changeCategories && comparison.changeCategories.some((cc) => cc.changes.length > 0);

    return (
        <div>
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <Grid>
                {someChangeInCategories ? (
                    <>
                        <GridCell className="u-xl-width1of2">
                            <ComparisonTable comparison={comparison} />
                        </GridCell>
                        <GridCell className="u-xl-width1of2 u-print-none">
                            <Grid>
                                <GridCell className="u-xl-width1of1 u-print-none">
                                    <Actions before={before} after={after} />
                                </GridCell>
                                <GridCell className="u-xl-width1of1 u-print-none">
                                    <Comments before={before} after={after} />
                                </GridCell>
                                <GridCell className="u-xl-width1of1 u-print-none">
                                    <NutrientsSummary before={before} after={after} />
                                </GridCell>
                                <GridCell className="u-xl-width1of1 u-print-none">
                                    <GhgSummary before={before} after={after} />
                                </GridCell>
                            </Grid>
                        </GridCell>
                    </>
                ) : (
                    <>
                        <GridCell className="u-xl-width1of1 u-print-none">
                            <Actions before={before} after={after} />
                        </GridCell>
                        <GridCell className="u-xl-width1of1 u-print-none">
                            <Comments before={before} after={after} />
                        </GridCell>
                        <GridCell className="u-xl-width1of2 u-print-none">
                            <NutrientsSummary before={before} after={after} />
                        </GridCell>
                        <GridCell className="u-xl-width1of2 u-print-none">
                            <GhgSummary before={before} after={after} />
                        </GridCell>
                    </>
                )}
            </Grid>
            <Grid>
                <GridCell className="u-xl-width1of1 u-print-none">
                    <div className="ReportSection">
                        <NutrientBudgetMovementTotals before={before} after={after} nutrient={{ value: "nitrogen", value1: "N", text: "Nitrogen", display: "N" }} summaryOnly />
                        <NutrientBudgetMovementTotals before={before} after={after} nutrient={{ value: "phosphorus", value1: "P", text: "Phosphorus", display: "P" }} summaryOnly />
                    </div>
                </GridCell>
            </Grid>
            {false && (
                <Grid>
                    <GridCell className="u-xl-width1of1 u-print-none">
                        <div className="ReportSection">
                            <FarmTrends farm={farm} />
                        </div>
                    </GridCell>
                </Grid>
            )}
            <Grid>
                <GridCell className="u-xl-width1of2 u-print-none">
                    <PhysicalCharacteristics before={before} after={after} />
                </GridCell>
                <GridCell className="u-xl-width1of2 u-print-none">
                    <AnimalsSummary before={before} after={after} farm={farm} />
                </GridCell>
            </Grid>

            <Grid>
                <GridCell className="u-xl-width1of2 u-print-none">
                    <CropsSummary before={before} after={after} />
                </GridCell>
                <GridCell className="u-xl-width1of2 u-print-none">
                    <FeedSummary before={before} after={after} />
                </GridCell>
            </Grid>
            <Grid>
                <GridCell className="u-xl-width1of2 u-print-none">
                    <FertiliserSummary before={before} after={after} />
                </GridCell>
                <GridCell className="u-xl-width1of2 u-print-none">
                    <IrrigationSummary before={before} after={after} />
                </GridCell>
            </Grid>
        </div>
    )
}
