import { useState, useEffect, useCallback } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { v4 as uuidv4 } from "uuid";

export const useGoogleApi = (libraries = ["drawing", "places", "geometry", "visualization"]) => {
    const [google, setGoogle] = useState(window.google && window.google.maps ? window.google : undefined);

    useEffect(() => {
        let isMounted = true;
        const loadGoogleApi = async () => {
            const loader = new Loader({
                apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
                libraries,
            });
            await loader.load();
            if (isMounted) {
                setGoogle(window.google);
            }
        };

        if (!google) {
            loadGoogleApi();
        }

        return () => (isMounted = false);
    }, [google, libraries]);

    return google;
};

export const useGoogleMap = (options = {}) => {
    const google = useGoogleApi();
    const [state, setState] = useState({ mapId: uuidv4() });

    const setMapRef = useCallback((mapRef) => {
        setState((prevState) => ({ ...prevState, mapRef }));
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (google && state.mapRef && !state.map) {
            const mapOptions = {
                center: options.center || { lat: -41.320632, lng: 174.371767 },
                zoom: options.zoom || 6,
                mapTypeControl: options.mapTypeControl !== undefined ? options.mapTypeControl : true,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP,
                },
                mapTypeId: google.maps.MapTypeId.HYBRID,
                fullscreenControl: false,
                streetViewControl: false,
                gestureHandling: options.gestureHandling || "auto",
            };

            const map = new google.maps.Map(state.mapRef, mapOptions);

            if (isMounted) {
                setState((prevState) => ({ ...prevState, map }));
            }
        }
        return () => (isMounted = false);
    }, [google, state, options]);

    return { google, setMapRef, ...state };
};
