import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import { Grid, GridCell } from "components/Grid";
import RadioGroupPack from "components/RadioGroupPack";
import InputPack from "components/InputPack";

export default function OutdoorPigNumbersUnmatedGilts({ form, outdoorPigs }) {
    const numbers = outdoorPigs.numbers || {};
    const unmatedGilts = numbers.unmatedGilts || {};
    const showEnterByNumberOnHand = !unmatedGilts.enterBy || Number(unmatedGilts.enterBy) === ENTER_BY_OPTIONS[0].value;
    const showEnterByDaysSpentGrowing = Number(unmatedGilts.enterBy) === ENTER_BY_OPTIONS[1].value;

    const numbersOnHand = getNumberOnHand(outdoorPigs);
    const daysSpentGrowing = getDaysSpentGrowing(outdoorPigs);

    return (
        <Grid title="Unmated gilts (replacements)">
            <GridCell>
                <Field name="numbers.unmatedGilts.enterBy" options={ENTER_BY_OPTIONS} value={unmatedGilts.enterBy ? Number(unmatedGilts.enterBy) : ENTER_BY_OPTIONS[0].value} onChange={onEnterByChanged(form)} inline type="radio" component={RadioGroupPack} />
            </GridCell>
            {showEnterByNumberOnHand && (
                <>
                    <GridCell className="u-width1of4">
                        <Field name="numbers.unmatedGilts.numbersOnHand" label="Number on hand" placeholder="0" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                    </GridCell>
                    <GridCell className="u-width1of4">
                        <div className="Field">
                            <label className="Field-label">Days spent growing gilts</label>
                            <div className="Input_wrapper">
                                <div className="Input Input--block Input--disabled">
                                    <div style={{ position: "relative", top: "-4.5px" }}>{daysSpentGrowing}</div>
                                </div>
                                <small className="Input-helper">days</small>
                            </div>
                        </div>
                    </GridCell>
                </>
            )}
            {showEnterByDaysSpentGrowing && (
                <>
                    <GridCell className="u-width1of4">
                        <div className="Field">
                            <label className="Field-label">Number on hand</label>
                            <div className="Input_wrapper">
                                <div className="Input Input--block Input--disabled">
                                    <div style={{ position: "relative", top: "-4.5px" }}>{numbersOnHand}</div>
                                </div>
                            </div>
                        </div>
                    </GridCell>
                    <GridCell className="u-width1of4">
                        <Field name="numbers.unmatedGilts.daysSpentGrowing" label="Days spent growing gilts" uom="days" placeholder="0" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                    </GridCell>
                </>
            )}
        </Grid>
    );
};

const ENTER_BY_OPTIONS = [
    { value: 0, text: "Enter by number on hand" },
    { value: 1, text: "Enter by days spent growing gilts" },
];

export const validateUnmatedGilts = (unmatedGilts) => {
    const errors = {};

    const enterByNumberOnHand = !unmatedGilts.enterBy || Number(unmatedGilts.enterBy) === ENTER_BY_OPTIONS[0].value;
    const enterByDaysSpentGrowing = Number(unmatedGilts.enterBy) === ENTER_BY_OPTIONS[1].value;

    if (enterByNumberOnHand) {
        errors.numbersOnHand = FormUtils.validators.required(unmatedGilts.numbersOnHand);
        errors.numbersOnHand = errors.numbersOnHand || FormUtils.validators.max(99999)(unmatedGilts.numbersOnHand);
    } else if (enterByDaysSpentGrowing) {
        errors.daysSpentGrowing = FormUtils.validators.required(unmatedGilts.daysSpentGrowing);
        errors.daysSpentGrowing = errors.daysSpentGrowing || FormUtils.validators.max(85)(unmatedGilts.daysSpentGrowing);
    }

    return errors;
}

const onEnterByChanged = (form) => (e) => {
    form.mutators.setProperty("numbers.unmatedGilts.numbersOnHand", 0);
    form.mutators.setProperty("numbers.unmatedGilts.daysSpentGrowing", 0);
}

const getNumberOnHand = (outdoorPigs) => {
    const numbers = outdoorPigs.numbers || {};
    const sows = numbers.sows || {};
    const { numberSowsMatedGilts = 0, replacementRate = 0 } = sows;
    const replacementRatio = (numberSowsMatedGilts * replacementRate) / 100;

    const unmatedGilts = numbers.unmatedGilts || {};
    const { daysSpentGrowing = 0 } = unmatedGilts;
    const result = FormUtils.formatters.formatInt((replacementRatio * daysSpentGrowing) / 365) || 0;
    return result;
}

const getDaysSpentGrowing = (outdoorPigs) => {
    const numbers = outdoorPigs.numbers || {};

    const sows = numbers.sows || {};
    const { numberSowsMatedGilts = 0, replacementRate = 0 } = sows;
    const replacementRatio = (numberSowsMatedGilts * replacementRate) / 100;

    const unmatedGilts = numbers.unmatedGilts || {};
    const { numbersOnHand = 0 } = unmatedGilts;
    const result = FormUtils.formatters.formatInt((numbersOnHand * 365) / replacementRatio) || 0;
    return result;
}
