import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import * as icons from "common/icons";
import * as cu from "containers/BudgetHome/Crops/_utils";
import numeral from "numeral";
import { Grid, GridCell } from "components/Grid";
import { SelectField, TextField } from "components/FormFields";
import Alert from "components/Alert";
import Watermark from "components/Watermark";
import { getBlocksRotatedThru, getFodderRotations } from "common/viewModels";
import FeatureTracker, { FEATURES } from "components/FeatureTracker/FeatureTracker";
import { helpText } from "components/Help";
import BlockMap from "./BlockMap";
import { BlockResultsWidget } from "components/ResultsWidgets";
import { useBlockResults, useBlockSummary } from "containers/BudgetHome";

export default function BlockDescription({ farm, analysis, block, onBlockChange }) {
    const { data: blockInputs } = useBlockSummary(analysis, block);
    const { data: blockResults } = useBlockResults(analysis, block);
    const refData = useRefData();

    const blockDrawn = (analysis.features || []).some((f) => f.properties.blockId === block.id);
    const blockList = analysis.blocks
        .sort((a, b) => (b.type === a.type ? (b.name < a.name ? 1 : -1) : b.type > a.type ? 1 : -1))
        .map((b, i) => {
            return { value: b.id, text: b.name };
        });
    const results = block.currentResults || {};
    const fodderRotations = getFodderRotations(block, analysis)?.map(r => r.name)?.join(", ");
    const blocksRotatedThru = getBlocksRotatedThru(block, analysis)?.map(r => r.name)?.join(", ");

    var crops = [];

    if (block.pasture && block.pasture.pastureCategory) {
        crops[0] = { name: utils.valueToText(refData.pastureTypes, block.pasture.pastureCategory), icon: icons.pasture };
    } else if (block.fruit) {
        const yieldData = (refData && refData.fruitTypes && refData.fruitTypes[block.fruit.cropType]) || undefined;
        crops[0] = { name: block.fruit.cropType, icon: utils.getFruitIcon(block.fruit.cropType), yield: block.fruit.productYield, units: yieldData ? " " + yieldData.unitsDescription : "" };
    } else if (block.crops && block.crops.length > 0) {
        const area = block.areaInHectares ? (block.areaInHectares * block.cropBlock.cultivatedAreaPercent) / 100 : block.rotationArea;

        const { cropCategories = [] } = refData;

        crops = block.crops.map((crop) => {
            const selectedCategory = cropCategories.find((c) => c.value === crop.category);
            const cropTypes = selectedCategory ? selectedCategory.children : [];
            const selectedCropType = cropTypes.find((t) => t.value === crop.cropId) || {};
            const { yieldUnits = "T DM/yr" } = selectedCropType;
            const annualUnits = yieldUnits.replace("T/ha", "T");
            return { icon: utils.getCropIcon(crop), name: crop.name ? crop.name : cu.cropSubHeading(crop, refData), area: area, yield: crop.productYield ? crop.productYield * area : undefined, units: annualUnits };
        });
    }

    const supplementsProducedByWeightDM = analysis.feedSupplements.filter((f) => f.type === "Harvested" && f.amountType === "Weight" && f.isDryWeight).reduce((sum, f) => (sum += f.sources.filter((s) => s.blockId === block.id).reduce((amount, s) => (amount += s.amount), 0)), 0);
    const supplementsProducedByWeight = analysis.feedSupplements.filter((f) => f.type === "Harvested" && f.amountType === "Weight" && !f.isDryWeight).reduce((sum, f) => (sum += f.sources.filter((s) => s.blockId === block.id).reduce((amount, s) => (amount += s.amount), 0)), 0);
    const supplementsProducedByVolume = analysis.feedSupplements.filter((f) => f.type === "Harvested" && f.amountType === "Volume").reduce((sum, f) => (sum += f.sources.filter((s) => s.blockId === block.id).reduce((amount, s) => (amount += s.amount), 0)), 0);
    const supplementsProducedByBales = analysis.feedSupplements.filter((f) => f.type === "Harvested" && f.amountType === "Bales").reduce((sum, f) => (sum += f.sources.filter((s) => s.blockId === block.id).reduce((amount, s) => (amount += s.amount), 0)), 0);
    const supplementsProducedByCuts = analysis.feedSupplements.filter((f) => f.type === "Harvested" && f.amountType === "Cuts").reduce((sum, f) => (sum += f.sources.filter((s) => s.blockId === block.id).reduce((amount, s) => (amount += s.amount), 0)), 0);

    let nutrients = [];

    nutrients[0] = block.type !== "ProductiveOutdoorPigs" && results.maintenanceNutrientResults && { label: "Maintenance", nutrients: results.maintenanceNutrientResults.fertiliserNutrients };
    nutrients[1] = results.nutrientBudget && results.nutrientBudget.nutrientCategories && results.nutrientBudget.nutrientCategories.Fertiliser && results.nutrientBudget.nutrientCategories.Fertiliser.total && { label: "Fertiliser applied", nutrients: results.nutrientBudget.nutrientCategories.Fertiliser.total.nutrients };

    nutrients[2] = results.nutrientBudget && results.nutrientBudget.nutrientCategories && results.nutrientBudget.nutrientCategories.Irrigation && results.nutrientBudget.nutrientCategories.Irrigation.total && { label: "Irrigation", nutrients: results.nutrientBudget.nutrientCategories.Irrigation.total.nutrients };

    nutrients[3] = results.nutrientBudget && results.nutrientBudget.nutrientCategories && results.nutrientBudget.nutrientCategories.Effluent && results.nutrientBudget.nutrientCategories.Effluent.total && { label: "Effluent", nutrients: results.nutrientBudget.nutrientCategories.Effluent.total.nutrients };

    const showNutrients = nutrients.find((n) => n && n.nutrients && Object.keys(n.nutrients).length > 0);

    return (
        <>
            <Alert className="u-print-none" type="info" text="View block details by clicking on the block on the map or selecting a block from the list. You can print this page by selecting print under the browser menu. For PDF, change the destination to PDF. " />
            <FeatureTracker feature={FEATURES.BLOCK_DETAILS} farm={farm} />
            <Watermark />

            <Grid className="u-mb-md">
                {blockDrawn && (
                    <GridCell className="u-xl-width1of2 u-print-none">
                        <div style={{ display: "flex", height: "600px" }}>
                            <BlockMap farm={farm} analysis={analysis} blockId={block.id} onClick={onBlockChange} />
                        </div>
                    </GridCell>
                )}
                <GridCell className={`u-mb-lg ${blockDrawn ? "u-xl-width1of2" : "u-xl-width1of1"}`}>
                    <div className="Field-group u-mt-sm">
                        <SelectField input={{ name: "block_selection", value: block.id }} label="Select a block" value={block.id} options={blockList} onChange={onBlockChange} dataWidth="50" />
                        <BlockResultsWidget analysis={analysis} block={block} />
                    </div>
                    <div className="Field-group">
                        <TextField input={{ id: "type", value: utils.valueToText(refData.blockTypes, block.type) }} label="Type" readOnly />
                        {block.areaInHectares && <TextField input={{ id: "area", value: block.areaInHectares.toLocaleString() }} label="Area" uom="ha" readOnly />}
                        {block.rotationArea && <TextField input={{ id: "area", value: block.rotationArea.toLocaleString() }} label="Rotation area" uom="ha" readOnly />}
                        {blockInputs?.climate && <>
                            <TextField input={{ id: "temperature", value: utils.numeric.format(blockInputs.climate.averageTemperature.value, 1) }} label="Average temperature" uom={blockInputs.climate.averageTemperature.unit.notation} readOnly />
                            <TextField input={{ id: "rainfall", value: utils.numeric.format(blockInputs.climate.annualRainfall.value) }} label="Annual rainfall" uom={blockInputs.climate.annualRainfall.unit.notation} readOnly />
                            <TextField input={{ id: "pet", value: utils.numeric.format(blockInputs.climate.annualPotentialEvapotranspiration.value) }} label="Annual PET" uom={blockInputs.climate.annualPotentialEvapotranspiration.unit.notation} readOnly />
                        </>}
                    </div>
                    {blockResults?.animals && (
                        <div className="Field-group">
                            {blockResults.animals.pastureGrowth && <TextField input={{ id: "growth", value: utils.numeric.format(blockResults.animals.pastureGrowth.value) }} label="Pasture growth" uom={blockResults.animals.pastureGrowth.unit.notation} tooltip={helpText.pastureGrowth} readOnly />}
                            {blockResults.animals.pastureUtilisation && <TextField input={{ id: "utilisation", value: utils.numeric.format(blockResults.animals.pastureUtilisation.value) }} label="Utilisation" uom={blockResults.animals.pastureUtilisation.unit.notation} readOnly />}
                            {blockResults.animals.pastureIntake && <TextField input={{ id: "intake", value: utils.numeric.format(blockResults.animals.pastureIntake.value) }} label="Pasture intake" uom={blockResults.animals.pastureIntake.unit.notation} tooltip={helpText.pastureIntake} readOnly />}
                            {blockResults.animals.supplementsRemoved && <TextField input={{ id: "removed", value: utils.numeric.format(blockResults.animals.supplementsRemoved.value) }} label="Removed" uom={blockResults.animals.supplementsRemoved.unit.notation} readOnly />}
                        </div>
                    )}
                    {(supplementsProducedByWeightDM > 0 || supplementsProducedByWeight > 0 || supplementsProducedByVolume > 0 || supplementsProducedByBales > 0 || supplementsProducedByCuts > 0) && (
                        <div className="Field-group">
                            {supplementsProducedByWeightDM > 0 && <TextField input={{ id: "supplements", value: numeral(supplementsProducedByWeightDM).format("0.0").toLocaleString() }} label="Harvested" uom="t DM" readOnly />}
                            {supplementsProducedByWeight > 0 && <TextField input={{ id: "supplements", value: numeral(supplementsProducedByWeight).format("0.0").toLocaleString() }} label="Harvested" uom="t" readOnly />}
                            {supplementsProducedByVolume > 0 && <TextField input={{ id: "supplements", value: numeral(supplementsProducedByVolume).format("0.0").toLocaleString() }} label="Harvested" uom="m3" readOnly />}
                            {supplementsProducedByBales > 0 && <TextField input={{ id: "supplements", value: numeral(supplementsProducedByBales).format("0").toLocaleString() }} label="Harvested" uom="bales" readOnly />}
                            {supplementsProducedByCuts > 0 && <TextField input={{ id: "supplements", value: numeral(supplementsProducedByCuts).format("0").toLocaleString() }} label="Harvested" uom="cuts" readOnly />}
                        </div>
                    )}
                    {fodderRotations && <TextField label="Fodder crop rotations" input={{ value: fodderRotations }} readOnly />}
                    {blocksRotatedThru && <TextField label="Rotates through" input={{ value: blocksRotatedThru }} readOnly />}
                    <div className="Table u-mt-lg">
                        <table className="Calendar">
                            <tbody>
                                <tr className="Calendar-slots">
                                    {blockResults?.animals?.pastureRSU?.byEnterprise &&
                                        blockResults.animals.pastureRSU.byEnterprise.filter((ent) => ent.total.value > 0).map((ent) => {
                                            // Only showing that are actually on this block (e.g. total RSU > 0)
                                            const enterprise = analysis.enterprises.find((e) => e.id === ent.id);
                                            const icon = utils.getAnimalIcon(enterprise.type);
                                            return (
                                                <td key={"pasture-rsu-" + ent.id} className="Calendar-slot">
                                                    <img id={`enterpriseicon.${enterprise.id}`} src={icon} className="Calendar-icon u-p-xxs" width="50" height="50" alt={enterprise.name} />
                                                    <span id={`enterprisetext.${enterprise.id}`} className="Calendar-icon-text">
                                                        {utils.numeric.format(ent.total.value, 2)} {ent.total.unit.notation}
                                                    </span>
                                                </td>
                                            );
                                        })}
                                    {crops.map((c, i) => (
                                        <td key={"crop-" + i} className="Calendar-slot">
                                            <img id={`cropicon.${c.name}`} src={c.icon} className="Calendar-icon u-p-xxs" width="50" height="50" alt="" />
                                            {!c.yield && (
                                                <span id={`cropname.${c.name}`} className="Calendar-icon-text">
                                                    {c.name}
                                                </span>
                                            )}
                                            {c.yield && (
                                                <span id={`cropname.${c.name}`} className="Calendar-icon-text">
                                                    {c.name + " - " + numeral(c.yield).format("0") + " " + c.units}
                                                </span>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {showNutrients && (
                        <>
                            <div className="Table u-mt-md Table-Compressed">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Applications (kg/ha)</th>
                                            <th className="u-textCenter">N</th>
                                            <th className="u-textCenter">P</th>
                                            <th className="u-textCenter">K</th>
                                            <th className="u-textCenter">S</th>
                                            <th className="u-textCenter">Ca</th>
                                            <th className="u-textCenter">Mg</th>
                                            <th className="u-textCenter">Na</th>
                                            <th className="u-textCenter">
                                                Lime <sup>1</sup>
                                            </th>
                                            <th className="u-textCenter">
                                                H+ <sup>2</sup>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {nutrients.map(
                                            (n, i) =>
                                                n &&
                                                n.nutrients &&
                                                Object.keys(n.nutrients).length > 0 && (
                                                    <tr key={"nutrient-" + i}>
                                                        <td>{n.label}</td>
                                                        <td className="u-textRight">{n.label === "Maintenance" ? "NA" : n.nutrients.N}</td>
                                                        <td className="u-textRight">{n.nutrients.P}</td>
                                                        <td className="u-textRight">{n.nutrients.K}</td>
                                                        <td className="u-textRight">{n.nutrients.S}</td>
                                                        <td className="u-textRight">{n.nutrients.Ca}</td>
                                                        <td className="u-textRight">{n.nutrients.Mg}</td>
                                                        <td className="u-textRight">{n.nutrients.Na}</td>
                                                        {n.label === "Maintenance" && (
                                                            <>
                                                                <td className="u-textRight">{n.nutrients.H}</td>
                                                                <td className="u-textRight">-</td>
                                                            </>
                                                        )}
                                                        {n.label === "Fertiliser applied" && (
                                                            <>
                                                                <td className="u-textRight">-</td>
                                                                <td className="u-textRight">{n.nutrients.H}</td>
                                                            </>
                                                        )}
                                                        {n.label !== "Maintenance" && n.label !== "Fertiliser applied" && (
                                                            <>
                                                                <td className="u-textRight">-</td>
                                                                <td className="u-textRight">-</td>
                                                            </>
                                                        )}
                                                    </tr>
                                                )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <sub>1 - Maintenance lime is the amount of lime required to neutralise any increase in acidity. The value is in pure lime - divide by lime purity to get application rates.</sub>
                            </div>
                            <div>
                                <sub>2 - Acidity - used in calculation of maintenance lime requirements. A gain in acidity indicates that soil pH will decrease.</sub>
                            </div>
                        </>
                    )}
                </GridCell>
            </Grid>
        </>
    )
}
