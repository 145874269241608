import React from "react";
import Spinner from "components/Spinner/Spinner";

const PanelBody = ({ children, grey, className, loading, waiting }) => {
    let bodyCssClasses = "Tile-body u-print-noborder";
    if (grey) bodyCssClasses += " u-bg-grey u-p-10";
    if (waiting) bodyCssClasses += " waiting";
    if (className) bodyCssClasses += ` ${className}`;

    return (
        <div className={bodyCssClasses}>
            {loading && (
                <div className={grey ? "HeroPanel" : ""}>
                    <div className="Tile-body-message">
                        <Spinner dark />
                        <p className="lead">Loading...</p>
                    </div>
                </div>
            )}
            {!loading && children}
        </div>
    );
};

export default PanelBody;
