import { Fragment } from "react";

export default function BlockMonths({ block, thisBlockModel, rotationYear }) {
    return thisBlockModel.categories.map((category, j) => {
        return Category(rotationYear, category, block.id);
    })
}

function MonthRow(rotationYear, row, key) {
    return (
        <tr key={key}>
            <td datawidth="10">{row.labels && row.labels.join(", ")}</td>

            {rotationYear.map((calendarMonth, i) => {
                const month = row.months && row.months.find((m) => m.month === calendarMonth && m.reportingYear === (rotationYear.length === 12 || i >= 12));

                return (
                    <td className="u-textCenter" key={`${key}-${i}`}>
                        {month && <span id={`calendar.${i}.text.months.${key}`}>{month.value > 0 ? month.value : "-"}</span>}
                        {!month && <span id={`zero.calendar.${i}.text.months.${key}`}>-</span>}
                    </td>
                );
            })}
        </tr>
    )
}

function Category(rotationYear, category, blockId) {
    return (
        <Fragment key={`block-category-${blockId}-${category.name}`}>
            <tr key={`block-category-${blockId}-${category.name}`}>
                <th colSpan={rotationYear.length + 1} className="u-textBold">
                    <span className="u-flex u-flexAlignItemsCenter">
                        {category.icon && <img id={`calendar.titleIcon.${category.name}`} src={category.icon} className="u-mr-xs u-p-5" width="35" height="35" alt="" />}
                        <span className="u-textBold">
                            <span>{category.label}</span>
                        </span>
                    </span>
                </th>
            </tr>

            {category.rows &&
                category.rows
                    .filter((r) => r.name !== "Total")
                    .map((r) => {
                        const name = r.name || (r.labels && r.labels.length > 0 && r.labels.join("-"));
                        return MonthRow(rotationYear, r, `blockmonthrow-${blockId}-${category.name}-${name}`);
                    })}
            {category.subCategories &&
                category.subCategories.map((sc) => {
                    return Category(rotationYear, sc, blockId);
                })}
        </Fragment>
    )
}
