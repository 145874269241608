import React from "react";
import SelectPack from "components/SelectPack2";
import { Field } from "react-final-form";
import InputPack from "components/InputPack";
import { ExistingWarning, GlobalCopy } from "./Dialogs";
import { GridCell } from "components/Grid";

const CustomSoluble = ({ viewModel, customFertiliser, customCompositions }) => {
    const localProducts = viewModel.isNew ? [] : customFertiliser.map((c) => ({ value: c.id, text: c.productName, groupIndex: 1, groupLabel: "Use existing custom soluble" }));
    localProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    const myProducts = customCompositions.filter((c) => c.userId).map((c) => ({ value: c.id, text: c.name, groupIndex: 2, groupLabel: "Copy my soluble product" }));
    const orgProducts = customCompositions.filter((c) => !c.userId).map((c) => ({ value: c.id, text: c.name, groupIndex: 3, groupLabel: "Copy organisation soluble product" }));
    myProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    orgProducts.sort((a, b) => (b.text < a.text ? 1 : -1));
    const products = [...[{ value: "NEW", text: "New custom soluble", groupLabel: "Add new custom soluble", groupIndex: 0 }], ...localProducts, ...myProducts, ...orgProducts];

    return (
        <GridCell>
            {(localProducts.length > 0 || myProducts.length > 0 || orgProducts.length > 0) && <Field name="fertiliser.id" label="Custom products" component={SelectPack} required={true} options={products} />}
            <GlobalCopy viewModel={viewModel} />
            <Field name={`fertiliser.productName`} label="Name" component={InputPack} requiredLabel={true} disabled={viewModel.existing} />
        </GridCell>
    );
};

const Soluble = ({ viewModel, customFertiliser = [], customCompositions = [] }) => {
    return (
        <React.Fragment>
            <CustomSoluble customFertiliser={customFertiliser} viewModel={viewModel} customCompositions={customCompositions} />
            <ExistingWarning viewModel={viewModel} />
        </React.Fragment>
    );
};

export default Soluble;
