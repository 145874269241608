import { Range } from "rc-slider";
import * as utils from "common/utils";
import { useRefData } from "common/hooks";
import ActionLink from "components/ActionLink";
import CheckboxPack from "components/CheckboxPack";
import NumericInputPack from "components/NumericInputPack";
import SelectPack from "components/SelectPack";

export default function AnimalGrazing({ onChange, animalGrazing, validation, source, enterprises = [], defoliation, crop, cropBlock, displayEqualRatio, isRequired, isRotation }) {
    const refData = useRefData();

    const { animalTypes = [], animalSource = [] } = refData;
    const isDefoliationCutAndCarry = defoliation && defoliation.defoliationMethod === "CutandCarry";
    const hasInFarmFate = defoliation && defoliation.defoliationFate && !["ToStorage", "Exported"].includes(defoliation.defoliationFate);

    if (animalGrazing.animalSource !== "Standalonegeneralisedanimaltype") {
        delete animalGrazing.animalType;
    } else {
        delete animalGrazing.percentageGrazed;
        delete animalGrazing.hoursCropGrazed;
    }

    if (isDefoliationCutAndCarry) {
        animalGrazing.animalSource = "Integratedwithpastoralblocks";
        if (!hasInFarmFate) {
            delete animalGrazing.percentageGrazed;
        }
    }
    const multipleOnFarmAnimals = animalGrazing.animalSource === "Integratedwithpastoralblocks" && enterprises.length > 1;

    if (!animalGrazing.specifyAnimalConsumption && cropBlock) {
        delete animalGrazing.percentageGrazed;
    }

    if (animalGrazing.animalSource === "Integratedwithpastoralblocks" && enterprises.length === 1) {
        animalGrazing.percentageGrazed = [{ percentage: 100, enterpriseId: enterprises[0].id }];
    }

    let modAnimalSource = animalSource.map((a) => {
        return { value: a.value, text: a.text.replace(" - see Enterprise numbers panes", "") };
    });
    if (isRotation) {
        modAnimalSource = modAnimalSource.filter((a) => a.value !== "Standalonegeneralisedanimaltype");
    }

    if (enterprises.length === 1) {
        const singleFarmOpt = modAnimalSource.find((a) => a.value === "Integratedwithpastoralblocks");
        if (singleFarmOpt) {
            const { enterpriseTypes = [] } = refData;
            const enterpriseType = enterpriseTypes.find((e) => e.value === enterprises[0].type) || {};
            singleFarmOpt.text = enterpriseType.text;
        }
    }
    if (enterprises.length === 0) {
        modAnimalSource = modAnimalSource.filter((a) => a.value !== "Integratedwithpastoralblocks");
    }

    const animalGrazingChange = (e, source) => {
        animalGrazing[source.key] = utils.ctrlVal(e);
        if (source.key === "animalSource" && enterprises.length === 1) {
            animalGrazing.specifyAnimalConsumption = true;
        }
        const result = animalGrazing.animalSource === "" ? undefined : animalGrazing;
        onChange(e, { type: "animalGrazing", key: "reset", animalGrazing: result });
    };

    const animalsDistribute = () => {
        const equalValue = Math.floor(100 / animalGrazing.percentageGrazed.length);
        const index = animalGrazing.percentageGrazed.length - 1;
        for (let i = 0; i < index; i++) {
            animalGrazing.percentageGrazed[i].percentage = equalValue;
        }
        if (index >= 0) {
            animalGrazing.percentageGrazed[index].percentage = 100 - index * equalValue;
        }
    };

    const animalSelect = (e, enterprise, remove) => {
        const { percentageGrazed = [] } = animalGrazing;
        animalGrazing.percentageGrazed = percentageGrazed;
        animalGrazing.percentageGrazed = animalGrazing.percentageGrazed.filter((e) => e.enterpriseId !== enterprise.id);
        if (!remove) {
            //This needs to be in the same order as enterprises
            animalGrazing.percentageGrazed.push({ enterpriseId: enterprise.id, percentage: 0 });
        }
        animalsDistribute(animalGrazing);
        onChange(e, { type: "animalGrazing", key: "reset", animalGrazing: animalGrazing });
    };

    const applyConsumption = (e) => {
        animalGrazing.percentageGrazed = e;
        onChange(e, { type: "animalGrazing", key: "reset", animalGrazing: animalGrazing });
    };

    const getActiveControls = (animalGrazing) => {
        const activeControls = {};
        activeControls.animalSource = !isDefoliationCutAndCarry;
        activeControls.animalType = animalGrazing.animalSource === "Standalonegeneralisedanimaltype" && !isDefoliationCutAndCarry;
        activeControls.percentageGrazed = multipleOnFarmAnimals && displayEqualRatio;
        activeControls.animalConsumption = ((!isDefoliationCutAndCarry && multipleOnFarmAnimals) || (isDefoliationCutAndCarry && hasInFarmFate)) && (animalGrazing.specifyAnimalConsumption || !activeControls.percentageGrazed);
        activeControls.hoursCropGrazed = animalGrazing.animalSource === "Integratedwithpastoralblocks" && !isDefoliationCutAndCarry && !cropBlock && !crop;
        return activeControls;
    };

    const activeControls = getActiveControls(animalGrazing);

    return (
        <div>
            <div className="Field-group">
                <SelectPack id={`animalSource_${source}`} label="Animal source" dataWidth="50" meta={{ nrf: true }} onChange={(e) => animalGrazingChange(e, { type: "animalGrazing", key: "animalSource" })} value={animalGrazing.animalSource} val={validation.animalGrazing_animalSource} isHidden={!activeControls.animalSource} requiredLabel={isRequired}>
                    {isRequired ? (
                        <option value="" disabled={true}>
                            Select animal source
                        </option>
                    ) : (
                        <option value="">Not applicable</option>
                    )}
                    {utils.mapRefDataItems(modAnimalSource)}
                </SelectPack>
                <SelectPack id={`animalType_${source}`} label="Animal type" dataWidth="50" isHidden={!activeControls.animalType} meta={{ nrf: true }} onChange={(e) => animalGrazingChange(e, { type: "animalGrazing", key: "animalType" })} value={animalGrazing.animalType} val={validation.animalGrazing_animalType} requiredLabel={isRequired}>
                    <option value="" disabled={true}>
                        Select animal type
                    </option>
                    {utils.mapRefDataItems(animalTypes)}
                </SelectPack>
            </div>
            {enterprises.length === 0 && (
                <div className="Alert Alert--info">
                    <div>
                        <span>To specify farm stock you will need to configure animal enterprises. </span>
                    </div>
                </div>
            )}

            <CheckboxPack id={`specifyConsumption_${source}`} dataWidth={50} isHidden={!activeControls.percentageGrazed} meta={{ nrf: true }} value={animalGrazing.specifyAnimalConsumption} onChange={(e) => animalGrazingChange(e, { type: "animalGrazing", key: "specifyAnimalConsumption" })} label="Specify by % of pasture production consumed by enterprises" />
            <div className="Grid-cell">
                <AnimalConsumption distributionChanged={(e) => applyConsumption(e)} animalSelect={(e, ent, remove) => animalSelect(e, ent, remove)} animalGrazing={animalGrazing} isHidden={!activeControls.animalConsumption} validation={validation} enterprises={enterprises} />
            </div>
            <div className="Field-group">
                <NumericInputPack id={`hoursCropGrazed_${source}`} label="Crop grazed for" placeholder="Leave blank if grazed all day" dataWidth="50" uom="hours/day" meta={{ nrf: true }} isHidden={!activeControls.hoursCropGrazed} onChange={(e) => animalGrazingChange(e, { type: "animalGrazing", key: "hoursCropGrazed" })} value={animalGrazing.hoursCropGrazed} val={validation.animalGrazing_hoursCropGrazed} tip="If a restricted grazing policy is in place, enter the average hours/day animals graze the block during the month; otherwise leave blank (animals are on the block all day)." requiredLabel={false} />
            </div>
        </div>
    );
}

function AnimalConsumption({ enterprises, animalSelect, validation, animalGrazing, distributionChanged, isHidden }) {
    const refData = useRefData();

    if (isHidden || enterprises.length === 0) return null;
    const trackStyle = { backgroundColor: "#509bc2" };
    const rangePercentages = [];
    let percentage = 0;

    if (animalGrazing.percentageGrazed) {
        const entIds = enterprises.map((e) => e.id);
        const { percentageGrazed = [] } = animalGrazing;
        animalGrazing.percentageGrazed = percentageGrazed.sort((a, b) => entIds.indexOf(a.enterpriseId) - entIds.indexOf(b.enterpriseId));
    }

    const { percentageGrazed = [] } = animalGrazing;
    if (percentageGrazed.length > 0) {
        rangePercentages.push(0);
    }
    const animalPercentages = enterprises.map((enterprise, i) => {
        const pGrazed = percentageGrazed.find((e) => e.enterpriseId === enterprise.id);
        const { enterpriseTypes = [] } = refData;
        const enterpriseType = enterpriseTypes.find((e) => e.value === enterprise.type) || {};
        if (pGrazed) {
            percentage += parseInt(pGrazed.percentage, 10);
            rangePercentages.push(percentage);
        }
        return pGrazed ? (
            <div className="Grid-cell u-md-width1of3 u-mt-lg" key={i}>
                <div className={`SoilRange-item SoilRange-item--${utils.colourNameIndex[i]}`}>
                    <div className="SoilRange-name">{"Eaten by " + enterpriseType.text}</div>
                    <div className="SoilRange-value">
                        {pGrazed.percentage}
                        <span>%</span>
                    </div>
                    {enterprises.length > 1 && (
                        <div>
                            <ActionLink onClick={(evt) => animalSelect(evt, enterprise, true)} id={`remove_${enterprise.id}`}>
                                Remove
                            </ActionLink>
                        </div>
                    )}
                </div>
            </div>
        ) : (
            <div className="Grid-cell u-md-width1of3 u-mt-lg" key={i}>
                <div className="SoilRange-item SoilRange-item--add">
                    <img alt="Add Soil" height="45" src={utils.getAnimalIcon(enterprise.type)} title="Add headlands" width="45" className="u-p-5"/>
                    <div>{enterpriseType.text}</div>
                    <div>
                        <ActionLink onClick={(evt) => animalSelect(evt, enterprise, false)} id={`add_${enterprise.id}`}>
                            Add
                        </ActionLink>
                    </div>
                </div>
            </div>
        );
    });

    const distributionChange = (values) => {
        const inputData = utils.clone(values);
        const { percentageGrazed = [] } = animalGrazing;
        let last = 0;
        inputData.pop();
        inputData.push(100);
        for (let i = 0; i < percentageGrazed.length; i++) {
            const ac = percentageGrazed[i];
            ac.percentage = inputData[i + 1] - last;
            last = inputData[i + 1];
        }
        distributionChanged(percentageGrazed);
    };

    const trackStyles = percentageGrazed.map((a, i) => {
        return { backgroundColor: utils.colourHexIndex[i] };
    });

    return (
        <div>
            <h3 className="u-mt-xl">Percentage of crop eaten by each animal type</h3>
            <div className="SoilRange">
                <div className="Grid Grid--withGutter">{animalPercentages}</div>
            </div>
            {percentageGrazed.length > 1 && (
                <div className="SoilRange-slider">
                    <Range step={1} allowCross={true} pushable={1} onChange={distributionChange} value={rangePercentages} railStyle={trackStyle} trackStyle={trackStyles} />
                </div>
            )}
            {validation.animalConsumption && validation.animalConsumption.error && (
                <div className="Field has-error">
                    <small className="Field-error">{validation.animalConsumption.message}</small>
                </div>
            )}
        </div>
    )
}
