import React from "react";
import { compose, withActions, withState, withAppContext } from "containers/HOC";
import { getBalanceSummary, getBalance, clearBalanceSummary, clearBalance } from "../_actions";
import moment from "moment";
import { Grid, GridCell } from "components/Grid";
import SelectPack from "components/SelectPack";
import { Panel, PanelBody } from "components/Panel";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import LoaderSection from "components/LoaderSection";
import AccountBalance from "components/AccountBalance";
import numeral from "numeral";
import ActionLink from "components/ActionLink";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileBodyMessage from "components/TileBodyMessage";
import * as icons from "common/icons";
import { useAddBankDepositModal } from "containers/Admin/Payments/AddBankDepositModal";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
function Payments(props) {
    const [addBankDepositModal, openAddBankDepositModal] = useAddBankDepositModal();

    return (
        <>
            <PaymentsClassComponent {...props} openAddBankDepositModal={openAddBankDepositModal} />
            {addBankDepositModal}
        </>
    )
}

class PaymentsClassComponent extends React.Component {
    state = {};

    getYearMonthOptions = () => {
        const options = [];

        let start = moment("2018-01-01");
        const now = moment();
        while (start.isBefore(now)) {
            options.unshift({ value: start.format("YYYY-MM"), text: start.format("MMMM YYYY") });
            start.add(1, "months");
        }

        return options;
    };

    loadAccountBalances = (account) => {
        this.setState({ account });
    };

    componentWillUnmount() {
        this.props.clearBalanceSummary();
        this.props.clearBalance();
    }

    dateChange = (e) => {
        const month = e.target.value;
        const { getBalanceSummary, clearBalance } = this.props;
        clearBalance();
        getBalanceSummary(month);
        this.setState({ month, account: undefined });
    };

    componentDidUpdate() {
        const { balanceSummary = [] } = this.props;
        const { account } = this.state;
        if (account) {
            const balanceSummaryAccount = balanceSummary.find((b) => b.accountId === account.accountId);
            if (balanceSummaryAccount && balanceSummaryAccount.balance !== account.balance) {
                this.setState({ account: balanceSummaryAccount });
            }
        }
    }

    createPayment = (accountId, accountName) => {
        const { month } = this.state;
        const viewModel = {
            accountId,
            yearMonth: month,
            organisationName: accountName,
            paymentTransaction: {
                id: uuidv4(),
                accountId,
            },
        };
        this.props.openAddBankDepositModal(viewModel);
    };

    render() {
        const { authContext, balanceSummaries = [], balanceSummaryLoading } = this.props;
        const { month, account } = this.state;
        const yearMonthOptions = this.getYearMonthOptions();
        let farmCount = 0,
            purchases = 0,
            tax = 0;
        const balanceSummary = account && balanceSummaries.find((s) => s.accountId === account.accountId);
        const transactions = balanceSummary && utils.clone(balanceSummary.transactions);
        if (transactions && month) {
            farmCount = transactions.reduce((acc, cur) => (acc += cur.farmIds.length), 0);
            purchases = transactions.reduce((acc, cur) => (acc += cur.amountExclGst), 0);
            tax = transactions.reduce((acc, cur) => (acc += cur.gst), 0);
        }
        const displayBalanceSummary = balanceSummaries && balanceSummaries.length > 0 && month;
        const monthSelected = yearMonthOptions.find((y) => y.value === month);

        return (
            <Panel>
                <PanelBody>
                    <div className="h2">Invoices report</div>
                    <Grid>
                        <GridCell className="u-width1of5">
                            <div className="u-mb-md">
                                <SelectPack id="crop_category" label="Month" meta={{ nrf: true }} onChange={(e) => this.dateChange(e)} value={month} requiredLabel={true}>
                                    <option value="" disabled={true}>
                                        Select a month
                                    </option>
                                    {yearMonthOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </SelectPack>
                            </div>
                            {balanceSummaryLoading ? (
                                <LoaderSection message="Loading..." dark />
                            ) : (
                                <>
                                    {displayBalanceSummary ? (
                                        <>
                                            <p className="u-mt-0 u-mb-md">
                                                <span className="h3">{balanceSummaries.length}</span> Account(s) found
                                            </p>
                                            <SortableTable data={balanceSummaries} defaultSortCriteria={{ fieldName: "accountName", fieldType: "string" }}>
                                                {({ data, sortCriteria, handleSort }) => {
                                                    return (
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <SortableTableHeader label="Name" fieldName="accountName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                    <SortableTableHeader label="Balance" fieldName="balance" sortCriteria={sortCriteria} handleSort={handleSort} shrink />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((summary) => {
                                                                    return (
                                                                        <tr key={summary.accountId} className={account && summary.accountId === account.accountId ? "Table-tr--blue is-selected" : "u-link hover"} onClick={() => this.loadAccountBalances(summary)}>
                                                                            <td>{summary.accountName}</td>
                                                                            <td className="u-textRight u-textNoWrap">{numeral(summary.currentBalance).format("$0,0.00")}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    );
                                                }}
                                            </SortableTable>
                                        </>
                                    ) : (
                                        <div className="h3">No data</div>
                                    )}
                                </>
                            )}
                        </GridCell>
                        <GridCell className="u-width4of5">
                            {account && transactions && displayBalanceSummary && (
                                <>
                                    <div className="h3 u-mb-md">{account.accountName}</div>
                                    <div className="ActionsBar ActionsBar--super u-print-none">
                                        <div className="ActionsBar-left">
                                            <ul className="ActionsBar-links">
                                                <li>
                                                    <ActionLink id="admin-create-payment" className="IconLink--arrow-plus" onClick={() => this.createPayment(account.accountId, account.accountName)}>
                                                        Add bank deposit for this org
                                                    </ActionLink>
                                                </li>
                                                <li>
                                                    <Link className="IconLink--search" to={`/app/admin/orgs/${account.accountId}`}>
                                                        Organisation details
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <Grid className="u-mb-lg u-mt-sm">
                                        <GridCell className="u-width1of1">
                                            <div className="Table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Overview</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="HeroValue">
                                                                    <div className="HeroValue-item">
                                                                        <h5>Balance</h5>
                                                                        <p className="HeroValue-item-number">{numeral(balanceSummary.currentBalance).format("$0,0.00")}</p>
                                                                    </div>
                                                                    <div className="HeroValue-item">
                                                                        <h5>Credit limit</h5>
                                                                        <p className="HeroValue-item-number">{numeral(balanceSummary.creditLimit).format("$0,0.00")}</p>
                                                                    </div>
                                                                    <div className="HeroValue-item">
                                                                        <h5>Farms</h5>
                                                                        <p className="HeroValue-item-number">{farmCount}</p>
                                                                    </div>
                                                                    <div className="HeroValue-item">
                                                                        <h5>Amount</h5>
                                                                        <p className="HeroValue-item-number">{numeral(purchases).format("$0,0.00")}</p>
                                                                    </div>
                                                                    <div className="HeroValue-item">
                                                                        <h5>GST</h5>
                                                                        <p className="HeroValue-item-number">{numeral(tax).format("$0,0.00")}</p>
                                                                    </div>
                                                                    <div className="HeroValue-item">
                                                                        <h5>Total</h5>
                                                                        <p className="HeroValue-item-number">{numeral(purchases + tax).format("$0,0.00")}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </GridCell>
                                    </Grid>
                                    <div className="h3 u-mb-md">Transactions for {monthSelected && monthSelected.text}</div>
                                    <AccountBalance accountId={account.accountId} txns={transactions} authContext={authContext} />
                                </>
                            )}
                            {!transactions && displayBalanceSummary && (
                                <Tile>
                                    <TileBody>
                                        <TileBodyMessage>
                                            <img src={icons.searchMedium} alt="Plus" />
                                            <p className="lead">Select an account to view details</p>
                                        </TileBodyMessage>
                                    </TileBody>
                                </Tile>
                            )}
                        </GridCell>
                    </Grid>
                </PanelBody>
            </Panel>
        );
    }
}

export default compose(
    withAppContext,
    withActions({ getBalanceSummary, getBalance, clearBalanceSummary, clearBalance }),
    withState((state) => {
        return {
            balanceSummaries: state.admin.balanceSummary,
            balanceSummaryLoading: state.admin.balanceSummaryLoading,
            balance: state.admin.balance,
        };
    })
)(Payments);
