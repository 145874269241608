import ActionLink from "components/ActionLink";
import { useViewPublicationHistoryModal } from "./ViewPublicationHistoryModal";

export default function ViewPublicationHistoryActionLink({ publication, label = "History", iconClass = "IconLink--clock" }) {
    const [viewPublicationHistoryModal, openViewPublicationHistoryModal] = useViewPublicationHistoryModal(publication);
    return (
        <>
            <ActionLink id={`view-publication-history-${publication.id}`} className={iconClass} onClick={openViewPublicationHistoryModal}>
                {label}
            </ActionLink>
            {viewPublicationHistoryModal}
        </>
    )
}
