import React from "react";
import { connect } from "react-redux";
import { Route, Link, withRouter } from "react-router-dom";
import * as icons from "common/icons";
import Page from "components/Page";
import PageBody from "components/PageBody";
import Register from "containers/Auth/Register";
import Login from "containers/Auth/Login";
import ResetPassword from "containers/Auth/ResetPassword";
import UserInvitation from "containers/Auth/UserInvitation";
import FarmAccessInvitation from "containers/Auth/FarmAccessInvitation";
import FarmAccessRequest from "containers/Auth/FarmAccessRequest";
import ActivateUser from "containers/Auth/ActivateUser";
import OfflinePoller from "components/OfflinePoller";
import ImagePreloader from "components/ImagePreloader";
import ModalConductor from "components/ModalConductor";
import Loader from "components/Loader";
import { OfflinePanel } from "components/OfflinePanel";
import { useClearQueryCacheOnLogout } from "containers/Auth/_hooks";

const PublicLayout = (props) => {
    useClearQueryCacheOnLogout();

    let header = process.env.REACT_APP_PRODUCT === "ed" ? "Header--green" : "";
    header = process.env.REACT_APP_PRODUCT === "sci" ? "Header--orange" : header;

    return (
        <div className={"react-page" + (props.modal.modalType !== null ? " modal-is-open" : "")}>
            <header className={`Header ${header}`}>
                <div className="Header-logo">
                    <Link className="Header-logo-sm" to="/">
                        <img src={icons.logoSmall()} alt="Overseer logo" />
                    </Link>
                    <Link className="Header-logo-lg" to="/">
                        <img src={icons.logo()} alt="Overseer logo" />
                    </Link>
                </div>
                <Route
                    exact
                    path="/pub/farmAccessRequests/:accessRequestId"
                    render={() => (
                        <div id="nav-menus" className="UtilityNav u-print-none u-pr-md">
                            <Link to={"/pub/login"} className="u-font-white u-font-md u-link-white">
                                Login
                            </Link>
                        </div>
                    )}
                />
            </header>
            <Page>
                <PageBody>
                    <OfflinePoller />
                    <OfflinePanel>
                        {props.isLoading && <Loader message="Loading..." />}
                        <Route exact path="/pub/register" component={Register} />
                        <Route exact path="/pub/register/:farmId/dp" component={Register} />
                        <Route exact path="/pub/register/:invitationId" component={Register} />
                        <Route exact path="/pub/login/:farmId/dp" component={Login} />
                        <Route exact path="/pub/login" component={Login} />
                        <Route exact path="/pub/userInvitations/:invitationId" component={UserInvitation} />
                        <Route exact path="/pub/farmAccessInvitations/:invitationId/dp" component={FarmAccessInvitation} />
                        <Route exact path="/pub/farmAccessInvitations/:invitationId" component={FarmAccessInvitation} />
                        <Route exact path="/pub/farmAccessRequests/:accessRequestId" component={FarmAccessRequest} />
                        <Route exact path="/pub/activate/:activationToken" component={ActivateUser} />
                        <Route exact path="/pub/resetPassword/:resetToken" component={ResetPassword} />
                    </OfflinePanel>
                    <ImagePreloader />
                </PageBody>
            </Page>
            <footer className="Footer u-print-none">
                <div className="Container">
                    Overseer &copy; 2018 | <span id="ui-version">v{process.env.REACT_APP_VERSION.split("+")[0]}</span>
                </div>
            </footer>
            <ModalConductor modal={props.modal} />
        </div>
    );
};

const _mapStateToProps = (state, ownProps) => {
    return {
        modal: state.app.modal,
        online: state.app.online,
        isLoading: state.auth.isLoading,
    };
};

export default withRouter(connect(_mapStateToProps, null)(PublicLayout));
