import React from "react";

const Field = (props) => {
    const { fields, name, component: Component } = props;
    const field = fields[name];
    const meta = { touched: field && field.touched, error: field && field.error };
    const componentProps = { value: field && field.value };
    componentProps[name] = field && field.value;

    const _keyPress = (e) => {
        if (props.normalize) {
            const value = e.target.value.concat(e.key);
            const result = props.normalize(value);
            if (result === undefined || result.toString() !== value) e.preventDefault();
        }
        if (props.onKeyPress) {
            props.onKeyPress(e);
        }
    };

    return <Component {...props} {...componentProps} meta={meta} onKeyPress={_keyPress} />;
};

export default Field;
