import { supplementsViewModel } from "common/viewModels";
import Watermark from "components/Watermark";
import { useRefData } from "common/hooks";

export default function SupplementList({ analysis }) {
    const refData = useRefData();
    const viewModel = supplementsViewModel(analysis, refData);

    return (
        <>
            {viewModel.length === 0 && "There are no supplements produced or imported on this farm."}
            {viewModel.length > 0 && (
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="md-15 xl-15">Category</th>
                                <th data-width="md-15 xl-15">Feed</th>
                                <th data-width="md-25 xl-25">Source</th>
                                <th data-width="md-10 xl-5">Dry weight</th>
                                <th data-width="md-10 xl-10">Amount</th>
                                <th data-width="md-20 xl-20">Destination</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewModel.map((vm) => {
                                return (
                                    <tr key={vm.id}>
                                        <td>
                                            <span className="u-flex u-flexAlignItemsCenter">
                                                <img className="u-mr-xs u-p-5" src={vm.icon} width="35" height="35" alt={`${vm.category} icon`} />
                                                <span id={`supplement-category-${vm.id}`}>{vm.category}</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span id={`supplement-feed-${vm.id}`}>{`${vm.feed}`}</span>
                                        </td>
                                        <td>
                                            <span>{vm.sourcedFrom.join(", ")}</span>
                                        </td>
                                        <td>
                                            <span id={`supplement-dryweight-${vm.id}`}>{vm.amounts.dryWeight ? "Yes" : "No"}</span>
                                        </td>
                                        <td>
                                            <span id={`supplement-amount-${vm.id}`}>{`${vm.amounts.totalSourced} ${vm.amountTypeMeta.uom}`}</span>
                                        </td>
                                        <td>{vm.distributedTo.length > 0 && <span>{vm.distributedTo.join(", ")}</span>}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}
