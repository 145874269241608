import { Link } from "react-router-dom";
import * as utils from "common/utils";
import Alert from "components/Alert";
import WidgetContainer from "components/WidgetContainer";

export default function Analyses({ farm, className, height }) {
    const latestPublication = farm?.publications?.length > 0 ? farm.publications.sort((a, b) => (a.publishedOn < b.publishedOn ? 1 : -1))[0] : undefined;
    const yearEndYears = farm?.budgets?.filter((b) => ["YearEnd", "PrivateYearEnd"].includes(b.type) && b.class === "Budget").map((y) => Number(y.year));
    const planYears = farm?.budgets?.filter((b) => b.type === "Predictive" && b.class === "Budget" && b.year > 0).map((y) => Number(y.year));
    const maxYearEnd = yearEndYears.length > 0 ? Math.max(...yearEndYears) : 0;
    const maxPlanYear = planYears.length > 0 ? Math.max(...planYears) : 0;
    const currentYear = new Date().getFullYear();
    const isWarning = currentYear > maxYearEnd || currentYear > maxPlanYear;
    const yearEndMessage = currentYear > maxYearEnd ? `This farm does not have a year end analysis for this year (${currentYear})` : `This farm has a year end analysis for this year (${currentYear})`;
    const planMessage = currentYear > maxPlanYear ? ` and has no active plan.` : ` and has an active plan for ${maxPlanYear}.`;

    return (
        <WidgetContainer id="analyses" title="Analyses" icon="IconLink--compare" className={className} linkTitle="View all" linkUrl={`/app/farm/${farm.id}/analysis`} height={height}>
            <div>
                <Alert type={isWarning ? "warning" : "help"} text={`${yearEndMessage} ${planMessage}`} className="u-border" />
                <div className="HeroValue u-mt-sm">
                    <div className="HeroValue-item compressed">
                        <h5>Publications</h5>
                        <p className="HeroValue-item-number">{farm?.publications?.length}</p>
                    </div>
                    <div className="HeroValue-item compressed">
                        <h5>Year ends</h5>
                        <p className="HeroValue-item-number">{yearEndYears.length}</p>
                    </div>
                    <div className="HeroValue-item compressed">
                        <h5>Plans</h5>
                        <p className="HeroValue-item-number">{planYears.length}</p>
                    </div>
                    <div className="HeroValue-item compressed">
                        <h5>Predictive</h5>
                        <p className="HeroValue-item-number">{farm?.budgets?.filter((b) => b.type === "Predictive" && b.class === "Budget" && (!b.year || b.year === 0))?.length}</p>
                    </div>
                    <div className="HeroValue-item compressed">
                        <h5>Scenarios</h5>
                        <p className="HeroValue-item-number">{farm?.budgets?.filter((b) => b.type === "Scenario")?.length}</p>
                    </div>
                </div>
                <>
                    {latestPublication ? (
                        <p className="u-pt-md u-pl-md u-pr-md">
                            Your latest publication was to <b>{latestPublication.publishedTo}</b> on the <b>{utils.dateOnly(latestPublication.publishedOn)}</b>.<Link to={`/app/farm/${farm.id}/analysis/${latestPublication.publishedAnalysisId}/overview`}> Click here to view</Link>
                        </p>
                    ) : (
                        <p className="u-pt-md u-pl-md u-pr-md">You have not published any analysis. To publish an analysis use the publish button on the analysis list screen.</p>
                    )}
                </>
            </div>
        </WidgetContainer>
    )
}
