import { useAnalysisResults } from "containers/BudgetHome";
import Loader from "components/Loader";
import NutrientBudget from "./NutrientBudget";

export default function FarmNutrientBudget({ analysis }) {
    const { isLoading, data: analysisResults } = useAnalysisResults(analysis);

    if (isLoading) {
        return <Loader />
    }

    if (!analysisResults?.nutrientBudget) {
        return <>There are no nutrient results for this analysis</>
    }

    const nitrogenResults = analysisResults.nitrogen;
    const phosphorusResults = analysisResults.phosphorus;
    const nutrientBudget = analysisResults.nutrientBudget;

    return <NutrientBudget nitrogenResults={nitrogenResults} phosphorusResults={phosphorusResults} nutrientBudget={nutrientBudget} id="FarmNutrientBudget" />;
}
