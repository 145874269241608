import actionTypes from "./_actions";
import authActionTypes from "containers/Auth/_actions";

function reducer(state = {}, action) {
    switch (action.type) {
        case actionTypes.MY_ORG_GET_ORG: {
            return {
                ...state,
                accountLoading: true,
            };
        }
        case actionTypes.MY_ORG_GET_ANALYSIS_SUMMARY: {
            return {
                ...state,
                analysisSummaryLoading: true,
            };
        }
        case actionTypes.MY_ORG_GET_ANALYSIS_SUMMARY_SUCCESS: {
            return {
                ...state,
                analysisSummaryLoading: false,
                analysisSummary: action.payload,
            };
        }
        case actionTypes.MY_ORG_GET_ANALYSIS_SUMMARY_CLEAR:
        case actionTypes.MY_ORG_GET_ANALYSIS_SUMMARY_FAILURE: {
            return {
                ...state,
                analysisSummaryLoading: false,
                analysisSummary: undefined,
            };
        }
        case actionTypes.MY_ORG_GET_ALL_FARMS_SUCCESS: {
            return {
                ...state,
                allFarms: action.payload,
            };
        }
        case actionTypes.MY_ORG_GET_ORG_SUCCESS: {
            return {
                ...state,
                account: {
                    ...action.payload,
                    userInvitations: (action.payload.userInvitations || []).filter((i) => i.status !== "Accepted" && i.target !== "Organisation"),
                },
                accountLoading: false,
            };
        }
        case actionTypes.MY_ORG_GET_ORG_FAILURE: {
            return {
                ...state,
                account: undefined,
                accountLoading: false,
            };
        }

        case actionTypes.MY_ORG_GET_PAYMENTS: {
            return {
                ...state,
                paymentsLoading: true,
            };
        }
        case actionTypes.MY_ORG_GET_PAYMENTS_SUCCESS: {
            return {
                ...state,
                payments: action.payload.filter((p) => p.status !== "Aborted"),
                paymentsLoading: false,
            };
        }
        case actionTypes.MY_ORG_GET_PAYMENTS_FAILURE: {
            return {
                ...state,
                payments: undefined,
                paymentsLoading: false,
            };
        }

        case actionTypes.MY_ORG_GET_ACTIVITY: {
            return {
                ...state,
                activityLoading: true,
            };
        }
        case actionTypes.MY_ORG_GET_ACTIVITY_SUCCESS: {
            return {
                ...state,
                activity: action.payload.filter((p) => p.status !== "Aborted"),
                activityLoading: false,
            };
        }
        case actionTypes.MY_ORG_GET_ACTIVITY_FAILURE: {
            return {
                ...state,
                activity: undefined,
                activityLoading: false,
            };
        }

        case actionTypes.MY_ORG_GET_BALANCE: {
            return {
                ...state,
                balance: undefined,
                balanceLoading: true,
            };
        }
        case actionTypes.MY_ORG_GET_BALANCE_SUCCESS: {
            return {
                ...state,
                balance: action.payload,
                balanceLoading: false,
            };
        }
        case actionTypes.MY_ORG_GET_BALANCE_FAILURE: {
            return {
                ...state,
                balance: undefined,
                balanceLoading: false,
            };
        }

        case actionTypes.MY_ORG_UPDATE_ORG_SUCCESS: {
            return {
                ...state,
                account: {
                    ...state.account,
                    name: action.payload.name,
                },
            };
        }

        case authActionTypes.AUTH_CLEAR_STORAGE:
            return {};

        default:
            return state;
    }
}

export default reducer;
