import Alert from "components/Alert";
import { useShowQuickTips } from "common/hooks";

export default function Grid({ title, instructions, help, children, className }) {
    const showQuickTips = useShowQuickTips();

    return (
        <div className={`Grid Grid--withGutter ${className ? className : ""}`}>
            {(title || instructions) && (
                <div className="Grid-cell">
                    {title && <h3 className="u-text-md">{title}</h3>}
                    {instructions && <p>{instructions}</p>}
                </div>
            )}
            {showQuickTips && help && (
                <div className="Grid-cell">
                    <Alert type="help" text={help} className="u-mb-0" />
                </div>
            )}
            {children}
        </div>
    );
}
