import React from "react";
import { withActions } from "containers/HOC";
import { push } from "connected-react-router";
import ActionLink from "./ActionLink";

export const calendarMonths = ["July", "August", "September", "October", "November", "December", "January", "February", "March", "April", "May", "June"];

const _gotoUrl = (url, push) => (e) => {
    if (url && push) {
        e.preventDefault();
        push(url);
    }
};

const CalendarLine = ({ item, i, months, allowDelete, onDelete, push, column }) => {
    return (
        <tr key={i} className={"Calendar-slots" + ((item.url || item.edit) && " hover u-link")} onClick={item.url ? _gotoUrl(item.url, push) : item.edit ? item.edit : undefined}>
            <td className="td--keyValuePair">
                <span className="u-flex u-flexAlignItemsCenter">
                    {item.alertIcon && (
                        <span className={`Todo Todo--${item.alertIcon} ${item.alertIcon === "error" ? "error-anchor" : ""}`}>
                            <i className="icon icon-alert" />
                        </span>
                    )}
                    {item.titleIcon && <img id={`calendar.titleIcon.${item.id}`} src={item.titleIcon} className="u-mr-xs u-p-5" width="35" height="35" alt={item.title} />}
                    <span id={`calendar.title.${item.id}`} className="td-key">
                        {item.title}
                    </span>
                </span>
                <span id={`calendar.subTitle.${item.id}`} className="td-value-text">
                    {item.subTitle}
                </span>
            </td>
            {column && <td className="Calendar-slot">{item.firstColumn}</td>}
            {item.noMonthsMessage && item.slots.length === 0 && (
                <td colSpan="12">
                    <div id={`calendar.noMonthsMessage.${item.id}`} className="u-mt-0">
                        {item.noMonthsMessage}
                    </div>
                </td>
            )}
            {item.slots.length > 0 &&
                months.map((calendarMonth, i) => {
                    const slot = item.slots.find((slot) => slot.monthIndx === i) || item.slots.find((slot) => slot.month === calendarMonth);
                    if (slot) {
                        return (
                            <td key={i} className="Calendar-slot">
                                {slot.icon && <img id={`calendar.${calendarMonth}.icon.${item.id}`} src={slot.icon} width={45} height={45} className="Calendar-icon u-p-xxs" alt="Calendar icon" />}
                                {slot.iconText && (
                                    <span id={`calendar.${calendarMonth}.iconText.${item.id}`} className="Calendar-icon-text">
                                        {slot.iconText}
                                    </span>
                                )}
                                {slot.text && <span id={`calendar.${calendarMonth}.text.${item.id}`}>{slot.text}</span>}
                            </td>
                        );
                    } else {
                        return <td key={i} className="Calendar-slot"></td>;
                    }
                })}
            {item.actions && <td>{item.actions}</td>}
            {allowDelete && (
                <td>
                    <ActionLink id={`delete-cal-${item.id}`} className="IconLink--cross-circle" onClick={() => onDelete(item.id)} title="Delete">
                        <span></span>
                    </ActionLink>
                </td>
            )}
        </tr>
    );
};

const Calendar = (props) => {
    const { data, push, column } = props;
    const months = data.length > 0 && data[0].months ? data[0].months : calendarMonths;

    return (
        <div className="Table">
            <table className="Calendar">
                <thead>
                    <tr className="Calendar-months">
                        <th className="Calendar-title">Name</th>
                        {column && <th className="Calendar-month">{column}</th>}
                        {months.map((m, i) => (
                            <th key={i} className="Calendar-month">
                                {m.substring(0, 3)}
                            </th>
                        ))}
                        {data.some((i) => i.actions) && <th className="th--shrink">&nbsp;</th>}
                        {props.allowDelete && <th className="th--shrink">&nbsp;</th>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, i) => {
                        return <CalendarLine key={i} item={item} i={i} months={months} allowDelete={props.allowDelete} onDelete={props.onDelete} push={push} column={column} />;
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default withActions({ push })(Calendar);
