import { useMutation } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import { useModal } from "common/hooks";
import { httpClient } from "common/httpClient";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import { DecimalNumberField, TextField } from "components/FormFields";
import { getPaymentsByMonthYear, getBalanceSummary } from "containers/Admin/_actions";
import { useDispatch } from "react-redux";

function AddBankDepositModal({ viewModel, close }) {
    const createPaymentAsync = useCreatePaymentAsync();

    const validate = (formValues) => {
        const validation = { paymentTransaction: {} };

        validation.paymentTransaction.accountId = FormUtils.validators.required(formValues.paymentTransaction.accountId);
        validation.paymentTransaction.amount = FormUtils.validators.required(formValues.paymentTransaction.amount);
        validation.paymentTransaction.amount = validation.paymentTransaction.amount || FormUtils.validators.range(-100000, 100000)(formValues.paymentTransaction.amount);
        validation.paymentTransaction.amount = validation.paymentTransaction.amount || (formValues.paymentTransaction.amount === 0 ? "Value must be greater or less than 0" : undefined);
        validation.paymentTransaction.reference = FormUtils.validators.required(formValues.paymentTransaction.reference);
        validation.paymentTransaction.reference = validation.paymentTransaction.reference || FormUtils.validators.maxLength(50)(formValues.paymentTransaction.reference);

        return validation;
    };

    const submitAsync = async (formValues) => {
        try {
            await createPaymentAsync(formValues.paymentTransaction, viewModel.yearMonth, viewModel.accountId);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    };

    return (
        <Form initialValues={viewModel} validate={validate} onSubmit={submitAsync}>
            {({ handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Add bank deposit for org" fluid skinny submitting={submitting} close={close}>
                            <ModalBody error={submitError}>
                                <Grid>
                                    <GridCell>
                                        <Field name="organisationName" label="Organisation" disabled component={TextField} />
                                        <Field name="paymentTransaction.amount" label="Amount" placeholder="Payment amount" dataWidth="50" required component={DecimalNumberField} decimalPlaces={2} />
                                        <Field name="paymentTransaction.reference" label="Reference" placeholder="Payment reference" required component={TextField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit primary waiting={submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useAddBankDepositModal() {
    const [modal, openModal] = useModal(AddBankDepositModal);

    const open = (viewModel) => {
        const modalProps = {
            viewModel
        };
        openModal(modalProps);
    };

    return [modal, open];
}

function useCreatePaymentAsync() {
    const dispatch = useDispatch();

    const mutation = useMutation({
        mutationFn: async (request) => {
            try {
                await httpClient.post(`admin/payments`, request.payment);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            dispatch(getPaymentsByMonthYear(variables.yearMonth, variables.orgId));
            dispatch(getBalanceSummary(variables.yearMonth));
        }
    });

    return (payment, yearMonth, orgId) => mutation.mutateAsync({ payment, yearMonth, orgId });
}