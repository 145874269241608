import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { useAuthContext, useConfirm, useRefData } from "common/hooks";
import { httpClient } from "common/httpClient";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import ActionLink from "components/ActionLink";
import { ZendeskLink } from "components/Help";
import { useMyFarmGroups } from "containers/hooks";

export default function MyFarmGroups() {
    const confirm = useConfirm();
    const refData = useRefData();
    const authContext = useAuthContext();

    const { isFetching, isLoading, data: myFarmGroups } = useMyFarmGroups();
    const deleteFarmGroupAsync = useDeleteFarmGroupAsync();

    const deleteFarmGroup = (farmGroup) => {
        confirm(`Are you sure you want to delete this farm group?`, async () => {
            await deleteFarmGroupAsync(farmGroup);
        });
    }

    const info = (
        <>
            The following is a list of groups that you are the administrator of. You can view aggregated reporting and benchmarking for the group by selecting from the list. To see a user guide on farm groups,{" "}
            <b>
                <ZendeskLink title="click here" url="https://support.overseer.org.nz/hc/en-us/articles/900000809586" target="_blank" rel="noopener noreferrer" id="lnk-open-guide" />
            </b>
        </>
    );

    const data = myFarmGroups?.filter((farmGroup) => farmGroup.role === "Admin" && farmGroup.id !== authContext.accountId) || [];
    if (data.length === 0) {
        return null;
    }

    return (
        <>
            <Panel title="Farm groups" iconClassName="IconLink--group" info={info} waiting={isFetching} loading={isLoading}>
                <PanelBody>
                    <SortableTable data={data} defaultSortCriteria={{ fieldName: "createdOn", fieldType: "date", desc: true }}>
                        {({ data, sortCriteria, handleSort }) => {
                            return (
                                <table>
                                    <thead>
                                        <tr>
                                            <SortableTableHeader label="Name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                            <SortableTableHeader label="Created" fieldName="createdOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                            <SortableTableHeader label="Role" fieldName="role" sortCriteria={sortCriteria} handleSort={handleSort} />
                                            <SortableTableHeader label="Visibility" fieldName="farmGroupVisibility" sortCriteria={sortCriteria} handleSort={handleSort} />
                                            <SortableTableHeader label="Invited" fieldName="invitedCount" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                            <SortableTableHeader label="Members" fieldName="memberCount" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                            <th className="th--shrink"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((farmGroup) => {
                                            const isFarmGroupAdmin = farmGroup.role === "Admin";
                                            return (
                                                <tr key={farmGroup.id} className="hover">
                                                    <td>
                                                        <Link to={`/app/farmGroup/${farmGroup.id}/aggregated`} id={`view-farm-group-${farmGroup.id}`} className="u-textBold">
                                                            {farmGroup.name}
                                                        </Link>
                                                    </td>
                                                    <td>{utils.dateOnly(farmGroup.createdOn)}</td>
                                                    <td>{farmGroup.role}</td>
                                                    <td>{utils.valueToText(refData.farmGroupVisibility, farmGroup.farmGroupVisibility)}</td>
                                                    <td>{farmGroup.numInvited}</td>
                                                    <td>{farmGroup.numMembers}</td>
                                                    <td>
                                                        {isFarmGroupAdmin && farmGroup.numMembers === 0 && farmGroup.id !== authContext.accountId && (
                                                            <ActionLink id={`delete-${farmGroup.id}`} onClick={() => deleteFarmGroup(farmGroup)} className="IconLink--trash u-link">
                                                                Delete
                                                            </ActionLink>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )
                        }}
                    </SortableTable>
                </PanelBody>
            </Panel>
        </>
    )
}

function useDeleteFarmGroupAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (farmGroup) => {
            try {
                await httpClient.del(`farmGroups/${farmGroup.id}`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 404) {
                    // Do nothing, the farm has already been deleted
                }
                else {
                    throw new Error(error.message);
                }
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["farm-details"] });
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-group-details"] });
        }
    });

    return (farmGroup) => mutation.mutateAsync(farmGroup);
}
