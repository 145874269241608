import { useEffect, useState, useCallback } from "react";
import { useGoogleApi } from "components/Map/hooks";

export default function MapLabel({ map, position, innerHTML, className, mapPane, hidden }) {
    const google = useGoogleApi();
    const [overlay, setOverlay] = useState();
    const [div, setDiv] = useState();

    // Called when the popup is added to the map - e.g. overlay.setMap(map)
    const onAdd = useCallback(() => {
        if (overlay && div) {
            const panes = overlay.getPanes();
            if (panes) {
                if (!mapPane) panes[MAP_PANES.MARKER_LAYER].appendChild(div);
                else panes[mapPane].appendChild(div);
            }
        }
    }, [overlay, div, mapPane]);

    // Called when the popup is removed from the map - e.g. overlay.setMap(null)
    const onRemove = useCallback(() => {
        if (div && div.parentElement) {
            div.parentElement.removeChild(div);
        }
    }, [div]);

    // Called each frame when the popup needs to draw itself.
    const draw = useCallback(() => {
        if (google && position && overlay && div) {
            const projection = overlay.getProjection();
            if (projection) {
                // Get the center from position.
                const bounds = new google.maps.LatLngBounds();
                bounds.extend(position);
                const center = bounds.getCenter();

                // Make sure label stays in same lat/lng relative to map canvas.
                const newPosition = projection.fromLatLngToDivPixel(center);
                div.style.left = `${newPosition.x}px`;
                div.style.top = `${newPosition.y}px`;

                // Hide/show label.
                if (hidden) {
                    div.style.display = "none";
                } else {
                    div.style.display = "block";
                }

                // Adjust font size with zoom.
                div.style.fontSize = `${map.getZoom() * 0.7}px`;
            }
        }
    }, [google, map, overlay, div, position, hidden]);

    // Set the overlay.
    useEffect(() => {
        if (google && !overlay) {
            const newOverlay = new google.maps.OverlayView();
            setOverlay(newOverlay);
        }
        return () => {
            if (overlay) {
                overlay.setMap(null);
            }
        };
    }, [google, overlay]);

    // Set the content.
    useEffect(() => {
        if (overlay) {
            const div = document.createElement("div");
            div.classList.add("mapLabel");
            if (className) div.classList.add(className);
            div.innerHTML = innerHTML;
            setDiv(div);
        }
    }, [overlay, className, innerHTML]);

    // google.maps.OverlayView requires implementations of onAdd, onRemove, draw.
    // Set onAdd, onRemove, draw implementations and add the overlay to the map.
    useEffect(() => {
        if (map && overlay && div) {
            overlay.onAdd = onAdd;
            overlay.onRemove = onRemove;
            overlay.draw = draw;
            overlay.setMap(map);
        }
    }, [map, overlay, div, onAdd, onRemove, draw]);

    return null;
}

export const MAP_PANES = {
    FLOAT_PANE: "floatPane",
    MAP_PANE: "mapPane",
    MARKER_LAYER: "markerLayer",
    OVERLAY_LAYER: "overlayLayer",
    OVERLAY_MOUSE_TARGET: "overlayMouseTarget",
};
