import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { isIE } from "react-device-detect";
import { store, history, persistor } from "./store";
import App from "containers/App/Index";
import Loader from "./components/Loader";
import BrowserNotSupported from "components/BrowserNotSupported";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

const onBeforeLift = () => {
    //console.log('Before lift')
    // take some action before the gate lifts
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <>
        {isIE ? (
            <BrowserNotSupported />
        ) : (
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <PersistGate loading={<Loader message="Loading..." />} onBeforeLift={onBeforeLift} persistor={persistor}>
                        <App history={history} persistStore={persistor} />
                        <ReactQueryDevtools />
                    </PersistGate>
                </Provider>
            </QueryClientProvider>
        )}
    </>
);
