import React from "react";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import CreditCards from "components/CreditCards";
import Button from "components/Button/Button";

const CreditCardModal = ({ cards = [], close, submitCard }) => {
    const [cardId, setCardId] = React.useState();

    const onCardSelected = (card) => {
        setCardId(card.id);
    };

    const sendCardId = () => {
        submitCard(cardId);
        close();
    };

    return (
        <Modal title="Saved cards" skinny close={close}>
            <ModalBody>
                <div className="h2 u-mb-lg u-mt-md">Payment options</div>
                <CreditCards className="u-flexJustifyCenter" cards={cards} cardId={cardId} onCardSelected={onCardSelected} />
            </ModalBody>
            <ModalFooter>
                <ModalFooterLeft>
                    <Button id="cancel" onClick={close} secondary>
                        Cancel
                    </Button>
                </ModalFooterLeft>
                <ModalFooterRight>
                    <Button id="submit" disabled={!cardId} onClick={() => sendCardId()} primary>
                        Save
                    </Button>
                </ModalFooterRight>
            </ModalFooter>
        </Modal>
    );
};
export default CreditCardModal;
