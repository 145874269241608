import { useState } from "react";
import { useDispatch } from "react-redux";
import Spinner from "components/Spinner/Spinner";
import { setFormState } from "containers/App/_actions";
import { useFormState } from "common/hooks";

export default function TileSubPanel({ id, title, children, waiting, loading, stickyHeaderName, midBlue, skyBlue, green, orange, className }) {
    const { collapsed, toggleCollapse } = useToggleCollapse(stickyHeaderName);
    const classNames = buildClassName(collapsed, midBlue, skyBlue, green, orange, loading, waiting, className);

    return (
        <div id={id} className={classNames}>
            {title && (
                <div className={`FarmTableGroup-heading u-link u-print-none`} onClick={toggleCollapse}>
                    <span className="u-mr-sm u-textNoWrap">
                        {title}
                        {collapsed && <span> ...</span>}
                    </span>
                    {(loading || waiting) && <Spinner />}
                    <i className={`Collapsible-head-affordance icon  ${collapsed ? "icon-arrow-down" : "icon-arrow-up"}`}></i>
                </div>
            )}
            {!collapsed && loading && (
                <div className="HeroPanel">
                    <div className="Tile-body-message">
                        <Spinner dark />
                        <p className="lead">Loading...</p>
                    </div>
                </div>
            )}
            {!collapsed && !loading && children}
        </div>
    )
}

function useToggleCollapse(stickyHeaderName) {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch();
    const formState = useFormState();
    const collapsed = stickyHeaderName ? formState[stickyHeaderName] : isCollapsed;

    const toggleCollapse = () => {
        if (stickyHeaderName) {
            dispatch(setFormState(stickyHeaderName, !collapsed));
        }
        else {
            setIsCollapsed(!collapsed);
        }
    };

    return {
        collapsed,
        toggleCollapse
    }
}

function buildClassName(isCollapsed, midBlue, skyBlue, green, orange, loading, waiting, className) {
    let classNames = "u-pos-rel u-overflow-hidden FarmTableGroup";

    if (isCollapsed) {
        classNames += " u-print-none";
    }

    if (midBlue) {
        classNames += " midblue";
    } else if (skyBlue) {
        classNames += " skyblue";
    } else if (green) {
        classNames += " green";
    } else if (orange) {
        classNames += " orange";
    } else {
        classNames += " darkblue";
    }

    if (loading) {
        classNames += " Tile-loading";
    }

    if (waiting) {
        classNames += " waiting";
    }

    if (className) {
        classNames += ` ${className}`;
    }

    return classNames;
}
