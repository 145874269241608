import React from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import { getAnalysisComparison } from "./_actions";
import AnalysisComparison from "components/Comparison/AnalysisComparison";
import { getAnalysisDisplayName } from "containers/FarmHome/_utils";

const AnalysisComparisonById = ({ farm, fromId, toId, getAnalysisComparison, ...rest }) => {
    const [loading, setLoading] = React.useState(true);
    const [comparison, setComparison] = React.useState();

    React.useEffect(() => {
        const getComparison = async (farmId, fromId, toId) => {
            setLoading(true);
            try {
                const results = await getAnalysisComparison(farmId, fromId, toId);
                const fromName = getAnalysisDisplayName(farm, fromId);
                const toName = getAnalysisDisplayName(farm, toId);
                setComparison({
                    ...results,
                    fromName,
                    toName,
                });
            } catch (error) {
                setComparison({ error });
            } finally {
                setLoading(false);
            }
        };
        getComparison(farm.id, fromId, toId);
    }, [farm, fromId, toId, getAnalysisComparison]);

    return <AnalysisComparison loading={loading} comparison={comparison} {...rest} />;
};

export default compose(withAppContext, withActions({ getAnalysisComparison }))(AnalysisComparisonById);
