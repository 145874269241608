import React from "react";
import { compose, withActions, withAppContext } from "containers/HOC";
import { upgradeAnalysis, upgradeFarmDetails } from "../_actions";
import ActionLink from "components/ActionLink";

const AnalysisUpgradeWorker = ({ sent = [], duplicates = [], index, runId, worker, upgradeAnalysis, upgradeFarmDetails, onSuccess, onException, deleteWorker }) => {
    React.useEffect(() => {
        const upgradeEntity = async (runId, worker) => {
            if (worker.farmsOnly) {
                await upgradeFarmDetails(runId, worker.entityId);
            } else {
                await upgradeAnalysis(runId, worker.entityId);
            }
        };

        const upgrade = async (runId, worker, onSuccess, onException) => {
            await upgradeEntity(runId, worker)
                .then(() => {
                    if (sent.includes(worker.entityId)) duplicates.push(worker.entityId);
                    else sent.push(worker.entityId);
                    if (onSuccess) onSuccess(runId, worker);
                })
                .catch((e) => {
                    if (onException) {
                        const { exception = e } = e.response || {};
                        const workerException = {
                            workerId: worker.id,
                            farmId: e.response?.farmId || (worker.farmsOnly ? worker.entityId : undefined),
                            budgetBaseId: e.response?.budgetBaseId || (!worker.farmsOnly ? worker.entityId : undefined),
                            httpStatus: e.status,
                            details: exception,
                        };
                        onException(runId, worker.entityId, workerException);
                    }
                });
        };

        if (worker && worker.counter >= 0) {
            if (worker.shutdownWhenDone && onSuccess) onSuccess(runId, worker);
            else upgrade(runId, worker, onSuccess, onException);
        }
    }, [sent, duplicates, runId, worker, onSuccess, onException, upgradeAnalysis, upgradeFarmDetails]);

    return (
        <div style={{ width: "165px", borderRadius: "3px", border: "1px solid #efefef" }} className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter u-mr-sm u-p-10 u-mb-sm">
            <h5 className="u-mt-0">Worker {worker.number}:</h5>
            <span className="u-textSuccess">{worker.counter.toLocaleString()}</span>
            <ActionLink onClick={() => deleteWorker(worker.entityId)} className="icon icon-trash" title={`Delete Worker ${worker.number}`} />
        </div>
    );
};

export default compose(withAppContext, withActions({ upgradeAnalysis, upgradeFarmDetails }))(AnalysisUpgradeWorker);
