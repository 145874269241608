import { Panel, PanelBody } from "components/Panel";
import AnalysisCard from "./AnalysisCard";

export default function AnalysesByTypePanel({ title, farm, analyses, showVisibility }) {
    return (
        <Panel title={title} midBlue className="u-mb-lg">
            <PanelBody grey>
                {analyses.map((analysis) => (
                    <AnalysisCard key={analysis.id} farm={farm} analysis={analysis} parentTitle={title} showVisibility={showVisibility} />
                ))}
            </PanelBody>
        </Panel>
    )
}
