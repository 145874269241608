import { Component } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import * as domain from "common/domain";
import * as icons from "common/icons";
import moment from "moment";
import * as cropUtils from "containers/BudgetHome/Crops/_utils";
import * as effluentUtils from "containers/BudgetHome/Effluent/_utils";
import * as fertUtils from "containers/BudgetHome/Fertiliser/_utils";
import * as animalUtils from "containers/BudgetHome/Animals/_utils";
import Watermark from "components/Watermark";
import ActionLink from "components/ActionLink";
import { getBlockIconsViewModel } from "common/viewModels";
import { BlockResultsWidget } from "components/ResultsWidgets";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function BlockListCard({ farm, analysis, block }) {
    const refData = useRefData();

    return <BlockListCardClassComponent farm={farm} analysis={analysis} block={block} refData={refData} />;
}

class BlockListCardClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClosed: !props.expandAll,
        };
    }

    _renderMonth(blockActivity, month, i) {
        const monthActivity = blockActivity.activity.find((a) => a.month === month.month && a.reportingYear === month.reportingYear);
        return monthActivity ? monthActivity.content : <td key={i} className="Calendar-slot"></td>;
    }

    _toggleMe = (e) => {
        e.preventDefault();
        var cmp = this;
        this.setState({ isClosed: !cmp.state.isClosed });
    };

    _rotationYear = (block, useFarmYear) => {
        const defaultEndMonth = useFarmYear ? domain.farmYear[11] : domain.calendarYear[11];
        const endMonth = block.monthResown && domain.calendarYear.includes(block.monthResown) ? block.monthResown : defaultEndMonth;
        const indx = domain.calendarYear.indexOf(endMonth);
        const months = 12;
        const result = [];
        for (let i = 0; i < months; i++) {
            const endDate = moment(`2000-${indx + 1}-01`, "YYYY-MM-DD");
            result.push({ month: endDate.add(i + 1, "months").format("MMMM"), reportingYear: months === 24 ? i >= 12 : true });
        }
        return result;
    };

    _getCropData = (block) => {
        const { crops = [] } = block;
        const rotationYear = cropUtils.rotationYear(block);

        const result = rotationYear.map((month, indx) => {
            let icon = undefined;
            let iconText = undefined;
            /* eslint-disable no-unused-vars */
            for (const crop of crops) {
                if (cropUtils.isCropMonth(rotationYear, crop, indx)) {
                    icon = utils.getCropIcon(crop);
                    iconText = crop.name ? crop.name : cropUtils.cropSubHeading(crop, this.props.refData);
                    break;
                }
            }
            return {
                month: month,
                icon: icon,
                iconText: icon && iconText,
                monthIndx: indx,
            };
        });

        //Prior land use
        const { cropBlock } = block;
        if (cropBlock && cropBlock.preCrop) {
            const preCropIcon = utils.getPreCropIcon(cropBlock.preCrop);
            const firstCropEventIndx = cropUtils.getFirstEventIndex(block, rotationYear);

            for (let i = 0; i < firstCropEventIndx; i++) {
                const slot = result.find((s) => s.monthIndx === i);
                if (slot) {
                    slot.icon = preCropIcon;
                    slot.preCrop = true;
                }
            }
        }

        return result;
    };

    render() {
        const { farm, analysis, block, refData } = this.props;
        const { swardAnimalTypes = [] } = refData;
        const _blockIcon = utils.getBlockIcon(block.type);
        const _blockType = utils.getBlockTypeText(refData, block);
        const isPublication = analysis.class === "PublishedAnalysis";

        const rotationYear = cropUtils.rotationYear(block);
        const reportingYear = rotationYear.length > 12 ? rotationYear.slice(12) : rotationYear;
        const blockIcons = getBlockIconsViewModel(refData, analysis, block);
        const blockActivity = [];

        const _toSentenceCase = (text) => {
            if (!text || text.length < 2) return text;
            return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
        };

        //Crops
        const { crops = [] } = block;
        const { cropBlock = {} } = block;
        if (crops.length > 0 || cropBlock.preCrop) {
            let cropData = this._getCropData(block, refData);
            cropData = cropData.slice(Math.max(cropData.length - 12, 1));

            const cropAct = cropData
                .filter((m) => m.icon)
                .map((m) => {
                    return {
                        month: m.month,
                        reportingYear: true,
                        content: (
                            <td key={uuidv4()} className="Calendar-slot">
                                <img className="Calendar-icon u-p-xxs" src={m.icon} alt="Crop icon" style={{ opacity: m.preCrop ? "0.2" : "1" }} />
                                <span className="Calendar-icon-text">{m.iconText}</span>
                            </td>
                        ),
                    };
                });
            const cropLink = block.type === "ProductiveCrop" ? `/app/farm/${farm.id}/analysis/${analysis.id}/crops/crop/${block.id}` : `/app/farm/${farm.id}/analysis/${analysis.id}/crops/rotation/${block.id}`;
            const titleContent = isPublication ? (
                <div className="td-value">
                    <span className="td-value-text">Crops</span>
                </div>
            ) : (
                <Link to={cropLink} className="td-value">
                    <span className="td-value-text">Crops</span>
                    <span className="icon icon-edit" />
                </Link>
            );

            const cropActivity = {
                id: uuidv4(),
                titleContent: titleContent,
                heading: "Crops",
                activity: cropAct,
            };
            if (cropActivity.activity.length > 0) {
                blockActivity.push(cropActivity);
            }
        }

        //Fruit
        if (block.fruit) {
            const fruitIcon = { icon: utils.getFruitIcon(block.fruit.cropType), name: _toSentenceCase(block.fruit.cropType) };
            const monthFruitAct = domain.farmYear.map((m) => {
                return {
                    month: m,
                    reportingYear: true,
                    content: (
                        <td key={uuidv4()} className="Calendar-slot">
                            <img className="Calendar-icon u-p-xxs" src={fruitIcon.icon} width={45} height={45} alt="Produce icon" />
                        </td>
                    ),
                };
            });
            const titleContent = isPublication ? (
                <div className="td-value">
                    <span className="td-value-text">{block.fruit.cropType}</span>
                </div>
            ) : (
                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/crops/${utils.valueToText(refData.blockTypes, block.type)}/${block.id}`} className="td-value">
                    <span className="td-value-text">{block.fruit.cropType}</span>
                    <span className="icon icon-edit" />
                </Link>
            );
            const fruitActivity = {
                id: uuidv4(),
                titleContent: titleContent,
                heading: "Fruit Crop",
                activity: monthFruitAct,
            };
            if (fruitActivity.activity.length > 0) {
                blockActivity.push(fruitActivity);
            }

            if (block.fruit.swardAnimalSource === "NonFarmAnimals") {
                const swardAnimalName = utils.valueToText(swardAnimalTypes, block.fruit.swardAnimalType);
                const swardAnimalIcon = cropUtils.getNonFarmAnimalIcon(block.fruit.swardAnimalType);

                const label = (
                    <div className="td-value">
                        <span className="td-value-text">{swardAnimalName}</span>
                    </div>
                );

                const noMonthsMessage = (
                    <span className="u-flex u-flexAlignItemsCenter">
                        <img src={swardAnimalIcon} className="u-p-5" width="45" height="45" alt={swardAnimalName} />
                        <span>Using default grazing months</span>
                    </span>
                );
                const nonFarmAct = [];
                nonFarmAct.push({
                    content: noMonthsMessage,
                    reportingYear: true,
                });

                blockActivity.push({ id: uuidv4(), heading: "Non farm animals", titleContent: label, activity: nonFarmAct });
            }
        }

        //Pasture
        if (block.pasture && block.pasture.pastureCategory) {
            const monthFruitAct = domain.farmYear.map((m) => {
                return {
                    month: m,
                    reportingYear: true,
                    content: (
                        <td key={uuidv4()} className="Calendar-slot">
                            <img className="Calendar-icon u-p-xxs" src={icons.pasture} width={45} height={45} alt="Pasture icon" />
                        </td>
                    ),
                };
            });
            const titleContent = isPublication ? (
                <div className="td-value">
                    <span className="td-value-text">{utils.valueToText(refData.pastureTypes, _toSentenceCase(block.pasture.pastureCategory))}</span>
                </div>
            ) : (
                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/crops/${utils.valueToText(refData.blockTypes, block.type)}/${block.id}`} className="td-value">
                    <span className="td-value-text">{utils.valueToText(refData.pastureTypes, _toSentenceCase(block.pasture.pastureCategory))}</span>
                    <span className="icon icon-edit" />
                </Link>
            );
            const pastureActivity = {
                id: uuidv4(),
                titleContent: titleContent,
                heading: "Pasture Crop",
                activity: monthFruitAct,
            };
            if (pastureActivity.activity.length > 0) {
                blockActivity.push(pastureActivity);
            }
        }

        if (["ProductiveCrop", "FodderCrop"].includes(block.type)) {
            const { animalTypes = [] } = refData;

            const f = (budget, id) => budget.enterprises.find((e) => e.id === id) || animalTypes.find((a) => a.value === id);

            var _enterpriseMap = cropUtils.getAnimalsOnCropBlock(block, rotationYear, analysis);

            for (var id in _enterpriseMap) {
                const enterprise = id === "NonFarmAnimals" ? { isNonFarmAnimal: true } : f(analysis, id);
                if (enterprise) {
                    const name = enterprise.isNonFarmAnimal ? "Non farm animals" : utils.valueToText(refData.enterpriseTypes, enterprise.type);
                    const months = _enterpriseMap[id];
                    let icon = enterprise.isNonFarmAnimal ? null : utils.getAnimalIcon(enterprise.type);

                    const cropLink = block.type === "ProductiveCrop" ? `/app/farm/${farm.id}/analysis/${analysis.id}/crops/crop/${block.id}` : `/app/farm/${farm.id}/analysis/${analysis.id}/crops/rotation/${block.id}`;
                    const titleContent = isPublication ? (
                        <div className="td-value">
                            <span className="td-value-text">Crops</span>
                        </div>
                    ) : (
                        <Link to={cropLink} className="td-value">
                            <span className="td-value-text">Crops</span>
                            <span className="icon icon-edit" />
                        </Link>
                    );

                    const slots = animalUtils.animalMonthlyCounts(enterprise);

                    const monthAnimals = reportingYear.map((m) => {
                        const animalMonth = {
                            month: m,
                            reportingYear: true,
                        };

                        const monthData = months.find((m1) => m1.month === m);
                        if (monthData) {
                            const hasStockOnFarm = enterprise.type === "Other" || slots.find((s) => s.month === m && s.text > 0) || monthData.isNonFarmAnimals;
                            const monthIcon = monthData.isNonFarmAnimals ? cropUtils.getNonFarmAnimalIcon(monthData.type) : icon;
                            animalMonth.content = (
                                <td key={uuidv4()} className="Calendar-slot">
                                    {hasStockOnFarm && <img className="Calendar-icon u-p-xxs" src={monthIcon} width={45} height={45} alt="Animal icon" />}
                                    {hasStockOnFarm && <span className="Calendar-icon-text">{monthData.text}</span>}
                                </td>
                            );
                        } else {
                            animalMonth.content = <td key={uuidv4()} className="Calendar-slot"></td>;
                        }

                        return animalMonth;
                    });

                    const animalsActivity = {
                        id: uuidv4(),
                        titleContent: titleContent,
                        heading: name,
                        activity: monthAnimals,
                    };

                    if (animalsActivity.activity.length > 0) {
                        blockActivity.push(animalsActivity);
                    }
                }
            }
        }

        //Animals
        if (block.animals && block.animals.length > 0) {
            /* eslint-disable no-unused-vars */
            for (const animals of block.animals) {
                const enterprise = analysis.enterprises.find((e) => e.id === animals.enterpriseId);

                const name = utils.valueToText(refData.enterpriseTypes, enterprise.type);

                const animalResults = block.currentResults && block.currentResults.pastureResults && block.currentResults.pastureResults.pastureRsu ? block.currentResults.pastureResults.pastureRsu[enterprise.id] : undefined;

                const titleContent = isPublication ? (
                    <div className="td-value">
                        <span className="td-value-text">Animal distribution</span>
                    </div>
                ) : (
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/animals/distribution`} className="td-value">
                        <span className="td-value-text">Animal distribution</span>
                        <span className="icon icon-edit" />
                    </Link>
                );

                const enterpriseIcon = { icon: utils.getAnimalIcon(enterprise.type), name: _toSentenceCase(name) };

                let monthAnimals = animals.months.map((m) => {
                    var blockResults = animalResults ? animalResults.find((r) => r.month === m) : undefined;
                    var rsu = blockResults && blockResults.value > 0 ? `${blockResults.value.toFixed(2)} rsu` : "-";
                    return { month: m, reportingYear: true, text: rsu };
                });

                if (monthAnimals.length === 0) {
                    monthAnimals = (animalResults || [])
                        .filter((m) => m.value > 0)
                        .map((m) => {
                            return { month: m.month, reportingYear: true, text: `${m.value.toFixed(2)} rsu` };
                        });
                }

                monthAnimals.forEach((m) => {
                    m.content = (
                        <td key={uuidv4()} className="Calendar-slot">
                            <img className="Calendar-icon u-p-xxs" src={enterpriseIcon.icon} width={45} height={45} alt="Animal icon" />
                            <span className="Calendar-icon-text">{m.text}</span>
                        </td>
                    );
                });

                const animalsActivity = {
                    id: uuidv4(),
                    titleContent: titleContent,
                    heading: name,
                    activity: monthAnimals,
                };

                if (animalsActivity.activity.length > 0) {
                    blockActivity.push(animalsActivity);
                }
            }
        }
        if (block.type === domain.BlockType.ProductiveOutdoorPigs) {
            const pigIcon = { icon: utils.getAnimalIcon("outdoorpigs"), name: "Outdoor pigs" };

            const titleContent = (
                <div className="td-value">
                    <span className="td-value-text">Outdoor pigs</span>
                </div>
            );

            const noMonthsMessage = (
                <span className="u-flex u-flexAlignItemsCenter">
                    <img src={pigIcon.icon} className="u-p-5" width="45" height="45" alt={pigIcon.name} />
                    <span>Using default distribution</span>
                </span>
            );

            const pigActivity = {
                id: id,
                titleContent: titleContent,
                heading: "Outdoor pigs",
                activity: [{ content: noMonthsMessage, reportingYear: true }],
            };
            blockActivity.push(pigActivity);
        }

        //Irrigation
        if (analysis.irrigators && analysis.irrigators.length > 0) {
            /* eslint-disable no-unused-vars */
            for (const irrigator of analysis.irrigators) {
                const name = utils.valueToText(this.props.refData.irrigatorTypes, irrigator.type);
                const irrigatorResults = block.currentResults && block.currentResults.irrigationResults ? block.currentResults.irrigationResults[irrigator.id] : undefined;

                const titleContent = isPublication ? (
                    <div className="td-value">
                        <span className="td-value-text">{name}</span>
                    </div>
                ) : (
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/irrigation/${irrigator.id}`} className="td-value">
                        <span className="td-value-text">{name}</span>
                        <span className="icon icon-edit" />
                    </Link>
                );

                const irrigatorIcon = { icon: utils.getIrrigatorIcon(irrigator.type), name: _toSentenceCase(name) };

                const irrigatorActivity = {
                    id: irrigator.id,
                    titleContent: titleContent,
                    heading: "Irrigator",
                    activity: [],
                };

                /* eslint-disable no-unused-vars */
                for (const application of irrigator.applications.filter((a) => a.blockIds.includes(block.id))) {
                    /* eslint-disable no-unused-vars */
                    for (const month of application.months) {
                        var blockResults = irrigatorResults ? irrigatorResults.months.find((r) => r.month === month.month && r.reportingYear === month.reportingYear) : undefined;
                        var applied = blockResults && blockResults.applied > 0 ? blockResults.applied + " mm" : "";

                        const content = (
                            <td key={uuidv4()} className="Calendar-slot">
                                <img className="Calendar-icon u-p-xxs" src={irrigatorIcon.icon} alt={irrigatorIcon.name} />
                                <span className="Calendar-icon-text">{applied}</span>
                            </td>
                        );
                        irrigatorActivity.activity.push({
                            month: month.month,
                            content: content,
                            reportingYear: month.reportingYear,
                        });
                    }
                }
                if (irrigatorActivity.activity.length > 0) {
                    blockActivity.push(irrigatorActivity);
                }
            }
        }

        //Fertiliser
        if (analysis.fertiliser && analysis.fertiliser.length > 0) {
            /* eslint-disable no-unused-vars */
            for (const fertiliser of analysis.fertiliser) {
                const titleContent = isPublication ? (
                    <div className="td-value">
                        <span className="td-value-text">{fertiliser.productName}</span>
                    </div>
                ) : (
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/applications?productId=${fertiliser.id}`} className="td-value">
                        <span className="td-value-text">{fertiliser.productName}</span>
                        <span className="icon icon-edit" />
                    </Link>
                );

                const fertiliserIcon = { icon: utils.getFertiliserIcon(fertiliser.material), name: _toSentenceCase("Fertiliser " + fertiliser.material) };

                const fertiliserActivity = {
                    id: fertiliser.id,
                    titleContent: titleContent,
                    heading: "Fertiliser - " + fertiliser.material,
                    activity: [],
                };

                const applications = fertiliser.applications.filter((a) => a.blockIds.includes(block.id) && !a.isLastPreviousLime);

                //const blockSize = calcBlockSize(block)
                const blockSize = fertUtils.getApplicationBlockArea([block.id], analysis.blocks);

                if (applications.length > 0) {
                    if (!blockActivity.find((f) => f.id === fertiliserActivity.id)) {
                        blockActivity.push(fertiliserActivity);
                    }

                    /* eslint-disable no-unused-vars */
                    for (const month of reportingYear) {
                        const monthApps = applications.filter((a) => a.months.find((m) => m.reportingYear && m.month === month));

                        /* eslint-disable no-unused-vars */
                        const content =
                            monthApps.length > 0 ? (
                                <td key={uuidv4()} className="Calendar-slot">
                                    <img className="Calendar-icon u-p-xxs" src={fertiliserIcon.icon} alt={fertiliserIcon.name} />
                                    <span className="Calendar-icon-text"> {fertUtils.formatFertAmount(fertiliser, monthApps, analysis)}</span>
                                </td>
                            ) : (
                                <td key={uuidv4()} className="Calendar-slot"></td>
                            );

                        fertiliserActivity.activity.push({
                            month: month,
                            content: content,
                            reportingYear: true,
                        });
                    }
                }
            }
        }

        //DCD
        if (analysis.nitrateInhibitors && analysis.nitrateInhibitors.length > 0) {
            const dcdApplicationMonths = (analysis.nitrateInhibitors || []).reduce((months, nitrateInhibitor) => {
                const applicationToThisBlock = nitrateInhibitor.applications.find((a) => (a.blockIds || []).includes(block.id));
                if (applicationToThisBlock) {
                    months = applicationToThisBlock.months.map((m) => m.month);
                }
                return months;
            }, []);

            if (dcdApplicationMonths.length > 0) {
                const titleContent = isPublication ? (
                    <div className="td-value">
                        <span className="td-value-text">DCD</span>
                    </div>
                ) : (
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/dcd`} className="td-value">
                        <span className="td-value-text">DCD</span>
                        <span className="icon icon-edit" />
                    </Link>
                );

                const additiveIcon = utils.getFertiliserIcon("Soluble");

                const additiveActivity = {
                    id: uuidv4(),
                    titleContent: titleContent,
                    heading: "Additive",
                    activity: [],
                };

                /* eslint-disable no-unused-vars */
                for (const month of dcdApplicationMonths) {
                    const content = (
                        <td key={`${month}`} className="Calendar-slot">
                            <img className="Calendar-icon u-p-xxs" src={additiveIcon} alt="DCD" />
                            <span className="Calendar-icon-text"></span>
                        </td>
                    );

                    additiveActivity.activity.push({
                        month,
                        content,
                        reportingYear: true,
                    });
                }

                if (additiveActivity.activity.length > 0) {
                    blockActivity.push(additiveActivity);
                }
            }
        }

        //Effluent
        if (analysis.effluentSystem) {
            blockActivity.push(...effluentUtils._getEffluentApplicationsForSystem(farm, analysis, block, refData, analysis.effluentSystem, isPublication));
        }
        /* eslint-disable no-unused-vars */
        for (const s of analysis.structures.filter((s) => s.effluentSystem)) {
            blockActivity.push(...effluentUtils._getEffluentApplicationsForSystem(farm, analysis, block, refData, s.effluentSystem, isPublication, s));
        }
        if (analysis.outdoorPigEffluentSystem) {
            blockActivity.push(...effluentUtils._getEffluentApplicationsForOutdoorPigSystem(farm, analysis, block, refData, analysis.outdoorPigEffluentSystem, isPublication));
        }

        const isClosed = this.state.isClosed;

        //const area = block.type === domain.BlockType.FodderCrop ? block.rotationArea : block.areaInHectares

        const blockHeading = (
            <ActionLink onClick={this._toggleMe}>
                <ul className="DataWidthTable FarmTable">
                    <li className="FarmTable-title" data-width="md-100 xl-40">
                        <div className="FarmTitle">
                            <img className="FarmTitle-icon" src={_blockIcon} alt="Block Type" />
                            <div className="FarmTitle-heading">
                                <span className="FarmTitle-name" title={block.name}>
                                    {block.name}
                                </span>
                                <div className="FarmTitle-sub">{_blockType}</div>
                            </div>
                        </div>
                    </li>

                    <li className="FarmTable-summaryIcons" data-width="md-shrink xl-shrink">
                        {blockIcons.climateIcon && <img className="u-p-10" src={blockIcons.climateIcon.icon} width="40" height="40" alt="Warm" title={blockIcons.climateIcon.name} />}
                        {blockIcons.tempIcon && <img className="u-p-10" src={blockIcons.tempIcon.icon} width="40" height="40" alt={blockIcons.tempIcon.name} title={blockIcons.tempIcon.name} />}
                        {blockIcons.hasSnow && <img className="u-p-10" src={icons.snow} width="40" height="40" alt="Snow" title="Snow" />}
                        {blockIcons.fruitIcon && <img className="u-p-10" src={blockIcons.fruitIcon.icon} width="40" height="40" alt={blockIcons.fruitIcon.name} title={blockIcons.fruitIcon.name} />}
                        {blockIcons.hascropsGrain && <img className="u-p-10" src={icons.cropsGrain} width="40" height="40" alt="Grain" title="Grain" />}
                        {blockIcons.enterpriseIcons.map((i) => (
                            <img className="u-p-10" src={i.icon} key={uuidv4()} width="40" height="40" alt={i.name} title={i.name} />
                        ))}
                        {blockIcons.hasBaleage && <img className="u-p-10" src={icons.suppBaleage} width="40" height="40" alt="Supplementary Baleage" title="Supplementary Baleage" />}
                        {blockIcons.hasSoilTests && <img className="u-p-10" src={icons.soilTest} width="40" height="40" alt="Soil Tests" title="Soil Tests" />}
                        {blockIcons.hasWetlandDrainage && <img className="u-p-10" src={icons.wetlandDrainage} width="40" height="40" alt="Drainage" title="Drainage" />}
                        {blockIcons.fertiliserIcons.map((i) => (
                            <img className="u-p-10" src={i.icon} key={uuidv4()} width="40" height="40" alt={i.name} title={i.name} />
                        ))}
                        {blockIcons.irrigatorIcons.map((i) => (
                            <img className="u-p-10" src={i.icon} key={uuidv4()} width="40" height="40" alt={i.name} title={i.name} />
                        ))}
                        {blockIcons.hasSolidEffluentApp && <img className="u-p-10" src={icons.effluentSolid} width="30" height="30" alt="Solid effluent" title="Solid effluent" />}
                        {blockIcons.hasLiquidEffluentApp && <img className="u-p-10" src={icons.effluentLiquid} width="30" height="30" alt="Liquid effluent" title="Liquid effluent" />}
                    </li>
                    <li className="FarmTable-nutrientsLost u-ml-auto" data-width="md-shrink xl-shrink">
                        <BlockResultsWidget analysis={analysis} block={block} />
                    </li>
                    {block.isProductive && (
                        <li className="FarmTable-slideToggle" data-width="md-shrink xl-shrink">
                            <span className={"RoundButton RoundButton--up"} data-slidetoggle={block.id} data-target-collapsed={isClosed ? "false" : "true"}></span>
                        </li>
                    )}
                </ul>
            </ActionLink>
        );

        if (blockActivity.length === 0) {
            return <div className="FarmTable_wrapper">{blockHeading}</div>;
        }

        const year = this._rotationYear(block, true);
        return (
            <div className="FarmTable_wrapper">
                {blockHeading}
                <div id={block.id} className={"u-pos-rel u-overflow-hidden FarmTable-supplementary"} data-collapsed={isClosed ? "true" : "false"}>
                    <Watermark />
                    <div className="Table">
                        <table className="Calendar">
                            <thead>
                                <tr className="Calendar-months">
                                    <th className="Calendar-title">Name</th>
                                    {year.map((m) => (
                                        <th key={uuidv4()} className="Calendar-month">
                                            {m.month.substring(0, 3)}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {blockActivity.map((b, ix) => {
                                    return (
                                        <tr className="Calendar-slots Calendar-slots--fertiliser" key={ix}>
                                            <td className="td--keyValuePair">
                                                <span className="td-key">{b.heading}</span>
                                                <span className="td-value-text">{b.titleContent}</span>
                                            </td>
                                            {b.activity[0].month ? (
                                                year.map((m, i) => this._renderMonth(b, m, i))
                                            ) : (
                                                <td key={b.id} colSpan="12" className="Calendar-slot">
                                                    {b.activity[0].content}
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
