import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useConfirm } from "common/hooks";
import * as utils from "common/utils";
import { useAnalysisSummary } from "containers/BudgetHome";
import { useUpdateAnalysisAsync } from "containers/hooks";
import TileSubPanel from "components/TileSubPanel";
import ActionLink from "components/ActionLink";
import { useSoilModal } from "containers/Soils/SoilModal";

export default function SoilsGreenBox({ analysis }) {
    const { isFetching, isLoading, data: soils } = useSoils(analysis);
    const { expandedSoilIds, toggleSoilBlocks } = useToggleSoilBlocks();
    const [soilModal, openSoilModal] = useSoilModal();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const _editSoil = (soilId) => {
        const soilModalOptions = {
            analysis,
            saveOnSubmit: true,
            onSubmit: async (soil) => {
                const { soils = [] } = analysis;
                utils.merge(soil, soils);
                await updateAnalysisAsync(analysis);
            },
        };

        const soil = analysis.soils.find((s) => s.id === soilId);
        openSoilModal(soil, soilModalOptions);
    }

    const _deleteSoil = (soilId) => {
        confirm(`Are you sure you want to delete this soil?`, async () => {
            analysis.soils = analysis.soils.filter((s) => s.id !== soilId);
            analysis.farmSoilBlocks = analysis.farmSoilBlocks.filter((fsb) => fsb.soilId !== soilId);
            await updateAnalysisAsync(analysis);
        });
    }

    if (!isLoading && !soils)
        return null;

    return (
        <>
            <TileSubPanel title="Soils" waiting={isFetching} loading={isLoading} green stickyHeaderName="budget.soil.greenBox.collapsed">
                <div className="FarmTable_wrapper">
                    <div className="FarmTable-supplementary u-pt-md">
                        <div className="Table">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="th--shrink">Blocks</th>
                                        <th data-width="10">S-Map ref/Name</th>
                                        <th data-width="10">Order</th>
                                        <th data-width="10">Group</th>
                                        <th data-width="10">Drainage class</th>
                                        <th data-width="15">Description</th>
                                        <th className="th--shrink numeric">Total area</th>
                                        <th className="th--shrink numeric">% of productive blocks</th>
                                        <th className="th--shrink"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {soils?.map((soil) => {
                                        const soilIsNotAssociatedWithAnyBlocks = soil.totalArea === undefined;
                                        const showBlocks = expandedSoilIds.some((b) => b === soil.id);
                                        return (
                                            <Fragment key={soil.id}>
                                                <tr className={soilIsNotAssociatedWithAnyBlocks ? "u-textError" : undefined}>
                                                    <td valign="top" className="u-textCenter">
                                                        <ActionLink onClick={() => toggleSoilBlocks(soil.id)}>
                                                            <i className={`icon icon-arrow-${showBlocks ? "up" : "down"}`}></i>
                                                        </ActionLink>
                                                    </td>
                                                    <td valign="top">
                                                        <div className="u-flexSplit">
                                                            <span>{soil.name || "-"}</span>
                                                            {soil.isModified && (
                                                                <span className="IconLink--soil u-textWarning u-ml-md" title="Modified soil properties">
                                                                    Modified
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td valign="top">{soil.order}</td>
                                                    <td valign="top">{soil.group}</td>
                                                    <td valign="top">{soil.drainageClass}</td>
                                                    <td valign="top">{soil.description}</td>
                                                    <td valign="top" className="numeric">{utils.numeric.format(soil.totalArea?.value, 1)} {soil.totalArea?.unit?.notation}</td>
                                                    <td valign="top" className="numeric">{utils.numeric.format(soil.percentageOfProductiveBlocks?.value, 1)}{soil.percentageOfProductiveBlocks?.unit?.notation}</td>
                                                    <td valign="top">
                                                        <div className="u-flexSplit">
                                                            <ActionLink className="IconLink--edit" id={`${soil.id}_edit`} onClick={() => _editSoil(soil.id)} title="Edit soil" disabled={isFetching}></ActionLink>
                                                            {soilIsNotAssociatedWithAnyBlocks && (
                                                                <ActionLink className="IconLink--trash" id={`${soil.id}_remove`} onClick={() => _deleteSoil(soil.id)} title="Delete soil" disabled={isFetching}></ActionLink>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                {showBlocks && (
                                                    <tr className="tr-nested">
                                                        <td></td>
                                                        <td valign="top" colSpan="8">
                                                            <b>Blocks using this soil</b>
                                                            {soil.blocks?.length > 0
                                                                ? <ul className="disc">
                                                                    {soil.blocks?.map((block) => {
                                                                        return (
                                                                            <li key={block.id}>
                                                                                <Link id={`soil_block_${block.id}`} to={`/app/farm/${analysis.farmId}/analysis/${analysis.id}/soil/blocks/${block.id}`} className="FarmTitle-name" title={block.name}>
                                                                                    {block.name}
                                                                                </Link>
                                                                                <span> - {utils.numeric.format(block.percentageOfBlock.value)}{block.percentageOfBlock.unit.notation} of block</span>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                                : <div className="u-flex Alert Alert--info u-mt-0 u-mb-0">
                                                                    <i className="icon icon-info" />
                                                                    <div style={{ paddingTop: "3px" }}>
                                                                        <div>This soil is not used by any blocks</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                            </Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </TileSubPanel>
            {soilModal}
        </>
    )
}

function useSoils(analysis) {
    const { isFetching, isLoading, data } = useAnalysisSummary(analysis);

    const soils = data?.soils?.map(soil => {
        const soilBlocks = data?.blocks?.filter(block => block.soils?.some(s => s.soilId === soil.id));

        // A bit of "view model-esque" massaging to add blocks that use this soil to the soil object
        const blocks = soilBlocks?.reduce((results, block) => {
            const blockSoil = block.soils.find(s => s.soilId === soil.id);
            if (blockSoil) {
                results.push({
                    id: block.id,
                    name: block.name,
                    areaOfBlock: blockSoil.areaOfBlock,
                    percentageOfBlock: blockSoil.percentageOfBlock
                })
            }
            return results;
        }, []);

        return {
            ...soil,
            blocks
        }
    });

    return {
        isLoading,
        isFetching,
        data: soils
    }
}

function useToggleSoilBlocks() {
    const [expandedSoilIds, setExpandedSoilIds] = useState([]);

    const toggleSoilBlocks = (soilId) => {
        if (!expandedSoilIds.some((id) => id === soilId)) setExpandedSoilIds([...expandedSoilIds, soilId]);
        else setExpandedSoilIds(expandedSoilIds.filter((id) => id !== soilId));
    }

    return { expandedSoilIds, toggleSoilBlocks };
}
