import Spinner from "components/Spinner/Spinner";

export default function LoadingPanel({ isLoading, message = "Loading...", subMessage, children }) {
    return (
        <>
            {isLoading
                ? <div className="HeroPanel waiting">
                    <div className="Tile-body-message">
                        <Spinner dark />
                        <p className="lead">{message}</p>
                        {subMessage && <p className="u-mt-sm">{subMessage}</p>}
                    </div>
                </div>
                : children
            }
        </>
    )
}
