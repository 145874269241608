import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import { useModal } from "common/hooks";
import { httpClient } from "common/httpClient";

export default function FarmOwnershipNominationResponseModal({ nomination, close }) {
    const acceptFarmOwnershipAsync = useAcceptFarmOwnershipAsync();
    const declineFarmOwnershipAsync = useDeclineFarmOwnershipAsync();

    const submitAsync = async (formValues) => {
        try {
            if (formValues.accepted) {
                await acceptFarmOwnershipAsync(formValues);
            } else {
                await declineFarmOwnershipAsync(formValues);
            }
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    };

    return (
        <Form initialValues={{ ...nomination, nominatedBy: nomination.invitedBy.userFullName }} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const error = (values && values.error) || (!dirtySinceLastSubmit && submitError);
                const nominatedBy =
                    nomination.invitedBy.userFullName === nomination.invitedBy.accountName ? (
                        <b>{nomination.invitedBy.userFullName}</b>
                    ) : (
                        <>
                            <b>{nomination.invitedBy.userFullName}</b> on behalf of <b>{nomination.invitedBy.accountName}</b>
                        </>
                    );
                const info = (
                    <span>
                        {nominatedBy} has nominated you as an <b>{nomination.role}</b> of the <b>{nomination.farmName}</b> farm. Review the nomination details and respond.
                    </span>
                );
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Farm ownership nomination" close={close} submitting={submitting} fluid>
                            <ModalBody info={info} error={error}>
                                <Field name="id" type="hidden" component="input" />
                                <Grid>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="farmName" label="Requested farm" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="farmAddress" label="Farm address" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="nominatedBy" label="Nominated by" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-mt-md">
                                        <h3>
                                            If you <u>accept</u> ownership...
                                        </h3>
                                        <ul className="disc">
                                            <li>
                                                You accept ownership of the <b>{nomination.farmName}</b> farm
                                            </li>
                                            <li>As the farm account owner, you have full control over who can view or edit your information</li>
                                        </ul>
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                    <Button id="decline" submit onClick={() => form.change("accepted", false)} tertiary disabled={submitting}>
                                        Decline
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="accept" submit onClick={() => form.change("accepted", true)} primary disabled={submitting}>
                                        Accept
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useFarmOwnershipNominationResponseModal() {
    const [modal, openModal] = useModal(FarmOwnershipNominationResponseModal);

    const openFarmOwnershipNominationResponseModal = (nomination) => {
        const modalProps = {
            nomination
        };
        openModal(modalProps);
    };

    return [modal, openFarmOwnershipNominationResponseModal];
}

function useAcceptFarmOwnershipAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (nomination) => {
            try {
                await httpClient.put(`farmAccessInvitations/${nomination.id}/accept`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 400) {
                    throw new Error("This nomination is no longer valid.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        }
    });

    return (nomination) => mutation.mutateAsync(nomination);
}

function useDeclineFarmOwnershipAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (nomination) => {
            try {
                await httpClient.put(`farmAccessInvitations/${nomination.id}/decline`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 400) {
                    throw new Error("This nomination is no longer valid.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        }
    });

    return (nomination) => mutation.mutateAsync(nomination);
}
