import React from "react";
import BlockLevelApplications from "./BlockLevelApplications";

const BlockLevel = (props) => {
    const { form, viewModel, pastoralBlocksOnFarm, values } = props;
    return (
        <React.Fragment>
            <BlockLevelApplications className="u-mt-md" form={form} values={values} applications={viewModel.applications} availableBlocks={pastoralBlocksOnFarm} />
        </React.Fragment>
    );
};

export default BlockLevel;
