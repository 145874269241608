import { Bar } from "react-chartjs-2";
import * as domain from "common/domain";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import { irrigatorViewModel } from "common/viewModels";
import Alert from "components/Alert";
import { FileSummary, MonthlySummary, getFarmLevelModel } from "components/PlanningReport";
import IrrigationSummary from "./IrrigationSummary";
import { getGroupLabel } from "./_utils";

export default function Irrigation({ before, after, showFileComparison }) {
    const refData = useRefData();
    const budget = after.details;
    const vm = irrigatorViewModel(budget, refData);
    const baseVm = before && irrigatorViewModel(before.details, refData);

    if (vm.length === 0 && (!baseVm || baseVm.length === 0))
        return (
            <div className="u-pt-lg">
                <Alert type="info" text="There is no irrigation information for this analysis" />
            </div>
        );

    const scenarios = [after];
    if (before) scenarios.unshift(before);

    const getData = (viewModel, group) => {
        return domain.farmYear.map((month) => {
            let monthTotal = 0;
            for (const irrSys of viewModel) {
                for (const app of irrSys.applications.filter((a) => a.group === group && a.months.find((m) => m.month === month && m.reportingYear))) {
                    monthTotal += app.area;
                }
            }
            return utils.round(monthTotal, 1);
        });
    };

    const getVmGroups = (name, viewModel, before) => {
        return [1, 2, 3, 4, 5].map((grp) => ({
            stack: name,
            label: getGroupLabel(grp),
            fill: false,
            lineTension: 0.1,
            backgroundColor: before ? "#E3EEF8" : "#c6f3c6",
            borderColor: before ? "#509BC2" : "#52af6e",
            borderWidth: 1,
            data: getData(viewModel, grp),
        }));
    };

    const datasets = baseVm ? [...getVmGroups(before.displayName, baseVm, true), ...getVmGroups(after.displayName, vm, false)] : getVmGroups(after.displayName, vm, false);

    const monthlyAreaData = {
        datasets,
        labels: domain.farmYear,
    };

    const barOptions = {
        scales: {
            y: {
                display: true,
                title: { display: true, text: "Area (ha)" },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
        responsive: true,
    };

    const showIrrigationAmountsByMonth = hasIrrigationAmountsByMonth(scenarios);

    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <IrrigationSummary before={before} after={after} vm={vm} baseVm={baseVm} />
            {showIrrigationAmountsByMonth && (
                <div className="ReportSection">
                    <h1>Irrigated amounts by month</h1>
                    <MonthlySummary scenarios={scenarios} categoryName="Irrigation" rowName="Applied" />
                </div>
            )}
            <div className="ReportSection">
                <h1>Irrigated area by month</h1>
                <div className="printable-chart-container">
                    <Bar data={monthlyAreaData} options={barOptions} className="printable-chart" />
                </div>
            </div>
        </div>
    )
}

function hasIrrigationAmountsByMonth(scenarios) {
    const { model } = getFarmLevelModel(scenarios, "Irrigation", "Applied");
    const hasData = model.some((m) => m.total > 0);
    return hasData;
}