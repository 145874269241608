import React from "react";
import { Link } from "react-router-dom";
import { canAddStructure } from "./_utils";
import * as icons from "common/icons";

export default function NoEffluent({ farm, analysis, noEnterprises, hasDairy, hasCows, hasOutdoorPigs, structuresNotRequired }) {
    let tBody = null;
    if (noEnterprises) {
        tBody = (
            <div className="Tile-body-message">
                <img src={icons.add} className="u-p-5" alt="Plus" />
                <p className="lead">You do not have any animal enterprises</p>
                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/animals`} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="create-milk-link">
                    <span>Add animals</span>
                </Link>
            </div>
        );
    } else if (structuresNotRequired) {
        tBody = (
            <div className="Tile-body-message">
                <img src={icons.add} className="u-p-5" alt="Plus" />
                <p className="lead">Structures are not required for this farm</p>
                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/animals`} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="create-milk-link">
                    <span>Add enterprise</span>
                </Link>
            </div>
        );
    } else if (analysis.effluentSystem || !hasDairy) {
        tBody = (
            <div className="Tile-body-message">
                <img src={icons.add} className="u-p-5" alt="Plus" />
                <p className="lead">You do not have any structures</p>
                {hasDairy && canAddStructure(analysis, hasDairy, "MilkingShed") && (
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/structure?type=MilkingShed`} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="create-milkingShed">
                        <span>Define feeding for milking shed</span>
                    </Link>
                )}
                {hasCows && canAddStructure(analysis, hasDairy, "FeedingPad") && (
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/structure?type=FeedingPad`} className="IconLink--arrow-plus Button Button--lg u-mt-md" id="create-feedingPad">
                        <span>Add feed pad</span>
                    </Link>
                )}
                {(canAddStructure(analysis, hasDairy, "CoveredWinteringPad") || canAddStructure(analysis, hasDairy, "UncoveredWinteringPad")) && (
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/structure?type=WinteringPad`} className="IconLink--arrow-plus Button Button--lg u-mt-md" id="create-winteringPad">
                        <span>Add wintering pad</span>
                    </Link>
                )}
                {hasCows && canAddStructure(analysis, hasDairy, "StandoffPad") && (
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/structure?type=StandoffPad`} className="IconLink--arrow-plus Button Button--lg u-mt-md" id="create-standoffPad">
                        <span>Add standoff pad</span>
                    </Link>
                )}
                {hasOutdoorPigs && canAddStructure(analysis, hasDairy, "OutdoorPigFarrowingVillages") && (
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/outdoorPigFarrowingVillages`} className="IconLink--arrow-plus Button Button--lg u-mt-md" id="create-farrowingVillage">
                        <span>Add outdoor pig farrowing village</span>
                    </Link>
                )}
                {hasOutdoorPigs && canAddStructure(analysis, hasDairy, "OutdoorPigBarns") && (
                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/outdoorPigBarns`} className="IconLink--arrow-plus Button Button--lg u-mt-md" id="create-pigShed">
                        <span>Add outdoor pig shed/barn</span>
                    </Link>
                )}
            </div>
        );
    } else {
        tBody = (
            <div className="Tile-body-message">
                <img className="u-p-5" src={icons.add} alt="Plus" />
                <p className="lead">You do not have an effluent system</p>
                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/effluent`} className="IconLink--arrow-plus Button Button--secondary u-mt-lg" id="create-milk-link">
                    <span>Add effluent system</span>
                </Link>
            </div>
        );
    }

    return (
        <div className="FarmTableGroup">
            <div className={`FarmTableGroup-heading ${analysis.effluentSystem ? "FarmTableGroup-heading--super" : ""}`}>
                <span>Structures/Effluent system</span>
            </div>
            <div className="FarmTable_wrapper">
                <div className="FarmTable-supplementary">
                    <div className="Tile-body">{tBody}</div>
                </div>
            </div>
        </div>
    );
}
