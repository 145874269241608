import * as utils from "common/utils";

export default function EnterpriseNameWithIcon({ enterprise, hasError, hasWarning }) {
    const name = enterprise.name;
    const icon = utils.getAnimalIcon(enterprise.type)
    const alertClass = hasError ? "error" : (hasWarning ? "warning" : undefined);

    return (
        <span className="u-flex u-flexAlignItemsCenter">
            {alertClass && (
                <span className={`Todo Todo--${alertClass}`}>
                    <i className="icon icon-alert" />
                </span>
            )}
            <img src={icon} className="u-mr-xs u-p-5" width="35" height="35" alt={name} />
            <span data-test-id={`enterprise_name_with_icon_${name}`}>{name}</span>
        </span>
    )
}
