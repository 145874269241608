import React from "react";
import * as icons from "common/icons";

const EmptyCustom = ({ label }) => {
    return (
        <div className="Tile-body">
            <div className="Tile-body-message  u-mt-0 u-mb-0">
                <img className="u-p-5" src={icons.add} alt="Plus" />
                <p className="lead">{label}</p>
                <p>Click a button above to get started</p>
            </div>
        </div>
    );
};

export default EmptyCustom;
