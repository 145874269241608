import { useRefData } from "common/hooks";
import { getBlockList } from "common/utils";
import * as domain from "common/domain";
import TileSubPanel from "components/TileSubPanel";
import BlockListCard from "./BlockListCard";

export default function BlockList({ farm, analysis }) {
    const refData = useRefData();

    const blockList = getBlockList(analysis.blocks);
    const fodderCropBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.FodderCrop);
    const pastureBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductivePasture);
    const fruitBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductiveFruit);
    const cropBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductiveCrop);
    const outdoorPigBlocks = blockList.productiveBlocks.filter((block) => block.type === domain.BlockType.ProductiveOutdoorPigs);
    const otherBlocks = blockList.nonProductiveBlocks;

    return (
        <>
            {pastureBlocks.length > 0 && (
                <TileSubPanel title="Pasture blocks" stickyHeaderName="budget.overview.overview.pastureBlocks.collapsed">
                    {pastureBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} refData={refData} />
                    ))}
                </TileSubPanel>
            )}
            {cropBlocks.length > 0 && (
                <TileSubPanel title="Crop blocks" stickyHeaderName="budget.overview.overview.cropBlocks.collapsed">
                    {cropBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} refData={refData} />
                    ))}
                </TileSubPanel>
            )}
            {fodderCropBlocks.length > 0 && (
                <TileSubPanel title="Fodder crop rotations" stickyHeaderName="budget.overview.overview.fodderCropRotations.collapsed">
                    {fodderCropBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} refData={refData} />
                    ))}
                </TileSubPanel>
            )}
            {fruitBlocks.length > 0 && (
                <TileSubPanel title="Fruit blocks" stickyHeaderName="budget.overview.overview.fruitBlocks.collapsed">
                    {fruitBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} refData={refData} />
                    ))}
                </TileSubPanel>
            )}
            {outdoorPigBlocks.length > 0 && (
                <TileSubPanel title="Outdoor pigs" stickyHeaderName="budget.overview.overview.outdoorPigs.collapsed">
                    {outdoorPigBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} refData={refData} />
                    ))}
                </TileSubPanel>
            )}
            {otherBlocks.length > 0 && (
                <TileSubPanel title="Other blocks" stickyHeaderName="budget.overview.overview.otherBlocks.collapsed">
                    {otherBlocks.map((block) => (
                        <BlockListCard key={block.id} farm={farm} analysis={analysis} block={block} refData={refData} />
                    ))}
                </TileSubPanel>
            )}
        </>
    )
}