import { useParams } from "react-router-dom";
import Dataset from "containers/Datasets/Dataset";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";

export default function OrgDataset() {
    const { accountId } = useParams();

    const breadcrumb = [{ name: "Datasets", to: `/app/admin/orgs/${accountId}/datasets` }];
    breadcrumb.push({ name: "Details" });

    return (
        <div>
            {breadcrumb && breadcrumb.length > 0 && <Breadcrumb breadcrumb={breadcrumb} hideDashboard={true} />}
            <Dataset />
        </div>
    );
}
