import { Component } from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import createDecorator from "final-form-calculate";
import * as utils from "common/utils";
import Fertiliser from "./ProductType/Fertiliser";
import Lime from "./ProductType/Lime";
import Organic from "./ProductType/Organic";
import Soluble from "./ProductType/Soluble";
import Nutrients from "components/Nutrients";
import * as fu from "./_utils";
import { validateNutrients, nutrientDecorators } from "components/Nutrients";
import { v4 as uuidv4 } from "uuid";
import * as validations from "common/validations";
import { showDmContent, showPerOrganicN, showDairyEffluent, showEffluentForm } from "./ProductType/Organic";
import { useCustomNutrientCompositions, useRefData, useModal } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function FertiliserProductModal({ viewModel, onAdd, close }) {
    const refData = useRefData();
    const customCompositions = useCustomNutrientCompositions();
    const updateAnalysisAsync = useUpdateAnalysisAsync(viewModel?.analysis);

    return <ProductModalClassComponent viewModel={viewModel} close={close} updateAnalysisAsync={updateAnalysisAsync} onAdd={onAdd} customCompositions={customCompositions} refData={refData} />;
}

class ProductModalClassComponent extends Component {
    constructor(props) {
        super(props);
        const { viewModel } = props;
        const { fertiliser } = viewModel;
        this.fertName = fertiliser.productName;
        this.refData = props.refData;
        this.analysis = viewModel.analysis;
        this.farm = viewModel.farm;
        this.cropBlockIds = this.analysis.blocks.filter((b) => ["FodderCrop", "ProductiveCrop"].includes(b.type)).map((c) => c.id);
        this.otherBlockIds = this.analysis.blocks.filter((b) => !["FodderCrop", "ProductiveCrop"].includes(b.type) && b.isProductive).map((c) => c.id);
        this.newFertiliserId = uuidv4();

        this.allowAddCropApplication = this.cropBlockIds.length > 0;
        this.allowAddPastureApplication = this.analysis.blocks.some((b) => ["ProductiveFruit", "ProductivePasture"].includes(b.type));
        const material = viewModel.material;
        let productSource = this.props.viewModel.isNew ? (["Organic", "Soluble"].includes(material) ? "CUSTOM" : "MANUFACTURER") : fertiliser.productId === 0 ? "CUSTOM" : "MANUFACTURER";

        this.state = {};
        this.state.viewModel = {
            fertiliser: utils.clone(fertiliser),
            productSource,
            isNew: viewModel.isNew,
            origId: fertiliser.id,
            origProdId: fertiliser.productId,
            analysis: viewModel.analysis,
            preventCustomSwitch: viewModel.preventCustomSwitch,
        };
        if (viewModel.isNew) {
            this.state.viewModel.fertiliser.material = material;
            this.state.viewModel.fertiliser.isLastPreviousLime = viewModel.isLastPreviousLime;
        } else {
            const manufacturerId = isNaN(fertiliser.manufacturerId) ? undefined : parseInt(fertiliser.manufacturerId, 10);
            const manufacturer = props.refData.fertiliser.find((p) => p.id === manufacturerId) || { products: [] };
            if (manufacturer.products.length > 0 && fertiliser.productName && !isNaN(fertiliser.productId) && !manufacturer.products.find((p) => String(p.id) === String(fertiliser.productId))) {
                this.state.viewModel.retired = {
                    value: fertiliser.productId,
                    text: fertiliser.productName,
                    manufacturerId: fertiliser.manufacturerId,
                    nutrients: fertiliser.nutrients,
                    nType: fertiliser.nType,
                    pType: fertiliser.pType,
                    percentElementalS: fertiliser.percentElementalS,
                    mgType: fertiliser.mgType,
                };
            }
        }
        this.state.viewModel.productSource_prev = this.state.viewModel.productSource;
        this.state.viewModel.manufacturerId_prev = this.state.viewModel.fertiliser.manufacturerId;
        this.state.viewModel.productId_prev = this.state.viewModel.fertiliser.productId;
        this.state.viewModel.id_prev = this.state.viewModel.fertiliser.id;

        this.customFertiliser = utils.clone(this.analysis.fertiliser.filter((f) => f.manufacturerId === fu.CustomManufacturerId && f.material === this.state.viewModel.fertiliser.material && f.isLastPreviousLime === this.state.viewModel.fertiliser.isLastPreviousLime));

        switch (this.state.viewModel.fertiliser.material) {
            case fu.ProductType.Lime:
                this.productHeading = this.state.viewModel.fertiliser.isLastPreviousLime ? "previous lime" : "lime";
                break;
            case fu.ProductType.Organic:
                this.productHeading = "organic fertiliser";
                break;
            case fu.ProductType.Soluble:
                this.productHeading = "soluble fertiliser";
                break;
            default:
                this.productHeading = "fertiliser product";
                break;
        }

        this.decorators = [
            nutrientDecorators(),
            createDecorator(
                {
                    field: "productSource",
                    updates: (fieldValue, fieldName, allValues) => {
                        const vm = Object.assign({}, allValues);
                        if (fieldValue === vm.productSource_prev) return {};
                        vm.productSource_prev = fieldValue;
                        const { fertiliser = {} } = vm;
                        const existing = fieldValue === "EXISTING" && this.analysis.fertiliser.find((f) => String(f.id) === String(fertiliser.id));
                        vm.id_prev = undefined;
                        if (existing) {
                            mapFertiliser(existing, fertiliser);
                        } else {
                            fertiliser.manufacturerId = undefined;
                            fertiliser.productId = undefined;
                            fertiliser.nutrients = undefined;
                            fertiliser.nType = undefined;
                            fertiliser.pType = undefined;
                            fertiliser.percentElementalS = undefined;
                            fertiliser.mgType = undefined;
                            fertiliser.productName = undefined;
                            vm.existing = undefined;
                            vm.productId_prev = undefined;
                        }
                        return vm;
                    },
                },
                {
                    field: "fertiliser.dairyFactoryEffluent",
                    updates: (fieldValue, fieldName, allValues) => {
                        const vm = Object.assign({}, allValues);
                        if (vm.fertiliser.material !== fu.ProductType.Organic && vm.fertiliser.organicType === "Dairyfactory") return {};
                        const customComposition = this.customCompositions().find((c) => c.id === vm.fertiliser.dairyFactoryEffluent);
                        vm.fertiliser.nutrients = customComposition ? customComposition.nutrients : vm.fertiliser.nutrients;
                        vm.fertiliser.nutrientUnit = customComposition ? customComposition.effluentUnit : vm.fertiliser.nutrientUnit;
                        vm.globalSelect = customComposition ? "source.fertiliser.dairyFactoryEffluent" : undefined;
                        if (vm.fertiliser.dairyFactoryEffluent === "Userdefined") {
                            const { applications = [] } = vm.fertiliser;
                            applications.forEach((app) => {
                                app.unit = "kgha";
                            });
                        }
                        return vm;
                    },
                },
                {
                    field: "fertiliser.organicType",
                    updates: (fieldValue, fieldName, allValues) => {
                        const vm = Object.assign({}, allValues);
                        vm.globalSelect = false;
                        const { applications = [] } = vm.fertiliser;
                        vm.fertiliser.nutrientUnit = "Percent";
                        //delete vm.fertiliser.nutrients
                        if (!["Dairyfactory"].includes(fieldValue)) {
                            delete vm.fertiliser.dairyFactoryEffluent;
                        }
                        if (["Otherorganicmaterial", "CompostMulches"].includes(fieldValue)) {
                            const { nutrients = {} } = vm.fertiliser;
                            const keys = Object.keys(nutrients) || [];
                            keys.forEach((k) => (nutrients[k] = utils.round(nutrients[k], 1)));
                        }
                        if (fieldValue === "CompostMulches") {
                            applications.forEach((app) => {
                                app.unit = "tonnes";
                            });
                        }
                        if (fu.nutrientsOnApplication(vm.fertiliser)) {
                            applications.forEach((app) => {
                                delete app.amount;
                            });
                        } else {
                            applications.forEach((app) => {
                                app.nutrients = {};
                            });
                        }
                        return vm;
                    },
                },
                {
                    field: "fertiliser.manufacturerId",
                    updates: (fieldValue, fieldName, allValues) => {
                        const vm = Object.assign({}, allValues);
                        if (fieldValue === vm.manufacturerId_prev) return {};
                        const manufacturer = this.props.refData.fertiliser.find((f) => String(f.id) === fieldValue);
                        vm.manufacturerId_prev = fieldValue;
                        const { fertiliser = {} } = vm;
                        fertiliser.productId = undefined;
                        fertiliser.nutrients = undefined;
                        fertiliser.nType = undefined;
                        fertiliser.pType = undefined;
                        fertiliser.percentElementalS = undefined;
                        fertiliser.mgType = undefined;
                        fertiliser.productName = undefined;
                        vm.existing = undefined;
                        if (manufacturer) {
                            fertiliser.manufacturer = manufacturer.name;
                        }
                        return vm;
                    },
                },
                {
                    field: "fertiliser.id",
                    updates: (fieldValue, fieldName, allValues) => {
                        const vm = Object.assign({}, allValues);
                        if (fieldValue === vm.id_prev) return {};
                        vm.id_prev = fieldValue;
                        vm.existing = fieldValue && this.analysis.fertiliser.find((f) => String(f.id) === String(fieldValue) && String(fieldValue) !== String(vm.origId));
                        const existingFert = this.customFertiliser.find((f) => f.id === fieldValue) || this.customCompositions().find((c) => String(c.id) === String(fieldValue));
                        vm.globalSelect = undefined;
                        if (existingFert) {
                            mapFertiliser(existingFert, vm.fertiliser);
                            if (!vm.existing && existingFert.id !== vm.origId) {
                                vm.resetIdOnSave = true;
                                vm.globalSelect = "source.fertiliser.id";
                            }
                        } else {
                            const { fertiliser = {} } = vm;
                            fertiliser.productId = undefined;
                            fertiliser.nutrients = undefined;
                            fertiliser.nType = undefined;
                            fertiliser.pType = undefined;
                            fertiliser.percentElementalS = undefined;
                            fertiliser.mgType = undefined;
                            fertiliser.productName = undefined;
                            vm.existing = undefined;
                        }
                        return vm;
                    },
                },
                {
                    field: "fertiliser.productId",
                    updates: (fieldValue, fieldName, allValues) => {
                        const vm = Object.assign({}, allValues);
                        if (fieldValue === vm.productId_prev || !fieldValue) return {};
                        vm.productId_prev = fieldValue;
                        const { fertiliser = {} } = vm;
                        const copyNutrients = (p) => ({ N: p.n, P: p.p, K: p.k, S: p.s, Ca: p.ca, Mg: p.mg, Na: p.na });
                        vm.existing = fieldValue && this.analysis.fertiliser.find((f) => String(f.productId) === String(fieldValue) && String(fieldValue) !== String(vm.origProdId) && f.material === fertiliser.material && f.isLastPreviousLime === fertiliser.isLastPreviousLime);
                        switch (fertiliser.material) {
                            case fu.ProductType.Lime: {
                                const lime = this.props.refData.lime.find((l) => String(l.id) === String(fieldValue));
                                if (lime) {
                                    fertiliser.manufacturer = "Lime";
                                    fertiliser.productName = lime.name;
                                    fertiliser.nutrients = copyNutrients(lime);
                                    fertiliser.nType = "None";
                                    fertiliser.pType = "None";
                                    fertiliser.percentElementalS = 0;
                                    fertiliser.mgType = "None";
                                    fertiliser.nutrientUnit = "Percent";
                                }
                                break;
                            }
                            default: {
                                const manufacturer = this.refData.fertiliser.find((f) => String(f.id) === String(fertiliser.manufacturerId), 10);
                                if (manufacturer) {
                                    const product = manufacturer.products.find((f) => String(f.id) === String(fieldValue));
                                    if (product) {
                                        fertiliser.productName = product.name;
                                        fertiliser.nutrients = copyNutrients(product);
                                        const { nutrientNType = [], nutrientPType = [], nutrientMgType = [] } = this.props.refData;
                                        fertiliser.nType = nutrientNType.length > product.nType ? nutrientNType[product.nType].value : "None";
                                        fertiliser.pType = nutrientPType.length > product.pType ? nutrientPType[product.pType].value : "None";
                                        fertiliser.mgType = nutrientMgType.length > product.mgType ? nutrientMgType[product.mgType].value : "None";
                                        fertiliser.percentElementalS = product.elementalS;
                                        fertiliser.nutrientUnit = "Percent";
                                    } else if (allValues.retired) {
                                        const { retired } = allValues;
                                        fertiliser.productName = retired.text;
                                        fertiliser.nutrients = retired.nutrients;
                                        fertiliser.nType = retired.nType;
                                        fertiliser.pType = retired.pType;
                                        fertiliser.mgType = retired.mgType;
                                        fertiliser.percentElementalS = retired.percentElementalS;
                                        fertiliser.nutrientUnit = "Percent";
                                    }
                                }
                                break;
                            }
                        }
                        return vm;
                    },
                }
            ),
        ];
    }

    customCompositions() {
        return utils.clone(this.props.customCompositions.filter((c) => c.type === materialToType(this.state.viewModel.fertiliser.material)) || []);
    }

    setDataTypes(viewModel) {
        const { fertiliser, productSource } = viewModel;
        if (viewModel.resetIdOnSave) {
            fertiliser.id = this.newFertiliserId;
        }
        if (productSource !== "MANUFACTURER") {
            fertiliser.manufacturerId = 1;
            fertiliser.productId = 0;
            fertiliser.manufacturer = fertiliser.customCompositionId ? `Global ${this.productHeading}` : `Custom ${this.productHeading}`;
        }
        if (!["CompostMulches", "Otherorganicmaterial"].includes(fertiliser.organicType)) {
            fertiliser.dmContent = 0;
        }
        if (!["Otherorganicmaterial"].includes(fertiliser.organicType)) {
            fertiliser.percentageOrganicN = 0;
        }
        if (hideNutrientsOnFertiliser(fertiliser)) {
            delete fertiliser.nutrients;
        }

        if (!["Dairyfactory"].includes(fertiliser.organicType)) {
            delete fertiliser.dairyFactoryEffluent;
        }
        if (!["Piggeryeffluent", "Importeddairyeffluent"].includes(fertiliser.organicType)) {
            delete fertiliser.effluentForm;
        }
        if (fertiliser.id === "NEW") fertiliser.id = this.newFertiliserId;
        fertiliser.dmContent = parseFloat(fertiliser.dmContent) || 0;
        fertiliser.percentageOrganicN = parseFloat(fertiliser.percentageOrganicN) || 0;
        if (fertiliser.material === fu.ProductType.Organic && fertiliser.organicType === "Dairyfactory" && this.customCompositions().find((c) => c.id === fertiliser.dairyFactoryEffluent)) {
            fertiliser.dairyFactoryEffluent = "Userdefined";
        }
        /* eslint-disable no-unused-vars */
        for (const application of fertiliser.applications) {
            delete application.fertiliser;
            application.amount = parseFloat(application.amount) || 0;
            if (fu.kghaOnly(viewModel)) {
                application.unit = "kgha";
            }
            if (viewModel.fertiliser.organicType === fu.CompostMulches) {
                application.unit = "tonnes";
            }
        }
        if (fertiliser.material !== fu.ProductType.Organic) {
            delete fertiliser.organicType;
        }

        if (fertiliser.material === fu.ProductType.Product) {
            const noNitrogen = !fertiliser.nutrients.N > 0;
            if (noNitrogen) {
                fertiliser.nType = "None";
            }
            /* eslint-disable no-unused-vars */
            for (const application of fertiliser.applications) {
                const selectedBlocks = application.blockIds.map((id) => this.analysis.blocks.find((b) => b.id === id));
                const hasCrops = selectedBlocks.some((b) => ["FodderCrop", "ProductiveCrop"].includes(b.type));
                if (!hasCrops || noNitrogen) {
                    application.nFertAppMethod = "None";
                }
            }
            if (!fertiliser.nutrients.N > 0) fertiliser.nType = "None";
            if (!fertiliser.nutrients.P > 0) fertiliser.pType = "None";
            if (!fertiliser.nutrients.S > 0) fertiliser.percentElementalS = 0;
            if (!fertiliser.nutrients.Mg > 0) fertiliser.mgType = "None";
        }

        if (fu.nutrientsOnApplication(fertiliser)) {
            delete fertiliser.nutrients;
            delete fertiliser.nutrientUnit;
            /* eslint-disable no-unused-vars */
            for (const application of fertiliser.applications) {
                application.amount = 0;
            }
        } else {
            /* eslint-disable no-unused-vars */
            for (const application of fertiliser.applications) {
                delete application.nutrients;
            }
        }
    }

    onSubmitAsync = async (formValues) => {
        this.setDataTypes(formValues);

        const updatedAnalysis = this.analysis;
        const { fertiliser, existing } = formValues;

        if (formValues.isNew) {
            // Adding.
            updatedAnalysis.fertiliser.push(fertiliser);
        } else {
            // Editing.
            if (existing && existing.id !== fertiliser.id) {
                updatedAnalysis.fertiliser = updatedAnalysis.fertiliser.filter((f) => f.id !== existing.id);
            } else if (formValues.origId !== fertiliser.id) {
                updatedAnalysis.fertiliser = updatedAnalysis.fertiliser.filter((f) => f.id !== formValues.origId);
            } else {
                updatedAnalysis.fertiliser = updatedAnalysis.fertiliser.map((f) => {
                    if (f.id === fertiliser.id) {
                        return fertiliser;
                    } else {
                        return f;
                    }
                });
            }
        }

        try {
            await this.props.updateAnalysisAsync(updatedAnalysis);
            if (this.props.onAdd) {
                this.props.onAdd(fertiliser);
            }
            this.props.close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    };

    render() {
        return (
            <Form
                onSubmit={this.onSubmitAsync}
                initialValues={this.state.viewModel}
                decorators={this.decorators}
                validate={(values) => {
                    const errors = { fertiliser: {} };
                    const nutrientsOnFertiliser = !hideNutrientsOnFertiliser(values.fertiliser);
                    errors.fertiliser.productName = validations.required(values.fertiliser.productName);
                    errors.fertiliser.productName = errors.fertiliser.productName || validations.maxLength(200)(values.fertiliser.productName);
                    errors.fertiliser.productName = errors.fertiliser.productName || (this.analysis.fertiliser.find((f) => (String(values.fertiliser.productId) === "0" || !values.fertiliser.productId) && values.fertiliser.productName && f.productName && f.isLastPreviousLime === values.fertiliser.isLastPreviousLime && f.id !== values.fertiliser.id && this.fertName !== values.fertiliser.productName && values.fertiliser.productName.trim().toUpperCase() === f.productName.trim().toUpperCase()) ? "Name already in use" : undefined);

                    switch (values.productSource) {
                        case "MANUFACTURER": {
                            const productId = values.fertiliser.productId === "0" ? undefined : values.fertiliser.productId;
                            errors.fertiliser.productId = validations.required(productId);
                            switch (values.fertiliser.material) {
                                case fu.ProductType.Product:
                                    errors.fertiliser.manufacturerId = validations.required(values.fertiliser.manufacturerId);
                                    errors.fertiliser.manufacturerId = errors.fertiliser.manufacturerId || (values.fertiliser.manufacturerId === "0" ? "Required" : undefined);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        }
                        case "GLOBAL":
                            errors.fertiliser.customCompositionId = validations.required(values.fertiliser.customCompositionId);
                            break;
                        case "CUSTOM":
                            switch (values.fertiliser.material) {
                                case fu.ProductType.Organic:
                                    errors.fertiliser.organicType = validations.required(values.fertiliser.organicType);
                                    if (showDmContent(values.fertiliser)) {
                                        errors.fertiliser.dmContent = validations.required(values.fertiliser.dmContent);
                                        errors.fertiliser.dmContent = errors.fertiliser.dmContent || validations.range(1, 100)(values.fertiliser.dmContent);
                                    }
                                    if (showPerOrganicN(values.fertiliser)) {
                                        errors.fertiliser.percentageOrganicN = errors.fertiliser.percentageOrganicN || validations.range(0, 100)(values.fertiliser.percentageOrganicN);
                                    }
                                    if (showDairyEffluent(values.fertiliser)) {
                                        errors.fertiliser.dairyFactoryEffluent = validations.required(values.fertiliser.dairyFactoryEffluent);
                                    }
                                    if (showEffluentForm(values.fertiliser)) {
                                        errors.fertiliser.effluentForm = validations.required(values.fertiliser.effluentForm);
                                    }
                                    break;
                                default:
                                    break;
                            }
                            if (nutrientsOnFertiliser) {
                                const nutrientErrors = validateNutrients(values.fertiliser, values.fertiliser.material);
                                errors.fertiliser = { ...errors.fertiliser, ...nutrientErrors };
                            }
                            break;
                        default:
                            break;
                    }
                    return errors;
                }}
                render={({ handleSubmit, submitting, values, errors, form }) => {
                    const { fertiliser } = values;
                    const { nutrients = {} } = fertiliser;
                    const hideNutrients = hideNutrientsOnFertiliser(fertiliser);
                    const nutType = fertiliser.material === "Organic" && hideNutrients ? "OrganicApplication" : fertiliser.material;
                    const readonly = (["Product", "Lime"].includes(fertiliser.material) && values.productSource === "MANUFACTURER") || values.existing;
                    const preSubmit = () => {
                        const { existing } = values;
                        const { submit: mergeDone } = values;
                        form.change("submit", true);
                        if (!existing || mergeDone) return;
                        form.change("fertiliser.applications", [...existing.applications, ...fertiliser.applications]);
                    };
                    let title = "";
                    switch (fertiliser.material) {
                        case "Lime":
                            title = "lime fertiliser";
                            break;
                        case "Organic":
                            title = "organic fertiliser";
                            break;
                        case "Soluble":
                            title = "soluble fertiliser";
                            break;
                        default:
                            title = "fertiliser product";
                            break;
                    }

                    return (
                        <form onSubmit={handleSubmit}>
                            <Modal title={`${values.isNew ? "Create" : "Edit"} ${title}`} close={this.props.close} submitting={submitting} skinny fluid>
                                <ModalBody info="Fertiliser compositions used within this analysis">
                                    <Field name="fertiliser.id" type="hidden" component="input" />
                                    <input type="hidden" value={this.newFertiliserId} name="fertiliser.newId" />
                                    <Grid>
                                        {fertiliser.material === "Lime" && <Lime viewModel={values} customFertiliser={this.customFertiliser} customCompositions={this.customCompositions()} />}
                                        {fertiliser.material === "Organic" && <Organic viewModel={values} customFertiliser={this.customFertiliser} customCompositions={this.customCompositions()} />}
                                        {fertiliser.material === "Soluble" && <Soluble viewModel={values} customFertiliser={this.customFertiliser} customCompositions={this.customCompositions()} />}
                                        {fertiliser.material === "Product" && <Fertiliser viewModel={values} customFertiliser={this.customFertiliser} customCompositions={this.customCompositions()} />}
                                        <GridCell>{!hideNutrients && <Nutrients type={nutType} unitReadOnly={true} errors={errors} nutrients={nutrients} readonly={readonly} organicType={values.fertiliser.organicType} />}</GridCell>
                                    </Grid>
                                </ModalBody>
                                <ModalFooter>
                                    <ModalFooterLeft>
                                        <Button id="cancel-button" secondary disabled={submitting} onClick={() => this.props.close()}>
                                            Cancel
                                        </Button>
                                    </ModalFooterLeft>
                                    <ModalFooterRight>
                                        <Button id="save-button" submit primary waiting={submitting} onClick={() => preSubmit()}>
                                            Save
                                        </Button>
                                    </ModalFooterRight>
                                </ModalFooter>
                            </Modal>
                        </form>
                    );
                }}
            />
        );
    }
}

const materialToType = (material) => {
    switch (material) {
        case "Product":
            return "Fertiliser";
        case "Organic":
            return "Effluent";
        default:
            return material;
    }
};

const hideNutrientsOnFertiliser = (fertiliser) => {
    return fu.nutrientsOnApplication(fertiliser) || (fertiliser.organicType === "Dairyfactory" && ["Lacticacidwhey", "Sulphuricacidwhey", "Cheesewhey", "None"].includes(fertiliser.dairyFactoryEffluent)) || (fertiliser.material === "Organic" && !fertiliser.organicType) || (fertiliser.organicType === "Dairyfactory" && !fertiliser.dairyFactoryEffluent);
};

const mapFertiliser = (source, destination) => {
    if (!source || !destination) return;
    destination.nutrients = source.nutrients;
    destination.productName = source.productName || source.name;
    destination.effluentForm = source.effluentForm;
    destination.dairyFactoryEffluent = source.dairyFactoryEffluent;
    destination.percentageOrganicN = source.percentageOrganicN;
    destination.dmContent = source.dmContent;
    destination.organicType = source.organicType;
    destination.nType = source.nType;
    destination.pType = source.pType;
    destination.percentElementalS = source.percentElementalS;
    destination.mgType = source.mgType;
    destination.nutrientUnit = source.nutrientUnit || source.effluentUnit;
};

export function useFertiliserProductModal() {
    const [modal, openModal] = useModal(FertiliserProductModal);

    const openFertiliserProductModal = (viewModel, onAdd) => {
        const modalProps = {
            viewModel,
            onAdd,
        };
        openModal(modalProps);
    };

    return [modal, openFertiliserProductModal];
}
