import { Component } from "react";
import { Link, useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-calculate";
import arrayMutators from "final-form-arrays";
import { v4 as uuidv4 } from "uuid";
import * as FormUtils from "common/FormUtils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Button } from "components/Button";
import Alert from "components/Alert";
import { Grid, GridCell } from "components/Grid";
import { CheckboxField, DecimalNumberField, FieldGroup, HiddenField, RadioGroupField, SelectField, TextField, WholeNumberField } from "components/FormFields";
import SavePrompt from "components/SavePrompt";
import FeedSupplementSources from "./FeedSupplementSources";
import * as utils from "common/utils";
import Nutrients, { validateNutrients, nutrientDecorators, augmentNutrients } from "components/Nutrients";
import * as domain from "common/domain";
import { supplementUtils, EstimatedDryMatterWeight } from "containers/BudgetHome/Supplements";
import { useOnline, useRefData, useGetCustomNutrientCompositions, useCustomNutrientCompositions, useShowQuickTips } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function FeedSupplement({ farm, analysis, type }) {
    const { feedSupplementId } = useParams();

    const viewModel = analysis.feedSupplements?.find((fs) => fs.id === feedSupplementId) || { id: uuidv4(), type, sources: [{}], };
    if (!viewModel.amount && viewModel.sources?.length === 1) {
        viewModel.amount = viewModel.sources[0].amount;
    }
    if (viewModel.amountType === "Bales") {
        if (viewModel.baleHeight_cm > 0) {
            viewModel.baleSizingMethod = "Dimensions";
        } else {
            viewModel.baleSizingMethod = "Standard";
            if (viewModel.baleEquivalents > 1 && (!viewModel.baleDM || viewModel.baleDM === 0)) {
                viewModel.baleDM = utils.round(viewModel.baleEquivalents * 20, 0);
            } else if ((!viewModel.baleEquivalents || viewModel.baleEquivalents === 0) && (!viewModel.baleDM || viewModel.baleDM === 0)) {
                viewModel.baleEquivalents = 1;
            }
        }
    }
    const blocks = (analysis.blocks || []).filter((block) => ["ProductivePasture"].includes(block.type) || (block.cropBlock && block.cropBlock.supplementsRemoved) || (["ProductiveCrop", "FodderCrop"].includes(block.type) && block.crops && block.crops.some((c) => supplementUtils.cropHasSupplements(c))));
    viewModel.nutrientSource = feedSupplementId;
    viewModel.supplementType = viewModel.categoryGeneralType;

    const online = useOnline();
    const refData = useRefData();
    const showQuickTips = useShowQuickTips();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const getCustomCompositions = useGetCustomNutrientCompositions();
    const customCompositions = useCustomNutrientCompositions().filter(nc => nc.type === "Supplements");

    const nutrientSource = viewModel.category === "Userdefined" && {
        id: viewModel.id,
        name: viewModel.name,
        nutrients: viewModel.nutrients,
        supplementType: viewModel.supplementType,
        clValue: utils.round(viewModel.clValue, 1),
        dm: viewModel.dm,
        me: viewModel.me,
        digestibility: viewModel.digestibility,
    }

    return <FeedSupplementClassComponent farm={farm} analysis={analysis} viewModel={viewModel} blocks={blocks} nutrientSource={nutrientSource} getCustomCompositions={getCustomCompositions} customCompositions={customCompositions} online={online} refData={refData} showQuickTips={showQuickTips} updateAnalysisAsync={updateAnalysisAsync} />
}

class FeedSupplementClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            decorators: this._createDecorators(),
            referrer: `/app/farm/${props.farm.id}/analysis/${props.analysis.id}/supplements`,
            viewModel: props.viewModel,
            submitting: false,
            submitSucceeded: false,
            feed: props.viewModel.name,
        };
    }

    componentDidMount() {
        this.props.getCustomCompositions();
    }

    _formatCategories = (values, items = []) => {
        if (values.type !== "FromStorage") {
            const userdefined = items.filter((t) => t.value === "Userdefined").map((i) => ({ ...i, ...{ groupLabel: "Add new custom supplement", groupIndex: 0 } }));
            const predefined = items.filter((t) => t.value !== "Userdefined").map((i) => ({ ...i, ...{ groupLabel: "Use predefined supplement", groupIndex: 1 } }));
            return [...userdefined, ...predefined];
        }
        return items;
    };

    _showSource = (values) => {
        return supplementUtils.isImported(values);
    };

    _showCategory = (values) => {
        return values.type && true;
    };

    _showSupplementaryFeed = (values) => {
        return supplementUtils.isPurchased(values) && values.category && values.category !== "Userdefined";
    };

    _showWrapped = (values) => {
        return !supplementUtils.isImported(values) && values.category && values.category !== "DirectFeeding";
    };

    _showWeightFields = (values) => {
        return values.category && (!this._showSupplementaryFeed(values) || values.name);
    };

    _showAmountType = (values) => {
        return !supplementUtils.canEstimateByWeightOnly(values);
    };

    _showAmount = (values) => {
        return supplementUtils.isImported(values) && values.category && values.amountType;
    };

    _showAreaCut = (values) => {
        return supplementUtils.isPurchased(values) && values.amountType === "Cuts";
    };

    _showIsDryWeight = (values) => {
        return values.amountType === "Weight" && values.type !== "FromStorage";
    };

    _showSilageStack = (values) => {
        return values.type === "Harvested" && this._showWeightFields(values) && values.category === "Silage" && ["Weight", "Volume", "Cuts"].includes(values.amountType);
    };

    _showSilageCuttingMethod = (values) => {
        return values.category === "Silage" && values.amountType === "Weight" && !values.isDryWeight && (!supplementUtils.isImported(values) || ["Pasture good quality silage", "Pasture average quality silage", "Pasture poor quality silage", "Lucerne silage"].includes(values.name));
    };

    _showBaleFields = (values) => {
        return values.amountType === "Bales";
    };

    _showBalesPackagingMethod = (values) => {
        return this._showBaleFields(values);
    };

    _showNonBalesPackagingMethod = (values) => {
        return ["Hay", "Baleage"].includes(values.category) && ["Volume", "Cuts"].includes(values.amountType);
    };

    _showBaleEquivalents = (values) => {
        return this._showBaleFields(values) && values.baleSizingMethod === "Standard";
    };

    _showBaleDimensions = (values) => {
        return this._showBaleFields(values) && values.baleSizingMethod === "Dimensions";
    };

    _showNutrientSource = (values) => {
        return values.type !== "FromStorage" && values.category === "Userdefined";
    };

    _showCustomNutrients = (values) => {
        return this._showNutrientSource(values) && values.nutrientSource;
    };

    _showBaleDepth = (values) => {
        return this._showBaleDimensions(values) && values.packagingMethod === "Squarebales";
    };

    _showSources = (values) => {
        return values.type === "Harvested" && values.amountType;
    };

    _mapViewModelToStateModel = (values) => {
        const feedSupplement = { ...values };

        delete feedSupplement.saveType;

        if (supplementUtils.isImported(feedSupplement)) {
            feedSupplement.sources = [{ amount: feedSupplement.amount }];
        }

        if (!this._showWrapped(feedSupplement)) {
            delete feedSupplement.wrapped;
        }

        if (!this._showAmount(feedSupplement)) {
            delete feedSupplement.amount;
        }

        if (!this._showAreaCut(feedSupplement)) {
            delete feedSupplement.areaCut;
        }

        if (!this._showIsDryWeight(feedSupplement)) {
            delete feedSupplement.isDryWeight;
        }

        if (!this._showSilageStack(feedSupplement)) {
            delete feedSupplement.silageStack;
            delete feedSupplement.stackContained;
        }

        if (!this._showSilageCuttingMethod(feedSupplement)) {
            delete feedSupplement.wilted;
        }

        if (!this._showBalesPackagingMethod(feedSupplement) && !this._showNonBalesPackagingMethod(feedSupplement)) {
            delete feedSupplement.packagingMethod;
        }

        if (!this._showBaleFields(feedSupplement)) {
            delete feedSupplement.baleSizingMethod;
        }

        if (!this._showBaleEquivalents(feedSupplement)) {
            delete feedSupplement.baleEquivalents;
            delete feedSupplement.baleDM;
        } else if ((!feedSupplement.baleEquivalents || feedSupplement.baleEquivalents === 0) && (!feedSupplement.baleDM || feedSupplement.baleDM === 0)) {
            feedSupplement.baleEquivalents = 1;
        }

        if (!this._showBaleDimensions(feedSupplement)) {
            delete feedSupplement.baleHeight_cm;
            delete feedSupplement.baleWidth_cm;
        }

        if (!this._showBaleDepth(feedSupplement)) {
            delete feedSupplement.baleDepth_cm;
        }

        if (!this._showNutrientSource(feedSupplement)) {
            supplementUtils.resetNutrients(feedSupplement);
        } else {
            augmentNutrients(feedSupplement);
        }

        if (feedSupplement.type === "Harvested" && feedSupplement.amountType === "Cuts") {
            feedSupplement.sources = (feedSupplement.sources || []).map((s) => ({ ...s, amount: 1 }));
        }

        if (feedSupplement.category === "Silage" && !feedSupplement.silageStack) {
            const { destinations = [] } = feedSupplement;
            const offFarmDistributions = destinations.filter((d) => d.type === "OffFarm");
            offFarmDistributions.forEach((d) => delete d.storageCondition);
        }

        return feedSupplement;
    };

    _createDecorators = () => {
        return [
            nutrientDecorators(),
            createDecorator(
                {
                    field: "category",
                    isEqual: (newValue, prevValue) => {
                        // Make sure update does not fire if is initial load or field value hasn't actually changed.
                        const isInitialLoad = this.state.viewModel.category && !prevValue;
                        return isInitialLoad || newValue === prevValue;
                    },
                    updates: (fieldValue, fieldName, allFieldValues) => {
                        const updatedFormValues = {};

                        if (!allFieldValues.amountType || supplementUtils.canEstimateByWeightOnly(allFieldValues) || (allFieldValues.amountType === "Bales" && !supplementUtils.canEstimateByBales(allFieldValues))) {
                            updatedFormValues.amountType = "Weight";
                        }

                        updatedFormValues.name = null;

                        return updatedFormValues;
                    },
                },
                {
                    field: "supplementType",
                    updates: (fieldValue, fieldName, allFieldValues) => {
                        return { categoryGeneralType: fieldValue };
                    },
                },
                {
                    field: "amountType",
                    updates: (fieldValue, fieldName, allFieldValues) => {
                        const updatedFormValues = {};

                        if (fieldValue === "Bales" && !allFieldValues.baleSizingMethod) {
                            updatedFormValues.baleSizingMethod = "Standard";
                        }

                        allFieldValues.sources.forEach((s, i) => {
                            const amountFieldName = `sources[${i}].amount`;
                            if (allFieldValues.amountType === "Weight") updatedFormValues[amountFieldName] = FormUtils.formatters.formatDecimal(1)(allFieldValues.sources[i].amount);
                            else updatedFormValues[amountFieldName] = FormUtils.formatters.formatInt(allFieldValues.sources[i].amount);

                            // Cuts must all be from the same block.
                            if (allFieldValues.amountType === "Cuts" && i > 0 && !s.blockId) {
                                const blockIdFieldName = `sources[${i}].blockId`;
                                updatedFormValues[blockIdFieldName] = allFieldValues.sources[0].blockId;
                            }
                        });

                        return updatedFormValues;
                    },
                },
                {
                    field: "baleDM",
                    updates: (fieldValue, fieldName, allFieldValues) => {
                        const baleEquivalents = fieldValue && fieldValue >= 20 ? fieldValue / 20 : undefined;
                        return { baleEquivalents };
                    },
                },
                {
                    field: "name",
                    updates: (fieldValue, fieldName, allFieldValues) => {
                        this.setState({ feed: fieldValue });

                        const updatedFormValues = {};

                        if (supplementUtils.canEstimateByWeightOnly(allFieldValues)) {
                            updatedFormValues.amountType = "Weight";
                        }

                        return updatedFormValues;
                    },
                },
                {
                    field: "nutrientSource",
                    updates: (fieldValue, fieldName, allFieldValues) => {
                        const updatedFormValues = { isGlobal: false };

                        if (supplementUtils.canEstimateByWeightOnly(allFieldValues)) {
                            updatedFormValues.amountType = "Weight";
                        }

                        supplementUtils.resetNutrients(updatedFormValues);
                        if (allFieldValues.category === "Userdefined") {
                            const { nutrientSource = {} } = this.props;
                            const customComposition = fieldValue === allFieldValues.id ? nutrientSource : this.props.customCompositions.find((c) => c.id === fieldValue);
                            supplementUtils.copyCustomComposition(updatedFormValues, customComposition);
                            updatedFormValues.name = customComposition && customComposition.name;
                            updatedFormValues.isGlobal = customComposition && customComposition.id !== nutrientSource.id;
                        }

                        return updatedFormValues;
                    },
                },
                {
                    field: /sources\[\d+\]\.blockId/g,
                    updates: (fieldValue, fieldName, allFieldValues) => {
                        const updatedFormValues = {};

                        // Cuts must all be from the same block.
                        if (allFieldValues.amountType === "Cuts") {
                            const index = Number(fieldName.match(/\d+/)[0]);
                            if (index === 0) {
                                const source = allFieldValues.sources[index];
                                allFieldValues.sources.forEach((s, i) => {
                                    if (i > 0) {
                                        const blockIdFieldName = fieldName.replace(index, i);
                                        updatedFormValues[blockIdFieldName] = source.blockId;
                                    }
                                });
                            }
                        }

                        return updatedFormValues;
                    },
                },
            ),
        ];
    };

    _validate = (values) => {
        let errors = {};

        if (this._showSource(values)) {
            errors.type = FormUtils.validators.required(values.type);
            if (errors.type) return errors;
        }

        if (this._showCategory(values)) {
            errors.category = FormUtils.validators.required(values.category);
            if (errors.category) return errors;
        }

        if (this._showSupplementaryFeed(values)) {
            errors.name = FormUtils.validators.required(values.name);
        }

        errors.amountType = FormUtils.validators.required(values.amountType);

        if (this._showAmount(values)) {
            errors.amount = FormUtils.validators.required(values.amount);
            errors.amount = errors.amount || FormUtils.validators.range(values.amountType === "Weight" ? 0.1 : 1, 10000)(values.amount);
        }

        if (this._showAreaCut(values)) {
            errors.areaCut = FormUtils.validators.required(values.areaCut);
            errors.areaCut = errors.areaCut || FormUtils.validators.range(1, 10000)(values.areaCut);
        }

        if (this._showBalesPackagingMethod(values) || this._showNonBalesPackagingMethod(values)) {
            errors.packagingMethod = FormUtils.validators.required(values.packagingMethod);
        }

        if (this._showSilageCuttingMethod(values)) {
            errors.wilted = FormUtils.validators.required(values.wilted);
        }

        if (this._showBaleEquivalents(values)) {
            errors.baleDM = errors.baleDM || FormUtils.validators.range(20, 10000)(values.baleDM);
        }

        if (this._showBaleDimensions(values)) {
            errors.baleHeight_cm = FormUtils.validators.required(values.baleHeight_cm);
            errors.baleHeight_cm = errors.baleHeight_cm || FormUtils.validators.range(1, 200)(values.baleHeight_cm);
            errors.baleWidth_cm = FormUtils.validators.required(values.baleWidth_cm);
            errors.baleWidth_cm = errors.baleWidth_cm || FormUtils.validators.range(1, 200)(values.baleWidth_cm);
        }

        if (this._showNutrientSource(values)) {
            errors.nutrientSource = FormUtils.validators.required(values.nutrientSource);
            errors.name = FormUtils.validators.required(values.name);
        }

        if (this._showBaleDepth(values)) {
            errors.baleDepth_cm = FormUtils.validators.required(values.baleDepth_cm);
            errors.baleDepth_cm = errors.baleDepth_cm || FormUtils.validators.range(1, 200)(values.baleDepth_cm);
        }

        if (this._showCustomNutrients(values)) {
            const nutrientErrors = validateNutrients(values, "Supplements");
            errors = { ...errors, ...nutrientErrors };
        }

        return errors;
    };

    _submit = async (viewModel) => {
        this.setState({ submitting: true });

        const { farm, analysis } = this.props;

        const feedSupplement = this._mapViewModelToStateModel(viewModel);
        const updatedAnalysis = supplementUtils.getUpdatedAnalysisFromSavingSupplement(analysis, feedSupplement);
        await this.props.updateAnalysisAsync(updatedAnalysis);

        this.setState({
            submitting: false,
            submitSucceeded: true,
            referrer: viewModel.saveType === "Save" ? this.state.referrer : `/app/farm/${farm.id}/analysis/${analysis.id}/supplements/${feedSupplement.id}/distribution`,
        });
    };

    render() {
        const { online, refData, showQuickTips, blocks } = this.props;
        const assumptions = domain.SupplementaryFeedAssumptions.find((a) => a.key === this.state.feed);
        return (
            <Form initialValues={this.state.viewModel} decorators={this.state.decorators} mutators={{ ...arrayMutators }} validate={this._validate} onSubmit={this._submit}>
                {({ form, values, dirty, handleSubmit, errors }) => {
                    const _hasValues = Object.keys(values).length > 0;
                    const _isImported = supplementUtils.isImported(values);
                    const _categories = this._formatCategories(values, supplementUtils.getCategories(values.type, refData));
                    const _supplementaryFeeds = supplementUtils.getSupplementaryFeeds(values, refData);
                    const _nutrientSource = supplementUtils.getNutrientSource(this.props.customCompositions, this.props.nutrientSource);
                    const _amountTypes = supplementUtils.getAmountTypes(values, refData);
                    const _amountTypeMeta = supplementUtils.getAmountTypeMeta(values.amountType, values.type);
                    const _baleSizingMethods = supplementUtils.getBaleSizingMethods();
                    const title = values.type === "FromStorage" ? "Supplement from storage" : (values.type === "Purchased" ? "Purchased supplement" : "Harvested supplement");

                    return (
                        <form onSubmit={handleSubmit}>
                            <SavePrompt blockIf={dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={this.state.referrer} />
                            <Tile title={title} waiting={this.state.submitting} referrer={this.state.referrer}>
                                {!_isImported && <Alert className="u-mb-0" type="info" text="Select the category of supplement and add each harvest event. All blocks selected must have the same type of pasture." />}
                                {showQuickTips && (
                                    <Alert
                                        className="u-mb-0"
                                        type="help"
                                        text={
                                            <div>
                                                <div>Describe pasture that is cut, conserved and fed in the same year. There is also an option to store the supplement, which means conserving in one year and using in another. Feed can also be exported off farm.</div>
                                                <br />
                                                <div>By default the model assumes that supplement removal starts in late spring and continues through summer to autumn, depending on the amount of supplement removed, and that animal grazing occurs in the remainder of the time (winter and back towards spring). The timing of animal grazing can be set on the Animals page.</div>
                                            </div>
                                        }
                                    />
                                )}
                                <TileBody>
                                    <Grid title="Supplement details">
                                        <Field name="id" component={HiddenField} />
                                        <Field name="type" component={HiddenField} />
                                        {this._showCategory(values) && (
                                            <GridCell className="u-md-width1of2 u-lg-width1of4">
                                                <Field name="category" label="Category" placeholder="Select a category" options={_categories} required component={SelectField} />
                                            </GridCell>
                                        )}
                                        {this._showSupplementaryFeed(values) && (
                                            <GridCell className="u-md-width1of2 u-lg-width1of4">
                                                <Field name="name" label="Supplementary feed" placeholder="Select a feed" options={_supplementaryFeeds} required component={SelectField} />
                                            </GridCell>
                                        )}
                                        {assumptions && assumptions.text && (
                                            <GridCell className="u-md-width1of1 u-lg-width1of1">
                                                <Alert type="info" text={assumptions.text} bold={assumptions.bold} />
                                            </GridCell>
                                        )}
                                        {this._showNutrientSource(values) && (
                                            <GridCell className="u-md-width1of2 u-lg-width1of4">
                                                <Field name="nutrientSource" label="Nutrient source" placeholder="Select a source" options={_nutrientSource} required component={SelectField} />
                                            </GridCell>
                                        )}
                                        {this._showWrapped(values) && (
                                            <GridCell className="u-md-width1of2 u-lg-width3of4 u-mt-lg">
                                                <Field name="wrapped" label="Wrapped or covered with plastic" component={CheckboxField} />
                                            </GridCell>
                                        )}
                                        {this._showSilageStack(values) && (
                                            <GridCell>
                                                <FieldGroup>
                                                    <Field name="silageStack" label="Silage stack used" component={CheckboxField} />
                                                    {values.silageStack && <Field name="stackContained" label="Effluent from stack contained" component={CheckboxField} />}
                                                </FieldGroup>
                                            </GridCell>
                                        )}
                                    </Grid>
                                    {this._showCustomNutrients(values) && (
                                        <Grid>
                                            <GridCell className="u-lg-width1of2">
                                                <Field name="name" label="Name" required component={TextField} />
                                                {values.isGlobal && <Alert className="u-mb-0" type="info" text="Nutrient details have been copied from the global nutrient supplement make up. Editing these nutrients within the analysis will only edit the supplement for the analysis - not the global supplement" />}
                                            </GridCell>
                                            <GridCell className="u-lg-width3of4">
                                                <Nutrients type="Supplements" errors={errors} nutrients={values.nutrients} prefix="" />
                                            </GridCell>
                                        </Grid>
                                    )}
                                    {this._showWeightFields(values) && (
                                        <Grid title="Estimated weight">
                                            {this._showAmountType(values) && (
                                                <GridCell>
                                                    <Field name="amountType" label="Weight estimated by" options={_amountTypes} value={values.amountType} component={RadioGroupField} inline />
                                                </GridCell>
                                            )}
                                            {this._showAmount(values) && (
                                                <GridCell className="u-md-width1of2 u-lg-width1of4">
                                                    {_amountTypeMeta.uom === "tonnes"
                                                        ? <Field name="amount" label={_amountTypeMeta.label} placeholder="0" uom={_amountTypeMeta.uom} required component={DecimalNumberField} decimalPlaces={1} />
                                                        : <Field name="amount" label={_amountTypeMeta.label} placeholder="0" uom={_amountTypeMeta.uom} required component={WholeNumberField} />
                                                    }
                                                </GridCell>
                                            )}
                                            {this._showIsDryWeight(values) && (
                                                <GridCell className={`u-md-width1of2 u-lg-width1of4 ${_isImported ? "u-mt-lg" : ""}`}>
                                                    <Field name="isDryWeight" label="Weight defined as dry weight" component={CheckboxField} />
                                                </GridCell>
                                            )}
                                        </Grid>
                                    )}
                                    {this._showSilageCuttingMethod(values) && (
                                        <Grid>
                                            <GridCell className="u-md-width1of2 u-lg-width1of4">
                                                <Field name="wilted" label="Silage cutting method" placeholder="Select a method" options={refData.silageCuttingMethods} required component={SelectField} />
                                            </GridCell>
                                        </Grid>
                                    )}
                                    {this._showWeightFields(values) && (
                                        <Grid>
                                            {this._showAreaCut(values) && (
                                                <GridCell className="u-md-width1of2 u-lg-width1of4">
                                                    <Field name="areaCut" label="Area cut" placeholder="0.0" uom="ha" required component={WholeNumberField} />
                                                </GridCell>
                                            )}
                                            {this._showNonBalesPackagingMethod(values) && (
                                                <GridCell className="u-lg-width1of4">
                                                    <Field name="packagingMethod" label="Packaging" placeholder="Select a method" options={refData.supplementPackagingMethods} required component={SelectField} />
                                                </GridCell>
                                            )}
                                            {this._showBaleFields(values) && <>
                                                <GridCell>
                                                    <Field name="baleSizingMethod" label="Bale sizing method" options={_baleSizingMethods} value={values.baleSizingMethod} component={RadioGroupField} inline />
                                                </GridCell>
                                                {this._showBalesPackagingMethod(values) && (
                                                    <GridCell className="u-md-width1of2 u-lg-width1of4">
                                                        <Field name="packagingMethod" label="Packaging" placeholder="Select a method" options={refData.supplementPackagingMethods} required component={SelectField} />
                                                    </GridCell>
                                                )}
                                                {this._showBaleEquivalents(values) && (
                                                    <GridCell className="u-md-width1of2 u-lg-width1of5">
                                                        <Field name="baleDM" label="DM/bale" placeholder="20" uom="kg" component={WholeNumberField} />
                                                    </GridCell>
                                                )}
                                                {this._showBaleDimensions(values) && <>
                                                    <GridCell className="u-md-width1of2 u-lg-width1of4">
                                                        <Field name="baleHeight_cm" label="Height" placeholder="0" uom="cm" required component={WholeNumberField} />
                                                    </GridCell>
                                                    <GridCell className="u-md-width1of2 u-lg-width1of4">
                                                        <Field name="baleWidth_cm" label={this._showBaleDepth(values) ? "Width" : "Diameter"} placeholder="0" uom="cm" required component={WholeNumberField} />
                                                    </GridCell>
                                                    {this._showBaleDepth(values) && (
                                                        <GridCell className="u-md-width1of2 u-lg-width1of4">
                                                            <Field name="baleDepth_cm" label="Depth" placeholder="0" uom="cm" required component={WholeNumberField} />
                                                        </GridCell>
                                                    )}
                                                </>}
                                            </>}
                                            {this._showSources(values) && (
                                                <GridCell className="u-mt-lg u-lg-width1of1">
                                                    <FeedSupplementSources form={form} values={values} blocks={blocks} />
                                                </GridCell>
                                            )}
                                        </Grid>
                                    )}
                                    {online && this._showWeightFields(values) && (this._showAmount(values) || this._showSources(values)) && (
                                        <EstimatedDryMatterWeight farm={this.props.farm} analysis={this.props.analysis} feedSupplement={this._mapViewModelToStateModel(values)} />
                                    )}
                                </TileBody>
                                <TileFooter>
                                    <div className="ButtonBar ButtonBar--fixed">
                                        <div className="ButtonBar-left">
                                            <Link to={this.state.referrer} id="cancel-button" className="Button Button--secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="ButtonBar-right">
                                            <Button id="save-and-distribute-button" submit tertiary waiting={values.saveType === "SaveAndDistribute" && this.state.submitting} disabled={!_hasValues} onClick={() => form.change("saveType", "SaveAndDistribute")}>
                                                Save and distribute
                                            </Button>
                                            <Button id="submit-button" submit primary waiting={values.saveType === "Save" && this.state.submitting} disabled={!_hasValues} onClick={() => form.change("saveType", "Save")}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </TileFooter>
                            </Tile>
                        </form>
                    );
                }}
            </Form>
        )
    }
}
