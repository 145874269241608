import React from "react";
import TileSubPanel from "components/TileSubPanel";
import { Bar, Scatter } from "react-chartjs-2";
import TileActionsBar from "components/TileActionsBar";
import * as utils from "common/utils";
import { Link } from "react-router-dom";
import * as domain from "common/domain";
import numeral from "numeral";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";
import { Panel, PanelBody } from "components/Panel";
import Spinner from "components/Spinner/Spinner";

const blockTabOptions = ["Total N", "N per ha", "Total P", "P per ha"];
const drainageKey = "WaterMovement";

class ModellingRun extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAnalysisResultsOpen: false,
            isAnalysisMonthlyResultsOpen: false,
            isBlockResultsOpen: false,
            isBlockMonthlyResultsOpen: false,
            showAnalysisBudgetDifferences: true,
            showBlockBudgetDifferences: false,
            blockActiveTab: 0,
        };
    }

    substringName(name) {
        return name.length > 10 ? name.substring(0, 9) : name;
    }

    csvRun(run) {
        return [run.description, utils.dateLong(run.dateRun).replace(/,/g, ""), run.modelType, run.type, run.engineVersion].join(",") + "\n";
    }

    csvHeader(run1, run2) {
        const headers = ["Run", "Date", "Model type", "Run type", "Engine version"].join(",") + "\n";

        return headers + this.csvRun(run1) + (run2 ? this.csvRun(run2) : "") + "\n";
    }

    downloadCsv(csvContent, fileName) {
        var blob = new Blob([csvContent], { type: "text/csv" });
        var url = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    }

    downloadMonthlyBlockData(dsname, run, includePreviousYear) {
        let monthlyCSVData = this.csvHeader(run) + ["Farm name", "Analysis name", "Block name", "Block type", "Label", "Attribute"].concat(domain.calendarYear.map((m) => m)).join(",");

        if (includePreviousYear) monthlyCSVData += "," + domain.calendarYear.map((m) => m).join(",");

        /* eslint-disable no-unused-vars */
        for (const a of run.analyses.sort((a, b) => (b.name < a.name ? 1 : -1))) {
            /* eslint-disable no-unused-vars */
            for (const b of a.blocks) {
                /* eslint-disable no-unused-vars */
                for (const eId of Object.keys(b.enterpriseUrineReports)) {
                    /* eslint-disable no-unused-vars */
                    for (const key of Object.keys(b.enterpriseUrineReports[eId])) {
                        const monthMapList = b.enterpriseUrineReports[eId][key];
                        let csvArray = [a.farmName ? a.farmName.replace(/,/g, "") : "", a.name.replace(/,/g, ""), b.name.replace(/,/g, ""), b.type, a.enterprisesMap[eId], key];

                        if (includePreviousYear) csvArray = csvArray.concat(domain.calendarYear.map((m) => "-"));

                        csvArray = csvArray.concat(
                            domain.calendarYear.map((m) => {
                                return monthMapList.results[m];
                            })
                        );
                        monthlyCSVData += "\n" + csvArray.join(",");
                    }
                }
                if (b.pastureResults && b.pastureResults.enterprisePastureReports) {
                    /* eslint-disable no-unused-vars */
                    for (const eId of Object.keys(b.pastureResults.enterprisePastureReports)) {
                        /* eslint-disable no-unused-vars */
                        for (const key of Object.keys(b.pastureResults.enterprisePastureReports[eId])) {
                            const monthMapList = b.pastureResults.enterprisePastureReports[eId][key];
                            let csvArray = [a.farmName ? a.farmName.replace(/,/g, "") : "", a.name.replace(/,/g, ""), b.name.replace(/,/g, ""), b.type, a.enterprisesMap[eId], key];

                            if (includePreviousYear) csvArray = csvArray.concat(domain.calendarYear.map((m) => "-"));

                            csvArray = csvArray.concat(
                                domain.calendarYear.map((m) => {
                                    return monthMapList.results[m];
                                })
                            );
                            monthlyCSVData += "\n" + csvArray.join(",");
                        }
                    }
                }
                /* eslint-disable no-unused-vars */
                for (const key of Object.keys(b.nPools)) {
                    const monthMapList = b.nPools[key];
                    monthlyCSVData += "\n" + this.createNPoolsLine(a, b, "N pools", key, monthMapList, includePreviousYear).join(",");
                }
                /* eslint-disable no-unused-vars */
                for (const key of Object.keys(b.changeInNPools)) {
                    const monthMapList = b.changeInNPools[key];
                    monthlyCSVData += "\n" + this.createNPoolsLine(a, b, "Change in N pools", key, monthMapList, includePreviousYear).join(",");
                }
                if (b.soilResults) {
                    /* eslint-disable no-unused-vars */
                    for (const soilResults of b.soilResults) {
                        for (const report of soilResults.results) {
                            monthlyCSVData += "\n" + this.createNPoolsLine(a, b, soilResults.label, report.type, report, includePreviousYear).join(",");
                        }
                    }
                }
            }
        }
        const filename = this.substringName(dsname) + "-" + this.substringName(run.description) + "-monthly-blocks.csv";
        this.downloadCsv(monthlyCSVData, filename);
    }

    createNPoolsLine(a, b, label, attribute, monthMapList, includePreviousYear) {
        let csvline = [a.farmName ? a.farmName.replace(/,/g, "") : "", a.name.replace(/,/g, ""), b.name.replace(/,/g, ""), b.type, label, attribute];

        if (includePreviousYear) {
            if (monthMapList.previousResults && monthMapList.previousResults)
                csvline = csvline.concat(
                    domain.calendarYear.map((m) => {
                        return monthMapList.previousResults[m];
                    })
                );
            else
                csvline = csvline.concat(
                    domain.calendarYear.map((m) => {
                        return "-";
                    })
                );
        }
        csvline = csvline.concat(
            domain.calendarYear.map((m) => {
                return monthMapList.results ? monthMapList.results[m] : "-";
            })
        );
        return csvline;
    }

    downloadMonthlyAnalysisData(dsname, run) {
        let monthlyCSVData = this.csvHeader(run) + ["Farm name", "Analysis", "Enterprise", "Stock class", "Attribute"].concat(domain.calendarYear.map((m) => m)).join(",");
        /* eslint-disable no-unused-vars */
        for (const a of run.analyses.sort((a, b) => (b.name < a.name ? 1 : -1))) {
            /* eslint-disable no-unused-vars */
            for (const eId of Object.keys(a.animalResults)) {
                /* eslint-disable no-unused-vars */
                for (const key of Object.keys(a.animalResults[eId].monthlyResults)) {
                    const monthMapList = a.animalResults[eId].monthlyResults[key];
                    const csvline = [a.farmName ? a.farmName.replace(/,/g, "") : "", a.name.replace(/,/g, ""), a.enterprisesMap[eId], "", key]
                        .concat(
                            domain.calendarYear.map((m) => {
                                return monthMapList.results[m];
                            })
                        )
                        .join(",");
                    monthlyCSVData += "\n" + csvline;
                }
                if (a.animalResults[eId].stockResults) {
                    /* eslint-disable no-unused-vars */
                    for (const stockResults of a.animalResults[eId].stockResults) {
                        /* eslint-disable no-unused-vars */
                        for (const stockKey of Object.keys(stockResults.meRequirements)) {
                            const csvline = [a.farmName ? a.farmName.replace(/,/g, "") : "", a.name.replace(/,/g, ""), a.enterprisesMap[eId], stockResults.stockClass, stockKey]
                                .concat(
                                    domain.calendarYear.map((m) => {
                                        return stockResults.meRequirements[stockKey].results[m];
                                    })
                                )
                                .join(",");
                            monthlyCSVData += "\n" + csvline;
                        }
                    }
                }
                if (a.animalResults[eId].intake) {
                    /* eslint-disable no-unused-vars */
                    for (const feedSource of Object.keys(a.animalResults[eId].intake)) {
                        const csvline = [a.farmName ? a.farmName.replace(/,/g, "") : "", a.name.replace(/,/g, ""), a.enterprisesMap[eId], "", "N Intake - " + feedSource]
                            .concat(
                                domain.calendarYear.map((m) => {
                                    const nutrients = a.animalResults[eId].intake[feedSource].find((nutrients) => nutrients.month === m);
                                    if (nutrients) return nutrients.nutrients.N;
                                    else return "";
                                })
                            )
                            .join(",");
                        monthlyCSVData += "\n" + csvline;
                    }
                }
            }
        }

        const filename = this.substringName(dsname) + "-" + this.substringName(run.description) + "-monthly-analyses.csv";
        this.downloadCsv(monthlyCSVData, filename);
    }

    downloadAnalysisData(dsname, run1, run2) {
        let analysisCsv = this.csvHeader(run1, run2);

        let titles = ["Farm ID", "Farm name", "Analysis name", "Reference", "Total N", "N/Ha", "Total P", "P/Ha", "GHG total"];
        if (run2) {
            titles = titles.concat(["Total N", "N/Ha", "Total P", "P/Ha", "GHG total"]);
        }
        analysisCsv += titles.join(",");

        /* eslint-disable no-unused-vars */
        for (const a1 of run1.analyses.sort((a, b) => (b.name < a.name ? 1 : -1))) {
            const n1Overview = a1.nitrogenOverview || {};
            const p1Overview = a1.phosphorusOverview || {};
            let csvArray = [a1.farmId, a1.farmName ? a1.farmName.replace(/,/g, "") : "", a1.name.replace(/,/g, ""), a1.reference && a1.reference.replace(/,/g, ""), n1Overview.totalLost, n1Overview.lostToWater, p1Overview.totalLost, p1Overview.lostToWater, a1.ghgResults && a1.ghgResults.total];

            const a2 = run2 && run2.analyses.find((a2) => a2.id === a1.id);
            if (a2) {
                const n2Overview = a2.nitrogenOverview || {};
                const p2Overview = a2.phosphorusOverview || {};
                csvArray = csvArray.concat([n2Overview.totalLost, n2Overview.lostToWater, p2Overview.totalLost, p2Overview.lostToWater, a2.ghgResults && a2.ghgResults.total]);
            }
            analysisCsv += "\n" + csvArray.join(",");
        }
        const filename = this.substringName(dsname) + "-analyses.csv";
        this.downloadCsv(analysisCsv, filename);
    }

    downloadAnalysisGHGData(dsname, run1, run2) {
        let analysisCsv = this.csvHeader(run1, run2);

        let titles = ["Farm ID", "Farm name", "Analysis name", "Reference", "GHG total", "Methane", "N2O"];
        if (run2) {
            titles = titles.concat(["GHG total", "Methane", "N2O"]);
        }
        analysisCsv += titles.join(",");

        /* eslint-disable no-unused-vars */
        for (const a1 of run1.analyses.sort((a, b) => (b.name < a.name ? 1 : -1))) {
            let csvArray = [a1.farmId, a1.farmName ? a1.farmName.replace(/,/g, "") : "", a1.name.replace(/,/g, ""), a1.reference && a1.reference.replace(/,/g, ""), a1.ghgResults && a1.ghgResults.total, a1.ghgResults && a1.ghgResults.methaneEmissionsTotal, a1.ghgResults && a1.ghgResults.n2OEmissionsTotal];

            const a2 = run2 && run2.analyses.find((a2) => a2.id === a1.id);
            if (a2) {
                csvArray = csvArray.concat([a2.ghgResults && a2.ghgResults.total, a2.ghgResults && a2.ghgResults.methaneEmissionsTotal, a2.ghgResults && a2.ghgResults.n2OEmissionsTotal]);
            }
            analysisCsv += "\n" + csvArray.join(",");
        }
        const filename = this.substringName(dsname) + "-analyses-ghg.csv";
        this.downloadCsv(analysisCsv, filename);
    }

    downloadBlockData(dsname, run1, run2, hasMeasuredN, hasDrainage, hasPastureDryMatterIntake, hasPastureNIntake) {
        let blockCsv = this.csvHeader(run1, run2);

        let titles = ["Analysis ID", "Block ID", "Farm name", "Region", "Town", "Analysis name", "Block name", "Block type", "Area", "Soil1 Name", "Soil1 Area", "Soil2 Name", "Soil2 Area", "Soil3 Name", "Soil3 Area", "Crop/Pasture", "Animals", "Fertiliser N", "Total N", "N/Ha", "Total P", "P/Ha", "Annual PET", "Annual rainfall", "Annual temp", "Longitude", "Latitude"];
        if (hasDrainage) titles.push("Drainage");
        if (hasMeasuredN) titles.push("Measured N/Ha");
        if (hasPastureDryMatterIntake) titles.push("Pasture Dry Matter Intake");
        if (hasPastureNIntake) titles.push("Pasture Nitrogen Intake");

        if (run1.modelType === "Lysimeter") titles = titles.concat(["NPV1", "NPV2", "NPV3"]);

        if (run2) {
            titles = titles.concat(["Total N", "N/Ha", "Total P", "P/Ha"]);
            if (hasDrainage) titles.push("Drainage");
            if (run2.modelType === "Lysimeter") titles = titles.concat(["NPV1", "NPV2", "NPV3"]);
        }

        blockCsv += titles.join(",");
        /* eslint-disable no-unused-vars */
        for (const a1 of run1.analyses.sort((a, b) => (b.name < a.name ? 1 : -1))) {
            const a2 = run2 && run2.analyses.find((a2) => a2.id === a1.id);
            /* eslint-disable no-unused-vars */
            for (const b1 of a1.blocks) {
                const n1Overview = b1.nitrogenOverview || {};
                const p1Overview = b1.phosphorusOverview || {};
                const { climate = {} } = b1;

                const soil1Name = b1.soils && b1.soils.length > 0 ? (b1.soils[0].sMap ? `${b1.soils[0].sMap} - ${b1.soils[0].group}` : b1.soils[0].group) : "";
                const soil1Area = b1.soils && b1.soils.length > 0 ? b1.soils[0].area : "";
                const soil2Name = b1.soils && b1.soils.length > 1 ? (b1.soils[1].sMap ? `${b1.soils[1].sMap} - ${b1.soils[1].group}` : b1.soils[1].group) : "";
                const soil2Area = b1.soils && b1.soils.length > 1 ? b1.soils[1].area : "";
                const soil3Name = b1.soils && b1.soils.length > 2 ? (b1.soils[2].sMap ? `${b1.soils[2].sMap} - ${b1.soils[2].group}` : b1.soils[2].group) : "";
                const soil3Area = b1.soils && b1.soils.length > 2 ? b1.soils[2].area : "";

                let csvArray = [a1.id, b1.id, a1.farmName ? a1.farmName.replace(/,/g, "") : "", a1.region ? a1.region : "", a1.nearestTown ? a1.nearestTown : "", a1.name.replace(/,/g, ""), b1.name.replace(/,/g, ""), b1.type, b1.area, soil1Name, soil1Area, soil2Name, soil2Area, soil3Name, soil3Area, b1.crops, b1.animals ? b1.animals.join(" ") : "", b1.fertiliserTotalNKgs, n1Overview.totalLost, n1Overview.lostToWater, p1Overview.totalLost, p1Overview.lostToWater, climate.annualPetInMM, climate.averageRain, climate.averageTemp, climate.longitude, climate.latitude];
                if (hasDrainage) csvArray.push(b1.totalDrainage);
                if (hasMeasuredN) csvArray.push(b1.measuredNPerHa);
                if (hasPastureDryMatterIntake) csvArray.push(b1.totalPastureDMIntake);
                if (hasPastureNIntake) csvArray.push(b1.totalPastureNIntake);

                if (run1.modelType === "Lysimeter") csvArray = csvArray.concat([b1.otherValues.NPv1 && b1.otherValues.NPv1.number, b1.otherValues.NPv2 && b1.otherValues.NPv2.number, b1.otherValues.NPv3 && b1.otherValues.NPv3.number]);

                const b2 = a2 && a2.blocks.find((b2) => b2.id === b1.id);
                if (b2) {
                    const n2Overview = b2.nitrogenOverview || {};
                    const p2Overview = b2.phosphorusOverview || {};

                    csvArray = csvArray.concat([n2Overview.totalLost, n2Overview.lostToWater, p2Overview.totalLost, p2Overview.lostToWater]);
                    if (hasDrainage) csvArray.push(b2.totalDrainage);
                    if (hasPastureDryMatterIntake) csvArray.push(b2.totalPastureDMIntake);
                    if (hasPastureNIntake) csvArray.push(b2.totalPastureNIntake);

                    if (run2.modelType === "Lysimeter") csvArray = csvArray.concat([b2.otherValues.NPv1 && b2.otherValues.NPv1.number, b2.otherValues.NPv2 && b2.otherValues.NPv2.number, b2.otherValues.NPv3 && b2.otherValues.NPv3.number]);
                }
                blockCsv += "\n" + csvArray.join(",");
            }
        }
        const filename = this.substringName(dsname) + "-blocks.csv";
        this.downloadCsv(blockCsv, filename);
    }

    downloadAveragedBlockData(dsname, run) {
        let blockCsv = this.csvHeader(run);

        let titles = ["Farm name", "Block name", "Modelled N/Ha", "Measured N/Ha"];
        blockCsv += titles.join(",") + "\n";

        var blocksMap = run.analyses.reduce((map, a) => {
            a.blocks
                .filter((b) => b.measuredNPerHa)
                .forEach((b) => {
                    const nOverview = b.nitrogenOverview || {};
                    if (!map[a.farmId + "-" + b.name]) map[a.farmId + "-" + b.name] = { farmName: a.farmName, name: b.name, modelledNLosses: [], measuredNLosses: [] };

                    map[a.farmId + "-" + b.name].modelledNLosses.push(nOverview.lostToWater);
                    map[a.farmId + "-" + b.name].measuredNLosses.push(b.measuredNPerHa);
                });
            return map;
        }, {});

        var rows = Object.keys(blocksMap).map((k) => {
            var avgModelledNloss = blocksMap[k].modelledNLosses.reduce((sum, l) => (sum += l), 0);
            var avgMeasuredNloss = blocksMap[k].measuredNLosses.reduce((sum, l) => (sum += l), 0);
            var csvArray = [blocksMap[k].farmName, blocksMap[k].name, utils.round(avgModelledNloss / blocksMap[k].modelledNLosses.length, 0), utils.round(avgMeasuredNloss / blocksMap[k].measuredNLosses.length, 0)];
            return csvArray.join(",");
        });

        const filename = this.substringName(dsname) + "-avg-blocks.csv";
        this.downloadCsv(blockCsv + rows.join("\n"), filename);
    }

    downloadAnalysisAndBlockData(dsname, run1) {
        let blockCsv = this.csvHeader(run1);

        let titles = ["Analysis ID", "Block ID", "Reference", "Farm name", "Analysis name", "Year", "Analysis Total N", "Analysis N/Ha", "Analysis Total P", "Analysis P/Ha", "Analysis GHG", "Block name", "Block type", "Crop/Pasture", "Area", "Total N", "N/Ha", "Total P", "P/Ha"];

        blockCsv += titles.join(",");
        /* eslint-disable no-unused-vars */
        for (const a1 of run1.analyses.sort((a, b) => (b.name < a.name ? 1 : -1))) {
            const n1Overview = a1.nitrogenOverview || {};
            const p1Overview = a1.phosphorusOverview || {};

            /* eslint-disable no-unused-vars */
            for (const b1 of a1.blocks) {
                const n1BlockOverview = b1.nitrogenOverview || {};
                const p1BlockOverview = b1.phosphorusOverview || {};

                let csvArray = [a1.id, b1.id, a1.reference && a1.reference.replace(/,/g, ""), a1.farmName ? a1.farmName.replace(/,/g, "") : "", a1.name.replace(/,/g, ""), a1.year && a1.year > 0 ? a1.year : "", n1Overview.totalLost, n1Overview.lostToWater, p1Overview.totalLost, p1Overview.lostToWater, a1.ghgTotal, b1.name.replace(/,/g, ""), b1.type, b1.crops, b1.area, n1BlockOverview.totalLost, n1BlockOverview.lostToWater, p1BlockOverview.totalLost, p1BlockOverview.lostToWater];

                blockCsv += "\n" + csvArray.join(",");
            }
        }
        const filename = this.substringName(dsname) + "-blocks.csv";
        this.downloadCsv(blockCsv, filename);
    }

    downloadMinimumAnalysisData(dsname, run1, run2) {
        let csv = this.csvHeader(run1, run2);

        let titles = (!run2 && run1.sensitivityCriteria && run1.sensitivityCriteria.attributes.map((a) => a.attributeType)) || [];

        titles = titles.concat(["ID", "Farm", "Name", "Total N", "N/Ha", "Total P", "P/Ha", "GHG", "GHG/ha"]);

        if (run2 || run1.minimumResults[0].previousResults) titles = titles.concat(["Total N", "N/Ha", "Total P", "P/Ha", "GHG", "GHG/ha", "N matches", "P matches", "GHG matches"]);

        csv += titles.join(",");
        /* eslint-disable no-unused-vars */
        for (const a1 of run1.minimumResults.sort((a, b) => (b.name < a.name ? 1 : -1))) {
            let attributes = (!run2 && a1.sensitivityAttributes && a1.sensitivityAttributes.map((a) => a.valueDisplay)) || [];

            const a2 = run2 && run2.minimumResults.find((a2) => a2.id === a1.id);

            const n1Overview = a1.nitrogen || {};
            const p1Overview = a1.phosphorus || {};
            const ghg = a1.ghg || {};

            if (a1.errors && a1.errors.length > 0) {
                csv += "\n" + attributes.concat([a1.id, a1.farmName.replace(/,/g, ""), a1.name.replace(/,/g, ""), a1.errors[0].replace(/,/g, "")]).join(",");
            } else {
                csv += "\n" + attributes.concat([a1.id, a1.farmName.replace(/,/g, ""), a1.name.replace(/,/g, ""), n1Overview.total, n1Overview.perha, p1Overview.total, p1Overview.perha, ghg.total, ghg.perha]).join(",");
            }
            if (a2 || (a1.previousResults && !run2)) {
                const n2Overview = a2 ? a2.nitrogen || {} : a1.previousResults.nitrogen || {};
                const p2Overview = a2 ? a2.phosphorus || {} : a1.previousResults.phosphorus || {};
                const ghg2 = a2 ? a2.ghg || {} : a1.previousResults.ghg || {};

                if (a2 && a2.errors && a2.errors.length > 0) {
                    csv += "," + a2.errors[0].replace(/,/g, "");
                } else {
                    csv += "," + attributes.concat([n2Overview.total, n2Overview.perha, p2Overview.total, p2Overview.perha, ghg2.total, ghg2.perha]).join(",");
                }
                if (n1Overview.total === n2Overview.total) csv += ",Y";
                else csv += ",N";

                if (p1Overview.total === p2Overview.total) csv += ",Y";
                else csv += ",N";

                if (ghg.total === ghg2.total) csv += ",Y";
                else csv += ",N";
            }
        }
        const filename = this.substringName(dsname) + "-analyses.csv";
        this.downloadCsv(csv, filename);
    }

    downloadMinimumBlockData(dsname, run1) {
        let blockCsv = this.csvHeader(run1);

        let titles = ["Farm", "Analysis", "Name", "Total N", "N/Ha", "Total P", "P/Ha"];
        const criteria = run1.sensitivityCriteria && run1.sensitivityCriteria.attributes.map((a) => a.attributeType);

        if (criteria) titles = criteria.concat(titles);

        blockCsv += titles.join(",");
        /* eslint-disable no-unused-vars */
        for (const a1 of run1.minimumResults.sort((a, b) => (b.name < a.name ? 1 : -1))) {
            let attributes = a1.sensitivityAttributes && a1.sensitivityAttributes.map((a) => a.valueDisplay);

            /* eslint-disable no-unused-vars */
            for (const b1 of a1.blocks) {
                const n1Overview = b1.nitrogen || {};
                const p1Overview = b1.phosphorus || {};

                let csvArray = [a1.farmName.replace(/,/g, ""), a1.name.replace(/,/g, ""), b1.name.replace(/,/g, ""), n1Overview.total, n1Overview.perha, p1Overview.total, p1Overview.perha];
                if (attributes) csvArray = attributes.concat(csvArray);
                blockCsv += "\n" + csvArray.join(",");
            }
        }
        const filename = this.substringName(dsname) + "-blocks.csv";
        this.downloadCsv(blockCsv, filename);
    }

    compareAnalysis(a1, a2) {
        let results = [];
        const nb1 = a1 ? a1.nutrientBudget || {} : {};
        const nb2 = a2 ? a2.nutrientBudget || {} : {};

        if (nb1.nutrientCategories && nb2.nutrientCategories) {
            /* eslint-disable no-unused-vars */
            for (const key of Object.keys(nb1.nutrientCategories)) {
                if (nb2.nutrientCategories[key]) {
                    let nutrients = [];
                    /* eslint-disable no-unused-vars */
                    for (const nKey of Object.keys(nb1.nutrientCategories[key].total.nutrients)) {
                        if (nb1.nutrientCategories[key].total.nutrients[nKey] !== nb2.nutrientCategories[key].total.nutrients[nKey]) nutrients.push(nKey + "(" + (nb1.nutrientCategories[key].total.nutrients[nKey] || "-") + "|" + (nb2.nutrientCategories[key].total.nutrients[nKey] || "-") + ")");
                    }
                    if (nutrients.length > 0) results.push(key + ": " + nutrients.join(", "));
                }
            }
        } else if (a1 && a2 && a1.nitrogenOverview && a2.nitrogenOverview && a1.nitrogenOverview.totalLost !== a2.nitrogenOverview.totalLost) {
            results.push(numeral(a2.nitrogenOverview.totalLost - a1.nitrogenOverview.totalLost).format("0.00"));
        }
        return results;
    }

    analysisNutrientRow(analysisDataRow, analyses, compare) {
        const exists = analyses.find((a) => a.id === analysisDataRow.id);

        const className = analysisDataRow.comparison.length > 0 || (analysisDataRow.n2 && analysisDataRow.n1 !== analysisDataRow.n2) ? "Table-tr--red" : "";
        return (
            <tr className={className} key={analysisDataRow.id}>
                <td>
                    {exists && (
                        <Link to={`/app/farm/${analysisDataRow.farmId}`} target="_blank" className="FarmTitle-link">
                            <span className="FarmTitle-name">{analysisDataRow.farmName}</span>
                        </Link>
                    )}
                    {!exists && <span className="FarmTitle-name">{analysisDataRow.farmName}</span>}
                </td>
                <td>
                    {exists && (
                        <Link to={`/app/farm/${analysisDataRow.farmId}/analysis/${analysisDataRow.id}`} target="_blank" className="FarmTitle-link">
                            <span className="FarmTitle-name">{analysisDataRow.name}</span>
                        </Link>
                    )}
                    {!exists && <span className="FarmTitle-name">{analysisDataRow.name}</span>}
                </td>
                {this.state.showAnalysisBudgetDifferences && compare && <td>{analysisDataRow.comparison.join(",")}</td>}
                <td className="Table-td--red u-textCenter">{analysisDataRow.nPerHa1}</td>
                {compare && <td className="Table-td--blue u-textCenter">{analysisDataRow.nPerHa2}</td>}
                <td className="Table-td--red u-textCenter">{analysisDataRow.n1}</td>
                {compare && <td className="Table-td--blue u-textCenter">{analysisDataRow.n2}</td>}
                <td className="Table-td--red u-textCenter">{analysisDataRow.pPerHa1}</td>
                {compare && <td className="Table-td--blue u-textCenter">{analysisDataRow.pPerHa2}</td>}
                <td className="Table-td--red u-textCenter">{analysisDataRow.p1}</td>
                {compare && <td className="Table-td--blue u-textCenter">{analysisDataRow.p2}</td>}
                <td className="Table-td--red u-textCenter">{analysisDataRow.ghg1}</td>
                {compare && <td className="Table-td--blue u-textCenter">{analysisDataRow.ghg2}</td>}
            </tr>
        );
    }

    analysisDataRow(analysis1, analysis2, modelTest) {
        const results1 = analysis1 || {};
        const results2 = analysis2 || {};
        const n1 = results1.nitrogenOverview || {};
        const n2 = results2.nitrogenOverview || {};
        const p1 = results1.phosphorusOverview || {};
        const p2 = results2.phosphorusOverview || {};

        const comparison = this.state.showAnalysisBudgetDifferences ? (modelTest ? analysis1.notMatchingMessages : this.compareAnalysis(results1, results2)) : [];

        return { id: analysis1.id, farmId: analysis1.farmId, name: analysis1.name, farmName: analysis1.farmName, comparison: comparison, nPerHa1: n1.lostToWater, n1: n1.totalLost, pPerHa1: p1.lostToWater, p1: p1.totalLost, nPerHa2: n2.lostToWater, n2: n2.totalLost, pPerHa2: p2.lostToWater, p2: p2.totalLost, ghg1: results1.ghgResults && results1.ghgResults.total, ghg2: results2.ghgResults && results2.ghgResults.total };
    }

    blockNutrientRow(key, analyses, blockData, datasetId, modelType, refData, compare, hasMeasuredN, hasDrainage, hasPastureDryMatterIntake, hasPastureNIntake) {
        const className = blockData.comparison.length > 0 || (blockData.n1 && blockData.n2 && blockData.n1 !== blockData.n2) ? "Table-tr--red" : "";

        const exists = blockData.analysisId && analyses.find((a) => a.id === blockData.analysisId);

        return (
            <tr className={className} key={key}>
                <td>
                    {exists && (
                        <Link to={modelType === "Full" ? `/app/farm/${blockData.farmId}/analysis/${blockData.analysisId}` : `/app/datasets/${datasetId}/farm/${blockData.farmId}/analysis/${blockData.analysisId}`} className="FarmTitle-link">
                            <span className="FarmTitle-name">{blockData.farmName}</span>
                        </Link>
                    )}
                    {!exists && <span className="FarmTitle-name">{blockData.farmName}</span>}
                </td>
                <td>
                    {exists && (
                        <Link to={modelType === "Full" ? `/app/farm/${blockData.farmId}/analysis/${blockData.analysisId}` : `/app/datasets/${datasetId}/farm/${blockData.farmId}/analysis/${blockData.analysisId}`} className="FarmTitle-link">
                            <span className="FarmTitle-name">{blockData.analysisName}</span>
                        </Link>
                    )}
                    {!exists && <span className="FarmTitle-name">{blockData.analysisName}</span>}
                </td>
                <td>{blockData.name}</td>
                <td>{blockData.blockType ? utils.valueToText(refData.blockTypes, blockData.blockType) : ""}</td>
                <td>{blockData.crops}</td>
                <td>{blockData.animals}</td>
                {this.state.showBlockBudgetDifferences && <td>{blockData.comparison.join(",")}</td>}
                {hasMeasuredN && <td className="Table-td--orange u-textCenter">{blockData.measuredNPerHa ? blockData.measuredNPerHa.toFixed(2) : "-"}</td>}
                {modelType === "Full" && <td className="Table-td--red u-textCenter">{blockData.nPerHa1 ? blockData.nPerHa1.toFixed(2) : "-"}</td>}
                {modelType === "Full" && compare && <td className="Table-td--blue u-textCenter">{blockData.nPerHa2 ? blockData.nPerHa2.toFixed(2) : "-"}</td>}

                <td className="Table-td--red u-textCenter">{blockData.n1 ? blockData.n1.toFixed(2) : "-"}</td>
                {compare && <td className="Table-td--blue u-textCenter">{blockData.n2 ? blockData.n2.toFixed(2) : "-"}</td>}
                {modelType === "Full" && <td className="Table-td--red u-textCenter">{blockData.pPerHa1 ? blockData.pPerHa1.toFixed(2) : "-"}</td>}
                {modelType === "Full" && compare && <td className="Table-td--blue u-textCenter">{blockData.pPerHa2 ? blockData.pPerHa2.toFixed(2) : "-"}</td>}

                {modelType === "Full" && <td className="Table-td--red u-textCenter">{blockData.p1 ? blockData.p1.toFixed(2) : "-"}</td>}
                {modelType === "Full" && compare && <td className="Table-td--blue u-textCenter">{blockData.p2 ? blockData.p2.toFixed(2) : "-"}</td>}

                {hasDrainage ? <td className="Table-td--red u-textCenter">{blockData.drainage1 ? blockData.drainage1.toFixed(2) : "-"}</td> : undefined}
                {hasDrainage && compare ? <td className="Table-td--blue u-textCenter">{blockData.drainage2 ? blockData.drainage2.toFixed(2) : "-"}</td> : undefined}

                {hasPastureDryMatterIntake ? <td className="Table-td--red u-textCenter">{blockData.pastureDMI1 ? blockData.pastureDMI1.toFixed(2) : "-"}</td> : undefined}
                {hasPastureDryMatterIntake && compare ? <td className="Table-td--blue u-textCenter">{blockData.pastureDMI2 ? blockData.pastureDMI2.toFixed(2) : "-"}</td> : undefined}

                {hasPastureNIntake ? <td className="Table-td--red u-textCenter">{blockData.pastureNI1 ? blockData.pastureNI1.toFixed(2) : "-"}</td> : undefined}
                {hasPastureNIntake && compare ? <td className="Table-td--blue u-textCenter">{blockData.pastureNI2 ? blockData.pastureNI2.toFixed(2) : "-"}</td> : undefined}
            </tr>
        );
    }

    blockDataRow(analysis, block1, block2, modelTest) {
        const results1 = block1 || {};
        const results2 = block2 || {};
        const n1 = results1.nitrogenOverview || {};
        const n2 = results2.nitrogenOverview || {};
        const p1 = results1.phosphorusOverview || {};
        const p2 = results2.phosphorusOverview || {};

        const comparison = this.state.showBlockBudgetDifferences && modelTest ? block1.notMatchingMessages : [];

        return { id: block1.id, farmId: analysis && analysis.farmId, farmName: analysis && analysis.farmName, analysisId: analysis && analysis.id, analysisName: analysis && analysis.name, name: block1.name, blockType: block1.type, crops: block1.crops, animals: block1.animals && block1.animals.join(","), comparison: comparison, measuredNPerHa: block1.measuredNPerHa, nPerHa1: n1.lostToWater, n1: n1.totalLost, pPerHa1: p1.lostToWater, p1: p1.totalLost, nPerHa2: n2.lostToWater, n2: n2.totalLost, pPerHa2: p2.lostToWater, p2: p2.totalLost, drainage1: block1.totalDrainage, drainage2: block2 && block2.totalDrainage, pastureDMI1: block1.totalPastureDMIntake, pastureDMI2: block2 && block2.totalPastureDMIntake, pastureNI1: block1.totalPastureNIntake, pastureNI2: block2 && block2.totalPastureNIntake };
    }

    analysisMonthlyRow(analysis, key, enterpriseType, monthMapList) {
        return (
            <tr key={key}>
                <td>{analysis ? analysis.farmName : ""}</td>
                <td>{analysis ? analysis.name : ""}</td>
                <td>{enterpriseType ? enterpriseType : ""}</td>
                <td></td>
                <td>{monthMapList.label}</td>
                {monthMapList &&
                    domain.calendarYear.map((m) => {
                        return <td key={key + "-" + m}>{monthMapList.results[m]}</td>;
                    })}
            </tr>
        );
    }

    intakeMonthlyRow(enterpriseType, key, label, monthMapList) {
        return (
            <tr key={key}>
                <td></td>
                <td></td>
                <td>{enterpriseType}</td>
                <td></td>
                <td>{"N Intake - " + label}</td>
                {monthMapList &&
                    domain.calendarYear.map((m) => {
                        const mItem = monthMapList.find((mItem) => mItem.month === m);
                        return <td key={key + "-" + m}>{mItem ? mItem.nutrients.N : ""}</td>;
                    })}
            </tr>
        );
    }

    stockResultsMonthlyRow(analysis, enterpriseType, stockClass, key, monthMap) {
        return (
            <tr key={key}>
                <td>{analysis ? analysis.farmName : ""}</td>
                <td>{analysis ? analysis.name : ""}</td>
                <td>{enterpriseType ? enterpriseType : ""}</td>
                <td>{stockClass}</td>
                <td>{monthMap.label}</td>
                {monthMap &&
                    monthMap.results &&
                    domain.calendarYear.map((m) => {
                        const mItem = monthMap.results[m];
                        return <td key={key + "-" + m}>{mItem ? numeral(mItem).format("0.0") : ""}</td>;
                    })}
            </tr>
        );
    }

    monthlyRow(analysis, block, key, enterpriseType, label, monthMapList) {
        return (
            <tr key={key}>
                <td>{analysis ? analysis.farmName : ""}</td>
                <td>{analysis ? analysis.name : ""}</td>
                <td>{block ? block.name : ""}</td>
                <td>{block ? block.type : ""}</td>
                <td>{enterpriseType ? enterpriseType : ""}</td>
                <td>{label}</td>
                {monthMapList &&
                    domain.calendarYear.map((m) => {
                        return <td key={m}>{monthMapList[m]}</td>;
                    })}
            </tr>
        );
    }

    addSoilResults = (monthlyResults, a, b, index, soilResults, report, firstBlock, firstMonthLine) => {
        if (report) {
            if (report.results) monthlyResults.push(this.monthlyRow(firstBlock ? a : undefined, firstMonthLine ? b : undefined, index + "-" + report.type + "-reporting", soilResults.label, report.label + "-reporting", report.results));

            if (report.previousResults) monthlyResults.push(this.monthlyRow(undefined, undefined, index + "-" + report.type + "-previous", soilResults.label, report.label + "-previous", report.previousResults));
        }
    };

    render() {
        const { dataset, analyses, run1, run2, refData, isLoading } = this.props;
        const { blockActiveTab } = this.state;

        const AnalysisBarGraph = ({ data, run2 }) => {
            let results1 = [];
            switch (blockActiveTab) {
                case 0:
                    results1 = data.map((d, i) => {
                        return d.n1;
                    });
                    break;
                case 1:
                    results1 = data.map((d, i) => {
                        return d.nPerHa1;
                    });
                    break;
                case 2:
                    results1 = data.map((d, i) => {
                        return d.p1;
                    });
                    break;
                case 3:
                    results1 = data.map((d, i) => {
                        return d.pPerHa1;
                    });
                    break;
                default:
                    results1 = data.map((d, i) => {
                        return d.n1;
                    });
            }

            const nData = {
                labels: data.map((a, i) => `${a.name && a.name.length > 15 ? a.name.substring(0, 14) + "..." : a.name}`),
                datasets: [
                    {
                        fill: "origin",
                        lineTension: 0,
                        backgroundColor: "#ad343e",
                        borderColor: "#ad343e",
                        pointRadius: 0,
                        data: results1,
                    },
                ],
            };

            let results2 = [];
            switch (blockActiveTab) {
                case 0:
                    results2 = data.map((d, i) => {
                        return d.n2;
                    });
                    break;
                case 1:
                    results2 = data.map((d, i) => {
                        return d.nPerHa2;
                    });
                    break;
                case 2:
                    results2 = data.map((d, i) => {
                        return d.p2;
                    });
                    break;
                case 3:
                    results2 = data.map((d, i) => {
                        return d.pPerHa2;
                    });
                    break;
                default:
                    results2 = data.map((d, i) => {
                        return d.n2;
                    });
            }

            if (run2) {
                nData.datasets.push({
                    fill: "origin",
                    lineTension: 0,
                    backgroundColor: "#395998",
                    borderColor: "#395998",
                    pointRadius: 0,
                    data: results2,
                });
            }
            const options = {
                scales: {
                    x: { display: true, ticks: { autoSkip: false } },
                    y: { ticks: { beginAtZero: true }, display: true },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            };

            return <TileSubPanel title={blockTabOptions[blockActiveTab]}>{nData.labels.length > 0 && <Bar data={nData} height={30} options={options} />}</TileSubPanel>;
        };

        const ScatterGraph = ({ data }) => {
            let results = [];
            switch (blockActiveTab) {
                case 0:
                    results = data.map((d, i) => {
                        return { label: d.name, x: d.n1, y: d.n2 };
                    });
                    break;
                case 1:
                    results = data.map((d, i) => {
                        return { label: d.name, x: d.nPerHa1, y: d.nPerHa2 };
                    });
                    break;
                case 2:
                    results = data.map((d, i) => {
                        return { label: d.name, x: d.p1, y: d.p2 };
                    });
                    break;
                case 3:
                    results = data.map((d, i) => {
                        return { label: d.name, x: d.pPerHa1, y: d.pPerHa2 };
                    });
                    break;
                default:
                    results = data.map((d, i) => {
                        return { label: d.name, x: d.n1, y: d.n2 };
                    });
            }

            const nData = {
                labels: data.map((d) => d.name),
                datasets: [
                    {
                        fill: false,
                        backgroundColor: "rgba(75,192,192,0.4)",
                        pointBorderColor: "rgba(75,192,192,1)",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "rgba(75,192,192,1)",
                        pointHoverBorderColor: "rgba(220,220,220,1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: results,
                    },
                ],
            };

            let max = 0;
            if (blockActiveTab === 0) {
                const maxX = data.reduce((prev, current) => Math.max(prev, current.n1 || 0), 0);
                const maxY = data.reduce((prev, current) => Math.max(prev, current.n2 || 0), 0);
                max = Math.max(maxX, maxY);
            } else if (blockActiveTab === 1) {
                const maxX = data.reduce((prev, current) => Math.max(prev, current.nPerHa1 || 0), 0);
                const maxY = data.reduce((prev, current) => Math.max(prev, current.nPerHa2 || 0), 0);
                max = Math.max(maxX, maxY);
            } else if (blockActiveTab === 2) {
                const maxX = data.reduce((prev, current) => Math.max(prev, current.p1 || 0), 0);
                const maxY = data.reduce((prev, current) => Math.max(prev, current.p2 || 0), 0);
                max = Math.max(maxX, maxY);
            } else if (blockActiveTab === 3) {
                const maxX = data.reduce((prev, current) => Math.max(prev, current.pPerHa1 || 0), 0);
                const maxY = data.reduce((prev, current) => Math.max(prev, current.pPerHa2 || 0), 0);
                max = Math.max(maxX, maxY);
            }

            const options = {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => {
                                //return tooltipItem.label + ": (" + tooltipItem.xLabel + ", " + tooltipItem.yLabel + ")";
                                return tooltipItem.label + ": (" + tooltipItem.formattedValue + ")";
                            },
                        },
                    },
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: { ticks: { beginAtZero: false, min: 0, max: max } },
                    y: { ticks: { beginAtZero: false, min: 0, max: max } },
                },
            };
            return data.length > 0 ? <Scatter data={nData} options={options} /> : null;
        };

        const BudgetMessageRow = ({ analyses, analysis, message, firstRun }) => {
            const exists = analyses.find((a) => a.id === analysis.id);

            return (
                <tr className={firstRun ? "Table-tr--red is-selected" : "Table-tr--blue is-selected"}>
                    <td>
                        {exists && (
                            <Link to={`/app/farm/${analysis.farmId}`} className="FarmTitle-link">
                                {analysis.farmName}
                            </Link>
                        )}
                        {!exists && <span className="FarmTitle-name">{analysis.farmName}</span>}
                    </td>
                    <td>
                        {exists && (
                            <Link to={`/app/farm/${analysis.farmId}/analysis/${analysis.id}`} className="FarmTitle-link">
                                {analysis.name}
                            </Link>
                        )}
                        {!exists && <span className="FarmTitle-name">{analysis.name}</span>}
                    </td>
                    <td>{message.text}</td>
                    <td>{JSON.stringify(message.data)}</td>
                </tr>
            );
        };

        const ModelRunParameter = ({ name, effects, param1, param2, run2 }) => {
            return param1 || param2 ? (
                <tr>
                    <td>{name}</td>
                    <td>{effects}</td>
                    <td className="Table-td--red">{param1 ? param1 : "-"}</td>
                    {run2 && <td className="Table-td--blue">{param2 ? param2 : "-"}</td>}
                </tr>
            ) : null;
        };

        const GetFactorsAsString = (factors) => {
            return (
                factors &&
                Object.keys(factors)
                    .map((k) => {
                        return k + ":" + factors[k];
                    })
                    .join(", ")
            );
        };

        const ModelRunParameters = ({ run1, run2 }) => {
            const { urineParams: urineParams1 = {}, cropParams: cropParams1 = {}, pasture: pasture1 = {}, pastureOverride: pastureOverride1 = {} } = run1.modelParams || {};
            const { urineParams: urineParams2 = {}, cropParams: cropParams2 = {}, pasture: pasture2 = {}, pastureOverride: pastureOverride2 = {} } = (run2 && run2.modelParams) || {};

            const { grassYieldParameters: grassYieldParameters1 = {}, cloverYieldParameters: cloverYieldParameters1 = {} } = pasture1;
            const { grassYieldParameters: grassYieldParameters2 = {}, cloverYieldParameters: cloverYieldParameters2 = {} } = pasture2;

            const { sensitivityCriteria } = run1;

            return (
                <>
                    {sensitivityCriteria && <ModelRunParameter name="Sensitivity criteria" effects="Analysis" param1={sensitivityCriteria.attributes.map((a) => a.attributeType).join(" | ")} param2={urineParams2.pUrine} />}
                    <ModelRunParameter name="Urine proportion" effects="Urine model" param1={urineParams1.pUrine} param2={urineParams2.pUrine} run2={run2} />
                    <ModelRunParameter name="Urine proportion factor" effects="Urine model" param1={urineParams1.pUrineFactor} param2={urineParams2.pUrineFactor} run2={run2} />
                    <ModelRunParameter name="Urine load" effects="Urine model" param1={urineParams1.urineLoad} param2={urineParams2.urineLoad} run2={run2} />
                    <ModelRunParameter name="Base Uptake" effects="Urine model" param1={urineParams1.baseUptake} param2={urineParams2.baseUptake} run2={run2} />
                    <ModelRunParameter name="Volatilisation" effects="Urine patch" param1={urineParams1.volatilisation} param2={urineParams2.volatilisation} run2={run2} />
                    <ModelRunParameter name="Denitrification" effects="Urine patch" param1={urineParams1.denitrification} param2={urineParams2.denitrification} run2={run2} />
                    <ModelRunParameter name="Initial immobilisation" effects="Urine patch" param1={urineParams1.initialImmobilisation} param2={urineParams2.initialImmobilisation} run2={run2} />
                    <ModelRunParameter name="Monthly immobilisation rate" effects="Urine patch" param1={urineParams1.monthlyImmobilisationRate} param2={urineParams2.monthlyImmobilisationRate} run2={run2} />
                    <ModelRunParameter name="Initial fertiliser immobilisation" effects="Urine patch" param1={urineParams1.initialFertiliserImmobilisation} param2={urineParams2.initialFertiliserImmobilisation} run2={run2} />
                    <ModelRunParameter name="Remove dentrification" effects="Urine patch" param1={urineParams1.removeDenitrification ? "True" : undefined} param2={urineParams2.removeDenitrification ? "True" : undefined} run2={run2} />
                    <ModelRunParameter name="South island additional uptake" effects="Urine patch" param1={urineParams1.southIslandAdditionalUptake} param2={urineParams2.southIslandAdditionalUptake} run2={run2} />
                    <ModelRunParameter name="NPV1 factor" effects="Urine model" param1={urineParams1.npvFactors && urineParams1.npvFactors.nPv1Factor} param2={urineParams2.npvFactors && urineParams2.npvFactors.nPv1Factor} run2={run2} />
                    <ModelRunParameter name="NPV2 factor" effects="Urine model" param1={urineParams1.npvFactors && urineParams1.npvFactors.nPv2Factor} param2={urineParams2.npvFactors && urineParams2.npvFactors.nPv2Factor} run2={run2} />
                    <ModelRunParameter name="NPV3 factor" effects="Urine model" param1={urineParams1.npvFactors && urineParams1.npvFactors.nPv3Factor} param2={urineParams2.npvFactors && urineParams2.npvFactors.nPv3Factor} run2={run2} />
                    <ModelRunParameter name="Uptake factors" effects="Urine model" param1={GetFactorsAsString(urineParams1.uptakeFactors)} param2={GetFactorsAsString(urineParams2.uptakeFactors)} run2={run2} />
                    <ModelRunParameter name="Leach factors" effects="Urine model" param1={GetFactorsAsString(urineParams1.leachFactors)} param2={GetFactorsAsString(urineParams2.leachFactors)} run2={run2} />

                    <ModelRunParameter name="Background DM factor" effects="Crop model" param1={cropParams1.backgroundFactor} param2={cropParams2.backgroundFactor} run2={run2} />
                    <ModelRunParameter name="Inorganic proportion effluent" effects="Crop model" param1={cropParams1.inorganicProportion} param2={cropParams2.inorganicProportion} run2={run2} />
                    <ModelRunParameter name="Release rate roots" effects="Crop model" param1={cropParams1.releaseRateRoots} param2={cropParams2.releaseRateRoots} run2={run2} />
                    <ModelRunParameter name="Release rate stover" effects="Crop model" param1={cropParams1.releaseRateStover} param2={cropParams2.releaseRateStover} run2={run2} />
                    <ModelRunParameter name="DM percentage" effects="Crop model" param1={cropParams1.dmPercentage} param2={cropParams2.dmPercentage} run2={run2} />
                    <ModelRunParameter name="ME" effects="Crop model" param1={cropParams1.mePerKgDM} param2={cropParams2.mePerKgDM} run2={run2} />

                    <ModelRunParameter name="Root turnover factor" effects="Pasture yield" param1={pasture1.rootTurnoverFactor} param2={pasture2.rootTurnoverFactor} run2={run2} />
                    <ModelRunParameter name="Shoots factor" effects="Pasture yield" param1={pasture1.shootsFactor} param2={pasture2.shootsFactor} run2={run2} />

                    <ModelRunParameter name="Grass yield multiplier" effects="Pasture yield" param1={grassYieldParameters1.yieldMultiplier} param2={grassYieldParameters2.yieldMultiplier} run2={run2} />
                    <ModelRunParameter name="Grass minimum temperature" effects="Pasture yield" param1={grassYieldParameters1.minTemperature} param2={grassYieldParameters2.minTemperature} run2={run2} />
                    <ModelRunParameter name="Grass temperature adjustment" effects="Pasture yield" param1={grassYieldParameters1.temperatureAdjuster} param2={grassYieldParameters2.temperatureAdjuster} run2={run2} />
                    <ModelRunParameter name="Clover yield multiplier" effects="Pasture yield" param1={cloverYieldParameters1.yieldMultiplier} param2={cloverYieldParameters2.yieldMultiplier} run2={run2} />
                    <ModelRunParameter name="Clover minimum temperature" effects="Pasture yield" param1={cloverYieldParameters1.minTemperature} param2={cloverYieldParameters2.minTemperature} run2={run2} />
                    <ModelRunParameter name="Clover temperature adjustment" effects="Pasture yield" param1={cloverYieldParameters1.temperatureAdjuster} param2={cloverYieldParameters2.temperatureAdjuster} run2={run2} />

                    <ModelRunParameter name="Pasture" effects="Pasture yield" param1={pastureOverride1.pastureCategory} param2={pastureOverride2.pastureCategory} run2={run2} />
                    <ModelRunParameter name="Topography" effects="Pasture yield" param1={pastureOverride1.topography} param2={pastureOverride2.topography} run2={run2} />
                    <ModelRunParameter name="Clover levels" effects="Pasture yield" param1={pastureOverride1.cloverLevels} param2={pastureOverride2.cloverLevels} run2={run2} />
                    <ModelRunParameter name="Pasture utilisation" effects="Pasture yield" param1={pastureOverride1.pastureUtilisationPercentage} param2={pastureOverride2.pastureUtilisationPercentage} run2={run2} />
                    <ModelRunParameter name="Nitrogen concentration" effects="Pasture yield" param1={pastureOverride1.nitrogenConcentrationPercentage} param2={pastureOverride2.nitrogenConcentrationPercentage} run2={run2} />
                </>
            );
        };

        let analysisRows = [];
        let analysisMonthlyResults = [];
        let messageRows = [];
        let blockRows = [];
        let monthlyResults = [];
        let hasMeasuredN = false;
        let hasDrainage = false;
        let hasPastureDryMatterIntake = false;
        let hasPastureNIntake = false;
        let includePreviousYear = false;
        let soilResultsIndex = 0;

        if (run1 && run1.analyses && run1.analyses.length <= 1000) {
            /* eslint-disable no-unused-vars */
            for (const a1 of run1.analyses) {
                const a2 = run2 && run2.analyses && run2.analyses.find((a2) => a2.id === a1.id);

                analysisRows.push(this.analysisDataRow(a1, a2, run1.type === "ModelTest"));

                if (a1.messages && a1.messages.length > 0)
                    messageRows = messageRows.concat(
                        a1.messages.map((_msg, i) => {
                            return <BudgetMessageRow key={"budget-msg-row-" + a1.id + "-1" + i} analyses={run1.analyses} analysis={a1} message={_msg} firstRun={true} />;
                        })
                    );

                if (a2 !== undefined) {
                    if (a2.messages && a2.messages.length > 0)
                        messageRows = messageRows.concat(
                            a2.messages.map((_msg, i) => {
                                return <BudgetMessageRow key={"budget-msg-row-" + a2.id + "-2" + i} analyses={run1.analyses} analysis={a2} message={_msg} firstRun={false} />;
                            })
                        );
                }

                if (this.state.isAnalysisMonthlyResultsOpen && a1.animalResults) {
                    /* eslint-disable no-unused-vars */
                    for (const eId of Object.keys(a1.animalResults)) {
                        const enterpriseType = a1.enterprisesMap ? a1.enterprisesMap[eId] : undefined;

                        let firstE = true;
                        /* eslint-disable no-unused-vars */
                        for (const key of Object.keys(a1.animalResults[eId].monthlyResults)) {
                            const monthMapList = a1.animalResults[eId].monthlyResults[key];
                            analysisMonthlyResults.push(this.analysisMonthlyRow(firstE ? a1 : undefined, eId + "-" + key, enterpriseType, monthMapList));
                            firstE = false;
                        }

                        if (a1.animalResults[eId].intake) {
                            /* eslint-disable no-unused-vars */
                            for (const key of Object.keys(a1.animalResults[eId].intake)) {
                                const intakeList = a1.animalResults[eId].intake[key];

                                analysisMonthlyResults.push(this.intakeMonthlyRow(enterpriseType, eId + "-" + key, key, intakeList));
                            }
                        }

                        if (a1.animalResults[eId].stockResults) {
                            let index = 0;
                            /* eslint-disable no-unused-vars */
                            for (const stockResults of a1.animalResults[eId].stockResults) {
                                /* eslint-disable no-unused-vars */
                                for (const stockKey of Object.keys(stockResults.meRequirements)) {
                                    analysisMonthlyResults.push(this.stockResultsMonthlyRow(firstE ? a1 : undefined, enterpriseType, stockResults.stockClass, eId + "-" + index++ + "-" + stockKey, stockResults.meRequirements[stockKey]));
                                    firstE = false;
                                }
                            }
                        }
                    }
                }

                let firstBlock = true;
                /* eslint-disable no-unused-vars */
                for (const b1 of a1.blocks) {
                    const b2 = a2 && a2.blocks.find((b2) => b2.id === b1.id);

                    let firstMonthLine = true;

                    if (b1.enterpriseUrineReports) {
                        /* eslint-disable no-unused-vars */
                        for (const eId of Object.keys(b1.enterpriseUrineReports)) {
                            b1.totalDrainage = b1.enterpriseUrineReports && b1.enterpriseUrineReports[eId] && b1.enterpriseUrineReports[eId][drainageKey] ? Object.keys(b1.enterpriseUrineReports[eId][drainageKey].results).reduce((total, month) => (total += b1.enterpriseUrineReports[eId][drainageKey].results[month]), 0) : 0;
                            if (b2) b2.totalDrainage = b2.enterpriseUrineReports && b2.enterpriseUrineReports[eId] && b2.enterpriseUrineReports[eId][drainageKey] ? Object.keys(b2.enterpriseUrineReports[eId][drainageKey].results).reduce((total, month) => (total += b2.enterpriseUrineReports[eId][drainageKey].results[month]), 0) : 0;

                            if (this.state.isBlockMonthlyResultsOpen) {
                                /* eslint-disable no-unused-vars */
                                for (const key of Object.keys(b1.enterpriseUrineReports[eId])) {
                                    const monthMapList = b1.enterpriseUrineReports[eId][key];

                                    monthlyResults.push(this.monthlyRow(firstBlock ? a1 : undefined, firstMonthLine ? b1 : undefined, b1.id + "-" + eId + "-" + key, a1.enterprisesMap ? a1.enterprisesMap[eId] : undefined, monthMapList.label, monthMapList.results));
                                    firstMonthLine = false;
                                    firstBlock = false;
                                }
                            }
                        }
                    }

                    if (b1.soilResults && b1.soilResults.length > 0) {
                        for (const soilResult of b1.soilResults) {
                            for (const report of soilResult.results) {
                                this.addSoilResults(monthlyResults, a1, b1, soilResultsIndex++, soilResult, report, firstMonthLine, firstMonthLine);
                                firstMonthLine = false;
                            }
                        }
                    }

                    if (b1.nPools && b1.nPools.Plant && b1.nPools.Plant.previousResults) includePreviousYear = true;

                    if (b1.pastureResults) {
                        b1.totalPastureDMIntake = b1.pastureResults.pastureIntake;
                        b1.totalPastureNIntake = b1.pastureResults.nitrogenIntake;

                        if (b1.pastureResults.enterprisePastureReports) {
                            /* eslint-disable no-unused-vars */
                            for (const eId of Object.keys(b1.pastureResults.enterprisePastureReports)) {
                                if (this.state.isBlockMonthlyResultsOpen) {
                                    /* eslint-disable no-unused-vars */
                                    for (const key of Object.keys(b1.pastureResults.enterprisePastureReports[eId])) {
                                        const monthMapList = b1.pastureResults.enterprisePastureReports[eId][key];

                                        monthlyResults.push(this.monthlyRow(firstBlock ? a1 : undefined, firstMonthLine ? b1 : undefined, b1.id + "-" + eId + "-" + key, a1.enterprisesMap ? a1.enterprisesMap[eId] : undefined, monthMapList.label, monthMapList.results));
                                        firstMonthLine = false;
                                        firstBlock = false;
                                    }
                                }
                            }
                        }
                    }
                    if (this.state.isBlockResultsOpen) {
                        hasDrainage = !hasDrainage ? b1.totalDrainage && b1.totalDrainage > 0 : hasDrainage;
                        hasMeasuredN = !hasMeasuredN ? b1.measuredNPerHa && b1.measuredNPerHa > 0 : hasMeasuredN;
                        hasPastureDryMatterIntake = !hasPastureDryMatterIntake ? b1.totalPastureDMIntake && b1.totalPastureDMIntake > 0 : hasPastureDryMatterIntake;
                        hasPastureNIntake = !hasPastureNIntake ? b1.totalPastureNIntake && b1.totalPastureNIntake > 0 : hasPastureNIntake;
                        blockRows.push(this.blockDataRow(firstBlock ? a1 : undefined, b1, b2, run1.type === "ModelTest"));
                    }

                    firstBlock = false;
                }
            }
        }
        analysisRows = analysisRows.sort((a, b) => (b.farmName < a.farmName ? 1 : b.farmName === a.farmName ? (b.name < a.name ? 1 : -1) : -1));

        const run1HasParameters = run1 && (run1.modelParams || run1.sensitivityCriteria);
        const run2HasParameters = run2 && (run2.modelParams || run2.sensitivityCriteria);
        const showComparison = run1 && run1.modelType === "Full" && run1.type !== "Sensitivity" && run2 && run2.modelType === "Full" && run2.type !== "Sensitivity";

        return (
            <>
                <h3 className="u-mt-sm">Model run parameters</h3>
                {isLoading && (
                    <div className="Tile-body-message">
                        <Spinner dark />
                        <p className="lead u-text-lg u-mt-md">Loading run...</p>
                    </div>
                )}
                {!isLoading && (
                    <>
                        {run1HasParameters || run2HasParameters ? (
                            <div className="Table u-mt-md">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Model run parameter</th>
                                            <th>Where parameter is used</th>
                                            {run1 && <th>{run1.description}</th>}
                                            {run2 && <th>{run2.description}</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ModelRunParameters run1={run1} run2={run2} />
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <Alert className="u-mt-sm u-mb-0" type="success" text="The selected model runs do not have any model parameters entered." />
                        )}
                        {run1 && run1.minimumResults && run1.minimumResults.length > 0 && (
                            <div className="u-mb-0 u-bg-white u-pb-sm">
                                <TileActionsBar>
                                    <ul className="ActionsBar-links">
                                        <>
                                            <li>
                                                <ActionLink onClick={() => this.downloadMinimumAnalysisData(dataset.name, run1, run2)} id="analysis-csv" className="IconLink--download">
                                                    Create Analysis CSV file
                                                </ActionLink>
                                            </li>
                                            <li>
                                                <ActionLink onClick={() => this.downloadMinimumBlockData(dataset.name, run1)} id="blocks-csv" className="IconLink--download">
                                                    Create Block CSV file
                                                </ActionLink>
                                            </li>
                                        </>
                                    </ul>
                                </TileActionsBar>
                            </div>
                        )}
                        {analysisRows.length > 0 && (
                            <>
                                {run1.modelType === "Full" && (
                                    <Panel title="Analysis results" open={false} onChange={(collapsed) => this.setState({ isAnalysisResultsOpen: !collapsed })} className="u-mt-lg" skyBlue>
                                        <PanelBody>
                                            <TileActionsBar>
                                                <ul className="ActionsBar-links">
                                                    {run1 && run1.analyses && (
                                                        <>
                                                            <li>
                                                                <ActionLink onClick={() => this.downloadAnalysisData(dataset.name, run1, run2)} id="analysis-csv" className="IconLink--download">
                                                                    Create Analysis CSV file
                                                                </ActionLink>
                                                            </li>
                                                            {run1.type !== "Sensitivity" && (
                                                                <li>
                                                                    <ActionLink onClick={() => this.downloadAnalysisGHGData(dataset.name, run1, run2)} id="analysis-ghg-csv" className="IconLink--download">
                                                                        Create Analysis GHG CSV file
                                                                    </ActionLink>
                                                                </li>
                                                            )}
                                                            {run1.type !== "Sensitivity" && (
                                                                <li>
                                                                    <ActionLink onClick={() => this.downloadAnalysisAndBlockData(dataset.name, run1)} id="analysis-csv" className="IconLink--download">
                                                                        Create Analysis and block CSV file
                                                                    </ActionLink>
                                                                </li>
                                                            )}
                                                        </>
                                                    )}
                                                    {(run1.type === "ModelTest" || run2) && (
                                                        <li>
                                                            <ActionLink onClick={() => this.setState({ showAnalysisBudgetDifferences: !this.state.showAnalysisBudgetDifferences })} id="show-analysis-comparison" className={this.state.showAnalysisBudgetDifferences ? "IconLink--arrow-up" : "IconLink--arrow-down"}>
                                                                {this.state.showAnalysisBudgetDifferences ? "Hide differences" : "Show differences"}
                                                            </ActionLink>
                                                        </li>
                                                    )}
                                                </ul>
                                            </TileActionsBar>
                                            {messageRows && messageRows.length > 0 && (
                                                <div className="Table u-mt-md">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Farm name</th>
                                                                <th>Analysis Name</th>
                                                                <th>Message</th>
                                                                <th>Data</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{messageRows.map((m) => m)}</tbody>
                                                    </table>
                                                </div>
                                            )}
                                            <div className="Table u-mt-md">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Farm name</th>
                                                            <th>Analysis name</th>
                                                            {this.state.showAnalysisBudgetDifferences && showComparison && <th>Compare</th>}
                                                            <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                KG N/ha
                                                            </th>
                                                            <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                KG N
                                                            </th>
                                                            <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                KG P/ha
                                                            </th>
                                                            <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                KG P
                                                            </th>
                                                            <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                KG CO2/ha
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {analysisRows.map((r) => {
                                                            return this.analysisNutrientRow(r, analyses, showComparison);
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {run1 && (
                                                <div className="u-border u-p-10 u-pb-0 u-mt-md">
                                                    <ul className="SubMenu u-mb-md">
                                                        {blockTabOptions.map((t, i) => (
                                                            <li key={t} className={`SubMenu-item ${i === blockActiveTab ? "is-active" : ""}`}>
                                                                <ActionLink onClick={() => this.setState({ blockActiveTab: i })}>
                                                                    <span>{t}</span>
                                                                </ActionLink>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <AnalysisBarGraph data={analysisRows} run2={run2} />
                                                    {run2 && (
                                                        <TileSubPanel title={blockTabOptions[blockActiveTab]}>
                                                            <ScatterGraph data={analysisRows} />
                                                        </TileSubPanel>
                                                    )}
                                                </div>
                                            )}
                                        </PanelBody>
                                    </Panel>
                                )}
                                {!run2 && run1.modelType !== "Lysimeter" && (
                                    <Panel title="Analysis monthly results" open={false} onChange={(collapsed) => this.setState({ isAnalysisMonthlyResultsOpen: !collapsed })} className="u-mt-lg" skyBlue>
                                        <PanelBody>
                                            {analysisMonthlyResults.length === 0 && <Alert className="u-mb-0" type="warning" text="This is not a detailed model run and so has no monthly results" />}
                                            {analysisMonthlyResults.length > 0 && (
                                                <>
                                                    <TileActionsBar>
                                                        <ul className="ActionsBar-links">
                                                            {run1 && run1.analyses && (
                                                                <li>
                                                                    <ActionLink onClick={() => this.downloadMonthlyAnalysisData(dataset.name, run1)} id="analysis-monthly-csv" className="IconLink--download">
                                                                        Create CSV file
                                                                    </ActionLink>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </TileActionsBar>
                                                    <div className="Table u-mt-md">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Farm name</th>
                                                                    <th>Analysis name</th>
                                                                    <th>Enterprise type</th>
                                                                    <th>Stock class</th>
                                                                    <th>Attribute</th>
                                                                    {domain.calendarYear.map((m) => (
                                                                        <th key={m}>{m.substring(0, 3)}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>{analysisMonthlyResults.map((r) => r)}</tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            )}
                                        </PanelBody>
                                    </Panel>
                                )}
                                {run1.modelType !== "AnimalME" && (
                                    <>
                                        <Panel title="Block results" open={false} onChange={(collapsed) => this.setState({ isBlockResultsOpen: !collapsed })} className="u-mt-lg" skyBlue>
                                            <PanelBody>
                                                <TileActionsBar>
                                                    <ul className="ActionsBar-links">
                                                        {run1 && run1.analyses && (
                                                            <li>
                                                                <ActionLink onClick={() => this.downloadBlockData(dataset.name, run1, run2, hasMeasuredN, hasDrainage, hasPastureDryMatterIntake, hasPastureNIntake)} id="blocks-csv" className="IconLink--download">
                                                                    Create CSV file
                                                                </ActionLink>
                                                            </li>
                                                        )}
                                                        {false && run1 && run1.analyses && (
                                                            <li>
                                                                <ActionLink onClick={() => this.downloadAveragedBlockData(dataset.name, run1)} id="avg-blocks-csv" className="IconLink--download">
                                                                    Create Average CSV file
                                                                </ActionLink>
                                                            </li>
                                                        )}
                                                        {(run1.type === "ModelTest" || run2) && (
                                                            <li>
                                                                <ActionLink onClick={() => this.setState({ showBlockBudgetDifferences: !this.state.showBlockBudgetDifferences })} id="show-analysis-comparison" className={this.state.showBlockBudgetDifferences ? "IconLink--arrow-up" : "IconLink--arrow-down"}>
                                                                    {this.state.showBlockBudgetDifferences ? "Hide budget differences" : "Show budget differences"}
                                                                </ActionLink>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </TileActionsBar>
                                                <div className="Table u-mt-md">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Farm name</th>
                                                                <th>Analysis name</th>
                                                                <th>Block name</th>
                                                                <th>Block type</th>
                                                                <th>Pasture/crop</th>
                                                                <th>Animals</th>
                                                                {this.state.showBlockBudgetDifferences && <th>Compare</th>}
                                                                {hasMeasuredN ? <th className="u-textCenter">Measured N/ha</th> : undefined}
                                                                {run1.modelType === "Full" && (
                                                                    <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                        KG N/Ha
                                                                    </th>
                                                                )}
                                                                <th className="u-textCenter" colSpan={run2 ? 2 : 1}>
                                                                    KG N
                                                                </th>
                                                                {run1.modelType === "Full" && (
                                                                    <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                        KG P/Ha
                                                                    </th>
                                                                )}
                                                                {run1.modelType === "Full" && (
                                                                    <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                        KG P
                                                                    </th>
                                                                )}
                                                                {hasDrainage ? (
                                                                    <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                        Drainage
                                                                    </th>
                                                                ) : undefined}
                                                                {hasPastureDryMatterIntake ? (
                                                                    <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                        Pasture DM Intake
                                                                    </th>
                                                                ) : undefined}
                                                                {hasPastureNIntake ? (
                                                                    <th className="u-textCenter" colSpan={showComparison ? 2 : 1}>
                                                                        Pasture N Intake
                                                                    </th>
                                                                ) : undefined}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {blockRows.map((r, i) => {
                                                                return this.blockNutrientRow(i, analyses, r, dataset.id, run1.modelType, refData, showComparison, hasMeasuredN, hasDrainage, hasPastureDryMatterIntake, hasPastureNIntake);
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {run1 && run2 && (
                                                    <div className="u-border u-p-10 u-pb-0 u-mt-md">
                                                        {run1.modelType === "Full" && (
                                                            <ul className="SubMenu u-mt-md">
                                                                {blockTabOptions.map((t, i) => (
                                                                    <li key={t} className={`SubMenu-item ${i === blockActiveTab ? "is-active" : ""}`}>
                                                                        <ActionLink onClick={() => this.setState({ blockActiveTab: i })}>
                                                                            <span>{t}</span>
                                                                        </ActionLink>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                        <TileSubPanel title={blockTabOptions[blockActiveTab]}>
                                                            <ScatterGraph data={blockRows} />
                                                        </TileSubPanel>
                                                    </div>
                                                )}
                                            </PanelBody>
                                        </Panel>
                                        {!run2 && (run1.detailedResults || run1.modelType === "Lysimeter") && (
                                            <Panel title="Block monthly results" open={false} onChange={(collapsed) => this.setState({ isBlockMonthlyResultsOpen: !collapsed })} className="u-mt-lg" skyBlue>
                                                <PanelBody>
                                                    <TileActionsBar>
                                                        <ul className="ActionsBar-links">
                                                            <li>
                                                                <ActionLink onClick={() => this.downloadMonthlyBlockData(dataset.name, run1, includePreviousYear)} id="block-npools-csv" className="IconLink--download">
                                                                    Create block CSV file
                                                                </ActionLink>
                                                            </li>
                                                        </ul>
                                                    </TileActionsBar>
                                                    {monthlyResults.length > 0 && (
                                                        <div className="Table u-mt-md">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Farm name</th>
                                                                        <th>Analysis name</th>
                                                                        <th>Block name</th>
                                                                        <th>Block type</th>
                                                                        <th>Type</th>
                                                                        <th>Attribute</th>
                                                                        {domain.calendarYear.map((m) => (
                                                                            <th key={m}>{m.substring(0, 3)}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>{monthlyResults.map((r) => r)}</tbody>
                                                            </table>
                                                        </div>
                                                    )}
                                                </PanelBody>
                                            </Panel>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
}

export default ModellingRun;
