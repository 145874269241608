import React from "react";
import { compose, withAppContext } from "containers/HOC";
import { Bar } from "react-chartjs-2";
import * as utils from "common/utils";

const PLossByBlockTypesChart = ({ soils, colours }) => {
    const [showPerHa, setShowPerHa] = React.useState(false);
    const chartData = getChartData(soils, showPerHa, colours);
    const chartOptions = getChartOptions(soils);
    const title = `Phosphorus loss by soil order ${showPerHa ? "(kg/ha)" : "(kg)"}`;
    return (
        <div className="HeroPanel HeroPanel--ploss u-mt-md">
            <div className="u-flex u-flexJustifyBetween">
                <div className="HeroPanel-heading">{title}</div>
                <div>
                    <div className="Field u-mt-xs">
                        <div className="Radio_group Radio_group--inline">
                            <label className="Radio u-mr-md">
                                <input checked={!showPerHa} onChange={() => setShowPerHa(false)} className="Radio-input" type="radio" />
                                <span className="Radio-label">Show total kg</span>
                            </label>
                            <label className="Radio">
                                <input checked={showPerHa} onChange={() => setShowPerHa(true)} className="Radio-input" type="radio" />
                                <span className="Radio-label">Show kg per ha</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <Bar data={chartData} options={chartOptions} height={80} />
        </div>
    );
};

const getChartData = (soils, showPerHa, colours) => {
    return {
        datasets: [
            {
                data: soils.map((b) => utils.round((showPerHa ? b.pLossPerHa : b.pLossTotal) || 0, 1)),
                backgroundColor: soils.map((_, i) => colours[i]),
            },
        ],
        labels: soils.map((soil) => soil.soilOrder),
    };
};

const getChartOptions = (soils) => {
    return {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        if (soils.length <= tooltipItem.dataIndex) return tooltipItem.label;
                        const soil = soils[tooltipItem.dataIndex];
                        return `${utils.round(soil.pLossPerHa || 0, 1)} kg/ha | ${utils.round(soil.pLossTotal || 0, 1).toLocaleString()} kg`;
                    },
                },
            },
        },
        scales: {
            x: { display: true },
            y: {
                ticks: {
                    beginAtZero: true,
                    display: true,
                    callback: function (label, index, labels) {
                        return Number(label).toLocaleString();
                    },
                },
            },
        },
    };
};

export default compose(withAppContext)(PLossByBlockTypesChart);
