import React from "react";
import { Field } from "react-final-form";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";

class TargetFarmList extends React.Component {
    render() {
        const { form, farms, refData } = this.props;

        const fieldState = form.getFieldState("destinationFarmId");
        const error = fieldState && fieldState.touched && fieldState.error;

        return (
            <React.Fragment>
                <p className="u-mt-0 u-mb-md">
                    <span className="h3">{farms.length}</span> farm(s) found
                </p>
                {farms.length === 0 && <div className="Field-error u-block u-mt-0">Required</div>}
                {farms.length > 0 && (
                    <SortableTable data={farms} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }}>
                        {({ data, sortCriteria, handleSort }) => {
                            return (
                                <React.Fragment>
                                    <table>
                                        <thead>
                                            <tr>
                                                <SortableTableHeader label="Name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Address" fieldName="address" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Region" fieldName="region" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Subscription" fieldName="expirationDate" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                <SortableTableHeader label="Created" fieldName="createdOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                <SortableTableHeader label="Modified" fieldName="modifiedOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((farm) => {
                                                const region = farm.region ? utils.valueToText(refData.regions, farm.region) : "-";
                                                return (
                                                    <tr key={farm.id} className="hover">
                                                        <td>
                                                            <Link to={`/app/farm/${farm.id}`} target="_blank">
                                                                {farm.name}
                                                            </Link>
                                                        </td>
                                                        <td>{farm.address}</td>
                                                        <td>{region}</td>
                                                        <td className={farm.expired ? "u-textError" : ""}>{farm.expired ? "Expired" : utils.dateLong(farm.expirationDate, "-")}</td>
                                                        <td>{utils.dateLong(farm.createdOn, "-")}</td>
                                                        <td>{utils.dateLong(farm.modifiedOn, "-")}</td>
                                                        <td>{!farm.disabled && <Field name="destinationFarmId" type="radio" component="input" value={farm.id} />}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    {error && error.length > 0 && <div className="Field-error u-block u-mt-0">{error}</div>}
                                </React.Fragment>
                            );
                        }}
                    </SortableTable>
                )}
            </React.Fragment>
        );
    }
}

export default TargetFarmList;
