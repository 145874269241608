import React from "react";
import { Panel, PanelBody } from "components/Panel";
import PublicationList from "containers/Dashboard/PublicationList";

const MyPublications = (props) => {
    const { myPublications = [] } = props;

    return (
        <Panel title="My publications">
            <PanelBody>
                <PublicationList myPublications={myPublications} />
            </PanelBody>
        </Panel>
    );
};

export default MyPublications;
