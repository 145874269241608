import React from "react";
import { compose, withAppContext } from "containers/HOC";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import Alert from "components/Alert";
import SelectPack from "components/SelectPack2";
import InputPack from "components/InputPack";
import CheckboxPack from "components/CheckboxPack";

const getSelectedMonths = (applications) => {
    return applications.map((a) => a.month);
};

const getMonthOptions = (applications, months, index) => {
    const selectedMonths = getSelectedMonths(applications);
    return months.filter((month) => selectedMonths.some((selectedMonth, i) => i !== index && selectedMonth === month.value) === false);
};

const addApplication = (form, applications, months) => (e) => {
    const selectedMonths = getSelectedMonths(applications);
    const lastSelectedMonth = selectedMonths[selectedMonths.length - 1];
    let lastSelectedMonthIndex = months.reduce((result, month, i) => {
        if (month.value === lastSelectedMonth && i < 11) result = i;
        return result;
    }, -1);

    const unselectedMonths = months.filter((month) => selectedMonths.some((m) => m === month.value) === false);
    const nextIndex = months.findIndex((month, i) => unselectedMonths.some((m) => m.value === month.value) && i > lastSelectedMonthIndex);

    form.mutators.push("applications", { month: months[nextIndex].value });
};

const removeApplication = (form, values, index) => {
    const results = values.applications.reduce((acc, cur, i) => {
        if (i !== index) acc.push(cur);
        return acc;
    }, []);
    form.change("applications", results);
};

const validateApplications = (applications) => {
    const errors = [];

    applications &&
        applications.forEach((application) => {
            const error = {};

            error.rotationLength = FormUtils.validators.required(application.rotationLength);
            error.rotationLength = error.rotationLength || FormUtils.validators.range(1, 9999)(application.rotationLength);

            errors.push(error);
        });

    return errors;
};

const FarmLevelApplications = (props) => {
    const { form, viewModel, className, refData, values } = props;
    const months = refData.months || [];

    const addApplicationLink = viewModel.applications.length < 3 && (
        <span id="add-application-link" className="u-link IconLink--arrow-plus" onClick={addApplication(form, viewModel.applications, months)}>
            Add application
        </span>
    );

    return (
        <Panel title="DCD Applications - by animal enterprises" actions={addApplicationLink} className={className} midBlue>
            <Alert type="info" text="DCD is applied to paddocks within 7 days of grazing up to rotation length. Rotation length is the smaller of (a) the number of days before the paddock is regrazed or (b) number of days to next DCD application. DCD can be applied up to three times per year, typically - twice per year in April/May and July/August or March and April/May in Southland when grazing off in winter occurs." />
            <PanelBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th>Month</th>
                                <th className="th--shrink">Rotation length (days)</th>
                                <th className="th--shrink">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <FieldArray name="applications" validate={validateApplications}>
                                {({ fields }) => {
                                    return fields.map((field, i) => {
                                        const monthOptions = getMonthOptions(fields.value, months, i);
                                        return (
                                            <tr key={field}>
                                                <td>
                                                    <Field name={`${field}.month`} options={monthOptions} component={SelectPack} />
                                                </td>
                                                <td>
                                                    <Field name={`${field}.rotationLength`} type="text" label="" placeholder="0" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur required />
                                                </td>
                                                <td>{i > 0 && <span id={`delete-application-${i}`} className="a u-link IconLink--trash" onClick={() => removeApplication(form, values, i)}></span>}</td>
                                            </tr>
                                        );
                                    });
                                }}
                            </FieldArray>
                        </tbody>
                    </table>
                </div>
                <Grid>
                    <GridCell>
                        <Field name="nFertiliserAppliedWithinSevenDays" type="Checkbox" component={CheckboxPack} label="N fertiliser applied in the same month as DCD is applied within 7 days of the DCD application" />
                    </GridCell>
                    {viewModel.longTermFactor !== 100 && (
                        <GridCell className="u-width1of5">
                            <Field name="longTermFactor" type="text" label="DCD long term factor" uom="%" placeholder="0" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                        </GridCell>
                    )}
                </Grid>
            </PanelBody>
        </Panel>
    );
};
export default compose(withAppContext)(FarmLevelApplications);
