import * as domain from "common/domain";
import * as icons from "common/icons";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileBodyMessage from "components/TileBodyMessage";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import TabMessages from "containers/BudgetHome/TabMessages";
import ActionLink from "components/ActionLink";
import BlocksActions from "./BlocksActions";
import BlocksTabViews from "./BlocksTabViews";
import { useBlockModal } from "./Modals/BlockModal";
import { useEditFarmAreaModal } from "./Modals/EditFarmAreaModal";
import ImportMapLink from "containers/BudgetHome/Blocks/Modals/ImportMapLink";

export default function Blocks({ farm, analysis }) {
    const [openBlockModal, blockModal] = useBlockModal(farm, analysis);
    const [openEditFarmAreaModal, editFarmAreaModal] = useEditFarmAreaModal(analysis);

    const _hasBlocks = analysis.blocks && analysis.blocks.filter((b) => b.type !== domain.BlockType.FodderCrop).length > 0;

    return (
        <>
            <Tile>
                <PageTabs farm={farm} analysis={analysis} tab={TAB.BLOCKS} />
                {(_hasBlocks || analysis.isDrawn) && (
                    <TileBody>
                        <TabMessages farm={farm} analysis={analysis} tab={TAB.BLOCKS} />
                        <BlocksActions farm={farm} analysis={analysis} />
                        <BlocksTabViews farm={farm} analysis={analysis} />
                        {_hasBlocks && !analysis.hasCompletedBlocks && (
                            <div className="ButtonBar">
                                <div className="ButtonBar-right">
                                    <ActionLink onClick={openEditFarmAreaModal} className="Button Button--lg">
                                        I have completed all my blocks
                                    </ActionLink>
                                </div>
                            </div>
                        )}
                    </TileBody>
                )}
                {!_hasBlocks && !analysis.isDrawn && (
                    <TileBody>
                        <BlocksActions farm={farm} analysis={analysis} />
                        <TileBodyMessage>
                            <img src={icons.add} className="u-p-5" alt="Plus" />
                            <p className="lead">Let's get started...</p>
                            <ImportMapLink farm={farm} analysis={analysis} className="IconLink--polygon Button Button--primary Button--lg u-mt-md" />
                            <ActionLink onClick={openBlockModal} className="IconLink--blocks Button Button--secondary Button--lg u-mt-md">
                                Create first block
                            </ActionLink>
                        </TileBodyMessage>
                    </TileBody>
                )}
            </Tile>
            {blockModal}
            {editFarmAreaModal}
        </>
    );
}
