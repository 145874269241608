import * as utils from "common/utils";
import { isSoilModified } from "containers/Soils/_utils";
import { useRefData } from "common/hooks";

export default function SoilDetails({ farm, analysis, block }) {
    const refData = useRefData();

    const soils = analysis.useFarmSoils ? farm.soils : analysis.soils;
    const _blockSoils =
        analysis.farmSoilBlocks &&
        analysis.farmSoilBlocks
            .filter((fsb) => fsb.blockId === block.id)
            .map((fsb) => {
                return {
                    id: fsb.id,
                    soil: soils.find((s) => s.id === fsb.soilId),
                    percentage: fsb.percentageOfBlock,
                    defaultASC: fsb.defaultASC,
                    defaultTBKReserveK: fsb.defaultTBKReserveK
                };
            })
            .filter((s) => s.soil);

    return (
        <div className="FarmTable-supplementary">
            <div className="Table" style={{ backgroundColor: "#fff" }}>
                <table>
                    <thead>
                        <tr>
                            <th data-width="10">S-Map ref/name</th>
                            <th data-width="10">Order</th>
                            <th data-width="10">Group</th>
                            <th data-width="10">Drainage</th>
                            <th data-width="5">Max rooting depth</th>
                            <th data-width="5">Impeded layer depth</th>
                            <th data-width="20">Description</th>
                            <th data-width="5">ASC</th>
                            <th data-width="5">TBK reserve K</th>
                            <th data-width="5">% of block</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_blockSoils &&
                            _blockSoils.map((o, i) => {
                                if (!o.soil) return null;
                                const _soilProfile = utils.getSoilProfile(o.soil);
                                const profileDrainageClass = o.soil.profileDrainageClass || _soilProfile.profileDrainageClass;
                                return (
                                    <tr key={o.id} id={o.soil.id + "_soil" + i} name={block.id} className="Table-tr">
                                        <td className="td--alignTop">
                                            <div className="u-flexSplit">
                                                <span>{o.soil.sibling ? o.soil.sibling.smapReference : o.soil.name}</span>
                                                {isSoilModified(o.soil) && (
                                                    <span className="IconLink--soil u-textWarning u-ml-md" title="Modified soil properties">
                                                        Modified
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                        <td className="td--alignTop">{o.soil.soilOrder === "Undefined" ? "-" : o.soil.soilOrder}</td>
                                        <td className="td--alignTop">{utils.valueToText(refData.soilGroups, o.soil.soilGroup)}</td>
                                        <td className="td--alignTop">{profileDrainageClass ? utils.valueToText(refData.drainageClasses, profileDrainageClass) : "-"}</td>
                                        <td className="td--alignTop">{o.soil.bottomRootingDepth}</td>
                                        <td className="td--alignTop">{o.soil.impededLayerDepth}</td>
                                        <td className="td--alignTop">{o.soil.sibling ? o.soil.sibling.description : "-"}</td>
                                        <td className="td--alignTop">{o.defaultASC || "-"}</td>
                                        <td className="td--alignTop">{o.defaultTBKReserveK || "-"}</td>
                                        <td className="td--alignTop">{o.percentage}%</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
