import Spinner from "components/Spinner/Spinner";

export default function ResultsLoading({ id, isLoading, children }) {
    return (
        <>
            {isLoading
                ? <div id={id} className="results-widget__item">
                    <Spinner dark results />
                </div>
                : children
            }
        </>
    )
}
