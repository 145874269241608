import { useState } from "react";
import { getAnalysisDiagnostics } from "../_actions";
import { useDispatch } from "react-redux";
import InputPack from "components/InputPack";
import { Grid, GridCell } from "components/Grid";

export default function AnalysisDiagnostics() {
    const [results, setResults] = useState([]);
    const [analysisId, setAnalysisId] = useState();
    const dispatch = useDispatch();

    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    const validAnalysisId = analysisId && regexExp.test(analysisId);

    async function runDiagnostics() {
        if (validAnalysisId) {
            const newResults = await dispatch(getAnalysisDiagnostics(analysisId))
                .then((response) => response)
                .catch((error) => setResults([`Error: ${error}`]));
            setResults(newResults);
        }
    }

    return (
        <div>
            <Grid>
                <GridCell className="u-md-width1of2 u-lg-width1of3 u-pb-md">
                    <InputPack label="Analysis ID" type="text" value={analysisId} meta={{ nrf: true }} onChange={(e) => setAnalysisId(e.target.value)} />
                </GridCell>
            </Grid>

            <input type="button" onClick={() => runDiagnostics()} value="submit" className="Button  Button--primary" disabled={!validAnalysisId} />

            <div className="u-pt-md">
                {results &&
                    Array.isArray(results) &&
                    results.map((r) => (
                        <div key={r} style={{ color: r && r.startsWith("PASS") ? "black" : r && r.startsWith("##") ? "blue" : "Red" }}>
                            {r}
                        </div>
                    ))}
            </div>
        </div>
    );
}
