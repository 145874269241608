import { NutrientBudgetDescriptions, NutrientBudgetMovementGraph, NutrientBudgetMovementTotals } from "components/NutrientBudget";

export default function NutrientsExtraSummary({ before, after }) {
    const nutrientList = [
        { value: "nitrogen", text: "Nitrogen", display: "N" },
        { value: "phosphorus", text: "Phosphorus", display: "P" },
    ];
    return nutrientList.map((n, i) => {
        return (
            <div key={"nutrient-budget-" + i} className="ReportSection">
                <NutrientBudgetMovementTotals before={before} after={after} nutrient={n} />
                <NutrientBudgetDescriptions before={before} after={after} nutrient={n} />
                <NutrientBudgetMovementGraph before={before} after={after} nutrient={n} />
            </div>
        )
    });
}
