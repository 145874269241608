import Page from "components/Page";
import PageBody from "components/PageBody";
import FarmHomePageHead from "./FarmHomePageHead";
import { Panel, PanelBody } from "components/Panel";
import AnalysesPanel from "./AnalysesPanel";

export default function FarmHome({ farm }) {
    const referrer = `/app/farm/${farm.id}`;

    const breadcrumb = [{ name: farm.displayName, to: referrer }, { name: "Analyses" }];

    return (
        <Page>
            <FarmHomePageHead breadcrumb={breadcrumb} />
            <PageBody>
                <Panel title="Analyses" iconClassName="IconLink--compare" referrer={referrer}>
                    <PanelBody>
                        <AnalysesPanel farm={farm} />
                    </PanelBody>
                </Panel>
            </PageBody>
        </Page>
    )
}
