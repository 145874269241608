import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as icons from "common/icons";
import { requiresOutdoorPigLiquidApplications, requiresOutdoorPigSolidApplications, requiresOutdoorPigStrawCompostApplications } from "common/viewModels";

export const structureEffluent = (structure) => {
    if (!structure) return [false, false];
    let hasSolids = false;
    let hasLiquids = false;
    const { bunker = {}, feedingApron = {}, pad = {} } = structure;
    const liquidCleaning = ["Flushing", "FlushingSeparated", "Scraping", "ScrapingSeparated"];
    const solidCleaning = ["ScrapingSeparated", "ScrapingStored", "FlushingSeparated"];

    if (structure.type === "FeedingPad") {
        hasSolids = ["FlushingSeparated", "Scraping", "ScrapingSeparated"].includes(structure.cleaningMethod);
    } else if (structure.type === "StandoffPad") {
        hasSolids = true;
    } else if (structure.type === "UncoveredWinteringPad") {
        //Solids
        hasSolids = true;

        //Liquid effluent
        hasLiquids = liquidCleaning.includes(feedingApron.cleaningMethod);
        hasLiquids |= pad.lined;
    } else if (structure.type === "CoveredWinteringPad") {
        //Solids
        hasSolids = solidCleaning.includes(bunker.cleaningMethod);
        hasSolids |= solidCleaning.includes(feedingApron.cleaningMethod);
        hasSolids |= ["Carbonrichsawdustbarkwoodchips", "Soil"].includes(bunker.bunkerLining);

        //Liquid effluent
        hasLiquids = liquidCleaning.includes(feedingApron.cleaningMethod);
        hasLiquids |= liquidCleaning.includes(bunker.cleaningMethod);
        hasLiquids |= bunker.liquidDrained;
    }
    return [hasSolids, hasLiquids];
};

export const deselectMonths = (application, applications) => {
    if (!application.months) return;
    /* eslint-disable no-unused-vars */
    for (const sibling of applications.filter((a) => a.id !== application.id)) {
        const { months = [] } = sibling;
        sibling.months = months.filter((m) => !application.months.find((m2) => m2 === m));
    }
};

export const canAddStructure = (budget, hasDairy, structureType) => {
    const { structures = [] } = budget;
    const typeStructures = structures.filter((s) => s.type === structureType) || [];
    const availableEnterpriseCount = availableEnterprises(budget, {}, structureType).length;

    if (availableEnterpriseCount === 0) {
        return false;
    }

    if (!budget.effluentSystem && hasDairy) {
        return false;
    }

    switch (structureType) {
        case "MilkingShed": {
            const enterpriseDairy = budget.enterprises.filter((e) => ["Dairy", "DairyGoat"].includes(e.type)) || [];
            return typeStructures.length < enterpriseDairy.length;
        }
        case "FeedingPad":
        case "StandoffPad":
            return typeStructures.length === 0;
        case "CoveredWinteringPad":
        case "UncoveredWinteringPad": {
            const winteringPads = structures.filter((s) => ["CoveredWinteringPad", "UncoveredWinteringPad"].includes(s.type));
            return winteringPads.length === 0 || availableEnterpriseCount > 0;
        }
        case "OutdoorPigFarrowingVillages": {
            const enterprise = (budget.enterprises || []).find((e) => e.type === "OutdoorPigs");
            const canAddFarrowingVillage = enterprise && !enterprise.outdoorPigs.growOutUnitOnly && structures.some((s) => s.type === "OutdoorPigFarrowingVillages") === false;
            return canAddFarrowingVillage;
        }
        case "OutdoorPigBarns": {
            const canAddPigShed = structures.some((s) => s.type === "OutdoorPigBarns") === false;
            return canAddPigShed;
        }
        default:
            return true;
    }
};

export const hideAnimalsTimeOnPad = (structure) => {
    return structure.type === "MilkingShed" || (["CoveredWinteringPad", "UncoveredWinteringPad"].includes(structure.type) && !structure.grazingHours);
};

export const availableEnterprises = (analysis, refData, type, structureId = "") => {
    const { enterpriseTypes = [] } = refData;
    const { structures = [] } = analysis;
    const winteringPads = ["UncoveredWinteringPad", "CoveredWinteringPad"];
    const types = winteringPads.includes(type) ? winteringPads : [type];
    const existingEnterprises = structures.filter((b) => b.id !== structureId && types.includes(b.type)).map((s) => s.enterpriseId);
    const includeOutdoorPigs = ["OutdoorPigFarrowingVillages", "OutdoorPigBarns", "OutdoorPigHuts"].includes(type);

    return (analysis.enterprises || [])
        .filter((e) => !existingEnterprises.includes(e.id) && (includeOutdoorPigs || e.type !== "OutdoorPigs") && (["Dairy", "DairyGoat"].includes(e.type) || !["MilkingShed"].includes(type)) && (["Dairy"].includes(e.type) || !["StandoffPad", "FeedingPad"].includes(type)) && (!["Sheep", "Other"].includes(e.type) || !winteringPads.includes(type)))
        .map((e) => {
            const enterpriseType = enterpriseTypes.find((t) => t.value === e.type) || {};
            return { value: e.id, text: enterpriseType.text };
        });
};

export const _getEffluentApplicationsForSystem = (farm, budget, block, refData, system, hideLink, structure) => {
    let blockActivities = [];
    const enterprise = structure && _findEnterpriseType(refData, structure, budget);
    const title = structure ? utils.valueToText(refData.structureType, structure.type) + "-" + enterprise.text : "Farm effluent system";

    if (system.solidManagement) {
        blockActivities.push(..._getEffluentApplications(farm, budget, block, title, refData, system.solidManagement.solidApplications, "Solids", { icon: icons.effluentSolid, text: "Solid" }, hideLink, structure));
        blockActivities.push(..._getEffluentApplications(farm, budget, block, title, refData, system.solidManagement.pondApplications, "Pond solids", { icon: icons.effluentSolid, text: "Solid" }, hideLink, structure));
    }
    if (system.liquidManagement) {
        blockActivities.push(..._getEffluentApplications(farm, budget, block, title, refData, system.liquidManagement.applications, "Liquids", { icon: icons.effluentLiquid, text: "Liquid" }, hideLink, structure));
    }
    return blockActivities;
};

export const _getEffluentApplicationsForOutdoorPigSystem = (farm, budget, block, refData, system, hideLink) => {
    let blockActivities = [];
    const title = "Outdoor pig effluent system";

    if (system.liquidEffluentApplications && system.liquidEffluentApplications.length > 0) {
        blockActivities.push(..._getEffluentApplications(farm, budget, block, title, refData, system.liquidEffluentApplications, "Liquids", { icon: icons.effluentLiquid, text: "Liquid" }, hideLink, undefined, "outdoorPigEffluent"));
    }

    if (system.pondSolidApplications && system.pondSolidApplications.length > 0) {
        blockActivities.push(..._getEffluentApplications(farm, budget, block, title, refData, system.pondSolidApplications, "Pond solids", { icon: icons.effluentSolid, text: "Solid" }, hideLink, undefined, "outdoorPigEffluent"));
    }

    if (system.strawCompostApplications && system.strawCompostApplications.length > 0) {
        blockActivities.push(..._getEffluentApplications(farm, budget, block, title, refData, system.strawCompostApplications, "Straw compost", { icon: icons.effluentSolid, text: "Straw" }, hideLink, undefined, "outdoorPigEffluent"));
    }
    return blockActivities;
};

const _findEnterpriseType = (refData, structure, budget) => {
    const { enterpriseTypes = [] } = refData;
    let enterprise = budget.enterprises && budget.enterprises.find((e) => e.id === structure.enterpriseId);
    if (!enterprise) {
        enterprise = budget.enterprises.find((e) => e.type === "Dairy") || {};
    }
    return enterpriseTypes.find((t) => t.value === enterprise.type) || {};
};

const _getEffluentApplications = (farm, budget, block, title, refData, applications, type, icon, hideLink, structure, systemName) => {
    let blockActivities = [];
    if (applications && applications.length > 0) {
        let applicationsForBlock = applications.filter((s) => s.blockIds.includes(block.id));
        const link = `/app/farm/${farm.id}/analysis/${budget.id}/structures/` + (structure ? `effluent?id=${structure.id}` : systemName ? systemName : "effluent");

        const titleContent = hideLink ? (
            <div className="td-value">
                <span className="td-value-text">{title}</span>
            </div>
        ) : (
            <Link to={link} type="button" id={`${budget.id}_effluent_sys`} className="td-value">
                <span className="td-value-text">{title}</span>
                <span className="icon icon-edit" />
            </Link>
        );

        /* eslint-disable no-unused-vars */
        for (const application of applicationsForBlock) {
            const blockActivity = {
                id: application.id,
                type: type,
                titleContent: titleContent,
                heading: "Effluent - " + type,
                activity: [],
            };
            let depth = undefined;
            if (application.applicationDepth) {
                const { effluentAppRates = [] } = refData;
                depth = effluentAppRates.find((t) => t.value === application.applicationDepth) || {};
            }

            if (application.months && application.months.length > 0) {
                const depthText = depth ? (depth.text === "Low application method" ? "Low depth" : depth.text) : null;
                /* eslint-disable no-unused-vars */
                for (const month of application.months) {
                    const content = (
                        <td key={`${block.id}_${application.id}_${month.month}`} className="Calendar-slot">
                            <img className="Calendar-icon u-p-xxs" src={icon.icon} alt={icon.text} width={35} height={35} />
                            {depth && <span className="Calendar-icon-text"> {depthText}</span>}
                        </td>
                    );
                    blockActivity.activity.push({
                        month: month.month,
                        content: content,
                        reportingYear: month.reportingYear,
                    });
                }
            } else if (application.applicationDepth) {
                const noMonthsMessage = (
                    <span className="u-flex u-flexAlignItemsCenter">
                        <img className="u-p-5" src={icon.icon} width="45" height="45" alt={icon.text} />
                        <span>{application.areaEffluent && application.areaEffluent > 0 ? "Applications applied regularly throughout the year at " + depth.text + " over " + application.areaEffluent + "% of the block" : "Applications applied regularly throughout the year at " + depth.text}</span>
                    </span>
                );
                blockActivity.activity.push({
                    content: noMonthsMessage,
                    reportingYear: true,
                });
            }
            if (blockActivity.activity.length > 0) {
                blockActivities.push(blockActivity);
            }
        }
    }
    return blockActivities;
};

/**
* Clean up the analysis from saving a structure and return the updated analysis
*/
export function getUpdatedAnalysisFromSavingStructure(analysis, structure) {
    const updatedAnalysis = {
        ...analysis
    };

    if (updatedAnalysis.structures.some(s => s.id === structure.id)) {
        updatedAnalysis.structures = updatedAnalysis.structures.map(s => s.id === structure.id ? structure : s);
    } else {
        updatedAnalysis.structures.push(structure);
    }

    // Add as option to dairy goat left overs if structure is a wintering pad.
    if (["CoveredWinteringPad", "UncoveredWinteringPad"].includes(structure.type)) {
        updatedAnalysis.enterprises = (updatedAnalysis.enterprises || []).map((enterprise) => {
            if (enterprise.type === "DairyGoat" && enterprise.dairyGoatLeftOvers && enterprise.dairyGoatLeftOvers.winteringPadLeftOvers && enterprise.dairyGoatLeftOvers.winteringPadLeftOvers.fate === "FedOnWinteringPads") {
                if (!enterprise.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages.some((ep) => ep.enterpriseId === structure.enterpriseId)) {
                    enterprise.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages.push({ enterpriseId: structure.enterpriseId, percentage: 0 });
                }
            }
            return enterprise;
        });
    }

    // Clean up associated outdoor pigs effluent applications.
    if (updatedAnalysis.outdoorPigEffluentSystem && ["OutdoorPigFarrowingVillages", "OutdoorPigBarns", "OutdoorPigHuts"].includes(structure.type)) {
        if (!updatedAnalysis.structures.some((s) => requiresOutdoorPigLiquidApplications(s))) delete updatedAnalysis.outdoorPigEffluentSystem.liquidEffluentApplications;

        // Clean up liquid application months
        if (structure.type === "OutdoorPigFarrowingVillages" && structure.effluentDisposal !== "HoldingpondSprayinginfrequently") {
            updatedAnalysis.outdoorPigEffluentSystem.liquidEffluentApplications = (updatedAnalysis.outdoorPigEffluentSystem.liquidEffluentApplications || []).map((application) => {
                const isForCropBlocks = application.blockIds.some((blockId) => (updatedAnalysis.blocks || []).some((b) => b.id === blockId && ["ProductiveCrop", "FodderCrop"].includes(b.type)));
                if (!isForCropBlocks) return { ...application, months: [] };

                return application;
            });
        }

        if (!updatedAnalysis.structures.some((s) => requiresOutdoorPigSolidApplications(s))) delete updatedAnalysis.outdoorPigEffluentSystem.pondSolidApplications;

        if (!updatedAnalysis.structures.some((s) => requiresOutdoorPigStrawCompostApplications(s))) delete updatedAnalysis.outdoorPigEffluentSystem.strawCompostApplications;
    }

    return updatedAnalysis;
}

/**
* Clean up the analysis from deleting a structure and return the updated analysis
*/
export function getUpdatedAnalysisFromDeletingStructure(analysis, structureId) {
    const structureBeingDeleted = (analysis.structures || []).find((s) => s.id === structureId);

    const updatedAnalysis = {
        ...analysis,
        structures: analysis.structures.filter((s) => s.id !== structureBeingDeleted.id)
    };

    // Clean up associated feed supplements.
    if (updatedAnalysis.feedSupplements?.length > 0) {
        updatedAnalysis.supplements = analysis.feedSupplements.reduce((results, supplement) => {
            const destinations = (supplement.destinations || []).filter((destination) => destination.structureId !== structureBeingDeleted.id);
            results.push({ ...supplement, destinations });
            return results;
        }, []);
    }

    // Clean up associated dairy goat left overs wintering pads.
    if (["CoveredWinteringPad", "UncoveredWinteringPad"].includes(structureBeingDeleted.type)) {
        updatedAnalysis.enterprises = (updatedAnalysis.enterprises || []).map((enterprise) => {
            if (enterprise.type === "DairyGoat" && enterprise.dairyGoatLeftOvers && enterprise.dairyGoatLeftOvers.winteringPadLeftOvers) {
                if (structureBeingDeleted.enterpriseId === enterprise.id) {
                    delete enterprise.dairyGoatLeftOvers.winteringPadLeftOvers;
                } else if (enterprise.dairyGoatLeftOvers.winteringPadLeftOvers.fate === "FedOnWinteringPads") {
                    const enterprisePercentages = enterprise.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages.filter((ep) => ep.enterpriseId !== structureBeingDeleted.enterpriseId);
                    if (enterprisePercentages.length === 0) {
                        delete enterprise.dairyGoatLeftOvers.winteringPadLeftOvers;
                    } else {
                        enterprise.dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages = enterprisePercentages;
                    }
                }
            }
            return enterprise;
        });
    }

    // Clean up associated outdoor pigs effluent applications.
    if (updatedAnalysis.outdoorPigEffluentSystem && ["OutdoorPigFarrowingVillages", "OutdoorPigBarns", "OutdoorPigHuts"].includes(structureBeingDeleted.type)) {
        if (!updatedAnalysis.structures.some((s) => requiresOutdoorPigLiquidApplications(s))) delete updatedAnalysis.outdoorPigEffluentSystem.liquidEffluentApplications;

        if (!updatedAnalysis.structures.some((s) => requiresOutdoorPigSolidApplications(s))) delete updatedAnalysis.outdoorPigEffluentSystem.pondSolidApplications;

        if (!updatedAnalysis.structures.some((s) => requiresOutdoorPigStrawCompostApplications(s))) delete updatedAnalysis.outdoorPigEffluentSystem.strawCompostApplications;
    }

    return updatedAnalysis;
}

/**
* Clean up the analysis from saving an effluent system and return the updated analysis
*/
export function getUpdatedAnalysisFromSavingEffluentSystem(analysis, effluentSystem, structureId) {
    const updatedAnalysis = {
        ...analysis
    };

    if (structureId) {
        const structure = updatedAnalysis.structures.find((s) => s.id === structureId);
        structure.effluentSystem = effluentSystem;
    } else {
        updatedAnalysis.effluentSystem = effluentSystem;
    }

    return updatedAnalysis;
}

/**
* Clean up the analysis from saving an outdoor pig effluent system and return the updated analysis
*/
export function getUpdatedAnalysisFromSavingOutdoorPigEffluentSystem(analysis, outdoorPigEffluentSystem) {
    const updatedAnalysis = {
        ...analysis,
        outdoorPigEffluentSystem
    };

    // Update villages effluent disposal.
    if (outdoorPigEffluentSystem.villagesEffluentDisposal) {
        updatedAnalysis.structures = (updatedAnalysis.structures || []).map((structure) => {
            if (structure.type !== "OutdoorPigFarrowingVillages") return structure;

            return {
                ...structure,
                effluentDisposal: outdoorPigEffluentSystem.villagesEffluentDisposal,
            };
        });
    }

    // Update barns effluent disposal.
    if (outdoorPigEffluentSystem.barnsEffluentDisposal) {
        updatedAnalysis.structures = (updatedAnalysis.structures || []).map((structure) => {
            if (structure.type !== "OutdoorPigBarns") return structure;

            return {
                ...structure,
                effluentDisposal: outdoorPigEffluentSystem.barnsEffluentDisposal,
            };
        });
    }

    return updatedAnalysis;
}
