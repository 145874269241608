import { Menu, Item, MenuProvider, Separator } from "react-contexify";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import Card from "components/new/Card";
import CardHeader from "components/new/CardHeader";
import CardHeaderColumn from "components/new/CardHeaderColumn";
import CardHeaderField from "components/new/CardHeaderField";
import CardActions from "components/new/CardActions";
import CardActionStatus from "components/new/CardActionStatus";
import CardActionsLeft from "components/new/CardActionsLeft";
import CardActionsRight from "components/new/CardActionsRight";
import { AnalysisResultsWidget } from "components/ResultsWidgets";
import { isPublicationLocked, isPublishingToMPIWholeFarmDataProject } from "containers/Publications";
import { usePublicationDetailsModal } from "./PublicationDetailsModal";
import { useViewPublicationHistoryModal } from "./ViewPublicationHistoryModal";
import { useAuthContext, useConfirm, useNavigate, useOnline } from "common/hooks";
import { useCreateWorkingCopyAsync, useDeletePublicationAsync, useSetPublicationAsPlanAsync } from "containers/hooks";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";

export default function PublicationCard({ farm, analysis, publication }) {
    const online = useOnline();
    const authContext = useAuthContext();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const createWorkingCopyAsync = useCreateWorkingCopyAsync();
    const deletePublicationAsync = useDeletePublicationAsync();
    const setPublicationAsPlanAsync = useSetPublicationAsPlanAsync();

    const workingCopy = publication.workingCopyId && farm.budgets?.find((b) => b.id === publication.workingCopyId);
    const publishedAnalysis = publication.publishedAnalysisId && farm.budgets?.find((b) => b.id === publication.publishedAnalysisId);

    const isLocked = isPublicationLocked(publication);
    const canManage = online && !isLocked;
    const canViewHistory = online;
    const canViewWorkingCopy = online && publication.hasPublisheeAccess && publication.accountId !== publication.publishedBy.accountId && !publication.farmGroupId;
    const canAddComment = online;
    const isPublishedByMe = publication.publishedBy.accountId === authContext.accountId;
    const isPublishedToMe = publication.accountId === authContext.accountId;
    const isPublishedToAFarmGroup = publication.farmGroupId && !publication.accountId;
    const isPublishingToMPI = isPublishingToMPIWholeFarmDataProject(publication.accountId);
    const iAmAnOwnerOfTheFarm = farm.role === "Owner";
    const canDelete = online && (authContext.isSystemAdmin || isPublishedToMe || (iAmAnOwnerOfTheFarm && isPublishedToAFarmGroup) || (isPublishedByMe && isPublishingToMPI));
    const [addOrEditPublicationModal, openAddOrEditPublicationModal] = usePublicationDetailsModal(publication);
    const [viewPublicationHistoryModal, openViewPublicationHistoryModal] = useViewPublicationHistoryModal(publication);
    const [publicationCommentsModal, openPublicationCommentsModal] = usePublicationDetailsModal(publication, true);
    const featureTracker = useFeatureTracker(FEATURES.ANALYSIS_LINKS_LIST, undefined, farm);

    const _viewPublication = () => {
        featureTracker.track("View analysis - Clicked");
        navigate(`/app/farm/${farm.id}/analysis/${publication.publishedAnalysisId}/overview`);
    };

    const _viewWorkingCopy = async () => {
        if (workingCopy) {
            navigate(`/app/farm/${farm.id}/analysis/${workingCopy.id}`);
        } else {
            const newWorkingCopyId = uuidv4();
            await createWorkingCopyAsync(publication, newWorkingCopyId);
            navigate(`/app/farm/${publication.farmId}/analysis/${newWorkingCopyId}`);
        }
    };

    const _deletePublication = () => {
        confirm(`Are you sure you want to delete the "Version ${publication.version}" publication of the "${analysis.name}" analysis?`, async () => {
            await deletePublicationAsync(farm, publication);
        });
    };

    const _setPublicationAsPlan = async () => {
        await setPublicationAsPlanAsync(publication);
    }

    const showPreviousResults = !publishedAnalysis.previousNLossPerHa || publishedAnalysis.previousNLossPerHa === 0 ? false : publishedAnalysis.nLossPerHa === publication.previousNLossPerHa && publication.pLossPerHa === publishedAnalysis.previousPLossPerHa && publishedAnalysis.ghgkPerHa > 0 && publishedAnalysis.ghgkPerHa === publishedAnalysis.previousGHGKPerHa ? false : true;
    const previousMessage = !publishedAnalysis.previousNLossPerHa || publishedAnalysis.previousNLossPerHa === 0 ? `v${publishedAnalysis.previousModelVersion}: No results` : publication.nLossPerHa === publication.previousNLossPerHa && publishedAnalysis.pLossPerHa === publishedAnalysis.previousPLossPerHa && publishedAnalysis.ghgkPerHa > 0 && publishedAnalysis.ghgkPerHa === publishedAnalysis.previousGHGKPerHa ? `v${publishedAnalysis.previousModelVersion}: No change` : `v${publishedAnalysis.previousModelVersion}: `;
    const canSetAsPlan = analysis.type === "Predictive" && publication.accountId === authContext.accountId && (authContext.isSystemAdmin || authContext.orgApiAccess);

    let statusIcon = "u-textSecondary";
    switch (publication.status) {
        case "Draft":
            statusIcon = "u-textSecondary";
            break;
        case "Submitted":
        case "Processed":
            statusIcon = "u-textSuccess";
            break;
        case "Returned":
        case "Reviewing":
            statusIcon = "u-textWarning";
            break;
        default:
            statusIcon = "u-textMidGrey";
    }

    const menuShown = () => {
        const menu = document.querySelector(".react-contexify");
        const container = document.querySelector(".actions-link");

        if (menu && container) {
            menu.style.top = `${menu.getBoundingClientRect().top + 13}px`;
            menu.style.left = `${container.getBoundingClientRect().left}px`;
        }
    };

    const MenuItem = ({ id, className, text, evt }) => (
        <Item onClick={(e) => evt && evt(e)}>
            <div className="u-flex" id={id}>
                <i className={className} />
                <span>{text}</span>
            </div>
        </Item>
    );

    return (
        <>
            {addOrEditPublicationModal}
            {publicationCommentsModal}
            {viewPublicationHistoryModal}
            <Card key={publication.id}>
                <input type="hidden" id={`${analysis.id}_${publication.version}`} value={publication.id} />
                <CardHeader dataWidth="md-100 xl-20 xxl-30" title={`${publication.publishedTo}`} subTitle={`Version ${publication.version}`} onTitleClick={online ? _viewPublication : undefined} onHeaderClick={online ? _viewPublication : undefined} id={publication.id} name={publication.reference}>
                    <CardHeaderColumn className="u-pr-xxl">
                        <CardHeaderField label="Published" value={utils.dateOnly(publication.publishedOn)} />
                    </CardHeaderColumn>
                    <CardHeaderColumn className="u-pr-xxl">
                        <CardHeaderField label="Published by" value={publication.publishedBy ? publication.publishedBy.accountName : "-"} />
                    </CardHeaderColumn>
                    <CardHeaderColumn className="u-pr-xxl">
                        <CardHeaderField label="Farm identifier" value={publication.farmIdentifier ? publication.farmIdentifier : "-"} />
                    </CardHeaderColumn>
                    <CardHeaderColumn className="u-pr-xxl">
                        <CardHeaderField label="Reference" value={publication.reference ? publication.reference : "-"} />
                    </CardHeaderColumn>
                    <CardHeaderColumn nutrientsLost>
                        <AnalysisResultsWidget analysis={publishedAnalysis} perHaOnly />
                        <MenuProvider event="onClick" id={`menu_${publication.id}`}>
                            <div className="u-flex u-flexJustifyCenter u-link u-font-blue actions-link u-textBold">
                                <i className="icon icon--sm icon-dots-horizontal-triple" />
                                <span id={`menu_${publication.id}`}>Actions</span>
                            </div>
                        </MenuProvider>
                        <Menu id={`menu_${publication.id}`} onShown={(e) => menuShown()}>
                            {canSetAsPlan && publication.isPlan && <MenuItem id={`isplan-${publication.id}`} className="IconLink--tick-circle" text="Is plan" />}
                            {canSetAsPlan && !publication.isPlan && <MenuItem id={`isplan-${publication.id}`} className="IconLink--target" text="Set as plan" evt={_setPublicationAsPlan} />}
                            {canManage && <MenuItem id={`manage-${publication.id}`} className="IconLink--settings" text="Manage" evt={openAddOrEditPublicationModal} />}
                            {canViewHistory && <MenuItem id={`history-${publication.id}`} className="IconLink--clock" text="History" evt={openViewPublicationHistoryModal} />}
                            {canViewWorkingCopy && <MenuItem id={`view-working-copy-${publication.id}`} className="IconLink--edit" text="Working copy" evt={_viewWorkingCopy} />}
                            {canAddComment && <MenuItem id={`comments-${publication.id}`} className="IconLink--chat" text="Comment" evt={openPublicationCommentsModal} />}
                            {canDelete && (
                                <>
                                    <Separator />
                                    <MenuItem id={`delete-publication-${publication.id}`} className="IconLink--trash" text="Delete" evt={_deletePublication} />
                                </>
                            )}
                        </Menu>
                    </CardHeaderColumn>
                </CardHeader>
                <CardActions>
                    <CardActionsLeft>
                        {online && (
                            <>
                                <CardActionStatus className={"icon icon-dot " + statusIcon} status={publication.status} />
                            </>
                        )}
                        <>
                            <CardActionStatus status={previousMessage} />
                            {showPreviousResults && (
                                <>
                                    {publishedAnalysis.previousNLossPerHa ? <CardActionStatus className="icon icon-square N-color" status={`N/ha: ${publishedAnalysis.previousNLossPerHa}`} /> : ""}
                                    {publishedAnalysis.previousPLossPerHa ? <CardActionStatus className="icon icon-square P-color" status={`P/ha: ${publishedAnalysis.previousPLossPerHa}`} /> : ""}
                                    {publishedAnalysis.previousGHGKPerHa ? <CardActionStatus className="icon icon-square GHG-color" status={`GHG/ha: ${publishedAnalysis.previousGHGKPerHa}`} /> : ""}
                                </>
                            )}
                        </>
                        {!online && (
                            <>
                                <CardActionStatus className="icon icon-cross-circle u-textError" status="Offline" />
                            </>
                        )}
                    </CardActionsLeft>
                    <CardActionsRight>
                        
                    </CardActionsRight>
                </CardActions>
            </Card>
        </>
    )
}
