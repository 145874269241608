import React from "react";
import { getAutoRenewals, deleteAutoRenewal } from "../_actions";
import { useDispatch } from "react-redux";
import { Panel, PanelBody } from "components/Panel";
import Alert from "components/Alert";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { Link } from "react-router-dom";
import PageHead from "components/PageHead";
import ActionLink from "components/ActionLink";
import * as utils from "common/utils";
import * as icons from "common/icons";
import { useConfirm } from "common/hooks";

export default function AutoRenewals() {
    const dispatch = useDispatch();
    const confirm = useConfirm();

    const [autoRenewals, setAutoRenewals] = React.useState();
    const [searchText, setSearchText] = React.useState();
    const [searchProperty, setsearchProperty] = React.useState("tokenUser");

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(getAutoRenewals());
                setAutoRenewals(response);
            } catch {
                setAutoRenewals([]);
            }
        };

        if (!autoRenewals) {
            fetchData();
        }
    }, [autoRenewals, dispatch]);

    const filteredRenewals = autoRenewals ? autoRenewals.filter((d) => !searchText || searchText.length < 3 || d[searchProperty].toLowerCase().includes(searchText.toLowerCase())) : [];
    const searchTextPlaceholder = searchProperty === "tokenUser" ? "Search by users name. When no search text all upcoming renewals are showing" : "Search by farm name. When no search text all upcoming renewals are showing";

    const confirmRemoveToken = (renewal) => {
        const removeToken = async () => {
            await dispatch(deleteAutoRenewal(renewal.id));
            setAutoRenewals(autoRenewals.filter((r) => r.id !== renewal.id));
        };
        confirm(`Are you sure you want to remove the token from ${renewal.name}?`, () => removeToken());
    };

    return (
        <Panel>
            <Alert type="info" text="This list shows farms that will have their upcoming subscription expiry auto renewed. It shows the user who has put the auto renewal on the farm. You can remove the auto renewl by selecting the trash icon. This will only remove the auto renewal for the farm - not for the card that may have multiple farms on it." />
            <PanelBody loading={!autoRenewals}>
                <div>
                    <PageHead className="u-mb-md">
                        <div className="PageHead-actions">
                            <label className="Select Select--sort">
                                <span className="Select--sort-label">Search by</span>
                                <select id="searchFor" value={searchProperty} className="Select--sort-select" onChange={(e) => setsearchProperty(e.target.value)}>
                                    <option value="name">Farms</option>
                                    <option value="tokenUser">Users</option>
                                </select>
                            </label>
                        </div>
                        <div className="PageHead-searchInput">
                            <div className="Input_wrapper">
                                <input id="searchText" placeholder={searchTextPlaceholder} value={searchText} type="search" className="Input Input--block has-clear has-value" onChange={(e) => setSearchText(e.target.value)} />
                                <ActionLink id="searchClear" className="Input-clear" onClick={() => setSearchText("")}>
                                    <img src={icons.clear} height="24px" width="24px" className="u-p-5" alt="Clear" />
                                </ActionLink>
                            </div>
                        </div>
                    </PageHead>
                </div>
                <div>
                    <React.Fragment>
                        {autoRenewals && (
                            <div>
                                <p className="u-mt-0 u-mb-md">
                                    <span className="h3">{autoRenewals.length}</span> farms are scheduled to have their subscription auto renewed this coming year.
                                </p>
                                <p className="u-mt-0 u-mb-md">
                                    <span className="h3">{autoRenewals.filter((r) => r.subscriptionExpiryDate < r.tokenExpiryDate).length}</span> will have a valid credit card at the time of auto renewal.
                                </p>
                            </div>
                        )}
                        <SortableTable data={filteredRenewals} defaultSortCriteria={{ fieldName: "subscriptionExpiryDate", fieldType: "date", desc: false }} pageSize={50}>
                            {({ data, sortCriteria, handleSort }) => {
                                return (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Reminder sent</th>
                                                <th>Reminder date</th>
                                                <th>Payment date</th>
                                                <th>CC expiry date</th>
                                                <th>Method</th>
                                                <th>Will expire</th>
                                                <SortableTableHeader label="Sub expiry" fieldName="subscriptionExpiryDate" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Farm name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Farm address" fieldName="address" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="User" fieldName="tokenUser" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Email" fieldName="tokenUserEmail" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Organisation" fieldName="tokenUserAccount" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <th label="Remove auto payment"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((renewal) => {
                                                return (
                                                    <tr key={renewal.id}>
                                                        <td>{renewal.autoRenewalReminderEmailSentDate ? "Yes" : "-"}</td>
                                                        {renewal.autoRenewalReminderEmailSentDate ? <td>{utils.dateOnly(renewal.autoRenewalReminderEmailSentDate, "-")}</td> : <td>{utils.dateOnly(renewal.estimatedReminderDate, "-")} </td>}
                                                        {renewal.autoRenewalScheduledPaymentDate ? <td>{utils.dateOnly(renewal.autoRenewalScheduledPaymentDate, "-")}</td> : <td>{utils.dateOnly(renewal.estimatedScheduledPaymentDate, "-")} </td>}
                                                        <td>{utils.dateOnly(renewal.tokenExpiryDate, "-")}</td>
                                                        <td>{renewal.paymentMethod}</td>
                                                        <td>{renewal.subscriptionExpiryDate < renewal.tokenExpiryDate ? "No" : "Yes"}</td>
                                                        <td>{utils.dateOnly(renewal.subscriptionExpiryDate, "-")}</td>
                                                        <td>
                                                            <Link target="_blank" to={`/app/farm/${renewal.id}`}>
                                                                {renewal.name}
                                                            </Link>
                                                        </td>
                                                        <td>{renewal.address}</td>
                                                        <td>{renewal.tokenUser}</td>
                                                        <td>{renewal.tokenUserEmail}</td>
                                                        <td>{renewal.tokenUserAccount}</td>
                                                        <td valign="top">
                                                            <div className="u-flex">
                                                                <ActionLink id={renewal.id} className="icon icon-trash" title="Remove auto renewal" onClick={() => confirmRemoveToken(renewal)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                );
                            }}
                        </SortableTable>
                    </React.Fragment>
                </div>
            </PanelBody>
        </Panel>
    );
}
