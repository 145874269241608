import React from "react";
import * as utils from "common/utils";
import { Range } from "rc-slider";
import * as icons from "common/icons";
import MonthDayInputPack from "components/MonthDayInputPack";
import ActionLink from "components/ActionLink";

const DairyCalving = ({ calvingDetails = [], deleteHerd, addHerd, updateCalvingDetails }) => {
    if (!calvingDetails || calvingDetails.length === 0) return null;
    const rangeVals = [];
    const handleStyle = { backgroundColor: "#509bc2", borderColor: "#509bc2", borderWidth: 4, height: 28, width: 28, marginTop: -12 };
    const blankHandleStyle = { backgroundColor: "#fff", borderColor: "#fff", borderWidth: 0, height: 1, width: 1, marginLeft: 0, marginTop: 0 };
    const handleStyles = [];
    let total = 0;
    calvingDetails.forEach((cd) => {
        total += cd.calvingHerdPercentage;
        rangeVals.push(total);
        handleStyles.push(handleStyle);
    });
    handleStyles.pop();
    handleStyles.push(blankHandleStyle);

    //rangeVals.pop()

    const rangeChange = (values) => {
        const inputData = utils.clone(values);
        calvingDetails[0].calvingHerdPercentage = inputData[0];
        if (calvingDetails.length === 3) {
            calvingDetails[1].calvingHerdPercentage = inputData[1] - inputData[0];
            calvingDetails[2].calvingHerdPercentage = 100 - inputData[1];
        } else {
            calvingDetails[1].calvingHerdPercentage = 100 - inputData[0];
        }
        updateCalvingDetails(calvingDetails);
    };

    const medCalvingDateChange = (e, indx) => {
        const dateParts = e.split(" ");
        if (dateParts.length === 2) {
            calvingDetails[indx].midCalvingDay = parseInt(dateParts[0], 10);
            calvingDetails[indx].midCalvingMonth = dateParts[1];
        }
        updateCalvingDetails(calvingDetails);
    };

    const dryDateChange = (e, indx) => {
        if (e) {
            const dateParts = e.split(" ");
            if (dateParts.length === 2) {
                calvingDetails[indx].dryDay = parseInt(dateParts[0], 10);
                calvingDetails[indx].dryMonth = dateParts[1];
            }
        } else {
            delete calvingDetails[indx].dryDay;
            delete calvingDetails[indx].dryMonth;
        }
        updateCalvingDetails(calvingDetails);
    };

    const Herd = ({ index }) => {
        const medCalvingDateInput = {
            id: `medCalvingDate_${index}`,
            onChange: (e) => medCalvingDateChange(e, index),
            value: calvingDetails[index].midCalvingDay ? `${calvingDetails[index].midCalvingDay} ${calvingDetails[index].midCalvingMonth}` : "",
        };
        const dryDateInput = {
            id: `dryDate_${index}`,
            onChange: (e) => dryDateChange(e, index),
            value: calvingDetails[index].dryDay ? `${calvingDetails[index].dryDay} ${calvingDetails[index].dryMonth}` : "",
        };

        return (
            <div className={`CalvingRange-item CalvingRange-item--${utils.colourNameIndex[index]}`}>
                <div className="CalvingRange-name">Herd {index + 1}</div>
                <div className="CalvingRange-value" id={`herd_amount_${index}`}>
                    {calvingDetails[index].calvingHerdPercentage}
                    <span>%</span>
                </div>
                <div style={{ marginTop: -25 }}>
                    <MonthDayInputPack name={`medCalvingDate_${index}`} label="Median calving date" input={medCalvingDateInput} />
                </div>
                <div>
                    <MonthDayInputPack name={`dryOffDate_${index}`} label="Drying off date" input={dryDateInput} clearable={true} />
                </div>
                {(calvingDetails[index].error || calvingDetails[index].error === 0) && (
                    <div className="Field has-error">
                        <small className="Field-error" id="stockCounts-error">
                            Lactation length (days from calving to drying off) must be between 100 and 335 days. Currently set at {calvingDetails[index].error} {calvingDetails[index].error === 1 ? "day" : "days"}
                        </small>
                    </div>
                )}
                <div style={{ height: "40px", paddingTop: "20px", fontSize: "14px" }}>
                    {index > 0 && (
                        <ActionLink id={`herd2_del_${index}`} onClick={() => deleteHerd(index)}>
                            Delete
                        </ActionLink>
                    )}
                </div>
            </div>
        );
    };

    const HerdAdd = ({ index }) => {
        return (
            <div className="CalvingRange-item CalvingRange-item--add">
                <img alt="Add herd" height="45" className="u-p-5" src={icons.dairy} title="Add herd" width="45" />
                <div className="CalvingRange-item--title">Herd {index + 1}</div>
                <div className="CalvingRange-item--title">
                    <ActionLink id={`herd_add_${index}`} onClick={() => addHerd()}>
                        Add
                    </ActionLink>
                </div>
            </div>
        );
    };

    let trackStyles = calvingDetails.map((c, i) => {
        return { backgroundColor: utils.colourHexIndex[i + 1] };
    });

    const railStyle = { backgroundColor: utils.colourHexIndex[0] };

    return (
        <React.Fragment>
            <div className="CalvingRange u-flex">
                <Herd index={0} />
                {calvingDetails.length > 1 ? <Herd index={1} /> : <HerdAdd index={1} />}
                {calvingDetails.length > 2 ? <Herd index={2} /> : <HerdAdd index={2} />}
            </div>
            {calvingDetails.length > 1 && (
                <div className="CalvingRange-slider">
                    <Range step={1} onChange={rangeChange} allowCross={true} pushable={1} min={1} max={99} value={rangeVals} trackStyle={trackStyles} railStyle={railStyle} handleStyle={handleStyles} />
                </div>
            )}
        </React.Fragment>
    );
};

export default DairyCalving;
