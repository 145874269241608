import React from "react";
import * as utils from "common/utils";
import { compose, withAppContext, withState } from "containers/HOC";
import { withRouter } from "react-router-dom";
import { Form, Field } from "react-final-form";
import InputPack from "components/InputPack";
import CheckboxPack from "components/CheckboxPack";
import * as validations from "common/validations";
import Nutrients, { validateNutrients, nutrientDecorators, augmentNutrients } from "components/Nutrients";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";

class ProductModal extends React.Component {
    _closeOnEscape = (e) => {
        if (e.keyCode === 27) {
            this.props.cancel();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this._closeOnEscape);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._closeOnEscape);
    }

    onSubmit(values) {
        const { fertiliser } = values;
        if (fertiliser.type === "Supplements") {
            augmentNutrients(fertiliser);
        }
        this.props.submit(fertiliser);
    }

    render() {
        const { viewModel = {}, online, compositions = [], isReadOnly } = this.props;

        return (
            <Form
                onSubmit={(values) => this.onSubmit(values)}
                initialValues={viewModel}
                decorators={[nutrientDecorators()]}
                validate={(values) => {
                    const errors = { fertiliser: {} };
                    const nameExists = compositions.find((f) => f.name && values.fertiliser.name && f.id !== values.fertiliser.id && f.name.trim().toUpperCase() === values.fertiliser.name.trim().toUpperCase());

                    errors.fertiliser.name = validations.required(values.fertiliser.name);
                    errors.fertiliser.name = errors.fertiliser.name || validations.maxLength(200)(values.fertiliser.name);
                    errors.fertiliser.name = errors.fertiliser.name || (nameExists ? "Name already in use" : undefined);

                    const nutrientErrors = validateNutrients(values.fertiliser, values.fertiliser.type);
                    errors.fertiliser = { ...errors.fertiliser, ...nutrientErrors };
                    return errors;
                }}
                render={({ handleSubmit, submitting, values, errors }) => {
                    const { nutrients = {} } = values.fertiliser;
                    let heading = viewModel.fertiliser.type;
                    let instructionText = undefined;
                    switch (viewModel.fertiliser.type) {
                        case "Effluent":
                            heading = "Dairy factory effluent";
                            instructionText = (
                                <div>
                                    <p>Custom dairy factory effluent nutrient {isReadOnly ? "can be viewed here." : "make up can be created and edited here."} These can be used within an analysis. When used in an analysis a copy of the custom effluent at that time will be used, therefore any subsequent custom effluent changes here will not be reflected in the analysis.</p>
                                    <p>Values for each nutrient element within the nutrient data section is expressed as a percentage of wet weight (assuming 1 litre weighs 1kg).</p>
                                    <p>If you have nutrient loading information (amount of nutrients applied, kg/ha), then nutrient concentrations can be calculated as:</p>
                                    <p>concentration of nutrient(%) = loading of nutrient(kg/ha) * 100 / rate</p>
                                    <p>where rate is the rate of application (in litres per hectare) or 40,000 litres per hectare if not known.</p>
                                </div>
                            );
                            break;
                        case "Supplements":
                            heading = "Supplement";
                            instructionText = (
                                <div>
                                    <p>Custom supplements nutrient {isReadOnly ? "can be viewed here." : "make up can be created and edited here."} These can be used within an analysis. When used in an analysis a copy of the custom supplement at that time will be used, therefore any subsequent custom supplements changes here will not be reflected in the analysis.</p>
                                    <p>Values for each nutrient element within the nutrient data section is expressed as percentages</p>
                                </div>
                            );
                            break;
                        case "Lime":
                            instructionText = (
                                <div>
                                    <p>Custom lime nutrient {isReadOnly ? "can be viewed here." : "make up can be created and edited here."} These can be used within an analysis. When used in an analysis a copy of the custom lime at that time will be used, therefore any subsequent custom lime changes here will not be reflected in the analysis.</p>
                                    <p>Values for each nutrient element within the nutrient data section is expressed as percentages.</p>
                                </div>
                            );
                            break;
                        case "Irrigation":
                            instructionText = <div>Custom irrigation nutrient {isReadOnly ? "can be viewed here." : "make up can be created and edited here."} These can be used within an analysis. When used in an analysis a copy of the custom irrigation at that time will be used, therefore any subsequent custom irrigation changes here will not be reflected in the analysis.</div>;
                            break;
                        case "Fertiliser":
                            instructionText = (
                                <div>
                                    <p>Custom fertilser nutrient {isReadOnly ? "can be viewed here." : "make up can be created and edited here."} These can be used within an analysis. When used in an analysis a copy of the custom fertiliser at that time will be used, therefore any subsequent custom fertiliser changes here will not be reflected in the analysis.</p>
                                    <p>Values for each nutrient element within the nutrient data section is expressed as percentages. Additional information is required if values are entered for N, S, P or Mg.</p>
                                    <p>Note: If entering data for "N type", "Mixed" means that no one form is dominant, as can occur with some compound fertilisers.</p>
                                </div>
                            );
                            break;
                        default:
                            break;
                    }
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="Modal_wrapper">
                                <div className="Modal Modal--medium">
                                    <div className="Modal-head">
                                        <span className="Modal-head--left">{heading} Details</span>
                                        <span className="Modal-head--right">
                                            <ActionLink onClick={this.props.toggleQuickTips} className="Modal-close">
                                                <i className="icon icon-question" title={`${this.props.showQuickTips ? "Hide" : "Show"} quick tips`} />
                                            </ActionLink>
                                            <ActionLink onClick={this.props.cancel} id="irrigation_modal_close" className="Modal-close">
                                                <i className="icon icon-cross" title="Close" />
                                            </ActionLink>
                                        </span>
                                    </div>
                                    {instructionText && online && (
                                        <div className="Modal-subhead">
                                            <Alert type="info" text={instructionText} />
                                        </div>
                                    )}
                                    {!online && (
                                        <div className="Modal-subhead">
                                            <Alert type="error" text="Overseer is currently offline. Please try again later." />
                                        </div>
                                    )}
                                    <div className="Modal-body">
                                        <div className="Grid">
                                            <div className="Grid-cell u-lg-width2of3">
                                                <input type="hidden" id="fertiliser.id" value={values.fertiliser.id} />
                                                <Field name={`fertiliser.name`} label="Name" component={InputPack} requiredLabel={true} disabled={isReadOnly} />
                                            </div>
                                        </div>
                                        <Nutrients type={values.fertiliser.type} errors={errors} refData={this.props.refData} nutrients={nutrients} readonly={isReadOnly} />
                                        {this.props.isAdmin && (
                                            <div className="Grid-cell">
                                                <Field name={`fertiliser.isOrganisation`} label="Share with organisation" component={CheckboxPack} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="Modal-footer">
                                        <div className="ButtonBar">
                                            <div className="ButtonBar-left">
                                                <ActionLink id="cancel-button" onClick={() => this.props.cancel()} className="Button Button--secondary" type="button" name="cancel-button">
                                                    Cancel
                                                </ActionLink>
                                            </div>
                                            {!isReadOnly && (
                                                <div className="ButtonBar-right">
                                                    <button id="confirm-button" className="Button Button--primary" type="submit" disabled={submitting || !online} name="confirm-button">
                                                        Save
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            />
        );
    }
}

export default compose(
    withRouter,
    withAppContext,
    withState((state, ownProps) => {
        const { refData = {} } = state.app;
        const viewModel = {
            fertiliser: utils.clone(ownProps.product),
        };
        return {
            ...ownProps,
            online: state.app.online,
            refData: refData,
            viewModel: viewModel,
            isAdmin: state.auth.isAdmin,
        };
    })
)(ProductModal);
