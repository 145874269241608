import React from "react";
import { Form } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import { FORM_ERROR } from "final-form";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import FarmGroupDetails from "./FarmGroupDetails";
import { useModal, useRefData } from "common/hooks";
import { httpClient } from "common/httpClient";
import { useQueryClient, useMutation } from "@tanstack/react-query";

const FarmGroupDetailsModal = ({ farmGroup, close }) => {
    const refData = useRefData();
    const updateFarmGroup = useUpdateFarmGroupAsync();

    const submit = async (farmGroup) => {
        if (farmGroup.farmGroupVisibility === "Private") {
            farmGroup.showAggregatedReport = true;
            farmGroup.showBenchmarkReport = true;
            farmGroup.showMembers = true;
        }

        await updateFarmGroup(farmGroup)
            .then(close)
            .catch((ex) => ({ [FORM_ERROR]: ex.message }));
    };

    const validate = (farmGroup) => {
        const error = {};
        error.name = FormUtils.validators.required(farmGroup.name);
        error.name = error.name || FormUtils.validators.maxLength(50)(farmGroup.name);
        error.description = FormUtils.validators.maxLength(100)(farmGroup.description);
        error.farmGroupVisibility = FormUtils.validators.required(farmGroup.farmGroupVisibility);
        error.showAggregatedReport = !farmGroup.showBenchmarkReport && !farmGroup.showAggregatedReport ? "At lease one report type needs to be selected" : undefined;
        return error;
    };

    return (
        <Form initialValues={farmGroup} validate={validate} onSubmit={submit}>
            {({ handleSubmit, pristine, submitting, submitError, dirtySinceLastSubmit, values }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Edit farm group" close={close} submitting={submitting}>
                            <ModalBody>
                                <FarmGroupDetails refData={refData} submitError={submitError} dirtySinceLastSubmit={dirtySinceLastSubmit} values={values} />
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="btnFarmGroupDetails_cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="btnFarmGroupDetails_save" submit primary disabled={pristine || submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
};
export default FarmGroupDetailsModal;

export function useFarmGroupDetailsModal(farmGroup) {
    const [modal, openModal] = useModal(FarmGroupDetailsModal);

    const open = () => {
        const modalProps = {
            farmGroup,
        };
        openModal(modalProps);
    };

    return [modal, open];
}

export function useUpdateFarmGroupAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (farmGroup) => {
            try {
                await httpClient.put(`farmGroups/${farmGroup.id}`, farmGroup);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["reporting"] });
        },
    });

    return (farmTags) => mutation.mutateAsync(farmTags);
}
