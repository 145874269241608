import { useState, Fragment, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import { isEqualSoil } from "common/utils";
import Alert from "components/Alert";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import RadioGroupField from "components/FormFields/RadioGroupField";
import TextField from "components/FormFields/TextField";
import SelectField from "components/FormFields/SelectField";
import CheckboxField from "components/FormFields/CheckboxField";
import SmapSiblingSummary from "./SmapSiblingSummary";
import BlocksUsingSoils from "./BlocksUsingSoils";
import SoilProfile, { validateSoilProfileFields, resetSoilProfile } from "./SoilProfile";
import SoilProperties, { validateSoilPropertiesFields, resetSoilProperties } from "./SoilProperties";
import { getSmapSoilByRefAsync } from "./_actions";
import { isSoilModified, getIsTextureGroupRequired, getModifiedSoilProfileData, getModifiedSoilPropertiesData } from "./_utils";
import { useRefData, useModal } from "common/hooks";

export default function SoilModal({ soil, existingSoils = [], analysis, maxSoilCount, saveOnSubmit, onSubmit, close, fslSoilOrder, autoSelectSoilOrder, featureTracker }) {
    const refData = useRefData();
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState();
    const [defaultProperties, setDefaultProperties] = useState();
    const soilOrderOptions = getSoilOrderOptions(refData);
    const params = useParams();
    const fslSoilGroup = fslSoilOrder && refData.soilGroups.find((g) => g.children && g.children.find((c) => c.value === fslSoilOrder));
    const existingFslSoil = existingSoils.find((s) => s.soilOrder === fslSoilOrder && s.sibling === undefined);

    useEffect(() => {
        featureTracker && featureTracker.track("Modal: Open");
    }, [featureTracker]);

    const handleSoilOrderChange = (form) => async (soilOrder) => {
        featureTracker && featureTracker.track("Modal: Soil order changed");
        const soilGroup = refData.soilGroups.find((g) => g.children && g.children.find((c) => c.value === soilOrder));
        if (soilGroup) {
            form.change("soilGroup", soilGroup.value);
            setDefaultProperties(soilGroup.children.find((c) => c.value === soilOrder));
        }
    };

    const handleSoilGroupChange = (form, soilOrder) => async (newSoilGroup) => {
        featureTracker && featureTracker.track("Modal: Soil group changed");
        const soilGroup = refData.soilGroups.find((g) => g.value === newSoilGroup);
        if (soilGroup) {
            if (!soilGroup.children.some((c) => c.value === soilOrder)) {
                form.change("soilOrder", undefined);
                setDefaultProperties(soilGroup);
            }
        }
    };

    const handleClose = () => {
        featureTracker && featureTracker.track("Modal: Cancel Modal");
        close();
    };

    const searchSmap = (form, formValues) => async (e) => {
        e.preventDefault();
        setFetching(true);
        await dispatch(getSmapSoilByRefAsync.send(formValues.smapReference))
            .then((response) => {
                // Clear any previous search results.
                delete formValues.id;
                delete formValues.sibling;
                delete formValues.soilOrder;
                delete formValues.soilGroup;
                delete formValues.title;
                delete formValues.subTitle;
                delete formValues.error;

                // Return the soil or an error if there is no soil found.
                const soil = response || { error: "S-Map reference not found" };
                form.initialize({ ...formValues, ...soil });
            })
            .finally(() => {
                setFetching(false);
            });
    };

    const validate = (formValues) => {
        const validation = {};

        if (formValues.isNew) {
            if (formValues.addingBy === "Existing") {
                const hasSelectedExistingSoils = formValues.existingSoilIds && existingSoils.some((s) => formValues.existingSoilIds[s.id]);
                if (!hasSelectedExistingSoils) validation.existingSoilIsRequired = "Required";
            }

            if (formValues.addingBy === "Smap") {
                validation.smapReference = FormUtils.validators.required(formValues.smapReference);
                validation.smapReference = validation.smapReference || formValues.error;
            }

            if (formValues.addingBy === "GroupOrder") {
                validation.soilGroup = FormUtils.validators.required(formValues.soilGroup);
                if (validation.soilGroup) {
                    validation.soilOrder = FormUtils.validators.required(formValues.soilOrder);
                }
            }
        }

        const soilProfileValidation = validateSoilProfileFields(formValues);

        const soilPropertiesValidation = validateSoilPropertiesFields(formValues);

        const validationResults = { ...validation, ...soilProfileValidation, ...soilPropertiesValidation };
        return validationResults;
    };

    const submitAsync = async (formValues) => {
        try {
            featureTracker && featureTracker.track("Modal: Done");
            if (!getIsTextureGroupRequired(formValues)) {
                delete formValues.textureGroup;
            }

            if (!formValues.parentMaterial || formValues.parentMaterial === "Undefined") {
                delete formValues.nonStandardLayerDepth;
            }

            if (!formValues.id) {
                formValues.id = uuidv4();
            }

            if (!formValues.subTitle) {
                if (formValues.sibling && formValues.sibling.smapReference) {
                    formValues.subTitle = formValues.sibling.smapReference;
                } else if (formValues.name) {
                    formValues.subTitle = formValues.name;
                } else if (formValues.soilOrder) {
                    formValues.subTitle = utils.valueToText(refData.soilOrders, formValues.soilOrder);
                }
            }

            if (formValues.addingBy === "Existing") {
                const soils = existingSoils.filter((s) => formValues.existingSoilIds[s.id]);
                await onSubmit(soils);
            } else {
                const existingSoil = existingSoils.find((s) => isEqualSoil(s, formValues));
                if (existingSoil) {
                    formValues = existingSoil;
                }
                delete formValues.addingBy;
                await onSubmit(formValues);
            }
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }

    const setFslSoil = (form, values) => {
        if (values.addingBy === "GroupOrder") {
            form.change("soilOrder", fslSoilOrder);
            form.change("soilGroup", fslSoilGroup.value);
        } else {
            const { existingSoilIds = [] } = values;
            for (const id of Object.keys(existingSoilIds)) {
                form.change(`existingSoilIds.${id}`, false);
            }
            form.change(`existingSoilIds.${existingFslSoil.id}`, true);
        }
    };

    const existingDefault = soil.isNew && existingSoils.length > 0;
    const existingSoilIds = useMemo(() => {
        let result = {};
        if (existingDefault && fslSoilOrder && autoSelectSoilOrder) {
            for (const existingSoil of existingSoils) {
                if (existingSoil.soilOrder === fslSoilOrder && existingSoil.sibling === undefined) {
                    result[existingSoil.id] = true;
                    break;
                }
            }
        }
        return result;
    }, [autoSelectSoilOrder, existingDefault, existingSoils, fslSoilOrder]);

    const soilOrder = soil.isNew && fslSoilOrder && Object.keys(existingSoilIds).length === 0 && autoSelectSoilOrder ? fslSoilOrder : soil.soilOrder;
    const soilGroup = soil.isNew && fslSoilGroup && Object.keys(existingSoilIds).length === 0 && autoSelectSoilOrder ? fslSoilGroup.value : soil.soilGroup;
    const fslDefaults = { soilOrder, soilGroup, existingSoilIds };
    const addingBy = useMemo(() => (existingDefault && (!fslSoilOrder || Object.keys(existingSoilIds).length > 0 || !autoSelectSoilOrder) ? "Existing" : "GroupOrder"), [autoSelectSoilOrder, existingDefault, existingSoilIds, fslSoilOrder]);

    return (
        <>
            <Form keepDirtyOnReinitialize={true} initialValues={{ ...soil, addingBy, ...fslDefaults }} validate={validate} onSubmit={submitAsync}>
                {({ form, values, handleSubmit, submitting, submitError }) => {
                    const formState = form.getState();
                    const isAddingNewSoil = values.isNew;
                    const isAddingByExistingSoil = isAddingNewSoil && values.addingBy === "Existing" && existingSoils.length > 0;
                    const showSmapSoilDetails = isAddingNewSoil ? values.addingBy === "Smap" : values.sibling !== undefined;
                    const isGroupOrderSoil = isAddingNewSoil ? values.addingBy === "GroupOrder" : !values.sibling;
                    const hasSoil = values.soilOrder || values.soilGroup;

                    const modifiedWarning = isSoilModified(values) && "This farm soil has been modified from its defaults";
                    const showExistingSoilsRequiredError = Object.keys(formState.touched).some((key) => key.startsWith("existingSoilIds") && formState.touched[key] === true) && formState.errors.existingSoilIsRequired;
                    const soilsSelected = values.existingSoilIds && existingSoils.filter((s) => values.existingSoilIds[s.id]);
                    const isFslSoilSelected = (values.addingBy === "GroupOrder" && fslSoilOrder && values.soilOrder === fslSoilOrder) || (values.addingBy === "Existing" && soilsSelected && soilsSelected.length === 1 && soilsSelected.sibling === undefined && existingFslSoil && Object.keys(values.existingSoilIds).includes(existingFslSoil.id) && values.existingSoilIds[existingFslSoil.id]);

                    const reachedMax = soilsSelected && soilsSelected.length >= maxSoilCount;
                    const showAutoSelectedSoilOrder = isFslSoilSelected && ["GroupOrder", "Existing"].includes(values.addingBy);
                    const showAutoSelectOrderButton = (!showAutoSelectedSoilOrder && ["GroupOrder", "Existing"].includes(values.addingBy) && fslSoilOrder && values.addingBy === "GroupOrder") || existingSoils.find((s) => s.soilOrder === fslSoilOrder && s.sibling === undefined);

                    return (
                        <form onSubmit={handleSubmit}>
                            <Modal title={`${values.isNew ? "Add" : "Edit"} ${params.budgetId ? "soil to block" : "farm soil"}`} close={close} submitting={fetching || submitting} wide>
                                <ModalBody warning={modifiedWarning} error={submitError}>
                                    {isAddingNewSoil && (
                                        <Grid title="Choose existing soil or create a new soil">
                                            <GridCell>
                                                <Field name="addingBy" options={getAddingByOptions(existingSoils)} onChange={resetForm(form, fslDefaults, featureTracker)} inline type="radio" component={RadioGroupField} />
                                            </GridCell>
                                        </Grid>
                                    )}

                                    {showAutoSelectedSoilOrder && (
                                        <Grid>
                                            <GridCell>
                                                <Alert type="info" className="u-mb-0 u-mt-md" text="Automatically selected soil order." />
                                            </GridCell>
                                        </Grid>
                                    )}

                                    {showAutoSelectOrderButton && (
                                        <Grid>
                                            <GridCell className="u-pt-sm u-pb-sm">
                                                <Button onClick={() => setFslSoil(form, values)} className="IconLink--arrow-plus Button--secondary u-mt-md">
                                                    Automatically select soil order
                                                </Button>
                                            </GridCell>
                                        </Grid>
                                    )}

                                    {isAddingByExistingSoil && (
                                        <>
                                            <div className="Field">
                                                <Alert type="info" className="u-mb-0" text={`A block can have up to 3 soils. You are able to add up to ${maxSoilCount} soils.`} />
                                            </div>
                                            {showExistingSoilsRequiredError && (
                                                <div className="Field has-error u-mt-xs">
                                                    <small className="Field-error u-mt-0">{formState.errors.existingSoilIsRequired}</small>
                                                </div>
                                            )}
                                            <div className="Table u-mt-xs">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="th--shrink"></th>
                                                            <th data-width="20">S-Map ref/Name</th>
                                                            <th>Order</th>
                                                            <th>Group</th>
                                                            <th>Description</th>
                                                            <th>Soil profile</th>
                                                            <th>Soil properties</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {existingSoils.map((existingSoil) => {
                                                            const isChecked = values.existingSoilIds && values.existingSoilIds[existingSoil.id];
                                                            const soilLabel = existingSoil.name || existingSoil.subTitle || "-";
                                                            const isTextureGroupRequired = getIsTextureGroupRequired(existingSoil);
                                                            const customSoilProfileData = getModifiedSoilProfileData(existingSoil, isTextureGroupRequired, refData);
                                                            const customSoilPropertiesData = getModifiedSoilPropertiesData(existingSoil, refData);
                                                            const isSmapSoil = existingSoil.sibling !== undefined;
                                                            return (
                                                                <tr key={existingSoil.id}>
                                                                    <td valign="top">
                                                                        <Field name={"existingSoilIds." + existingSoil.id} testingId={`${soilLabel}-${existingSoil.id}`} type="checkbox" disabled={!isChecked && reachedMax} component={CheckboxField} />
                                                                    </td>
                                                                    <td valign="top">
                                                                        <div className="u-flexSplit">
                                                                            <span>{soilLabel}</span>
                                                                            {isSoilModified(existingSoil) && (
                                                                                <span className="IconLink--soil u-textWarning u-ml-md" title="Modified soil properties">
                                                                                    Modified
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td valign="top">{utils.valueToText(refData.soilOrders, existingSoil.soilOrder) || "-"}</td>
                                                                    <td valign="top">{utils.valueToText(refData.soilGroups, existingSoil.soilGroup)}</td>
                                                                    <td valign="top">{existingSoil.sibling ? existingSoil.sibling.description : existingSoil.description || "-"}</td>
                                                                    <td valign="top">
                                                                        {Object.keys(customSoilProfileData).length > 0
                                                                            ? Object.keys(customSoilProfileData).map((key, i) => {
                                                                                  return (
                                                                                      <Fragment key={key}>
                                                                                          <div>
                                                                                              <b>{customSoilProfileData[key].title}</b>
                                                                                          </div>
                                                                                          {customSoilProfileData[key].items.map((item) => (
                                                                                              <div key={item}>{item}</div>
                                                                                          ))}
                                                                                      </Fragment>
                                                                                  );
                                                                              })
                                                                            : isSmapSoil
                                                                            ? "Using S-Map defaults"
                                                                            : "Using system defaults"}
                                                                    </td>
                                                                    <td valign="top">
                                                                        {Object.keys(customSoilPropertiesData).length > 0
                                                                            ? Object.keys(customSoilPropertiesData).map((key, i) => {
                                                                                  return (
                                                                                      <Fragment key={key}>
                                                                                          <div>
                                                                                              <b>{customSoilPropertiesData[key].title}</b>
                                                                                          </div>
                                                                                          {customSoilPropertiesData[key].items.map((item) => (
                                                                                              <div key={item}>{item}</div>
                                                                                          ))}
                                                                                      </Fragment>
                                                                                  );
                                                                              })
                                                                            : isSmapSoil
                                                                            ? "Using S-Map defaults"
                                                                            : "Using system defaults"}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {showExistingSoilsRequiredError && (
                                                <div className="Field has-error u-mt-0">
                                                    <small className="Field-error u-mt-xs">{formState.errors.existingSoilIsRequired}</small>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {!isAddingByExistingSoil && (
                                        <>
                                            <Grid>
                                                {showSmapSoilDetails && isAddingNewSoil && (
                                                    <>
                                                        <GridCell className="u-width1of3">
                                                            <Field
                                                                name="smapReference"
                                                                label="S-Map reference"
                                                                required
                                                                placeholder="Enter the S-Map reference"
                                                                clearable
                                                                onClear={resetForm(form, undefined, featureTracker)}
                                                                button={
                                                                    <Button id="search-smap" className="IconLink--search Button--secondary u-mt-md" disabled={!values.smapReference || values.smapReference.length === 0} type="submit" onClick={searchSmap(form, values)}>
                                                                        Search S-Map
                                                                    </Button>
                                                                }
                                                                component={TextField}
                                                            />
                                                        </GridCell>
                                                        <GridCell className="u-width2of3"></GridCell>
                                                        <GridCell className="u-mt-md">
                                                            <div className="small">S-Map soil data reproduced with the permission of Manaaki Whenua - Landcare Research</div>
                                                        </GridCell>
                                                    </>
                                                )}
                                                {showSmapSoilDetails && hasSoil && (
                                                    <GridCell className="u-width1of2">
                                                        <SmapSiblingSummary soil={values} refData={refData} />
                                                    </GridCell>
                                                )}
                                                {isGroupOrderSoil && (
                                                    <GridCell className="u-width1of2">
                                                        <Grid>
                                                            <GridCell className="u-width1of2">
                                                                <Field name="soilOrder" label="Soil order" placeholder={values.soilGroup ? "Unknown" : "Select a soil order"} required options={soilOrderOptions} onChange={handleSoilOrderChange(form)} component={SelectField} />
                                                            </GridCell>
                                                            <GridCell className="u-width1of2">
                                                                <Field name="soilGroup" label="Soil group" placeholder="Select a soil group" required options={refData.soilGroups.filter((g) => g.value !== "Undefined")} onChange={handleSoilGroupChange(form, values.soilOrder)} component={SelectField} />
                                                            </GridCell>
                                                        </Grid>
                                                        <Field name="name" label="Name" placeholder="Enter a soil name (optional)" maxLength="20" component={TextField} />
                                                    </GridCell>
                                                )}
                                                {analysis && !isAddingNewSoil && (
                                                    <GridCell className="u-width1of2">
                                                        <BlocksUsingSoils analysis={analysis} soil={values} />
                                                    </GridCell>
                                                )}
                                            </Grid>
                                            {hasSoil && (
                                                <>
                                                    <SoilProfile soil={values} form={form} />
                                                    <SoilProperties soil={values} defaultProperties={defaultProperties} form={form} />
                                                </>
                                            )}
                                        </>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <ModalFooterLeft>
                                        <Button id="cancel" onClick={() => handleClose()} secondary disabled={submitting}>
                                            Cancel
                                        </Button>
                                    </ModalFooterLeft>
                                    <ModalFooterRight>
                                        <Button id="save-soil" submit primary={saveOnSubmit} waiting={submitting}>
                                            {saveOnSubmit ? "Save" : "Done"}
                                        </Button>
                                    </ModalFooterRight>
                                </ModalFooter>
                            </Modal>
                        </form>
                    );
                }}
            </Form>
        </>
    );
}

export function useSoilModal() {
    const [modal, openModal] = useModal(SoilModal);

    const open = (soil, soilModalOptions) => {
        const modalProps = {
            soil,
            ...soilModalOptions,
        };
        openModal(modalProps);
    };

    return [modal, open];
}

const resetForm = (form, fslDefaults, featureTracker) => async (value) => {
    const soilOrder = value === "GroupOrder" && fslDefaults ? fslDefaults.soilOrder : undefined;
    const soilGroup = value === "GroupOrder" && fslDefaults ? fslDefaults.soilGroup : undefined;
    const existingSoilIds = value === "Existing" && fslDefaults ? fslDefaults.existingSoilIds : undefined;
    featureTracker && featureTracker.track(value);

    form.batch(() => {
        form.change("existingSoilIds", existingSoilIds);
        form.change("smapReference", undefined);
        form.change("sibling", undefined);
        form.change("soilOrder", soilOrder);
        form.change("soilGroup", soilGroup);
        form.change("name", undefined);
        form.change("title", undefined);
        form.change("subTitle", undefined);
        form.change("error", undefined);
        form.change("addingBy", value);
        resetSoilProfile(form);
        resetSoilProperties(form);
    });
};

const getAddingByOptions = (soils) => {
    const options = [
        { value: "GroupOrder", text: "New order/group" },
        { value: "Smap", text: "New S-Map" },
    ];

    if (soils && soils.length > 0) {
        options.unshift({ value: "Existing", text: "Existing farm soil" });
    }

    return options;
};

const getSoilOrderOptions = (refData) => {
    if (!refData) return [];

    return (refData.soilGroups || [])
        .filter((x) => x.children.length > 0)
        .map((g, i) => g.children.map((c) => ({ value: c.value, text: c.text, groupIndex: i, groupLabel: g.text })))
        .reduce((a, b) => [...a, ...b], []);
};
