import { Component } from "react";
import { withActions } from "containers/HOC";
import { Field, change, untouch, arrayRemoveAll, arrayPush } from "redux-form";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import Alert from "components/Alert";
import CheckboxPack from "components/CheckboxPack";
import DairyGoatLeftOversDetails from "./DairyGoatLeftOversDetails";
import * as _utils from "../_utils";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
function DairyGoatLeftOvers({ enterprise, analysis, ...rest }) {
    return <DairyGoatLeftOversClassComponent enterprise={enterprise} analysis={analysis} {...rest} />
}

const FORM_NAME = "enterprise-details-form";

class DairyGoatLeftOversClassComponent extends Component {
    state = {
        showSpecifyPastureBlockLeftOvers: _utils.hasNonDairyGoatEnterprises(this.props.analysis),
        showPastureBlockLeftOvers: this.props.enterprise.dairyGoatLeftOvers && this.props.enterprise.dairyGoatLeftOvers.pastureBlockLeftOvers ? true : false,
        hasPastureBlockLeftOvers: this.props.enterprise.dairyGoatLeftOvers && this.props.enterprise.dairyGoatLeftOvers.pastureBlockLeftOvers ? true : false,

        showSpecifyWinteringPadLeftOvers: _utils.hasDairyGoatWinteringPad(this.props.analysis),
        showWinteringPadLeftOvers: this.props.enterprise.dairyGoatLeftOvers && this.props.enterprise.dairyGoatLeftOvers.winteringPadLeftOvers ? true : false,
        hasWinteringPadLeftOvers: this.props.enterprise.dairyGoatLeftOvers && this.props.enterprise.dairyGoatLeftOvers.winteringPadLeftOvers ? true : false,
    };

    render() {
        const { enterprise, analysis, change, untouch, arrayRemoveAll, arrayPush } = this.props;

        const _onSpecifyChanged = (e, checked) => {
            if (e.target.name === "specifyPastureBlockLeftOvers") {
                if (checked) {
                    // Reset pasture block left over defaults.
                    change(FORM_NAME, "dairyGoatLeftOvers.pastureBlockLeftOvers.rate", 17);
                    arrayRemoveAll(FORM_NAME, `dairyGoatLeftOvers.pastureBlockLeftOvers.enterprisePercentages`);
                    const nonDairyGoatEnterprisesOnPastureBlocks = _utils.getNonDairyGoatEnterpriesesOnPastureBlocks(analysis.enterprises, analysis.blocks);
                    nonDairyGoatEnterprisesOnPastureBlocks.forEach((e) => arrayPush(FORM_NAME, "dairyGoatLeftOvers.pastureBlockLeftOvers.enterprisePercentages", { enterpriseId: e.id, percentage: nonDairyGoatEnterprisesOnPastureBlocks.length === 1 ? 100 : 0 }));
                } else {
                    change(FORM_NAME, "dairyGoatLeftOvers.pastureBlockLeftOvers", null);
                }
                this.setState({ showPastureBlockLeftOvers: !this.state.showPastureBlockLeftOvers });
            } else {
                this.setState({ showWinteringPadLeftOvers: !this.state.showWinteringPadLeftOvers });
                if (checked) {
                    // Reset wintering pad left over defaults.
                    change(FORM_NAME, "dairyGoatLeftOvers.winteringPadLeftOvers.rate", 17);
                    change(FORM_NAME, "dairyGoatLeftOvers.winteringPadLeftOvers.fate", null);
                    untouch(FORM_NAME, "dairyGoatLeftOvers.winteringPadLeftOvers.fate");
                    arrayRemoveAll(FORM_NAME, `dairyGoatLeftOvers.winteringPadLeftOvers.enterprisePercentages`);
                } else {
                    change(FORM_NAME, "dairyGoatLeftOvers.winteringPadLeftOvers", null);
                }
            }
        };

        return (
            <Tile title="Left-over feeding" tertiary>
                <Alert type="info" text="Left-overs are uneaten supplements fed to goats on pastoral blocks or wintering pads. By default, 17% of supplements fed become left-overs and if from the wintering pad, become part of the solid effluent management system. If you wish to change how left-over supplements are handled, do so here." />
                <TileBody>
                    {this.state.showSpecifyPastureBlockLeftOvers && (
                        <div className="Grid-cell">
                            <Field name="specifyPastureBlockLeftOvers" type="checkbox" component={CheckboxPack} label="Specify animals that consume left-over supplements on pastoral blocks" checked={this.state.showPastureBlockLeftOvers} vertical={true} onChange={_onSpecifyChanged} />
                            {this.state.showPastureBlockLeftOvers && <DairyGoatLeftOversDetails name="pastureBlockLeftOvers" isPastureBlockLeftOvers={true} enterprise={enterprise} analysis={analysis} />}
                        </div>
                    )}
                    {this.state.showSpecifyWinteringPadLeftOvers && (
                        <div className="Grid-cell u-mt-md">
                            <Field name="specifyWinteringPadLeftOvers" type="checkbox" component={CheckboxPack} label="Specify fate of left-over supplements from wintering pad/barn or animal shelter" checked={this.state.showWinteringPadLeftOvers} vertical={true} onChange={_onSpecifyChanged} />
                            {this.state.showWinteringPadLeftOvers && <DairyGoatLeftOversDetails name="winteringPadLeftOvers" isWinteringPadLeftOvers={true} enterprise={enterprise} analysis={analysis} />}
                        </div>
                    )}
                </TileBody>
            </Tile>
        );
    }
}

export default withActions({ change, untouch, arrayRemoveAll, arrayPush })(DairyGoatLeftOvers);
