import { useState, useMemo } from "react";
import * as utils from "common/utils";
import * as domain from "common/domain";
import { Panel, PanelBody } from "components/Panel";
import Watermark from "components/Watermark";
import { RadioButtonOptionSelector } from "components/RadioButtonOptionSelector";
import { useAnalysisResults } from "containers/BudgetHome";

export default function EnterpriseMonthResults({ analysis }) {
    const { data } = useData(analysis);
    const [selectedValue, setSelectedValue] = useState(OPTIONS[0].value);

    const selectedData = useMemo(() => {
        return data?.[selectedValue];
    }, [data, selectedValue])

    const showPercentagesFootnote = selectedValue === OPTIONS[0].value;

    const rootKey = `requirements_${selectedValue}`;

    if (!selectedData) {
        return null;
    }

    return (
        <Panel title="Monthly requirements and intake" skyBlue>
            <PanelBody>
                <div className="Table">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="20">Enterprise</th>
                                <th colSpan="13">
                                    <RadioButtonOptionSelector name="requirements_options" options={OPTIONS} value={selectedValue} onChange={setSelectedValue} />
                                </th>
                            </tr>
                            <tr>
                                <th className="u-borderTop-none">&nbsp;</th>
                                {domain.farmYear.map((month) => (
                                    <th key={month} data-width="5" className="numeric">
                                        {utils.month.format(month)}
                                    </th>
                                ))}
                                <th data-width="5" className="numeric">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedData?.byEnterprise?.map((enterprise) => {
                                const enterpriseKey = `${rootKey}_${enterprise.name}`;
                                return (
                                    <tr key={enterpriseKey}>
                                        <td>
                                            {enterprise.name}
                                        </td>
                                        {enterprise.byMonth.map((m) => {
                                            const monthKey = `${enterpriseKey}_${m.month}`;
                                            const isOverfeedError = m.percentageFromFeed?.value > 120;
                                            return (
                                                <td key={monthKey} className={`numeric ${isOverfeedError ? "Alert--error" : undefined}`}>
                                                    <div id={monthKey}>{utils.numeric.format(m.amount.value)}</div>
                                                    {m.percentageFromFeed?.value > 0 && (
                                                        <div className={`small ${isOverfeedError ? "u-textError" : undefined}`}>
                                                            <span id={`${monthKey}_percentageFromFeed`}>{utils.numeric.format(m.percentageFromFeed.value, 1)}</span>
                                                            <span id={`${monthKey}_percentageFromFeed_uom`}>%</span>
                                                            <sup>*</sup>
                                                        </div>
                                                    )}
                                                </td>
                                            )
                                        })}
                                        <td id={`${enterpriseKey}_total`} className="numeric total">
                                            {utils.numeric.format(enterprise.total.value)}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            {selectedData &&
                                <tr className="totals">
                                    <th>Totals</th>
                                    {selectedData.byMonth.map((m) => {
                                        const monthKey = `${rootKey}_totals_${m.month}`;
                                        return (
                                            <th key={monthKey} id={monthKey} className="numeric total">
                                                {utils.numeric.format(m.amount.value)}
                                            </th>
                                        )
                                    })}
                                    <th id={`${rootKey}_total`} className="numeric total">
                                        {utils.numeric.format(selectedData.total.value)}
                                    </th>
                                </tr>
                            }
                        </tfoot>
                    </table>
                </div>
                {showPercentagesFootnote && <sub>* Percentage of ME requirements from all available sources. Anything over 120% of ME requirements is considered an overfeed error.</sub>}
            </PanelBody>
        </Panel>
    )
}

function useData(analysis) {
    const { data: analysisResults } = useAnalysisResults(analysis);
    if (analysisResults?.animals) {
        const data = {};
        data[OPTIONS[0].value] = analysisResults?.animals?.metabolicEnergyRequirements;
        data[OPTIONS[1].value] = analysisResults?.animals?.dryMatterIntake;
        data[OPTIONS[2].value] = analysisResults?.animals?.excretaNitrogen;
        return { data };
    }
}

const OPTIONS = [
    { value: "meRequirements", text: "Metabolic energy requirements", uom: "MJ ME" },
    { value: "dmIntake", text: "Dry matter intake", uom: "kg DM" },
    { value: "excretaN", text: "Excreta N", uom: "kg N" },
];