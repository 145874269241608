import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import * as FormUtils from "common/FormUtils";
import { useModal, useRefData } from "common/hooks";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import { CheckboxField, FieldGroup, RadioGroupField, SelectField, WholeNumberField } from "components/FormFields";
import * as animalUtils from "../_utils";

function HealthSupplementsModal({ healthSupplements, onSubmit, close }) {
    const refData = useRefData();
    const saltBlockTypeOptions = animalUtils.getSaltBlockTypeOptions(refData);

    const handleDrenchTypeChanged = (form, drenchType) => {
        if (drenchType === "None") {
            form.change("drenching.mgRate_gCowDay", null);
            form.change("drenching.mgNumberOfWeeksUsed", null);
        }
    }

    const handleSaltChanged = (form, checked) => {
        if (!checked) {
            form.change("drenching.saltRate_gCowDay", null);
            form.change("drenching.saltNumberOfWeeksUsed", null);
        }
    }

    const handleLimeFlourChanged = (form, checked) => {
        if (!checked) {
            form.change("drenching.limeFlourRate_gCowDay", null);
            form.change("drenching.limeFlourNumberOfWeeksUsed", null);
        }
    }

    const addSaltBlockApplication = (form) => {
        form.mutators.push("saltBlocks", {});
    }

    const deleteSaltBlockApplication = (form, index) => {
        form.mutators.remove("saltBlocks", index);
    }

    const validateSaltBlockApplications = (saltBlockApplications) => {
        const validations = [];

        saltBlockApplications?.forEach((application) => {
            const validation = {};

            validation.type = FormUtils.validators.required(application.type);
            validation.numberPerYear = FormUtils.validators.required(application.numberPerYear);
            validation.numberPerYear = validation.numberPerYear || FormUtils.validators.range(1, 10000)(application.numberPerYear);

            validations.push(validation);
        })

        return validations;
    }

    const validate = (formValues) => {
        const validation = {};

        if (showTotalsFields(formValues)) {
            validation.totals = {};
            validation.totals.mgOxide_kgYr = FormUtils.validators.range(0, 99999)(formValues.totals?.mgOxide_kgYr);
            validation.totals.mgSulphate_kgYr = FormUtils.validators.range(0, 99999)(formValues.totals?.mgSulphate_kgYr);
            validation.totals.mgChloride_kgYr = FormUtils.validators.range(0, 99999)(formValues.totals?.mgChloride_kgYr);
            validation.totals.salt_kgYr = FormUtils.validators.range(0, 99999)(formValues.totals?.salt_kgYr);
            validation.totals.limeFlour_kgYr = FormUtils.validators.range(0, 99999)(formValues.totals?.limeFlour_kgYr);

            validation.nitrogen_kgYr = FormUtils.validators.range(0, 99999)(formValues.nitrogen_kgYr);
            validation.phosphate_kgYr = FormUtils.validators.range(0, 99999)(formValues.phosphate_kgYr);
        }

        if (showDrenchingFields(formValues)) {
            validation.drenching = {};

            if (enableMagnesiumFields(formValues)) {
                validation.drenching.mgRate_gCowDay = FormUtils.validators.required(formValues.drenching?.mgRate_gCowDay);
                validation.drenching.mgRate_gCowDay = validation.drenching.mgRate_gCowDay || FormUtils.validators.range(1, 99999)(formValues.drenching?.mgRate_gCowDay);
                validation.drenching.mgNumberOfWeeksUsed = FormUtils.validators.required(formValues.drenching?.mgNumberOfWeeksUsed);
                validation.drenching.mgNumberOfWeeksUsed = validation.drenching.mgNumberOfWeeksUsed || FormUtils.validators.range(1, 52)(formValues.drenching?.mgNumberOfWeeksUsed);
            }

            if (enableSaltFields(formValues)) {
                validation.drenching.saltRate_gCowDay = FormUtils.validators.required(formValues.drenching?.saltRate_gCowDay);
                validation.drenching.saltRate_gCowDay = validation.drenching.saltRate_gCowDay || FormUtils.validators.range(1, 99999)(formValues.drenching?.saltRate_gCowDay);
                validation.drenching.saltNumberOfWeeksUsed = FormUtils.validators.required(formValues.drenching?.saltNumberOfWeeksUsed);
                validation.drenching.saltNumberOfWeeksUsed = validation.drenching.saltNumberOfWeeksUsed || FormUtils.validators.range(1, 52)(formValues.drenching?.saltNumberOfWeeksUsed);
            }

            if (enableLimeFlourFields(formValues)) {
                validation.drenching.limeFlourRate_gCowDay = FormUtils.validators.required(formValues.drenching?.limeFlourRate_gCowDay);
                validation.drenching.limeFlourRate_gCowDay = validation.drenching.limeFlourRate_gCowDay || FormUtils.validators.range(1, 99999)(formValues.drenching?.limeFlourRate_gCowDay);
                validation.drenching.limeFlourNumberOfWeeksUsed = FormUtils.validators.required(formValues.drenching?.limeFlourNumberOfWeeksUsed);
                validation.drenching.limeFlourNumberOfWeeksUsed = validation.drenching.limeFlourNumberOfWeeksUsed || FormUtils.validators.range(1, 52)(formValues.drenching?.limeFlourNumberOfWeeksUsed);
            }

            validation.drenching.mgDusting_kghaYr = FormUtils.validators.range(0, 99999)(formValues.drenching?.mgDusting_kghaYr);
            validation.drenching.saltDusting_kghaYr = FormUtils.validators.range(0, 99999)(formValues.drenching?.saltDusting_kghaYr);
            validation.drenching.limeFlourDusting_kghaYr = FormUtils.validators.range(0, 99999)(formValues.drenching?.limeFlourDusting_kghaYr);
        }

        return validation;
    }

    const submitAsync = async (formValues) => {
        if (onSubmit) {
            if (showTotalsFields(formValues)) {
                delete formValues.drenching;
            } else if (showDrenchingFields(formValues)) {
                delete formValues.totals;
                delete formValues.nitrogen_kgYr;
                delete formValues.phosphate_kgYr;
            }
            await onSubmit(formValues);
        }
        close();
    }

    return (
        <Form initialValues={healthSupplements} mutators={{ ...arrayMutators }} validate={validate} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting }) => {

                const showSupplementsType = showSupplementsTypeField(values);
                const showTotals = showTotalsFields(values);
                const showDrenching = showDrenchingFields(values);
                const enableMagnesium = enableMagnesiumFields(values);
                const enableSalt = enableSaltFields(values);
                const enableLimeFlour = enableLimeFlourFields(values);
                const drenchingRateRequired = enableMagnesium || enableSalt || enableLimeFlour;
                const numberOfWeeksRequired = enableMagnesium || enableSalt || enableLimeFlour;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Health supplements" submitting={submitting} close={close} fluid>
                            <ModalBody>
                                <Grid title="Magnesium, salt and lime flour">
                                    <GridCell>
                                        {showSupplementsType
                                            ? <>
                                                <p>Enter the total amount per year or describe the dusting and drenching regime</p>
                                                <Field name="supplementsType" options={SUPPLEMENTS_TYPE_OPTIONS} component={RadioGroupField} inline />
                                            </>
                                            : <p>Enter the total amount applied per year when drenching, pasture dusting or adding to supplements</p>
                                        }
                                    </GridCell>
                                </Grid>
                                {showTotals && (<>
                                    <Grid title="Total annual amounts">
                                        <GridCell>
                                            <FieldGroup className="u-mt-md">
                                                <Field name="totals.mgOxide_kgYr" label="Magnesium oxide" placeholder="0" uom="kg/yr" dataWidth="33" component={WholeNumberField} />
                                                <Field name="totals.mgSulphate_kgYr" label="Magnesium sulphate" placeholder="0" uom="kg/yr" dataWidth="33" component={WholeNumberField} />
                                                <Field name="totals.mgChloride_kgYr" label="Magnesium chloride" placeholder="0" uom="kg/yr" dataWidth="33" component={WholeNumberField} />
                                            </FieldGroup>
                                            <FieldGroup>
                                                <Field name="totals.salt_kgYr" label="Salt" placeholder="0" uom="kg/yr" dataWidth="33" component={WholeNumberField} />
                                                <Field name="totals.limeFlour_kgYr" label="Lime flour" placeholder="0" uom="kg/yr" dataWidth="33" component={WholeNumberField} />
                                            </FieldGroup>
                                        </GridCell>
                                    </Grid>
                                </>)}
                                {showDrenching && (<>
                                    <Grid title="Drenching regime">
                                        <GridCell>
                                            <FieldGroup className="u-mt-md">
                                                <Field name="drenching.mgMaterial" label="Drench type" options={refData.magnesiumSources} onChange={(drenchType) => handleDrenchTypeChanged(form, drenchType)} dataWidth="33" component={SelectField} />
                                                <Field name="drenching.mgRate_gCowDay" label="Drenching rate" placeholder={enableMagnesium ? "0" : "-"} uom="g/cow/day" dataWidth="33" disabled={!enableMagnesium} required={drenchingRateRequired} component={WholeNumberField} />
                                                <Field name="drenching.mgNumberOfWeeksUsed" label="Number of weeks" placeholder={enableMagnesium ? "0" : "-"} uom="weeks" dataWidth="33" disabled={!enableMagnesium} required={numberOfWeeksRequired} component={WholeNumberField} />
                                            </FieldGroup>
                                            <FieldGroup>
                                                <Field name="drenching.saltMaterial" label="Salt" onChange={(checked) => handleSaltChanged(form, checked)} dataWidth="33" component={CheckboxField} />
                                                <Field name="drenching.saltRate_gCowDay" placeholder={enableSalt ? "0" : "-"} uom="g/cow/day" dataWidth="33" disabled={!enableSalt} component={WholeNumberField} />
                                                <Field name="drenching.saltNumberOfWeeksUsed" placeholder={enableSalt ? "0" : "-"} uom="weeks" dataWidth="33" disabled={!enableSalt} component={WholeNumberField} />
                                            </FieldGroup>
                                            <FieldGroup>
                                                <Field name="drenching.limeFlourMaterial" label="Lime flour" onChange={(checked) => handleLimeFlourChanged(form, checked)} dataWidth="33" component={CheckboxField} />
                                                <Field name="drenching.limeFlourRate_gCowDay" placeholder={enableLimeFlour ? "0" : "-"} uom="g/cow/day" dataWidth="33" disabled={!enableLimeFlour} component={WholeNumberField} />
                                                <Field name="drenching.limeFlourNumberOfWeeksUsed" placeholder={enableLimeFlour ? "0" : "-"} uom="weeks" dataWidth="33" disabled={!enableLimeFlour} component={WholeNumberField} />
                                            </FieldGroup>
                                        </GridCell>
                                    </Grid>
                                    <Grid title="Dusting regime">
                                        <GridCell>
                                            <FieldGroup className="u-mt-md">
                                                <Field name="drenching.mgDusting_kghaYr" label="Magnesium oxide (dusting)" placeholder="0" uom="kg/yr" dataWidth="33" component={WholeNumberField} />
                                                <Field name="drenching.saltDusting_kghaYr" label="Salt (dusting)" placeholder="0" type="text" uom="kg/yr" dataWidth="33" component={WholeNumberField} />
                                                <Field name="drenching.limeFlourDusting_kghaYr" label="Lime flour (dusting)" placeholder="0" type="text" uom="kg/yr" dataWidth="33" component={WholeNumberField} />
                                            </FieldGroup>
                                        </GridCell>
                                    </Grid>
                                </>)}
                                {showTotals && (<>
                                    <Grid title="Added to feed">
                                        <GridCell>
                                            <FieldGroup className="u-mt-md">
                                                <Field name="nitrogen_kgYr" label="Urea (N)" placeholder="0" uom="kg/yr" dataWidth="33" component={WholeNumberField} />
                                                <Field name="phosphate_kgYr" label="Phosphate (P)" placeholder="0" uom="kg/yr" dataWidth="33" component={WholeNumberField} />
                                            </FieldGroup>
                                        </GridCell>
                                    </Grid>
                                </>)}
                                <Panel title="Salt block applications" className="u-mt-lg" midBlue actions={<span id="add-salt-block-link" className="IconLink--arrow-plus u-link" onClick={() => addSaltBlockApplication(form)}>Add salt block application</span>}>
                                    <PanelBody>
                                        <FieldArray name="saltBlocks" validate={validateSaltBlockApplications}>
                                            {({ fields }) => {
                                                const hasApplications = fields.length > 0;
                                                return (
                                                    <div className="Table u-mt-md">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th data-width="md-40 xl-40">Salt block type</th>
                                                                    <th data-width="md-20 xl-20">Number of blocks per year</th>
                                                                    {hasApplications && <th className="th--shrink">&nbsp;</th>}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {hasApplications
                                                                    ? fields.map((fieldName, index) => {
                                                                        return (
                                                                            <tr key={fieldName}>
                                                                                <td valign="top">
                                                                                    <Field name={`${fieldName}.type`} placeholder="Select type" options={saltBlockTypeOptions} required component={SelectField} />
                                                                                </td>
                                                                                <td valign="top">
                                                                                    <Field name={`${fieldName}.numberPerYear`} placeholder="0" uom="number/yr" required component={WholeNumberField} />
                                                                                </td>
                                                                                <td valign="top">
                                                                                    <ActionLink id={`remove-salt-block-${index}`} className="IconLink--trash u-mt-xs" onClick={() => deleteSaltBlockApplication(form, index)} />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    : <tr>
                                                                        <td colSpan="3">
                                                                            <div className="Tile-body">
                                                                                <div className="Tile-body-message">
                                                                                    <Button id="add-salt-block-button" secondary onClick={() => addSaltBlockApplication(form)} className="IconLink--arrow-plus u-mt-md">
                                                                                        Add salt block application
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            }}
                                        </FieldArray>
                                    </PanelBody>
                                </Panel>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="confirm-button" submit waiting={submitting}>
                                        Done
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useHealthSupplementsModal() {
    const [modal, openModal] = useModal(HealthSupplementsModal);

    const open = (healthSupplements, onSubmit) => {
        const modalProps = {
            healthSupplements,
            onSubmit
        };
        openModal(modalProps);
    };

    return [modal, open];
}

function showSupplementsTypeField(healthSupplements) {
    return healthSupplements?.enterpriseType === "Dairy";
}

function showTotalsFields(healthSupplements) {
    return healthSupplements?.supplementsType === SUPPLEMENTS_TYPE_OPTIONS[0].value;

}

function showDrenchingFields(healthSupplements) {
    return healthSupplements?.supplementsType === SUPPLEMENTS_TYPE_OPTIONS[1].value;
}

function enableMagnesiumFields(healthSupplements) {
    return healthSupplements?.drenching?.mgMaterial && healthSupplements.drenching.mgMaterial !== "None";
}

function enableSaltFields(healthSupplements) {
    return healthSupplements?.drenching?.saltMaterial;
}

function enableLimeFlourFields(healthSupplements) {
    return healthSupplements?.drenching?.limeFlourMaterial;
}

const SUPPLEMENTS_TYPE_OPTIONS = [
    { value: "Totals", text: "Total annual amount applied" },
    { value: "Drenching", text: "Drenching and dusting regimes" },
];
