import { Fragment, useEffect, useState } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import Application, { validateApplication } from "./Application";
import { Link, useLocation } from "react-router-dom";
import Alert from "components/Alert";
import * as fertUtils from "./_utils";
import * as utils from "common/utils";
import { Redirect } from "react-router-dom";
import * as domain from "common/domain";
import * as cropUtils from "containers/BudgetHome/Crops/_utils";
import { v4 as uuidv4 } from "uuid";
import { factoryFertiliser } from "common/domain";
import * as fu from "./_utils";
import numeral from "numeral";
import * as icons from "common/icons";
import ActionLink from "components/ActionLink";
import createDecorator from "final-form-calculate";
import { Panel, PanelBody } from "components/Panel";
import { useCustomNutrientCompositions, useRefData, useShowQuickTips } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { Button } from "components/Button";
import { useFertiliserProductModal } from "./ProductModal";

export default function Applications({ farm, analysis }) {
    const refData = useRefData();
    const customCompositions = useCustomNutrientCompositions();
    const showQuickTips = useShowQuickTips();
    const location = useLocation();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const [fertiliserProductModal, openFertiliserProductModal] = useFertiliserProductModal();

    const params = new URLSearchParams(location.search);
    const blockId = params.get("blockId") !== null ? params.get("blockId") : undefined;
    const productId = params.get("productId") !== null ? params.get("productId") : undefined;
    const [appGroups, setAppGroups] = useState([]);
    fertUtils.updateApplicationModel(analysis, blockId);
    const block = blockId && analysis.blocks.find((b) => b.id === blockId);
    const _blockIcon = block && utils.getBlockIcon(block.type);
    const _blockType = block && utils.getBlockTypeText(refData, block);
    const product = productId && analysis.fertiliser.find((f) => f.id === productId);
    const productIcon = product && utils.getFertiliserIcon(product.material);
    const unit = product && product.organicType === fu.CompostMulches ? "tonnes" : "kgha";
    const factoryEffluent = product && product.dairyFactoryEffluent && refData.factoryEffluent.find((r) => r.value === product.dairyFactoryEffluent);
    const prodNutrients = (factoryEffluent && factoryEffluent.nutrients) || (product && product.nutrients);
    const { nutrientNType = [], nutrientPType = [], nutrientMgType = [] } = refData;

    useEffect(() => {
        const prodBlockGroups = [];
        if (blockId || productId) {
            analysis.fertiliser
                .filter((f) => !productId || f.id === productId)
                .forEach((fertiliser) => {
                    const { applications = [] } = fertiliser;
                    applications
                        .filter((a) => !blockId || a.blockIds.includes(blockId))
                        .forEach((app) => {
                            const { months = [] } = app;
                            app.productId = fertiliser.id;
                            app.fertiliser = {
                                ...fertiliser,
                                applications: [],
                            };
                            if (months.length > 0) {
                                app.month = months[0].month;
                                app.reportingYear = months[0].reportingYear;
                            }
                            const { blockIds: appBlockIds = [] } = app;
                            const sortedAppBlockIds = appBlockIds.sort();

                            let appGroup = prodBlockGroups.find((ag) => ag.blockIds.length === sortedAppBlockIds.length && ag.blockIds.every((id, i) => id === sortedAppBlockIds[i]));
                            if (!appGroup) {
                                appGroup = {
                                    id: uuidv4(),
                                    blockIds: sortedAppBlockIds,
                                    applications: [],
                                };
                                prodBlockGroups.push(appGroup);
                            }
                            appGroup.applications.push(app);
                        });
                });
        }

        if (prodBlockGroups.length === 0) {
            const blockIds = blockId ? [blockId] : [];
            prodBlockGroups.push({
                blockIds,
                applications: [{ id: uuidv4(), addedAt: new Date().getTime(), unit, productId, fertiliser: product && product !== null ? product : undefined }],
            });
        } else {
            prodBlockGroups.forEach((g) => g.applications.sort((a, b) => (a.rotationIndex < b.rotationIndex ? -1 : 1)));
        }

        setAppGroups(prodBlockGroups);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockId, productId]);

    const viewModel = {
        appGroups,
        analysis,
    };

    const { blocks = [] } = analysis;
    const fertBlock = blockId && blocks.find((b) => b.id === blockId);
    const rotationYear = blockId ? cropUtils.rotationYear(fertBlock, true) : domain.farmYear;

    const adjForPreviousLime = (products, productsToSubmit) => {
        //Find blocks that have two prev lime applications (only one is allowed)
        const allProducts = [...productsToSubmit, ...products];
        const blockLastPrev = [];
        allProducts.forEach((product) => {
            const { applications = [] } = product;
            const lastPreviousApps = applications.filter((a) => a.isLastPreviousLime);
            lastPreviousApps.forEach((lpa) => {
                const { blockIds = [] } = lpa;
                blockIds.forEach((id) => {
                    const block = blocks.find((b) => b.id === id);
                    let lastPrevForBlock = blockLastPrev.find((b) => b.blockId === id);
                    if (!lastPrevForBlock) {
                        lastPrevForBlock = {
                            blockId: id,
                            values: [],
                            isFodder: block.type === "FodderCrop",
                        };
                        blockLastPrev.push(lastPrevForBlock);
                    }
                    if (!lastPrevForBlock.values.find((v) => v.applicationId === lpa.id)) lastPrevForBlock.values.push({ applicationId: lpa.id, addedAt: lpa.addedAt || 0, productId: product.id });
                });
            });
        });

        //Delete all but the latest application for a block and delete for fodder crops
        const blocksWithAppsToDelete = blockLastPrev.filter((b) => b.values.length > 1);
        blocksWithAppsToDelete.forEach((blockToDelApp) => {
            const latestModifiedApp = blockToDelApp.values.reduce((a, b) => (a.addedAt > b.addedAt ? a : b), blockToDelApp.values[0]);
            blockToDelApp.values
                .filter((a) => a.applicationId !== latestModifiedApp.applicationId)
                .forEach((app) => {
                    const product = allProducts.find((p) => p.id === app.productId);
                    const lplApp = product.applications.find((a) => a.id === app.applicationId);
                    lplApp.blockIds = lplApp.blockIds.filter((b) => b !== blockToDelApp.blockId);
                    if (lplApp.blockIds.length === 0) {
                        product.applications = product.applications.filter((a) => a.id !== lplApp.id);
                    }
                    if (!productsToSubmit.find((p) => p.id === product.id)) {
                        productsToSubmit.push(product);
                    }
                });
        });

        //Remove fodder crop blocks from previous lime applications
        const fodderAppsToDelete = blockLastPrev.filter((b) => b.isFodder);
        fodderAppsToDelete.forEach((blockToDelApp) => {
            blockToDelApp.values.forEach((app) => {
                const product = allProducts.find((p) => p.id === app.productId);
                const lplApp = product.applications.find((a) => a.id === app.applicationId);
                lplApp.blockIds = lplApp.blockIds.filter((b) => b !== blockToDelApp.blockId);
                if (!productsToSubmit.find((p) => p.id === product.id)) {
                    productsToSubmit.push(product);
                }
            });
        });
    };

    const setApplicationData = (app, fertiliser) => {
        const { nutrients = {} } = app;
        const selectedBlocks = app.blockIds.map((id) => analysis.blocks.find((b) => b.id === id));
        const hasCrops = selectedBlocks.some((b) => ["FodderCrop", "ProductiveCrop"].includes(b.type));
        const nutrientsOnApplication = fu.nutrientsOnApplication(fertiliser);
        const noNitrogen = nutrientsOnApplication && !Number(nutrients.N) > 0;
        if (!hasCrops || noNitrogen) {
            app.nFertAppMethod = "None";
        }
        if (app.limeDissolvesWithinYear) {
            delete app.limeYearsSinceApplied;
        }
        delete app.rotationIndex;
        delete app.fertiliser;
    };

    const submit = async (viewModel) => {
        const { appGroups = [], analysis, deleteGroupAppIds = [] } = viewModel;
        const { fertiliser: products = [], blocks = [] } = analysis;
        const productsToSubmit = [];
        let filteredApplications = [];
        const expandedYear = rotationYear.length > 12 ? rotationYear : [...rotationYear, ...rotationYear];

        products.forEach((vmProduct) => {
            let { applications: productApplications = [] } = vmProduct;
            productApplications.forEach((a) => (a.productId = vmProduct.id));
            const modProduct = product && vmProduct.id === product.id ? { ...product, ...{ applications: productApplications } } : vmProduct;
            if (fu.nutrientsOnApplication(modProduct)) {
                delete modProduct.amount;
            }
            let productAppIdsToDel = [];
            appGroups.forEach((appGroup) => {
                const { applications = [] } = appGroup;
                const { deletedAppIds = [] } = appGroup;
                const delApplicationIds = productApplications.filter((a) => a.productId === modProduct.id && deletedAppIds.includes(a.id)).map((a) => a.id);
                productAppIdsToDel = [...productAppIdsToDel, ...delApplicationIds];
                applications
                    .filter((a) => a.productId === modProduct.id)
                    .forEach((app) => {
                        productApplications = modProduct.applications || [];
                        filteredApplications = productApplications.filter((a) => a.id !== app.id);
                        const rotIndex = Number(app.rotationIndex);
                        const isReportingYear = rotIndex > 11;
                        const month = { month: expandedYear[Number(app.rotationIndex)], reportingYear: isReportingYear };
                        const selectedBlocks = appGroup.blockIds.map((id) => blocks.find((b) => b.id === id));
                        const currApp =
                            rotIndex < 0
                                ? {
                                    ...app,
                                    blockIds: appGroup.blockIds,
                                    isLastPreviousLime: true,
                                    limeDissolvesWithinYear: Math.abs(rotIndex) === 1,
                                    limeYearsSinceApplied: Math.abs(rotIndex) - 1,
                                }
                                : {
                                    ...app,
                                    blockIds: isReportingYear || app.isLastPreviousLime ? appGroup.blockIds : selectedBlocks.filter((b) => b.type === "ProductiveCrop").map((b) => b.id),
                                    months: app.isLastPreviousLime ? [] : [month],
                                };

                        setApplicationData(currApp, modProduct);
                        if (currApp.blockIds.length > 0 && (currApp.isLastPreviousLime || (currApp.months.length > 0 && rotationYear.includes(currApp.months[0].month)))) {
                            filteredApplications.push(currApp);
                        }
                        modProduct.applications = filteredApplications;
                        if (!productsToSubmit.find((p) => p.id === modProduct.id)) {
                            productsToSubmit.push(modProduct);
                        }
                    });
            });

            productAppIdsToDel = [...productAppIdsToDel, ...deleteGroupAppIds];
            const productHasDeletes = modProduct.applications.some((a) => productAppIdsToDel.includes(a.id));
            if (productHasDeletes) {
                filteredApplications = modProduct.applications.filter((a) => !productAppIdsToDel.includes(a.id));
                modProduct.applications = filteredApplications;
                if (!productsToSubmit.find((p) => p.id === modProduct.id)) {
                    productsToSubmit.push(modProduct);
                }
            }
        });

        adjForPreviousLime(products, productsToSubmit);

        productsToSubmit.forEach((fertProduct) => {
            utils.merge(fertProduct, analysis.fertiliser);
        });
        await updateAnalysisAsync(analysis);
    };

    const validateAppGroup = (fields = []) => {
        const errorArray = [];
        fields.forEach((appGroup) => {
            const grpErrors = { applications: [] };
            const { blockIds = [] } = appGroup;
            if (blockIds.length === 0) grpErrors.blockIds = "Required";
            const { applications = [] } = appGroup;
            applications.forEach((app) => {
                app.blockIds = appGroup.blockIds;
                const appErrors = validateApplication(app, analysis);
                grpErrors.applications.push(appErrors);
            });
            errorArray.push(grpErrors);
        });
        return errorArray;
    };

    const applicationCount = viewModel.appGroups.reduce((t, g) => (t += g.applications.length), 0);

    if (applicationCount > 150) {
        return (
            <Panel>
                <PanelBody>
                    <div className="Tile-body">
                        <div className="Tile-body-message">
                            <img src={icons.cancel} className="u-p-5" alt="Back" />
                            <p className="lead">There are too many applications to display for this product. Please view the applications via the block link.</p>
                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser`} className="Button Button--secondary u-mt-lg">
                                Back
                            </Link>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
        );
    }

    return (
        <>
            <Form initialValues={viewModel} mutators={{ ...arrayMutators }} decorators={[appGroupDecorator]} keepDirtyOnReinitialize={true} onSubmit={submit}>
                {({ handleSubmit, form, values, submitSucceeded, submitting }) => {
                    if (submitSucceeded) {
                        return <Redirect to={`/app/farm/${analysis.farmId}/analysis/${analysis.id}/fertiliser`} />;
                    }

                    const addFertilser = (material, appPath) => {
                        const newFertAdded = (fert) => {
                            form.mutators.push("analysis.fertiliser", fert);
                            form.change(`${appPath}.fertiliser`, fert);
                            if (fert.organicType === fu.CompostMulches) {
                                form.change(`${appPath}.unit`, "tonnes");
                            } else if (fu.kghaOnly({ fertiliser: fert })) {
                                form.change(`${appPath}.unit`, "kgha");
                            }
                            form.change(`${appPath}.productId`, fert.id);
                        };

                        const fertiliser = factoryFertiliser();
                        fertiliser.id = "NEW";

                        const viewModel = {
                            material,
                            farm,
                            analysis,
                            fertiliser,
                            refData,
                            isLastPreviousLime: false,
                            customCompositions: [],
                            applicationPath: appPath,
                            isNew: true,
                        };

                        openFertiliserProductModal(viewModel, newFertAdded);
                    };

                    const editFertiliser = (fertiliser) => {
                        const viewModel = {
                            material: fertiliser.material,
                            farm,
                            analysis,
                            fertiliser,
                            refData,
                            preventCustomSwitch: true,
                            isLastPreviousLime: false,
                            customCompositions: [],
                            isNew: false,
                        };

                        openFertiliserProductModal(viewModel);
                    };

                    const addGroup = (index) => {
                        const blockIds = blockId ? [blockId] : [];
                        const group = {
                            blockIds,
                            applications: [{ addedAt: new Date().getTime(), id: uuidv4(), unit, productId, fertiliser: product }],
                        };
                        const appGroups = [...values.appGroups];
                        appGroups.splice(index, 0, group);
                        form.change("appGroups", appGroups);
                    };

                    const copyGroup = (index) => {
                        const dup = utils.clone(values.appGroups[index]);
                        dup.id = uuidv4();
                        const { applications = [] } = dup;
                        applications.forEach((a) => {
                            a.id = uuidv4();
                            a.blockIds = dup.blockIds;
                        });
                        const appGroups = [...values.appGroups];
                        appGroups.splice(index, 0, dup);
                        form.change("appGroups", appGroups);
                    };

                    const deleteGroup = (index) => {
                        const appGroup = values.appGroups[index] || {};
                        const { applications = [] } = appGroup;
                        const appIds = applications.map((a) => a.id);
                        const { deleteGroupAppIds = [] } = values;
                        form.change("deleteGroupAppIds", [...deleteGroupAppIds, ...appIds]);
                        form.change(`appGroups[${index}].isDeleted`, true);
                    };

                    const productsOnBlock = [];
                    values.appGroups
                        .filter((g) => (g.blockIds || []).includes(blockId))
                        .forEach((g) => {
                            const { applications = [] } = g;
                            applications.forEach((a) => {
                                if (a.fertiliser) {
                                    let fertOnBlock = productsOnBlock.find((p) => p.id === a.fertiliser.id);
                                    if (!fertOnBlock) {
                                        fertOnBlock = { fertiliser: a.fertiliser, months: [] };
                                        productsOnBlock.push(fertOnBlock);
                                    }
                                    fertOnBlock.months.push(Number(a.rotationIndex));
                                }
                            });
                        });

                    const maintenanceNutrients = block && block.currentResults && block.currentResults.maintenanceNutrientResults && block.currentResults.maintenanceNutrientResults.fertiliserNutrients;

                    const totalNutrients = block && block.currentResults && block.currentResults.nutrientBudget && block.currentResults.nutrientBudget.nutrientCategories && block.currentResults.nutrientBudget.nutrientCategories.Fertiliser && block.currentResults.nutrientBudget.nutrientCategories.Fertiliser.total && block.currentResults.nutrientBudget.nutrientCategories.Fertiliser.total.nutrients;

                    return (
                        <div className={submitting ? "waiting" : undefined}>
                            {(blockId || productId) && (
                                <div className="Grid u-pl-sm">
                                    <div className="Grid-cell">
                                        {showQuickTips && <Alert type="help" text="Applications can be applied to multiple blocks at once. Select the blocks from the drop down in an application group and then add applications to that group. An application can be copied using the copy icon at the end of the row. A whole group can be copied and then edited to adjust the blocks or applications for that new group." />}
                                        {block && (
                                            <ul className="DataWidthTable FarmTable">
                                                <li className="FarmTable-title u-pt-0 u-pb-0" data-width="25">
                                                    <div className="FarmTitle">
                                                        <img className="FarmTitle-icon" src={_blockIcon} alt="Block Type" />
                                                        <div className="FarmTitle-heading">
                                                            <span className="FarmTitle-name" title={block.name}>
                                                                {block.name}
                                                            </span>
                                                            <div className="FarmTitle-sub">{_blockType}</div>
                                                        </div>
                                                    </div>
                                                </li>

                                                {totalNutrients && Object.keys(totalNutrients).length > 0 && (
                                                    <li data-width="33">
                                                        <div className="FarmTable-keyValuePair">
                                                            <div className="FarmTable-keyValue">
                                                                <span className="FarmTable-key">Reporting Year - Total nutrients (kg/ha/yr)</span>
                                                                <span className="FarmTable-value">
                                                                    {totalNutrients.N !== undefined && (
                                                                        <span>
                                                                            <b>N:</b> {numeral(totalNutrients.N).format("0.0")}
                                                                        </span>
                                                                    )}
                                                                    {totalNutrients.P !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>P:</b> {numeral(totalNutrients.P).format("0.0")}
                                                                        </span>
                                                                    )}
                                                                    {totalNutrients.K !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>K:</b> {numeral(totalNutrients.K).format("0.0")}
                                                                        </span>
                                                                    )}
                                                                    {totalNutrients.S !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>S:</b> {numeral(totalNutrients.S).format("0.0")}
                                                                        </span>
                                                                    )}
                                                                    {totalNutrients.Ca !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Ca:</b> {numeral(totalNutrients.Ca).format("0.0")}
                                                                        </span>
                                                                    )}
                                                                    {totalNutrients.Mg !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Mg:</b> {numeral(totalNutrients.Mg).format("0.0")}
                                                                        </span>
                                                                    )}
                                                                    {totalNutrients.Na !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Na:</b> {numeral(totalNutrients.Na).format("0.0")}
                                                                        </span>
                                                                    )}
                                                                    {totalNutrients.H !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>H+:</b> {numeral(totalNutrients.H).format("0.0")} <sup>1</sup>
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                                {maintenanceNutrients && Object.keys(maintenanceNutrients).length > 0 && (
                                                    <li data-width="33">
                                                        <div className="FarmTable-keyValuePair">
                                                            <div className="FarmTable-keyValue">
                                                                <span className="FarmTable-key">Maintenance nutrients (kg/ha/yr)</span>
                                                                <span className="FarmTable-value">
                                                                    {maintenanceNutrients.P !== undefined && (
                                                                        <span>
                                                                            <b>P:</b> {maintenanceNutrients.P}
                                                                        </span>
                                                                    )}
                                                                    {maintenanceNutrients.K !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>K:</b> {maintenanceNutrients.K}
                                                                        </span>
                                                                    )}
                                                                    {maintenanceNutrients.S !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>S:</b> {maintenanceNutrients.S}
                                                                        </span>
                                                                    )}
                                                                    {maintenanceNutrients.Ca !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Ca:</b> {maintenanceNutrients.Ca}
                                                                        </span>
                                                                    )}
                                                                    {maintenanceNutrients.Mg !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Mg:</b> {maintenanceNutrients.Mg}
                                                                        </span>
                                                                    )}
                                                                    {maintenanceNutrients.Na !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Na:</b> {maintenanceNutrients.Na}
                                                                        </span>
                                                                    )}
                                                                    {maintenanceNutrients.H !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Lime:</b> {maintenanceNutrients.H} <sup>2</sup>
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="GridCell">
                                        {fertBlock && (
                                            <>
                                                <div className="FarmTable-supplementary u-pt-xl" style={{ overflowX: "auto" }}>
                                                    <div className="Table" style={{ minWidth: "1200px" }}>
                                                        <table className="Calendar u-tbl-fix">
                                                            <>
                                                                {cropUtils.cropTableHeader(analysis, rotationYear, fertBlock, refData, false, 0, 0)}
                                                                <tbody>
                                                                    <tr>
                                                                        {rotationYear.map((m, i) => {
                                                                            const isReportingYear = cropUtils.indexToReportingYear(i, rotationYear);
                                                                            const farmYearMonthIndex = isReportingYear ? rotationYear.lastIndexOf(m) + (rotationYear.length > 12 ? 0 : 12) : rotationYear.indexOf(m);
                                                                            const productsOnBlockForMonth = productsOnBlock.filter((p) => (p.months || []).includes(farmYearMonthIndex)).map((p) => p.fertiliser);
                                                                            return (
                                                                                <td key={i} className="u-textCenter td--alignBottom" style={{ height: "77px" }}>
                                                                                    <div className="u-flexColumn">
                                                                                        {productsOnBlockForMonth.length > 0 && (
                                                                                            <div className="Calendar-slot">
                                                                                                <img className="Calendar-icon u-icon-sm u-p-5" src={utils.getFertiliserIcon(productsOnBlockForMonth[0].material)} alt={productsOnBlockForMonth[0].material} />
                                                                                                <span className="Calendar-icon-text">x {productsOnBlockForMonth.length}</span>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                            );
                                                                        })}
                                                                    </tr>
                                                                </tbody>
                                                            </>
                                                        </table>
                                                    </div>
                                                </div>
                                                {totalNutrients && Object.keys(totalNutrients).length > 0 && (
                                                    <div className="u-ml-sm">
                                                        <sub style={{ lineHeight: "1rem" }}>1 - Acidity - used in calculation of maintenance lime requirements. A gain in acidity indicates that soil pH will decrease.</sub>
                                                    </div>
                                                )}
                                                {maintenanceNutrients && Object.keys(maintenanceNutrients).length > 0 && (
                                                    <div className="u-ml-sm">
                                                        <sub style={{ lineHeight: "1rem" }}>2 - Maintenance lime is the amount of lime required to neutralise any increase in acidity. The value is in pure lime - divide by lime purity to get application rates.</sub>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    {product && (
                                        <div className="GridCell u-pt-lg">
                                            <ul className="DataWidthTable FarmTable">
                                                <li className="FarmTable-title u-pb-0" data-width="25">
                                                    <div className="FarmTitle">
                                                        <img className="FarmTitle-icon" src={productIcon} alt="Block Type" />
                                                        <div className="FarmTitle-heading">
                                                            <span className="FarmTitle-name" title={product.productName}>
                                                                <ActionLink onClick={() => editFertiliser(product)}>{product.productName}</ActionLink>
                                                            </span>
                                                            <div className="FarmTitle-sub">{product.material}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                {prodNutrients && Object.keys(prodNutrients).length > 0 && (
                                                    <li data-width="33">
                                                        <div className="FarmTable-keyValuePair">
                                                            <div className="FarmTable-keyValue">
                                                                <span className="FarmTable-key">Nutrients (%)</span>
                                                                <span className="FarmTable-value u-font-md">
                                                                    {prodNutrients.N !== undefined && (
                                                                        <span>
                                                                            <b>N:</b> {prodNutrients.N}
                                                                        </span>
                                                                    )}
                                                                    {prodNutrients.P !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>P:</b> {prodNutrients.P}
                                                                        </span>
                                                                    )}
                                                                    {prodNutrients.K !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>K:</b> {prodNutrients.K}
                                                                        </span>
                                                                    )}
                                                                    {prodNutrients.S !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>S:</b> {prodNutrients.S}
                                                                        </span>
                                                                    )}
                                                                    {prodNutrients.Ca !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Ca:</b> {prodNutrients.Ca}
                                                                        </span>
                                                                    )}
                                                                    {prodNutrients.Mg !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Mg:</b> {prodNutrients.Mg}
                                                                        </span>
                                                                    )}
                                                                    {prodNutrients.Na !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Na:</b> {prodNutrients.Na}
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}

                                                {product && ["Fertiliser", "Product"].includes(product.material) && (
                                                    <li data-width="33">
                                                        <div className="FarmTable-keyValuePair">
                                                            <div className="FarmTable-keyValue">
                                                                <span className="FarmTable-key">Nutrients Data</span>
                                                                <span className="FarmTable-value u-font-md">
                                                                    {product.nType !== undefined && (
                                                                        <span>
                                                                            <b>N Type:</b> {utils.valueToText(nutrientNType, product.nType)}
                                                                        </span>
                                                                    )}
                                                                    {product.pType !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>P Type:</b> {utils.valueToText(nutrientPType, product.pType)}
                                                                        </span>
                                                                    )}
                                                                    {product.percentElementalS !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>% of S as elemental S:</b> {product.percentElementalS + "%"}
                                                                        </span>
                                                                    )}
                                                                    {product.mgType !== undefined && (
                                                                        <span className="u-ml-sm">
                                                                            <b>Mg Type:</b> {utils.valueToText(nutrientMgType, product.mgType)}
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                <FieldArray name={`appGroups`} validate={validateAppGroup}>
                                    {({ fields }) => {
                                        return fields.length === 0 ? (
                                            <div className="Tile-body">
                                                <div className="Tile-body-message">
                                                    <img src={icons.add} className="u-p-5" alt="Plus" />
                                                    <p className="lead">You do not have any applications for this {blockId ? "block" : "product"}</p>
                                                    <ActionLink onClick={() => addGroup(0)} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-product">
                                                        Add applications
                                                    </ActionLink>
                                                </div>
                                            </div>
                                        ) : (
                                            fields.map((field, index) => {
                                                return values.appGroups[index].isDeleted ? null : (
                                                    <Fragment key={index}>
                                                        <Application blockId={blockId} rotationYear={rotationYear} appGroup={field} form={form} values={values} appGroupIndex={index} customCompositions={customCompositions} deleteGroup={() => deleteGroup(index)} copyGroup={(index) => copyGroup(index)} addApplicationGroup={(index) => addGroup(index)} addFertilser={(f, p) => addFertilser(f, p)} productId={productId} products={analysis.fertiliser} />
                                                    </Fragment>
                                                );
                                            })
                                        );
                                    }}
                                </FieldArray>
                                <div className="ButtonBar ButtonBar--fixed">
                                    <div className="ButtonBar-left">
                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser`} className="Button Button--secondary">
                                            Cancel
                                        </Link>
                                    </div>
                                    <div className="ButtonBar-right">
                                        <Button submit primary id="fertiliser-system-submit" waiting={submitting}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    );
                }}
            </Form>
            {fertiliserProductModal}
        </>
    );
};

const appGroupDecorator = createDecorator({
    field: /appGroups\[\d+\]\.blockIds/g,
    updates: (fieldValue, fieldName, allFieldValues) => {
        const index = Number(fieldName.match(/\d+/)[0]);
        const appGroup = allFieldValues.appGroups[index];
        const { applications = [] } = appGroup;
        applications.forEach((a) => (a.blockIds = fieldValue));
        return { [`appGroups[${index}].applications`]: applications };
    },
})
