import { Link } from "react-router-dom";
import * as utils from "common/utils";
import WidgetContainer from "components/WidgetContainer";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";

const Activity = ({ farm, className, height }) => {
    const featureTracker = useFeatureTracker(FEATURES.ANALYSIS_LINKS_LOG_HOME, undefined, farm);

    const hasActivity = farm?.activity?.length > 0 || false;

    return (
        <WidgetContainer id="activity" title="Latest farm activity" icon="IconLink--stack" className={className} hideLink={!hasActivity} linkTitle="View all" linkUrl={`/app/farm/${farm?.id}/activity`} height={height}>
            <div>
                {!hasActivity ? (
                    <div>
                        <div className="Widget-content-title">No activity</div>
                        <p>This farm has no recent activity to display</p>
                    </div>
                ) : (
                    <div className="SimpleTable">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>User</th>
                                    <th>Analysis</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    farm.activity.slice(0, 6).map((a) => {
                                        const modelVersionUpgrade = a.description.startsWith("Model version upgrade:");
                                        return (
                                            <tr key={a.time}>
                                                <td>{utils.dateOnly(a.time)}</td>
                                                {modelVersionUpgrade
                                                    ? <>
                                                        <td>Model version upgrade</td>
                                                        <td>
                                                            <Link to={`/app/farm/${farm.id}/analysis`} className="u-link">
                                                                All analyses
                                                            </Link>
                                                        </td>
                                                    </>
                                                    : <>
                                                        <td>{a.userName && a.userName.length > 20 ? a.userName.substring(0, 18) + "..." : a.userName}</td>
                                                        {farm.budgets.find((b) => b.id === a.budgetId)
                                                            ? <td>
                                                                <Link onClick={() => featureTracker.track("Clicked")} to={`/app/farm/${farm.id}/analysis/${a.budgetId}`}>
                                                                    {a.budgetName.length > 18 ? a.budgetName.substring(0, 16) + "..." : a.budgetName}
                                                                </Link>
                                                            </td>
                                                            : <td>{a.budgetName.length > 18 ? a.budgetName.substring(0, 16) + "..." : a.budgetName}</td>
                                                        }
                                                    </>
                                                }
                                                <td>{a.description}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </WidgetContainer>
    )
}

export default Activity;
