import { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { AkuakuContext } from "common/AkuakuContext";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileBodyMessage from "components/TileBodyMessage";
import Loader from "components/Loader";
import NotFound from "components/NotFound";
import FarmAccessDenied from "containers/Farm/FarmAccessDenied";
import { closeModal } from "containers/App/_actions";
import { useFarm, useAnalysis, useFarmAccess } from "containers/hooks";
import { useAnalysisResults, useAnalysisSummary } from "containers/BudgetHome";

export default function FarmRoute(FarmComponent) {
    const FarmRoute = () => {
        const { isLoading, data: farm, error } = useFarm();
        const { data: analysis } = useAnalysis();

        // Pre-fetch v2 farm access, analysis results and summary
        useFarmAccess();
        useAnalysisResults(analysis);
        useAnalysisSummary(analysis);

        const dispatch = useDispatch();

        const farmAccessDenied = error?.status === 403;
        const farmNotFound = error?.status === 404;

        //console.log(farm);
        //console.log(analysis);

        useEffect(() => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            // make sure modal is reset if screen is refreshed
            dispatch(closeModal());
        }, [dispatch]);

        const [, setContext] = useContext(AkuakuContext);
        useEffect(() => {
            if (farm) {
                const farmName = farm?.name?.replace("%", "");
                const budgetName = analysis?.name?.replace("%", "");

                setContext((ctx) => ({ ...ctx, lastFarmName: farmName, lastBudgetName: budgetName }));
            }
        }, [farm, analysis, setContext]);

        if (farmNotFound) return <NotFound />

        if (isLoading) return <Loader message="Loading..." />

        if (farmAccessDenied) return <FarmAccessDenied />

        if (farm && !farm.IsNew) return <FarmComponent farm={farm} analysis={analysis} />

        return (
            <Tile>
                <TileBody>
                    <TileBodyMessage>
                        <p className="lead">Farm currently not available</p>
                        <p>Try reloading the farm from the Dashboard or checking your internet connection</p>
                    </TileBodyMessage>
                </TileBody>
            </Tile>
        )
    }
    return FarmRoute;
}
