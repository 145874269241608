import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";

export function useCouncilReportingData(criteria) {
    const queryString = Object.keys(criteria).reduce((results, key) => {
        if (criteria[key]) results += `${key}=${criteria[key]}&`;
        return results;
    }, "");

    const query = useQuery({
        queryKey: ["reporting", "national", "year", criteria.year, "queryString", queryString],
        queryFn: async () => httpClient.get(`reporting/national/${criteria.year}?${queryString}`),
        retry: false,
    });

    return {
        loading: query.isLoading,
        error: query.error?.message,
        data: query.data,
    };
}

export const useCouncilHistory = (criteria) => {
    const qsCriteria = { ...criteria, year: new Date().getFullYear() };

    const queryString = Object.keys(qsCriteria).reduce((results, key) => {
        if (criteria[key]) results += `${key}=${criteria[key]}&`;
        return results;
    }, "");

    const query = useQuery({
        queryKey: ["reporting", "national", "year", qsCriteria.year, "history", "queryString", queryString],
        queryFn: async () => httpClient.get(`reporting/national/${qsCriteria.year}/history?${queryString}`),
        retry: false,
    });

    return {
        loading: query.isLoading,
        error: query.error?.message,
        data: query.data,
    };
};
