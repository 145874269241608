import React, { useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { farmYear } from "common/domain";
import * as utils from "common/utils";

const MobWeights = ({ value = [], showWeaning = true, stockCounts = [], flattenWeightGain = false, startMonth = "July" }) => {
    const [isLine, setIsLine] = useState(true);
    if (!value || value.length === 0) return null;

    //const startWeight = defaults.startWeight

    const datasetsBar = value.map((mobData, index) => {
        const { changeInLiveWeight = [], numberOfAnimalsOnFarm = [], name } = mobData;
        let label = `Group ${index + 1}`;

        const weightData = farmYear.map((monthName) => {
            const monthWeight = changeInLiveWeight[monthName];
            const monthValue = numberOfAnimalsOnFarm[monthName] > 0 ? utils.round(monthWeight, 2) : null;
            if (monthValue) {
                label = name ? name : "Remaining animals";
                label = index + ". " + label;
            }
            return monthValue;
        });

        return {
            label,
            data: weightData,
            borderColor: utils.graphColours[index],
            backgroundColor: utils.graphColours[index],
            fill: false,
        };
    });

    const datasetsLine = value.map((mobData, index) => {
        const { changeInLiveWeight = [], numberOfAnimalsOnFarm = [], startWeight, name } = mobData;
        let runningWeight = startWeight;
        let label = null;
        //        let adjustedYear = numberOfAnimalsOnFarm['July']>0?['Start'].concat(farmYear):farmYear

        const weightData = farmYear.map((monthName, i) => {
            let monthValue = numberOfAnimalsOnFarm[farmYear[i]] ? utils.round(runningWeight, 1) : null;
            if (!monthValue && i > 0 && numberOfAnimalsOnFarm[farmYear[i - 1]]) monthValue = utils.round(runningWeight, 1);

            if (monthValue) {
                label = name ? name : "Remaining animals";
            }
            runningWeight += changeInLiveWeight[farmYear[i]] ? changeInLiveWeight[farmYear[i]] : 0;
            return monthValue;
        });

        if (numberOfAnimalsOnFarm["June"]) weightData.push(runningWeight);

        return {
            label,
            data: weightData,
            borderColor: utils.graphColours[index],
            backgroundColor: utils.graphColours[index],
            fill: false,
        };
    });

    const chartData = {
        labels: farmYear.map((month) => month),
        datasets: isLine ? datasetsLine.filter((ds) => ds.data.some((d) => d > 1)) : datasetsBar.filter((ds) => ds.data.some((d) => d > 0)),
    };

    chartData.labels.push("");

    const barOptions = {
        scales: {
            y: {
                ticks: {
                    beginAtZero: true,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const { dataset, dataIndex } = tooltipItem;
                        return dataset.data[dataIndex] ? `Weight gain: ${utils.round(dataset.data[dataIndex], 2)}kg` : "";
                    },
                },
            },
        },
    };

    const lineOptions = {
        datasets: {
            line: {
                tension: 0.5,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const { dataset, dataIndex } = tooltipItem;
                        return `Weight: ${utils.round(dataset.data[dataIndex], 1)}kg`;
                    },
                    afterLabel: (tooltipItem) => {
                        const group = value[tooltipItem.datasetIndex];
                        const displayMonth = tooltipItem.dataIndex > 0 ? farmYear[tooltipItem.dataIndex - 1] : "";
                        const monthNum = new Date(Date.parse(displayMonth + " 1, 2012")).getMonth() + 1;
                        const monthCount = stockCounts.find((s) => s.month === monthNum) || {};
                        const sumOfAnimals = value.reduce((t, d) => (t += (d.numberOfAnimalsOnFarm || [])[displayMonth]), 0);
                        if (monthCount.stockCount !== sumOfAnimals) return "";
                        const { numberOfAnimalsOnFarm = [] } = group;
                        return numberOfAnimalsOnFarm[displayMonth] ? `Animals: ${numberOfAnimalsOnFarm[displayMonth]}` : "";
                    },
                },
            },
        },
    };

    const firstMob = value[0];

    return (
        <>
            {!flattenWeightGain && (
                <>
                    <div className="Field">
                        <div className="Radio_group Radio_group--inline">
                            <label className="Radio" htmlFor="showLine">
                                <input id="showLine" className="Radio-input" type="radio" checked={isLine} onChange={() => setIsLine(true)} />
                                <span className="Radio-label">Liveweight</span>
                            </label>
                            <label className="Radio" htmlFor="showBar">
                                <input id="showBar" className="Radio-input" type="radio" checked={!isLine} onChange={() => setIsLine(false)} />
                                <span className="Radio-label">Weight gain</span>
                            </label>
                        </div>
                    </div>

                    <div className="h5 u-mt-lg">{isLine ? "Liveweight" : "Weight gain"}</div>
                    {isLine ? <Line data={chartData} height={80} options={lineOptions} /> : <Bar data={chartData} height={80} options={barOptions} />}
                </>
            )}
            <div className="FarmTable_wrapper u-mt-md">
                <ul className={`DataWidthTable FarmTable u-flex ${showWeaning ? "u-flexJustifyBetween" : ""}`}>
                    {firstMob.startAge !== 0 && (
                        <li>
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Start age</span>
                                    <span className="FarmTable-value">{utils.round(firstMob.startAge, 2)} months</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {firstMob.startWeight !== 0 && (
                        <li>
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Start weight</span>
                                    <span className="FarmTable-value">{utils.round(firstMob.startWeight, 2)}kg</span>
                                </div>
                            </div>
                        </li>
                    )}

                    {firstMob.fatContent !== 0 && (
                        <li>
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Fat content</span>
                                    <span className="FarmTable-value">{utils.round(firstMob.fatContent, 2)}%</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {firstMob.proteinContent !== 0 && (
                        <li>
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Protein content</span>
                                    <span className="FarmTable-value">{utils.round(firstMob.proteinContent, 2)}%</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {firstMob.milkYield !== 0 && (
                        <li>
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Milk yeild</span>
                                    <span className="FarmTable-value">{utils.round(firstMob.milkYield, 2)}kg</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {firstMob.weaningAge !== 0 && showWeaning && (
                        <li>
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Weaning age</span>
                                    <span className="FarmTable-value">{firstMob.weaningAge} months</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {firstMob.weaningRate !== 0 && showWeaning && (
                        <li>
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Weaning rate</span>
                                    <span className="FarmTable-value">{utils.round(firstMob.weaningRate, 2)}</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {firstMob.weaningWeight !== 0 && showWeaning && (
                        <li>
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Weaning weight</span>
                                    <span className="FarmTable-value">{utils.round(firstMob.weaningWeight, 2)}kg</span>
                                </div>
                            </div>
                        </li>
                    )}
                    {firstMob.matureWeight !== 0 && false && (
                        <li>
                            <div className="FarmTable-keyValuePair">
                                <div className="FarmTable-keyValue">
                                    <span className="FarmTable-key">Mature weight</span>
                                    <span className="FarmTable-value">{utils.round(firstMob.matureWeight, 2)}kg</span>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
};

export default MobWeights;
