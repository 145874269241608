import React from "react";
import { compose, withAppContext, withActions, withState } from "containers/HOC";
import { Link } from "react-router-dom";
import * as api from "api";
import { useDebounce, useCloseOnEscape } from "common/effects";
import ActionLink from "components/ActionLink";
import { setFormState } from "containers/App/_actions";
import { modal as launchModal } from "containers/App/_actions";
import { updateMyOrg } from "containers/MyOrg/_actions";
import FarmSearchResults from "./FarmSearchResults";
import PublicationSearchResults from "./PublicationSearchResults";
import HelpArticleSearchResults from "./HelpArticleSearchResults";
import StudentSearchResults from "./StudentSearchResults";

const QuickSearch = ({ rightPos, showQuickSearch, toggleQuickSearch, searchKnowledgebase, searchFarms, searchPublications, searchStudents, updateMyOrg, formState, setFormState, launchModal, refData, authContext }) => {
    const [searchText, setSearchText] = React.useState("");
    const searchTextIsValid = searchText && searchText.length > 2;
    const debouncedSearchText = useDebounce(searchText, 500);
    const quickSearchRef = React.useRef();
    const searchInputRef = React.useRef();
    useCloseOnEscape(() => toggleQuickSearch(false));

    const [farmResults, setFarmResults] = React.useState();
    const disableFarmSearch = formState["quickSearch_disable_farm_search"];
    React.useEffect(() => {
        if (disableFarmSearch || !searchTextIsValid) {
            setFarmResults([]);
        } else if (debouncedSearchText) {
            searchFarms(debouncedSearchText)
                .then((searchResults) => setFarmResults(searchResults))
                .catch((error) => console.log(error));
        }
    }, [debouncedSearchText, searchTextIsValid, disableFarmSearch, searchFarms]);

    const [publicationResults, setPublicationResults] = React.useState();
    const disablePublicationSearch = formState["quickSearch_disable_publication_search"];
    React.useEffect(() => {
        if (disablePublicationSearch || !searchTextIsValid) {
            setPublicationResults([]);
        } else if (debouncedSearchText) {
            searchPublications(debouncedSearchText)
                .then((searchResults) => setPublicationResults(searchResults))
                .catch((error) => console.log(error));
        }
    }, [debouncedSearchText, searchTextIsValid, disablePublicationSearch, searchPublications]);

    const [helpArticleResults, setHelpArticleResults] = React.useState();
    const disableHelpArticleSearch = formState["quickSearch_disable_help_article_search"];
    React.useEffect(() => {
        if (disableHelpArticleSearch || !searchTextIsValid) {
            setHelpArticleResults([]);
        } else if (debouncedSearchText) {
            searchKnowledgebase(debouncedSearchText)
                .then((searchResults) => setHelpArticleResults(searchResults.results))
                .catch((error) => console.log(error));
        }
    }, [debouncedSearchText, searchTextIsValid, disableHelpArticleSearch, searchKnowledgebase]);

    const isOverseerEd = refData && refData.deploymentType === "Education" && authContext.accountType === "EducationProvider";
    const [studentResults, setStudentResults] = React.useState();
    const disableStudentSearch = formState["quickSearch_disable_student_search"];
    React.useEffect(() => {
        if (!isOverseerEd || disableStudentSearch || !searchTextIsValid) {
            setStudentResults([]);
        } else if (debouncedSearchText) {
            searchStudents(debouncedSearchText)
                .then((searchResults) => setStudentResults(searchResults))
                .catch((error) => console.log(error));
        }
    }, [debouncedSearchText, searchTextIsValid, isOverseerEd, disableStudentSearch, searchStudents]);

    const [selectedSearchResultIndex, setSelectedSearchResultIndex] = React.useState(-1);
    React.useEffect(() => {
        let isMounted = true;
        function resetSelectedSearchResultIndex() {
            if (isMounted) setSelectedSearchResultIndex(-1);
        }
        resetSelectedSearchResultIndex();
        return () => (isMounted = false);
    }, [debouncedSearchText]);

    React.useEffect(() => {
        function handleKeydown(e) {
            const { key, shiftKey } = e;
            const down = key === "ArrowDown";
            const up = key === "ArrowUp";
            const tabDown = key === "Tab" && !shiftKey;
            const tabUp = key === "Tab" && shiftKey;

            if (down || tabDown) {
                e.preventDefault();
                const searchResultsCount = studentResults.length + farmResults.length + publicationResults.length + helpArticleResults.length - 1;
                if (searchResultsCount > selectedSearchResultIndex) {
                    const updatedResultIndex = selectedSearchResultIndex + 1;
                    setSelectedSearchResultIndex(updatedResultIndex);
                    if (updatedResultIndex === searchResultsCount) searchInputRef.current.focus();
                } else if (selectedSearchResultIndex === searchResultsCount) {
                    setSelectedSearchResultIndex(-1);
                    searchInputRef.current.focus();
                }
            } else if (up || tabUp) {
                e.preventDefault();
                if (selectedSearchResultIndex >= 0) {
                    const updatedResultIndex = selectedSearchResultIndex - 1;
                    setSelectedSearchResultIndex(updatedResultIndex);
                    if (updatedResultIndex < 0) searchInputRef.current.focus();
                }
            }
        }
        const quickSearch = quickSearchRef.current;
        quickSearch.addEventListener("keydown", handleKeydown);
        return () => {
            quickSearch.removeEventListener("keydown", handleKeydown);
        };
    }, [selectedSearchResultIndex, studentResults, farmResults, publicationResults, helpArticleResults]);

    React.useEffect(() => {
        if (!showQuickSearch) {
            setSearchText("");
            searchInputRef.current.blur();
        }
    }, [showQuickSearch]);

    const showSearchResults = helpArticleResults || farmResults || publicationResults || studentResults;

    return (
        <div ref={quickSearchRef} id="nav-search" className={`u-print-none QuickSearch ${showQuickSearch ? "is-searching" : ""}`} onFocus={() => toggleQuickSearch(true)} style={{ right: rightPos }}>
            <div className="QuickSearch-input-wrapper">
                <i className="icon icon-search u-textCoolBlue"></i>
                <input id="nav-search-input" ref={searchInputRef} onChange={(e) => setSearchText(e.target.value)} value={searchText} className="QuickSearch-input" autoComplete="off" type="search" placeholder="Search" />
                {showQuickSearch && <i id="nav-search-close" className="icon icon-cross u-textCoolBlue u-link u-ml-auto u-mr-xs" onClick={() => toggleQuickSearch(false)}></i>}
            </div>
            {showQuickSearch && showSearchResults && (
                <>
                    <div className="QuickSearch-panel">
                        <Link id="advanced-farm-search" to="/app/search/farms" onClick={() => toggleQuickSearch(false)} className="IconLink--search">
                            Advanced farm search
                        </Link>
                        <div className="QuickSearch-filters">
                            <ul className="QuickSearch-options-list">
                                {isOverseerEd && (
                                    <li
                                        className={`BlockList-item u-link ${disableStudentSearch ? "" : "is-active"}`}
                                        onClick={() => {
                                            setFormState("quickSearch_disable_student_search", !disableStudentSearch);
                                            searchInputRef.current.focus();
                                            setSelectedSearchResultIndex(-1);
                                        }}
                                    >
                                        <span id="nav-search-option-farms">Students</span>
                                        <ActionLink className="BlockList-item-close">
                                            <i className="icon icon-cross" />
                                        </ActionLink>
                                    </li>
                                )}
                                <li
                                    className={`BlockList-item u-link ${disableFarmSearch ? "" : "is-active"}`}
                                    onClick={() => {
                                        setFormState("quickSearch_disable_farm_search", !disableFarmSearch);
                                        searchInputRef.current.focus();
                                        setSelectedSearchResultIndex(-1);
                                    }}
                                >
                                    <span id="nav-search-option-farms">Farms</span>
                                    <ActionLink className="BlockList-item-close">
                                        <i className="icon icon-cross" />
                                    </ActionLink>
                                </li>
                                <li
                                    className={`BlockList-item u-link ${disablePublicationSearch ? "" : "is-active"}`}
                                    onClick={() => {
                                        setFormState("quickSearch_disable_publication_search", !disablePublicationSearch);
                                        searchInputRef.current.focus();
                                        setSelectedSearchResultIndex(-1);
                                    }}
                                >
                                    <span id="nav-search-option-farms">Publications {isOverseerEd ? "" : "received"}</span>
                                    <ActionLink className="BlockList-item-close">
                                        <i className="icon icon-cross" />
                                    </ActionLink>
                                </li>
                                <li
                                    className={`BlockList-item u-link ${disableHelpArticleSearch ? "" : "is-active"}`}
                                    onClick={() => {
                                        setFormState("quickSearch_disable_help_article_search", !disableHelpArticleSearch);
                                        searchInputRef.current.focus();
                                        setSelectedSearchResultIndex(-1);
                                    }}
                                >
                                    <span id="nav-search-option-help-articles">Help articles</span>
                                    <ActionLink className="BlockList-item-close">
                                        <i className="icon icon-cross" />
                                    </ActionLink>
                                </li>
                            </ul>
                        </div>
                        <div className="QuickSearch-results">
                            {isOverseerEd && searchTextIsValid && !disableStudentSearch && <StudentSearchResults students={studentResults} selectedIndex={selectedSearchResultIndex} onSelected={() => toggleQuickSearch(false)} />}
                            {searchTextIsValid && !disableFarmSearch && <FarmSearchResults farms={farmResults} selectedIndex={selectedSearchResultIndex - (!isOverseerEd || disableStudentSearch ? 0 : studentResults.length)} onSelected={() => toggleQuickSearch(false)} />}
                            {searchTextIsValid && !disablePublicationSearch && <PublicationSearchResults publications={publicationResults} selectedIndex={selectedSearchResultIndex - (!isOverseerEd || disableStudentSearch ? 0 : studentResults.length) - (disableFarmSearch ? 0 : farmResults.length)} onSelected={() => toggleQuickSearch(false)} />}
                            {searchTextIsValid && !disableHelpArticleSearch && <HelpArticleSearchResults helpArticles={helpArticleResults} selectedIndex={selectedSearchResultIndex - (!isOverseerEd || disableStudentSearch ? 0 : studentResults.length) - (disableFarmSearch ? 0 : farmResults.length) - (disablePublicationSearch ? 0 : publicationResults.length)} />}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const searchKnowledgebase = (searchText) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const encodedSearchText = encodeURIComponent(searchText);
        return dispatch(
            api.get({
                url: `https://overseer-helpdesk.zendesk.com/api/v2/help_center/articles/search.json?query=${encodedSearchText}`,
                authorization: " ",
                suppressXUiVer: true,
                silentMode: true,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

const searchFarms = (searchText) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const encodedSearchText = encodeURIComponent(searchText);
        return dispatch(
            api.get({
                path: `search/farms?searchText=${encodedSearchText}`,
                silentMode: true,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

const searchPublications = (searchText) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const encodedSearchText = encodeURIComponent(searchText);
        return dispatch(
            api.get({
                silentMode: true,
                path: `publications/search?searchText=${encodedSearchText}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

const searchStudents = (searchText) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const encodedSearchText = encodeURIComponent(searchText);
        return dispatch(
            api.get({
                path: `accounts/students/search?searchText=${encodedSearchText}`,
                silentMode: true,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export default compose(
    withAppContext,
    withActions({ searchKnowledgebase, searchFarms, searchPublications, searchStudents, updateMyOrg, launchModal, setFormState }),
    withState((state) => ({ formState: state.app.formState }))
)(QuickSearch);
