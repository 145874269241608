import Alert from "components/Alert";
import { useFieldHelp } from "./useFieldHelp";

export default function SelectField({ input, options, label, placeholder, onChange, className, dataWidth, info, warning, tip, disabled, readOnly, required, meta }) {
    const [fieldHelpLink, fieldHelp] = useFieldHelp(tip);

    const handleOnChange = (e) => {
        if (input.onChange) input.onChange(e.target.value);

        if (onChange) onChange(e.target.value);
    };

    const optionGroups = (options || []).reduce((groups, option) => {
        const groupIndex = option.groupIndex !== undefined ? option.groupIndex : "";
        const groupLabel = option.groupLabel || "";
        groups[groupIndex] = groups[groupIndex] || [];
        groups[groupIndex].push({ ...option, groupLabel });
        return groups;
    }, {});

    const error = meta && meta.touched && meta.error;

    return (
        <div className={`Field ${error ? "has-error" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            <label htmlFor={input.name} className="Select Select--block">
                {label && (
                    <span className={`Field-label ${disabled && !readOnly ? "is-disabled" : ""}`}>
                        {label}
                        {required && <sup className="required">*</sup>}
                        {fieldHelpLink}
                    </span>
                )}
                <select {...input} id={input.name} disabled={disabled || readOnly} data-test-id={input.name} onChange={handleOnChange}>
                    {placeholder && (
                        <option value="" disabled={required}>
                            {placeholder}
                        </option>
                    )}
                    {Object.keys(optionGroups).map((key) => {
                        if (key === "") {
                            return optionGroups[key].map((option) => (
                                <option key={option.value} disabled={option.disabled} value={option.value}>
                                    {option.text}
                                </option>
                            ));
                        } else {
                            const groupLabel = optionGroups[key][0].groupLabel;
                            return (
                                <optgroup key={key} label={groupLabel}>
                                    {optionGroups[key].map((option) => (
                                        <option key={option.value} disabled={option.disabled} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </optgroup>
                            );
                        }
                    })}
                </select>
            </label>
            {error && (
                <div id={`${input.name}-error`} data-test-id={`${input.name}-error`} className="Field-error u-block u-mt-sm">
                    {error}
                </div>
            )}
            {warning && <Alert type="warning" text={warning} className="u-mb-0" />}
            {info && <Alert type="info" text={info} className="u-mb-0" />}
            {fieldHelp}
        </div>
    );
}
