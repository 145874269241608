import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import TextareaField from "components/FormFields/TextareaField";
import { useModal } from "common/hooks";
import { httpClient } from "common/httpClient";

export default function FarmAccessRequestResponseModal({ accessRequest, close }) {
    const acceptFarmAccessRequestAsync = useAcceptFarmAccessRequestAsync();
    const declineFarmAccessRequestAsync = useDeclineFarmAccessRequestAsync();

    const submitAsync = async (formValues) => {
        try {
            if (formValues.accepted) {
                await acceptFarmAccessRequestAsync(formValues);
            } else {
                await declineFarmAccessRequestAsync(formValues);
            }
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }

    return (
        <Form initialValues={{ ...accessRequest, requestedBy: accessRequest.requestedBy.accountName }} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const error = (values && values.error) || (!dirtySinceLastSubmit && submitError);
                const info = (
                    <>
                        <b>{accessRequest.requestedBy.accountName}</b> has requested <b>{accessRequest.role}</b> access to <b>{accessRequest.farmName}</b> farm. Review the request details and respond.
                    </>
                );
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Farm access request" close={close} submitting={submitting} fluid>
                            <ModalBody info={info} error={error}>
                                <Field name="id" type="hidden" component="input" />
                                <Grid>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="farmName" label="Requested farm" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="farmAddress" label="Farm address" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="role" label="Requested access" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="requestedBy" label="Requested by" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell>
                                        <Field name="comments" label="Comments" readOnly component={TextareaField} />
                                    </GridCell>
                                    <GridCell className="u-mt-md">
                                        <h3>
                                            If you <u>accept</u> this request...
                                        </h3>
                                        <ul className="disc">
                                            <li>
                                                All users of the <b>{accessRequest.requestedBy.accountName}</b> organisation will be granted <b>{accessRequest.role}</b> access to the <b>{accessRequest.farmName}</b> farm and its year end analysis data
                                            </li>
                                        </ul>
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                    <Button id="decline" submit onClick={() => form.change("accepted", false)} tertiary disabled={submitting}>
                                        Decline
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="accept" submit onClick={() => form.change("accepted", true)} primary disabled={submitting}>
                                        Accept
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useFarmAccessRequestResponseModal() {
    const [modal, openModal] = useModal(FarmAccessRequestResponseModal);

    const openFarmAccessRequestResponseModal = (accessRequest) => {
        const modalProps = {
            accessRequest
        };
        openModal(modalProps);
    };

    return [modal, openFarmAccessRequestResponseModal];
}

function useAcceptFarmAccessRequestAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (accessRequest) => {
            try {
                await httpClient.put(`farmAccessRequests/${accessRequest.id}/accept`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 400) {
                    throw new Error("This access request is no longer valid.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        }
    });

    return (accessRequest) => mutation.mutateAsync(accessRequest);
}

function useDeclineFarmAccessRequestAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (accessRequest) => {
            try {
                await httpClient.put(`farmAccessRequests/${accessRequest.id}/decline`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 400) {
                    throw new Error("This access request is no longer valid.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
            queryClient.invalidateQueries({ queryKey: ["farm-details", variables.farmId] });
        }
    });

    return (accessRequest) => mutation.mutateAsync(accessRequest);
}
