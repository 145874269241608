import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Link } from "react-router-dom";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import Alert from "components/Alert";
import SavePrompt from "components/SavePrompt";
import FertiliserApplicationActivities from "./FertiliserApplicationActivities";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { Button } from "components/Button";

export default function Fertiliser({ farm, analysis }) {
    const initialValues = (analysis.ghg || {}).fertiliser;
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const referrer = `/app/farm/${farm.id}/analysis/${analysis.id}/ghg`;

    const _submit = async (values) => {
        const updatedAnalysis = {
            ...analysis,
            ghg: {
                ...analysis.ghg,
                fertiliser: values,
            }
        }
        await updateAnalysisAsync(updatedAnalysis);
    };

    return (
        <Form initialValues={initialValues} mutators={{ ...arrayMutators }} onSubmit={_submit}>
            {({ form, values, dirty, submitting, submitSucceeded, handleSubmit }) => {
                const hasValues = Object.keys(values).length > 0;
                return (
                    <form onSubmit={handleSubmit}>
                        <SavePrompt blockIf={dirty && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={referrer} />
                        <Tile title="GHG fertiliser application footprint" waiting={submitting} referrer={referrer}>
                            <Alert type="info" text="Fertilisers are assumed to be applied by contractors and are calculated using default application methods. Use this page override any of these default application methods." />
                            <TileBody waiting={submitting}>
                                <div className="Grid Grid--withGutter">
                                    <div className="Grid-cell u-lg-width1of1">
                                        <FertiliserApplicationActivities form={form} values={values} />
                                    </div>
                                </div>
                            </TileBody>
                            <TileFooter>
                                <div className="ButtonBar ButtonBar--fixed">
                                    <div className="ButtonBar-left">
                                        <Link to={referrer} id="cancel-button" className="Button Button--secondary">
                                            Cancel
                                        </Link>
                                    </div>
                                    <div className="ButtonBar-right">
                                        <Button submit primary id="save-button" waiting={submitting} disabled={!hasValues}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </TileFooter>
                        </Tile>
                    </form>
                );
            }}
        </Form>
    )
}
