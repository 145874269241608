import React, { Component } from "react";
import numeral from "numeral";
import * as utils from "common/utils";
import * as cu from "containers/BudgetHome/Crops/_utils";
import * as iu from "containers/BudgetHome/Irrigation/_utils";
import * as domain from "common/domain";
import Alert from "components/Alert";
import { Panel, PanelBody } from "components/Panel";
import Watermark from "components/Watermark";
import FarmSummary from "../Sections/Cards/FarmSummary";
import NameValueSplit from "../Sections/Tables/NameValueSplit";
import { farmDetailsViewModel } from "common/viewModels";
import ActionLink from "components/ActionLink";
import FeatureTracker, { FEATURES } from "components/FeatureTracker/FeatureTracker";
import { useRefData } from "common/hooks";
import { HelpTooltip, helpText } from "components/Help";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function CompareBlocks({ farm, analysis }) {
    const refData = useRefData();

    return <CompareBlocksClassComponent farm={farm} analysis={analysis} refData={refData} />
}

class CompareBlocksClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceType: domain.BlockType.ProductivePasture,
            options: OPTIONS.filter((o) => o.value === "all" || o.value === "other" || props.analysis.blocks.find((b) => b.type === o.value)),
        };
    }

    componentDidMount() {
        this.setState({ sourceType: this.state.options[0].value });
    }

    sourceTypeChanged = (sourceType) => {
        this.setState({
            sourceType: sourceType,
        });
    };

    getClimate = (farm, block, refData) => {
        const requiresClimate = utils.requiresClimate(block.type);

        let averageTemp = requiresClimate ? `Using farm climate (${farm.defaultClimate.averageTemp})` : "-";
        if (block.climate) {
            averageTemp = block.climate.averageTemp;
        }

        let averageRain = requiresClimate ? `Using farm climate (${farm.defaultClimate.averageRain.toLocaleString()})` : "-";
        if (block.climate) {
            averageRain = block.climate.averageRain.toLocaleString();
        }

        let annualPET = requiresClimate ? `Using farm climate (${farm.defaultClimate.annualPetInMM.toLocaleString()})` : "-";
        if (block.climate) {
            if (block.climate.annualPetInMM && block.climate.annualPetInMM > 0) {
                annualPET = block.climate.annualPetInMM.toLocaleString();
            } else if (block.climate.annualPET) {
                annualPET = utils.valueToText(refData.petValues, block.climate.annualPET);
            }
        }

        const lat = block.overrideClimateLocation && block.climateLatitude ? numeral(block.climateLatitude).format("0.0000") : "-";

        const lng = block.overrideClimateLocation && block.climateLongitude ? numeral(block.climateLongitude).format("0.0000") : "-";

        return { averageRain, averageTemp, annualPET, lat, lng };
    };

    downloadCsv = () => {
        const { farm, analysis, refData } = this.props;

        const fileName = `CompareBlocks-${farm.name}-${analysis.name}.csv`;

        const header = ["FarmName", "SupplierNumber", "AnalysisName", "BlockName", "BlockType", "BlockAreaHa", "Topography", "AvgRainMillimetresPerYear", "AvgTempCelcius", "AnnualPETMillimetresPerYear", "ClimateLat", "ClimateLng", "NLossKg", "NLossKgPerHa", "NDrainagePPM", "NAddedKgPerHa", "NSurplusKgPerHa", "NFertiliserKgPerHa", "NIrrigationKgPerHa", "NEffluentKgPerHa", "PLossKg", "PLossKgPerHa", "FarmTotalAreaHa", "FarmBlockedAreaHa", "FarmNLossKg", "FarmNLossKgPerHa", "FarmPLossKg", "FarmPLossKgPerHa", "PercentageOfFarmBlockedArea", "NLossKgPercentageOfFarm", "PLossKgPercentageOfFarm"];

        const analysisResults = analysis.currentResults || {};
        const analysisNResults = analysisResults.nitrogenOverview || {};
        const analysisPResults = analysisResults.phosphorusOverview || {};

        const body = analysis.blocks.map((block) => {
            const blockResults = block.currentResults || {};
            const blockNResults = blockResults.nitrogenOverview || {};
            const blockPResults = blockResults.phosphorusOverview || {};
            const blockNNutrientBudget = block.currentResults.nutrientBudget.nutrientCategories || {};
            const blockType = utils.getBlockTypeText(refData, block, false);
            const blockArea = block.areaInHectares || block.area || block.rotationArea;
            const topography = block.pasture && block.pasture.topography ? utils.valueToText(refData.slopes, block.pasture.topography) : "-";
            const climate = this.getClimate(farm, block, refData);
            const drainage = blockNResults.drainage && !isNaN(blockNResults.drainage) ? blockNResults.drainage : "-";
            const percentageOfFarmBlockedArea = block.type === "FodderCrop" ? "-" : utils.round((blockArea / analysis.totalBlockAreaInHectares) * 100, 0);
            const percentageOfFarmN = utils.round((blockNResults.totalLost / analysisNResults.totalLost) * 100, 0);
            const percentageOfFarmP = utils.round((blockPResults.totalLost / analysisPResults.totalLost) * 100, 0);

            const bodyValues = [
                farm.name.replace(/,/g, ""),
                farm.supplierNumber?.replace(/,/g, ""),
                analysis.name.replace(/,/g, ""),
                block.name.replace(/,/g, ""),
                blockType,
                blockArea,
                topography,
                climate.averageRain.replace(/,/g, ""),
                climate.averageTemp,
                climate.annualPET.replace(/,/g, ""),
                climate.lat,
                climate.lng,
                blockNResults.totalLost,
                blockNResults.lostToWater,
                drainage,
                blockNResults.added,
                blockNResults.surplus,
                blockNNutrientBudget?.Fertiliser?.total?.nutrients?.N,
                blockNNutrientBudget?.Irrigation?.total?.nutrients?.N,
                blockNNutrientBudget?.Effluent?.total?.nutrients?.N,
                blockPResults.totalLost,
                blockPResults.lostToWater,
                analysis.totalFarmArea,
                analysis.totalBlockAreaInHectares,
                analysisNResults.farmTotalLoss,
                analysisNResults.farmLossPerHa,
                analysisPResults.farmTotalLoss,
                analysisPResults.farmLossPerHa,
                percentageOfFarmBlockedArea,
                percentageOfFarmN,
                //No line wrap
                percentageOfFarmP,
            ];
            return bodyValues.join(",");
        });

        const csv = header.join(",") + "\n" + body.join("\n");

        var blob = new Blob([csv], { type: "text/csv" });
        var url = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    render() {
        const { farm, analysis, refData } = this.props;
        const { options, sourceType } = this.state;

        const soils = analysis.useFarmSoils ? farm.soils : analysis.soils;
        const soilMap = new Map(soils.map((s) => [s.id, { label: `${s.subTitle || s.title}${s.name ? " - " + s.name : ""}`, soil: s }]));
        const drawnAreas = iu.drawnAreas(analysis);
        const blocks = utils.getBlockList(analysis.blocks);
        let blockList = [];

        if (sourceType === OPTIONS[0].value) blockList = blocks.productiveBlocks.concat(blocks.nonProductiveBlocks);
        else if (sourceType === OPTIONS[5].value) blockList = blocks.nonProductiveBlocks;
        else blockList = blocks.productiveBlocks.filter((b) => b.type === sourceType);

        let blockNames = [];
        let farmData = [];
        farmData.blockDetails = {
            title: "Block details",
            attributes: [
                { label: "Type", values: [] },
                { label: "Area (ha)", values: [] },
                { label: "Topography", values: [] },
                { label: "Climate lat", values: [] },
                { label: "Climate long", values: [] },
                { label: "Rainfall (mm/yr)", values: [] },
                { label: "Temperature (˚ C)", values: [] },
                { label: "Annual PET (mm/yr)", values: [] },
            ],
        };
        farmData.soil = {
            title: "Soil",
            attributes: [
                { label: "Soil", values: [] },
                { label: "Percentage of block", values: [] },
                { label: "Modified?", values: [] },
                { label: "Default test?", values: [] },
                { label: "ASC", values: [] },
                { label: "TBK reserve K", values: [] },
            ],
        };

        farmData.crops = {
            title: "Pasture/crops",
            subTitle: "Crop yield is only representative of crops sown in the reporting year. Pasture growth, intake supplements removed, utilisation and RSU only includes pasture and pasture seed crops.",
            attributes: [
                { label: "Pasture/crop", values: [] },
                { label: "Yield", values: [] },
                { label: "Pasture growth (kg DM/ha)", tooltip: helpText.pastureGrowth, values: [] },
                { label: "Pasture intake (kg DM/ha)", tooltip: helpText.pastureIntake, values: [] },
                { label: "Supplements removed (kg DM/ha)", values: [] },
                { label: "Pasture utilisation (%)", values: [] },
                { label: "Total RSU/ha", values: [] },
            ],
        };

        farmData.animals = {
            title: "Animal enterprise RSU from pasture eaten",
            attributes: analysis.enterprises.map((e) => {
                return { id: e.id, label: utils.valueToText(refData.enterpriseTypes, e.type), values: [] };
            }),
        };

        farmData.fertiliser = {
            title: "Maintenance fertiliser",
            subTitle: "Check whether soil test values for the blocks are at the economic optimum before using a maintenance fertiliser rate. Rates lower than maintenance may be required if soil test levels are above optimum.",
            sections: [
                {
                    sectionTitle: (
                        <>
                            Nutrients required (kg/ha/yr) <sup>1</sup>
                        </>
                    ),
                    attributes: [
                        { label: "P", values: [] },
                        { label: "K", values: [] },
                        { label: "S", values: [] },
                        { label: "Ca", values: [] },
                        { label: "Mg", values: [] },
                        { label: "Na", values: [] },
                        {
                            label: (
                                <span>
                                    Lime <sup>2</sup>
                                </span>
                            ),
                            values: [],
                        },
                    ],
                    subscript: (
                        <>
                            <div>
                                <sub>1 - Assumes effluent from FDE and feedpads is still added to effluent blocks</sub>
                            </div>
                            <div>
                                <sub>2 - Maintenance lime is the amount of lime required to neutralise any increase in acidity. The value is in pure lime - divide by lime purity to get application rates</sub>
                            </div>
                        </>
                    ),
                },
                /*
                {
                    sectionTitle: <>Nutrients transfered to camp (kg/ha/yr) <sup>1</sup></>,
                    attributes: [{ label: "P", values: [] }, { label: "K", values: [] }, { label: "S", values: [] }, { label: "Ca", values: [] }, { label: "Mg", values: [] }, { label: "Na", values: [] }],
                    subscript: <>
                        <div><sub>1 - The internal transfer of nutrients from the main part of the block to the camp sites that is included in maintenance nutrient requirements</sub></div>
                    </>
                },
                {
                    sectionTitle: "Predicted relative yield (%)",
                    attributes: [{ label: "P", values: [] }, { label: "K", values: [] }, { label: "S", values: [] }, { label: "Ca", values: [] }, { label: "Mg", values: [] }, { label: "Na", values: [] }, { label: "All", values: [] }]
                }
                */
            ],
        };

        farmData.irrigation = { title: "Irrigation", attributes: [], irrigators: [] };
        /* eslint-disable no-unused-vars */
        for (const irrigator of analysis.irrigators) {
            farmData.irrigation.irrigators.push(irrigator.name + " (" + drawnAreas.find((ia) => ia.irrigatorId === irrigator.id).area + " ha)");
            farmData.irrigation.attributes.push({ id: irrigator.id, label: "Supplied", values: [] });
            farmData.irrigation.attributes.push({ id: irrigator.id, label: "Applied", values: [] });
        }

        farmData.nitrogen = {
            title: "Nitrogen summary",
            attributes: [
                { label: "Total loss (kg)", values: [] },
                { label: "Loss per ha (kg/ha)", values: [] },
                { label: "N in drainage (ppm)", values: [] },
                { label: "Added (kg/ha)", values: [] },
                { label: "Surplus (kg/ha)", values: [] },
                { label: "Fertiliser (kg/ha)", values: [] },
                { label: "Irrigation (kg/ha)", values: [] },
                { label: "Effluent (kg/ha)", values: [] },
                { label: "Blocked area %", values: [] },
                { label: "Farm loss %", values: [] },
            ],
        };
        farmData.phosphorus = {
            title: "Phosphorus summary",
            attributes: [
                { label: "Total loss (kg)", values: [] },
                { label: "Loss per ha (kg/ha)", values: [] },
                { label: "Fertiliser (kg/ha)", values: [] },
                { label: "Irrigation (kg/ha)", values: [] },
                { label: "Effluent (kg/ha)", values: [] },
                { label: "Olsen P", values: [] },
            ],
        };

        farmData.soilIrrigation = {
            title: "Soil and irrigation results",
            attributes: [
                { label: "Soil", values: [] },
                { label: "Irrigation", values: [] },
                { label: "Percentage", values: [] },
                { label: "N Loss", values: [] },
                { label: "N Loss/Ha", values: [] },
                { label: "P Loss", values: [] },
                { label: "P Loss/Ha", values: [] },
                { label: "Drainage", values: [] },
                { label: "PAW 60cm", values: [] },
                { label: "PAW 150cm", values: [] },
            ],
        };

        const analysisResults = analysis.currentResults || {};
        const analysisNResults = analysisResults.nitrogenOverview || {};

        let blockSoils = [];

        blockList.map((b, i) => {
            var cropYield = "-";
            var crop = "-";

            if (b.pasture) {
                crop = utils.valueToText(refData.pastureTypes, b.pasture.pastureCategory);
                crop = b.pasture.plantainPercentage ? crop + ` (${b.pasture.plantainPercentage}%)` : crop;
            } else if (b.fruit) {
                crop = b.fruit.cropType;
                const yieldData = (refData && refData.fruitTypes && refData.fruitTypes[b.fruit.cropType]) || undefined;
                cropYield = b.fruit.productYield + (yieldData ? " " + yieldData.unitsDescription : "");
            } else if (b.crops && b.crops.length > 0) {
                crop = b.crops.map((c) => (c.name ? c.name : cu.cropSubHeading(c, refData)) + " | ");
                const yieldAmount = b.crops.filter((c) => c.isInReportingYear).reduce((sum, c) => (sum += isNaN(c.productYield) ? 0 : c.productYield), 0);
                cropYield = yieldAmount > 0 ? numeral(yieldAmount).format(0.0) + " T DM/Ha" : "-";
            }

            //var drainageType = 'None'
            //if (b.drainageDetailsId) {
            //    const drainage = analysis.drainageSystems.find(d => d.drainage.find(db => db.id === b.drainageDetailsId))
            //    if (drainage) drainageType = drainage.drainageMethod
            //}
            const blockResults = b.currentResults ? b.currentResults : {};
            const blockNResults = blockResults.nitrogenOverview || {};
            const blockPResults = blockResults.phosphorusOverview || {};
            const blockPastureResults = blockResults.pastureResults || {};
            const blockIrrigationResults = blockResults.irrigationResults || {};
            const blockNutrientApplied = (blockResults.nutrientBudget && blockResults.nutrientBudget.nutrientCategories) || {};

            blockSoils[i] =
                analysis.farmSoilBlocks &&
                analysis.farmSoilBlocks
                    .filter((fsb) => fsb.blockId === b.id)
                    .map((fsb) => {
                        const soil = soilMap.get(fsb.soilId);
                        const test = b.soilTestId && analysis.soilTests.find((t) => t.id === b.soilTestId);

                        const ob = {
                            soil: soil && soil.label,
                            percentage: fsb.percentageOfBlock,
                            modified: soil && utils.soilModified(soil.soil) ? "Yes" : "No",
                            defaultTest: test && test.defaultTest ? "Yes" : "No",
                            asc: test ? (test.asc > 0 ? test.asc : fsb.defaultASC || "-") : "-",
                            tbkReserveK: test ? (test.tbkReserveK > 0 ? test.tbkReserveK : test.kReserveStatusOption || fsb.defaultTBKReserveK || "-") : "-",
                            p: test && test.nutrients["P"] ? test.nutrients["P"] : "-",
                            irrigation: [{}],
                        };

                        if (blockResults.soilIrrigation && blockResults.soilIrrigation.length > 0) {
                            ob.irrigation = blockResults.soilIrrigation
                                .filter((n) => n.farmSoilBlockId === fsb.id)
                                .map((si) => {
                                    const { nitrogen = {}, phosphorus = {}, otherValues = {} } = si;
                                    const irrigator = analysis.irrigators.find((i) => i.id === si.irrigatorId);
                                    return { irrigator: irrigator ? irrigator.name : "no irrigation", percentage: si.percentageOfBlock, totalN: nitrogen.loss, nPerha: nitrogen.lossPerHa, totalP: phosphorus.loss, pPerha: phosphorus.lossPerHa, drainage: otherValues.annualDrainage, paw60: otherValues.paW60, paw150: otherValues.paW150 };
                                });
                        }
                        return ob;
                    });

            blockNames[i] = b.name;
            farmData.blockDetails.attributes[0].values[i] = utils.valueToText(refData.blockTypes, b.type);
            farmData.blockDetails.attributes[1].values[i] = b.type === domain.BlockType.FodderCrop ? b.rotationArea : b.areaInHectares;
            farmData.blockDetails.attributes[2].values[i] = b.pasture ? utils.valueToText(refData.slopes, b.pasture.topography) : "-";
            farmData.blockDetails.attributes[3].values[i] = b.overrideClimateLocation && b.climateLatitude ? numeral(b.climateLatitude).format("0.0000") : "-";
            farmData.blockDetails.attributes[4].values[i] = b.overrideClimateLocation && b.climateLongitude ? numeral(b.climateLongitude).format("0.0000") : "-";

            const climate = this.getClimate(farm, b, refData);

            farmData.blockDetails.attributes[5].values[i] = climate.averageRain;
            farmData.blockDetails.attributes[6].values[i] = climate.averageTemp;
            farmData.blockDetails.attributes[7].values[i] = climate.annualPET;

            if (b.isProductive) {
                farmData.crops.attributes[0].values[i] = crop;
                farmData.crops.attributes[1].values[i] = cropYield;
                farmData.crops.attributes[2].values[i] = blockPastureResults.pastureGrowth;
                farmData.crops.attributes[3].values[i] = b.type === "ProductiveOutdoorPigs" ? "-" : blockPastureResults.pastureIntake;
                farmData.crops.attributes[4].values[i] = blockPastureResults.supplementsRemoved;
                farmData.crops.attributes[5].values[i] = blockPastureResults.pastureUtilisation;
                farmData.crops.attributes[6].values[i] = blockPastureResults.totalPastoralRsu;

                farmData.animals.attributes.forEach((e) => {
                    if (blockPastureResults.pastureRsu && blockPastureResults.pastureRsu[e.id]) {
                        e.values[i] = numeral(blockPastureResults.pastureRsu[e.id].reduce((sum, m) => (sum += m.value), 0)).format("0.00");
                    } else e.values[i] = "-";
                });

                const maintenanceNutrientResults = b.currentResults && b.currentResults.maintenanceNutrientResults;
                if (maintenanceNutrientResults) {
                    const fertiliserNutrients = maintenanceNutrientResults.fertiliserNutrients;
                    if (fertiliserNutrients) {
                        const nutrientsRequiredSection = farmData.fertiliser.sections[0];
                        nutrientsRequiredSection.attributes[0].values[i] = fertiliserNutrients.P || "-";
                        nutrientsRequiredSection.attributes[1].values[i] = fertiliserNutrients.K || "-";
                        nutrientsRequiredSection.attributes[2].values[i] = fertiliserNutrients.S || "-";
                        nutrientsRequiredSection.attributes[3].values[i] = fertiliserNutrients.Ca || "-";
                        nutrientsRequiredSection.attributes[4].values[i] = fertiliserNutrients.Mg || "-";
                        nutrientsRequiredSection.attributes[5].values[i] = fertiliserNutrients.Na || "-";
                        nutrientsRequiredSection.attributes[6].values[i] = fertiliserNutrients.H || "-";
                    }
                    const transferToCamp = maintenanceNutrientResults.transferToCamp && false;
                    if (transferToCamp) {
                        const transferToCampSection = farmData.fertiliser.sections[1];
                        transferToCampSection.attributes[0].values[i] = transferToCamp.P || "-";
                        transferToCampSection.attributes[1].values[i] = transferToCamp.K || "-";
                        transferToCampSection.attributes[2].values[i] = transferToCamp.S || "-";
                        transferToCampSection.attributes[3].values[i] = transferToCamp.Ca || "-";
                        transferToCampSection.attributes[4].values[i] = transferToCamp.Mg || "-";
                        transferToCampSection.attributes[5].values[i] = transferToCamp.Na || "-";
                    }
                    const predictedRelativeYield = maintenanceNutrientResults.predictedRelativeYield && false;
                    if (predictedRelativeYield) {
                        const predictedRelativeYieldSection = farmData.fertiliser.sections[2];
                        predictedRelativeYieldSection.attributes[0].values[i] = predictedRelativeYield.P || "-";
                        predictedRelativeYieldSection.attributes[1].values[i] = predictedRelativeYield.K || "-";
                        predictedRelativeYieldSection.attributes[2].values[i] = predictedRelativeYield.S || "-";
                        predictedRelativeYieldSection.attributes[3].values[i] = predictedRelativeYield.Ca || "-";
                        predictedRelativeYieldSection.attributes[4].values[i] = predictedRelativeYield.Mg || "-";
                        predictedRelativeYieldSection.attributes[5].values[i] = predictedRelativeYield.Na || "-";
                        predictedRelativeYieldSection.attributes[6].values[i] = maintenanceNutrientResults.predictedRelativeYieldAll || "-";
                    }
                }

                for (var j = 0; j < farmData.irrigation.attributes.length; j++) {
                    farmData.irrigation.attributes[j].values[i] = blockIrrigationResults[farmData.irrigation.attributes[j].id] ? blockIrrigationResults[farmData.irrigation.attributes[j].id].totalSupplied : "-";
                    j++;
                    farmData.irrigation.attributes[j].values[i] = blockIrrigationResults[farmData.irrigation.attributes[j].id] ? blockIrrigationResults[farmData.irrigation.attributes[j].id].totalApplied : "-";
                }
            }

            farmData.nitrogen.attributes[0].values[i] = blockNResults.totalLost;
            farmData.nitrogen.attributes[1].values[i] = blockNResults.lostToWater;
            farmData.nitrogen.attributes[2].values[i] = blockNResults.drainage ? (blockNResults.drainage.isNaN ? "-" : blockNResults.drainage) : "-";
            farmData.nitrogen.attributes[3].values[i] = blockNResults.added;
            farmData.nitrogen.attributes[4].values[i] = blockNResults.surplus;
            farmData.nitrogen.attributes[5].values[i] = blockNutrientApplied.Fertiliser && blockNutrientApplied.Fertiliser.total.nutrients.N ? blockNutrientApplied.Fertiliser.total.nutrients.N : 0;
            farmData.nitrogen.attributes[6].values[i] = blockNutrientApplied.Irrigation && blockNutrientApplied.Irrigation.total.nutrients.N ? blockNutrientApplied.Irrigation.total.nutrients.N : 0;
            farmData.nitrogen.attributes[7].values[i] = blockNutrientApplied.Effluent && blockNutrientApplied.Effluent.total.nutrients.N ? blockNutrientApplied.Effluent.total.nutrients.N : 0;
            farmData.nitrogen.attributes[8].values[i] = b.type === "FodderCrop" ? "-" : utils.round((b.areaInHectares / analysis.totalBlockAreaInHectares) * 100, 0);
            farmData.nitrogen.attributes[9].values[i] = utils.round((blockNResults.totalLost / analysisNResults.totalLost) * 100, 0);

            farmData.phosphorus.attributes[0].values[i] = blockPResults.totalLost;
            farmData.phosphorus.attributes[1].values[i] = blockPResults.lostToWater;
            farmData.phosphorus.attributes[2].values[i] = blockNutrientApplied.Fertiliser && blockNutrientApplied.Fertiliser.total.nutrients.P ? blockNutrientApplied.Fertiliser.total.nutrients.P : 0;
            farmData.phosphorus.attributes[3].values[i] = blockNutrientApplied.Irrigation && blockNutrientApplied.Irrigation.total.nutrients.P ? blockNutrientApplied.Irrigation.total.nutrients.P : 0;
            farmData.phosphorus.attributes[4].values[i] = blockNutrientApplied.Effluent && blockNutrientApplied.Effluent.total.nutrients.P ? blockNutrientApplied.Effluent.total.nutrients.P : 0;
            farmData.phosphorus.attributes[5].values[i] = blockSoils && blockSoils.length > i && blockSoils[i].length > 0 ? blockSoils[i][0].p : "-";
            return farmData;
        });

        return (
            <>
                <FeatureTracker feature={FEATURES.COMPARE_BLOCKS} farm={farm} />
                <Alert className="u-print-none" type="info" text="You can print this page by selecting print under the browser menu. For PDF, change the destination to PDF. Landscape is recommended for the block attributes, while portrait is recommended for nutrient budgets." />

                <Panel
                    title="Compare blocks"
                    actions={
                        <ActionLink id="download-compare-blocks" className="IconLink--download u-link u-textWhite" onClick={this.downloadCsv}>
                            Download as csv
                        </ActionLink>
                    }
                    skyBlue
                >
                    <PanelBody>
                        <ul className="SubMenu">
                            {options.map((o, i) => (
                                <li key={i} className={`SubMenu-item u-print-none ${o.value === sourceType ? "is-active" : ""}`}>
                                    <ActionLink onClick={() => this.sourceTypeChanged(o.value)}>
                                        <span>{o.text}</span>
                                    </ActionLink>
                                </li>
                            ))}
                        </ul>

                        <div className="u-mt-md u-pos-rel u-overflow-hidden">
                            <Watermark />
                            <FarmSummary farm={farm} analysis={analysis} />
                            <div className="u-pt-0">
                                <NameValueSplit nameValues={farmDetailsViewModel(analysis, refData)} splitBy={1} />
                            </div>
                        </div>

                        <Watermark />

                        {blockList.length === 0 && <Alert type="info" text="There are no blocks of this type on this farm." />}
                        {blockList.length > 0 &&
                            Object.keys(farmData)
                                .filter((s) => {
                                    const sections = farmData[s].sections || [farmData[s]];
                                    return sections.some((section) => {
                                        const hasSoilValues = ["soilIrrigation", "soil"].includes(s) && blockSoils.some((bs) => bs);
                                        const hasNonSoilValues = (section.attributes || []).some((attribute) => (attribute.values || []).length > 0);
                                        return hasSoilValues || hasNonSoilValues;
                                    });
                                })
                                .map((s, i) => {
                                    const sections = farmData[s].sections || [farmData[s]];
                                    return (
                                        <div className="FarmTable-supplementary Table-Compressed u-page-break-avoid" key={`${s}-${i}_1`}>
                                            <h3 className="u-mt-lg">{farmData[s].title}</h3>
                                            {farmData[s].subTitle && <p>{farmData[s].subTitle}</p>}
                                            {sections
                                                .filter((section) => section.attributes.length > 0)
                                                .map((section, sectionIndex) => (
                                                    <React.Fragment key={`${section.title}-${sectionIndex}_2`}>
                                                        <div className={`Table u-mb-0 ${sectionIndex > 0 ? "u-mt-lg" : "u-mt-sm"}`}>
                                                            <table>
                                                                <thead>
                                                                    {s === "irrigation" && (
                                                                        <tr>
                                                                            <th className="th--shrink"></th>
                                                                            {section.irrigators.map((i, j) => (
                                                                                <th key={s + "-name-" + i + "-heading_3"} className="u-textCenter" colSpan="2">
                                                                                    {i}
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    )}
                                                                    <tr key={`${section.title}-${sectionIndex}_head_4`}>
                                                                        <th className={`th--shrink ${s === "fertiliser" ? "h3" : ""}`}>{section.sectionTitle}</th>
                                                                        {section.attributes.map((a, j) => (
                                                                            <th className="u-textCenter" key={s + "-" + a.label + "-heading_5" + j} data-width={s === "soil" || s === "soilIrrigation" ? "md-5 xl-5" : "md-10 xl-10"}>
                                                                                {a.label}
                                                                                <HelpTooltip tip={a.tooltip} />
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {s === "soil" &&
                                                                        blockSoils.map((blockSoil, x) => {
                                                                            return (
                                                                                blockSoil &&
                                                                                blockSoil.map((soil, y) => {
                                                                                    return (
                                                                                        <tr key={soil.soil + "-" + y + "_6"}>
                                                                                            <th>{y === 0 && blockNames[x]}</th>
                                                                                            <td className="u-textCenter">{soil.soil}</td>
                                                                                            <td className="u-textCenter">{soil.percentage}%</td>
                                                                                            <td className="u-textCenter">{soil.modified}</td>
                                                                                            <td className="u-textCenter">{soil.defaultTest}</td>
                                                                                            <td className="u-textCenter">{soil.asc}</td>
                                                                                            <td className="u-textCenter">{soil.tbkReserveK}</td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            );
                                                                        })}
                                                                    {s === "soilIrrigation" &&
                                                                        blockSoils.map((blockSoil, x) => {
                                                                            return (
                                                                                blockSoil &&
                                                                                blockSoil.map((soil, y) => {
                                                                                    return (
                                                                                        soil.irrigation &&
                                                                                        soil.irrigation.map((irrigator, z) => {
                                                                                            return (
                                                                                                <tr key={soil.soil + "-" + irrigator.name + "-" + z + "_7"}>
                                                                                                    <th>{y === 0 && z === 0 && blockNames[x]}</th>
                                                                                                    <td className="u-textCenter">{z === 0 && soil.soil}</td>
                                                                                                    <td className="u-textCenter">{irrigator.irrigator}</td>
                                                                                                    <td className="u-textCenter">{utils.round(irrigator.percentage || 0, 1).toLocaleString()}</td>
                                                                                                    <td className="u-textCenter">{utils.round(irrigator.totalN || 0, 1).toLocaleString()}</td>
                                                                                                    <td className="u-textCenter">{utils.round(irrigator.nPerha || 0, 1).toLocaleString()}</td>
                                                                                                    <td className="u-textCenter">{utils.round(irrigator.totalP || 0, 1).toLocaleString()}</td>
                                                                                                    <td className="u-textCenter">{utils.round(irrigator.pPerha || 0, 1).toLocaleString()}</td>
                                                                                                    <td className="u-textCenter">{utils.round(irrigator.drainage || 0, 1).toLocaleString()}</td>
                                                                                                    <td className="u-textCenter">{utils.round(irrigator.paw60 || 0, 1).toLocaleString()}</td>
                                                                                                    <td className="u-textCenter">{utils.round(irrigator.paw150 || 0, 1).toLocaleString()}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        })
                                                                                    );
                                                                                })
                                                                            );
                                                                        })}
                                                                    {s !== "soil" &&
                                                                        s !== "soilIrrigation" &&
                                                                        section.attributes[0].values.map((v, k) => (
                                                                            <tr key={s + "-" + k + "_8"}>
                                                                                <th className="th--shrink">{blockNames[k]}</th>
                                                                                {section.attributes.map((a, j) => (
                                                                                    <td key={j + "_9"} className="u-textCenter">
                                                                                        {section.attributes[j].values[k]}
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {section.subscript && <div className="u-mb-sm">{section.subscript}</div>}
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    );
                                })}
                    </PanelBody>
                </Panel>
            </>
        );
    }
}

const OPTIONS = [
    { value: "all", text: "All", checked: false },
    { value: domain.BlockType.ProductivePasture, text: "Pasture", checked: true },
    { value: domain.BlockType.ProductiveFruit, text: "Fruit", checked: false },
    { value: domain.BlockType.ProductiveCrop, text: "Crop", checked: false },
    { value: domain.BlockType.ProductiveOutdoorPigs, text: "Outdoor pigs", checked: false },
    { value: "other", text: "Other", checked: false },
];
