import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { Panel, PanelBody } from "components/Panel";
import NoData from "components/NoData/NoData";
import { UserList } from "containers/Admin/Users";

export default function OrgUsers({ org }) {
    const { isLoading, error, users} = useOrgUsers(org?.id);
    const hasData = users?.length > 0;

    return (
        <Panel error={error}>
            <PanelBody loading={isLoading}>
                {hasData
                    ? <UserList org={org} users={users} />
                    : <NoData />
                }
            </PanelBody>
        </Panel>
    );
};

function useOrgUsers(orgId) {
    const query = useQuery({
        queryKey: ["orgs", orgId, "users"],
        queryFn: async () => httpClient.get(`admin/accounts/${orgId}/users`),
        retry: false
    });

    return {
        isLoading: query.isLoading,
        error: query.error?.message,
        users: query.data?.users?.map(user => ({ ...user, accountId: orgId })),
    };
}
