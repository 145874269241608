import { useRefData } from "common/hooks";
import { cropViewModel } from "common/viewModels";
import Alert from "components/Alert";
import { FileSummary } from "components/PlanningReport";
import CropsSummary from "./CropsSummary";

export default function Crops({ before, after, showFileComparison }) {
    const refData = useRefData();
    const actualCrops = cropViewModel(after.details, refData);
    const baseCrops = before && cropViewModel(before.details, refData);
    const uniqueKeys = Array.from(new Set([...Object.keys(actualCrops), ...(before ? Object.keys(baseCrops) : [])]));

    if (uniqueKeys.length === 0) {
        return (
            <div className="u-pt-lg">
                <Alert type="info" text="There is no crop information for this analysis" />
            </div>
        );
    }

    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <CropsSummary before={before} after={after} />
        </div>
    )
}
