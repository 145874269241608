import { Link } from "react-router-dom";
import * as utils from "common/utils";
import TileBodyMessage from "components/TileBodyMessage";
import { Panel, PanelBody } from "components/Panel";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import ActionLink from "components/ActionLink";
import { useFarmGroupInvitationResponseModal } from "containers/FarmGroups/FarmGroupInvitationResponseModal";
import { ZendeskLink } from "components/Help";
import Page from "components/Page";
import PageBody from "components/PageBody";
import FarmHomePageHead from "containers/FarmHome/FarmHomePageHead";
import { VIS_HELP } from "containers/FarmGroups/FarmGroupDetails";
import { useOnline, useRefData } from "common/hooks";
import { useFarmGroupsByFarm } from "containers/hooks";

export default function FarmGroups({ farm }) {
    const online = useOnline();
    const { farmGroupVisibility = [] } = useRefData();
    const { isLoading, data: farmGroups, error } = useFarmGroupsByFarm(farm.id);
    const canRespondToFarmGroupInvite = utils.canManageAccess(farm);
    const [farmGroupInvitationResponseModal, openFarmGroupInvitationResponseModal] = useFarmGroupInvitationResponseModal();

    const respondToInvitation = (farmGroup) => () => {
        openFarmGroupInvitationResponseModal(farmGroup);
    };

    const breadcrumb = [{ name: farm.name, to: `/app/farm/${farm.id}` }, { name: "Farm groups" }];

    const info = (
        <>
            <div>The following is a list of groups that this farm belongs to. If your access allows, you can view aggregated reporting and benchmarking for the group by selecting the group from the list. </div>
            <div className="u-pb-md">
                <div className="h5 u-pb-sm u-pt-md">Farm group visibility</div>
                {VIS_HELP}
            </div>
            <div>
                To see a user guide on farm groups,{" "}
                <b>
                    <ZendeskLink title="click here" url="https://support.overseer.org.nz/hc/en-us/articles/900000809586" target="_blank" rel="noopener noreferrer" id="lnk-open-guide" />
                </b>
            </div>
        </>
    );

    return (
        <Page>
            <FarmHomePageHead breadcrumb={breadcrumb} />
            <PageBody>
                <Panel title="Farm groups" iconClassName="IconLink--users" info={info} error={error} referrer={`/app/farm/${farm.id}`}>
                    <PanelBody loading={isLoading} waiting={isLoading}>
                        <>
                            {farmGroups && farmGroups.length > 0 && (
                                <SortableTable data={farmGroups} defaultSortCriteria={{ fieldName: "createdOn", fieldType: "date", desc: true }}>
                                    {({ data, sortCriteria, handleSort }) => {
                                        return (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <SortableTableHeader label="Name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                        <SortableTableHeader label="Created" fieldName="createdOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                        <th>Group administrator</th>
                                                        <th>Visibility</th>
                                                        <th>Your farm role</th>
                                                        <SortableTableHeader label="Invited" fieldName="invitedCount" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                        <SortableTableHeader label="Members" fieldName="memberCount" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                                        <th>Membership status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((farmGroup) => {
                                                        const hasPrivateAccess = farmGroup.farmGroupVisibility === "Private" && farmGroup.role === "Admin";
                                                        const hasOwnerAccess = farmGroup.farmGroupVisibility === "Owner" && farm.role === "Owner";
                                                        const noOwnerAccess = farmGroup.farmGroupVisibility === "Owner" && farm.role !== "Owner";
                                                        const destinationLink = !hasPrivateAccess && !hasOwnerAccess ? "membership" : "aggregated";
                                                        const showLink = farmGroup.isMember && (farmGroup.farmGroupVisibility !== "Private" || farmGroup.role === "Admin") && !noOwnerAccess;

                                                        return (
                                                            <tr key={farmGroup.id} className="hover">
                                                                <td>
                                                                    {showLink ? (
                                                                        <Link to={`/app/farm/${farm.id}/farmgroup/${farmGroup.id}/${destinationLink}`} id={`view-farm-group-${farmGroup.id}`} className="u-textBold">
                                                                            {farmGroup.name}
                                                                        </Link>
                                                                    ) : (
                                                                        <span>{farmGroup.name}</span>
                                                                    )}
                                                                </td>
                                                                <td>{utils.dateOnly(farmGroup.createdOn)}</td>
                                                                <td>{farmGroup.administratorOrganisation}</td>
                                                                <td>{utils.valueToText(farmGroupVisibility, farmGroup.farmGroupVisibility)}</td>
                                                                <td>{farm.role}</td>
                                                                <td>{farmGroup.numInvited}</td>
                                                                <td>{farmGroup.numMembers}</td>
                                                                {farmGroup.isMember && <td>Membership active</td>}
                                                                {online && !farmGroup.isMember && canRespondToFarmGroupInvite && (
                                                                    <td valign="top">
                                                                        <div className="u-flex">
                                                                            <ActionLink id={`respond-to-farm-group-invitation-${farmGroup.id}`} className="IconLink--edit" onClick={respondToInvitation(farmGroup)}>
                                                                                Respond to invite
                                                                            </ActionLink>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {!farmGroup.isMember && !canRespondToFarmGroupInvite && <td valign="top">Membership pending</td>}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        );
                                    }}
                                </SortableTable>
                            )}
                            {farmGroups && farmGroups.length === 0 && (
                                <TileBodyMessage>
                                    <p className="lead">This farm does not belong to and has not been invited to any farm groups</p>
                                </TileBodyMessage>
                            )}
                            {farmGroupInvitationResponseModal}
                        </>
                    </PanelBody>
                </Panel>
            </PageBody>
        </Page>
    );
}
