import { Field } from "react-final-form";
import * as domain from "common/domain";
import * as FormUtils from "common/FormUtils";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import Alert from "components/Alert";
import { Grid, GridCell } from "components/Grid";
import CheckboxPack from "components/CheckboxPack";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack2";

export default function FruitFormFields({ block, form, values }) {
    const refData = useRefData();

    const fruitTypes = getFruitTypeOptions(refData);
    const yieldTips = values.cropType && getYieldTips(block, values.cropType, refData);
    const showAnimalSource = values.swardType && showSwardAnimalSource(values.swardType);
    const showAnimalType = values.swardType && values.swardAnimalSource && showSwardAnimalType(values.swardType, values.swardAnimalSource);
    const animalSourceTips = values.swardAnimalSource && domain.AnimalGrazingTips[values.swardAnimalSource];

    return (
        <>
            <Grid title="Crop details">
                <GridCell className="u-width1of3">
                    <Field name="cropType" label="Fruit type" placeholder="Select a fruit type" options={fruitTypes} required component={SelectPack} />
                </GridCell>
                <GridCell className="u-width1of3">
                    <Field name="productYield" label="Product yield" placeholder={`Enter the ${yieldTips ? "total " + yieldTips.uom : "product yield"}`} uom={yieldTips ? yieldTips.uom : undefined} format={FormUtils.formatters.formatInt} formatOnBlur required type="text" component={InputPack} />
                    {yieldTips && <Alert type="info" text={yieldTips.tips} />}
                </GridCell>

                <GridCell className="u-width1of3">
                    <Field name="rejectPercentage" label="Reject rate" placeholder="Enter the reject rate" uom="%" format={FormUtils.formatters.formatInt} formatOnBlur type="text" component={InputPack} tip="If the product yield is the amount of product that leaves the block, leave blank. If the product yield is recorded at a packhouse or after leaving the block, enter the packhouse yield and set the reject rate as the percentage of the product that leaves the block but is not recorded at the packhouse" />
                </GridCell>
                <GridCell className="u-width1of3">
                    <Field name="ageOfTrees_yrs" label="Age of current trees" placeholder="Enter the age of current trees" uom="years" onChange={onAgeOfTreesChange(form)} format={FormUtils.formatters.formatInt} formatOnBlur required type="text" component={InputPack} />
                    {values.ageOfTrees_yrs === 1 && <Field name="isCultivatedAtEstablishment" label="Cultivated when established" type="checkbox" component={CheckboxPack} />}
                </GridCell>

                <GridCell className="u-width1of3">
                    <Field name="pruningDisposalMethod" label="Pruning management method" placeholder="Select a pruning mananagement method" options={refData.pruningDisposalMethods} required component={SelectPack} />
                </GridCell>
            </Grid>

            <Grid title="Sward management">
                <GridCell className="u-width1of3">
                    <Field name="swardType" label="Sward type" placeholder="Select a sward type" options={refData.swardTypes} onChange={onSwardTypeChange(form)} required component={SelectPack} />
                </GridCell>
                {showAnimalSource && (
                    <GridCell className="u-width1of3">
                        <Field name="swardAnimalSource" label="Animals grazing sward" placeholder="Select a source" options={refData.swardAnimalSources} onChange={onSwardAnimalSourceChange(form)} required component={SelectPack} />
                        {animalSourceTips && <Alert type="info" text={animalSourceTips.text} bold={animalSourceTips.bold} />}
                    </GridCell>
                )}
                {showAnimalType && (
                    <GridCell className="u-width1of3">
                        <Field name="swardAnimalType" label="Animal type" placeholder="Select a type" options={refData.swardAnimalTypes} required component={SelectPack} />
                    </GridCell>
                )}
            </Grid>

            <Grid title="Foliar sprays">
                <GridCell className="u-mt-sm">
                    <Alert className="u-mb-0" type="info" text="High foliar spray rates (e.g. >5-10 Kg/month as foliar spray) and other fertilisers should be entered as fertiliser." bold={"Note"} />
                </GridCell>
                <GridCell className="u-width1of3">
                    <Field name="numberOfSpraysCAN" label="Calcium ammonium nitrate" placeholder="" uom="sprays/yr" format={FormUtils.formatters.formatInt} formatOnBlur type="text" component={InputPack} />
                </GridCell>
                <GridCell className="u-width1of3">
                    <Field name="numberOfSpraysCN" label="Calcium nitrate" placeholder="" uom="sprays/yr" format={FormUtils.formatters.formatInt} formatOnBlur type="text" component={InputPack} />
                </GridCell>
                <GridCell className="u-width1of3">
                    <Field name="numberOfSpraysUrea" label="Urea and other N sprays" placeholder="" uom="sprays/yr" format={FormUtils.formatters.formatInt} formatOnBlur type="text" component={InputPack} />
                    <br />
                </GridCell>
            </Grid>
        </>
    );
}

export const validateFruitFormFields = (viewModel) => {
    const error = {};

    error.cropType = FormUtils.validators.required(viewModel.cropType);

    error.productYield = FormUtils.validators.required(viewModel.productYield);
    error.productYield = error.productYield || FormUtils.validators.range(1, 999999)(viewModel.productYield);

    error.rejectPercentage = FormUtils.validators.range(1, 99)(viewModel.rejectPercentage);

    error.ageOfTrees_yrs = FormUtils.validators.required(viewModel.ageOfTrees_yrs);
    error.ageOfTrees_yrs = error.ageOfTrees_yrs || FormUtils.validators.range(1, 999)(viewModel.ageOfTrees_yrs);

    error.pruningDisposalMethod = FormUtils.validators.required(viewModel.pruningDisposalMethod);

    error.swardType = FormUtils.validators.required(viewModel.swardType);
    if (showSwardAnimalSource(viewModel.swardType)) {
        error.swardAnimalSource = FormUtils.validators.required(viewModel.swardAnimalSource);
    }
    if (showSwardAnimalType(viewModel.swardType, viewModel.swardAnimalSource)) {
        error.swardAnimalType = FormUtils.validators.required(viewModel.swardAnimalType);
    }

    error.numberOfSpraysCAN = FormUtils.validators.max(99999)(viewModel.numberOfSpraysCAN);
    error.numberOfSpraysCN = FormUtils.validators.max(99999)(viewModel.numberOfSpraysCN);
    error.numberOfSpraysUrea = FormUtils.validators.max(99999)(viewModel.numberOfSpraysUrea);

    return error;
}

const getFruitTypeOptions = (refData) => {
    const fruitTypes =
        refData &&
        Object.keys(refData.fruitTypes || []).map((key) => {
            const fruitName = refData.fruitTypes[key].name;
            return { value: fruitName, text: fruitName };
        });
    return fruitTypes;
}

const getYieldTips = (block, fruitType, refData) => {
    const yieldData = (refData && refData.fruitTypes && refData.fruitTypes[fruitType]) || {};
    const typicalYield = yieldData.typicalYield || 0;
    const totalYield = utils.round(typicalYield * block.areaInHectares, 0);
    const uom = yieldData.unitsDescription;
    const tips =
        totalYield > 0 ? (
            <span>
                <b>Typical yield:</b> A typical yield for this size of block is {totalYield} {uom} ({typicalYield} {uom}/ha)
            </span>
        ) : (
            ""
        );
    return {
        uom,
        tips,
    };
}

const showSwardAnimalSource = (swardType) => {
    return ["Herbicidestrip", "Fullpasture"].includes(swardType);
}

const showSwardAnimalType = (swardType, swardAnimalSource) => {
    return showSwardAnimalSource(swardType) && ["NonFarmAnimals"].includes(swardAnimalSource);
}

const onSwardTypeChange = (form) => (e, swardType) => {
    if (swardType === "Nopasture") {
        form.change("swardAnimalSource", undefined);
        form.change("swardAnimalType", undefined);
    }
}

const onSwardAnimalSourceChange = (form) => (e, swardAnimalSource) => {
    if (swardAnimalSource !== "NonFarmAnimals") {
        form.change("swardAnimalType", undefined);
    }
}

const onAgeOfTreesChange = (form) => (e) => {
    const ageOfTrees = Number(e.target.value);
    if (ageOfTrees !== 1) {
        form.change("isCultivatedAtEstablishment", undefined);
    }
}
