import { useCallback, useState } from "react";
import { Button } from "components/Button";
import { useOnEscapeKeyPress } from "common/hooks";

export default function ConfirmModal({ prompt, onConfirm, close }) {
    useOnEscapeKeyPress(close);
    const [submitState, setSubmitState] = useState();

    const handleConfirm = useCallback(async () => {
        try {
            setSubmitState({ submitting: true });
            if (onConfirm) {
                await onConfirm();
            }
            close();
        } catch (ex) {
            setSubmitState({
                submitting: false,
                submitError: ex.message
            });
        }

    }, [onConfirm, close])

    const submitting = submitState?.submitting || false;
    const submitError = submitState?.submitError;

    return (
        <div className={`Modal2-wrapper ${submitting ? "waiting" : undefined}`}>
            <div className="Modal Modal--notification">
                <div className="Modal-body u-pl-lg u-pr-lg">
                    {submitError && (
                        <div className="Alert Alert--error u-mt-0">
                            <i className="icon icon-alert" id="login-errormsg" />
                            {submitError}
                        </div>
                    )}
                    <i className="icon icon-alert icon--lg icon--confirm" />
                    <h2>{prompt}</h2>
                    <p>This action cannot be undone</p>
                </div>
                <div className="Modal-footer">
                    <Button id="cancel-button" primary onClick={close} disabled={submitting}>
                        No
                    </Button>
                    <Button id="confirm-button" secondary onClick={handleConfirm} waiting={submitting}>
                        Yes
                    </Button>
                </div>
            </div>
        </div>
    )
}
