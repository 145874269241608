import * as domain from "common/domain";
import * as icons from "common/icons";
import * as utils from "common/utils";
import { Link } from "react-router-dom";
import TileSubPanel from "components/TileSubPanel";
import { effluentSystemViewModel, structuresViewModel, requiresOutdoorPigEffluentSystem } from "common/viewModels";
import OutdoorPigStructureCard from "./OutdoorPigStructureCard";
import OutdoorPigEffluentSystemCard from "./OutdoorPigEffluentSystemCard";
import ActionLink from "components/ActionLink";
import { useConfirm, useNavigate, useRefData } from "common/hooks";
import * as structureUtils from "./_utils";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function StructureList({ farm, analysis }) {
    const refData = useRefData();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const { structures = [] } = analysis;
    const structuresVm = structuresViewModel(analysis, structures, refData);

    const _deleteStructure = (id) => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete this structure?`, async () => {
            const updatedAnalysis = structureUtils.getUpdatedAnalysisFromDeletingStructure(analysis, id);
            await updateAnalysisAsync(updatedAnalysis);
        });
    };

    const _deleteSystemPrompt = () => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete this effluent system?`, async () => {
            delete analysis.effluentSystem;
            await updateAnalysisAsync(analysis);
        });
    };

    const dairyEffluentSystem = analysis.effluentSystem;
    const editDairyEffluentSystemUrl = `/app/farm/${farm.id}/analysis/${analysis.id}/structures/effluent`;
    const showOutdoorPigEffluentSystem = (analysis.structures || []).filter((s) => ["OutdoorPigBarns", "OutdoorPigHuts", "OutdoorPigFarrowingVillages"].includes(s.type)).some((s) => requiresOutdoorPigEffluentSystem(s));

    return (
        <>
            <TileSubPanel title="Structures/Effluent system" green stickyHeaderName="analysis.structures.structuresEffluent.collapsed">
                {dairyEffluentSystem && (
                    <div className="FarmTable_wrapper" key="effluent_system">
                        <EffluentSystem effluentSystem={dairyEffluentSystem} name="Dairy effluent system" icon={icons.farm} invalidSystem={false} to={editDairyEffluentSystemUrl} />
                        <div className="ActionsBar">
                            <div className="ActionsBar-right">
                                <ul className="ActionsBar-links">
                                    <li>
                                        <ActionLink id={`delete-effluent-system`} className="IconLink--trash" onClick={_deleteSystemPrompt()} title="Delete">
                                            <span>Delete system</span>
                                        </ActionLink>
                                    </li>
                                    <li>
                                        <Link to={editDairyEffluentSystemUrl} id={`edit__effluent_system`} className="IconLink--edit">
                                            Edit system
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                {showOutdoorPigEffluentSystem && <OutdoorPigEffluentSystemCard farm={farm} analysis={analysis} />}
                {structuresVm.map((vm, index) => {
                    const { structure } = vm;
                    const { enterprise } = vm;
                    const isMilkingShed = structure.type === "MilkingShed";
                    const isOutdoorPigStructure = ["OutdoorPigFarrowingVillages", "OutdoorPigHuts", "OutdoorPigBarns"].includes(structure.type);
                    const editStructureUrl = isOutdoorPigStructure ? `/app/farm/${farm.id}/analysis/${analysis.id}/structures/${structure.type}` : `/app/farm/${farm.id}/analysis/${analysis.id}/structures/structure?type=${structure.type}&id=${structure.id}`;

                    if (isOutdoorPigStructure) return <OutdoorPigStructureCard key={structure.id} farm={farm} analysis={analysis} structure={structure} icon={vm.icon} />;
                    else
                        return (
                            <div className="FarmTable_wrapper" key={index}>
                                <EffluentSystem effluentSystem={vm.effluentSystem} name={vm.name} icon={vm.icon} invalidSystem={vm.invalidSystem} to={editStructureUrl} />
                                <div className="FarmTable-supplementary is-open">
                                    <div className="Table" style={{ backgroundColor: "#fff" }}>
                                        <table className="Calendar">
                                            <thead>
                                                <tr className="Calendar-months">
                                                    <th className="Calendar-title">Animals</th>
                                                    {domain.farmYear.map((m, i) => (
                                                        <th className="Calendar-month" key={i}>
                                                            {m.substring(0, 3)}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="Calendar-slots Calendar-slots--fertiliser">
                                                    <td className="td--keyValuePair">
                                                        {vm.enterprise.enterpriseType ? <span className="td-key">{enterprise.enterpriseType.text}</span> : <span className="td-key u-textError">No enterprise defined</span>}
                                                        <Link to={editStructureUrl} id={`${structure.id}_structure`} name={`${structure.type}_${enterprise.enterpriseType.text}`} className="td-value">
                                                            <span className="td-value-text">{isMilkingShed ? "Milking shed feeding" : vm.name}</span>
                                                            <span className="icon icon-edit" />
                                                        </Link>
                                                    </td>
                                                    {vm.enterprise.animals.length === 0 && (
                                                        <td colSpan="12">
                                                            <span className="u-flex u-flexAlignItemsCenter">
                                                                {enterprise.enterpriseType && <img src={utils.getAnimalIcon(enterprise.enterpriseType.value)} width="45" height="45" className="u-p-5" alt={enterprise.enterpriseType.text} />}
                                                                <span>Monthly distribution not specified</span>
                                                            </span>
                                                        </td>
                                                    )}
                                                    {vm.enterprise.animals.length > 0 && (
                                                        <>
                                                            {domain.farmYear.map((month) => (
                                                                <StructureMonthlyAnimals key={`${structure.id}.${month}`} animals={vm.enterprise.animals} month={month} type={enterprise.enterpriseType.value} id={structure.id} />
                                                            ))}
                                                        </>
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="ActionsBar">
                                    <div className="ActionsBar-right">
                                        <ul className="ActionsBar-links">
                                            <li>
                                                <ActionLink id={`delete-structure-${structure.id}`} className="IconLink--trash" onClick={_deleteStructure(structure.id)} title="Delete">
                                                    <span>Delete structure</span>
                                                </ActionLink>
                                            </li>
                                            <li>
                                                <Link to={editStructureUrl} id={`edit_${structure.id}_structure`} className="IconLink--edit">
                                                    Edit structure
                                                </Link>
                                            </li>
                                            {isMilkingShed && (
                                                <li>
                                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/effluent`} id={`edit__effluent_system`} className="IconLink--edit">
                                                        Edit system
                                                    </Link>
                                                </li>
                                            )}
                                            {vm.hasEffluentSystem && (
                                                <li>
                                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/effluent?id=${structure.id}`} className={`IconLink--edit ${vm.invalidSystem ? "u-textError" : ""}`} type="button" id={`${structure.id}_effluent_sys`}>
                                                        <span>Manage effluent</span>
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        );
                })}
            </TileSubPanel>
        </>
    );
}

function EffluentSystem({ effluentSystem, name, icon, invalidSystem, to }) {
    const refData = useRefData();
    const navigate = useNavigate();

    if (!effluentSystem) return null;
    const effluentSystemVm = effluentSystemViewModel(effluentSystem, refData, "Dairy effluent system");

    return (
        <ul className="DataWidthTable FarmTable hover u-link" onClick={() => navigate(to)}>
            <li className="FarmTable-title" data-width="md-100 xl-40">
                <div className="FarmTitle">
                    {invalidSystem && (
                        <span className="Todo Todo--error error-anchor">
                            <i className="icon icon-alert" />
                        </span>
                    )}

                    <img className="FarmTitle-icon" src={icon} alt={name} />
                    <div className="FarmTitle-heading">
                        <span className="FarmTitle-name a" title={name}>
                            {name}
                        </span>
                    </div>
                </div>
            </li>
            <li data-width="md-25 xl-15">
                <div className="FarmTable-keyValuePair">
                    <div className="FarmTable-keyValue">
                        <span className="FarmTable-key">Management system</span>
                        <span className="FarmTable-value" id={`effluent_system_management`}>
                            {effluentSystemVm.managementSystem}
                        </span>
                    </div>
                </div>
            </li>
            <li data-width="md-25 xl-15">
                <div className="FarmTable-keyValuePair">
                    <div className="FarmTable-keyValue">
                        <span className="FarmTable-key">Separated solids management</span>
                        <span className="FarmTable-value" id={`effluent_system_solids_management`}>
                            {effluentSystemVm.solidsManagement}
                        </span>
                    </div>
                </div>
            </li>
            <li data-width="md-25 xl-15">
                <div className="FarmTable-keyValuePair">
                    <div className="FarmTable-keyValue">
                        <span className="FarmTable-key">Pond sludge management</span>
                        <span className="FarmTable-value" id={`effluent_system_pond_management`}>
                            {effluentSystemVm.pondSolidsManagement}
                        </span>
                    </div>
                </div>
            </li>
            <li data-width="md-25 xl-15">
                <div className="FarmTable-keyValuePair">
                    <div className="FarmTable-keyValue">
                        <span className="FarmTable-key">Liquid management</span>
                        <span className="FarmTable-value" id={`effluent_system_pond_management`}>
                            {effluentSystemVm.liquidManagement}
                        </span>
                    </div>
                </div>
            </li>
        </ul>
    );
}

function StructureMonthlyAnimals({ animals, month, type, id }) {
    const tdId = `${id}_${month}`;

    if (animals) {
        const monthActivity = animals.find((a) => a.months && a.months.includes(month));
        if (monthActivity) {
            const iii = utils.getAnimalIcon(type);
            return (
                <td key={month} id={tdId} className="Calendar-slot">
                    <img className="Calendar-icon u-p-xxs" src={iii} width={45} height={45} alt={type} />
                    <span className="Calendar-icon-text">{monthActivity.percentageOfAnimals} %</span>
                </td>
            );
        }
    }

    return <td key={month} id={tdId} className="Calendar-slot"></td>;
}
