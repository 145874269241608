import * as api from "api";
import ReactGA from "react-ga4";

export const getInvoiceAsync = (paymentId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Payments",
            action: "Get Invoice",
        });
        dispatch(
            api.get({
                path: `payments/${paymentId}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    return [];
                },
            })
        );
    });
};
