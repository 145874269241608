import { useState } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { httpClient } from "common/httpClient";
import { useModal } from "common/hooks";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import ProgressBar from "components/ProgressBar";
export default function BulkResetSoilsToSMapDefaultsModal({ analysisIds, close }) {
    const [completedCount, setCompletedCount] = useState(analysisIds.length === 1 ? 1 : 0);

    const submitAsync = async () => {
        try {
            for (const analysisId of analysisIds) {
                const analysis = await getAnalysisAsync(analysisId);
                const updatedAnalysis = {
                    ...analysis,
                    blocks: analysis.blocks.map((block) => {
                        if (block.isDrawn && block.requiresSoil) {
                            return {
                                ...block,
                                autoGeneratedSoils: true,
                                regenerateSoils: true,
                            };
                        }
                        return block;
                    }),
                };
                await updateAnalysisAsync(updatedAnalysis);
                setCompletedCount((prevState) => prevState + 1);
            }
            close();
        } catch (ex) {
            return {
                [FORM_ERROR]: ex.message
            };
        }
    }

    const warningText = analysisIds.length === 1
        ? <><b>WARNING:</b> you are about to reset the soils for all blocks to use their S-Map defaults, if applicable</>
        : <><b>WARNING:</b> you are about to reset the soils for <strong>{analysisIds.length.toLocaleString()}</strong> analyses to use their S-Map defaults, if applicable</>

    return (
        <Form initialValues={{}} onSubmit={submitAsync}>
            {({ handleSubmit, submitting }) => {
                const progress = Math.round((completedCount / analysisIds.length) * 100, 0);
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Bulk reset soils to S-Map defaults" close={close} submitting={submitting} skinny fluid>
                            <ModalBody warning={warningText}>
                                {submitting
                                    ? <>
                                        <div className="u-mt-lg u-mb-sm">
                                            <strong>Processing:</strong> {completedCount} of {analysisIds.length}
                                        </div>
                                        <ProgressBar colour="#36975c" progress={progress} endLabel={`${progress}%`} showZero noPadLeft />
                                    </>
                                    : <>
                                        <p className="u-mt-md">Processing will begin as soon as you click the "Reset soils" button below</p>
                                    </>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting}>
                                        Reset soils
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useBulkResetSoilsToSMapDefaultsModal(analyses) {
    const [modal, openModal] = useModal(BulkResetSoilsToSMapDefaultsModal);

    const onClose = () => window.location.reload();

    const open = () => {
        const modalProps = {
            analysisIds: analyses.map((a) => a.id),
        };
        openModal(modalProps, onClose);
    }

    return [modal, open];
}

async function getAnalysisAsync(analysisId) {
    try {
        return await httpClient.get(`v1/analysis-details/${analysisId}`);
    } catch (error) {
        if (error.status === 401 || error.status === 403) {
            throw new Error("You are not authorised to make this change.");
        } else {
            throw new Error(error.message);
        }
    }
}

async function updateAnalysisAsync(updatedAnalysis) {
    try {
        const timeout = 1000 * 60 * 2; // 2 minutes
        await httpClient.put(`farms/${updatedAnalysis.farmId}/budgets/${updatedAnalysis.id}`, updatedAnalysis, timeout);
    } catch (error) {
        if (error.status === 401 || error.status === 403) {
            throw new Error("You are not authorised to make this change.");
        } else {
            throw new Error(error.message);
        }
    }
}