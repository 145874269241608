import Page from "components/Page";
import PageBody from "components/PageBody";
import DashboardPageHead from "./DashboardPageHead";
import MyPendingFarmAccessRequests from "./MyPendingFarmAccessRequests";

export default function MyPendingFarmAccessRequests_TMP() {
    return (
        <Page>
            <DashboardPageHead breadcrumb={[{ name: "Requests" }]} />
            <PageBody>
                <MyPendingFarmAccessRequests />
            </PageBody>
        </Page>
    )
}
