import React from "react";

const TileActionsBar = (props) => {
    const { children, className } = props;
    return (
        <div className={"ActionsBar ActionsBar--super u-print-none " + (className ? className : "")}>
            <div className="ActionsBar-left">{children}</div>
        </div>
    );
};

export default TileActionsBar;
