import React from "react";
import * as domain from "common/domain";
import moment from "moment";
import * as utils from "common/utils";
import * as icons from "common/icons";
import * as supplementUtils from "../Supplements/_utils";
import * as animalUtils from "containers/BudgetHome/Animals/_utils";

import { Line } from "react-chartjs-2";

export const isCropReadonly = (crop, createDefault, rYear) => {
    if (!createDefault) return false;
    const sown = findCropEvent(crop, "Cropsown");
    const monthIndx = rotationIndex(sown, rYear);
    return monthIndx === rYear.length - 1 && sown.type === "Cropsown" && crop.category === "Pasture";
};

export const rotationYear = (block, useFarmYear, excludePrevious) => {
    const hasPreviousYear = block.type === "ProductiveCrop";
    const defaultEndMonth = useFarmYear ? domain.farmYear[11] : domain.calendarYear[11];
    const endMonth = block.monthResown && domain.calendarYear.includes(block.monthResown) ? block.monthResown : defaultEndMonth;
    const indx = domain.calendarYear.indexOf(endMonth);
    const months = !excludePrevious && hasPreviousYear ? 24 : 12;
    const result = [];
    for (let i = 0; i < months; i++) {
        const endDate = moment(`2000-${indx + 1}-01`, "YYYY-MM-DD");
        result.push(endDate.add(i + 1, "months").format("MMMM"));
    }
    return result;
};

export const finishRotationYear = (block) => {
    const hasPreviousYear = block.type === "ProductiveCrop";
    const defaultEndMonth = domain.calendarYear[11];
    const endMonth = block.monthResown && domain.calendarYear.includes(block.monthResown) ? block.monthResown : defaultEndMonth;
    const indx = domain.calendarYear.indexOf(endMonth);
    const months = hasPreviousYear ? 36 : 12;
    const result = [];
    for (let i = 0; i < months; i++) {
        const endDate = moment(`2000-${indx + 1}-01`, "YYYY-MM-DD");
        result.push(endDate.add(i + 1, "months").format("MMMM"));
    }
    return result;
};

export const finishRotationYearInfo = (block) => {
    const hasPreviousYear = block.type === "ProductiveCrop";
    const defaultEndMonth = domain.calendarYear[11];
    const endMonth = block.monthResown && domain.calendarYear.includes(block.monthResown) ? block.monthResown : defaultEndMonth;
    const indx = domain.calendarYear.indexOf(endMonth);
    const months = hasPreviousYear ? 36 : 12;
    const result = [];
    for (let i = 0; i < months; i++) {
        const endDate = moment(`2000-${indx + 1}-01`, "YYYY-MM-DD");
        result.push({
            month: endDate.add(i + 1, "months").format("MMMM"),
            index: hasPreviousYear ? i : i + 12,
            reportingYear: hasPreviousYear ? i > 11 && i < 24 : i <= 11,
            postReportingYear: hasPreviousYear ? i > 23 : i > 11,
        });
    }
    return result;
};

export const croppingEvent = (rotationYear, block, index, type) => {
    const { croppingEvents = [] } = block;
    const reportingYear = indexToReportingYear(index, rotationYear);
    return croppingEvents.find((e) => e.month === rotationYear[index] && e.reportingYear === reportingYear && e.type === type);
};

export const isPastureInReportingYear = (block) => {
    const rYear = rotationYear(block, false);
    const { crops = [] } = block;
    const pastureCrops = crops.filter((c) => c.category === "Pasture");
    if (rYear.length === 12) return true;
    pastureCrops.forEach((c) => {
        const range = cropRange(rYear, block, c);
        if (indexToReportingYear(range.end, rYear)) {
            return true;
        }
    });
    return false;
};

export const isCropMonth = (rYear, crop, indx) => {
    const isFodderCrop = rYear.length === 12;
    const adjInd = isFodderCrop && indx < 12 ? indx + 12 : indx;
    const sown = findCropEvent(crop, "Cropsown");
    let endIndx = crop.thermalTimeEndIndex - 1;
    const monthIndx = rotationIndex(sown, rYear);
    const adjMonthIndx = isFodderCrop ? monthIndx + 12 : monthIndx;
    return adjInd >= adjMonthIndx && adjInd <= endIndx;
};

export const monthHasEvent = (isReportingYear, indx, crops, block, rYear, includeAdditionalMonths) => {
    const hasCropEvent = () => crops.some((c) => c.events && c.events.some((e) => e.month === rYear[indx] && e.reportingYear === isReportingYear));
    const hasCroppingEvent = () => block.croppingEvents && block.croppingEvents.some((e) => e.month === rYear[indx] && e.reportingYear === isReportingYear);
    const isAdditionalDefoliationEvent = () => {
        const crop = crops.find((c) => isCropMonth(rYear, c, indx)) || {};
        const { events = [] } = crop;
        let hasAddDefolEvent = false;
        events
            .filter((e) => e.type === "Defoliation" && Number(e.numberMonthsDefoliating || 0) > 0)
            .forEach((d) => {
                const defolRotIndx = rotationIndex(d, rYear);
                hasAddDefolEvent |= indx > defolRotIndx && indx < Number(d.numberMonthsDefoliating || 0) + defolRotIndx;
            });
        return hasAddDefolEvent;
    };
    return hasCropEvent() || hasCroppingEvent() || isAdditionalDefoliationEvent();
};

export const cropRange = (rYear, block, crop, ignorFinalHarvest) => {
    const result = {};
    for (let index = 0; index < rYear.length; index++) {
        const activeMonth = isCropMonth(rYear, crop, index);
        if (activeMonth && result.start === undefined) {
            result.start = index;
        }
        if (!activeMonth && result.start !== undefined && result.end === undefined) {
            result.end = index - 1;
            break;
        }
        if (result.end === undefined && index === rYear.length - 1) {
            result.end = index;
        }
    }
    return result;
};

export const sortCrops = (crops, rotationYear) => {
    return crops.sort((a, b) => rotationIndex(findCropEvent(a, "Cropsown"), rotationYear) - rotationIndex(findCropEvent(b, "Cropsown"), rotationYear));
};

export const cropSubHeading = (crop, refData) => {
    let { cropCategories = [] } = refData;
    const selectedCategory = cropCategories.find((c) => c.value === crop.category);
    const cropTypes = selectedCategory ? selectedCategory.children : [];
    const result = utils.valueToText(cropTypes, crop.cropId);
    return result && result.length > 0 ? result : crop.category;
};

export const getStartMonth = (block) => {
    const startDate = block.monthResown ? moment(`01 ${block.monthResown} 2000`, "DD MMMM YYYY").add(1, "month") : moment("01 July 2000");
    return startDate.format("MMMM");
};

export const priorLandUseHasCropEvents = (block) => {
    const { crops = [] } = block;
    return crops.some((c) => c.events && c.events.some((e) => !e.reportingYear));
};

export const anyCropStartsOnReportingYearStart = (block) => {
    const { crops = [] } = block;
    const startMonth = getStartMonth(block);
    const result = crops.some((c) => c.events && c.events.some((e) => e.reportingYear && e.month === startMonth));
    return result;
};

export const priorLandUseHasCultivationEvents = (block) => {
    const startMonth = getStartMonth(block);
    const { croppingEvents = [] } = block;
    return croppingEvents.some((c) => !c.reportingYear || (c.reportingYear && c.month === startMonth));
};

export const findCropEvent = (crop, eventType, insert = false) => {
    if (!["Cropsown", "Endcrop", "Enduptake", "Cropharvest", "Defoliation"].includes(eventType)) return;
    const { events = [] } = crop;
    crop.events = events;
    const eventsOfType = events.filter((e) => e.type === eventType);
    if (eventsOfType.length === 0) {
        const evt = { type: eventType };
        insert && events.push(evt);
        return evt;
    } else {
        return eventsOfType[eventsOfType.length - 1];
    }
};

export const findFinishedEvent = (crop = {}, finishRotationYear = []) => {
    const { events = [] } = crop;
    for (let i = finishRotationYear.length; i > 0; i--) {
        const lastEvent = events.find((e) => e.rotationMonth === i);
        if (lastEvent && ["Endcrop", "Cropharvest", "Defoliation", "Cultivation"].includes(lastEvent.eventType)) {
            return lastEvent;
        }
    }
};

export const getFirstCropEvent = (crops, indx, eventTypes, reportingYear, includeAdditionalMonths) => {
    const month = reportingYear[indx];
    /* eslint-disable no-unused-vars */
    const result = [undefined, undefined];
    for (const crop of crops) {
        const event = getCropEvent(crop, month, eventTypes, reportingYear, indx, includeAdditionalMonths);
        if (event) {
            result[0] = crop;
            result[1] = event;
        }
    }
    return result;
};

export const getCropEvent = (crop = {}, month, eventTypes = [], rYear, indx, includeAdditionalMonths) => {
    const isReportingYear = indexToReportingYear(indx, rYear);
    const { events = [] } = crop;
    let event = events.find((e) => eventTypes.includes(e.type) && e.month === month && e.reportingYear === isReportingYear);
    if (!event && includeAdditionalMonths) {
        let defolEvent = undefined;
        events
            .filter((e) => e.type === "Defoliation" && Number(e.numberMonthsDefoliating || 0) > 0)
            .forEach((d) => {
                const defolRotIndx = rotationIndex(d, rYear);
                if (indx > defolRotIndx && indx < Number(d.numberMonthsDefoliating || 0) + defolRotIndx) {
                    defolEvent = { ...d, ...{ isAdditionalDefoliationEvent: true } };
                }
            });
        return defolEvent;
    }
    return event;
};

export const calendarSubHeading = (crop, subHeading) => (crop.category === "Pasture" || !crop.productYield ? subHeading : `${crop.productYield}/Tha`);

export const rotationIndex = (rotation, rotationYear) => {
    return rotation && rotation.month ? (rotation.reportingYear ? rotationYear.lastIndexOf(rotation.month) : rotationYear.indexOf(rotation.month)) : undefined;
};

export const indexToReportingYear = (index, rotationYear) => index > 11 || rotationYear.length === 12;

export const getMonthIndex = (index, rotationYear) => {
    if (rotationYear.length === 12) {
        return index + 1 + 12;
    } else {
        return index + 1;
    }
}

export const clearTrailingEvents = (evt, crop, rotationYear) => {
    const cropEndIndex = rotationIndex(evt, rotationYear);
    crop.events = crop.events.filter((e) => rotationIndex(e, rotationYear) <= cropEndIndex);
};

export const getFirstCropSownIndex = (crops = [], rotationYear) => {
    const sortedCrops = sortCrops(crops, rotationYear);
    const firstCropSown = sortedCrops.length > 0 && sortedCrops[0].events.find((c) => c.type === "Cropsown");
    return firstCropSown ? rotationIndex(firstCropSown, rotationYear) : undefined;
};

export const handleEvent = (evtType, data, block) => {
    const { crops = [] } = block;
    const { croppingEvents = [] } = block;
    block.croppingEvents = croppingEvents;
    const rotationYear = data.rotationYear || [];
    const reportingYear = (!data.index && data.index !== 0) || indexToReportingYear(data.index, rotationYear);
    const existingEvt = (data, reportingYear, events, type) => events.find((c) => c.month === rotationYear[data.index] && c.reportingYear === reportingYear && c.type === type);

    const nameToEventType = (name) => {
        switch (name) {
            case "crop-end":
            case "crop-end-del":
                return "Endcrop";
            case "crop-enduptake":
            case "crop-enduptake-del":
                return "Enduptake";
            case "crop-harvest":
            case "crop-harvest-del":
                return "Cropharvest";
            default:
                console.log("Unknown event: " + name);
                return undefined;
        }
    };
    switch (evtType) {
        case "crop-save":
            block.crops = crops;
            utils.merge(data, crops);
            break;
        case "crop-save-withevents":
            block.croppingEvents = data.croppingEvents;
            block.crops = crops;
            utils.merge(data.crop, crops);
            break;
        case "crop-del":
            block.crops = crops.filter((c) => c.id !== data);
            break;
        case "crop-harvest":
        case "crop-enduptake":
        case "crop-end": {
            const crop = crops.find((c) => c.id === data.cropId);
            const eventType = nameToEventType(evtType);
            const evt = { type: eventType, month: rotationYear[data.index], reportingYear: reportingYear };
            if (crop) {
                const { events = [] } = crop;
                crop.events = events.filter((e) => e.type !== eventType);
                crop.events.push(evt);
            }
            if (["Endcrop", "Cropharvest"].includes(eventType)) {
                //No events after crop end
                clearTrailingEvents(evt, crop, rotationYear);
            }
            break;
        }
        case "crop-harvest-del":
        case "crop-enduptake-del":
        case "crop-end-del": {
            const dCrop = crops.find((c) => c.id === data.cropId);
            const dEventType = nameToEventType(evtType);
            if (dCrop) {
                const { events = [] } = dCrop;
                dCrop.events = events.filter((e) => e.type !== dEventType);
            }
            break;
        }
        case "crop-defol-del": {
            const eCrop = crops.find((c) => c.id === data.cropId);
            if (eCrop) {
                const { events = [] } = eCrop;
                eCrop.events = events.filter((e) => !(e.type === "Defoliation" && e.month === rotationYear[data.index] && e.reportingYear === reportingYear));
            } else {
                block.croppingEvents = croppingEvents.filter((c) => !(c.month === rotationYear[data.index] && c.reportingYear === reportingYear && c.type === "Defoliation"));
            }
            break;
        }
        case "cultivation-add": {
            const cultivationEvt = { type: "Cultivation", month: rotationYear[data.index], reportingYear: reportingYear };
            /* eslint-disable no-unused-vars */
            for (const cultivateCrop of crops) {
                if (isCropMonth(rotationYear, cultivateCrop, data.index) && data.index > 0) {
                    clearTrailingEvents(cultivationEvt, cultivateCrop, rotationYear);
                    break;
                }
            }
            if (!existingEvt(data, reportingYear, croppingEvents, "Cultivation")) {
                croppingEvents.push(cultivationEvt);
            }
            break;
        }
        case "cultivation-del":
            block.croppingEvents = croppingEvents.filter((c) => !(c.month === rotationYear[data.index] && c.reportingYear === reportingYear && c.type === "Cultivation"));
            break;
        default:
            console.log("Unknown event: " + evtType);
            break;
    }
};

const getGrazingData = (animalGrazing, enterpriseMap, startIndex, endIndex, rotationYear, budget, includeYear0) => {
    const isFodderCrop = rotationYear.length === 12;
    const { enterprises = [] } = budget;

    const addToEntMap = (k, m) => {
        if (!enterpriseMap[k]) enterpriseMap[k] = [];
        enterpriseMap[k].push(m);
    };

    if (animalGrazing.percentageGrazed) {
        for (var grazing of animalGrazing.percentageGrazed) {
            // eslint-disable-next-line no-loop-func
            const enterprise = enterprises.find((e) => e.id === grazing.enterpriseId) || {};
            const { mobs = [] } = enterprise;
            const stockCounts = mobs.map((m) => animalUtils.getStockCountsForMob(m));

            for (var i = startIndex; i <= endIndex; i++) {
                if (i >= 12 || includeYear0 || isFodderCrop) {
                    const isYear0 = rotationYear.length > 12 && i < 12;
                    // eslint-disable-next-line no-loop-func
                    const stockCountsForMonth = stockCounts.reduce((t, mobArray) => {
                        const monthCount = mobArray.find((ma) => ma.name === rotationYear[i]);
                        return monthCount ? t + monthCount.stockCount : t;
                    }, 0);
                    const isRsu = enterprise.specificationMethod === "RSU";

                    if (stockCountsForMonth > 0 || isYear0 || isRsu) {
                        addToEntMap(grazing.enterpriseId, { monthIndx: includeYear0 || isFodderCrop ? i : i - 12, month: rotationYear[i], text: grazing.percentage + "%" });
                    }
                }
            }
        }
    } else if (animalGrazing.animalSource === "Integratedwithpastoralblocks") {
        /* eslint-disable no-unused-vars */
        for (const enterprise of budget.enterprises) {
            // eslint-disable-next-line no-loop-func
            const { mobs = [] } = enterprise;
            const stockCounts = mobs.map((m) => animalUtils.getStockCountsForMob(m));

            for (var x = startIndex; x <= endIndex; x++) {
                if (x >= 12 || includeYear0 || isFodderCrop) {
                    const isYear0 = rotationYear.length > 12 && i < 12;
                    // eslint-disable-next-line no-loop-func
                    const stockCountsForMonth = stockCounts.reduce((t, mobArray) => {
                        const monthCount = mobArray.find((ma) => ma.name === rotationYear[i]);
                        return monthCount ? t + monthCount.stockCount : t;
                    }, 0);
                    const isRsu = enterprise.specificationMethod === "RSU";

                    if (stockCountsForMonth > 0 || isYear0 || isRsu) {
                        addToEntMap(enterprise.id, { monthIndx: includeYear0 || isFodderCrop ? x : x - 12, month: rotationYear[x], text: " " });
                    }
                }
            }
        }
    } else if (animalGrazing.animalSource === "Standalonegeneralisedanimaltype" && animalGrazing.animalType) {
        //These are non-farm animals which we can't map to one of our enterprise id's
        for (var y = startIndex; y <= endIndex; y++) {
            if (y >= 12 || includeYear0 || isFodderCrop) {
                addToEntMap("NonFarmAnimals", { monthIndx: includeYear0 || isFodderCrop ? y : y - 12, month: rotationYear[y], text: animalGrazing.animalType.includes("cattle") ? "Cattle" : "Non-farm", type: animalGrazing.animalType, isNonFarmAnimals: true });
            }
        }
    }
};

export const clearSupplementValues = (block, budget) => {
    const { cropBlock = {}, crops = [] } = block;
    const cropEventsSupplementsRemoved = crops.some((c) => supplementUtils.cropHasSupplements(c));
    if (!cropBlock.supplementsRemoved && !cropEventsSupplementsRemoved) {
        const { feedSupplements = [] } = budget;
        feedSupplements
            .filter((f) => f.sources && f.sources.length > 0)
            .forEach((f) => {
                f.sources = f.sources.filter((source) => source.blockId !== block.id);
            });
    }
};

export const getNonFarmAnimalIcon = (type) => {
    switch (type) {
        case "Femalecattle":
            return icons.dairy;
        case "Malecattle":
            return icons.beef;
        default:
            return icons.sheepDeer;
    }
};

export const getAnimalsOnCropBlock = (block, rotationYear, budget, includeYear0 = false) => {
    var _enterpriseMap = {};
    var firstCropIndex = 23;
    const { crops = [] } = block;

    for (var crop of crops) {
        if (crop.animalGrazing) {
            const range = cropRange(rotationYear, block, crop);
            getGrazingData(crop.animalGrazing, _enterpriseMap, range.start + 2, range.end < 23 ? range.end - 1 : range.end, rotationYear, budget, includeYear0);
        }

        for (var event of crop.events) {
            var eventIndex = rotationIndex(event, rotationYear);
            if (event.animalGrazing && event.defoliationMethod !== "CutandCarry") {
                getGrazingData(event.animalGrazing, _enterpriseMap, eventIndex, eventIndex, rotationYear, budget, includeYear0);

                if (event.type === "Defoliation" && event.numberMonthsDefoliating > 1) {
                    for (let i = 1; i < event.numberMonthsDefoliating; i++) {
                        getGrazingData(event.animalGrazing, _enterpriseMap, eventIndex + i, eventIndex + i, rotationYear, budget, includeYear0);
                    }
                }
            }

            if (block.cropBlock && block.cropBlock.animalGrazing && event.type === "Cropsown") {
                firstCropIndex = Math.min(firstCropIndex, eventIndex);
            }
        }
    }
    //Check to see if there is a cultivation event before the first crop sown.
    const { croppingEvents = [] } = block;
    croppingEvents.forEach((event) => {
        var eventIndex = rotationIndex(event, rotationYear);
        firstCropIndex = Math.min(firstCropIndex, eventIndex);
    });

    if (block.cropBlock && block.cropBlock.animalGrazing) {
        getGrazingData(block.cropBlock.animalGrazing, _enterpriseMap, 0, firstCropIndex - 1, rotationYear, budget, includeYear0);
    }

    return _enterpriseMap;
};

export const getFirstEventIndex = (block, rotationYear) => {
    const { crops = [] } = block;
    //Check to see if there is a cultivation event before the first crop sown.
    let firstCropEventIndx = getFirstCropSownIndex(crops, rotationYear);
    if (firstCropEventIndx === undefined) firstCropEventIndx = 24;

    const { croppingEvents = [] } = block;
    croppingEvents.forEach((event) => {
        var eventIndex = rotationIndex(event, rotationYear);
        firstCropEventIndx = Math.min(firstCropEventIndx, eventIndex);
    });
    return firstCropEventIndx;
};

export const getEventIcon = (evt, crop, budget) => {
    switch (evt.type) {
        case "Cropsown":
            return icons.sowing3;
        case "Endcrop":
            return icons.deleteIcon;
        case "Enduptake":
            return icons.pasture;
        case "Cropharvest":
            return icons.harvest2;
        case "Defoliation":
            if (evt.defoliationMethod === "CutandCarry" && evt.defoliationFate !== "Onpastoralblocks") {
                switch (evt.defoliationFate) {
                    case "Inshed":
                        return icons.milkingShed;
                    case "ToStorage":
                        return icons.silage;
                    case "Feedpad":
                        return icons.feedingPad;
                    case "Winteringpadbarn":
                        return icons.winteringPad;
                    default:
                        return icons.cutAndCarry;
                }
            }
            return getAnimalIcon(evt.animalGrazing, crop, budget);
        default:
            return icons.sowing1;
    }
};

export const mapAnimals = (block, budget, refData) => {
    const labelCells = [];
    const { enterpriseTypes = [] } = refData;
    const rYear = rotationYear(block);
    const f = (budget, id) => budget.enterprises.find((e) => e.id === id);
    const firstCropEventIndx = getFirstEventIndex(block, rYear);
    const _enterpriseMap = getAnimalsOnCropBlock(block, rYear, budget, true);
    const enterprisesOnCrops = [];

    for (var id in _enterpriseMap) {
        const enterprise = id === "NonFarmAnimals" ? { isNonFarmAnimal: true } : f(budget, id);
        if (enterprise) {
            const type = enterprise.isNonFarmAnimal ? _enterpriseMap[id][0].type : enterprise.type;
            enterprisesOnCrops.push({
                type,
                enterprise,
                name: enterprise.isNonFarmAnimal ? "Non farm animals" : utils.valueToText(enterpriseTypes, type),
                icon: enterprise.isNonFarmAnimal ? getNonFarmAnimalIcon(type) : utils.getAnimalIcon(type),
                months: _enterpriseMap[id],
            });
        }
    }

    for (let indx = 0; indx < rYear.length; indx++) {
        let preCrop = indx < firstCropEventIndx;
        const enterprises = enterprisesOnCrops.filter((e) => e.months.find((m) => m.monthIndx === indx));
        if (enterprises.length > 1) {
            labelCells.push({ iconText: "Multiple animal types", icon: icons.multiAnimal, colSpan: 1, preCrop });
        } else if (enterprises.length === 1) {
            labelCells.push({ iconText: enterprises[0].type, icon: enterprises[0].icon, colSpan: 1, preCrop });
        } else {
            labelCells.push({ iconText: "", icon: undefined, colSpan: 1, preCrop });
        }
    }

    return labelCells;
};

export const mapLabels = (block, growthCurve, refData) => {
    const labelCells = [];
    const { crops = [] } = block;
    let lastCropText = "";
    const rYear = rotationYear(block);
    const { cropBlock } = block;
    const { preCropManagement = [], cropCategories = [] } = refData;
    const firstCropEventIndx = getFirstEventIndex(block, rYear);

    for (let indx = 0; indx < rYear.length; indx++) {
        // eslint-disable-next-line no-loop-func
        let cropText = undefined;
        let preCrop = false;
        /* eslint-disable no-unused-vars */
        for (const c of crops) {
            if (isCropMonth(rYear, c, indx)) {
                const selectedCategory = cropCategories.find((c) => c.value === c.category);
                const cropTypes = selectedCategory ? selectedCategory.children : [];
                const selectedCropType = cropTypes.find((t) => t.value === c.cropId) || undefined;
                cropText = selectedCropType ? selectedCropType.text : cropSubHeading(c, refData);
                if (c.name) cropText = c.name;
            }
        }

        if (cropText && growthCurve && growthCurve.length > indx && growthCurve.reduce((a, b) => a + (b === null ? 0 : b.uptake), 0) !== 0) {
            if (growthCurve[indx].uptake === 0) cropText = undefined;
        }

        if (cropBlock && cropBlock.preCrop && cropBlock.preCrop !== "Undefined" && !cropText && indx < firstCropEventIndx) {
            cropText = utils.valueToText(preCropManagement, cropBlock.preCrop);
            preCrop = true;
        }

        if (lastCropText !== cropText) {
            labelCells.push({ iconText: cropText, colSpan: 1, preCrop });
        } else if (labelCells.length > 0) {
            labelCells[labelCells.length - 1].colSpan++;
        }
        lastCropText = cropText || "";
    }
    return labelCells;
};

export const mapGrowthCurve = (growthCurve, crops, block, rotationYear, analysis) => {
    const { messages = [] } = analysis;
    const curveCells = [];
    let lastCropId = "0";
    let span = undefined;
    let i = 0;
    /* eslint-disable no-unused-vars */
    for (const gc of growthCurve) {
        const cropId = gc.cropId && crops.some((c) => isCropMonth(rotationYear, c, growthCurve.indexOf(gc))) ? gc.cropId : "none";
        if (cropId !== lastCropId) {
            const cropErrors = messages.filter((m) => m.category === "Crops" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error");
            const hasErrors = cropErrors.some((m) => m.category === "Crops" && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error" && m.data && m.data.cropId === cropId);
            const cropColor = hasErrors ? "#FFCCCC" : "#e4f5eb";
            span = { cs: 0, cropId: gc.cropId };
            curveCells.push(span);
            lastCropId = cropId;
            if (cropId !== "none") {
                span.data = {
                    labels: [],
                    datasets: [
                        {
                            fill: "origin",
                            lineTension: 0.2,
                            backgroundColor: cropColor,
                            borderColor: cropColor,
                            pointRadius: 0,
                            data: [],
                        },
                    ],
                };
            }
        }
        span.cs++;
        i++;
        if (cropId !== "none") {
            span.data.labels.push(i);
            span.data.datasets[0].data.push(gc.uptake * 100);
        }
    }
    return curveCells;
};

const enterpriseIdToIcon = (id, budget) => {
    const { enterprises = [] } = budget;
    const topEnterprise = enterprises.find((e) => e.id === id);
    return topEnterprise ? utils.getAnimalIcon(topEnterprise.type) : icons.dairy;
};

const animalGrazingToIcon = (animalGrazing, budget) => {
    const { percentageGrazed = [] } = animalGrazing;
    if (percentageGrazed.length > 0) {
        const sortedEnterprises = animalGrazing.percentageGrazed.sort((a, b) => b.percentage - a.percentage);
        return enterpriseIdToIcon(sortedEnterprises[0].enterpriseId, budget);
    } else if (animalGrazing.animalType) {
        switch (animalGrazing.animalType) {
            case "Sheepandordeer":
                return icons.sheep;
            default:
                return icons.beef;
        }
    }
};

export const getDefoliationIcon = (budget, defolEvt) => {
    let icon = undefined;

    if (defolEvt.defoliationMethod === "CutandCarry" && defolEvt.defoliationFate === "Exported") {
        icon = icons.cutAndCarry;
    } else if (defolEvt.defoliationMethod === "CutandCarry" && defolEvt.defoliationFate === "ToStorage") {
        icon = icons.silage;
    } else if (defolEvt.animalGrazing) {
        icon = animalGrazingToIcon(defolEvt.animalGrazing, budget);
    }

    if (!icon) {
        let budgetEnterpriseId = budget.enterprises && budget.enterprises.length > 0 ? budget.enterprises[0].id : undefined;
        icon = enterpriseIdToIcon(budgetEnterpriseId, budget);
    }
    return icon;
};

export const getAnimalIcon = (animalGrazing, crop, budget) => {
    let icon = undefined;

    if (animalGrazing) {
        icon = animalGrazingToIcon(animalGrazing, budget);
    }

    if (!icon && crop) {
        const { events = [] } = crop;
        const sownEvent = events.find((e) => e.type === "Cropsown");
        if (sownEvent && sownEvent.animalGrazing) {
            icon = animalGrazingToIcon(sownEvent.animalGrazing, budget);
        }
    }

    if (!icon) {
        let budgetEnterprise = budget.enterprises && budget.enterprises.length > 0 ? budget.enterprises.find((e) => e.type !== "OutdoorPigs") : undefined;
        icon = budgetEnterprise && enterpriseIdToIcon(budgetEnterprise.id, budget);
    }

    if (!icon) {
        icon = icons.beef;
    }
    return icon;
};

export const findCropType = (crop, refData) => {
    if (!crop) return undefined;
    const { cropCategories = [] } = refData;
    const cropCategory = cropCategories.find((c) => c.value === crop.category);
    if (cropCategory) {
        const { children = [] } = cropCategory;
        return children.find((c) => c.value === crop.cropId);
    }
    return undefined;
};

export const singleEnterpriseGrazing = (enterpriseId) => {
    return {
        animalSource: "Integratedwithpastoralblocks",
        percentageGrazed: [{ enterpriseId: enterpriseId, percentage: 100 }],
    };
};

export const growthCurveCells = (curveCells) => {
    const options = {
        scales: {
            x: { display: false },
            y: { display: false },
        },
        plugins: {
            legend: { display: false },
        },
        maintainAspectRatio: false,
    };
    return curveCells.map((c, i) => {
        //const colSpan = isNaN(c.cs) || c.cs === 0 ? 1 : c.cs;
        //const sidePercentage = Math.round((1 / colSpan) * 50);
        const sidePercentage = 0;
        return (
            <td key={i} style={{ padding: 0, borderLeft: "none", borderRight: "none" }} colSpan={c.cs}>
                {c.data && c.data.labels.length > 1 && (
                    <div style={{ width: "100%", paddingRight: `${sidePercentage}%`, paddingLeft: `${sidePercentage}%`, paddingTop: 4 }}>
                        <Line data={c.data} height={50} options={options} />
                    </div>
                )}
            </td>
        );
    });
};

export const insertFinalDefoliationEvent = (block) => {
    const rotYear = rotationYear(block);
    const { crops = [] } = block;

    for (const c of crops) {
        if (c.thermalTimeEndIndex < rotYear.length) {
            const { events = [] } = c;
            if (c.thermalTimeEndReason === "FinalDefoliation") {
                const finalEvent = events.find((v) => v.month === rotYear[c.thermalTimeEndIndex - 1] && v.reportingYear === indexToReportingYear(c.thermalTimeEndIndex - 1, rotYear));

                if (!finalEvent) {
                    const newFinalEvent = {
                        type: "Defoliation",
                        month: rotYear[c.thermalTimeEndIndex - 1],
                        reportingYear: indexToReportingYear(c.thermalTimeEndIndex - 1, rotYear),
                        monthIndex: c.thermalTimeEndIndex,
                        incomplete: true,
                        finalHarvest: true,
                    };
                    c.events.push(newFinalEvent);
                }
            } else if (c.thermalTimeEndReason === "Cultivate") {
                const { croppingEvents = [] } = block;
                const finalEvent = croppingEvents.find((v) => v.month === rotYear[c.thermalTimeEndIndex - 1] && v.reportingYear === indexToReportingYear(c.thermalTimeEndIndex - 1, rotYear));

                if (!finalEvent) {
                    const newFinalEvent = {
                        type: "Cultivation",
                        month: rotYear[c.thermalTimeEndIndex - 1],
                        monthIndex: c.thermalTimeEndIndex,
                        reportingYear: indexToReportingYear(c.thermalTimeEndIndex - 1, rotYear),
                    };
                    croppingEvents.push(newFinalEvent);
                }
            }
        }
    }
};

export const renderCropIconMonth = (rotationYear, crops, indx, block, growthCurve, budget) => {
    const isFodderCrop = rotationYear.length === 12;
    const thermalTimeIndex = isFodderCrop ? indx + 12 : indx;
    let icon = undefined;
    let preCrop = false;
    let isFinishIcon = false;
    const { croppingEvents = [] } = block;
    const cultivation = croppingEvents.find((v) => v.type === "Cultivation" && v.month === rotationYear[indx] && v.reportingYear === indexToReportingYear(indx, rotationYear));

    for (const c of crops) {
        if (c.thermalTimeEndIndex - 1 === thermalTimeIndex) {
            isFinishIcon = true;
            switch (c.thermalTimeEndReason) {
                case "FinalDefoliation":
                    {
                        const { events = [] } = c;
                        const defolEvent = events.find((v) => v.type === "Defoliation" && v.month === rotationYear[indx] && v.reportingYear === indexToReportingYear(indx, rotationYear));
                        icon = defolEvent ? getDefoliationIcon(budget, defolEvent) : icons.defol;
                    }
                    break;
                case "Cultivate":
                    icon = icons.cultivate1;
                    break;
                case "EndCrop":
                    icon = icons.spraying;
                    break;
                default:
                    icon = icons.harvest2;
            }
        } else {
            const cropSown = c.events.find((v) => v.type === "Cropsown");
            const isReportingYear = cropSown && indexToReportingYear(indx, rotationYear);
            const sownOnHarvestMonth = cropSown && rotationYear[indx] === cropSown.month && cropSown.reportingYear === isReportingYear;
            const overwriteFinishIcon = isFinishIcon && sownOnHarvestMonth;
            if (overwriteFinishIcon || !isFinishIcon) {
                icon = isCropMonth(rotationYear, c, indx) ? utils.getCropIcon(c) : undefined;
                if (icon) {
                    if (c.category === "Seed" && c.monthSeedHarvested === rotationYear[indx]) {
                        if (!sownOnHarvestMonth) icon = icons.harvest2;
                    }
                    isFinishIcon = false;
                    break;
                }
            }
        }
    }

    if (icon && growthCurve && growthCurve.length > indx && growthCurve.reduce((a, b) => a + (b === null ? 0 : b.uptake), 0) !== 0) {
        if (growthCurve[indx].uptake === 0 && !isFinishIcon) icon = undefined;
    }

    const { cropBlock } = block;
    if (!icon && cropBlock && cropBlock.preCrop && cropBlock.preCrop !== "Undefined") {
        const firstCropEventIndx = getFirstEventIndex(block, rotationYear);
        if (firstCropEventIndx > indx) {
            icon = utils.getPreCropIcon(cropBlock.preCrop);
            preCrop = true;
        }
    }

    if (cultivation) icon = icons.cultivate1;

    return (
        <td key={`crop_${indx}`} className={`Calendar-slot`}>
            {icon && <img className="Calendar-icon u-p-xxs" src={icon} alt="crop icon" style={{ opacity: preCrop ? "0.2" : "1" }} />}
        </td>
    );
};

export const cropTableHeader = (budget, rotationYear, cropBlock = {}, refData, showAnimals, leadingColSpan, trailingColSpan) => {
    const { crops = [] } = cropBlock;
    const { cropGrowthCurves = [] } = cropBlock;
    const curveCells = mapGrowthCurve(cropGrowthCurves, crops, cropBlock, rotationYear, budget);
    const labelCells = mapLabels(cropBlock, cropGrowthCurves, refData);
    const animalCells = mapAnimals(cropBlock, budget, refData);

    return (
        <thead>
            <tr className="Calendar-months">
                {leadingColSpan > 0 && <th colSpan={leadingColSpan}></th>}
                {rotationYear.map((month, index) => (
                    <th className={`Calendar-month ${rotationYear.length > 12 && index < 12 ? "Year1" : ""}`} key={index}>
                        {month.substring(0, 3)}
                    </th>
                ))}
                {trailingColSpan > 0 && <th colSpan={trailingColSpan}></th>}
            </tr>
            {crops.length > 0 && rotationYear.length === cropGrowthCurves.length && (
                <tr>
                    {leadingColSpan > 0 && <td colSpan={leadingColSpan}></td>}
                    {growthCurveCells(curveCells)}
                    {trailingColSpan > 0 && <td colSpan={trailingColSpan}></td>}
                </tr>
            )}
            {crops.length > 0 && (
                <tr className="Calendar-slots">
                    {leadingColSpan > 0 && <td colSpan={leadingColSpan}></td>}
                    {rotationYear.map((month, index) => renderCropIconMonth(rotationYear, crops, index, cropBlock, cropGrowthCurves, budget))}
                    {trailingColSpan > 0 && <td colSpan={trailingColSpan}></td>}
                </tr>
            )}

            {labelCells.some((l) => l.iconText) && (
                <tr className="Calendar-slots Calendar-slots--crop">
                    {leadingColSpan > 0 && <td colSpan={leadingColSpan}></td>}
                    {labelCells.map((cell, i) => {
                        return (
                            <td key={i} colSpan={cell.colSpan} className={"Calendar-slot"}>
                                {cell.iconText && (
                                    <span className="h6" style={{ opacity: cell.preCrop ? "0.6" : "1" }}>
                                        {cell.iconText}
                                    </span>
                                )}
                            </td>
                        );
                    })}
                    {trailingColSpan > 0 && <td colSpan={trailingColSpan}></td>}
                </tr>
            )}
            {showAnimals && animalCells.some((l) => l.iconText) && (
                <tr className="Calendar-slots Calendar-slots--crop">
                    {leadingColSpan > 0 && <td colSpan={leadingColSpan}></td>}
                    {showAnimals &&
                        animalCells.map((cell, i) => {
                            return (
                                <td key={i} className={"Calendar-slot"}>
                                    {cell.icon && <img className="Calendar-icon u-p-xxs" src={cell.icon} alt="crop icon" style={{ opacity: cell.preCrop ? "0.2" : "1" }} />}
                                </td>
                            );
                        })}
                    {showAnimals && trailingColSpan > 0 && <td colSpan={trailingColSpan}></td>}
                </tr>
            )}
        </thead>
    );
};

/**
 * Clean up the analysis from saving pasture and return the updated analysis
 */
export function getUpdatedAnalysisFromSavingPasture(analysis, blockIds, pasture, runoffCharacteristics) {
    let prevPasture = undefined;

    const blocks = analysis.blocks.map((block) => {
        if (!blockIds.includes(block.id)) {
            return block;
        }

        prevPasture = block.pasture;

        // Auto add/remove animals
        if (runoffCharacteristics) {
            let animals = block.animals || [];
            if (animals.length === 0) {
                (analysis.enterprises || [])
                    .filter((enterprise) => enterprise.type !== "OutdoorPigs")
                    .forEach((enterprise) => {
                        if (!animals.some((a) => a.enterpriseId === enterprise.id)) {
                            animals = animals.concat({
                                enterpriseId: enterprise.id,
                                months: [],
                            });
                        }
                    });
            }
            return {
                ...block,
                runoffCharacteristics,
                pasture: {
                    ...pasture,
                    topography: prevPasture ? prevPasture.topography : undefined,
                },
                animals
            };
        }

        return {
            ...block,
            runoffCharacteristics: null,
            animals: [],
            pasture: {
                ...pasture,
                topography: prevPasture ? prevPasture.topography : undefined,
            }
        };
    });

    // Tidy up any feed supplements distributed to this block
    let feedSupplements = analysis.feedSupplements || [];
    if (!runoffCharacteristics) {
        feedSupplements = feedSupplements.map((supplement) => {
            const destinations = (supplement.destinations || []).map((destination) => {
                const applications = (destination.applications || []).filter((application) => !blockIds.includes(application.blockId));
                return {
                    ...destination,
                    applications,
                };
            });
            return {
                ...supplement,
                destinations: destinations.filter((d) => d.type !== "SpecifiedBlocks" || d.applications.length > 0),
            };
        });
    }

    // Tidy up cut lucerne distributions
    if (prevPasture) {
        if (prevPasture.pastureCategory === "Lucerne" && pasture.pastureCategory !== "Lucerne") {
            // Pasture type changed from lucerne to something else. Move any distributed autumn cuts to spring.
            feedSupplements = feedSupplements.map((supplement) => {
                if (supplement.type !== "Harvested" || supplement.amountType !== "Cuts") return supplement;

                const destinations = (supplement.destinations || []).map((destination) => {
                    const amount = Number(destination.amount) || 0;
                    const amountAutumn = Number(destination.amountAutumn) || 0;
                    return {
                        ...destination,
                        amount: amount + amountAutumn,
                        amountAutumn: null,
                    };
                });

                return {
                    ...supplement,
                    destinations,
                };
            });
        } else if (prevPasture.pastureCategory !== "Lucerne" && pasture.pastureCategory === "Lucerne") {
            // Pasture type changed from something else to lucerne. Clear all distributed
            // amounts for harvested cuts.
            feedSupplements = feedSupplements.map((supplement) => {
                if (supplement.type !== "Harvested" || supplement.amountType !== "Cuts") return supplement;

                const destinations = (supplement.destinations || []).map((destination) => {
                    return {
                        ...destination,
                        amount: 0,
                        amountAutumn: 0,
                    };
                });

                return {
                    ...supplement,
                    destinations,
                };
            });
        }
    }

    // Tidy up any DCD distributions to this block
    let nitrateInhibitors = analysis.nitrateInhibitors || [];
    if (!runoffCharacteristics) {
        nitrateInhibitors = nitrateInhibitors
            .map((nitrateInhibitor) => {
                const applications = (nitrateInhibitor.applications || []).filter((application) => !application.blockIds.some((blockId) => blockIds.includes(blockId)));
                return {
                    ...nitrateInhibitor,
                    applications,
                };
            })
            .filter((nitrateInhibitor) => nitrateInhibitor.applications.length > 0);
    }

    return {
        ...analysis,
        blocks,
        feedSupplements,
        nitrateInhibitors
    };
}

/**
 * Clean up the analysis from saving fruit and return the updated analysis
 */
export function getUpdatedAnalysisFromSavingFruit(analysis, blockId, fruit) {
    const blocks = analysis.blocks.map((block) => {
        if (block.id !== blockId) return block;

        // Auto add/remove animals
        if (fruit.swardAnimalSource === "EnterpriseStock") {
            let animals = block.animals || [];
            if (animals.length === 0) {
                (analysis.enterprises || [])
                    .filter((enterprise) => enterprise.type !== "OutdoorPigs")
                    .forEach((enterprise) => {
                        if (!animals.some((a) => a.enterpriseId === enterprise.id)) {
                            animals = animals.concat({
                                enterpriseId: enterprise.id,
                                months: [],
                            });
                        }
                    });
            }
            return {
                ...block,
                fruit,
                animals,
            };
        } else {
            return {
                ...block,
                animals: [],
                fruit,
            };
        }
    });

    return {
        ...analysis,
        blocks
    };
}