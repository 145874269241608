import { combineEpics } from "redux-observable";
import apiEpics from "api";
import actionTypes, * as appActions from "./_actions";
import fileDownload from "js-file-download";
import moment from "moment";

const postRehydrateEpic = (action$, store) => {
    return action$.ofType(actionTypes.APP_REHYDRATE).map((rsp) => {
        return appActions.postRehydrate();
    });
};

const downloadBudgetEpic = (action$) => {
    return action$.ofType(actionTypes.APP_DOWNLOAD).map((rsp) => {
        const { payload = {} } = rsp;
        const { content, name } = payload;
        fileDownload(JSON.stringify(content), `${moment().format("YYYYMMDDhhmmss")}_${name}.json`);
        return { type: actionTypes.APP_DOWNLOAD_SUCCESS };
    });
};

const epics = combineEpics(downloadBudgetEpic, postRehydrateEpic, apiEpics);

export default epics;
