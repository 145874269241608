import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { Panel, PanelBody } from "components/Panel";
import NoData from "components/NoData/NoData";
import { OrgList } from "containers/Admin/Orgs";

export default function AllOrgs() {
    const { isLoading, error, orgs } = useAllOrgs();
    const hasData = orgs?.length > 0;

    return (
        <Panel loading={isLoading} error={error}>
            <PanelBody loading={isLoading}>
                {hasData
                    ? <OrgList orgs={orgs} />
                    : <NoData />
                }
            </PanelBody>
        </Panel>
    );
};

export function useAllOrgs() {
    const query = useQuery({
        queryKey: ["orgs"],
        queryFn: async () => httpClient.get("admin/accounts"),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isLoading: query.isLoading,
        error: query.error?.message,
        orgs: query.data
    };
}
