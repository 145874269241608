import { useAuthContext } from "common/hooks";
import * as utils from "common/utils";
import WidgetContainer from "components/WidgetContainer";

export default function FertiliserRecommendations({ farm, className, height }) {
    const { canCreateFertiliserRecommendations } = useAuthContext();
    if (!canCreateFertiliserRecommendations) {
        return null;
    }

    const fertiliserRecommendations = farm?.budgets?.filter((b) => b.type === "FertiliserRecommendation" && b.class === "Budget");
    const hasSubscriptions = fertiliserRecommendations?.length > 0 || false;

    return (
        <WidgetContainer id="fertiliser-recommendations" title="Fertiliser recommendations" icon="IconLink--fertiliser" className={className} linkTitle="View all" linkUrl={`/app/farm/${farm.id}/fertiliser-recommendations`} height={height}>
            <div>
                {!hasSubscriptions ? (
                    <div>
                        <div className="Widget-content-title">No fertiliser recommendations</div>
                        <p>You have not created any fertilisr recommendations</p>
                    </div>
                ) : (
                    <div className="SimpleTable">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Created</th>
                                </tr>
                            </thead>
                                <tbody>
                                    {fertiliserRecommendations
                                        .sort((a, b) => (a.createdOn > b.createdOn ? -1 : 1))
                                        .slice(0, 3)
                                        .map((a) => (
                                            <tr key={a.id}>
                                                <td>{a.name}</td>
                                                <td>{utils.dateOnly(a.createdOn)}</td>
                                            </tr>
                                        ))}
                                </tbody>
                        </table>
                    </div>
                )}
            </div>
        </WidgetContainer>
    )
}
