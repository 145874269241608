import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import Alert from "components/Alert";
import SelectPack from "components/SelectPack2";
import InputPack from "components/InputPack";
import Button from "components/Button/Button";
import { useRefData } from "common/hooks";

export default function TruckActivities({ form, values }) {
    const refData = useRefData();

    const _addActivity = () => {
        form.mutators.push("truckActivities", {});
    };

    const _removeActivity = (index) => {
        const results = values.truckActivities.reduce((acc, cur, i) => {
            if (i !== index) acc.push(cur);
            return acc;
        }, []);
        form.change("truckActivities", results);
    };

    const _hasActivities = (values.truckActivities || []).length > 0;

    const _hasAllActivities = (values.truckActivities || []).length === (refData.ghgTruckActivities || []).length;

    const _addActivityLink = !_hasAllActivities && (
        <span id="add-truck-activity-link" className="u-link IconLink--arrow-plus" onClick={_addActivity}>
            Add override
        </span>
    );

    return (
        <Tile title="Other material transportation" actions={_addActivityLink} padTop tertiary>
            <Alert type="info" text="The transport of materials other than fertilisers, supplements, and animals can be calculated using the distance travelled multiplied by the weight of the material being transported. Use this section to define the kilometre tonnes for the different truck types used in the transport of materials other than fertilisers, supplements, and animals." />
            <TileBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th>Truck type</th>
                                <th className="th--shrink">Kilometre tonnes</th>
                                {_hasActivities && <th className="th--shrink">&nbsp;</th>}
                            </tr>
                        </thead>
                        {!_hasActivities && (
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                        <div className="Tile-body">
                                            <div className="Tile-body-message">
                                                <h3 className="u-mt-md">This analysis is currently using the default settings for the transport of materials other than fertilisers, supplements and animals</h3>
                                                <Button id="add-truck-activity-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={_addActivity}>
                                                    Add override
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {_hasActivities && (
                            <tbody>
                                <FieldArray name="truckActivities" validate={_validate}>
                                    {({ fields }) => {
                                        return fields.map((field, i) => {
                                            const activityOptions = _getActivityOptions(refData, fields.value, i);
                                            return (
                                                <tr key={field}>
                                                    <td>
                                                        <Field name={`${field}.activity`} component={SelectPack} options={activityOptions} placeholder="Select a truck type" />
                                                    </td>
                                                    <td>
                                                        <Field name={`${field}.kilometreTonnes`} type="text" label="" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                                    </td>
                                                    <td>
                                                        <span id={`delete-truck-activity-${i}`} className="a u-link IconLink--trash" onClick={() => _removeActivity(i)}></span>
                                                    </td>
                                                </tr>
                                            );
                                        });
                                    }}
                                </FieldArray>
                            </tbody>
                        )}
                    </table>
                </div>
            </TileBody>
        </Tile>
    )
}

const _getActivityOptions = (refData, selectedActivities, index) => {
    const options = (refData.ghgTruckActivities || []).filter((option) => {
        const isSelected = (selectedActivities || []).some((selectedActivity, i) => i !== index && selectedActivity.activity === option.value);
        return !isSelected;
    });
    return options;
}

const _validate = (values) => {
    const errorArray = [];

    values &&
        values.forEach((value) => {
            const errors = {};

            errors.activity = FormUtils.validators.required(value.activity);

            errors.kilometreTonnes = FormUtils.validators.required(value.kilometreTonnes);
            errors.kilometreTonnes = errors.kilometreTonnes || FormUtils.validators.range(1, 99999)(value.kilometreTonnes);

            errorArray.push(errors);
        });

    return errorArray;
}

