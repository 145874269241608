import actionTypes from "./_actions";
import authActions from "containers/Auth/_actions";
import * as utils from "common/utils";

const INITIAL_STATE = {nutrientCompositions:[]};

function reducer(state = INITIAL_STATE, action) {
    if (action && action.type === authActions.AUTH_CLEAR_STORAGE) {
        return INITIAL_STATE;
    }

    if (utils.skipReducer(action, ["CUSTOM_COMPOSITIONS_", "USERDEFINEDCROPS_"])) {
        return state;
    }

    switch (action.type) {
        case actionTypes.CUSTOM_COMPOSITIONS_FETCH_SUCCESS:
            return action.payload;
        case actionTypes.CUSTOM_COMPOSITIONS_SUBMIT_SUCCESS: {
            const compositions = utils.merge(action.payload, state.nutrientCompositions);
            return {
                ...state,
                nutrientCompositions: compositions,
            };
        }
        case actionTypes.CUSTOM_COMPOSITIONS_DELETE_SUCCESS: {
            const compositions2 = state.nutrientCompositions.filter((p) => p.id !== action.payload.id);
            return {
                ...state,
                nutrientCompositions: compositions2,
            };
        }
        case actionTypes.USERDEFINEDCROPS_SUBMIT_SUCCESS:
            return {
                ...state,
                crops: action.payload,
            };

        default:
            return state;
    }
}

export default reducer;
