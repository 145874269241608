import * as utils from "common/utils";
import * as icons from "common/icons";
import { useConfirm, useRefData } from "common/hooks";
import Calendar from "components/Calendar";
import { climateViewModel } from "common/viewModels";
import ActionLink from "components/ActionLink";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function BlockListCard({ farm, analysis, block }) {
    const confirm = useConfirm();
    const refData = useRefData();

    const showClimate = utils.requiresClimate(block.type);

    let snowfallIndex = 1;
    const snowfall = (analysis.snowfalls || []).find((snowfall, index) => {
        snowfallIndex = index + 1;
        return snowfall.blockIds.some((blockId) => blockId === block.id);
    });
    const viewModel = climateViewModel(farm, analysis, block, refData);

    const snowfallCalendarData = useSnowfallCalenderData(farm, analysis, snowfall, snowfallIndex, confirm);

    return (
        <>
            <div className="FarmTable_wrapper">
                <ul className="DataWidthTable FarmTable">
                    <BlockTitle block={block} />
                    {showClimate && (
                        <>
                            <li data-width="md-25 xl-17.5">
                                <div className="FarmTable-keyValuePair">
                                    <div className="FarmTable-keyValue">
                                        <span className="FarmTable-key">Average temp</span>
                                        <span className="FarmTable-value">{viewModel.averageTemp}</span>
                                    </div>
                                </div>
                            </li>
                            <li data-width="md-25 xl-17.5">
                                <div className="FarmTable-keyValuePair">
                                    <div className="FarmTable-keyValue">
                                        <span className="FarmTable-key">Annual rainfall</span>
                                        <span className="FarmTable-value">{viewModel.averageRain.toLocaleString()}</span>
                                    </div>
                                </div>
                            </li>
                            <li data-width="md-25 xl-17.5">
                                <div className="FarmTable-keyValuePair">
                                    <div className="FarmTable-keyValue">
                                        <span className="FarmTable-key">Annual PET</span>
                                        <span className="FarmTable-value">{viewModel.annualPET.toLocaleString()}</span>
                                    </div>
                                </div>
                            </li>
                            <li data-width="md-25 xl-17.5">
                                <div className="FarmTable-keyValuePair">
                                    <div className="FarmTable-keyValue">
                                        <span className="FarmTable-key">Snowfall</span>
                                        <span className="FarmTable-value">{viewModel.canHaveSnowfall}</span>
                                    </div>
                                </div>
                            </li>
                        </>
                    )}
                </ul>
                <div className="FarmTable-supplementary">{snowfallCalendarData && <Calendar data={snowfallCalendarData} />}</div>
            </div>
        </>
    )
}

function BlockTitle({ block }) {
    const refData = useRefData();

    const blockIcon = utils.getBlockIcon(block.type);
    const blockType = utils.getBlockTypeText(refData, block);

    return (
        <li className="FarmTable-title" data-width="md-100 xl-30">
            <div className="FarmTitle">
                <img className="FarmTitle-icon" src={blockIcon} alt="Block" />
                <div className="FarmTitle-heading">
                    <span className="FarmTitle-name" title={block.name}>
                        {block.name}
                    </span>
                    <div className="FarmTitle-sub">{blockType}</div>
                </div>
            </div>
        </li>
    )
}

function useSnowfallCalenderData(farm, analysis, snowfall, snowfallIndex, confirm) {
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    if (!snowfall) return null;

    const url = `/app/farm/${farm.id}/analysis/${analysis.id}/climate/snowfall/${snowfall.id}`;

    const deleteSnowfall = (analysis, snowfall, snowfallIndex) => (e) => {
        utils.stopPropagation(e);
        confirm(`Are you sure you want to delete Snowfall ${snowfallIndex}?`, async () => {
            analysis.snowfalls = analysis.snowfalls.filter(s => s.id !== snowfall.id);
            await updateAnalysisAsync(analysis);
        });
    }

    const subTitle = (
        <span className="FarmTable-value-link a">
            <span>Snowfall {snowfallIndex}</span>
            <span className="icon icon-edit"></span>
        </span>
    )

    return [
        {
            id: 1,
            title: "Snowfall",
            subTitle: subTitle,
            url: url,
            slots: snowfall.snowfallMonths.map((snowfallMonth) => {
                return {
                    month: snowfallMonth.month,
                    icon: icons.snow,
                    iconText: `${snowfallMonth.amount} cm`,
                };
            }),
            actions: (
                <ActionLink id={`delete-snowfall-${snowfall.id}`} onClick={deleteSnowfall(analysis, snowfall, snowfallIndex)} className="icon icon-trash" title="Delete snowfall" />
            )
        }
    ];
}
