import * as api from "api";
import ReactGA from "react-ga4";

export const findEmails = (search) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const searchText = [];
        search.start && searchText.push(`start=${search.start}`);
        search.end && searchText.push(`end=${search.end}`);
        search.toEmail && searchText.push(`toEmail=${encodeURIComponent(search.toEmail)}`);
        search.fromEmail && searchText.push(`fromEmail=${encodeURIComponent(search.fromEmail)}`);
        ReactGA.event({
            category: "Admin",
            action: "Find emails",
        });
        return dispatch(
            api.get({
                path: `admin/emails/search${searchText.reduce((t, q, i) => `${t}${i === 0 ? "?" : "&"}${q}`, "")}`,
                content: search,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};
