import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import { Grid, GridCell } from "components/Grid";
import InputPack from "components/InputPack";

export default function OutdoorPigNumbersSows({ outdoorPigs }) {
    const weanedPerWeek = getWeanedPerWeek(outdoorPigs);
    return (
        <Grid title="Sows">
            <GridCell className="u-width1of4">
                <Field name="numbers.sows.numberSowsMatedGilts" label="Number of sows and mated gilts" placeholder="0" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
            </GridCell>
            <GridCell className="u-width1of4">
                <Field name="numbers.sows.culledChopperCarcassWeight" label="Chopper (carcass) weight" uom="kg" placeholder="0" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
            </GridCell>
            <GridCell className="u-width1of4">
                <Field name="numbers.sows.replacementRate" label="Replacement rate" uom="%" placeholder="0" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
            </GridCell>
            <GridCell className="u-width1of4">
                <Field name="numbers.sows.littersPerYear" label="Litters per year" placeholder="0.00" required type="text" component={InputPack} format={FormUtils.formatters.formatDecimal(2)} formatOnBlur />
            </GridCell>
            <GridCell className="u-width1of4">
                <Field name="numbers.sows.weanedPerLitter" label="Average weaned per litter" placeholder="0.0" required type="text" component={InputPack} format={FormUtils.formatters.formatDecimal(1)} formatOnBlur />
            </GridCell>
            <GridCell className="u-width1of4">
                <div className="Field">
                    <label className="Field-label">Average weaned per week</label>
                    <div className="Input_wrapper">
                        <div className="Input Input--block Input--disabled">
                            <div style={{ position: "relative", top: "-4.5px" }}>{weanedPerWeek}</div>
                        </div>
                    </div>
                </div>
            </GridCell>
            <GridCell className="u-width1of4">
                <Field name="numbers.sows.weaningAge" label="Weaning age" placeholder="0" uom="days" required type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
            </GridCell>
            <GridCell className="u-width1of4">
                <Field name="numbers.sows.weaningLiveWeight" label="Weaning live weight" placeholder="0.0" uom="kg" required type="text" component={InputPack} format={FormUtils.formatters.formatDecimal(1)} formatOnBlur />
            </GridCell>
        </Grid>
    )
}

export const validateSows = (sows) => {
    const errors = {};

    errors.numberSowsMatedGilts = FormUtils.validators.required(sows.numberSowsMatedGilts);
    errors.numberSowsMatedGilts = errors.numberSowsMatedGilts || FormUtils.validators.max(99999)(sows.numberSowsMatedGilts);

    errors.culledChopperCarcassWeight = FormUtils.validators.required(sows.culledChopperCarcassWeight);
    errors.culledChopperCarcassWeight = errors.culledChopperCarcassWeight || FormUtils.validators.range(60, 250)(sows.culledChopperCarcassWeight);

    errors.replacementRate = FormUtils.validators.required(sows.replacementRate);
    errors.replacementRate = errors.replacementRate || FormUtils.validators.range(20, 100)(sows.replacementRate);

    errors.littersPerYear = FormUtils.validators.required(sows.littersPerYear);
    errors.littersPerYear = errors.littersPerYear || FormUtils.validators.range(1, 2.5)(sows.littersPerYear);

    errors.weanedPerLitter = FormUtils.validators.required(sows.weanedPerLitter);
    errors.weanedPerLitter = errors.weanedPerLitter || FormUtils.validators.range(6, 16)(sows.weanedPerLitter);

    errors.weaningAge = FormUtils.validators.required(sows.weaningAge);
    errors.weaningAge = errors.weaningAge || FormUtils.validators.range(1, 49)(sows.weaningAge);

    errors.weaningLiveWeight = FormUtils.validators.required(sows.weaningLiveWeight);
    errors.weaningLiveWeight = errors.weaningLiveWeight || FormUtils.validators.range(5, 15)(sows.weaningLiveWeight);

    return errors;
}

const getWeanedPerWeek = (outdoorPigs) => {
    const numbers = outdoorPigs.numbers || {};
    const sows = numbers.sows || {};
    const { numberSowsMatedGilts = 0, littersPerYear = 0, weanedPerLitter = 0 } = sows;
    const weanedPerWeek = (numberSowsMatedGilts * littersPerYear * weanedPerLitter) / 52;
    const result = FormUtils.formatters.formatDecimal(2)(weanedPerWeek) || 0;
    return result;
}
