import * as utils from "common/utils";
import * as futils from "containers/BudgetHome/Fertiliser/_utils";

export const getTableRowClass = (rowType) => {
    if (rowType === "N") return "Table-tr--nloss";

    if (rowType === "P") return "Table-tr--ploss";

    if (rowType === "K") return "Table-tr--kloss";

    if (rowType === "S") return "Table-tr--sloss";

    return ``;
}

export const getFertiliserNutrientsApplied = (analysis) => {
    const fodderDetails = utils.getFodderBlockAreas(analysis.blocks);

    return analysis.blocks
        .filter((b) => b.isProductive)
        .reduce((t, b) => {
            const area = futils.GetProductiveBlockArea(b, fodderDetails);
            updateForBlock(b, area, t);
            return t;
        }, []);
}

const updateForBlock = (block, area, records) => {
    const nFertiliserApplied = getFertiliserNutrients(block, area, "N");
    updateForBlockNutrientAndType(block, area, "N", "Synthetic", records, nFertiliserApplied);
    updateForBlockNutrientAndType(block, area, "N", "Organic", records, nFertiliserApplied);
    const pFertiliserApplied = getFertiliserNutrients(block, area, "P");
    updateForBlockNutrientAndType(block, area, "P", "Synthetic", records, pFertiliserApplied);
    updateForBlockNutrientAndType(block, area, "P", "Organic", records, pFertiliserApplied);
}

const getFertiliserNutrients = (block, area, nutrient) => {
    const fertiliserBudget = block?.currentResults?.nutrientBudget?.nutrientCategories?.Fertiliser;

    const total = fertiliserBudget && fertiliserBudget.total.nutrients[nutrient] ? fertiliserBudget.total.nutrients[nutrient] * area : 0;
    const Synthetic = fertiliserBudget && fertiliserBudget.subTotals.Nutrients.nutrients[nutrient] ? fertiliserBudget.subTotals.Nutrients.nutrients[nutrient] * area : 0;
    const Organic = total - Synthetic;
    return { Synthetic, Organic };
}

const updateForBlockNutrientAndType = (block, area, nutrient, fertiliserType, records, fertiliserApplied) => {
    var summary = records.find((s) => s.blockType === block.type && s.fertiliserType === fertiliserType && s.nutrient === nutrient);

    if (summary == null) {
        summary = { blockType: block.type, nutrient: nutrient, fertiliserType: fertiliserType, total: 0, area: 0 };
        records.push(summary);
    }

    if (fertiliserApplied && fertiliserApplied[fertiliserType]) {
        summary.total += fertiliserApplied[fertiliserType];
        summary.area += area;
    }
}
