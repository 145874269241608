import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Page from "components/Page";
import PageBody from "components/PageBody";
import Alert from "components/Alert";
import DashboardPageHead from "./DashboardPageHead";
import MyRequiredActions from "./MyRequiredActions";
import MyPendingFarmAccessRequests from "./MyPendingFarmAccessRequests";
import MyFarms from "./MyFarms";
import MyPublications from "./MyPublications";
import MyFarmGroups from "./MyFarmGroups";
import MyPendingFarmAccessInvitations from "./MyPendingFarmAccessInvitations";
import { useEmailTracking } from "common/effects";
import { useShowQuickTips } from "common/hooks";

export default function Dashboard() {
    const activationFailed = useSelector((state) => state.auth.activationFailed);
    const showQuickTips = useShowQuickTips();
    const location = useLocation();
    const referredFromActivate = location.state ? location.state.from === "/pub/activate" : false;
    useEmailTracking("Main dashboard opened", "");

    return (
        <Page>
            <DashboardPageHead />
            <PageBody>
                {showQuickTips && <Alert type="help" text="This is the dashboard. It contains any notifications about the system or new releases. You can use the search button in the menu above to search for farms and then add them to My Farms where they will appear here. If you wish to create a new farm select the Create New Farm link above. Please check whether the farm exists before creating a new one." />}
                {referredFromActivate && activationFailed && <Alert id="lnk_activation" type="error" text="The activation link was invalid or you have already activated your login." />}
                {referredFromActivate && !activationFailed && <Alert id="lnk_activation" type="success" text="Email verified!" />}
                <MyRequiredActions />
                <MyPendingFarmAccessRequests />
                <MyPendingFarmAccessInvitations />
                <MyFarms />
                <MyPublications />
                <MyFarmGroups />
            </PageBody>
        </Page>
    )
}
