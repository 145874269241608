import React from "react";
import { Panel, PanelBody } from "components/Panel";
import { useDispatch } from "react-redux";
import { getFarmStats } from "containers/Admin/_actions";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import { useRefData } from "common/hooks";

const SubscriptionsReport = () => {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [cacheLoading, setCacheLoading] = React.useState(false);
    const dispatch = useDispatch();
    const refData = useRefData();
    const { regions = [] } = refData;
    const [expandRegion, setExpandRegion] = React.useState(false);
    const [expandFarmType, setExpandFarmType] = React.useState(false);
    const farmTypes = ["Mixed", "NonDairyAnimals", "Dairy", "CropAndHorticulture", "Unknown"];

    const tSubs = data.filter((d) => d.notExpired);
    const tHadSub = data.filter((d) => d.renewals > 0);
    const tOwner = data.filter((d) => d.owner1Email && d.owner1Email.length > 0);
    const tPenOwnerInvite = data.filter((d) => d.invitedBy);
    const tPenOwnerRequest = data.filter((d) => d.requestedBy);

    React.useEffect(() => {
        setLoading(true);
        const getFarmStatistics = async () => {
            const results = await dispatch(getFarmStats());
            if (Array.isArray(results) && results.length > 0) {
                setData(results);
            } else {
                setCacheLoading(true);
            }
            setLoading(false);
        };
        getFarmStatistics();
    }, [dispatch]);

    const downloadCsv = (csvContent, fileName) => {
        var blob = new Blob([csvContent], { type: "text/csv" });
        var url = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    const createCsvContent = (farms) => {
        const format = (value) => {
            const text = typeof value === "string" || value instanceof String ? value.replace(/,/g, "") : value;
            return text;
        };
        const headers = ["id", "region", "town", "farmType", "farmName", "hasResults", "hasBeef", "hasDairy", "hasSheep", "hasOtherAnimals", "invitedby", "requestedBy", "renewals", "expiresAt", "notExpired", "lastPaidByAccount", "lastPaidByUser", "owner1Name", "owner1Email"].join(",") + "\n";
        const rows = farms.map((farm) => {
            return [farm.id, farm.region, farm.town, farm.farmType, farm.farmName, farm.hasResults, farm.hasBeef, farm.hasDairy, farm.hasSheep, farm.hasOtherAnimals, farm.invitedBy, farm.requestedBy, farm.renewals, farm.expiredAt, farm.notExpired, farm.lastPaidByAccount, farm.lastPaidByUser, farm.owner1Name, farm.owner1Email].map(format).join(",") + "\n";
        });

        return headers + rows.join("");
    };

    const SplitTd = ({ totalFarms, farms }) => {
        const amount = farms ? farms.length : 0;
        const total = totalFarms ? totalFarms.length : 0;
        return (
            <td>
                <div className="u-flex u-flexJustifyBetween">
                    <div className="u-textCoolBlue">{total > 0 ? Math.round((amount / total) * 100) : 0}%</div>
                    <div className="u-text-sm">
                        <ActionLink onClick={() => downloadCsv(createCsvContent(farms), "farms.csv")}>{amount}</ActionLink>
                    </div>
                </div>
            </td>
        );
    };

    return (
        <Panel title="Subscriptions Report" loading={loading} green>
            <PanelBody loading={loading}>
                {cacheLoading ? (
                    <Alert type="warning" text="No data found in cache. Please try again in 5 minutes." />
                ) : (
                    <>
                        <div className="h1 u-mb-sm u-mt-md">Regional Report</div>
                        <div className="Table">
                            <table style={{ tableLayout: "fixed" }}>
                                <thead>
                                    <tr>
                                        <th>Region</th>
                                        <th>Farms</th>
                                        <th>Current Sub</th>
                                        <th>Has had Sub</th>
                                        <th>Has Owner</th>
                                        <th>Pending Owner Invites</th>
                                        <th>Pending Owner Requests</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {regions.map((region) => {
                                        const regionData = data.filter((d) => d.region === region.value);
                                        const currentSub = regionData.filter((d) => d.notExpired);
                                        const hasHadSub = regionData.filter((d) => d.renewals > 0);
                                        const hasOwner = regionData.filter((d) => d.owner1Email && d.owner1Email.length > 0);
                                        const pendOwnerInvite = regionData.filter((d) => d.invitedBy);
                                        const pendOwnerRequest = regionData.filter((d) => d.requestedBy);
                                        const isExpanded = expandRegion === region.value;

                                        return (
                                            <>
                                                <tr key={region.value}>
                                                    <td>
                                                        <div className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
                                                            <h4>{region.text}</h4>
                                                            <ActionLink className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween" onClick={() => setExpandRegion(isExpanded ? false : region.value)}>
                                                                <i className={`icon icon-arrow-${isExpanded ? "up" : "down"}`}></i>
                                                            </ActionLink>
                                                        </div>
                                                    </td>

                                                    <SplitTd totalFarms={data} farms={regionData} />
                                                    <SplitTd totalFarms={regionData} farms={currentSub} />
                                                    <SplitTd totalFarms={regionData} farms={hasHadSub} />
                                                    <SplitTd totalFarms={regionData} farms={hasOwner} />
                                                    <SplitTd totalFarms={regionData} farms={pendOwnerInvite} />
                                                    <SplitTd totalFarms={regionData} farms={pendOwnerRequest} />
                                                </tr>
                                                {isExpanded && (
                                                    <>
                                                        {farmTypes.map((farmType) => {
                                                            const farmTypeData = regionData.filter((r) => (farmType === "Unknown" ? !farmTypes.includes(r.farmType) : r.farmType === farmType));
                                                            const ftCurrentSub = farmTypeData.filter((d) => d.notExpired);
                                                            const ftHasHadSub = farmTypeData.filter((d) => d.renewals > 0);
                                                            const ftHasOwner = farmTypeData.filter((d) => d.owner1Email && d.owner1Email.length > 0);
                                                            const ftPendOwnerInvite = farmTypeData.filter((d) => d.invitedBy);
                                                            const ftPendOwnerRequest = farmTypeData.filter((d) => d.requestedBy);
                                                            return (
                                                                <tr className="tr-nested">
                                                                    <td>{farmType}</td>
                                                                    <SplitTd totalFarms={regionData} farms={farmTypeData} />
                                                                    <SplitTd totalFarms={farmTypeData} farms={ftCurrentSub} />
                                                                    <SplitTd totalFarms={farmTypeData} farms={ftHasHadSub} />
                                                                    <SplitTd totalFarms={farmTypeData} farms={ftHasOwner} />
                                                                    <SplitTd totalFarms={farmTypeData} farms={ftPendOwnerInvite} />
                                                                    <SplitTd totalFarms={farmTypeData} farms={ftPendOwnerRequest} />
                                                                </tr>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </>
                                        );
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td align="right">
                                            <ActionLink onClick={() => downloadCsv(createCsvContent(data), "farms.csv")}>{data.length.toLocaleString()}</ActionLink>
                                        </td>
                                        <SplitTd totalFarms={data} farms={tSubs} />
                                        <SplitTd totalFarms={data} farms={tHadSub} />
                                        <SplitTd totalFarms={data} farms={tOwner} />
                                        <SplitTd totalFarms={data} farms={tPenOwnerInvite} />
                                        <SplitTd totalFarms={data} farms={tPenOwnerRequest} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div className="h1 u-mb-sm u-mt-lg">Enterprise Report</div>
                        <div className="Table">
                            <table style={{ tableLayout: "fixed" }}>
                                <thead>
                                    <tr>
                                        <th>Enterprise</th>
                                        <th>Farms</th>
                                        <th>Current Sub</th>
                                        <th>Has had Sub</th>
                                        <th>Has Owner</th>
                                        <th>Pending Owner Invite</th>
                                        <th>Pending Owner Request</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {farmTypes.map((farmType) => {
                                        const farmTypeData = data.filter((r) => (farmType === "Unknown" ? !farmTypes.includes(r.farmType) : r.farmType === farmType));

                                        const currentSub = farmTypeData.filter((d) => d.notExpired);
                                        const hasHadSub = farmTypeData.filter((d) => d.renewals > 0);
                                        const hasOwner = farmTypeData.filter((d) => d.owner1Email && d.owner1Email.length > 0);
                                        const pendOwnerInvite = farmTypeData.filter((d) => d.invitedBy);
                                        const pendOwnerRequest = farmTypeData.filter((d) => d.requestedBy);
                                        const isExpanded = expandFarmType === farmType;

                                        return (
                                            <>
                                                <tr key={farmType}>
                                                    <td>
                                                        <div className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
                                                            <h4>{farmType}</h4>
                                                            <ActionLink className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween" onClick={() => setExpandFarmType(isExpanded ? false : farmType)}>
                                                                <i className={`icon icon-arrow-${isExpanded ? "up" : "down"}`}></i>
                                                            </ActionLink>
                                                        </div>
                                                    </td>

                                                    <SplitTd totalFarms={data} farms={farmTypeData} />
                                                    <SplitTd totalFarms={farmTypeData} farms={currentSub} />
                                                    <SplitTd totalFarms={farmTypeData} farms={hasHadSub} />
                                                    <SplitTd totalFarms={farmTypeData} farms={hasOwner} />
                                                    <SplitTd totalFarms={farmTypeData} farms={pendOwnerInvite} />
                                                    <SplitTd totalFarms={farmTypeData} farms={pendOwnerRequest} />
                                                </tr>
                                                {isExpanded && (
                                                    <>
                                                        {regions.map((region) => {
                                                            const regionData = farmTypeData.filter((r) => r.region === region.value);

                                                            const ftCurrentSub = regionData.filter((d) => d.notExpired);
                                                            const ftHasHadSub = regionData.filter((d) => d.renewals > 0);
                                                            const ftHasOwner = regionData.filter((d) => d.owner1Email && d.owner1Email.length > 0);
                                                            const ftPendOwnerInvite = regionData.filter((d) => d.invitedBy);
                                                            const ftPendOwnerRequest = regionData.filter((d) => d.requestedBy);
                                                            return (
                                                                <tr className="tr-nested">
                                                                    <td>{region.text}</td>
                                                                    <SplitTd totalFarms={farmTypeData} farms={regionData} />
                                                                    <SplitTd totalFarms={farmTypeData} farms={ftCurrentSub} />
                                                                    <SplitTd totalFarms={farmTypeData} farms={ftHasHadSub} />
                                                                    <SplitTd totalFarms={farmTypeData} farms={ftHasOwner} />
                                                                    <SplitTd totalFarms={farmTypeData} farms={ftPendOwnerInvite} />
                                                                    <SplitTd totalFarms={farmTypeData} farms={ftPendOwnerRequest} />
                                                                </tr>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </>
                                        );
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td align="right">
                                            <ActionLink onClick={() => downloadCsv(createCsvContent(data), "farms.csv")}>{data.length.toLocaleString()}</ActionLink>
                                        </td>
                                        <SplitTd totalFarms={data} farms={tSubs} />
                                        <SplitTd totalFarms={data} farms={tHadSub} />
                                        <SplitTd totalFarms={data} farms={tOwner} />
                                        <SplitTd totalFarms={data} farms={tPenOwnerInvite} />
                                        <SplitTd totalFarms={data} farms={tPenOwnerRequest} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </>
                )}
            </PanelBody>
        </Panel>
    );
};

export default SubscriptionsReport;
