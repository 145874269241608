import { numeric } from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import Watermark from "components/Watermark";
import { useRefData } from "common/hooks";

export default function ProductionFootprint({ latestResults }) {
    const hasResults = latestResults && latestResults.ghgResults && latestResults.ghgResults.productionResults && latestResults.ghgResults.total !== 0;
    const refData = useRefData();

    if (!hasResults) return null;

    const animalTypes = ["Dairy", "DairyReplacements", "Sheep", "Beef", "DairyGrazing", "Deer", "DairyGoats", "OutdoorPigs", "OtherAnimals"];
    const animalFootprint = latestResults.ghgResults ? Object.keys(latestResults.ghgResults.productionResults).filter((k) => animalTypes.includes(k) && latestResults.ghgResults.productionResults[k].cO2Source > 0) : [];
    const nonAnimalFootprint = latestResults.ghgResults ? Object.keys(latestResults.ghgResults.productionResults).filter((k) => !animalTypes.includes(k) && latestResults.ghgResults.productionResults[k].cO2Source > 0) : [];

    return (
        <Panel title="Emissions by enterprise and area" skyBlue info="The following table lists CO2 equivalent (CO2e) footprints in KGs on a per farm enterprise.">
            <PanelBody>
                <Grid>
                    {animalFootprint.length > 0 && (
                        <GridCell className="u-md-width1of1 u-lg-width1of2 u-pb-md">
                            <div className="h3 u-mb-sm">Animals</div>
                            <div className="Table">
                                <Watermark />
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="50%">Enterprise</th>
                                            <th className="numeric">kg CO2e per unit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {animalFootprint.map((k, i) => (
                                            <tr key={i} id={`${k}-row`}>
                                                <td>{((refData.productionTypes && refData.productionTypes.find((r) => r.value === k)) || { text: k }).text}</td>
                                                <td className="numeric" id={`${k}_cO2Footprint`}>
                                                    {latestResults.ghgResults.productionResults[k].cO2Footprint.toLocaleString() + (k === "Dairy" ? " per cow" : k === "FruitCrop" || k === "VegetableArable" || k === "ExportedSupplement" ? " per ha" : " per SU")}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </GridCell>
                    )}
                    {nonAnimalFootprint.length > 0 && (
                        <GridCell className="u-md-width1of1 u-lg-width1of2 u-pb-md">
                            <div className="h3 u-mb-sm">Crops and supplements</div>
                            <div className="Table u-mt-0">
                                <Watermark />
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="50%">Enterprise</th>
                                            <th className="numeric">kg CO2e/unit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {nonAnimalFootprint.map((k, i) => (
                                            <tr key={i} id={`${k}-row`}>
                                                <td>{((refData.productionTypes && refData.productionTypes.find((r) => r.value === k)) || { text: k }).text}</td>
                                                <td className="numeric" id={`${k}_cO2Footprint`}>
                                                    {numeric.format(latestResults.ghgResults.productionResults[k].cO2Footprint) + (k === "Dairy" ? " per cow" : k === "FruitCrop" || k === "VegetableArable" || k === "ExportedSupplement" ? " per ha" : " per SU")}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </GridCell>
                    )}
                </Grid>
            </PanelBody>
        </Panel>
    )
}
