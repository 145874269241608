import React from "react";

const PhysicalComparison = ({ results = {} }) => {
    return (
        <div className="Table Table-Compressed">
            <table>
                <thead>
                    <tr>
                        <th className="h3" data-width="40">
                            Physical characteristics
                        </th>
                        <th className="u-textCenter" data-width="20">
                            Before
                        </th>
                        <th className="u-textCenter" data-width="20">
                            After
                        </th>
                        <th className="u-textRight" data-width="20">
                            Change
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <PhysicalComparisonRow result={results.farmArea} label="Farm area (ha)" />
                    <PhysicalComparisonRow result={results.temperature} label="Average temperature (&deg;C)" />
                    <PhysicalComparisonRow result={results.rainfallMM} label="Average rainfall (mm)" />
                    <PhysicalComparisonRow result={results.petmm} label="Average PET (mm)" />
                    <PhysicalComparisonRow result={results.productiveBlockArea} label="Productive block area (ha)" />
                    {results.soilAreas && results.soilAreas.map((result, index) => <PhysicalComparisonRow key={index} result={result} label={`${result.name} area (ha)`} />)}
                </tbody>
            </table>
        </div>
    );
};

const PhysicalComparisonRow = ({ result, label }) => {
    const diff = (result && result.to - result.from) || 0;
    const increased = diff > 0;
    const decreased = diff < 0;

    const from = result && result.from >= 0 ? result.from.toLocaleString() : "-";
    const to = result && result.to >= 0 ? result.to.toLocaleString() : "-";
    const change = increased ? `+${diff.toLocaleString()}` : decreased ? diff.toLocaleString() : "-";
    const changeTextClassName = increased ? "u-textError u-textBold" : decreased ? "u-textSuccess u-textBold" : "";
    const changeArrowClassName = increased ? "IconLink--arrow-up-right IconLink--compressed" : decreased ? "IconLink--arrow-down-right IconLink--compressed" : "";

    return (
        <tr>
            <td className="">{label}</td>
            <td className="u-textCenter">{from}</td>
            <td className="u-textCenter">{to}</td>
            <td className={changeTextClassName}>
                <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                    <span className={changeArrowClassName}></span>
                    <span>{change}</span>
                </div>
            </td>
        </tr>
    );
};

export default PhysicalComparison;
