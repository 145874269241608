import React from "react";
import { Link, withRouter, matchPath } from "react-router-dom";
import Spinner from "components/Spinner/Spinner";

const PanelTabsItem = ({ id, title, to, exact, location, className, waiting }) => {
    const path = to.pathname ? to.pathname : to;
    const isActive = matchPath(location.pathname, { path, exact });
    return (
        <li id={id} className={`PanelTabs-item ${isActive ? "is-active" : ""} ${className ? className : ""}`}>
            <Link to={to}>
                <span className={`PanelTabs-item-name ${isActive && waiting ? "u-mr-sm" : ""}`}>{title}</span>
                {isActive && waiting && <Spinner small />}
            </Link>
        </li>
    );
};

export default withRouter(PanelTabsItem);
