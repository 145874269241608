import ReactGA from "react-ga4";
import * as api from "api";

export const getPublicationsByYear = (year, includeLatestYearEnds) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Reporting",
            action: "Get Publications by Year",
        });
        return dispatch(
            api.get({
                path: `reporting/publications/${year}?includeLatestYearEnds=${includeLatestYearEnds ? "true" : "false"}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getPublicationsByFarmGroup = (year, includeLatestYearEnds, farmGroupId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Reporting",
            action: "Get Publications by Year",
        });
        return dispatch(
            api.get({
                path: `reporting/farmGroup/${farmGroupId}/publications/${year}?includeLatestYearEnds=${includeLatestYearEnds ? "true" : "false"}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getBenchmarkStatistics = (criteria) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Reporting",
            action: "Get Benchmark Statistics",
        });
        return dispatch(
            api.post({
                path: `reporting/benchmarks/statistics`,
                content: criteria,
                onSuccess: (response) => {
                    resolve(response);
                    return {};
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return {};
                },
            })
        );
    });
};

export const getNationalReportingData = (criteria) => (dispatch) => {
    const queryString = Object.keys(criteria)
        .filter((key) => key !== "year")
        .reduce((results, key) => {
            if (criteria[key] && criteria[key].length > 0) results += `${key}=${criteria[key]}&`;
            return results;
        }, "");
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get National Reporting Data",
        });
        return dispatch(
            api.get({
                path: `reporting/national/${criteria.year}?${queryString}`,
                timeout: 300000,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    return [];
                },
            })
        );
    });
};
