import React from "react";
import numeral from "numeral";
import * as utils from "common/utils";
import { Link } from "react-router-dom";

class AccountBalance extends React.Component {
    render() {
        const { accountId, txns = {}, authContext } = this.props;
        const isAuthorised = authContext.isSystemAdmin || authContext.accountId === accountId;
        const lines = utils.clone(txns).sort(utils.compareDate("created", true));

        return (
            <React.Fragment>
                {!isAuthorised && (
                    <div className="Tile-body-message">
                        <p className="lead">Access denied</p>
                    </div>
                )}
                {isAuthorised && (
                    <React.Fragment>
                        {lines.length === 0 ? (
                            <div className="Tile-body-message">
                                <p className="lead">No data</p>
                            </div>
                        ) : (
                            <div className="Table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Farms</th>
                                            <th>User name</th>
                                            <th>Reference</th>
                                            <th className="u-textRight">Amount</th>
                                            <th className="u-textRight">GST</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lines.map((balance, i) => {
                                            return (
                                                <tr key={i} className="hover">
                                                    <td>{utils.dateLong(balance.transactionTime, "-")}</td>
                                                    <td>
                                                        {balance.farms.length > 0 &&
                                                            balance.farms.map((farm, index) => {
                                                                const isLastInTheList = index === balance.farms.length - 1;
                                                                return (
                                                                    <span key={farm.id}>
                                                                        <Link to={`/app/farm/${farm.id}`}>
                                                                            {farm.name}
                                                                            {isLastInTheList ? "" : ", "}
                                                                        </Link>
                                                                    </span>
                                                                );
                                                            })}
                                                        {balance.farms.length === 0 && <>-</>}
                                                    </td>
                                                    <td>{balance.userFullName}</td>
                                                    <td>{balance.reference}</td>
                                                    <td className="u-textRight">{numeral(balance.amountExclGst).format("$0,0.00")}</td>
                                                    <td className="u-textRight">{numeral(balance.gst).format("$0,0.00")}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default AccountBalance;
