import { useParams } from "react-router-dom";
import AnalysisDetails from "./AnalysisDetails";
import Page from "components/Page";
import PageBody from "components/PageBody";
import * as datasetUtils from "./_utils";
import { useFarm } from "containers/hooks";
import { useCreateDatasetAnalysisAsync } from "./_hooks";

export default function AnalysisCreate() {
    const { datasetId, farmId, budgetId } = useParams();
    const createDatasetAnalysisAsync = useCreateDatasetAnalysisAsync();

    let { data: farm } = useFarm(farmId);
    if (!farm) {
        farm = {
            name: "",
            address: "",
            id: farmId,
            isNew: true,
            isOwner: "Yes",
            budgets: [],
            datasetId,
        };
    }

    let analysis = farm.budgets.find(b => b.id === budgetId);
    if (!analysis) {
        analysis = {
            id: budgetId,
            type: "Scenario",
            farmId: farmId,
            hasCompletedBlocks: true,
            isNew: true,
            blocks: [datasetUtils.createEmptyBlock("Block 1")],
            soils: [],
            farmSoilBlocks: [],
        };
    }

    const farmViewModel = {
        farm,
        analysis: datasetUtils.analysisViewModel(analysis)
    };

    return (
        <Page>
            <PageBody>
                <AnalysisDetails datasetId={datasetId} farmViewModel={farmViewModel} onSave={createDatasetAnalysisAsync} />
            </PageBody>
        </Page>
    )
}
