import ActionLink from "components/ActionLink";
import { usePublicationDetailsModal } from "./PublicationDetailsModal";

export default function ManagePublicationActionLink({ publication }) {
    const [addOrEditPublicationModal, openAddOrEditPublicationModal] = usePublicationDetailsModal(publication);
    return (
        <>
            <ActionLink id={`manage-publication-${publication.id}`} className="IconLink--settings" onClick={openAddOrEditPublicationModal}>
                Manage
            </ActionLink>
            {addOrEditPublicationModal}
        </>
    )
}
