import { Component } from "react";
import { farmYear } from "common/domain";
import * as normalizers from "common/normalizers";
import * as utils from "common/utils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import * as animalUtils from "../_utils";
import Field from "./Field";
import Input from "./Input";
import Alert from "components/Alert";
import { v4 as uuidv4 } from "uuid";
import * as domain from "common/domain";
import { useRefData, useShowQuickTips } from "common/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function BlockDistribution({ fields, blocks, enterpriseTypes, distributions, relProdMethod, blockStockRatios, percentageGrazedError, enterpriseDistributionError, onEnterpriseChanged, onSpecifyGrazingMonthsChanged, onBlockCheckBoxesChanged, onMonthChanged, onRelativeProductivityChanged, onPercenBlockPastureProdChanged, isMerino }) {
    const refData = useRefData();
    const showQuickTips = useShowQuickTips();

    return <BlockDistributionClassComponent
        fields={fields}
        blocks={blocks}
        enterpriseTypes={enterpriseTypes}
        distributions={distributions}
        relProdMethod={relProdMethod}
        blockStockRatios={blockStockRatios}
        percentageGrazedError={percentageGrazedError}
        enterpriseDistributionError={enterpriseDistributionError}
        onEnterpriseChanged={onEnterpriseChanged}
        onSpecifyGrazingMonthsChanged={onSpecifyGrazingMonthsChanged}
        onBlockCheckBoxesChanged={onBlockCheckBoxesChanged}
        onMonthChanged={onMonthChanged}
        onRelativeProductivityChanged={onRelativeProductivityChanged}
        onPercenBlockPastureProdChanged={onPercenBlockPastureProdChanged}
        isMerino={isMerino}
        refData={refData}
        showQuickTips={showQuickTips} />
}

class BlockDistributionClassComponent extends Component {
    render() {
        const { fields, blocks, enterpriseTypes, distributions = [], relProdMethod, blockStockRatios, percentageGrazedError, enterpriseDistributionError, onEnterpriseChanged, onSpecifyGrazingMonthsChanged, onBlockCheckBoxesChanged, onMonthChanged, onRelativeProductivityChanged, onPercenBlockPastureProdChanged, isMerino, showQuickTips, refData } = this.props;

        const showRelativeProductivity = animalUtils.showRelativeProductivity(relProdMethod);
        const showGrazingPercentage = enterpriseTypes.length > 1 && distributions.length > 1 && animalUtils.showPercenBlockPastureProd(blockStockRatios);
        const enterpriseSelectionDisabled = showGrazingPercentage !== true && blockStockRatios !== "BasedOnAnimalsOnBlock";
        const rows = [];

        distributions.forEach((distribution, index) => {
            const blockId = distribution.blockId;
            const block = blocks.find((block) => block.id === blockId);
            const percenBlockPastureProdInvalid = percentageGrazedError && percentageGrazedError.blockErrors.find((blockError) => blockError.blockId === blockId);
            const colour = utils.getColour(index);

            rows.push(
                enterpriseTypes.map((enterpriseType, index) => {
                    const animal = distribution ? distribution.animals.find((animal) => animal.enterpriseType === enterpriseType) : undefined;
                    const months = animal ? animal.months : [];
                    const enterpriseSelected = animal !== undefined;
                    const specifyGrazingMonths = animal ? animal.specifyGrazingMonths : false;
                    const enterpriseName = utils.valueToText(refData.enterpriseTypes, enterpriseType);
                    const showAccess = [domain.EnterpriseTypes.Dairy, domain.EnterpriseTypes.DairyReplacements, domain.EnterpriseTypes.Beef].includes(enterpriseType);
                    const showFinishing = [domain.EnterpriseTypes.Deer, domain.EnterpriseTypes.Beef].includes(enterpriseType);
                    const showWallow = [domain.EnterpriseTypes.Deer].includes(enterpriseType);
                    const showPace = [domain.EnterpriseTypes.Deer].includes(enterpriseType);
                    const showMerino = [domain.EnterpriseTypes.Sheep].includes(enterpriseType) && isMerino;

                    return (
                        <tr key={enterpriseType} className={`Table-tr--${colour}`}>
                            {index === 0 && (
                                <td className="td--alignTop">
                                    <h5 className="u-mt-0 u-textNoWrap">{block.name}</h5>
                                </td>
                            )}
                            {index > 0 && <td>&nbsp;</td>}
                            {showRelativeProductivity === true && index === 0 && <td className="td--alignTop">{block.type !== "ProductiveFruit" && <Field name={`${distribution.blockId}.relativeProductivity`} fields={fields} noLabel={true} onChange={_relativeProductivityChanged(onRelativeProductivityChanged, blockId)} unit="unit" normalize={normalizers.numeric} component={Input} />}</td>}
                            {showRelativeProductivity === true && index > 0 && <td>&nbsp;</td>}
                            <td>
                                <label className={`Checkbox ${enterpriseSelectionDisabled ? "is-disabled" : ""}`}>
                                    <input type="checkbox" name={`${distribution.blockId}.${enterpriseType}`} id={`${distribution.blockId}.${enterpriseType}`} className="Checkbox-input" checked={enterpriseSelected} disabled={enterpriseSelectionDisabled} onChange={_enterpriseChanged(onEnterpriseChanged, blockId, enterpriseType)} />
                                    <span className="Checkbox-label">{enterpriseName}</span>
                                </label>
                            </td>
                            <td className="u-textCenter">
                                {enterpriseSelected && (
                                    <label className="Checkbox">
                                        <input type="checkbox" name={`${distribution.blockId}.${enterpriseType}.specifyGrazingMonths`} className="Checkbox-input" checked={animal && animal.specifyGrazingMonths} onChange={_specifyGrazingMonthsChanged(onSpecifyGrazingMonthsChanged, blockId, enterpriseType)} />
                                        <span className="Checkbox-label"></span>
                                    </label>
                                )}
                            </td>
                            <td>
                                {enterpriseSelected && specifyGrazingMonths && (
                                    <div className="AnnualCheckboxes">
                                        {farmYear.map((calendarMonth, i) => {
                                            const monthSelected = months.some((month) => month === calendarMonth);
                                            const monthDisabled = specifyGrazingMonths === false;
                                            const id = `${distribution.blockId}.${enterpriseType}.${calendarMonth}`;
                                            return (
                                                <label key={i} className={`Checkbox ${monthDisabled ? "is-disabled" : ""}`}>
                                                    <input name={id} id={id} type="checkbox" checked={monthSelected} onChange={_monthChanged(onMonthChanged, blockId, enterpriseType, calendarMonth)} disabled={monthDisabled} className="Checkbox-input" />
                                                    <span className="Checkbox-label">{calendarMonth.substring(0, 3)}</span>
                                                </label>
                                            );
                                        })}
                                    </div>
                                )}
                                {enterpriseSelected && !specifyGrazingMonths && <span>Using default grazing months</span>}
                            </td>
                            {showGrazingPercentage === true && <td>{enterpriseSelected && <Field name={`${distribution.blockId}.${enterpriseType}.percenBlockPastureProd`} fields={fields} noLabel={true} invalid={percenBlockPastureProdInvalid} onChange={_percenBlockPastureProdChanged(onPercenBlockPastureProdChanged, blockId, enterpriseType)} unit="%" normalize={normalizers.integer} component={Input} />}</td>}
                            <td>
                                {enterpriseSelected && showAccess && (
                                    <label className={`Checkbox ${!enterpriseSelected ? "is-disabled" : ""}`}>
                                        <input type="checkbox" name={`${distribution.blockId}.${enterpriseType}.accesstostreams`} id={`${distribution.blockId}.${enterpriseType}.accesstostreams`} className="Checkbox-input" checked={animal && animal.hasStreamAccess ? true : false} disabled={!enterpriseSelected} onChange={_blockCheckBoxesChanged(onBlockCheckBoxesChanged, blockId, enterpriseType, "accesstostreams")} />
                                        <span className="Checkbox-label">Access to streams</span>
                                    </label>
                                )}
                                {enterpriseSelected && showWallow && (
                                    <label className={`Checkbox ${!enterpriseSelected ? "is-disabled" : ""}`}>
                                        <input type="checkbox" name={`${distribution.blockId}.${enterpriseType}.wallows`} id={`${distribution.blockId}.${enterpriseType}.wallows`} className="Checkbox-input" checked={animal && animal.isWallows ? true : false} disabled={!enterpriseSelected} onChange={_blockCheckBoxesChanged(onBlockCheckBoxesChanged, blockId, enterpriseType, "wallows")} />
                                        <span className="Checkbox-label">Wallows visible</span>
                                    </label>
                                )}
                                {enterpriseSelected && showPace && (
                                    <label className={`Checkbox ${!enterpriseSelected ? "is-disabled" : ""}`}>
                                        <input type="checkbox" name={`${distribution.blockId}.${enterpriseType}.pace`} id={`${distribution.blockId}.${enterpriseType}.pace`} className="Checkbox-input" checked={animal && animal.isPaceFenceline ? true : false} disabled={!enterpriseSelected} onChange={_blockCheckBoxesChanged(onBlockCheckBoxesChanged, blockId, enterpriseType, "pace")} />
                                        <span className="Checkbox-label">Pace fence line</span>
                                    </label>
                                )}
                            </td>
                            <td>
                                {enterpriseSelected && showFinishing && (
                                    <label className={`Checkbox ${!enterpriseSelected ? "is-disabled" : ""}`}>
                                        <input type="checkbox" name={`${distribution.blockId}.${enterpriseType}.finishing`} id={`${distribution.blockId}.${enterpriseType}.finishing`} className="Checkbox-input" checked={animal && animal.isFinishing ? true : false} disabled={!enterpriseSelected} onChange={_blockCheckBoxesChanged(onBlockCheckBoxesChanged, blockId, enterpriseType, "finishing")} />
                                        <span className="Checkbox-label">Finishing</span>
                                    </label>
                                )}
                                {enterpriseSelected && showMerino && (
                                    <label className={`Checkbox ${!enterpriseSelected ? "is-disabled" : ""}`}>
                                        <input type="checkbox" name={`${distribution.blockId}.${enterpriseType}.merino`} id={`${distribution.blockId}.${enterpriseType}.merino`} className="Checkbox-input" checked={animal && animal.isMerino ? true : false} disabled={!enterpriseSelected} onChange={_blockCheckBoxesChanged(onBlockCheckBoxesChanged, blockId, enterpriseType, "merino")} />
                                        <span className="Checkbox-label">Merino</span>
                                    </label>
                                )}
                            </td>
                        </tr>
                    );
                })
            );
            if (showGrazingPercentage) {
                const colSpan = showRelativeProductivity ? 5 : 4;
                const total = percenBlockPastureProdInvalid ? percenBlockPastureProdInvalid.total : 100;
                const message = isNaN(total) ? "Must be between 1 and 100" : `TOTAL: ${total}%`;

                rows.push(
                    <tr key={uuidv4()}>
                        <td colSpan={colSpan}></td>
                        <td className={`td--total ${percenBlockPastureProdInvalid ? "has-error" : ""}`}>{message}</td>
                        <td colSpan="2"></td>
                    </tr>
                );
            }
        });

        return (
            <Tile title="Distribution details" padTop collapsible tertiary>
                <Alert type="info" text="Select the location of your animals by month. Allocation of animals to months/blocks should be extremely coarse and allocated as an approximation of where animals are and their feed requirements." />
                {showQuickTips && <Alert type="help" text="Care is needed to ensure that relative pasture production is commensurate with the month of grazing. The relative productivity, sets the relative pasture production and hence total animal pasture intake from the block. The following determines the months that the pasture production is eaten, and hence the months that urine is deposited on the paddocks. Thus, if relative pasture productivity was the same for all blocks and only one month is selected, then the entire year's urine will be deposited on the pasture in that month, with the amount equivalent to the amount added to other blocks, but on other blocks the amount would be spread over 12 months." />}
                <TileBody>
                    <div className="Table u-mt-md">
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="md-25">Block</th>
                                    {showRelativeProductivity === true && <th>Relative productivity</th>}
                                    <th data-width="md-5">Enterprises</th>
                                    <th className="th--shrink">Specify grazing months?</th>
                                    <th data-width="md-25">Grazing months</th>
                                    {showGrazingPercentage === true && <th>Pasture grazed (%)</th>}
                                    <th data-width="md-10">Water connectivity</th>
                                    <th data-width="md-10">Production</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </div>
                    {percentageGrazedError && <div className="Field-error u-block">{percentageGrazedError.error}</div>}
                    {enterpriseDistributionError && <div className="Field-error u-block">{enterpriseDistributionError}</div>}
                </TileBody>
            </Tile>
        );
    }
}

const _blockCheckBoxesChanged = (onBlockCheckBoxesChanged, blockId, enterpriseType, type) => (e) => {
    if (onBlockCheckBoxesChanged) onBlockCheckBoxesChanged(blockId, enterpriseType, type, e.target.checked);
}

const _enterpriseChanged = (onEnterpriseChanged, blockId, enterpriseType) => (e) => {
    if (onEnterpriseChanged) onEnterpriseChanged(blockId, enterpriseType, e.target.checked);
}

const _specifyGrazingMonthsChanged = (onSpecifyGrazingMonthsChanged, blockId, enterpriseType) => (e) => {
    if (onSpecifyGrazingMonthsChanged) onSpecifyGrazingMonthsChanged(blockId, enterpriseType, e.target.checked);
}

const _relativeProductivityChanged = (onRelativeProductivityChanged, blockId) => (e) => {
    if (onRelativeProductivityChanged) onRelativeProductivityChanged(blockId, e.target.value);
}

const _percenBlockPastureProdChanged = (onPercenBlockPastureProdChanged, blockId, enterpriseType) => (e) => {
    if (onPercenBlockPastureProdChanged) onPercenBlockPastureProdChanged(blockId, enterpriseType, e.target.value);
}

const _monthChanged = (onMonthChanged, blockId, enterpriseType, calendarMonth) => (e) => {
    if (onMonthChanged) onMonthChanged(blockId, enterpriseType, calendarMonth, e.target.checked);
}
