import { Component } from "react";
import NumericInputPack from "components/NumericInputPack";
import SelectPack from "components/SelectPack";
import * as utils from "common/utils";
import CheckboxPack from "components/CheckboxPack";
import * as cropUtils from "../_utils";
import InputPack from "components/InputPack";
import * as domain from "common/domain";
import * as validations from "common/validations";
import { v4 as uuidv4 } from "uuid";
import AnimalGrazing from "../AnimalGrazing";
import RotationYear from "../RotationYear";
import Alert from "components/Alert";
import { rotationIndex } from "../_utils";
import ActionLink from "components/ActionLink";
import { useAuthContext, useRefData, useShowQuickTips, useToggleQuickTips, useGetCustomNutrientCompositions, useUserDefinedCrops } from "common/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function Cropsown({ analysis, block, isRotation, crop, croppingEvents, setCrop, categoryFilter, save, cancel, isEdit, isAutoRotationEvent }) {
    const authContext = useAuthContext();
    const refData = useRefData();
    const showQuickTips = useShowQuickTips();
    const toggleQuickTips = useToggleQuickTips();

    const getCustomCompositions = useGetCustomNutrientCompositions();
    const userDefinedCrops = useUserDefinedCrops();

    return (
        <CropsownClassComponent analysis={analysis} block={block} isRotation={isRotation} crop={crop} croppingEvents={croppingEvents} setCrop={setCrop} categoryFilter={categoryFilter} save={save} cancel={cancel} isEdit={isEdit} isAutoRotationEvent={isAutoRotationEvent} getCustomCompositions={getCustomCompositions} userDefinedCrops={userDefinedCrops} authContext={authContext} refData={refData} showQuickTips={showQuickTips} toggleQuickTips={toggleQuickTips} />
    )
}

class CropsownClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    _closeOnEscape = (e) => {
        if (e.keyCode === 27) {
            this.props.cancel();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this._closeOnEscape);
        this.props.getCustomCompositions();
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._closeOnEscape);
    }

    save() {
        const { crop } = this.props;
        const currentValidation = this.state.validation;
        const activeControls = this.getActiveControls(crop);
        if (this.isValid(crop, activeControls, currentValidation)) {
            this.props.save();
        }
    }

    removeFinishEvent(crop, croppingEvents, prevIndex) {
        const rotationYear = cropUtils.finishRotationYearInfo(this.props.block);
        const prevRotationYearInfo = rotationYear.find((r) => r.index === prevIndex - 1);
        //Find the old finish event
        const prevFinishEvent =
            (crop.events &&
                crop.events.find(
                    (e) =>
                        ["Endcrop", "Cultivation", "Cropharvest"].includes(e.type) ||
                        //Defoliation could be the last day of the rotation
                        (e.type === "Defoliation" && (e.finalHarvest || (e.month === rotationYear[11].month && e.reportingYear)))
                )) ||
            //Cultivation in a different list
            (prevRotationYearInfo && croppingEvents.find((e) => e.type === "Cultivation" && e.month === prevRotationYearInfo.month && e.reportingYear === prevRotationYearInfo.reportingYear));

        if (prevFinishEvent) {
            if (croppingEvents.find((e) => e === prevFinishEvent)) {
                croppingEvents.splice(croppingEvents.indexOf(prevFinishEvent), 1);
            } else if (crop.events.find((e) => e === prevFinishEvent)) {
                crop.events.splice(crop.events.indexOf(prevFinishEvent), 1);
            }
        }
        return prevFinishEvent;
    }

    updateCropFinishEvent(crop, croppingEvents = [], prevIndex = crop.thermalTimeEndIndex) {
        const isPasture = ["Pasture", "AnnualRyegrass"].includes(crop.cropId) || crop.category === "Seed";
        const rotationYear = cropUtils.finishRotationYearInfo(this.props.block);
        if (crop.thermalTimeEndReason === "Ongoing") {
            crop.thermalTimeEndIndex = rotationYear.length;
        } else if (isPasture && crop.thermalTimeEndIndex > 24) {
            crop.thermalTimeEndIndex = 24;
        }
        if (!crop.thermalTimeEndReason || !crop.thermalTimeEndIndex) return;
        const rotationYearInfo = rotationYear.find((r) => r.index === crop.thermalTimeEndIndex - 1);
        const prevFinishEvent = this.removeFinishEvent(crop, croppingEvents, prevIndex);
        const eventIndex = (e) => {
            const rotationMonth = rotationYear.find((r) => r.month === e.month && r.reportingYear === e.reportingYear && !r.postReportingYear);
            return rotationMonth ? rotationMonth.index + 1 : -1;
        };

        crop.events = crop.events.filter((e) => crop.thermalTimeEndIndex > eventIndex(e, rotationYear));
        for (const defolEvt of crop.events.filter((e) => e.type === "Defoliation")) {
            const defolRotationIndex = eventIndex(defolEvt, rotationYear);
            const numberMonthsDefoliating = defolEvt.numberMonthsDefoliating ? defolEvt.numberMonthsDefoliating - 1 : 0;
            if (defolRotationIndex + numberMonthsDefoliating >= crop.thermalTimeEndIndex) {
                defolEvt.numberMonthsDefoliating = crop.thermalTimeEndIndex - defolRotationIndex;
            }
        }

        //Don't add a finish event post reporting year (unless it's a final defol)
        if (rotationYearInfo.postReportingYear) {
            return;
        }
        const isDefolToDefol = prevFinishEvent && prevFinishEvent.type === "Defoliation" && crop.thermalTimeEndReason === "FinalDefoliation";

        const newFinishEvent = isDefolToDefol ? { ...prevFinishEvent } : {};
        delete newFinishEvent.additionalDefoliations;
        newFinishEvent.month = rotationYearInfo.month;
        newFinishEvent.reportingYear = rotationYearInfo.reportingYear;
        switch (crop.thermalTimeEndReason) {
            case "EndCrop":
                newFinishEvent.type = "Endcrop";
                crop.events.push(newFinishEvent);
                break;
            case "Cultivate":
                newFinishEvent.type = "Cultivation";
                croppingEvents.push(newFinishEvent);
                break;
            case "FinalDefoliation":
                newFinishEvent.type = "Defoliation";
                newFinishEvent.finalHarvest = true;
                newFinishEvent.incomplete = newFinishEvent.incomplete || !isDefolToDefol;
                crop.events.push(newFinishEvent);
                break;
            default:
                newFinishEvent.type = "Cropharvest";
                crop.events.push(newFinishEvent);
                break;
        }

        const finishEvents = this.getCropFinishReasons();
        if (!finishEvents.find((e) => e.value === crop.thermalTimeEndReason)) {
            delete crop.thermalTimeEndReason;
        }
    }

    isValid(crop, activeControls, currentValidation, source = undefined) {
        let validation = {};
        const { animalGrazing = {} } = crop;

        const required = (parent, keys) => {
            /* eslint-disable no-unused-vars */
            for (const key of keys.filter((k) => activeControls[k])) {
                const objKey = key.split("_").pop();
                validation[key] = utils.setVal(key, currentValidation, source, validations.requiredExt(parent[objKey]));
            }
        };

        const sown = cropUtils.findCropEvent(crop, "Cropsown");
        required(crop, ["category", "cropId", "productYield", "cultivationPractice", "residualDisposalMethod", "defoliationManagement", "fieldLossPercentage", "dressingLossPercentage", "plantingStage", "harvestStage", "productYield", "moisturePercentage", "thermalTimeEndIndex", "thermalTimeEndReason"]);
        required(sown, ["sown_month"]);
        required(animalGrazing, ["animalGrazing_animalSource", "animalGrazing_animalType"]);

        let message = (validation.monthsAfterCropRemoved && validation.monthsAfterCropRemoved.message) || validations.range(0, 24)(crop.monthsAfterCropRemoved);
        validation.monthsAfterCropRemoved = utils.setVal("monthsAfterCropRemoved", currentValidation, source, message);

        message = (validation.productYield && validation.productYield.message) || validations.range(0, 1000)(crop.productYield);
        validation.productYield = utils.setVal("productYield", currentValidation, source, message);

        message = (validation.fieldLossPercentage && validation.fieldLossPercentage.message) || validations.range(0, 100)(crop.fieldLossPercentage);
        validation.fieldLossPercentage = utils.setVal("fieldLossPercentage", currentValidation, source, message);

        message = (validation.dressingLossPercentage && validation.dressingLossPercentage.message) || validations.range(0, 100)(crop.dressingLossPercentage);
        validation.dressingLossPercentage = utils.setVal("dressingLossPercentage", currentValidation, source, message);

        message = (validation.moisturePercentage && validation.moisturePercentage.message) || validations.range(0, 100)(crop.moisturePercentage);
        validation.moisturePercentage = utils.setVal("moisturePercentage", currentValidation, source, message);

        message = (validation.name && validation.name.message) || validations.maxLength(50)(crop.name);
        validation.name = utils.setVal("name", currentValidation, source, message);

        const { soilTest = {} } = crop;
        const { nutrients = {} } = soilTest;

        if (crop.soilTest && (nutrients.P !== 0 || nutrients.K !== 0 || nutrients.Ca !== 0 || nutrients.Mg !== 0 || nutrients.Na !== 0)) {
            const { nutrients = {} } = crop.soilTest;
            if (!source) {
                const invalidValues = [undefined, "0", 0];
                let allValid = 1;
                /* eslint-disable no-unused-vars */
                for (const key in nutrients) {
                    allValid &= invalidValues.includes(nutrients[key]);
                }
                message = allValid === 1 ? "Invalid" : undefined;
                validation.soilTest = utils.setVal("soilTest", currentValidation, source, message);
            }

            message = nutrients.P ? validations.range(0, 10000000)(nutrients.P) : undefined;
            validation.nutrients_p = utils.setVal("P", currentValidation, source, message);

            message = nutrients.K ? validations.range(0, 10000000)(nutrients.K) : undefined;
            validation.nutrients_k = utils.setVal("K", currentValidation, source, message);

            message = nutrients.Ca ? validations.range(0, 10000000)(nutrients.Ca) : undefined;
            validation.nutrients_ca = utils.setVal("Ca", currentValidation, source, message);

            message = nutrients.Mg ? validations.range(0, 10000000)(nutrients.Mg) : undefined;
            validation.nutrients_mg = utils.setVal("Mg", currentValidation, source, message);

            message = nutrients.Na ? validations.range(0, 10000000)(nutrients.Na) : undefined;
            validation.nutrients_na = utils.setVal("Na", currentValidation, source, message);
        }

        if (activeControls.animalGrazing_consumption && !source) {
            const { percentageGrazed = [] } = animalGrazing;
            let message = percentageGrazed.length === 0 ? "Please select at least one enterprise" : undefined;
            message = message || (percentageGrazed.length > 0 && percentageGrazed.reduce((a, b) => (a += b.percentage), 0) !== 100 ? "Percentages need to sum to 100" : undefined);
            validation.animalConsumption = utils.setVal("animalConsumption", currentValidation, source, message);
        }

        if (activeControls.animalGrazing_hoursCropGrazed) {
            message = validations.range(0, 20)(animalGrazing.hoursCropGrazed);
            validation.animalGrazing_hoursCropGrazed = utils.setVal("hoursCropGrazed", currentValidation, source, message);
        }

        this.setState({ validation: validation });
        /* eslint-disable no-unused-vars */
        for (let key of Object.keys(validation)) {
            if (validation[key].error) {
                return false;
            }
        }
        return true;
    }

    switchSoilTests(e) {
        const { validation = {} } = this.state;
        const { crop, croppingEvents } = this.props;
        if (!crop) return;
        const { soilTest } = crop;
        const setBlankCropTest = () => {
            crop.soilTest = {
                id: uuidv4(),
                name: "crop soil tests",
                nutrients: {},
                sType: "Total",
                defaultASC: 0,
                defaultTBKReserveK: 0,
            };
        };
        if (soilTest) {
            delete crop.soilTest;
            delete validation.soilTest;
            if (utils.ctrlVal(e)) {
                //There was a soil test of all 0's. Without this the
                //user would need to click twice (only happens with imported files)
                setBlankCropTest();
            }
        } else {
            setBlankCropTest();
        }
        this.props.setCrop(crop, croppingEvents);
        this.setState({ validation: validation });
    }

    getActiveControls(crop) {
        const { cropCategories = [] } = this.props.refData;
        const { block } = this.props;
        const selectedCategory = cropCategories.find((c) => c.value === crop.category);
        const cropTypes = selectedCategory ? selectedCategory.children : [];
        const coefficient = cropTypes.find((t) => t.value === crop.cropId) || undefined;
        const isGreenManure = crop.category === "GreenManure";
        const isPasture = coefficient && coefficient.isPastoralModelCrop;
        const sown = cropUtils.findCropEvent(crop, "Cropsown");
        const rotationYear = cropUtils.rotationYear(block);
        const sownIndex = rotationIndex(sown, rotationYear);
        const isFodderCrop = rotationYear.length === 12;
        const isFodderEndMonth = sownIndex === 11 && isFodderCrop;

        const activeControls = {
            category: true,
            cropId: true,
            productYield: crop.cropId,
            moisturePercentage: false,
            sown_month: this.props.isEdit,
            name: true,
            thermalTimeEndReason: crop && crop.cropId && !isFodderEndMonth,
            thermalTimeEndIndex: crop && crop.thermalTimeEndReason !== "Ongoing",
            cultivationPractice: true,
            fieldLossPercentage: crop && crop.cropId && !isGreenManure && !isPasture,
        };
        if (crop) {
            activeControls.sown_reportingYear = !this.props.isRotation;
            const setAnimalGrazing = () => {
                const { animalGrazing = {} } = crop;
                const showConsumptionRange = animalGrazing.animalSource === "Integratedwithpastoralblocks" && animalGrazing.specifyAnimalConsumption;
                activeControls.animalGrazing_animalSource = true;
                activeControls.animalGrazing_animalType = animalGrazing.animalSource === "Standalonegeneralisedanimaltype";
                activeControls.animalGrazing_consumption = showConsumptionRange;
                activeControls.animalGrazing_hoursCropGrazed = showConsumptionRange;
            };

            if (coefficient) {
                activeControls.productYield = coefficient.requiresYield && crop.cropId;
                activeControls.residualDisposalMethod = coefficient.requiresResidualManagement;
                activeControls.monthsAfterCropRemoved = coefficient.requiresMonthsAfterInitialHarvest;
                activeControls.monthSeedHarvested = coefficient.requiresMonthSeedHarvested;
                activeControls.defoliationManagement = coefficient.requiresDefoliationManagement;
                activeControls.plantingStage = coefficient.typicalPlantingStage !== "Undefined";
            }

            activeControls.chineseCut = ["Broccoliwinterspring", "Broccolisummer"].includes(crop.cropId);
            displayAnimalGrazing(crop) && setAnimalGrazing();
            if (this.props.isAutoRotationEvent) {
                activeControls.defoliationManagement = false;
                activeControls.cultivationPractice = false;
            }
        }
        return activeControls;
    }

    clearOrphanEvents(crop) {
        const { block = {} } = this.props;
        const sown = cropUtils.findCropEvent(crop, "Cropsown");
        const rotationYear = cropUtils.rotationYear(block);
        const sownMonthIndx = cropUtils.rotationIndex(sown, rotationYear);
        const { events = [] } = crop;

        crop.events = events.filter((e) => e.type === "Cropsown" || (sownMonthIndx < cropUtils.rotationIndex(e, rotationYear) && crop.thermalTimeEndIndex > cropUtils.rotationIndex(e, rotationYear)));
        if (cropUtils.rotationIndex(crop.monthSeedHarvested, rotationYear) <= sownMonthIndx) {
            delete crop.monthSeedHarvested;
        }
    }

    onChange(e, source) {
        const { crop: cp = {}, userDefinedCrops = [], croppingEvents: ce = [] } = this.props;
        let crop = { ...cp };
        let croppingEvents = [...ce];
        const currentValidation = this.state.validation;
        const { soilTest = {} } = crop;
        const sown = cropUtils.findCropEvent(crop, "Cropsown", true);

        switch (source.type) {
            case "animalGrazing":
                crop.animalGrazing = source.animalGrazing;
                break;
            case "nutrients": {
                const { nutrients = {} } = soilTest;
                nutrients[source.key] = utils.ctrlVal(e);
                soilTest.nutrients = nutrients;
                crop.soilTest = soilTest;
                break;
            }
            case "sown":
                sown[source.key] = utils.ctrlVal(e);
                break;
            case "sown_reportingYear":
                sown.month = e.month;
                sown.reportingYear = e.rotationYear === "ReportingYear";
                this.clearOrphanEvents(crop);
                break;
            case "crop_monthSeedHarvested":
                crop.monthSeedHarvested = {
                    month: e.month,
                    reportingYear: e.reportingYear,
                };
                break;
            case "crop_thermalTimeEndIndex": {
                if (crop.thermalTimeEndIndex) {
                    this.removeFinishEvent(crop, croppingEvents, crop.thermalTimeEndIndex);
                }
                crop.thermalTimeEndIndex = e.rotationMonth + 1;
                this.clearOrphanEvents(crop);
                if (crop.thermalTimeEndReason) {
                    const cropThermalTimeEndReasons = this.getCropFinishReasons();
                    if (!cropThermalTimeEndReasons.find((r) => r.value === crop.thermalTimeEndReason)) {
                        delete crop.thermalTimeEndReason;
                    } else {
                        this.updateCropFinishEvent(crop, croppingEvents);
                    }
                }
                break;
            }
            case "crop_thermalTimeEndReason": {
                crop.thermalTimeEndReason = utils.ctrlVal(e);
                this.updateCropFinishEvent(crop, croppingEvents);
                break;
            }
            case "userDefinedCropId": {
                const udcId = utils.ctrlVal(e);
                if (udcId === "undefined") crop.userDefinedCropId = undefined;
                else {
                    const udc = userDefinedCrops.find((c) => c.id === udcId);
                    if (udc) {
                        crop.category = udc.category;
                        crop.cropId = udc.baseCropType;
                        crop.name = udc.name;
                        crop.userDefinedCropId = udc.id;
                    }
                }
                break;
            }
            default:
                crop[source.key] = utils.ctrlVal(e);
                if (source.key === "category") {
                    if (crop.thermalTimeEndIndex) {
                        this.removeFinishEvent(crop, croppingEvents, crop.thermalTimeEndIndex);
                    }
                    crop.events = crop.events.filter((e) => e.type === "Cropsown");
                    delete crop.cropId;
                    delete crop.thermalTimeEndReason;
                    delete crop.thermalTimeEndIndex;
                    if (currentValidation) {
                        delete currentValidation.cropId;
                    }
                } else if (source.key === "cropId") {
                    if (crop.thermalTimeEndIndex) {
                        this.removeFinishEvent(crop, croppingEvents, crop.thermalTimeEndIndex);
                    }
                    delete crop.thermalTimeEndReason;
                    delete crop.thermalTimeEndIndex;

                    const { cropCategories = [] } = this.props.refData;
                    const selectedCategory = cropCategories.find((c) => c.value === crop.category);
                    const cropTypes = selectedCategory ? selectedCategory.children : [];
                    const coefficient = cropTypes.find((t) => t.value === crop.cropId) || undefined;
                    if (coefficient) {
                        crop.fieldLossPercentage = coefficient.typicalFieldLossPercentage;
                        crop.dressingLossPercentage = coefficient.typicalDressingLossPercentage;
                        crop.moisturePercentage = coefficient.typicalMoisturePercentage;
                        crop.productYield = coefficient.typicalYield;
                        crop.plantingStage = coefficient.typicalPlantingStage;
                        crop.harvestStage = coefficient.typicalHarvestStage;
                    }
                }
                break;
        }
        const activeControls = this.getActiveControls(crop);
        const cropType = cropUtils.findCropType(crop, this.props.refData);
        const refDataAllowDefoliation = !cropType || cropType.allowedDefoliation;
        const refDataAllowHarvest = !cropType || cropType.allowedHarvest;
        if (!refDataAllowDefoliation) {
            crop.events = crop.events.filter((e) => e.type !== "Defoliation");
        }
        if (!refDataAllowHarvest) {
            crop.events = crop.events.filter((e) => !["Cropharvest"].includes(e.type));
        }

        crop.events = crop.events.filter((e) => domain.calendarYear.includes(e.month));
        this.setDefaults(crop, activeControls);
        this.isValid(crop, activeControls, currentValidation, source);
        this.props.setCrop(crop, croppingEvents);
    }

    setDefaults(crop, activeControls) {
        const { sown, cropEnd } = crop;
        const clearValues = (obj, keys) => {
            if (!obj) return;
            /* eslint-disable no-unused-vars */
            for (const key of keys.filter((k) => !activeControls[k])) {
                const objKey = key.split("_").pop();
                obj[objKey] = undefined;
            }
        };

        clearValues(crop, ["category", "cropId", "productYield", "cultivationPractice", "residualDisposalMethod", "monthsAfterCropRemoved", "chineseCut", "monthSeedHarvested", "defoliationManagement"]);
        clearValues(sown, ["sown_month"]);
        clearValues(cropEnd, ["cropEnd_month"]);

        if (crop.animalGrazing && !crop.animalGrazing.specifyAnimalConsumption) {
            delete crop.animalGrazing.percentageGrazed;
        }

        if (!displayAnimalGrazing(crop)) {
            delete crop.animalGrazing;
        }
    }

    getNextCropSownIndx() {
        const { crop, block } = this.props;
        const rotationYear = cropUtils.rotationYear(block);
        const sown = cropUtils.findCropEvent(crop, "Cropsown");
        const { crops = [] } = block;
        const sownIndex = rotationIndex(sown, rotationYear);
        let nextCropSownIndx = false;

        for (const c of crops.filter((c) => c.id !== crop.id)) {
            const cropSown = cropUtils.findCropEvent(c, "Cropsown");
            const cropSownIndx = cropSown && rotationIndex(cropSown, rotationYear);
            if (cropSown && sownIndex < cropSownIndx) {
                nextCropSownIndx = cropSownIndx;
                break;
            }
        }

        return nextCropSownIndx;
    }

    getCropFinishReasons() {
        const { refData, crop, croppingEvents = [], block } = this.props;
        const { cropCategories = [], thermalTimeEndReason = [] } = refData;
        const selectedCategory = cropCategories.find((c) => c.value === crop.category);
        const cropTypes = selectedCategory ? selectedCategory.children : [];
        const selectedCropType = cropTypes.find((t) => t.value === crop.cropId) || undefined;
        const nextCropSownIndx = this.getNextCropSownIndx();
        const nextCropSownOnFinish = nextCropSownIndx === crop.thermalTimeEndIndex - 1;

        const rotationYear = cropUtils.rotationYear(block);
        const sown = cropUtils.findCropEvent(crop, "Cropsown");
        const sownIndex = rotationIndex(sown, rotationYear);
        const futureCultivateEvts = croppingEvents.filter((e) => cropUtils.rotationIndex(e, rotationYear) > sownIndex);
        const hasNextCultivation = futureCultivateEvts.length > 1 || (futureCultivateEvts.length > 0 && rotationIndex(futureCultivateEvts[0], rotationYear) !== crop.thermalTimeEndIndex - 1);

        const selectedCropFinishReasons = (selectedCropType ? selectedCropType.thermalTimeEndReasons : []).filter((t) => !nextCropSownOnFinish || ["Harvest", "EndCrop"].includes(t));
        const cropThermalTimeEndReason = thermalTimeEndReason.filter((t) => selectedCropFinishReasons.includes(t.value) && ((!nextCropSownIndx && !hasNextCultivation) || t.value !== "Ongoing"));

        return cropThermalTimeEndReason;
    }

    getFinishTip(crop) {
        switch (crop.thermalTimeEndReason) {
            case "EndCrop":
                return (
                    <div>
                        <b>End crop:</b> The growth of the crop is stopped at the end of the month and non-harvested tops and roots become residues. No product is removed. This option represents management such as spraying or crop pulling.
                    </div>
                );
            case "Cultivate":
                return (
                    <div>
                        <b>Cultivation:</b> Any existing crop growth is stopped at the end of the month previous to the finish month.
                    </div>
                );
            case "Harvest":
                return (
                    <div>
                        <b>Harvest:</b> The growth of the crop is stopped at the end of the <i>Crop finishes in</i> month. This option is not applicable to fodder, forage, green manure or pasture crops.
                    </div>
                );
            case "FinalDefoliation":
                return (
                    <div>
                        <b>Final defoliation:</b> A defoliation event will be created on the crops final month and the defoliation details will be required to be entered.
                    </div>
                );
            default:
                return "For detailed help, please select a final event.";
        }
    }

    render() {
        const { cancel, refData, categoryFilter, crop, block, isAutoRotationEvent, userDefinedCrops, authContext, croppingEvents = [] } = this.props;
        const isPasture = ["Pasture", "AnnualRyegrass"].includes(crop.cropId) || crop.category === "Seed";
        const { validation = {} } = this.state;
        const { crops = [] } = block;
        const userDefinedCropOptions =
            refData.deploymentType === "Science" &&
            authContext.accountType === "Science" &&
            userDefinedCrops &&
            userDefinedCrops.map((c) => {
                return { value: c.id, text: c.name };
            });
        const userDefinedCrop = userDefinedCropOptions && crop.userDefinedCropId && userDefinedCrops.find((c) => c.id === crop.userDefinedCropId);

        let { cropCategories = [], tillages = [], cropResidues = [], defoliationManagement = [], cropStages = [] } = refData;
        cropCategories = cropCategories.filter((c) => c.value !== "Undefined" && (!categoryFilter || categoryFilter.includes(c.value)));

        const selectedCategory = cropCategories.find((c) => c.value === crop.category);
        const cropTypes = selectedCategory ? selectedCategory.children : [];
        const selectedCropType = cropTypes.find((t) => t.value === crop.cropId) || undefined;
        const finishRotationYear = cropUtils.finishRotationYear(block);

        const sown = cropUtils.findCropEvent(crop, "Cropsown");
        const finishDisabledMonths = [];

        const { soilTest = {} } = crop;
        const { nutrients = {} } = soilTest;
        const activeControls = this.getActiveControls(crop);
        const showGrazing = displayAnimalGrazing(crop);
        const { animalGrazing = {} } = crop;
        const rotationYear = cropUtils.rotationYear(block);
        const sownIndex = rotationIndex(sown, rotationYear);
        const defoliationMsg = selectedCropType && selectedCropType.defoliationMessage ? selectedCropType.defoliationMessage : selectedCategory ? selectedCategory.defoliationMessage : "";
        const isFodderCrop = rotationYear.length === 12;

        const nextCultivateIndx = croppingEvents.filter((e) => cropUtils.rotationIndex(e, rotationYear) >= (crop.thermalTimeEndIndex || sownIndex)).reduce((c, d) => Math.min(c, rotationIndex(d, rotationYear)), 36);
        const lastCroppingEvtIndx = croppingEvents.filter((e) => cropUtils.rotationIndex(e, rotationYear) < sownIndex).reduce((c, d) => Math.max(c, rotationIndex(d, rotationYear)), -1);
        const allThermalTimeEndIndx = crops.filter((c) => c.id !== crop.id).map((c) => c.thermalTimeEndIndex - 1);
        const prevThermalTimeEndIndx = allThermalTimeEndIndx.filter((i) => i <= sownIndex);
        const lastCropEndIndx = prevThermalTimeEndIndx.length > 0 ? Math.max(...prevThermalTimeEndIndx) : -1;
        const lastCrop = crops.find((c) => c.thermalTimeEndIndex === lastCropEndIndx + 1);
        const adjLastCropEndIndx = lastCrop && ["Harvest", "EndCrop"].includes(lastCrop.thermalTimeEndReason) ? lastCropEndIndx - 1 : lastCropEndIndx;
        const prevCropEventIndx = Math.max(lastCroppingEvtIndx, adjLastCropEndIndx);
        const sownDisabledMonths = [];
        const cropThermalTimeEndReason = this.getCropFinishReasons();
        const nextCropSownIndx = this.getNextCropSownIndx();
        const allowFinishOnNextSown = ["Harvest", "EndCrop"].includes(crop.thermalTimeEndReason);
        const adjNextCropSown = allowFinishOnNextSown ? nextCropSownIndx + 1 : nextCropSownIndx;
        const adjThermalTimeEndIndx = isFodderCrop ? crop.thermalTimeEndIndex - 12 : crop.thermalTimeEndIndex;

        for (let index = 0; index <= rotationYear.length; index++) {
            if (index <= sownIndex || (nextCropSownIndx && index >= adjNextCropSown) || (nextCultivateIndx < 36 && index >= nextCultivateIndx)) {
                finishDisabledMonths.push(index);
            }
            if (
                //  Any dates past the end of the crop
                index >= adjThermalTimeEndIndx - 1 ||
                //Can't go further back than the last event (previous to the crop)
                index <= prevCropEventIndx
            ) {
                sownDisabledMonths.push(index);
            }
        }

        //Disable all post reporting year
        if (nextCropSownIndx || nextCultivateIndx < 36) {
            for (let index = rotationYear.length; index < rotationYear.length + 12; index++) {
                finishDisabledMonths.push(index);
            }
        }

        const defoliationManagementValue = crop.defoliationManagement !== "Undefined" ? crop.defoliationManagement : undefined;
        const selectedDefoliationManagement = defoliationManagement.find((t) => t.value === crop.defoliationManagement);
        const fallowMsg = defoliationManagementValue === "Fallow" && activeControls.defoliationManagement ? selectedDefoliationManagement.defoliationMessage : undefined;
        const specifySoilTests = crop.soilTest && (nutrients.P !== 0 || nutrients.K !== 0 || nutrients.Ca !== 0 || nutrients.Mg !== 0 || nutrients.Na !== 0);
        const disableYear3 = isPasture && crop.thermalTimeEndReason !== "Ongoing";

        const yieldInfo =
            !userDefinedCrop && selectedCropType && selectedCropType.typicalYield > 0 ? (
                <div>
                    <b>Typical yield:</b> <span id="typical_yield">{`${selectedCropType.yieldType} for a mature crop is ${selectedCropType.typicalYield} ${selectedCropType.yieldUnits.replace("T/ha dry matter", "t DM/ha")}`}</span>
                </div>
            ) : (
                userDefinedCrop &&
                userDefinedCrop.typicalYield && (
                    <div>
                        <b>Typical yield:</b> <span id="typical_yield">{"Typical yield for a mature crop is " + userDefinedCrop.typicalYield + " t/ha"}</span>
                    </div>
                )
            );

        const fieldLossInfo = (
            <>
                <b>For horticulture/arable crops only:</b> represents the percentage of the crop left in the paddock due to disease, weather, pest damage or other quality issues.
            </>
        );
        const fieldLossHelp = (
            <>
                {fieldLossInfo} <b>For defoliated crops (grazed in-situ or cut and carry to feed to animals):</b> leave as 0% and adjust crop yield instead.
            </>
        );

        return (
            <div className="Modal_wrapper">
                <input type="hidden" value={crop.id} id="crop" />
                <div className="Modal Modal--skinny">
                    <div className="Modal-head">
                        <span className="Modal-head--left">{`Crop sown - ${sown.month} ${sown.reportingYear ? "Reporting year" : "Year 1"}`}</span>
                        <span className="Modal-head--right">
                            <ActionLink onClick={this.props.toggleQuickTips} className="Modal-close">
                                <i className="icon icon-question" title={`${this.props.showQuickTips ? "Hide" : "Show"} quick tips`} />
                            </ActionLink>
                            <ActionLink onClick={cancel} id="modal_close" className="Modal-close">
                                <i className="icon icon-cross" title="Close" />
                            </ActionLink>
                        </span>
                    </div>
                    <div className="Modal-subhead">
                        <Alert type="info" text="Select the type of crop, the month that it was sown, the month the crop finishes, and the reason the crop finishes. If you wish to provide an alternative name for this crop enter it below, otherwise, the crop type will be used." />
                    </div>
                    <div className="Modal-body">
                        <div className="Grid">
                            <div className="Grid-cell">
                                {userDefinedCropOptions && userDefinedCropOptions.length > 0 && (
                                    <div className="Field-group">
                                        <SelectPack id="user_defined_crop" label="User defined crop" dataWidth="50" meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "userDefinedCropId", key: "userDefinedCropId" })} value={crop.userDefinedCropId} val={validation.userDefinedCropId} requiredLabel={false}>
                                            <option value="undefined">Not user defined</option>
                                            {utils.mapRefDataItems(userDefinedCropOptions)}
                                        </SelectPack>
                                    </div>
                                )}

                                <div className="Field-group">
                                    <SelectPack id="crop_category" label="Category" dataWidth="50" meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "crop", key: "category" })} value={crop.category} disabled={isAutoRotationEvent || crop.userDefinedCropId} val={validation.category} requiredLabel={true}>
                                        <option value="" disabled={true}>
                                            Select a crop category
                                        </option>
                                        {utils.mapRefDataItems(cropCategories.filter((c) => c.children && c.children.length > 0))}
                                    </SelectPack>
                                    <SelectPack id="crop_type" label="Crop type" dataWidth="50" meta={{ nrf: true }} disabled={!selectedCategory || isAutoRotationEvent || crop.userDefinedCropId} onChange={(e) => this.onChange(e, { type: "crop", key: "cropId" })} value={crop.cropId} val={validation.cropId} requiredLabel={true}>
                                        <option value="" disabled={true}>
                                            Select a crop type
                                        </option>
                                        {utils.mapRefDataItems(cropTypes)}
                                    </SelectPack>
                                </div>

                                {/* These aren't  used */}
                                {activeControls.moisture && <NumericInputPack id="crop_moisture" isHidden={!activeControls.moisturePercentage} dataWidth="50" label="Moisture" disabled={isAutoRotationEvent} uom="%" requiredLabel={true} val={validation.moisturePercentage} onChange={(e) => this.onChange(e, { type: "crop", key: "moisturePercentage" })} decimalPlaces={0} value={crop.moisturePercentage} />}
                                {activeControls.dressingLossPercentage && <NumericInputPack id="crop_dressingLossPercentage" info="The typical percentage of potential yield that is lost and left in the field" dataWidth="50" label="Dressing loss" uom="%" requiredLabel={true} val={validation.dressingLossPercentage} onChange={(e) => this.onChange(e, { type: "crop", key: "dressingLossPercentage" })} decimalPlaces={0} value={crop.dressingLossPercentage} />}

                                {/* End un-used */}

                                <div className="Field-group u-flexWrap u-mt-sm">
                                    <InputPack id="crop_name" type="text" className="u-mt-lg" dataWidth="50" label="Alternate crop name" value={crop.name} onChange={(e) => this.onChange(e, { type: "crop", key: "name" })} meta={{ nrf: true }} disabled={isAutoRotationEvent} val={validation.name} requiredLabel={false} placeholder="Only use if planting alternate crops" />
                                    {activeControls.plantingStage && (
                                        <SelectPack dataWidth="50" className="u-mt-lg" id="crop_plantingStage" label="Planting method" meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "crop", key: "plantingStage" })} value={crop.plantingStage} requiredLabel={true}>
                                            <option value="" disabled={true}>
                                                Planting method
                                            </option>
                                            {utils.mapRefDataItems(cropStages.filter((c) => ["Seed", "Seedling"].includes(c.value)))}
                                        </SelectPack>
                                    )}
                                    {activeControls.harvestStage && (
                                        <SelectPack id="crop_harvestStage" className="u-mt-lg" label="Harvest stage" dataWidth="50" meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "crop", key: "harvestStage" })} value={crop.harvestStage} requiredLabel={true}>
                                            <option value="" disabled={true}>
                                                Harvest stage
                                            </option>
                                            {utils.mapRefDataItems(cropStages.filter((c) => !["Seed", "Seedling"].includes(c.value)))}
                                        </SelectPack>
                                    )}
                                    {activeControls.thermalTimeEndReason && activeControls.thermalTimeEndReason !== 0 && (
                                        <SelectPack id="crop_thermalTimeEndReason" className="u-mt-lg" label="Final event" dataWidth="50" meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "crop_thermalTimeEndReason", key: "thermalTimeEndReason" })} value={crop.thermalTimeEndReason} requiredLabel={true} val={validation.thermalTimeEndReason} tip={this.getFinishTip(crop)}>
                                            <option value="" disabled={true}>
                                                Final event
                                            </option>
                                            {utils.mapRefDataItems(cropThermalTimeEndReason)}
                                        </SelectPack>
                                    )}
                                </div>
                                {(activeControls.sown_month || activeControls.thermalTimeEndIndex) && (
                                    <>
                                        <div className="Field-group">
                                            <RotationYear id="sown_reportingYear" type="sown_reportingYear" dataWidth="50" label="Crop sown in" disabled={isAutoRotationEvent} isHidden={!activeControls.sown_month} onChange={(e, s) => this.onChange(e, s)} value={sown} val={validation.sown_month} rotationYear={rotationYear} initLabel="Select when sown" initReadonly={true} disabledMonths={sownDisabledMonths} requiredLabel={true} />
                                            <RotationYear id="crop_thermalTimeEndIndex" disableYear3={disableYear3} shift={isFodderCrop ? 12 : 0} dataWidth="50" label="Crop finishes in" isHidden={!activeControls.thermalTimeEndIndex} onChange={(e) => this.onChange(e, { type: "crop_thermalTimeEndIndex", key: "thermalTimeEndIndex" })} rotationIndex={crop.thermalTimeEndIndex - 1} val={validation.thermalTimeEndIndex} rotationYear={finishRotationYear} initLabel="Select when finished" initReadonly={true} disabledMonths={finishDisabledMonths} requiredLabel={true} tip={<div>This is used to determine the growth curve of the crop for all crops except pasture and pasture seed crops. which is calculated using your block location temperature. {isPasture ? `For pasture and seed crops if there is no end month select "Ongoing"` : ""}</div>} />
                                        </div>
                                        <Alert
                                            type="info"
                                            text={
                                                <div>
                                                    <div className="u-pb-sm">
                                                        <b>Sown:</b> The model assumes the crop is sown at the start of the month. Even if the crop is sown during the month.
                                                    </div>
                                                    <div>
                                                        <b>Finishes:</b> If the final event is a cultivate or defoliation the model assumes the crop finishes at the end of the month otherwise the model assumes the start of the month
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </>
                                )}

                                <CheckboxPack meta={{ nrf: true }} id="chineseCut" disabled={isAutoRotationEvent} isHidden={!activeControls.chineseCut} label="Chinese cut" value={crop.chineseCut} onChange={(e) => this.onChange(e, { type: "crop", key: "chineseCut" })} />

                                {(activeControls.productYield || activeControls.fieldLossPercentage) && !isAutoRotationEvent && (
                                    <div className="Field-group">
                                        {activeControls.productYield && <NumericInputPack dataWidth="50" id="crop_productYield" info={yieldInfo} isHidden={!activeControls.productYield} label={`Yield ${selectedCropType && selectedCropType.yieldType ? "- " + selectedCropType.yieldType : ""}`} disabled={isAutoRotationEvent} uom={selectedCropType && selectedCropType.yieldUnits ? selectedCropType.yieldUnits.replace("T/ha dry matter", "t DM/ha") : "t DM/ha"} requiredLabel={true} val={validation.productYield} onChange={(e) => this.onChange(e, { type: "crop", key: "productYield" })} decimalPlaces={1} value={crop.productYield} />}
                                        {activeControls.fieldLossPercentage && <NumericInputPack dataWidth="50" id="crop_fieldLoss" label="Override default field loss" uom="%" info={fieldLossInfo} tip={fieldLossHelp} requiredLabel={true} val={validation.fieldLossPercentage} onChange={(e) => this.onChange(e, { type: "crop", key: "fieldLossPercentage" })} decimalPlaces={0} value={crop.fieldLossPercentage} />}
                                    </div>
                                )}

                                {activeControls.cultivationPractice && !isAutoRotationEvent && (
                                    <div className="Field-group">
                                        <SelectPack id="crop_cultivationPractice" isHidden={!activeControls.cultivationPractice} label="Cultivation practice at sowing" meta={{ nrf: true }} dataWidth="50" onChange={(e) => this.onChange(e, { type: "crop", key: "cultivationPractice" })} value={crop.cultivationPractice} val={validation.cultivationPractice} requiredLabel={true}>
                                            <option value="" disabled={true}>
                                                Select a cultivation practice
                                            </option>
                                            {utils.mapRefDataItems(tillages)}
                                        </SelectPack>
                                    </div>
                                )}
                                {(activeControls.residualDisposalMethod || activeControls.monthsAfterCropRemoved || activeControls.monthSeedHarvested) && !isAutoRotationEvent && (
                                    <div className="Field-group">
                                        <SelectPack id="crop_residualDisposalMethod" isHidden={!activeControls.residualDisposalMethod} dataWidth="50" label="Residual management method" meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "crop", key: "residualDisposalMethod" })} value={crop.residualDisposalMethod} val={validation.residualDisposalMethod} requiredLabel={true}>
                                            <option value="" disabled={true}>
                                                Select a residual management method
                                            </option>
                                            {utils.mapRefDataItems(cropResidues)}
                                        </SelectPack>
                                        <NumericInputPack id="crop_monthsAfterCropRemoved" dataWidth="50" isHidden={!activeControls.monthsAfterCropRemoved} label="Months after initial harvest crop removed" uom="months" requiredLabel={false} val={validation.monthsAfterCropRemoved} onChange={(e) => this.onChange(e, { type: "crop", key: "monthsAfterCropRemoved" })} decimalPlaces={0} value={crop.monthsAfterCropRemoved} tip={"Number of months taken to harvest the entire crop excluding the first month. If takes no more then one month leave blank. If harvest takes an additional month then enter 1, if an additional 2 months then enter 2, etc."} />
                                        <SelectPack id="crop_monthSeedHarvested" dataWidth="50" label="Month seed harvested" meta={{ nrf: true }} isHidden={!activeControls.monthSeedHarvested} onChange={(e) => this.onChange(e, { type: "crop", key: "monthSeedHarvested" })} value={crop.monthSeedHarvested} val={validation.monthSeedHarvested} requiredLabel={true}>
                                            <option value="">Use default</option>
                                            {domain.calendarYear.map((m) => (
                                                <option key={m} value={m}>
                                                    {m}
                                                </option>
                                            ))}
                                        </SelectPack>
                                    </div>
                                )}

                                {defoliationMsg && <Alert type="info" text={defoliationMsg} />}
                                {fallowMsg && <Alert type="info" text={fallowMsg} />}
                                {!isAutoRotationEvent && (
                                    <>
                                        <SelectPack id="crop_defoliationManagement" dataWidth="50" label="Defoliation management" meta={{ nrf: true }} isHidden={!activeControls.defoliationManagement} onChange={(e) => this.onChange(e, { type: "crop", key: "defoliationManagement" })} value={defoliationManagementValue} val={validation.defoliationManagement} requiredLabel={true}>
                                            <option value="" disabled={true}>
                                                Select the defoliation management method
                                            </option>
                                            {utils.mapRefDataItems(defoliationManagement)}
                                        </SelectPack>
                                        {activeControls.defoliationManagement && (
                                            <Alert
                                                type="info"
                                                html="<div>If the pasture/crop is grazed (ie “Grazing only” or “Grazing and cutting”) the “Animals grazing” section is displayed below to enter the animal data that is grazing on the pasture.<br/><br/>  
                                        If the pasture/crop is cut (ie “Cut/Carry only” or “Cutting and grazing”) you are required to enter a “Harvested event” against this crop block within the “Supplements” screen.</div>"
                                            />
                                        )}
                                    </>
                                )}

                                {showGrazing && !isAutoRotationEvent
                                    && <AnimalGrazing
                                        onChange={(e, source) => this.onChange(e, source)}
                                        animalGrazing={animalGrazing}
                                        validation={validation}
                                        source="cropsown"
                                        enterprises={this.props.analysis.enterprises.filter((e) => e.type !== "OutdoorPigs")}
                                        crop={crop}
                                        displayEqualRatio={true}
                                        isRequired={true}
                                        isRotation={this.props.isRotation} />}
                                <CheckboxPack meta={{ nrf: true }} id="soil-tests" label="Specify soil tests" value={specifySoilTests} onChange={(e) => this.switchSoilTests(e)} tip={"Enter soil test values if known. The best values are ones taken during the crop growing phase. Samples taken prior to sowing are acceptable provided no capital fertiliser has been applied. If no soil test data is specified, default values based on a survey of soil test results will be used."} />
                                {specifySoilTests && (
                                    <div className="Field-group">
                                        <NumericInputPack id="crop_nutrients_P" dataWidth="20" label="Olsen P" val={validation.nutrients_p} onChange={(e) => this.onChange(e, { type: "nutrients", key: "P" })} decimalPlaces={0} value={nutrients.P} />
                                        <NumericInputPack id="crop_nutrients_K" dataWidth="20" label="QT K" val={validation.nutrients_k} onChange={(e) => this.onChange(e, { type: "nutrients", key: "K" })} decimalPlaces={0} value={nutrients.K} />
                                        <NumericInputPack id="crop_nutrients_Ca" dataWidth="20" label="QT CA" val={validation.nutrients_ca} onChange={(e) => this.onChange(e, { type: "nutrients", key: "Ca" })} decimalPlaces={0} value={nutrients.Ca} />
                                        <NumericInputPack id="crop_nutrients_Mg" dataWidth="20" label="QT Mg" val={validation.nutrients_mg} onChange={(e) => this.onChange(e, { type: "nutrients", key: "Mg" })} decimalPlaces={0} value={nutrients.Mg} />
                                        <NumericInputPack id="crop_nutrients_Na" dataWidth="20" label="QT Na" val={validation.nutrients_na} onChange={(e) => this.onChange(e, { type: "nutrients", key: "Na" })} decimalPlaces={0} value={nutrients.Na} />
                                    </div>
                                )}
                                {validation.soilTest && validation.soilTest.error && (
                                    <div className="Field has-error">
                                        <small className="Field-error">Please enter at least one soil test value</small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="Modal-footer">
                        <div className="ButtonBar">
                            <div className="ButtonBar-left">
                                <button type="button" onClick={cancel} className="Button Button--secondary" id="modal-cancel">
                                    Cancel
                                </button>
                            </div>
                            <div className="ButtonBar-right">
                                <button type="button" onClick={() => this.save()} className="Button" id="modal-save">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const displayAnimalGrazing = (crop) => ["Pasture", "Seed", "Forages"].includes(crop.category) && ["GrazeOnly", "GrazeCut"].includes(crop.defoliationManagement);
