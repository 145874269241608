import * as icons from "common/icons";
import Page from "components/Page";
import PageBody from "components/PageBody";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import { useMyProfileModal } from "containers/Auth/MyProfileModal";

export default function EmailRequired() {
    const [myProfileModal, openMyProfileModal] = useMyProfileModal();

    return (
        <>
            <Page>
                <section className="PageHead">
                    <div className="Container">
                        <div className="FarmTitle">
                            <div className="FarmTitle-heading">
                                <span id="page-head-title" className="FarmTitle-name">
                                    Dashboard
                                </span>
                            </div>
                        </div>
                        <div className="PageHead-actions"></div>
                    </div>
                </section>
                <PageBody>
                    <Panel title="Email required">
                        <PanelBody>
                            <div className="Tile-body-message">
                                <img className="u-p-5" src={icons.blocks} alt="Overseer" />
                                <h1 className="u-mt-0">We don't have your email address</h1>
                                <h2>Before you can continue using Overseer, we need to know your email address</h2>
                                <ActionLink className="IconLink--settings Button Button--lg u-mt-lg" onClick={openMyProfileModal}>
                                    Set my email
                                </ActionLink>
                            </div>
                        </PanelBody>
                    </Panel>
                </PageBody>
            </Page>
            {myProfileModal}
        </>
    )
}
