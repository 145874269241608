import React from "react";
import { connect } from "react-redux";
import Alert from "components/Alert";
import DatePicker from "react-datepicker";
import moment from "moment";

const DatePickerInputPack = ({ input, label, clearable, placeholder, id, isHidden, requiredLabel, required, noLabel, placement, disabled, meta: { touched, error }, uom, validateOnRender, dataWidth, hideErrors, minDate, className, showYearDropdown, inline = false, tip, showQuickTips, info }) => {
    if (isHidden) return null;

    const _handleChange = (date) => {
        const val = moment(date);
        if (val) {
            const formattedValue = val.format("DD MMMM YYYY");
            input.onChange(formattedValue);
        } else {
            input.onChange("");
        }
    };

    const _date = moment(input.value, "DD MMMM YYYY");

    return (
        <div className={`Field ${(validateOnRender || touched) && error ? "has-error" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            {noLabel !== true && (
                <label htmlFor={id || input.name} className={`Field-label ${disabled ? "is-disabled" : ""}`}>
                    {label}
                    {(requiredLabel === true || required) && <sup className="required">*</sup>}
                    {label === undefined && "\u00A0"}
                </label>
            )}
            <div className="Input_wrapper">
                <DatePicker autoComplete="off" {...input} dateFormat={`DD MMMM YYYY`} isClearable={clearable} minDate={minDate} openToDate={_date.toDate()} selected={_date.toDate()} onChange={_handleChange} inline={inline} showYearDropdown={showYearDropdown} popperPlacement={placement} fixedHeight placeholderText={placeholder} className={`Input Input--block ${disabled ? "is-disabled" : ""}`} disabled={disabled} />
                {uom && <small className="Input-helper">{uom}</small>}
            </div>
            {hideErrors !== true && (validateOnRender || touched) && error && (
                <small className="Field-error" id={`${id || input.name}-error`}>
                    {error}
                </small>
            )}
            {info && <Alert type="info" text={info} />}
            {showQuickTips && tip && <Alert type="help" text={tip} />}
        </div>
    );
};

export default connect((state) => {
    return {
        showQuickTips: state.app.showQuickTips,
    };
}, null)(DatePickerInputPack);
