import { Component } from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Link } from "react-router-dom";
import * as FormUtils from "common/FormUtils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import Alert from "components/Alert";
import InputPack from "components/InputPack";
import SavePrompt from "components/SavePrompt";
import FuelUsageActivities from "./FuelUsageActivities";
import FuelCropActivities, { FuelCropActivitiesDecorators } from "./FuelCropActivities";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { Button } from "components/Button";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function Fuel({ farm, analysis }) {
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const initialValues = (analysis.ghg || {}).fuel;

    return <FuelClassComponent farm={farm} analysis={analysis} initialValues={initialValues} updateAnalysisAsync={updateAnalysisAsync} />
}

class FuelClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referrer: `/app/farm/${props.farm.id}/analysis/${props.analysis.id}/ghg`,
            decoratros: [FuelCropActivitiesDecorators],
        };
    }

    _validate = (values) => {
        const errors = {};

        errors.petrolInLitresPerYear = FormUtils.validators.range(0, 999999)(values.petrolInLitresPerYear);
        errors.dieselInLitresPerYear = FormUtils.validators.range(0, 999999)(values.dieselInLitresPerYear);
        errors.aviationFuelInLitresPerYear = FormUtils.validators.range(0, 999999)(values.aviationFuelInLitresPerYear);

        if (values.dieselInLitresPerYear) errors.petrolInLitresPerYear = errors.petrolInLitresPerYear || undefined;
        else errors.petrolInLitresPerYear = errors.petrolInLitresPerYear || FormUtils.validators.required(values.petrolInLitresPerYear, "Required if diesel not specified");

        if (values.petrolInLitresPerYear) errors.dieselInLitresPerYear = errors.dieselInLitresPerYear || undefined;
        else errors.dieselInLitresPerYear = errors.dieselInLitresPerYear || FormUtils.validators.required(values.dieselInLitresPerYear, "Required if petrol not specified");

        return errors;
    };

    _submit = async (values) => {
        const updatedAnalysis = {
            ...this.props.analysis,
            ghg: {
                ...this.props.analysis.ghg,
                fuel: values,
            }
        }
        await this.props.updateAnalysisAsync(updatedAnalysis);
    };

    render() {
        const { initialValues } = this.props;
        return (
            <Form initialValues={initialValues} mutators={{ ...arrayMutators }} decorators={this.state.decoratros} validate={this._validate} onSubmit={this._submit}>
                {({ form, values, dirty, submitting, submitSucceeded, handleSubmit }) => {
                    const hasValues = Object.keys(values).length > 0;
                    return (
                        <form onSubmit={handleSubmit}>
                            <SavePrompt blockIf={dirty && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={this.state.referrer} />
                            <Tile title="GHG fuel footprint" waiting={submitting} referrer={this.state.referrer}>
                                <Alert type="info" text="The greenhouse gas footprint report requires an estimate of total fuel used to produce the products coming from the farm. Use this page to enter data on fuel used for farm activities (not personal) and on operations undertaken by contractors." />
                                <TileBody waiting={submitting}>
                                    <div className="Grid Grid--withGutter">
                                        <div className="Grid-cell u-lg-width1of5">
                                            <h3>Farm fuel use</h3>
                                            <Field name="petrolInLitresPerYear" type="text" label="Petrol" uom="litres/yr" component={InputPack} placeholder="0" format={FormUtils.formatters.formatInt} formatOnBlur />
                                            <Field name="dieselInLitresPerYear" type="text" label="Diesel" uom="litres/yr" component={InputPack} placeholder="0" format={FormUtils.formatters.formatInt} formatOnBlur />
                                            <Field name="aviationFuelInLitresPerYear" type="text" label="Aviation fuel" uom="litres/yr" component={InputPack} placeholder="0" format={FormUtils.formatters.formatInt} formatOnBlur />
                                        </div>
                                        <div className="Grid-cell u-lg-width4of5">
                                            <FuelUsageActivities form={form} values={values} />
                                            <FuelCropActivities form={form} values={values} />
                                        </div>
                                    </div>
                                </TileBody>
                                <TileFooter>
                                    <div className="ButtonBar ButtonBar--fixed">
                                        <div className="ButtonBar-left">
                                            <Link to={this.state.referrer} id="cancel-button" className="Button Button--secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="ButtonBar-right">
                                            <Button submit primary id="save-button" waiting={submitting} disabled={!hasValues}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </TileFooter>
                            </Tile>
                        </form>
                    );
                }}
            </Form>
        )
    }
}

