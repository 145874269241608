import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import { Grid, GridCell } from "components/Grid";
import InputPack from "components/InputPack";

export default function OutdoorPigNumbersGrowersFinishers({ outdoorPigs }) {
    const instructions = outdoorPigs.growOutUnitOnly ? "Growers brought onto outdoor blocks per week." : "Weaners added to outdoor blocks per week.";

    const numbers = outdoorPigs.numbers || {};
    const saleLiveWeight = getSaleLiveWeight(numbers.growersFinishers);
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;

    return (
        <Grid title="Outdoor growers & finishers" instructions={instructions}>
            {!outdoorPigs.growOutUnitOnly && (
                <GridCell className="u-width1of4">
                    <Field name="numbers.growersFinishers.weanedNumber" label="Weaned from farm" uom="per week" placeholder="0" type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                </GridCell>
            )}
            <GridCell className="u-width1of4">
                <Field name="numbers.growersFinishers.broughtIn" label="Brought in" uom="per week" placeholder="0" required={outdoorPigs.growOutUnitOnly} type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
            </GridCell>
            {(outdoorPigs.growOutUnitOnly || hasBroughtIn) && (
                <>
                    <GridCell className="u-width1of4">
                        <Field name="numbers.growersFinishers.purchaseLiveweight" label="Average live weight at purchase" uom="kg" placeholder="0.0" required={outdoorPigs.growOutUnitOnly || hasBroughtIn} type="text" component={InputPack} format={FormUtils.formatters.formatDecimal(1)} formatOnBlur />
                    </GridCell>
                    <GridCell className="u-width1of4">
                        <Field name="numbers.growersFinishers.purchaseSaleAge" label="Average age at purchase" uom="days" placeholder="0" required={outdoorPigs.growOutUnitOnly || hasBroughtIn} type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                    </GridCell>
                </>
            )}
            {(outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn) && (
                <>
                    <GridCell className="u-width1of4">
                        <Field name="numbers.growersFinishers.saleCarcassWeight" label="Average carcass weight at sale" uom="kg" placeholder="0.0" required={outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn} type="text" component={InputPack} format={FormUtils.formatters.formatDecimal(1)} formatOnBlur />
                    </GridCell>
                    <GridCell className="u-width1of4">
                        <div className="Field">
                            <label className="Field-label">Average live weight at sale</label>
                            <div className="Input_wrapper">
                                <div className="Input Input--block Input--disabled">
                                    <div style={{ position: "relative", top: "-4.5px" }}>{saleLiveWeight}</div>
                                    <small className="Input-helper">kg</small>
                                </div>
                            </div>
                        </div>
                    </GridCell>
                    <GridCell className="u-width1of4">
                        <Field name="numbers.growersFinishers.saleAge" label="Average age at sale" placeholder="0" uom="days" required={outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn} type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                    </GridCell>
                </>
            )}
        </Grid>
    );
};

export const validateGrowersFinishers = (growOutUnitOnly, growersFinishers) => {
    const errors = {};

    const hasWeanedNumber = growersFinishers.weanedNumber > 0;
    const hasBroughtIn = growersFinishers.broughtIn > 0;

    if (growOutUnitOnly) {
        errors.broughtIn = FormUtils.validators.required(growersFinishers.broughtIn);
    } else {
        errors.weanedNumber = FormUtils.validators.max(99999)(growersFinishers.weanedNumber);
    }
    errors.broughtIn = errors.broughtIn || FormUtils.validators.max(99999)(growersFinishers.broughtIn);

    if (growOutUnitOnly || hasBroughtIn) {
        errors.purchaseLiveweight = FormUtils.validators.required(growersFinishers.purchaseLiveweight);
        errors.purchaseLiveweight = errors.purchaseLiveweight || FormUtils.validators.max(99999)(growersFinishers.purchaseLiveweight);
        if (!errors.purchaseLiveweight) {
            const saleLiveWeight = getSaleLiveWeight(growersFinishers);
            errors.purchaseLiveweight = errors.purchaseLiveweight || !saleLiveWeight || growersFinishers.purchaseLiveweight <= saleLiveWeight ? undefined : "Must be less than average live weight at sale";
        }

        errors.purchaseSaleAge = FormUtils.validators.required(growersFinishers.purchaseSaleAge);
        errors.purchaseSaleAge = errors.purchaseSaleAge || FormUtils.validators.max(99999)(growersFinishers.purchaseSaleAge);
    }

    if (growOutUnitOnly || hasWeanedNumber || hasBroughtIn) {
        errors.saleCarcassWeight = FormUtils.validators.required(growersFinishers.saleCarcassWeight);
        errors.saleCarcassWeight = errors.saleCarcassWeight || FormUtils.validators.max(99999)(growersFinishers.saleCarcassWeight);
        errors.saleAge = FormUtils.validators.required(growersFinishers.saleAge);
        errors.saleAge = errors.saleAge || FormUtils.validators.max(99999)(growersFinishers.saleAge);
    }

    return errors;
}

const getSaleLiveWeight = (growersFinishers) => {
    if (!growersFinishers) return 0;

    const { saleCarcassWeight = 0 } = growersFinishers;
    const saleLiveWeight = saleCarcassWeight / 0.75;
    const result = FormUtils.formatters.formatDecimal(1)(saleLiveWeight) || 0;
    return result;
}
