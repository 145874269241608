import React from "react";
import { compose, withAppContext, withActions, withState } from "containers/HOC";
import debounce from "lodash/debounce";
import * as icons from "common/icons";
import Alert from "components/Alert";
import Page from "components/Page";
import PageBody from "components/PageBody";
import { Panel, PanelBody } from "components/Panel";
import { farmSearch, farmSearchClear } from "./_actions";
import FarmList from "containers/Dashboard/FarmList";
import MyPublications from "./MyPublications";
import ActionLink from "components/ActionLink";
import CreateFarmLink from "containers/Farm/CreateFarmLink";

const NoData = ({ icon, title, instructions, children }) => {
    return (
        <div className="Tile-body-message">
            {icon && <i className={`icon ${icon} icon--md u-textSecondary`} />}
            {title && <p className="lead">{title}</p>}
            {instructions && <p className="u-mt-md u-text-lg">{instructions}</p>}
            {children}
        </div>
    );
};

class FarmSearch extends React.Component {
    componentDidMount() {
        this.props.farmSearch(this.props.searchText, this.props.filterBy);
    }

    _autoSearch = debounce((e) => {
        if (this.props.online) {
            const filterBy = this.filterBy.value || "";
            const searchText = this.searchText.value || "";
            //if (searchText.length > 0 || filterBy == 'MyOrg')
            this.props.farmSearch(searchText, filterBy);
        }
    }, 500);

    _reset = (e) => {
        e.preventDefault();
        this.searchText.value = "";
        this.filterBy.value = "MyOrg";
        this.props.farmSearch("", "MyOrg");
    };

    _reloadSearch = () => {
        this.props.farmSearch(this.props.searchText, this.props.filterBy);
    };

    render() {
        const filterBy = this.filterBy ? this.filterBy.value : this.props.filterBy;
        const searchText = this.searchText ? this.searchText.value : this.props.searchText;
        const { online, searchResults, authContext, deploymentType, showQuickTips, searchResultsLoading } = this.props;

        const hasSearchCriteria = searchText.length > 0 || ["MyOrg", "MyFarms"].includes(filterBy);

        const isFarmSearch = filterBy !== "Publications";
        const hasFarmSearchResults = isFarmSearch && hasSearchCriteria && searchResults.length > 0 && !searchResults[0].publishedOn;

        const isPublicationSearch = filterBy === "Publications";
        const hasPublicationSearchResults = isPublicationSearch && hasSearchCriteria && searchResults.length > 0 && searchResults[0].publishedOn;

        return (
            <Page>
                <section className="PageHead">
                    <div className="Container">
                        {online && (
                            <div className="PageHead-actions">
                                <label className="Select Select--sort">
                                    <span className="Select--sort-label">Search by</span>
                                    <select className="Select--sort-select" onChange={this._autoSearch} defaultValue={this.props.filterBy} ref={(component) => (this.filterBy = component)} id="searchby">
                                        <option value="MyOrg">My organisation</option>
                                        <option value="MyFarms">My farms</option>
                                        {((authContext.accountType !== "Student" && deploymentType !== "Science") || authContext.isSystemAdmin) && <option value="AllFarms">All farms</option>}
                                        <option value="Publications">Publications</option>
                                    </select>
                                </label>
                            </div>
                        )}
                        {online && (
                            <div className="PageHead-searchInput">
                                <div className="Input_wrapper">
                                    <input autoFocus type="search" id="search" placeholder="Search by farm name or address" className="Input Input--block has-clear has-value" defaultValue={searchText} onChange={this._autoSearch} ref={(component) => (this.searchText = component)} />
                                    <ActionLink id="farm-search-reset" onClick={this._reset} className="Input-clear">
                                        <img className="u-p-5" height="24px" width="24px" src={icons.clear} alt="Clear" />
                                    </ActionLink>
                                </div>
                            </div>
                        )}
                        {online && (
                            <div className="PageHead-actions">
                                <CreateFarmLink id="farm-search-create" />
                            </div>
                        )}
                    </div>
                </section>
                <PageBody>
                    {showQuickTips && <Alert type="help" text="Use this screen to search for farms. Select an option to Search by from the list above. Selecting My organisations looks for farms that you have access to, My farms searches within your farms and All farms searches across the whole system. Enter the farm name, address or a possible farm identifier to start the search. As you type it will try and search for the farms that match what you have entered. You can also search for publications that have been published to you. For publications you may also enter the publication reference or a date before it was published (eg. 1 apr)." />}
                    <Panel title="Search results" referrer="/" loading={searchResultsLoading}>
                        {isFarmSearch && (
                            <PanelBody loading={hasSearchCriteria && !hasFarmSearchResults && searchResultsLoading}>
                                {hasSearchCriteria && hasFarmSearchResults && <FarmList farms={searchResults} filterBy={filterBy} reload={this._reloadSearch} />}
                                {hasSearchCriteria && !hasFarmSearchResults && (
                                    <NoData icon="icon-search" title="No results found" instructions="Refine your search or create a new farm...">
                                        <CreateFarmLink id="farm-search-create" className="Button Button--lg u-mt-lg" />
                                    </NoData>
                                )}
                                {!hasSearchCriteria && !hasFarmSearchResults && <NoData icon="icon-search" title="Search for a farm..." instructions="Start typing the name or address of the farm in the search box above" />}
                            </PanelBody>
                        )}
                        {isPublicationSearch && (
                            <PanelBody loading={hasSearchCriteria && !hasPublicationSearchResults && searchResultsLoading}>
                                {hasSearchCriteria && hasPublicationSearchResults && (
                                    <>
                                        <p className="u-mt-0 u-mb-md">
                                            <span className="h3">{searchResults.length}</span> farm(s) found
                                        </p>
                                        <MyPublications myPublications={searchResults} />
                                    </>
                                )}
                                {hasSearchCriteria && !hasPublicationSearchResults && <NoData icon="icon-search" title="No results found" instructions="You may search by farm name, farm address, publication reference, publishers email or published date" />}
                                {!hasSearchCriteria && !hasPublicationSearchResults && <NoData icon="icon-search" title="Search for a publication..." instructions="Start typing the publication reference or name or address of the farm in the search box above" />}
                            </PanelBody>
                        )}
                    </Panel>
                </PageBody>
            </Page>
        );
    }
}

export default compose(
    withAppContext,
    withActions({ farmSearch, farmSearchClear }),
    withState((state, ownProps) => {
        return {
            ...ownProps,
            searchResultsLoading: state.farmSearch.searchResultsLoading,
            searchText: state.farmSearch.searchText,
            filterBy: state.farmSearch.filterBy,
            searchResults: state.farmSearch.searchResults,
            showQuickTips: state.app.showQuickTips,
            authContext: {
                accountId: state.auth.accountId,
                accountName: state.auth.accountName,
                userId: state.auth.userId,
                fullName: state.auth.fullName,
                isSystemAdmin: state.auth.isSystemAdmin,
                isAdmin: state.auth.isAdmin,
                accountType: state.auth.accountType,
            },
            online: state.app.online,
            deploymentType: state.app.refData.deploymentType,
        };
    })
)(FarmSearch);
