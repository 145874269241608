import React from "react";
import numeral from "numeral";

class BalanceSummary extends React.Component {
    render() {
        const { accountId, balance = {}, authContext } = this.props;
        const isAuthorised = authContext.isSystemAdmin || authContext.accountId === accountId;
        const { currentBalance = 0, creditLimit = 0 } = balance;
        const availableCredit = currentBalance + creditLimit;

        return (
            <React.Fragment>
                {!isAuthorised && (
                    <div className="Tile-body-message">
                        <p className="lead">Access denied</p>
                    </div>
                )}
                {isAuthorised && (
                    <div className="u-mt-md u-mb-md">
                        {creditLimit > 0 && (
                            <span className="u-pr-lg">
                                <b>Postpaid spending limit:</b> {numeral(creditLimit).format("$0,0.00")}
                            </span>
                        )}
                        {creditLimit > 0 && currentBalance !== availableCredit && (
                            <span className="u-pr-lg">
                                <b>Postpaid purchases:</b> {numeral(currentBalance).format("$0,0.00")}
                            </span>
                        )}
                        <span>
                            <b>Available balance:</b> {numeral(availableCredit).format("$0,0.00")}
                        </span>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default BalanceSummary;
