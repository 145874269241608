import { push } from "connected-react-router";
import * as api from "api";
import appActionTypes from "containers/App/_actions";
import ReactGA from "react-ga4";

const authActionTypes = {
    // Register
    AUTH_REGISTER: "AUTH_REGISTER",
    AUTH_REGISTER_SUCCESS: "AUTH_REGISTER_SUCCESS",
    AUTH_REGISTER_FAILURE: "AUTH_REGISTER_FAILURE",
    AUTH_REGISTER_CANCEL: "AUTH_REGISTER_CANCEL",

    // Login
    AUTH_LOGIN: "AUTH_LOGIN",
    AUTH_LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
    AUTH_LOGIN_FAILURE: "AUTH_LOGIN_FAILURE",
    AUTH_LOGIN_CANCEL: "AUTH_LOGIN_CANCEL",
    AUTH_TERMS_REQUIRED: "AUTH_TERMS_REQUIRED",
    AUTH_TERMS_NOT_REQUIRED: "AUTH_TERMS_NOT_REQUIRED",

    // Logout
    AUTH_LOGOUT: "AUTH_LOGOUT",
    AUTH_CLEAR_STORAGE: "AUTH_CLEAR_STORAGE",
    AUTH_UNAUTHORISED: "AUTH_UNAUTHORISED",

    // Forgot password
    AUTH_REQUEST_PASSWORD_RESET: "AUTH_REQUEST_PASSWORD_RESET",
    AUTH_REQUEST_PASSWORD_RESET_SUCCESS: "AUTH_REQUEST_PASSWORD_RESET_SUCCESS",

    // Reset password
    AUTH_RESET_PASSWORD: "AUTH_RESET_PASSWORD",
    AUTH_RESET_PASSWORD_SUCCESS: "AUTH_RESET_PASSWORD_SUCCESS",
    AUTH_RESET_PASSWORD_FAILURE: "AUTH_RESET_PASSWORD_FAILURE",
    AUTH_RESET_PASSWORD_CANCEL: "AUTH_RESET_PASSWORD_CANCEL",

    // Get invitation
    AUTH_GET_INVITATION: "AUTH_GET_INVITATION",
    AUTH_GET_INVITATION_SUCCESS: "AUTH_GET_INVITATION_SUCCESS",
    AUTH_GET_INVITATION_FAILURE: "AUTH_GET_INVITATION_FAILURE",
    AUTH_GET_INVITATION_CANCEL: "AUTH_GET_INVITATION_CANCEL",

    // Get Access invitation
    AUTH_ACCESS_GET_INVITATION: "AUTH_ACCESS_GET_INVITATION",
    AUTH_ACCESS_GET_INVITATION_SUCCESS: "AUTH_ACCESS_GET_INVITATION_SUCCESS",
    AUTH_ACCESS_GET_INVITATION_FAILURE: "AUTH_ACCESS_GET_INVITATION_FAILURE",
    AUTH_ACCESS_GET_INVITATION_CANCEL: "AUTH_ACCESS_GET_INVITATION_CANCEL",

    // Verify email
    AUTH_USER_ACTIVATION_SUCCESS: "AUTH_USER_ACTIVATION_SUCCESS",
    AUTH_USER_ACTIVATION_FAILURE: "AUTH_USER_ACTIVATION_FAILURE",

    AUTH_ZENDESK_SUCCESS: "AUTH_ZENDESK_SUCCESS",
    AUTH_ZENDESK_CANCEL: "AUTH_ZENDESK_CANCEL",
    AUTH_ZENDESK_FAILURE: "AUTH_ZENDESK_FAILURE",
    AUTH_ZENDESK_CLEAR: "AUTH_ZENDESK_CLEAR",

    AUTH_ED_LOGIN: "AUTH_ED_LOGIN",
    AUTH_ED_LOGIN_SUCCESS: "AUTH_ED_LOGIN_SUCCESS",
    AUTH_ED_LOGIN_FAILURE: "AUTH_ED_LOGIN_FAILURE",
    AUTH_ED_LOGIN_CANCEL: "AUTH_ED_LOGIN_CANCEL",
};
export default authActionTypes;

//Register
export function register(registrationDetails, locationState) {
    ReactGA.event({
        category: "Auth",
        action: "Register",
    });
    return api.post({
        path: "accounts",
        content: registrationDetails,
        silentMode: true,
        onBeforeSend: () => {
            return { type: authActionTypes.AUTH_REGISTER, payload: { locationState } };
        },
        onSuccess: (response) => {
            return (dispatch) => {
                const autoLoginDetails = {
                    email: registrationDetails.user.email,
                    password: registrationDetails.user.password,
                };
                dispatch(login(autoLoginDetails));
            };
        },
        onFailure: (error) => {
            return (dispatch) => {
                if (error) {
                    switch (error.status) {
                        case 400:
                            dispatch({ type: authActionTypes.AUTH_REGISTER_FAILURE, payload: "Error registering. Please ensure your email address is valid." });
                            break;
                        case 409:
                            dispatch({ type: authActionTypes.AUTH_REGISTER_FAILURE, payload: "This email address is already registered. Use a different email address or login." });
                            break;
                        default:
                            dispatch({ type: authActionTypes.AUTH_REGISTER_FAILURE, payload: "Registration failed. Try again." });
                            break;
                    }
                }
            };
        },
        cancelActionType: authActionTypes.AUTH_REGISTER_CANCEL,
    });
}

export function registerCancel() {
    return (dispatch) => {
        dispatch({ type: authActionTypes.AUTH_REGISTER_CANCEL });
    };
}

export function clearZendeskToken() {
    return (dispatch) => {
        dispatch({ type: authActionTypes.AUTH_ZENDESK_CLEAR });
    };
}

// Login
export function login(loginDetails, locationState) {
    ReactGA.event({
        category: "Auth",
        action: "Login",
    });
    return api.post({
        path: "token",
        content: loginDetails,
        onBeforeSend: () => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_CLEAR_STORAGE });
                dispatch({ type: authActionTypes.AUTH_LOGIN, payload: { locationState } });
            };
        },
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_LOGIN_SUCCESS, payload: response });
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                if (error) {
                    switch (error.status) {
                        case 404:
                            dispatch({ type: authActionTypes.AUTH_LOGIN_FAILURE, payload: "Your account has expired. Please contact your education provider." });
                            break;
                        case 423:
                            dispatch({ type: authActionTypes.AUTH_LOGIN_FAILURE, payload: "Suspended account. Please contact support to resolve this issue." });
                            break;
                        default:
                            dispatch({ type: authActionTypes.AUTH_LOGIN_FAILURE, payload: "Invalid credentials. Please try again or use the forgotten password link below to request a password reset." });
                            break;
                    }
                }
            };
        },
        cancelActionType: authActionTypes.AUTH_LOGIN_CANCEL,
    });
}

export function edLogin(loginDetails) {
    ReactGA.event({
        category: "Auth",
        action: "Ed Login",
    });
    return api.post({
        url: `${process.env.REACT_APP_ED_URL}/token`,
        content: loginDetails,
        onBeforeSend: () => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_ED_LOGIN });
            };
        },
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_ED_LOGIN_SUCCESS, payload: response });
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                if (error) {
                    switch (error.status) {
                        case 404:
                            dispatch({ type: authActionTypes.AUTH_ED_LOGIN_FAILURE, payload: "Your account has expired. Please contact your education provider." });
                            break;
                        default:
                            dispatch({ type: authActionTypes.AUTH_ED_LOGIN_FAILURE, payload: "Invalid credentials. Please try again or contact your education provider." });
                            break;
                    }
                }
            };
        },
        cancelActionType: authActionTypes.AUTH_ED_LOGIN_CANCEL,
    });
}

export function getZendeskToken() {
    ReactGA.event({
        category: "Auth",
        action: "Login",
    });
    return api.get({
        path: "accounts/zendesktoken",
        onSuccess: (response) => {
            return { type: authActionTypes.AUTH_ZENDESK_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                if (error.status === 403) {
                    dispatch({ type: authActionTypes.AUTH_ZENDESK_FAILURE, payload: "You need to verify your email before using the helpdesk" });
                } else {
                    dispatch({ type: authActionTypes.AUTH_ZENDESK_FAILURE, payload: "Zendesk log in failure" });
                }
            };
        },
        cancelActionType: authActionTypes.AUTH_ZENDESK_CANCEL,
    });
}

export function loginCancel() {
    return (dispatch) => {
        dispatch({ type: authActionTypes.AUTH_LOGIN_CANCEL });
    };
}

export function loginWithGoogle(jwtIdToken, termsAccepted = false, locationState) {
    ReactGA.event({
        category: "Auth",
        action: "Login.Google",
    });
    return api.post({
        path: "auth/google",
        content: { jwtIdToken, termsAccepted },
        onBeforeSend: () => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_CLEAR_STORAGE });
                dispatch({ type: authActionTypes.AUTH_LOGIN, payload: { locationState } });
            };
        },
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_LOGIN_SUCCESS, payload: response });
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                switch (error.status) {
                    case 404:
                        dispatch({ type: authActionTypes.AUTH_TERMS_REQUIRED });
                        break;
                    case 423:
                        dispatch({ type: authActionTypes.AUTH_LOGIN_FAILURE, payload: "Suspended account. Please contact support to resolve this issue." });
                        break;
                    default:
                    case 403:
                        dispatch({ type: authActionTypes.AUTH_LOGIN_FAILURE, payload: "Invalid credentials. Please try again or use the forgotten password link below to request a password reset." });
                        break;
                }
            };
        },
        cancelActionType: authActionTypes.AUTH_LOGIN_CANCEL,
    });
}

export function reAuthWithGoogle(jwtIdToken, userId, originalPayload) {
    ReactGA.event({
        category: "Auth",
        action: "Login.Google",
    });
    return api.post({
        path: "auth/google",
        content: { jwtIdToken },
        onSuccess: (response) => {
            return (dispatch) => {
                // Make sure re-auth'd for same user.
                if (response.userId === userId) {
                    dispatch({ type: authActionTypes.AUTH_LOGIN_SUCCESS, payload: response });
                    dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
                    dispatch({ type: `@@api/${originalPayload.method}/${originalPayload.url}`, payload: originalPayload });
                } else {
                    dispatch({ type: appActionTypes.APP_SAVING, payload: false });
                    dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "Invalid credentials. Please log out and log in again." });
                }
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return [
                { type: appActionTypes.APP_SAVING, payload: false },
                { type: appActionTypes.APP_MODAL_ERROR, payload: error.status === 423 ? "Suspended account. Please contact support to resolve this issue." : "Invalid credentials. Please try again." },
            ];
        },
    });
}

export function cancelGoogleLogin() {
    return (dispatch) => {
        dispatch({ type: authActionTypes.AUTH_TERMS_NOT_REQUIRED });
    };
}

export function loginWithFacebook(accessToken, termsAccepted = false, locationState) {
    ReactGA.event({
        category: "Auth",
        action: "Login.Facebook",
    });
    return api.post({
        path: "auth/facebook",
        content: { accessToken, termsAccepted },
        onBeforeSend: () => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_CLEAR_STORAGE });
                dispatch({ type: authActionTypes.AUTH_LOGIN, payload: { locationState } });
            };
        },
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_LOGIN_SUCCESS, payload: response });
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                switch (error.status) {
                    case 404:
                        dispatch({ type: authActionTypes.AUTH_TERMS_REQUIRED });
                        break;
                    case 423:
                        dispatch({ type: authActionTypes.AUTH_LOGIN_FAILURE, payload: "Suspended account. Please contact support to resolve this issue." });
                        break;
                    default:
                    case 403:
                        dispatch({ type: authActionTypes.AUTH_LOGIN_FAILURE, payload: "Invalid credentials. Please try again or use the forgotten password link below to request a password reset." });
                        break;
                }
            };
        },
        cancelActionType: authActionTypes.AUTH_LOGIN_CANCEL,
    });
}

export function reAuthWithFacebook(accessToken, oldAuthContext, originalPayload) {
    ReactGA.event({
        category: "Auth",
        action: "Login.Facebook",
    });
    return api.post({
        path: "auth/facebook",
        content: { accessToken },
        onSuccess: (response) => {
            return (dispatch) => {
                // Make sure re-auth'd for same user.
                if (response.userId === oldAuthContext.userId) {
                    dispatch({ type: authActionTypes.AUTH_LOGIN_SUCCESS, payload: response });
                    dispatch({ type: appActionTypes.APP_CLOSE_MODAL });
                    dispatch({ type: `@@api/${originalPayload.method}/${originalPayload.url}`, payload: originalPayload });
                } else {
                    dispatch({ type: appActionTypes.APP_SAVING, payload: false });
                    dispatch({ type: appActionTypes.APP_MODAL_ERROR, payload: "Invalid credentials. Please log out and log in again." });
                }
            };
        },
        onFailure: (error) => {
            error.handled = true;

            return [
                { type: appActionTypes.APP_SAVING, payload: false },
                { type: appActionTypes.APP_MODAL_ERROR, payload: error.status === 423 ? "Suspended account. Please contact support to resolve this issue." : "Invalid credentials. Please try again." },
            ];
        },
    });
}

export function cancelFacebookLogin() {
    if (window.FB) {
        window.FB.getLoginStatus((loginStatus) => {
            if (loginStatus.status === "connected") window.FB.logout();
        }, true);
    }
    return (dispatch) => {
        dispatch({ type: authActionTypes.AUTH_TERMS_NOT_REQUIRED });
    };
}

// Logout
export function logout() {
    return (dispatch) => {
        dispatch(cancelGoogleLogin());
        dispatch(cancelFacebookLogin());
        dispatch({ type: authActionTypes.AUTH_LOGOUT });
        dispatch({ type: authActionTypes.AUTH_CLEAR_STORAGE });
        dispatch(push(`/pub/login`));
    };
}

// Forgot Password
export const requestPasswordReset = (email) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Auth",
            action: "Forgot Password",
        });
        dispatch(
            api.post({
                path: "passwordResets",
                content: { email },
                onBeforeSend: () => {
                    return { type: authActionTypes.AUTH_REQUEST_PASSWORD_RESET, payload: { email } };
                },
                onSuccess: (response) => {
                    resolve(response);
                    return [{ type: authActionTypes.AUTH_REQUEST_PASSWORD_RESET_SUCCESS }];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    return [];
                },
            })
        );
    });
};

// Reset password
export function resetPassword(resetToken, password) {
    ReactGA.event({
        category: "Auth",
        action: "Reset Password",
    });
    return api.put({
        path: `passwordResets/${resetToken}`,
        content: { resetToken, password },
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_RESET_PASSWORD_SUCCESS });
                dispatch(push(`/pub/login`));
            };
        },
        onFailure: (error) => {
            return (dispatch) => {
                dispatch(push(`/pub/login`));
            };
        },
    });
}

// Invitation
export function getUserInvitation(id) {
    return api.get({
        path: `invitations/${id}`,
        onBeforeSend: () => {
            return { type: authActionTypes.AUTH_GET_INVITATION, payload: id };
        },
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_GET_INVITATION_SUCCESS, payload: response });
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_GET_INVITATION_FAILURE });
            };
        },
        cancelActionType: authActionTypes.AUTH_GET_INVITATION_CANCEL,
    });
}

export function getUserInvitationCancel() {
    return (dispatch) => {
        dispatch({ type: authActionTypes.AUTH_GET_INVITATION_CANCEL });
    };
}

//Farm access invitations
export function getFarmAccessInvitation(id) {
    return api.get({
        path: `farmAccessInvitations/${id}`,
        onBeforeSend: () => {
            return { type: authActionTypes.AUTH_ACCESS_GET_INVITATION, payload: id };
        },
        onSuccess: (response) => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_ACCESS_GET_INVITATION_SUCCESS, payload: response });
            };
        },
        onFailure: (error) => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_ACCESS_GET_INVITATION_FAILURE });
            };
        },
        cancelActionType: authActionTypes.AUTH_ACCESS_GET_INVITATION_FAILURE,
    });
}

export function getFarmAccessInvitationCancel() {
    return (dispatch) => {
        dispatch({ type: authActionTypes.AUTH_ACCESS_GET_INVITATION_CANCEL });
    };
}

// Verify email
export function activateUser(activationToken) {
    ReactGA.event({
        category: "Auth",
        action: "Verify Email",
    });
    return api.put({
        path: `users/${activationToken}`,
        onSuccess: (repsonse) => {
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_USER_ACTIVATION_SUCCESS });
                dispatch(push({ pathname: "/", state: { from: "/pub/activate" } }));
            };
        },
        onFailure: (error) => {
            error.handled = true;
            return (dispatch) => {
                dispatch({ type: authActionTypes.AUTH_USER_ACTIVATION_FAILURE });
                dispatch(push({ pathname: "/", state: { from: "/pub/activate" } }));
            };
        },
    });
}

// Resend activation email
export function resendActivationEmail() {
    return api.put({
        path: `activationToken`,
        onFailure: (error) => {
            if (error.status === 426) {
                error.handled = true;
                return (dispatch) => {
                    dispatch({ type: authActionTypes.AUTH_USER_ACTIVATION_SUCCESS });
                    dispatch(push({ pathname: "/", state: { from: "/pub/activate" } }));
                };
            } else {
                return [];
            }
        },
    });
}
