import * as domain from "common/domain";
import DistributionDetails from "./DistributionDetails";

export default function Distribution({ farm, analysis }) {

    const blocks = analysis.blocks.filter((block) => (block.animals && block.animals.length > 0) || (block.type === domain.BlockType.ProductivePasture && block.runoffCharacteristics) || (block.type === domain.BlockType.ProductiveFruit && block.swardAnimalSource === "EnterpriseStock"));

    const enterpriseTypes = analysis.enterprises.map((e) => e.type);

    const sheep = analysis.enterprises.find((e) => e.type === domain.EnterpriseTypes.Sheep) || {};

    const isMerino = sheep.specificationMethod === "RSU" || (sheep.mobs && sheep.mobs.find((m) => m.sheepBreed === "Merino") ? true : false);

    const distributions = blocks.map((block) => {
        return {
            blockId: block.id,
            relativeProductivity: block.relativeProductivity,
            animals: block.animals.map((animal) => {
                return {
                    ...animal,
                    enterpriseType: analysis.enterprises.find((enterprise) => enterprise.id === animal.enterpriseId).type,
                    specifyGrazingMonths: (animal.months || []).length > 0 ? true : false,
                    pastureRsu: block.currentResults && block.currentResults.pastureResults && block.currentResults.pastureResults.pastureRsu && block.currentResults.pastureResults.pastureRsu[animal.enterpriseId],
                };
            }),
        };
    });

    const viewModel = {
        enterpriseTypes: enterpriseTypes.filter((et) => et !== "OutdoorPigs"),
        hasOutdoorPigs: enterpriseTypes.some((et) => et === "OutdoorPigs"),
        blockIds: blocks.map((block) => block.id),
        distributions: distributions,
        relProdMethod: analysis.animalDistribution ? analysis.animalDistribution.relProdMethod : null,
        blockStockRatios: analysis.animalDistribution ? analysis.animalDistribution.blockStockRatios : "AnimalEquivalent",
    };

    return <DistributionDetails farm={farm} analysis={analysis} viewModel={viewModel} isMerino={isMerino} />;
}
