import React from "react";
import { useParams } from "react-router-dom";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import SnowfallDetails from "./SnowfallDetails";

export default function SnowfallEdit({ farm, analysis }) {
    const { snowfallId } = useParams();
    const snowfall = analysis.snowfalls.find((snowfall) => snowfall.id === snowfallId);

    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.CLIMATE} />
            <TileBody>
                <SnowfallDetails farm={farm} analysis={analysis} snowfall={snowfall} />
            </TileBody>
        </Tile>
    )
}
