import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import * as utils from "common/utils";
import * as FormUtils from "common/FormUtils";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import InputPack from "components/InputPack";
import SelectPack from "components/SelectPack2";
import CheckboxPack from "components/CheckboxPack";
import { useRefData } from "common/hooks";

export default function OutdoorPigFeedingSystem({ outdoorPigs }) {
    const refData = useRefData();
    const { ageGrowersFedFeed: defaultAgeGrowersFedFeed, ageFinishersFedFeed: defaultAgeFinishersFedFeed } = refData.outdoorPigDefaults || {};

    const numbers = outdoorPigs.numbers || {};
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const showGrowersFinishersFields = outdoorPigs.growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    return (
        <Panel title="Feeding system" midBlue notCollapsible>
            <PanelBody>
                <h3 className="u-mt-md">Feeding methods</h3>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th data-width="30"></th>
                                <th>Feeding method</th>
                                <th className="th--shrink">Use pellets</th>
                            </tr>
                        </thead>
                        <tbody>
                            <FieldArray name="feedingSystem.feedingMethods" validate={validateFeedingMethods}>
                                {({ fields }) => {
                                    return fields.map((field, index) => {
                                        const feedingMethod = fields.value[index];

                                        if (feedingMethod.stockClassGroup !== "Growers" && outdoorPigs.growOutUnitOnly) return null;

                                        if (feedingMethod.stockClassGroup === "Growers" && !showGrowersFinishersFields) return null;

                                        const stockClassGroupDisplayText = utils.getOutdoorPigStockClassGroupDisplayText(feedingMethod.stockClassGroup);
                                        return (
                                            <tr key={feedingMethod.stockClassGroup}>
                                                <td className="u-textBold">{stockClassGroupDisplayText}</td>
                                                <td>
                                                    <Field name={`feedingSystem.feedingMethods[${index}].feedingMethod`} placeholder="Select a feeding method" options={refData.outdoorPigFeedingSystems} required component={SelectPack} />
                                                </td>
                                                <td className="u-textCenter">
                                                    <Field name={`feedingSystem.feedingMethods[${index}].usePellets`} type="checkbox" component={CheckboxPack} />
                                                </td>
                                            </tr>
                                        );
                                    });
                                }}
                            </FieldArray>
                        </tbody>
                    </table>
                </div>
                <Grid>
                    <GridCell className="u-width1of4">
                        <Field name="feedingSystem.noBirdLossOccurs" label="No bird loss occurs" type="checkbox" component={CheckboxPack} />
                    </GridCell>
                    {!outdoorPigs.growOutUnitOnly && (
                        <GridCell className="u-width1of4">
                            <Field name="feedingSystem.creepFeedSupplied" label="Creep feed supplied" type="checkbox" component={CheckboxPack} />
                        </GridCell>
                    )}
                </Grid>
                {showGrowersFinishersFields && (
                    <Grid title="Growers & finishers feeding">
                        <GridCell className="u-width1of4">
                            <Field name="feedingSystem.ageGrowersFedFeed" label="Age grower feed fed" uom="days" placeholder={defaultAgeGrowersFedFeed} type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                        </GridCell>
                        <GridCell className="u-width1of4">
                            <Field name="feedingSystem.ageFinishersFedFeed" label="Age finisher feed fed" uom="days" placeholder={defaultAgeFinishersFedFeed} type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                        </GridCell>
                    </Grid>
                )}
            </PanelBody>
        </Panel>
    )
}

export const validateFeedingSystem = (outdoorPigs) => {
    const errors = {};

    const { growOutUnitOnly, numbers = {}, feedingSystem = {} } = outdoorPigs;
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const validateGrowersFinishersFields = growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    if (validateGrowersFinishersFields) {
        errors.ageGrowersFedFeed = FormUtils.validators.max(9999)(feedingSystem.ageGrowersFedFeed);
        errors.ageFinishersFedFeed = FormUtils.validators.max(9999)(feedingSystem.ageFinishersFedFeed);
        errors.ageFinishersFedFeed = errors.ageFinishersFedFeed || (feedingSystem.ageGrowersFedFeed >= feedingSystem.ageFinishersFedFeed ? "Must be greater than Age Grower Feed Fed" : undefined);
    }

    return errors;
}

const validateFeedingMethods = (feedingMethods, outdoorPigs) => {
    const errors = [];

    const { growOutUnitOnly, numbers = {} } = outdoorPigs;
    const hasWeanedNumber = numbers.growersFinishers && numbers.growersFinishers.weanedNumber > 0;
    const hasBroughtIn = numbers.growersFinishers && numbers.growersFinishers.broughtIn > 0;
    const validateGrowersFinishersFields = growOutUnitOnly || hasWeanedNumber || hasBroughtIn;

    feedingMethods &&
        feedingMethods.forEach((feedingMethod) => {
            const error = {};

            const validateGrowers = feedingMethod.stockClassGroup === "Growers" && validateGrowersFinishersFields;
            const validateNonGrowers = feedingMethod.stockClassGroup !== "Growers" && !growOutUnitOnly;
            if (validateGrowers || validateNonGrowers) {
                error.feedingMethod = FormUtils.validators.required(feedingMethod.feedingMethod);
            }

            errors.push(error);
        });

    return errors;
}