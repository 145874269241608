import actionTypes from "./_actions";
import authActionTypes from "containers/Auth/_actions";

function reducer(state = {}, action) {
    switch (action.type) {
        case actionTypes.ADMIN_GET_STATS: {
            return {
                ...state,
                statsLoading: true,
            };
        }
        case actionTypes.ADMIN_GET_STATS_SUCCESS: {
            return {
                ...state,
                stats: action.payload,
                statsLoading: false,
            };
        }
        case actionTypes.ADMIN_GET_STATS_FAILURE: {
            return {
                ...state,
                stats: undefined,
                statsLoading: false,
            };
        }

        case actionTypes.ADMIN_FIND_ACCOUNTS: {
            return {
                ...state,
                ...action.payload,
                accountsLoading: true,
            };
        }
        case actionTypes.ADMIN_FIND_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                accounts: action.payload,
                accountsLoading: false,
            };
        }
        case actionTypes.ADMIN_FIND_ACCOUNTS_CANCEL: {
            return {
                ...state,
                ...action.payload,
                accounts: undefined,
                accountsLoading: false,
            };
        }
        case actionTypes.ADMIN_FIND_ACCOUNTS_FAILURE: {
            return {
                ...state,
                accounts: undefined,
                accountsLoading: false,
            };
        }

        case actionTypes.ADMIN_FIND_FARMS: {
            return {
                ...state,
                ...action.payload,
                farmsLoading: true,
            };
        }
        case actionTypes.ADMIN_FIND_FARMS_SUCCESS: {
            return {
                ...state,
                farms: action.payload,
                farmsLoading: false,
                stats: {
                    ...state.stats,
                    farmCount: action.payload.length,
                },
            };
        }
        case actionTypes.ADMIN_FIND_FARMS_CANCEL: {
            return {
                ...state,
                ...action.payload,
                farms: undefined,
                farmsLoading: false,
            };
        }
        case actionTypes.ADMIN_FIND_FARMS_FAILURE: {
            return {
                ...state,
                farms: undefined,
                farmsLoading: false,
            };
        }

        case actionTypes.ADMIN_GET_PAYMENTS: {
            return {
                ...state,
                paymentsLoading: true,
            };
        }
        case actionTypes.ADMIN_GET_PAYMENTS_SUCCESS: {
            return {
                ...state,
                payments: action.payload,
                paymentsLoading: false,
            };
        }
        case actionTypes.ADMIN_GET_PAYMENTS_CLEAR:
        case actionTypes.ADMIN_GET_PAYMENTS_FAILURE: {
            return {
                ...state,
                payments: undefined,
                paymentsLoading: false,
            };
        }

        case actionTypes.ADMIN_GET_BALANCESUMMARY: {
            return {
                ...state,
                balanceSummaryLoading: true,
            };
        }
        case actionTypes.ADMIN_GET_BALANCESUMMARY_SUCCESS: {
            return {
                ...state,
                balanceSummary: action.payload,
                balanceSummaryLoading: false,
            };
        }
        case actionTypes.ADMIN_GET_BALANCESUMMARY_CLEAR:
        case actionTypes.ADMIN_GET_BALANCESUMMARY_FAILURE: {
            return {
                ...state,
                balanceSummary: undefined,
                balanceSummaryLoading: false,
            };
        }

        case actionTypes.ADMIN_GET_BALANCE: {
            return {
                ...state,
                balanceLoading: true,
            };
        }
        case actionTypes.ADMIN_GET_BALANCE_SUCCESS: {
            return {
                ...state,
                balance: action.payload,
                balanceLoading: false,
            };
        }
        case actionTypes.ADMIN_GET_BALANCE_CLEAR:
        case actionTypes.ADMIN_GET_BALANCE_FAILURE: {
            return {
                ...state,
                balance: undefined,
                balanceLoading: false,
            };
        }

        case actionTypes.ADMIN_GET_ACCOUNT_CLEAR: {
            return {
                ...state,
                account: undefined,
                accountUsers: undefined,
                accountFarms: undefined,
            };
        }
        case actionTypes.ADMIN_GET_ACCOUNT: {
            return {
                ...state,
                accountLoading: true,
            };
        }
        case actionTypes.ADMIN_GET_ACCOUNT_SUCCESS: {
            return {
                ...state,
                account: action.payload,
                accountLoading: false,
            };
        }
        case actionTypes.ADMIN_GET_ACCOUNT_FAILURE: {
            return {
                ...state,
                account: undefined,
                accountLoading: false,
            };
        }
        case authActionTypes.AUTH_CLEAR_STORAGE:
            return {};

        default:
            return state;
    }
}

export default reducer;
