import React from "react";
import { Link, withRouter, matchPath } from "react-router-dom";
import { useReferrer } from "common/hooks";

const TabsItem = ({ id, title, to, exact, location }) => {
    const referrer = useReferrer();
    const path = to.pathname ? to.pathname : to;
    const isActive = matchPath(location.pathname, { path, exact });
    return (
        <li id={id} className={`SubMenu-item ${isActive ? "is-active" : ""}`}>
            <Link to={{ pathname: path, state: { referrer } }}>
                <span>{title}</span>
            </Link>
        </li >
    );
};

export default withRouter(TabsItem);
