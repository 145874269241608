import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import * as animalUtils from "../_utils";
import EnterpriseDetails from "./EnterpriseDetails";

export default function Enterprise({ farm, analysis }) {
    const refData = useRefData();
    const { enterpriseType: enterpriseTypeParam } = useParams();

    const enterpriseTypeRefData = (refData.enterpriseTypes || []).find((rd) => rd.value.toLowerCase() === enterpriseTypeParam.toLowerCase());
    const enterpriseType = enterpriseTypeRefData.value || enterpriseTypeParam;
    const enterpriseName = enterpriseTypeRefData.text;

    let enterprise = analysis.enterprises?.find((enterprise) => enterprise.type.toLowerCase() === enterpriseType.toLowerCase());
    if (enterprise) {
        enterprise.name = enterpriseName;
        if (enterprise.type === "Dairy") {
            enterprise.dairyStockNumbers = enterprise.specificationMethod === "MonthlyStockRec" ? "Dairy" : "DairyPeak";
            enterprise.specifyCalving = enterprise.peakCowNumbers && enterprise.specificationMethod !== "MonthlyStockRec" && enterprise.peakCowNumbers.calvingDetails;
        }
        const parturitionDate = utils.parseDate(`${enterprise.parturitionDay}-${enterprise.parturitionMonth}`, "D-MMM");
        if (parturitionDate) enterprise.parturitionDate = parturitionDate.format("DD MMMM");

        const weaningDate = utils.parseDate(`${enterprise.weaningDay}-${enterprise.weaningMonth}`, "D-MMM");
        if (weaningDate) enterprise.weaningDate = weaningDate.format("DD MMMM");

        (enterprise.mobs || []).forEach((mob) => {
            if (mob.calvingDetails) {
                if (!mob.calvingDetails.midCalvingDate) {
                    const midCalvingDate = utils.parseDate(`${mob.calvingDetails.midCalvingDay}-${mob.calvingDetails.midCalvingMonth}`, "D-MMM");
                    if (midCalvingDate) mob.calvingDetails.midCalvingDate = midCalvingDate.format("DD MMMM");
                }
                if (!mob.calvingDetails.dryDate) {
                    const dryDate = utils.parseDate(`${mob.calvingDetails.dryDay}-${mob.calvingDetails.dryMonth}`, "D-MMM");
                    if (dryDate) mob.calvingDetails.dryDate = dryDate.format("DD MMMM");
                }
            }
            mob.events.forEach((event) => {
                if (!event.eventDate) {
                    const eventDate = utils.parseDate(`${event.day}-${event.month}`, "D-MMM");
                    if (eventDate) event.eventDate = eventDate.format("DD MMMM");
                }
            });
            mob.stockCounts = animalUtils.getStockCountsForMob(mob);
        });
    } else {
        enterprise = {
            id: uuidv4(),
            isNew: true,
            type: enterpriseType,
            name: enterpriseName,
            specificationMethod: "MonthlyStockRec",
        };
    }

    return <EnterpriseDetails farm={farm} analysis={analysis} initialValues={enterprise} />
}
