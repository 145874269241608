import React from "react";

const LoaderSection = ({ message, dark }) => {
    return (
        <div className="u-textCenter">
            <div className={`Loader ${dark ? "Dark" : ""}`}>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </div>
            <h4 className={`${dark ? "" : "u-textWhite"} u-mb-0`}>{message}</h4>
        </div>
    );
};
export default LoaderSection;
