import { Switch } from "react-router-dom";
import ScrollTopRoute from "components/ScrollTopRoute";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import Fertiliser from "./Fertiliser";
import FertiliserApplications from "./Applications";
import DCD from "./DCD/DCD";

export default function FertiliserTab({ farm, analysis }) {
    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.FERTILISER} />
            <TileBody>
                <Switch>
                    <ScrollTopRoute path="/app/farm/:farmId/analysis/:budgetId/fertiliser" exact render={() => <Fertiliser farm={farm} analysis={analysis} />} restoreScrollY />
                    <ScrollTopRoute path="/app/farm/:farmId/analysis/:budgetId/fertiliser/applications" exact render={() => <FertiliserApplications farm={farm} analysis={analysis} />} />
                    <ScrollTopRoute path="/app/farm/:farmId/analysis/:budgetId/fertiliser/dcd" render={() => <DCD farm={farm} analysis={analysis} />} />
                </Switch>
            </TileBody>
        </Tile>
    )
}
