import SelectPack from "components/SelectPack";
import NumericInputPack from "components/NumericInputPack";
import * as utils from "common/utils";
import * as validations from "common/validations";
import SolidApplications from "./SolidApplications";

export default function PondEffluent({ solidManagement, solidsManagement, validate, validation, onChange, type, activeControls, cancelApplication, saveApplication, deleteApplication, modalInlineClose, modalInlineOpen, analysis, confirm, isFirst }) {
    if (!activeControls[`${type}_pondHeader`]) return null;
    return (
        <div className={`Grid Grid--withGutter ${isFirst ? "" : "u-mt-lg"}`}>
            <div className="Grid-cell u-md-width2of3 u-lg-width1of2">
                <h3>Pond sludge</h3>
                <div className="Field-group u-mt-md">
                    <SelectPack meta={{ nrf: true }} isHidden={!activeControls[`${type}_pondDisposalMethod`]} onChange={(e) => onChange(e, { type: type, key: "pondDisposalMethod" })} value={solidManagement.pondDisposalMethod} val={validation[`${type}_pondDisposalMethod`]} dataWidth="50" id={`${type}_pondDisposalMethod`} label="Pond sludge management" requiredLabel={true}>
                        <option value="" disabled={true}>
                            Select how pond sludge is managed
                        </option>
                        {utils.mapRefDataItems(solidsManagement)}
                    </SelectPack>
                    <NumericInputPack name="pondFrequency" label="Pond emptied every" isHidden={!activeControls[`${type}_pondFrequency`]} uom="years" requiredLabel={true} val={validation[`${type}_pondFrequency`]} dataWidth="50" onChange={(e) => onChange(e, { type: type, key: "pondFrequency" })} decimalPlaces={0} id={`${type}_pondFrequency`} value={solidManagement.pondFrequency} />
                </div>
            </div>
            {activeControls[`${type}_pondApplications`] && (
                <div className="Grid-cell">
                    <SolidApplications validation={validation} cancelApplication={cancelApplication} saveApplication={(application, dirty) => saveApplication(application, dirty)} deleteApplication={(id) => deleteApplication(id)} modalInlineClose={modalInlineClose} modalInlineOpen={modalInlineOpen} confirm={confirm} blocks={analysis.blocks.filter((b) => b.type !== "ProductiveOutdoorPigs" && (b.type.startsWith("Productive") || b.type === "FodderCrop"))} title="Application of pond sludge effluent on blocks" type={type} prefix="pond" applications={solidManagement.pondApplications || []} validate={validate} />
                </div>
            )}
        </div>
    )
}

export function validatePondEffluent(activeControls, validation, solidManagement, currentValidation, source, setVal, type) {
    let message = undefined;
    let key = undefined;
    let objkey = undefined;

    objkey = "pondDisposalMethod";
    key = `${type}_${objkey}`;
    if (activeControls[key]) {
        message = validations.required(solidManagement[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }

    objkey = "pondFrequency";
    key = `${type}_${objkey}`;
    if (activeControls[key]) {
        message = validations.requiredExt(solidManagement[objkey]);
        message = message || validations.isNumeric(solidManagement[objkey]);
        message = message || validations.range(0, 15)(solidManagement[objkey]);
        validation[key] = setVal(key, currentValidation, source, message);
    }

    objkey = "pondApplications";
    key = `${type}_${objkey}`;
    if (activeControls[key]) {
        const { pondApplications = [] } = solidManagement;
        /* eslint-disable no-unused-vars */
        for (const application of pondApplications) {
            if (!application.months || !application.months.length > 0) {
                key = `monthGroupVal_${application.id}`;
                validation[key] = setVal(key, currentValidation, source, "Required");
            }
        }
        if (solidManagement.pondFrequency <= 1 && pondApplications.length === 0) {
            key = `${type}_applications`;
            validation[key] = setVal(key, currentValidation, source, "Required");
        }
    }
}
