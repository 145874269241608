import Alert from "components/Alert";
import { FileSummary } from "components/PlanningReport";
import StructuresEffluentSummary from "./StructuresEffluentSummary";

export default function StucturesEffluent({ farm, before, after, showFileComparison }) {
    const hasStuctures = (budget) => {
        const { structures = [], enterprises = [] } = budget;
        const hasDairy = enterprises.some((e) => ["Dairy", "DairyGoat"].includes(e.type));
        return structures.length > 0 || (hasDairy && budget.effluentSystem);
    }

    const hasData = hasStuctures(after.details) || (before && hasStuctures(before.details));
    if (!hasData) {
        return (
            <div className="u-pt-lg">
                <Alert type="info" text="The is no stucture/effluent information for this analysis" />
            </div>
        )
    }

    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <StructuresEffluentSummary farm={farm} before={before} after={after}  />
        </div>
    )
}
