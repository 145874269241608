import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import * as domain from "common/domain";
import { Grid, GridCell } from "components/Grid";
import BlockSelector from "components/BlockSelector/BlockSelector";
import SelectPack from "components/SelectPack2";
import CheckboxPack from "components/CheckboxPack";
import InputPack from "components/InputPack";
import Alert from "components/Alert";
import { useRefData } from "common/hooks";

export default function PastureFormFields({ form, values }) {
    const refData = useRefData();

    const PRIMARY_LAND_USE_OPTIONS = [
        { value: "CutAndCarry", text: "Cut and carry - producing supplements" },
        { value: "Pastoral", text: "Pastoral - grazing animals" },
    ];

    const puggingTips = values.runoffCharacteristics && domain.PuggingTips[values.runoffCharacteristics.pugOccurence];

    const blocksWithPasture = values.availableBlocks.filter((b) => b.pasture && b.pasture.pastureCategory);
    const blocksWithoutPasture = values.availableBlocks.filter((b) => b.pasture && !b.pasture.pastureCategory);
    const availableBlockGroups = [
        {
            label: "Blocks missing pasture details",
            blocks: blocksWithoutPasture,
            icon: (
                <span className="Todo Todo--error">
                    <i className="icon icon-alert u-textError" />
                </span>
            ),
        },
        { label: "Blocks with existing pasture details", blocks: blocksWithPasture },
    ];

    return (
        <>
            <Field name="blockIds" title="Blocks" availableBlockGroups={availableBlockGroups} showBlockArea component={BlockSelector} />

            <Grid title="Land use">
                <GridCell className="u-width1of3">
                    <Field name="pastureCategory" label="Pasture type" placeholder="Select a pasture type" options={refData.pastureTypes} required component={SelectPack} />
                    {values.pastureCategory === "Plantain" && <Alert type="info" text="Plantain pastures should be selected if it is a variety that causes a (1) diuretic response in animals and (2) partitions nitrogen away from urine in the animal. Other beneficial effects are not currently represented. More information on the effects currently captured in OverseerFM is available in the knowledgebase. Overseer does not verify seed variety claims." />}
                    <Field name="pastureCultivated" label="Cultivated in the last 5 years" type="checkbox" component={CheckboxPack} />
                </GridCell>
                {values.pastureCategory === "Plantain" && (
                    <GridCell className="u-width1of3">
                        <Field name="plantainPercentage" label="Percentage of plantain" placeholder="Enter percentage of plantain in the pasture (between 5% and 100%)" required component={InputPack} format={FormUtils.formatters.formatDecimal(0)} formatOnBlur />
                        <Alert type="info" text="When plantain is selected as a pasture type, the remaining percentage will be modelled as Ryegrass/white clover." />
                    </GridCell>
                )}
                <GridCell className="u-width1of3">
                    <Field name="primaryLandUse" label="Primary land use" placeholder="Select a primary land use" onChange={onPrimaryLandUseChange(form)} options={PRIMARY_LAND_USE_OPTIONS} required component={SelectPack} />
                </GridCell>
            </Grid>

            {values.hasAnimals && (
                <Grid title="Runoff characteristics">
                    <GridCell className="u-width1of2">
                        <Field name="runoffCharacteristics.hydrophobicCondition" label="Hydrophobic condition" placeholder="Select how the soil reacts to rain" options={refData.hydrophobicity} required component={SelectPack} tip="Hydrophobicity is normally observed on dry soils and results in more run-off and surface ponding after rainfall than when soils are wet. The default value is based on the region and rainfall and should be used unless you have a godd reason to change it." />
                        <Field name="runoffCharacteristics.waterTablePresent" label="Naturally high water table (<0.75m from surface in water, not perched)" type="checkbox" component={CheckboxPack} tip="Is used in the wetland model. Has no affect on hydrology and hence losses from the block." />
                        <Field name="runoffCharacteristics.isCompacted" label="Is compacted" type="checkbox" component={CheckboxPack} tip="Soil is compacted severely enough to reduce water holding capacity." />
                    </GridCell>
                    <GridCell className="u-width1of2">
                        <Field name="runoffCharacteristics.pugOccurence" label="Susceptibility to pugging" placeholder="Select the susceptibility to pugging or treading damage" options={refData.pugOccurence} required component={SelectPack} tip="This is not whether pugging occurs or the degreee of pugging but rather the propensity for pugging. This is used in assessing soil drainage and estimating denitrifcation." />
                        {puggingTips && <Alert type="info" text={puggingTips.text} bold={puggingTips.bold} />}
                    </GridCell>
                    <GridCell></GridCell>
                </Grid>
            )}
        </>
    )
}

const onPrimaryLandUseChange = (form) => (e, primaryLandUse) => {
    const hasAnimals = primaryLandUse === "Pastoral";
    form.change("hasAnimals", hasAnimals);
    if (hasAnimals) {
        form.change("runoffCharacteristics.hydrophobicCondition", "Usedefault");
    } else {
        form.change("runoffCharacteristics", undefined);
    }
}

export const validatePastureFormFields = (viewModel) => {
    const error = {};
    error.blockIds = FormUtils.validators.required(viewModel.blockIds);
    error.pastureCategory = FormUtils.validators.required(viewModel.pastureCategory);
    error.primaryLandUse = FormUtils.validators.required(viewModel.primaryLandUse);
    if (viewModel.hasAnimals) {
        error.runoffCharacteristics = {};
        error.runoffCharacteristics.hydrophobicCondition = FormUtils.validators.required(viewModel.runoffCharacteristics) || FormUtils.validators.required(viewModel.runoffCharacteristics.hydrophobicCondition);
        error.runoffCharacteristics.pugOccurence = FormUtils.validators.required(viewModel.runoffCharacteristics) || FormUtils.validators.required(viewModel.runoffCharacteristics.pugOccurence);
    }
    if (viewModel.pastureCategory === "Plantain") {
        error.plantainPercentage = FormUtils.validators.required(viewModel.plantainPercentage) || FormUtils.validators.range(5, 100)(viewModel.plantainPercentage);
    }
    return error;
}
