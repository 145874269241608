export default function ConfirmationField({ input, confirmationPhrase = "CONFIRM" }) {
    const handleOnChange = (e) => {
        const inputValue = e.target.value;
        input.onChange(inputValue.toLowerCase() === confirmationPhrase.toLowerCase());
    };
    return (
        <div className="Confirmation-field">
            <label htmlFor={input.name} className="Confirmation-label">
                Type <strong>{confirmationPhrase.toUpperCase()}</strong> to confirm
            </label>
            <input id={input.name} data-test-id={`${input.name}-phrase`} type="text" className="Confirmation-input" onChange={handleOnChange} />
            <input {...input} data-test-id={input.name} className="Checkbox-input u-hidden" type="checkbox" />
        </div>
    );
}
