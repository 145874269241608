
export default function Spinner({ tiny, small, dark, results }) {
    return (
        <div className={`Loader ${dark ? "Loader--dark" : ""} ${tiny ? "Loader--tiny" : ""} ${small ? "Loader--small" : ""} ${results ? "Loader--results" : ""}`}>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
        </div>
    )
}
