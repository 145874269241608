import React from "react";

const ExistingWarning = ({ viewModel }) => {
    return viewModel.existing ? (
        <div className="Grid-cell">
            <div className="Alert Alert--info">
                <div>
                    <b>Existing {viewModel.fertiliser.material}</b> <span>- This product has already been used within this budget. Applications will be merged on save</span>
                </div>
            </div>
        </div>
    ) : null;
};

const GlobalCopy = ({ viewModel }) => {
    return viewModel.globalSelect ? (
        <div className="Grid-cell ">
            <div className="Alert Alert--info">
                <div>Nutrient details have been copied from the global nutrient fertiliser make up. Editing these nutrients within the analysis will only edit the fertiliser for the analysis - not the global fertiliser</div>
            </div>
        </div>
    ) : null;
};

export { ExistingWarning, GlobalCopy };
