import { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Link } from "react-router-dom";
import SavePrompt from "components/SavePrompt";
import Alert from "components/Alert";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import RadioGroupPack from "components/RadioGroupPack";
import FarmLevel from "./FarmLevel";
import BlockLevel from "./BlockLevel";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { Button } from "components/Button";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function DCD({ farm, analysis }) {
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    let viewModel = {};
    if (hasFarmLevelDCD(analysis)) {
        viewModel = getFarmLevelViewModel(analysis);
    } else {
        viewModel = getBlockLevelViewModel(analysis);
    }

    return <DCDClassComponent farm={farm} analysis={analysis} viewModel={viewModel} updateAnalysisAsync={updateAnalysisAsync} />
}

class DCDClassComponent extends Component {
    constructor(props) {
        super(props);
        const { farm, analysis, viewModel } = props;

        this.state = {
            referrer: `/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser`,
            pastoralBlocksOnFarm: (analysis.blocks || []).filter((b) => b.type === "ProductivePasture" && (b.animals || []).length > 0),
            enterprisesOnFarm: analysis.enterprises || [],
            viewModel,
        };
    }

    levelChange = (form) => (e) => {
        const { analysis } = this.props;
        if (isFarmLevel(e.target.value)) {
            const viewModel = getFarmLevelViewModel(analysis);
            form.change("applications", viewModel.applications);
            form.change("nFertiliserAppliedWithinSevenDays", viewModel.nFertiliserAppliedWithinSevenDays);
            form.change("longTermFactor", viewModel.longTermFactor);
            form.change("enterpriseIds", viewModel.enterpriseIds);
        } else {
            const viewModel = getBlockLevelViewModel(analysis);
            form.change("applications", viewModel.applications);
        }
    };

    save = async (viewModel) => {
        let nitrateInhibitors = [];

        if (isFarmLevel(viewModel.level)) {
            const { applications, enterpriseIds, nFertiliserAppliedWithinSevenDays, longTermFactor } = viewModel;
            const nitrateInhibitor = {
                id: uuidv4(),
                applications: applications.map((application) => {
                    return {
                        id: uuidv4(),
                        months: [{ month: application.month, reportingYear: true }],
                        rotationLength: application.rotationLength,
                    };
                }),
                enterpriseIds,
                nFertiliserAppliedWithinSevenDays,
                longTermFactor,
            };
            nitrateInhibitors.push(nitrateInhibitor);
        } else if (isBlockLevel(viewModel.level)) {
            nitrateInhibitors = viewModel.applications.map((application) => {
                const { id, blockIds, months, nFertiliserAppliedWithinSevenDays, longTermFactor } = application;
                const nitrateInhibitor = {
                    id: uuidv4(),
                    applications: [
                        {
                            id,
                            blockIds: blockIds,
                            months: months.map((m) => ({ month: m, reportingYear: true })),
                        },
                    ],
                    nFertiliserAppliedWithinSevenDays,
                    longTermFactor,
                };
                return nitrateInhibitor;
            });
        }

        const updatedAnalysis = {
            ...this.props.analysis,
            nitrateInhibitors
        };
        await this.props.updateAnalysisAsync(updatedAnalysis);
    };

    render() {
        const hasFarmLevel = hasFarmLevelDCD(this.props.analysis);
        const levelOptions = getLevelOptions(this.props.analysis);
        return (
            <Form initialValues={this.state.viewModel} mutators={{ ...arrayMutators }} onSubmit={this.save}>
                {(formProps) => {
                    const { form, values, handleSubmit, submitting, submitSucceeded, dirty } = formProps;
                    const disableSave = !values.applications || values.applications.length === 0;
                    return (
                        <form onSubmit={handleSubmit}>
                            {true && <SavePrompt blockIf={dirty && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={this.state.referrer} />}
                            <Panel title="DCD - Nitrification Inhibitors" waiting={submitting} referrer={this.state.referrer}>
                                <Alert type="info" text="DCD reduces the amount of nitrous oxide emitted and N leached. When applied to specified pastoral blocks it only affects the urine N leaching and nitrous oxide emissions from animals grazing those blocks for the months specified." />
                                <PanelBody waiting={submitting}>
                                    {hasFarmLevel && (
                                        <Grid className="u-mt-md u-mb-lg">
                                            <GridCell>
                                                <Field name="level" label="How is DCD applied on your farm?" options={levelOptions} value={values.level} type="radio" component={RadioGroupPack} inline onChange={this.levelChange(form)} />
                                            </GridCell>
                                        </Grid>
                                    )}
                                    {isFarmLevel(values.level) && <FarmLevel form={form} viewModel={values} enterprisesOnFarm={this.state.enterprisesOnFarm} values={values} />}
                                    {isBlockLevel(values.level) && <BlockLevel form={form} viewModel={values} pastoralBlocksOnFarm={this.state.pastoralBlocksOnFarm} values={values} />}
                                </PanelBody>
                                <PanelFooter>
                                    <div className="ButtonBar ButtonBar--fixed">
                                        <div className="ButtonBar-left">
                                            <Link to={this.state.referrer} id="cancel-button" className="Button Button--secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className="ButtonBar-right">
                                            <Button submit primary id="save-button" waiting={submitting} disabled={disableSave}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </PanelFooter>
                            </Panel>
                        </form>
                    );
                }}
            </Form>
        )
    }
}

const LEVEL_OPTIONS = [
    { value: "Block", text: "Applied to specified pastoral blocks" },
    { value: "Farm", text: "Applied for specified animal enterprises" },
];

const getLevelOptions = (analysis) => {
    if (!hasFarmLevelDCD(analysis)) return LEVEL_OPTIONS.filter((option) => !isFarmLevel(option.value));

    return LEVEL_OPTIONS;
}

const hasFarmLevelDCD = (analysis) => {
    const nitrateInhibitors = analysis.nitrateInhibitors || [];
    const hasDCDAppliedToEnterprises = nitrateInhibitors.some((ni) => ni.enterpriseIds && ni.enterpriseIds.length > 0);
    return nitrateInhibitors.length === 1 && hasDCDAppliedToEnterprises;
}

const hasBlockLevelDCD = (analysis) => {
    const nitrateInhibitors = analysis.nitrateInhibitors || [];
    const hasDCDAppliedToEnterprises = nitrateInhibitors.some((ni) => ni.enterpriseIds && ni.enterpriseIds.length > 0);
    return nitrateInhibitors.length >= 1 && !hasDCDAppliedToEnterprises;
}

const getFarmLevelViewModel = (analysis) => {
    const hasFarmLevel = hasFarmLevelDCD(analysis);

    const nitrateInhibitor = (analysis.nitrateInhibitors || [])[0] || {};

    let enterpriseIds = nitrateInhibitor.enterpriseIds || [];
    if (!hasFarmLevel || enterpriseIds.length === 0) enterpriseIds = (analysis.enterprises || []).map((e) => e.id);

    let applications = (nitrateInhibitor.applications || []).map((a) => {
        return { month: a.months[0].month, rotationLength: a.rotationLength };
    });
    if (!hasFarmLevel || applications.length === 0) applications = [{ month: "January" }];

    const viewModel = {
        level: LEVEL_OPTIONS[1].value,
        nFertiliserAppliedWithinSevenDays: nitrateInhibitor.nFertiliserAppliedWithinSevenDays,
        longTermFactor: nitrateInhibitor.longTermFactor || 100,
        enterpriseIds,
        applications,
    };
    return viewModel;
}

const getBlockLevelViewModel = (analysis) => {
    const hasBlockLevel = hasBlockLevelDCD(analysis);

    const nitrateInhibitors = analysis.nitrateInhibitors || [];
    const applications = nitrateInhibitors.reduce((results, nitrateInhibitor) => {
        (nitrateInhibitor.applications || []).forEach((a) => {
            const application = {
                id: a.id,
                blockIds: (a.blockIds || []).map((id) => id),
                months: (a.months || []).map((m) => m.month),
                nFertiliserAppliedWithinSevenDays: nitrateInhibitor.nFertiliserAppliedWithinSevenDays,
                longTermFactor: nitrateInhibitor.longTermFactor,
            };
            results.push(application);
        });
        return results;
    }, []);

    const viewModel = {
        level: LEVEL_OPTIONS[0].value,
        applications: hasBlockLevel ? applications : [],
    };
    return viewModel;
}

const isFarmLevel = (level) => {
    return level === LEVEL_OPTIONS[1].value;
}

const isBlockLevel = (level) => {
    return level === LEVEL_OPTIONS[0].value;
}
