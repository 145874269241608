import React from "react";
import { compose, withActions, withState, withAppContext } from "containers/HOC";
import PaymentList from "components/PaymentList";
import { getPaymentsByMonthYear, getRecentPayments } from "../_actions";

class PaymentTransactions extends React.Component {
    state = {};

    _getPayments(yearMonth, accountId) {
        this.setState({ yearMonth, accountId });
        const { getPaymentsByMonthYear } = this.props;
        getPaymentsByMonthYear(yearMonth, accountId);
    }

    _getRecentPayments(lastCount, accountId) {
        this.setState({ lastCount, accountId });
        const { getRecentPayments } = this.props;
        getRecentPayments(lastCount, accountId);
    }

    render() {
        const { authContext, payments, paymentsLoading } = this.props;
        return (
            <PaymentList getPaymentsByMonthYear={(a, b) => this._getPayments(a, b)} getRecentPayments={(a, b) => this._getRecentPayments(a, b)} authContext={authContext} payments={payments} recentPaymentCount={200} paymentsLoading={paymentsLoading} />
        );
    }
}

export default compose(
    withAppContext,
    withActions({ getPaymentsByMonthYear, getRecentPayments }),
    withState((state) => {
        return {
            payments: state.admin.payments,
            paymentsLoading: state.admin.paymentsLoading,
        };
    })
)(PaymentTransactions);
