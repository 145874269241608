import { connect } from "react-redux";
import { toggleQuickTips } from "containers/App/_actions";

export const compose = (...funcs) => {
    if (funcs.length === 0) {
        return (arg) => arg;
    }

    if (funcs.length === 1) {
        return funcs[0];
    }

    return funcs.reduce(
        (a, b) =>
            (...args) =>
                a(b(...args))
    );
};

export const withState = (mapStateToProps) => (WrappedComponent) => {
    const WithState = (props) => {
        return <WrappedComponent {...props} />;
    };

    return connect(mapStateToProps)(WithState);
};

export const withActions = (mapDispatchToProps) => (WrappedComponent) => {
    const WithActions = (props) => {
        return <WrappedComponent {...props} />;
    };

    return connect(null, mapDispatchToProps)(WithActions);
};

export const withAppContext = (WrappedComponent) => {
    const WithAppContext = (props) => {
        return <WrappedComponent {...props} />;
    };

    return compose(
        withState((state) => {
            const authContext = {
                isAuthenticated: state.auth.isAuthenticated,
                isActivated: state.auth.isActivated,
                isSystemAdmin: state.auth.isSystemAdmin,
                creditLimit: state.auth.creditLimit,
                isAdmin: state.auth.isAdmin,
            };

            if (authContext.isAuthenticated) {
                authContext.accountId = state.auth.accountId;
                authContext.accountName = state.auth.accountName;
                authContext.accountType = state.auth.accountType;
                authContext.userId = state.auth.userId;
                authContext.fullName = state.auth.fullName;
                authContext.email = state.auth.email;
                authContext.canChangePassword = state.auth.canChangePassword;
                authContext.canBePublishedTo = state.auth.canBePublishedTo;
                authContext.canPublishToMPIWholeFarmDataProject = state.auth.canPublishToMPIWholeFarmDataProject;
                authContext.canCreateFertiliserRecommendations = state.auth.canCreateFertiliserRecommendations;
                authContext.orgApiAccess = state.auth.orgApiAccess;
                authContext.isStudent = state.auth.accountType === "Student";
            }

            return {
                version: state.app.version,
                online: state.app.online,
                offline: !state.app.online,
                isLoading: state.app.isLoading || state.app.isModalLoading,
                isSaving: state.app.isSaving,
                outcome: state.app.outcome,
                location: state.router.location,
                refData: state.app.refData,
                showQuickTips: state.app.showQuickTips,
                isEd: state.app.refData.deploymentType === "Education",
                authContext,
            };
        }),
        withActions({ toggleQuickTips })
    )(WithAppContext);
};
