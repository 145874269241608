import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import Alert from "components/Alert";
import SelectPack from "components/SelectPack2";
import InputPack from "components/InputPack";
import Button from "components/Button/Button";
import { useRefData } from "common/hooks";

export default function TransportActivities({ form, values }) {
    const refData = useRefData();

    const _addActivity = () => {
        form.mutators.push("transportActivities", {});
    };

    const _removeActivity = (index) => {
        const results = values.transportActivities.reduce((acc, cur, i) => {
            if (i !== index) acc.push(cur);
            return acc;
        }, []);
        form.change("transportActivities", results);
    };

    const _hasActivities = (values.transportActivities || []).filter((ta) => !ANIMALS_SOLD_ACTIVITIES.includes(ta.activity)).length > 0;

    const _hasAllActivities = (values.transportActivities || []).length === (refData.ghgTransportActivities || []).length;

    const _addActivityLink = !_hasAllActivities && (
        <span id="add-transport-activity-link" className="u-link IconLink--arrow-plus" onClick={_addActivity}>
            Add override
        </span>
    );

    return (
        <Tile title="Fertilier, supplement, and animal transportation" actions={_addActivityLink} padTop tertiary>
            <Alert type="info" text="The transport of fertilisers, supplements, and animals is assumed to be done by contractors. The fuel used is calculated using default transportation distances. Use this section to override any of these default transportation distances." />
            <TileBody>
                <div className="Table u-mt-md">
                    <table>
                        <thead>
                            <tr>
                                <th>Activity</th>
                                <th className="th--shrink">Distance transported (km)</th>
                                {_hasActivities && <th className="th--shrink">&nbsp;</th>}
                            </tr>
                        </thead>
                        {!_hasActivities && (
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                        <div className="Tile-body">
                                            <div className="Tile-body-message">
                                                <h3 className="u-mt-md">This analysis is currently using the default transportation distances for the transport of fertilisers, supplements and animals</h3>
                                                <Button id="add-transport-activity-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={_addActivity}>
                                                    Add override
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {_hasActivities && (
                            <tbody>
                                <FieldArray name="transportActivities" validate={_validate}>
                                    {({ fields }) => {
                                        return fields.map((field, i) => {
                                            // Just in case someone already using the animals sold optinos before we turned them off.
                                            const transportActivity = fields.value[i];
                                            if (transportActivity && ANIMALS_SOLD_ACTIVITIES.includes(transportActivity.activity)) return null;

                                            const activityOptions = _getActivityOptions(refData, fields.value, i);

                                            return (
                                                <tr key={field}>
                                                    <td>
                                                        <Field name={`${field}.activity`} component={SelectPack} options={activityOptions} placeholder="Select an activity" />
                                                    </td>
                                                    <td>
                                                        <Field name={`${field}.distanceTransportedInKilometres`} type="text" label="" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />
                                                    </td>
                                                    <td>
                                                        <span id={`delete-transport-activity-${i}`} className="a u-link IconLink--trash" onClick={() => _removeActivity(i)}></span>
                                                    </td>
                                                </tr>
                                            );
                                        });
                                    }}
                                </FieldArray>
                            </tbody>
                        )}
                    </table>
                </div>
            </TileBody>
        </Tile>
    )
}

const FERTILISER_TRANSPORT_OPTIONS = ["Fertiliser", "Lime", "OrganicMaterial"];
const SUPPLEMENT_TRANSPORT_OPTIONS = ["HaySilages", "OtherSupplements"];
const ANIMALS_SOLD_ACTIVITIES = ["AnimalsSoldToStore", "AnimalsSoldToWorks"];

const _getActivityOptions = (refData, selectedActivities, index) => {
    const options = (refData.ghgTransportActivities || [])
        .filter((option) => !ANIMALS_SOLD_ACTIVITIES.includes(option.value))
        .reduce((results, option) => {
            const isSelected = (selectedActivities || []).some((selectedActivity, i) => i !== index && selectedActivity.activity === option.value);
            if (!isSelected) {
                results.push({
                    ...option,
                    groupIndex: FERTILISER_TRANSPORT_OPTIONS.some((o) => o === option.value) ? 0 : SUPPLEMENT_TRANSPORT_OPTIONS.some((o) => o === option.value) ? 1 : 2,
                    groupLabel: FERTILISER_TRANSPORT_OPTIONS.some((o) => o === option.value) ? "Fertiliser transport" : SUPPLEMENT_TRANSPORT_OPTIONS.some((o) => o === option.value) ? "Supplement transport" : "Animal transport",
                });
            }
            return results;
        }, []);
    return options;
}

const _validate = (values) => {
    const errorArray = [];

    values &&
        values.forEach((value) => {
            const errors = {};

            errors.activity = FormUtils.validators.required(value.activity);

            errors.distanceTransportedInKilometres = FormUtils.validators.required(value.distanceTransportedInKilometres);
            errors.distanceTransportedInKilometres = errors.distanceTransportedInKilometres || FormUtils.validators.range(1, 9999)(value.distanceTransportedInKilometres);

            errorArray.push(errors);
        });

    return errorArray;
}

