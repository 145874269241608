import React from "react";
import { compose, withAppContext } from "containers/HOC";
import { Form, Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import { valueToText } from "common/utils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import SelectField from "components/FormFields/SelectField";
import { getAnalysisOptions } from "containers/FarmHome/_utils";

const AnalysisComparisonModal = ({ farm, excludedAnalysisIds = [], onCompare, refData, close }) => {
    const options = getAnalysisOptions(farm, excludedAnalysisIds);

    const validate = (viewModel) => {
        const validation = {};
        validation.analysisId = FormUtils.validators.required(viewModel.analysisId);
        return validation;
    };

    const submit = (closeModal) => (viewModel) => {
        onCompare(viewModel.analysisId);
        closeModal();
    };

    return (
        <Form initialValues={{}} validate={validate} onSubmit={submit(close)}>
            {({ values, handleSubmit, submitting }) => {
                const selectedOption = options.find((o) => o.value === values.id);
                const label = !selectedOption ? "With" : selectedOption.type === "Publication" ? "With publication" : `With ${valueToText(refData.budgetTypes, selectedOption.type)} analysis`;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Compare with existing analysis" close={close} submitting={submitting} fluid>
                            <ModalBody>
                                <Field name="analysisId" label={label} placeholder="Select an analysis to compare with" options={options} required component={SelectField} />
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" primary submit>
                                        Compare
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
};

export default compose(withAppContext)(AnalysisComparisonModal);
