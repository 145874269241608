import React from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { getAllFarmGroups } from "../_actions";

const FarmGroups = ({ getAllFarmGroups }) => {
    const [viewModel, setViewModel] = React.useState();

    React.useEffect(() => {
        let isMounted = true;

        async function fetchData(getAllFarmGroups, setViewModel) {
            try {
                const farmGroups = await getAllFarmGroups();
                if (isMounted) {
                    setViewModel(farmGroups);
                }
            } catch {}
        }

        fetchData(getAllFarmGroups, setViewModel);
        return () => {
            isMounted = false;
        };
    }, [getAllFarmGroups]);

    return (
        <Panel>
            <PanelBody loading={!viewModel} waiting={!viewModel}>
                <SortableTable data={viewModel} defaultSortCriteria={{ fieldName: "createdOn", fieldType: "date", desc: true }}>
                    {({ data, sortCriteria, handleSort }) => {
                        return (
                            <table>
                                <thead>
                                    <tr>
                                        <SortableTableHeader label="Name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Created" fieldName="createdOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                        <SortableTableHeader label="Pending invites" fieldName="numInvited" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                        <SortableTableHeader label="Members" fieldName="numMembers" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((farmGroup) => {
                                        return (
                                            <tr key={farmGroup.id} className="hover">
                                                <td>
                                                    <Link to={`/app/farmGroup/${farmGroup.id}/aggregated`} id={farmGroup.id} className="u-textBold">
                                                        {farmGroup.name}
                                                    </Link>
                                                </td>
                                                <td>{utils.dateOnly(farmGroup.createdOn)}</td>
                                                <td>{farmGroup.numInvited}</td>
                                                <td>{farmGroup.numMembers}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        );
                    }}
                </SortableTable>
            </PanelBody>
        </Panel>
    );
};

export default compose(withAppContext, withActions({ getAllFarmGroups }))(FarmGroups);
