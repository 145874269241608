import React from "react";
import { compose, withAppContext, withState } from "containers/HOC";
import Alert from "components/Alert";
import Spinner from "components/Spinner/Spinner";

const ModalBody = ({ children, online, modalError, loading }) => {
    return (
        <div className="Modal-body">
            {online && modalError && <Alert className="u-mt-md" type="error" text={modalError} />}
            {loading && (
                <div className="Tile-body-message">
                    <Spinner dark />
                    <p className="lead">Loading...</p>
                </div>
            )}
            {!loading && children}
        </div>
    );
};

export default compose(
    withAppContext,
    withState((state) => {
        return { modalError: state.app.modalError };
    })
)(ModalBody);
