import { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import { FieldArray } from "react-final-form-arrays";
import * as domain from "common/domain";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import MonthSelector from "./MonthSelector";
import Button from "components/Button/Button";
import { useBlockLevelApplicationModal } from "./BlockLevelApplicationModal";

export default function BlockLevelApplications(props) {
    const { form, applications, availableBlocks, className, values } = props;
    const [blockLevelApplicationModal, openBlockLevelApplicationModal] = useBlockLevelApplicationModal();

    const selectedBlockIds = applications.reduce((results, application) => results.concat(application.blockIds), []);
    const unselectedBlocks = availableBlocks.filter((b) => !selectedBlockIds.includes(b.id));
    const allBlocksAlreadyHaveApplications = unselectedBlocks.length === 0;
    const addApplicationLink = !allBlocksAlreadyHaveApplications && (
        <span id="add-application-link" className="u-link IconLink--arrow-plus" onClick={addApplication(form, unselectedBlocks, openBlockLevelApplicationModal)}>
            Add application
        </span>
    );

    return (
        <>
            <Panel title="DCD Applications - by blocks" actions={addApplicationLink} className={className} midBlue>
                <Alert type="info" text="DCD can be applied up to 3 times per year. Typically, DCD is applied twice per year in April/May and July/August or March and April/May in Southland when grazing off in winter occurs." />
                <PanelBody>
                    {applications.length === 0 && (
                        <div className="Table u-mt-md">
                            <div className="Tile-body">
                                <div className="Tile-body-message">
                                    <Button id="add-application-button" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={addApplication(form, unselectedBlocks, openBlockLevelApplicationModal)}>
                                        Add application
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {applications.length > 0 && (
                        <>
                            <FieldArray name="applications">
                                {({ fields }) => {
                                    return fields.map((field, applicationIndex) => {
                                        const application = fields.value[applicationIndex];
                                        const { blockIds = [] } = application;
                                        const pastoralBlockIcon = utils.getBlockIcon(domain.BlockType.ProductivePasture);
                                        const selectedBlocks = availableBlocks.filter((b) => blockIds.includes(b.id));
                                        const selectedBlockNames = selectedBlocks.map((b) => b.name).join(" | ");

                                        const monthsFieldName = `applications[${applicationIndex}].months`;
                                        const monthsFieldState = form.getFieldState(monthsFieldName);
                                        const monthsAreInvalid = monthsFieldState && monthsFieldState.touched && (monthsFieldState.error || []).length > 0;

                                        return (
                                            <Fragment key={application.id}>
                                                <ul className="DataWidthTable FarmTable">
                                                    <li className="FarmTable-title">
                                                        <div className="FarmTitle">
                                                            <img className="FarmTitle-icon" src={pastoralBlockIcon} alt="Block" />
                                                            <div className="FarmTitle-heading">
                                                                <span className="FarmTitle-name" title={selectedBlockNames}>
                                                                    {selectedBlockNames}
                                                                </span>
                                                                <div className="FarmTitle-sub"></div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="FarmTable-supplementary">
                                                    <div className="Table">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th data-width="10"></th>
                                                                    {domain.farmYear.map((month) => (
                                                                        <th className="u-textCenter" key={month}>
                                                                            {month.substring(0, 3)}
                                                                        </th>
                                                                    ))}
                                                                    <th className="th--shrink">&nbsp;</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className={monthsAreInvalid ? "In-error" : ""}>
                                                                    <td>
                                                                        <ActionLink onClick={editApplication(form, application, applicationIndex, selectedBlocks, unselectedBlocks, openBlockLevelApplicationModal)}>{`Application ${applicationIndex + 1}`}</ActionLink>
                                                                    </td>
                                                                    <MonthSelector form={form} monthsFieldName={monthsFieldName} />
                                                                    <td className="u-textCenter">
                                                                        <ActionLink id={`delete-application-${applicationIndex}`} className="u-ml-xs IconLink--trash" onClick={removeApplication(form, values, applicationIndex)}></ActionLink>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        );
                                    });
                                }}
                            </FieldArray>
                        </>
                    )}
                </PanelBody>
            </Panel>
            {blockLevelApplicationModal}
        </>
    );
}

const addApplication = (form, unselectedBlocks, openBlockLevelApplicationModal) => () => {
    const viewModel = {
        id: uuidv4(),
        blockIds: [],
        months: [],
        longTermFactor: 100,
        availableBlocks: unselectedBlocks,
    };
    openBlockLevelApplicationModal(viewModel, applicationAdded(form));
};

const applicationAdded = (form) => (application) => {
    form.mutators.push("applications", application);
};

const removeApplication = (form, values, index) => (e) => {
    const results = values.applications.reduce((acc, cur, i) => {
        if (i !== index) acc.push(cur);
        return acc;
    }, []);
    form.change("applications", results);
};

const editApplication = (form, application, applicationIndex, selectedBlocks, unselectedBlocks, openBlockLevelApplicationModal) => () => {
    const viewModel = {
        ...application,
        availableBlocks: selectedBlocks.concat(unselectedBlocks),
    };
    openBlockLevelApplicationModal(viewModel, applicationEdited(form, applicationIndex));
};

const applicationEdited = (form, applicationIndex) => (application) => {
    form.mutators.update("applications", applicationIndex, application);
};
