import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";
import { useAnalysisCommentModal } from "containers/FarmHome/Modals/AnalysisCommentModal";
import Button from "components/Button/Button";
import { useRefData, useAuthContext, useConfirm } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function AnalysisCommentsTab({ analysis }) {
    const refData = useRefData();
    const currentUserId = useAuthContext()?.userId;
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const [analysisCommentModal, openAnalysisCommentModal] = useAnalysisCommentModal(analysis);

    const isPublication = analysis.class === "PublishedAnalysis";
    const comments = (analysis.comments || []).sort(utils.compareDate("createdOn", true));
    const hasComments = comments.length > 0;

    const addComment = () => {
        const newComment = {
            id: uuidv4(),
            type: "General",
            isNew: true,
        };
        openAnalysisCommentModal(newComment);
    }

    const editComment = (comment) => () => {
        openAnalysisCommentModal(comment);
    }

    const deleteComment = (comment) => () => {
        confirm(`Are you sure you want to delete this comment?`, async () => {
            const updatedAnalysis = {
                ...analysis,
                comments: analysis.comments.filter((c) => c.id !== comment.id),
            };
            await updateAnalysisAsync(updatedAnalysis);
        })
    }

    const addCommentAction = !isPublication ? (
        <ActionLink id="add-comment" className="IconLink--arrow-plus u-link u-textWhite" onClick={addComment}>
            Add comment
        </ActionLink>
    ) : undefined;

    return (
        <>
            <Alert type="info" text={<span>These are comments that were attached to the analysis at the time it was published. Comments made on this publication can be viewed by clicking the 'History' link above.</span>} />
            <Panel title={isPublication ? "Analysis comments" : "Comments"} actions={addCommentAction} skyBlue>
                <PanelBody>
                    <div className="Table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>By</th>
                                    <th>Type</th>
                                    <th>Included in report</th>
                                    <th>Comment</th>
                                    {!isPublication && <th className="th--shrink">&nbsp;</th>}
                                </tr>
                            </thead>
                            {!hasComments && (
                                <tbody>
                                    <tr>
                                        <td colSpan="5">
                                            <div className="Tile-body">
                                                <div className="Tile-body-message">
                                                    <h4 className="u-mt-0">There are no comments</h4>
                                                    {!isPublication && (
                                                        <Button id="add-comment" className="IconLink--arrow-plus Button Button--secondary u-mt-md" onClick={addComment}>
                                                            Add comment
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                            {hasComments && (
                                <tbody>
                                    {comments.map((comment, i) => {
                                        const isEditable = comment.createdBy.userId === currentUserId;
                                        return (
                                            <tr key={comment.id} className="hover" valign="top">
                                                <td>{utils.dateLong(comment.createdOn)}</td>
                                                <td>{comment.createdBy.userFullName}</td>
                                                <td>{utils.valueToText(refData.budgetCommentTypes, comment.type)}</td>
                                                <td>{comment.includeInFarmImpactReport ? "Yes" : "No"}</td>
                                                <td>{comment.comment}</td>
                                                {!isPublication && (
                                                    <td>
                                                        {isEditable && (
                                                            <div className="u-flex">
                                                                <ActionLink id={`edit-comment-${comment.id}`} className="IconLink--edit" title="Edit" onClick={editComment(comment)}></ActionLink>
                                                                <ActionLink id={`delete-comment-${comment.id}`} className="IconLink--trash" title="Delete" onClick={deleteComment(comment)}></ActionLink>
                                                            </div>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            )}
                        </table>
                    </div>
                </PanelBody>
            </Panel>
            {analysisCommentModal}
        </>
    )
}
