import ReactGA from "react-ga4";
import { push } from "connected-react-router";
import * as api from "api";
import signalRMiddleware from "signalRMiddleware";

const actionTypes = {
    APP_REDIRECT: "APP_REDIRECT",
    APP_VERSION: "APP_VERSION",
    APP_ONLINE: "APP_ONLINE",
    APP_OFFLINE: "APP_OFFLINE",
    APP_REF_DATA_FETCH: "APP_REF_DATA_FETCH",
    APP_REF_DATA_FETCH_SUCCESS: "APP_REF_DATA_FETCH_SUCCESS",
    APP_REF_DATA_FETCH_FAILURE: "APP_REF_DATA_FETCH_FAILURE",
    APP_OPEN_MODAL: "APP_OPEN_MODAL",
    APP_CLOSE_MODAL: "APP_CLOSE_MODAL",
    APP_MODAL_ERROR: "APP_MODAL_ERROR",
    APP_MODAL_CLEAR_ERROR: "APP_MODAL_CLEAR_ERROR",
    APP_MODAL_ERROR_REPEATABLE: "APP_MODAL_ERROR_REPEATABLE",
    APP_MODAL_INLINE_OPEN: "APP_MODAL_INLINE_OPEN",
    APP_MODAL_INLINE_CLOSE: "APP_MODAL_INLINE_CLOSE",
    APP_SUBMISSION_OUTCOME: "APP_SUBMISSION_OUTCOME",
    APP_SUBMISSION_CANCEL: "APP_SUBMISSION_CANCEL",
    APP_REHYDRATE: "persist/REHYDRATE",
    APP_POST_REHYDRATE: "APP_POST_REHYDRATE",
    APP_SAVING: "APP_SAVING",
    APP_LOADING_BEGIN: "APP_LOADING_BEGIN",
    APP_LOADING_END: "APP_LOADING_END",
    APP_TOGGLE_HELP: "APP_TOGGLE_HELP",
    APP_HELP_OFF: "APP_HELP_OFF",
    APP_FORM_STATE: "APP_FORM_STATE",
    APP_DOWNLOAD: "APP_DOWNLOAD",
    APP_DOWNLOAD_SUCCESS: "APP_DOWNLOAD_SUCCESS",
    APP_SIGNALR_CONN_CREATED: "APP_SIGNALR_CONN_CREATED",
    APP_VERSION_INVALID_COUNT: "APP_VERSION_INVALID_COUNT",
    APP_OFFLINE_COUNT: "APP_OFFLINE_COUNT",
};

export default actionTypes;

export const sendTrack = (location) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.post({
                path: "analytics/userSession",
                content: { location },
                onBeforeSend: () => ({ type: actionTypes.APP_SAVING, payload: false }),
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export function navigate(path) {
    return (dispatch) => {
        dispatch(push(path));
    };
}

export function setFormState(name, value) {
    return (dispatch) => {
        dispatch({ type: actionTypes.APP_FORM_STATE, payload: { name, value } });
    };
}

export function toggleQuickTips(e) {
    e.preventDefault();
    return (dispatch) => {
        dispatch({ type: actionTypes.APP_TOGGLE_HELP });
    };
}

export function helpOff() {
    return (dispatch) => {
        dispatch({ type: actionTypes.APP_HELP_OFF });
    };
}

export function setOnlineStatus(status) {
    if (status === true) {
        return (dispatch) => {
            dispatch({ type: actionTypes.APP_ONLINE });
        };
    } else {
        return { type: actionTypes.APP_OFFLINE };
    }
}

export function setInvalidVersionCount(count) {
    return { type: actionTypes.APP_VERSION_INVALID_COUNT, payload: count };
}

export function setOfflineCount(count) {
    return { type: actionTypes.APP_OFFLINE_COUNT, payload: count };
}

export function setVersion(version) {
    return { type: actionTypes.APP_VERSION, payload: version };
}

export function fetchRefData() {
    ReactGA.event({
        category: "RefData",
        action: "Get",
    });
    return api.get({
        path: "refdata",
        onSuccess: (response) => {
            return { type: actionTypes.APP_REF_DATA_FETCH_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            error.handled = true;
            return [];
        },
        silentMode: true,
    });
}

export function postRehydrate() {
    return { type: actionTypes.APP_POST_REHYDRATE };
}

export function modal(modalType, onConfirm, props) {
    return { type: actionTypes.APP_OPEN_MODAL, payload: { modalType, onConfirm, props } };
}

export function closeModal() {
    return { type: actionTypes.APP_CLOSE_MODAL };
}

export function modalInlineOpen() {
    return { type: actionTypes.APP_MODAL_INLINE_OPEN };
}

export function modalInlineClose() {
    return { type: actionTypes.APP_MODAL_INLINE_CLOSE };
}

export function modalError(message) {
    return { type: actionTypes.APP_MODAL_ERROR, payload: message };
}

export const startSignalR = () => {
    return (dispatch, getState) => {
        const { signalRConn } = getState().app;
        //if (state.auth.isAuthenticated && state.app.refData && state.app.refData.deploymentType==='Science') {

        if (!signalRConn || !signalRConn.state) {
            const signalR = signalRMiddleware(dispatch);
            dispatch({ type: actionTypes.APP_SIGNALR_CONN_CREATED, payload: signalR });
        }
    };
};

export const logEmailTracking = (step, emailId, emailTime) => {
    try {
        //If the email id is within the last day
        const id = emailId && emailTime > Date.now() - 86400000 ? emailId : undefined;
        if (id) {
            return api.post({
                path: `emails/track`,
                content: { logId: id, event: step },
                onFailure: (error) => {
                    error.handled = true;
                    return [];
                },
            });
        }
        return [];
    } catch (e) {
        //most likely a corrupt email time
        console.log(e);
        return [];
    }
};
