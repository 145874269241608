import Page from "components/Page";
import PageBody from "components/PageBody";
import DashboardPageHead from "./DashboardPageHead";
import MyFarmGroups from "./MyFarmGroups";

export default function MyFarmGroups_TMP() {
    return (
        <Page>
            <DashboardPageHead breadcrumb={[{ name: "Farm groups" }]} />
            <PageBody>
                <MyFarmGroups />
            </PageBody>
        </Page>
    )
}
