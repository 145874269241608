import React, { useState, useEffect } from "react";
import { compose, withAppContext, withActions } from "containers/HOC";
import { Form, Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import { FORM_ERROR } from "final-form";
import { Subject } from "rxjs";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SearchInputPack from "components/SearchInputPack";
import TextareaPack from "components/TextareaPack";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { findFarms, createFarmGroup } from "./_actions";
import FarmGroupDetails from "./FarmGroupDetails";
import { useQueryClient } from "@tanstack/react-query";

const onSearch$ = new Subject();

const handleSearchTextChange = (previousSearchResults, selectedFarmIds) => (e) => {
    const searchText = e.target.value;
    onSearch$.next({ searchText, previousSearchResults, selectedFarmIds });
};

const handleClearSearch = (form) => () => {
    form.change("search", "");
};

const validate = (viewModel) => {
    const error = {};
    error.name = FormUtils.validators.required(viewModel.name);
    error.name = error.name || FormUtils.validators.maxLength(50)(viewModel.name);
    error.description = FormUtils.validators.maxLength(100)(viewModel.description);
    error.farmGroupVisibility = FormUtils.validators.required(viewModel.farmGroupVisibility);
    error.showAggregatedReport = !viewModel.showBenchmarkReport && !viewModel.showAggregatedReport ? "At lease one report type needs to be selected" : undefined;
    return error;
};

const CreateFarmGroupModal = ({ viewModel, findFarms, createFarmGroup, close, refData }) => {
    const queryClient = useQueryClient();
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        async function handleFindFarms(searchText, previousSearchResults, selectedFarmIds) {
            const newSearchResults = await findFarms(searchText)
                .then((response) => response)
                .catch(() => []);

            const selectedFarms = previousSearchResults.filter((sr) => selectedFarmIds.includes(sr.id));
            const newFarms = newSearchResults.filter((s) => !selectedFarmIds.includes(s.id));

            setSearchResults([...selectedFarms, ...newFarms]);
        }

        // Like componentDidMount
        const subscription = onSearch$.debounceTime(500).subscribe((searchParams) => handleFindFarms(searchParams.searchText, searchParams.previousSearchResults, searchParams.selectedFarmIds));

        // Like componentWillUnmount
        return () => subscription.unsubscribe();
    }, [findFarms]);

    const submit = async (viewModel) => {
        if (viewModel.farmGroupVisibility === "Private") {
            viewModel.showAggregatedReport = true;
            viewModel.showBenchmarkReport = true;
            viewModel.showMembers = true;
        }
        const submitResult = await createFarmGroup(viewModel)
            .then(() => {
                queryClient.invalidateQueries({ queryKey: ["dashboard"] });
                queryClient.invalidateQueries({ queryKey: ["farm-details"] });
                close();
            })
            .catch((error) => ({ [FORM_ERROR]: error }));

        return submitResult;
    };

    return (
        <Form initialValues={viewModel} validate={validate} onSubmit={submit}>
            {({ form, handleSubmit, pristine, submitting, submitError, dirtySinceLastSubmit, values }) => {
                const selectedFarmIds = values.inviteFarmIds || [];

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Create farm group" close={close} submitting={submitting} full>
                            <ModalBody>
                                <FarmGroupDetails refData={refData} submitError={submitError} dirtySinceLastSubmit={dirtySinceLastSubmit} values={values} />
                                <>
                                    <Grid title="Member farms" instructions="Find farms to invite to this group. You can add more later if needed.">
                                        <GridCell className="u-lg-width2of3">
                                            <div className="Field u-mt-md">
                                                <label className="Field-label">Farm search</label>
                                                <Field name="search" placeholder="Search by farm name or address" onChange={handleSearchTextChange(searchResults, selectedFarmIds)} onClear={handleClearSearch(form)} component={SearchInputPack} />
                                            </div>
                                        </GridCell>
                                    </Grid>
                                    <Grid className="u-mt-md">
                                        <GridCell>
                                            <span className="h3">{searchResults.length}</span> farm(s) found
                                        </GridCell>
                                    </Grid>
                                </>
                                {searchResults.length > 0 && (
                                    <Grid className="u-mt-md">
                                        <GridCell>
                                            <SortableTable data={searchResults} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }}>
                                                {({ data, sortCriteria, handleSort }) => {
                                                    return (
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="th--shrink"></th>
                                                                    <SortableTableHeader label="Name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                    <SortableTableHeader label="Address" fieldName="address" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                    <SortableTableHeader label="Ownership status" fieldName="ownershipStatus" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((farm) => {
                                                                    return (
                                                                        <tr key={farm.id} className="hover">
                                                                            <td className="u-textCenter">
                                                                                <label className="Checkbox">
                                                                                    <Field name="inviteFarmIds" data-testing-name={farm.name} id={farm.id} value={farm.id} className="Checkbox-input" type="checkbox" component="input" />
                                                                                    <span className="Checkbox-label"></span>
                                                                                </label>
                                                                            </td>
                                                                            <td>{farm.name}</td>
                                                                            <td>{farm.address}</td>
                                                                            <td>{farm.ownershipStatus}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    );
                                                }}
                                            </SortableTable>
                                        </GridCell>
                                        <GridCell>
                                            <Field name="inviteComments" label="Invitation comments" placeholder="Enter any comments to include on the invitation email to the invited farms" type="text" component={TextareaPack} />
                                        </GridCell>
                                    </Grid>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="btnFarmGroupCreate_cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="btnFarmGroupCreate_save" submit primary disabled={pristine || submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
};
export default compose(withAppContext, withActions({ findFarms, createFarmGroup }))(CreateFarmGroupModal);
