import { useRef } from "react";
import ReactDOMServer from "react-dom/server";
import DropzoneComponent from "react-dropzone-component";
import toGeoJSON from "@mapbox/togeojson";

export default function MultiFileUploadField({ input, label, fileExtensions = ["xml"], required, meta }) {
    const fileType = fileExtensions.length > 0 ? fileExtensions[0].toLowerCase().replace(".", "") : "xml";
    const filesRef = useRef([]);

    const dropzoneConfig = getDropzoneConfig(fileExtensions);

    const djsConfig = getDJSConfig(fileExtensions);

    var eventHandlers = {
        addedfile: handleFileAdded(fileType, input, filesRef, djsConfig),
        removedfile: handleFileRemoved(input, filesRef),
        error: handleFileError(input, filesRef, djsConfig),
    };

    const error = meta && meta.touched && meta.error;

    return (
        <div className={`Field ${error ? "has-error" : ""}`}>
            {label && (
                <label className="Field-label">
                    {label}
                    {required && <sup className="required">*</sup>}
                </label>
            )}
            {
                // To allow dynamic fileType changes we need to render a distinct ds instance for each fileType
                isXml(fileType) && <DropzoneComponent config={dropzoneConfig} djsConfig={djsConfig} eventHandlers={eventHandlers} {...input} />
            }
            {
                // To allow dynamic fileType changes we need to render a distinct ds instance for each fileType
                isJson(fileType) && <DropzoneComponent config={dropzoneConfig} djsConfig={djsConfig} eventHandlers={eventHandlers} {...input} />
            }
            {
                // To allow dynamic fileType changes we need to render a distinct ds instance for each fileType
                isKml(fileType) && <DropzoneComponent config={dropzoneConfig} djsConfig={djsConfig} eventHandlers={eventHandlers} {...input} />
            }
            {
                // To allow dynamic fileType changes we need to render a distinct ds instance for each fileType
                isGeoJson(fileType) && <DropzoneComponent config={dropzoneConfig} djsConfig={djsConfig} eventHandlers={eventHandlers} {...input} />
            }
            {error && <div className="Field-error u-block u-mt-sm">{error}</div>}
        </div>
    );
}

export const isXml = (fileType) => fileType === "xml";

export const isJson = (fileType) => fileType === "json";

export const isKml = (fileType) => fileType === "kml";

export const isGeoJson = (fileType) => fileType === "geojson";

const handleFileAdded = (fileType, input, filesRef, djsConfig) => (addedFile) => {
    const reader = new FileReader();
    reader.readAsText(addedFile);
    reader.onload = () => {
        const {
            upload: { uuid },
            name,
        } = addedFile;

        let file = filesRef.current.find((f) => f.uuid === uuid);
        if (!file) {
            file = { uuid, name };
            filesRef.current.push(file);
        }

        const content = reader.result;
        file.content = content;
        file.type = fileType;

        const error = isCorrupted(file);
        if (error) {
            file.error = error;
        }

        input.onChange(filesRef.current);
        input.onBlur();
    };
}

const handleFileRemoved = (input, filesRef) => (removedFile) => {
    filesRef.current = filesRef.current.filter((f) => f.uuid !== removedFile.upload.uuid);
    if (filesRef.current.length === 0) {
        input.onChange([]);
    } else {
        var files = filesRef.current.map(file => {
            const msg = isCorrupted(file);
            if (msg) {
                file.error = msg;
            }
            return file;
        });
        input.onChange(files);
    }
    input.onBlur();
}

const handleFileError = (input, filesRef, djsConfig) => (erroredFile, error) => {
    const {
        upload: { uuid },
        name,
        type,
    } = erroredFile;
    const file = { uuid, name, type };
    if (error !== djsConfig.dictMaxFilesExceeded) {
        // dictMaxFilesExceeded error is handled in addedfile event handler.
        file.error = error;
    }
    filesRef.current.push(file);
    input.onChange(filesRef.current);
    input.onBlur();
}

const getDropzoneConfig = (fileExtensions) => {
    const config = {
        iconFiletypes: fileExtensions,
        showFiletypeIcon: true,
        postUrl: "no-url",
    };
    return config;
}

const getDJSConfig = (fileTypes) => {
    const config = {
        acceptedFiles: fileTypes.map((f) => `.${f}`).toString(),
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFilesize: 3,
        uploadMultiple: false,
        dictDefaultMessage: "Drag & drop the file here, or click here to select a file",
        dictFileTooBig: "File too big. Max file size is {{maxFilesize}} MB.",
        dictInvalidFileType: "File type not supported",
        dictMaxFilesExceeded: "Uploading multiple files not supported",
        previewTemplate: ReactDOMServer.renderToStaticMarkup(
            <div className="dz-preview dz-file-preview">
                <div className="dz-details">
                    <div className="dz-filename">
                        <span data-dz-name></span> (<span data-dz-size></span>)
                    </div>
                </div>
            </div>
        ),
    };
    return config;
}

const validateGeoJson = (geoJson) => {
    const isValidGeoJson = geoJson.type === "FeatureCollection" && Array.isArray(geoJson.features) && geoJson.features.some((f) => f.geometry);

    if (!isValidGeoJson) {
        return "Invalid file format";
    }
}

const isCorrupted = (file) => {
    if (!file.content) return false;

    if (isXml(file.type)) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(file.content, "text/xml");
        if (doc.getElementsByTagName("parsererror").length) {
            return doc.getElementsByTagName("parsererror")[0].textContent;
        }
    }

    if (isJson(file.type) || isGeoJson(file.type)) {
        try {
            file.json = JSON.parse(file.content);

            if (isGeoJson(file.type)) {
                return validateGeoJson(file.json);
            }
        } catch (e) {
            return e.message;
        }
    }

    if (isKml(file.type)) {
        try {
            // Convert the KML to GeoJson.
            const kml = file.content.replace(/(<Document.*>)/, "<Document>");
            const kmlDoc = new DOMParser().parseFromString(kml, "text/xml");
            file.json = toGeoJSON.kml(kmlDoc);
            return validateGeoJson(file.json);
        } catch (e) {
            return e.message;
        }
    }

    return false;
}
