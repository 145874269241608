import { useQuery } from "@tanstack/react-query";
import { useAuthContext, useConfirm, useRefData } from "common/hooks";
import { httpClient } from "common/httpClient";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import { Link } from "react-router-dom";
import { useGrantFarmAccessModal } from "containers/Farm/GrantFarmAccessModal";
import { useRevokeAccessInvitationAsync } from "containers/hooks";

export default function MyPendingFarmAccessInvitations() {
    const { isFetching, data: pendingAccessInvitations } = useMyPendingFarmAccessInvitations();
    const revokeAccessInvitationAsync = useRevokeAccessInvitationAsync();
    const [grantFarmAccessModal, openGrantFarmAccessModal] = useGrantFarmAccessModal();
    const confirm = useConfirm();
    const isSystemAdmin = useAuthContext()?.isSystemAdmin;
    const refData = useRefData();

    const hasPendingAccessInvitations = pendingAccessInvitations?.length > 0;
    if (!hasPendingAccessInvitations) {
        return null;
    }

    const _resendInvitation = (accessInvitation) => {
        const canInviteOwner = accessInvitation.role === "Owner" || isSystemAdmin;
        openGrantFarmAccessModal({ ...accessInvitation, canInviteOwner });
    }

    const _revokeInvitation = (accessInvitation) => {
        confirm(`Are you sure you want to delete this invitation?`, async () => {
            await revokeAccessInvitationAsync(accessInvitation);
        });
    }

    const info = "The list of people you have invited to farms. The email status shows if the email has been sent successfully to the recipient or not. If an email has not been sent successfully please check the email address is spelt correctly.";

    return (
        <>
            <Panel title={`My pending farm access invitations (${pendingAccessInvitations.length})`} skyBlue iconClassName="IconLink--clock" info={info} waiting={isFetching} open={false}>
                <PanelBody>
                    <div className="Table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Farm</th>
                                    <th>Email status</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th>Role</th>
                                    <th>Invited by</th>
                                    <th className="th--shrink"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingAccessInvitations
                                    .sort((a, b) => (b.farmName < a.farmName ? 1 : -1))
                                    .map((accessInvitation, i) => {
                                        const { emailLog } = accessInvitation;
                                        const status = emailLog ? emailLog.status : "Unknown";

                                        return (
                                            <tr key={accessInvitation.id} id={accessInvitation.id} className={emailLog && emailLog.failed ? "u-font-red u-font-600" : ""}>
                                                <td valign="top" id={`farmname_${accessInvitation.inviteeEmail}`}>
                                                    <Link to={`/app/farm/${accessInvitation.farmId}`} className="u-link u-textBold">
                                                        {accessInvitation.farmName}
                                                    </Link>
                                                    <br />
                                                    {accessInvitation.farmAddress}
                                                </td>
                                                <td id={`status_${accessInvitation.inviteeEmail}`}>{status}</td>
                                                <td id={`invitee_${accessInvitation.inviteeEmail}`}>{accessInvitation.inviteeEmail}</td>
                                                <td id={`date_${accessInvitation.inviteeEmail}`}>{utils.dateOnly(accessInvitation.invitedOn)}</td>
                                                <td id={`account_${accessInvitation.inviteeEmail}`}>{utils.valueToText(refData.farmRoles, accessInvitation.role)}</td>
                                                {accessInvitation.invitedBy.accountName !== accessInvitation.invitedBy.userFullName ? (
                                                    <td>
                                                        {accessInvitation.invitedBy.accountName} - {accessInvitation.invitedBy.userFullName}
                                                    </td>
                                                ) : (
                                                    <td>{accessInvitation.invitedBy.accountName}</td>
                                                )}
                                                <td>
                                                    <div className="u-flex">
                                                        {accessInvitation.status !== "Granted" && (
                                                            <ActionLink className="IconLink--edit" id={`resend-${accessInvitation.id}`} onClick={() => _resendInvitation(accessInvitation)}>
                                                                Resend
                                                            </ActionLink>
                                                        )}
                                                        {
                                                            <ActionLink className="IconLink--trash u-ml-sm" id={`revoke-${accessInvitation.id}`} onClick={() => _revokeInvitation(accessInvitation)}>
                                                                Delete
                                                            </ActionLink>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </PanelBody>
            </Panel>
            {grantFarmAccessModal}
        </>
    )
}

/**
 * Get farm access invitations sent by me that have not been responded to.
 */
function useMyPendingFarmAccessInvitations() {
    const query = useQuery({
        queryKey: ["dashboard", "my-pending-farm-access-invitations"],
        queryFn: async () => httpClient.get(`accessInvitations`),
        retry: false,
        refetchOnWindowFocus: false
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error
    };
}
