import { useState, useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import * as domain from "common/domain";
import * as utils from "common/utils";
import DownloadFarms from "components/DownloadFarms";
import { useAuthContext } from "common/hooks";

export default function MonthlyTimingPercentageChart({ months = [], title, colour, farmLookup }) {
    const isSystemAdmin = useAuthContext()?.isSystemAdmin;
    const sortedMonths = sortMonths(months);
    const data = getChartData(sortedMonths, colour);
    const options = getChartOptions(sortedMonths, isSystemAdmin);
    const chartTopRef = useRef();
    const [monthData, setMonthData] = useState();

    const chartClicked = (event) => {
        if (!isSystemAdmin || !farmLookup || Object.keys(farmLookup).length === 0) return;
        const chartElement = getElementAtEvent(chartTopRef.current, event);
        if (!chartElement || !Array.isArray(chartElement) || chartElement.length !== 1 || isNaN(chartElement[0].index)) return;
        setMonthData(sortedMonths[chartElement[0].index]);
    };

    return (
        <>
            {monthData && <DownloadFarms close={() => setMonthData(undefined)} farmIds={monthData.farmIds} farmLookup={farmLookup} fileName={`Monthly-${title}-${monthData.month}.csv`} />}
            <div key={`${title}`} className="ReportSection u-page-break-avoid">
                <h2>{title}</h2>
                <Bar data={data} height={50} options={options} onClick={chartClicked} ref={chartTopRef} />
            </div>
        </>
    );
}

const sortMonths = (months) => {
    const total = months.reduce((t, m) => (t += m.amount), 0);
    return domain.farmYear.map((m) => {
        const existing = months.find((em) => em.month === m) || { month: m, amount: 0 };
        existing.amount = utils.round((100 * existing.amount) / total, 0);
        return existing;
    });
};

const getChartData = (months, colour) => {
    return {
        labels: months.map((m) => m.month),
        datasets: [
            {
                data: months.map((m) => m.amount),
                backgroundColor: colour,
                hoverBackgroundColor: colour,
            },
        ],
    };
};

const getChartOptions = (sortedMonths, isSystemAdmin) => {
    const chartOptions = {
        scales: {
            y: {
                title: { display: true, text: "Percentage (%)" },
                ticks: {
                    display: true,
                    beginAtZero: true,
                    min: 0,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        if (!isSystemAdmin) return tooltipItem.formattedValue;
                        const { dataIndex } = tooltipItem;
                        if (sortedMonths.length <= dataIndex) return tooltipItem.formattedValue;
                        const val = sortedMonths[dataIndex];
                        const { farmIds = [] } = val;
                        const farmsTip = farmIds.length > 0 ? ` | Farms: ${farmIds.length}` : "";
                        return `${tooltipItem.formattedValue}%${farmsTip}`;
                    },
                    afterLabel: () => {
                        return isSystemAdmin ? "Click for download options" : "";
                    },
                },
            },
        },
    };
    return chartOptions;
};
