import { Grid, GridCell } from "components/Grid";
import MonthlyTimingPercentageChart from "./MonthlyTimingPercentageChart";

export default function MonthlyTiming({ aggregatedData, farmLookup }) {
    const { monthlyData = [] } = aggregatedData;

    const hasData = monthlyData.some(md => md.months && md.months.length > 0);
    if (!hasData)
        return null;

    return (
        <div className="HeroPanel u-mt-md u-page-break u-print-border">
            <div className="HeroPanel-heading">Timing - Percentage by month</div>
            <Grid className="u-page_break">
                {monthlyData.map((d, i) => {
                    return (
                        d.months &&
                        d.months.length > 0 && (
                            <GridCell className="u-xxl-width1of2 u-xl-width1of1" key={i}>
                                <MonthlyTimingPercentageChart months={d.months} title={d.name} colour={d.colour} farmLookup={farmLookup} />
                            </GridCell>
                        )
                    );
                })}
            </Grid>
        </div>
    )
}
