import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import { useParams, useLocation } from "react-router-dom";
import * as domain from "common/domain";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import CatchmentDetails from "./CatchmentDetails";

export default function CatchmentEdit({ farm, analysis }) {
    const { blockId, wetlandId } = useParams();
    const location = useLocation();
    const isUnfenced = location.query?.uf === "1" || false;

    let block = undefined;
    if (!isUnfenced && blockId) {
        block = analysis.blocks.find((b) => b.id === blockId && b.type !== domain.BlockType.FodderCrop);
    }

    let wetland = undefined;
    if (isUnfenced) {
        wetland = analysis.wetlands?.find((w) => w.id === wetlandId);
    } else {
        wetland = block?.wetland || domain.factoryWetland();
    }
    if (wetland && (!wetland.id || wetland.id === NIL_UUID)) {
        wetland.id = uuidv4();
    }

    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.WETLANDS} />
            <TileBody>
                <CatchmentDetails farm={farm} analysis={analysis} block={block} wetland={wetland} isUnfenced={isUnfenced} isNew={false} />
            </TileBody>
        </Tile>
    );
}
