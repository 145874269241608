import React from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import Spinner from "components/Spinner/Spinner";

class Tile extends React.Component {
    state = {
        open: this.props.open === undefined ? true : this.props.open,
    };

    _toggleCollapse = (e) => {
        if (this.props.collapsible) this.setState({ open: !this.state.open });
    };

    _close = (e) => {
        if (this.props.onClose) {
            this.props.onClose(e);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.active && !prevProps.active) {
            this.setState({ open: true });
        } else if (!this.props.active && prevProps.active) {
            this.setState({ open: false });
        } else if (this.props.open && !prevProps.open) {
            this.setState({ open: true });
        }
    }

    render() {
        const { title, referrer, children, className, indent, padTop, secondary, tertiary, collapsible, actions, img, iconClassName, loading, waiting } = this.props;

        return (
            <div className={`Tile ${loading ? "Tile-loading" : ""} ${className ? className : ""}${!this.state.open ? " u-print-none" : ""}${padTop ? " u-mt-md" : ""}${indent ? " Tile--indent" : ""}${waiting ? " waiting" : ""}`}>
                {title && (
                    <div className={`Tile-head ${secondary ? "Tile-head--secondary" : ""} ${tertiary ? "Tile-head--super" : ""} ${collapsible ? "u-link" : ""}`} onClick={this._toggleCollapse} id={utils.strToId(title)}>
                        {referrer && (
                            <Link to={referrer} className="Tile-head-close" onClick={this._close}>
                                <i className="icon icon-cross"></i>
                            </Link>
                        )}
                        {collapsible && !actions && <i className={`Tile-head-close icon ${this.state.open ? "icon-arrow-up" : "icon-arrow-down"}`}></i>}
                        {actions && <span className="Tile-head-close">{actions}</span>}
                        {img && <img id={`tile-head.titleIcon`} src={img} className="u-mr-xs u-p-5" width="35" height="35" alt="" />}
                        {iconClassName && <span className={iconClassName}></span>}
                        <span className="u-pt-xxs u-pb-xxs u-mr-sm">
                            {title}
                            {!this.state.open && <span> ...</span>}
                        </span>
                        {((!this.state.open && loading) || waiting) && <Spinner />}
                    </div>
                )}
                {this.state.open && children}
            </div>
        );
    }
}

export default Tile;
