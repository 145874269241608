import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as icons from "common/icons";
import { useConfirm } from "common/hooks";
import { useAnalysisSummary } from "containers/BudgetHome";
import TileSubPanel from "components/TileSubPanel";
import ActionLink from "components/ActionLink";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function SupplementsGreenBox({ farm, analysis, readOnly }) {
    const { isFetching, isLoading, supplements } = useSupplementsSummary(analysis);
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const hasSupplements = !!supplements;

    const deleteSupplement = (supplementId) => {
        confirm(`Are you sure you want to delete this supplement?`, async () => {
            analysis.feedSupplements = analysis.feedSupplements.filter((s) => s.id !== supplementId);
            updateAnalysisAsync(analysis);
        });
    }

    return (
        <>
            <TileSubPanel title="Supplements" waiting={isFetching} loading={isLoading} green stickyHeaderName="analysis.supplements.greenBox.collapsed">
                <div className="FarmTable_wrapper">
                    <div className="FarmTable-supplementary u-pt-md">
                        {hasSupplements
                            ? <>
                                <div className="Table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th data-width="md-15 xl-15">Category</th>
                                                <th data-width="md-15 xl-15">Feed</th>
                                                <th data-width="md-25 xl-25">Sources</th>
                                                <th data-width="md-10 xl-7.5" className="numeric">Sourced</th>
                                                <th data-width="md-10 xl-7.5" className="numeric">Distributed</th>
                                                <th data-width="md-5 xl-5" className="u-textCenter">Remaining</th>
                                                <th data-width="md-20 xl-20">Destinations</th>
                                                <th data-width="md-25 xl-5" className="numeric">Est. dry matter</th>
                                                {!readOnly && (
                                                    <th className="th--shrink"></th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {supplements?.bySource?.map((supplement) => {
                                                const icon = utils.getSupplementIcon(supplement);
                                                const isFullyDistributed = supplement.undistributedAmount.value === 0;
                                                const hasErrors = !isFullyDistributed || analysis?.messages?.find((m) => m.category === "Supplements" && m.entityType === "FeedSupplement" && m.entityId === supplement.id && m.severity === "Error") ? "error" : undefined;
                                                const isHarvested = supplement.source === "Harvested";
                                                const isPurchased = supplement.source === "Purchased";
                                                // Show 1 decimal place for tonnes
                                                const dp = supplement?.sourcedAmount?.unit?.notation === "t" ? 1 : 0;

                                                const harvestedByBlock = supplement.harvestedByBlock?.map(b => `${b.name} (${utils.numeric.format(b.amount.value, dp)} ${b.amount.unit.notation})`).join(", ");
                                                const destinations = supplement.destinations?.map(d => `${d.name} (${utils.numeric.format(d.amount.value, dp)} ${d.amount.unit.notation})`).join(", ");

                                                return (
                                                    <tr key={supplement.id}>
                                                        <td valign="top">
                                                            <span className="u-flex u-flexAlignItemsCenter">
                                                                {hasErrors && (
                                                                    <span className="Todo Todo--error error-anchor">
                                                                        <i className="icon icon-alert" />
                                                                    </span>
                                                                )}
                                                                <img className="u-mr-xs u-p-5" src={icon} width="35" height="35" alt={`${supplement.category} icon`} />
                                                                <span id={`supplement-${supplement.id}`}>{supplement.category}</span>
                                                            </span>
                                                        </td>
                                                        <td id={`supplement-feed-${supplement.id}`} className="u-pt-md" valign="top">
                                                            {supplement.feed || "-"}
                                                        </td>
                                                        <td className="u-pt-md" valign="top">
                                                            {(readOnly || isFetching)
                                                                ? <span>
                                                                    {isHarvested
                                                                        ? <><b>Harvested: </b> {harvestedByBlock}</>
                                                                        : <>{supplement.source}</>
                                                                    }
                                                                </span>
                                                                : <>
                                                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/${isHarvested ? "harvested" : (isPurchased ? "purchased" : "fromStorage")}/${supplement.id}`} className="u-flex u-flexAlignItemsCenter" id={`edit-supplement-${supplement.id}`}>
                                                                        <span>
                                                                            {isHarvested
                                                                                ? <><b>Harvested: </b> {harvestedByBlock}</>
                                                                                : <>{supplement.source}</>
                                                                            }
                                                                        </span>
                                                                    </Link>
                                                                </>
                                                            }
                                                        </td>
                                                        <td id={`supplement-sourced-${supplement.id}`} className="numeric u-pt-md" valign="top">
                                                            {utils.numeric.format(supplement.sourcedAmount.value, dp)} {supplement.sourcedAmount.unit.notation}
                                                        </td>
                                                        <td id={`supplement-distributed-${supplement.id}`} className="numeric u-pt-md" valign="top">
                                                            {utils.numeric.formatAllowZero(supplement.distributedAmount.value, dp)} {supplement.distributedAmount.unit.notation}
                                                        </td>
                                                        <td id={`supplement-remaining-${supplement.id}`} className={`u-pt-md u-textBold u-textCenter ${isFullyDistributed ? "u-textSuccess" : "u-textError"}`} valign="top">
                                                            {isFullyDistributed
                                                                ? <>-</>
                                                                : <>
                                                                    {utils.numeric.format(supplement.undistributedAmount.value, dp)} {supplement.undistributedAmount.unit.notation}
                                                                </>
                                                            }

                                                        </td>
                                                        <td className="u-pt-md" valign="top">
                                                            {(readOnly || isFetching)
                                                                ? <>
                                                                    {isFullyDistributed
                                                                        ? <span>
                                                                            {destinations}
                                                                        </span>
                                                                        : <>-</>
                                                                    }
                                                                </>
                                                                : <>
                                                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/${supplement.id}/distribution`} className={isFullyDistributed ? "" : "IconLink--copy"} id={`distribute-supplement-${supplement.id}`}>
                                                                        {isFullyDistributed
                                                                            ? <span>
                                                                                {destinations}
                                                                            </span>
                                                                            : <>Distribute</>
                                                                        }
                                                                    </Link>
                                                                </>
                                                            }
                                                        </td>
                                                        <td id={`supplement-dryweight-${supplement.id}`} className="numeric u-pt-md" valign="top">
                                                            {utils.numeric.format(supplement.totalDryMatter.value, 1)} {supplement.totalDryMatter.unit.notation}
                                                        </td>
                                                        {!readOnly && (
                                                            <td className="u-pt-md" valign="top">
                                                                <div className="u-flexSplit">
                                                                    <ActionLink className="IconLink--trash" id={`delete-supplement-${supplement.id}`} onClick={() => deleteSupplement(supplement.id)} title="Delete supplement" disabled={isFetching}></ActionLink>
                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={readOnly ? 8 : 9}>
                                                    <div className="u-flex u-flexJustifyEnd">
                                                        <div className="u-table-clear Table-Compressed">
                                                            <table>
                                                                <tbody>
                                                                    {supplements?.totalDryMatterHarvested?.value > 0 && (
                                                                        <tr style={{ fontWeight: "normal" }}>
                                                                            <td align="right">
                                                                                <b>Estimated available dry matter from harvested supplements: </b>
                                                                            </td>
                                                                            <td className="numeric">{utils.numeric.format(supplements.totalDryMatterHarvested.value, 1)} {supplements.totalDryMatterHarvested.unit.notation}</td>
                                                                        </tr>
                                                                    )}
                                                                    {supplements?.totalDryMatterPurchased?.value > 0 && (
                                                                        <tr style={{ fontWeight: "normal" }}>
                                                                            <td align="right">
                                                                                <b>Estimated available dry matter from purchased supplements: </b>
                                                                            </td>
                                                                            <td className="numeric">{utils.numeric.format(supplements.totalDryMatterPurchased.value, 1)} {supplements.totalDryMatterPurchased.unit.notation}</td>
                                                                        </tr>
                                                                    )}
                                                                    {supplements?.totalDryMatterFromStorage?.value > 0 && (
                                                                        <tr style={{ fontWeight: "normal" }}>
                                                                            <td align="right">
                                                                                <b>Estimated available dry matter from supplements from storage: </b>
                                                                            </td>
                                                                            <td className="numeric">{utils.numeric.format(supplements.totalDryMatterFromStorage.value, 1)} {supplements.totalDryMatterFromStorage.unit.notation}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </>
                            : <>
                                <div className="Tile-body">
                                    <div className="Tile-body-message">
                                        <img src={icons.add} className="u-p-5" alt="Plus" />
                                        <p className="lead">You do not have any feed supplements</p>
                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/harvested`} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-harvested-button">
                                            Add harvested supplement
                                        </Link>
                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/purchased`} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-purchased-button">
                                            Add purchased supplement
                                        </Link>
                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/fromStorage`} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-fromStorage-button">
                                            Add supplement from storage
                                        </Link>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </TileSubPanel>
        </>
    )
}

function useSupplementsSummary(analysis) {
    const { isFetching, isLoading, data } = useAnalysisSummary(analysis);

    return {
        isFetching,
        isLoading,
        supplements: data?.supplements
    }
}
