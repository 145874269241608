import React from "react";
import { compose, withActions, withState, withAppContext } from "containers/HOC";
import PaymentList from "components/PaymentList";
import { getMyOrgPaymentsByMonthYear, getMyOrgBalance, getMyOrgRecentPayments } from "./_actions";
import BalanceSummary from "components/BalanceSummary";

class MyOrgPayments extends React.Component {
    state = {};
    componentDidMount() {
        const { authContext } = this.props;
        authContext.isAdmin && this.props.getMyOrgBalance(authContext.accountId);
    }

    render() {
        const { getMyOrgPaymentsByMonthYear, authContext, payments, paymentsLoading, balance, getMyOrgRecentPayments } = this.props;
        return (
            <React.Fragment>
                {authContext.isAdmin && balance && <BalanceSummary balance={balance} authContext={authContext} accountId={authContext.accountId} />}

                <PaymentList payments={payments} paymentsLoading={paymentsLoading} getPaymentsByMonthYear={getMyOrgPaymentsByMonthYear} getRecentPayments={getMyOrgRecentPayments} authContext={authContext} balance={balance} recentPaymentCount={50} accountId={authContext.accountId} />
            </React.Fragment>
        );
    }
}

export default compose(
    withAppContext,
    withActions({ getMyOrgPaymentsByMonthYear, getMyOrgBalance, getMyOrgRecentPayments }),
    withState((state) => {
        return {
            payments: state.myOrg.payments,
            paymentsLoading: state.myOrg.paymentsLoading,
            balance: state.myOrg.balance,
            balanceLoading: state.myOrg.balanceLoading,
        };
    })
)(MyOrgPayments);
