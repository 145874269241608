import ActionLink from "components/ActionLink";
import { useBulkImportMapsModal } from "./BulkImportMapsModal";

export default function BulkImportMapsLink({ analyses, className = "IconLink--upload" }) {
    const [bulkImportMapsModal, openBulkImportMapsModal] = useBulkImportMapsModal(analyses);

    return (
        <>
            <ActionLink id="import-map" className={className} onClick={() => openBulkImportMapsModal()}>
                Bulk import maps for the analyses in this dataset
            </ActionLink>
            {bulkImportMapsModal}
        </>
    )
}
