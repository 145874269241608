import { useMutation } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import { useModal } from "common/hooks";
import { httpClient } from "common/httpClient";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import { TextField } from "components/FormFields";

function ChangePasswordModal({ close }) {
    const changePasswordAsync = useChangePasswordAsync();

    const validate = (values) => {
        const validation = {};

        validation.currentPassword = FormUtils.validators.required(values.currentPassword);
        validation.currentPassword = validation.currentPassword || FormUtils.validators.minLength(8)(values.currentPassword);
        validation.currentPassword = validation.currentPassword || FormUtils.validators.maxLength(50)(values.currentPassword);

        validation.newPassword = FormUtils.validators.required(values.newPassword);
        validation.newPassword = validation.newPassword || FormUtils.validators.minLength(8)(values.newPassword);
        validation.newPassword = validation.newPassword || FormUtils.validators.maxLength(50)(values.newPassword);

        validation.confirmNewPassword = FormUtils.validators.required(values.confirmNewPassword);
        if (values.confirmNewPassword !== values.newPassword) validation.confirmNewPassword = validation.confirmNewPassword || "Password does not match";

        return validation;
    }

    const submitAsync = async (formValues) => {
        try {
            await changePasswordAsync(formValues.currentPassword, formValues.newPassword);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }

    return (
        <Form validate={validate} onSubmit={submitAsync}>
            {({ handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Change password" submitting={submitting} close={close} fluid skinny>
                            <ModalBody error={submitError}>
                                <Grid>
                                    <GridCell className="">
                                        <Field name="currentPassword" label="Current password" placeholder="Enter your current password" required type="password" component={TextField} />
                                        <Field name="newPassword" label="New password" placeholder="Enter your new password" required type="password" component={TextField} />
                                        <Field name="confirmNewPassword" label="Confirm new password" placeholder="Confirm your new password" required type="password" component={TextField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit primary waiting={submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useChangePasswordModal() {
    const [modal, openModal] = useModal(ChangePasswordModal);

    const open = () => {
        const modalProps = {};
        openModal(modalProps);
    };

    return [modal, open];
}

function useChangePasswordAsync() {
    const mutation = useMutation({
        mutationFn: async ({ currentPassword, newPassword }) => {
            try {
                await httpClient.put(`passwords`, { currentPassword, newPassword });
            } catch (error) {
                if (error.status === 403) {
                    throw new Error("Invalid credentials. Please try again.");
                } else {
                    throw new Error(error.message);
                }
            }
        }
    });

    return (currentPassword, newPassword) => mutation.mutateAsync({ currentPassword, newPassword });
}
