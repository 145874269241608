import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { v4 as uuidv4 } from "uuid";
import * as FormUtils from "common/FormUtils";
import { useModal } from "common/hooks";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Grid, GridCell } from "components/Grid";
import { HiddenField, TextField } from "components/FormFields";
import { Button } from "components/Button";
import { useInviteUserToMyOrgAsync } from "./_hooks";

export default function InviteUserModal({ org, invitation, close }) {
    const inviteUserToMyOrgAsync = useInviteUserToMyOrgAsync();

    const doesUserAlreadyExist = (email) => org?.users?.some((user) => user.email.toLowerCase() === email?.toLowerCase()) || false;

    const validate = (formValues) => {
        const validation = {};

        validation.email = FormUtils.validators.required(formValues.email);
        validation.email = validation.email || FormUtils.validators.email(formValues.email);

        if (!validation.email) {
            if (doesUserAlreadyExist(formValues.email)) {
                validation.email = "A user with this email address already exists";
            }
        }

        return validation;
    }

    const submitAsync = async (formValues) => {
        try {
            await inviteUserToMyOrgAsync(formValues);
            close();
        } catch (ex) {
            return { [FORM_ERROR]: ex.message };
        }
    }

    return (
        <Form initialValues={invitation} validate={validate} onSubmit={submitAsync}>
            {({ values, handleSubmit, submitting, submitError }) => {
                const userAlreadyExists = doesUserAlreadyExist(values.email);
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Ask a staff member to join your organisation" close={close} submitting={submitting} skinny fluid>
                            <ModalBody info="This should only be used to add users to your organisation (not your farm). If you want to grant someone access to your farm please select manage farm access from within the farm screen instead. On completion an email invitation will be sent to the user for them confirm their registration as a user in your organisation." error={submitError}>
                                <Grid>
                                    <GridCell>
                                        <Field name="id" component={HiddenField} />
                                        <Field name="accountId" component={HiddenField} />
                                        <Field name="email" label="Email" placeholder="Enter email of person you wish to invite to your organisation. Do not use this to grant access to a farm." disabled={invitation.email !== undefined} required component={TextField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="save-button" submit primary disabled={userAlreadyExists} waiting={submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useInviteUserModal() {
    const [modal, openModal] = useModal(InviteUserModal);

    const openInviteUserModal = (org, invitation) => {
        if (!invitation) {
            invitation = {
                id: uuidv4(),
                accountId: org.id
            };
        }
        const modalProps = {
            org,
            invitation
        };
        openModal(modalProps);
    };

    return [modal, openInviteUserModal];
}
