import { Link } from "react-router-dom";
import * as icons from "common/icons";
import { useFarm } from "containers/hooks";
import RenewSubscriptionButton from "containers/Payments/RenewSubscriptionButton";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";

export default function FarmPageHead({ title, breadcrumb }) {
    const { data: farm } = useFarm();

    if (!farm) {
        return null;
    }

    return (
        <>
            <section className="PageHead">
                <div className="Container BudgetHeader">
                    <div className="FarmTitle">
                        <Link to={`/app/farm/${farm.id}`}>
                            <img className="FarmTitle-icon FarmTitle-icon--lg u-p-5" src={icons.farm} alt="Farm icon" />
                        </Link>
                        <div className="FarmTitle-heading FarmTitle-heading--lg">
                            <Link to={`/app/farm/${farm.id}`}>
                                <span id="page-head-title" className="FarmTitle-name" title={farm.name}>
                                    {farm.name}
                                </span>
                            </Link>
                            <div id="page-head-sub-title" className="FarmTitle-sub" title={farm.address}>
                                {farm.address}
                            </div>
                        </div>
                    </div>

                    <div className="PageHead-budgetOverview u-print-none u-mr-sm" title={title}>
                        {title}
                    </div>

                    <RenewSubscriptionButton farm={farm} />

                    <div className="PageHead-PrintLogo">
                        <img className="u-p-5" src={icons.logoPrint()} alt="logo" style={{ width: "200px", height: "50px" }} />
                    </div>
                </div>
            </section>
            {breadcrumb?.length > 0 && <Breadcrumb breadcrumb={breadcrumb} />}
        </>
    )
}
