import React from "react";
import { connect } from "react-redux";
import Alert from "components/Alert";

const Select = (props) => {
    const { id, name, value, label, placeholder, options, noLabel, required, dataWidth, disabled, autoFocus, onChange, meta, showQuickTips, tip } = props;

    const hasError = meta && meta.touched && meta.error;

    return (
        <div className={`Field${hasError ? " has-error" : ""}`} data-width={dataWidth}>
            <label className="Select Select--block" htmlFor={name}>
                {noLabel !== true && (
                    <label htmlFor={id || name} className={`Field-label${disabled ? " is-disabled" : ""}`}>
                        {label}
                        {required === true && <sup className="required">*</sup>}
                    </label>
                )}
                <select name={name} id={id || name} value={value || ""} onChange={onChange} disabled={disabled} autoFocus={autoFocus === true}>
                    {placeholder && (
                        <option value="" disabled={true}>
                            {placeholder}
                        </option>
                    )}
                    {options &&
                        options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                </select>
            </label>
            {hasError && (
                <small id={`${id || name}-error`} className="Field-error">
                    {meta.error}
                </small>
            )}
            {showQuickTips && tip && <Alert type="help" text={tip} />}
        </div>
    );
};

export default connect((state) => {
    return {
        showQuickTips: state.app.showQuickTips,
    };
}, null)(Select);
