import { Fragment } from "react";
import { Field } from "react-final-form";
import { Grid, GridCell } from "components/Grid";
import { SelectField } from "components/FormFields";
import SelectPack from "components/SelectPack2";
import BoolSelectPack from "components/BoolSelectPack";
import InputPack from "components/InputPack";
import * as FormUtils from "common/FormUtils";
import * as validations from "common/validations";
import BlockSelector from "components/new/BlockSelector";
import { FieldArray } from "react-final-form-arrays";
import * as fu from "./_utils";
import * as utils from "common/utils";
import Nutrients, { validateNutrients } from "components/Nutrients";
import { Panel, PanelBody } from "components/Panel";
import { v4 as uuidv4 } from "uuid";
import Alert from "components/Alert";
import { useRefData } from "common/hooks";

export default function Application({ appGroup, form, errors, values, appGroupIndex, deleteGroup, products = [], copyGroup, blockId, addApplicationGroup, addFertilser, rotationYear, productId }) {
    const refData = useRefData();

    const { analysis } = values;
    const { fertiliserMaterial = [] } = refData;
    const fertTypes = fertiliserMaterial.map((m) => m.value);
    const productOptions = products.map((f) => ({ value: f.id, text: f.productName, groupIndex: fertTypes.indexOf(f.material), groupLabel: f.material }));
    productOptions.push({ value: "Product", text: "Product", groupIndex: 10, groupLabel: "Add new" });
    productOptions.push({ value: "Lime", text: "Lime", groupIndex: 10, groupLabel: "Add new" });
    productOptions.push({ value: "Organic", text: "Organic", groupIndex: 10, groupLabel: "Add new" });
    productOptions.push({ value: "Soluble", text: "Soluble", groupIndex: 10, groupLabel: "Add new" });
    const { appGroups = [] } = values;
    const { blockIds = [] } = appGroups[appGroupIndex];
    const { applications = [] } = appGroups[appGroupIndex];
    const selectedBlocks = blockIds.map((id) => analysis.blocks.find((b) => b.id === id));
    const hasFruit = selectedBlocks.some((b) => b.type === "ProductiveFruit");
    const hasCrops = selectedBlocks.some((b) => ["FodderCrop", "ProductiveCrop"].includes(b.type));
    const hasProdCrops = selectedBlocks.some((b) => "ProductiveCrop" === b.type);
    const hasNonCrops = selectedBlocks.some((b) => b.type !== "ProductiveCrop");
    const hasYear1 = applications.some((a) => a.rotationIndex < 12);
    const product = productId && analysis.fertiliser.find((f) => f.id === productId);
    const unit = product && product.organicType === fu.CompostMulches ? "tonnes" : "kgha";

    const addApplication = (form) => {
        applications.unshift({
            id: uuidv4(),
            blockIds: appGroups[appGroupIndex].blockIds,
            addedAt: new Date().getTime(),
            unit,
            productId,
            fertiliser: product,
        });
        form.change(
            `${appGroup}.applications`,
            applications.map((b) => b)
        );
    };

    const { blocks: availableBlocks = [] } = analysis;
    const { nitrogenFertAppMethod = [] } = refData;
    const allFodder = selectedBlocks.every((b) => b.type === "FodderCrop");
    const hasFodder = selectedBlocks.some((b) => b.type === "FodderCrop");
    const hasPrevLime = applications.some((a) => a.isLastPreviousLime);
    if (allFodder) {
        applications
            .filter((a) => a.isLastPreviousLime)
            .forEach((a, i) => {
                form.change(`${appGroup}.applications[${i}].rotationIndex`, undefined);
                form.change(`${appGroup}.applications[${i}].isLastPreviousLime`, false);
                form.change(`${appGroup}.applications[${i}].limeYearsSinceApplied`, undefined);
            });
    }

    const groupActions = (
        <div className="u-flex">
            <span id="add-application-grp-link" className="u-link IconLink--arrow-plus" onClick={() => addApplicationGroup(appGroupIndex)}>
                Add group
            </span>
            <span id="copy-application-grp-link" className="u-link IconLink--copy u-ml-sm" onClick={() => copyGroup(appGroupIndex)}>
                Copy group
            </span>
            <span id="remove-application-grp-link" className="u-link IconLink--trash u-ml-sm" onClick={() => deleteGroup(appGroupIndex)}>
                Delete group
            </span>
            <span id="add-application-link" className="u-link IconLink--arrow-plus u-ml-sm" onClick={() => addApplication(form)}>
                Add application
            </span>
        </div>
    );

    const npksSum = [0, 0, 0, 0];
    const anyNutrientOnApp = applications.find((a) => a.fertiliser && fu.nutrientsOnApplication(a.fertiliser));

    for (const app of applications) {
        const [, , kgAmount] = fu.getApplicationAmount(app, analysis);
        const npks = getNpks(app, kgAmount, refData, analysis);
        if (npks) {
            npksSum[0] += npks[0];
            npksSum[1] += npks[1];
            npksSum[2] += npks[2];
            npksSum[3] += npks[3];
        }
    }

    return (
        <Panel title={`Application Group ${appGroupIndex + 1}`} midBlue className="u-mt-lg" actions={groupActions}>
            <Alert type="info" text="An application group contains applications that are applied to a set group of blocks. For applications on pasture blocks by tonne or kg, the rate is calcuated after removing any fodder crop rotations from the pasture blocks. The area removed is based on dividing the total fodder area by the total pastoral area for the farm." />
            <PanelBody>
                <Field name={`${appGroup}.id`} type="hidden" component="input" />
                <Grid>
                    <GridCell>
                        <Field name={`${appGroup}.blockIds`} isMulti={true} blockId={blockId} component={BlockSelector} availableBlocks={availableBlocks.filter((b) => b.isProductive && b.type !== "ProductiveOutdoorPigs")} hideLabel={true} />
                        {hasNonCrops && hasYear1 && hasProdCrops && <Alert type="warning" text="You have a mixture of crop and non crop blocks. Year 1 applications will only be applied to crop blocks" className="u-mt-md u-mb-0" />}
                        {hasFodder && hasPrevLime && <Alert type="warning" text="Previous lime applications will not be applied to fodder crop rotations" className="u-mt-md u-mb-0" />}
                    </GridCell>
                </Grid>
                <Grid>
                    <GridCell>
                        <div className="Table u-mt-md">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>When applied</th>
                                        <th style={{ minWidth: "120px" }}>Unit</th>
                                        <th>Details</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <FieldArray name={`${appGroup}.applications`}>
                                        {({ fields }) => {
                                            const { value = [] } = fields;

                                            const previousLimeCount = value.filter((a) => a.isLastPreviousLime).length;
                                            return fields.map((field, appIndex) => {
                                                const application = {
                                                    id: value[appIndex].id,
                                                    productId: value[appIndex].productId,
                                                    isLastPreviousLime: value[appIndex].isLastPreviousLime,
                                                    rotationIndex: value[appIndex].rotationIndex,
                                                    unit: value[appIndex].unit,
                                                    nutrients: value[appIndex].nutrients,
                                                    amount: value[appIndex].amount,
                                                    nFertAppMethod: value[appIndex].nFertAppMethod,
                                                    limeYearsSinceApplied: value[appIndex].limeYearsSinceApplied,
                                                    limeDissolvesWithinYear: value[appIndex].limeDissolvesWithinYear,
                                                    fertiliserNPlacedUnderDripLine: value[appIndex].fertiliserNPlacedUnderDripLine,
                                                    fertiliser: value[appIndex].fertiliserNPlacedUnderDripLine || {},
                                                    blockIds,
                                                };
                                                const { fertiliser = {} } = value[appIndex];

                                                const removeApplication = (index, id) => {
                                                    const results = value.reduce((acc, cur, i) => {
                                                        if (i !== index) acc.push(cur);
                                                        return acc;
                                                    }, []);
                                                    form.change(`${appGroup}.applications`, results);
                                                    form.mutators.push(`${appGroup}.deletedAppIds`, id);
                                                };

                                                const copyApplication = (i) => {
                                                    const dup = utils.clone(value[i]);
                                                    dup.id = uuidv4();
                                                    if (dup.isLastPreviousLime) {
                                                        delete dup.rotationIndex;
                                                    }
                                                    dup.isLastPreviousLime = false;
                                                    const appGroupApps = [...value];
                                                    appGroupApps.splice(i + 1, 0, dup);
                                                    form.change(`${appGroup}.applications`, appGroupApps);
                                                };

                                                const productChange = (value, id, i) => {
                                                    if (!value) return;

                                                    const appId = uuidv4();
                                                    form.mutators.push(`${appGroup}.deletedAppIds`, id);
                                                    form.change(`${field}.id`, appId);

                                                    if (["Product", "Lime", "Organic", "Soluble"].includes(value)) {
                                                        const path = `${appGroup}.applications[${i}]`;
                                                        form.change(`${field}.productId`, undefined);
                                                        addFertilser(value, path);
                                                    } else {
                                                        let appFertiliser = analysis.fertiliser.find((f) => f.id === value);
                                                        if (!appFertiliser) {
                                                            appFertiliser = products.find((f) => f.id === value);
                                                            form.mutators.push("analysis.fertiliser", appFertiliser);
                                                        }
                                                        if (fu.nutrientsOnApplication(appFertiliser)) {
                                                            form.change(`${field}.amount`, undefined);
                                                        } else {
                                                            form.change(`${field}.nutrients`, undefined);
                                                        }

                                                        form.change(`${field}.fertiliser`, appFertiliser);
                                                        if (appFertiliser.organicType === fu.CompostMulches) {
                                                            form.change(`${field}.unit`, "tonnes");
                                                        } else if (fu.kghaOnly({ fertiliser: appFertiliser })) {
                                                            form.change(`${field}.unit`, "kgha");
                                                        }
                                                    }
                                                };

                                                const rotationIndexChange = (e, rotationIndex) => {
                                                    switch (rotationIndex) {
                                                        case "-1":
                                                            form.change(`${field}.isLastPreviousLime`, true);
                                                            form.change(`${field}.limeDissolvesWithinYear`, true);
                                                            form.change(`${field}.limeYearsSinceApplied`, 0);
                                                            break;
                                                        case "-2":
                                                            form.change(`${field}.isLastPreviousLime`, true);
                                                            form.change(`${field}.limeDissolvesWithinYear`, false);
                                                            form.change(`${field}.limeYearsSinceApplied`, 1);
                                                            break;
                                                        case "-3":
                                                            form.change(`${field}.isLastPreviousLime`, true);
                                                            form.change(`${field}.limeDissolvesWithinYear`, false);
                                                            form.change(`${field}.limeYearsSinceApplied`, 2);
                                                            break;
                                                        case "-4":
                                                            form.change(`${field}.isLastPreviousLime`, true);
                                                            form.change(`${field}.limeDissolvesWithinYear`, false);
                                                            form.change(`${field}.limeYearsSinceApplied`, 3);
                                                            break;
                                                        case "-5":
                                                            form.change(`${field}.isLastPreviousLime`, true);
                                                            form.change(`${field}.limeDissolvesWithinYear`, false);
                                                            form.change(`${field}.limeYearsSinceApplied`, 4);
                                                            break;
                                                        case "-6":
                                                            form.change(`${field}.isLastPreviousLime`, true);
                                                            form.change(`${field}.limeDissolvesWithinYear`, false);
                                                            form.change(`${field}.limeYearsSinceApplied`, 5);
                                                            break;
                                                        default:
                                                            form.change(`${field}.isLastPreviousLime`, false);
                                                            form.change(`${field}.limeDissolvesWithinYear`, undefined);
                                                            form.change(`${field}.limeYearsSinceApplied`, undefined);
                                                            break;
                                                    }
                                                };

                                                application.fertiliser = fertiliser;
                                                application.blockIds = blockIds;
                                                const nutrientsOnApplication = fu.nutrientsOnApplication(fertiliser);
                                                const kghaOnly = fu.kghaOnly(application);
                                                const units = getFertiliserUnits(application, kghaOnly);
                                                const organicCompost = fertiliser.material === fu.ProductType.Organic && fertiliser.organicType === "CompostMulches";
                                                const [totalAmount = 0, amountLabel, kgAmount] = fu.getApplicationAmount(application, analysis);
                                                const npks = getNpks(application, kgAmount, refData, analysis);
                                                const nutrients = (nutrientsOnApplication ? application.nutrients : fertiliser.nutrients) || {};
                                                const showNFertAppMethod = !fu.hideNFertAppMethod(nutrients, hasCrops, fertiliser);
                                                const showLimeDissolves = fertiliser.material === fu.ProductType.Lime;
                                                const tdClass = "td--alignBottom";
                                                const cropRotYear = hasProdCrops && rotationYear.length < 13 ? [...rotationYear, ...rotationYear] : rotationYear;
                                                const canSelectPreviousLime = !allFodder && fertiliser.material === fu.ProductType.Lime && !applications.some((a, j) => j !== appIndex && a.isLastPreviousLime);
                                                const prevLimeMonth = canSelectPreviousLime
                                                    ? [
                                                          { value: -1, text: "1 year ago (dissolved)", groupIndex: 0, groupLabel: "Years since previous lime" },
                                                          { value: -2, text: "1 year ago (not dissolved)", groupIndex: 0, groupLabel: "Years since previous lime" },
                                                          { value: -3, text: "2 years ago", groupIndex: 0, groupLabel: "Years since previous lime" },
                                                          { value: -4, text: "3 years ago", groupIndex: 0, groupLabel: "Years since previous lime" },
                                                          { value: -5, text: "4 years ago", groupIndex: 0, groupLabel: "Years since previous lime" },
                                                          { value: -6, text: "5 years ago", groupIndex: 0, groupLabel: "Years since previous lime" },
                                                      ]
                                                    : [];
                                                const formattedYear = hasProdCrops ? prevLimeMonth.concat(cropRotYear.map((c, i) => ({ value: i, text: String(i) === String(application.rotationIndex) ? `${c} -  ${i < 12 ? "Year 1" : "Reporting year"}` : c, groupIndex: i < 12 ? 1 : 2, groupLabel: i < 12 ? "Year 1" : "Reporting year" }))) : prevLimeMonth.concat(rotationYear.map((m, i) => ({ text: m, value: i + 12, groupIndex: 1, groupLabel: "Reporting year" })));

                                                return (
                                                    <Fragment key={appIndex}>
                                                        <tr>
                                                            <td className={tdClass} style={{ minWidth: "140px" }}>
                                                                <Field name={`${field}.productId`} noLabel placeholder="Select a product" options={productOptions} component={SelectField} onChange={(val) => productChange(val, application.id, appIndex)} />
                                                            </td>
                                                            <td className={tdClass} style={{ minWidth: "140px" }}>
                                                                <Field name={`${field}.rotationIndex`} noLabel placeholder="Select when applied" options={formattedYear} required component={SelectPack} onChange={rotationIndexChange} />
                                                            </td>
                                                            <td className={tdClass}>
                                                                <Field name={`${field}.unit`} noLabel component={SelectPack} required={true} options={units} placeholder="Please select" disabled={organicCompost || kghaOnly} />
                                                            </td>
                                                            {nutrientsOnApplication && (
                                                                <>
                                                                    <td className={tdClass}>
                                                                        <div className="u-flex u-flexJustifyBetween u-flexEnd">
                                                                            <div style={{ bottom: "0" }}>
                                                                                <Nutrients type="Soluble" noMarginTop={true} errors={errors} refData={refData} nutrients={application.nutrients} solubleUnits={units} prefix={`${field}.`} noLabel hideUnits={true} />
                                                                            </div>
                                                                            {hasFruit && !application.isLastPreviousLime && <Field name={`${field}.fertiliserNPlacedUnderDripLine`} label="Under drip line" component={BoolSelectPack} className="u-ml-md u-mt-0" />}
                                                                            {showNFertAppMethod && <Field name={`${field}.nFertAppMethod`} label="App method" component={SelectPack} options={nitrogenFertAppMethod} placeholder="Application method" className="u-mt-0" />}
                                                                        </div>
                                                                    </td>
                                                                </>
                                                            )}
                                                            {!nutrientsOnApplication && (
                                                                <td className={tdClass}>
                                                                    <div className="u-flex u-flexJustifyBetween u-flexEnd">
                                                                        <Field name={`${field}.amount`} label="Amount" requiredLabel={false} type="text" component={InputPack} format={FormUtils.formatters.formatInt} formatOnBlur />

                                                                        {showNFertAppMethod && <Field name={`${field}.nFertAppMethod`} label="App method" component={SelectPack} options={nitrogenFertAppMethod} placeholder="Application method" className="u-ml-md" />}
                                                                        {hasFruit && !application.isLastPreviousLime && <Field name={`${field}.fertiliserNPlacedUnderDripLine`} label="Under drip line" component={BoolSelectPack} className="u-ml-md" />}
                                                                        {showLimeDissolves && <>{application.isLastPreviousLime ? <Field name={`${field}.limeDissolvesWithinYear`} component="input" type="hidden" /> : <Field name={`${field}.limeDissolvesWithinYear`} label="Dissolves within 1 year" component={BoolSelectPack} className="u-ml-md" />}</>}
                                                                        {!allFodder && fertiliser.material === fu.ProductType.Lime && (previousLimeCount === 0 || application.isLastPreviousLime) && <Field name={`${field}.isLastPreviousLime`} component="input" type="hidden" />}
                                                                    </div>
                                                                </td>
                                                            )}
                                                            <td>
                                                                <div className={anyNutrientOnApp ? "" : "u-flex u-flexJustifyBetween u-flexCenter"}>
                                                                    {!nutrientsOnApplication && (
                                                                        <div className="Field u-ml-md u-mt-0">
                                                                            <label className={`Field-label`}>Total</label>
                                                                            <div className="u-font-md">{`${totalAmount.toLocaleString()} ${amountLabel}`}</div>
                                                                        </div>
                                                                    )}
                                                                    {npks && (
                                                                        <div className={`Field u-ml-md ${anyNutrientOnApp && !nutrientsOnApplication ? "u-mt-md" : "u-mt-0"}`}>
                                                                            <label className={`Field-label`}>N-P-K-S (kg/ha)</label>
                                                                            <div className="u-font-md">{`${npks[0]}-${npks[1]}-${npks[2]}-${npks[3]}`}</div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="u-flex">
                                                                    {value.length > 1 && <span id={`remove-app-${appIndex}`} className="a u-link IconLink--trash" onClick={() => removeApplication(appIndex, application.id)}></span>}
                                                                    <span id={`copy-app-${appIndex}`} className="a u-link IconLink--copy" onClick={() => copyApplication(appIndex, application.id)}></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                );
                                            });
                                        }}
                                    </FieldArray>
                                </tbody>
                                {applications.length > 1 && (
                                    <tfoot>
                                        <tr>
                                            <th colSpan={4} className="u-textRight">
                                                Application group NPKS total
                                            </th>
                                            <th>
                                                <div className="u-flex u-flexJustifyEnd">
                                                    <div className="Field u-ml-md u-mt-0">
                                                        <label className={`Field-label`}>N-P-K-S (kg/ha)</label>
                                                        <div className="u-font-md">{`${utils.round(npksSum[0], 1)}-${utils.round(npksSum[1], 1)}-${utils.round(npksSum[2], 1)}-${utils.round(npksSum[3], 1)}`}</div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </tfoot>
                                )}
                            </table>
                        </div>
                    </GridCell>
                </Grid>
            </PanelBody>
        </Panel>
    );
};


const getFertiliserUnits = (application, kghaOnly) => {
    const fertiliserUnitsForDairy = [{ text: "litres/ha", value: "kgha" }];
    const { fertiliser } = application;
    const fertiliserUnits =
        fertiliser.material === fu.ProductType.Organic && fertiliser.organicType === "CompostMulches"
            ? [
                { text: "tonnes wet weight", value: "tonnes" },
                { text: "kgs wet weight", value: "kg" },
                { text: "kgs/ha wet weight", value: "kgha" },
            ]
            : [
                { text: "tonnes", value: "tonnes" },
                { text: "kgs ", value: "kg" },
                { text: "kgs/ha", value: "kgha" },
            ];

    return kghaOnly ? fertiliserUnitsForDairy : fertiliserUnits;
}

const getNpks = (application, kgAmount, refData, analysis) => {
    const { fertiliser = {} } = application;
    const dmContent = isNaN(fertiliser.dmContent) ? 100 : Number(fertiliser.dmContent);
    const dmPercentage = dmContent > 0 ? dmContent * 0.01 : 1;

    const nv = (nutrient) => {
        const adjNut = (!nutrient || nutrient === null ? 0 : nutrient) / 100;
        const adjKgAmount = isNaN(kgAmount) ? 0 : Number(kgAmount);
        return utils.round(adjNut * adjKgAmount * dmPercentage, 0);
    };

    const nutrientsOnApplication = fu.nutrientsOnApplication(fertiliser);
    const factoryEffluent = fertiliser.dairyFactoryEffluent && fertiliser.dairyFactoryEffluent !== "Userdefined" && refData.factoryEffluent.find((r) => r.value === fertiliser.dairyFactoryEffluent);
    const { nutrients = {} } = nutrientsOnApplication ? application : factoryEffluent || fertiliser;

    if (nutrientsOnApplication && nutrients) {
        if (application.unit === "kgha") {
            const n = isNaN(nutrients.N) ? 0 : Number(nutrients.N) * dmPercentage;
            const p = isNaN(nutrients.P) ? 0 : Number(nutrients.P) * dmPercentage;
            const k = isNaN(nutrients.K) ? 0 : Number(nutrients.K) * dmPercentage;
            const s = isNaN(nutrients.S) ? 0 : Number(nutrients.S) * dmPercentage;
            return [n, p, k, s];
        } else {
            const { blocks = [] } = analysis;
            const { blockIds: selectedBlockIds = [] } = application;
            const selectedBlocks = selectedBlockIds.map((id) => blocks.find((b) => b.id === id));
            const filteredBlockIds = Number(application.rotationIndex) > 11 || application.isLastPreviousLime ? selectedBlockIds : selectedBlocks.filter((b) => b.type === "ProductiveCrop").map((b) => b.id);
            const area = fu.getApplicationBlockArea(filteredBlockIds, blocks);
            if (area === 0) return [0, 0, 0, 0];
            const m = application.unit === "tonnes" ? 1000 : 1;
            const n = utils.round(isNaN(nutrients.N) ? 0 : (Number(nutrients.N) * m) / area, 0) * dmPercentage;
            const p = utils.round(isNaN(nutrients.P) ? 0 : (Number(nutrients.P) * m) / area, 0) * dmPercentage;
            const k = utils.round(isNaN(nutrients.K) ? 0 : (Number(nutrients.K) * m) / area, 0) * dmPercentage;
            const s = utils.round(isNaN(nutrients.S) ? 0 : (Number(nutrients.S) * m) / area, 0) * dmPercentage;
            return [n, p, k, s];
        }
    }

    return nutrients && (nutrients.N || nutrients.P || nutrients.K || nutrients.S) ? [nv(nutrients.N), nv(nutrients.P), nv(nutrients.K), nv(nutrients.S)] : undefined;
}

export const validateApplication = (app, analysis) => {
    const errors = {};
    const { fertiliser = {}, blockIds: fBlockIds = [] } = app;
    const fSelectedBlocks = fBlockIds.map((id) => analysis.blocks.find((b) => b.id === id));
    const hasCrops = fSelectedBlocks.some((b) => ["FodderCrop", "ProductiveCrop"].includes(b.type));
    const nutrientsOnApplication = fu.nutrientsOnApplication(fertiliser);
    const nutrients = (nutrientsOnApplication ? app.nutrients : fertiliser.nutrients) || {};
    const showNFertAppMethod = !fu.hideNFertAppMethod(nutrients, hasCrops, fertiliser);
    if (showNFertAppMethod) {
        errors.nFertAppMethod = validations.required(app.nFertAppMethod) || app.nFertAppMethod === "None" ? "Required" : undefined;
    }
    errors.rotationIndex = validations.requiredExt(app.rotationIndex);

    errors.productId = validations.required(app.productId);
    if (["Product", "Lime", "Organic", "Soluble"].includes(app.productId)) {
        errors.productId = "Required";
    }
    if (nutrientsOnApplication) {
        const nutrientErrors = validateNutrients(app, "Soluble");
        return { ...errors, ...nutrientErrors };
    } else {
        errors.unit = validations.required(app.unit);
        errors.amount = validations.required(app.amount);
        errors.amount = errors.amount || validations.range(1, 10000000)(app.amount);
    }
    return errors;
}
