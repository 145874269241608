import React from "react";
import { Field } from "redux-form";
import * as validations from "common/validations";
import Modal from "components/ModalOld";
import InputPack from "components/InputPack";
import Alert from "components/Alert";

class InviteUserModal extends React.Component {
    _validate = (formValues) => {
        const errors = {};

        if (this.props.initialValues.existingUsers && this.props.initialValues.existingUsers.some((u) => u.email === formValues.email)) errors.email = "User already exists";

        return errors;
    };

    _onSubmit = (invitation) => {
        this.props.modal.onConfirm(invitation);
    };

    render() {
        const { initialValues } = this.props;

        return (
            <Modal title={`Invite user`} submitLabel="Send invite" instructionText="<b>Please note: <b>This should be used to add users to your organisation. If you want to grant someone access to your farm please open your farm and select manage farm access instead. On completion an email invitation will be sent to the user for them confirm their registration as a user in your organisation." initialValues={initialValues} validate={this._validate} onSubmit={this._onSubmit} allowOffline={false}>
                <div className="Grid">
                    <div className="Grid-cell u-lg-width2of3">
                        <Field name="id" type="hidden" component="input" />
                        {initialValues.accountName.length === 0 && (
                            <div>
                                <Alert type="info" text="The account that you are inviting users to join does not currently have a name. Please enter a name before inviting other people. This could be your farm name, consultancy name, or your name." />
                                <Field name="accountName" type="text" label="Your account name" placeholder="You do not have a name for this account. Please enter one before inviting users." requiredLabel={true} component={InputPack} validate={[validations.required, validations.maxLength(50), validations.minLength(1)]} />
                            </div>
                        )}
                        <Field name="email" type="text" label="Email" requiredLabel={true} disabled={initialValues && initialValues.email ? true : false} component={InputPack} validate={[validations.required, validations.maxLength(255), validations.minLength(1), validations.email]} placeholder="Enter email of person you wish to invite" />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default InviteUserModal;
