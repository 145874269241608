import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import TextField from "components/FormFields/TextField";
import ConfirmationField from "components/FormFields/ConfirmationField";
import { useModal } from "common/hooks";
import { httpClient } from "common/httpClient";
import { logout } from "containers/Auth/_actions";
import { useDispatch } from "react-redux";

export default function OrgInvitationResposneModal({ invitation, close }) {
    const acceptOrgInvitationAsync = useAcceptOrgInvitationAsync();
    const declineOrgInvitationAsync = useDeclineOrgInvitationAsync();
    const dispatch = useDispatch();

    const submitAsync = async (formValues) => {
        try {
            if (formValues.accepted) {
                await acceptOrgInvitationAsync(formValues);
                dispatch(logout());
            } else {
                await declineOrgInvitationAsync(formValues);
            }
            close();
        } catch (error) {
            return { [FORM_ERROR]: error };
        }
    };

    return (
        <Form initialValues={invitation} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const error = (values && values.error) || (!dirtySinceLastSubmit && submitError);
                const warning = (
                    <span>
                        You have been invited to join the <b>{invitation.accountName}</b> organisation as a user. This should not be accepted if you are simply wanting access to a farm. Review the invitation details and respond.
                    </span>
                );
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Leave your current organisation and join a new organisation" close={close} submitting={submitting} fluid>
                            <ModalBody warning={warning} error={error}>
                                <Field name="id" type="hidden" component="input" />
                                <Grid>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="accountName" label="Invitation to join" readOnly component={TextField} />
                                    </GridCell>
                                    <GridCell className="u-mt-md">
                                        <h3>
                                            If you <u>accept</u> this invitation...
                                        </h3>
                                        <ul className="disc">
                                            <li>
                                                You will <b>lose</b> access to all your current farm and analysis data
                                            </li>
                                            <li>
                                                You will <b>gain</b> access to all the farm and analysis data for <b>{invitation.accountName}</b>
                                            </li>
                                        </ul>
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                    <Button id="decline" submit onClick={() => form.change("accepted", false)} tertiary disabled={submitting}>
                                        Decline
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Field name="confirmed" confirmationPhrase="LEAVE MY ORG" component={ConfirmationField} />
                                    <Button id="accept" submit onClick={() => form.change("accepted", true)} primary disabled={!values.confirmed || submitting}>
                                        Accept
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useOrgInvitationResposneModal() {
    const [modal, openModal] = useModal(OrgInvitationResposneModal);

    const openOrgInvitationResposneModal = (invitation) => {
        const modalProps = {
            invitation,
        };
        openModal(modalProps);
    };

    return [modal, openOrgInvitationResposneModal];
}

function useAcceptOrgInvitationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (invitation) => {
            try {
                await httpClient.put(`invitations/${invitation.id}/accept`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 400) {
                    throw new Error("This invitation is no longer valid.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
        },
    });

    return (invitation) => mutation.mutateAsync(invitation);
}

function useDeclineOrgInvitationAsync() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (invitation) => {
            try {
                await httpClient.put(`invitations/${invitation.id}/decline`);
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 400) {
                    throw new Error("This invitation is no longer valid.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["dashboard"] });
        },
    });

    return (invitation) => mutation.mutateAsync(invitation);
}
