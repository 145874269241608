import React from "react";
import { Field } from "react-final-form";
import * as utils from "common/utils";
import * as domain from "common/domain";
import SelectField from "components/FormFields/SelectField";

const SupplementStorageAndUtilisation = ({ analysis, options, disabled }) => {
    const supplements = analysis.feedSupplements || [];

    const fedToPaddocks = supplements.some((supp) => (supp.destinations || []).some((dest) => isFedToPaddocks(dest)));
    const fedToStructures = supplements.some((supp) => (supp.destinations || []).some((dest) => isFedToStructures(dest)));

    const paddockStorageConditionHelp = domain.SupplementStorageTips[options.paddockStorageCondition];
    const paddockUtilisationHelp = domain.SupplementPaddockConditionTips[options.paddockUtilisation];
    const structureStorageConditionHelp = domain.SupplementStorageTips[options.structureStorageCondition];
    const structureUtilisationHelp = domain.SupplementStructureConditionTips[options.structureUtilisation];

    const showStorageCondition = supplements.some((supp) => supplementIsStoredBeforeFeeding(supp));

    return (
        <div className="Scenario-panel-body">
            {fedToPaddocks && (
                <>
                    <h3 className="u-mt-0 u-text-md">Feeding to paddocks</h3>
                    {showStorageCondition && (
                        <Field
                            name="options.supplementStorageAndUtilisation.paddockStorageCondition"
                            label="Storage condition - change all to"
                            options={[
                                { value: "", text: "No change" },
                                { value: "Poor", text: "Poor" },
                                { value: "Average", text: "Average" },
                                { value: "Excellent", text: "Excellent" },
                                { value: "Nostorage", text: "No storage" },
                            ]}
                            tip={paddockStorageConditionHelp && paddockStorageConditionHelp.text}
                            disabled={disabled}
                            component={SelectField}
                        />
                    )}
                    <Field
                        name="options.supplementStorageAndUtilisation.paddockUtilisation"
                        label="Utilisation - change all to"
                        options={[
                            { value: "", text: "No change" },
                            { value: "Poor", text: "Poor" },
                            { value: "Average", text: "Average" },
                            { value: "Excellent", text: "Excellent" },
                        ]}
                        tip={paddockUtilisationHelp && paddockUtilisationHelp.text}
                        disabled={disabled}
                        component={SelectField}
                    />
                </>
            )}
            {fedToStructures && (
                <>
                    <h3 className={`${fedToPaddocks ? "u-mt-lg" : "u-mt-0"} u-text-md`}>Feeding to structures</h3>
                    {showStorageCondition && (
                        <Field
                            name="options.supplementStorageAndUtilisation.structureStorageCondition"
                            label="Storage condition - change all to"
                            options={[
                                { value: "", text: "No change" },
                                { value: "Poor", text: "Poor" },
                                { value: "Average", text: "Average" },
                                { value: "Excellent", text: "Excellent" },
                            ]}
                            tip={structureStorageConditionHelp && structureStorageConditionHelp.text}
                            disabled={disabled}
                            component={SelectField}
                        />
                    )}
                    <Field
                        name="options.supplementStorageAndUtilisation.structureUtilisation"
                        label="Utilisation - change all to"
                        options={[
                            { value: "", text: "No change" },
                            { value: "Poor", text: "Poor" },
                            { value: "Verygood", text: "Very good" },
                        ]}
                        tip={structureUtilisationHelp && structureUtilisationHelp.text}
                        disabled={disabled}
                        component={SelectField}
                    />
                </>
            )}
        </div>
    );
};
export default SupplementStorageAndUtilisation;

function getEnterpriseRsuByBlocks(analysis) {
    const blocks = analysis.blocks || [];
    const fodderBlockAreas = utils.getFodderBlockAreas(blocks);
    const blocksWithPastureResults = blocks.filter((block) => block.currentResults && block.currentResults.pastureResults && block.currentResults.pastureResults.pastureRsu);

    const enterpriseRsuByBlocks = blocksWithPastureResults.map((block) => {
        const enterpriseRsuByBlock = { id: block.id, enterprises: [] };
        const area = fodderBlockAreas.pastureBlockIds.has(block.id) ? block.areaInHectares - block.areaInHectares * fodderBlockAreas.ratio : block.areaInHectares || block.rotationArea;
        enterpriseRsuByBlock.enterprises = Object.keys(block.currentResults.pastureResults.pastureRsu).map((enterpriseId) => {
            const rsuPerHa = block.currentResults.pastureResults.pastureRsu[enterpriseId].reduce((sum, result) => (sum += result.value), 0);
            const rsu = rsuPerHa * area;
            return { id: enterpriseId, rsu };
        });
        return enterpriseRsuByBlock;
    });
    return enterpriseRsuByBlocks;
}

function supplementIsStoredBeforeFeeding(supplement) {
    return supplement.category !== "DirectFeeding";
}

function isFedToPaddocks(dest) {
    return ["Enterprise", "AllBlocks", "SpecifiedBlocks"].includes(dest.type);
}

function isFedToStructures(dest) {
    return dest.type === "Structure";
}

export function requiresStorageConditionOrUtilisation(dest) {
    return ["Enterprise", "Structure", "AllBlocks", "SpecifiedBlocks"].includes(dest.type);
}

export function getSupplementStorageAndUtilisationScenarioResults(analysis, options, refData) {
    const scenarioResults = { name: "Change supplement storage and utilisation", warnings: [], messages: [] };

    scenarioResults.enterprises = (analysis.enterprises || []).map((enterprise) => {
        return { id: enterprise.id, animalME: 0, harvestME: 0, pastureME: 0, importedME: 0, warnings: [], messages: ["No change to feed"] };
    });

    if (options.paddockUtilisation) scenarioResults.messages.push(`Paddock utilisation changed to ${utils.valueToText(refData.supplementUtilisations, options.paddockUtilisation)}.`);
    if (options.paddockStorageCondition) scenarioResults.messages.push(`Paddock storage conditions changed to ${utils.valueToText(refData.supplementStorageConditions, options.paddockStorageCondition)}.`);
    if (options.structureUtilisation) scenarioResults.messages.push(`Structure utilisation changed to ${utils.valueToText(refData.supplementStructureUtilisations, options.structureUtilisation)}.`);
    if (options.structureStorageCondition) scenarioResults.messages.push(`Structure storage condition changed to ${utils.valueToText(refData.supplementStorageConditions, options.structureStorageCondition)}.`);

    const enterpriseRsuByBlocks = getEnterpriseRsuByBlocks(analysis);

    const supplements = (analysis.feedSupplements || []).filter((supp) => (supp.destinations || []).some((dest) => requiresStorageConditionOrUtilisation(dest)));
    supplements.forEach((supp) => {
        const supplementCategory = supp.category === "Userdefined" ? (refData.supplementGeneralTypes || []).find((sgt) => sgt.value === supp.categoryGeneralType) : (refData.supplementCategories || []).find((sc) => sc.value === supp.category);
        const supplementType = (supplementCategory && (supplementCategory.children || []).find((sc) => sc.value === supp.name)) || supplementCategory;
        if (supplementType) {
            const meContentForThisSupplementType = supp.category === "Userdefined" ? supp.me : supplementType.meContent;

            const imported = supp.type === "Purchased";
            const totalSourceAmount = supp.sources.reduce((sum, source) => (sum += source.amount), 0);
            const dryMatterInKg = supp.dryWeight * 1000;
            const supplementIsStored = supplementIsStoredBeforeFeeding(supp);

            supp.destinations
                .filter((dest) => requiresStorageConditionOrUtilisation(dest))
                .forEach((dest) => {
                    const fedToPaddocks = isFedToPaddocks(dest);
                    const fedToStructures = isFedToStructures(dest);

                    const dryMatterAllocatedToThisDestination = dryMatterInKg * (dest.amount / totalSourceAmount);

                    const oldStorageConditionRatio = supp.type === "FromStorage" || dest.storageCondition === "Nostorage" ? 1 : 1 - supplementType.storageLoss[dest.storageCondition] / 100;
                    let newStorageConditionRatio = oldStorageConditionRatio;
                    if (supplementIsStored) {
                        if (fedToPaddocks && options.paddockStorageCondition) {
                            newStorageConditionRatio = supp.type === "FromStorage" || options.paddockStorageCondition === "Nostorage" ? 1 : 1 - supplementType.storageLoss[options.paddockStorageCondition] / 100;
                        } else if (fedToStructures && options.structureStorageCondition) {
                            newStorageConditionRatio = supp.type === "FromStorage" ? 1 : 1 - supplementType.storageLoss[options.structureStorageCondition] / 100;
                        }
                    }

                    const oldUtilisationRatio = 1 - (fedToStructures ? supplementType.structureUtilisation[dest.structureUtilisation] : supplementType.paddockUtilisation[dest.utilisation]) / 100;
                    let newUtilisationRatio = oldUtilisationRatio;
                    if (fedToPaddocks && options.paddockUtilisation) {
                        newUtilisationRatio = 1 - supplementType.paddockUtilisation[options.paddockUtilisation] / 100;
                    } else if (fedToStructures && options.structureUtilisation) {
                        newUtilisationRatio = 1 - supplementType.structureUtilisation[options.structureUtilisation] / 100;
                    }

                    const oldME = dryMatterAllocatedToThisDestination * meContentForThisSupplementType * oldStorageConditionRatio * oldUtilisationRatio;
                    const newME = dryMatterAllocatedToThisDestination * meContentForThisSupplementType * newStorageConditionRatio * newUtilisationRatio;
                    const changeInME = newME - oldME;

                    if (dest.type === "Enterprise") {
                        const scenarioResult = scenarioResults.enterprises.find((e) => e.id === dest.enterpriseId);
                        if (scenarioResult) {
                            // reset messages
                            if (changeInME !== 0) scenarioResult.messages = [];
                            if (imported) scenarioResult.importedME += changeInME;
                            else scenarioResult.harvestME += changeInME;
                        }
                    } else if (dest.type === "Structure") {
                        const structure = (analysis.structures || []).find((s) => s.id === dest.structureId);
                        if (structure) {
                            const scenarioResult = scenarioResults.enterprises.find((e) => e.id === structure.enterpriseId);
                            if (scenarioResult) {
                                // reset messages
                                if (changeInME !== 0) scenarioResult.messages = [];
                                if (imported) scenarioResult.importedME += changeInME;
                                else scenarioResult.harvestME += changeInME;
                            }
                        }
                    } else if (["AllBlocks", "SpecifiedBlocks"].includes(dest.type)) {
                        const blockIds = dest.type === "SpecifiedBlocks" ? (dest.applications || []).map((application) => application.blockId) : enterpriseRsuByBlocks.map((block) => block.id);

                        const rsuByEnterprises = enterpriseRsuByBlocks
                            .filter((block) => blockIds.includes(block.id))
                            .reduce((results, block) => {
                                block.enterprises.forEach((enterprise) => {
                                    let result = results.find((r) => r.id === enterprise.id);
                                    if (!result) {
                                        results.push({ ...enterprise });
                                    } else {
                                        result.rsu += enterprise.rsu;
                                    }
                                });
                                return results;
                            }, []);

                        const totalRsu = rsuByEnterprises.reduce((sum, enterprise) => (sum += enterprise.rsu), 0);

                        rsuByEnterprises.forEach((enterprise) => {
                            const scenarioResult = scenarioResults.enterprises.find((e) => e.id === enterprise.id);
                            if (scenarioResult) {
                                const proportionOfTotalRsu = enterprise.rsu / totalRsu;
                                const change = changeInME * proportionOfTotalRsu;
                                // reset messages
                                if (change !== 0) scenarioResult.messages = [];

                                if (imported) scenarioResult.importedME += change;
                                else scenarioResult.harvestME += change;
                            }
                        });
                    }

                    if (supplementIsStored && fedToPaddocks && options.paddockStorageCondition) dest.storageCondition = options.paddockStorageCondition;

                    if (fedToPaddocks && options.paddockUtilisation) dest.utilisation = options.paddockUtilisation;

                    if (fedToStructures && options.structureStorageCondition) dest.storageCondition = options.structureStorageCondition;

                    if (fedToStructures && options.structureUtilisation) dest.structureUtilisation = options.structureUtilisation;
                });
        }
    });

    return scenarioResults;
}
