import { useIsOverseerEd } from "common/hooks";
import Page from "components/Page";
import PageBody from "components/PageBody";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileBodyMessage from "components/TileBodyMessage";
import FarmHomePageHead from "containers/FarmHome/FarmHomePageHead";
import { RequestFarmAccessActionLink } from "containers/Farm/RequestFarmAccess";
import { useFarm, useFarmAccess } from "containers/hooks";

export default function FarmAccessDenied() {
    const { data: farm } = useFarm();
    const breadcrumb = [{ name: farm?.displayName, to: `/app/farm/${farm?.id}` }, { name: "Access denied" }];

    const { data: farmAccess } = useFarmAccess();
    const title = farmAccess?.accessRequest ? `Access ${farmAccess?.accessRequest.status}` : `Access denied`;

    const isOverseerEd = useIsOverseerEd();

    return (
        <Page>
            <FarmHomePageHead breadcrumb={breadcrumb} />
            <PageBody>
                <Tile title={title} referrer="/">
                    <TileBody>
                        <TileBodyMessage>
                            {farmAccess?.accessRequest ? (
                                <>
                                    <p className="lead">You have requested access to this farm</p>
                                    <h2>Access {farmAccess.accessRequest.status}</h2>
                                </>
                            ) : (
                                <>
                                    <p className="lead">You do not have permission to access this farm</p>
                                    {!isOverseerEd && <RequestFarmAccessActionLink farm={farm} className="IconLink--arrow-plus Button Button--lg u-mt-lg" />}
                                </>
                            )}
                        </TileBodyMessage>
                    </TileBody>
                </Tile>
            </PageBody>
        </Page>
    );
}
