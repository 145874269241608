import ReactGA from "react-ga4";
import * as api from "api";

const actionTypes = {
    GET_BLOCK_REGION_BENCHMARKS: "GET_BLOCK_REGION_BENCHMARKS",
    GET_BLOCK_REGION_BENCHMARKS_SUCCESS: "GET_BLOCK_REGION_BENCHMARKS_SUCCESS",
    GET_BLOCK_REGION_BENCHMARKS_FAILURE: "GET_BLOCK_REGION_BENCHMARKS_FAILURE",
};
export default actionTypes;

export const getAnalysisComparison = (farmId, fromAnalysisId, toAnalysisId) => (dispatch) => {
    const cancelActionType = `compareAnalyses/${farmId}/${fromAnalysisId}`;
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Budget",
            action: "Compare by Id",
        });
        dispatch({ type: cancelActionType });
        return dispatch(
            api.get({
                path: `farms/${farmId}/budgets/${fromAnalysisId}/compare/${toAnalysisId}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    return [];
                },
                cancelActionType,
            })
        );
    });
};

export const getAnalysisComparisonByJson = (farmId, fromAnalysis, toAnalysis) => (dispatch) => {
    const cancelActionType = `compareJsonAnalyses`;
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Budget",
            action: "Compare by Json",
        });
        dispatch({ type: cancelActionType });
        return dispatch(
            api.post({
                path: "compare/budgets",
                content: {
                    farmId,
                    fromBudget: fromAnalysis,
                    toBudget: toAnalysis,
                },
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    return [];
                },
                cancelActionType,
            })
        );
    });
};





