import { Link } from "react-router-dom";
import * as icons from "common/icons";
import Card from "components/new/Card";
import CardHeader from "components/new/CardHeader";
import CardHeaderColumn from "components/new/CardHeaderColumn";
import CardHeaderField from "components/new/CardHeaderField";
import CardActions from "components/new/CardActions";
import CardActionsRight from "components/new/CardActionsRight";
import { getOutdoorPigEffluentSystemViewModel } from "common/viewModels";
import { useNavigate, useRefData } from "../../../common/hooks";

export default function OutdoorPigEffluentSystemCard({ farm, analysis }) {
    const refData = useRefData();
    const navigate = useNavigate();

    const editApplicationsUrl = `/app/farm/${farm.id}/analysis/${analysis.id}/structures/outdoorPigEffluent`;

    const _editApplications = () => navigate(editApplicationsUrl);

    const _getBlockNames = (blocks = [], applications = []) => {
        return (
            blocks
                .filter((b) => applications.some((a) => (a.blockIds || []).includes(b.id)))
                .map((b) => b.name)
                .join(" | ") || "-"
        );
    };

    const viewModel = getOutdoorPigEffluentSystemViewModel(farm, analysis, refData);

    const strawCompostApplicationsRequired = viewModel.strawCompostSources.length > 0 && viewModel.strawCompostApplications.length === 0;
    const strawCompostManagement = strawCompostApplicationsRequired ? "Required" : _getBlockNames(analysis.blocks, viewModel.strawCompostApplications);

    const pondSolidsApplicationsRequired = viewModel.pondSolidSources.length > 0 && viewModel.pondSolidApplications.length === 0;
    const pondSolidsManagement = pondSolidsApplicationsRequired ? "Required" : _getBlockNames(analysis.blocks, viewModel.pondSolidApplications);

    const liquidApplicationsRequired = viewModel.liquidEffluentSources.length > 0 && viewModel.liquidEffluentApplications.length === 0;
    const liquidManagement = liquidApplicationsRequired ? "Required" : _getBlockNames(analysis.blocks, viewModel.liquidEffluentApplications);

    const hasAnyError = strawCompostApplicationsRequired || pondSolidsApplicationsRequired || liquidApplicationsRequired;

    return (
        <Card>
            <CardHeader id="outdoorPigEffluentApplications" hasError={hasAnyError} dataWidth="md-100 xl-40" titleIcon={icons.farm} title="Outdoor pigs effluent system" onTitleClick={_editApplications} onHeaderClick={_editApplications}>
                <CardHeaderColumn dataWidth="md-30 xl-20">
                    <CardHeaderField label="Straw compost management" value={strawCompostManagement} hasError={strawCompostApplicationsRequired} />
                </CardHeaderColumn>
                <CardHeaderColumn dataWidth="md-30 xl-20">
                    <CardHeaderField label="Pond solids management" value={pondSolidsManagement} hasError={pondSolidsApplicationsRequired} />
                </CardHeaderColumn>
                <CardHeaderColumn dataWidth="md-30 xl-20">
                    <CardHeaderField label="Liquid management" value={liquidManagement} hasError={liquidApplicationsRequired} />
                </CardHeaderColumn>
            </CardHeader>
            <CardActions>
                <CardActionsRight>
                    <ul className="ActionsBar-links">
                        <li>
                            <Link to={editApplicationsUrl} id="edit-outdoorPigsEffluentApplications" className={`IconLink--settings ${hasAnyError ? "u-textError" : ""}`}>
                                Manage effluent
                            </Link>
                        </li>
                    </ul>
                </CardActionsRight>
            </CardActions>
        </Card>
    )
}
