import ReactGA from "react-ga4";
import * as api from "api";

export const getAllNotifications = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get All Notifications",
        });
        return dispatch(
            api.get({
                path: `messages/all`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const createNotification = (notification) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Create Notification",
        });
        dispatch(
            api.post({
                path: `messages`,
                content: notification,
                onSuccess: () => {
                    resolve();
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const updateNotification = (notification) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Update Notification",
        });
        dispatch(
            api.put({
                path: `messages/${notification.id}`,
                content: notification,
                onSuccess: () => {
                    resolve();
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const deleteNotification = (notificationId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Delete Notification",
        });
        dispatch(
            api.del({
                path: `messages/${notificationId}`,
                onSuccess: () => {
                    resolve();
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};
