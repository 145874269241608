import { useParams } from "react-router-dom";
import SoilTestsDetails from "./SoilTestsDetails";

export default function SoilTestsEdit({ farm, analysis }) {
    const soilTests = useSoilTests(analysis);
    return <SoilTestsDetails farm={farm} analysis={analysis} soilTests={soilTests} />
}

function useSoilTests(analysis) {
    const { soilTestsId } = useParams();

    let soilTests = (analysis.soilTests || []).find((st) => st.id === soilTestsId);
    if (!soilTests) {
        return undefined;
    }

    return {
        ...soilTests,
        applications: analysis.blocks.filter((b) => b.soilTestId === soilTestsId).map((b) => ({ id: b.id, block: b }))
    }
}
