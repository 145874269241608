import actionTypes from "./_actions";

const INITIAL_STATE = { carbonSequestration: undefined, carbonSequestrationStatus: "Pending", baseAnalysisMap: [] };

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.BASE_ANALYSIS_CLEAR: {
            const { baseAnalysisMap = [] } = state;
            const cleanMap = baseAnalysisMap.filter((m) => m.analysisId !== action.payload);
            return {
                ...state,
                baseAnalysisMap: cleanMap,
            };
        }
        case actionTypes.BASE_ANALYSIS_SET: {
            const { baseAnalysisMap = [] } = state;
            const { payload = {} } = action;
            const cleanMap = baseAnalysisMap.filter((m) => m.analysisId !== payload.analysisId);
            cleanMap.push({ analysisId: payload.analysisId, baseId: payload.baseId });
            return {
                ...state,
                baseAnalysisMap: cleanMap,
            };
        }
        case actionTypes.PLANNING_DATA_SET: {
            return {
                ...state,
                planningData: action.payload,
            };
        }
        case actionTypes.PLANNING_DATA_CLEAR: {
            return {
                ...state,
                planningData: undefined,
            };
        }
        case actionTypes.CARBON_FETCH_FAILURE:
            return {
                carbonSequestration: undefined,
                carbonSequestrationStatus: "Failed",
            };
        case actionTypes.CARBON_FETCH_CANCEL:
            return {
                ...state,
                carbonSequestration: undefined,
                carbonSequestrationStatus: "Pending",
            };
        case actionTypes.CARBON_FETCH_SUCCESS:
            return {
                carbonSequestration: action.payload,
                carbonSequestrationStatus: "Success",
            };
        default:
            return state;
    }
}

export default reducer;
