import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Link } from "react-router-dom";
import * as FormUtils from "common/FormUtils";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Grid, GridCell } from "components/Grid";
import SavePrompt from "components/SavePrompt";
import InputPack from "components/InputPack";
import CheckboxPack from "components/CheckboxPack";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { Button } from "components/Button";

export default function Electricity({ farm, analysis }) {
    const initialValues = (analysis.ghg || {}).electricity;
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const referrer = `/app/farm/${farm.id}/analysis/${analysis.id}/ghg`;

    const _validate = (values) => {
        const errors = {};

        errors.usageInKilowattHoursPerYear = FormUtils.validators.required(values.usageInKilowattHoursPerYear);
        errors.usageInKilowattHoursPerYear = errors.usageInKilowattHoursPerYear || FormUtils.validators.range(1, 999999999)(values.usageInKilowattHoursPerYear);

        return errors;
    };

    const _submit = async (values) => {
        const updatedAnalysis = {
            ...analysis,
            ghg: {
                ...analysis.ghg,
                electricity: values,
            },
        };
        await updateAnalysisAsync(updatedAnalysis);
    };

    return (
        <Form initialValues={initialValues} mutators={{ ...arrayMutators }} validate={_validate} onSubmit={_submit}>
            {({ values, dirty, submitting, submitSucceeded, handleSubmit }) => {
                const hasValues = Object.keys(values).length > 0;
                return (
                    <form onSubmit={handleSubmit}>
                        <SavePrompt blockIf={dirty && !submitSucceeded} redirectIf={submitSucceeded} redirectTo={referrer} />
                        <Tile title="GHG electricity footprint" waiting={submitting} referrer={referrer}>
                            <TileBody waiting={submitting}>
                                <Grid title="Electricity used to produce farm products">
                                    <GridCell className="Grid-cell u-lg-width1of3">
                                        <Field name="usageInKilowattHoursPerYear" type="text" label="Electricity use" uom="kWh/yr" component={InputPack} placeholder="0" format={FormUtils.formatters.formatInt} formatOnBlur />
                                        <Field name="includesPersonalUse" type="checkbox" label="Includes personal use" component={CheckboxPack} />
                                    </GridCell>
                                </Grid>
                            </TileBody>
                            <TileFooter>
                                <div className="ButtonBar ButtonBar--fixed">
                                    <div className="ButtonBar-left">
                                        <Link to={referrer} id="cancel-button" className="Button Button--secondary">
                                            Cancel
                                        </Link>
                                    </div>
                                    <div className="ButtonBar-right">
                                        <Button submit primary id="save-button" waiting={submitting} disabled={!hasValues}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </TileFooter>
                        </Tile>
                    </form>
                );
            }}
        </Form>
    );
}
