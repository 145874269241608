import { Link } from "react-router-dom";
import { useRefData, useModal } from "common/hooks";
import * as utils from "common/utils";
import * as _utils from "../_utils";
import { Modal, ModalBody } from "components/Modal";
import { Grid, GridCell } from "components/Grid";

function CreateEnterpriseModal({ farm, analysis, close }) {
    const refData = useRefData();

    const enterpriseTypeOptions = _utils.getEnterpriseTypeOptions(analysis, refData);
    const hasDairy = enterpriseTypeOptions.some((o) => o.value === "Dairy" && o.disabled);
    const newEnterpriseTypes = enterpriseTypeOptions.filter((o) => !o.disabled);
    const existingEnterpriseTypes = enterpriseTypeOptions.filter((o) => o.disabled && (o.value !== "DairyReplacements" || hasDairy));

    return (
        <Modal title="Select enterprise type" fluid skinny close={close}>
            <ModalBody>
                <Grid className="u-mt-lg">
                    <GridCell className="Grid-cell--center u-width1of2">
                        {newEnterpriseTypes.map((option) => {
                            const enterpriseIcon = utils.getAnimalIcon(option.value);
                            const enterpriseName = option.text.replace("- ", "");
                            return (
                                <Link key={option.value} id={`add-${option.value}`} onClick={close} to={`/app/farm/${farm.id}/analysis/${analysis.id}/animals/enterprises/${option.value.toLowerCase()}`} className="IconLink--arrow-plus Button Button--secondary Button--lg u-mt-md">
                                    Add {enterpriseName}
                                    <img src={enterpriseIcon} className="u-ml-xs u-p-10" height="45px" width="45px" alt={enterpriseName} />
                                </Link>
                            );
                        })}
                    </GridCell>
                    {existingEnterpriseTypes && existingEnterpriseTypes.length > 0 && (
                        <GridCell className="Grid-cell--center u-mt-lg u-width1of2">
                            <h5>Existing enterprises:</h5>
                            {existingEnterpriseTypes.map((option) => {
                                const enterpriseIcon = utils.getAnimalIcon(option.value);
                                const enterpriseName = option.text.replace("- ", "");
                                return <img key={option.value} src={enterpriseIcon} className="u-ml-xs u-p-5" height="45px" width="45px" title={enterpriseName} alt={enterpriseName} />;
                            })}
                        </GridCell>
                    )}
                </Grid>
            </ModalBody>
        </Modal>
    )
}

export function useCreateEnterpriseModal(farm, analysis) {
    const [modal, openModal] = useModal(CreateEnterpriseModal);

    const open = () => {
        const modalProps = {
            farm,
            analysis
        };
        openModal(modalProps);
    };

    return [modal, open];
}
