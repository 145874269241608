import React from "react";
import { Link, useLocation, matchPath } from "react-router-dom";
import PanelTabsItem from "./PanelTabsItem";
import Spinner from "components/Spinner/Spinner";

const PanelTabs = ({ title, tabs, referrer, children, actions, midBlue, skyBlue, green, orange, iconClassName, waiting }) => {
    const [hamburgerOpen, setHamburgerOpen] = React.useState(false);

    let color = "";
    if (midBlue) color = "midblue";
    else if (skyBlue) color = "skyblue";
    else if (green) color = "green";
    else if (orange) color = "orange";

    const location = useLocation();
    const activeTab = getActiveTab(tabs, location);

    return (
        <div className={`PanelTabs u-print-none ${color} u-flex u-flexJustifyBetween ${hamburgerOpen ? "is-open" : ""}`} onMouseLeave={() => setHamburgerOpen(false)}>
            {tabs && (
                <span className="PanelTabs-hamburger icon icon-hamburger" onMouseEnter={() => setHamburgerOpen(true)} onClick={() => setHamburgerOpen(!hamburgerOpen)}>
                    {activeTab && <span className="PanelTabs-hamburger-title">{activeTab.title}</span>}
                </span>
            )}
            {!tabs && (
                <span className="Tile-head">
                    {waiting && <Spinner />}
                    {iconClassName && !waiting && <span className={iconClassName}></span>}
                    {title}
                </span>
            )}
            <ul className="PanelTabs-menu">
                {tabs &&
                    Object.keys(tabs)
                        .filter((key) => tabs[key].show !== false)
                        .map((key) => <PanelTabsItem key={key} id={key} title={tabs[key].title} to={tabs[key].path} exact={tabs[key].exact} />)}
                {children}
            </ul>
            <span className="PanelTabs-actions u-flexSplit">
                {actions}
                {referrer && (
                    <Link to={referrer} className="Tile-head-close u-ml-sm">
                        <i className="icon icon-cross"></i>
                    </Link>
                )}
            </span>
        </div>
    );
};

const getActiveTab = (tabs, location) => {
    const tabKey = tabs && Object.keys(tabs).find((key) => matchPath(location.pathname, { ...tabs[key] }));
    if (tabKey) return tabs[tabKey];
};

export default PanelTabs;
