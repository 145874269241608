import React from "react";
import ActionLink from "./ActionLink";

const Alert = ({ id, type, code, text, onAction, actionMessage = "Dismiss", html, className, bold, actionAlignRight, actionIcon = "IconLink--cross-circle", moreInfo }) => {
    if (!text && !html) return null;

    const alertText = text === Object(text) && !React.isValidElement(text) ? "Oops, something went wrong." : text;

    const alertLevel = type.toLowerCase();
    let iconLevel = "alert";
    switch (alertLevel) {
        case "error":
            iconLevel = "alert";
            break;
        case "success":
            iconLevel = "tick-circle";
            break;
        case "help":
            iconLevel = "help";
            break;
        default:
            break;
    }

    const _action = (e) => {
        e.preventDefault();
        onAction(code);
    };

    return (
        <div className={`u-mt-0 ${actionAlignRight ? "u-flexJustifyBetween" : ""} Alert Alert--${alertLevel} ${className ? className : ""} u-flexStart`} id={id}>
            <div className="u-flex u-flexSelfStart">
                <i className={"icon icon-" + iconLevel} />
                {html ? (
                    <div style={{ marginTop: "3px" }} dangerouslySetInnerHTML={{ __html: html }} />
                ) : (
                    <div style={{ marginTop: "3px" }}>
                        {bold ? <b>{bold}: </b> : null}
                        {alertText}
                    </div>
                )}
            </div>
            {onAction && (
                <div>
                    <ActionLink id="action_link" className={`${actionIcon} u-ml-md`} onClick={_action}>
                        {actionMessage}
                    </ActionLink>
                </div>
            )}
            {moreInfo && <div className="u-ml-auto">{moreInfo}</div>}
        </div>
    );
};

export default Alert;
