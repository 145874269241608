import React from "react";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import ResultsComparison from "./ResultsComparison";
import PhysicalComparison from "./PhysicalComparison";
import SystemComparison from "./SystemComparison";
import MonthlyComparison from "./MonthlyComparison";

const AnalysisComparison = ({ comparison, loading, title = "Analysis comparison", info, warning, refData }) => {
    return (
        <Panel title={title} info={info} warning={warning} loading={loading || !comparison} className="u-mt-lg" skyBlue>
            <PanelBody loading={loading || !comparison}>
                {comparison && !comparison.error && (
                    <Grid>
                        {(comparison.results || comparison.physical) && (
                            <GridCell className="u-xxl-width1of2">
                                {comparison.results && (
                                    <div className="u-mt-md">
                                        <ResultsComparison results={comparison.results} />
                                    </div>
                                )}
                                {comparison.physical && (
                                    <div className="u-mt-md">
                                        <PhysicalComparison results={comparison.physical} />
                                    </div>
                                )}
                            </GridCell>
                        )}
                        {comparison.system && (
                            <GridCell className="u-xxl-width1of2">
                                <div className="u-mt-md">
                                    <SystemComparison results={comparison.system} refData={refData} />
                                </div>
                            </GridCell>
                        )}
                        {comparison && comparison.system && ((comparison.system.animalNumbers && Object.keys(comparison.system.animalNumbers).length > 0) || (comparison.system.fertiliserNutrientsAppliedKG && Object.keys(comparison.system.fertiliserNutrientsAppliedKG).length > 0) || (comparison.system.irrigationAppliedMM || []).length > 0) && (
                            <GridCell>
                                <MonthlyComparison results={comparison.system} refData={refData} />
                            </GridCell>
                        )}
                    </Grid>
                )}
            </PanelBody>
        </Panel>
    );
};

export default AnalysisComparison;
