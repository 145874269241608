import numeral from "numeral";
import * as domain from "common/domain";
import * as icons from "common/icons";
import * as utils from "common/utils";
import * as animalUtils from "containers/BudgetHome/Animals/_utils.js";

export function isImported(feedSupplement) {
    return !feedSupplement.type || ["Purchased", "FromStorage"].includes(feedSupplement.type);
}

export function isHarvested(feedSupplement) {
    return feedSupplement.type === "Harvested" || !isImported(feedSupplement);
}

export function isPurchased(feedSupplement) {
    return feedSupplement.type === "Purchased";
}

export function isFromStorage(feedSupplement) {
    return feedSupplement.type === "FromStorage";
}

export const getSupplementTypes = (refData) => {
    const enabledSources = ["Purchased", "FromStorage"];

    const options =
        refData &&
        refData.supplementTypes &&
        refData.supplementTypes.filter((option) => {
            return enabledSources.includes(option.value);
        });

    return options;
};

export const getCategories = (feedSupplementType, refData) => {
    const harvestedCategories = ["Hay", "Baleage", "Silage", "DirectFeeding"];
    const purchasedCategories = ["Hay", "Silage", "Straws", "GrainsPulses", "Greenfeeds", "Vegeshortproducts", "Processbyproducts", "Userdefined"];
    const fromStorageCategories = ["Hay", "Baleage", "Silage", "MaizeSilage"];

    const options =
        refData &&
        refData.supplementCategories &&
        refData.supplementCategories.filter((option) => {
            switch (feedSupplementType) {
                case "Harvested":
                    return harvestedCategories.includes(option.value);
                case "Purchased":
                    return purchasedCategories.includes(option.value);
                case "FromStorage":
                    return fromStorageCategories.includes(option.value);
                default:
                    return false;
            }
        }).map((option) => {
            if (feedSupplementType === "FromStorage" && option.value === "Silage") {
                return { ...option, text: "Grass silage" };
            }
            return option;
        });

    return options;
};

export const getSupplementaryFeeds = (feedSupplement, refData, customCompositions = []) => {
    const { category } = feedSupplement;
    if (category === "Userdefined") {
        const addNewCustomOptions = [{ value: "NEW", text: "New custom feed nutrients", groupLabel: "Add new custom feed nutrients", groupIndex: 0 }];
        const myProducts = customCompositions.filter((c) => c.userId).map((c) => ({ value: c.id, text: c.name, groupLabel: "Copy my supplement feed nutrients", groupIndex: 1 }));
        const orgProducts = customCompositions.filter((c) => !c.userId).map((c) => ({ value: c.id, text: c.name, groupLabel: "Copy organisation supplement feed nutrients", groupIndex: 2 }));
        return [...addNewCustomOptions, ...myProducts, ...orgProducts];
    }

    const option = refData && refData.supplementCategories && refData.supplementCategories.find((sc) => sc.value === feedSupplement.category);

    return option && option.children && option.children.map((c) => (c.value === "Dairy goat feed (DDG)" ? { ...c, text: "Dried Distillers Grain (DDG)" } : c));
};

export const getNutrientSource = (customCompositions = [], nutrientSource) => {
    const currentSource = nutrientSource ? [{ value: nutrientSource.id, text: nutrientSource.name, groupIndex: 0, groupLabel: "Current" }] : [];
    const addNewCustomOptions = [{ value: "NEW", text: "New custom feed nutrients", groupLabel: "Add new custom feed nutrients", groupIndex: 1 }];
    const myProducts = customCompositions.filter((c) => c.userId).map((c) => ({ value: c.id, text: c.name, groupLabel: "Copy my supplement feed nutrients", groupIndex: 2 }));
    const orgProducts = customCompositions.filter((c) => !c.userId).map((c) => ({ value: c.id, text: c.name, groupLabel: "Copy organisation supplement feed nutrients", groupIndex: 3 }));
    return [...currentSource, ...addNewCustomOptions, ...myProducts, ...orgProducts];
};

export const canEstimateByBales = (feedSupplement) => {
    if (feedSupplement.category === "DirectFeeding") return false;

    if (feedSupplement.category === "Silage" && feedSupplement.silageStack) return false;

    return true;
};

export const canEstimateByWeightOnly = (feedSupplement) => {
    if (["Straws", "GrainsPulses", "Greenfeeds", "Vegeshortproducts", "Processbyproducts", "Userdefined"].includes(feedSupplement.category)) return true;

    if (feedSupplement.type === "Purchased" && feedSupplement.category === "Silage" && ["Maize silage", "Cereal silage", "Triticale silage", "Barley milky dough silage"].includes(feedSupplement.name)) return true;

    if (feedSupplement.type === "FromStorage") return true;

    return false;
};

export const getAmountTypes = (feedSupplement, refData) => {
    const options =
        refData &&
        refData.supplementAmountTypes &&
        refData.supplementAmountTypes.filter((option) => {
            if (option.value === "Bales" && !canEstimateByBales(feedSupplement)) return false;

            if (option.value !== "Weight" && canEstimateByWeightOnly(feedSupplement)) return false;

            return true;
        });

    return (
        (options &&
            options.map((option) => {
                return { ...option, text: getAmountTypeValue(option.value) };
            })) ||
        []
    );
};

export const getAmountTypeValue = (amountType) => {
    switch (amountType) {
        case "Weight":
            return "Actual weight";
        case "Volume":
            return "Volume of material";
        case "Bales":
            return "Bale size";
        case "Cuts":
            return "Cuts";
        default:
            return amountType;
    }
};

export const getAmountTypeMeta = (amountType, source) => {
    if (source && source === "FromStorage") return { label: "Dry weight", uom: "tonnes" };

    switch (amountType) {
        case "Volume":
            return { label: "Volume", uom: "m3" };
        case "Bales":
            return { label: "Number of bales", uom: "bales" };
        case "Cuts":
            return { label: "Number of cuts", uom: "cuts" };
        case "Weight":
        default:
            return { label: "Weight", uom: "tonnes" };
    }
};

export const getBaleSizingMethods = () => {
    return [
        { value: "Standard", text: "Standard bale sizing" },
        { value: "Dimensions", text: "Bale dimensions" },
    ];
};

export const cropHasSupplements = (crop) => ["Pasture", "Seed", "Forages"].includes(crop.category) && ["CutandCarry", "GrazeCut"].includes(crop.defoliationManagement);

export const canHarvestSupplements = (block) => {
    const { cropBlock = {}, crops = [] } = block;
    return block.type === "ProductivePasture" || (["ProductiveCrop", "FodderCrop"].includes(block.type) && (cropBlock.supplementsRemoved || crops.some((c) => cropHasSupplements(c))));
};

export const getAvailableDistributionStructures = (analysis, feedSupplement, id, refData) => {
    const availableStructures = [];
    const { structures = [], enterprises = [] } = analysis;

    structures
        .filter((s) => s.type !== "StandoffPad")
        .forEach((structure) => {
            const structureType = utils.valueToText(refData.structureType, structure.type);
            const enterprise = enterprises.find((enterprise) => enterprise.id === structure.enterpriseId);

            const hasAnimals = (structure.animals || []).length > 0;

            const alreadyUsed = feedSupplement.destinations && feedSupplement.destinations.some((d) => d.id !== id && d.structureId === structure.id);

            if (hasAnimals && !alreadyUsed && !availableStructures.find((s) => s.value === structure.id)) {
                if (enterprise) {
                    const enterpriseType = utils.valueToText(refData.enterpriseTypes, enterprise.type);
                    availableStructures.push({
                        value: structure.id,
                        text: `${enterpriseType} - ${structureType}`,
                    });
                } else {
                    availableStructures.push({
                        value: structure.id,
                        text: structureType,
                    });
                }
            }
        });

    return availableStructures;
};

export const getAvailableDistributionEnterprises = (analysis, feedSupplement, id, refData) => {
    const options = [];
    const { enterprises = [] } = analysis;

    enterprises
        .filter((enterprise) => enterprise.type !== "OutdoorPigs")
        .forEach((enterprise) => {
            const enterpriseType = utils.valueToText(refData.enterpriseTypes, enterprise.type);

            const alreadyUsed = feedSupplement.destinations && feedSupplement.destinations.some((d) => d.id !== id && d.enterpriseId === enterprise.id);

            if (!alreadyUsed) {
                options.push({
                    value: enterprise.id,
                    text: enterpriseType,
                });
            }
        });

    return options;
};

export const getDistributionStorageConditions = (refData, destinationType) => {
    const enabledOptions = ["Excellent", "Average", "Poor"];
    if (["SpecifiedBlocks", "AllBlocks", "Enterprise"].includes(destinationType)) enabledOptions.push("Nostorage");

    const options =
        refData &&
        refData.supplementStorageConditions &&
        refData.supplementStorageConditions.filter((option) => {
            return enabledOptions.includes(option.value);
        });

    return options;
};

export const getAnimalDistributionTypes = (analysis, refData) => {
    const options = [{ value: "Blocks", text: "Blocks" }];
    const { enterprises = [] } = analysis;

    enterprises.forEach((enterprise) => {
        const enterpriseType = utils.valueToText(refData.enterpriseTypes, enterprise.type);
        options.push({
            value: enterprise.id,
            text: enterpriseType,
        });
    });

    return options;
};

export const getBlockTypes = (refData) => {
    const enabledOptions = ["AllBlocks", "SpecifiedBlocks"];

    const options =
        refData &&
        refData.supplementDestinationTypes &&
        refData.supplementDestinationTypes.filter((option) => {
            return enabledOptions.includes(option.value);
        });

    return options;
};

export const getSpecifiedFeedingTotals = (applications) => {
    const specifiedFeedingTotals = applications.reduce(
        (totals, application) => {
            domain.farmYear.forEach((month) => {
                if (!totals[month]) totals[month] = 0;

                const monthAmount = application.months ? parseInt(application.months[month] || 0, 10) : 0;
                totals[month] += monthAmount;
                totals.total += monthAmount;
            });
            return totals;
        },
        { total: 0 }
    );

    return specifiedFeedingTotals;
};

export const getDestinationTitle = (analysis, destination, refData) => {
    const { enterprises = [], structures = [] } = analysis;
    switch (destination.type) {
        case "Enterprise": {
            const enterprise = enterprises.find((e) => e.id === destination.enterpriseId);
            if (enterprise) {
                const enterpriseType = utils.valueToText(refData.enterpriseTypes, enterprise.type);
                return `${enterpriseType}`;
            }
            break;
        }
        case "Structure": {
            const structure = structures.find((s) => s.id === destination.structureId);
            if (structure) return utils.getStructureTitle(analysis, structure, refData);
            break;
        }
        default:
            break;
    }

    return utils.valueToText(refData.supplementDestinationTypes, destination.type);
};

export const getDestinationIcon = (analysis, destination) => {
    const { enterprises = [], structures = [] } = analysis;

    switch (destination.type) {
        case "Enterprise": {
            const enterprise = enterprises.find((e) => e.id === destination.enterpriseId);
            if (enterprise) return utils.getAnimalIcon(enterprise.type);
            break;
        }
        case "Structure": {
            const structure = structures.find((e) => e.id === destination.structureId);
            return utils.getStructureIcon(structure && structure.type);
        }
        case "Storage":
            return icons.storage;
        case "OffFarm":
            return icons.offFarm;
        case "SpecifiedBlocks":
        case "AllBlocks":
            return icons.farm;
        default:
            break;
    }

    return icons.unknown;
};

export const getRemainingAmount = (feedSupplement) => {
    const destinations = feedSupplement.destinations || [];

    const supplementAmount = feedSupplement.sources.reduce((sum, source) => (sum += parseInt(source.amount, 10)), 0);
    const distributedAmount = destinations.reduce((sum, destination) => (sum += parseInt(destination.amount, 10)), 0);

    return supplementAmount - distributedAmount;
};

export const getDistributedTo = (analysis, feedSupplement, refData) => {
    const destinations = feedSupplement.destinations || [];

    const distributedTo = destinations.reduce((result, destination) => {
        const totalDistributed = (destination.amount || 0) + (destination.amountAutumn || 0);
        result.push(`${getDestinationTitle(analysis, destination, refData)} (${totalDistributed})`);
        return result;
    }, []);

    return distributedTo;
};

export const getSourcedFrom = (analysis, feedSupplement, refData) => {
    const sources = feedSupplement.sources || [];
    const { blocks = [] } = analysis;

    const sourcedFrom = [];
    if (feedSupplement.type === "Harvested") {
        blocks.forEach((block) => {
            const totalAmount = sources.reduce((sum, source) => {
                if (source.blockId === block.id) {
                    sum += source.amount;
                }
                return sum;
            }, 0);
            if (totalAmount > 0) {
                sourcedFrom.push(`${block.name} (${utils.round(totalAmount, 1)})`);
            }
        });
    } else {
        sources.forEach((source) => {
            sourcedFrom.push(`${utils.valueToText(refData.supplementTypes, feedSupplement.type)}`);
        });
    }
    return sourcedFrom;
};

export const getAvailableDistributionBlocks = (analysis) => {
    return (analysis.blocks || []).filter((block) => {
        return block.type !== "ProductiveOutdoorPigs" && (animalUtils.canHaveAnimals(block) || ["ProductiveCrop", "FodderCrop"].includes(block.type));
    });
};

export const getAmounts = (analysis, feedSupplement) => {
    const springSummerMonths = ["September", "October", "November", "December", "January", "February"];
    const springSummerSourced = (feedSupplement.sources || []).reduce((sum, source) => {
        if (!source.month || springSummerMonths.includes(source.month)) {
            var amount = numeral(source.amount);
            if (amount.value()) sum = amount.add(sum).value();
        }
        return sum;
    }, 0);
    const springSummerDistributed = (feedSupplement.destinations || []).reduce((sum, destination) => {
        var amount = numeral(destination.amount);
        if (amount.value()) sum = amount.add(sum).value();
        return sum;
    }, 0);

    const autumnWinterSourced = (feedSupplement.sources || []).reduce((sum, source) => {
        if (source.month && !springSummerMonths.includes(source.month)) {
            var amount = numeral(source.amount);
            if (amount.value()) sum = amount.add(sum).value();
        }
        return sum;
    }, 0);
    const autumnWinterDistributed = (feedSupplement.destinations || []).reduce((sum, destination) => {
        var amount = numeral(destination.amountAutumn);
        if (amount.value()) sum = amount.add(sum).value();
        return sum;
    }, 0);

    const totalSourced = numeral(numeral(springSummerSourced + autumnWinterSourced).format("0.0")).value();

    const includeAutumnCutsInTotals = showAutumnCuts(analysis, feedSupplement);
    const totalDistributed = numeral(numeral(springSummerDistributed + (includeAutumnCutsInTotals ? autumnWinterDistributed : 0)).format("0.0")).value();

    const amounts = {
        dryWeight: feedSupplement.isDryWeight,
        totalSourced,
        totalDistributed,
        totalRemaining: numeral(totalSourced).subtract(totalDistributed).value(),
        springSummerSourced,
        springSummerDistributed,
        springSummerRemaining: numeral(springSummerSourced).subtract(springSummerDistributed).value(),
        autumnWinterSourced,
        autumnWinterDistributed,
        autumnWinterRemaining: numeral(autumnWinterSourced).subtract(autumnWinterDistributed).value(),
    };
    return amounts;
};

export const showAutumnCuts = (analysis, feedSupplement) => {
    if (!feedSupplement) return false;

    if (feedSupplement.type !== "Harvested") return false;

    if (feedSupplement.amountType !== "Cuts") return false;

    return (analysis.blocks || []).some((block) => {
        return (feedSupplement.sources || []).some((s) => s.blockId === block.id) && (block.pasture || {}).pastureCategory === "Lucerne";
    });
};

export const copyCustomComposition = (supplement, customComposition) => {
    if (!customComposition) return;
    supplement.customName = customComposition.name;
    supplement.categoryGeneralType = customComposition.supplementType;
    supplement.supplementType = customComposition.supplementType;
    supplement.nutrients = customComposition.nutrients;
    supplement.dm = customComposition.dm;
    supplement.me = customComposition.me;
    supplement.clValue = customComposition.clValue;
    supplement.digestibility = customComposition.digestibility;
};

export const resetNutrients = (supplement) => {
    supplement.customName = "";
    supplement.categoryGeneralType = undefined;
    supplement.supplementType = undefined;
    supplement.nutrients = undefined;
    supplement.dm = undefined;
    supplement.me = undefined;
    supplement.clValue = undefined;
    supplement.digestibility = undefined;
};

/**
* Clean up the analysis from saving a supplement and return the updated analysis
*/
export function getUpdatedAnalysisFromSavingSupplement(analysis, feedSupplement) {
    const feedSupplements = (analysis.feedSupplements || []).map((fs) => {
        if (fs.id !== feedSupplement.id) return fs;

        const originalAmounts = getAmounts(analysis, fs);
        const newAmounts = getAmounts(analysis, feedSupplement);

        const amountsReduced = originalAmounts.totalSourced > newAmounts.totalSourced;
        const amountTypeChanged = fs.amountType !== feedSupplement.amountType;
        const prevShowAutumnCuts = showAutumnCuts(analysis, fs);
        const doShowAutumnCuts = showAutumnCuts(analysis, feedSupplement);
        let destinations = feedSupplement.destinations || [];
        // Reset distribution amounts if amount type changed or blocks have changed between lucerne and non-lucerne.
        if (amountsReduced || amountTypeChanged || prevShowAutumnCuts !== doShowAutumnCuts) {
            destinations = destinations.map((destination) => {
                return {
                    ...destination,
                    amount: 0,
                    amountAutumn: 0,
                };
            });
        } else if (doShowAutumnCuts) {
            // Reset distribution amounts if months have changed for harvested lucerne cuts.
            const prevSources = fs.sources || [];
            const sources = feedSupplement.sources || [];
            const springSummerMonths = ["September", "October", "November", "December", "January", "February"];
            const prevSpringSummerMonthCount = prevSources.filter((s) => !s.month || springSummerMonths.includes(s.month)).length;
            const prevAutumnWinterMonthCount = prevSources.filter((s) => s.month && !springSummerMonths.includes(s.month)).length;
            const springSummerMonthCount = sources.filter((s) => !s.month || springSummerMonths.includes(s.month)).length;
            const autumnWinterMonthCount = sources.filter((s) => s.month && !springSummerMonths.includes(s.month)).length;
            if (prevSpringSummerMonthCount !== springSummerMonthCount || prevAutumnWinterMonthCount !== autumnWinterMonthCount) {
                destinations = destinations.map((destination) => {
                    return {
                        ...destination,
                        amount: 0,
                        amountAutumn: 0,
                    };
                });
            }
        }

        return {
            ...feedSupplement,
            destinations,
        };
    });
    if (!feedSupplements.some((fs) => fs.id === feedSupplement.id)) {
        feedSupplements.push(feedSupplement);
    }
    
    return {
        ...analysis,
        feedSupplements
    };
}
