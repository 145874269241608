import { useLocation, matchPath } from "react-router-dom";
import * as utils from "common/utils";
import Alert from "components/Alert";
import * as domain from "common/domain";
import ActionLink from "components/ActionLink";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { useOnline, useShowQuickTips } from "common/hooks";

export const MESSAGE_CODE = {
    ENGINE_ERROR: 9999,
    UNKNOWN_ERROR: 9998,
    EXPIRED_FARM: 9998,
    CLIMATE_REQUIRED: 1001,
    AREA_REQUIRED: 1002,
    BLOCK_NOT_MAPPED: 1003,
    BLOCK_TOO_SMALL: 1004,
    BLOCK_SIZE_WARNING: 1005,
    SOIL_REQUIRED: 2001,
    INSUFFICIENT_FARM_SOIL_COVERAGE: 2003,
    FARM_SOIL_AUTO_GENERATED_WARNING: 2005,
    BLOCK_SOIL_MANAGEMENT_MOVED: 2006,
    CROPS_REQUIRED: 3001,
    FRUIT_REQUIRED: 3002,
    PASTURE_REQUIRED: 3003,
    SOWING_REQUIRED: 3004,
    FODDER_CROP_BLOCKS_REQUIRED: 3007,
    CROP_ANIMAL_DIST_INVALID_DEFOLIATION: 3008,
    CROP_ANIMAL_DIST_INVALID_SOWN: 3012,
    CROP_ANIMAL_DIST_INVALID_CROP: 3013,
    CROP_INVALID_COUNT: 3014,
    CROP_END_EVENT_REQUIRED: 3009,
    CROP_HISTORY_REQUIRED: 3010,
    CROP_DEFOLIATION_REQUIRED: 3011,
    FERTILISER_NOT_APPLIED: 4001,
    FERTILISERFORM_NOT_IMPORTED: 4003,
    FERTILISER_APP_MONTH: 4005,
    IRRIGATION_NOT_APPLIED: 5001,
    OVERLAPPING_IRRIGATORS: 5002,
    NO_BLOCKS_FOR_IRRIGATOR: 5003,
    SOIL_TESTS_NOT_APPLIED: 6001,
    WETLANDS_CATCHMENT_REQUIRED: 7001,
    WETLANDS_PERCENTAGE_CATCHMENT_AREA_GREATER_THAN_BLOCK_AREA: 7002,
    GRASS_FILTER_STRIP_CATCHMENT_AREA_GREATER_THAN_BLOCK_AREA: 7003,
    WETLAND_CATCHMENT_DISTRIBUTION_REQUIRED: 7004,
    LIVESTOCK_DATA_REQUIRED: 8001,
    ANIMALS_NOT_ON_FARM: 8002,
    ANIMALS_NOT_DISTRIBUTED: 8003,
    ANIMALS_REQUIRED_FOR_PASTURE_BLOCK: 8004,
    SWARD_NO_ENTERPRISE: 8005,
    NO_ANIMAL_BLOCKS: 8009,
    OUTDOOR_PIGS_ENTERPRISE_REQUIRED: 8016,
    OUTDOOR_PIGS_BLOCK_REQUIRED: 8017,
    OUTDOOR_PIGS_BLOCK_ALLOCATION_REQUIRED: 8018,
    SUPPLEMENTS_UNDER_DISTRIBUTED: 9001,
    SUPPLEMENTS_OVER_DISTRIBUTED: 9002,
    SUPPLEMENTS_DISTRIBUTED_TO_BLOCK_WITH_NO_ANIMALS: 9003,
    SUPPLEMENTS_REQUIRED_FOR_PASTURE_BLOCK: 9004,
    SUPPLEMENTS_HARVESTED_FROM_DIFFERENT_PASTURE_TYPES: 9007,
    SUPPLEMENTS_REQUIRED_FOR_CROP_BLOCK: 9010,
    STRUCTURES_NO_ENTERPRISE: 10000,
    STRUCTURES_INVALID_EFFLUENTSYS: 10001,
    STRUCTURES_MISSING_SOLID_APPS: 10002,
    STRUCTURES_MISSING_LIQUID_APPS: 10003,
    PASTURE_EATEN_INVALID_SUM: 10010,
    GOAT_LEFTOVERS_BLOCKS_NOT_FED: 10012,
    INVALID_FOREST: 12000,
};

export const categoriseMessage = (message, category) => {
    if (message.code === MESSAGE_CODE.SOIL_REQUIRED) {
        return category === "Soil";
    }
    return message.category === category;
};

export default function TabMessages({ farm, analysis, tab, className, publication, actions = {} }) {
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const online = useOnline();
    const showQuickTips = useShowQuickTips();
    const location = useLocation();

    const category = tab.category || tab.title;
    const messagesByCategory = publication ? analysis.messages && analysis.messages.filter((m) => m.severity === "Error") : analysis.messages && analysis.messages.filter((m) => categoriseMessage(m, category));

    const messages = [];

    if (farm.expired || !farm.expirationDate) {
        const expiredText = analysis.currentResults ? "This farm does not have an active subscription. If you make any changes to this analysis the model will not run and so existing results will be removed." : "This farm does not have an active subscription. The model will not run and so no results are available.";
        const userHasFarmWriteAccess = utils.canUpdateFarm(farm);
        const subscriptionMessage = userHasFarmWriteAccess ? " Select 'Pay for subscription' to pay for a subscription." : "";

        messages.push({
            entityType: "farm",
            entityId: farm.id,
            code: MESSAGE_CODE.EXPIRED_FARM,
            severity: "error",
            text: expiredText + subscriptionMessage,
        });
    } else if (!messagesByCategory) return null;

    const _suppress = async (code) => {
        if (analysis.suppressedMessageCodes.includes(code)) {
            return;
        }
        analysis.suppressedMessageCodes.push(code);
        await updateAnalysisAsync(analysis);
    };

    const _suppressed = (code) => analysis.suppressedMessageCodes && analysis.suppressedMessageCodes.includes(code);

    const _processMessages = (severity, code, text, codeAs, onAction) => {
        if (location.pathname.toLowerCase().endsWith("/animals/enterprises/outdoorpigs") && code === MESSAGE_CODE.OUTDOOR_PIGS_ENTERPRISE_REQUIRED) return;

        if (_suppressed(code)) return;

        const messagesByCode = messagesByCategory && messagesByCategory.filter((m) => m.code === code);
        if (!messagesByCode || messagesByCode.length === 0) return;

        let blocks = [];
        let enterprises = [];

        switch (code) {
            case MESSAGE_CODE.STRUCTURES_INVALID_EFFLUENTSYS:
            case MESSAGE_CODE.STRUCTURES_MISSING_SOLID_APPS:
            case MESSAGE_CODE.STRUCTURES_MISSING_LIQUID_APPS: {
                let structures = messagesByCode.reduce((results, message) => {
                    if (!results.includes(message.info)) results.push(message.info);
                    return results;
                }, []);
                text = `${structures.join(", ")} - ${messagesByCode[0].text}`;
                break;
            }
            case MESSAGE_CODE.ANIMALS_NOT_ON_FARM:
                enterprises = messagesByCode.reduce((results, message) => {
                    if (!results.includes(message.info)) results.push(message.info);
                    return results;
                }, []);
                text = `${enterprises.join(", ")} - You have distributed animals to your blocks during months when they are not on your farm. Select the animal distribution link below.`;
                break;

            case MESSAGE_CODE.NO_ANIMAL_BLOCKS:
                enterprises = messagesByCode.reduce((results, message) => {
                    if (!results.includes(message.info)) results.push(message.info);
                    return results;
                }, []);
                text = `${enterprises.join(", ")} - You have animals on the farm but no blocks that can support animals. Select the pasture/crops tab and ensure that you have selected animals present for all pastoral blocks.`;
                break;

            case MESSAGE_CODE.ANIMALS_NOT_DISTRIBUTED:
                enterprises = messagesByCode.reduce((results, message) => {
                    if (!results.includes(message.info)) results.push(message.info);
                    return results;
                }, []);
                text = `${enterprises.join(", ")} - You have months when your animals have not been distributed to any blocks. Select the animal distribution link below.`;
                break;
            case MESSAGE_CODE.PASTURE_EATEN_INVALID_SUM:
                blocks = messagesByCode.reduce((results, message) => {
                    if (!results.includes(message.info)) results.push(message.info);
                    return results;
                }, []);
                text = `${blocks.join(", ")} - ${blocks.length > 1 ? "These blocks have" : "This block has"} a sum of pasture eaten that does not add up to 100%. Please select animal distribution to resolve.`;
                break;
            case MESSAGE_CODE.ANIMALS_REQUIRED_FOR_PASTURE_BLOCK:
                blocks = messagesByCode.reduce((results, message) => {
                    if (!results.includes(message.info)) results.push(message.info);
                    return results;
                }, []);
                text = `${blocks.join(", ")} - Animals must be assigned to these blocks, or finish setting up the pasture characteristics for the block. Edit the block's pasture or the animal distribution.`;
                break;
            case MESSAGE_CODE.SUPPLEMENTS_REQUIRED_FOR_PASTURE_BLOCK:
                blocks = messagesByCode.reduce((results, message) => {
                    if (!results.includes(message.info)) results.push(message.info);
                    return results;
                }, []);
                text = `${blocks.join(", ")} - You must harvest supplements from these blocks or define animals as present (under pasture) and add animals under animal distribution.`;
                break;
            case MESSAGE_CODE.WETLANDS_PERCENTAGE_CATCHMENT_AREA_GREATER_THAN_BLOCK_AREA:
                blocks = messagesByCode.reduce((results, message) => {
                    if (!results.includes(message.info)) results.push(message.info);
                    return results;
                }, []);
                text = `${blocks.join(", ")} - ${blocks.length > 1 ? "These blocks have" : "This block has"} a drainage catchment area greater than ${blocks.length > 1 ? "their" : "its"} actual block size. Please check the block size and/or the drainage/wetlands associated with ${blocks.length > 1 ? "these" : "this"} block.`;
                break;
            case MESSAGE_CODE.GRASS_FILTER_STRIP_CATCHMENT_AREA_GREATER_THAN_BLOCK_AREA:
                blocks = messagesByCode.reduce((results, message) => {
                    if (!results.includes(message.info)) results.push(message.info);
                    return results;
                }, []);
                text = `${blocks.join(", ")} - ${blocks.length > 1 ? "These blocks have" : "This block has"} a grass filter strip catchment area greater than ${blocks.length > 1 ? "their" : "its"} actual block size. Please check the block size and/or the grass filter strip associated with ${blocks.length > 1 ? "these" : "this"} block.`;
                break;
            case MESSAGE_CODE.WETLAND_CATCHMENT_DISTRIBUTION_REQUIRED: {
                let wetlands = messagesByCode.reduce((results, message) => {
                    if (!results.includes(message.info)) results.push(message.info);
                    return results;
                }, []);
                text = `${wetlands.join(", ")} - Catchment distribution required.`;
                break;
            }
            default:
                break;
        }

        /* eslint-disable no-unused-vars */
        for (const messageByCode of messagesByCode) {
            let messageText = text || messageByCode.text;
            let actionIcon = "IconLink--cross-circle";
            let actionMessage = "Dismiss";

            switch (code) {
                case MESSAGE_CODE.LIVESTOCK_DATA_REQUIRED:
                    messageText = `${messageByCode.info} livestock data is required. Edit ${messageByCode.info} to define the livestock data for your farm.`;
                    break;
                case MESSAGE_CODE.PASTURE_REQUIRED:
                    actionIcon = "IconLink--arrow-plus";
                    actionMessage = "Enter pasture details";
                    onAction = actions[MESSAGE_CODE.PASTURE_REQUIRED];
                    break;
                case MESSAGE_CODE.CROPS_REQUIRED:
                    actionIcon = "IconLink--arrow-plus";
                    actionMessage = "Enter crop details";
                    onAction = actions[MESSAGE_CODE.CROPS_REQUIRED];
                    break;
                case MESSAGE_CODE.FRUIT_REQUIRED:
                    actionIcon = "IconLink--arrow-plus";
                    actionMessage = "Enter fruit details";
                    onAction = actions[MESSAGE_CODE.FRUIT_REQUIRED];
                    break;
                case MESSAGE_CODE.INSUFFICIENT_FARM_SOIL_COVERAGE:
                    actionIcon = "IconLink--edit";
                    actionMessage = "Edit farm soils";
                    onAction = hasFarmAccess ? actions[MESSAGE_CODE.INSUFFICIENT_FARM_SOIL_COVERAGE] : null;
                    break;

                case MESSAGE_CODE.SOIL_REQUIRED:
                    actionIcon = "IconLink--edit";
                    actionMessage = "Edit block soils";
                    onAction = actions[MESSAGE_CODE.SOIL_REQUIRED];
                    break;

                case MESSAGE_CODE.ENGINE_ERROR:
                    if (messageText.includes("'Animal Reports")) {
                        actionIcon = "IconLink--compare";
                        actionMessage = "Go to animal reports";
                        onAction = actions[MESSAGE_CODE.ENGINE_ERROR];
                    }
                    break;
                default:
                    break;
            }

            const alreadyProcessed = codeAs && messages.some((m) => m.code === codeAs);
            if (!alreadyProcessed) {
                messages.push({
                    entityType: messageByCode.entityType,
                    entityId: messageByCode.entityId,
                    code: code,
                    severity: severity,
                    text: messageText,
                    actionIcon,
                    actionMessage,
                    onAction,
                });
            }

            if (text) break;
        }
    };

    const _processErrors = (code, text, codeAs) => _processMessages("error", code, text, codeAs);

    const _processWarnings = (code, text, codeAs) => _processMessages("warning", code, text, codeAs, _suppress);

    const isAnimalsHidden = location.pathname.toLowerCase().endsWith("/animals/distribution") || location.pathname.toLowerCase().includes("/animals/enterprises");
    const isStructuresHidden = location.pathname.toLowerCase().includes("/structures/structure");
    const isOnlyFarmSoilError = messages.every((m) => m.code === MESSAGE_CODE.INSUFFICIENT_FARM_SOIL_COVERAGE);
    const hasFarmAccess = utils.canUpdateFarm(farm);

    switch (category) {
        case "Blocks":
            _processWarnings(MESSAGE_CODE.BLOCK_NOT_MAPPED, "One or more blocks have not been drawn on the map. Click on suppress if do not require blocks to be mapped.");
            _processErrors(MESSAGE_CODE.CLIMATE_REQUIRED, "There was a problem getting climate data for one or more blocks. Please check the location of the block, or the farms location if the block is not drawn.");
            _processErrors(MESSAGE_CODE.INVALID_FOREST, "One or more blocks have invalid forest data.");
            _processErrors(MESSAGE_CODE.AREA_REQUIRED, "One or more blocks do not have a valid area. Please check the block drawing..");
            _processErrors(MESSAGE_CODE.BLOCK_TOO_SMALL, "Blocks must be at least 0.1 Ha in size. Blocks are split by soil and irrigation and so this will reduce the size of the blocks that are sent to the model.");
            _processWarnings(MESSAGE_CODE.BLOCK_SIZE_WARNING, "Pastoral blocks under 1 Ha are not recommended for the model. Please review the size, soils and irrigation for these blocks as this splits the block into smaller blocks for the model.");
            _processWarnings(MESSAGE_CODE.BLOCK_SOIL_MANAGEMENT_MOVED, 'The management of block soils has been moved to the "Soil" tab.');
            break;
        case "Soil":
            _processErrors(MESSAGE_CODE.SOIL_REQUIRED, "One or more blocks require soil data.");
            _processErrors(MESSAGE_CODE.INSUFFICIENT_FARM_SOIL_COVERAGE, hasFarmAccess ? "There is insufficient coverage of blocks with soil polygons. Please check the farm soils map and make sure all blocks are covered." : "There is insufficient coverage of blocks with soil polygons. You do not have access to the farm, so you will need to get access or revert to using analysis soils.");
            _processWarnings(MESSAGE_CODE.FARM_SOIL_AUTO_GENERATED_WARNING, "The soils for some of the blocks have been automatically assigned. Please check that they are correct or edit them to adjust the percentages.");
            _processWarnings(MESSAGE_CODE.SOIL_TESTS_NOT_APPLIED, "One or more soils do not have soil tests. Click on suppress to use typical soil test values.");
            break;
        case "Crops":
            _processErrors(MESSAGE_CODE.CROPS_REQUIRED, "One or more crop blocks require crop details.");
            _processErrors(MESSAGE_CODE.FRUIT_REQUIRED, "One or more fruit blocks require fruit details.");
            _processErrors(MESSAGE_CODE.PASTURE_REQUIRED, "One or more pasture blocks require pasture details.");
            _processErrors(MESSAGE_CODE.SOWING_REQUIRED, "One or more crops require a sowing event.");
            _processErrors(MESSAGE_CODE.FODDER_CROP_BLOCKS_REQUIRED, "One or more fodder crop rotations require pasture blocks set.");
            _processErrors(MESSAGE_CODE.CROP_ANIMAL_DIST_INVALID_DEFOLIATION, "Animal grazing distribution is invalid for one or more defoliation events.");
            _processErrors(MESSAGE_CODE.CROP_ANIMAL_DIST_INVALID_SOWN, "Animal grazing distribution is invalid for one or more sown events.");
            _processErrors(MESSAGE_CODE.CROP_ANIMAL_DIST_INVALID_CROP, "Animal grazing distribution is invalid for prior land use.");
            _processErrors(MESSAGE_CODE.CROP_INVALID_COUNT, "One or more crop blocks require crop details.", MESSAGE_CODE.CROPS_REQUIRED);
            _processErrors(MESSAGE_CODE.CROP_END_EVENT_REQUIRED, "Fodder and forage crops need a cultivation, additional defoliations, or final harvest entered when defoliation is present.");
            _processErrors(MESSAGE_CODE.CROP_HISTORY_REQUIRED, "Crop history is required for one or more crops.");
            _processErrors(MESSAGE_CODE.CROP_DEFOLIATION_REQUIRED, 'Defoliation management is required on one or more crops. This analysis was most likely created from a legacy xml file that pre dates Overseer version 6.3.0. To fix this error, edit the errored crop and enter "Defoliation management" for any pasture or pasture seed crops.');
            break;
        case "Fertiliser":
            _processWarnings(MESSAGE_CODE.FERTILISER_NOT_APPLIED, "One or more productive blocks do not have fertiliser.");
            _processWarnings(MESSAGE_CODE.FERTILISERFORM_NOT_IMPORTED, "The xml file that was imported contained fertiliser specified by fertiliser form. This has not been imported. Please add your fertiliser again.");
            _processErrors(MESSAGE_CODE.FERTILISER_APP_MONTH, "One or more fertiliser applications require months to be set.");
            break;
        case "Irrigation":
            _processWarnings(MESSAGE_CODE.IRRIGATION_NOT_APPLIED, "No irigation has been applied to blocks. If you did not apply irrigation click on suppress to remove this message.");
            _processErrors(MESSAGE_CODE.OVERLAPPING_IRRIGATORS, "The irrigated areas for two or more irrigation systems overlap. Please review the irrigated areas for the highlighted irrigation systems.");
            _processErrors(MESSAGE_CODE.NO_BLOCKS_FOR_IRRIGATOR, "An irrigation system must intersect more than 5% of at least one block. Please review the irrigated areas for the highlighted irrigation systems.");
            break;
        case "Wetlands":
            _processErrors(MESSAGE_CODE.WETLANDS_CATCHMENT_REQUIRED, "Fenced wetlands require catchment data.");
            _processErrors(MESSAGE_CODE.WETLANDS_PERCENTAGE_CATCHMENT_AREA_GREATER_THAN_BLOCK_AREA, "One or more blocks have allocated wetland catchment areas greater than their block areas.");
            _processErrors(MESSAGE_CODE.GRASS_FILTER_STRIP_CATCHMENT_AREA_GREATER_THAN_BLOCK_AREA, "One or more pasture blocks have grass filter strip catchment areas greater than their block areas.");
            _processErrors(MESSAGE_CODE.WETLAND_CATCHMENT_DISTRIBUTION_REQUIRED, "Catchment distribution required.");
            break;
        case "Effluent":
            _processErrors(MESSAGE_CODE.STRUCTURES_NO_ENTERPRISE, "One or more structures need enterprises configured.");
            _processErrors(MESSAGE_CODE.STRUCTURES_INVALID_EFFLUENTSYS, "One or more structures need an effluent system configured.");
            _processErrors(MESSAGE_CODE.STRUCTURES_MISSING_SOLID_APPS, "One or more structures are missing applications of solids.");
            _processErrors(MESSAGE_CODE.STRUCTURES_MISSING_LIQUID_APPS, "One or more structures are missing applications of liquids.");

            break;
        case "Supplements":
            _processErrors(MESSAGE_CODE.SUPPLEMENTS_UNDER_DISTRIBUTED, "One or more supplements have not been fully distributed.");
            _processErrors(MESSAGE_CODE.SUPPLEMENTS_OVER_DISTRIBUTED, "One or more supplements have been over distributed.");
            _processErrors(MESSAGE_CODE.SUPPLEMENTS_DISTRIBUTED_TO_BLOCK_WITH_NO_ANIMALS, "One or more supplements have been distributed to blocks that do not have farm animals grazing on them. Note, supplements cannot be distributed to blocks that have non-farm animals grazing.");
            _processErrors(MESSAGE_CODE.SUPPLEMENTS_HARVESTED_FROM_DIFFERENT_PASTURE_TYPES, "One or more supplements have been harvested from blocks with different pasture types.");
            _processErrors(MESSAGE_CODE.SUPPLEMENTS_REQUIRED_FOR_CROP_BLOCK, "One or more crop blocks have been marked as having supplements removed in the reporting year. Please add a harvesting event for these blocks.");
            break;
        case "Animals":
            _processErrors(MESSAGE_CODE.LIVESTOCK_DATA_REQUIRED);
            _processErrors(MESSAGE_CODE.ANIMALS_NOT_ON_FARM);
            _processErrors(MESSAGE_CODE.NO_ANIMAL_BLOCKS);
            _processErrors(MESSAGE_CODE.OUTDOOR_PIGS_ENTERPRISE_REQUIRED);
            _processErrors(MESSAGE_CODE.OUTDOOR_PIGS_BLOCK_REQUIRED);
            _processErrors(MESSAGE_CODE.OUTDOOR_PIGS_BLOCK_ALLOCATION_REQUIRED);
            _processErrors(MESSAGE_CODE.ANIMALS_NOT_DISTRIBUTED);
            _processErrors(MESSAGE_CODE.SWARD_NO_ENTERPRISE, "One or more fruit blocks require animals for sward management. Select animal distribution to add animals.");
            _processWarnings(MESSAGE_CODE.GOAT_LEFTOVERS_BLOCKS_NOT_FED, "You may have left over feed from supplements fed to dairy goats on pastoral blocks. If this has been fed to other animals, edit the dairy enterprise and set the percentages fed out.");

            break;
        case "Overview":
            _processErrors(MESSAGE_CODE.ENGINE_ERROR);
            _processErrors(MESSAGE_CODE.UNKNOWN_ERROR);
            break;
        default:
            break;
    }

    if (messagesByCategory) {
        /* eslint-disable no-unused-vars */
        for (const message of messagesByCategory) {
            const code = message.code === MESSAGE_CODE.CROP_INVALID_COUNT ? MESSAGE_CODE.CROPS_REQUIRED : message.code;
            var processed = messages.find((m) => m.code === code);
            if (!processed) _processMessages(message.severity.toLowerCase(), message.code, message.text, _suppress);
        }
    }

    const gotoError = () => {
        var errorAnchors = document.getElementsByClassName("error-anchor");
        if (errorAnchors && errorAnchors.length > 0) {
            errorAnchors[0].scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    const takeMeToFirstError =
        messagesByCategory && messagesByCategory.find((m) => m.severity === "Error") && category !== "Overview" && !isAnimalsHidden && !isStructuresHidden && !isOnlyFarmSoilError ? (
            <div className="u-mt-0  Alert Alert--error ">
                <div className="u-flex">
                    <i className={"icon icon-alert"} />
                    <div style={{ paddingTop: "3px", fontSize: "13px" }}>
                        <ActionLink id="lnk_first_error" className="u-IconLink--arrow-down" onClick={() => gotoError()} title="Take me to my first error">
                            Take me to my first error
                        </ActionLink>
                    </div>
                </div>
            </div>
        ) : null;

    return (
        <div className={`${className ? className : ""}`}>
            <>
                {showQuickTips && domain.tabHelpMessages[tab.route] && matchPath(location.pathname, { path: `/app/farm/${farm.id}/analysis/${analysis.id}/${tab.route}`, exact: true }) && <Alert type="help" text={domain.tabHelpMessages[tab.route]} />}
                {online && messages.length > 0 && (
                    <>
                        {messages.map((message, index) => (
                            <Alert key={`${message.code}-${index}`} type={message.severity} code={message.code} text={message.text} actionIcon={message.actionIcon} actionMessage={message.actionMessage} onAction={message.onAction} />
                        ))}
                        {takeMeToFirstError}
                    </>
                )}
            </>
        </div>
    );
}
