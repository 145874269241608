import { Component } from "react";
import { Form } from "react-final-form";
import { useParams, Link } from "react-router-dom";
import SavePrompt from "components/SavePrompt";
import NotFound from "components/NotFound";
import { Panel, PanelBody, PanelFooter } from "components/Panel";
import Button from "components/Button/Button";
import { useUpdateAnalysisAsync } from "containers/hooks";
import * as cropUtils from "../_utils";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import FruitFormFields, { validateFruitFormFields } from "./FruitFormFields";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function Fruit({ farm, analysis }) {
    const { blockId } = useParams();
    const block = (analysis.blocks || []).find((b) => b.id === blockId);
    const fruit = block?.fruit || {};

    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    return <FruitClassComponent farm={farm} analysis={analysis} block={block} fruit={fruit} updateAnalysisAsync={updateAnalysisAsync} />
}

class FruitClassComponent extends Component {
    state = {
        referrer: `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/crops`,
        submitSucceeded: false,
    };

    save = async (fruit) => {
        const { analysis, block } = this.props;

        const updatedAnalysis = cropUtils.getUpdatedAnalysisFromSavingFruit(analysis, block.id, fruit);
        await this.props.updateAnalysisAsync(updatedAnalysis);

        this.setState({ submitSucceeded: true });
    };

    render() {
        const { farm, analysis, block, fruit } = this.props;

        if (!block) {
            return <NotFound />;
        }

        const title = block ? `Fruit details: ${block.name}` : "Fruit details";

        return (
            <Panel>
                <PageTabs farm={farm} analysis={analysis} tab={TAB.CROPS} />
                <PanelBody>
                    <Form initialValues={fruit} validate={validateFruitFormFields} onSubmit={this.save}>
                        {({ form, values, handleSubmit, pristine, submitting, dirty }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <SavePrompt blockIf={dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={this.state.referrer} />
                                    <Panel title={title} waiting={submitting} referrer={this.state.referrer}>
                                        <PanelBody waiting={submitting}>
                                            <FruitFormFields block={block} form={form} values={values} />
                                        </PanelBody>
                                        <PanelFooter>
                                            <div className="ButtonBar ButtonBar--fixed">
                                                <div className="ButtonBar-left">
                                                    <Link to={this.state.referrer} className="Button Button--secondary" id="fruit-cancel" disabled={submitting}>
                                                        Cancel
                                                    </Link>
                                                </div>
                                                <div className="ButtonBar-right">
                                                    <Button id="fruit-submit" submit primary waiting={submitting} disabled={pristine}>
                                                        Save
                                                    </Button>
                                                </div>
                                            </div>
                                        </PanelFooter>
                                    </Panel>
                                </form>
                            );
                        }}
                    </Form>
                </PanelBody>
            </Panel>
        )
    }
}
