import { Switch, Route } from "react-router-dom";
import { Panel, PanelBody } from "components/Panel";
import { PaymentTransactions, AutoRenewals, FailedPayments } from "containers/Admin/Payments";

export default function Payments() {
    const baseUrl = "/app/admin/payments";
    const tabs = {
        payments: { title: "Payment transactions", path: `${baseUrl}`, exact: true },
        autoRenewals: { title: "Upcoming auto renewals", path: `${baseUrl}/renewals`, exact: true },
        failedPayments: { title: "Failed payments with no subscriptions", path: `${baseUrl}/failedpayments`, exact: true },
    };
    return (
        <Panel tabs={tabs}>
            <PanelBody>
                <Switch>
                    <Route exact path={tabs.payments.path} render={() => <PaymentTransactions />} />
                    <Route exact path={tabs.autoRenewals.path} render={() => <AutoRenewals />} />
                    <Route exact path={tabs.failedPayments.path} render={() => <FailedPayments />} />
                </Switch>
            </PanelBody>
        </Panel>
    );
}
