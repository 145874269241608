import ReactGA from "react-ga4";
import { LOCATION_CHANGE } from "connected-react-router";

ReactGA.initialize(process.env.REACT_APP_GA);
let prevLocation = {};

const googleAnalyticsMiddleware = (store) => (next) => (action) => {
    if (action.type === LOCATION_CHANGE) {
        const nextLocation = action.payload.location;

        if (prevLocation.pathname !== nextLocation.pathname || prevLocation.search !== nextLocation.search) {
            const accountId = store.getState().auth.accountId;
            if (accountId) ReactGA.set({ userId: accountId });

            const page = nextLocation.pathname + nextLocation.search;
            ReactGA.send({ hitType: "pageview", page: page });
        }

        prevLocation = action.payload.location;
    }
    return next(action);
};

export default googleAnalyticsMiddleware;
