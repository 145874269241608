import React from "react";
import * as utils from "common/utils";
import * as domain from "common/domain";

const MonthlyComparison = ({ results = {}, refData }) => {
    return (
        <div className="Table Table-Compressed u-mt-md">
            <table className="Calendar">
                <thead>
                    <tr className="Calendar-months">
                        <th className="h3">Monthly comparison</th>
                        {domain.farmYear.map((m, i) => (
                            <th key={i} className="u-textCenter">
                                {m.substring(0, 3)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {results.animalNumbers && Object.keys(results.animalNumbers).map((key, index) => <MonthlyComparisonRow key={index} months={results.animalNumbers[key]} label={`Animal numbers - ${utils.valueToText(refData.enterpriseTypes, key)}`} />)}
                    {results.fertiliserNutrientsAppliedKG && Object.keys(results.fertiliserNutrientsAppliedKG).map((key, index) => <MonthlyComparisonRow key={index} months={results.fertiliserNutrientsAppliedKG[key]} label={`Fertiliser applied - ${key}`} />)}
                    {(results.irrigationAppliedMM || []).length > 0 && <MonthlyComparisonRow months={results.irrigationAppliedMM} label="Irrigation applied (mm)" />}
                    {(results.irrigatedArea || []).length > 0 && <MonthlyComparisonRow months={results.irrigatedArea} label="Irrigated area (ha)" />}
                </tbody>
            </table>
        </div>
    );
};

const MonthlyComparisonRow = ({ months, label }) => {
    return (
        <tr>
            <td className="u-textBold">{label}</td>
            {domain.farmYear.map((m, i) => {
                const key = label + "-" + m;
                const change = months.find((c) => c.month === m);
                const hasIncreased = change && change.to > change.from;

                return change ? (
                    <td key={key} className={`Calendar-slot ${change ? (hasIncreased ? "Table-td--red" : "Table-td--green") : ""}`}>
                        {Number(change.from).toLocaleString()} {hasIncreased ? "<" : ">"} {Number(change.to).toLocaleString()}
                    </td>
                ) : (
                    <td key={key}></td>
                );
            })}
        </tr>
    );
};

export default MonthlyComparison;
